import React, { useState } from 'react';
import { RouterLink } from '@routing/router';

import { CollectionModel } from '@api/collections/CollectionModel';
import Box from '@components/Box';
import FavoriteButton from '@components/FavoriteButton';
import Text from '@components/Text';
import theme from '@styles/theme';
import formatNumber from '@utils/formatNumber';
import itemRandomGenerator from '@utils/itemRandomGenerator';

import { COLLECTION_ICON_DEFAULT_COLORS } from './CollectionCard.constants';
import {
  StyledCollectionCard,
  StyledCollectionCardDescription,
  StyledCollectionCardIconButton,
  StyledCollectionCardTitle,
} from './CollectionCard.styles';
import { CollectionIconColors } from './CollectionCard.types';

interface CollectionCardProps {
  collection: CollectionModel;
}

const CollectionCard = ({ collection }: CollectionCardProps) => {
  const [isSelected, setIsSelected] = useState(false);
  const { assetCount, description, guid, name, routePath } = collection;

  const { backgroundColor, iconColor } = itemRandomGenerator<CollectionIconColors>({
    itemsList: COLLECTION_ICON_DEFAULT_COLORS,
    representativeText: guid,
  }) as CollectionIconColors;

  const handleCardSelection = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsSelected((current) => !current);
  };

  return (
    <StyledCollectionCard as={RouterLink} to={routePath}>
      <Box alignItems="center" compDisplay="flex" justifyContent="space-between">
        <Box alignItems="center" compDisplay="flex" gap={1}>
          <StyledCollectionCardIconButton
            aria-label="check-button"
            backgroundColor={isSelected ? theme.colors.white : backgroundColor}
            iconColor={isSelected ? 'gray.800' : iconColor}
            iconName={isSelected ? 'check' : 'collection'}
            iconSize="28px"
            isSelected={isSelected}
            onClick={handleCardSelection}
          />
          <StyledCollectionCardTitle aria-label="collection-name">{name}</StyledCollectionCardTitle>
        </Box>
        <FavoriteButton iconButtonSize="lg" iconSize="24px" objGuid={guid} objName={name} />
      </Box>
      <Box alignItems="center" compDisplay="flex" gap={2}>
        <Text aria-label="collection-counts" color="gray.500" fontSize="body2" fontWeight="medium">
          {formatNumber({ value: assetCount })} Asset{assetCount === 1 ? '' : 's'}
        </Text>
      </Box>
      <StyledCollectionCardDescription aria-label="collection-description">
        {description}
      </StyledCollectionCardDescription>
    </StyledCollectionCard>
  );
};

export default CollectionCard;
