import { Expose } from 'class-transformer';

export interface DataSourceSettingsData {
  isEditable: boolean;
}

export class DataSourceSettings {
  @Expose({ name: 'is_editable' })
  isEditable!: boolean;

  @Expose({ name: 'has_dbt_tests' })
  hasDbtTests?: boolean;
}
