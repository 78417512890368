import { Expose, Transform, Type } from 'class-transformer';
import moment from 'moment';

import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import type { DescriptionSource } from '@api/description/description.types';
import { TaggedItemModel } from '@api/tags/TaggedItemModel';
import { ObjectType } from '@api/types';
import { breadcrumbsToLabelList, breadcrumbsToList } from '@components/Breadcrumbs';
import isEmptyRichText from '@components/RichTextEditor/helpers/isEmptyRichText';
import DataTypesModel from '@models/DataTypesModel';
import { MetadataModel } from '@models/MetadataModel';
import { PopularityData, PopularityModel } from '@models/PopularityModel';
import { Reference } from '@models/Reference';
import { urlFor } from '@utils/routing';

import { LookerExploreData, LookerExploreModel } from '../explores/LookerExploreModel';
import { LookMLViewModel } from '../lookML/LookMLViewModel';

export interface FieldData {
  dataType: string;
  dataTypeIcon: string;
  description?: string;
  explore: LookerExploreData;
  externalType: string;
  fieldType: string;
  guid: string;
  icon: string;
  isUsedAsFilter?: boolean;
  isUsedAsPivot?: boolean;
  label: string;
  name: string;
  popularity?: PopularityData;
  suggestedDescription?: string;
  type: string;
  viewLabel: string;
}

export class LookMLFieldModel {
  static objectType: ObjectType = 'lookmlfield';

  objectType: ObjectType = LookMLFieldModel.objectType;

  static typeDisplay: string = 'LookMLField';

  typeDisplay: string = LookMLFieldModel.typeDisplay;

  guid!: string;

  name!: string;

  @Expose({ name: 'search_name' })
  fullName?: string;

  description?: string;

  @Expose({ name: 'data_types' })
  @Type(() => DataTypesModel)
  dataTypes?: DataTypesModel = new DataTypesModel();

  @Expose({ name: 'suggested_description' })
  suggestedDescription?: string;

  @Expose({ name: 'suggested_description_source' })
  suggestedDescriptionSource?: string;

  @Expose({ name: 'suggested_description_source_object' })
  @Type(() => Reference)
  suggestedDescriptionSourceObject?: Reference<MetadataModel>;

  // TODO: Remove transform https://app.shortcut.com/select-star/story/55620
  @Expose({ name: 'richtext_description' })
  @Transform((richtextDescription) =>
    isEmptyRichText(richtextDescription) ? '' : richtextDescription,
  )
  richtextDescription?: string;

  label!: string;

  type!: string;

  sql?: string;

  @Expose({ name: 'external_type' })
  externalType!: string;

  @Expose({ name: 'field_type' })
  fieldType!: string;

  @Type(() => LookerExploreModel)
  explore!: LookerExploreModel;

  @Expose({ name: 'view_label' })
  viewLabel!: string;

  @Type(() => LookMLViewModel)
  view?: LookMLViewModel;

  @Type(() => PopularityModel)
  popularity?: PopularityModel;

  @Expose({ name: 'tagged_items' })
  @Type(() => TaggedItemModel)
  taggedItems?: TaggedItemModel[];

  @Expose({ name: 'data_type' })
  dataType?: string;

  @Expose({ name: 'is_used_as_filter' })
  isUsedAsFilter?: boolean;

  @Expose({ name: 'is_used_as_pivot' })
  isUsedAsPivot?: boolean;

  @Expose({ name: 'is_metric' })
  isMetric?: boolean;

  @Expose({ name: 'is_mention' })
  isMention?: boolean;

  @Type(() => BreadcrumbModel)
  breadcrumbs?: BreadcrumbModel[];

  get breadcrumbList() {
    return breadcrumbsToList(this.breadcrumbs);
  }

  get breadcrumbLabelList() {
    const url = urlFor(this, false, '');
    return breadcrumbsToLabelList(this.name, url, this.breadcrumbs);
  }

  @Expose({ name: 'parent_guid' })
  @Transform((value, obj) => (obj.view ? obj.view?.guid : value))
  parentGuid?: string;

  @Expose({ name: 'deactivation_scheduled_on' })
  @Transform((value) => (value ? moment(value) : value))
  deactivationScheduledOn?: moment.Moment;

  @Expose({ name: 'ai_description' })
  aiDescription?: string;

  @Expose({ name: 'description_source' })
  descriptionSource?: DescriptionSource;

  @Expose({ name: 'ingested_description' })
  ingestedDescription?: string;

  @Expose({ name: 'user_description' })
  userDescription?: string;
}
