import React from 'react';

import useNewLayout from '@hooks/useNewLayout';

import AppMainSidebarV0, { AppMainSidebarProps } from './AppMainSidebar';
import AppMainSidebarV1 from './AppMainSidebar.v1';

const AppMainSidebar: React.FC<AppMainSidebarProps> = (props) => {
  const { shouldUseNewLayout } = useNewLayout();

  return shouldUseNewLayout ? <AppMainSidebarV1 /> : <AppMainSidebarV0 {...props} />;
};

export default AppMainSidebar;
