import { Expose, Transform, Type } from 'class-transformer';
import moment from 'moment';

import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import { ObjectType } from '@api/types';
import { breadcrumbsToLabelList } from '@components/Breadcrumbs';
import type { DataSourceTypesType } from '@models/DataSourceCredentials';
import DataTypesModel from '@models/DataTypesModel';
import { urlFor } from '@utils/routing';

export class TableQueryModel {
  static objectType: ObjectType = 'tablequery';

  objectType: ObjectType = TableQueryModel.objectType;

  static typeDisplay: string = 'Table';

  typeDisplay: string = TableQueryModel.typeDisplay;

  guid!: string;

  name!: string;

  @Expose({ name: 'search_name' })
  fullName!: string;

  @Expose({ name: 'last_queried_on' })
  @Transform((value) => (value ? moment(value) : value))
  lastQueriedOn?: moment.Moment;

  @Expose({ name: 'query_runs' })
  queryRuns!: number;

  @Expose({ name: 'data_source_name' })
  dataSourceName!: string;

  @Expose({ name: 'data_source_type' })
  dataSourceType!: DataSourceTypesType;

  @Expose({ name: 'data_types' })
  @Type(() => DataTypesModel)
  dataTypes?: DataTypesModel = new DataTypesModel();

  @Expose({ name: 'database_name' })
  databaseName!: string;

  @Expose({ name: 'schema_name' })
  schemaName!: string;

  get fullPath() {
    return `${this.databaseName}.${this.schemaName}.${this.name}`;
  }

  @Type(() => BreadcrumbModel)
  breadcrumbs?: BreadcrumbModel[];

  get breadcrumbLabelList() {
    const url = urlFor(this, false, '');
    return breadcrumbsToLabelList(this.name, url, this.breadcrumbs);
  }
}
