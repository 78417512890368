import React from 'react';

import Icon from '@components/UI/Icon';

import type { StyledAlertProps } from './Alert.styles';
import {
  StyledAlert,
  StyledAlertContentWrapper,
  StyledAlertIconWrapper,
  StyledAlertTitle,
} from './Alert.styles';

export interface AlertProps extends StyledAlertProps {
  bgColor?: string;
  className?: string;
  icon?: React.ReactNode | null;
  title?: string;
}

const iconMap = {
  error: <Icon name="error" size="18px" />,
  info: <Icon name="info" size="18px" />,
  success: <Icon name="success" size="18px" />,
  warning: <Icon name="warning" size="18px" />,
};

const Alert: React.FC<AlertProps> = ({
  bgColor,
  children,
  className,
  icon,
  title,
  type = 'info',
  ...other
}) => {
  const finalIcon = icon !== undefined ? icon : iconMap[type] ?? iconMap.info;

  return (
    <StyledAlert
      aria-labelledby="alert-title"
      bgColor={bgColor}
      className={className}
      role="alert"
      type={type}
      {...other}
    >
      {finalIcon && <StyledAlertIconWrapper title={type}>{finalIcon}</StyledAlertIconWrapper>}
      <StyledAlertContentWrapper id="alert-title">
        {title && <StyledAlertTitle>{title}</StyledAlertTitle>} {children}
      </StyledAlertContentWrapper>
    </StyledAlert>
  );
};

export default Alert;
