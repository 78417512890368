import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Box from '@components/Box';
import { getTreeNodeHoverColor } from '@components/Tree/Tree.v2/TreeNode.v2.styles';
import rootTheme from '@styles/theme';

export const StyledDocumentsListMenu = styled(Box)``;

StyledDocumentsListMenu.defaultProps = {
  borderBottom: '1px solid',
  borderColor: 'gray.300',
  compDisplay: 'flex',
  flexDirection: 'column',
  mb: 1,
  pb: 1,
  px: 1.5,
};

interface StyledDocumentsListMenuItemProps {
  isSelected?: boolean;
  level?: number;
}

export const StyledDocumentsListMenuItem = styled(Box)<StyledDocumentsListMenuItemProps>`
  color: ${({ theme }) => theme.colors.v1.gray[700]};
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
  font-size: ${({ theme }) => theme.typography.fontSizes.body2};
  border-radius: ${({ theme }) => theme.radius.md};
  transition: all 0.1s ease;
  padding-left: ${({ level = 0, theme }) => theme.space(1 + level * 1.25)};

  &:hover {
    ${getTreeNodeHoverColor()}
  }

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      color: ${theme.colors.v1.primary[500]};
      background-color: ${theme.colors.v1.primary[100]};
    `}
`;

StyledDocumentsListMenuItem.defaultProps = {
  alignItems: 'center',
  compDisplay: 'flex',
  compHeight: rootTheme.space(3.5),
  cursor: 'pointer',
  gap: 1,
  pr: 1.5,
};
