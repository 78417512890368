import { atom } from 'recoil';

import { DefaultExploreOptions, ExploreOptions } from './ExploreOptions';

export const pinnedTable = atom<string | null>({
  default: null,
  key: 'explorePinnedTable',
});
export const desiredZoomTable = atom<string | null>({
  default: null,
  key: 'exploreZoomTable',
});

export const exploreOptions = atom<ExploreOptions>({
  default: DefaultExploreOptions,
  key: 'exploreOptions',
});

export interface SearchOptions {
  exclude: boolean;
  keyword: string;
  orderBy: 'default' | 'asc' | 'desc';
  sortBy: 'name' | 'popularity' | 'description' | 'usage' | 'impactScore';
}

export const searchOptions = atom<SearchOptions>({
  default: {
    exclude: false,
    keyword: '',
    orderBy: 'desc',
    sortBy: 'popularity',
  },
  key: 'searchOptions',
});

export const searchOptionsSortedByUsage = atom<SearchOptions>({
  default: {
    exclude: false,
    keyword: '',
    orderBy: 'default',
    sortBy: 'usage',
  },
  key: 'searchOptionsSortedByUsage',
});
