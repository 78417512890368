import React, { useEffect } from 'react';
import { useHistory, useLocation } from '@routing/router';

import { usePostMask } from '@api/mask';
import Alert from '@components/Alert';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import Input from '@components/Input/Input.v1';
import LayoutMainGrid from '@components/Layout/LayoutMainGrid';
import { renderInfoToast } from '@components/Toast';
import InputLabel from '@components/UI/Form/InputLabel';
import MetadataDecorator from '@utils/MetadataDecorator';

import { mask } from './MaskPage.utils';

const MaskPage: React.FC = () => {
  const history = useHistory();
  const [email, setEmail] = React.useState('');
  const { search: searchLocation } = useLocation();
  const params = new URLSearchParams(searchLocation);
  const userEmailLocation = params.get('user') ?? undefined;
  const redirectUrl = params.get('redirectUrl') ?? undefined;

  const { error, isLoading, mutate } = usePostMask({
    onSuccess: (data) => {
      mask(data);
      renderInfoToast('Masked successfully.');
      // This forces page reload (rerender user/org specific stuff)
      requestAnimationFrame(() => document.location.reload());
    },
  });

  const onClick = () => {
    if (!isLoading) {
      mutate({ email });
      if (redirectUrl) {
        history.push(redirectUrl);
      }
    }
  };

  useEffect(() => {
    if (userEmailLocation) {
      setEmail(userEmailLocation);
    }
  }, [userEmailLocation]);

  return (
    <>
      <MetadataDecorator title="Mask" />
      <LayoutMainGrid>
        <Box alignItems="flex-end" as="form" compDisplay="flex" flexDirection="column" gap={2}>
          <InputLabel alignItems="baseline" compWidth="100%" fontWeight="700" gap={6}>
            Email
            <Input
              autoFocus
              compWidth="100%"
              error={Boolean(error?.data?.email)}
              helperText={error?.data?.email}
              onChange={(e) => setEmail(e.target.value as string)}
              placeholder="john@yourdomain.com"
              type="email"
              value={email}
            />
          </InputLabel>
          {error && error.status === 500 && (
            <Alert title="Could not Mask" type="error">
              Internal Server Error
            </Alert>
          )}
          <Button disabled={isLoading} onClick={onClick}>
            Mask
          </Button>
        </Box>
      </LayoutMainGrid>
    </>
  );
};

export default MaskPage;
