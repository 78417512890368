import React, { forwardRef } from 'react';

interface GetLayoutComponentProps {
  useNewLayout?: boolean;
}

const getLayoutComponent = <P extends object>(
  OldComponent: React.ComponentType<P>,
  NewComponent: React.ComponentType<P>,
) =>
  forwardRef((props: P & GetLayoutComponentProps, ref) => {
    const { useNewLayout, ...rest } = props;
    return useNewLayout ? (
      <NewComponent ref={ref} {...(rest as P)} />
    ) : (
      <OldComponent ref={ref} {...(rest as P)} />
    );
  });

export default getLayoutComponent;
