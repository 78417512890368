import 'semantic-ui-css/semantic.min.css';
import 'reactflow/dist/style.css';

import React from 'react';

import QueryModal from '@components/Modal/QueryModal';
import { useModal } from '@context/Modal';

const QueryModalContainer: React.FC = () => {
  const { MODAL_IDS, checkModalOpened, closeModal, getModalContent } = useModal();
  const props = getModalContent(MODAL_IDS.query);

  if (!checkModalOpened(MODAL_IDS.query)) return null;
  return (
    <QueryModal
      {...props}
      onClose={() => {
        closeModal(MODAL_IDS.query);
        props?.onClose?.();
      }}
    />
  );
};

export default QueryModalContainer;
