import React from 'react';

import PopularityCell from '@components/Table/Cells/PopularityCell';

import type { SidebarTreeItemProps } from './types';

const SidebarTreePopularityCell: React.FC<SidebarTreeItemProps> = ({ dataTypes, popularity }) => {
  if (dataTypes?.dataType === 'implicit' || !popularity) {
    return null;
  }

  return <PopularityCell popularity={popularity} />;
};

export default SidebarTreePopularityCell;
