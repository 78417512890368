import React from 'react';
import { RouterLink, useHistory, useParams } from '@routing/router';

import { TeamModel } from '@api/teams/TeamModel';
import { useTeamsSections } from '@components/AppMainSidebar/TeamsMenu';
import Box from '@components/Box';
import TreeV2 from '@components/Tree/Tree.v2';
import Avatar from '@components/UI/Avatar';

import HierarchyLoadingSkeleton from '../HierarchyLoadingSkeleton';

import {
  StyledTeamsHierarchyNoTeamsMessage,
  StyledTeamsHierarchySection,
  StyledTeamsHierarchyTeamLink,
} from './TeamsHierarchy.styles';

const NO_TEAM_ASSIGNED_ITEM_GUID = 'no_team_assigned';

const TeamsHierarchy = () => {
  const history = useHistory();
  const { guid } = useParams<{ guid: string }>();
  const { isLoading, sections } = useTeamsSections();

  if (isLoading) {
    return <HierarchyLoadingSkeleton />;
  }

  const hierarchyData = sections.map((section) => ({
    ...section,
    children:
      section.teams.length > 0
        ? section.teams
        : [{ guid: NO_TEAM_ASSIGNED_ITEM_GUID, title: 'No team assigned' }],
    guid: section.key,
    isSection: true,
  }));

  const sectionsKeys = sections.map((section) => section.key);
  const excludedHighlightKeys = [NO_TEAM_ASSIGNED_ITEM_GUID, ...sectionsKeys];

  return (
    <Box overflowX="hidden" overflowY="auto">
      <TreeV2
        allowDataLoad={() => false}
        disableHover={(node) => node.key === NO_TEAM_ASSIGNED_ITEM_GUID}
        enableHighlight={(node) => {
          return !excludedHighlightKeys.includes(node.key);
        }}
        expandedKeys={sectionsKeys}
        getKey={(item) => item.guid}
        highlightedKeys={[guid]}
        onNodeClick={({ original }) => {
          if (original instanceof TeamModel) {
            history.push(`/teams/${original.guid}`);
          }
        }}
        renderLabel={({ original }) => {
          if (original.isSection) {
            return <StyledTeamsHierarchySection>{original.title}</StyledTeamsHierarchySection>;
          }

          if (original instanceof TeamModel) {
            return (
              <StyledTeamsHierarchyTeamLink as={RouterLink} to={`/teams/${original.guid}`}>
                <Avatar {...original.mappedAvatar} />
                {original.name}
              </StyledTeamsHierarchyTeamLink>
            );
          }

          return (
            <StyledTeamsHierarchyNoTeamsMessage>
              {original.title}
            </StyledTeamsHierarchyNoTeamsMessage>
          );
        }}
        showIndentationLines
        treeData={hierarchyData}
        useNewLayout
      />
    </Box>
  );
};

export default TeamsHierarchy;
