import React from 'react';

import { Dot, LoadingDotsWrapper } from './DotsLoader.styles';

const DotsLoader: React.FC = () => {
  return (
    <LoadingDotsWrapper data-testid="dots-loader" role="progressbar">
      <Dot delay={0} />
      <Dot delay={0.5} />
      <Dot delay={1} />
    </LoadingDotsWrapper>
  );
};

export default DotsLoader;
