import React from 'react';

import { useFetchMentions } from '@api/mentions';
import Box from '@components/Box';
import Mention from '@components/Mention';
import Skeleton from '@components/Skeleton';
import Text from '@components/Text';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import { useModal } from '@context/Modal';
import { DeletedModel } from '@models/DeletedModel';
import theme from '@styles/theme';

const MENTIONS_TO_SHOW = 5;

export interface MentionedByProps {
  targetId?: string;
}

const MentionedBy: React.FC<MentionedByProps> = ({ targetId }) => {
  const { MODAL_IDS, openModal } = useModal();
  const { data, isLoading } = useFetchMentions({
    params: {
      query: '{guid,source}',
      targets: targetId,
    },
  });

  const activeMentions =
    data?.results.filter(({ source }) => source.obj.name !== DeletedModel.deletedName) ?? [];
  const displayMentions = activeMentions.slice(0, MENTIONS_TO_SHOW);

  return (
    <Box compWidth="100%">
      <Box compDisplay="inline-flex" compWidth="100%" justifyContent="space-between" mb={2}>
        <Box alignItems="center" compDisplay="flex">
          <Text
            as="h3"
            color="gray.700"
            fontSize={theme.typography.h4.fontSize}
            fontWeight="medium"
            mb={0}
          >
            Mentioned by
          </Text>
          <Tooltip content="This is mentioned in">
            <Box ml={1}>
              <Icon color={theme.colors.v1.gray[700]} name="info-outline" size="16px" />
            </Box>
          </Tooltip>
        </Box>
        {activeMentions.length > 0 && (
          <Text
            as="div"
            color="gray.400"
            cursor="pointer"
            fontSize={theme.typography.fontSizes.body1}
            onClick={() => openModal(MODAL_IDS.mentionedBy, { targetId })}
            textDecorationHover="underline"
          >
            Show more
          </Text>
        )}
      </Box>
      <Box compDisplay="flex" flexDirection="column" gap={0.3}>
        {isLoading && (
          <>
            <Skeleton compWidth="60%" />
            <Skeleton compWidth="30%" />
          </>
        )}
        {!isLoading && activeMentions.length === 0 && (
          <Text as="div" color="gray.400" fontSize={theme.typography.fontSizes.body1}>
            None detected yet.
          </Text>
        )}
        {displayMentions?.map((mention) => {
          const { dataTypes, guid, name, parentGuid } = mention.source.obj;

          return (
            <Mention
              key={mention.guid}
              dataTypes={dataTypes}
              guid={guid}
              name={name}
              parentGuid={parentGuid}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default MentionedBy;
