import React from 'react';

import invalidateCache from '@api/invalidateCache';
import { useFetchJobs } from '@api/jobs';
import { JobModel } from '@api/jobs/JobModel.v1';
import Box from '@components/Box';
import Breadcrumbs, { BreadcrumbItem } from '@components/Breadcrumbs';
import BulkButtons from '@components/BulkButtons/BulkButtons';
import LayoutMainGrid from '@components/Layout/LayoutMainGrid';
import PageHeaderBar from '@components/PageHeaderBar';
import JobTable, {
  JOB_TABLE_SEARCH_CONFIG,
  JOB_TABLE_SORT_CONFIG,
} from '@components/Table/JobTable';
import Icon from '@components/UI/Icon';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import { useUserContext } from '@context/User';
import useBulkEditSelected from '@hooks/useBulkEditSelected';
import DataTypesModel from '@models/DataTypesModel';
import { setParams, useUpdateFilters } from '@utils/filters';
import getItemsCount, { GetItemsCountParams } from '@utils/getItemsCount';
import MetadataDecorator from '@utils/MetadataDecorator';

const REQUEST_CONFIG = { order: `-${JOB_TABLE_SORT_CONFIG.lastRun}`, page: 1, page_size: 100 };

export interface JobsPageProps {
  breadcrumbList?: BreadcrumbItem[];
  countItemsConfig?: GetItemsCountParams['countItems'];
  dataTypes?: DataTypesModel;
  dsGuid?: string;
  guid?: string;
  title?: string;
}
const JobsPage: React.FC<JobsPageProps> = ({
  breadcrumbList,
  countItemsConfig,
  dataTypes,
  dsGuid,
  guid,
  title,
}) => {
  const { getDataSourceSettings } = useUserContext();
  const { permissions } = useObjectPermissionsContext();
  const { selected, tagsCounts, toggleAll, toggleItem } = useBulkEditSelected<JobModel>();
  const filterService = useUpdateFilters(
    REQUEST_CONFIG,
    JOB_TABLE_SEARCH_CONFIG,
    JOB_TABLE_SORT_CONFIG,
    REQUEST_CONFIG.order,
  );

  const { data, isLoading } = useFetchJobs({
    params: {
      ...(setParams(filterService.filter) as any),
      ...(guid && { folders: guid }),
    },
  });

  const dataSourceSettings = getDataSourceSettings(dsGuid);
  const itemCount = getItemsCount({ countItems: countItemsConfig, ref: data });

  const reload = () => {
    invalidateCache((keys) => [keys.jobs.all]);
  };

  const isDataSourceEditable = Boolean(dataSourceSettings?.isEditable);
  const selectedEditableItems = selected.items.filter(
    (item: any) => permissions[item.guid]?.isEditable,
  );

  return (
    <Box compDisplay="flex">
      <LayoutMainGrid>
        <MetadataDecorator title={title} />
        <PageHeaderBar
          icon={dataTypes && <Icon name={dataTypes.icons.dataType} />}
          sticky
          supIcon={dataTypes && <Icon name={dataTypes.icons.dataSource} />}
          supTitle={breadcrumbList && <Breadcrumbs items={breadcrumbList} />}
          title={title}
          titleSuffix={!isLoading && itemCount}
        />
        <BulkButtons
          canRemove={!filterService.filter.is_hidden}
          canUnHide={filterService.filter.is_hidden}
          isDataSourceEditable={isDataSourceEditable}
          onEdit={reload}
          selectedEditableItems={selectedEditableItems}
          selectedItems={selected.items}
          showText
          tagsCounts={tagsCounts}
        />
        <Box mb={0.5}>
          <JobTable
            data={data}
            filterService={filterService}
            isDataSourceEditable={isDataSourceEditable}
            isLoading={isLoading}
            selectedRowIds={selected.ids}
            toggleAll={(checked) => {
              toggleAll(data?.results!, checked);
            }}
            toggleItem={toggleItem}
          />
        </Box>
      </LayoutMainGrid>
    </Box>
  );
};

export default JobsPage;
