import React from 'react';

import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import theme from '@styles/theme';

import type { VariantProps } from './StatSummaryBar.styles';
import {
  StyledStatSummaryBar,
  StyledStatSummaryBarDescription,
  StyledStatSummaryBarNumber,
} from './StatSummaryBar.styles';

export interface StatSummaryBarProps extends VariantProps {
  data: {
    description: string;
    isValid?: boolean;
    subvalue?: string | number;
    tooltip: string;
    value?: number | string;
  }[];
  loading?: boolean;
  showSubvalue?: boolean;
}

const StatSummaryBar: React.FC<StatSummaryBarProps> = ({
  data,
  loading,
  showSubvalue,
  variant,
}) => (
  <StyledStatSummaryBar variant={variant}>
    {loading ? (
      <CircularLoader borderWidth={5} centered compSize={3} />
    ) : (
      data.map(({ description, isValid = true, subvalue, tooltip, value }) => (
        <Box
          key={description}
          alignItems="center"
          compDisplay="flex"
          compWidth="100%"
          flexDirection="column"
          justifyContent="center"
        >
          <StyledStatSummaryBarNumber variant={isValid ? variant : 'invalid'}>
            {value ?? 0}
          </StyledStatSummaryBarNumber>
          {showSubvalue && (
            <Box color={theme.colors.v1.gray[600]} minHeight="1em">
              {subvalue}
            </Box>
          )}
          <Tooltip content={tooltip}>
            <StyledStatSummaryBarDescription variant={variant}>
              {description}
              <Icon color="currentColor" name="info-outline" size="16px" />
            </StyledStatSummaryBarDescription>
          </Tooltip>
        </Box>
      ))
    )}
  </StyledStatSummaryBar>
);

export default StatSummaryBar;
