import styled from '@emotion/styled';

import Box from '@components/Box';

export const StyledCommentTextBox = styled(Box)`
  padding: 0;
  width: 100%;
  max-width: none;
  display: flex;
  position: relative;
`;

export interface StyledCommentEditorProps {
  editable?: boolean;
  isNewMessage?: boolean;
}

export const StyledCommentEditor = styled.div<StyledCommentEditorProps>`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: ${({ isNewMessage }) => (isNewMessage ? '0.35rem' : '0')};
  position: relative;
  min-height: ${({ editable, isNewMessage }) => (isNewMessage || editable ? '50px' : 'auto')};
  width: 100%;
  font-size: ${({ theme }) => theme.typography.fontSizes.body2};
  padding: ${({ editable, isNewMessage, theme }) =>
    isNewMessage || editable ? theme.space(1, 0) : 0};
  border-bottom: ${({ editable }) => (editable ? `1px solid` : 'none')};
  border-color: ${({ theme }) => theme.colors.gray[200]};
`;

export const CommentEditorControls = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.space(1)};
  align-self: end;
`;
