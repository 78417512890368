import styled from '@emotion/styled';

import Box, { StyledBoxProps } from '@components/Box';

export interface StyledAppHeaderProps extends StyledBoxProps {
  children?: React.ReactNode;
  hasBottomBorder?: boolean;
}

export const StyledAppHeader = styled(Box)<StyledAppHeaderProps>`
  border-bottom: ${({ hasBottomBorder, theme }) =>
    hasBottomBorder ? `1px solid ${theme.colors.gray[300]}` : 'none'};
`;

StyledAppHeader.defaultProps = {
  alignItems: 'center',
  backgroundColor: 'white',
  compDisplay: 'flex',
  compHeight: '60px',
  compWidth: '100%',
  justifyContent: 'center',
  ml: 0.25,
  position: 'relative',
  px: 3,
  py: 1,
};

export const StyledAppHeaderSearchBarContainer = styled(Box)``;

StyledAppHeaderSearchBarContainer.defaultProps = {
  alignItems: 'center',
  compDisplay: 'flex',
  compWidth: '100%',
  maxWidth: '1034px',
};
