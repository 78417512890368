import React, { useEffect, useMemo, useState } from 'react';
import { IMPACT_SCORE_DESCRIPTION } from '@constants';
import TreeMenu, { TreeNodeInArray } from 'react-simple-tree-menu';
import { RecoilState, useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import getDatasourceTypesOptions from 'src/context/User/getDataSourceTypesOptions';
import { useDebouncedCallback } from 'use-debounce';

import Box from '@components/Box';
import getTreeColumnsConfig from '@components/ExploreSidebar/getTreeColumnsConfig';
import { applyTreeFilters } from '@components/ExploreSidebar/SidebarTree/fiters';
import SidebarTreeNameCell from '@components/ExploreSidebar/SidebarTreeNameCell';
import SidebarTreeTable from '@components/ExploreSidebar/SidebarTreeTable';
import SidebarTreeTableHeader from '@components/ExploreSidebar/SidebarTreeTableHeader';
import SidebarTreeUsageCell from '@components/ExploreSidebar/SidebarTreeUsageCell';
import type { SearchOptions } from '@components/ExploreTree/atoms';
import { desiredZoomTable, exploreOptions, searchOptions } from '@components/ExploreTree/atoms';
import RelevantLineageToggle from '@components/LineageExplore/components/RelevantLineageToggle';
import { OrderBy } from '@components/OrderByButton/OrderByButton.styles';
import { POPULARITY_DESCRIPTION } from '@components/Table/Cells/PopularityCell/PopularityCellHeader';
import Select from '@components/UI/Select';
import { useModal } from '@context/Modal';
import { useUserContext } from '@context/User';
import ExportToCsvButton from '@pages/DocumentsPage/GlossaryTab/ExportToCsvButton';
import { MakeSpaceProps } from '@styles/mixins/makeSpace';
import wrapString from '@utils/wrapString';

import getTree from '../getTree';
import sidebarInit from '../sidebarInit';
import SidebarTreeDescriptionCell from '../SidebarTreeDescriptionCell';
import SidebarTreeImpactScoreCell from '../SidebarTreeImpactScoreCell';
import SidebarTreePopularityCell from '../SidebarTreePopularityCell';
import { ClickedGuidAtLevel, SidebarProps, SidebarTreeColumn } from '../types';

import { TreeContainer } from './SidebarTree.styles';
import SidebarTreeSearchInput from './SidebarTreeSearchInput';

const DEFAULT_EMPTY_TREE = { key: '0', label: 'No results found.', nodes: [] };
const EXPAND_ALL_COUNT_LIMIT = 700;

export type SidebarTreeProps = SidebarProps & {
  counts?: {
    downstream: number | undefined;
    upstream: number | undefined;
  };
  customSearchOptions?: RecoilState<SearchOptions>;
  direction: 'right' | 'left';
  isExpandingAll?: boolean;
  isLoading?: boolean;
  isLoadingExportCsv?: boolean;
  onDataLoad?: (guid: string) => Promise<any>;
  onExportCsvClick?: () => void;
  onUseRelevantLineageChange?: (checked: boolean) => void;
  shouldShowImpactScore?: boolean;
  showDataSourcesFilter?: boolean;
  showDataTypesFilter?: boolean;
  showDescriptions?: boolean;
  showExpandAll?: boolean;
  showRelevantLineageToggle?: boolean;
  showUsage?: boolean;
  startingGuid?: string;
  topBarSpacing?: MakeSpaceProps;
  useRelevantLineage?: boolean;
};

const SidebarTree: React.FC<SidebarTreeProps> = (props) => {
  const {
    counts,
    customSearchOptions,
    direction,
    isExpandingAll,
    isLoading,
    isLoadingExportCsv,
    loadLineage,
    nodeKey: startingKey,
    onExportCsvClick,
    onItemClick,
    onUseRelevantLineageChange,
    shouldShowImpactScore,
    showDataSourcesFilter = false,
    showDescriptions,
    showExpandAll = false,
    showRelevantLineageToggle = true,
    showUsage,
    startingGuid,
    tables,
    topBarSpacing,
    type,
    useRelevantLineage,
    zoomOnItemClick = true,
  } = props;

  const treeColumnsConfig = getTreeColumnsConfig({ showDescriptions, showUsage });
  const { dataSources } = useUserContext();
  const dataTypeOptions = getDatasourceTypesOptions(dataSources);
  const [selectedDataSourceOptions, setSelectedDataSourceOptions] = useState(dataTypeOptions);
  const treeSearchOptions = customSearchOptions ?? searchOptions;
  const { organization } = useUserContext();
  const [search, setSearch] = useRecoilState(treeSearchOptions);
  const resetSearch = useResetRecoilState(treeSearchOptions);
  const [expandAllState, setExpandAllState] = useState<'collapsed' | 'expanded'>('collapsed');
  const resetExploreOptions = useResetRecoilState(exploreOptions);
  const [clickedGuidAtLevel, setClickedGuid] = useState<ClickedGuidAtLevel>({ '0': 0 });
  const propIndex = ['left', 'right'].indexOf(direction);
  const [keyboardUser, setKeyboardUser] = useState(false);
  const { allNodes, traversalProps } = sidebarInit(props);
  const setZoomToTableId = useSetRecoilState(desiredZoomTable);
  const { MODAL_IDS, openModal } = useModal();
  const setSearchDebounced = useDebouncedCallback((val: SearchOptions) => {
    setSearch(val);
  }, 750);

  const allNodeIds = useMemo(() => {
    return new Set<string | undefined>([...allNodes.map((t) => t.guid)]);
  }, [allNodes]);

  const startingNode = useMemo(() => {
    if (startingKey?.includes('/co_')) {
      const [, columnGuid] = startingKey.split('/');
      return allNodes.find((node) => node.guid === columnGuid);
    }
    return allNodes.find((node) => node.guid === startingKey);
  }, [startingKey, allNodes]);

  const startingDataSourceType = startingNode?.dataSourceType;

  useEffect(() => {
    return () => {
      resetSearch();
      resetExploreOptions();
    };
  }, [resetExploreOptions, resetSearch]);

  const updateSearch = (sortBy: SearchOptions['sortBy'], orderBy: OrderBy) => {
    setSearch((prev) => ({
      ...prev,
      orderBy,
      sortBy,
    }));
  };

  const onSqlButtonClick = (query?: string) => {
    openModal(MODAL_IDS.query, { codeString: query });
  };

  const handleExpandAllClick = () => {
    setExpandAllState((prev) => (prev === 'collapsed' ? 'expanded' : 'collapsed'));
    loadLineage?.(startingGuid ?? '', direction, { maxDepth: 'max' });
  };

  const treeData = useMemo<{
    data: TreeNodeInArray[];
    expandAllKeys: string[] | undefined;
  }>(() => {
    if (!startingKey) {
      return {
        data: [DEFAULT_EMPTY_TREE],
        expandAllKeys: [],
      };
    }

    const expandAllKeys: string[] = [];
    const treeResult = getTree({
      config: {
        allNodeIds,
        allNodes,
        expandAllKeys,
        maxLevel: isExpandingAll ? undefined : Math.max(...Object.values(clickedGuidAtLevel)) + 1,
        propIndex,
        search,
        startingDataSourceType,
        startingKey,
        tables,
        traversalProps,
        type,
      },
      id: startingKey,
      onNodeReturn: (node) => {
        if (node.level > 0) {
          const options = {
            exclude: search.exclude,
            keyword: search.keyword,
            selectedDataSources: showDataSourcesFilter ? selectedDataSourceOptions : undefined,
          };

          return applyTreeFilters(node, options) ? node : undefined;
        }

        return node;
      },
    });

    return {
      data: [treeResult ?? DEFAULT_EMPTY_TREE],
      expandAllKeys,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allNodes,
    startingKey,
    propIndex,
    search.keyword,
    search.exclude,
    search.sortBy,
    search.orderBy,
    selectedDataSourceOptions,
    showDataSourcesFilter,
  ]);

  if (!startingKey) {
    return null;
  }

  const disableExpandAll = () => {
    if (direction === 'right') {
      return (counts?.downstream ?? 0) > EXPAND_ALL_COUNT_LIMIT;
    }
    return (counts?.upstream ?? 0) > EXPAND_ALL_COUNT_LIMIT;
  };

  const { useRelevantLineage: organizationUseRelevantLineage } = organization?.settings ?? {};

  const treeKey =
    expandAllState === 'expanded' && isExpandingAll
      ? treeData.expandAllKeys?.join()
      : `${useRelevantLineage ? 'relevant_lineage_' : ''}${startingKey}`;

  return (
    <Box>
      <Box
        alignItems="center"
        compDisplay="flex"
        flexWrap="wrap"
        gap={1}
        justifyContent="space-between"
        mb={0.75}
        mt={2.25}
        {...topBarSpacing}
      >
        <Box
          alignItems="center"
          compDisplay="flex"
          gap={1}
          justifyContent={organizationUseRelevantLineage ? 'flex-start' : 'space-between'}
        >
          <SidebarTreeSearchInput
            onExcludeValueChange={(e) =>
              setSearch({ ...search, exclude: Boolean(e.target.checked) })
            }
            onSearchValueChange={({ target }) => {
              setSearchDebounced({ ...search, keyword: target.value });
            }}
          />
          {showDataSourcesFilter && (
            <Box compWidth="140px">
              <Select
                isDropdown
                isMulti
                label="datasource_types"
                onChange={(options) => {
                  setSelectedDataSourceOptions(options as typeof dataTypeOptions);
                }}
                optionListMaxHeight="165px"
                options={dataTypeOptions}
                optionsFitAnchorWidth={false}
                pl={0.5}
                placeholder="Data Source"
                value={selectedDataSourceOptions}
              />
            </Box>
          )}
          {onExportCsvClick && (
            <ExportToCsvButton isLoading={isLoadingExportCsv} onClick={onExportCsvClick} />
          )}
        </Box>
        {showRelevantLineageToggle && organizationUseRelevantLineage && (
          <RelevantLineageToggle
            checked={useRelevantLineage}
            onChange={(e) => onUseRelevantLineageChange?.(e.target.checked)}
          />
        )}
      </Box>
      <TreeContainer
        className={keyboardUser ? 'keyUser' : ''}
        onClick={() => setKeyboardUser(false)}
        onKeyUp={() => setKeyboardUser(true)}
      >
        <TreeMenu
          key={treeKey}
          data={treeData.data}
          hasSearch={false}
          initialOpenNodes={
            expandAllState === 'expanded' && isExpandingAll ? treeData.expandAllKeys : [startingKey]
          }
        >
          {({ items }) => {
            const treeColumns: SidebarTreeColumn[] = [
              {
                Cell: SidebarTreeNameCell,
                Header: (
                  <SidebarTreeTableHeader
                    id="name"
                    label={`Name ${wrapString((items?.length ?? 1) - 1)}`}
                    onClick={updateSearch}
                    orderBy={search.orderBy}
                    sortBy={search.sortBy}
                  />
                ),
                canHide: false,
                id: 'name',
                name: 'Name',
                styles: {
                  compWidth: '38%',
                  flexGrow: 1,
                  maxWidth: '100%',
                  minWidth: '38%',
                },
                visibleByDefault: true,
              },
              ...(showDescriptions
                ? [
                    {
                      Cell: SidebarTreeDescriptionCell,
                      Header: (
                        <SidebarTreeTableHeader
                          id="description"
                          label="Description"
                          onClick={updateSearch}
                          orderBy={search.orderBy}
                          sortBy={search.sortBy}
                        />
                      ),
                      canHide: true,
                      id: 'description',
                      name: 'Description',
                      styles: { compWidth: '28%', maxWidth: '28%', minWidth: '28%' },
                      visibleByDefault: true,
                    },
                  ]
                : []),
              ...(showUsage
                ? [
                    {
                      Cell: SidebarTreeUsageCell,
                      Header: (
                        <SidebarTreeTableHeader
                          id="usage"
                          label="Usage"
                          onClick={updateSearch}
                          orderBy={search.orderBy}
                          sortBy={search.sortBy}
                        />
                      ),
                      canHide: true,
                      id: 'usage',
                      name: 'Usage',
                      styles: {
                        compWidth: '100px',
                        minWidth: '100px',
                      },
                      visibleByDefault: true,
                    },
                  ]
                : []),
              {
                Cell: SidebarTreePopularityCell,
                Header: (
                  <SidebarTreeTableHeader
                    description={POPULARITY_DESCRIPTION}
                    id="popularity"
                    label="Popularity"
                    onClick={updateSearch}
                    orderBy={search.orderBy}
                    sortBy={search.sortBy}
                  />
                ),
                canHide: true,
                id: 'popularity',
                name: 'Popularity',
                styles: {
                  compWidth: '120px',
                  minWidth: '120px',
                },
                visibleByDefault: true,
              },
              ...(shouldShowImpactScore
                ? [
                    {
                      Cell: SidebarTreeImpactScoreCell,
                      Header: (
                        <SidebarTreeTableHeader
                          description={IMPACT_SCORE_DESCRIPTION}
                          id="impactScore"
                          label="Impact Score"
                          onClick={updateSearch}
                          orderBy={search.orderBy}
                          sortBy={search.sortBy}
                        />
                      ),
                      canHide: true,
                      id: 'impactScore',
                      name: 'Impact Score',
                      styles: {
                        compWidth: '130px',
                        fontWeight: 'normal',
                        minWidth: '130px',
                      },
                      visibleByDefault: false,
                    },
                  ]
                : []),
            ];

            return (
              <SidebarTreeTable
                direction={direction}
                disableExpandAll={disableExpandAll()}
                enableHighlight={!search.exclude}
                expandAllState={expandAllState}
                isExpandingAll={isExpandingAll}
                isLoading={isLoading}
                items={items}
                loadLineage={loadLineage}
                onExpandAllClick={handleExpandAllClick}
                onItemClick={(item) => {
                  if (zoomOnItemClick) {
                    setZoomToTableId(item.tableId);
                  }

                  onItemClick?.(item);
                  setClickedGuid((prev) => ({ ...prev, [item.id]: item.level }));
                }}
                onSqlButtonClick={onSqlButtonClick}
                searchKeyword={search.keyword}
                shouldShowImpactScore={shouldShowImpactScore}
                showExpandAll={showExpandAll}
                treeColumns={treeColumns}
                treeColumnsConfig={treeColumnsConfig}
                type={type}
              />
            );
          }}
        </TreeMenu>
      </TreeContainer>
    </Box>
  );
};

export default React.memo(SidebarTree);
