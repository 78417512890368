export const all = ['slack'];

export const slackSettings = [...all, 'slackSettings'];

export const accountBinding = (id: string) => ['accountBinding', id];

export const slackTeam = [...all, 'slackTeam'];

export const connectedChannels = [...all, 'connectedChannels'];

export const slackWorkspace = [...all, 'slackWorkspace'];
