import styled from '@emotion/styled';

/**
 * @tod Architecture - Refactor legacy templates to remove this styling;
 */
const TabbedPageStyles = styled.div`
  .title-header {
    flex-wrap: wrap;
    align-items: stretch;
  }

  .collapseTabButton {
    display: block;
    right: 0;
    top: 0;
    position: absolute;
    height: 100%;
    padding: 8px 8px 5px;
    background-color: #f1f3f8;
    color: #6f747c;
    font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
  }

  .collapseTabButton:hover {
    color: #0c3476;
  }

  .tag-popularity-group {
    display: flex;
    flex-grow: 1;
    .tag-header {
      margin-left: 0;
      padding-left: 0;
    }
    .tag-header,
    .popularity {
      flex-shirink: 0;
      width: auto !important;
    }
  }

  .headerVerticalIcons {
    width: 34px;
    flex-shrink: 0;
    flex-grow: 0;
    user-select: none;
  }

  .headerVerticalIcons > span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .greenIcon,
  .greyIcon,
  .purpleIcon {
    margin-left: 0.4rem;
  }

  .greenIcon path {
    fill: #01b45f;
  }

  .purpleIcon path {
    fill: #6c4add;
  }

  .greyIcon path {
    fill: #666666;
  }
`;

export default TabbedPageStyles;
