import styled from '@emotion/styled';

import IconButton from '@components/IconButton';

export const StyledIconButton = styled(IconButton)`
  span {
    width: auto;
    padding-right: ${({ theme }) => theme.space(1)};
  }
`;

export const StyledButton = styled.button`
  padding-right: ${({ theme }) => theme.space(0.5)};
`;
