import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory, useRouteMatch } from '@routing/router';
import { useDebouncedCallback } from 'use-debounce';

import { CommentModel } from '@api/comments/CommentModel';

interface ContextValue {
  applyDraftComment: () => void;
  clearCurrentComment: () => void;
  currentComment?: CommentModel;
  setCurrentComment: (comment: CommentModel) => void;
  setDraftComment: (comment: CommentModel) => void;
}
const defaultContextValue: ContextValue = {
  applyDraftComment: () => {},
  clearCurrentComment: () => {},
  currentComment: undefined,
  setCurrentComment: () => {},
  setDraftComment: () => {},
};

export const NotificationContext = createContext(defaultContextValue);

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotificationContext missing its provider');
  }

  return context;
};

interface NotificationProviderProps {
  children: ReactNode;
}

const EMPTY_DRAFT_COMMENT = {
  message: '',
  richtextMessage: '[{"children":[{"text":""}],"type":"paragraph"}]',
} as CommentModel;

export const NotificationProvider = ({ children }: NotificationProviderProps) => {
  const [comment, setComment] = useState<CommentModel | undefined>(undefined);
  const [draftComment, setDraftComment] = useState<CommentModel>(EMPTY_DRAFT_COMMENT);
  const history = useHistory();
  const { url } = useRouteMatch();

  const applyDraftComment = useCallback(() => {
    setComment(draftComment);
  }, [draftComment]);

  const clearCurrentComment = useCallback(() => {
    setComment(undefined);
    setDraftComment(EMPTY_DRAFT_COMMENT);
  }, []);

  const internalSetDraftComment = useDebouncedCallback((val: CommentModel) => {
    setDraftComment(val);
  }, 500);

  const value = useMemo(
    () => ({
      applyDraftComment,
      clearCurrentComment,
      currentComment: comment,
      setCurrentComment: setComment,
      setDraftComment: internalSetDraftComment,
    }),
    [comment, applyDraftComment, clearCurrentComment, internalSetDraftComment],
  );

  useEffect(() => {
    if (comment) {
      history.push(`${url}/discussion/notify${history.location.search}`);
    }
  }, [comment, url, history]);

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};
