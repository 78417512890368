import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import useMutation, { UseMutationOptions } from '@hooks/useMutation';

export const useFetchSubscribersSlack = (options?: UseFetchOptions) => {
  return useFetch({
    ...options,
    url: `/subscribers/slack/`,
  });
};

export const useDeleteSubscribersSlack = (options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    method: 'DELETE',
    url: `/subscribers/slack/`,
  });
};

export const usePostSubscribersSlack = (options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    method: 'POST',
    url: `/subscribers/slack/`,
  });
};
