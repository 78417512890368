import React from 'react';

import BetaBadge from '@components/BetaBadge';
import Box, { StyledBoxProps } from '@components/Box';
import Toggle from '@components/Toggle';
import InputLabel from '@components/UI/Form/InputLabel';
import RadioButton from '@components/UI/Form/RadioButton';

import {
  AppSettingsInputOptionSubtitle,
  AppSettingsInputOptionTitle,
} from './AppSettingsInput.styles';

interface Option {
  subtitle?: string;
  title?: string;
  value: string;
}

interface AppSettingsInputProps {
  children?: React.ReactNode;
  onChange?: (newValue: string | boolean) => void;
  options?: Option[];
  showBetaBadge?: boolean;
  subtitle?: string;
  title?: string;
  titleAlignment?: StyledBoxProps['alignItems'];
  type?: 'toggle' | 'radio';
  value?: string | boolean;
}

const AppSettingsInput = ({
  children,
  onChange,
  options,
  showBetaBadge = false,
  subtitle,
  title,
  titleAlignment = 'start',
  type = 'radio',
  value,
}: AppSettingsInputProps) => {
  const handleChange = (optionValue: string) => {
    onChange?.(optionValue);
  };

  return (
    <Box alignItems={titleAlignment} compDisplay="grid" gridTemplateColumns="repeat(2, 1fr)">
      <Box compDisplay="flex" flexDirection="column">
        <AppSettingsInputOptionTitle>
          {title}
          {showBetaBadge && <BetaBadge />}
        </AppSettingsInputOptionTitle>
        {subtitle && <AppSettingsInputOptionSubtitle>{subtitle}</AppSettingsInputOptionSubtitle>}
      </Box>
      {children ?? (
        <>
          {type === 'radio' && (
            <Box
              aria-label={title}
              compDisplay="flex"
              flexDirection="column"
              gap={2}
              role="radiogroup"
            >
              {options?.map((option) => (
                <InputLabel key={option.value} alignItems="flex-start" compDisplay="flex" gap={1}>
                  <RadioButton
                    aria-labelledby={option.value}
                    checked={option.value === value}
                    mt={0.25}
                    onChange={() => handleChange(option.value)}
                  />
                  <Box compDisplay="flex" flexDirection="column">
                    <AppSettingsInputOptionTitle id={option.value}>
                      {option.title}
                    </AppSettingsInputOptionTitle>
                    {option.subtitle && (
                      <AppSettingsInputOptionSubtitle>
                        {option.subtitle}
                      </AppSettingsInputOptionSubtitle>
                    )}
                  </Box>
                </InputLabel>
              ))}
            </Box>
          )}
          {type === 'toggle' && (
            <Box>
              <Toggle checked={Boolean(value)} name={title} onChange={() => onChange?.(!value)} />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default AppSettingsInput;
