import type { OpenlineageAuthTokenRequest } from '@api/openapi.generated';
import { rawSelect } from '@api/utils';
import type { UseMutationOptions } from '@hooks/useMutation';
import useMutation from '@hooks/useMutation';
import type { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';

import type { OpenLineageAuthTokenModel } from './OpenLineageAuthTokenModel.v1';
import mapOpenLineageAuthTokenModel from './OpenLineageAuthTokenModel.v1';

export const postOpenLineageAuthSelect = rawSelect(mapOpenLineageAuthTokenModel);

export const usePostOpenLineageAuth = (
  options?: UseMutationOptions<
    OpenLineageAuthTokenModel,
    EnhancedErrorResult,
    OpenlineageAuthTokenRequest
  >,
) => {
  return useMutation<OpenLineageAuthTokenModel>({
    ...options,
    method: 'POST',
    onSuccess: (data, variables, context) => {
      const transformedData = postOpenLineageAuthSelect(data);
      options?.onSuccess?.(transformedData, variables, context);
    },
    url: '/openlineage/auth',
  });
};
