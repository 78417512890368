import type { DataTypes } from '@configs/dataTypes/types';

import config from './config';

interface UseFetchObjectMetadataParams {
  dataSourceType?: DataTypes['dataSourceType'];
  enabled?: boolean;
  guid: string;
  path?: string;
}

const useFetchObjectMetadata = ({
  enabled,
  guid,
  path = '/tables/:guid',
}: UseFetchObjectMetadataParams) => {
  const currentConfig = config[path as keyof typeof config];
  const { fetchHook, fetchHookExtraParams, query } = currentConfig;

  const fetchData = fetchHook(guid, {
    enabled: enabled ?? Boolean(guid),
    keepPreviousData: true,
    params: {
      query,
      ...fetchHookExtraParams,
    },
  });

  return { config: currentConfig, fetchData };
};

export default useFetchObjectMetadata;
