import escapeHtml from 'escape-html';
import showdown from 'showdown';

import cleanDOM from './cleanDOM';
import { parseStringToHTML } from './serializationHelpers';

const markdownConverter = new showdown.Converter({
  literalMidWordUnderscores: true,
  disableForced4SpacesIndentedSublists: true,
  tables: true,
  simplifiedAutoLink: true,
  simpleLineBreaks: true,
  ellipsis: false,
});

const convertMarkdownStringToHtml = (str: string) => {
  const htmlString = markdownConverter.makeHtml(escapeHtml(str)).replaceAll('&amp;nbsp;', '&nbsp;');
  const htmlDocument = parseStringToHTML(htmlString);

  cleanDOM(htmlDocument.body);

  return htmlDocument.body;
};

export default convertMarkdownStringToHtml;
