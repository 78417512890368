import paginatedTransform from '@api/paginatedTransform';
import rawTransform from '@api/rawTransform';
import type { UseFetchOptions } from '@hooks/useFetch';
import useFetch from '@hooks/useFetch';
import type { UseMutationOptions } from '@hooks/useMutation';
import useMutation from '@hooks/useMutation';
import type { OwnersUpdateData } from '@models/Owners';
import { PaginatedResponse } from '@models/PaginatedResponse';
import type { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';

import * as cacheKeys from './cacheKeys';
import { DatabaseModel } from './DatabaseModel';

export const fetchDatabaseSelect = rawTransform(DatabaseModel);
export const useFetchDatabase = (id: string, options?: UseFetchOptions<DatabaseModel>) => {
  return useFetch<DatabaseModel>({
    ...options,
    queryKey: [...cacheKeys.database(id), options?.params],
    select: fetchDatabaseSelect,
    url: `/databases/${id}/`,
  });
};

export const usePatchDatabasesOwners = (
  options?: UseMutationOptions<never, EnhancedErrorResult, OwnersUpdateData>,
) => {
  return useMutation({
    ...options,
    method: 'PATCH',
    url: `/databases/owners/`,
  });
};

const fetchDatabasesSelect = paginatedTransform(DatabaseModel);
export const useFetchDatabases = (options?: UseFetchOptions<PaginatedResponse<DatabaseModel>>) => {
  return useFetch<PaginatedResponse<DatabaseModel>>({
    ...options,
    queryKey: [...cacheKeys.all, options?.params],
    select: fetchDatabasesSelect,
    url: `/databases/`,
  });
};
