import React, { useCallback, useEffect, useState } from 'react';
import { TreeMenuItem } from 'react-simple-tree-menu';

import { useFetchLineage } from '@api/lineage';
import { GetLineageRetrieveQueryParams } from '@api/openapi.generated';
import Box from '@components/Box';
import ExploreSidebar from '@components/ExploreSidebar';
import useLineageInteractions from '@components/LineageExplore/components/LineageInteractionsWrapper/useLineageInteractions';
import { useLineageExplore } from '@components/LineageExplore/useLineageExplore';
import Skeleton from '@components/Skeleton';
import { useUserContext } from '@context/User';
import flags from '@features';
import useMergeLineageData from '@hooks/useMergeLineageData';
import theme from '@styles/theme';

const LineageTreeTab = () => {
  const { selectedNode, stackData, useRelevantLineage } = useLineageExplore();
  const { organization } = useUserContext();
  const { useImpactScore } = organization?.settings ?? {};
  const { focusOnNode } = useLineageInteractions();
  const [currentNodeId, setCurrentNodeId] = useState(selectedNode?.guid);
  const type =
    selectedNode?.key && stackData?.nodesById?.[selectedNode?.key]?.type === 'column'
      ? 'column'
      : 'table';

  const [requestParams, setRequestParams] = useState<GetLineageRetrieveQueryParams>({
    dbt_links: true,
    group_by_data_source: true,
    include_borderline_edges: true,
    include_impact_score: useImpactScore,
    looker_view_lineage: flags.looker_view_lineage,
    mode: type,
    mode_lineage: true,
  });

  const { data: selectedNodeData, isLoading: isSelectedNodeDataLoading } = useFetchLineage(
    selectedNode?.guid ?? '',
    {
      enabled: Boolean(selectedNode?.guid === currentNodeId),
      params: {
        ...requestParams,
        relevant_lineage: useRelevantLineage,
      },
    },
  );

  const { data: expandedNodeData } = useFetchLineage(currentNodeId ?? '', {
    enabled: Boolean(currentNodeId),
    params: {
      ...requestParams,
      mode: 'all',
      relevant_lineage: useRelevantLineage,
    },
  });

  const lineageDataMerged = useMergeLineageData({
    columns: [...(selectedNodeData?.columns ?? []), ...(expandedNodeData?.columns ?? [])],
    tables: [...(selectedNodeData?.tables ?? []), ...(expandedNodeData?.tables ?? [])],
  });

  useEffect(() => {
    lineageDataMerged.reset();
  }, [useRelevantLineage]);

  const handleLineageRepositioning = (item: TreeMenuItem) => {
    focusOnNode(item.id);
  };

  const loadLineage = useCallback(
    (id: string, direction?: GetLineageRetrieveQueryParams['direction']) => {
      setCurrentNodeId(id);
      setRequestParams((prevReq) => {
        const newRequest = { ...prevReq };
        delete newRequest.direction;

        if (direction) {
          newRequest.direction = direction;
        }

        return newRequest;
      });
    },
    [setRequestParams, setCurrentNodeId],
  );

  const upstreamCount = selectedNode?.metadata?.upstreamObjectsCounts?.total;
  const downstreamCount = selectedNode?.metadata?.downstreamObjectsCounts?.total;

  if (isSelectedNodeDataLoading) {
    return (
      <Box compDisplay="flex" compWidth="100%" flexDirection="column" gap={1} p={1}>
        <Skeleton compHeight={theme.space(3)} compWidth="100%" grayShade={200} variant="text" />
        <Skeleton compHeight={theme.space(4)} compWidth="100%" grayShade={200} variant="text" />
        <Skeleton compHeight={theme.space(3)} compWidth="60%" grayShade={200} variant="text" />
      </Box>
    );
  }

  return (
    <ExploreSidebar
      columns={lineageDataMerged?.columns ?? []}
      downstreamCount={downstreamCount}
      loadLineage={loadLineage}
      nodeKey={selectedNode?.key}
      onItemClick={handleLineageRepositioning}
      shouldShowImpactScore={useImpactScore}
      showInfo={false}
      showLineageTree={lineageDataMerged.tables.length > 0}
      showRelevantLineageToggle={false}
      tables={lineageDataMerged?.tables ?? []}
      type={type}
      upstreamCount={upstreamCount}
      zoomOnItemClick={false} // this is used by the old lineage, we may remove it on the future
    />
  );
};

export default LineageTreeTab;
