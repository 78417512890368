import isEqual from 'lodash/isEqual';
import { Editor, Transforms } from 'slate';

import { DEFAULT_SLATE_STATE } from './serializationHelpers';

/**
 * Resets editor state to empty or new value if passed.
 */
export const resetEditorValue = (editor: Editor, newValue = DEFAULT_SLATE_STATE) => {
  if (
    !editor ||
    editor.children.length < 1 ||
    newValue.length <= 0 ||
    isEqual(editor.children, newValue)
  )
    return;

  // Set selection to beginning of document. This avoids potential errors with selection being set on a node that is deleted.
  Transforms.select(editor, {
    anchor: { path: [0, 0], offset: 0 },
    focus: { path: [0, 0], offset: 0 },
  });

  // Delete the contents of the editor.
  Transforms.delete(editor, {
    at: {
      anchor: Editor.start(editor, []),
      focus: Editor.end(editor, []),
    },
    voids: true,
  });

  // Add the new content.
  newValue.forEach((value) => {
    Transforms.insertNodes(editor, value, {
      at: [editor.children.length],
    });
  });

  /**
   * Slate automatically adds a new empty paragraph node when we deleted the editor contents earlier.
   * It must be removed here or we will end up with an added empty paragraph at the start of the editor.
   */
  Transforms.delete(editor, { at: [0] });
};
