import { CustomAxiosMetadataConfig } from '@api/refreshToken';

const enrichApiResponseWithMetadata = (resultData?: any, metadata?: CustomAxiosMetadataConfig) => {
  const data = resultData;

  if (data && typeof data === 'object') {
    Object.defineProperty(resultData, 'requestMetadata', {
      value: metadata,
    });
  }

  return resultData;
};

export default enrichApiResponseWithMetadata;
