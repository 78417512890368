import { useFetchBiNotebook } from '@api/biNotebooks';

import NotebooksTabContent from './NotebooksTabContent';

export default {
  Content: NotebooksTabContent,
  CustomTitle: undefined,
  OverviewCustomContent: undefined,
  fetchHook: useFetchBiNotebook,
  query: undefined,
};
