import React, { useCallback, useMemo, useRef, useState } from 'react';
import { throttle } from 'lodash';

import type { StickyElementRef, StickyStyles } from './Sticky';
import { StickyContext } from './Sticky';

interface StickyProviderStyles {
  [key: string]: StickyStyles;
}

interface ElementsRef extends Record<string, StickyElementRef> {}

const getStyle = (elements: ElementsRef): StickyProviderStyles => {
  return Object.values(elements)
    .sort((a, b) => {
      const aParentOffset = a.current?.parentElement?.getBoundingClientRect().top ?? 0;
      const bParentOffset = b.current?.parentElement?.getBoundingClientRect().top ?? 0;

      return aParentOffset - bParentOffset;
    })
    .reduce((acc, el, index, arr): StickyProviderStyles => {
      const top = arr
        .slice(0, arr.indexOf(el))
        .reduce((accHeight, prevEl) => accHeight + (prevEl?.current?.offsetHeight ?? 0), 0);
      const id = el?.current?.id;
      return id
        ? {
            ...acc,
            [id]: {
              index,
              position: 'sticky',
              top: `${top - index / 2}px`,
            },
          }
        : acc;
    }, {});
};

const StickyProvider: React.FC = ({ children }) => {
  const elementsRef = useRef<ElementsRef>({});
  const [styles, setStyles] = useState<StickyProviderStyles>({});

  /*
   * Disabled the eslint rule since passing throttle as the callback function gives the following error:
   * React Hook useCallback received a function whose dependencies are unknown.
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateStyle = useCallback(
    throttle(() => {
      setStyles(getStyle(elementsRef.current));
    }, 500),
    [setStyles],
  );

  const addElement = useCallback(
    (ref: StickyElementRef) => {
      const id = ref.current?.id;
      if (!id || Boolean(elementsRef.current[id])) {
        return;
      }
      elementsRef.current = { ...elementsRef.current, [id]: ref };
      setStyles(getStyle(elementsRef.current));
    },
    [setStyles],
  );

  const removeElement = useCallback(
    (ref: StickyElementRef) => {
      const id = ref.current?.id;
      if (!id || !elementsRef.current[id]) {
        return;
      }
      const { [id]: savedEl, ...other } = elementsRef.current;
      elementsRef.current = other;
      setStyles(getStyle(elementsRef.current));
    },
    [setStyles],
  );

  const value = useMemo(
    () => ({
      addElement,
      elementsRef,
      removeElement,
      styles,
      updateStyle,
    }),
    [addElement, removeElement, styles, updateStyle],
  );

  return <StickyContext.Provider value={value}>{children}</StickyContext.Provider>;
};

export default StickyProvider;
