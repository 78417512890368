import styled from '@emotion/styled';

import Box from '@components/Box';
import rootTheme from '@styles/theme';

export const StyledControlBar = styled(Box)``;

StyledControlBar.defaultProps = {
  alignItems: 'center',
  compDisplay: 'flex',
  compHeight: rootTheme.space(6.25),
  gap: 2,
  position: 'absolute',
  right: rootTheme.space(2.25),
  top: rootTheme.space(0.625),
  zIndex: rootTheme.zIndex.floatingElement,
};
