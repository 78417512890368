import React from 'react';

import { useFetchTeamMostQueriedTables } from '@api/teams';
import { useFetchUserMostQueriedTables } from '@api/users';
import TabError from '@components/TabContent/TabError';
import MostUsedTable from '@components/Table/MostUsedTable';
import { DataSourceTypesType } from '@models/DataSourceCredentials';
import { ProfileType } from '@pages/ProfilePage/ProfilePage.types';

export interface MostQueriedSubTabProps {
  dataSourceType?: DataSourceTypesType;
  guid: string;
  profileType: ProfileType;
}

const defaultConfig = {
  params: {
    page_size: 20,
  },
};

const MostQueriedSubTabTables: React.FC<MostQueriedSubTabProps> = ({
  dataSourceType,
  guid,
  profileType,
}) => {
  const {
    data: teamResponse,
    isError: teamError,
    isLoading: teamLoading,
  } = useFetchTeamMostQueriedTables(guid, { ...defaultConfig, enabled: profileType === 'team' });

  const {
    data: userResponse,
    isError: userError,
    isLoading: userLoading,
  } = useFetchUserMostQueriedTables(guid, { ...defaultConfig, enabled: profileType === 'user' });

  const data = userResponse || teamResponse;
  const isLoading = userLoading || teamLoading;
  const isError = userError || teamError;

  if (isError) return <TabError />;

  return (
    <MostUsedTable
      data={data?.results}
      dataSourceType={dataSourceType}
      itemCount={data?.results?.length}
      loading={isLoading}
    />
  );
};

export default MostQueriedSubTabTables;
