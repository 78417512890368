import React, { useEffect, useMemo, useState } from 'react';
import groupBy from 'lodash/groupBy';
import xor from 'lodash/xor';

import { useFetchSourceColumns } from '@api/lineage';
import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import FiltersList from '@components/FiltersList';
import type { FiltersListFilterType } from '@components/FiltersList/FiltersList';
import SourceColumnsTabSchemaRow from '@components/TabContent/SourceColumnsTab/SourceColumnsTabSchemaRow';
import SourceColumnsTabTableRow from '@components/TabContent/SourceColumnsTab/SourceColumnsTabTableRow';
import { StyledSourceTablesTabItemWrapper } from '@components/TabContent/SourceTablesTab/SourceTablesTab.styles';
import TabError from '@components/TabContent/TabError';
import Text from '@components/Text';
import { WarehouseType } from '@models/DataSourceCredentials';

import SourceColumnsTabColumnsRow from './SourceColumnsTabColumnsRow';
import SourceColumnsTabRow from './SourceColumnsTabRow';

interface SourceColumnsTabFilter extends FiltersListFilterType {
  dataSourceType: WarehouseType | 'all';
  type: 'schema' | 'table' | 'column' | 'all';
}

export const NO_SOURCE_COLUMNS_MESSAGE = 'No source columns detected.';

const defaultFilter: SourceColumnsTabFilter = {
  dataSourceType: 'all',
  label: 'All',
  name: 'all',
  type: 'all',
};

interface SourceColumnsSubTabProps {
  guid: string;
}

const SourceColumnsTab: React.FC<SourceColumnsSubTabProps> = ({ guid }) => {
  const [selectedFilter, setSelectedFilter] = useState<SourceColumnsTabFilter>(defaultFilter);
  const [openedItemsIds, setOpenedItemsIds] = useState<string[] | null>(null);
  const { data, isError, isLoading } = useFetchSourceColumns(guid, { enabled: Boolean(guid) });

  const sourceColumnsFilters = useMemo(() => {
    if (!data?.upstream || data?.upstream?.length === 0) {
      return [];
    }

    const groupedSchemas = groupBy(data?.upstream, (el) => el?.schema?.dataSourceType);
    const dataSourceTypeKeys = Object.keys(groupedSchemas) as WarehouseType[];

    const sourceColumnFilters = dataSourceTypeKeys?.reduce((acc, dataSourceType) => {
      const schemaTablesItem = groupedSchemas?.[dataSourceType];

      if (!schemaTablesItem || schemaTablesItem?.length === 0) {
        return acc;
      }

      const newFilters: SourceColumnsTabFilter[] = [
        ...acc,
        {
          count: groupedSchemas?.[dataSourceType]?.length,
          dataSourceType,
          dataTypes: schemaTablesItem[0].schema.dataTypes,
          label: 'Schemas',
          name: `${dataSourceType}-schema`,
          type: 'schema',
        },
      ];

      const tablesCount = schemaTablesItem?.reduce(
        (sum, item) => (item?.tables?.length > 0 ? sum + item?.tables?.length ?? 0 : sum),
        0,
      );
      const columnsCount = schemaTablesItem?.reduce(
        (sum, item) =>
          item?.tables?.length
            ? sum +
              item?.tables?.reduce(
                (columnSum, { columns }) => (columns?.length > 0 ? columnSum + columns?.length : 0),
                0,
              )
            : sum,
        0,
      );

      if (tablesCount > 0) {
        newFilters.push({
          count: tablesCount,
          dataSourceType,
          dataTypes: schemaTablesItem[0].tables[0].table.dataTypes,
          label: 'Tables',
          name: `${dataSourceType}-table`,
          type: 'table',
        });
      }

      if (columnsCount > 0) {
        newFilters.push({
          count: columnsCount,
          dataSourceType,
          dataTypes: schemaTablesItem[0].tables[0].columns[0].dataTypes,
          label: 'Columns',
          name: `${dataSourceType}-column`,
          type: 'column',
        });
      }

      return newFilters;
    }, [] as SourceColumnsTabFilter[]);

    return [defaultFilter, ...sourceColumnFilters];
  }, [data?.upstream]);

  const toggleOpenedItemId = (id?: string) => {
    if (id) {
      setOpenedItemsIds((prev) => xor(prev, [id]));
    }
  };

  const allKeys = useMemo(() => {
    const schemaKeys: string[] = [];
    const tableKeys: string[] = [];

    data?.upstream?.forEach((item) => {
      schemaKeys.push(item.schema.guid);

      item.tables.forEach(({ table }) => {
        tableKeys.push(table.guid);
      });
    });

    return [...tableKeys, ...schemaKeys];
  }, [data?.upstream]);

  const handleFilterChange = (value: SourceColumnsTabFilter) => {
    if (value.type === 'all') {
      setOpenedItemsIds(allKeys);
    } else {
      setOpenedItemsIds(null);
    }
    setSelectedFilter(value);
  };

  /** Shows all by default. */
  useEffect(() => {
    setOpenedItemsIds(allKeys);
  }, [allKeys, data?.upstream, setOpenedItemsIds]);

  if (isLoading) {
    return <CircularLoader centered compSize={5} data-testid="data-loading" />;
  }

  if (isError) return <TabError />;

  if (data?.upstream?.length === 0 || (!isLoading && !data) || (!isLoading && !data?.upstream)) {
    return <Text color="inherit">{NO_SOURCE_COLUMNS_MESSAGE}</Text>;
  }

  return (
    <Box>
      <FiltersList
        data={sourceColumnsFilters}
        onClick={handleFilterChange}
        selectedFilter={selectedFilter}
      />
      <Box borderBottom="1px solid" borderColor="#d8dbe0" mb={0.5} pb={0.5} pt={1}>
        <SourceColumnsTabRow
          firstColumn={
            <Text color="inherit" fontSize="13px" fontWeight="bold" pl={5.5}>
              Name
            </Text>
          }
          secondColumn={
            <Text color="inherit" fontSize="13px" fontWeight="bold">
              Popularity
            </Text>
          }
        />
      </Box>
      {data?.upstream
        ?.filter((item) => {
          return (
            selectedFilter.type === 'all' ||
            item?.schema?.dataSourceType === selectedFilter.dataSourceType
          );
        })
        .map((item) => {
          const schema = item?.schema;
          const id = item?.schema?.guid;
          const isTablesSelected = selectedFilter.type === 'table';
          const isColumnSelected = selectedFilter.type === 'column';
          const isSchemaOpen =
            openedItemsIds?.includes(schema.guid) || isTablesSelected || isColumnSelected;

          return (
            <Box key={id}>
              <SourceColumnsTabSchemaRow
                breadcrumbs={schema?.breadcrumbLabelList}
                dataTypes={schema?.dataTypes}
                isOpen={isSchemaOpen}
                isToggleDisabled={isTablesSelected || isColumnSelected}
                onToggle={() => toggleOpenedItemId(id)}
                popularity={schema?.popularity}
              />
              {isSchemaOpen && (
                <StyledSourceTablesTabItemWrapper>
                  {item?.tables?.map(({ columns, table }) => {
                    const isTableOpen = openedItemsIds?.includes(table.guid) || isColumnSelected;

                    return (
                      <>
                        <SourceColumnsTabTableRow
                          key={table?.guid}
                          dataTypes={table?.dataTypes}
                          isOpen={isTableOpen}
                          isToggleDisabled={isColumnSelected}
                          name={table?.name}
                          onToggle={() => toggleOpenedItemId(table?.guid)}
                          popularity={table?.popularity}
                          routePath={table?.routePath}
                        />
                        {isTableOpen &&
                          columns?.map((column) => (
                            <SourceColumnsTabColumnsRow
                              key={column.guid}
                              dataTypes={column.dataTypes}
                              name={column.name}
                              popularity={column.popularity}
                              routePath={column.routePath}
                            />
                          ))}
                      </>
                    );
                  })}
                </StyledSourceTablesTabItemWrapper>
              )}
            </Box>
          );
        })}
    </Box>
  );
};

export default SourceColumnsTab;
