import { Editor, Element, Node, Path, Range } from 'slate';

/**
 * Plugin that allows inserting a new line while a void element(such as images) is selected.
 */
export const withCorrectVoidBehavior = (editor: Editor) => {
  const { insertBreak } = editor;

  // if current selection is void node, insert a default node below
  editor.insertBreak = () => {
    if (!editor.selection || !Range.isCollapsed(editor.selection)) {
      return insertBreak();
    }

    const selectedNodePath = Path.parent(editor.selection.anchor.path);
    const selectedNode = Node.get(editor, selectedNodePath);
    if (Element.isElement(selectedNode) && Editor.isVoid(editor, selectedNode)) {
      Editor.insertNode(editor, {
        children: [{ text: '' }],
        type: 'paragraph',
      });
      return;
    }

    insertBreak();
  };

  return editor;
};
