import { Filter } from '@utils';
// import stripSpaces from '@utils/stripSpaces';

export const defaultFilterOptions: Filter.FilterOptions = {
  order: 'name',
  page_size: 100,
  /*
   * query: stripSpaces(`{
   *   guid,
   *   name,
   *   description,
   *   richtext_description,
   *   tagged_items,
   *   data_type,
   *   data_types,
   *   technical_owner {
   *     object_type,
   *     obj
   *   },
   *   business_owner {
   *     object_type,
   *     obj
   *   },
   *   created_on,
   *   updated_on,
   *   breadcrumbs,
   *   represented_as,
   *   richtext_represented_as
   * }`),
   */
  sortColumn: 'name',
  sortDirection: 'ascending',
};

export const searchConfig: { [key: string]: keyof Filter.FilterOptions } = {
  name: 'search_name',
};

export const sortConfig: { [key: string]: string } = {
  assetsCount: 'assets_count',
  businessOwner: 'business_owner',
  createdAt: 'created_on',
  description: 'description',
  isFavorite: 'is_favorite',
  name: 'name',
  technicalOwner: 'technical_owner',
  updatedAt: 'last_updated',
};
