import styled from '@emotion/styled';

import Box from '@components/Box';
import type { StyledBoxProps } from '@components/Box/Box.styles';
import { Breakpoint } from '@styles/theme/breakpoints';

export interface StyledHiddenProps {
  hideDown?: Breakpoint[];
  hideUp?: Breakpoint[];
}

const StyledHidden = styled(Box)<StyledHiddenProps & StyledBoxProps>`
  ${({ hideUp, theme }) => {
    return hideUp?.map((breakpoint) => {
      return theme.media.up(breakpoint)`
        display: none;
      `;
    });
  }};

  ${({ hideDown, theme }) => {
    return hideDown?.map((breakpoint) => {
      return theme.media.down(breakpoint)`
        display: none;
      `;
    });
  }};
`;

export default StyledHidden;
