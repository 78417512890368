import React from 'react';
import { RenderLeafProps } from 'slate-react';

import { CodeElement } from './CodeElement.styles';
import { HighlightedText } from './HighlightedText.styles';

/**
 * The render leaf function is how we define rendering custom inline styles. These styles will be used to style the leaf(text) nodes
 * in the editor.
 * See https://docs.slatejs.org/walkthroughs/04-applying-custom-formatting for more info.
 */
const renderLeaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <CodeElement {...attributes}>{children}</CodeElement>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.highlight) {
    children = <HighlightedText>{children}</HighlightedText>;
  }

  return <span {...attributes}>{children}</span>;
};

export default renderLeaf;
