import { DashboardUserQuerySummaryModel } from '@api/dashboards/DashboardUserQuerySummaryModel';
import { PatchTeamsPartialUpdateBodyParams } from '@api/openapi.generated';
import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import useMutation, { UseMutationOptions } from '@hooks/useMutation';
import { PaginatedResponse } from '@models/PaginatedResponse';
import { TableQueryModel } from '@models/TableQueryModel';
import { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';

import invalidateCache from '../invalidateCache';
import paginatedTransform from '../paginatedTransform';
import rawTransform from '../rawTransform';

import * as cacheKeys from './cacheKeys';
import { TeamModel } from './TeamModel';

export const fetchTeamsSelect = paginatedTransform(TeamModel);
export const useFetchTeams = (options?: UseFetchOptions<PaginatedResponse<TeamModel>>) => {
  return useFetch<PaginatedResponse<TeamModel>>({
    ...options,
    queryKey: [...cacheKeys.all, options?.params],
    select: fetchTeamsSelect,
    url: '/teams/',
  });
};

const selectTeam = rawTransform(TeamModel);
export const useFetchTeam = (id: string, options?: UseFetchOptions<TeamModel>) => {
  return useFetch<TeamModel>({
    ...options,
    queryKey: cacheKeys.team(id),
    select: selectTeam,
    url: `/teams/${id}/`,
  });
};

export const usePatchTeam = (
  id: string,
  options?: UseMutationOptions<TeamModel, EnhancedErrorResult, PatchTeamsPartialUpdateBodyParams>,
) => {
  return useMutation<TeamModel, EnhancedErrorResult, PatchTeamsPartialUpdateBodyParams>({
    ...options,
    method: 'PATCH',
    url: `/teams/${id}/`,
  });
};

export const useDeleteTeam = (id: string, options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    method: 'DELETE',
    onSuccess: (data, variables, context) => {
      invalidateCache((keys) => [keys.teams.all]);
      options?.onSuccess?.(data, variables, context);
    },
    url: `/teams/${id}/`,
  });
};

const selectTeamMostQueriedDashboards = paginatedTransform(DashboardUserQuerySummaryModel);
export const useFetchTeamMostQueriedDashboards = (
  id: string,
  options?: UseFetchOptions<PaginatedResponse<DashboardUserQuerySummaryModel>>,
) => {
  return useFetch<PaginatedResponse<DashboardUserQuerySummaryModel>>({
    ...options,
    queryKey: [...cacheKeys.teamMostQueriedDashboards(id), options?.params],
    select: selectTeamMostQueriedDashboards,
    url: `/teams/${id}/most-queried-dashboards/`,
  });
};

const selectTeamMostQueriedTables = paginatedTransform(TableQueryModel);
export const useFetchTeamMostQueriedTables = (
  id: string,
  options?: UseFetchOptions<PaginatedResponse<TableQueryModel>>,
) => {
  return useFetch<PaginatedResponse<TableQueryModel>>({
    ...options,
    queryKey: [...cacheKeys.teamMostQueriedTables(id), options?.params],
    select: selectTeamMostQueriedTables,
    url: `/teams/${id}/most-queried-tables/`,
  });
};

export const usePostTeams = (options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    method: 'POST',
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
    },
    url: '/teams/',
  });
};

export const usePatchTransferTeamUsers = (id: string, options?: UseMutationOptions<TeamModel>) => {
  return useMutation<TeamModel>({
    ...options,
    method: 'PATCH',
    url: `/teams/${id}/transfer-users/`,
  });
};
