import React from 'react';

import Box from '@components/Box';
import type { OwnerType } from '@components/Owners/OwnerItem';

interface OwnerLabelProps {
  owner: OwnerType;
}
const OwnerLabel = ({ owner }: OwnerLabelProps) => {
  return (
    <Box
      color="inherit"
      fontWeight="inherit"
      lineHeight="26px"
      overflow="hidden"
      textOverflow="ellipsis"
      whiteSpace="nowrap"
    >
      {owner.fullName}
      {'team' in owner && (
        <Box as="span" color="gray.400" fontWeight="semibold" lineHeight="inherit" ml={1.15}>
          {owner?.team?.name}
        </Box>
      )}
    </Box>
  );
};

export default OwnerLabel;
