import { plainToClass } from 'class-transformer';

import { Reference } from '@models/Reference';

import type { ActivityType } from './types';

export const activityTypeToUserAction = (activityType?: ActivityType) => {
  switch (activityType) {
    case 'create': {
      return 'created';
    }
    case 'update': {
      return 'updated';
    }
    case 'view': {
      return 'viewed';
    }
    case 'comment': {
      return 'commented on';
    }
    case 'notify': {
      return 'sent notifications from';
    }
    case 'delete': {
      return 'deleted';
    }
    case 'pinned': {
      return 'pinned a comment on';
    }
    case 'unpinned': {
      return 'unpinned a comment on';
    }
    case 'tagged': {
      return 'tagged';
    }
    default: {
      return 'acted on';
    }
  }
};

export const parseMessageValues = (values: Record<string, any>) => {
  const parsedValues: Record<string, Reference<any>> = {};
  Object.entries(values).forEach(([key, value]) => {
    if (value?.object_type) {
      parsedValues[key] = plainToClass(Reference, {
        obj: value.data,
        object_type: value.object_type,
      });
    }
  });
  return parsedValues;
};
