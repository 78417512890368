import React from 'react';

import invalidateCache from '@api/invalidateCache';
import { usePatchTeam } from '@api/teams';
import { TeamModel } from '@api/teams/TeamModel';
import Box from '@components/Box';
import RichTextDescriptionEditor from '@components/RichTextDescriptionEditor';
import { renderErrorToast, renderInfoToast } from '@components/Toast';

export interface FormValues {
  value: string;
}

export interface TeamEditDescriptionInlineProps {
  initialData: TeamModel;
}

const TeamEditDescriptionInline: React.FC<TeamEditDescriptionInlineProps> = ({ initialData }) => {
  const patchQuery = usePatchTeam(initialData.guid, {
    onError: () => {
      renderErrorToast(`There was an error updating this team's description.`);
    },
    onSuccess: () => {
      invalidateCache((keys) => [keys.teams.team(initialData.guid)]);
      renderInfoToast('Description updated.');
    },
  });

  const onSubmit = (richText?: string, plainText: string = '') => {
    patchQuery.mutate({
      description: plainText,
      richtext_description: richText,
    });
  };

  return (
    <Box mt={0.5}>
      <RichTextDescriptionEditor
        descriptions={{
          description: initialData.description,
          richTextDescription: initialData.richTextDescription,
        }}
        editIconVariant="always"
        guid={initialData.guid}
        isEditable
        isSuccess={patchQuery.isSuccess}
        maxLength={250}
        onDescriptionSave={onSubmit}
        placeholder="Team description"
      />
    </Box>
  );
};

export default TeamEditDescriptionInline;
