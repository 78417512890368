import copy from 'copy-to-clipboard';

import { renderInfoToast } from '@components/Toast';

interface NavigateToPathArgs {
  finalPath: string;
  guid: string;
  navigate: (path: string, options?: Record<string, unknown>) => void;
  options?: Record<string, unknown>;
  path: string;
}

export const navigateToPath = ({
  finalPath,
  guid,
  navigate,
  options,
  path,
}: NavigateToPathArgs) => {
  const pathBase = path.split(guid)[0];
  if (!pathBase || !guid) return;

  navigate(`${pathBase}${guid}${finalPath}`, options);
};

export const handleShareClick = () => {
  copy(window.location.href);
  renderInfoToast('Url copied to clipboard');
};
