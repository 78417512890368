import { MetricModel } from '@api/metrics/MetricModel';
import paginatedTransform from '@api/paginatedTransform';
import type { UseFetchOptions } from '@hooks/useFetch';
import useFetch from '@hooks/useFetch';
import useMutation, { UseMutationOptions } from '@hooks/useMutation';
import { PaginatedResponse } from '@models/PaginatedResponse';

import { BiConnectionModel } from './BiConnectionModel';

export const biConnectionsCacheKeys = {
  biConnections: ['biConnections'],
  dsBiConnections: (id: string) => ['biConnections', id],
};

export const useFetchBiconnections = (
  id: string,
  options?: UseFetchOptions<PaginatedResponse<BiConnectionModel>>,
) => {
  return useFetch<PaginatedResponse<BiConnectionModel>>({
    ...options,
    queryKey: [...biConnectionsCacheKeys.dsBiConnections(id), options?.params],
    select: paginatedTransform(BiConnectionModel),
    url: `/bi/bi-connections/`,
  });
};

export const usePatchBIConnections = (options?: UseMutationOptions<MetricModel>) => {
  return useMutation<MetricModel>({
    ...options,
    method: 'PATCH',
  });
};
