import { Expose, Transform, Type } from 'class-transformer';
import moment from 'moment';

import { TeamModel } from '@api/teams/TeamModel';
import { UserModel } from '@api/user/UserModel';

import { OrganizationUserRole } from './OrganizationUserRole';

export class OrganizationUserModel {
  static objectType: string = 'organizationuser';

  objectType: string = OrganizationUserModel.objectType;

  static typeDisplay: string = 'OrganizationUser';

  typeDisplay: string = OrganizationUserModel.typeDisplay;

  @Expose({ name: 'sso_used_on' })
  @Transform((value) => value && moment(value))
  lastSSOUse?: moment.Moment;

  @Expose()
  @Type(() => UserModel)
  user!: UserModel;

  @Expose()
  @Type(() => TeamModel)
  team?: TeamModel;

  @Expose()
  role?: OrganizationUserRole;
}
