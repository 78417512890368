import { Expose } from 'class-transformer';

export class SlackTeamModel {
  objectType: string = 'slackTeam';

  typeDisplay: string = 'SlackTeam';

  @Expose({ name: 'slack_team_id' })
  slackTeamId!: string;
}
