import { Node } from '@components/ExploreSidebar/getTree';
import { GetDataSourceTypesOption } from '@context/User/getDataSourceTypesOptions/getDataSourceTypesOptions';

interface SearchByKeywordOptions {
  exclude: boolean;
  keyword: string;
}

export const searchByKeyword = (
  node: Node,
  { exclude, keyword }: SearchByKeywordOptions,
): boolean => {
  if (!keyword) {
    return true;
  }

  const searchString = keyword?.toLocaleLowerCase();
  const stringToMatch = `${node.label ?? ''}${node.description ?? ''}`?.toLocaleLowerCase();
  const match = stringToMatch?.includes(searchString);

  if (exclude) {
    return !match;
  }

  return match;
};

interface FilterByDataSourceOptions {
  selectedDataSources?: GetDataSourceTypesOption[];
}

export const filterByDataSource = (
  node: Node,
  { selectedDataSources }: FilterByDataSourceOptions,
): boolean => {
  if (node.isLoadingNode) {
    return true;
  }

  if (selectedDataSources) {
    return selectedDataSources
      ?.map((option) => option.value)
      ?.includes(node?.dataTypes?.dataSourceType);
  }

  return true;
};

type ApplyTreeFiltersOptions = SearchByKeywordOptions & FilterByDataSourceOptions;

const checkChildren = (cb: (node: Node, options: ApplyTreeFiltersOptions) => boolean) => {
  return (node: Node, options: ApplyTreeFiltersOptions): boolean => {
    if (!node || node.isLoadingNode) {
      return false;
    }

    if (cb(node, options)) {
      return true;
    }

    return node?.nodes?.some((child) => checkChildren(cb)(child, options)) ?? false;
  };
};

const filtersWithDescendantsCheck = [
  checkChildren(searchByKeyword),
  checkChildren(filterByDataSource),
];

export const applyTreeFilters = (node: Node, options: ApplyTreeFiltersOptions) => {
  return filtersWithDescendantsCheck.every((filter) => filter(node, options));
};
