import React, { useMemo } from 'react';

import { useFetchDataQualityUpstreamIssues } from '@api/dataQuality';
import { useFetchDbtTests, useFetchDbtWarehouseLink } from '@api/dbt';
import { useFetchMonitors } from '@api/monitors';
import {
  GetDataQualityUpstreamIssuesListPathParams,
  GetMonitorsListQueryParams,
} from '@api/openapi.generated';
import { ObjectType } from '@api/types';
import Box from '@components/Box';
import StatusBadge from '@components/StatusBadge';
import { useUserContext } from '@context/User';
import { Filter } from '@utils';

import DataQualityTabContent from './DataQualityTabContent';
import { FILTER_CONFIG as DBT_TESTS_FILTER_CONFIG } from './DataQualityTabContent/MonitorsDbtTestsTable';
import { FILTER_CONFIG as MONITORS_FILTER_CONFIG } from './DataQualityTabContent/MonitorsTable';
import { FILTER_CONFIG as UPSTREAM_ISSUES_FILTER_CONFIG } from './DataQualityTabContent/UpstreamIssuesTable/UpstreamIssuesTable';

interface UseDataQualityTab {
  guid: string;
  objectType?: ObjectType;
}

const useDataQualityTab = ({ guid, objectType = 'table' }: UseDataQualityTab) => {
  const { dataSources, organization } = useUserContext();

  const { useDataQualityTab: useDataQuality, useDataQualityUpstreamIssues } =
    organization?.settings ?? {};

  const isTable = objectType === 'table';
  let hasMonteIntegration = false;
  let hasDbtTests = false;

  Object.values(dataSources ?? {})?.forEach(({ settings, type }) => {
    if (type === 'monte_carlo') {
      hasMonteIntegration = true;
    }
    if (type === 'dbt' && settings?.hasDbtTests) {
      hasDbtTests = true;
    }
  });

  const { filter: monitorsFilter } = Filter.useUpdateFilters(
    { ...MONITORS_FILTER_CONFIG.defaultFiltersConfig, page_size: 1 },
    MONITORS_FILTER_CONFIG.searchConfig,
    MONITORS_FILTER_CONFIG.sortConfig,
    MONITORS_FILTER_CONFIG.defaultOrder,
  );
  const monitorsFiltersWithParams = Filter.setParams(monitorsFilter);

  const { data: monitorsData, isLoading: isMonitorsLoading } = useFetchMonitors({
    enabled: useDataQuality && hasMonteIntegration && isTable,
    params: {
      ...monitorsFiltersWithParams,
      tables: guid,
    } as GetMonitorsListQueryParams,
  });

  const { data: dbtWarehouseLinkData } = useFetchDbtWarehouseLink(guid, {
    enabled: useDataQuality && hasDbtTests && Boolean(guid) && isTable,
    params: {
      types: 'table,view,incremental,seed,dynamic_table,source',
    },
  });

  const dbtModelGuid = dbtWarehouseLinkData?.results?.map((el) => el?.dbtModel?.guid).join(',');

  const { filter: dbtTestsFilter } = Filter.useUpdateFilters(
    { ...DBT_TESTS_FILTER_CONFIG.defaultFiltersConfig, page_size: 1 },
    DBT_TESTS_FILTER_CONFIG.searchConfig,
    DBT_TESTS_FILTER_CONFIG.sortConfig,
    DBT_TESTS_FILTER_CONFIG.defaultOrder,
  );

  const { data: dbtTestsData, isLoading: isDbtTestsLoading } = useFetchDbtTests({
    enabled: useDataQuality && hasDbtTests && Boolean(dbtModelGuid),
    params: {
      ...Filter.setParams(dbtTestsFilter),
      table: dbtModelGuid,
    },
  });

  const { filter: upstreamIssuesFilter } = Filter.useUpdateFilters(
    { ...UPSTREAM_ISSUES_FILTER_CONFIG.defaultFiltersConfig, page_size: 1 },
    UPSTREAM_ISSUES_FILTER_CONFIG.searchConfig,
    UPSTREAM_ISSUES_FILTER_CONFIG.sortConfig,
    UPSTREAM_ISSUES_FILTER_CONFIG.defaultOrder,
  );
  const upstreamIssuesFilterWithParams = Filter.setParams(upstreamIssuesFilter);

  const { data: upstreamIssuesData } = useFetchDataQualityUpstreamIssues(guid, {
    enabled: useDataQuality && (hasDbtTests || hasMonteIntegration),
    params: upstreamIssuesFilterWithParams as GetDataQualityUpstreamIssuesListPathParams,
  });

  const hasDataQualityIntegration = isTable
    ? hasMonteIntegration || hasDbtTests
    : useDataQualityUpstreamIssues && (hasMonteIntegration || hasDbtTests);

  /*
   * Data quality count:
   * error: This property represents the monitors total count of errors and warnings (dbtTestsData and monitorsData).
   * success: If there is any upstream issue, then the success count is set to 0. Otherwise, it is set to the monitors total count of success (dbtTestsData and monitorsData).
   * warning: It's defined by the upstream issues error count.
   */
  const calculateDataQualityCount = () => {
    if (!hasMonteIntegration && !hasDbtTests) {
      return undefined;
    }

    if (hasDbtTests && isDbtTestsLoading) {
      return undefined;
    }

    if (hasMonteIntegration && isMonitorsLoading) {
      return undefined;
    }

    const monitorsWarningCount =
      (dbtTestsData?.status_counts?.warning ?? 0) + (monitorsData?.status_counts?.warning ?? 0);
    const monitorsErrorCount =
      (dbtTestsData?.status_counts?.error ?? 0) + (monitorsData?.status_counts?.error ?? 0);
    const monitorsSuccessCount =
      (dbtTestsData?.status_counts?.success ?? 0) + (monitorsData?.status_counts?.success ?? 0);

    const errorCount = monitorsErrorCount + monitorsWarningCount;
    let successCount = monitorsSuccessCount;
    const warningCount = useDataQualityUpstreamIssues
      ? upstreamIssuesData?.status_counts?.error ?? 0
      : 0;

    if (
      useDataQualityUpstreamIssues &&
      !warningCount &&
      !dbtTestsData?.status_counts?.warning &&
      !monitorsData?.status_counts?.warning
    ) {
      const upstreamIssuesSuccessCount = upstreamIssuesData?.non_failing_data_quality_tests_exist
        ? 1
        : 0;
      successCount += upstreamIssuesSuccessCount;
    }

    return {
      error: errorCount,
      success: successCount,
      warning: warningCount,
    };
  };

  const dataQualityCount = useMemo(calculateDataQualityCount, [
    dbtTestsData?.status_counts?.error,
    dbtTestsData?.status_counts?.success,
    dbtTestsData?.status_counts?.warning,
    hasDbtTests,
    hasMonteIntegration,
    isDbtTestsLoading,
    isMonitorsLoading,
    monitorsData?.status_counts?.error,
    monitorsData?.status_counts?.success,
    monitorsData?.status_counts?.warning,
    upstreamIssuesData?.non_failing_data_quality_tests_exist,
    upstreamIssuesData?.status_counts?.error,
    useDataQualityUpstreamIssues,
  ]);

  const dataQualityTabConfig = useMemo(() => {
    if (!hasDataQualityIntegration) {
      return null;
    }

    const showMonteCarloTab = isTable && hasMonteIntegration;
    const showDbtTestsTab = isTable && hasDbtTests;

    return {
      component: () => (
        <DataQualityTabContent
          dbtModelGuid={dbtModelGuid}
          guid={guid}
          initialCounts={{
            dbt: dbtTestsData?.status_counts,
            monteCarlo: monitorsData?.status_counts,
            upstreamIssues: {
              error: 0,
              success: upstreamIssuesData?.non_failing_data_quality_tests_exist ? 1 : 0,
              warning: upstreamIssuesData?.status_counts?.error ?? 0,
            },
          }}
          tabs={{
            dbt: showDbtTestsTab,
            monteCarlo: showMonteCarloTab,
            upstreamIssues: useDataQualityUpstreamIssues,
          }}
        />
      ),
      contentOffset: 1.6,
      label: (
        <Box alignItems="center" compDisplay="flex" gap={0.5}>
          Data Quality
          <StatusBadge counts={dataQualityCount} />
        </Box>
      ),
      route: '/data-quality',
    };
  }, [
    dataQualityCount,
    dbtModelGuid,
    dbtTestsData?.status_counts,
    guid,
    hasDataQualityIntegration,
    hasDbtTests,
    hasMonteIntegration,
    isTable,
    monitorsData?.status_counts,
    upstreamIssuesData?.non_failing_data_quality_tests_exist,
    upstreamIssuesData?.status_counts?.error,
    useDataQualityUpstreamIssues,
  ]);

  return {
    dataQualityTabConfig,
  };
};

export default useDataQualityTab;
