import { Type } from 'class-transformer';

class OwnedObjectsCountModel {
  total!: number;
}

export class OwnedObjectsModel {
  @Type(() => OwnedObjectsCountModel)
  counts!: OwnedObjectsCountModel;
}
