import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import { PaginatedResponse } from '@models/PaginatedResponse';

import paginatedTransform from '../paginatedTransform';

import * as cacheKeys from './cacheKeys';
import { LookMLFieldModel } from './LookMLFieldModel';

export const fieldsCacheKeys = {
  fields: cacheKeys.all,
};

const fetchBiFieldsSelect = paginatedTransform(LookMLFieldModel);
export const useFetchBiFields = (
  options?: UseFetchOptions<PaginatedResponse<LookMLFieldModel>>,
) => {
  return useFetch<PaginatedResponse<LookMLFieldModel>>({
    ...options,
    queryKey: [...cacheKeys.all, options?.params],
    select: fetchBiFieldsSelect,
    url: '/bi/fields/',
  });
};
