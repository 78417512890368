import React, { useMemo, useState } from 'react';
import type { Cell } from 'react-table';

import type { DbtProjectModelV1 } from '@api/dbt/DbtProjectModel.v1';
import Button from '@components/Button/Button';
import DateTime from '@components/DateTime';
import LinkedCell from '@components/Table/Cells/LinkedCell';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import { useModal } from '@context/Modal';
import type { PaginatedResponse } from '@models/PaginatedResponse';
import { Filter } from '@utils';
import type { FilterOptions } from '@utils/filters';

import DeleteProjectCell from './DeleteProjectCell';

enum ColumnKey {
  configure = 'configure',
  delete = 'delete',
  name = 'name',
  search = 'search',
  updatedAt = 'updatedAt',
}

const DEFAULT_COLUMNS = [
  ColumnKey.search,
  ColumnKey.name,
  ColumnKey.updatedAt,
  ColumnKey.configure,
  ColumnKey.delete,
];

export const DBT_PROJECTS_TABLE_SEARCH_CONFIG: PartialRecord<ColumnKey, keyof FilterOptions> = {
  [ColumnKey.name]: 'search_name',
};

export const DBT_PROJECTS_TABLE_SORT_CONFIG = {
  [ColumnKey.name]: 'name',
  [ColumnKey.updatedAt]: 'updated_on',
};

export interface DbtProjectsTableProps {
  data?: Pick<PaginatedResponse<DbtProjectModelV1>, 'count' | 'results'>;
  filterService: Filter.FilterServiceInterface;
  isLoading?: boolean;
  visibleColumns?: Array<ColumnKey>;
}

const DbtProjectsTable: React.FC<DbtProjectsTableProps> = ({
  data,
  filterService,
  isLoading,
  visibleColumns = DEFAULT_COLUMNS,
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const { MODAL_IDS, openModal } = useModal();
  const { changePage, filter, initialTableSortState, search, sort } = filterService;

  const columns = useMemo(() => {
    const all: Record<ColumnKey, ColumnConfig<DbtProjectModelV1>> = {
      [ColumnKey.search]: {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: ColumnKey.search,
        width: 32,
      },
      [ColumnKey.name]: {
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ column, row: { original }, state }: Cell<DbtProjectModelV1>) => (
          <LinkedCell
            column={column}
            item={original}
            noLink
            showIcon
            showNameTooltip
            state={state}
          />
        ),
        Header: `Name (${data?.count ?? 0})`,
        accessor: ColumnKey.name,
        disableHiding: true,
        id: ColumnKey.name,
        width: '125%',
      },
      [ColumnKey.updatedAt]: {
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row: { original } }: Cell<DbtProjectModelV1>) => (
          <DateTime datetime={original.updatedAt} />
        ),
        Header: 'Last Updated',
        accessor: (d) => d.updatedAt?.toDate(),
        disableFilters: true,
        id: ColumnKey.updatedAt,
        width: '400%',
      },
      [ColumnKey.configure]: {
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row: { original } }: Cell<DbtProjectModelV1>) => (
          <Button
            onClick={() => {
              openModal(MODAL_IDS.createDbtProject, original);
            }}
            px={0}
            type="button"
            variant="text"
          >
            Configure
          </Button>
        ),
        center: true,
        disableResizing: true,
        disableSortBy: true,
        id: ColumnKey.configure,
        width: '6.5rem',
      },
      [ColumnKey.delete]: {
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row: { original } }: Cell<DbtProjectModelV1>) => (
          <DeleteProjectCell dbtProject={original} />
        ),
        center: true,
        disableResizing: true,
        disableSortBy: true,
        id: ColumnKey.delete,
        width: '3rem',
      },
    };

    return visibleColumns.map((col) => all[col]);
  }, [MODAL_IDS.createDbtProject, data?.count, openModal, visibleColumns]);

  const totalPages = data && filter.page_size ? Math.ceil(data.count / filter.page_size) : 1;

  return (
    <TableStyled>
      <Table
        basic="very"
        changePage={changePage}
        columns={columns}
        compact
        data={data?.results ?? []}
        disableColumnFiltering
        disableRowSelect
        initialState={{
          sortBy: initialTableSortState,
        }}
        loading={isLoading}
        manualFilters
        manualPagination
        manualSortBy
        setFilters={search}
        setSortBy={sort}
        showFilter={showFilter}
        singleLine
        sortable
        stickyHeader
        toggleFilter={() => setShowFilter((prev) => !prev)}
        totalPages={totalPages}
        unstackable
      />
    </TableStyled>
  );
};

export default DbtProjectsTable;
