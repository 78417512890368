import React from 'react';

import DateRangePicker from '@components/DateRangePicker';
import { useDateRangePickerContext } from '@components/DateRangePicker/DateRangePicker.context';
import CostByUserTable from '@components/Table/CostByUserTable';

import TeamCostChart from '../charts/TeamCostChart';

import { SectionTitleWrapper, Title } from './SectionTitle';

const CostAnalysisUserTab: React.FC = () => {
  const { dateRange, maxRangeDays, presetDateOptions, setDateRange } = useDateRangePickerContext();

  return (
    <>
      <SectionTitleWrapper my={3}>
        <Title>Cost per Team</Title>
        <DateRangePicker
          initialRange={dateRange}
          maxRangeDays={maxRangeDays}
          onChange={setDateRange}
          presetDateOptions={presetDateOptions}
        />
      </SectionTitleWrapper>
      <TeamCostChart range={dateRange} />
      <CostByUserTable range={dateRange} />
    </>
  );
};

export default CostAnalysisUserTab;
