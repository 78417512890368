import { useCallback } from 'react';

import useLocalStorage from '@utils/useLocalStorage';

interface LocalStorageTableColumnsConfig {
  [columnId: string]: boolean;
}

interface LocalStorageTableFiltersConfig {
  [cacheKey: string]: LocalStorageTableColumnsConfig;
}

export interface UseTableFiltersConfigProps {
  cacheKey: string;
  defaultFilters?: LocalStorageTableColumnsConfig;
  storageKey: string;
}

const useLocalStorageTableFiltersConfig = ({
  cacheKey,
  defaultFilters = {},
  storageKey,
}: UseTableFiltersConfigProps) => {
  const [config, setConfig] = useLocalStorage<LocalStorageTableFiltersConfig>(storageKey, {});
  const chosenFilters = config[cacheKey] ?? defaultFilters;
  const finalFilters = { ...chosenFilters };
  const hiddenStorageTableColumns = Object.keys(finalFilters).filter((key) => !finalFilters[key]);

  const handleStorageTableFilterUpdate = useCallback(
    (columnId: string, isVisible: boolean) => {
      setConfig({
        ...config,
        [cacheKey]: { ...chosenFilters, ...{ [columnId]: isVisible } },
      });
    },
    [cacheKey, chosenFilters, config, setConfig],
  );

  return {
    handleStorageTableFilterUpdate,
    hiddenStorageTableColumns,
    storageTableColumnsFilters: finalFilters,
  };
};

export default useLocalStorageTableFiltersConfig;
