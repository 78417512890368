import { createContext } from 'react';
import * as d3 from 'd3';

import type { TreeContext } from './types';

export const defaultValues: { [key: string]: number } = {
  rectHeight: 1,
  rectWidth: 4,
  viewHeight: 500,
  viewMinX: -370,
  viewMinY: -150,
  viewWidth: 1000,
  xMaxRange: 900,
  yMaxRange: 320,
};

// fetch defaultValues from query string
const query = new URLSearchParams(document.location.search);
query.forEach((value, key) => {
  const parsedValue = parseFloat(value);
  // make extra sure this was intentional and correct + sanitize
  if (key in defaultValues && !Number.isNaN(parsedValue) && parsedValue.toString() === value) {
    defaultValues[key] = parsedValue;
  }
});

const xScale = d3.scaleLinear().domain([0, 10]).range([0, defaultValues.xMaxRange]);
const yScale = d3.scaleLinear().domain([0, 10]).range([0, defaultValues.yMaxRange]);

/** Create a line generator function that will map an array of coordinates relatively to scales */
const lineGenerator = d3
  .line()
  .x((d) => xScale(d[0]))
  .y((d) => yScale(d[1]));
const curveGenerator = d3
  .line()
  .curve(d3.curveBasis)
  .x((d) => xScale(d[0]))
  .y((d) => yScale(d[1]));

export const defaultYSpacing = defaultValues.rectHeight / 3;
const xSpacing = defaultValues.rectWidth / 5;

export const defaultContextValue = {
  activeSearchTables: [],
  columnExists: {},
  columnsById: {},
  curveGenerator,
  lineGenerator,
  pinnableColumnsById: {},
  rectHeight: defaultValues.rectHeight,
  rectWidth: defaultValues.rectWidth,
  renderContext: '',
  sortedColumnIdsByTableId: {},
  stack: {},
  stackedAt: {},
  tablesById: {},
  viewHeight: defaultValues.viewHeight,
  viewMinX: defaultValues.viewMinX,
  viewMinY: defaultValues.viewMinY,
  viewWidth: defaultValues.viewWidth,
  visibleColumns: [],
  visibleTables: [],
  xScale,
  xSpacing,
  yScale,
  ySpacing: defaultYSpacing,
};

const ExploreTreeContext = createContext<TreeContext>(defaultContextValue);

export default ExploreTreeContext;
