import styled from '@emotion/styled';

import { GridContainer } from '@components/Grid';

export const StyledCostAnalysisSharedFilter = styled(GridContainer)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space(1)};
  justify-content: flex-end;
  padding: 0;

  ${({ theme }) => theme.media.down('sm')`
    & > * {
      width: 100%;
    }
  `};

  ${({ theme }) => theme.media.up('sm')`
    flex-direction: row;

    & > * {
      max-width: ${theme.space(40)};
    }
  `};
`;
