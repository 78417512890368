import React from 'react';

import IconButton from '@components/IconButton/IconButton';
import Tooltip from '@components/Tooltip';

import Option from '../Option';
import TileTooltip from '../TileTooltip/TileTooltip';
import type { Option as OptionType } from '../types';

import { StyledTile } from './Tile.styles';

export interface TileProps {
  item: OptionType;
  onRemove: (item: OptionType) => void;
}

const Tile = ({ item, onRemove }: TileProps) => {
  const handleCloseIconClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onRemove(item);
  };

  const { hasError, text, tooltip } = item;

  const tooltipContent = tooltip ? <TileTooltip {...tooltip} /> : undefined;

  return (
    <Tooltip content={tooltipContent} interactive>
      <StyledTile aria-label={`Selected option ${text}`} hasError={hasError}>
        <Option {...item} fontWeight="medium" />
        <IconButton
          aria-label={`Remove ${text}`}
          color="gray.400"
          iconName="close"
          iconSize="10px"
          onClick={handleCloseIconClick}
          size="xs"
          variant="opaque"
        />
      </StyledTile>
    </Tooltip>
  );
};

export default Tile;
