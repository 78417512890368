import styled from '@emotion/styled';

import InputLabel from '@components/UI/Form/InputLabel';

export const StyledCreateCollectionContentFieldLabel = styled(InputLabel)``;

StyledCreateCollectionContentFieldLabel.defaultProps = {
  alignItems: 'center',
  compDisplay: 'grid',
  cursor: 'default',
  fontWeight: 'medium',
  gridTemplateColumns: '132px 1fr',
  mb: 1.5,
  variantColor: 'modalForm',
};
