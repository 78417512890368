import { CustomElement } from '../../RichTextEditor.types';

export const createTable = (rows: number, columns: number): CustomElement | undefined => {
  if (columns < 1 || rows < 1) {
    return;
  }
  const tableHead = createTableHead(columns);
  const tableBody = createTableBody(rows - 1, columns);

  const children = [tableHead];
  if (tableBody) {
    children.push(tableBody);
  }

  return {
    type: 'table',
    children,
  };
};

const createTableHead = (columns: number): CustomElement => {
  return {
    type: 'thead',
    children: [createRow(columns, true)],
  };
};

const createTableBody = (rows: number, columns: number): CustomElement | undefined => {
  const rowNodes = [];

  for (let i = 0; i < rows; i += 1) {
    rowNodes.push(createRow(columns));
  }

  if (rowNodes.length === 0) {
    return;
  }

  return {
    type: 'tbody',
    children: rowNodes,
  };
};

export const createRow = (columns: number, isHeader?: boolean): CustomElement => {
  const cellNodes = [];

  for (let i = 0; i < columns; i += 1) {
    cellNodes.push(createCell(isHeader));
  }

  return {
    type: 'tr',
    children: cellNodes,
  };
};

export const createCell = (isHeader?: boolean): CustomElement => {
  const content = createContent();
  return {
    type: isHeader ? 'th' : 'td',
    children: [content],
  };
};

const createContent = (): CustomElement => {
  return {
    type: 'paragraph',
    children: [{ text: '' }],
  };
};
