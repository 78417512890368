import React from 'react';

import type { IconProps } from '@components/UI/Icon';
import Icon from '@components/UI/Icon';

import SchemaHeaderStyled from './SchemaHeaderStyled';

interface SchemaHeaderProps {
  icon?: IconProps['name'];
  name?: string;
  selectedCount?: number;
}

const SchemaHeader: React.FC<SchemaHeaderProps> = ({ icon, name, selectedCount = 0 }) => {
  return (
    <SchemaHeaderStyled>
      {icon && <Icon mr={0.5} name={icon} size="16px" />}
      {name && (
        <span aria-level={4} className="name" role="heading">
          {name}
        </span>
      )}
      <div className="spacer" />
      <span className="selected-count">{`${selectedCount} selected`}</span>
    </SchemaHeaderStyled>
  );
};

export default SchemaHeader;
