import React from 'react';

import { ConditionModel } from '@api/joins/ConditionModel';
import { QueryModel } from '@api/queries/QueryModel';
import Highlighter from '@components/Highlighter';
import { useModal } from '@context/Modal';

import { JoinConditionsItem } from './JoinConditions.styles';

interface JoinConditionsProps {
  conditions: ConditionModel[];
  query: QueryModel;
}

const JoinConditions: React.FC<JoinConditionsProps> = ({ conditions, query }) => {
  const { MODAL_IDS, openModal } = useModal();

  return (
    <>
      {conditions.map((condition) => (
        <JoinConditionsItem
          key={condition.raw}
          className="join"
          onClick={() =>
            openModal(MODAL_IDS.query, {
              codeString: query.rawSql,
              dataSourceType: query.database?.dataSourceType,
              query,
            })
          }
        >
          <Highlighter
            autoEscape
            bgColor="#e2e9f0"
            searchWords={[condition?.leftColumn?.name ?? '', condition?.rightColumn?.name ?? '']}
            textToHighlight={condition?.raw ?? ''}
          />
        </JoinConditionsItem>
      ))}
    </>
  );
};

export default React.memo<JoinConditionsProps>(JoinConditions);
