import React from 'react';

import { useFetchDashboards } from '@api/dashboards';
import { DashboardModel } from '@api/dashboards/DashboardModel';
import invalidateCache from '@api/invalidateCache';
import Box from '@components/Box';
import Breadcrumbs, { BreadcrumbItem } from '@components/Breadcrumbs';
import BulkButtons from '@components/BulkButtons/BulkButtons';
import type { FiltersSidebarProps } from '@components/FiltersSidebar';
import { FiltersSidebar } from '@components/FiltersSidebar';
import LayoutMainGrid from '@components/Layout/LayoutMainGrid';
import PageHeaderBar from '@components/PageHeaderBar';
import Icon from '@components/UI/Icon';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import { useUserContext } from '@context/User';
import useBulkEditSelected from '@hooks/useBulkEditSelected';
import DataTypesModel from '@models/DataTypesModel';
import DASHBOARD_QUERY from '@pages/DashboardsPage/dashboardQuery';
import type { DashboardsTableProps } from '@pages/DashboardsPage/DashboardsTable';
import DashboardsTable, {
  DASHBOARD_TABLE_SEARCH_CONFIG,
  DASHBOARD_TABLE_SORT_CONFIG,
} from '@pages/DashboardsPage/DashboardsTable';
import { setParams, useUpdateFilters } from '@utils/filters';
import getItemsCount, { GetItemsCountParams } from '@utils/getItemsCount';
import MetadataDecorator from '@utils/MetadataDecorator';

const REQUEST_CONFIG = { order: '-popularity', page: 1, page_size: 100, query: DASHBOARD_QUERY };

export interface DashboardsPageProps
  extends Pick<DashboardsTableProps, 'visibleColumns' | 'hiddenColumns'> {
  breadcrumbList?: BreadcrumbItem[];
  countItemsConfig?: GetItemsCountParams['countItems'];
  dataTypeOptions?: FiltersSidebarProps['dataTypeOptions'];
  dataTypes?: DataTypesModel;
  dsGuid?: string;
  filterDataType?: string;
  filterProps?: Omit<FiltersSidebarProps, 'filterService'>;
  guid?: string;
  onSelectDataType?: FiltersSidebarProps['onSelectDataType'];
  selectDataType?: string;
  title?: string;
}
const DashboardsPage: React.FC<DashboardsPageProps> = ({
  breadcrumbList,
  countItemsConfig,
  dataTypeOptions,
  dataTypes,
  dsGuid,
  filterDataType,
  filterProps,
  guid,
  hiddenColumns,
  onSelectDataType,
  selectDataType,
  title,
  visibleColumns,
}) => {
  const { getDataSourceSettings } = useUserContext();
  const { selected, tagsCounts, toggleAll, toggleItem } = useBulkEditSelected<DashboardModel>();
  const filterService = useUpdateFilters(
    REQUEST_CONFIG,
    DASHBOARD_TABLE_SEARCH_CONFIG,
    DASHBOARD_TABLE_SORT_CONFIG,
    REQUEST_CONFIG.order,
  );

  const { data, isLoading } = useFetchDashboards({
    enabled: Boolean(dsGuid || guid),
    params: {
      ...setParams(filterService.filter),
      ...(filterDataType && { data_type: filterDataType }),
      ...(dsGuid && { datasources: dsGuid }),
      ...(guid && { bifolders: guid }),
    },
  });

  const dataSourceSettings = getDataSourceSettings(dsGuid);

  const itemCount = getItemsCount({ countItems: countItemsConfig, ref: data });

  const reload = () => {
    invalidateCache((keys) => [keys.dashboards.all]);
  };

  const isDataSourceEditable = Boolean(dataSourceSettings?.isEditable);

  const { permissions } = useObjectPermissionsContext();

  const selectedEditableItems = selected.items.filter(
    (item: any) => permissions[item.guid]?.isEditable,
  );

  return (
    <Box compDisplay="flex">
      <LayoutMainGrid>
        <MetadataDecorator title={title} />
        <PageHeaderBar
          icon={dataTypes && <Icon name={dataTypes.icons.dataType} />}
          sticky
          supIcon={dataTypes && <Icon name={dataTypes.icons.dataSource} />}
          supTitle={breadcrumbList && <Breadcrumbs items={breadcrumbList} />}
          title={title}
          titleSuffix={!isLoading && itemCount}
        />
        <BulkButtons
          canEditTags
          canRemove={!filterService.filter.is_hidden}
          canUnHide={filterService.filter.is_hidden}
          isDataSourceEditable={isDataSourceEditable}
          onEdit={reload}
          selectedEditableItems={selectedEditableItems}
          selectedItems={selected.items}
          showText
          tagsCounts={tagsCounts}
        />
        <Box mb={0.5}>
          <DashboardsTable
            data={data}
            filterService={filterService}
            hiddenColumns={hiddenColumns}
            isDataSourceEditable={isDataSourceEditable}
            isLoading={isLoading}
            selectedRowIds={selected.ids}
            toggleAll={(checked) => {
              toggleAll(data?.results!, checked);
            }}
            toggleItem={toggleItem}
            visibleColumns={visibleColumns}
          />
        </Box>
      </LayoutMainGrid>
      <FiltersSidebar
        dataSourceType={dataTypes?.dataSourceType}
        dataTypeInitialSelected={
          selectDataType ?? filterService.filter.data_type ?? dataTypeOptions?.[0].value
        }
        dataTypeOptions={dataTypeOptions}
        filterService={filterService}
        isCategoryTags
        isCreatedBy
        isPopularity
        isSelectStarDetected
        isStatusTags
        onSelectDataType={onSelectDataType}
        showHidden
        {...filterProps}
      />
    </Box>
  );
};

export default DashboardsPage;
