import React from 'react';
import { RouterLink } from '@routing/router';
import * as CSS from 'csstype';

import { DsUserModel } from '@api/dsusers/DsUserModel';
import { TeamModel } from '@api/teams/TeamModel';
import { UserModel } from '@api/user/UserModel';
import Box from '@components/Box';
import { Label } from '@components/Label';
import Tooltip from '@components/Tooltip';
import Avatar from '@components/UI/Avatar';
import { DeletedModel } from '@models/DeletedModel';
import { OrganizationTeamModel } from '@models/OrganizationTeamModel';
import { urlFor } from '@utils/routing';

export type OwnerType = UserModel | TeamModel | DsUserModel | OrganizationTeamModel;
interface OwnerItemProps {
  avatarMargin?: number;
  bold?: boolean;
  contentAlignment?: CSS.Property.AlignItems;
  height?: string;
  labelMargin?: number;
  owner?: OwnerType | DeletedModel | null;
  showAvatar?: boolean;
  showTags?: boolean;
}

const getName = (name: string | undefined) => {
  if (!name) return '';

  if (name === 'Deleted Item') {
    return 'Deactivated account';
  }

  return name;
};

const getToolTipText = (name: string | undefined, teamName: string | undefined) => {
  if (name === 'Deleted Item') {
    return 'Deactivated account';
  }

  if (teamName === 'short') {
    return `${name}, ${teamName}`;
  }

  return name;
};

const OwnerItem: React.FC<OwnerItemProps> = ({
  avatarMargin = 2,
  bold = false,
  contentAlignment = 'center',
  height = '1.625rem',
  labelMargin = 1,
  owner,
  showAvatar = 'true',
  showTags,
}) => {
  if (!owner) return null;

  const teamName = (owner as UserModel)?.team?.name;
  const teamUrl = urlFor((owner as UserModel)?.team, true);
  const name = getName(owner.fullName);
  const toolTipText = getToolTipText(owner.fullName, teamName);

  const isInactive = (owner as DeletedModel).isDeleted || !(owner as UserModel)?.isActive;
  const ownerUrl = isInactive ? undefined : urlFor(owner, true);

  return (
    <Box alignItems={contentAlignment} compDisplay="flex" noDefault overflow="hidden">
      <Tooltip content={toolTipText}>
        <Box
          alignItems="center"
          as={ownerUrl ? RouterLink : 'span'}
          compDisplay="flex"
          cursor={ownerUrl ? 'pointer' : 'not-allowed'}
          noDefault
          to={ownerUrl}
        >
          {showAvatar && (
            <Avatar {...owner.mappedAvatar} initialSize="md" mr={avatarMargin} size={height} />
          )}
          <Box
            as="span"
            color="gray.700"
            fontSize="0.875rem"
            fontWeight={bold ? 'semibold' : 'regular'}
            noDefault
            textOverflow="ellipsis"
          >
            {name}
          </Box>
        </Box>
      </Tooltip>
      {showTags && owner.objectType === 'user' && (
        <Tooltip action content="Go to the page">
          <Box>
            <Label
              guid={(owner as UserModel)?.team?.guid}
              labelText={(owner as UserModel)?.team?.name}
              ml={labelMargin}
              url={teamUrl || ownerUrl}
            />
          </Box>
        </Tooltip>
      )}
    </Box>
  );
};

export default OwnerItem;
