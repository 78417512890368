import * as Sentry from '@sentry/react';
import * as d3 from 'd3';
import getDebug from 'debug';

import type {
  ColumnCoordinates,
  ColumnsMap,
  CoordinatesMap,
  SortedColumns,
  TablesMap,
} from '../types';

const debug = getDebug('selectstar:explore');

interface GetColumnsCoordinatesArgs {
  columnIndex?: number;
  columns: string[];
  columnsById: ColumnsMap;
  rectHeight: number;
  recursive?: boolean;
  root: d3.Selection<d3.BaseType, unknown, null, undefined>;
  sortedColumnIdsByTableId: SortedColumns;
  tableCoordinatesMap: CoordinatesMap;
  tablesById: TablesMap;
}

const getColumnsCoordinates = ({
  columns,
  columnsById,
  rectHeight,
  recursive = true,
  root,
  sortedColumnIdsByTableId,
  tableCoordinatesMap,
  tablesById,
}: GetColumnsCoordinatesArgs): ColumnCoordinates[] => {
  const output: ColumnCoordinates[] = [];

  for (let i = 0; i < columns.length; i += 1) {
    const columnId = columns[i];
    const column = columnsById[columnId];
    const tableId = column?.tableGuid;

    if (tableId && columnId && column) {
      const tableCoords = tableCoordinatesMap[tableId];
      const columnNode = root?.select(`[id="${column.key}"]`);

      if (!columnNode?.empty()) {
        const columnIndex = columnNode.attr('data-position');

        if (tableCoords) {
          /**
           * Target columns should only include columns contained in `columns`,
           * except for self (it would cause infinite recursion).
           */
          if (column.targetEdges?.[columnId] !== undefined) {
            debug('getColumnsCoordinates', 'self referencing column will be skipped', columnId);
          }

          const targetColumns =
            Object.keys(column?.targetEdges ?? {})?.filter(
              (tcol) => columns.includes(tcol) && tcol !== columnId,
            ) || [];

          output.push({
            key: columnId,
            stackPos: tableCoords.stackPos,
            targets: recursive
              ? getColumnsCoordinates({
                  columns: targetColumns,
                  columnsById,
                  rectHeight,
                  root,
                  sortedColumnIdsByTableId,
                  tableCoordinatesMap,
                  tablesById,
                })
              : [],
            x: tableCoords.x,
            y: tableCoords.y + rectHeight * (Number(columnIndex) + 1),
          });
        } else {
          Sentry.captureMessage(`If this condition happens, it means we had enough data from server to render column data,
            but we failed to generate table coordinates for the current column. This indicates a
            failure in traversing the table graph, therefore this warning is better kept even in
            production environments (don't use debug() for that). This can now happen when "auto-close" is enabled.
            Missing CoordinatesMap should never happen on getColumnsCoordinates.`);
        }
      }
    }
  }

  return output.sort((a, b) => a.x - b.x);
};

export default getColumnsCoordinates;
