import { QuickSearchIndexFilter } from '@api/search/types';
import type { DataSourceTypesType } from '@models/DataSourceCredentials';

export const ALL_SEARCH_FILTERS: QuickSearchIndexFilter[] = [
  {
    indexes: ['users', 'teams'],
  },
];

export const TEAMS_SEARCH_FILTERS: QuickSearchIndexFilter[] = [
  {
    indexes: ['teams'],
  },
];

export const USERS_SEARCH_FILTERS: QuickSearchIndexFilter[] = [
  {
    indexes: ['users'],
  },
];

export const RECIPIENTS_SEARCH_FILTERS = {
  all: ALL_SEARCH_FILTERS,
  team: TEAMS_SEARCH_FILTERS,
  user: USERS_SEARCH_FILTERS,
};

export const NOTIFICATIONS_DATA_SOURCE_BLACKLIST: DataSourceTypesType[] = ['dbt'];
