import React, { useEffect } from 'react';

import { usePostQuickSearch } from '@api/search';
import type { FilterIndex } from '@api/search/types';
import CircularLoader from '@components/CircularLoader';
import QuickSearchInput from '@components/QuickSearch/QuickSearchInput';

import { SelectedMeasure } from './types';

export interface DimensionSearchProps {
  addSelectedItem?: (value: SelectedMeasure) => void;
  indexes: FilterIndex[];
  isPatch?: boolean;
  selectedItems?: SelectedMeasure[];
  selectedMeasure: SelectedMeasure;
  setSelectedItems?: (value: SelectedMeasure[]) => void;
}

const DimensionSearch: React.FC<DimensionSearchProps> = ({
  addSelectedItem,
  indexes,
  isPatch,
  selectedItems = [],
  selectedMeasure,
  setSelectedItems,
}) => {
  const { isLoading, mutate } = usePostQuickSearch({
    onSuccess: (result) => {
      setSelectedItems?.(result.data);
    },
  });

  useEffect(() => {
    if (!isPatch) {
      mutate({
        all_buckets_search: true,
        index_filters: [
          {
            filters: [
              { field: 'parent_guid', values: [selectedMeasure.parentGuid!] },
              { field: 'guid', must_not: [selectedMeasure.guid] },
            ],
            indexes,
          },
        ],
        no_external_tags: false,
        query: undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPatch, selectedMeasure]);

  if (isLoading) return <CircularLoader centered compSize={5} cover />;

  return (
    <QuickSearchInput
      filters={[
        {
          filters: [
            {
              field: 'guid',
              must_not: [selectedMeasure.guid].concat(selectedItems?.map(({ guid }) => guid)),
            },
          ],
          indexes,
        },
      ]}
      onResultSelect={addSelectedItem}
    />
  );
};

export default DimensionSearch;
