import styled from '@emotion/styled';

export const SidebarInfoDescription = styled.div`
  font-size: 13px;
  overflow: auto;
  max-height: 120px;
  flex-shrink: 0;
  margin-top: ${({ theme }) => theme.space(0.5)};
`;

export const SidebarInfoObjectInfo = styled.div`
  margin: ${({ theme }) => theme.space(3, 2, 0, 2)};
  flex-shrink: 0;
  padding-bottom: 15px;
`;

export const SidebarInfoTitle = styled.div`
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
  margin-bottom: ${({ theme }) => theme.space(0.15)};
  width: 100%;

  a,
  span {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    color: #2c2e36;
    display: -webkit-box;
    margin-right: 3px;
    overflow-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: break-spaces;
    width: auto;
  }

  a {
    :hover {
      text-decoration: underline;
    }
  }
`;
