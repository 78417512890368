import React from 'react';

import { DashboardModel } from '@api/dashboards/DashboardModel';
import { DescriptionSource } from '@api/description/description.types';
import { LookerExploreModel } from '@api/explores/LookerExploreModel';
import { LookMLViewModel } from '@api/lookML/LookMLViewModel';
import { TableauDataSourceModel } from '@api/tableau/TableauDataSourceModel';
import { TableModel } from '@api/tables/TableModel';
import { TagModel } from '@api/tags/TagModel';
import DbtSection from '@components/DbtSection';
import DetailsSection from '@components/DetailsSection';
import { disabledDetailsV1ObjectTypes } from '@components/DetailsSection/config';
import EditableCustomAttributesSection from '@components/EditableCustomAttributesSection';
import IndexesSection from '@components/IndexesSection';
import RichTextDescriptionEditor, {
  RichTextDescriptionEditorProps,
} from '@components/RichTextDescriptionEditor/RichTextDescriptionEditor';
import { StyledTextProps } from '@components/Text/Text';
import { CardProps } from '@components/UI/Card';
import { MetadataModel } from '@models/MetadataModel';
import theme from '@styles/theme';

type Section = 'details' | 'customAttributes' | 'description' | 'indexes' | 'dbt';

export interface OverviewContentProps {
  blockNavigation?: boolean;
  canEditDescription?: boolean;
  cardProps?: CardProps;
  data?:
    | TableModel
    | DashboardModel
    | LookerExploreModel
    | TableauDataSourceModel
    | TagModel
    | LookMLViewModel;
  description?: string;
  descriptionAllowedElements?: RichTextDescriptionEditorProps['allowedElements'];
  descriptionHeading?: string;
  descriptionShouldStartFocused?: boolean;
  descriptionVariantV1?: RichTextDescriptionEditorProps['variant'];
  forceDescriptionEditModeOnStart?: boolean;
  guid: string;
  isEditable: boolean;
  isError?: boolean;
  isLoading?: boolean;
  onDescriptionChange?: (description: string, plainTextDescription?: string) => void;
  onDescriptionSave: (
    description: string,
    plainTextDescription?: string,
    descriptionSource?: DescriptionSource,
  ) => void;
  reloadData?: () => void;
  richtextDescription?: string;
  sections: Section[];
  shouldFocusDescriptionOnEdit?: boolean;
  suggestedDescriptionSourceObject?: MetadataModel;
  titleFontSize?: StyledTextProps['fontSize'];
}

const OverviewContent = ({
  blockNavigation = true,
  canEditDescription,
  cardProps,
  data,
  description,
  descriptionAllowedElements,
  descriptionHeading,
  descriptionShouldStartFocused,
  descriptionVariantV1 = 'block',
  forceDescriptionEditModeOnStart,
  guid,
  isEditable,
  isError,
  isLoading,
  onDescriptionChange,
  onDescriptionSave,
  reloadData,
  richtextDescription,
  sections,
  shouldFocusDescriptionOnEdit,
  suggestedDescriptionSourceObject,
  titleFontSize = theme.typography.h4.fontSize,
}: OverviewContentProps) => {
  const componentsMap: Record<Section, React.ReactNode> = {
    customAttributes: (
      <EditableCustomAttributesSection
        key="customAttributes"
        cardProps={cardProps}
        guid={guid}
        isEditable={Boolean(canEditDescription)}
        titleFontSize={titleFontSize}
      />
    ),
    dbt: <DbtSection key="dbt" guid={guid} titleFontSize={titleFontSize} />,
    description: (
      <RichTextDescriptionEditor
        key="description"
        allowedElements={descriptionAllowedElements}
        blockNavigation={blockNavigation}
        dataSourceType={data?.dataTypes?.dataSourceType}
        dataTypes={data?.dataTypes}
        descriptions={{
          aiDescription: data?.aiDescription,
          description: data?.description ?? description,
          ingestedDescription: data?.ingestedDescription,
          richTextDescription: data?.richtextDescription ?? richtextDescription,
          suggestedDescription: data?.suggestedDescription,
          userDescription: data?.userDescription,
        }}
        descriptionSource={data?.descriptionSource}
        forceEditModeOnStart={forceDescriptionEditModeOnStart}
        guid={guid}
        isEditable={canEditDescription}
        isError={isError}
        isLoading={isLoading}
        onDescriptionChange={onDescriptionChange}
        onDescriptionSave={onDescriptionSave}
        shouldFocusOnEdit={shouldFocusDescriptionOnEdit}
        shouldStartFocused={descriptionShouldStartFocused}
        showDescriptionSelector={data?.showDescriptionSelector ?? Boolean(data?.descriptionSource)}
        suggestedDescriptionSource={data?.suggestedDescriptionSource}
        suggestedDescriptionSourceObject={suggestedDescriptionSourceObject}
        title={descriptionHeading}
        titleFontSize={titleFontSize}
        variant={descriptionVariantV1}
        wrapperProps={cardProps}
      />
    ),
    details: !disabledDetailsV1ObjectTypes.includes(data?.objectTypeV1!) ? (
      <DetailsSection
        key="details"
        cardProps={cardProps}
        data={data}
        isEditable={isEditable}
        reloadData={reloadData}
        titleFontSize={titleFontSize}
      />
    ) : null,
    indexes: <IndexesSection key="indexes" cardProps={cardProps} guid={guid} />,
  };

  return <>{sections.map((section) => componentsMap[section])}</>;
};

export default OverviewContent;
