import { Transform } from 'class-transformer';
import moment from 'moment';

export interface ProcessedThroughEntry {
  date?: moment.Moment;
  key: string;
}

export class ProcessedThroughEntry {
  static objectType: string = 'processedThroughEntry';

  objectType: string = ProcessedThroughEntry.objectType;

  key!: string;

  @Transform((value) => (value ? moment(value) : value))
  date?: moment.Moment;
}
