import React from 'react';
import getDebug from 'debug';

import { useFetchTableLatestSnapshot } from '@api/tables';
import TabError from '@components/TabContent/TabError';
import PreviewTable from '@components/Table/PreviewTable';
import { TabContentProps } from '@components/Tabs/types';

const debug = getDebug('selectstar:tab-loading');

interface PreviewTabProps extends TabContentProps {
  guid: string;
}

const PreviewTab = ({ guid }: PreviewTabProps) => {
  const { data, isError, isLoading } = useFetchTableLatestSnapshot(guid, {
    params: { page_size: 1 },
  });

  const columns = data?.columns || [];
  const rows = data?.rows || [];

  if (isError) return <TabError />;

  debug('display Preview');

  return <PreviewTable columns={columns} loading={isLoading} rows={rows} />;
};

export default React.memo<PreviewTabProps>(PreviewTab);
