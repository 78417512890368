import { DataSource } from '@configs/dataSources/types';

import useMutation, { UseMutationOptions } from '@hooks/useMutation';
import { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';

interface UsePostDataSourcesSelected
  extends UseMutationOptions<never, EnhancedErrorResult, { data_source_types: DataSource[] }> {}

export const usePostOnboardingDataSourcesSelected = (options: UsePostDataSourcesSelected) => {
  return useMutation({
    ...options,
    method: 'POST',
    url: '/onboarding/data-sources-selected/',
  });
};
