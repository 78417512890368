import React from 'react';

import Box from '@components/Box';
import Text from '@components/Text';
import Image from '@components/UI/Image';

const ChatBotEmptyState = () => {
  return (
    <Box alignItems="center" compDisplay="flex" compWidth="100%" flexDirection="column" mt={4}>
      <Image alt="No chats" height="auto" src="/images/conversation.svg" width="180px" />
      <Text fontSize="body2" fontWeight="medium" textAlign="center">
        Start by asking a question →
      </Text>
    </Box>
  );
};

export default ChatBotEmptyState;
