import React, { useCallback, useMemo, useState } from 'react';
import { Cell } from 'react-table';

import { TaggedItemModel } from '@api/tags/TaggedItemModel';
import DescriptionCell from '@components/Table/Cells/DescriptionCell';
import LinkedCell from '@components/Table/Cells/LinkedCell';
import PopularityCell from '@components/Table/Cells/PopularityCell';
import PopularityCellHeader from '@components/Table/Cells/PopularityCell/PopularityCellHeader';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import { useUserContext } from '@context/User';
import { Filter } from '@utils';

import TaggedItemCell from '../Cells/TaggedItemCell';

const initialSortState = [
  {
    desc: true,
    id: 'popularity',
  },
];
interface TaggedItemsTableProps {
  data?: TaggedItemModel[];
  filterService: Filter.FilterServiceInterface;
  guid: string;
  itemCount: number;
  totalPages: number;
}

export const NO_DESCRIPTION_SELECTOR_OBJECTS = ['document', 'metric', 'term', 'dbttest'];

const TaggedItemsTable = React.memo<TaggedItemsTableProps>(
  ({ data, filterService, guid, itemCount, totalPages }) => {
    const { organization } = useUserContext();
    const [isShowFilter, setShowFilter] = useState(false);
    const toggleFilter = useCallback(() => setShowFilter((prev) => !prev), [setShowFilter]);

    const { changePage, filter, search, sort } = filterService;

    const columns = useMemo(() => {
      const cols: ColumnConfig<TaggedItemModel>[] = [
        {
          Header: SearchHeader,
          disableFilters: true,
          disableResizing: true,
          disableSortBy: true,
          id: 'search',
          width: 32,
        },
        {
          Cell: (props: Cell<TaggedItemModel>) => {
            const { row } = props;
            const item = row.original;
            if (!item?.item?.obj) {
              return null;
            }

            return (
              <LinkedCell
                {...props}
                customUrl={item?.item?.obj.routePath}
                item={item?.item?.obj}
                showDataSourceIcon
                showIcon
              />
            );
          },
          Header: `Name (${itemCount})`,
          accessor: (d) => d.item?.obj?.fullName,
          disableHiding: true,
          id: 'name',
          width: '132%',
        },
        {
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (props: Cell<TaggedItemModel>) => {
            const {
              row: { original },
            } = props;

            const showDescriptionSelector = !NO_DESCRIPTION_SELECTOR_OBJECTS.includes(
              original.item?.obj?.dataTypes?.objectType ?? '',
            );

            return (
              <DescriptionCell
                {...props}
                aiDescription={original.item?.obj?.aiDescription}
                dataSourceType={original.item?.obj?.dataTypes?.dataSourceType}
                description={original.item?.obj?.description}
                descriptionSource={original.item?.obj?.descriptionSource}
                guid={original.item?.obj?.guid ?? ''}
                ingestedDescription={original.item?.obj?.ingestedDescription}
                isDataSourceEditable={false}
                name={original.item?.obj?.name ?? ''}
                richtextDescription={original.item?.obj?.richtextDescription}
                showDescriptionSelector={showDescriptionSelector}
                showEditIcon={!showDescriptionSelector}
                suggestedDescription={original.item?.obj?.suggestedDescription}
                suggestedDescriptionSource={original.item?.obj?.suggestedDescriptionSource}
                suggestedDescriptionSourceObj={
                  original.item?.obj?.suggestedDescriptionSourceObject?.obj
                }
                truncateDisabled={!organization?.settings?.useShowMoreButton}
                userDescription={original.item?.obj?.userDescription}
              />
            );
          },
          Header: 'Description',
          accessor: (d) => d.item?.obj?.description,
          id: 'description',
          width: '150%',
        },
        {
          Cell: (props: Cell<TaggedItemModel>) => {
            return <TaggedItemCell {...props} ignoreLinksTo={(item) => item.tag.guid === guid} />;
          },
          Header: 'Tags',
          accessor: (d) => d.item?.obj?.taggedItems,
          disableSortBy: true,
          id: 'tags',
          width: '118%',
        },
        {
          Cell: (props: Cell<TaggedItemModel>) => {
            const { row } = props;
            const item = row.original;
            return (
              <PopularityCell
                {...props}
                dataTypes={item?.item?.obj?.dataTypes}
                popularity={item?.item?.obj?.popularity}
                rawPopularity={item?.item?.obj?.rawPopularity}
              />
            );
          },
          Header: PopularityCellHeader,
          accessor: (d) => d.item?.obj?.popularity?.popularity,
          disableFilters: true,
          disableResizing: true,
          dropdownCheckboxLabel: 'Popularity',
          id: 'popularity',
          sortDescFirst: true,
          width: 120,
        },
      ];

      return cols;
    }, [guid, itemCount]);

    const getRowId = React.useCallback((row: Partial<TaggedItemModel>) => row.item?.obj?.guid!, []);

    return (
      <TableStyled>
        <Table
          basic="very"
          changePage={changePage}
          className="table-full"
          columns={columns}
          compact
          data={data || []}
          disableColumnFiltering
          disableRowSelect
          getRowId={getRowId}
          initialState={{
            pageIndex: filter.page ? filter.page - 1 : 0,
            sortBy: initialSortState,
          }}
          loading={data === undefined}
          manualFilters
          manualPagination
          manualSortBy
          setFilters={search}
          setSortBy={sort}
          showFilter={isShowFilter}
          sortable
          stickyHeader
          toggleFilter={toggleFilter}
          totalPages={totalPages}
          unstackable
        />
      </TableStyled>
    );
  },
);

export default TaggedItemsTable;
