interface FormatNumberArgs {
  locale?: string;
  options?: { maximumFractionDigits?: number; notation?: 'compact' };
  roundMax?: number;
  value?: number | string;
}

const formatNumber = ({
  locale,
  options = { maximumFractionDigits: 2 },
  roundMax,
  value,
}: FormatNumberArgs) => {
  const v = Number(value);

  if (Number.isNaN(v)) {
    return '0';
  }

  const formatter = locale
    ? new Intl.NumberFormat(locale, options)
    : new Intl.NumberFormat(undefined, options);

  if (roundMax && v > roundMax) {
    return `${formatter.format(roundMax)}+`;
  }

  return formatter.format(v);
};

export default formatNumber;
