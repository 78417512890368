import { css } from '@emotion/react';

import theme from '@styles/theme';

export type RadioButtonState = 'checked' | 'default' | 'error';

export const DEFAULT_DISABLED_STYLES = css`
  background-color: ${theme.colors.v1.gray[100]};
  border-color: ${theme.colors.v1.gray[200]};
  cursor: auto;
`;

export const CONTAINER_STATE_VARIANTS = {
  checked: {
    default: css`
      border-color: ${theme.colors.v1.primary[600]};
    `,
    disabled: DEFAULT_DISABLED_STYLES,
    hover: css`
      border-color: ${theme.colors.v1.primary[700]};
    `,
  },
  default: {
    default: css`
      border-color: ${theme.colors.v1.gray[300]};
    `,
    disabled: DEFAULT_DISABLED_STYLES,
    hover: css`
      border-color: ${theme.colors.v1.gray[400]};
    `,
  },
  error: {
    default: css`
      color: ${theme.colors.error};
      border-color: ${theme.colors.error};
    `,
    disabled: DEFAULT_DISABLED_STYLES,
    hover: css`
      color: ${theme.colors.error};
      border-color: ${theme.colors.error};
    `,
  },
} as const;

export const DOT_STATE_VARIANTS = {
  checked: {
    default: css`
      background-color: ${theme.colors.v1.primary[600]};
    `,
    disabled: css`
      background-color: ${theme.colors.v1.gray[300]};
      cursor: auto;
    `,
    error: css`
      background-color: ${theme.colors.error};
    `,
  },
  default: {
    default: css`
      background-color: transparent;
    `,
    disabled: DEFAULT_DISABLED_STYLES,
    error: css`
      background-color: transparent;
    `,
  },
};
