import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import { PaginatedResponse } from '@models/PaginatedResponse';

import paginatedTransform from '../paginatedTransform';

import { BiViewModel } from './BiViewModel';
import * as cacheKeys from './cacheKeys';

export const viewsCacheKeys = {
  views: cacheKeys.all,
};

const fetchBiViewsSelect = paginatedTransform(BiViewModel);
export const useFetchBiViews = (options?: UseFetchOptions<PaginatedResponse<BiViewModel>>) => {
  return useFetch<PaginatedResponse<BiViewModel>>({
    ...options,
    queryKey: [...viewsCacheKeys.views, options?.params],
    select: fetchBiViewsSelect,
    url: '/bi/views/',
  });
};
