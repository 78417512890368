import React, { useCallback } from 'react';
import { Cell } from 'react-table';

import { TagModel } from '@api/tags/TagModel';
import AppTag from '@components/AppTag';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import { Filter } from '@utils';
import wrapString from '@utils/wrapString';

import DbtTagsSetupSelect from './DbtTagsSetupSelect';

const initialSortState = [
  {
    desc: false,
    id: 'name',
  },
];

interface Props {
  data?: TagModel[];
  filterService: Filter.FilterServiceInterface;
  selectedRowIds: { [guid: string]: boolean };
  setSelectedRowIds: React.Dispatch<React.SetStateAction<{}>>;
  totalPages?: number;
}

const DbtTagsSetupTable: React.FC<Props> = ({
  data,
  filterService,
  selectedRowIds,
  setSelectedRowIds,
  totalPages,
}) => {
  const [isShowFilter, setShowFilter] = React.useState(false);
  const { changePage, filter, search } = filterService;

  const toggleFilter = React.useCallback(() => setShowFilter((prev) => !prev), [setShowFilter]);
  const columns: ColumnConfig<TagModel>[] = React.useMemo(
    () => [
      {
        Cell: ({ row }: Cell<TagModel>) => {
          const { original: tag } = row;

          return <AppTag alwaysVisible ignoreLinksTo tag={tag} variant="outlined" />;
        },
        Header: `dbt Tag ${wrapString(data?.length)}`,
        disableHiding: true,
        id: 'dbt_tag',
        width: '30%',
      },
      {
        Cell: ({ row }: Cell<TagModel>) => {
          const { original: tag } = row;

          return <DbtTagsSetupSelect tag={tag} />;
        },
        Header: `Select Star Tag`,
        disableHiding: true,
        id: 'ss_tag',
        width: '30%',
      },
      {
        id: 'padding',
        width: '40%',
      },
    ],
    [data?.length],
  );

  const getRowId = useCallback((row: Partial<TagModel>) => row.guid!, []);

  return (
    <TableStyled>
      <Table
        basic="very"
        changePage={changePage}
        className="table-full"
        columns={columns}
        data={data || []}
        disableColumnFiltering
        disablePageRefresh
        disablePagination
        getRowId={getRowId}
        initialState={{
          pageIndex: filter.page ? filter.page - 1 : 0,
          selectedRowIds,
          sortBy: initialSortState,
        }}
        loading={data === undefined}
        manualFilters
        rangeSelectConfig={{
          headerLabel: 'Tag Sync',
          hideHeaderCheckbox: true,
          maxWidth: 90,
          minWidth: 90,
          width: 90,
        }}
        selectable
        setFilters={search}
        setSelectedRowIds={setSelectedRowIds}
        showFilter={isShowFilter}
        sortable
        toggleFilter={toggleFilter}
        totalPages={totalPages}
        unstackable
      />
    </TableStyled>
  );
};

export default React.memo(DbtTagsSetupTable);
