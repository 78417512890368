import { plainToClass } from 'class-transformer';

import DataTypesModel from '@models/DataTypesModel';

export const DATA_TYPE_OPTIONS = [
  {
    key: 'content',
    text: 'Content',
    value: 'power_bi_elements',
  },
  {
    key: 'report',
    text: 'Reports',
    value: 'power_bi_reports',
  },
  {
    key: 'dashboard',
    text: 'Dashboards',
    value: 'power_bi_dashboards',
  },
  {
    key: 'dataset',
    text: 'Datasets',
    value: 'powerbidataset',
  },
  {
    key: 'dataflow',
    text: 'Dataflows',
    value: 'powerbidataflow',
  },
];

export const CONTAINER_CONFIG = {
  dataTypes: plainToClass(DataTypesModel, {
    data_source_type: 'power_bi',
    object_type: 'power_bi_folders_container',
  }),
  title: 'All Objects',
};
