import styled from '@emotion/styled';

import Input from '@components/Input/Input.v1';
import { StyledInput, StyledInputField } from '@components/Input/Input.v1.styles';
import rootTheme from '@styles/theme';

export const StyledColumnsSeachInput = styled(Input)`
  & ${StyledInputField} {
    color: ${({ theme }) => theme.colors.v1.gray[600]};
    font-size: ${({ theme }) => theme.typography.fontSizes.sm};
  }

  & ${StyledInput} {
    gap: ${({ theme }) => theme.space(0.5)};
    padding-left: ${({ theme }) => theme.space(0.5)};
    padding-right: ${({ theme }) => theme.space(0.5)};
    border-radius: ${({ theme }) => theme.radius.default};
    height: ${({ theme }) => theme.space(3.25)};

    &:focus,
    &:focus-within {
      border-color: ${({ theme }) => theme.colors.v1.primary[500]};
    }
  }
`;

StyledColumnsSeachInput.defaultProps = {
  compHeight: rootTheme.space(3.25),
  compWidth: '100%',
};
