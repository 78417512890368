import type { AxiosError, AxiosRequestConfig } from 'axios';
import {
  useMutation as useReactQueryMutation,
  UseMutationOptions as UseReactQueryMutationOptions,
  UseMutationResult as UseReactQueryMutationResult,
} from 'react-query';

import { CustomAxiosRequestConfig } from '@api/refreshToken';
import httpClient from '@lib/httpClient';
import createEnhancedError from '@utils/createEnhancedError';
import type { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';
import enrichApiResponseWithMetadata from '@utils/enrichApiResponseWithMetadata';

import handleRetry from './useFetch/handleRetry';

interface UseCustomMutationOptions<T> {
  headers?: { 'Content-Type': string };
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  onDownloadProgress?: AxiosRequestConfig['onDownloadProgress'];
  params?: T;
  url?: string;
}
interface CustomVariables {
  httpClientUrl?: string;
}

export type UseMutationOptions<
  TData = any,
  TError = EnhancedErrorResult,
  TVariables = any,
  TParams = { [key: string]: any },
> = UseCustomMutationOptions<TParams> &
  UseReactQueryMutationOptions<TData, TError, TVariables & CustomVariables>;

const useMutation = <
  TData = unknown,
  TError = EnhancedErrorResult,
  TVariables = any,
  TParams = any,
>({
  headers,
  method = 'POST',
  onDownloadProgress,
  params,
  url,
  ...options
}: UseMutationOptions<TData, TError, TVariables, TParams>): UseReactQueryMutationResult<
  TData,
  TError,
  TVariables & CustomVariables
> => {
  // @ts-expect-error
  return useReactQueryMutation<TData, TError, TVariables & CustomVariables>({
    mutationFn: async (variables?: TVariables & CustomVariables) => {
      let finalUrl = url;
      let data = variables;

      if (variables?.httpClientUrl) {
        const { httpClientUrl = undefined, ...other } = { ...variables };
        finalUrl = httpClientUrl;
        data = other as TVariables & CustomVariables;
      }

      try {
        const result = await httpClient({
          data,
          headers,
          method,
          onDownloadProgress,
          params: method === 'GET' ? data : params,
          url: finalUrl,
        });

        const resultData = result?.data ?? result;

        const enrichedData = enrichApiResponseWithMetadata(
          resultData,
          (result.config as CustomAxiosRequestConfig)?.metadata,
        );

        return enrichedData;
      } catch (err) {
        throw createEnhancedError({
          data: (err as AxiosError)?.response?.data,
          message: (err as AxiosError)?.response?.statusText ?? (err as Error)?.message,
          status: (err as AxiosError)?.response?.status,
        });
      }
    },
    retry: handleRetry,
    ...options,
  });
};

export default useMutation;
