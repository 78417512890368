import styled from '@emotion/styled';

import Code from '@components/UI/Code';

export const CodeElement = styled(Code)`
  p {
    font-family: monospace;
    font-size: 12px;
  }
`;

CodeElement.defaultProps = {
  variantColor: 'red',
};
