import styled from '@emotion/styled';

import BaseTableStyled from '@components/Table/TableStyled/BaseTableStyled';

export const StyledLookMLProjectTable = styled(BaseTableStyled)`
  &.ui.table {
    font-size: 14px !important;
  }
  &.ui.table tr td {
    &.publicKey {
    }
  }
  &.ui.table tr th {
    &.publicKey {
      text-align: left;
    }
  }
`;
