import React from 'react';
import { useTheme } from '@emotion/react';

import { CustomAttributeType } from '@api/customAttributes/CustomAttributeModel';
import Box from '@components/Box';
import Text from '@components/Text';
import type { IconProps } from '@components/UI/Icon';
import Icon from '@components/UI/Icon';

interface TypeElementProps {
  type: CustomAttributeType;
}

interface CustomAttributeTypeElement {
  icon: IconProps['name'];
  label: string;
}

export const typeElementMap: {
  [key in CustomAttributeType]: CustomAttributeTypeElement;
} = {
  text: { icon: 'attribute-text', label: 'Text' },
};

const TypeElement: React.FC<TypeElementProps> = ({ type }) => {
  const { colors, typography } = useTheme();
  return (
    <Box alignItems="center" compDisplay="flex">
      <Icon color={colors.black} name={typeElementMap[type].icon} size="13px" />
      <Box ml={1}>
        <Text fontSize={typography.fontSizes.body1}>{typeElementMap[type].label}</Text>
      </Box>
    </Box>
  );
};

export default TypeElement;
