import { Expose } from 'class-transformer';

export class TeamSettingsModel {
  @Expose({ name: 'emails_for_notifications' })
  emailsForNotifications!: string[];

  @Expose({ name: 'slack_channels_for_notifications' })
  slackChannelsForNotifications!: string[];

  @Expose({ name: 'enable_notifications_for_schema_changes' })
  enableNotificationsForSchemaChanges!: boolean;

  @Expose({ name: 'enable_notifications_for_metadata_changes' })
  enableNotificationsForMetadataChanges!: boolean;
}
