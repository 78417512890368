import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import type { UseMutationOptions } from '@hooks/useMutation';
import useMutation from '@hooks/useMutation';
import { HierarchyData, HierarchyModel } from '@models/HierarchyModel';
import { PaginatedResponse } from '@models/PaginatedResponse';
import { WorkspaceItemModel } from '@models/WorkspaceItemModel';

import invalidateCache from '../invalidateCache';
import paginatedTransform from '../paginatedTransform';
import rawTransform from '../rawTransform';

import * as cacheKeys from './cacheKeys';

export const fetchWorkspacesHierarchySelect = rawTransform(HierarchyModel);
export const useFetchWorkspacesHierarchy = (options?: UseFetchOptions<HierarchyData>) => {
  return useFetch<HierarchyData>({
    ...options,
    queryKey: [...cacheKeys.hierarchy],
    select: fetchWorkspacesHierarchySelect,
    url: '/workspaces/default/hierarchy/',
  });
};

const fetchWorkspacesSelect = paginatedTransform(WorkspaceItemModel);
export const useFetchWorkspaces = (
  id: string,
  options?: UseFetchOptions<PaginatedResponse<WorkspaceItemModel>>,
) => {
  return useFetch<PaginatedResponse<WorkspaceItemModel>>({
    ...options,
    queryKey: [...cacheKeys.all, options?.params],
    select: fetchWorkspacesSelect,
    url: `/workspaces/items/${id}/`,
  });
};

const fetchTeamWorkspacesSelect = paginatedTransform(WorkspaceItemModel);
export const useFetchTeamWorkspaces = (
  id: string,
  options?: UseFetchOptions<PaginatedResponse<WorkspaceItemModel>>,
) => {
  return useFetch<PaginatedResponse<WorkspaceItemModel>>({
    ...options,
    queryKey: [...cacheKeys.teamWorkspaces(id), options?.params],
    select: fetchTeamWorkspacesSelect,
    url: `/teams/workspaces/items/${id}/`,
  });
};

export const usePostWorkspaces = (id: string, options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    method: 'POST',
    onSuccess: (data, variables, context) => {
      invalidateCache((keys) => [keys.workspaces.hierarchy]);
      options?.onSuccess?.(data, variables, context);
    },
    url: `/workspaces/items/${id}/`,
  });
};

export const useDeleteWorkspace = (id: string, itemId: string, options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    method: 'DELETE',
    onSuccess: (data, variables, context) => {
      invalidateCache((keys) => [keys.workspaces.hierarchy]);
      options?.onSuccess?.(data, variables, context);
    },
    url: `/workspaces/items/${id}/${itemId}/`,
  });
};
