import React, { useCallback, useMemo, useState } from 'react';

import { useFetchRbacRoles } from '@api/rbacPolicy';
import RbacRoles from '@api/rbacPolicy/RbacRoles';
import { useUserContext } from '@context/User';

import type { ObjectPermissions } from './ObjectPermissionsContext';
import { ObjectPermissionsContext } from './ObjectPermissionsContext';

const parsePermissions = (obj?: RbacRoles): ObjectPermissions => {
  if (!obj) {
    return {};
  }

  Object.keys(obj).forEach((key) => {
    const role = obj[key]?.role ?? 'NO_ROLE';

    // eslint-disable-next-line no-param-reassign
    (obj as ObjectPermissions)[key].isEditable = ['admin', 'data_manager'].includes(role);
  });

  return obj as ObjectPermissions;
};

const ObjectPermissionsProvider: React.FC = ({ children }) => {
  const [batchIds, setBatchIds] = useState<Set<string>>(new Set());
  const [permissions, setPermissions] = useState<ObjectPermissions>({});
  const { hasEditPermissions, organization } = useUserContext();
  const { useRbac } = organization?.settings ?? {};
  const isPbac = Boolean(useRbac);

  const { isFetching } = useFetchRbacRoles({
    enabled: isPbac && batchIds.size > 0,
    onSuccess: (data) => {
      const newPermissions = parsePermissions(data);
      setPermissions((prev) => ({ ...prev, ...newPermissions }));
      setBatchIds(new Set());
    },
    params: { guids: Array.from(batchIds) },
  });

  const orgSettingPermissionById = useMemo(
    () =>
      new Proxy(
        {},
        {
          get: () => ({ isEditable: hasEditPermissions }),
        },
      ),
    [hasEditPermissions],
  );

  const checkPermissionsById = useCallback(
    (id: string) => {
      if (!batchIds.has(id) && !permissions[id]) {
        setBatchIds((prev) => {
          return new Set(prev.add(id));
        });
      }
    },
    [batchIds, permissions],
  );

  const checkPermissionsByIds = useCallback(
    (ids: string[]) => {
      ids.forEach((id) => {
        checkPermissionsById(id);
      });
    },
    [checkPermissionsById],
  );

  const cleanPermissionsCache = useCallback(() => {
    setPermissions({});
  }, []);

  const context = useMemo(
    () => ({
      checkPermissionsById,
      checkPermissionsByIds,
      cleanPermissionsCache,
      hasEditPermissions: Boolean(hasEditPermissions),
      isFetching,
      isPbac,
      permissions: isPbac ? permissions : orgSettingPermissionById,
      useRbac: Boolean(useRbac),
    }),
    [
      checkPermissionsById,
      checkPermissionsByIds,
      cleanPermissionsCache,
      hasEditPermissions,
      isFetching,
      isPbac,
      permissions,
      orgSettingPermissionById,
      useRbac,
    ],
  );

  return (
    <ObjectPermissionsContext.Provider value={context}>
      {children}
    </ObjectPermissionsContext.Provider>
  );
};

export default ObjectPermissionsProvider;
