import React from 'react';

import { NotificationModel } from '@api/notifications/NotificationModel';
import ActivityMessage from '@components/ActivityMessage';
import Box from '@components/Box';
import Text from '@components/Text';
import Avatar from '@components/UI/Avatar';
import Icon from '@components/UI/Icon';
import theme from '@styles/theme';

import { StyledNotificationDescriptionContainer } from './Notification.styles';

interface SystemNotificationProps {
  item: NotificationModel;
}

const SystemNotification = ({ item }: SystemNotificationProps) => {
  return (
    <Box compDisplay="flex" gap={1}>
      <Avatar
        backgroundColor={theme.colors.white}
        icon={{ name: 'select-star-colored', size: '18px' }}
        mt={0.25}
        size={theme.space(4)}
      />
      <StyledNotificationDescriptionContainer>
        {item.message ? (
          <ActivityMessage isShownOnTable={false} message={item.message} />
        ) : (
          <>
            <Icon
              compDisplay="inline"
              name={item.target.dataTypes?.icons.objectType!}
              verticalAlign="middle"
            />
            <Text display="inline" fontSize="body1" fontWeight="bold" mb={0} verticalAlign="middle">
              {item.target.name}
            </Text>
            {item.verb.split(' ').map((chunk) => (
              <Text
                key={chunk}
                as="span"
                display="inline"
                fontSize="body1"
                mb={0}
                verticalAlign="middle"
              >
                {chunk}
              </Text>
            ))}
          </>
        )}
      </StyledNotificationDescriptionContainer>
    </Box>
  );
};

export default SystemNotification;
