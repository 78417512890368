import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';

import type { StyledBoxProps } from '@components/Box';
import { BoxStyles } from '@components/Box/Box.styles';

const spinLeftAnimation = keyframes`
  from {transform: rotate(0deg)}
  to {transform: rotate(-360deg)}
`;

export interface StyledSVGIconProps extends StyledBoxProps {
  hoverColor?: string;
  spinLeft?: boolean;
}

export const StyledSVGIcon = styled.svg<StyledSVGIconProps>`
  ${BoxStyles};

  user-select: none;
  flex-shrink: 0;

  &:hover {
    fill: ${({ hoverColor }) => hoverColor};
  }

  animation: ${({ spinLeft }) =>
    spinLeft
      ? css`
          ${spinLeftAnimation} 1.5s infinite;
        `
      : 'none'};
`;
