import React from 'react';

import Button from '@components/Button/Button';
import { StyledButtonProps } from '@components/Button/Button.styles';
import CircularLoader from '@components/CircularLoader';
import theme from '@styles/theme';

interface ExportToCSVButtonPRops extends Pick<StyledButtonProps, 'compSize'> {
  isLoading?: boolean;
  onClick?: () => void;
}
const ExportToCSVButton: React.FC<ExportToCSVButtonPRops> = ({ compSize, isLoading, onClick }) => {
  return (
    <Button
      color={theme.colors.gray[800]}
      compSize={compSize}
      disabled={isLoading}
      onClick={onClick}
      startIcon={isLoading && <CircularLoader compSize={1} />}
      startIconName={!isLoading ? 'export' : undefined}
      variant="outlined"
    >
      Export to CSV
    </Button>
  );
};

export default ExportToCSVButton;
