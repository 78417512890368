import { RelatedDashboardModel } from '@api/dashboards/RelatedDashboardModel';
import paginatedTransform from '@api/paginatedTransform';
import rawTransform from '@api/rawTransform';
import { TableModel } from '@api/tables/TableModel';
import type { UseFetchOptions } from '@hooks/useFetch';
import useFetch from '@hooks/useFetch';
import type { UseMutationOptions } from '@hooks/useMutation';
import useMutation from '@hooks/useMutation';
import { FrequentDSUserModel } from '@models/FrequentDSUserModel';
import type { OwnersUpdateData } from '@models/Owners';
import { PaginatedResponse } from '@models/PaginatedResponse';
import { RelatedTableModel } from '@models/RelatedTableModel';
import type { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';

import {
  GetTablesRelatedDashboardsCsvRetrievePathParams,
  GetTablesRelatedDashboardsListQueryParams,
  GetTablesRelatedTablesListPathParams,
} from '../openapi.generated';

import * as cacheKeys from './cacheKeys';
import { TableSnapshotModel } from './TableSnapshotModel';

type TablePaginatedResponse = {
  model_count: number;
  schema_count: number;
} & PaginatedResponse<TableModel>;

export const fetchTablesSelect = paginatedTransform(TableModel);
export const useFetchTables = (options?: UseFetchOptions<TablePaginatedResponse>) => {
  return useFetch<TablePaginatedResponse>({
    ...options,
    queryKey: [...cacheKeys.all, options?.params],
    select: fetchTablesSelect,
    url: '/tables/',
  });
};

export const fetchTableSelect = rawTransform(TableModel);
export const useFetchTable = (id: string, options?: UseFetchOptions<TableModel>) => {
  return useFetch<TableModel>({
    ...options,
    queryKey: cacheKeys.table(id),
    select: fetchTableSelect,
    url: `/tables/${id}/`,
  });
};

const fetchSimilarTablesSelect = paginatedTransform(TableModel);
export const useFetchSimilarTables = (
  id: string,
  options?: UseFetchOptions<TablePaginatedResponse>,
) => {
  return useFetch<TablePaginatedResponse>({
    ...options,
    queryKey: cacheKeys.similarTables(id),
    select: fetchSimilarTablesSelect,
    url: `/tables/${id}/similar/`,
  });
};

export const tableRelatedDashboardsSelect = paginatedTransform(RelatedDashboardModel);
export const useFetchTableRelatedDashboards = (
  id: string,
  options: UseFetchOptions<
    PaginatedResponse<RelatedDashboardModel>,
    GetTablesRelatedDashboardsListQueryParams
  >,
) => {
  return useFetch<
    PaginatedResponse<RelatedDashboardModel>,
    GetTablesRelatedDashboardsListQueryParams
  >({
    ...options,
    queryKey: [...cacheKeys.tableRelatedDashboards(id), options?.params],
    select: tableRelatedDashboardsSelect,
    url: `/tables/${id}/related-dashboards/`,
  });
};

export const jobRelatedDashboardsSelect = paginatedTransform(RelatedDashboardModel);
export const useFetchJobRelatedDashboards = (
  id: string,
  options: UseFetchOptions<
    PaginatedResponse<RelatedDashboardModel>,
    GetTablesRelatedDashboardsListQueryParams
  >,
) => {
  return useFetch<
    PaginatedResponse<RelatedDashboardModel>,
    GetTablesRelatedDashboardsListQueryParams
  >({
    ...options,
    queryKey: [...cacheKeys.jobRelatedDashboards(id), options?.params],
    select: jobRelatedDashboardsSelect,
    url: `/jobs/${id}/related-dashboards/`,
  });
};

const fetchRelatedTableSelect = paginatedTransform(RelatedTableModel);
export const useFetchRelatedTables = (
  id: string,
  options?: UseFetchOptions<
    PaginatedResponse<RelatedTableModel>,
    GetTablesRelatedTablesListPathParams
  >,
) => {
  return useFetch<PaginatedResponse<RelatedTableModel>, GetTablesRelatedTablesListPathParams>({
    ...options,
    queryKey: [...cacheKeys.relatedTables(id), options?.params],
    select: fetchRelatedTableSelect,
    url: `/tables/${id}/related-tables/`,
  });
};

export const fetchTableLatestSnapshotSelect = rawTransform(TableSnapshotModel);
export const useFetchTableLatestSnapshot = (
  id: string,
  options: UseFetchOptions<TableSnapshotModel>,
) => {
  return useFetch<TableSnapshotModel>({
    ...options,
    queryKey: [...cacheKeys.tableSnapshot(id), options?.params],
    select: fetchTableLatestSnapshotSelect,
    url: `/tables/${id}/snapshots/latest`,
  });
};

export const useFetchTableExportData = (options?: UseFetchOptions) => {
  return useFetch({
    ...options,
    queryKey: [...cacheKeys.exportData, options?.params],
    responseType: 'blob',
    url: `/tables/export/`,
  });
};

export const fetchTableFrequentDSUsersSelect = paginatedTransform(FrequentDSUserModel);
export const useFetchTableFrequentDSUsers = (id: string, options?: UseFetchOptions) => {
  return useFetch<PaginatedResponse<FrequentDSUserModel>>({
    ...options,
    queryKey: [...cacheKeys.tableFrequentDsUsers(id), options?.params],
    select: fetchTableFrequentDSUsersSelect,
    url: `tables/${id}/frequent-dsusers/`,
  });
};

const fetchTableBiFrequentDSUsersSelect = paginatedTransform(FrequentDSUserModel);
export const useFetchTableBiFrequentDSUsers = (id: string, options?: UseFetchOptions) => {
  return useFetch<PaginatedResponse<FrequentDSUserModel>>({
    ...options,
    queryKey: [...cacheKeys.tableFrequentBiDsUsers(id), options?.params],
    select: fetchTableBiFrequentDSUsersSelect,
    url: `tables/${id}/frequent-bi-dsusers/`,
  });
};

export const usePatchTablesOwners = (
  options?: UseMutationOptions<never, EnhancedErrorResult, OwnersUpdateData>,
) => {
  return useMutation({
    ...options,
    method: 'PATCH',
    url: `/tables/owners/`,
  });
};

export const useFetchTablesRelatedDashboardsCsv = (
  id: string,
  options?: UseFetchOptions<any, GetTablesRelatedDashboardsCsvRetrievePathParams>,
) => {
  return useFetch({
    ...options,
    queryKey: [...cacheKeys.tableRelatedDashboardsCsv(id), options?.params],
    url: `/tables/${id}/related-dashboards/csv/`,
  });
};

export const useFetchJobRelatedDashboardsCsv = (
  id: string,
  options?: UseFetchOptions<any, GetTablesRelatedDashboardsCsvRetrievePathParams>,
) => {
  return useFetch({
    ...options,
    queryKey: [...cacheKeys.jobRelatedDashboardsCsv(id), options?.params],
    url: `/jobs/${id}/related-dashboards/csv/`,
  });
};
