import { XYPosition } from 'reactflow';

import { SIZES } from '../../../components/Nodes/config';
import { ExploreNode } from '../../../LineageExplore.types';
import createShowMore from '../../utils/createShowMore';
import {
  calcGroupX,
  calculateGroupChildX,
  calculateGroupWidth,
  createCountMapByStacks,
  createGroupNodeInitialStacksConfig,
  getOccupiedColumnsByNode,
  initialGroupHeight,
  showMoreTotalHeight,
  sortNodeKeysByColumnsRelevance,
  sortNodeKeysByPopularity,
} from '../algorithm.utils';
import { EdgesById } from '../types';

import parseNode from './parseNode';
import { NodeParser } from './types';

const biNodeParser: NodeParser = ({
  edgesById,
  initialPosition = { x: 0, y: 0 } as XYPosition,
  isColumnLevelLineage = false,
  nodesById,
  rawNode,
  shouldHideFilterLineage,
}) => {
  const groupWidth = calculateGroupWidth(
    rawNode,
    rawNode.stackedAt as number,
    isColumnLevelLineage,
  );
  const style = {
    height: initialGroupHeight,
    width: groupWidth,
  };
  const biNode: ExploreNode = {
    ...rawNode,
    data: {
      ...rawNode?.data,
      hiddenChildren: new Set([]),
      hiddenChildrenCount: 0,
      noMatchedChildren: false,
      style,
    },
    nodeStacksConfig: createGroupNodeInitialStacksConfig(rawNode),
    position: {
      x: initialPosition.x + calcGroupX(rawNode.stackedAt!, isColumnLevelLineage),
      y: initialPosition.y,
    },
    style,
  };

  const groupStacks = getOccupiedColumnsByNode(biNode);
  const extraHeightPerStack = createCountMapByStacks(
    groupStacks,
    SIZES.openedContentHeight.database,
  );
  const childrenCountPerStack = createCountMapByStacks(groupStacks);
  const biChildrenNodes: ExploreNode[] = [];
  let extraEdgesById: EdgesById = {};

  if (biNode?.data?.isOpen) {
    biNode.style!.height = SIZES.openedContentHeight.database + SIZES.paddingBottom.database;
    const sortingFunction =
      isColumnLevelLineage && shouldHideFilterLineage
        ? sortNodeKeysByColumnsRelevance
        : sortNodeKeysByPopularity;
    const biNodeChildren = sortingFunction(Array.from(biNode.data.children ?? []), nodesById).map(
      (childId) => nodesById[childId],
    );

    biNodeChildren.forEach((childNode, index) => {
      const currentShownChildrenCount =
        biNode.nodeStacksConfig?.[childNode.stackedAt!]?.shownChildrenCount!;
      const currentChildrenOnStack = childrenCountPerStack[childNode.stackedAt!];

      if (currentChildrenOnStack < currentShownChildrenCount) {
        const {
          children = [],
          extraEdgesById: childExtraEdgesById,
          node,
          nodeHeight,
        } = parseNode({
          edgesById,
          initialPosition: {
            x: calculateGroupChildX(biNode, Number(childNode.stackedAt), isColumnLevelLineage),
            y: extraHeightPerStack[childNode.stackedAt!] ?? 0,
          },
          isColumnLevelLineage,
          nodeIndex: index,
          nodesById,
          rawNode: childNode,
          shouldHideFilterLineage,
        });

        biChildrenNodes.push(node, ...children);
        extraEdgesById = { ...extraEdgesById, ...childExtraEdgesById };
        extraHeightPerStack[node.stackedAt!] += nodeHeight + SIZES.rowGap.table;
        childrenCountPerStack[childNode.stackedAt!] = currentChildrenOnStack + 1;
      } else {
        biNode.nodeStacksConfig![childNode.stackedAt!].hiddenChildrenCount! += 1;
        const currentHiddenChildren = (
          biNode.nodeStacksConfig![childNode.stackedAt!]?.hiddenChildren ?? new Set()
        ).add(String(childNode.key));
        biNode.nodeStacksConfig![childNode.stackedAt!].hiddenChildren = currentHiddenChildren;
      }
    });
  }

  Object.entries(biNode.nodeStacksConfig!).forEach(([stackId, stackConfig]) => {
    const { hiddenChildren, hiddenChildrenCount } = stackConfig;
    if (hiddenChildrenCount > 0) {
      const showMore = createShowMore({
        data: biNode.data,
        edgesById,
        hiddenChildren,
        hiddenChildrenCount,
        id: `${stackId}-${biNode.key}`,
        nodesById,
        parent: String(biNode.key),
        position: {
          x: calculateGroupChildX(biNode, Number(stackId), isColumnLevelLineage),
          y: extraHeightPerStack[Number(stackId)],
        },
        stackedAt: Number(stackId),
        width: SIZES.width.schema,
      });

      biChildrenNodes.push(showMore.node);
      extraEdgesById = { ...extraEdgesById, ...showMore.edges };
    }
  });

  const hasShowMore = Object.values(biNode.nodeStacksConfig!).some(
    (config) => config.hiddenChildrenCount > 0,
  );

  if (hasShowMore) {
    biNode.style!.height = Number(biNode.style!.height) + showMoreTotalHeight;
  }

  const biggerInternalStackHeight = Math.max(...Object.values(extraHeightPerStack));
  biNode.style!.height =
    Number(biNode.style!.height) + biggerInternalStackHeight - SIZES.openedContentHeight.database;

  if (biNode?.data?.isOpen) {
    biNode.style!.height = Number(biNode.style!.height) - SIZES.rowGap.table;
  }

  /*
   * We need to update the height and width of the node outside the style object
   * since react-flow uses these values to calculate the nodes overlaping and
   * remove the ones not visible on screen
   */
  biNode.height = Number(biNode.style?.height);
  biNode.width = Number(biNode.style?.width);

  return {
    children: biChildrenNodes,
    extraEdgesById,
    node: biNode,
    nodeHeight: Number(biNode?.style?.height),
  };
};

export default biNodeParser;
