import React from 'react';

import { ObjectType } from '@api/types';
import Avatar from '@components/UI/Avatar/Avatar';
import Icon, { IconProps } from '@components/UI/Icon';
import theme from '@styles/theme';

interface FavoritesHierarchyItemProps {
  color: string;
  dataSourceIcon: IconProps['name'];
  dataTypeIcon: string;
  guid: string;
  icon: string;
  name: string;
  objectType: ObjectType;
}

const FavoritesHierarchyItemIcons: React.FC<FavoritesHierarchyItemProps> = ({
  color,
  dataSourceIcon,
  dataTypeIcon,
  guid,
  icon,
  name,
  objectType,
}) => {
  return (
    <>
      <Icon color={theme.colors.v1.gray[700]} name={dataSourceIcon} size="16px" />
      {objectType === 'team' ? (
        <Avatar guid={guid} name={name} size="16px" />
      ) : (
        <Icon
          color={color ?? theme.colors.v1.gray[700]}
          name={(icon as IconProps['name']) ?? dataTypeIcon}
          size="16px"
        />
      )}
    </>
  );
};

export default React.memo(FavoritesHierarchyItemIcons);
