import React from 'react';

import { usePatchDescriptionAiBulkRemove } from '@api/description';
import invalidateCache from '@api/invalidateCache';
import Box from '@components/Box/Box.styles';
import Button from '@components/Button/Button';
import CircularLoader from '@components/CircularLoader';
import { renderErrorToast, renderInfoToast } from '@components/Toast';
import Modal, { ModalContent, ModalFooter, ModalHeader } from '@components/UI/Modal';

export const AI_BULK_ASSIST_DELETE_MODAL_TITLE = 'Remove all AI descriptions';
export const AI_BULK_ASSIST_DELETE_MODAL_DESCRIPTION =
  'Are you sure you want to remove all AI descriptions?';

export const AI_BULK_ASSIST_DELETE_MODAL_REMOVED_MSG = 'All AI descriptions have been removed';

export const AI_BULK_ASSIST_DELETE_MODAL_NOTHING_TO_REMOVE_MSG = 'No AI descriptions to remove';

export const invalidate = () =>
  invalidateCache((keys) => [
    keys.columns.all,
    keys.biColumns.all,
    keys.biDashboardElements.all,
    keys.dashboards.all,
    keys.queries.all,
    keys.fields.all,
    keys.explores.all,
    keys.tableau.all,
    keys.views.all,
  ]);

interface AiBulkAssistDeleteModalProps {
  guid: string;
  onClose: () => void;
}

const AiBulkAssistDeleteModal: React.FC<AiBulkAssistDeleteModalProps> = ({ guid, onClose }) => {
  const { isLoading, mutate: removeDescription } = usePatchDescriptionAiBulkRemove(guid, {
    onError: () => {
      renderErrorToast('Failed to remove AI descriptions');
    },
    onSuccess: (d) => {
      if (d?.updated_objects_count > 0) {
        renderInfoToast(AI_BULK_ASSIST_DELETE_MODAL_REMOVED_MSG);
        invalidate();
      } else {
        renderInfoToast(AI_BULK_ASSIST_DELETE_MODAL_NOTHING_TO_REMOVE_MSG);
      }
      onClose();
    },
  });

  return (
    <Modal onClose={onClose} size="tiny">
      <ModalHeader onClose={onClose} title={AI_BULK_ASSIST_DELETE_MODAL_TITLE} />
      <ModalContent minHeight="auto" py={2}>
        {AI_BULK_ASSIST_DELETE_MODAL_DESCRIPTION}
      </ModalContent>
      <ModalFooter>
        <Button disabled={isLoading} onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          disabled={isLoading}
          onClick={() => removeDescription(null)}
          startIcon={
            isLoading ? (
              <Box compWidth="16px">
                <CircularLoader borderWidth={2} color="gray.500" compSize={1.75} />
              </Box>
            ) : null
          }
        >
          Remove
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AiBulkAssistDeleteModal;
