import React, { useCallback, useMemo } from 'react';
import { Cell } from 'react-table';

import DatabaseIngestionConfigModel from '@api/ingestion/DatabaseIngestionConfigModel';
import Table from '@components/Table/Table';
import { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import Icon from '@components/UI/Icon';
import { Filter } from '@utils';

interface Props {
  changePage: (page: number) => void;
  currentPage: number;
  data?: DatabaseIngestionConfigModel[];
  filterService: Filter.FilterServiceInterface;
  selectedRowIds: { [guid: string]: boolean };
  setSelectedRowIds: (dbs: { [guid: string]: boolean }) => void;
  totalPages: number;
}

const DatabaseIngestionConfigTable: React.FC<Props> = ({
  changePage,
  currentPage,
  data,
  filterService,
  selectedRowIds,
  setSelectedRowIds,
  totalPages,
}) => {
  const { search } = filterService;

  const dbIngestionTableColumns: ColumnConfig<DatabaseIngestionConfigModel>[] = useMemo(
    () => [
      {
        Cell: (props: Cell<DatabaseIngestionConfigModel>) => {
          const { row } = props;
          const db = row.original;
          return (
            <>
              <Icon compDisplay="inline-block" mr={0.5} name="database" size="16px" />
              {db.name}
            </>
          );
        },
        accessor: (d) => d?.name,
        disableHiding: true,
        id: 'name',
        width: '200%',
      },
    ],
    [],
  );

  const getRowId = useCallback((row: Partial<DatabaseIngestionConfigModel>) => row.guid!, []);

  return (
    <TableStyled>
      <Table
        basic="very"
        changePage={changePage}
        className="table-full"
        columns={dbIngestionTableColumns}
        compact
        data={data || []}
        disableColumnFiltering
        disableHeaders
        disableResizing
        disableSortBy
        getRowId={getRowId}
        initialState={{
          pageIndex: currentPage - 1,
          selectedRowIds,
        }}
        loading={data === undefined}
        manualFilters
        manualPagination
        selectable
        setFilters={search}
        setSelectedRowIds={setSelectedRowIds}
        showFilter
        showSelectionHeader
        sortable
        stickyHeader={false}
        totalPages={totalPages}
      />
    </TableStyled>
  );
};

export default DatabaseIngestionConfigTable;
