import React, { memo, useEffect, useState } from 'react';

import { UsageTypeType } from '@api/lineage/types';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import NotApplicable from '@components/NotApplicable';
import Tooltip from '@components/Tooltip.v1/Tooltip';
import Select, { Option, SelectValue } from '@components/UI/Select';
import { StyledCaretButtonIcon } from '@components/UI/Select/CaretButton/CaretButton.styles';
import { useUserContext } from '@context/User';

import { UsageTypesState } from '../../useLineageExplore/LineageExplore.context.types';
import UsageTypeBadge from '../Common/UsageTypeBadge';
import RelevantLineageToggle from '../RelevantLineageToggle';

import {
  StyledUsageFieldFilterActionContainer,
  StyledUsageFieldFilterAnchor,
  StyledUsageFieldFilterHeader,
  StyledUsageFieldFilterOptionsContainer,
  StyledUsageFieldFilterOverlay,
  StyledUsageFieldFilterRelevantLineageContainer,
} from './UsageFieldFilter.styles';

const TOOLTIP_TEXT_MAP: Record<UsageTypeType, string> = {
  aggregated: 'Field is aggregated',
  asis: 'Field has been replicated as is',
  filter: 'Field has been used as a filter',
  none: 'Field usage not detected or available',
  transformed: 'Field has been transformed',
};

const OPTIONS_MAP: Record<UsageTypeType, Option> = {
  aggregated: {
    customLabel: <UsageTypeBadge tooltipText={TOOLTIP_TEXT_MAP.aggregated} type="aggregated" />,
    text: 'Aggr',
    value: 'aggregated',
  },
  asis: {
    customLabel: <UsageTypeBadge tooltipText={TOOLTIP_TEXT_MAP.asis} type="asis" />,
    text: 'As is',
    value: 'asis',
  },
  filter: {
    customLabel: (
      <UsageTypeBadge showFilterFullText tooltipText={TOOLTIP_TEXT_MAP.filter} type="filter" />
    ),
    text: 'Filter',
    value: 'filter',
  },
  none: {
    customLabel: (
      <Tooltip content={TOOLTIP_TEXT_MAP.none}>
        <NotApplicable aria-label="na" data-testid="column-usage" fontSize="body2" />
      </Tooltip>
    ),
    text: 'None',
    value: 'none',
  },
  transformed: {
    customLabel: <UsageTypeBadge tooltipText={TOOLTIP_TEXT_MAP.transformed} type="transformed" />,
    text: 'Transformed',
    value: 'transformed',
  },
};

const SORTED_OPTIONS_KEYS: UsageTypeType[] = [
  'asis',
  'aggregated',
  'transformed',
  'none',
  'filter',
];

const OPTIONS = SORTED_OPTIONS_KEYS.map((usageType) => OPTIONS_MAP[usageType]);

interface UsageFieldFilterProps {
  selectedUsageTypes: UsageTypeType[];
  setSelectedUsageTypesState: (usageTypes: UsageTypesState) => void;
  setUseRelevantLineage: (useRelevantLineage: boolean) => void;
  useRelevantLineage: boolean;
}

const UsageFieldFilter = ({
  selectedUsageTypes,
  setSelectedUsageTypesState,
  setUseRelevantLineage,
  useRelevantLineage,
}: UsageFieldFilterProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [isRelevantLineage, setIsRelevantLineage] = useState(useRelevantLineage);

  const { organization } = useUserContext();

  const handleFilterChange = (newValue: SelectValue) => {
    setSelectedOptions(newValue as Option[]);
  };

  useEffect(() => {
    if (isOpen) {
      setSelectedOptions(selectedUsageTypes.map((usageType) => OPTIONS_MAP[usageType]));
      setIsRelevantLineage(useRelevantLineage);
    }
  }, [selectedUsageTypes, useRelevantLineage, isOpen]);

  const handleRedrawLineage = () => {
    const newUsageTypes = selectedOptions.map((option) => option.value as UsageTypeType);
    setSelectedUsageTypesState({ usageTypes: newUsageTypes });
    setUseRelevantLineage(isRelevantLineage);
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && <StyledUsageFieldFilterOverlay />}
      <Select
        isMulti
        isOpen={isOpen}
        onChange={handleFilterChange}
        options={OPTIONS}
        optionsFitAnchorWidth={false}
        optionsListFooterElement={
          <Box compDisplay="flex" flexDirection="column">
            {organization?.settings?.useRelevantLineage && (
              <StyledUsageFieldFilterRelevantLineageContainer>
                <RelevantLineageToggle
                  checked={isRelevantLineage}
                  onChange={(e) => setIsRelevantLineage(Boolean(e.target.checked))}
                />
              </StyledUsageFieldFilterRelevantLineageContainer>
            )}
            <StyledUsageFieldFilterActionContainer>
              <Button compSize="xxs" onClick={handleCancel} variant="outlined">
                Cancel
              </Button>
              <Button compSize="xxs" onClick={handleRedrawLineage}>
                Apply
              </Button>
            </StyledUsageFieldFilterActionContainer>
          </Box>
        }
        optionsListHeaderElement={
          <StyledUsageFieldFilterHeader>Field Usage</StyledUsageFieldFilterHeader>
        }
        popperConfigProps={{
          customPopperStyles: {
            width: '240px',
          },
          placement: 'bottom-end',
        }}
        renderCustomAnchor={({ anchorProps }) => {
          return (
            <StyledUsageFieldFilterAnchor as="button" {...anchorProps}>
              View Settings
              <StyledCaretButtonIcon color="currentColor" isOpen={isOpen} name="down" size="16px" />
            </StyledUsageFieldFilterAnchor>
          );
        }}
        renderCustomContainer={({ children }) => (
          <StyledUsageFieldFilterOptionsContainer>
            {children}
          </StyledUsageFieldFilterOptionsContainer>
        )}
        setIsOpen={(newIsOpen) => setIsOpen(Boolean(newIsOpen))}
        showSelectAllCount={false}
        value={selectedOptions}
      />
    </>
  );
};

export default memo(UsageFieldFilter);
