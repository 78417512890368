import React, { useMemo } from 'react';

import ConnectionAlertModel, {
  ConnectionAlertLevel,
} from '@api/connectionAlerts/ConnectionAlertModel';
import Box from '@components/Box';
import Icon from '@components/UI/Icon';

import {
  ALERT_ICON_COLOR_MAP,
  ALERT_ICON_NAME_MAP,
  ALERT_LABEL_MAP,
} from './AlertTooltipContent.constants';
import { generateAlertLabel } from './AlertTooltipContent.utils';

interface AlertTooltipContentProps {
  alerts: ConnectionAlertModel[];
}

const AlertTooltipContent = ({ alerts }: AlertTooltipContentProps) => {
  const content = useMemo(() => {
    const alertsByType = alerts.reduce((acc, cur) => {
      if (!acc[cur.level]) acc[cur.level] = [];
      acc[cur.level].push(cur);
      return acc;
    }, {} as Record<ConnectionAlertLevel, ConnectionAlertModel[]>);

    if (alerts.length > 1) {
      return Object.entries(alertsByType).map(([level, currentAlerts]) => (
        <Box key={level} alignItems="center" compDisplay="flex" gap={0.5}>
          <Icon
            color={ALERT_ICON_COLOR_MAP[level as ConnectionAlertLevel]}
            name={ALERT_ICON_NAME_MAP[level as ConnectionAlertLevel]}
            size="16px"
          />
          {generateAlertLabel(level as ConnectionAlertLevel, currentAlerts.length)}
        </Box>
      ));
    }

    const [singleAlert] = alerts;

    return (
      <Box as="span" verticalAlign="middle">
        <Icon
          color={ALERT_ICON_COLOR_MAP[singleAlert.level]}
          compDisplay="inline"
          mb={0.25}
          mr={0.5}
          name={ALERT_ICON_NAME_MAP[singleAlert.level]}
          size="16px"
        />
        {`1 ${ALERT_LABEL_MAP[singleAlert.level]}: ${singleAlert.title}`}
      </Box>
    );
  }, [alerts]);

  return (
    <Box compDisplay="flex" flexDirection="column" gap={0.5}>
      {content}
    </Box>
  );
};

export default AlertTooltipContent;
