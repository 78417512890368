import styled from '@emotion/styled';

export const StyledLinkElement = styled.a`
  color: ${({ theme }) => theme.colors.hyperlink};
  font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  text-decoration-line: underline;

  &:hover {
    color: ${({ theme }) => theme.colors.hyperlink};
    text-decoration-line: underline;
  }
`;
