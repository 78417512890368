import { Editor, Element, Text, Transforms } from 'slate';

import { CustomElement, FormattedText } from '../RichTextEditor.types';

const ELEMENTS = ['blockquote', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
const BLOCK_WRAP_TYPES = ['blockquote'];

/**
 * Insert a break after the ELEMENTS above by pressing enter
 */
const withInsertBreak = (editor: Editor) => {
  const { insertBreak } = editor;
  editor.insertBreak = () => {
    /**
     * Find if we are inside one of the block types that we need to add a break line
     * by pressing enter
     */
    const [nodeMatch] = Array.from(
      Editor.nodes(editor, {
        match: (node) => {
          if (Text.isText(node)) {
            return ELEMENTS.some((style) => style in node);
          }
          if (Element.isElement(node)) {
            return ELEMENTS.some((style) => node.type === style);
          }
          return false;
        },
      }),
    );

    if (nodeMatch) {
      const node = Editor.node(editor, nodeMatch?.[1]);
      const nodeType = (node[0] as CustomElement).type;
      const nodePath = node[1];

      const nextNode = Editor.next(editor, { at: nodePath });
      const nextNodePath = nextNode ? nextNode?.[1] : [editor.children.length];

      /**
       * If node is a wrapper block, add a new paragraph after the block and move the
       * cursor to the new node. Else, just add a new paragraph after the current selection
       */
      if (BLOCK_WRAP_TYPES.includes(nodeType) && nextNodePath) {
        const wrapped = (node[0] as Element).children;
        const firstWrappedChildren = (wrapped[0] as Element).children;

        Transforms.insertNodes(
          editor,
          {
            children: [{ text: '' }],
            type: 'paragraph',
          },
          { at: nextNodePath },
        );
        Transforms.select(editor, { path: [nextNodePath[0], 0], offset: 0 });

        if (firstWrappedChildren.length === 1 && !(firstWrappedChildren[0] as FormattedText).text) {
          Transforms.delete(editor, { at: nodePath });
        }
      } else {
        Transforms.insertNodes(editor, {
          children: [{ text: '' }],
          type: 'paragraph',
        });
      }

      return;
    }

    insertBreak();
  };

  return editor;
};

export default withInsertBreak;
