import styled from '@emotion/styled';

interface Props {
  bold?: boolean;
  color?: string;
}

const PageText = styled.span<Props>`
  color: ${({ color, theme }) => color ?? theme.colors.text};
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  font-weight: ${({ bold, theme }) => theme.typography.fontWeights[bold ? 'bold' : 'regular']};
  display: inline;
  font-size: 14px;
  word-break: break-word;

  &::after {
    content: ' ';
  }
`;

export default PageText;
