import React from 'react';

import Button from '@components/Button/Button';

interface PreviousStepButtonProps {
  disabled?: boolean;
  onClick?: () => void;
}

const PreviousStepButton: React.FC<PreviousStepButtonProps> = ({ disabled, onClick }) => {
  return (
    <Button
      compWidth="100px"
      disabled={disabled}
      onClick={onClick}
      type="button"
      variant="outlined"
    >
      Back
    </Button>
  );
};

export default PreviousStepButton;
