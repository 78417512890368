import React from 'react';
import { Redirect, RouteComponentProps, useParams } from '@routing/router';
import * as Sentry from '@sentry/react';

import { getDiscussionUrl, getObjectTypeFromGuid, makeUrl, urlMap } from '@utils/routing';
import InvalidDataException from '@utils/sentry/errors/invalidDataException';

import NotFoundPage from './404';

const LinkRedirectPage: React.FC<RouteComponentProps> = () => {
  const { childGuid, guid } = useParams<{ childGuid: string; guid: string }>();
  const objectType = getObjectTypeFromGuid(guid);
  const childObjectType = getObjectTypeFromGuid(childGuid);

  // Special handling for comments because we must first know the parent object type to link to correct discussion page.
  if (childObjectType && childObjectType === 'comment') {
    if (guid && objectType && objectType in urlMap) {
      const baseUrl = makeUrl(objectType, guid);
      return <Redirect to={getDiscussionUrl(baseUrl, childGuid)} />;
    }
  }

  if (!objectType || !(objectType in urlMap) || !guid) {
    Sentry.captureException(
      new InvalidDataException(`Redirected to not found page (guid: ${guid})`),
      {
        extra: {
          childGuid,
          guid,
          objectType,
        },
        tags: {
          guid,
          objectType,
        },
      },
    );

    return <NotFoundPage />;
  }

  const redirectUrl =
    childGuid && childObjectType
      ? makeUrl(childObjectType, childGuid, guid)
      : makeUrl(objectType, guid);

  return <Redirect to={redirectUrl} />;
};

export default LinkRedirectPage;
