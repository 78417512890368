import React, { useState } from 'react';

import Alert from '@components/Alert';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import CenterAlignedBox from '@components/CenterAlignedBox';
import GoogleButton from '@components/GoogleButton';
import TermsOfService from '@components/TermsOfService';
import Checkbox from '@components/UI/Form/Checkbox';
import InputLabel from '@components/UI/Form/InputLabel';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import flags from '@features';
import useUrlSearchParams from '@hooks/useUrlSearchParams';
import theme from '@styles/theme';

import {
  StyledFreeTrialText,
  StyledHeaderWrapper,
  StyledImage,
  StyledNoCreditCardMessage,
} from './GoogleSignUp.styles';

interface GoogleSignUpProps {
  errorMessage?: string;
  signUpWithEmail?: () => void;
}

const GoogleSignUp: React.FC<GoogleSignUpProps> = ({ errorMessage, signUpWithEmail }) => {
  const [isAgreed, setIsAgreed] = useState(false);
  const [error, setError] = useState<string>();
  const segment = useSegmentContext();
  const query = useUrlSearchParams();
  const utmCampaign = query.get('utm_campaign');

  return (
    <CenterAlignedBox maxWidth="490px" minWidth="360px">
      <StyledHeaderWrapper
        compDisplay="flex"
        flexDirection="column"
        gap={1.5}
        id="here"
        mb={0.25}
        mt={2}
      >
        <StyledImage alt="Select Star" src="/icons/logo-ss-horizontal-black.svg" />
        <StyledFreeTrialText
          as="h1"
          color={theme.colors.v1.gray[900]}
          fontWeight="bold"
          lineHeight={1.4}
          m={0}
        >
          Start your 14-day free trial
        </StyledFreeTrialText>
        <StyledNoCreditCardMessage
          color={theme.colors.v1.gray[900]}
          fontWeight="semibold"
          lineHeight={1.4}
        >
          No credit card required.
        </StyledNoCreditCardMessage>
      </StyledHeaderWrapper>
      <Box alignItems="flex-start" compDisplay="inline" compWidth="100%" pb={3}>
        <InputLabel fontSize="body1">
          <Checkbox checked={isAgreed} onChange={() => setIsAgreed((prev) => !prev)} />
          <TermsOfService />
        </InputLabel>
      </Box>
      <Box maxWidth="410px">
        {!flags.hide_google_login && (
          <Box pb={1}>
            <GoogleButton disabled={!isAgreed} utmCampaign={utmCampaign} />
          </Box>
        )}
        {errorMessage && (
          <Box alignItems="flex-start" compDisplay="inline" compWidth="100%" pb={2}>
            <Alert type="error">{errorMessage}</Alert>
          </Box>
        )}
        <Box compDisplay="flex" compWidth="100%" justifyContent="center">
          <Button
            color={theme.colors.v1.gray[900]}
            compSize="lg"
            fontSize="13px"
            fontWeight="regular"
            onClick={() => {
              if (!isAgreed) {
                setError('You need to agree to Terms and Conditions');
              } else {
                segment?.track(SegmentTrackEventName.SignUpEmailSignUpLinkClicked);
                signUpWithEmail?.();
              }
            }}
            textAlign="center"
            variant="text"
          >
            Sign up with email
          </Button>
        </Box>
        {error && (
          <Box alignItems="flex-start" compDisplay="inline" compWidth="100%" pb={2}>
            <Alert type="error">{error}</Alert>
          </Box>
        )}
      </Box>
    </CenterAlignedBox>
  );
};

export default GoogleSignUp;
