import React from 'react';

import { useFetchDataSources } from '@api/dataSources';
import { useFetchDsUsers, usePatchDsUser } from '@api/dsusers';
import {
  StyledFormHorizontalLabelGrid,
  StyledLabel,
} from '@components/DataSourceSetup/DataSourceSetup.styles';
import { getDatasourceOptions, getDsUsersOptionsV1 } from '@components/Dropdown/helpers';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import Select, { SelectValue } from '@components/UI/Select';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import { isWarehouseType } from '@models/DataSourceCredentials';
import { DataSourceModel } from '@models/DataSourceModel';
import stripSpaces from '@utils/stripSpaces';

import useDataSourceMutation from '../useDataSourceMutation';

import { DataSourceFormProps } from './types';

const REQUIRED_FIELDS = [
  'name',
  'username',
  'password',
  'organization',
  'selectedDsConnection',
  'selectedDsUser',
] as const;

const ModeForm: React.FC<DataSourceFormProps> = ({
  children,
  dataSource,
  dataType,
  name = '',
  onSuccess,
  renderBefore,
}) => {
  const segment = useSegmentContext();
  const { mutate: updateDsUser } = usePatchDsUser('');
  const { error, isLoading, mutate } = useDataSourceMutation({ dataSource });

  const {
    data: dataSourcesResponse,
    error: dataSourcesError,
    isLoading: isLoadingDataSources,
  } = useFetchDataSources({
    params: {
      query: stripSpaces(`{
        guid,
        name,
        type,
        data_types,
      }`),
    },
  });

  const { handleChange, handleSubmit, setValues, values } = useForm({
    initialValues: {
      name: dataSource?.name ?? name,
      organization: '',
      password: '',
      selectedCloudProvider: [] as SelectValue,
      selectedDsConnection: [] as SelectValue,
      selectedDsUser: [] as SelectValue,
      username: '',
    },
    onSubmit: (val) => {
      mutate(
        {
          credentials: {
            organization: val.organization,
            password: val.password,
            username: val.username,
          },
          name: val.name,
          type: dataType,
        },
        {
          onSuccess: (ds) => {
            onSuccess(ds);
            updateDsUser({
              data: { is_mode_service_account: true },
              httpClientUrl: `/dsusers/${val.selectedDsUser?.[0]?.value}/`,
            });
          },
        },
      );
      segment?.track(SegmentTrackEventName.CreateServiceAccountConnectButtonClicked, { dataType });
    },
  });

  const {
    data: dsUserResponse,
    error: dsUsersError,
    isLoading: loadingDsUsers,
  } = useFetchDsUsers({
    enabled: Boolean(values.selectedDsConnection?.length),
    params: { datasources: values.selectedDsConnection?.[0]?.value! },
  });

  const datasourceConnections =
    dataSourcesResponse?.results.filter((ds: DataSourceModel) => isWarehouseType(ds.type)) ?? [];
  const dataSourceOptions = getDatasourceOptions(datasourceConnections);
  const userOptions = getDsUsersOptionsV1(dsUserResponse?.results);
  const usernameFieldIsDisabled = values.selectedDsConnection?.length === 0;
  const isInvalid = REQUIRED_FIELDS.some((key) => Boolean(values[key]?.length) === false);

  return (
    <Form isLoading={isLoading || isLoadingDataSources} onSubmit={handleSubmit}>
      <StyledFormHorizontalLabelGrid>
        {renderBefore?.({ error, loading: isLoading })}
        <StyledLabel>
          Display Name
          <Input
            error={error?.data?.name}
            helperText={error?.data?.name}
            maxLength={50}
            name="name"
            onChange={handleChange}
            placeholder="Mode"
            type="text"
            value={values.name}
          />
        </StyledLabel>
        <StyledLabel>
          Workspace API token
          <Input
            error={error?.data?.username}
            helperText={error?.data?.username}
            name="username"
            onChange={handleChange}
            placeholder="Workspace API token"
            type="text"
            value={values.username}
          />
        </StyledLabel>
        <StyledLabel>
          Token secret
          <Input
            error={error?.data?.password}
            helperText={error?.data?.password}
            name="password"
            onChange={handleChange}
            placeholder="Token secret"
            type="password"
            value={values.password}
          />
        </StyledLabel>
        <StyledLabel>
          Organization
          <Input
            error={error?.data?.organization}
            helperText={error?.data?.organization}
            name="organization"
            onChange={handleChange}
            placeholder="organization"
            type="text"
            value={values.organization}
          />
        </StyledLabel>
        {datasourceConnections.length > 0 && (
          <>
            <StyledLabel as="div">
              DB Connection
              <Select
                error={Boolean(dataSourcesError)}
                isLoading={isLoadingDataSources}
                label="DB Connection"
                maxOptionsVisible={7}
                onChange={(change) => {
                  setValues((prev) => ({
                    ...prev,
                    selectedDsConnection: change,
                    selectedDsUser: [],
                  }));
                }}
                options={dataSourceOptions}
                showClearButton
                value={values.selectedDsConnection}
              />
            </StyledLabel>
            <StyledLabel as="div">
              DB Username
              <Select
                error={Boolean(dsUsersError)}
                isDisabled={usernameFieldIsDisabled}
                isLoading={loadingDsUsers}
                label="DB Username"
                maxOptionsVisible={7}
                onChange={(change) => {
                  setValues((prev) => ({ ...prev, selectedDsUser: change }));
                }}
                options={userOptions}
                placeholder={
                  usernameFieldIsDisabled ? 'Please select a DB connection first.' : undefined
                }
                showClearButton
                value={values.selectedDsUser}
              />
            </StyledLabel>
          </>
        )}
      </StyledFormHorizontalLabelGrid>
      {children?.({ error, loading: isLoading || isInvalid })}
    </Form>
  );
};

export default ModeForm;
