import styled from '@emotion/styled';

export const StyledUploadCSVFileStyledLink = styled.a`
  font-size: ${({ theme }) => theme.typography.fontSizes.xs};
  color: ${({ theme }) => theme.colors.lightblue};
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
  text-decoration-line: underline;
  &:hover {
    color: ${({ theme }) => theme.colors.lightblue};
    text-decoration-line: underline;
  }
`;

export const StyledUploadCSVFilePreviewMarkdownWrapper = styled.div`
  ul {
    max-height: 100px;
    overflow: auto;
  }
`;
