import React, { useEffect } from 'react';
import { useHistory } from '@routing/router';

import Box from '@components/Box/Box.styles';
import Login from '@components/Forms/Login';
import Text from '@components/Text';
import TwoColumnDecorativeComponent from '@components/TwoColumnDecorativeComponent';
import TwoColumnLayout from '@components/TwoColumnLayout';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName, SegmentTrackPageName } from '@context/Segment/Segment.types';
import theme from '@styles/theme';
import MetadataDecorator from '@utils/MetadataDecorator';

const LoginPage: React.FC = () => {
  const segment = useSegmentContext();
  const history = useHistory();

  useEffect(() => {
    segment?.page(SegmentTrackPageName.SignIn);
  }, []);

  return (
    <>
      <MetadataDecorator title="Login" />
      <TwoColumnLayout
        left={
          <Login>
            <Box compDisplay="inline" p={4} textAlign="center">
              <Text color={theme.colors.gray[600]} fontSize="13px" fontWeight="regular">
                Don't have an account?&nbsp;
              </Text>
              <Text
                color={theme.colors.gray[600]}
                cursor="pointer"
                fontSize="13px"
                fontWeight="regular"
                onClick={() => {
                  segment?.track(SegmentTrackEventName.LoginSignUpLinkClicked);
                  history.push('/signup');
                }}
                textDecoration="underline"
              >
                Sign up
              </Text>
            </Box>
          </Login>
        }
        right={<TwoColumnDecorativeComponent />}
      />
    </>
  );
};

export default LoginPage;
