import React from 'react';
import { RenderElementProps, useFocused, useSelected } from 'slate-react';

import { StyledImage } from './ImageElement.styles';

const ImageElement = ({ attributes, children, element }: RenderElementProps) => {
  const selected = useSelected();
  const focused = useFocused();
  return (
    <div {...attributes}>
      {children}
      <div contentEditable={false}>
        <StyledImage alt="document" selected={selected && focused} src={element.url} />
      </div>
    </div>
  );
};

export default ImageElement;
