import styled from '@emotion/styled';

const getPaddingLeft = (level: number, type: string) => {
  /*
   * Will stop increasing padding after MAX_INDENT_LEVELS level
   * to make the tree more compact for a narrow sidebar.
   */
  const MAX_INDENT_LEVELS = 3;

  let paddingLeft = Math.min(level, MAX_INDENT_LEVELS) * 1;
  if ((type === 'table' || type === 'column') && level > MAX_INDENT_LEVELS) {
    paddingLeft += 1;
  }
  return paddingLeft;
};

interface StyledTreeNodeProps {
  level: number;
  type: string;
}

// eslint-disable-next-line import/prefer-default-export
export const StyledTreeNode = styled.div<StyledTreeNodeProps>`
  .treenode-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.375rem;
    line-height: 1.25rem;
    padding-left: ${(props: { level: number; type: string }) =>
      getPaddingLeft(props.level, props.type)}rem;
    cursor: pointer;
    position: relative;
    text-decoration: none;
    color: inherit;
  }

  .treenode-wrapper.active:before,
  .treenode-wrapper:hover:before {
    content: '';
    position: absolute;
    top: 0;
    left: -1.25rem;
    background: #e2e9f0;
    width: calc(100% + 1.25rem + 1.25rem);
    height: 100%;
  }

  .treenode-wrapper.active:after,
  .treenode-wrapper:hover:after {
    content: '';
    position: absolute;
    top: 0;
    left: -3.5rem;
    background: #0c3476;
    width: 0.6rem;
    height: 100%;
  }

  .treenode-wrapper.active .treenode-label,
  .treenode-wrapper:hover {
    color: #0c3476;
    font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
  }
  .treenode-wrapper svg {
    z-index: 2;
    position: relative;
  }

  .treenode-wrapper.active svg,
  .treenode-wrapper:hover svg {
    fill: #0c3476;
  }

  .treenode-wrapper.active .left-nav-tag1,
  .treenode-wrapper:hover .left-nav-tag1 {
    color: #0c3476;
    border-color: #0c3476;
    font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
  }

  .treenode-label {
    position: relative;
  }

  .treenode-label .highlight {
    font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
    font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
    background-color: transparent;
    color: inherit;
    padding: 0.2rem 0;
  }
`;
