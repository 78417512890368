import React, { useMemo } from 'react';
import { Cell } from 'react-table';

import { OrganizationInvitationModel } from '@api/invitations/OrganizationInvitationModel';
import Button from '@components/Button/Button';
import DateTime from '@components/DateTime';
import RevokeInvitationModal from '@components/Modal/RevokeInvitationModal';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import { useModal } from '@context/Modal';
import { OrganizationUserRoleMap } from '@models/OrganizationUserRole';

interface Props {
  invitations: OrganizationInvitationModel[];
  onInvitationRemoved: (invitation: OrganizationInvitationModel) => void;
  onInvitationResendClick: (invitation: OrganizationInvitationModel) => void;
}

const AdminInvitationsTable: React.FC<Props> = ({
  invitations,
  onInvitationRemoved,
  onInvitationResendClick,
}) => {
  const { MODAL_IDS, checkModalOpened, closeModal, getModalContent, openModal } = useModal();
  const revokeInvitationModalProps = getModalContent(MODAL_IDS.revokeInvitation);
  const columns: ColumnConfig<OrganizationInvitationModel>[] = useMemo(
    () => [
      {
        Header: 'Email',
        accessor: (d) => d?.email,
        disableFilters: true,
        disableResizing: true,
        id: 'email',
        width: '35%',
      },
      {
        Header: 'Team',
        accessor: (d) => d.team?.fullName,
        disableFilters: true,
        disableResizing: true,
        id: 'team',
        width: '15%',
      },
      {
        Header: () => 'Role',
        accessor: (d) => d?.role && OrganizationUserRoleMap[d?.role],
        disableFilters: true,
        disableResizing: true,
        id: 'role',
        width: '15%',
      },
      {
        Header: () => 'Status',
        accessor: (d) => d?.status,
        disableFilters: true,
        disableResizing: true,
        id: 'status',
        width: '15%',
      },
      {
        Cell: ({ row }: Cell<OrganizationInvitationModel>) => {
          const d = row.original;
          return <DateTime datetime={d?.lastInvitationEmailSentOn} />;
        },
        Header: () => 'Invite Sent',
        accessor: (d) => d?.lastInvitationEmailSentOn?.fromNow(),
        disableFilters: true,
        disableResizing: true,
        id: 'inviteSent',
        width: '20%',
      },
      {
        Cell: (props: Cell<OrganizationInvitationModel>) => {
          const { row } = props;
          const invitation = row.original;

          return (
            <Button
              onClick={() => onInvitationResendClick(invitation)}
              px={0}
              type="button"
              variant="text"
            >
              Resend
            </Button>
          );
        },
        disableFilters: true,
        disableResizing: true,
        id: 'resend',
        width: '6.5rem',
      },
      {
        Cell: (props: Cell<OrganizationInvitationModel>) => {
          const { row } = props;
          const invitation = row.original;
          return (
            <Button
              onClick={() => openModal(MODAL_IDS.revokeInvitation, { invitation })}
              px={0}
              type="button"
              variant="text"
            >
              Revoke
            </Button>
          );
        },
        disableFilters: true,
        disableResizing: true,
        id: 'revoke',
        width: '6.5rem',
      },
    ],
    [MODAL_IDS.revokeInvitation, openModal],
  );

  return (
    <TableStyled>
      <Table
        basic="very"
        className="table-full"
        columns={columns}
        compact
        data={invitations || []}
        disableColumnFiltering
        disableFilters
        disableGlobalFilter
        disablePagination
        disableResizing
        disableRowSelect
        disableSortBy
        loading={invitations === undefined}
        singleLine
        unstackable
      />
      {checkModalOpened(MODAL_IDS.revokeInvitation) && revokeInvitationModalProps && (
        <RevokeInvitationModal
          invitation={revokeInvitationModalProps.invitation}
          onClose={() => closeModal(MODAL_IDS.revokeInvitation)}
          onRemoved={onInvitationRemoved}
        />
      )}
    </TableStyled>
  );
};

export default React.memo(AdminInvitationsTable);
