import styled from '@emotion/styled';
import { Table as SemanticTable } from 'semantic-ui-react';

export interface StyledSemanticTableCellProps {
  noBorder?: boolean;
}

export const StyledSemanticTableCell = styled(SemanticTable.Cell)<StyledSemanticTableCellProps>`
  border-bottom: ${({ noBorder }) => (noBorder ? 'none !important' : 'inherit')};
`;

export const StyledTablePagination = styled.div`
  .ui.pagination {
    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: ${({ theme }) => theme.space(1.6, 1.85)};
      border-radius: ${({ theme }) => theme.radius.default};

      &:last-child {
        border-radius: ${({ theme }) => theme.radius.default};
      }
    }

    .active.item {
      padding: ${({ theme }) => theme.space(1.6, 1.85)};
      border-radius: ${({ theme }) => theme.radius.default};
    }
  }
`;
