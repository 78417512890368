import React, { useState } from 'react';
import { Pagination } from 'semantic-ui-react';
import { useDebouncedCallback } from 'use-debounce';

import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader/CircularLoader';
import { Column, GridContainer, Row } from '@components/Grid';
import { ColumnsCount } from '@components/Grid/Column.styles';
import Text from '@components/Text';

interface MetadataGridProps<T> {
  changePage?: (page: number) => void;
  children?: (item: T) => React.ReactElement;
  columnsCount?: {
    '2xl'?: ColumnsCount;
    lg?: ColumnsCount;
    md?: ColumnsCount;
    sm?: ColumnsCount;
    xl?: ColumnsCount;
    xs?: ColumnsCount;
  };
  data?: T[];
  enableScrollTop?: boolean;
  isLoading?: boolean;
  label?: string;
  showNoDataMessage?: boolean;
  totalPages?: number;
}

const MetadataGrid = <T,>({
  changePage = () => {},
  children,
  columnsCount = {
    lg: 4,
    md: 6,
    xl: 3,
    xs: 12,
  },
  data,
  enableScrollTop = true,
  isLoading,
  label,
  showNoDataMessage = true,
  totalPages = 0,
}: MetadataGridProps<T>) => {
  const [currentPage, setCurrentPage] = useState(1);

  const onPageChange = useDebouncedCallback(changePage, 10);

  const handlePageChange = (nextPage: number) => {
    setCurrentPage(nextPage);
    onPageChange(nextPage);

    if (enableScrollTop) {
      window.scrollTo({ behavior: 'smooth', top: 0 });
    }
  };

  if (isLoading) {
    return (
      <Box compDisplay="flex" compWidth="100%" justifyContent="center" py={3}>
        <CircularLoader compSize={5} />
      </Box>
    );
  }

  if (data?.length === 0) {
    if (showNoDataMessage) {
      return <Text fontSize="body1">No data</Text>;
    }

    return null;
  }

  return (
    <>
      <GridContainer aria-label={label} fluid hPaddingSpace={0} role="grid">
        <Row as="ul" flexWrap="wrap" space={1.5}>
          {data?.map((item, index) => {
            return (
              <Column key={index} as="li" role="gridcell" {...columnsCount}>
                {children?.(item)}
              </Column>
            );
          })}
        </Row>
      </GridContainer>
      {totalPages > 1 && (
        <Box compDisplay="flex" justifyContent="center" pt={1.2}>
          <Pagination
            activePage={currentPage}
            onPageChange={(_, { activePage }) => {
              handlePageChange(Number(activePage));
            }}
            secondary
            size="mini"
            totalPages={totalPages}
          />
        </Box>
      )}
    </>
  );
};

export default MetadataGrid;
