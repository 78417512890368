import React from 'react';

import { UserModel } from '@api/user/UserModel';
import Box from '@components/Box';
import Avatar from '@components/UI/Avatar';

interface ProfileAvatarProps {
  onClick?: (e: React.SyntheticEvent) => void;
  user: UserModel;
}

const ProfileAvatar: React.FC<ProfileAvatarProps> = ({ children, onClick, user }) => {
  return (
    <Box
      as="button"
      className="icon-profile"
      onClick={(e: React.MouseEvent) => onClick && onClick(e)}
      onKeyDown={(e: React.KeyboardEvent) => onClick && onClick(e)}
      outline="none !important"
      tabIndex={-1}
    >
      <Avatar {...user.mappedAvatar} className="avatar" size="1.75rem" />
      {children}
    </Box>
  );
};

export default ProfileAvatar;
