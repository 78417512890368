import React from 'react';

import { useFetchQueries } from '@api/queries';
import TabError from '@components/TabContent/TabError';
import PopularQueryTable from '@components/Table/PopularQueryTable';
import { FilterOptions, setParams } from '@utils/filters';
import stripSpaces from '@utils/stripSpaces';

import type { QueriesAndJoinsProps } from '../QueriesJoinsTab/QueriesJoinsTab';

const defaultConfig: FilterOptions = {
  order: '-popularity',
  query: stripSpaces(`{
    guid,
    name,
    database{
      data_source{
        type
      }
    },
    raw_sql,
    last_executed_on,
    created_by{
      guid,
      first_name,
      last_name,
      avatar
    },
    dsuser_created_by{
      name,
      display_name
    },
    main_user,
    main_dsuser,
    popularity
  }`),
  types: ['select'],
};

interface PopularQueriesTabProps extends QueriesAndJoinsProps {
  isColumn: boolean;
}

const PopularQueriesTab: React.FC<PopularQueriesTabProps> = ({
  basic,
  isColumn,
  requestConfig,
}) => {
  const {
    data: popularQueryResponse,
    isError,
    isLoading,
  } = useFetchQueries({
    params: setParams({
      ...defaultConfig,
      ...requestConfig,
      page_size: isColumn ? 6 : 5,
    }),
  });

  if (isError) return <TabError />;

  return (
    <PopularQueryTable basic={basic} data={popularQueryResponse?.results} loading={isLoading} />
  );
};

export default React.memo(PopularQueriesTab);
