import styled from '@emotion/styled';

export const StyledLoaderWrapper = styled.div`
  ${() => {
    if (process.env.REACT_APP_CHROME_EXTENSION_BUILD) {
      /**
       * Trying to set the extension height to 600px (maximum height) creates
       * an issue where the scrollbar disappears for some users.
       * To make the height look like the maximum, we used 599px.
       */
      return `
        height: 599px;
        display: flex;
        align-items: center;
      `;
    }
    return '';
  }}
`;
