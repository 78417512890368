import React from 'react';
import { Table } from 'semantic-ui-react';

import LinkedCell from '@components/Table/Cells/LinkedCell';
import { TableQueryModel } from '@models/TableQueryModel';
import { dateTimeFormat } from '@utils/moment';
import useSortableTableLocally, { SortConfig } from '@utils/sortableTableLocally';

import TablesQueryTableStyled from './TablesQueryTableStyled';

type SortColumn = 'fullPath' | 'queryRuns' | 'lastQueriedOn';
const sortConfig: SortConfig<SortColumn> = [
  {
    name: 'fullPath',
    type: 'text',
  },
  {
    name: 'queryRuns',
    reversed: true,
    type: 'number',
  },
  {
    name: 'lastQueriedOn',
    reversed: true,
    type: 'moment',
  },
];

interface Props {
  data?: TableQueryModel[];
}

const TablesQueryTable: React.FC<Props> = ({ data }) => {
  // eslint-disable-next-line
  const { sortedData, sortColumn, sortDirection, handleSort } = useSortableTableLocally<
    SortColumn,
    TableQueryModel
  >(data || [], sortConfig);

  return (
    <TablesQueryTableStyled className="table-full">
      <Table basic="very" selectable sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              className="sortable"
              onClick={() => handleSort('fullPath')}
              sorted={sortColumn?.name === 'fullPath' ? sortDirection : undefined}
            >
              Table / View
            </Table.HeaderCell>
            <Table.HeaderCell
              className="sortable"
              onClick={() => handleSort('queryRuns')}
              sorted={sortColumn?.name === 'queryRuns' ? sortDirection : undefined}
            >
              Query Runs
            </Table.HeaderCell>
            <Table.HeaderCell
              className="sortable"
              onClick={() => handleSort('lastQueriedOn')}
              sorted={sortColumn?.name === 'lastQueriedOn' ? sortDirection : undefined}
            >
              Last Queried
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {!!sortedData?.length &&
            sortedData.map((item) => (
              <Table.Row key={item.guid}>
                <Table.Cell>
                  <LinkedCell fullPath item={item} />
                </Table.Cell>
                <Table.Cell>{item.queryRuns}</Table.Cell>
                <Table.Cell>
                  {item.lastQueriedOn ? item.lastQueriedOn?.format(dateTimeFormat()) : '---'}
                </Table.Cell>
              </Table.Row>
            ))}
          {data?.length === 0 && (
            <Table.Row>
              <Table.Cell colSpan="3">No items.</Table.Cell>
            </Table.Row>
          )}
          {data === undefined && (
            <Table.Row>
              <Table.Cell colSpan="3">Loading items...</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </TablesQueryTableStyled>
  );
};

export default React.memo(TablesQueryTable);
