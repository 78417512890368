import { useCallback, useEffect, useMemo } from 'react';
import type { UseMultipleSelectionActions } from 'downshift';
import { useMultipleSelection } from 'downshift';
import uniqBy from 'lodash/uniqBy';

import { TaggedItemModel } from '@api/tags/TaggedItemModel';
import { TagCounts } from '@api/tags/TagModel';
import { ObjectType } from '@api/types';
import { useUserContext } from '@context/User';

export interface SelectedBulkItem {
  guid: string;
  objectType: ObjectType;
  taggedItems?: TaggedItemModel[];
}

export interface TagsCounts {
  [tagGuid: string]: number;
}

interface UseBulkEditSelectedResults<T> {
  reset: UseMultipleSelectionActions<T>['reset'];
  selected: {
    ids: { [key: string]: boolean };
    items: T[];
    keys: string[];
  };
  setSelected: (items: T[] | undefined) => void;
  tagsCounts: TagsCounts;
  toggleAll: (all: T[], checked: boolean) => void;
  toggleItem: (item: T, checked: boolean) => void;
}

interface UseBulkEditSelectedProps<T> {
  defaultSelected?: T[];
  initialSelected?: T[];
  key?: string;
}

const useBulkEditSelected = <T extends SelectedBulkItem>({
  defaultSelected,
  initialSelected,
  key,
}: UseBulkEditSelectedProps<T> = {}): UseBulkEditSelectedResults<T> => {
  const {
    addSelectedItem,
    removeSelectedItem,
    reset,
    selectedItems: selected,
    setSelectedItems,
  } = useMultipleSelection<T>({
    defaultSelectedItems: defaultSelected,
    initialSelectedItems: initialSelected,
  });
  const { organization } = useUserContext();
  const showSuggestedTags = organization?.settings?.useSuggestedTags || false;

  useEffect(
    function updateSelectedOnDefaultSelectedChange() {
      if (defaultSelected && JSON.stringify(defaultSelected) !== JSON.stringify(selected)) {
        reset();
      }
    },
    [defaultSelected, reset, selected],
  );

  useEffect(
    function resetOnKeysChange() {
      if (key) {
        reset();
      }
    },
    [key, reset],
  );

  const selectedKeys = useMemo(() => selected?.map((i) => i.guid), [selected]);

  const selectedIds = useMemo(() => {
    return selected?.reduce((acc, item) => ({ ...acc, [item.guid]: true }), {});
  }, [selected]);

  const toggleAll = useCallback(
    (all?: T[], checked?: boolean) => {
      if (!all) return;
      if (checked) {
        setSelectedItems(uniqBy([...selected, ...all], 'guid'));
      } else {
        setSelectedItems(selected.filter((a) => !all.some((b) => b.guid === a.guid)));
      }
    },
    [selected, setSelectedItems],
  );

  const toggleItem = useCallback(
    (item: T, checked: boolean) => {
      if (checked) {
        addSelectedItem(item);
      } else {
        removeSelectedItem(item);
      }
    },
    [addSelectedItem, removeSelectedItem],
  );

  const setSelected = useCallback(
    (items: T[] | undefined) => {
      if (items) {
        setSelectedItems(items);
      } else {
        setSelectedItems([]);
      }
    },
    [setSelectedItems],
  );

  const tagsCounts = useMemo(() => {
    const counts: TagCounts = {};

    selected?.forEach((item) => {
      item?.taggedItems
        ?.filter((i) => (i.kind === 'suggestion' && showSuggestedTags) || i.kind === 'user-defined')
        ?.forEach((taggedItem) => {
          const tagGuid = taggedItem.tag.guid;
          counts[tagGuid] = counts[tagGuid] ? counts[tagGuid] + 1 : 1;
        });
    });

    return counts;
  }, [selected, showSuggestedTags]);

  return {
    reset,
    selected: {
      ids: selectedIds ?? {},
      items: selected ?? [],
      keys: selectedKeys ?? [],
    },
    setSelected,
    tagsCounts,
    toggleAll,
    toggleItem,
  };
};

export default useBulkEditSelected;
