import { useMemo } from 'react';

import { useFetchOrganizationUsers } from '@api/organizationUser';
import { useUserContext } from '@context/User';
import stripSpaces from '@utils/stripSpaces';

import { CurrentOwner } from './BulkEditOwnersModal.types';
import { formatDefaultOptions } from './BulkEditOwnersModal.utils';

interface UseDefaultOptionsProps {
  currentBusinessOwner?: CurrentOwner;
  currentTechnicalOwner?: CurrentOwner;
}

const TEAM_USERS_PARAMS = {
  is_active: true,
  order: 'role,name',
  page: 1,
  page_size: 5,
  query: stripSpaces(`{
    role,
    user{
      guid,
      avatar,
      first_name,
      last_name,
      is_active,
      team
    }
  }`),
};

const useDefaultOptions = ({
  currentBusinessOwner,
  currentTechnicalOwner,
}: UseDefaultOptionsProps) => {
  const { user } = useUserContext();

  const { data: organizationUsers, isLoading } = useFetchOrganizationUsers({
    enabled: Boolean(user?.team?.guid),
    params: {
      teams: user?.team?.guid,
      ...TEAM_USERS_PARAMS,
    },
  });

  const ownersConfig = useMemo(() => {
    const businessOwnerConfig = formatDefaultOptions({
      currentOwner: currentBusinessOwner,
      loggedUser: user,
      organizationUsers: organizationUsers?.results,
    });
    const technicalOwnerConfig = formatDefaultOptions({
      currentOwner: currentTechnicalOwner,
      loggedUser: user,
      organizationUsers: organizationUsers?.results,
    });

    return { businessOwnerConfig, technicalOwnerConfig };
  }, [currentBusinessOwner, user, organizationUsers?.results, currentTechnicalOwner]);

  return { isLoading, ...ownersConfig };
};

export default useDefaultOptions;
