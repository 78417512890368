import paginatedTransform from '@api/paginatedTransform';
import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import { PaginatedResponse } from '@models/PaginatedResponse';

import { BreadcrumbModel } from './BreadcrumbModel';

const breadcrumbsCacheKeys = {
  breadcrumbs: ['breadcrumbs'],
};

const breadcrumbsSelect = paginatedTransform(BreadcrumbModel);

export const useFetchBreadcrumbs = (
  options?: UseFetchOptions<PaginatedResponse<BreadcrumbModel>>,
) => {
  return useFetch<PaginatedResponse<BreadcrumbModel>>({
    ...options,
    queryKey: [...breadcrumbsCacheKeys.breadcrumbs, options?.params],
    url: `/breadcrumbs/`,
    select: breadcrumbsSelect,
  });
};
