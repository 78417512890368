import React from 'react';

import {
  useFetchSyncBackPullRequests,
  useFetchSyncBackRepository,
  useFetchSyncBackRepositoryNextExecution,
} from '@api/syncBack';
import Box from '@components/Box';
import DateTime from '@components/DateTime/DateTime';
import DbtDocsSyncTable from '@components/Table/DbtDocsSyncTable';
import { TabContentProps } from '@components/Tabs/types';
import Text from '@components/Text';
import theme from '@styles/theme';
import { Filter } from '@utils';
import { FilterOptions } from '@utils/filters';

export const PULL_REQUESTS_REQUEST_CONFIG: FilterOptions = {
  order: '-created_on',
  page: 1,
  page_size: 20,
};

const SORT_CONFIG: { [key: string]: string } = {
  createdOn: 'created_on',
};

interface DbtDocsSyncProps extends TabContentProps {
  dsGuid: string;
}

const DbtDocsSync = ({ dsGuid }: DbtDocsSyncProps) => {
  const { data: repository } = useFetchSyncBackRepository(dsGuid);
  const FilterService = Filter.useUpdateFilters(
    PULL_REQUESTS_REQUEST_CONFIG,
    {},
    SORT_CONFIG,
    '-created_on',
  );
  const { filter } = FilterService;

  const { data: pullRequests, isLoading } = useFetchSyncBackPullRequests({
    params: {
      ...Filter.setParams(filter),
      repository_guid: dsGuid,
    },
  });

  const { data: nextExecution } = useFetchSyncBackRepositoryNextExecution();

  const totalPages =
    pullRequests && filter.page_size ? Math.ceil(pullRequests.count / filter.page_size) : 1;

  return (
    <Box compDisplay="flex" flexDirection="column">
      <Box compDisplay="flex" justifyContent="flex-end">
        {repository && (
          <Text
            fontSize={theme.typography.fontSizes.body2}
            lineHeight={theme.typography.lineHeights.default}
          >
            Next submission at &nbsp;
            <DateTime datetime={nextExecution?.nextExecution} format="fullDateFormat" />
            &nbsp; UTC
          </Text>
        )}
      </Box>
      <Box compDisplay="flex" minWidth="100%">
        <DbtDocsSyncTable
          data={pullRequests?.results}
          filterService={FilterService}
          loading={isLoading}
          noRepository={!repository}
          totalPages={totalPages}
        />
      </Box>
    </Box>
  );
};

export default DbtDocsSync;
