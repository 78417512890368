import React, { useState } from 'react';

import type { CheckboxProps } from '@components/UI/Form/Checkbox';
import Checkbox from '@components/UI/Form/Checkbox';
import InputLabel from '@components/UI/Form/InputLabel';
import Icon from '@components/UI/Icon';

import { AccordionItemContent, AccordionItemTitle } from './StyledAccordion';

type ActionType = {
  payload: string;
  type: 'removeSelected' | 'addSelected' | 'removeIndeterminate';
};

type StateType = {
  indeterminate: Set<string>;
  selected: Set<string>;
};

const accordionItemReducer = (state: StateType, action: ActionType): StateType => {
  /*
   *Updates state based checkbox clicks.
   */
  const tempSelected = new Set(state.selected);
  const tempIndeterminate = new Set(state.indeterminate);
  switch (action.type) {
    case 'removeSelected':
      tempSelected.delete(action.payload);
      return { ...state, selected: tempSelected };
    case 'addSelected':
      tempSelected.add(action.payload);
      return { ...state, selected: tempSelected };
    case 'removeIndeterminate':
      tempIndeterminate.delete(action.payload);
      tempSelected.add(action.payload);
      return { ...state, indeterminate: tempIndeterminate, selected: tempSelected };
    default:
      return state;
  }
};

const getState = (accordionItems: AccordionItemInterface[]) => {
  const selected = new Set<string>();
  const indeterminate = new Set<string>();

  accordionItems.forEach((item) => {
    if (item.checked) {
      selected.add(item.id);
    }
    if (item.indeterminate) {
      indeterminate.add(item.id);
    }
  });

  return { indeterminate, selected };
};

export interface AccordionItemInterface {
  checked?: boolean;
  id: string;
  indeterminate?: boolean;
  value: React.ReactNode;
}

interface Props {
  accordionItems: AccordionItemInterface[];
  alignItemsStart?: boolean;
  checkboxVariantColor?: CheckboxProps['variantColor'];
  title: string | React.ReactElement;
  update: (item: string) => void;
}
const AccordionItem: React.FC<Props> = React.memo(
  ({ accordionItems, alignItemsStart, checkboxVariantColor = 'white', title, update }) => {
    const [active, setActive] = useState(true);
    const [state, dispatch] = React.useReducer(accordionItemReducer, getState(accordionItems));

    const handleStateUpdate = (id: string) => {
      /* On click */
      if (state.indeterminate.has(id)) {
        dispatch({
          payload: id,
          type: 'removeIndeterminate',
        });
      } else if (state.selected.has(id)) {
        dispatch({
          payload: id,
          type: 'removeSelected',
        });
      } else {
        dispatch({
          payload: id,
          type: 'addSelected',
        });
      }
    };

    return (
      <>
        <AccordionItemTitle active={active} onClick={() => setActive(!active)}>
          {title}
          <Icon ml={0.5} name={active ? 'caret-down' : 'caret-right'} size="10px" />
        </AccordionItemTitle>
        <AccordionItemContent active={active}>
          {accordionItems.map((item) => (
            <InputLabel
              key={item.id}
              alignItems={alignItemsStart ? 'flex-start' : 'center'}
              compDisplay="flex"
              fontSize="body1"
              py={0.5}
            >
              <Checkbox
                checked={state.selected.has(item.id) || state.indeterminate.has(item.id)}
                name={item.id}
                onChange={() => {
                  handleStateUpdate(item.id);
                  update(item.id);
                }}
                variantColor={checkboxVariantColor}
              />
              {item.value}
            </InputLabel>
          ))}
        </AccordionItemContent>
      </>
    );
  },
);

export default AccordionItem;
