import mapColumnLineageModel from '@api/lineage/ColumnLineageModel.v1';

import LineageExploreModel from './LineageExploreModel';
import mapTableLineageModel from './TableLineageModel.v1';

const mapLineageExploreModel = (data: any): LineageExploreModel => {
  return {
    columns: data?.column_lineage?.map(mapColumnLineageModel),
    tables: data?.table_lineage?.map(mapTableLineageModel),
  };
};

export default mapLineageExploreModel;
