import styled from '@emotion/styled';
import { RouterLink } from '@routing/router';

export const DataSourceLink = styled(RouterLink)`
  color: inherit;

  &:hover {
    color: inherit;
    text-decoration: underline;
  }
`;
