import React, { useState } from 'react';
import { Cell } from 'react-table';

import { ActivityModel } from '@api/activity/ActivityModel';
import DateTime from '@components/DateTime';
import ActivityCell from '@components/Table/Cells/ActivityCell';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import { FilterServiceInterface } from '@utils/filters';

const initialSortState = [
  {
    desc: true,
    id: 'date',
  },
];

interface AutomatedPiiHistoryTableProps {
  filterService: FilterServiceInterface;
  loading: boolean;
  rows?: ActivityModel[];
  totalPages?: number;
}

const AutomatedPiiHistoryTable: React.FC<AutomatedPiiHistoryTableProps> = ({
  filterService,
  loading,
  rows = [],
  totalPages,
}) => {
  const [isShowFilter, setShowFilter] = useState(false);

  const columns: ColumnConfig<ActivityModel>[] = [
    {
      Header: SearchHeader,
      disableFilters: true,
      disableResizing: true,
      disableSortBy: true,
      id: 'search',
      width: 32,
    },
    {
      Cell: (props: Cell<ActivityModel>) => {
        return <ActivityCell {...props} />;
      },
      Header: 'Event Description',
      accessor: (d) => d.message,
      disableHiding: true,
      disableSortBy: true,
      id: 'event',
      width: '195%',
    },
    {
      Cell: (props: Cell<ActivityModel>) => {
        const { row } = props;
        return <DateTime {...props} datetime={row.original.performedOn} format="fullDateFormat" />;
      },
      Header: 'Date',
      accessor: (d) => d.performedOn?.toDate(),
      disableFilters: true,
      disableHiding: true,
      id: 'date',
      width: '105%',
    },
  ];

  return (
    <TableStyled>
      <Table
        basic="very"
        changePage={filterService?.changePage}
        className="table-full"
        columns={columns}
        compact
        data={rows}
        disableColumnFiltering
        disableRowSelect
        initialState={{
          pageIndex: filterService ? filterService!.filter.page! - 1 : 0,
          sortBy: initialSortState,
        }}
        loading={loading}
        manualGlobalFilter
        manualPagination
        manualSortBy
        setGlobalFilter={filterService.globalSearch}
        setSortBy={filterService.sort}
        showFilter={isShowFilter}
        sortable
        stickyHeader
        toggleFilter={() => {
          setShowFilter((prev) => !prev);
        }}
        totalPages={totalPages}
        unstackable
      />
    </TableStyled>
  );
};

export default AutomatedPiiHistoryTable;
