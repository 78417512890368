import React from 'react';

import { DescriptionSource } from '@api/description/description.types';
import { exploresCacheKeys } from '@api/explores';
import { fieldsCacheKeys } from '@api/fields';
import invalidateCache from '@api/invalidateCache';
import { usePatchMetadataById } from '@api/metadata';
import { tableauCacheKeys } from '@api/tableau';
import { viewsCacheKeys } from '@api/views';
import Box from '@components/Box';
import type { RichTextDescriptionEditorProps } from '@components/RichTextDescriptionEditor';
import RichTextDescriptionEditor from '@components/RichTextDescriptionEditor';
import { RichTextEditorElement } from '@components/RichTextEditor/RichTextEditor.types';
import Icon from '@components/UI/Icon';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import fetchClient from '@lib/fetchClient';
import { ValidDSType } from '@models/DataSourceCredentials';
import { MetadataModel } from '@models/MetadataModel';

import { TypedCell } from '../types';

export const SIMPLIFIED_DESCRIPTION_ALLOWED_ELEMENTS: RichTextEditorElement[] = [
  'mention',
  'link',
  'image',
  'italic',
  'bold',
  'underline',
  'li',
  'ol',
  'ul',
  'blockquote',
  'table',
  'tbody',
  'td',
  'th',
  'thead',
  'tr',
  'codeblock',
];

export interface DescriptionCellProps extends Pick<RichTextDescriptionEditorProps, 'dataTypes'> {
  aiDescription?: string;
  allowedElements?: RichTextEditorElement[];
  dataSourceType?: ValidDSType;
  description?: string;
  descriptionSource?: DescriptionSource;
  guid: string;
  ingestedDescription?: string;
  isDataSourceEditable?: boolean;
  name?: string;
  parentGuid?: string;
  richtextDescription?: string;
  showDescriptionSelector?: boolean;
  showEditIcon?: boolean;
  suggestedDescription?: string;
  suggestedDescriptionSource?: string;
  suggestedDescriptionSourceObj?: MetadataModel;
  truncateDisabled?: boolean;
  userDescription?: string;
}

const DescriptionCell: React.FC<Partial<TypedCell> & DescriptionCellProps> = ({
  aiDescription,
  allowedElements = SIMPLIFIED_DESCRIPTION_ALLOWED_ELEMENTS,
  column,
  dataSourceType,
  dataTypes,
  description,
  descriptionSource,
  globalFilter,
  guid,
  ingestedDescription,
  isDataSourceEditable,
  name,
  parentGuid,
  richtextDescription,
  showDescriptionSelector = true,
  showEditIcon,
  suggestedDescription,
  suggestedDescriptionSource,
  suggestedDescriptionSourceObj,
  truncateDisabled = false,
  userDescription,
}) => {
  const { isSuccess, mutate } = usePatchMetadataById(guid, {
    onSuccess: () => {
      invalidateCache((keys) => [
        keys.columns.all,
        keys.biTables.all,
        keys.biNotebooks.all,
        keys.biColumns.all,
        keys.biDashboardElements.all,
        keys.dashboards.all,
        keys.tables.all,
        keys.tableau.all,
        keys.datasets.all,
        keys.queries.all,
        keys.dbt.tests,
        keys.lookML.views,
        keys.activity.all,
      ]);
      fetchClient.invalidateQueries(exploresCacheKeys.exploreFields(parentGuid ?? ''));
      fetchClient.invalidateQueries(fieldsCacheKeys.fields);
      fetchClient.invalidateQueries(tableauCacheKeys.tableauFields(parentGuid ?? ''));
      fetchClient.invalidateQueries(viewsCacheKeys.views);
      fetchClient.invalidateQueries(exploresCacheKeys.explores);
    },
  });
  const { isEditable: isObjectEditable } = useObjectPermissionsContext({ id: guid });
  const isEditable = isDataSourceEditable && isObjectEditable;

  const handleDescriptionSave = (
    richtextDesc?: string,
    plainTextDesc?: string,
    descSource?: DescriptionSource,
  ) => {
    mutate({
      description: plainTextDesc,
      description_source: descSource,
      richtext_description: richtextDesc,
    });
  };

  return (
    <Box alignItems="center" compDisplay="flex" gap={0.5}>
      {showEditIcon && <Icon color="gray.500" name="edit-outline" size="16px" />}
      <RichTextDescriptionEditor
        allowedElements={allowedElements}
        dataSourceType={dataSourceType}
        dataTypes={dataTypes}
        descriptions={{
          aiDescription,
          description,
          ingestedDescription,
          richTextDescription: richtextDescription,
          suggestedDescription,
          userDescription,
        }}
        descriptionSource={descriptionSource}
        guid={guid}
        isEditable={isEditable}
        isSuccess={isSuccess}
        onDescriptionSave={handleDescriptionSave}
        parentGuid={parentGuid}
        searchTermsToHighlight={globalFilter ?? column?.filterValue}
        showDescriptionSelector={showDescriptionSelector}
        suggestedDescriptionSource={suggestedDescriptionSource}
        suggestedDescriptionSourceObject={suggestedDescriptionSourceObj}
        toastTitle={`${name} description has been updated.`}
        truncateDisabled={truncateDisabled}
      />
    </Box>
  );
};

export default DescriptionCell;
