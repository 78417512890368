import styled from '@emotion/styled';

import type { StyledBoxProps } from '@components/Box';
import Box from '@components/Box';

export interface StyledCheckboxInputLabelProps extends StyledBoxProps {}

export const StyledCheckboxInputLabel = styled(Box)<StyledCheckboxInputLabelProps>`
  width: fit-content;
`;

StyledCheckboxInputLabel.defaultProps = {
  as: 'label',
  className: 'CheckboxLabel',
  noDefault: true,
};

export const StyledCheckboxInputField = styled.input`
  display: none;
`;
