import React, { useEffect, useState } from 'react';

import useLocalStorage from '@utils/useLocalStorage';

import { SidebarItemConfig, SidebarSection } from '../AppMainSidebar.types';

import {
  SIDEBAR_EXPANSION_TRANSITION_DURATION,
  StyledSecondarySidebar,
  StyledSecondarySidebarContainer,
  StyledSecondarySidebarContent,
  StyledSecondarySidebarTitle,
} from './SecondarySidebar.styles';

const SIDEBAR_DEFAULT_WIDTH = 264;

interface SecondarySidebarProps {
  activeMenuItemId?: string;
  isCollapsed?: boolean;
  isVisible: boolean;
  sections: SidebarSection[];
}

const SecondarySidebar = ({
  activeMenuItemId,
  isCollapsed = false,
  isVisible,
  sections,
}: SecondarySidebarProps) => {
  const items = sections.flatMap((section) => section.items);
  const [internalIsCollapsed, setInternalIsCollapsed] = useState<boolean>(true);
  const menuItemsById = items.reduce((acc, item) => {
    acc[item.id] = item;
    item.options?.forEach((option) => {
      acc[option.value] = { ...item, id: String(option.value) };
    });
    return acc;
  }, {} as Record<string, SidebarItemConfig>);
  const activeMenuItem = activeMenuItemId ? menuItemsById[activeMenuItemId] : undefined;
  const [sidebarWidth, setSidebarWidth] = useLocalStorage('sidebarWidth', SIDEBAR_DEFAULT_WIDTH);
  const { secondarySidebarTitleHeight = 'normal' } = activeMenuItem ?? {};

  const isSidebarVisible = isVisible && Boolean(activeMenuItem);
  const isSmallTitle = secondarySidebarTitleHeight === 'small';

  useEffect(() => {
    if (!isCollapsed) {
      setTimeout(() => {
        setInternalIsCollapsed(isCollapsed);
      }, SIDEBAR_EXPANSION_TRANSITION_DURATION);
    } else {
      setInternalIsCollapsed(isCollapsed);
    }
  }, [isCollapsed]);

  return (
    <StyledSecondarySidebar
      aria-expanded={isSidebarVisible && !isCollapsed}
      currentWidth={sidebarWidth}
      data-testid="secondary-sidebar"
      defaultSize={{
        height: '100%',
        width: sidebarWidth,
      }}
      enable={{ right: !isCollapsed }}
      isCollapsed={isCollapsed && internalIsCollapsed}
      isVisible={isSidebarVisible}
      maxWidth={400}
      minWidth={internalIsCollapsed ? 0 : 200}
      onResizeStop={(_e, _direction, ref) => {
        setSidebarWidth(ref.offsetWidth);
      }}
      style={{
        overflow: internalIsCollapsed ? 'hidden' : undefined,
        transition: internalIsCollapsed ? undefined : 'none',
      }}
    >
      {activeMenuItem && (
        <StyledSecondarySidebarContainer
          minWidth={isCollapsed || internalIsCollapsed ? `${sidebarWidth}px` : undefined}
        >
          <StyledSecondarySidebarTitle
            as="p"
            isSmallTitle={isSmallTitle}
            {...activeMenuItem.titleSpacing}
          >
            {activeMenuItem?.secondarySidebarLabel ?? activeMenuItem.label}
          </StyledSecondarySidebarTitle>
          <StyledSecondarySidebarContent>{activeMenuItem.children}</StyledSecondarySidebarContent>
        </StyledSecondarySidebarContainer>
      )}
    </StyledSecondarySidebar>
  );
};

export default SecondarySidebar;
