import React from 'react';
import type { Filter } from '@utils';

import { useFetchCustomAttributesWithValue } from '@api/customAttributesWithValue';
import { customAttributeValuesCacheKeys } from '@api/customAttributeValues';
import { EditCustomAttributeValueInline } from '@components/Forms/EditCustomAttributeValueForm';
import fetchClient from '@lib/fetchClient';

const fetchCustomAttributeValuesConfig: Filter.FilterOptions = {
  query: `{
    guid,
    type,
    name,
    value
  }`,
};

export interface PageColumnInfoCustomAttributeValuesProps {
  guid: string;
  isEditable?: boolean;
}

const PageColumnInfoCustomAttributeValues: React.FC<PageColumnInfoCustomAttributeValuesProps> = ({
  guid,
  isEditable,
}) => {
  const { data } = useFetchCustomAttributesWithValue(guid, {
    onSuccess: () => {
      fetchClient.invalidateQueries(customAttributeValuesCacheKeys.customAttributeValues);
    },
    params: {
      page: 1,
      page_size: 100,
      query: fetchCustomAttributeValuesConfig.query,
    },
  });

  return (
    <>
      {data?.results.map((customAttributeItem) => (
        <EditCustomAttributeValueInline
          key={customAttributeItem.guid}
          customAttribute={customAttributeItem}
          customAttributeValue={customAttributeItem.value}
          editIconVariant="always"
          isEditable={isEditable}
          itemGuid={guid}
        />
      ))}
    </>
  );
};

export default PageColumnInfoCustomAttributeValues;
