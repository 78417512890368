import type { LineageEdgeJob } from '@api/openapi.generated';

import mapJobModel from './JobModel.v1';

export type LineageEdgeJobResponse = Partial<LineageEdgeJob>;
const mapLineageEdgeJobModel = (value: LineageEdgeJobResponse) => {
  return mapJobModel({
    data_types: {
      data_source_type: value?.data_source_type,
      data_type: '',
      object_type: 'job',
    },
    guid: value?.job_guid,
    last_run_duration: value?.last_run_duration,
    last_run_on: value?.last_run_on,
    last_run_status: value?.last_run_status,
    name: value?.job_name,
  });
};

export default mapLineageEdgeJobModel;

export type LineageEdgeJobModel = ReturnType<typeof mapLineageEdgeJobModel>;
