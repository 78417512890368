import { useMemo } from 'react';

import { useFetchTeams } from '@api/teams';
import { TeamModel } from '@api/teams/TeamModel';
import { mapOwnerToOption } from '@components/OwnerSelect/utils';

const useNewTeamsOptions = (currentTeam: TeamModel) => {
  const { data: teams, isLoading } = useFetchTeams();

  const options = useMemo(() => {
    if (!teams) return [];

    const teamsExpectCurrent = teams.results.filter((team) => team.guid !== currentTeam.guid);

    return teamsExpectCurrent.map((team) => mapOwnerToOption(team));
  }, [teams, currentTeam]);

  return { isLoading, options };
};

export default useNewTeamsOptions;
