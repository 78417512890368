import { Element } from 'slate';

import parseJSON from '@utils/parseJSON';

import convertMarkdownStringToHtml from './convertMarkdownStringToHtml';
import { DEFAULT_SLATE_STATE, deserializeToSlate } from './serializationHelpers';

const isSlateState = (state: object) => {
  return Boolean(Element.isElementList(state));
};

/**
 * Converts a string to slate state.
 * If there is no string returns the default slate state.
 * Will convert plain text, JSON, slate state, or markdown.
 */
const convertStringToSlate = (str?: string, shouldParseTextToSlate: boolean = true) => {
  if (!str) {
    return DEFAULT_SLATE_STATE;
  }

  /**
   * Sometimes we don't want to convert the text to slate state.
   * For example, we don't have control over the value of the ingested descriptions,
   * so it might have a value that breaks slatejs.
   */
  if (!shouldParseTextToSlate) {
    return [{ children: [{ text: str }], type: 'paragraph' }];
  }

  const state = parseJSON(str);

  if (state && isSlateState(state)) {
    return state;
  }

  return deserializeToSlate(convertMarkdownStringToHtml(str));
};

export default convertStringToSlate;
