import styled from '@emotion/styled';

import Box from '@components/Box';

const DecoratedText = styled(Box)`
  display: flex;
  flex-direction: row;
  color: #d8dbe0;

  &:before,
  &:after {
    background-color: #e2e9f0;
    content: '';
    flex: 1 1;
    border-bottom: 1px solid #e2e9f0;
    margin: auto;
  }

  &:before {
    margin-right: ${({ theme }) => theme.space(1)};
  }

  &:after {
    margin-left: ${({ theme }) => theme.space(1)};
  }
`;
export default DecoratedText;
