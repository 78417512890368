import styled from '@emotion/styled';

import Box from '@components/Box';
import Text from '@components/Text';

export const SUB_COLLECTION_ICON_COLORS = {
  collection: '#F8F1DB',
  collectionBorder: '#FDF6E7',
  collectionFolderIcon: '#412800',
};

export const StyledSubCollectionCard = styled(Box)`
  height: ${({ theme }) => theme.space(9)};
  background-color: ${({ theme }) => theme.colors.white};
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.v1.gray[50]};
  }
`;

StyledSubCollectionCard.defaultProps = {
  alignItems: 'center',
  border: '1px solid',
  borderColor: 'gray.400',
  borderRadius: 'lg',
  compDisplay: 'flex',
  compWidth: '100%',
  cursor: 'pointer',
  gap: 1,
  px: 2.5,
};

export const StyledSubCollectionCardIconContainer = styled(Box)`
  background-color: ${SUB_COLLECTION_ICON_COLORS.collection};
  border: 1px solid;
  border-color: ${SUB_COLLECTION_ICON_COLORS.collectionBorder};
  height: ${({ theme }) => theme.space(6)};
  width: ${({ theme }) => theme.space(6)};
  min-height: ${({ theme }) => theme.space(6)};
  min-width: ${({ theme }) => theme.space(6)};
`;

StyledSubCollectionCardIconContainer.defaultProps = {
  alignItems: 'center',
  borderRadius: '50%',
  compDisplay: 'flex',
  justifyContent: 'center',
};

const SUB_COLLECTION_CARD_DESCRIPTION_LINE_HEIGHT = 22;
const SUB_COLLECTION_CARD_DESCRIPTION_LINES_COUNT = 2;
const SUB_COLLECTION_CARD_DESCRIPTION_MAX_HEIGHT =
  SUB_COLLECTION_CARD_DESCRIPTION_LINE_HEIGHT * SUB_COLLECTION_CARD_DESCRIPTION_LINES_COUNT;

export const StyledSubCollectionCardTitle = styled(Text)`
  max-height: ${SUB_COLLECTION_CARD_DESCRIPTION_MAX_HEIGHT}px;
  -webkit-line-clamp: ${SUB_COLLECTION_CARD_DESCRIPTION_LINES_COUNT};
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
`;

StyledSubCollectionCardTitle.defaultProps = {
  color: 'gray.700',
  fontSize: 'h3',
  fontWeight: 'medium',
};
