import React from 'react';
import { Cell } from 'react-table';

import { TagModel } from '@api/tags/TagModel';
import ExcerptText from '@components/ExcerptText';
import type { ColumnConfig } from '@components/Table/Table/types';

import LinkedCell from '../Cells/LinkedCell';
import Table from '../Table';
import TableStyled from '../TableStyled';

interface OwnedTagsTableProps {
  data?: TagModel[];
  itemCount?: number;
  loading?: boolean;
  stickyHeader?: boolean;
}

const OwnedTagsTable: React.FC<OwnedTagsTableProps> = ({
  data,
  itemCount = 0,
  loading,
  stickyHeader,
}) => {
  const columns: ColumnConfig<TagModel>[] = React.useMemo(
    () => [
      {
        Cell: (props: Cell<TagModel>) => {
          const { row } = props;
          const item = row.original;
          return <LinkedCell {...props} item={item} showIcon showNameTooltip />;
        },
        Header: `Name (${itemCount})`,
        accessor: (t) => t?.name,
        disableFilters: true,
        disableHiding: true,
        id: 'dataObject',
        width: '140%',
      },
      {
        Cell: ExcerptText,
        Header: 'Description',
        accessor: (t) => t?.description,
        id: 'description',
        width: '160%',
      },
    ],
    [itemCount],
  );

  return (
    <TableStyled>
      <Table
        basic="very"
        className="table-full"
        columns={columns}
        data={data || []}
        disableColumnFiltering
        disablePagination
        disableRowSelect
        loading={loading}
        singleLine
        sortable
        stickyHeader={stickyHeader}
        unstackable
      />
    </TableStyled>
  );
};

export default OwnedTagsTable;
