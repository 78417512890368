import type { CacheKeyType } from '@api/allCacheKeys';
import allCacheKeys from '@api/allCacheKeys';
import fetchClient from '@lib/fetchClient';

const invalidateCache = (cb: (args: CacheKeyType) => (string | undefined)[][]) => {
  const cacheKeys = cb(allCacheKeys);

  cacheKeys.forEach((key) => {
    fetchClient.invalidateQueries(key);
  });
};

export default invalidateCache;
