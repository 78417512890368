import { atom } from 'recoil';

/**
 * A simple selector to get and set the current data type.
 * Can be any metadatatype we have.
 *
 *  These are mapped to how the BE expects them to ensure we send the
 *  right value to the BE
 */
export type MetadataObjectType =
  | 'documents'
  | 'tables'
  | 'dashboards'
  | 'dashboardelements'
  | 'reports'
  | 'reportQueries'
  | 'reportquery'
  | 'explores'
  | 'lookmlviews'
  | 'columns'
  | 'tableauViews'
  | 'tableauDataSources'
  | 'tableauFields'
  | 'workbooks'
  | 'metrics'
  | 'lookMLFields'
  | 'power_bi_dashboards'
  | 'power_bi_reports'
  | 'power_bi_elements'
  | 'tags'
  | 'dbttest'
  | 'thoughtspot_answers'
  | 'thoughtspot_liveboards'
  | 'thoughtspot_tables'
  | 'thoughtspot_views'
  | 'thoughtspot_worksheets'
  | 'looks'
  | 'terms';

export const metadataType = atom<MetadataObjectType>({
  default: 'tables',
  key: 'metadataType',
});

export interface MetadataOption {
  key: string;
  text: string;
  value: MetadataObjectType | null;
}
