import React from 'react';

import {
  COLORS,
  ColorState,
  EDGE_MARKER_COLOR_ID,
  EdgeType,
} from '../ExploreGraph/LineageExploreGraph.colors';

const BaseArrowMarker = ({ color, id }: { color: string; id: string }) => (
  <marker
    className="react-flow__arrowhead"
    id={id}
    markerHeight="18"
    markerUnits="strokeWidth"
    markerWidth="18"
    orient="auto-start-reverse"
    refX="0"
    refY="0"
    viewBox="-10 -10 20 20"
  >
    <polyline
      fill="none"
      points="-5,-4 0,0 -5,4"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{ stroke: color, strokeWidth: 1 }}
    />
  </marker>
);

/*
 * We need to add markers with any custom arrowhead to the
 * SVG element that ReactFlow uses to render the edge markers.
 */
const ArrowHeads: React.FC = () => {
  const edgesTypes = Object.keys(COLORS.edge) as EdgeType[];

  return (
    <svg data-testid-id="arrow-heads-svg" style={{ left: '-100%', position: 'absolute', top: 0 }}>
      <defs>
        {edgesTypes.map((edgeType) => {
          const config = Object.keys(COLORS.edge[edgeType]) as ColorState[];

          return config.map((state) => {
            return (
              <BaseArrowMarker
                key={`${edgeType}-${state}`}
                color={COLORS.edge[edgeType][state]}
                id={EDGE_MARKER_COLOR_ID[edgeType][state]}
              />
            );
          });
        })}
      </defs>
    </svg>
  );
};

export default ArrowHeads;
