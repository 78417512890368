import React from 'react';
import { useHistory } from '@routing/router';

import Box from '@components/Box';
import Avatar from '@components/UI/Avatar/Avatar';
import type { Option } from '@components/UI/Select';
import Select, { SelectValue } from '@components/UI/Select';
import { useUserContext } from '@context/User';
import { isMasked } from '@pages/MaskPage/MaskPage.utils';
import theme from '@styles/theme';

import { StyledProfileMenu } from './ProfileMenu.styles';

export const ariaLabel = 'Profile menu';
export const defaultProfileMenu: Option[] = [
  {
    icon: 'account',
    iconColor: 'currentColor',
    text: 'My Profile',
    value: '/profile',
  },
  {
    icon: 'logout',
    iconColor: 'currentColor',
    text: 'Sign Out',
    value: '/logout',
  },
];

interface ProfileMenuProps {
  config?: Option[];
  placement?: 'right-end' | 'bottom-end';
  showName?: boolean;
}

const ProfileMenu: React.FC<ProfileMenuProps> = ({
  config = defaultProfileMenu,
  placement = 'right-end',
  showName,
}) => {
  const { user } = useUserContext();
  const history = useHistory();
  const isUnmaskOptionAdded = config.find((item) => item.value === '/unmask');

  if (isMasked() && !isUnmaskOptionAdded) {
    defaultProfileMenu.push({
      icon: 'unmask',
      text: 'Unmask',
      value: '/unmask',
    });
  }

  const handleOnChange = (options: SelectValue) => {
    const option = options?.[0];
    const pathname = option?.value as string;
    const { externalLink } = option?.props ?? {};

    if (externalLink) {
      window.open(pathname, '_blank', 'noopener,noreferrer');
      return;
    }

    if (pathname) {
      history.push(pathname);
    }
  };

  return (
    <Select
      isDropdown
      onChange={handleOnChange}
      options={config}
      optionsFitAnchorWidth={false}
      pl={0.5}
      popperConfigProps={{
        customPopperStyles: {
          borderRadius: theme.radius.lg,
          boxShadow: theme.shadow['4xl'],
          marginLeft: '14px',
          width: '200px',
        },
        placement,
      }}
      renderCustomAnchor={({ anchorProps, isOpen }) => (
        <StyledProfileMenu
          as="button"
          isActive={isOpen}
          tabIndex={-1}
          {...anchorProps}
          aria-label={ariaLabel}
        >
          <Avatar {...user?.mappedAvatar} className="avatar" size="24px" />
          <Box whiteSpace="break-spaces">{showName && user?.fullName}</Box>
        </StyledProfileMenu>
      )}
    />
  );
};

export default ProfileMenu;
