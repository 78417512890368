import {
  DeleteCollectionsDestroyPathParams,
  GetCollectionsListQueryParams,
  GetCollectionsRetrievePathParams,
  PatchCollectionsPartialUpdateBodyParams,
  PostCollectionsCreateBodyParams,
} from '@api/openapi.generated';
import rawTransform from '@api/rawTransform';
import { paginatedSelect, rawSelect } from '@api/utils';
import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import useMutation, { UseMutationOptions } from '@hooks/useMutation';
import { HierarchyData, HierarchyModel } from '@models/HierarchyModel';
import { PaginatedResponse } from '@models/PaginatedResponse';
import { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';

import * as cacheKeys from './cacheKeys';
import mapCollectionModel, { CollectionModel } from './CollectionModel';

export const collectionsHierarchySelect = rawTransform(HierarchyModel);
export const useFetchCollectionsHierarchy = (options?: UseFetchOptions<HierarchyData[]>) => {
  return useFetch<HierarchyData[]>({
    ...options,
    queryKey: [...cacheKeys.hierarchy],
    select: collectionsHierarchySelect,
    url: '/collections/hierarchy/',
  });
};
const fetchCollectionSelect = rawSelect(mapCollectionModel);
export const useFetchCollection = (
  id: string,
  options?: UseFetchOptions<CollectionModel, GetCollectionsRetrievePathParams>,
) => {
  return useFetch<CollectionModel>({
    ...options,
    queryKey: [...cacheKeys.collection(id), options?.params],
    select: fetchCollectionSelect,
    url: `/collections/${id}/`,
  });
};

export const fetchCollectionsSelect = paginatedSelect(mapCollectionModel);
export const useFetchCollections = (
  options?: UseFetchOptions<PaginatedResponse<CollectionModel>, GetCollectionsListQueryParams>,
) => {
  return useFetch<PaginatedResponse<CollectionModel>>({
    ...options,
    queryKey: [...cacheKeys.all, options?.params],
    select: fetchCollectionsSelect,
    url: '/collections/',
  });
};

export const usePatchCollection = (
  id?: string,
  options?: UseMutationOptions<
    CollectionModel,
    EnhancedErrorResult,
    PatchCollectionsPartialUpdateBodyParams
  >,
) => {
  return useMutation<CollectionModel, EnhancedErrorResult, PatchCollectionsPartialUpdateBodyParams>(
    {
      ...options,
      method: 'PATCH',
      url: id ? `/collections/${id}/` : undefined,
    },
  );
};

type UsePostMutateOptions = UseMutationOptions<
  CollectionModel,
  EnhancedErrorResult,
  PostCollectionsCreateBodyParams
>;

export const usePostCollection = (options?: UsePostMutateOptions) => {
  return useMutation({
    ...options,
    method: 'POST',
    onSuccess: (data, variables, context) => {
      const transformedData = fetchCollectionSelect(data);
      options?.onSuccess?.(transformedData, variables, context);
    },
    url: `/collections/`,
  });
};

export const useDeleteCollection = (
  id: string,
  options?: UseMutationOptions<DeleteCollectionsDestroyPathParams>,
) => {
  return useMutation({
    ...options,
    method: 'DELETE',
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
    },
    url: `/collections/${id}/`,
  });
};

// TODO: add types using openapi [sc-101183]
export const usePatchCollectionsItems = (options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    method: 'PATCH',
    url: '/collections/items/',
  });
};
