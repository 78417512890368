import React from 'react';
import styled from '@emotion/styled';

import { colors } from './colors';

const StyledExploreTreeSvg = styled.svg`
  *:focus {
    outline: none;
  }

  text {
    color: #2c2e36;
    font-size: 13px;
    font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  }

  .column {
    cursor: pointer;

    rect {
      fill: transparent;
    }

    .pin,
    .pinActive {
      display: none;
    }
  }

  .column-row {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0 3px;

    .column-name {
      align-items: center;
      display: inline-flex;
      flex-grow: 1;
      font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
      font-size: ${({ theme }) => theme.typography.fontSizes.body1};
      font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
      gap: ${({ theme }) => theme.space(0.5)};
      padding-right: ${({ theme }) => theme.space(1)};
      line-height: 1;
    }

    .iconGroup {
      width: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      background-color: transparent;
      stroke: none;
      outline: none;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }

  .column:not(.pinned):hover {
    .pin {
      display: block;
    }
  }

  .column.pinned {
    .pinActive {
      display: block;
    }
  }

  .column.hovered {
    .column-row {
      background-color: ${colors.hoveredColumnFill};
    }
  }

  .column.pinned {
    .column-row {
      background-color: ${colors.pinnedColumnFill};
    }
  }

  .column.pinned.userPinned {
    .colum-row {
      background-color: ${colors.userPinnedColumnFill};
    }
  }

  .onlyTargeted {
    display: none;
  }

  .columnsToggle:hover {
    rect {
      fill: #e2e9f0;
    }

    text {
      text-decoration: underline;
    }
  }

  .startingTableLink {
    fill: none;
    stroke: ${colors.tableStroke};
    stroke-width: 1.5px;
  }

  .manualLineageTableLink {
    fill: none;
    stroke: ${colors.manualLineageColor};
    stroke-width: 1.5px;
  }

  .pinnedTableLink {
    fill: none;
    stroke: ${colors.tableLinkColor};
    stroke-width: 1.5px;

    &.manual {
      opacity: 0;
    }
  }

  .hoveredTableLink {
    fill: none;
    stroke: ${colors.tableLinkHoverColor};
    stroke-width: 1.5px;

    &.manual {
      opacity: 0;
    }
  }

  .toBeRemovedTableLink {
    fill: none;
    stroke: ${colors.removeArrowColor};
    stroke-width: 1.5px;
  }

  .hoveredColumnLink {
    fill: none;
    stroke: ${colors.hoveredColumnArrowColor};
    stroke-width: 1.5px;

    &.manual {
      stroke: ${colors.manualLineageColor};
    }
  }

  .pinnedColumnLink {
    fill: none;
    stroke: ${colors.pinnedColumnArrowColor};
    stroke-width: 1.5px;

    &.manual {
      stroke: ${colors.manualLineageColor};
    }
  }

  .arrowCircle {
    stroke-width: 1.5px;
    stroke: ${colors.columnArrowCircleStroke};
    fill: ${colors.columnArrowCircleFill};
  }

  #startingTableLinkArrow {
    stroke: ${colors.tableStroke};
    fill: ${colors.tableStroke};
    overflow: visible;
  }

  #manualLineageTableLinkArrow {
    stroke: ${colors.manualLineageColor};
    fill: ${colors.manualLineageColor};
    overflow: visible;
  }

  #pinnedTableLinkArrow {
    stroke: ${colors.tableLinkColor};
    fill: ${colors.tableLinkColor};
    overflow: visible;
  }

  #hoveredTableLinkArrow {
    stroke: ${colors.tableLinkHoverColor};
    fill: ${colors.tableLinkHoverColor};
    overflow: visible;
  }

  #toBeRemovedTableLinkArrow {
    stroke: ${colors.removeArrowColor};
    fill: ${colors.removeArrowColor};
    overflow: visible;
  }

  #pinnedColumnLinkArrow {
    stroke: ${colors.pinnedColumnArrowColor};
    fill: ${colors.pinnedColumnArrowColor};
    overflow: visible;
  }

  #hoveredColumnLinkArrow {
    stroke: ${colors.hoveredColumnArrowColor};
    fill: ${colors.hoveredColumnArrowColor};
    overflow: visible;
  }

  #manualpinnedColumnLinkArrow,
  #manualhoveredColumnLinkArrow {
    stroke: ${colors.manualLineageColor};
    fill: ${colors.manualLineageColor};
  }

  #circle {
    stroke: ${colors.columnArrowCircleStroke};
    fill: ${colors.columnArrowCircleFill};
  }

  .ellipses {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: auto;
  }
`;

export default React.memo(StyledExploreTreeSvg);
