import React, { useState } from 'react';

import { useFetchBiconnections } from '@api/biconnections';
import { BiConnectionModel } from '@api/biconnections/BiConnectionModel';
import { useFetchDataSources } from '@api/dataSources';
import TabError from '@components/TabContent/TabError';
import BIConnectionsSetupTable from '@components/Table/BIConnectionsSetupTable';
import { TabContentProps } from '@components/Tabs/types';
import { DataSourceModel } from '@models/DataSourceModel';
import { Filter } from '@utils';

const SEARCH_CONFIG: { [key: string]: keyof Filter.FilterOptions } = {
  name: 'search_name',
};

const SORT_CONFIG: { [key: string]: string } = {
  name: 'name',
};

const DS_USERS_CONFIG_MODE: Filter.FilterOptions = {
  page: 1,
  page_size: 20,
};

interface BIConnectionsProps extends TabContentProps {
  dsGuid: string;
}

const BIConnections: React.FC<BIConnectionsProps> = ({ dsGuid }) => {
  const [selectedRowIds, setSelectedRowIds] = useState({});

  const FilterService = Filter.useUpdateFilters(
    DS_USERS_CONFIG_MODE,
    SEARCH_CONFIG,
    SORT_CONFIG,
    'name',
  );

  const { filter } = FilterService;

  const { data: connectionsResponse, error } = useFetchBiconnections(dsGuid, {
    params: {
      ...Filter.setParams(filter),
      datasources: dsGuid,
    },
  });

  const totalPages =
    connectionsResponse && filter.page_size
      ? Math.ceil(connectionsResponse.count / filter.page_size)
      : 1;

  const connections: BiConnectionModel[] | undefined = connectionsResponse?.results;

  const { data: dataSourcesResponse, isLoading: isLoadingDataSources } = useFetchDataSources();
  const dataSources: DataSourceModel[] | undefined = dataSourcesResponse?.results;

  if (error) return <TabError />;

  return (
    <BIConnectionsSetupTable
      data={connections}
      dataSources={dataSources}
      filterService={FilterService}
      itemsCount={connectionsResponse?.count}
      loadingDS={isLoadingDataSources}
      selectedRowIds={selectedRowIds}
      setSelectedRowIds={setSelectedRowIds}
      totalPages={totalPages}
    />
  );
};

export default React.memo(BIConnections);
