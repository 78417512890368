import ColumnLineageModel from '@api/lineage/ColumnLineageModel';

import type { FindRelatedColumnsArgs } from './findRelatedColumns';
import findRelatedColumns from './findRelatedColumns';

type FindAllRelatedColumnsArgs = Omit<FindRelatedColumnsArgs, 'columnId'> & { columnIds: string[] };

const findAllRelatedColumns = ({
  columnIds,
  ...other
}: FindAllRelatedColumnsArgs): ColumnLineageModel[] => {
  let columns: ColumnLineageModel[] = [];

  for (let i = 0; i < columnIds.length; i += 1) {
    const columnId = columnIds[i];

    if (columnId) {
      columns = [...columns, ...findRelatedColumns({ columnId, ...other })];
    }
  }

  return columns.filter(Boolean);
};

export default findAllRelatedColumns;
