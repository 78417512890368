export const ExtensionStorage = {
  AccessToken: 'Select-Star-Access-Token',
  AccessTokenTime: 'Select-Star-Access-Token-Time',
  RefreshToken: 'Select-Star-Refresh-Token',
  ShouldSignIn: 'Select-Star-Sign-In',
  defaultOrganization: 'defaultOrganization',
  organizations: 'organizations',
  isStaff: 'isStaff',
  isSuperUser: 'isSuperUser',
  userEmail: 'userEmail',
  isMasked: 'isMasked',
};
