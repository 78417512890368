import styled from '@emotion/styled';

import Box from '@components/Box';
import Button from '@components/Button/Button';
import rootTheme from '@styles/theme';

import { SIZES } from '../config';
import { NODE_OPACITY } from '../TableNode/TableNode.styles';

interface StyledShowMoreNodeProps {
  isUnfocused?: boolean;
}

export const StyledShowMoreNode = styled(Button)<StyledShowMoreNodeProps>`
  background-color: ${({ theme }) => theme.colors.white};
  transition: all 300ms linear;
  opacity: ${({ isUnfocused }) => (isUnfocused ? NODE_OPACITY.unfocused : NODE_OPACITY.default)};

  &:hover {
    background-color: ${({ theme }) => theme.colors.v1.primary[50]};
  }
`;

StyledShowMoreNode.defaultProps = {
  borderRadius: 'default',
  borderWidth: '1px',
  compHeight: `${SIZES.height.showMoreButton}px`,
  compWidth: '100%',
  fontSize: 'sm',
  gap: 1,
};

export const StyledShowMoreNodeCount = styled(Box)``;

StyledShowMoreNodeCount.defaultProps = {
  alignItems: 'center',
  backgroundColor: 'gray.50',
  borderColor: 'gray.200',
  borderRadius: 'md',
  borderWidth: '0.5px',
  color: 'gray.700',
  compDisplay: 'flex',
  compHeight: rootTheme.space(2.25),
  fontSize: 'sm',
  px: 0.5,
};
