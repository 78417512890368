import React, { useCallback, useMemo, useState } from 'react';

import { searchCacheKeys, useFetchSearch } from '@api/search';
import { SearchModel } from '@api/search/SearchModel';
import fetchClient from '@lib/fetchClient';

import { FetchedMentionsContext } from './FetchedMentionsContext';

const FetchedMentionsProvider: React.FC = ({ children }) => {
  const [ids, setIds] = useState<Set<string>>(new Set());
  const [accessDeniedGuids, setAccessDeniedGuids] = useState<Set<string>>(new Set([]));

  const { isError, isFetching } = useFetchSearch({
    enabled: ids.size > 0,
    onSuccess: (d) => {
      d?.data?.forEach((item) => {
        fetchClient.setQueryData(searchCacheKeys.searchItem(item.guid), item);
      });

      if (d?.accessDeniedGuids?.length > 0) {
        setAccessDeniedGuids((prev) => new Set([...Array.from(prev), ...d.accessDeniedGuids]));
      }

      setIds(new Set());
    },
    params: { guids: Array.from(ids).join(',') },
  });

  const getMentionFromCacheById = useCallback((id: string) => {
    return fetchClient.getQueryCache().find(searchCacheKeys.searchItem(id))?.state
      ?.data as SearchModel;
  }, []);

  const searchMentionById = useCallback(
    (id: string) => {
      if (!ids.has(id) && !getMentionFromCacheById(id)) {
        setIds((prev) => {
          return new Set(prev.add(id));
        });
      }
    },
    [getMentionFromCacheById, ids],
  );

  const getIsFetching = useCallback(
    (id) => {
      return isFetching && ids.has(id);
    },
    [ids, isFetching],
  );

  const checkDeniedAccess = useCallback(
    (id) => {
      return accessDeniedGuids.has(id);
    },
    [accessDeniedGuids],
  );

  const context = useMemo(() => {
    return {
      checkDeniedAccess,
      getIsFetching,
      getMentionFromCacheById,
      isError,
      searchMentionById,
    };
  }, [searchMentionById, getIsFetching, getMentionFromCacheById, isError, checkDeniedAccess]);

  return (
    <FetchedMentionsContext.Provider value={context}>{children}</FetchedMentionsContext.Provider>
  );
};

export default FetchedMentionsProvider;
