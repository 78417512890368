import { useMemo } from 'react';

import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import { useUserContext } from '@context/User';
import { DataSourceData } from '@models/DataSourceModel';
import { HierarchyData } from '@models/HierarchyModel';

type Data = HierarchyData | DataSourceData;

interface UseEditableDataSourcesProps<T> {
  data: Array<T & Data>;
}

interface UseEditableDataSources<T> {
  dataSources: Array<T>;
  isLoading: boolean;
}

const useEditableDataSources = <T>({
  data,
}: UseEditableDataSourcesProps<T>): UseEditableDataSources<T> => {
  const { isOrgAdmin } = useUserContext();
  const { isFetching: isLoading, permissions } = useObjectPermissionsContext({
    ids: data.map((item) => item.guid),
  });

  const allowedDataSources = useMemo(() => {
    if (isOrgAdmin) return data;
    const adminPermissionItems = Object.entries(permissions)
      .filter(([, value]) => value.role === 'admin')
      .map(([guid]) => guid);

    return data.filter((ds) => adminPermissionItems.includes(ds.guid));
  }, [data, isOrgAdmin, permissions]);

  return {
    dataSources: allowedDataSources,
    isLoading,
  };
};

export default useEditableDataSources;
