export const getDebugSimplifiedKeyLabel = (key: string | undefined) => {
  if (!key) {
    return 'undefined';
  }

  const splitKey = key?.split('/');
  return `${splitKey?.[0].substring(0, 6)}...${splitKey?.[0].substring(
    (splitKey?.[0] ?? '').length - 3,
  )}/${splitKey?.[1].substring(0, 6)}...${splitKey?.[1].substring(
    (splitKey?.[1] ?? '').length - 3,
  )}`;
};
