import React from 'react';
import copy from 'copy-to-clipboard';

import IconButton from '@components/IconButton';
import { renderInfoToast } from '@components/Toast';
import Tooltip from '@components/Tooltip';
import { useMetadataObjectContext } from '@context/MetadataObject';
import { isBIType } from '@models/DataSourceCredentials';
import { GetColorProps } from '@styles/mixins/getColor';

interface CopyFullPathButtonProps {
  className?: string;
  hoverBackgroundColor?: GetColorProps;
  hoverColor?: GetColorProps;
  iconColor?: GetColorProps;
  iconSize?: string;
  text: string;
  tooltipText?: string;
}

export const onCopyClick = (textToCopy: string) => {
  copy(textToCopy);
  renderInfoToast('Full name copied to clipboard');
};

const CopyFullPathButton: React.FC<CopyFullPathButtonProps> = ({
  className,
  hoverBackgroundColor,
  hoverColor,
  iconColor = 'gray.400',
  iconSize = '16px',
  text,
  tooltipText = 'Copy',
}) => {
  const { dataSourceType } = useMetadataObjectContext();
  const textToCopy =
    dataSourceType && isBIType(dataSourceType) ? text.split('.').join(' / ') : text;

  return (
    <Tooltip content={tooltipText}>
      <IconButton
        className={className}
        hoverBackgroundColor={hoverBackgroundColor}
        hoverColor={hoverColor}
        iconColor={iconColor}
        iconName="copy"
        iconSize={iconSize}
        onClick={(e) => {
          /**
           * stop propagation is important here,
           * otherwise, this would open the
           * column page.
           */
          e.stopPropagation();
          onCopyClick(textToCopy);
        }}
        role="button"
      />
    </Tooltip>
  );
};

export default CopyFullPathButton;
