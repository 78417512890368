import React, { useCallback, useEffect } from 'react';

import Portal from '@components/Portal';

import type { StyledModalCardProps, StyledModalOverlayProps } from './Modal.styles';
import { StyledModal, StyledModalCard, StyledModalOverlay } from './Modal.styles';

export interface ModalProps extends StyledModalCardProps {
  closeOnDimmerClick?: boolean;
  closeOnEscape?: boolean;
  id?: string;
  onClose?: () => void;
  renderContent?: (value: { modalHandleClose: () => void }) => void;
  transparentOverlay?: StyledModalOverlayProps['transparent'];
}

const Modal: React.FC<ModalProps> = ({
  children,
  closeOnDimmerClick = true,
  closeOnEscape = true,
  onClose,
  renderContent,
  transparentOverlay,
  ...other
}) => {
  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && closeOnEscape) handleClose();
    };

    document.addEventListener('keydown', handleEscKey, false);

    return () => {
      document.removeEventListener('keydown', handleEscKey, false);
    };
  }, [closeOnEscape, handleClose]);

  const component = (
    <StyledModal>
      <StyledModalOverlay
        onClick={() => {
          if (closeOnDimmerClick && onClose) handleClose();
        }}
        role="none"
        transparent={transparentOverlay}
      />
      <StyledModalCard borderRadius="lg" {...other}>
        {renderContent?.({ modalHandleClose: handleClose })}
        {children}
      </StyledModalCard>
    </StyledModal>
  );

  return <Portal>{component}</Portal>;
};

export default Modal;
