import React from 'react';

import Box from '@components/Box';
import NotFoundError from '@components/Error/NotFoundError';
import MetadataDecorator from '@utils/MetadataDecorator';

const NotFoundPage: React.FC = () => (
  <Box
    alignItems="center"
    compDisplay="flex"
    compHeight="100%"
    compWidth="100%"
    flex={1}
    justifyContent="center"
  >
    <MetadataDecorator title="404 Not found" />
    <NotFoundError />
  </Box>
);

export default NotFoundPage;
