import React, { createContext, useContext } from 'react';

import { TagModel } from '@api/tags/TagModel';

export type TagList = Record<'status' | 'category' | 'dbt', Record<string, TagModel>>;

export interface TagsArray {
  category: TagModel[];
  dbt: TagModel[];
  status: TagModel[];
}

export interface TagContextProps {
  findTag: (id: string) => TagModel | undefined;
  isLoading: boolean;
  setTags?: React.Dispatch<React.SetStateAction<TagList>>;
  tags?: TagList;
  tagsArray?: TagsArray;
}

export const TagContext = createContext<TagContextProps>({} as never);

export const useTagContext = () => {
  const tagContext = useContext(TagContext);

  if (tagContext === undefined) {
    throw new Error(`useTagContext must be used within <TagProvider>.`);
  }

  return tagContext;
};

export default useTagContext;
