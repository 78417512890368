import React from 'react';

import { useFetchBiDashboardElements } from '@api/biDashboardElements';
import type { BiDashboardElementModel } from '@api/biDashboardElements/biDashboardElementModel.v1';
import BulkButtons from '@components/BulkButtons';
import TabError from '@components/TabContent/TabError';
import type { BiDashboardsElementsTableProps } from '@components/Table/BiDashboardElementsTable';
import BiDashboardsElementsTable, {
  BI_DASHBOARD_ELEMENTS_TABLE_SEARCH_CONFIG,
  BI_DASHBOARD_ELEMENTS_TABLE_SORT_CONFIG,
} from '@components/Table/BiDashboardElementsTable';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import useBulkEditSelected from '@hooks/useBulkEditSelected';
import type { FilterOptions } from '@utils/filters';
import { setParams, useUpdateFilters } from '@utils/filters';

const REQUEST_CONFIG: FilterOptions = {
  order: 'name',
  page: 1,
  page_size: 50,
};

export interface BiDashboardElementsTabProps
  extends Pick<BiDashboardsElementsTableProps, 'visibleColumns'> {
  guid?: string;
  isDataSourceEditable: boolean;
}

const BiDashboardElementsTab: React.FC<BiDashboardElementsTabProps> = ({
  guid,
  isDataSourceEditable,
  visibleColumns,
}) => {
  const { isEditable: isObjectEditable } = useObjectPermissionsContext({ id: guid });
  const filterService = useUpdateFilters(
    REQUEST_CONFIG,
    BI_DASHBOARD_ELEMENTS_TABLE_SEARCH_CONFIG,
    BI_DASHBOARD_ELEMENTS_TABLE_SORT_CONFIG,
    REQUEST_CONFIG.order,
  );
  const { data, isError, isLoading } = useFetchBiDashboardElements({
    enabled: Boolean(guid),
    params: {
      ...(setParams(filterService.filter) as any),
      dashboards: guid,
    },
  });

  const { selected } = useBulkEditSelected<BiDashboardElementModel>({
    defaultSelected: data?.results,
  });

  if (isError) return <TabError />;

  return (
    <>
      <BulkButtons
        canUseBulkAssist={isObjectEditable}
        guid={guid}
        isDataSourceEditable={isDataSourceEditable}
        selectedEditableItems={selected.items}
        selectedItems={selected.items}
      />
      <BiDashboardsElementsTable
        data={data}
        filterService={filterService}
        isDataSourceEditable={isDataSourceEditable}
        isLoading={isLoading}
        visibleColumns={visibleColumns}
      />
    </>
  );
};

export default React.memo(BiDashboardElementsTab);
