import useMutation, { UseMutationOptions } from '@hooks/useMutation';
import { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';

export const usePostPasswordReset = (
  options?: UseMutationOptions<
    never,
    EnhancedErrorResult,
    {
      email: string;
    }
  >,
) => {
  return useMutation({
    ...options,
    url: '/password/reset/',
  });
};

export const usePostPasswordResetVerify = (
  options?: UseMutationOptions<
    never,
    EnhancedErrorResult,
    {
      token: string;
      user: string;
    }
  >,
) => {
  return useMutation({
    ...options,
    url: '/password/reset/verify/',
  });
};

export const usePostPasswordResetConfirm = (
  options?: UseMutationOptions<
    never,
    EnhancedErrorResult,
    {
      confirm_password: string;
      new_password: string;
      token: string;
      user: string;
    }
  >,
) => {
  return useMutation({
    ...options,
    url: '/password/reset/confirm/',
  });
};
