import React from 'react';

import { DateRangePickerProvider } from '@components/DateRangePicker/DateRangePicker.context';
import LayoutMainGrid from '@components/Layout/LayoutMainGrid';
import type { TabRoutesConfig } from '@components/TabsRouter';
import TabsRouter from '@components/TabsRouter';
import { MultiSelectProvider } from '@components/UI/Select/MultiSelect.context';
import AdminTitleView from '@pages/Admin/AdminTitleView/AdminTitleView';
import MetadataDecorator from '@utils/MetadataDecorator';

import CostAnalysisDataTab from './tabs/CostAnalysisDataTab';
import CostAnalysisOverviewTab from './tabs/CostAnalysisOverviewTab';
import CostAnalysisUserTab from './tabs/CostAnalysisUserTab';

const title = 'Snowflake Cost Analysis';

const AdminCostAnalysisPage: React.FC = () => {
  const tabsRouterConfig: TabRoutesConfig[] = [
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <CostAnalysisOverviewTab />,
      default: true,
      label: 'Overview',
      route: '/overview',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <CostAnalysisDataTab />,
      label: 'Data',
      route: '/data',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <CostAnalysisUserTab />,
      label: 'User',
      route: '/user',
    },
  ];

  return (
    <>
      <MetadataDecorator title={title} />
      <LayoutMainGrid>
        <AdminTitleView title={title} />
        <DateRangePickerProvider>
          <MultiSelectProvider>
            <TabsRouter config={tabsRouterConfig} />
          </MultiSelectProvider>
        </DateRangePickerProvider>
      </LayoutMainGrid>
    </>
  );
};

export default AdminCostAnalysisPage;
