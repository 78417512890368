import styled from '@emotion/styled';
import * as CSS from 'csstype';

import getColor, { GetColorProps } from '@styles/mixins/getColor';
import type { MakeSpaceProps } from '@styles/mixins/makeSpace';
import makeSpace from '@styles/mixins/makeSpace';

export interface StyledCircularLoaderProps extends MakeSpaceProps {
  bgColor?: GetColorProps;
  borderRadius?: CSS.Property.BorderRadius;
  borderWidth?: number;
  centered?: boolean;
  color?: GetColorProps;
  compDisplay?: CSS.Property.Display;
  compSize?: number;
  cover?: boolean;
  delay?: number;
}

const COMP_SIZE = 7;

export const StyledCircularLoader = styled.div<StyledCircularLoaderProps>`
  display: ${({ centered, compDisplay = 'inline-block' }) => (centered ? 'block' : compDisplay)};
  height: ${({ compSize = COMP_SIZE, cover, theme }) => (cover ? 0 : theme.space(compSize))};
  margin: ${({ centered }) => (centered ? '0 auto' : undefined)};
  position: ${({ cover }) => (cover ? 'static' : 'relative')};
  width: ${({ compSize = COMP_SIZE, cover, theme }) => (cover ? 0 : theme.space(compSize))};
  border-radius: ${({ borderRadius = 'inherit' }) => borderRadius};

  &::before {
    background: rgba(255, 255, 255, 0.8);
    bottom: 0;
    content: ${({ cover }) => (cover ? '""' : '')};
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    border-radius: inherit;
  }

  &::after {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    margin: ${({ compSize = COMP_SIZE, theme }) => theme.space(-compSize / 2, 0, 0, -compSize / 2)};
    height: ${({ compSize = COMP_SIZE, theme }) => theme.space(compSize)};
    width: ${({ compSize = COMP_SIZE, theme }) => theme.space(compSize)};
    animation: circular-loader-rotate 1s linear;
    animation-iteration-count: infinite;
    border-radius: 500rem;
    border-color: ${({ bgColor = '#e2e9f0', color = '#375fa0' }) =>
      `${getColor(color)} ${getColor(bgColor)} ${getColor(bgColor)} ${getColor(bgColor)}`};
    border-style: solid;
    border-width: ${({ borderWidth = 3 }) => `${borderWidth}px`};
    box-shadow: 0 0 0 1px transparent;
    visibility: visible;
    z-index: 1;
  }

  @keyframes circular-loader-rotate {
    to {
      transform: rotate(360deg);
    }
  }

  ${({ theme, ...props }) => makeSpace(theme, props)};
`;

StyledCircularLoader.defaultProps = {
  'aria-busy': 'true',
  'aria-label': 'loading',
  'aria-live': 'polite',
  role: 'progress',
};
