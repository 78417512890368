import { FEDataSourceType } from '@configs/dataTypes/types';
import { Expose, Type } from 'class-transformer';

import { DataType, ObjectType } from '@api/types';
import DataTypesModel from '@models/DataTypesModel';

class HierarchyModelV1 {
  @Expose({ name: 'guid' })
  guid!: string;

  @Expose({ name: 'ordinal' })
  ordinal!: number;

  @Expose({ name: 'data_type' })
  dataType!: DataType;

  @Expose({ name: 'name' })
  name!: string;

  @Expose({ name: 'object_type' })
  objectType!: ObjectType;

  @Expose({ name: 'data_source_type' })
  dataSourceType!: FEDataSourceType;

  @Expose({ name: 'data_source_guid' })
  dataSourceGuid!: string;

  @Expose({ name: 'has_children' })
  hasChildren?: string;

  @Expose({ name: 'data_types' })
  @Type(() => DataTypesModel)
  dataTypes?: DataTypesModel;
}

export default HierarchyModelV1;
