import { Editor, Element, Range, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import { createTable } from './createTable';

export const insertTable = (editor: Editor, rows = 3, columns = 3) => {
  if (!editor.selection) return;

  const node = Editor.above(editor, {
    match: (n) => Element.isElement(n) && n.type === 'table',
  });

  ReactEditor.focus(editor);

  const isCollapsed = Range.isCollapsed(editor.selection);

  if (!node && isCollapsed) {
    const table = createTable(rows, columns);
    if (!table) {
      return;
    }
    Transforms.insertNodes(editor, [table, { type: 'paragraph', children: [{ text: '' }] }]);
  }
};
