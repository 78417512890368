import React, { useCallback, useMemo, useState } from 'react';

import { useFetchTags } from '@api/tags';
import useTagsHierarchy from '@context/TagsHierarchy/TagsHierarchyContext';
import { useUserContext } from '@context/User';
import stripSpaces from '@utils/stripSpaces';

import { TagContext, TagList, TagsArray } from './Tag';
import { getSortedTagsByType } from './TagProvider.utils';

export const tagsQuery = {
  params: {
    page: 1,
    page_size: 1000,
    query: stripSpaces(`{
      guid,
      type,
      name,
      icon,
      color,
      owner,
      links_to,
      linked_from,
      description,
      richtext_description,
      breadcrumbs
    }`),
  },
};

const TagProvider: React.FC = ({ children }) => {
  const [page, setPage] = useState<number>(1);
  const [tags, setTags] = useState<TagList>({ category: {}, dbt: {}, status: {} });
  const [tagsArray, setTagsArray] = useState<TagsArray>({ category: [], dbt: [], status: [] });
  const {
    isOnboarding,
    isOnboardingComplete,
    loading: isLoadingOrg,
    organization,
  } = useUserContext();
  const { tagsHierarchy, tagsHierarchyIsLoading } = useTagsHierarchy();

  const shouldFetchTags =
    !isLoadingOrg &&
    !organization?.isTrialExpired &&
    !organization?.isSubscriptionEnded &&
    !isOnboarding &&
    isOnboardingComplete &&
    !tagsHierarchyIsLoading;

  const { isLoading: isTagsLoading } = useFetchTags({
    enabled: shouldFetchTags,
    onSuccess: (data) => {
      if (data?.next) {
        setPage((prev) => prev + 1);
      }

      const transformedData = getSortedTagsByType({
        tags: data.results,
        tagsHierarchy,
      });

      setTags((prev) => ({
        category: { ...prev?.category, ...transformedData.obj?.category },
        dbt: { ...prev?.dbt, ...transformedData.obj?.dbt },
        status: { ...prev?.status, ...transformedData.obj?.status },
      }));

      setTagsArray((prevTagsArray) => {
        return {
          category: [...prevTagsArray.category, ...(transformedData.array.category ?? [])],
          dbt: [...prevTagsArray.dbt, ...(transformedData.array.dbt ?? [])],
          status: [...prevTagsArray.status, ...(transformedData.array.status ?? [])],
        };
      });
    },
    params: {
      ...tagsQuery?.params,
      page,
    },
  });

  const findTag = useCallback(
    (id: string) => {
      const statusTags = tags?.status;
      const categoryTags = tags?.category;
      const dbtTags = tags?.dbt;
      return statusTags?.[id] ?? categoryTags?.[id] ?? dbtTags?.[id];
    },
    [tags?.category, tags?.dbt, tags?.status],
  );

  const context = useMemo(
    () => ({
      findTag,
      isLoading: isLoadingOrg || isTagsLoading,
      setTags,
      tags,
      tagsArray,
    }),
    [findTag, isLoadingOrg, isTagsLoading, tags, tagsArray],
  );

  return <TagContext.Provider value={context}>{children}</TagContext.Provider>;
};

export default TagProvider;
