import styled from '@emotion/styled';

import Box from '@components/Box';
import Icon from '@components/UI/Icon';
import { GetColorProps } from '@styles/mixins/getColor';
import rootTheme from '@styles/theme';

import { SIZES } from '../../Nodes/config';
import { NODE_OPACITY } from '../../Nodes/TableNode/TableNode.styles';

export type IntegrationType = 'bi' | 'database';

interface StyledIntegrationExploreNode {
  contentWidth?: string;
  isOpen?: boolean;
  isUnfocused?: boolean;
}

export const StyledIntegrationNode = styled(Box)<StyledIntegrationExploreNode>`
  transition: opacity 300ms linear;
  opacity: ${({ isUnfocused }) => (isUnfocused ? NODE_OPACITY.unfocused : NODE_OPACITY.default)};
  height: 100%;
  padding-right: ${SIZES.paddingRight.database}px;
  padding-left: ${SIZES.paddingLeft.database}px;
  padding-bottom: ${SIZES.paddingBottom.database}px;
  padding-top: ${SIZES.paddingTop.database}px;
  width: ${({ contentWidth }) => contentWidth ?? `${SIZES.width.database}px`};
`;

StyledIntegrationNode.defaultProps = {
  borderRadius: 'md',
  borderStyle: 'solid',
  borderWidth: `${SIZES.borderWidth.integration}px`,
  compDisplay: 'flex',
  flexDirection: 'column',
  p: 1,
};

export const StyledIntegrationNodeTitle = styled(Box)``;

StyledIntegrationNodeTitle.defaultProps = {
  color: 'gray.800',
  fontSize: 'sm',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

export const StyledIntegrationNodeCount = styled(Box)`
  border: 0.5px solid ${({ theme }) => theme.colors.v1.gray[200]};
`;

StyledIntegrationNodeCount.defaultProps = {
  alignItems: 'center',
  backgroundColor: 'gray.50',
  borderRadius: 'md',
  color: 'gray.700',
  compDisplay: 'flex',
  compHeight: rootTheme.space(2),
  fontSize: 'sm',
  justifyContent: 'center',
  noDefault: true,
  px: 0.5,
  textAlign: 'center',
};

interface StyledIntegrationNodeCaretContainerProps {
  hoverColor?: GetColorProps;
}

export const StyledIntegrationNodeCaretContainer = styled(
  Box,
)<StyledIntegrationNodeCaretContainerProps>`
  transition: all 300ms linear;
  background-color: transparent;
  opacity: 0.7;

  &:hover {
    background-color: ${({ hoverColor = 'white' }) => hoverColor};
  }
`;

StyledIntegrationNodeCaretContainer.defaultProps = {
  alignItems: 'center',
  borderRadius: 'xs',
  compDisplay: 'flex',
  compHeight: rootTheme.space(2.75),
  compWidth: rootTheme.space(2.75),
  cursor: 'pointer',
  justifyContent: 'center',
};

interface StyledIntegrationNodeCaretProps {
  isOpen?: boolean;
}

export const StyledIntegrationNodeCaret = styled(Icon)<StyledIntegrationNodeCaretProps>`
  min-width: ${({ theme }) => theme.space(2)};
  transition: all 300ms ease;
  transform: rotate(${({ isOpen }) => (isOpen ? '180deg' : '0')});
  cursor: pointer;
`;
