import React from 'react';
import { useRouteMatch } from '@routing/router';

import { DashboardModel } from '@api/dashboards/DashboardModel';
import type { DatasetModel } from '@api/datasets/DatasetModel.v1';
import { LookerExploreModel } from '@api/explores/LookerExploreModel';
import type { JobModel } from '@api/jobs/JobModel.v1';
import { TableauDataSourceModel } from '@api/tableau/TableauDataSourceModel';
import { TableModel } from '@api/tables/TableModel';
import Box from '@components/Box';
import LineageExplore from '@components/LineageExplore/LineageExplore';
import RelatedDashboards from '@components/TabContent/RelatedDashboards';
import SourceTablesTab from '@components/TabContent/SourceTablesTab';
import UpstreamDownstreamTab from '@components/TabContent/UpstreamDownstreamTab';
import { TabContentProps } from '@components/Tabs/types';
import type { TabRoutesConfig } from '@components/TabsRouter';
import TabsRouter from '@components/TabsRouter';
import Icon from '@components/UI/Icon';
import wrapString from '@utils/wrapString/wrapString';

import CountLabel from './CountLabel';

interface TabbedLineageProps extends TabContentProps {
  guid: string;
  hideCount?: boolean;
  itemMetadata?:
    | TableModel
    | DashboardModel
    | LookerExploreModel
    | TableauDataSourceModel
    | JobModel
    | DatasetModel;
  objectType?:
    | 'explore'
    | 'table'
    | 'job'
    | 'column'
    | 'dashboard'
    | 'lookmlview'
    | 'tableaudatasource';
  showDownstream?: boolean;
  showDownstreamDashboards?: boolean;
  showExplore?: boolean;
  sourceTables?: boolean;
  stickyHeader?: boolean;
}

const TabbedLineage: React.FC<TabbedLineageProps> = ({
  guid,
  hideCount,
  itemMetadata,
  objectType,
  showDownstream = true,
  showDownstreamDashboards = true,
  showExplore = true,
  sourceTables = false,
  stickyHeader,
}) => {
  const { url } = useRouteMatch();

  const upstreamCount = itemMetadata?.upstreamObjectsCounts?.total;
  const downstreamCount = itemMetadata?.downstreamObjectsCounts?.total;
  const dashboardsCount = itemMetadata?.downstreamObjectsCounts?.dashboard;
  const sourceTablesCount = itemMetadata?.upstreamObjectsCounts?.sourceTable;

  const tabsRouterConfig: TabRoutesConfig[] = [
    ...(sourceTables
      ? [
          {
            // eslint-disable-next-line react/no-unstable-nested-components
            component: () => <SourceTablesTab guid={guid} />,
            contentOffset: 2,
            default: sourceTables,
            label: `Source Tables ${wrapString(sourceTablesCount)}`,
            route: '/source-tables',
          },
        ]
      : []),
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => (
        <UpstreamDownstreamTab
          assetName={itemMetadata?.name ?? ''}
          counts={{
            downstream: downstreamCount,
            upstream: itemMetadata?.upstreamObjectsCounts?.totalAllDepths,
          }}
          direction="left"
          enableExportCsv
          nodeKey={guid}
          requestParamsExtraProps={{ enable_description: true }}
          tableOrColumnId={guid}
        />
      ),
      default: !sourceTables,
      label: <CountLabel count={upstreamCount} hideCount={hideCount} text="Upstream" />,
      route: '/upstream',
    },
    ...(showDownstream
      ? [
          {
            // eslint-disable-next-line react/no-unstable-nested-components
            component: () => (
              <UpstreamDownstreamTab
                assetName={itemMetadata?.name ?? ''}
                counts={{
                  downstream: downstreamCount,
                  upstream: itemMetadata?.upstreamObjectsCounts?.totalAllDepths,
                }}
                direction="right"
                enableExportCsv
                nodeKey={guid}
                requestParamsExtraProps={{ enable_description: true }}
                tableOrColumnId={guid}
              />
            ),
            label: <CountLabel count={downstreamCount} hideCount={hideCount} text="Downstream" />,
            route: '/downstream',
          },
        ]
      : []),
    ...(showDownstreamDashboards
      ? [
          {
            // eslint-disable-next-line react/no-unstable-nested-components
            component: () => (
              <RelatedDashboards
                assetName={itemMetadata?.name ?? ''}
                dataTypes={itemMetadata?.dataTypes}
                guid={guid}
                objectType={objectType}
                stickyHeader
              />
            ),
            label: (
              <CountLabel
                count={dashboardsCount}
                hideCount={hideCount}
                text="Downstream Dashboards"
              />
            ),
            route: '/related-dashboards',
          },
        ]
      : []),
    ...(showExplore
      ? [
          {
            // eslint-disable-next-line react/no-unstable-nested-components
            component: () => (
              <LineageExplore prevRoute={url.replace('/lineage/explore', '/lineage')} />
            ),
            label: (
              <Box alignItems="center" compDisplay="flex" gap={0.5}>
                Explore <Icon color="currentColor" name="open-new-tab" size="14px" />
              </Box>
            ),
            openInNewTab: Boolean(process.env.REACT_APP_CHROME_EXTENSION_BUILD),
            route: '/explore',
            variant: 'filled' as TabContentProps['variant'],
          },
        ]
      : []),
  ];

  return <TabsRouter config={tabsRouterConfig} sticky={stickyHeader} subTabs />;
};

export default React.memo<TabbedLineageProps>(TabbedLineage);
