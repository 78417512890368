import React from 'react';

import { useFetchReportsNewestItems } from '@api/reports';
import MetadataGrid from '@components/MetadataGrid';

import MainPageSection from '../MainPageSection';
import MetadataGridHomepageCard from '../MetadataGridHomepageCard';

export const RECENTLY_ADDED_SECTION_LABEL = 'Recently Added';

const RecentlyAdded = () => {
  const { data, isLoading } = useFetchReportsNewestItems({
    params: {
      page_size: 4,
    },
  });

  if (isLoading || !data?.length) {
    return null;
  }

  return (
    <MainPageSection label={RECENTLY_ADDED_SECTION_LABEL}>
      <MetadataGrid data={data}>
        {(obj) => {
          return <MetadataGridHomepageCard obj={obj} />;
        }}
      </MetadataGrid>
    </MainPageSection>
  );
};

export default RecentlyAdded;
