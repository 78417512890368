import React from 'react';
import getDebug from 'debug';

import { useFetchRelatedTables } from '@api/tables';
import { RelatedTableList } from '@components/RelatedTableList';
import TabError from '@components/TabContent/TabError';
import { TabContentProps } from '@components/Tabs/types';
import { TableViewTabProps } from '@pages/TabbedTableViewPage/TableViewTab';

const debug = getDebug('selectstar:tab-loading');

export default React.memo<TableViewTabProps & TabContentProps>(function RelatedTablesTab({ guid }) {
  const { data, error, isLoading } = useFetchRelatedTables(guid);

  const relatedTables = data?.results.map((rt) => rt.relatedTable);

  if (error) return <TabError />;

  debug('display RelatedTablesTab');

  return <RelatedTableList loading={isLoading} tables={relatedTables} />;
});
