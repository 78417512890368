import React from 'react';
import { RouterLink } from '@routing/router';

import {
  useFetchAnalyticsUsersActive,
  useFetchAnalyticsUsersContributing,
  useFetchAnalyticsUsersNew,
  useFetchAnalyticsUsersTotalCount,
} from '@api/analytics';
import Box from '@components/Box';
import DateRangePicker from '@components/DateRangePicker';
import { Column, Row } from '@components/Grid';
import TabError from '@components/TabContent/TabError';
import HR from '@components/UI/HR';
import StatSummaryBar from '@components/UI/StatSummaryBar';
import {
  SectionTitleWrapper,
  Subtitle,
  Title,
} from '@pages/Admin/AdminCostAnalysisPage/tabs/SectionTitle';
import theme from '@styles/theme';

import { DateRangeProps } from '../../Analytics.types';

import NewUsersTable from './tables/NewUsersTable';
import TopActiveUsersTable from './tables/TopActiveUsersTable';
import TopContributorsTable from './tables/TopContributorsTable';

const AnalyticsUsersTab: React.FC<DateRangeProps> = ({
  dateRange: [start_date, end_date],
  onDateRangeChange,
}) => {
  const {
    data: activeUsersData,
    isError: activeUsersIsError,
    isLoading: activeUsersIsLoading,
  } = useFetchAnalyticsUsersActive({ params: { end_date, page_size: 5, start_date } });
  const {
    data: newUsersData,
    isError: newUsersIsError,
    isLoading: newUsersIsLoading,
  } = useFetchAnalyticsUsersNew({ params: { end_date, page_size: 5, start_date } });
  const {
    data: topContributorsData,
    isError: topContributorsIsError,
    isLoading: topContributorsIsLoading,
  } = useFetchAnalyticsUsersContributing({ params: { end_date, page_size: 5, start_date } });
  const {
    data: totalMembersCountData,
    isError: totalMembersCountIsError,
    isLoading: totalMembersCountIsLoading,
  } = useFetchAnalyticsUsersTotalCount({ params: { end_date, start_date } });

  const headerIsLoading = newUsersIsLoading || activeUsersIsLoading || totalMembersCountIsLoading;

  if (activeUsersIsError || newUsersIsError || topContributorsIsError || totalMembersCountIsError) {
    return <TabError />;
  }

  const borderColor = theme.colors.v1.gray[200];

  return (
    <Box compDisplay="flex" flexDirection="column">
      <SectionTitleWrapper my={3}>
        <Title>Overview</Title>
        <DateRangePicker initialRange={[start_date, end_date]} onChange={onDateRangeChange} />
      </SectionTitleWrapper>
      <StatSummaryBar
        data={[
          {
            description: 'New Users',
            tooltip: 'Based on who joined recently',
            value: newUsersData?.count,
          },
          {
            description: 'Active Users',
            tooltip: 'Based on all activity logs',
            value: activeUsersData?.count,
          },
          {
            description: 'Total Members',
            tooltip: 'Total number of users in the organization',
            value: totalMembersCountData?.count,
          },
        ]}
        loading={headerIsLoading}
      />
      <HR mb={2} />
      <Row flexWrap="wrap" space={1.5}>
        <Column md={6} xs={12}>
          <Box alignItems="center" compDisplay="flex" justifyContent="space-between" py={2}>
            <Title>Top Active Users</Title>
            <Subtitle as={RouterLink} to="/admin/analytics/users/top-users">
              Show more
            </Subtitle>
          </Box>
          <Box border={`1px solid ${borderColor}`} borderRadius={theme.radius.md} p={2}>
            <TopActiveUsersTable loading={activeUsersIsLoading} rows={activeUsersData?.results} />
          </Box>
        </Column>
        <Column md={6} xs={12}>
          <Box alignItems="center" compDisplay="flex" justifyContent="space-between" py={2}>
            <Title>Top Contributors</Title>
            <Subtitle as={RouterLink} to="/admin/analytics/users/top-contributors">
              Show more
            </Subtitle>
          </Box>
          <Box border={`1px solid ${borderColor}`} borderRadius={theme.radius.md} p={2}>
            <TopContributorsTable
              loading={topContributorsIsLoading}
              rows={topContributorsData?.results}
            />
          </Box>
        </Column>
      </Row>
      <Row space={1.5}>
        <Column md={6} xs={12}>
          <Box alignItems="center" compDisplay="flex" justifyContent="space-between" py={2}>
            <Title>New Users</Title>
            <Subtitle as={RouterLink} to="/admin/analytics/users/new-users">
              Show more
            </Subtitle>
          </Box>
          <Box border={`1px solid ${borderColor}`} borderRadius={theme.radius.md} p={2}>
            <NewUsersTable loading={newUsersIsLoading} rows={newUsersData?.results} />
          </Box>
        </Column>
      </Row>
    </Box>
  );
};

export default AnalyticsUsersTab;
