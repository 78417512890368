import { Expose, Type } from 'class-transformer';

import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import { UserModel } from '@api/user/UserModel';
import { breadcrumbsToLabelList } from '@components/Breadcrumbs';
import { MetadataModel } from '@models/MetadataModel';
import { Reference } from '@models/Reference';
import { urlFor } from '@utils/routing';

export class WorkspaceItemModel {
  static objectType: string = 'workspaceitem';

  objectType: string = WorkspaceItemModel.objectType;

  static typeDisplay: string = 'Workspace Item';

  typeDisplay: string = WorkspaceItemModel.typeDisplay;

  @Type(() => Reference)
  item?: Reference<MetadataModel>;

  @Type(() => UserModel)
  user?: UserModel;

  @Expose({ name: 'item_name' })
  itemName!: string;

  @Type(() => BreadcrumbModel)
  breadcrumbs?: BreadcrumbModel[];

  get breadcrumbLabelList() {
    const url = urlFor(this, false, '');
    return breadcrumbsToLabelList(this.itemName, url, this.breadcrumbs);
  }
}
