import styled from '@emotion/styled';

import getLayoutComponent from '@utils/getLayoutComponent';

const StyledTreeV0 = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const StyledTreeV1 = styled(StyledTreeV0)`
  padding-left: ${({ theme }) => theme.space(1.5)};
  padding-right: ${({ theme }) => theme.space(1.5)};
`;

export const StyledTree = getLayoutComponent(StyledTreeV0, StyledTreeV1);
