import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from '@routing/router';
import { Cell } from 'react-table';

import { useFetchMonitors } from '@api/monitors';
import { MonitorModel } from '@api/monitors/MonitorsModel';
import { GetMonitorsListQueryParams } from '@api/openapi.generated';
import { StatusType } from '@api/types';
import Box from '@components/Box';
import DateTime from '@components/DateTime';
import EmptyContent from '@components/EmptyContent';
import type { FiltersListFilterType } from '@components/FiltersList/FiltersList';
import FiltersList from '@components/FiltersList/FiltersList';
import OpenInNewTabButton from '@components/OpenInNewTabButton/OpenInNewTabButton';
import Status, { statusMap } from '@components/Status/Status';
import TabError from '@components/TabContent/TabError';
import { TableItem } from '@components/Table/Cells/LinkedCell';
import MonitorColumnCell from '@components/Table/Cells/MonitorColumnCell';
import Table from '@components/Table/Table';
import { sortDates } from '@components/Table/Table/sortDates';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import Icon from '@components/UI/Icon';
import { useMetadataObjectContext } from '@context/MetadataObject';
import { useUserContext } from '@context/User';
import ColumnPage from '@pages/ColumnPage';
import { Filter } from '@utils';
import wrapString from '@utils/wrapString';

export type MonitorsStatusCountsType = Record<StatusType, number> | null;

export const MONITORS_EMPTY_CONTENT_MESSAGE = 'No quality checks available';

const defaultFiltersConfig: Filter.FilterOptions = {
  order: 'last_run_at',
  page: 1,
  page_size: 100,
  sortColumn: 'latestRunAt',
  sortDirection: 'ascending',
};

const searchConfig: { [key: string]: keyof Filter.FilterOptions } = {
  name: 'search_name',
};

const sortConfig = {
  latestRunAt: 'last_run_at',
  name: 'name',
};

const defaultFilter = {
  label: 'All',
  name: 'all',
};

export const FILTER_CONFIG = {
  defaultFiltersConfig,
  defaultOrder: 'last_run_at',
  searchConfig,
  sortConfig,
};

interface MonteCarloTableProps {
  tableGuid: string;
}

const MonitorsTable: React.FC<MonteCarloTableProps> = ({ tableGuid }) => {
  const { changePage, filter, globalSearch, sort } = Filter.useUpdateFilters(
    FILTER_CONFIG.defaultFiltersConfig,
    FILTER_CONFIG.searchConfig,
    FILTER_CONFIG.sortConfig,
    FILTER_CONFIG.defaultOrder,
  );

  const history = useHistory();
  const { guid, itemId } = useParams<{ guid: string; itemId: string }>();
  const [searchVisible, setSearchVisible] = useState(false);
  const [currColumn, setCurrColumn] = useState<string | undefined>(itemId);
  const initialStatusCounts = useRef<MonitorsStatusCountsType>(null);
  const initialResultsLengthRef = useRef<number | null>(null);
  const [selectedFilter, setSelectedFilter] = useState<FiltersListFilterType | null>(null);
  const filtersWithParams = Filter.setParams(filter);
  const { dataSourceGuid } = useMetadataObjectContext();
  const { dataSources, organization } = useUserContext();
  const isDataSourceEditable = Boolean(dataSources?.[dataSourceGuid ?? '']?.settings?.isEditable);
  const showColumnCategoryTags = organization?.settings?.showColumnCategoryTags ?? false;

  const { data, isError, isLoading } = useFetchMonitors({
    params: {
      ...filtersWithParams,
      order: filtersWithParams.order as GetMonitorsListQueryParams['order'],
      status: selectedFilter?.name !== 'all' ? selectedFilter?.name : undefined,
      tables: tableGuid,
    },
  });

  const columns: ColumnConfig<MonitorModel>[] = [
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (props: Cell<MonitorModel>) => {
        const { row } = props;

        return (
          <Box alignItems="center" compDisplay="flex">
            <TableItem {...props} item={row.original} name={row.original.name} noLink showIcon />
            <OpenInNewTabButton url={row.original.externalUrl} />
          </Box>
        );
      },
      Header: `Test ${wrapString(data?.count)}`,
      accessor: 'name',
      disableHiding: true,
      id: 'name',
      width: '280%',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (props: Cell<MonitorModel>) => {
        const { row } = props;
        const columnData = row?.original?.columns?.[0];

        return (
          <MonitorColumnCell
            columnData={columnData}
            openColumnsPage={() => {
              setCurrColumn(columnData?.guid);
            }}
          />
        );
      },
      Header: 'Column',
      accessor: (d) => d?.columns?.[0],
      disableFilters: true,
      disableSortBy: true,
      id: 'column',
      width: '120%',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ row }: Cell<MonitorModel>) => <Status status={row.original.status} />,
      Header: 'Status',
      accessor: 'status',
      disableFilters: true,
      disableSortBy: true,
      id: 'status',
      width: 130,
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ row }: Cell<MonitorModel>) => (
        <DateTime datetime={row.original.latestRunAt} format="fullDateFormat" />
      ),
      Header: 'Last Run',
      accessor: (d) => d?.latestRunAt,
      disableFilters: true,
      id: 'latestRunAt',
      sortType: sortDates,
      width: 160,
    },
  ];

  const totalPages = data && filter.page_size ? Math.ceil(data.count / filter.page_size) : 1;
  const getRowId = useCallback((row: Partial<any>) => row.guid!, []);

  const statusCounts = useMemo(() => {
    if (data?.status_counts && initialStatusCounts.current === null) {
      initialStatusCounts.current = data?.status_counts;
    }

    return initialStatusCounts.current;
  }, [data?.status_counts]);

  const initialResultsLength = useMemo(() => {
    if (data?.results && initialResultsLengthRef.current === null) {
      initialResultsLengthRef.current = data?.results.length;
    }
    return initialResultsLengthRef.current;
  }, [data?.results]);

  const filters = [
    defaultFilter,
    {
      count: statusCounts?.error,
      label: 'Failed',
      name: 'error',
      startIcon: <Icon name={statusMap.error.icon} size="18px" />,
    },
    {
      count: statusCounts?.success,
      label: 'Passed',
      name: 'success',
      startIcon: <Icon name={statusMap.success.icon} size="18px" />,
    },
  ];

  if (isError) {
    return <TabError />;
  }

  return (
    <>
      {initialResultsLength === 0 ? (
        <EmptyContent message={MONITORS_EMPTY_CONTENT_MESSAGE} />
      ) : (
        <TableStyled>
          <Table
            basic="very"
            changePage={changePage}
            className="table-full"
            columns={columns}
            compact
            customTopBarContent={
              <FiltersList
                data={filters}
                mb={0}
                ml={1}
                onClick={(value) => {
                  changePage(1);
                  setSelectedFilter(value);
                }}
                selectedFilter={selectedFilter ?? defaultFilter}
              />
            }
            data={data?.results ?? []}
            disableRowSelect
            getRowId={getRowId}
            initialState={{
              sortBy: [
                {
                  desc: false,
                  id: 'latestRunAt',
                },
              ],
            }}
            loading={isLoading}
            manualGlobalFilter
            manualPagination
            manualSortBy
            selectable
            setGlobalFilter={globalSearch}
            setSortBy={sort}
            showFilter={searchVisible}
            showGlobalFilterV1
            sortable
            stickyHeader
            toggleFilter={() => setSearchVisible((prev) => !prev)}
            totalPages={totalPages}
            unstackable
          />
        </TableStyled>
      )}
      {currColumn && (
        <ColumnPage
          dsGuid={dataSourceGuid}
          guid={currColumn}
          hideCategoryTags={!showColumnCategoryTags}
          isDataSourceEditable={isDataSourceEditable}
          onClose={() => {
            history.push(`/tables/${guid}/data-quality/monte-carlo`);
            setCurrColumn(undefined);
          }}
        />
      )}
    </>
  );
};

export default MonitorsTable;
