import styled from '@emotion/styled';

import Box from '@components/Box';

import { SIZES } from '../../Nodes/config';

export const StyledNoMatchedDataBadge = styled(Box)``;

StyledNoMatchedDataBadge.defaultProps = {
  alignItems: 'center',
  backgroundColor: 'white',
  color: 'gray.400',
  compDisplay: 'flex',
  compHeight: `${SIZES.height.noMatchedDataBadge}px`,
  compWidth: '100%',
  fontSize: 'sm',
  fontStyle: 'italic',
  px: 0.5,
};
