import 'semantic-ui-css/semantic.min.css';
import 'reactflow/dist/style.css';

import React from 'react';

import MentionedByModal from '@components/MentionedBy/MentionedByModal';
import { useModal } from '@context/Modal';

const MentionedByModalContainer: React.FC = () => {
  const { MODAL_IDS, checkModalOpened, closeModal, getModalContent } = useModal();
  const props = getModalContent(MODAL_IDS.mentionedBy);

  if (!checkModalOpened(MODAL_IDS.mentionedBy)) return null;
  return <MentionedByModal {...props} onClose={() => closeModal(MODAL_IDS.mentionedBy)} />;
};

export default MentionedByModalContainer;
