import React from 'react';

import DateRangePicker from '@components/DateRangePicker';
import { useDateRangePickerContext } from '@components/DateRangePicker/DateRangePicker.context';
import TableByQueryCountTable from '@components/Table/TableByQueryCountTable';
import flags from '@features';

import TableScatterChart from '../charts/TableScatterChart';

import { SectionTitleWrapper, Subtitle } from './SectionTitle';

const CostAnalysisTableQueryByCountTab: React.FC = () => {
  const { dateRange, maxRangeDays, presetDateOptions, setDateRange } = useDateRangePickerContext();

  return (
    <>
      <SectionTitleWrapper my={2}>
        <Subtitle>Table queried by top queries and dashboards</Subtitle>
        <DateRangePicker
          initialRange={dateRange}
          maxRangeDays={maxRangeDays}
          onChange={setDateRange}
          presetDateOptions={presetDateOptions}
        />
      </SectionTitleWrapper>
      {flags.show_cost_analysis_table_scatter_chart && <TableScatterChart range={dateRange} />}
      <TableByQueryCountTable range={dateRange} />
    </>
  );
};

export default CostAnalysisTableQueryByCountTab;
