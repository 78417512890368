import React from 'react';

import { CommentModel } from '@api/comments/CommentModel';
import { TeamModel } from '@api/teams/TeamModel';
import { UserModel } from '@api/user/UserModel';
import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import DeleteCommentModal from '@components/Modal/DeleteCommentModal';
import { useModal } from '@context/Modal';

import CommentTextBox from './CommentTextBox';
import type { CommentPayload } from './CommentTextBox/CommentTextBox.types';
import Message from './Message';

interface DiscussionProps {
  businessOwner?: UserModel | TeamModel;
  data?: CommentModel[];
  editingNotificationGuid?: string;
  enableNotifications?: boolean;
  isParentEditable?: boolean;
  loading?: boolean;
  onNotificationUpdate?: (payload: CommentModel) => void;
  onSave: (payload: CommentPayload) => void;
  parentGuid?: string;
  reloadComments: () => void;
  shouldBlockNavigation?: boolean;
  technicalOwner?: UserModel | TeamModel;
}

const Discussion: React.FC<DiscussionProps> = ({
  businessOwner,
  data,
  editingNotificationGuid,
  enableNotifications,
  isParentEditable,
  loading,
  onNotificationUpdate,
  onSave,
  parentGuid,
  reloadComments,
  shouldBlockNavigation,
  technicalOwner,
}) => {
  const { MODAL_IDS, checkModalOpened, closeModal, getModalContent } = useModal();

  const deleteCommentModalProps = getModalContent(MODAL_IDS.deleteComment);

  const renderComments = (comments: CommentModel[] | undefined) => {
    if (!comments || comments.length < 1) return 'No comments yet...';
    return comments.map((comment) => {
      return (
        <Message
          key={comment.guid}
          businessOwner={businessOwner}
          comment={comment}
          editingNotificationGuid={editingNotificationGuid}
          hasEditPermissions={isParentEditable}
          onNotificationUpdate={onNotificationUpdate}
          onSave={onSave}
          parentGuid={parentGuid}
          reloadComments={reloadComments}
          technicalOwner={technicalOwner}
        />
      );
    });
  };

  if (loading || !parentGuid) return <CircularLoader compDisplay="block" mx="auto" />;

  return (
    <>
      <CommentTextBox
        enableNotifications={enableNotifications}
        isNewMessage
        onSave={onSave}
        parentGuid={parentGuid}
        shouldBlockNavigation={shouldBlockNavigation}
      />
      <Box mt={2}>{renderComments(data)}</Box>
      {checkModalOpened(MODAL_IDS.deleteComment) && deleteCommentModalProps && (
        <DeleteCommentModal
          item={deleteCommentModalProps.item}
          onClose={() => closeModal(MODAL_IDS.deleteComment)}
          reloadData={reloadComments}
        />
      )}
    </>
  );
};

export default Discussion;
