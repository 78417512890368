import { NodesById } from '@components/LineageExplore/useCreateNodesEdges/algorithm/types';
import { EXPLORE_NODE_PAGE_SIZE } from '@components/LineageExplore/useShowMoreNodes/useShowMore.constants';

import {
  BI_GROUP_SEPARATOR,
  BI_GROUP_STACK_INDICATOR,
} from '../../../useCreateNodesEdges.contants';
import { GroupParser } from '../types';

import { biParser, columnParser, databaseParser, schemaParser, tableParser } from './nodeParsers';

export const dwhGroupParser: GroupParser = ({
  currentNodesById,
  currentStackGroups,
  id,
  inputNodesById,
  options,
  pivotToSkipCollapsing,
  stackId,
  startingColumnGuid,
  startingTableId,
}) => {
  const nodesById: NodesById = { ...currentNodesById };
  const stackGroups: Record<number, Set<string>> = { ...currentStackGroups };
  const dbNodes: Array<string> = [];
  const initiallyCollapsedTableNodes: Array<string> = [];
  const inputNode = inputNodesById[id];
  const linkedObjects: Array<string> = [];
  let schemaKeyToSkipCollapsing: string | undefined;

  const metadata = inputNode?.metadata;
  const { parent_guid: parentGuid } = metadata;

  const schemaGuid = parentGuid as string;
  const databaseGuid = inputNodesById[schemaGuid]?.metadata?.parent_guid as string;
  const databaseNodeKey = `${stackId}-${databaseGuid}`;
  const schemaNodeKey = `${stackId}-${schemaGuid}`;

  if (id === pivotToSkipCollapsing) {
    schemaKeyToSkipCollapsing = schemaNodeKey;
  }

  if (!currentNodesById[databaseNodeKey]) {
    const { parsedNode: parsedDatabaseNode } = databaseParser({
      children: new Set([schemaNodeKey]),
      databaseGuid,
      inputNodesById,
      nodeId: databaseNodeKey,
      stackId,
    });
    nodesById[databaseNodeKey] = parsedDatabaseNode;
    dbNodes.push(databaseNodeKey);
    stackGroups[stackId] = (stackGroups[stackId] ?? new Set()).add(databaseNodeKey);
  } else {
    if (nodesById[databaseNodeKey]?.data) {
      nodesById[databaseNodeKey].data.children?.add(schemaNodeKey);
      nodesById[databaseNodeKey].data.tablesCount =
        (nodesById[databaseNodeKey].data.tablesCount ?? 0) + 1;
    }

    stackGroups[stackId]?.add(databaseNodeKey);
  }

  if (!nodesById[schemaNodeKey]) {
    const { parsedNode: parsedSchemaNode } = schemaParser({
      children: new Set([id]),
      inputNodesById,
      nodeId: schemaNodeKey,
      parentId: databaseNodeKey,
      schemaGuid,
      stackId,
    });

    nodesById[schemaNodeKey] = parsedSchemaNode;
  } else {
    nodesById[schemaNodeKey]?.data.children?.add(id);
    nodesById[schemaNodeKey].data.childrenCount =
      (nodesById[schemaNodeKey]?.data.childrenCount ?? 0) + 1;
  }

  if (!nodesById[id]) {
    const columns = inputNodesById[id]?.columns ?? [];

    const {
      isInitiallyCollapsed,
      linkedObjs,
      parsedNode: parsedTableNode,
    } = tableParser({
      inputNodesById,
      isBiTable: false,
      nodeId: id,
      options,
      parentId: schemaNodeKey,
      parents: [schemaNodeKey, databaseNodeKey],
      stackId,
      startingColumnGuid,
      startingTableId,
    });
    nodesById[id] = parsedTableNode;

    if (isInitiallyCollapsed) {
      initiallyCollapsedTableNodes.push(id);
    }

    linkedObjects.push(...(linkedObjs ?? []));

    columns.forEach((columnId: string) => {
      const { parsedNode: parsedColumnNode } = columnParser({
        inputNodesById,
        nodeId: columnId,
        options,
        parentId: id,
        parents: [id, schemaNodeKey, databaseNodeKey],
        stackId,
      });
      nodesById[columnId] = parsedColumnNode;
      nodesById[id].data.childrenCount = (nodesById[id]?.data.childrenCount ?? 0) + 1;
    });
  }

  return {
    dbNodes,
    initiallyCollapsedTableNodes,
    linkedObjects,
    nodesById,
    schemaKeyToSkipCollapsing,
    stackGroups,
  };
};

export const biGroupParser: GroupParser = ({
  currentNodesById,
  currentStackGroups,
  id,
  inputNodesById,
  options,
  stackId,
  startingColumnGuid,
  startingTableId,
}) => {
  const nodesById: NodesById = { ...currentNodesById };
  const inputNode = inputNodesById[id];
  const stackGroups: Record<number, Set<string>> = { ...currentStackGroups };
  const { enableHorizontalGroups } = options ?? {};
  const initiallyCollapsedTableNodes: Array<string> = [];
  const linkedObjects: Array<string> = [];

  const metadata = inputNode?.metadata;
  const { dataTypes } = metadata;
  const { dataSourceType, dataType, objectType } = dataTypes ?? {};

  const groupStackKey = `${stackId}${BI_GROUP_STACK_INDICATOR}`;
  const groupKey = `${dataSourceType}${BI_GROUP_SEPARATOR}${objectType}${BI_GROUP_SEPARATOR}${dataType}`;
  const dataTypeKey = enableHorizontalGroups ? groupKey : `${groupStackKey}${groupKey}`;

  if (!nodesById[dataTypeKey] && objectType) {
    const { parsedNode: parsedBiNode } = biParser({
      children: new Set([id]),
      inputNodesById,
      metadataNodeGuid: id,
      nodeId: dataTypeKey,
      stackId,
    });
    nodesById[dataTypeKey] = parsedBiNode;
    stackGroups[stackId] = (stackGroups[stackId] ?? new Set()).add(dataTypeKey);
  } else {
    nodesById[dataTypeKey]?.data.children?.add(id);
    if (!stackGroups[stackId]) {
      stackGroups[stackId] = new Set();
    }

    if (!nodesById[dataTypeKey].nodeStacksConfig![stackId]) {
      nodesById[dataTypeKey].nodeStacksConfig![stackId] = {
        hiddenChildren: new Set(),
        hiddenChildrenCount: 0,
        shownChildrenCount: EXPLORE_NODE_PAGE_SIZE,
      };
    }

    const newStartStackId = Math.min(nodesById[dataTypeKey].startStackId!, stackId);
    const newEndStackId = Math.max(nodesById[dataTypeKey].endStackId!, stackId);

    nodesById[dataTypeKey].endStackId = newEndStackId;
    nodesById[dataTypeKey].startStackId = newStartStackId;
    nodesById[dataTypeKey].stackedAt = newStartStackId;

    if (stackGroups[newEndStackId]?.has(dataTypeKey)) {
      stackGroups[newEndStackId].delete(dataTypeKey);
      stackGroups[newStartStackId].add(dataTypeKey);
    }

    nodesById[dataTypeKey].data.tablesCount = (nodesById[dataTypeKey].data.tablesCount ?? 0) + 1;
    nodesById[dataTypeKey].data.childrenCount =
      (nodesById[dataTypeKey].data.childrenCount ?? 0) + 1;
  }

  if (!nodesById[id] && objectType) {
    const biTableColumns = inputNodesById[id]?.columns ?? [];

    const {
      isInitiallyCollapsed,
      linkedObjs,
      parsedNode: parsedBiTableNode,
    } = tableParser({
      inputNodesById,
      isBiTable: true,
      nodeId: id,
      options,
      parentId: dataTypeKey,
      parents: [dataTypeKey],
      stackId,
      startingColumnGuid,
      startingTableId,
    });
    nodesById[id] = parsedBiTableNode;

    if (isInitiallyCollapsed) {
      initiallyCollapsedTableNodes.push(id);
    }

    linkedObjects.push(...(linkedObjs ?? []));

    biTableColumns.forEach((columnId: string) => {
      const { parsedNode: parsedBiColumnNode } = columnParser({
        inputNodesById,
        isBITableColumn: true,
        nodeId: columnId,
        options,
        parentId: id,
        parents: [id, dataTypeKey],
        stackId,
      });
      nodesById[columnId] = parsedBiColumnNode;
      nodesById[id].data.childrenCount = (nodesById[id]?.data.childrenCount ?? 0) + 1;
    });
  }

  return {
    initiallyCollapsedTableNodes,
    linkedObjects,
    nodesById,
    stackGroups,
  };
};
