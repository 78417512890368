const splitter: Record<string, true> = {
  ' ': true,
  '-': true,
  _: true,
};

const titleize = (str: string | undefined = '') => {
  let upper = true;
  let newStr = '';

  if (!str) return '';

  for (let i = 0, l = str.length; i < l; i += 1) {
    const char = str[i];

    if (splitter[char]) {
      upper = true;
      newStr += ' ';
      continue;
    }
    newStr += upper ? char.toUpperCase() : char.toLowerCase();
    upper = false;
  }
  return newStr;
};

export default titleize;
