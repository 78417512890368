import ColumnLineageModel from '@api/lineage/ColumnLineageModel';
import TableLineageModel from '@api/lineage/TableLineageModel';
import sortByType from '@utils/sortByType';

import { ColumnsMap, IDExists, SortedColumns } from '../types';

const getAllSortedColumns = (
  tables: TableLineageModel[],
  columnsById: ColumnsMap,
  pinnableColumnsById: IDExists,
  sortBy: 'name' | 'popularity' | 'description' | 'usage' | 'impactScore',
  orderBy: 'asc' | 'desc' | 'default',
): SortedColumns => {
  return tables.reduce((result, { columns, key }) => {
    Object.assign(result, {
      [key]: (columns || ([] as string[]))
        .map((c) => columnsById[c])
        .filter((c: any): c is ColumnLineageModel => c !== undefined)
        .sort((a, b) => b.ordinal! - a.ordinal!)
        .sort((a, b) => {
          const valuesMap = {
            description: { a: a?.description, b: b?.description },
            impactScore: {},
            name: { a: a?.name, b: b?.name },
            popularity: { a: a?.popularity?.popularity ?? 0, b: b?.popularity?.popularity ?? 0 },
            usage: {},
          };

          return sortByType({ ...valuesMap[sortBy], orderBy });
        })
        .sort((a, b) => (pinnableColumnsById[b.key] ? 1 : 0) - (pinnableColumnsById[a.key] ? 1 : 0))
        .map((_) => _.key),
    });

    return result;
  }, {} as SortedColumns);
};

export default getAllSortedColumns;
