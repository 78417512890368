import { Descendant, Element } from 'slate';

const NODES_TO_STRIP = ['table', 'thead', 'tbody', 'tr', 'th', 'td', 'paragraph'];

const stripSlateNode = (
  node: Descendant,
  nodesToStrip = NODES_TO_STRIP,
): Descendant | Descendant[] => {
  if (Element.isElement(node) && nodesToStrip.includes(node.type)) {
    if (node.children.length > 0) {
      return stripSlateNodes(node.children).flat();
    }
  }
  return [node];
};

/**
 * Strips select nodes from inner content. Useful for copy and pasting in tables to ensure we're not copying tables elements.
 */
const stripSlateNodes = (nodes: Descendant[], nodesToStrip = NODES_TO_STRIP) => {
  return nodes.map((node) => stripSlateNode(node, nodesToStrip)).flat();
};

export default stripSlateNodes;
