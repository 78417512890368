import { Expose, Type } from 'class-transformer';

import { PiiRuleModel } from '@api/piiRules/PiiRuleModel';
import { MetadataModel } from '@models/MetadataModel';
import { Reference } from '@models/Reference';

import { TagModel } from './TagModel';

export type TaggedItemKind = 'user-defined' | 'suggestion' | 'rejected-suggestion';

type TaggedItemSuggestionSourceKind =
  | 'pii-from-columns'
  | 'lineage'
  | 'downstream_lineage'
  | 'upstream_lineage'
  | 'similarity'
  | 'unknown';

/*
 * This model exposes information about the tag, the tagged model, and where the tag came from
 * for example, is this tag a user-defined tag, a suggested tag, or a rejected tag (used to be suggested)
 * you may see this model as a field on other models that have the `tags?: TagModel[]` field.
 * This is because tagged items is part of the new tags api, and `tags` is part of the old tags api.
 * All new development should prefer `taggedItems?: TaggedItemModel[]` over `tags: TagModel[]`
 */
export class TaggedItemModel {
  kind!: TaggedItemKind;

  @Expose({ name: 'suggested_source_kind' })
  suggestedSourceKind!: TaggedItemSuggestionSourceKind;

  static objectType: 'taggeditems' = 'taggeditems';

  objectType: 'taggeditems' = TaggedItemModel.objectType;

  static typeDisplay: string = 'Tagged Item';

  typeDisplay: string = TaggedItemModel.typeDisplay;

  @Type(() => TagModel)
  tag!: TagModel;

  @Type(() => Reference)
  item?: Reference<MetadataModel>;

  @Expose({ name: 'suggestion_source_object' })
  @Type(() => Reference)
  suggestionSource?: Reference<PiiRuleModel | TaggedItemModel>;

  get mappedSuggestionSource() {
    return this.suggestionSource?.obj?.objectType === 'piirule'
      ? this.suggestionSource?.obj
      : this.suggestionSource?.obj?.item?.obj;
  }

  guid!: string;

  get option() {
    return {
      icon: this.tag.icon,
      iconColor: this.tag.color,
      text: this.tag.name,
      value: this.tag.guid,
    };
  }
}
