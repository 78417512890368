import React from 'react';

import Box from '@components/Box';
import Text from '@components/Text';
import theme from '@styles/theme';

interface SettingsSectionProps {
  subtitle?: string;
  title?: string;
}

const SettingsSection: React.FC<SettingsSectionProps> = ({ children, subtitle, title }) => {
  return (
    <Box compDisplay="flex" compWidth="100%" flexDirection="column" gap={2.75} pb={2}>
      {(title || subtitle) && (
        <Box
          borderBottom={`1px solid ${theme.colors.v1.gray[200]}`}
          compDisplay="flex"
          flexDirection="column"
          gap={0.5}
          pb={2.5}
        >
          {title && (
            <Text as="h3" fontSize="h3" fontWeight="semibold" lineHeight={theme.space(3.5)} m={0}>
              {title}
            </Text>
          )}
          {subtitle && (
            <Text as="h4" color="gray.600" fontSize="body2" lineHeight={theme.space(2.5)} m={0}>
              {subtitle}
            </Text>
          )}
        </Box>
      )}
      {children}
    </Box>
  );
};

export default SettingsSection;
