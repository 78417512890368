import { TaggedItemModel } from '@api/tags/TaggedItemModel';

interface GetInitialMapItem {
  guid: string;
  taggedItems?: TaggedItemModel[];
}

const getInitialMap = (items: GetInitialMapItem[]): { [id: string]: string[] } => {
  return items?.reduce((acc, item) => {
    if (item?.taggedItems !== undefined) {
      return {
        ...acc,
        [item.guid]: item.taggedItems?.map((t) => t.tag.guid) || [],
      };
    }
    return acc;
  }, {});
};

export default getInitialMap;
