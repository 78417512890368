import React from 'react';

import { useFetchJoins } from '@api/joins';
import TabError from '@components/TabContent/TabError';
import PopularJoinTable from '@components/Table/PopularJoinTable';
import { setParams } from '@utils/filters';
import stripSpaces from '@utils/stripSpaces';

import type { QueriesAndJoinsProps } from '../QueriesJoinsTab/QueriesJoinsTab';

const QUERY = stripSpaces(`{
  guid,
  source_table {
    name,
    guid,
    data_types
  },
  joined_table {
    name,
    guid,
    data_types
  },
  conditions {
    raw,
    left_column {
      guid,
      name
    },
    right_column {
      guid,
      name
    }
  },
  sample_query {
    guid,
    name,
    raw_sql,
    last_executed_on,
    created_by {
      guid,
      first_name,
      last_name,
      avatar
    },
    dsuser_created_by {
      name,
      display_name
    }
  },
  popularity
}`);

interface PopularJoinsProps extends QueriesAndJoinsProps {
  isColumn: boolean;
}

const PopularJoins: React.FC<PopularJoinsProps> = ({
  basic,
  isColumn,
  itemMetadata,
  requestConfig,
}) => {
  const { data, isError, isLoading } = useFetchJoins({
    params: setParams({
      order: '-popularity',
      page_size: isColumn ? 6 : 10,
      query: QUERY,
      ...requestConfig,
    }),
  });

  if (isError) return <TabError />;

  return !itemMetadata ? null : (
    <PopularJoinTable
      activeTable={itemMetadata}
      basic={basic}
      data={data?.results}
      itemCount={data?.results?.length}
      loading={isLoading}
    />
  );
};

export default React.memo(PopularJoins);
