import React from 'react';

import { useFetchMetadataComments } from '@api/metadata';
import { isNotificationAllowed } from '@components/Modal/NotifyUsersModal/utils';
import ColumnsTab from '@components/TabContent/ColumnsTab/ColumnsTab';
import useDataQualityTab from '@components/TabContent/DataQualityTab/useDataQualityTab';
import DiscussionTab from '@components/TabContent/DiscussionTab';
import PreviewTab from '@components/TabContent/PreviewTab';
import QueriesJoinsTab from '@components/TabContent/QueriesJoinsTab';
import RelatedSimilarTabs from '@components/TabContent/RelatedSimilarTabs';
import TabbedLineage from '@components/TabContent/TabbedLineage';
import TopUsersTab from '@components/TabContent/TopUsers';
import type { TabRoutesConfig } from '@components/TabsRouter';
import { useMetadataObjectContext } from '@context/MetadataObject';
import { useUserContext } from '@context/User';
import type { DataSourceTypesType } from '@models/DataSourceCredentials';
import DbtTestsTable from '@pages/DbtTestsPage/DbtTestsTable';
import MetadataTabsRouter from '@pages/MetadataObjectPage/MetadataTabsRouter';
import wrapString from '@utils/wrapString';

import type { TabContentProps } from '../types';

interface TablesTabContentProps extends TabContentProps {}

const TablesTabContent: React.FC<TablesTabContentProps> = ({
  data,
  guid,
  name,
  overviewTabConfig,
}) => {
  const { data: commentData } = useFetchMetadataComments(guid);
  const { dataSources, organization, user } = useUserContext();
  const { dataSourceGuid, dataSourceType, dataTypes } = useMetadataObjectContext();
  const { dataQualityTabConfig } = useDataQualityTab({ guid });
  const isDataSourceEditable = Boolean(dataSources?.[dataSourceGuid ?? '']?.settings?.isEditable);
  const isDBT = dataSourceType === 'dbt';
  const isGlue = dataSourceType === 'glue';
  const isDataBricks = dataSourceType === 'databricks';
  const isOpenMetadata = ['mssql', 'mysql', 'oracle', 'db2', 'salesforce'].includes(
    dataSourceType as string,
  );
  const showPreviews = organization?.settings?.showPreviews;
  const hidePreview = !showPreviews || isDBT || isDataBricks || isGlue || isOpenMetadata;
  const hideQueries = isDBT || isGlue;
  const hideTopUsers = isDBT || isGlue;
  const hideRelated = isGlue;
  const userDefaultTab = user?.settings.defaultTableDashboardTab;
  const overviewTabDefaultConfig = { default: userDefaultTab === 'overview' };
  const showColumnCategoryTags = organization?.settings?.showColumnCategoryTags ?? false;

  const tabsRouterConfig: TabRoutesConfig[] = [
    { ...overviewTabConfig, ...overviewTabDefaultConfig },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => (
        <ColumnsTab
          dataSourceType={dataSourceType}
          dataTypes={dataTypes}
          fullTableName={name}
          guid={guid}
          isDataSourceEditable={isDataSourceEditable}
          objectType="table"
        />
      ),
      default: userDefaultTab === 'columns_charts',
      label: 'Columns',
      path: '/columns/:itemId?',
      route: '/columns',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <TabbedLineage guid={guid} itemMetadata={data} objectType="table" />,
      contentOffset: 1.6,
      label: 'Lineage',
      route: '/lineage',
    },
    ...(!hideQueries
      ? [
          {
            // eslint-disable-next-line react/no-unstable-nested-components
            component: () => (
              <QueriesJoinsTab
                fullTableName={name}
                guid={guid}
                isDataSourceEditable={isDataSourceEditable}
                itemMetadata={data}
                objectType="table"
                showPopularity
              />
            ),
            contentOffset: 1.6,
            label: 'Queries & Joins',
            route: '/queries',
          },
        ]
      : []),
    ...(!hideRelated
      ? [
          {
            // eslint-disable-next-line react/no-unstable-nested-components
            component: () => (
              <RelatedSimilarTabs
                fullTableName={name}
                guid={guid}
                isDataSourceEditable={isDataSourceEditable}
                objectType="table"
                showDownstreamDashboardsAllowed={false}
                showPopularity={dataSourceType !== 'dbt'}
              />
            ),
            contentOffset: 1.6,
            label: 'Related',
            route: '/related',
          },
        ]
      : []),
    ...(isDBT
      ? [
          {
            // eslint-disable-next-line react/no-unstable-nested-components
            component: () => (
              <DbtTestsTable
                isDataSourceEditable={isDataSourceEditable}
                parentUrl={`/tables/${guid}`}
                showColumnCategoryTags={showColumnCategoryTags}
                tableGuid={guid}
              />
            ),
            contentOffset: 2,
            label: 'Tests',
            path: '/tests',
            route: '/tests',
          },
        ]
      : []),
    ...(!hideTopUsers
      ? [
          {
            // eslint-disable-next-line react/no-unstable-nested-components
            component: () => (
              <TopUsersTab
                fullTableName={name}
                guid={guid}
                isDataSourceEditable={isDataSourceEditable}
                objectType="table"
                showPopularity
              />
            ),
            contentOffset: 1.6,
            label: 'Top Users',
            route: '/topusers',
          },
        ]
      : []),
    ...(organization?.settings?.useDataQualityTab &&
    dataSourceType !== 'dbt' &&
    dataQualityTabConfig
      ? [dataQualityTabConfig]
      : []),
    ...(!hidePreview
      ? [
          {
            // eslint-disable-next-line react/no-unstable-nested-components
            component: () => <PreviewTab guid={guid} />,
            label: 'Preview',
            route: '/preview',
          },
        ]
      : []),
    ...(organization?.settings?.useDiscussions
      ? [
          {
            // eslint-disable-next-line react/no-unstable-nested-components
            component: () => (
              <DiscussionTab
                businessOwner={data?.businessOwner?.obj}
                enableNotifications={isNotificationAllowed(dataSourceType as DataSourceTypesType)}
                guid={guid}
                technicalOwner={data?.technicalOwner?.obj}
              />
            ),
            contentOffset: 2,
            label: `Discussion ${wrapString(commentData?.length)}`,
            path: '/discussion/:itemId?',
            route: '/discussion',
          },
        ]
      : []),
  ];

  return <MetadataTabsRouter config={tabsRouterConfig} />;
};

export default TablesTabContent;
