export const isAbsoluteURL = (url: string) => /^([a-z][a-z\d+\-.]*:)?\/\//i.test(url);

export const isFormData = (val: any) => {
  return typeof FormData !== 'undefined' && val instanceof FormData;
};

export const isStandardBrowserEnv = () => {
  return typeof window !== 'undefined' && typeof document !== 'undefined';
};

export const isURLSearchParams = (val: any) => {
  return typeof URLSearchParams !== 'undefined' && val instanceof URLSearchParams;
};

export const encode = (val: string) => {
  return encodeURIComponent(val)
    .replace(/%3A/gi, ':')
    .replace(/%24/g, '$')
    .replace(/%2C/gi, ',')
    .replace(/%20/g, '+')
    .replace(/%5B/gi, '[')
    .replace(/%5D/gi, ']');
};
