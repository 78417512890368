import React from 'react';

import Icon from '@components/UI/Icon';
import DataTypesModel from '@models/DataTypesModel';

import { StyledRelatedTableListItem } from './RelatedTableListStyle';

interface ItemProps {
  dataTypes?: DataTypesModel;
  name: string;
}

const Item: React.FC<ItemProps> = ({ dataTypes, name }) => {
  return (
    <StyledRelatedTableListItem>
      <Icon name={dataTypes?.icons.dataType!} size="15px" />
      <span>{name}</span>
    </StyledRelatedTableListItem>
  );
};

export default Item;
