import React from 'react';

import Icon from '@components/UI/Icon';
import { HierarchyData } from '@models/HierarchyModel';

export const SettingsHierarchy: HierarchyData[] = [
  {
    breadcrumbLabelList: [],
    children: [],
    guid: '/settings/account',
    iconEl: <Icon color="currentColor" name="account" />,
    name: 'Account',
    objectType: 'menu-item',
    url: '/settings/account',
  },
  {
    breadcrumbLabelList: [],
    children: [],
    guid: '/settings/user',
    iconEl: <Icon color="currentColor" name="user-square" />,
    name: 'User Settings',
    objectType: 'menu-item',
    url: '/settings/user',
  },
];

export const SelectStarAdminHierarchy: HierarchyData[] = [
  {
    breadcrumbLabelList: [],
    children: [],
    guid: '/mask',
    iconEl: <Icon color="currentColor" name="exchange" />,
    name: 'Mask',
    objectType: 'menu-item',
    url: '/mask',
  },
  {
    breadcrumbLabelList: [],
    children: [],
    guid: '/feature-flags',
    iconEl: <Icon color="currentColor" name="flag" />,
    name: 'Feature Flags',
    objectType: 'menu-item',
    url: '/feature-flags',
  },
];
