import { dataSources } from '@configs/dataSources/config';
import type { DataSource } from '@configs/dataSources/types';

import DataTypesModel from '@models/DataTypesModel';

interface LinksProps {
  label: string;
}

const biLinksPropsMap: PartialRecord<DataSource, LinksProps> = {
  dbt: {
    label: `To ${dataSources.dbt.text} Model`,
  },
};

export const getBiLinkLabel = (dataTypes?: DataTypesModel) => {
  const name = dataTypes?.tooltips?.dataSource;

  if (!name) return { label: 'Open external link' };

  return biLinksPropsMap[dataTypes?.dataSourceType!] ?? { label: `Open in ${name}` };
};

export default biLinksPropsMap;
