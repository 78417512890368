import React from 'react';

import { DsUserModel } from '@api/dsusers/DsUserModel';
import { TeamModel } from '@api/teams/TeamModel';
import { UserModel } from '@api/user/UserModel';
import Box from '@components/Box';
import FavoriteButton from '@components/FavoriteButton';
import Avatar from '@components/UI/Avatar';
import Icon from '@components/UI/Icon';
import { urlFor } from '@utils/routing';

import ConnectedAccounts from '../ConnectedAccounts';
import { Label } from '../Label';

import DataSourceName from './DataSourceName';
import ProfileStyled from './ProfileStyled';

interface Props {
  connectedAccounts?: DsUserModel[];
  item: UserModel | DsUserModel | TeamModel;
}

const Profile: React.FC<Props> = ({ connectedAccounts = [], item }) => {
  const objectType = item?.objectType;

  if (objectType === 'team') {
    const teamItem = item as TeamModel;
    return (
      <Box alignItems="center" compDisplay="flex">
        <Avatar {...teamItem.mappedAvatar} mr={1} size="30px" />
        <Box fontFamily="primary" fontSize="1.75rem" fontWeight="bold" lineHeight="normal">
          {teamItem && teamItem.fullName}
        </Box>
        {teamItem.name && <FavoriteButton objGuid={teamItem?.guid} objName={teamItem.name} />}
      </Box>
    );
  }

  const isDSUser = objectType === 'dsuser';
  const isUser = objectType === 'user';
  const dataSource = (item as DsUserModel)?.dataSource ? (item as DsUserModel)?.dataSource : null;
  const { dataTypes, email, mappedAvatar, team } = item as DsUserModel;

  return (
    <ProfileStyled>
      <div className="profile--container">
        <div className="icon--profile">
          <Avatar {...mappedAvatar} size="100%" />
        </div>
        <div className="profile-info">
          <div className="title-row">
            <div className="text--name">{item && item.fullName}</div>
            {item && team && (
              <Label guid={team?.guid} labelText={team?.name} url={urlFor(team, true)} />
            )}
          </div>
          {isUser && (
            <div className="email-address--container">
              <Icon className="icon--control" name="email" />
              <span className="text--email-address text">{item && email}</span>
            </div>
          )}
          <div className="subtitle-row">
            {dataSource && (
              <>
                on
                <Icon name={dataTypes?.icons.dataSource!} />
                <DataSourceName>{(item as DsUserModel).dataSource?.name}</DataSourceName>
              </>
            )}
          </div>
        </div>
      </div>
      {item && <ConnectedAccounts dsUserPage={isDSUser} users={connectedAccounts} />}
    </ProfileStyled>
  );
};

export default Profile;
