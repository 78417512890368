import React, { useState } from 'react';
import { Cell } from 'react-table';

import { DsUserModel } from '@api/dsusers/DsUserModel';
import { TeamModel } from '@api/teams/TeamModel';
import { UserModel } from '@api/user/UserModel';
import type { ColumnConfig } from '@components/Table/Table/types';
import { Reference } from '@models/Reference';
import { Filter } from '@utils';

import SearchHeader from '../Cells/SearchHeader';
import UserCell from '../Cells/UserCell';
import Table from '../Table';
import TableStyled from '../TableStyled';

const initialSortState = [
  {
    desc: true,
    id: 'downstreamDashboards',
  },
];

type DownstreamOwnersRow = Reference<DsUserModel | UserModel | TeamModel>;
interface DownstreamOwnersTableProps {
  data?: DownstreamOwnersRow[];
  filterService: Filter.FilterServiceInterface;
  itemCount?: number;
  totalPages: number;
}

const DownstreamOwnersTable: React.FC<DownstreamOwnersTableProps> = ({
  data,
  filterService,
  itemCount = 0,
  totalPages,
}) => {
  const [isShowFilter, setShowFilter] = useState(false);
  const { changePage, filter, search, sort } = filterService;

  const columns = React.useMemo(() => {
    const cols: ColumnConfig<DownstreamOwnersRow>[] = [
      {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      {
        Cell: (props: Cell<DownstreamOwnersRow>) => {
          const { row } = props;
          const owner = row.original.obj;
          const validOwner = owner instanceof DsUserModel ? owner.validOwner : owner;
          return <UserCell user={validOwner} />;
        },
        Header: `User (${itemCount})`,
        accessor: (d) => d.obj?.fullName,
        id: 'user',
        width: '130%',
      },
      {
        Header: 'Owned Downstream Dashboards',
        accessor: (d) => d?.obj?.downstreamDashboards,
        disableFilters: true,
        id: 'downstreamDashboards',
        width: '135%',
      },
      {
        Header: 'Owned Downstream Tables',
        accessor: (d) => d?.obj?.downstreamTables,
        disableFilters: true,
        id: 'downstreamTables',
        sortDescFirst: true,
        width: '135%',
      },
    ];

    return cols;
  }, [itemCount]);

  return (
    <TableStyled>
      <Table
        basic="very"
        changePage={changePage}
        className="table-full"
        columns={columns}
        compact
        data={data || []}
        disableColumnFiltering
        disableRowSelect
        initialState={{
          pageIndex: filter.page ? filter.page - 1 : 0,
          sortBy: initialSortState,
        }}
        loading={data === undefined}
        manualPagination
        manualSortBy
        setFilters={search}
        setSortBy={sort}
        showFilter={isShowFilter}
        sortable
        toggleFilter={() => setShowFilter((prev) => !prev)}
        totalPages={totalPages}
        unstackable
      />
    </TableStyled>
  );
};

export default DownstreamOwnersTable;
