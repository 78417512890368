import React, { useEffect, useState } from 'react';
import { useLocation } from '@routing/router';

import client from '@api';
import { useStickyProvider } from '@components/Sticky/Sticky';
import Tree from '@components/Tree';
import { useUserContext } from '@context/User';
import useWindowSize from '@hooks/useWindowSize';
import { HierarchyState } from '@models/HierarchyState';

import AdminSidebar from './AdminSidebar';
import { DataManagerHierarchy } from './DataManagerHierarchy';
import { SelectStarAdminHierarchy, SettingsHierarchy } from './SettingsMenuHierarchy';
import {
  StyledSidebar,
  ToggleContainer,
  ToggleIcon,
  ToggleIconContainer,
} from './SettingsSideabar.styles';

const SettingsSideBar: React.FC = () => {
  const location = useLocation();
  const { isUpLg } = useWindowSize();
  const stickyProvider = useStickyProvider();
  const [settingsState] = useState(new HierarchyState());
  const [selectStarAdminState] = useState(new HierarchyState());
  const [dataManagerState] = useState(new HierarchyState());
  const [adminState] = useState(new HierarchyState());
  const [isVisible, setVisible] = useState(true);

  settingsState.updateHierarchy(SettingsHierarchy);
  settingsState.updateActive(location.pathname);
  selectStarAdminState.updateHierarchy(SelectStarAdminHierarchy);
  selectStarAdminState.updateActive(location.pathname);
  dataManagerState.updateHierarchy(DataManagerHierarchy);
  dataManagerState.updateActive(location.pathname);
  adminState.updateActive(location.pathname);

  const { hasEditPermissions, isOrgAdmin, organization } = useUserContext();
  const useRbac = organization?.settings?.useRbac;

  useEffect(() => {
    setVisible(isUpLg);
  }, [isUpLg]);

  const accessRestricted = organization?.isTrialExpired || organization?.isSubscriptionEnded;
  const headerHeight = stickyProvider?.elementsRef.current?.AppHeader?.current?.clientHeight;

  return (
    <StyledSidebar
      defaultSize={{ height: 'auto', width: '18.75rem' }}
      enable={{ right: true }}
      top={headerHeight}
      visible={isVisible}
      width="18.75rem"
    >
      {isVisible && (
        <div className="left-nav-container">
          <div className="left-nav-title2">Settings</div>
          {!accessRestricted && (
            <div className="left-nav-list2">
              <Tree data={SettingsHierarchy} state={settingsState} />
            </div>
          )}
          {(useRbac || isOrgAdmin) && <AdminSidebar adminState={adminState} />}
          {!accessRestricted && hasEditPermissions && (
            <>
              <div className="left-nav-title2">Data Manager</div>
              <div className="left-nav-list2">
                <Tree data={DataManagerHierarchy} state={dataManagerState} />
              </div>
            </>
          )}
          {!accessRestricted && client.isStaff() && (
            <>
              <div className="left-nav-title2">Select Star Admin</div>
              <div className="left-nav-list2">
                <Tree data={SelectStarAdminHierarchy} state={selectStarAdminState} />
              </div>
            </>
          )}
        </div>
      )}
      <ToggleContainer top={headerHeight}>
        <ToggleIconContainer visible={isVisible}>
          <ToggleIcon
            onClick={() => setVisible(!isVisible)}
            onKeyDown={() => setVisible(!isVisible)}
            visible={isVisible}
          />
        </ToggleIconContainer>
      </ToggleContainer>
    </StyledSidebar>
  );
};

export default React.memo(SettingsSideBar);
