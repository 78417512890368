import styled from '@emotion/styled';

const ProfilePageStyled = styled.div`
  .tab--container {
    margin-top: 4.375rem;
  }

  .tab .ui.menu.secondary.pointing {
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  }

  .tab .ui.menu.secondary.pointing .item {
    font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
    font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
    border-bottom-width: medium;
    min-width: 7.875rem;
    justify-content: center;
    color: #aeb4bb;
  }

  .tab .ui.menu.secondary.pointing .item.active:hover,
  .tab .ui.menu.secondary.pointing .item:hover {
    color: #375fa0;
    border-color: #375fa0;
    background-color: white;
  }

  .tab .ui.secondary.pointing.menu .item.active {
    font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
    color: #0c3476;
    border-color: #375fa0;
  }
`;

export default ProfilePageStyled;
