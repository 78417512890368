import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import { MetadataModel } from '@models/MetadataModel';

import rawTransform from '../rawTransform';

export const reportsCacheKeys = {
  reportsNewestItems: ['reportsNewestItems'],
};

export const fetchReportsNewestItemsSelect = rawTransform(MetadataModel);
export const useFetchReportsNewestItems = (
  options?: UseFetchOptions<MetadataModel[], { page_size: number }>,
) => {
  return useFetch<MetadataModel[]>({
    ...options,
    queryKey: [...reportsCacheKeys.reportsNewestItems, options],
    select: fetchReportsNewestItemsSelect,
    url: '/reports/newest-items/',
  });
};
