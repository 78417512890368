import rawTransform from '@api/rawTransform';
import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import useMutation, { UseMutationOptions } from '@hooks/useMutation';

import * as cacheKeys from './cacheKeys';
import { ClientApiTokenModel } from './ClientApiTokenModel';

const fetchClientApiTokenByIdSelect = rawTransform(ClientApiTokenModel);
export const useFetchClientApiTokenById = (
  id: string,
  options?: UseFetchOptions<ClientApiTokenModel>,
) => {
  return useFetch<ClientApiTokenModel>({
    ...options,
    queryKey: cacheKeys.user(id),
    select: fetchClientApiTokenByIdSelect,
    url: `/client_api_token/${id}/`,
  });
};

export const useDeleteClientApiTokenById = (id: string, options?: UseMutationOptions) => {
  return useMutation<ClientApiTokenModel>({
    ...options,
    method: 'DELETE',
    onSuccess: (data, variables, context) => {
      const transformedData = rawTransform(ClientApiTokenModel)(data);
      options?.onSuccess?.(transformedData, variables, context);
    },
    url: `/client_api_token/${id}/`,
  });
};

export const usePutClientApiTokenById = (id: string, options?: UseMutationOptions) => {
  return useMutation<ClientApiTokenModel>({
    ...options,
    method: 'PUT',
    onSuccess: (data, variables, context) => {
      const transformedData = rawTransform(ClientApiTokenModel)(data);
      options?.onSuccess?.(transformedData, variables, context);
    },
    url: `/client_api_token/${id}/`,
  });
};
