import invalidateCache from '@api/invalidateCache';
import { piiRulesCacheKeys, usePatchPiiRule, usePostPiiRule } from '@api/piiRules';
import { PiiRuleModel, PiiRulePayload } from '@api/piiRules/PiiRuleModel';
import { renderErrorToast, renderInfoToast } from '@components/Toast';
import fetchClient from '@lib/fetchClient';
import type { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';

interface UseCreatePiiRuleFormProps {
  onError?: (error: EnhancedErrorResult) => void;
  onSuccess?: () => void;
  pii?: PiiRuleModel;
}

const useCreatePiiRuleForm = ({ onError, onSuccess, pii, ...other }: UseCreatePiiRuleFormProps) => {
  const isEditing = Boolean(pii);
  const action = isEditing ? 'updat' : 'creat';

  const handleSuccess = (res: any, payload: PiiRulePayload) => {
    fetchClient.invalidateQueries(piiRulesCacheKeys.piiRules);
    invalidateCache((keys) => [keys.activity.all]);
    renderInfoToast(`PII Rule "${payload?.name}" ${action}ed`);
    onSuccess?.();
  };

  const handleError = (error: EnhancedErrorResult, payload: PiiRulePayload) => {
    if (error?.data.regex) {
      renderErrorToast('Rule regex not valid');
    } else {
      renderErrorToast(`There was an error ${action}ing ${payload?.name}.`);
    }
    onError?.(error);
  };

  const patchQuery = usePatchPiiRule(pii?.guid ?? '', {
    onError: handleError,
    onSuccess: handleSuccess,
    ...other,
  });

  const postQuery = usePostPiiRule({
    onError: handleError,
    onSuccess: handleSuccess,
    ...other,
  });

  return { patchQuery, postQuery };
};

export default useCreatePiiRuleForm;
