import React from 'react';
import { useLocation, useParams } from '@routing/router';

import { useFetchFolder } from '@api/folder';
import CircularLoader from '@components/CircularLoader';
import NotFoundError from '@components/Error/NotFoundError';
import stripSpaces from '@utils/stripSpaces';

import JobsPage from './JobsPage';

const FOLDER_QUERY = stripSpaces(`{
  name,
  breadcrumbs,
  data_types,
  data_source{
    guid
  }
}`);

const JobsPageRouted: React.FC = () => {
  const location = useLocation();
  const { guid = '' } = useParams<{ guid?: string }>();
  const { data, isError, isLoading } = useFetchFolder(guid, { params: { query: FOLDER_QUERY } });

  const viewProps = {
    breadcrumbList: data?.breadcrumbList,
    dataTypes: data?.dataTypes,
    dsGuid: data?.dataSource?.guid,
    guid,
    key: location.pathname,
    title: data?.name,
  };

  if (!guid || isError) {
    return <NotFoundError />;
  }

  if (isLoading) {
    return <CircularLoader centered cover />;
  }

  return <JobsPage {...viewProps} />;
};

export default JobsPageRouted;
