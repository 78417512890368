import styled from '@emotion/styled';

import Box from '@components/Box';

import BaseTableStyled from './BaseTableStyled';

const TableStyled = styled(BaseTableStyled)`
  .markdown {
    span {
      user-select: text;
      line-height: 1rem;
      display: inline-block;
    }
  }

  .ui.table {
    table-layout: fixed;

    thead th {
      padding-top: 0;
      padding-bottom: 0;
    }

    &.sortable {
      thead th.sortable:not(.sorted):after {
        display: inline;
        content: '\\f0dc';
        color: #aeb4bb;
      }

      thead th.sortable {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      thead th.sortable:hover:after {
        content: '\\f0d8';
        color: rgba(0, 0, 0, 0.95);
      }

      thead th.sortable.desc:hover:after {
        content: '\\f0d7';
        color: rgba(0, 0, 0, 0.95);
      }

      thead th.sortable.sorted.ascending:hover:after {
        content: '\\f0d7';
        color: rgba(0, 0, 0, 0.95);
      }

      thead th.sortable.sorted.desc.ascending:hover:after {
        content: '';
      }

      thead th.sortable.sorted.descending:hover:after {
        content: '';
      }

      thead th.sortable.sorted.desc.descending:hover:after {
        content: '\\f0d8';
        color: rgba(0, 0, 0, 0.95);
      }
    }

    &.compact {
      tr:not(.description) {
        height: 2.5rem;
      }
    }

    tr {
      &.current-selected {
        background: rgba(0, 0, 0, 0.05) !important;
      }

      th {
        &.empty-cell {
          background-color: white;

          &.hovered {
            border-right: 0.125rem solid #7490be;
          }

          &:hover {
            background-color: white !important;
          }
        }

        &.search {
          background-color: rgb(242, 242, 242) !important;
        }

        &.filter-columns-button {
          width: 30px !important;
          max-width: 30px;
          padding: 0;
          font-size: 0.8rem !important;
          margin: 0;
          height: 100%;

          i.icon {
            height: auto !important;
            display: flex;
            align-items: center;
          }
        }
      }

      td {
        user-select: text;

        &.hovered {
          border-right: 0.125rem solid #7490be;
        }

        &.search {
          text-overflow: unset;
        }
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .table-full {
      width: 100%;
      overflow-x: auto;

      .ui {
        &.celled {
          & table {
            min-width: 23.4rem;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1300px) {
    .table-full {
      width: 100%;
      overflow-x: auto;

      .ui {
        &.celled {
          &.tableview-with-sidebar {
            min-width: 32.8rem;
          }
        }
      }
    }
  }

  .table-full {
    font-size: 0.8125rem;

    td.delete-row .text-button {
      font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
      font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
      font-size: 0.875rem;
      color: #0c3476;
      cursor: pointer;
    }
  }

  div.ui.mini.pagination.secondary.menu {
    display: flex;
    justify-content: center;
  }
`;

export default TableStyled;

export const CellWithButtons = styled(Box)`
  .inline-button {
    visibility: hidden;
  }

  .inline-button:focus-within {
    visibility: visible;
  }

  :hover {
    .inline-button {
      visibility: visible;
    }
  }
`;
