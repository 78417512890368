import { useEffect, useRef } from 'react';

interface UseOverrideCmdCtrlKeyProps {
  cmd?: boolean;
  ctrl?: boolean;
  key?: string;
  onPress: () => void;
}

const useOverrideCmdCtrlKey = ({
  cmd = true,
  ctrl = true,
  key = 'f',
  onPress,
}: UseOverrideCmdCtrlKeyProps) => {
  const memoizedOnPress = useRef(onPress);

  useEffect(() => {
    memoizedOnPress.current = onPress;
  }, [onPress]);

  useEffect(() => {
    const handlePressCmdCtrlKey = (e: KeyboardEvent) => {
      if (((cmd && e.metaKey) || (ctrl && e.ctrlKey)) && e.key === key) {
        e.preventDefault();
        memoizedOnPress.current();
      }
    };

    window.addEventListener('keydown', handlePressCmdCtrlKey);

    return () => {
      window.removeEventListener('keydown', handlePressCmdCtrlKey);
    };
  }, [cmd, ctrl, key]);
};

export default useOverrideCmdCtrlKey;
