import { UserModel } from '@api/user/UserModel';

export const formatUserNameAndTeam = (user: UserModel) => {
  const userTeam = user.team ? ` (${user.team.name})` : '';
  return `${user.fullName}${userTeam}`;
};

interface GetTextBasedOnObjectCountProps {
  count: number;
  terms: {
    plural: string;
    singular: string;
  };
}

export const getTextBasedOnObjectCount = ({
  count,
  terms: { plural, singular },
}: GetTextBasedOnObjectCountProps) => (count >= 2 ? plural : singular);
