import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { SearchModel } from '@api/search/SearchModel';
import type { QuickSearchIndexFilter, SearchResult } from '@api/search/types';
import Box from '@components/Box';
import type { TabProps, TabsProps } from '@components/Tabs';
import Tabs from '@components/Tabs';
import Checkbox from '@components/UI/Form/Checkbox';
import InputLabel from '@components/UI/Form/InputLabel';
import Icon from '@components/UI/Icon';

import { DatasourceSubtabV1, DatasourceTabV1, tabConfigDefault } from './config';
import { StyledFacetContainerV1 } from './DatasourceTabs.styles';

type MappedTab = {
  count: number;
  index: number;
  showSubtabs: boolean;
  subtabs: Array<DatasourceSubtabV1 & { count: number }>;
  tab: DatasourceTabV1;
};

const getTabs = (tabs: DatasourceTabV1[], facets?: SearchResult<SearchModel>['facets']) => {
  const result: Record<string, MappedTab> = {};
  let tabIndex = 0;

  tabs.forEach((tab) => {
    const matchedSubtabs: MappedTab['subtabs'] = [] as any;
    // get all indexes from the tab to get match count
    const tabFilterIndexes = Array.isArray(tab.filters)
      ? tab.filters.map((filter) => filter.indexes).flat(1) // quicksearch filter
      : Object.keys(tab.filters); // search filter

    const tabMachesCount = tabFilterIndexes
      .map((i) => (facets as any)?.[i] ?? 0)
      .reduce((acc, v) => acc + v, 0);

    tab?.subtabs?.forEach((subtab) => {
      let subTabCount = 0;

      subtab.indexes.forEach((i) => {
        const facet = (facets as any)?.[i];

        if (!facet) return;

        subTabCount += facet;

        matchedSubtabs.push({
          ...subtab,
          count: subTabCount,
        });
      });
    });

    const showTab = tabMachesCount > 0 || tabIndex === 0;

    const showSubtabs = matchedSubtabs.length > 0;

    if (!showTab) return;

    result[tab.name] = {
      count: tabMachesCount,
      index: tabIndex,
      showSubtabs,
      subtabs: matchedSubtabs,
      tab,
    };

    tabIndex += 1;
  });

  return result;
};

export interface DatasourceTabsV1Props extends Pick<TabsProps, 'subTabs'> {
  data?: SearchResult<SearchModel>;
  facets?: SearchResult<SearchModel>['facets'];
  hideCount?: boolean;
  onFilterChange?: (value: DatasourceTabV1['filters'], activeTab: DatasourceTabV1) => void;
  onSubtabVisibilityChange?: (value: boolean) => void;
  showResultCount?: boolean;
  tabs?: DatasourceTabV1[];
}

const DatasourceTabs: React.FC<DatasourceTabsV1Props> = ({
  data,
  facets,
  hideCount,
  onFilterChange,
  onSubtabVisibilityChange,
  showResultCount,
  subTabs = true,
  tabs = tabConfigDefault,
  ...other
}: DatasourceTabsV1Props) => {
  const [initialData, setInitialData] = useState<SearchResult<SearchModel> | undefined>();
  const [checkedSubtabs, setCheckedSubtabs] = useState<
    Partial<Record<string, MappedTab['subtabs'][number]>>
  >({});
  const activeTab = useRef<DatasourceTabV1>(tabs[0]);
  const filteredTabs = useMemo(() => getTabs(tabs, facets), [facets, tabs]);

  const handleCheck = useCallback(
    (subtab: MappedTab['subtabs'][number], tab: DatasourceTabV1) => {
      const checked = { ...checkedSubtabs };
      const checkFilters: QuickSearchIndexFilter[] = [];

      if (checked[subtab.name]) {
        delete checked[subtab.name];
      } else {
        checked[subtab.name] = subtab;
      }

      tab?.subtabs?.forEach((st) => {
        if (checked[st.name]) checkFilters.push({ indexes: st.indexes });
      });

      const filter = checkFilters.length === 0 ? tab.filters : checkFilters;

      setCheckedSubtabs(checked);
      onFilterChange?.(filter, tab);
    },
    [checkedSubtabs, onFilterChange],
  );

  const handleTabChange = useCallback(
    (showIndex: boolean, tab: DatasourceTabV1) => {
      setCheckedSubtabs({});
      activeTab.current = tab;

      onSubtabVisibilityChange?.(showIndex);
      onFilterChange?.(tab.filters, tab);
    },
    [onSubtabVisibilityChange, onFilterChange],
  );

  const tabsConfig = useMemo(() => {
    const result: TabProps[] = Object.keys(filteredTabs).map((k) => {
      const { count, showSubtabs, subtabs, tab } = filteredTabs[k];
      return {
        // eslint-disable-next-line react/no-unstable-nested-components
        component: () =>
          showSubtabs && (
            <StyledFacetContainerV1>
              {subtabs.map((subtab) => {
                const label = `${subtab.name} (${subtab.count})`;

                return (
                  <InputLabel key={subtab.name} alignItems="center" gap={0.5} px={0.8}>
                    <Checkbox
                      checked={Boolean(checkedSubtabs?.[subtab.name])}
                      data-testid={label}
                      onClick={() => {
                        handleCheck(subtab, tab);
                      }}
                    />
                    {label}
                  </InputLabel>
                );
              })}
            </StyledFacetContainerV1>
          ),
        icon: tab.icon && <Icon name={tab.icon} size="16px" />,
        id: tab.name,
        label: hideCount ? tab.name : `${tab.name} (${count})`,
        onTabChange: () => {
          handleTabChange(showSubtabs, tab);
        },
        variant: 'flat' as const,
      };
    });

    if (initialData && initialData?.total > 0 && showResultCount) {
      result.push({
        as: 'div',
        // eslint-disable-next-line react/no-unstable-nested-components
        component: () => null,
        cursor: 'default',
        id: 'count',
        label: (
          <Box color="gray.400" fontSize="body2">
            {initialData?.total > 1000 ? '1000+' : initialData?.total} results ({initialData?.shown}{' '}
            shown)
          </Box>
        ),
        ml: 'auto',
        onTabChange: () => {},
        variant: 'flat',
        zIndex: 1,
      });
    }
    return result;
  }, [
    checkedSubtabs,
    filteredTabs,
    handleCheck,
    handleTabChange,
    hideCount,
    initialData,
    showResultCount,
  ]);

  useEffect(() => {
    // reset tab selection if the active tab doesnt have matches
    const filteredKeys = Object.keys(filteredTabs);
    if (filteredKeys.length !== 0 && !filteredTabs[activeTab.current.name]) {
      handleTabChange(false, filteredTabs[filteredKeys[0]].tab);
    }
  }, [filteredTabs, handleTabChange, initialData?.data]);

  useEffect(() => {
    if (!initialData && data) {
      setInitialData(data);
    }

    if (!data?.total) {
      setInitialData(undefined);
    }
  }, [data, initialData]);

  return (
    <Tabs
      activeTab={filteredTabs[activeTab.current.name]?.index ?? 0}
      config={tabsConfig}
      overflowVariant="scroll"
      subTabs={subTabs}
      {...other}
    />
  );
};

export default DatasourceTabs;
