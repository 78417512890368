import styled from '@emotion/styled';

import Box from '@components/Box';

interface StyledTileProps {
  hasError?: boolean;
}

export const StyledTile = styled(Box)<StyledTileProps>`
  display: flex;
  align-items: center;
  border: 1px solid
    ${({ hasError, theme }) => (hasError ? theme.colors.v1.error[500] : theme.colors.v1.gray[300])};
  border-radius: ${({ theme }) => theme.radius.md};
  height: ${({ theme }) => theme.space(3.25)};
  gap: ${({ theme }) => theme.space(0.5)};
  padding: ${({ theme }) => theme.space(0, 0.5, 0, 0.75)};
  background-color: ${({ theme }) => theme.colors.white};
  cursor: default;
`;
