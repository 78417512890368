import React from 'react';
import type { ResizableProps as ReResizableProps } from 're-resizable';

import { StyledResizable } from './Resizable.styles';

interface ResizableProps extends Omit<ReResizableProps, 'handleClasses'> {}

const Resizable: React.FC<ResizableProps> = ({ children, ...other }) => {
  return (
    <StyledResizable
      {...other}
      handleClasses={{
        bottom: 'resizable-line',
        left: 'resizable-line',
        right: 'resizable-line',
        top: 'resizable-line',
      }}
      handleStyles={{
        left: { left: 0, width: 3 },
        right: { right: -3, width: 3 },
      }}
    >
      {children}
    </StyledResizable>
  );
};

export default Resizable;
