import React from 'react';

import Box from '@components/Box';
import { Column, Row } from '@components/Grid';
import Tabs from '@components/Tabs/Tabs';

import type { AdminAppCardProps } from './AdminAppCard';
import AdminAppCard from './AdminAppCard';

interface App extends AdminAppCardProps {
  id: string;
}

interface AdminAppsContainerProps {
  apps: App[];
  'data-testid'?: string;
  title: string;
}

const AdminAppsContainer = ({ apps, 'data-testid': testId, title }: AdminAppsContainerProps) => {
  const content = () => (
    <Box data-testid={testId} minHeight="300px" py={2.5}>
      <Row flexWrap="wrap">
        {apps.map(({ connected, description, icon, id, name, onClick, onConnect }) => (
          <Column key={id} flexShrink={0} lg={4} md={6} sm={12}>
            <AdminAppCard
              connected={connected}
              description={description}
              icon={icon}
              name={name}
              onClick={onClick}
              onConnect={onConnect}
            />
          </Column>
        ))}
      </Row>
    </Box>
  );

  return (
    <Box>
      <Tabs
        config={[
          {
            component: content,
            id: '0',
            label: title,
          },
        ]}
      />
    </Box>
  );
};

export default AdminAppsContainer;
