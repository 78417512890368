import React from 'react';
import { useTheme } from '@emotion/react';
import Tippy, { TippyProps } from '@tippyjs/react';
import { followCursor as followCursorPlugin, Instance, Placement } from 'tippy.js';

import { StyledTooltipText } from './Tooltip.styles';

export interface TooltipProps {
  action?: boolean;
  animation?: TippyProps['animation'];
  appendTo?: Element | 'parent' | ((ref: Element) => Element);
  children: React.ReactElement;
  content?: React.ReactNode;
  delay?: number;
  followCursor?: boolean;
  forceDefaultBackground?: boolean;
  interactive?: boolean;
  offset?: [number, number];
  placement?: Placement;
  targetRef?: React.RefObject<HTMLElement>;
  trigger?: TippyProps['trigger'];
}

const Tooltip: React.FC<TooltipProps> = ({
  action,
  animation,
  appendTo = document.body,
  children,
  content,
  delay = 50,
  followCursor = true,
  forceDefaultBackground = false,
  interactive,
  offset,
  placement = 'auto-end',
  targetRef,
  trigger,
}) => {
  const { zIndex } = useTheme();

  if (content === undefined || content === '' || content === null) return children;

  const parsedContent =
    ['string', 'number'].includes(typeof content) || forceDefaultBackground ? (
      <StyledTooltipText isAction={action}>{content}</StyledTooltipText>
    ) : (
      content
    );

  const handleOnMount = (instance: Instance) => {
    const scrollBoxElement = (instance.props.content as Element).firstChild as Element;

    const hasScrollableContent =
      scrollBoxElement && scrollBoxElement.scrollHeight > scrollBoxElement.clientHeight;

    instance.setProps({
      interactive: hasScrollableContent || interactive,
      zIndex: zIndex.floatingElement,
    });
  };

  return (
    <Tippy
      allowHTML
      animation={animation}
      appendTo={appendTo}
      content={parsedContent}
      delay={[delay, 0]}
      followCursor={followCursor}
      interactive={interactive}
      maxWidth={276}
      offset={offset}
      onMount={handleOnMount}
      placement={placement}
      plugins={[followCursorPlugin]}
      reference={targetRef || null}
      role="tooltip"
      trigger={trigger}
    >
      {children}
    </Tippy>
  );
};

export default Tooltip;
