import React from 'react';
import { RouterLink, useParams } from '@routing/router';

import { useFetchWorkspacesHierarchy } from '@api/workspaces';
import Box from '@components/Box';
import Breadcrumbs from '@components/Breadcrumbs';
import Link from '@components/Link/Link.styles';
import Text from '@components/Text';
import getUrl from '@components/Tree/getUrl';
import TreeV2 from '@components/Tree/Tree.v2';
import Image from '@components/UI/Image';
import useNewLayout from '@hooks/useNewLayout';
import theme from '@styles/theme';

import HierarchyLoadingSkeleton from '../HierarchyLoadingSkeleton';

import FavoritesHierarchyItemIcons from './FavoritesHierarchyItemIcons';

const FavoritesHierarchy: React.FC = () => {
  const { shouldUseNewLayout } = useNewLayout();

  const { data, isLoading } = useFetchWorkspacesHierarchy({
    params: {
      order: 'created_on',
    },
  });
  const { guid } = useParams<{ guid: string }>();

  if (isLoading) {
    return <HierarchyLoadingSkeleton />;
  }

  if (!data?.children || data?.children?.length === 0) {
    return (
      <Box
        alignItems="center"
        compDisplay="flex"
        compWidth="100%"
        flexDirection="column"
        gap={2}
        mt={10}
      >
        <Image alt="No chats" height="auto" src="/images/empty-favorites.png" width="140px" />
        <Text color="gray.400" fontSize="body2" fontWeight="medium" textAlign="center">
          There are no items here yet
        </Text>
      </Box>
    );
  }

  return (
    <Box
      compDisplay={isLoading ? 'none' : 'block'}
      compHeight="100%"
      overflowX="hidden"
      overflowY="auto"
    >
      <TreeV2
        allowCaret={() => false}
        allowDataLoad={() => false}
        getKey={(item) => item.guid}
        highlightedKeys={[guid]}
        isFlatHierarchy={shouldUseNewLayout}
        renderLabel={({ original }) => {
          const url =
            getUrl({
              dataType: original.dataType,
              dataTypes: original.dataTypes,
              dsGuid: original.dataSourceGuid,
              guid: original.guid,
              objectType: original.objectType,
              showSchemataPage: true,
              type: original.dataSourceType,
            }) ?? '#';

          return (
            <Link
              as={RouterLink}
              color="inherit"
              compDisplay="block"
              compWidth="100%"
              fontSize="inherit"
              to={url}
            >
              <Box
                alignItems="center"
                as="span"
                compDisplay="flex"
                gap={0.5}
                py={shouldUseNewLayout ? 0.625 : 0.75}
              >
                <FavoritesHierarchyItemIcons
                  color={original.color}
                  dataSourceIcon={original.dataTypes?.icons.dataSource}
                  dataTypeIcon={original.dataTypes?.icons.dataType!}
                  guid={original.guid}
                  icon={original.icon}
                  name={original.name}
                  objectType={original.dataTypes?.objectType}
                />
                {original.breadcrumbLabelList ? (
                  <Breadcrumbs
                    color="inherit"
                    fixedUrl={url}
                    fontSize={theme.typography.fontSizes.body1}
                    fontWeight="unset"
                    items={original.breadcrumbLabelList}
                    showTrailingSlash={false}
                  />
                ) : (
                  original.name
                )}
              </Box>
            </Link>
          );
        }}
        treeData={data?.children}
        useNewLayout={shouldUseNewLayout}
      />
    </Box>
  );
};

export default FavoritesHierarchy;
