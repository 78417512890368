import React from 'react';

import { Column, GridContainer, Row } from '@components/Grid';
import ToastContainer from '@components/ToastContainer';

interface TwoColumnLayoutProps {
  left: React.ReactNode;
  right: React.ReactNode;
}

const TwoColumnLayout: React.FC<TwoColumnLayoutProps> = ({ left, right }) => {
  return (
    <GridContainer fluid hPaddingSpace={0}>
      <ToastContainer fixed />
      <Row alignItems="center" flexDirection="row" flexGrow={0} justifyContent="center" space={0}>
        <Column md={7} sm={12}>
          {left}
        </Column>
        <Column compHeight="100%" hideDown={['sm', 'xs', 'md']} md={5} sm={12}>
          {right}
        </Column>
      </Row>
    </GridContainer>
  );
};

export default TwoColumnLayout;
