import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import type { UseMutationOptions } from '@hooks/useMutation';
import useMutation from '@hooks/useMutation';
import { PaginatedResponse } from '@models/PaginatedResponse';

import paginatedTransform from '../paginatedTransform';
import rawTransform from '../rawTransform';

import { OrganizationInvitationModel } from './OrganizationInvitationModel';
import { OrganizationInvitationVerifyModel } from './OrganizationInvitationVerifyModel';

export const invitationsCacheKeys = {
  invitation: (id: string) => ['invitations', id],
  invitations: ['invitations'],
};

export const usePostInvitation = (options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    method: 'POST',
    url: '/invitations/',
  });
};

export const usePatchInvitation = (url?: string, options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    method: 'PATCH',
    url,
  });
};

export const useDeleteInvitation = (id: string, options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    method: 'DELETE',
    url: `/invitations/${id}/`,
  });
};

const fetchInvitationsSelect = paginatedTransform(OrganizationInvitationModel);
export const useFetchInvitations = (options?: UseFetchOptions) => {
  return useFetch<PaginatedResponse<OrganizationInvitationModel>>({
    ...options,
    queryKey: [...invitationsCacheKeys.invitations, options?.params],
    select: fetchInvitationsSelect,
    url: '/invitations/',
  });
};

export const fetchInvitationSelect = rawTransform(OrganizationInvitationVerifyModel);
export const useFetchInvitation = (id: string, options?: UseFetchOptions) => {
  return useFetch<OrganizationInvitationVerifyModel>({
    ...options,
    queryKey: [...invitationsCacheKeys.invitation(id), options?.params],
    select: fetchInvitationSelect,
    url: `/invitations/${id}/`,
  });
};

export const usePostResendInvitation = (id?: string, options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    method: 'POST',
    url: `invitations/${id}/resend/`,
  });
};
