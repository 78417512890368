import React from 'react';
import { useRecoilValue } from 'recoil';

import { metadataType } from '@atoms';
import { FiltersSidebar, FiltersSidebarProps } from '@components/FiltersSidebar';
import DataTypesModel from '@models/DataTypesModel';

export interface TableFiltersProps
  extends Pick<FiltersSidebarProps, 'dataSourceType' | 'filterService'> {
  dataTypes?: DataTypesModel;
}

const TableFilters: React.FC<TableFiltersProps> = ({
  dataSourceType,
  dataTypes,
  filterService,
}) => {
  const metadata = useRecoilValue(metadataType);
  return (
    <FiltersSidebar
      dataSourceType={dataSourceType}
      enableSchemaFilter={
        dataTypes?.dataSourceType !== 'dbt' &&
        dataTypes?.objectType !== 'schema' &&
        ['columns', 'tables'].includes(metadata)
      }
      enableTableFilter={dataTypes?.dataSourceType !== 'dbt' && metadata === 'columns'}
      filterService={filterService}
      isCategoryTags={metadata === 'tables'}
      isColumnDataType={metadata === 'columns'}
      isDataType
      isDbtTags
      isOwners={metadata === 'tables'}
      isPopularity
      isSelectStarDetected
      isStatusTags
      showHidden={metadata === 'tables'}
      showJoinKeyFilter={metadata === 'columns'}
    />
  );
};

export default React.memo(TableFilters);
