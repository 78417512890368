import React, { useMemo } from 'react';
import { useTheme } from '@emotion/react';
import copy from 'copy-to-clipboard';

import { getBiLinkLabel } from '@components/BILink/biLinksPropsMap';
import Box from '@components/Box/Box.styles';
import {
  DropDownMenuItemStyled,
  DropDownMenuPopupStyled,
} from '@components/TableCellDropdown/TableCellDropdown.styles';
import { renderInfoToast } from '@components/Toast';
import Icon from '@components/UI/Icon';
import DataTypesModel from '@models/DataTypesModel';

import { StyledDownstreamChartsTabOptionsButton } from './DownstreamChartsTab.styles';

export interface DownstreamChartsTabDropdownProps {
  copyPath?: string;
  dataSourceType?: string;
  dataTypes?: DataTypesModel;
  externalUrl?: string;
  routePath?: string;
}

const DownstreamChartsTabDropdown: React.FC<DownstreamChartsTabDropdownProps> = ({
  copyPath,
  dataTypes,
  externalUrl,
  routePath,
}) => {
  const { zIndex } = useTheme();

  const dropdownItems = useMemo(() => {
    const items = [];

    if (copyPath) {
      items.push({
        icon: <Icon color="#44464A" name="copy" size="14px" />,
        label: 'Copy Path',
        onClick: () => {
          copy(copyPath);
          renderInfoToast('Full name copied to clipboard');
        },
      });
    }

    if (routePath) {
      items.push({
        icon: <Icon color="#44464A" name="open-new-tab" size="14px" />,
        label: 'Open in Select Star',
        onClick: () => {
          window.open(
            process.env.REACT_APP_CHROME_EXTENSION_BUILD
              ? `${process.env.REACT_APP_SS_APP_URL}${routePath}`
              : routePath,
            '_blank',
            'noopener,noreferrer',
          );
        },
      });
    }

    if (externalUrl && dataTypes) {
      items.push({
        icon: (
          <Icon id={dataTypes?.dataSourceType} name={dataTypes?.icons?.dataSource!} size="14px" />
        ),
        label: getBiLinkLabel(dataTypes).label,
        onClick: () => {
          window.open(externalUrl, '_blank', 'noopener,noreferrer');
        },
      });
    }

    return items;
  }, [copyPath, routePath, externalUrl, dataTypes]);

  if (dropdownItems.length === 0) {
    return null;
  }

  return (
    <DropDownMenuPopupStyled
      basic
      hoverable
      offset={[-5, -10]}
      on="click"
      pinned
      position="bottom left"
      style={{
        padding: 0,
      }}
      trigger={
        <Box compWidth="30px" justifyContent="center">
          <StyledDownstreamChartsTabOptionsButton fontSize="16px">
            <Icon color="#6f747c" name="ellipsis" />
          </StyledDownstreamChartsTabOptionsButton>
        </Box>
      }
    >
      <Box compWidth="169px" zIndex={zIndex.sidebar}>
        {dropdownItems.map((item) => (
          <DropDownMenuItemStyled
            key={item.label}
            onClick={(e) => {
              e.stopPropagation();
              item?.onClick?.();
            }}
          >
            {item.icon}
            <span>{item.label}</span>
          </DropDownMenuItemStyled>
        ))}
      </Box>
    </DropDownMenuPopupStyled>
  );
};

export default DownstreamChartsTabDropdown;
