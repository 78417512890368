export const colors = {
  columnArrowCircleFill: '#ffffff',

  columnArrowCircleStroke: '#cfd9e7',

  headingFill: '#f2f5f9',

  headingFillHover: '#d5ddeb',

  headingIconBGCloseFillHover: '#2c2e36',

  headingIconBGFillHover: '#b5c3dc',

  // arrows from column to column
  hoveredColumnArrowColor: '#e3649b',

  hoveredColumnFill: '#eedbe3',

  manualLineageColor: '#9269FF',

  pinnedColumnArrowColor: '#4c288f',

  pinnedColumnFill: '#d5cfe1',

  removeArrowColor: '#cc5f36',

  tableFill: '#ffffff',

  // arrows from table to table
  tableLinkColor: '#7490be',

  tableLinkHoverColor: '#F38817',

  tableStroke: '#cfd9e7',

  tableStrokeHover: '#375fa0',

  userPinnedColumnFill: '#C1C4DA',
};

export const exploreErdColors = {
  default: '#F3F2FF',
  hover: '#e8e7f8',
  iconHover: colors.headingIconBGFillHover,
  tableStroke: '#A896C9',
  tableStrokeHover: '#4C288F',
};
