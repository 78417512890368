import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { useFetchCostAnalysisTime } from '@api/costAnalysis';
import type TimeModel from '@api/costAnalysis/TimeModel';
import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import theme from '@styles/theme';

import type { CostAnalysisSharedFilterParams } from '../../CostAnalysisSharedFilter';
import { colors } from '../constants';
import TooltipBoxValue from '../Tooltip/TooltipBoxValue';
import type { TooltipContentProps } from '../Tooltip/TooltipContent';
import TooltipContent from '../Tooltip/TooltipContent';
import type { TooltipContentPayload } from '../Tooltip/types';
import { formatN } from '../utils';

import { formatValue, mapToWeekly } from './utils';
import { StyledWeeklyUsageCostChart } from './WeeklyUsageCostChart.styles';

interface CustomTooltipPayload extends Array<{ name: string; value: string }> {}

const CustomTooltipItem = ({ value }: TooltipContentPayload<CustomTooltipPayload>) => {
  return (
    <>
      {value.map((payload: { name: string; value: string }) => (
        <TooltipBoxValue
          key={payload.name}
          divisor={false}
          name={payload.name}
          value={payload.value}
        />
      ))}
    </>
  );
};

const CustomTooltipContent = (props: TooltipContentProps<CustomTooltipPayload>) => {
  const { payload } = props;
  const hasCreditsUsed = payload?.[0]?.payload?.totalCreditsUsed > 0;
  return hasCreditsUsed ? <TooltipContent {...props} Child={CustomTooltipItem} /> : null;
};
const customLegendLabel = (legend: string) => {
  const isLabel = legend === 'Avg Credit';

  return (
    <Box as="span" color={colors.legend} fontWeight={isLabel ? 'semibold' : 'regular'}>
      {legend}
    </Box>
  );
};

const rangeColor = ['#EFF4FF', '#E5ECFF', '#84ADFF', '#528BFF', '#4E55EB'] as const;

export interface WeeklyUsageCostChartProps extends CostAnalysisSharedFilterParams {
  active?: TimeModel;
  onBarClick?: (value: TimeModel) => void;
}

const WeeklyUsageCostChart: React.FC<WeeklyUsageCostChartProps> = ({
  active,
  onBarClick,
  range,
  warehouses,
}) => {
  const { data: timeData, isLoading } = useFetchCostAnalysisTime({
    params: {
      aggregation: 'hourly',
      end_date: range[1],
      start_date: range[0],
      warehouses,
    },
  });

  const weeklyData = useMemo(() => mapToWeekly(timeData), [timeData]);

  const legendPayload = weeklyData.ranges.map(([min, max], index) => ({
    color: rangeColor[index],
    type: 'circle' as const,
    value: `${formatN(min)} - ${formatN(max)}`,
  }));

  const handleClick = useCallback(
    (entry: { payload: TimeModel }) => {
      onBarClick?.(entry.payload);
    },
    [onBarClick],
  );

  return (
    <StyledWeeklyUsageCostChart>
      {isLoading ? (
        <CircularLoader cover />
      ) : (
        weeklyData.data.map(({ data, day }) => {
          const isLast = day === 6;
          const weekName = moment.utc().weekday(day).format('ddd');

          return (
            <ResponsiveContainer key={day} height={isLast ? 110 : 50} width="100%">
              <BarChart data={data}>
                {!isLast && (
                  <CartesianGrid horizontalPoints={[45]} stroke={colors.grid} vertical={false} />
                )}
                <YAxis
                  axisLine={false}
                  dataKey="totalCreditsUsed"
                  label={{ fill: colors.axisLabel, position: 'insideBottomLeft', value: weekName }}
                  mirror
                  tick={false}
                  tickLine={false}
                />
                <XAxis
                  axisLine={{ stroke: colors.grid }}
                  dataKey="aggregation.hour"
                  fontSize={13}
                  hide={!isLast}
                  padding={{ left: 70, right: 20 }}
                  stroke={colors.axisText}
                  tick={isLast}
                  tickCount={24}
                  tickFormatter={(value) => `${value}:00`}
                  tickLine={false}
                  type="number"
                />
                <Tooltip
                  content={CustomTooltipContent as any}
                  cursor={{ fill: 'white' }}
                  formatter={formatValue}
                  isAnimationActive={false}
                  offset={30}
                  wrapperStyle={{ zIndex: 100 }}
                />
                <Bar
                  dataKey="totalCreditsUsed"
                  minPointSize={3}
                  onClick={handleClick}
                  radius={[4, 4, 0, 0]}
                >
                  {data.map((entry) => {
                    const index = weeklyData.ranges.findIndex(([min, max]) => {
                      const value = entry.totalCreditsUsed;
                      return value >= min && value <= max;
                    });
                    const cellColor = rangeColor[index];
                    const hasCreditsUsed = entry.totalCreditsUsed > 0;
                    return (
                      <Cell
                        key={entry.guid}
                        cursor="pointer"
                        display={!hasCreditsUsed ? 'none' : undefined}
                        fill={
                          entry.guid === active?.guid ? theme.colors.v1.primary[500] : cellColor
                        }
                      />
                    );
                  })}
                </Bar>
                {isLast && (
                  <Legend
                    formatter={customLegendLabel}
                    iconSize={8}
                    payload={[
                      {
                        color: 'white',
                        value: 'Avg Credit',
                      },
                      ...legendPayload,
                    ]}
                    wrapperStyle={{
                      paddingTop: 8,
                    }}
                  />
                )}
              </BarChart>
            </ResponsiveContainer>
          );
        })
      )}
    </StyledWeeklyUsageCostChart>
  );
};

export default WeeklyUsageCostChart;
