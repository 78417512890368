import moment from 'moment';

import type { BreadcrumbPayload } from '@api/breadcrumbs/BreadcrumbModel';
import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import { DescriptionSource } from '@api/description/description.types';
import { DsUserModel } from '@api/dsusers/DsUserModel';
import { TaggedItemModel } from '@api/tags/TaggedItemModel';
import type { ObjectType } from '@api/types';
import { rawClassArrayMap, rawClassMap, rawMap } from '@api/utils';
import isEmptyRichText from '@components/RichTextEditor/helpers/isEmptyRichText';
import type { DataTypesPayload } from '@models/DataTypesModel';
import DataTypesModel from '@models/DataTypesModel';
import { PopularityModel } from '@models/PopularityModel';
import RelatedObjectsCountsModel from '@models/RelatedObjectsCountsModel';
import { urlFor } from '@utils/routing';

export interface DatasetPayload {
  ai_description: string;
  breadcrumbs: BreadcrumbPayload[];
  created_at: string;
  data_types: DataTypesPayload;
  datasource_guid: string;
  description: string;
  description_source: DescriptionSource;
  downstream_objects_counts: {};
  dsuser_created_by: {};
  external_id: string;
  guid: string;
  ingested_description: string;
  is_hidden: boolean;
  name: string;
  popularity: {};
  richtext_description: string;
  source_tables_count: number;
  tagged_items: [];
  updated_at: string;
  upstream_objects_counts: {};
  url: string;
  user_description: string;
}

const objectType: ObjectType = 'powerbidataset';

const mapDatasetModel = (res: DatasetPayload) => {
  const dataTypes = rawClassMap(DataTypesModel, res.data_types);
  const dsuserCreatedBy = rawClassMap(DsUserModel, res?.dsuser_created_by);
  const formattedDsuserCreatedBy = dsuserCreatedBy?.user?.fullName
    ? dsuserCreatedBy?.user
    : dsuserCreatedBy;
  const breadcrumbs = rawClassArrayMap(BreadcrumbModel, res?.breadcrumbs);

  return {
    aiDescription: res.ai_description,
    breadcrumbList: breadcrumbs,
    breadcrumbs,
    createdAt: rawMap(moment, res.created_at),
    dataSourceGuid: res.datasource_guid,
    dataTypes,
    description: res.description,
    descriptionSource: res.description_source,
    downstreamObjectsCounts: rawClassMap(RelatedObjectsCountsModel, res?.downstream_objects_counts),
    dsuserCreatedBy,
    externalId: res.external_id,
    externalUrl: res.url,
    formattedDsuserCreatedBy,
    guid: res.guid,
    ingestedDescription: res.ingested_description,
    isHidden: res.is_hidden,
    name: res.name,
    objectType: objectType as ObjectType,
    popularity: rawClassMap(PopularityModel, res?.popularity),
    richtextDescription: isEmptyRichText(res.richtext_description) ? '' : res.richtext_description,
    routePath: urlFor({ dataTypes, guid: res.guid }),
    showCustomAttributes: true,
    showMentionedBy: true,
    sourceTablesCount: res.source_tables_count,
    taggedItems: rawClassArrayMap(TaggedItemModel, res?.tagged_items),
    updatedAt: rawMap(moment, res.updated_at),
    upstreamObjectsCounts: rawClassMap(RelatedObjectsCountsModel, res?.upstream_objects_counts),
    userDescription: res.user_description,
  };
};

export default mapDatasetModel;

mapDatasetModel.objectType = objectType;

export type DatasetModel = ReturnType<typeof mapDatasetModel>;
