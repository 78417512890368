import { Expose, Transform, Type } from 'class-transformer';
import moment from 'moment';

import { ColumnModel } from '@api/columns/ColumnModel';
import { StatusType } from '@api/types';

import DbtTestModel from './DbtTestModel';

class DbtRunModel {
  guid!: string;

  @Expose({ name: 'data_source' })
  dataSource!: string;

  name!: string;

  @Type(() => ColumnModel)
  column!: ColumnModel;

  @Expose({ name: 'compiled_sql' })
  compiledSql!: string;

  @Expose({ name: 'completed_at' })
  @Transform((value) => (value ? moment(value) : undefined))
  completedAt?: moment.Moment;

  @Expose({ name: 'error_message' })
  errorMessage!: string;

  @Expose({ name: 'execution_time' })
  executionTime!: number;

  status!: StatusType;

  @Type(() => DbtTestModel)
  test!: DbtTestModel;
}

export default DbtRunModel;
