import React from 'react';
import { Cell } from 'react-table';

import AnalyticsPageModel from '@api/analytics/AnalyticsPageModel';
import { TagModel } from '@api/tags/TagModel';
import AppTag from '@components/AppTag';
import ExcerptText from '@components/ExcerptText';
import LinkedCell from '@components/Table/Cells/LinkedCell';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import { FilterServiceInterface } from '@utils/filters';

interface TopPagesTableProps {
  detailed?: boolean;
  filterService?: FilterServiceInterface;
  loading: boolean;
  rows?: AnalyticsPageModel[];
  totalPages?: number;
}

const TopPagesTable: React.FC<TopPagesTableProps> = ({
  detailed = false,
  filterService,
  loading,
  rows = [],
  totalPages,
}) => {
  const columns: ColumnConfig<AnalyticsPageModel>[] = [
    {
      Cell: (props: Cell<AnalyticsPageModel>) => {
        const { row } = props;
        const item = {
          dataType: row.original.pageDataType,
          dataTypes: row.original.dataTypes,
          guid: row.original.pageGuid,
          name: row.original.pageName,
          objectType: row.original.pageObjectType,
          parentGuid: row.original.pageParentGuid,
        };

        return <LinkedCell {...props} item={item} noLinkOnInvalidUrl showIcon showNameTooltip />;
      },
      Header: 'Page',
      accessor: (d) => d.pageName,
      disableFilters: true,
      disableHiding: true,
      id: 'page',
      width: detailed ? '25%' : '50%',
    },
    {
      Cell: (props: Cell<AnalyticsPageModel>) => {
        const { row } = props;
        return <>{row.original.count}</>;
      },
      Header: 'Page Views',
      accessor: (d) => d.count,
      disableFilters: true,
      disableHiding: true,
      id: 'count',
      width: detailed ? '15%' : '50%',
    },
    ...(detailed
      ? [
          {
            Cell: (props: Cell<AnalyticsPageModel>) => {
              const { row } = props;
              return <ExcerptText value={row.original.description} />;
            },
            Header: 'Description',
            disableFilters: true,
            disableHiding: true,
            disableSortBy: true,
            id: 'description',
            width: '35%',
          },
          {
            Cell: (props: Cell<AnalyticsPageModel>) => {
              const { row } = props;
              return row.original.tags?.map((tag: TagModel) => (
                <AppTag key={tag.guid} tag={tag} variant="outlined" />
              ));
            },
            Header: 'Tags',
            disableFilters: true,
            disableHiding: true,
            disableSortBy: true,
            id: 'tags',
            width: '25%',
          },
        ]
      : []),
  ];

  return (
    <TableStyled>
      <Table
        basic="very"
        changePage={filterService?.changePage}
        className="table-full"
        columns={columns}
        compact
        data={rows}
        disableColumnFiltering
        disableFilters
        disablePagination={!detailed}
        disableRowSelect
        disableSortBy={!detailed}
        initialState={{
          pageIndex: filterService ? filterService!.filter.page! - 1 : 0,
          sortBy: [
            {
              desc: true,
              id: 'count',
            },
          ],
        }}
        loading={loading}
        manualPagination={detailed}
        manualSortBy={detailed}
        setSortBy={filterService?.sort}
        sortable={detailed}
        stickyHeader={detailed}
        totalPages={totalPages}
        unstackable
      />
    </TableStyled>
  );
};

export default TopPagesTable;
