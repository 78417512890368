import styled from '@emotion/styled';

import Icon from '@components/UI/Icon';

interface StyledCaretButtonIconProps {
  isOpen?: boolean;
}
export const StyledCaretButtonIcon = styled(Icon)<StyledCaretButtonIconProps>`
  transition: all 300ms ease;
  transform: rotate(${({ isOpen }) => (isOpen ? '180deg' : '0')});
`;
