import React from 'react';

import Checkbox from '@components/UI/Form/Checkbox';
import InputLabel from '@components/UI/Form/InputLabel';

// Search module imports
import { SearchFacetType } from '../SearchTypes';

export interface FacetNamesFiltersTypes {
  [index: string]: boolean;
}

interface Props {
  checkedFilters: FacetNamesFiltersTypes;
  facet: SearchFacetType;
  facetCount: number;
  setCheckedFilters: React.Dispatch<React.SetStateAction<FacetNamesFiltersTypes>>;
}

const SearchFacetCheckbox: React.FC<Props> = ({
  checkedFilters,
  facet,
  facetCount,
  setCheckedFilters,
}) => {
  const handleCheck = () => {
    setCheckedFilters((prev) => {
      const prevChecked = { ...prev };
      facet.name.forEach((name) => {
        prevChecked[name] = !prevChecked[name];
      });

      return prevChecked;
    });
  };

  return (
    <InputLabel key={facet.name[0]} color="gray.500" fontSize="default" gap={0.8} mx={1}>
      <Checkbox checked={checkedFilters[facet.name[0]]} onClick={handleCheck} />
      {`${facet.label} (${facetCount})`}
    </InputLabel>
  );
};

export default SearchFacetCheckbox;
