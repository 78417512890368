import React from 'react';

import { useFetchTaggedItems } from '@api/taggedItems';
import CircularLoader from '@components/CircularLoader';
import TabError from '@components/TabContent/TabError';
import TaggedItemsTable from '@components/Table/TaggedItemsTable';
import { Filter } from '@utils';
import stripSpaces from '@utils/stripSpaces';

const query = stripSpaces(`{
  kind,
  item{
    object_type,
    obj
  }
}`);

export const defaultTagConfig: Filter.FilterOptions = {
  kind: 'suggestions,user',
  object_type: ['all'],
  order: '-popularity',
  page: 1,
  page_size: 50,
  query,
  sortColumn: 'popularity',
  sortDirection: 'descending',
};

const searchConfig: { [key: string]: keyof Filter.FilterOptions } = {
  description: 'search_description',
  name: 'search_name',
  tags: 'search_tags',
};

const sortConfig: { [key: string]: string } = {
  description: 'description',
  name: 'name',
  popularity: 'popularity',
};

interface AssetsTabProps {
  guid: string;
}

const AssetsTab: React.FC<AssetsTabProps> = ({ guid }) => {
  const FilterService = Filter.useUpdateFilters(defaultTagConfig, searchConfig, sortConfig);
  const { filter } = FilterService;

  const { data, isError, isLoading } = useFetchTaggedItems(guid, {
    enabled: Boolean(guid),
    keepPreviousData: true,
    params: Filter.setParams(filter),
  });

  const totalPages = data && filter.page_size ? Math.ceil(data.count / filter.page_size) : 1;

  if (isError) return <TabError />;
  if (isLoading) return <CircularLoader compDisplay="block" mx="auto" my={2} />;

  return (
    <TaggedItemsTable
      data={data?.results}
      filterService={FilterService}
      guid={guid}
      itemCount={data?.count ?? 0}
      totalPages={totalPages}
    />
  );
};

export default React.memo<AssetsTabProps>(AssetsTab);
