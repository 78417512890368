import type { DataTypesConfig, ObjectTypeConfig } from '../dataTypes/types';

export const NO_LINK = '#';

const URL_CONFIG_BY_OBJECT: ObjectTypeConfig<string> = {
  bicolumn: { default: '/datasets/{parentGuid}/fields/{guid}' },
  bidashboardelement: { default: '/dashboards/{parentGuid}/visuals/{guid}' },
  bidataset: { default: '/datasets/{guid}' },
  column: { default: '/tables/{parentGuid}/columns/{guid}' },
  dashboard: { default: '/dashboards/{guid}' },
  database: { default: '/databases/{guid}' },
  datasource: { default: '/admin/mssql/{guid}' },
  default: NO_LINK,
  dsuser: { default: '/dsusers/{guid}' },
  schema: { default: '/schemas/{guid}' },
  table: { default: '/tables/{guid}' },
};

export const URL_CONFIG: DataTypesConfig<string> = {
  airflow: {
    ...URL_CONFIG_BY_OBJECT,
    datasource: { default: '/admin/usr-con/{guid}' },
    folder: { default: '/job-list/fl/{guid}' },
    job: { default: '/jobs/{guid}' },
  },
  bigquery: {
    ...URL_CONFIG_BY_OBJECT,
    datasource: { default: '/admin/bigquery/{guid}' },
    default: NO_LINK,
  },
  collection: { default: '/collections/{guid}' },
  data_studio: {
    ...URL_CONFIG_BY_OBJECT,
    bifolder: { default: '/data-studio/{guid}/folder' },
    dashboard: {
      data_studio_dashboard: '/dashboards/{guid}',
      default: NO_LINK,
    },
    datasource: { default: '/admin/data_studio/{guid}' },
    default: NO_LINK,
    reportquery: {
      default: NO_LINK,
      page: '/dashboards/{parentGuid}/pages/{guid}',
    },
  },
  databricks: {
    ...URL_CONFIG_BY_OBJECT,
    bifolder: { default: '/notebooks/fl/{guid}' },
    databricks_notebooks_container: { default: '/notebooks/ds/{parentGuid}' },
    datasource: { default: '/admin/databricks/{guid}' },
    default: NO_LINK,
    notebook: { default: '/notebooks/{guid}' },
  },
  db2: URL_CONFIG_BY_OBJECT,
  dbt: {
    ...URL_CONFIG_BY_OBJECT,
    datasource: { default: '/admin/dbt/{guid}' },
    dbt_test_container: { default: '/dbt-tests/{parentGuid}/{guid}/tests' },
    dbttest: { default: '/dbt-tests/{parentGuid}/tests/{guid}' },
    default: NO_LINK,
    folder: { default: '/projects/{guid}' },
  },
  document: { default: '/docs/{guid}' },
  glue: {
    ...URL_CONFIG_BY_OBJECT,
    datasource: { default: '/admin/glue/{guid}' },
    default: NO_LINK,
  },
  hex: {
    ...URL_CONFIG_BY_OBJECT,
    bifolder: { default: '/hex/folder/{guid}' },
    datasource: { default: '/admin/usr-con/{guid}' },
    hex_folders_container: { default: '/hex/{parentGuid}/folder' },
  },
  looker: {
    ...URL_CONFIG_BY_OBJECT,
    bifolder: {
      default: '/folders/{guid}',
      group: '/groups/{guid}',
    },
    dashboardelement: { default: '/dashboards/{parentGuid}/tiles/{guid}' },
    datasource: { default: '/admin/looker/{guid}' },
    default: NO_LINK,
    explore: { default: '/explores/{guid}' },
    explorefield: { default: '/explores/{parentGuid}/fields/{guid}' },
    folders_container: { default: '/looker/{guid}/folders_container/dashboards' },
    lookmlfield: { default: '/views/{parentGuid}/fields/{guid}' },
    lookmlproject: { default: '/lookmlprojects/{guid}' },
    lookmlview: { default: '/views/{guid}' },
    models_container: { default: '/looker/{guid}/models_container/explores' },
    views_container: { default: '/looker/{guid}/views_container/views' },
  },
  metabase: {
    ...URL_CONFIG_BY_OBJECT,
    bifolder: {
      collection: '/metabase/{guid}/folder',
      default: NO_LINK,
    },
    datasource: { default: '/admin/metabase/{guid}' },
    default: NO_LINK,
    metabase_folders_container: {
      default: '/metabase/{guid}/metabase_folders_container/datasource',
    },
    reportquery: {
      card: '/dashboards/{parentGuid}/cards/{guid}',
      default: NO_LINK,
    },
  },
  metric: { default: '/metrics/{guid}' },
  mode: {
    ...URL_CONFIG_BY_OBJECT,
    bifolder: {
      default: NO_LINK,
      space: '/spaces/{guid}',
    },
    collections_container: { default: '/mode/{guid}/collections_container/spaces' },
    dashboard: {
      default: NO_LINK,
      report: '/dashboards/{guid}/queries',
    },
    datasource: { default: '/admin/mode/{guid}' },
    default: NO_LINK,
    reportquery: {
      default: NO_LINK,
      query: '/dashboards/{parentGuid}/queries/{guid}',
    },
  },
  mssql: {
    ...URL_CONFIG_BY_OBJECT,
    datasource: { default: '/admin/mssql/{guid}' },
    default: NO_LINK,
  },
  mysql: {
    ...URL_CONFIG_BY_OBJECT,
    datasource: { default: '/admin/mysql/{guid}' },
    default: NO_LINK,
  },
  oracle: {
    ...URL_CONFIG_BY_OBJECT,
    datasource: { default: '/admin/oracle/{guid}' },
    default: NO_LINK,
  },
  postgres: {
    ...URL_CONFIG_BY_OBJECT,
    datasource: { default: '/admin/postgres/{guid}' },
    default: NO_LINK,
  },
  power_bi: {
    ...URL_CONFIG_BY_OBJECT,
    bifolder: { default: '/power-bi/{guid}/folder' },
    datasource: { default: '/admin/power-bi/{guid}' },
    default: NO_LINK,
    power_bi_folders_container: {
      default: '/power-bi/{parentGuid}/power_bi_folders_container/datasource',
    },
    powerbicolumn: { default: '/power-bi-tables/{parentGuid}/fields/{guid}' },
    powerbidataset: { default: '/power-bi-tables/{guid}' },
    reportquery: {
      default: NO_LINK,
      page: '/dashboards/{parentGuid}/pages/{guid}',
      tile: '/dashboards/{parentGuid}/tiles/{guid}',
    },
  },
  quicksight: {
    ...URL_CONFIG_BY_OBJECT,
    bifolder: { default: '/quicksight/folder/{guid}' },
    datasource: { default: '/admin/quicksight/{guid}' },
    default: NO_LINK,
    quicksight_folders_container: { default: '/quicksight/{parentGuid}/folder' },
  },
  redshift: {
    ...URL_CONFIG_BY_OBJECT,
    datasource: { default: '/admin/redshift/{guid}' },
    default: NO_LINK,
  },
  salesforce: {
    ...URL_CONFIG_BY_OBJECT,
    datasource: { default: '/admin/mssql/{guid}' },
    default: NO_LINK,
  },
  sigma: {
    ...URL_CONFIG_BY_OBJECT,
    bifolder: { default: '/sigma/{guid}/folder' },
    dashboard: {
      default: NO_LINK,
      sigma_dashboard: '/dashboards/{guid}',
    },
    datasource: { default: '/admin/sigma/{guid}' },
    default: NO_LINK,
    reportquery: {
      default: NO_LINK,
      element: '/dashboards/{parentGuid}/elements/{guid}',
    },
  },
  snowflake: {
    ...URL_CONFIG_BY_OBJECT,
    datasource: { default: '/admin/snowflake/{guid}' },
    default: NO_LINK,
  },
  tableau: {
    ...URL_CONFIG_BY_OBJECT,
    bifolder: {
      default: NO_LINK,
      project: '/tableau/folder_container/{guid}',
    },
    datasource: { default: '/admin/tableau/{guid}' },
    default: NO_LINK,
    projects_container: { default: '/tableau/{parentGuid}/projects_container/projects' },
    tableaudatasource: { default: '/tableaudatasources/{guid}' },
  },
  tag: { default: '/tags/{guid}' },
  team: { default: '/teams/{guid}' },
  term: { default: '/terms/{guid}' },
  thoughtspot: {
    ...URL_CONFIG_BY_OBJECT,
    bifolder: {
      answer: '/thoughtspot/folder_container/{guid}?{dataTypesParams}',
      default: NO_LINK,
      liveboard: '/thoughtspot/folder_container/{guid}?{dataTypesParams}',
      one_to_one_logical: '/bi-tables/folder_container/{guid}?{dataTypesParams}',
      sql_view: '/bi-tables/folder_container/{guid}?{dataTypesParams}',
      worksheet: '/bi-tables/folder_container/{guid}?{dataTypesParams}',
    },
    datasource: { default: '/admin/thoughtspot/{guid}' },
    default: NO_LINK,
    reportquery: {
      column: '/dashboards/{parentGuid}/fields/{guid}',
      default: NO_LINK,
      visual: '/dashboards/{parentGuid}/charts/{guid}',
    },
    thoughtspot_folders_container: {
      default: '/thoughtspot/{parentGuid}/folder_container?{dataTypesParams}',
    },
    thoughtspotcolumn: { default: '/bi-table/{parentGuid}/fields/{guid}' },
    thoughtspottable: { default: '/bi-table/{guid}' },
    ts_data_container: { default: '/bi-tables/{parentGuid}/folder_container?{dataTypesParams}' },
  },
  user: { default: '/profiles/{guid}' },
};
