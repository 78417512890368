import React from 'react';

import type { StyledBoxProps } from '@components/Box';
import Box from '@components/Box';
import type { AvatarProps } from '@components/UI/Avatar';
import Avatar from '@components/UI/Avatar';

export interface AvatarGroupProps extends StyledBoxProps {
  items?: Array<Omit<AvatarProps, 'size' | 'showName'>>;
  size?: string;
}
export const AvatarGroup: React.FC<AvatarGroupProps> = ({ items, size, ...other }) => (
  <Box compDisplay="flex" {...other}>
    {items?.map((item, i) => (
      <Avatar
        {...item}
        key={item.guid}
        outline="2px solid white"
        size={size}
        zIndex={items.length - i}
      />
    ))}
  </Box>
);

export default AvatarGroup;
