import { createContext, RefObject, useContext, useEffect, useRef } from 'react';
import { useTheme } from '@emotion/react';
import { uniqueId } from 'lodash';

export interface StickyStyles {
  index: number;
  position: 'sticky';
  top: string;
}

export interface StickyElementRef extends RefObject<HTMLDivElement> {}

export interface StickyContextProps {
  addElement?: (ref: StickyElementRef) => void;
  elementsRef: React.MutableRefObject<Record<string, StickyElementRef | undefined>>;
  removeElement?: (ref: StickyElementRef) => void;
  styles?: { [key: string]: StickyStyles };
  updateStyle: () => void;
}

export const StickyContext = createContext<StickyContextProps | undefined>(undefined);

export const useStickyProvider = (): StickyContextProps | undefined => useContext(StickyContext);

export interface UseStickyContextProps {
  enabled?: boolean;
  id?: string;
}

interface UseStickyContextResult extends Partial<Omit<StickyStyles, 'index'>> {
  id?: string;
  ref?: RefObject<HTMLDivElement>;
  zIndex?: number;
}

export const useStickyContext = ({
  enabled = true,
  id,
}: UseStickyContextProps): UseStickyContextResult => {
  const stickyContext = useContext(StickyContext);
  const ref = useRef<HTMLDivElement>(null);
  const internalId = useRef<string>(id ?? uniqueId('sticky-'));
  const { zIndex } = useTheme();

  if (stickyContext === undefined) {
    throw new Error(`useStickyContext must be used within <StickyProvider>.`);
  }

  const { addElement, removeElement, styles, updateStyle } = stickyContext;
  const { position, top } = styles?.[internalId.current] ?? {};

  useEffect(() => {
    let prevHeight = ref.current?.clientHeight ?? 0;

    const observer = new ResizeObserver((entries) => {
      const el = entries?.[0]?.target as HTMLDivElement;
      if (prevHeight !== el.clientHeight) {
        updateStyle();
        prevHeight = el.clientHeight;
      }
    });
    if (ref && ref.current) {
      addElement?.(ref);
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
      removeElement?.(ref);
    };
  }, [addElement, removeElement, updateStyle]);

  return enabled
    ? {
        id: internalId.current,
        position,
        ref,
        top,
        zIndex: zIndex.stickyElement,
      }
    : {};
};

export default useStickyContext;
