import React from 'react';

import {
  getInitSearchFacets,
  getInitSearchFilters,
  SearchFacetsResultsType,
  SearchFiltersType,
} from './SearchTypes';

export interface SearchContextInterface {
  facets: Partial<SearchFacetsResultsType>;
  filters: SearchFiltersType;
  setFilters: (filters: SearchFiltersType) => void;
  shown: number;
  total: number;
}

export const defaultSearchContext: SearchContextInterface = {
  facets: getInitSearchFacets(),
  filters: getInitSearchFilters(),
  // eslint-disable-next-line no-console
  setFilters: (filters: SearchFiltersType) => console.log(filters),

  shown: 0,

  total: 0,
};

export const SearchContext = React.createContext(defaultSearchContext);
