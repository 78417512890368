import React, { useState } from 'react';
import { Cell } from 'react-table';

import AnalyticsActivityModel from '@api/analytics/AnalyticsActivityModel';
import ActivityMessage from '@components/ActivityMessage';
import Box from '@components/Box';
import DateTime from '@components/DateTime';
import Highlighter from '@components/Highlighter';
import { DataSourceLink } from '@components/Table/AdminDatasourcesTable/AdminDatasourcesTable.styles';
import LinkedCell from '@components/Table/Cells/LinkedCell';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import Icon from '@components/UI/Icon';
import { FilterServiceInterface } from '@utils/filters';

interface SchemaChangesTableProps {
  filterService: FilterServiceInterface;
  loading: boolean;
  rows?: AnalyticsActivityModel[];
  totalPages?: number;
}

const SchemaChangesTable: React.FC<SchemaChangesTableProps> = ({
  filterService,
  loading,
  rows = [],
  totalPages,
}) => {
  const [searchVisible, setSearchVisible] = useState(false);

  const columns: ColumnConfig<AnalyticsActivityModel>[] = [
    {
      Header: SearchHeader,
      disableFilters: true,
      disableResizing: true,
      disableSortBy: true,
      id: 'search',
      width: 32,
    },
    {
      Cell: (props: Cell<AnalyticsActivityModel>) => {
        const {
          column,
          row: { original: activity },
        } = props;
        const { dataTypes, targetDataSource: ds } = activity;
        return ds ? (
          <Box alignItems="center" compDisplay="flex" justifyContent="flex-start">
            <Icon mr={0.5} name={dataTypes?.icons.dataSource!} size="16px" />
            <DataSourceLink to={`/admin/${ds.type}/${ds.guid}`}>
              <Highlighter
                autoEscape
                searchWords={column.filterValue?.split(/[.| ]+/) ?? []}
                textToHighlight={ds.name}
              />
            </DataSourceLink>
          </Box>
        ) : null;
      },
      Header: 'Data Source',
      accessor: (d) => d.targetDataSource?.name,
      disableHiding: true,
      id: 'dataSource',
      width: '12em',
    },
    {
      Cell: (props: Cell<AnalyticsActivityModel>) => {
        const {
          column,
          row: { original: activity },
        } = props;
        const { targetRoutePath } = activity;
        return (
          <LinkedCell
            breadcrumbFixedUrl={targetRoutePath}
            globalFilter={column.filterValue}
            item={activity}
            showBreadcrumbs
            showDataSourceIcon
            showIcon
          />
        );
      },
      Header: 'Data Object',
      accessor: (d) => d.user?.fullName,
      disableHiding: true,
      id: 'target',
      width: '150%',
    },

    {
      Cell: (props: Cell<AnalyticsActivityModel>) => {
        const {
          column,
          row: { original: activity },
        } = props;
        return <ActivityMessage globalFilter={column.filterValue} message={activity.message} />;
      },
      Header: 'Event Description',
      accessor: (d) => d.targetGuid,
      disableHiding: true,
      id: 'message',
      width: '150%',
    },
    {
      Cell: (props: Cell<AnalyticsActivityModel>) => {
        const { row } = props;
        return <DateTime {...props} datetime={row.original.performedOn} format="fullDateFormat" />;
      },
      Header: 'Date',
      accessor: (d) => d.performedOn?.toDate(),
      disableFilters: true,
      disableHiding: true,
      id: 'date',
      width: '12em',
    },
  ];

  return (
    <TableStyled>
      <Table
        basic="very"
        changePage={filterService?.changePage}
        className="table-full"
        columns={columns}
        compact
        data={rows}
        disableColumnFiltering
        disableRowSelect
        initialState={{
          pageIndex: filterService ? filterService!.filter.page! - 1 : 0,
          sortBy: [
            {
              desc: true,
              id: 'date',
            },
          ],
        }}
        loading={loading}
        manualFilters
        manualPagination
        manualSortBy
        setFilters={filterService.search}
        setSortBy={filterService.sort}
        showFilter={searchVisible}
        sortable
        stickyHeader
        toggleFilter={() => setSearchVisible((prev) => !prev)}
        totalPages={totalPages}
        unstackable
      />
    </TableStyled>
  );
};

export default SchemaChangesTable;
