import React from 'react';

import Icon from '@components/UI/Icon';

import { StyledColumnsSeachInput } from './ColumnsSeachInput.styles';

interface ColumnsSeachInputProps {
  onChange?: (newValue: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
}

const ColumnsSeachInput = ({ onChange, value }: ColumnsSeachInputProps) => {
  return (
    <StyledColumnsSeachInput
      autoFocus
      onChange={onChange}
      placeholder="Search"
      startIcon={<Icon color="gray.500" name="bi-search" size="16px" />}
      value={value}
    />
  );
};

export default ColumnsSeachInput;
