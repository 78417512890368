import React from 'react';
import { DataTypesConfig } from '@configs/dataTypes/types';
import { useRouteMatch } from '@routing/router';

import { useFetchMetadataById } from '@api/metadata';
import Box from '@components/Box';
import LineageExplore from '@components/LineageExplore';
import PageColumnInfo from '@components/PageColumnInfo';
import PageColumnTemplate from '@components/PageColumnTemplate';
import type { TabRoutesConfig } from '@components/TabsRouter/TabsRouter';
import Icon from '@components/UI/Icon';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import stripSpaces from '@utils/stripSpaces';

import BIColumnLineageTab from './tabs/BIColumnLineageTab';

const query = stripSpaces(`{
  is_metric,
  is_mention,
  guid,
  name,
  url,
  description,
  data_types,
  richtext_description,
  suggested_description,
  suggested_description_source,
  suggested_description_source_object,
  short_name,
  search_name,
  object_type,
  data_type,
  external_type,
  parent_guid,
  tagged_items,
  data_source_type,
  last_updated_on,
  downstream_objects_counts,
  upstream_objects_counts,
  ai_description,
  ingested_description,
  description_source,
  user_description,
  breadcrumbs
}`);

interface BIColumnPageProps {
  guid: string;
  isDataSourceEditable: boolean;
  onClose: () => void;
}

type Config = { hideDownstreamLineage?: boolean };
const config: DataTypesConfig<Config> = {
  thoughtspot: {
    default: {},
    reportquery: {
      default: {
        hideDownstreamLineage: true,
      },
    },
  },
};

const BIColumnPage: React.FC<BIColumnPageProps> = ({ guid, isDataSourceEditable, onClose }) => {
  const { url } = useRouteMatch();

  const { isEditable: isObjectEditable } = useObjectPermissionsContext({ id: guid });

  const { data, error, isError, isLoading } = useFetchMetadataById(guid, {
    enabled: Boolean(guid),
    params: {
      force_showing_suggested_description: true,
      query,
    },
  });

  const upstreamCount = data?.upstreamObjectsCounts?.total;
  const downstreamCount = data?.downstreamObjectsCounts?.total;
  const downstreamChartsCount = data?.downstreamObjectsCounts?.chart;
  const isEditable = isDataSourceEditable && isObjectEditable;

  const usedConfig = data?.dataTypes?.findConfig<Config>(config);

  const tabObjects: TabRoutesConfig[] = [
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => (
        <BIColumnLineageTab
          assetName={data?.name ?? ''}
          downstreamChartsCount={downstreamChartsCount}
          downstreamCount={downstreamCount}
          guid={guid}
          hideDownstreamLineage={usedConfig?.hideDownstreamLineage}
          nodeKey={`${data?.parentGuid}/${data?.guid}`}
          upstreamCount={upstreamCount}
          upstreamTotalAllDepths={data?.upstreamObjectsCounts?.totalAllDepths}
        />
      ),
      contentOffset: 0.8,
      default: true,
      label: 'Lineage',
      route: '/lineage',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <LineageExplore prevRoute={url.replace('/lineage-explore', '')} />,
      label: (
        <Box alignItems="center" compDisplay="flex" gap={0.5}>
          Explore <Icon color="currentColor" name="open-new-tab" size="14px" />
        </Box>
      ),
      openInNewTab: Boolean(process.env.REACT_APP_CHROME_EXTENSION_BUILD),
      route: '/lineage-explore',
    },
  ];

  return (
    <PageColumnTemplate
      header={<PageColumnInfo guid={guid} isEditable={isEditable} item={data} />}
      isAuthorized={!(error && error?.status === 403)}
      isError={isError || !guid}
      isLoading={isLoading}
      onClose={onClose}
      tabsRouterConfig={tabObjects}
      title={data?.name}
    />
  );
};

export default React.memo(BIColumnPage);
