export const SHOW_SQL_OPTION = {
  icon: 'code-block' as const,
  id: 'show-sql',
  label: 'Show SQL',
};

export const TRIGGER_COLUMN_SEARCH_OPTION = {
  icon: 'search' as const,
  id: 'trigger-column-search',
  label: 'Search',
};

export const OPEN_ALL_UPSTREAM_OPTION = {
  icon: 'arrow-left' as const,
  id: 'open-all-upstream',
  label: 'Open all upstream',
};

export const CLOSE_ALL_UPSTREAM_OPTION = {
  icon: 'arrow-left-close' as const,
  id: 'close-all-upstream',
  label: 'Close all upstream',
};

export const OPEN_ALL_DOWNSTREAM_OPTION = {
  icon: 'arrow-right' as const,
  id: 'open-all-downstream',
  label: 'Open all downstream',
};

export const CLOSE_ALL_DOWNSTREAM_OPTION = {
  icon: 'arrow-right-close' as const,
  id: 'close-all-downstream',
  label: 'Close all downstream',
};

export const OPEN_IN_LOOKER_OPTION = {
  icon: 'looker' as const,
  id: 'open-in-looker',
  label: 'Open in Looker',
};
