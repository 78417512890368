import { Expose, Transform } from 'class-transformer';
import moment from 'moment';

export type ChatBotMessageRole = 'user' | 'assistant';

export type ChatBotRatingType = 0 | 1 | -1;

export interface ChatBotMessageData {
  content: string;
  created_on?: string;
  guid: string;
  rating?: ChatBotRatingType;
  response_time?: number;
  role: ChatBotMessageRole;
  title?: string;
}

class ChatBotMessageModel {
  role!: ChatBotMessageRole;

  content!: string;

  @Expose({ name: 'response_time' })
  responseTime?: number;

  rating?: ChatBotRatingType;

  guid!: string;

  title?: string;

  @Expose({ name: 'created_on' })
  @Transform((value) => moment(value))
  createdOn?: moment.Moment;
}

export default ChatBotMessageModel;
