import React from 'react';
import { RouterLink } from '@routing/router';

import { CollectionModel } from '@api/collections/CollectionModel';
import Tooltip from '@components/Tooltip.v1';
import Icon from '@components/UI/Icon';

import {
  StyledSubCollectionCard,
  StyledSubCollectionCardIconContainer,
  StyledSubCollectionCardTitle,
  SUB_COLLECTION_ICON_COLORS,
} from './SubCollectionCard.styles';

interface SubCollectionsCardProps {
  collection: CollectionModel;
}

const SubCollectionsCard = ({ collection }: SubCollectionsCardProps) => {
  const { name, routePath } = collection;
  return (
    <StyledSubCollectionCard as={RouterLink} to={routePath}>
      <StyledSubCollectionCardIconContainer>
        <Icon
          color={SUB_COLLECTION_ICON_COLORS.collectionFolderIcon}
          name="collection"
          size="28px"
        />
      </StyledSubCollectionCardIconContainer>
      <Tooltip content={name}>
        <StyledSubCollectionCardTitle>{name}</StyledSubCollectionCardTitle>
      </Tooltip>
    </StyledSubCollectionCard>
  );
};

export default SubCollectionsCard;
