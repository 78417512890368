import { Expose, plainToClass, Transform } from 'class-transformer';

import { rawMap } from '@api/utils';

import { DeletedModel } from './DeletedModel';

export const referenceObjectMap: { [objectType: string]: any } = {};
export const referenceObjectMapV1: { [objectType: string]: any } = {};
export interface ReferenceData<T> {
  obj: T;
  objectType: string;
}

export class Reference<T> {
  @Expose({ name: 'object_type' })
  objectType!: string;

  @Transform((obj, ref) => {
    if (obj === null) {
      const deletedObject = new DeletedModel();
      deletedObject.objectType = ref.object_type;
      return deletedObject;
    }

    if (ref.object_type in referenceObjectMapV1) {
      return rawMap(referenceObjectMapV1[ref.object_type], obj);
    }

    return plainToClass(referenceObjectMap[ref.object_type], obj);
  })
  obj?: T;
}
