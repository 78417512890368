import React from 'react';
import clsx from 'clsx';

import LinkedCell from '@components/Table/Cells/LinkedCell';
import { MetadataData } from '@models/MetadataModel';

import Box from '../Box';

import { StyledTableList } from './StyledTableList';

interface TableListProps {
  fullHeight?: boolean;
  items?: MetadataData[];
  showTooltip?: boolean;
  simple?: boolean;
}

const TableList: React.FC<TableListProps> = ({ fullHeight, items, showTooltip, simple }) => {
  return (
    <StyledTableList
      className={clsx(simple && 'simple', !simple && 'with-padding', fullHeight && 'full-height')}
    >
      {items ? (
        items.map((item) => (
          <Box key={item.guid} className="item" mb={simple ? 1.1 : 'auto'} my={0.25}>
            <LinkedCell
              key={item.guid}
              item={item}
              noLink={!showTooltip}
              showIcon
              showNameTooltip={showTooltip}
            />
          </Box>
        ))
      ) : (
        <Box alignItems="center" className="item" compDisplay="inline-flex" my={0.25}>
          No items to show
        </Box>
      )}
    </StyledTableList>
  );
};

export default React.memo(TableList);
