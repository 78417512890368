import React from 'react';
import { RouterLink } from '@routing/router';

import { useFetchRecentSearches } from '@api/search';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import Skeleton from '@components/Skeleton';
import Text from '@components/Text';
import theme from '@styles/theme';

export const RECENT_SEARCHES_LABEL = 'Recent Searches';

const RecentSearches = () => {
  const { data, isLoading } = useFetchRecentSearches({
    params: {
      page_size: 5,
    },
  });

  if (isLoading) {
    return <Skeleton compWidth="100px" grayShade={200} variant="text" />;
  }

  if (!isLoading && !data?.results?.length) {
    return null;
  }

  return (
    <Box alignItems="center" compDisplay="flex" flexWrap="wrap" gap={1}>
      <Text color="gray.600" fontWeight="semibold" mb={0}>
        {RECENT_SEARCHES_LABEL}
      </Text>
      {data?.results?.map((item) => {
        return (
          <Button
            key={item.searchTerm}
            as={RouterLink}
            backgroundColor={theme.colors.v1.primary[100]}
            borderRadius="20px"
            color={theme.colors.v1.primary[800]}
            compHeight="auto"
            compSize="xs"
            justifyContent="flex-start"
            minHeight="28px"
            overflowWrap="anywhere"
            py={0.75}
            textAlign="left"
            to={`/search?q=${item.searchTerm}`}
          >
            {item.searchTerm}
          </Button>
        );
      })}
    </Box>
  );
};

export default RecentSearches;
