import React from 'react';

import { Circle, StyledToggle, StyledToggleProps, ToggleInput } from './Toggle.styles';

interface ToggleProps extends Partial<StyledToggleProps> {
  checked?: boolean;
  className?: string;
  name?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  type?: 'checkbox' | 'radio';
  value?: string;
}

const Toggle: React.FC<ToggleProps> = ({
  checked,
  className,
  compSize = 'default',
  disabled,
  name,
  onChange,
  type = 'checkbox',
  value,
}) => (
  <StyledToggle
    aria-checked={checked}
    aria-label={name ?? 'toggle'}
    className={className}
    compSize={compSize}
    role="checkbox"
  >
    <ToggleInput
      checked={checked}
      compSize={compSize}
      disabled={disabled}
      name={name}
      onChange={onChange}
      type={type}
      value={value}
    />
    <Circle compSize={compSize} disabled={disabled} />
  </StyledToggle>
);

export default Toggle;
