import styled from '@emotion/styled';

import Box from '@components/Box';
import theme from '@styles/theme';

export const StyledTeamsHierarchySection = styled(Box)``;

StyledTeamsHierarchySection.defaultProps = {
  alignItems: 'center',
  color: 'gray.700',
  compDisplay: 'flex',
  fontSize: 'body2',
  fontWeight: 'medium',
  minHeight: theme.space(4),
};

export const StyledTeamsHierarchyTeamLink = styled(Box)`
  color: currentColor;

  &:hover {
    color: currentColor;
  }
`;

StyledTeamsHierarchyTeamLink.defaultProps = {
  alignItems: 'center',
  color: 'currentcolor',
  compDisplay: 'flex',
  compWidth: '100%',
  fontSize: 'body2',
  fontWeight: 'medium',
  gap: 1,
  minHeight: theme.space(4),
};

export const StyledTeamsHierarchyNoTeamsMessage = styled(Box)`
  &:hover {
    background-color: transparent;
  }
`;

StyledTeamsHierarchyNoTeamsMessage.defaultProps = {
  alignItems: 'center',
  color: 'gray.400',
  compDisplay: 'flex',
  fontSize: 'body2',
  fontStyle: 'italic',
  fontWeight: 'medium',
  minHeight: theme.space(4),
};
