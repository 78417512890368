import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import * as CSS from 'csstype';

import Box from '@components/Box';
import rootTheme from '@styles/theme';
import getLayoutComponent from '@utils/getLayoutComponent';

export interface StyledTreeNodeProps {
  disableHover?: boolean;
  hoverBackgroundColor?: CSS.Property.BackgroundColor;
  hoverFontWeight?: CSS.Property.FontWeight;
  hoverLetterSpacing?: CSS.Property.LetterSpacing;
  isCaretVisible?: boolean;
  isFlatHierarchy?: boolean;
  isHighlighted: boolean;
  offset: number;
}

const DEFAULT_NODE_PADDING = 1;

const makeActiveColor = (customHover: {
  backgroundColor?: CSS.Property.Color;
  fontWeight?: CSS.Property.FontWeight;
  letterSpacing?: CSS.Property.LetterSpacing;
}) => css`
  font-weight: ${customHover.fontWeight ?? rootTheme.typography.fontWeights.bold};
  background-color: ${customHover.backgroundColor ?? rootTheme.colors.hierarchyHoverBackground};
  color: ${rootTheme.colors.primary};
  letter-spacing: ${customHover.letterSpacing ?? '-0.0075em'};
`;

const StyledTreeNodeV0 = styled(Box)<StyledTreeNodeProps>`
  color: ${({ theme }) => theme.colors.text};
  cursor: ${({ disableHover = false }) => (disableHover ? 'default' : 'pointer')};
  font-size: ${({ theme }) => theme.typography.fontSizes.body2};
  padding-left: ${({ offset, theme }) => theme.space(DEFAULT_NODE_PADDING + offset * 2.5)};
  padding-right: ${({ theme }) => theme.space(DEFAULT_NODE_PADDING)};
  transition: background-color 0.1s ease, color 0.1s ease;
  user-select: none;

  ${({ isHighlighted }) => (isHighlighted ? makeActiveColor : undefined)}

  &:hover {
    ${({ disableHover = false, hoverBackgroundColor, hoverFontWeight, hoverLetterSpacing }) =>
      disableHover
        ? ''
        : makeActiveColor({
            backgroundColor: hoverBackgroundColor,
            fontWeight: hoverFontWeight,
            letterSpacing: hoverLetterSpacing,
          })}
  }
`;

export const getTreeNodeHoverColor = (customHover?: {
  backgroundColor?: CSS.Property.Color;
  fontWeight?: CSS.Property.FontWeight;
  letterSpacing?: CSS.Property.LetterSpacing;
}) => {
  const { backgroundColor, fontWeight } = customHover ?? {};

  return css`
    font-weight: ${fontWeight ?? rootTheme.typography.fontWeights.medium};
    background-color: ${backgroundColor ?? rootTheme.colors.v1.gray[100]};
    color: ${rootTheme.colors.v1.gray[700]};
  `;
};

export const StyledTreeNodeItemWrapper = styled(Box)``;

const StyledTreeNodeV1 = styled(Box)<StyledTreeNodeProps>`
  position: relative;
  color: ${({ theme }) => theme.colors.v1.gray[700]};
  cursor: ${({ disableHover = false }) => (disableHover ? 'default' : 'pointer')};
  font-size: ${({ theme }) => theme.typography.fontSizes.body2};
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
  transition: all 0.1s ease, color 0.1s ease;
  user-select: none;
  padding-left: ${({ offset, theme }) => theme.space(offset * 2.25)};

  /** Apply styles to the div containing the text, not the entire line */
  ${StyledTreeNodeItemWrapper} {
    overflow: hidden;
    border-radius: ${({ theme }) => theme.radius.md};
    padding-left: ${({ isFlatHierarchy, theme }) => theme.space(isFlatHierarchy ? 1 : 0.25)};
    padding-right: ${({ theme }) => theme.space(1)};
    ${({ isHighlighted, theme }) =>
      isHighlighted
        ? css`
            color: ${theme.colors.v1.primary[500]};
            background-color: ${theme.colors.v1.primary[100]};
          `
        : undefined}
  }

  &:hover {
    /** Apply hover effects only to the div containing the text, not the entire line */
    ${StyledTreeNodeItemWrapper} {
      ${({ disableHover = false, hoverBackgroundColor, hoverFontWeight, hoverLetterSpacing }) =>
        disableHover
          ? ''
          : getTreeNodeHoverColor({
              backgroundColor: hoverBackgroundColor,
              fontWeight: hoverFontWeight,
              letterSpacing: hoverLetterSpacing,
            })}
    }
  }
`;

export const StyledTreeNode = getLayoutComponent<StyledTreeNodeProps>(
  StyledTreeNodeV0,
  StyledTreeNodeV1,
);

interface StyledTreeNodeIndentationLineProps {
  isFirstChild?: boolean;
  isLastChild?: boolean;
  level: number;
}

export const StyledTreeNodeIndentationLine = styled.div<StyledTreeNodeIndentationLineProps>`
  position: absolute;
  pointer-events: none;
  left: ${({ level, theme }) => theme.space(1.25 + level * 2.25)};
  width: 1px;
  background-color: ${({ theme }) => theme.colors.v1.gray[300]};

  ${({ isFirstChild, isLastChild }) => {
    if (isLastChild) {
      return css`
        top: 0;
        height: 90%;
      `;
    }

    if (isFirstChild) {
      return css`
        bottom: 0;
        height: 90%;
      `;
    }
    return css`
      height: 100%;
      top: 0;
    `;
  }}
`;

export const StyledTreeNodeLabelWrapper = styled.span`
  display: flex;
  width: 100%;
`;

interface StyledTreeNodeCaretProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isExpanded: boolean;
}

const StyledTreeNodeCaretV0 = styled.button<StyledTreeNodeCaretProps>`
  transform: ${({ isExpanded }) => `rotate(${isExpanded ? '90deg' : '0'})`};
  background: transparent;
  outline: none;
`;

const StyledTreeNodeCaretV1 = styled(StyledTreeNodeCaretV0)<StyledTreeNodeCaretProps>`
  margin-right: ${({ theme }) => theme.space(0.25)};
`;

export const StyledTreeNodeCaret = getLayoutComponent<StyledTreeNodeCaretProps>(
  StyledTreeNodeCaretV0,
  StyledTreeNodeCaretV1,
);
