import React from 'react';

import ConnectionAlertModel from '@api/connectionAlerts/ConnectionAlertModel';
import Box from '@components/Box';
import Status from '@components/Status';
import Tooltip from '@components/Tooltip';

import AlertTooltipContent from '../AlertTooltipContent';

interface ConnectionStatusProps {
  alerts?: ConnectionAlertModel[];
  isIncompleteConnection?: boolean;
  isSyncingPaused?: boolean;
  onClick?: (alerts: ConnectionAlertModel[]) => void;
}

const ConnectionStatus = ({
  alerts,
  isIncompleteConnection,
  isSyncingPaused,
  onClick,
}: ConnectionStatusProps) => {
  if (isSyncingPaused) {
    return (
      <Tooltip content="Please reach out to support@getselectstar.com to unpause this data source.">
        <Box cursor="default">
          <Status status="paused" />
        </Box>
      </Tooltip>
    );
  }

  if (!alerts || alerts.length === 0) {
    return isIncompleteConnection ? null : <Status status="success">Connected</Status>;
  }

  const hasErrors = alerts.some((el) => el.level === 'error');

  const handleStatusClick = () => {
    onClick?.(alerts);
  };

  return (
    <Box as="button" onClick={handleStatusClick}>
      <Tooltip content={<AlertTooltipContent alerts={alerts} />} forceDefaultBackground>
        <Box>
          <Status status={hasErrors ? 'error' : 'warning'}>
            {hasErrors ? 'Error' : 'Warning'}
          </Status>
        </Box>
      </Tooltip>
    </Box>
  );
};

export default ConnectionStatus;
