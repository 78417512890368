import React, { useCallback, useState } from 'react';

import useRefValueChange from '@hooks/useRefValueChange';

export interface UseFormProps<T = { [key: string]: string | number | null }> {
  initialValues: T;
  onSubmit?: (values: T) => void;
  syncInitialValues?: boolean;
}

type TouchedObject<T> = Record<keyof T, boolean>;

export interface UseFormReturnProps<T> {
  handleChange: (e: ChangeEvent) => void;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
  setValues: React.Dispatch<React.SetStateAction<T>>;
  touched: TouchedObject<T>;
  values: T;
}

const getInitialTouchState = <T>(initialValues: T, defaultValue = false) => {
  const initialTouchState = {} as TouchedObject<T>;

  Object.keys(initialValues as Object).forEach((key) => {
    initialTouchState[key as keyof T] = defaultValue;
  });

  return initialTouchState;
};

const useForm = <T>({
  initialValues,
  onSubmit,
  syncInitialValues,
}: UseFormProps<T>): UseFormReturnProps<T> => {
  const [values, setValues] = useState<T>(initialValues);
  const [touched, setTouched] = useState<TouchedObject<T>>(getInitialTouchState(initialValues));

  const handleChange = useCallback((e: ChangeEvent) => {
    const { name, value } = e.target;
    setTouched((prev) => ({
      ...prev,
      [name]: true,
    }));
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const handleSubmit = useCallback(
    (e?: React.FormEvent<HTMLFormElement>) => {
      e?.preventDefault?.();
      setTouched((prev) => getInitialTouchState(prev));
      onSubmit?.(values);
    },
    [onSubmit, values],
  );

  const reset = useCallback((val) => {
    setValues(val);
  }, []);

  useRefValueChange({ enabled: syncInitialValues, onChange: reset, value: initialValues });

  return { handleChange, handleSubmit, setValues, touched, values };
};

export default useForm;
