import { KeyboardEvent } from 'react';

export const handlePlainTextKeyDown = <T>(
  event: KeyboardEvent<T>,
  {
    onPressEnter,
    onPressEsc,
  }: {
    onPressEnter: () => void;
    onPressEsc: () => void;
  },
) => {
  const { key, shiftKey } = event;
  switch (key) {
    case 'Escape':
      event.stopPropagation();
      onPressEsc();
      return;
    case 'Enter':
      if (!shiftKey) {
        event.preventDefault();
        event.stopPropagation();
        onPressEnter();
      }
      break;
    default:
  }
};
