import React, { forwardRef } from 'react';
import type { ChangeEvent } from 'react';

import Box, { StyledBoxProps } from '@components/Box';

import {
  StyledRadioButton,
  StyledRadioButtonDot,
  StyledRadioButtonInput,
} from './RadioButton.styles';

export interface RadioButtonProps extends StyledBoxProps {
  checked?: boolean;
  disabled?: boolean;
  error?: boolean;
  id?: string;
  name?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const getDotVisualState = (isDisabled: boolean, isError: boolean) => {
  if (isDisabled) return 'disabled';
  if (isError) return 'error';
  return 'default';
};

const getContainerVisualState = (isChecked: boolean, isError: boolean) => {
  if (isError) return 'error';
  if (isChecked) return 'checked';
  return 'default';
};

const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(
  ({ checked = false, disabled = false, error = false, id, name, onChange, ...other }, ref) => {
    const containerState = getContainerVisualState(checked, error);
    const dotState = getDotVisualState(disabled, error);

    return (
      <Box as="label" {...other}>
        <StyledRadioButtonInput
          ref={ref}
          checked={checked}
          disabled={disabled}
          id={id}
          name={name}
          onChange={onChange}
          role="presentation"
          type="radio"
        />
        <StyledRadioButton
          aria-checked={checked}
          aria-disabled={disabled}
          aria-label={name}
          disabled={disabled}
          role="radio"
          state={containerState}
        >
          <StyledRadioButtonDot checked={checked} state={dotState} />
        </StyledRadioButton>
      </Box>
    );
  },
);

export default RadioButton;
