import { Expose, plainToClass } from 'class-transformer';

import getUrl from '@components/Tree/getUrl';
import type { DataSourceTypesType } from '@models/DataSourceCredentials';
import DataTypesModel from '@models/DataTypesModel';

export interface BreadcrumbPayload {
  guid: string;
  has_children: string;
  ordinal: number;
  target_data_source_guid: string;
  target_data_source_type: DataSourceTypesType;
  target_data_type: string;
  target_guid: string;
  target_name: string;
  target_object_type: string;
}

export class BreadcrumbModel {
  @Expose({ name: 'guid' })
  guid!: string;

  @Expose({ name: 'ordinal' })
  ordinal!: number;

  @Expose({ name: 'target_data_type' })
  targetDataType!: string;

  @Expose({ name: 'target_guid' })
  targetGuid!: string;

  @Expose({ name: 'target_name' })
  targetName!: string;

  @Expose({ name: 'target_object_type' })
  targetObjectType!: string;

  @Expose({ name: 'target_data_source_type' })
  targetDataSourceType?: DataSourceTypesType;

  @Expose({ name: 'target_data_source_guid' })
  targetDataSourceGuid!: string;

  @Expose({ name: 'has_children' })
  hasChildren?: string;

  get dataTypes() {
    return plainToClass(DataTypesModel, {
      data_source_type: this.targetDataSourceType,
      data_type: this.targetDataType,
      object_type: this.targetObjectType,
    });
  }

  get routerUrl() {
    return getUrl({
      dataType: this.targetDataType,
      dataTypes: this.dataTypes,
      defaultUrl: '',
      dsGuid: this.targetDataSourceGuid,
      guid: this.targetGuid,
      objectType: this.targetObjectType,
      rawDataSourceGuid: this.targetDataSourceGuid,
      showSchemataPage: true,
      type: this.targetDataSourceType,
    });
  }

  /**
   * @TODO update Breadcrumbs component to use routerUrl
   */
  get url() {
    return this.routerUrl;
  }

  get label() {
    return this.targetName;
  }
}
