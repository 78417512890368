import React, { useMemo, useState } from 'react';

import { QueryLogRecordModel } from '@models/QueryLogRecordModel';
import { Filter } from '@utils';

import Table from '../Table';

import getRecentQueriesColumns from './getRecentQueriesColumns';
import { StyledRecentQueriesTable } from './RecentQueriesTable.styles';

const INITIAL_SORT_STATE = [
  {
    desc: true,
    id: 'startTime',
  },
];

interface RecentQueriesTableProps {
  basic?: boolean;
  data?: QueryLogRecordModel[];
  enableApiSearch?: boolean;
  filterService: Filter.FilterServiceInterface;
  hiddenColumns?: string[];
  loading?: boolean;
  stickyHeader?: boolean;
  userColName?: string;
}

const RecentQueriesTable: React.FC<RecentQueriesTableProps> = ({
  basic,
  data,
  enableApiSearch = false,
  filterService,
  hiddenColumns,
  loading,
  stickyHeader,
  userColName,
}) => {
  const [isShowFilter, setShowFilter] = useState(false);
  const { changePage, filter, search, sort } = filterService;

  const columns = useMemo(
    () => getRecentQueriesColumns(userColName || 'User', basic),
    [userColName, basic],
  );

  return (
    <StyledRecentQueriesTable>
      <Table
        basic="very"
        changePage={changePage}
        className="table-full"
        columns={columns}
        compact
        data={data || []}
        disableColumnFiltering
        disableRowSelect
        initialState={{
          hiddenColumns,
          pageIndex: filter.page ? filter.page - 1 : 0,
          sortBy: INITIAL_SORT_STATE,
        }}
        loading={loading}
        manualFilters={enableApiSearch}
        manualPagination
        manualSortBy
        setFilters={enableApiSearch ? search : undefined}
        setSortBy={sort}
        showFilter={isShowFilter}
        singleLine
        sortable
        stickyHeader={stickyHeader}
        toggleFilter={() => setShowFilter((prev) => !prev)}
        unstackable
      />
    </StyledRecentQueriesTable>
  );
};

export default React.memo(RecentQueriesTable);
