import React, { useMemo, useState } from 'react';
import type { FC } from 'react';
import type { Cell } from 'react-table';

import { useFetchCostAnalysisQueries } from '@api/costAnalysis';
import type { QueryModel } from '@api/queries/QueryModel';
import DateTime from '@components/DateTime';
import QueryModalWithTargetBox from '@components/Modal/QueryModal/QueryModalWithTargetBox';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import UserCell from '@components/Table/Cells/UserCell';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import type { CostAnalysisSharedFilterParams } from '@pages/Admin/AdminCostAnalysisPage/CostAnalysisSharedFilter';
import { FilterOptions, setParams, useUpdateFilters } from '@utils/filters';

const sortConfig = {
  averageCreditsUsed: 'average_credits_used',
  lastExecutedOn: 'last_executed_on',
  maxDuration: 'max_duration',
  query: 'raw_sql',
  totalCreditsUsed: 'total_credits_used',
  totalRun: 'total_run',
  user: 'user',
  warehouse: 'warehouse',
} as const;

const searchConfig: { [key: string]: keyof FilterOptions } = {
  query: 'search_raw_sql',
  warehouse: 'search_warehouse',
};

const requestConfig: FilterOptions = {
  order: `-${sortConfig.totalCreditsUsed}`,
  page: 1,
  page_size: 20,
};

const initialTableSort = [{ desc: true, id: 'totalCreditsUsed' }];

interface QueryByCostTableProps extends CostAnalysisSharedFilterParams {}

const QueryByCostTable: FC<QueryByCostTableProps> = ({ range, warehouses }) => {
  const [showFilter, setShowFilter] = useState(false);
  const { changePage, filter, search, sort } = useUpdateFilters(
    requestConfig,
    searchConfig,
    sortConfig,
    requestConfig.order,
  );

  const { data, isLoading } = useFetchCostAnalysisQueries({
    params: {
      ...setParams(filter),
      end_date: range[1],
      start_date: range[0],
      warehouses,
    },
  });

  const columns: ColumnConfig<QueryModel>[] = useMemo(
    () => [
      {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      {
        Cell: ({ row: { original } }: Cell<QueryModel>) => (
          <DateTime datetime={original.lastExecutedOn} format="fullDateFormat" />
        ),
        Header: 'Last Timestamp',
        accessor: (d) => d?.lastExecutedOn,
        disableFilters: true,
        id: 'lastExecutedOn',
        width: '9rem',
      },
      {
        Cell: ({ column, row: { original } }: Cell<QueryModel>) => (
          <QueryModalWithTargetBox
            codeString={original.rawSql}
            displayText={original?.displayName}
            highlighterProps={{
              searchWords: [column?.filterValue],
            }}
            query={original}
            tooltipText={original?.rawSql}
          />
        ),
        Header: 'Query',
        accessor: (d) => d?.displayName,
        disableHiding: true,
        id: 'query',
        width: '150%',
      },
      {
        Header: 'Warehouse',
        accessor: (d) => d.warehouse,
        id: 'warehouse',
        width: '7rem',
      },
      {
        Cell: ({ row: { original } }: Cell<QueryModel>) => (
          <UserCell ellipsis user={original.dsuserCreatedBy} />
        ),
        Header: 'User',
        accessor: (d) => d.dsuserCreatedBy?.fullName,
        disableFilters: true,
        id: 'user',
        width: '150%',
      },
      {
        Header: 'Runs',
        accessor: (d) => d?.formattedTotalRun,
        disableFilters: true,
        id: 'totalRun',
        width: '5rem',
      },
      {
        Header: 'Max Run Time',
        accessor: (d) => d?.formattedMaxDuration,
        disableFilters: true,
        id: 'maxDuration',
        width: '8rem',
      },
      {
        Header: 'Credit',
        accessor: (d) => d?.formattedTotalCreditsUsed,
        disableFilters: true,
        id: 'totalCreditsUsed',
        width: '8.5rem',
      },
      {
        Header: 'Credit/Run',
        accessor: (d) => d.formattedAverageCreditsUsed,
        disableFilters: true,
        id: 'averageCreditsUsed',
        width: '8rem',
      },
    ],
    [],
  );

  const totalPages = data && filter.page_size ? Math.ceil(data.count / filter.page_size) : 1;

  return (
    <TableStyled>
      <Table
        basic="very"
        changePage={changePage}
        columns={columns}
        compact
        data={data?.results ?? []}
        disableRowSelect
        initialState={{
          sortBy: initialTableSort,
        }}
        loading={isLoading}
        manualFilters
        manualPagination
        manualSortBy
        setFilters={search}
        setSortBy={sort}
        showFilter={showFilter}
        sortable
        toggleFilter={() => {
          setShowFilter((prev) => !prev);
        }}
        totalPages={totalPages}
        unstackable
      />
    </TableStyled>
  );
};

export default QueryByCostTable;
