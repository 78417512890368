import styled from '@emotion/styled';

import type { StyledBoxProps } from '@components/Box';
import Box from '@components/Box';

interface ModalSidebarProps extends StyledBoxProps {
  isRight?: boolean;
}
const ModalSidebar = styled(Box)<ModalSidebarProps>`
  display: flex;
  flex-direction: column;
  width: 23rem;
  background-color: #f2f5f9;
  border-radius: ${({ isRight, theme }) =>
    isRight
      ? `0 ${theme.radius.lg} ${theme.radius.lg} 0`
      : `${theme.radius.lg} 0 0 ${theme.radius.lg}`};

  ${({ theme }) => theme.media.down('md')`
  width: 17rem;
`}
`;
export default ModalSidebar;
