import React from 'react';

import Box from '@components/Box';
import ProgressBar from '@components/ProgressBar';
import Text, { defaultParagraphStyles } from '@components/Text';

interface UsageCardProps {
  currentValue?: number;
  title: string;
  totalValue: number;
}

const UsageCard: React.FC<UsageCardProps> = ({ currentValue = 0, title, totalValue }) => {
  return (
    <Box
      alignItems="center"
      compDisplay="flex"
      compHeight="80px"
      gap={2}
      justifyContent="flex-start"
    >
      <ProgressBar currentValue={currentValue} totalValue={totalValue} />
      <Box alignItems="flex-start" compDisplay="flex" flexDirection="column">
        <span>{title}</span>
        <Text {...defaultParagraphStyles} fontSize="15px" fontWeight="bold" textAlign="right">
          {`${currentValue.toLocaleString()} /${totalValue.toLocaleString()}`}
        </Text>
      </Box>
    </Box>
  );
};

export default UsageCard;
