import { ColumnModel } from '@api/columns/ColumnModel';
import type { UseFetchOptions } from '@hooks/useFetch';
import useFetch from '@hooks/useFetch';
import type { PaginatedResponse } from '@models/PaginatedResponse';

import paginatedTransform from '../paginatedTransform';

import * as cacheKeys from './cacheKeys';

export const fetchBiColumnsSelect = paginatedTransform(ColumnModel);
interface FetchBiColumnsParams {
  bi_tables?: string;
  datasets?: string;
  order?: string;
}

export const useFetchBiColumns = (
  options: UseFetchOptions<PaginatedResponse<ColumnModel>, FetchBiColumnsParams>,
) => {
  return useFetch<PaginatedResponse<ColumnModel>>({
    ...options,
    queryKey: [...cacheKeys.all, options.params],
    select: fetchBiColumnsSelect,
    url: `/bi/columns/`,
  });
};
