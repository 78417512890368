import styled from '@emotion/styled';

import Box from '@components/Box';
import rootTheme from '@styles/theme';

export const StyledOptionsButton = styled(Box)`
  transition: all 150ms linear;
  background: transparent;
  border-color: transparent;

  &:hover {
    background-color: ${({ theme }) => theme.colors.v1.primary[50]};
    border-color: ${({ theme }) => theme.colors.gray[200]};
  }
`;

StyledOptionsButton.defaultProps = {
  alignItems: 'center',
  borderRadius: 'default',
  borderStyle: 'solid',
  borderWidth: '1px',
  compDisplay: 'flex',
  compHeight: rootTheme.space(2.5),
  compWidth: rootTheme.space(2.5),
  justifyContent: 'center',
};
