import stripSpaces from '@utils/stripSpaces';

export default stripSpaces(`{
  guid,
  type,
  name,
  icon,
  color,
  owner,
  description,
  richtext_description,
  updated_on,
  breadcrumbs
}`);
