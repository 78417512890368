import React from 'react';

import Box from '@components/Box';

import { SidebarSection } from '../AppMainSidebar.types';
import ShowMore from '../ShowMore';

import {
  StyledLogo,
  StyledLogoLink,
  StyledPrimarySidebar,
  StyledPrimarySidebarLinksSection,
  StyledPrimarySidebarLogoContainer,
} from './PrimarySidebar.styles';

export interface PrimarySidebarProps {
  activeMenuItemId?: string;
  disableAccessToHomepage?: boolean;
  isCollapsed: boolean;
  onHomeClick?: () => void;
  sections: SidebarSection[];
  useLlmChatBot?: boolean;
}

const PrimarySidebar = ({
  activeMenuItemId,
  disableAccessToHomepage = false,
  isCollapsed,
  onHomeClick,
  sections = [],
  useLlmChatBot,
}: PrimarySidebarProps) => {
  return (
    <StyledPrimarySidebar
      aria-expanded={!isCollapsed}
      data-testid="primary-sidebar"
      isCollapsed={isCollapsed}
    >
      <StyledPrimarySidebarLogoContainer shouldCenterContent={isCollapsed}>
        <StyledLogoLink
          aria-disabled={disableAccessToHomepage}
          disableAccess={disableAccessToHomepage}
          isCollapsed={isCollapsed}
          onClick={onHomeClick}
          to="/"
        >
          <StyledLogo alt="Select Star logo" src="/icons/logo-ss-sign.svg" />
          <StyledLogo
            alt="Select Star text"
            height="14px"
            src="/icons/logo-ss-text-only-black.svg"
          />
        </StyledLogoLink>
      </StyledPrimarySidebarLogoContainer>
      <Box compDisplay="flex" compWidth="100%" flex={1} flexDirection="column">
        {sections.map(({ grow, id, items, maxItems, position = 'top' }) => {
          const initialItems = items.slice(0, maxItems);
          const showMoreItems = maxItems && items.length > maxItems ? items.slice(maxItems) : [];

          return (
            <StyledPrimarySidebarLinksSection
              key={id}
              alignSelf={position === 'bottom' ? 'flex-end' : 'flex-start'}
              flexGrow={grow ? 1 : undefined}
              mt={position === 'bottom' ? 'auto' : undefined}
              sectionPosition={position}
            >
              {initialItems.map((item) => {
                return item?.anchor?.({
                  activeMenuItemId,
                  id: item.id,
                  isActive: activeMenuItemId === item.id,
                  isCollapsed,
                  label: item.label,
                  onClick: item.onClick,
                  useLlmChatBot,
                });
              })}
              {showMoreItems.length > 0 && (
                <ShowMore
                  activeMenuItemId={activeMenuItemId}
                  isCollapsed={isCollapsed}
                  items={showMoreItems}
                />
              )}
            </StyledPrimarySidebarLinksSection>
          );
        })}
      </Box>
    </StyledPrimarySidebar>
  );
};

export default PrimarySidebar;
