import { Editor, Element, Path, Text, Transforms } from 'slate';

const INLINE_ELEMENTS = ['code', 'mention', 'link', 'span', 'br'];

/**
 * Plugin for defining new custom inline elements and custom logic related to inline elements.
 */
const withInlineElementsPlugin = (editor: Editor) => {
  const { insertBreak, isInline } = editor;

  editor.isInline = (element) =>
    INLINE_ELEMENTS.includes(element.type) ? true : isInline(element);

  /**
   * For certain inline elements inserting a break should not carry styles over to new line.
   * For those elements we will insert a new unstyled paragraph node instead.
   */
  editor.insertBreak = () => {
    const { selection } = editor;
    if (selection) {
      const [nodeMatch] = Array.from(
        Editor.nodes(editor, {
          match: (node) => {
            if (Text.isText(node)) {
              return INLINE_ELEMENTS.some((style) => style in node);
            }
            if (Element.isElement(node)) {
              return INLINE_ELEMENTS.some((style) => node.type === style);
            }
            return false;
          },
        }),
      );

      const selectionPoint = Editor.point(editor, selection);
      const nodeEnd = Editor.end(editor, nodeMatch?.[1]);

      /**
       * Determine if we are at the end of the type of block we need to breakout of
       * and also at the end of the document.
       */
      const shouldBreakout =
        selectionPoint &&
        nodeEnd &&
        Path.equals(nodeEnd?.path, selectionPoint.path) &&
        selectionPoint.offset === nodeEnd.offset;

      if (shouldBreakout) {
        Transforms.insertNodes(editor, {
          children: [{ text: '' }],
          type: 'paragraph',
        });
        return;
      }
      if (nodeMatch) {
        Transforms.splitNodes(editor, { always: true, at: selection });
        Transforms.setNodes(editor, { type: 'paragraph' });
        return;
      }
    }
    insertBreak();
  };

  return editor;
};

export default withInlineElementsPlugin;
