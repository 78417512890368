import { Expose, Transform, Type } from 'class-transformer';
import moment from 'moment';

import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import { DashboardModel } from '@api/dashboards/DashboardModel';
import type { DescriptionSource } from '@api/description/description.types';
import { DsUserModel } from '@api/dsusers/DsUserModel';
import { TaggedItemModel } from '@api/tags/TaggedItemModel';
import { ObjectType } from '@api/types';
import { breadcrumbsToLabelList, breadcrumbsToList } from '@components/Breadcrumbs';
import isEmptyRichText from '@components/RichTextEditor/helpers/isEmptyRichText';
import DataTypesModel from '@models/DataTypesModel';
import { PopularityModel } from '@models/PopularityModel';
import { urlFor } from '@utils/routing';

type ViewType = 'dashboard' | 'sheet' | 'unknown';

export class BiViewModel {
  static objectType: ObjectType = 'tableauview';

  objectType: ObjectType = BiViewModel.objectType;

  static typeDisplay: string = 'Tableau View';

  typeDisplay: string = BiViewModel.typeDisplay;

  guid!: string;

  @Type(() => DashboardModel)
  workbook!: DashboardModel;

  @Type(() => BreadcrumbModel)
  breadcrumbs?: BreadcrumbModel[];

  get breadcrumbList() {
    return breadcrumbsToList(this.breadcrumbs);
  }

  get breadcrumbLabelList() {
    const url = urlFor(this, false, '');
    return breadcrumbsToLabelList(this.name, url, this.breadcrumbs);
  }

  @Expose({ name: 'external_id' })
  externalId!: string;

  name!: string;

  description?: string;

  // TODO: Remove transform https://app.shortcut.com/select-star/story/55620
  @Expose({ name: 'richtext_description' })
  @Transform((richtextDescription) =>
    isEmptyRichText(richtextDescription) ? '' : richtextDescription,
  )
  richtextDescription?: string;

  @Expose({ name: 'view_type' })
  viewType!: ViewType;

  @Expose({ name: 'view_count' })
  viewCount!: number;

  @Expose({ name: 'view_created_at' })
  @Transform((value) => moment(value))
  createdAt!: moment.Moment;

  @Expose({ name: 'view_updated_at' })
  @Transform((value) => moment(value))
  updatedAt!: moment.Moment;

  @Expose({ name: 'dsuser_created_by' })
  @Type(() => DsUserModel)
  dsuserCreatedBy?: DsUserModel;

  @Expose({ name: 'is_hidden' })
  isHidden?: boolean;

  @Type(() => PopularityModel)
  popularity?: PopularityModel;

  @Expose({ name: 'data_types' })
  @Type(() => DataTypesModel)
  dataTypes?: DataTypesModel = new DataTypesModel();

  @Expose({ name: 'tagged_items' })
  @Type(() => TaggedItemModel)
  taggedItems?: TaggedItemModel[];

  @Expose({ name: 'search_name' })
  fullName?: string;

  @Expose({ name: 'parent_guid' })
  @Transform((value, obj) => (obj.workbook ? obj.workbook.guid : value))
  parentGuid?: string;

  @Expose({ name: 'deactivation_scheduled_on' })
  @Transform((value) => (value ? moment(value) : value))
  deactivationScheduledOn?: moment.Moment;

  @Expose({ name: 'ai_description' })
  aiDescription?: string;

  @Expose({ name: 'ingested_description' })
  ingestedDescription?: string;

  @Expose({ name: 'description_source' })
  descriptionSource?: DescriptionSource;

  @Expose({ name: 'user_description' })
  userDescription?: string;
}
