import React, { forwardRef, useEffect, useRef } from 'react';

import type { StyledTextareaInputProps, StyledTextareaProps } from './Textarea.styles';
import { StyledTextarea, StyledTextareaInput, StyledTextareaMessage } from './Textarea.styles';

export interface TextareaProps
  extends StyledTextareaInputProps,
    Omit<StyledTextareaProps, 'state'> {
  autoHeight?: boolean;
  errorMessage?: React.ReactNode;
  isError?: boolean;
  maxHeight?: string;
  message?: React.ReactNode;
}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      autoHeight,
      borderWidth = 2,
      disabled,
      errorMessage,
      fontSize,
      isError,
      maxHeight,
      message,
      outlined,
      value,
      ...other
    },
    ref,
  ) => {
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);
    const getState = () => {
      if (disabled) return 'disabled';
      if (isError) return 'error';
      return 'idle';
    };

    const state = getState();

    useEffect(() => {
      if (autoHeight && textareaRef.current) {
        /*
         * We need to reset the height momentarily to get the correct scrollHeight for the textarea.
         * Without this the textarea will properly grow but not shrink.
         */
        textareaRef.current.style.height = '0px';
        const { scrollHeight } = textareaRef.current;
        textareaRef.current.style.height = `${scrollHeight}px`;
      }
    }, [autoHeight, textareaRef, value]);

    return (
      <>
        <StyledTextarea
          borderWidth={borderWidth}
          fontSize={fontSize}
          outlined={outlined}
          state={state}
          tabIndex={0}
        >
          <StyledTextareaInput
            ref={(node) => {
              textareaRef.current = node;
              if (typeof ref === 'function') {
                ref(node);
              } else if (ref) {
                ref.current = node;
              }
            }}
            disabled={disabled}
            maxHeight={maxHeight}
            value={value}
            {...other}
          />
        </StyledTextarea>
        {(errorMessage || message) && (
          <StyledTextareaMessage state={state}>
            {isError && errorMessage}
            {!isError && message}
          </StyledTextareaMessage>
        )}
      </>
    );
  },
);

export default Textarea;
