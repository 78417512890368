import React from 'react';

import Box from '@components/Box';
import Button from '@components/Button';
import Text from '@components/Text';
import Image from '@components/UI/Image';
import { useModal } from '@context/Modal';
import { useUserContext } from '@context/User';

const CollectionsPageEmptyState = () => {
  const { hasEditPermissions } = useUserContext();
  const { MODAL_IDS, openModal } = useModal();

  const handleCreateCollectionClick = () => {
    openModal(MODAL_IDS.createCollection);
  };

  return (
    <Box
      alignItems="center"
      compDisplay="flex"
      compHeight="100%"
      compWidth="100%"
      flexWrap="wrap"
      justifyContent="center"
    >
      <Image alt="collection" height="auto" src="/images/collection.svg" width="400px" />
      <Box alignItems="center" compDisplay="flex" flexDirection="column" gap={2.5} maxWidth="300px">
        <Box alignItems="center" compDisplay="flex" flexDirection="column">
          <Text
            color="gray.500"
            fontSize="body2"
            fontWeight="semibold"
            lineHeight="17px"
            textAlign="center"
          >
            Keep your domain knowledge accessible and organized across teams.
          </Text>
          <Text color="gray.500" fontSize="body2" lineHeight="17px" textAlign="center">
            Start creating collections that allow seamless collaboration and foster data sharing
            between departments.
          </Text>
        </Box>
        {hasEditPermissions && (
          <Button
            onClick={handleCreateCollectionClick}
            startIconName="plus"
            startIconSize="20px"
            variant="outlined"
          >
            New Collection
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default CollectionsPageEmptyState;
