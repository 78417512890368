import React from 'react';

import { useFetchItemUserLists } from '@api/userLists';
import DownstreamOwnersTable from '@components/Table/DownstreamOwnersTable';
import { Filter } from '@utils';

const SORT_CONFIG: { [key: string]: string } = {
  downstreamDashboards: 'downstream_dashboards',
  downstreamTables: 'downstream_tables',
  user: 'name',
};

const DEFAULT_CONFIG = {
  order: '-downstream_dashboards',
  page_size: 25,
};

const DOWNSTREAM_OWNERS_LIST_NAME = 'Downstream Owners';

interface DownstreamOwnersTabProps {
  itemGuid: string;
}

const DownstreamOwnersTab = ({ itemGuid }: DownstreamOwnersTabProps) => {
  const FilterService = Filter.useUpdateFilters(DEFAULT_CONFIG, {}, SORT_CONFIG, '');
  const { filter } = FilterService;

  const { data } = useFetchItemUserLists(itemGuid, {
    params: {
      ...Filter.setParams(filter),
      search_name: DOWNSTREAM_OWNERS_LIST_NAME,
      user_list_types: 'downstream_owners',
    },
  });

  const users = data?.[0].users;
  const userCount = data?.[0].userCount;

  const totalPages = userCount ? Math.ceil(userCount / DEFAULT_CONFIG.page_size) : 1;

  return (
    <DownstreamOwnersTable
      data={users}
      filterService={FilterService}
      itemCount={userCount}
      totalPages={totalPages}
    />
  );
};

export default DownstreamOwnersTab;
