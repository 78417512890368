import React from 'react';

import Alert from '@components/Alert';
import type { StyledBoxProps } from '@components/Box';
import Box from '@components/Box';
import ErrorCredentialAlert from '@components/DataSourceSetup/components/ErrorCredentialAlert/ErrorCredentialAlert';
import type { ApiError } from '@models/ApiError';
import type { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';

import ErrorDescriptionMarkdown from '../ErrorDescriptionMarkdown';

interface DataSourceAddStepErrorProps extends StyledBoxProps {
  error?: EnhancedErrorResult | ApiError | null | undefined;
}

const DataSourceAddStepError: React.FC<DataSourceAddStepErrorProps> = ({ error, ...other }) => {
  const hasStatusError = error?.status === 500;
  const hasCredentialError = error?.data.credentials;
  const hasError = hasStatusError || hasCredentialError;

  if (!hasError) return null;

  return (
    <Box px={3} {...other}>
      {hasStatusError && <Alert title="Couldn't store credentials.">Please try again later.</Alert>}
      <ErrorCredentialAlert error={error?.data.credentials} />
      {!hasCredentialError && <ErrorDescriptionMarkdown error={error} />}
    </Box>
  );
};

export default DataSourceAddStepError;
