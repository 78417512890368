import styled from '@emotion/styled';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.space(0.25, 0.5)};
  background: ${({ theme }) => theme.colors.v1.gray[200]};
  border-radius: 4px;
  line-height: normal;
  height: 20px;
  gap: ${({ theme }) => theme.space(0.25)};
`;
