import styled from '@emotion/styled';
import { Loader } from 'semantic-ui-react';

/**
 * Only to be used when there are no other components on screen
 * except the loader
 */

/** @deprecated use @CircularLoader */
export const FullPageVerticallyCenteredLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: auto;
  height: 100vh;

  ${() => {
    if (process.env.REACT_APP_CHROME_EXTENSION_BUILD) {
      /**
       * Trying to set the extension height to 600px (maximum height) creates
       * an issue where the scrollbar disappears for some users.
       * To make the height look like the maximum, we used 599px.
       */
      return `
        height: 599px;
      `;
    }
    return '';
  }}
`;

/** @deprecated use @CirularLoader */
export const CenterLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

/** @deprecated use @CirularLoader */
export const IndeterminateLoader = styled(Loader)`
  &.ui.loader {
    z-index: 1;
    &:before {
      border: 0.2em solid #e2e9f0;
    }
    &:after {
      border-color: #375fa0 transparent transparent;
    }
  }
`;

/**
 * Workaround for a bug breaking loader inside a modal
 * https://github.com/Semantic-Org/Semantic-UI/issues/4014
 */
/** @deprecated use @CirularLoader */
export const IndeterminateLoaderModal = styled(IndeterminateLoader)`
  &.ui.loader {
    color: #2c2e36 !important;
    &:before {
      border: 0.2em solid #e2e9f0 !important;
    }
    &:after {
      border-color: #375fa0 transparent transparent !important;
    }
  }
`;

IndeterminateLoaderModal.defaultProps = {
  'aria-busy': 'true',
  'aria-label': 'loading',
  'aria-live': 'polite',
  role: 'progressbar',
};

export default IndeterminateLoader;
