import React from 'react';

import AboveSearchContainer from '@components/SearchBar/SearchResult/AboveSearchContainer.styles';
import Text from '@components/Text';

interface SearchSuggestionItemProps {
  searchSuggestionTexts: Array<string>;
  text: string;
}

const SearchSuggestionItem: React.FC<SearchSuggestionItemProps> = ({
  searchSuggestionTexts,
  text,
}) => {
  return (
    <AboveSearchContainer>
      0 results for
      <Text as="span" color="inherit" fontSize="inherit" fontWeight="bold" mb={0} mx={0.25}>
        {text}.
      </Text>
      <Text as="span" color="inherit" fontSize="inherit" mb={0} mx={0.25}>
        Showing search results for:
      </Text>
      <Text as="span" color="inherit" fontSize="inherit" fontWeight="bold" mb={0} mx={0.25}>
        {searchSuggestionTexts.join(', ')}
      </Text>
    </AboveSearchContainer>
  );
};

export default SearchSuggestionItem;
