import React from 'react';

import { useFetchTeamWorkspaces, useFetchWorkspaces } from '@api/workspaces';
import TabError from '@components/TabContent/TabError';
import { FavoritesTable } from '@components/Table';
import { TabContentProps } from '@components/Tabs/types';
import { useTagContext } from '@context/Tag';
import stripSpaces from '@utils/stripSpaces';

import { ProfileType } from '../ProfilePage.types';

import { MostQueriedTabProps } from './MostQueriedMainTab';

const WORKSPACE_ITEMS_QUERY = stripSpaces(`{
    item_name,
    item{
      object_type,
      obj{
        guid,
        name,
        search_name,
        data_type,
        data_source_type,
        last_updated_on,
        breadcrumbs,
        data_types
      }
    }
}`);

const WORKSPACE_TEAM_QUERY = stripSpaces(`{
    user{
      guid,
      first_name,
      last_name,
      avatar
    },
    item_name,
    item{
      object_type,
      obj{
        guid,
        name,
        search_name,
        data_type,
        data_source_type,
        last_updated_on,
        breadcrumbs,
        data_types
      }
    }
}`);

interface ProfileFavoritesTabProps extends TabContentProps {
  guid: string;
  profileType: ProfileType;
}

const ProfileFavoritesTab: React.FC<ProfileFavoritesTabProps> = ({
  guid,
  profileType,
  stickyHeader,
}) => {
  const { data: userWorkspaces, isError: userWorkspacesError } = useFetchWorkspaces(guid, {
    enabled: profileType === ProfileType.user,
    params: {
      query: WORKSPACE_ITEMS_QUERY,
    },
  });

  const { data: teamWorkspaces, isError: teamWorkspacesError } = useFetchTeamWorkspaces(guid, {
    enabled: profileType === ProfileType.team,
    params: {
      query: WORKSPACE_TEAM_QUERY,
    },
  });

  const { findTag } = useTagContext();

  const data = teamWorkspaces || userWorkspaces;
  const isError = teamWorkspacesError || userWorkspacesError;

  if (isError) return <TabError />;

  return (
    <FavoritesTable
      findTag={findTag}
      hideUserColumn={profileType === ProfileType.user}
      itemCount={data?.results.length}
      items={data?.results}
      stickyHeader={stickyHeader}
      userColName="Member"
    />
  );
};

export default React.memo<MostQueriedTabProps>(ProfileFavoritesTab);
