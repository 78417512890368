import React, { useState } from 'react';
import { RouterNavLink } from '@routing/router';

import Box from '@components/Box';
import Avatar from '@components/UI/Avatar';
import Icon from '@components/UI/Icon';
import theme from '@styles/theme';

import {
  StyledTeamsEmptyMessage,
  StyledTeamsMenuLink,
  StyledTeamsMenuSectionTitle,
} from './TeamsMenu.styles';
import type { TeamSection } from './useTeamsSections';

interface Props {
  section: TeamSection;
}

const TeamsMenu: React.FC<Props> = ({ section }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <>
      <StyledTeamsMenuSectionTitle
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <Box
          alignItems="center"
          compDisplay="flex"
          compHeight={theme.space(3)}
          compWidth={theme.space(2.5)}
          justifyContent="center"
        >
          <Icon
            color="currentColor"
            name={isOpen ? 'arrow-down-filled' : 'arrow-right-filled'}
            size="18px"
          />
        </Box>
        {section.title}
      </StyledTeamsMenuSectionTitle>
      {isOpen
        ? section.teams.map((item) => (
            <StyledTeamsMenuLink
              key={item.guid}
              as={RouterNavLink}
              compDisplay="flex"
              gap={1}
              to={`/teams/${item.guid}`}
            >
              <Avatar {...item.mappedAvatar} />
              {item.name}
            </StyledTeamsMenuLink>
          ))
        : null}
      {isOpen && section.isEmpty ? (
        <StyledTeamsEmptyMessage>{section.emptyMessage}</StyledTeamsEmptyMessage>
      ) : null}
    </>
  );
};

export default TeamsMenu;
