import { useFetchDataset } from '@api/datasetsV1';

import DatasetsTabContent from './DatasetsTabContent';

export default {
  Content: DatasetsTabContent,
  CustomTitle: undefined,
  OverviewCustomContent: undefined,
  enableNotifications: true,
  fetchHook: useFetchDataset,
  query: undefined,
};
