/* eslint-disable import/prefer-default-export */
import type { Formatter } from 'recharts/types/component/DefaultTooltipContent';

import type { Name, Value } from '../types';

export const formatValue: Formatter<Value, Name> = (value, name, item) => {
  const team = item.payload.payload;
  return [
    [
      {
        name: 'Team',
        value: name,
      },
      { name: 'Credit', value: team.formattedTotalCreditsUsed },
      { name: 'Query Count', value: team.formattedTotalRun },
      { name: 'Active Users', value: team.formattedMembersCount },
    ],
    name,
  ];
};
