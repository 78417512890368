import styled from '@emotion/styled';

import Button from '@components/Button/Button';
import rootTheme from '@styles/theme';

export interface StyledButtonProps {
  state: 'collapsed' | 'expanded';
}

export const colors = {
  collapsed: {
    default: {
      background: 'white',
      border: rootTheme.colors.v1.gray[300],
      color: rootTheme.colors.v1.gray[500],
      icon: rootTheme.colors.v1.gray[500],
    },
    hover: {
      background: rootTheme.colors.v1.gray[200],
      border: rootTheme.colors.v1.gray[300],
      color: rootTheme.colors.v1.gray[500],
      icon: rootTheme.colors.v1.gray[500],
    },
  },
  expanded: {
    default: {
      background: rootTheme.colors.v1.primary[50],
      border: rootTheme.colors.v1.primary[300],
      color: rootTheme.colors.v1.primary[500],
      icon: rootTheme.colors.v1.primary[500],
    },
    hover: {
      background: rootTheme.colors.v1.primary[200],
      border: rootTheme.colors.v1.primary[300],
      color: rootTheme.colors.v1.primary[500],
      icon: rootTheme.colors.v1.primary[500],
    },
  },
};

export const StyledExpandCollapseButton = styled(Button)<StyledButtonProps>`
  ${({ state, theme }) =>
    `
    text-wrap: nowrap;
    border-radius: ${theme.radius.xl}
    background-color: ${colors[state].default.background};
    border-color: ${colors[state].default.border};
    color: ${colors[state].default.color};

    &:hover {
      background-color: ${colors[state].hover.background};
      border-color: ${colors[state].hover.border};
      color: ${colors[state].hover.color};

      svg {
        color: ${colors[state].hover.icon};
      }
    }
  `}
`;

StyledExpandCollapseButton.defaultProps = {
  compSize: 'xxs',
  spacing: 0.5,
};
