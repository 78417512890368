import React, { createContext, useContext } from 'react';

export interface WorkspacesContextInterface {
  setWorkspaces?: React.Dispatch<React.SetStateAction<string[] | undefined>>;
  workspaces?: string[];
}

export const WorkspacesContext = createContext<WorkspacesContextInterface>({});

export const useWorkspacesContext = () => {
  const workspacesContext = useContext(WorkspacesContext);

  if (workspacesContext === undefined) {
    throw new Error(`useWorkspacesContext must be used within <WorkspacesProvider>.`);
  }

  return workspacesContext;
};

export default useWorkspacesContext;
