import { Expose, Transform, Type } from 'class-transformer';
import moment from 'moment';

import { ObjectType } from '@api/types';
import DataTypesModel from '@models/DataTypesModel';
import { ProcessedThroughModel } from '@models/ingestionConfig/ProcessedThroughModel';

import type { DataSourceCredentials, DataSourceTypesType } from './DataSourceCredentials';
import { DataSourceSettings, DataSourceSettingsData } from './DataSourceSettngsModel';

export enum ConnectionStatusEnum {
  Correct = 'OK',
  Error = 'ER',
  Unknown = 'UK',
}

export interface DataSourceData {
  cloudFormationUrl?: { [key: string]: string };
  connectionErrorMessage?: string;
  connectionStatus: ConnectionStatusEnum;
  credentials?: DataSourceCredentials;
  description?: string;
  externalUUID?: string;
  guid: string;
  iamPrincipal?: string;
  iconName?: string;
  isCredentialsSetupIncomplete?: boolean;
  isEmailSent?: boolean;
  isIngesting?: boolean;
  isModeDiscoveryDBAdded?: boolean;
  lastFullyIngestedOn?: moment.Moment;
  lastIngestedOn?: moment.Moment;
  name: string;
  processedThrough?: ProcessedThroughModel;
  settings?: DataSourceSettingsData;
  type: DataSourceTypesType;
}

export class DataSourceModel {
  static objectType: ObjectType = 'datasource';

  objectType: ObjectType = DataSourceModel.objectType;

  static typeDisplay: string = 'Data Source';

  typeDisplay: string = DataSourceModel.typeDisplay;

  guid!: string;

  type!: DataSourceTypesType;

  name!: string;

  @Expose({ name: 'data_types' })
  @Type(() => DataTypesModel)
  dataTypes?: DataTypesModel = new DataTypesModel();

  @Expose({ name: 'search_name' })
  fullName?: string;

  @Expose({ name: 'external_uuid' })
  externalUUID?: string;

  @Expose({ name: 'is_syncing_paused' })
  isSyncingPaused?: boolean;

  @Expose({ name: 'iam_principal' })
  iamPrincipal?: string;

  @Expose({ name: 'cloudformation_url' })
  cloudFormationUrl?: { [key: string]: string };

  @Expose({ name: 'is_credentials_setup_incomplete' })
  isCredentialsSetupIncomplete?: boolean;

  @Expose({ name: 'connection_status' })
  connectionStatus!: ConnectionStatusEnum;

  @Expose({ name: 'connection_error_message' })
  connectionErrorMessage?: string;

  description?: string;

  @Type(() => ProcessedThroughModel)
  @Expose({ name: 'processed_through' })
  processedThrough?: ProcessedThroughModel;

  @Expose({ name: 'last_fully_ingested_on' })
  @Transform((value) => (value ? moment(value) : value))
  lastFullyIngestedOn?: moment.Moment;

  @Expose({ name: 'last_ingested_on' })
  @Transform((value) => (value ? moment(value) : value))
  lastIngestedOn?: moment.Moment;

  @Expose({ name: 'is_email_sent' })
  isEmailSent?: boolean;

  @Expose({ name: 'is_ingesting' })
  isIngesting?: boolean;

  credentials?: DataSourceCredentials;

  @Type(() => DataSourceSettings)
  settings?: DataSourceSettings;

  @Expose({ name: 'is_mode_discovery_db_added' })
  isModeDiscoveryDBAdded?: boolean;

  @Expose({ name: 'data_source_type' })
  dataSourceType?: DataSourceTypesType;

  @Expose({ name: 'should_show' })
  shouldShow?: boolean;
}
