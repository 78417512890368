import * as CSS from 'csstype';

import { FONT_SIZE_BASE } from '../constants';
import rem from '../mixins/rem';

export interface TypographyElementProps {
  fontSize: CSS.Property.FontSize;
  fontWeight: CSS.Property.FontWeight;
  lineHeight: CSS.Property.LineHeight;
}

export type TypographyElement = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p';

export type FontWeight = 'medium' | 'regular' | 'semibold' | 'bold';
export type FontFamily = 'primary' | 'mono' | 'inherit';
export type LineHeight = 'default' | 'sm' | 'md';
export type FontSize =
  | 'xs'
  | 'sm'
  | 'default'
  | 'inherit'
  | 'body1'
  | 'body2'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5';

export type FontWeights = Record<FontWeight, 400 | 500 | 600 | 700>;
export type FontFamilies = Record<FontFamily, CSS.Property.FontFamily>;
export type LineHeights = Record<LineHeight, CSS.Property.LineHeight>;
export type FontSizes = Record<FontSize, CSS.Property.FontSize>;

export interface TypographyGeneralOptions {
  fontFamilies: FontFamilies;
  fontSizes: FontSizes;
  fontWeights: FontWeights;
  lineHeights: LineHeights;
}

export type TypographyElementOptions = Record<TypographyElement, TypographyElementProps>;

export type Typography = TypographyGeneralOptions & TypographyElementOptions;

const fontWeights: FontWeights = {
  bold: 700,
  medium: 500,
  regular: 400,
  semibold: 600,
};
const fontSizes: FontSizes = {
  body1: rem('13px'),
  body2: rem('14px'),
  default: rem(FONT_SIZE_BASE),
  h1: rem('28px'),
  h2: rem('20px'),
  h3: rem('18px'),
  h4: rem('16px'),
  h5: rem('14px'),
  inherit: 'inherit',
  sm: rem('12px'),
  xs: rem('11px'),
};

const typography: Typography = {
  fontFamilies: {
    inherit: 'inherit',
    mono: 'Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace, monospace',
    primary: '"Inter", "Oxygen", "Ubuntu", sans-serif',
  },
  fontSizes,
  fontWeights,
  h1: {
    fontSize: fontSizes.h1,
    fontWeight: fontWeights.bold,
    lineHeight: 1.2,
  },
  h2: {
    fontSize: fontSizes.h2,
    fontWeight: fontWeights.bold,
    lineHeight: 1.2,
  },
  h3: {
    fontSize: fontSizes.h3,
    fontWeight: fontWeights.bold,
    lineHeight: 1.2,
  },
  h4: {
    fontSize: fontSizes.h4,
    fontWeight: fontWeights.regular,
    lineHeight: 1.2,
  },
  h5: {
    fontSize: fontSizes.h5,
    fontWeight: fontWeights.bold,
    lineHeight: 1.2,
  },
  lineHeights: {
    default: 1.45,
    md: 1.35,
    sm: 1.2,
  },
  p: {
    fontSize: '1rem',
    fontWeight: fontWeights.regular,
    lineHeight: 1.5,
  },
};

export default typography;
