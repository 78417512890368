import { css } from '@emotion/react';
import styled from '@emotion/styled';
import * as CSS from 'csstype';

import RichTextEditor from '@components/RichTextEditor';
import Icon from '@components/UI/Icon';

import {
  EDIT_ICON_SIZE,
  EditorVariant,
  editorVariantStyles,
  slateVariantStyles,
} from './RichTextDescriptionEditor.styles.variants';

interface StyledEditIconProps {
  variant: 'hover' | 'always';
}

export const StyledEditIcon = styled(Icon)<StyledEditIconProps>`
  visibility: ${({ variant }) => (variant === 'hover' ? 'hidden' : 'visible')};
  cursor: pointer;
  margin-right: ${({ theme, variant }) => (variant === 'hover' ? 0 : theme.space(0.5))};
`;

StyledEditIcon.defaultProps = {
  color: 'gray.400',
  ml: 'auto',
  size: EDIT_ICON_SIZE,
};

interface StyledRichTextEditorProps
  extends Pick<StyledRichTextDescriptionEditorProps, 'variant' | 'editIconVariant'> {
  isEditing?: boolean;
}

export const StyledRichTextEditor = styled(RichTextEditor)<StyledRichTextEditorProps>`
  ${({ editIconVariant, isEditing, variant }) => {
    if (!isEditing && variant === 'inline') {
      const mappedVariant = slateVariantStyles[variant];
      if (editIconVariant === 'always') {
        return css`
          ${mappedVariant['read-icon-always']}
        `;
      }
      return css`
        ${mappedVariant['read-icon-hover']}
      `;
    }
    return 'width: 100%;';
  }}

  .slate-editable.slate-editable {
    ${({ isEditing, variant = 'inline' }) => {
      const state = isEditing ? 'edit' : 'read';
      const mappedVariant = slateVariantStyles[variant];
      return css`
        ${mappedVariant[state]}
      `;
    }};

    height: 100%;
    word-break: break-word;
    white-space: initial;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const StyledInlineRichTextEditorContent = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  overflow-x: auto;
`;

export interface StyledRichTextDescriptionEditorProps {
  editIconVariant?: 'hover' | 'always';
  editorIsFocused?: boolean;
  fontSize?: CSS.Property.FontSize;
  isEditing?: boolean;
  variant?: EditorVariant;
}

export const StyledRichTextDescriptionEditor = styled.div<StyledRichTextDescriptionEditorProps>`
  position: relative;
  flex-shrink: 0;
  font-size: ${({ fontSize }) => fontSize};
  border-radius: ${({ theme }) => theme.radius.default};
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  border-width: 1px;
  border-radius: ${({ theme }) => theme.radius.md};
  margin: ${({ theme }) => theme.space(0.4, 0.4, 0.4, 0)};
  &::-webkit-scrollbar {
    display: none;
  }
  span[data-slate-placeholder='true'] {
    top: ${({ theme }) => theme.space(1)}!important;
  }
  :hover {
    ${StyledEditIcon} {
      visibility: visible;
    }
  }

  width: ${({ editIconVariant, isEditing = '100%' }) =>
    !isEditing && editIconVariant === 'always' ? 'fit-content' : '100%'};

  ${({ editorIsFocused, isEditing, variant = 'inline' }) => {
    let state = 'read';
    if (editorIsFocused) {
      state = 'edit-focused';
    } else if (isEditing) {
      state = 'edit';
    }
    const mappedVariant = editorVariantStyles[variant];
    return css`
      ${mappedVariant[state as keyof typeof mappedVariant]}
    `;
  }};
`;
