import moment from 'moment';

import { ColumnModel } from '@api/columns/ColumnModel';
import { Monitor } from '@api/openapi.generated';
import type { ObjectType } from '@api/types';
import { rawClassArrayMap } from '@api/utils';
import mapDataTypesModel from '@models/DataTypesModel/DataTypesModel.v1';

const objectType: ObjectType = 'monitor';

const mapMonitorsModel = (data: Monitor) => {
  return {
    columns: rawClassArrayMap(ColumnModel, data.columns),
    dataTypes: mapDataTypesModel(data.data_types),
    externalUrl: data.external_url,
    guid: data.guid,
    latestRunAt: data.last_run_at ? moment(data.last_run_at) : undefined,
    name: data.name,
    status: data.status as any, // @see sc-91107 Requested to return Union
  };
};

export default mapMonitorsModel;

mapMonitorsModel.objectType = objectType;

export type MonitorModel = ReturnType<typeof mapMonitorsModel>;
