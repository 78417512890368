import React from 'react';

import ConnectionAlertModel from '@api/connectionAlerts/ConnectionAlertModel';
import Box from '@components/Box';
import PreviewMarkdown from '@components/PreviewMarkdown';
import {
  ALERT_ICON_COLOR_MAP,
  ALERT_ICON_NAME_MAP,
} from '@components/Table/AdminDatasourcesTable/AlertTooltipContent/AlertTooltipContent.constants';
import Text from '@components/Text';
import Icon from '@components/UI/Icon';

interface AlertDescriptionProps {
  alert: ConnectionAlertModel;
}

const AlertDescription = ({ alert }: AlertDescriptionProps) => {
  const { description, lastSeen, level, title } = alert;
  return (
    <Box compDisplay="flex" compWidth="100%" flexDirection="column" gap={1.25}>
      <Box alignItems="center" compDisplay="flex" justifyContent="space-between">
        <Box alignItems="center" compDisplay="flex" gap={0.5}>
          <Icon
            color={ALERT_ICON_COLOR_MAP[level]}
            compDisplay="inline"
            name={ALERT_ICON_NAME_MAP[level]}
            size="16px"
          />
          <Text color="gray.900" fontSize="h4" fontWeight="semibold">
            {title}
          </Text>
        </Box>
        <Text color="gray.500" fontSize="body1">
          {lastSeen?.format('YYYY-MM-DD HH:mm:ss')}
        </Text>
      </Box>
      <PreviewMarkdown markdown={description} />
    </Box>
  );
};

export default AlertDescription;
