import styled from '@emotion/styled';

import Box from '@components/Box';

const ModalContainer = styled(Box)`
  width: 66.66667%;
`;

ModalContainer.defaultProps = {
  compDisplay: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
};

export default ModalContainer;
