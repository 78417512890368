import { UsageTypeType } from '@api/lineage/types';

export const getColumnIdFromKey = (key: string) => {
  const [, columnId] = key.split('/');
  return columnId;
};

export const getNewUsageTypes = (
  currentUsageTypes: Set<UsageTypeType>,
  newlySelectedUsageTypes: UsageTypeType[],
) => {
  if (currentUsageTypes.size === 0) {
    return newlySelectedUsageTypes;
  }

  return newlySelectedUsageTypes.filter((usageType) => !currentUsageTypes.has(usageType));
};

export const cloneLineageData = <T = any>(toClone: T): T => {
  if (typeof toClone !== 'object' || toClone === null) return toClone;

  if (toClone instanceof Set) {
    return new Set(toClone) as unknown as T;
  }

  if (Array.isArray(toClone)) {
    return toClone.map(cloneLineageData) as unknown as T;
  }

  if (toClone instanceof Date) {
    return new Date(toClone) as unknown as T;
  }

  const cloned: Record<string, any> = { ...toClone };
  const clonedObjKeys = Object.keys(cloned);

  for (let i = 0; i < clonedObjKeys.length; i += 1) {
    const key = clonedObjKeys[i];
    cloned[key] = cloneLineageData(cloned[key]);
  }

  return cloned as T;
};
