export const all = ['explores'];

export const explore = (id: string) => [...all, id];

export const exploreFields = (id: string) => [...all, 'fields', id];

export const exploreFrequentDsUsers = (id: string) => [...all, id, 'frequentDsUsers'];

export const exploreRelatedDashboards = (id: string) => [...all, 'relatedDashboards', id];

export const exploreRelatedDashboardsCsv = (id: string) => [...all, 'relatedDashboards', 'csv', id];
