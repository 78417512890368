import useMutation, { UseMutationOptions } from '@hooks/useMutation';
import { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';

interface UsePostForeignKeysOptions
  extends UseMutationOptions<
    never,
    EnhancedErrorResult,
    { items: { source_column: string; target_column: null | string }[] }
  > {}

export const usePostForeignKeys = (options: UsePostForeignKeysOptions) => {
  return useMutation({
    ...options,
    method: 'POST',
    url: '/foreign-keys/',
  });
};

export const useDeleteForeignKey = (options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    method: 'DELETE',
    url: `/foreign-keys/${options}/`,
  });
};
