import React from 'react';
import moment from 'moment';

import NotApplicable from '@components/NotApplicable';
import Tooltip from '@components/Tooltip';

const formatTypeMap = {
  dateFormat: 'YYYY-MM-DD',
  fullDateFormat: 'YYYY-MM-DD HH:mm:ss',
  textMonthFormat: 'MMM Do, YYYY',
  timeFormat: 'HH:mm:ss',
};
interface DateTimeProps {
  datetime?: moment.Moment | null;
  format?: keyof typeof formatTypeMap;
}

const DateTime: React.FC<DateTimeProps> = ({ datetime, format }) => {
  if (!datetime || !datetime.isValid()) {
    return <NotApplicable />;
  }

  return (
    <Tooltip content={datetime.format('YYYY-MM-DD HH:mm:ss')}>
      <span>
        {format && formatTypeMap?.[format]
          ? datetime.format(formatTypeMap?.[format])
          : datetime.fromNow()}
      </span>
    </Tooltip>
  );
};

export default DateTime;
