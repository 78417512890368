import styled from '@emotion/styled';

import Box from '@components/Box';

interface StyledTooltipTextProps {
  isAction?: boolean;
}

export const StyledTooltipText = styled(Box)<StyledTooltipTextProps>`
  text-align: left !important;
  display: inline-block;
  border-radius: 0.375rem;
  padding: 0.4rem;
  color: #fff;
  text-align: center;
  background-color: ${({ isAction = false, theme }) =>
    isAction ? theme.colors.hyperlink : theme.colors.v1.primary[900]};
  opacity: 0.9;
  line-height: 1.38;
  font-size: 0.8125rem;
  cursor: pointer;
  overflow-wrap: break-word;
  white-space: break-spaces;
  max-width: 276px;
  max-height: ${({ theme }) => `calc(100vh - ${theme.space(2)})`};
`;

StyledTooltipText.defaultProps = {
  noDefault: true,
  overflow: 'auto',
};
