import React, { useMemo } from 'react';

import { TagModel } from '@api/tags/TagModel';
import AppTag from '@components/AppTag';
import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import Text from '@components/Text';
import { useTagContext } from '@context/Tag';
import theme from '@styles/theme';

interface LinkedTagsProps {
  guid: string;
  type: 'linksTo' | 'linkedFrom';
}

const LinkedTags: React.FC<LinkedTagsProps> = ({ guid, type }) => {
  const { findTag, isLoading } = useTagContext();

  const linkedTags = useMemo(() => {
    const tag = findTag?.(guid);
    const linkedGuidsStringOrArray = tag?.[type];
    const guids = Array.isArray(linkedGuidsStringOrArray)
      ? linkedGuidsStringOrArray
      : [linkedGuidsStringOrArray];

    return guids?.map((id) => findTag?.(id)).filter(Boolean);
  }, [findTag, guid, type]);

  if (isLoading) {
    return <CircularLoader borderWidth={1} compSize={1.5} />;
  }

  if (!linkedTags.length) {
    return (
      <Text as="span" color="gray.400" fontSize={theme.typography.fontSizes.body2}>
        Link not found
      </Text>
    );
  }

  return (
    <Box compDisplay="flex" flexWrap="wrap">
      {(linkedTags as TagModel[])?.map((tag) => (
        <AppTag key={tag?.guid} ignoreLinksTo tag={tag} variant="outlined" />
      ))}
    </Box>
  );
};

export default LinkedTags;
