import React, { useEffect, useMemo, useRef } from 'react';
import type { FC } from 'react';
import type { Cell } from 'react-table';

import { useFetchCostAnalysisQueryLogs } from '@api/costAnalysis';
import type TimeModel from '@api/costAnalysis/TimeModel';
import QueryLogRecordModel from '@api/queryLog/QueryLogRecordModel';
import DateTime from '@components/DateTime';
import QueryModalWithTargetBox from '@components/Modal/QueryModal/QueryModalWithTargetBox';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import type { CostAnalysisSharedFilterParams } from '@pages/Admin/AdminCostAnalysisPage/CostAnalysisSharedFilter';
import { FilterOptions, setParams, useUpdateFilters } from '@utils/filters';

const sortConfig = {
  creditsUsed: 'credits_used',
  executedOn: 'executed_on',
  query: 'query',
} as const;

const searchConfig: { [key: string]: keyof FilterOptions } = {};

const requestConfig: FilterOptions = {
  order: `-${sortConfig.creditsUsed}`,
  page: 1,
  page_size: 20,
};

const initialTableSort = [{ desc: true, id: 'creditsUsed' }];

interface CostByDayOfWeekTableProps extends CostAnalysisSharedFilterParams {
  active: TimeModel;
}

const CostByDayOfWeekTable: FC<CostByDayOfWeekTableProps> = ({ active, range, warehouses }) => {
  const costByDayOfWeekChartWrapperRef = useRef<HTMLDivElement>(null);
  const { changePage, filter, search, sort } = useUpdateFilters(
    requestConfig,
    searchConfig,
    sortConfig,
    requestConfig.order,
  );

  const { data, isLoading } = useFetchCostAnalysisQueryLogs({
    enabled: Boolean(active),
    params: {
      ...setParams(filter),
      day_of_week: active.aggregation?.dayOfWeek,
      end_date: range[1],
      hour: active.aggregation?.hour,
      start_date: range[0],
      warehouses,
    },
  });

  const columns: ColumnConfig<QueryLogRecordModel>[] = useMemo(
    () => [
      {
        Cell: ({ row: { original } }: Cell<QueryLogRecordModel>) => {
          return <DateTime datetime={original.executedOn} format="fullDateFormat" />;
        },
        Header: 'Start Time',
        accessor: (d) => d?.executedOn,
        disableFilters: true,
        id: 'executedOn',
        width: '10rem',
      },
      {
        Cell: ({ row: { original } }: Cell<QueryLogRecordModel>) => {
          return (
            <QueryModalWithTargetBox
              codeString={original.query.rawSql}
              displayText={original?.query?.displayName}
              query={original.query}
              tooltipText={original?.query?.rawSql}
            />
          );
        },
        Header: 'Query',
        accessor: (d) => d?.query?.displayName,
        disableHiding: true,
        id: 'query',
        width: '150%',
      },
      {
        Header: 'Credit',
        accessor: (d) => d.formattedCreditsUsed,
        disableFilters: true,
        id: 'creditsUsed',
        width: '10rem',
      },
    ],
    [],
  );

  const totalPages = data && filter.page_size ? Math.ceil(data.count / filter.page_size) : 1;

  useEffect(() => {
    if (!isLoading && data) {
      costByDayOfWeekChartWrapperRef.current?.scrollIntoView?.({ behavior: 'smooth' });
    }
  }, [data, isLoading]);

  return (
    <div ref={costByDayOfWeekChartWrapperRef}>
      <TableStyled>
        <Table
          basic="very"
          changePage={changePage}
          columns={columns}
          compact
          data={data?.results ?? []}
          disableRowSelect
          initialState={{
            sortBy: initialTableSort,
          }}
          loading={isLoading}
          manualFilters
          manualPagination
          manualSortBy
          setFilters={search}
          setSortBy={sort}
          sortable
          totalPages={totalPages}
          unstackable
        />
      </TableStyled>
    </div>
  );
};

export default CostByDayOfWeekTable;
