import { CustomElementType } from '../RichTextEditor.types';

export const BLOCK_SHORTCUTS: { [key: string]: CustomElementType } = {
  '-': 'ul',
  '+': 'ul',
  '>': 'blockquote',
  '#': 'h1',
  '##': 'h2',
  '###': 'h3',
  '####': 'h4',
  '#####': 'h5',
  '######': 'h6',
  '```': 'codeblock',
};

export const INLINE_SHORTCUTS: { [key: string]: string } = {
  '`': 'code',
  '**': 'bold',
  __: 'bold',
  '*': 'italic',
  _: 'italic',
};
