import { GetMonitorsListQueryParams } from '@api/openapi.generated';
import { paginatedSelect } from '@api/utils';
import type { UseFetchOptions } from '@hooks/useFetch';
import useFetch from '@hooks/useFetch';
import { PaginatedResponse } from '@models/PaginatedResponse';

import * as cacheKeys from './cacheKeys';
import mapMonitorsModel, { MonitorModel } from './MonitorsModel';

export const fetchMonitorsSelect = paginatedSelect(mapMonitorsModel);
export const useFetchMonitors = (
  options?: UseFetchOptions<PaginatedResponse<MonitorModel>, GetMonitorsListQueryParams>,
) => {
  return useFetch<PaginatedResponse<MonitorModel>>({
    ...options,
    queryKey: [...cacheKeys.all, options?.params],
    select: fetchMonitorsSelect,
    url: `/monitors/`,
  });
};
