import React, { useState } from 'react';
import { useHistory } from '@routing/router';

import { usePatchInvitation } from '@api/invitations';
import { OrganizationInvitationVerifyModel } from '@api/invitations/OrganizationInvitationVerifyModel';
import { useFetchUsersEmployeeRoles } from '@api/users';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import CenterAlignedBox from '@components/CenterAlignedBox';
import DecoratedText from '@components/DecoratedText';
import GoogleButton from '@components/GoogleButton';
import Input from '@components/Input/Input.v1';
import Text, { defaultParagraphStyles } from '@components/Text';
import Title from '@components/Title/Title';
import Select, { Option, SelectValue } from '@components/UI/Select';

import { renderComponent, SignUpFields } from '../Signup';
import { FormState } from '../types';

const signUpFieldsIfExistingUser = ['email', 'password', 'is_agreed_to_terms'];
export const roleOtherOption = {
  key: 'other',
  text: 'Other (Please Specify)',
  value: 'other',
};

interface SignUpComponentProps {
  code: string;
  invitation?: OrganizationInvitationVerifyModel;
  isExistingUser?: boolean;
  onSuccess: () => void;
}

const InvitationSignUp: React.FC<SignUpComponentProps> = ({
  code,
  invitation,
  isExistingUser,
  onSuccess,
}) => {
  const history = useHistory();

  const defaultEmail = invitation?.email;
  const organizationName = invitation?.organizationName;
  const InvitationSignUpFields = isExistingUser
    ? SignUpFields.filter((field) => signUpFieldsIfExistingUser.includes(field.key))
    : SignUpFields;
  const { data: employeeRoles, isLoading: isLoadingRoles } = useFetchUsersEmployeeRoles();

  const roleOptions = (employeeRoles?.results ?? []).map((role) => {
    return {
      key: role.name,
      text: role.name,
      value: role.name,
    };
  });
  roleOptions?.push(roleOtherOption);

  const [signUpState, setSignUpState] = useState<FormState>(() =>
    InvitationSignUpFields.reduce(
      (dict, field) => {
        let fieldValue: boolean | string = '';

        if (field.type === 'boolean') {
          fieldValue = false;
        }

        if (field.key === 'email') {
          fieldValue = defaultEmail ?? '';
        }

        return Object.assign(dict, {
          [field.key]: fieldValue,
        });
      },
      {
        employeeRole: '',
        employeeRoleOther: '',
        status: 'accepted',
      },
    ),
  );

  const getEmployeeRoleValue: () => string | undefined = () => {
    if (signUpState.employeeRole) {
      if (signUpState.employeeRole === 'other') {
        return signUpState.employeeRoleOther as string;
      }
      return signUpState.employeeRole as string;
    }
  };

  const {
    error,
    isLoading,
    mutate: acceptInvite,
  } = usePatchInvitation(`/invitations/${code}/`, { onSuccess });

  const handleOnSubmit = () => {
    if (!isLoading) {
      const data = {
        ...signUpState,
        employee_role: getEmployeeRoleValue(),
      };
      acceptInvite(data);
    }
  };

  const isEmployeeRoleFilled: boolean = Boolean(getEmployeeRoleValue());

  if (!invitation) {
    return <> </>;
  }

  return (
    <CenterAlignedBox maxWidth="460px" minWidth="360px">
      <Box alignItems="flex-start" compWidth="100%">
        <Title showLogo>Welcome to Select Star</Title>
        <Text {...defaultParagraphStyles} as="p" fontSize="body1" py={1}>
          You are invited to join {organizationName} on Select Star
        </Text>
      </Box>
      <Box>
        <Text color="#2C2E36" display="block" fontSize="body2" fontWeight="bold" pb={1} pt={2}>
          Step 1. Choose your role at your company
        </Text>
        <Select
          error={Boolean(error?.data?.employeeRole) && signUpState.employeeRole !== 'other'}
          helperText={
            error?.data?.employeeRole && signUpState.employeeRole !== 'other'
              ? error?.data?.employeeRole
              : null
          }
          isLoading={isLoadingRoles}
          label="role"
          maxOptionsVisible={5}
          onChange={(newValue: SelectValue) => {
            const [selected] = newValue as Option<string>[];
            setSignUpState((prev) => ({
              ...prev,
              employeeRole: selected.value,
            }));
          }}
          options={roleOptions}
          placeholder="Choose your role"
        />
        {signUpState.employeeRole === 'other' && (
          <Input
            aria-label="Role Other"
            autoFocus
            error={Boolean(error?.data?.employeeRole) && signUpState.employeeRole === 'other'}
            helperText={
              error?.data?.employeeRole && signUpState.employeeRole === 'other'
                ? error?.data?.employeeRole
                : null
            }
            name="roleTypeOther"
            onChange={(e) => {
              const { value } = e.target;
              setSignUpState((prev) => ({
                ...prev,
                employeeRoleOther: value,
              }));
            }}
            placeholder="Enter your role here"
            py={1}
          />
        )}
      </Box>
      <Box pb={1}>
        <Text color="#2C2E36" display="block" fontSize="body2" fontWeight="bold" pt={2}>
          Step 2. Sign up
        </Text>
      </Box>
      <Box alignItems="flex-start" compWidth="100%" mb={1.5} mt={1}>
        <GoogleButton
          disabled={!isEmployeeRoleFilled}
          employeeRole={getEmployeeRoleValue()}
          isInviteSignUp
        />
      </Box>
      <Box alignItems="flex-start" compDisplay="flex" compWidth="100%" my={1}>
        <DecoratedText compWidth="100%">or</DecoratedText>
      </Box>
      {InvitationSignUpFields.map((field) => {
        const { key } = field;

        return (
          <Box key={key} alignItems="flex-start" compDisplay="flex" compWidth="100%" mt={1.5}>
            {renderComponent(
              field,
              setSignUpState,
              error,
              key === 'email' ? defaultEmail : undefined,
              signUpState,
            )}
          </Box>
        );
      })}
      <Button disabled={isLoading || !isEmployeeRoleFilled} fluid mt={2} onClick={handleOnSubmit}>
        Sign up
      </Button>
      <Button mt={1} onClick={() => history.push('/password/reset/')} variant="text">
        Forgot Password
      </Button>
    </CenterAlignedBox>
  );
};

export default InvitationSignUp;
