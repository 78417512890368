import React, { cloneElement, useRef, useState } from 'react';
import { useTheme } from '@emotion/react';
import {
  arrow,
  autoUpdate,
  flip,
  FloatingPortal,
  offset as offsetMiddleware,
  Placement,
  shift,
  useClick,
  useClientPoint,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
} from '@floating-ui/react';

import { StyledTooltipArrow, StyledTooltipArrowProps, StyledTooltipText } from './Tooltip.styles';

export interface TooltipProps extends Pick<StyledTooltipArrowProps, 'arrowColor'> {
  action?: boolean;
  anchorClassName?: string;
  appendTo?: HTMLElement;
  children?: React.ReactElement;
  content?: React.ReactNode;
  delay?: number;
  fallbackPlacements?: Placement[];
  followCursor?: boolean;
  forceDefaultBackground?: boolean;
  isOpen?: boolean;
  offset?: [number, number];
  onOpenChange?: (open: boolean) => void;
  onTriggerClick?: (e: Event) => void;
  placement?: Placement;
  portalId?: string;
  showArrow?: boolean;
  targetRef?: React.RefObject<HTMLElement>;
  trigger?: 'click' | 'hover';
}

const Tooltip = ({
  action,
  anchorClassName,
  appendTo = document.body,
  arrowColor,
  children,
  content,
  delay = 50,
  fallbackPlacements,
  followCursor = true,
  forceDefaultBackground = false,
  isOpen,
  offset = [0, 8],
  onOpenChange,
  onTriggerClick,
  placement = 'bottom-end',
  portalId,
  showArrow = false,
  targetRef,
  trigger = 'hover',
}: TooltipProps) => {
  const arrowRef = useRef(null);
  const [internalIsOpen, setInternalIsOpen] = useState(false);
  const { zIndex } = useTheme();
  const open = isOpen ?? internalIsOpen;
  const [crossAxis, mainAxis] = offset;
  const { context, floatingStyles, refs } = useFloating({
    elements: {
      reference: targetRef?.current,
    },
    middleware: [
      offsetMiddleware({ crossAxis, mainAxis }),
      flip({ fallbackPlacements }),
      shift(),
      arrow({
        element: arrowRef,
      }),
    ],
    onOpenChange: onOpenChange ?? setInternalIsOpen,
    open,
    placement,
    whileElementsMounted: autoUpdate,
  });
  const click = useClick(context, { enabled: trigger === 'click' });
  const hover = useHover(context, {
    delay: { open: delay },
    enabled: trigger === 'hover',
  });
  const dismiss = useDismiss(context, { escapeKey: true, outsidePress: true });
  const clientPoint = useClientPoint(context, { enabled: followCursor && trigger === 'hover' });
  const { getFloatingProps, getReferenceProps } = useInteractions([
    click,
    hover,
    clientPoint,
    dismiss,
  ]);

  if (!children) return null;

  if (content === undefined || content === '' || content === null) return children;

  const parsedContent =
    ['string', 'number'].includes(typeof content) || forceDefaultBackground ? (
      <StyledTooltipText isAction={action}>{content}</StyledTooltipText>
    ) : (
      content
    );

  const referenceProps = getReferenceProps();

  return (
    <>
      {cloneElement(children, {
        ref: refs.setReference,
        ...referenceProps,
        ...children.props,
        onClick: (e: Event) => {
          children.props.onClick?.(e);
          onTriggerClick?.(e);
          (referenceProps.onClick as (e: Event) => void)?.(e);
        },
      }) ?? null}
      {open && (
        <FloatingPortal id={portalId} root={appendTo}>
          <div
            ref={refs.setFloating}
            role="tooltip"
            style={{ ...floatingStyles, zIndex: zIndex.floatingElement }}
            {...getFloatingProps({ className: anchorClassName })}
          >
            {parsedContent}
            {showArrow && (
              <StyledTooltipArrow
                ref={arrowRef}
                arrowColor={arrowColor}
                context={context}
                isAction={action}
              />
            )}
          </div>
        </FloatingPortal>
      )}
    </>
  );
};

export default Tooltip;
