import React, { useState } from 'react';

import Box from '@components/Box';
import IconButton from '@components/IconButton';
import Avatar from '@components/UI/Avatar';
import Checkbox from '@components/UI/Form/Checkbox';
import InputLabel from '@components/UI/Form/InputLabel';
import Icon from '@components/UI/Icon';
import type { Option, SelectValue } from '@components/UI/Select';
import Select from '@components/UI/Select';
import type { SelectProps } from '@components/UI/Select/Select';

import { AccordionItemContent, AccordionItemTitle } from './StyledAccordion';

interface Props {
  handleSearch?: SelectProps['onSearchValueChange'];
  loading?: boolean;
  options: SelectProps['options'];
  title: string;
  update: (item: string) => void;
}

const AccordionInputItem = ({ handleSearch, loading, options, title, update }: Props) => {
  const [showInput, setShowInput] = useState(false);
  const [active, setActive] = useState(true);

  const [selected, setSelected] = useState<Option<string>[]>([]);

  const handleStateUpdate = (option: Option<string>) => {
    /* On click */
    if (selected.includes(option)) {
      setSelected((prev) => prev.filter((value) => value !== option));
    } else {
      setSelected((prev) => [...prev, option]);
    }
  };

  const renderAddFilterContent = (
    <IconButton iconName="plus" label="Add Filter" onClick={() => setShowInput(true)} />
  );

  const handleOptionChange = (newValue: SelectValue) => {
    const [option] = newValue as Option<string>[];
    setSelected((prev) => [...prev, option]);
    update(option.value);
    setShowInput(false);
  };

  const renderInputContent = (
    <Select
      inputProps={{ autoFocus: true }}
      isLoading={loading}
      label={`Select ${title}`}
      maxOptionsVisible={10}
      onChange={handleOptionChange}
      onInputBlur={() => {
        setShowInput(false);
      }}
      onSearchValueChange={handleSearch}
      options={options}
      placeholder="Add Filter"
    />
  );

  return (
    <>
      <AccordionItemTitle active={active} onClick={() => setActive(!active)}>
        {title}
        <Icon ml={0.5} name={active ? 'caret-down' : 'caret-right'} size="10px" />
      </AccordionItemTitle>
      <AccordionItemContent active={active}>
        {!showInput ? renderAddFilterContent : renderInputContent}
        {selected?.map((item) => {
          return (
            <InputLabel key={item.key} alignItems="flex-start" compDisplay="flex" gap={0.5} my={1}>
              <Checkbox
                checked
                mr={0.5}
                name={item.value}
                onChange={() => {
                  handleStateUpdate(item);
                  update(item.value);
                }}
              />
              {item.avatar && <Avatar {...item.avatar} size="1.25rem" />}
              {item.icon && <Icon compDisplay="inline-block" name={item.icon} size="16px" />}
              <Box overflow="hidden" textOverflow="ellipsis" wordBreak="normal">
                {item.text}
              </Box>
            </InputLabel>
          );
        })}
      </AccordionItemContent>
    </>
  );
};

export default AccordionInputItem;
