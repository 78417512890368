import React from 'react';
import moment from 'moment';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { useFetchCostAnalysisTime } from '@api/costAnalysis';
import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import theme from '@styles/theme';

import type { CostAnalysisSharedFilterParams } from '../../CostAnalysisSharedFilter';
import { colors } from '../constants';
import LegendLabel from '../LegendLabel';
import TooltipBoxValue from '../Tooltip/TooltipBoxValue';
import type { TooltipContentProps } from '../Tooltip/TooltipContent';
import TooltipContent from '../Tooltip/TooltipContent';
import { formatN } from '../utils';

import { formatValue } from './utils';

const CustomTooltipContent = ({ label, payload, ...other }: TooltipContentProps) => {
  const sortedPayload = payload?.sort((a) => {
    return a.dataKey === 'totalRun' ? -1 : 0;
  });
  return (
    <TooltipContent
      name={<TooltipBoxValue name="Date" value={moment.utc(label).format('MMM DD')} />}
      payload={sortedPayload}
      {...other}
    />
  );
};

const customColors = {
  credit: '#d0befc',
  queries: theme.colors.v1.primary[500],
};

const legendPayload = [
  {
    color: customColors.queries,
    type: 'circle' as 'circle',
    value: 'Query Count',
  },
  { color: customColors.credit, type: 'circle' as 'circle', value: 'Credit' },
];

export interface DailyUsageCostChartProps extends CostAnalysisSharedFilterParams {}

const DailyUsageCostChart: React.FC<DailyUsageCostChartProps> = ({ range, warehouses }) => {
  const { data, isLoading } = useFetchCostAnalysisTime({
    params: {
      aggregation: 'daily',
      end_date: range[1],
      start_date: range[0],
      warehouses,
    },
  });

  return (
    <ResponsiveContainer height={400} width="100%">
      {isLoading ? (
        <Box compHeight="100%" compWidth="100%" noDefault position="relative">
          <CircularLoader cover />
        </Box>
      ) : (
        <ComposedChart data={data} margin={{ left: 20, right: 20 }}>
          <defs>
            <linearGradient id="colorUv" x1="0" x2="0" y1="0" y2="1">
              <stop offset="50%" stopColor={customColors.credit} stopOpacity={1} />
              <stop offset="95%" stopColor={customColors.credit} stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid stroke={colors.grid} />
          <Area
            dataKey="totalCreditsUsed"
            fill="url(#colorUv)"
            name={legendPayload[1].value}
            stroke="none"
            type="step"
            yAxisId="right"
          />
          <Line
            dataKey="totalRun"
            dot={false}
            name={legendPayload[0].value}
            stroke={customColors.queries}
            strokeWidth={2}
            yAxisId="left"
          />
          <YAxis
            axisLine={false}
            dataKey="totalCreditsUsed"
            interval="preserveStartEnd"
            label={{
              angle: 90,
              dx: 10,
              dy: -12,
              fill: colors.axisLabel,
              position: 'right',
              value: legendPayload[1].value,
            }}
            mirror
            orientation="right"
            padding={{ top: 20 }}
            stroke={colors.axisText}
            tickFormatter={formatN}
            tickLine={false}
            yAxisId="right"
          />
          <YAxis
            axisLine={false}
            dataKey="totalRun"
            interval="preserveStartEnd"
            label={{
              angle: -90,
              dx: -10,
              dy: -35,
              fill: colors.axisLabel,
              position: 'left',
              value: legendPayload[0].value,
            }}
            mirror
            orientation="left"
            padding={{ top: 20 }}
            stroke={colors.axisText}
            tickFormatter={formatN}
            tickLine={false}
            yAxisId="left"
          />
          <XAxis
            axisLine={false}
            dataKey="aggregation.endTime"
            interval="preserveStartEnd"
            padding={{ left: 50, right: 50 }}
            stroke={colors.axisText}
            tickFormatter={(endTime: string) => moment.utc(endTime).format('MMM D')}
            tickLine={false}
          />
          <Tooltip content={CustomTooltipContent as any} formatter={formatValue} />
          <Legend
            formatter={LegendLabel}
            payload={legendPayload}
            wrapperStyle={{
              paddingTop: 8,
            }}
          />
        </ComposedChart>
      )}
    </ResponsiveContainer>
  );
};

export default DailyUsageCostChart;
