import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import { MetadataModel } from '@models/MetadataModel';

import rawTransform from '../rawTransform';

export const mostPopularItemsCacheKeys = {
  mostPopularItems: ['mostPopularItems'],
};

export const fetchMostPopularItemsSelect = rawTransform(MetadataModel);
export const useFetchMostPopularItems = (options?: UseFetchOptions<MetadataModel[]>) => {
  return useFetch<MetadataModel[]>({
    ...options,
    queryKey: [...mostPopularItemsCacheKeys.mostPopularItems, options?.params],
    select: fetchMostPopularItemsSelect,
    url: '/most-popular-items/',
  });
};
