import styled from '@emotion/styled';
import Calendar from 'react-calendar';

const StyledCalendar = styled(Calendar)`
  .react-calendar {
    width: 350px;
    max-width: 100%;
    background: white;
    line-height: 1.125em;
  }

  button,
  button:focus {
    outline: none;
  }

  .react-calendar--doubleView {
    width: 700px;
  }

  .react-calendar__viewContainer {
    border: 1px solid ${({ theme }) => theme.colors.gray[200]};
    padding-bottom: ${({ theme }) => theme.space(1)};
  }

  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5rem;
  }

  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5rem;
  }

  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .react-calendar button {
    margin: 0;
    border: 0;
  }

  .react-calendar button:enabled:hover {
    cursor: pointer;
  }

  .react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 0.25rem;
  }

  .react-calendar__navigation button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 44px;
    background: none;
    font-size: 1rem;
    font-weight: bold;
  }

  .react-calendar__navigation button:hover {
    background-color: ${({ theme }) => theme.colors.hover};
  }

  .react-calendar__navigation button:disabled {
    background-color: ${({ theme }) => theme.colors.gray[100]};
    cursor: not-allowed;
  }

  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: capitalize;
    font-size: 1rem;
    font-weight: ${({ theme }) => theme.typography.fontWeights.regular};

    & abbr {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.gray[500]};
    }
  }

  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }

  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
  }

  .react-calendar__month-view__days__day {
  }

  .react-calendar__month-view__days__day--weekend {
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: ${({ theme }) => theme.colors.gray[200]};
  }

  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }

  .react-calendar__tile {
    position: relative;
    max-width: 100%;
    padding: 0.5rem 0.25rem;
    background: none;
    text-align: center;

    &:after {
      content: ' ';
      position: absolute;
      z-index: 1;
      top: 0.1rem;
      left: 0.75rem;
      right: 0.75rem;
      bottom: 0.1rem;
      border: 2px solid transparent;
      border-radius: ${({ theme }) => theme.radius.default};
    }
  }

  .react-calendar__tile:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.gray[100]}50;

    & abbr {
      position: relative;
      z-index: 2;
      color: ${({ theme }) => theme.colors.gray[200]};
    }
  }

  .react-calendar__tile:enabled:hover {
    & abbr {
      position: relative;
      z-index: 2;
      color: ${({ theme }) => theme.colors.gray[800]};
    }

    &:after {
      background-color: ${({ theme }) => theme.colors.hover};
    }
  }

  .react-calendar__tile--active {
    & abbr {
      position: relative;
      z-index: 2;
      color: ${({ theme }) => theme.colors.white};
    }

    &:after {
      background-color: ${({ theme }) => theme.colors.lightblue};
    }
  }

  .react-calendar__tile--active:enabled:hover {
    &:after {
      background-color: ${({ theme }) => `${theme.colors.lightblue}80`};
    }
  }

  .react-calendar__tile--hover {
    &:after {
      background-color: ${({ theme }) => `${theme.colors.lightblue}30`};
    }
  }

  .react-calendar__month-view .react-calendar__tile--rangeStart,
  .react-calendar__month-view .react-calendar__tile--rangeEnd,
  .react-calendar__month-view .react-calendar__tile--range {
    & abbr {
      position: relative;
      z-index: 2;
      color: ${({ theme }) => theme.colors.white};
    }

    &:after {
      background-color: ${({ theme }) => theme.colors.lightblue};
    }

    &:before {
      content: ' ';
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 2px solid transparent;
      background-color: ${({ theme }) => theme.colors.hover};
    }
  }

  .react-calendar__tile--rangeStart {
    &:before {
      left: 0.75rem;
      border-bottom-left-radius: ${({ theme }) => theme.radius.default};
      border-top-left-radius: ${({ theme }) => theme.radius.default};
    }
  }

  .react-calendar__tile--rangeEnd {
    &:before {
      right: 0.75rem;
      border-bottom-right-radius: ${({ theme }) => theme.radius.default};
      border-top-right-radius: ${({ theme }) => theme.radius.default};
    }
  }

  .react-calendar__year-view .react-calendar__tile {
    & abbr {
      color: inherit;
    }

    &:after {
      background-color: transparent;
    }
  }

  .react-calendar__year-view .react-calendar__tile--hover {
    background-color: ${({ theme }) => theme.colors.hover};

    abbr {
      font-weight: bold;
    }

    &:after {
      background-color: transparent;
    }
  }

  .react-calendar__year-view .react-calendar__tile:enabled:hover {
    background-color: ${({ theme }) => theme.colors.hover};

    abbr {
      font-weight: bold;
    }

    &:after {
      background-color: transparent;
    }
  }
`;

export default StyledCalendar;
