import React, { useMemo, useState } from 'react';
import type { Cell } from 'react-table';

import { useFetchCostAnalysisUsers } from '@api/costAnalysis';
import type { DsUserModel } from '@api/dsusers/DsUserModel';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import UserCell from '@components/Table/Cells/UserCell';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import { FilterOptions, setParams, useUpdateFilters } from '@utils/filters';

const sortConfig = {
  averageCreditsUsed: 'average_credits_used',
  name: 'name',
  totalCreditsUsed: 'total_credits_used',
  totalRun: 'total_run',
} as const;

const searchConfig: { [key: string]: keyof FilterOptions } = {
  name: 'search',
};

const requestConfig: FilterOptions = {
  order: `-${sortConfig.totalCreditsUsed}`,
  page: 1,
  page_size: 20,
};

const initialTableSort = [{ desc: true, id: 'totalCreditsUsed' }];

interface CostByUserTableProps {
  range: [string, string];
}

const CostByUserTable: React.FC<CostByUserTableProps> = ({ range }) => {
  const [showFilter, setShowFilter] = useState(false);
  const { changePage, filter, search, sort } = useUpdateFilters(
    { ...requestConfig, end_date: range[1], start_date: range[0] },
    searchConfig,
    sortConfig,
    requestConfig.order,
  );

  const { data, isLoading } = useFetchCostAnalysisUsers({
    params: { ...setParams(filter), end_date: range[1], start_date: range[0] },
  });

  const columns: ColumnConfig<DsUserModel>[] = useMemo(
    () => [
      {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      {
        Cell: ({ column, row: { original }, state }: Cell<DsUserModel>) => (
          <UserCell column={column} state={state} user={original} />
        ),
        Header: 'User',
        accessor: (d) => d.fullName,
        disableHiding: true,
        id: 'name',
        width: '120%',
      },
      {
        Cell: (props: Cell<DsUserModel>) => {
          const { row } = props;

          return <UserCell {...props} user={row.original?.user?.team} />;
        },
        Header: 'Team',
        disableFilters: true,
        disableHiding: true,
        id: 'team',
        width: '120%',
      },
      {
        Header: 'Query Count',
        accessor: (d) => d.formattedTotalRun,
        disableFilters: true,
        id: 'totalRun',
        width: '120%',
      },
      {
        Header: 'Credit',
        accessor: (d) => d.formattedTotalCreditsUsed,
        disableFilters: true,
        id: 'totalCreditsUsed',
        width: '120%',
      },
      {
        Header: 'Avg Credit/Run',
        accessor: (d) => d.formattedAverageCreditsUsed,
        disableFilters: true,
        id: 'averageCreditsUsed',
        width: '120%',
      },
    ],
    [],
  );

  const totalPages = data && filter.page_size ? Math.ceil(data.count / filter.page_size) : 1;

  return (
    <TableStyled>
      <Table
        basic="very"
        changePage={changePage}
        columns={columns}
        compact
        data={data?.results ?? []}
        disableRowSelect
        initialState={{
          sortBy: initialTableSort,
        }}
        loading={isLoading}
        manualFilters
        manualPagination
        manualSortBy
        setFilters={search}
        setSortBy={sort}
        showFilter={showFilter}
        sortable
        toggleFilter={() => {
          setShowFilter((prev) => !prev);
        }}
        totalPages={totalPages}
        unstackable
      />
    </TableStyled>
  );
};

export default CostByUserTable;
