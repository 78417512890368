import styled from '@emotion/styled';
import type { RouterLinkProps } from '@routing/router';
import type { ElementType } from 'react';

import Box from '@components/Box';

interface SearchResultContainerProps {
  as?: ElementType;
  to?: RouterLinkProps['to'];
}

export const SearchResultContainer = styled(Box)<SearchResultContainerProps>`
  width: 100%;
  font-size: 1rem;
`;

interface SearchResultProps {
  isMentionItem?: boolean;
  isQuickSearch?: boolean;
}

export const SearchResultPath = styled.div<SearchResultProps>`
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  color: #8c939b;
  margin-bottom: ${({ isMentionItem, isQuickSearch, theme }) => {
    if (isMentionItem) {
      return theme.space(-0.35);
    }
    if (isQuickSearch) {
      return 0;
    }
    return theme.space(0.15);
  }};
  font-size: ${({ isQuickSearch }) => (isQuickSearch ? '0.8em' : '0.85em')};
`;

export const StyledSearchResultBreadcrumbs = styled.div<SearchResultProps>`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
  word-wrap: break-word;
`;

export const SearchResultTags = styled.div`
  display: inline-block;
  flex-grow: 2;
  text-align: end;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  margin-right: ${({ theme }) => theme.space(0.75)};
  flex-shrink: 10000;
  line-height: 0.25;

  a {
    display: inline-block;
  }
`;

export const SearchResultTitleRow = styled.div<{ isSearchResultCell?: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-size: 1em;
  height: ${({ isSearchResultCell }) => (isSearchResultCell ? 'auto' : '28px')};
`;

export const SearchResultTitle = styled.div<{ isSearchResultCell?: boolean }>`
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
  font-size: 1em;
  color: #2c2e36;
  margin: 0 0.25rem 0 0;
  max-width: ${({ isSearchResultCell }) => (isSearchResultCell ? 'unset' : 'calc(100% - 10.5rem)')};
  white-space: nowrap;
  overflow: ${({ isSearchResultCell }) => (isSearchResultCell ? 'unset' : 'hidden')};
  text-overflow: ellipsis;
  height: 1.12rem;

  & .title-highlighter {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const SearchResultPopularity = styled.div<SearchResultProps>`
  display: inline-flex;
  justify-content: flex-start;
  width: 5.1rem;
  min-width: 5.1rem;
  margin-left: auto;
  margin-bottom: ${({ isMentionItem, theme }) => (isMentionItem ? theme.space(0.4) : '0')};
  align-self: center;
  height: 1rem;
`;

export const SearchResultDescription = styled.div`
  font-size: 0.85rem;
  color: rgb(108 108 108);
  line-height: 130%;
  margin-top: '0.2rem';
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
`;
