import 'reflect-metadata';

import React from 'react';

import UserPermissions from '@components/UserPermissions';
import BINotebooksPageRouted from '@pages/BINotebooksPage/BINotebooksPageRouted';
import BITablesPage from '@pages/BITablesPage';
import DashboardBIToolPageRouted from '@pages/DashboardBIToolPageRouted';
import DashboardsPage from '@pages/DashboardsPage';
import PowerBIDashboardPage from '@pages/DashboardsPage/PowerBIDashboardPage';
import DefaultProfilePage from '@pages/DefaultProfilePage';
import DocumentsPage from '@pages/DocumentsPage';
import DocumentPage from '@pages/DocumentsPage/DocumentPage';
import DSUserPage from '@pages/DSUserPage';
import EmailVerificationPage from '@pages/EmailVerificationPage';
import ExploresPage from '@pages/ExploresPage';
import FreeTrialExpired from '@pages/FreeTrialExpired';
import GoogleSignUpRedirectPage from '@pages/GoogleSignUpRedirectPage';
import QuickSightListPage from '@pages/GroupedListPage/QuickSightListPage';
import JobsPageRouted from '@pages/JobsPage/JobsPageRouted';
import LinkRedirectPage from '@pages/LinkRedirectPage';
import Logout from '@pages/Logout';
import LookMLViewsPage from '@pages/LookMLViewsPage';
import MainPage from '@pages/MainPage';
import MaskPage from '@pages/MaskPage';
import MetadataObjectPage from '@pages/MetadataObjectPage';
import MetricPage from '@pages/MetricPage';
import ProfilePage from '@pages/ProfilePage';
import ProjectPage from '@pages/ProjectPage';
import { SearchResultsPage } from '@pages/SearchResultsPage';
import SSORedirectPage from '@pages/SSORedirectPage';
import SubscriptionExpired from '@pages/SubscriptionExpired';
import TablesPage from '@pages/TablesPage';
import TagsPage from '@pages/TagsPage';
import TeamProfilePage from '@pages/TeamProfilePage';
import TeamsPage from '@pages/TeamsPage';
import TOSPage from '@pages/TOSPage';
import UnmaskPage from '@pages/UnmaskPage';

import CollectionsPage from './pages/CollectionsPage';

export const SharedSignUpRoutes = [
  {
    component: EmailVerificationPage,
    route: '/verification/:code/',
  },
  {
    component: GoogleSignUpRedirectPage,
    route: '/google/redirect',
  },
  {
    component: SSORedirectPage,
    route: '/sso/redirect',
  },
  {
    component: Logout,
    route: '/logout',
  },
];

export const SharedOnboardingRoutes = [
  {
    component: TOSPage,
    exact: true,
    route: '/tos',
  },
  {
    component: FreeTrialExpired,
    exact: true,
    route: '/free-trial-expired',
  },
  {
    component: SubscriptionExpired,
    exact: true,
    route: '/subscription-expired',
  },
];

export const SharedSettingsRoutes = [
  {
    component: () => (
      <UserPermissions staffRequired>
        <MaskPage />
      </UserPermissions>
    ),
    route: '/mask',
  },
  {
    component: () => (
      <UserPermissions maskRequired>
        <UnmaskPage />
      </UserPermissions>
    ),
    route: '/unmask',
  },
];

export const SharedMainRoutes = [
  {
    component: LinkRedirectPage,
    route: '/link/:guid/:childGuid?',
  },
  {
    component: MetricPage,
    exact: false,
    route: '/metrics/:guid',
  },
  {
    component: SearchResultsPage,
    exact: false,
    route: '/search',
  },
  {
    component: TablesPage,
    exact: false,
    route: '/databases/:guid',
  },
  {
    component: TablesPage,
    exact: false,
    route: '/projects/:guid',
  },
  {
    component: ProjectPage,
    route: '/tableau/:dsGuid/:containerId?/projects',
  },
  {
    component: ProjectPage,
    route: '/tableau/folder_container/:guid',
  },
  {
    component: MetadataObjectPage,
    exact: false,
    route: '/tags/:guid',
  },
  {
    component: MetadataObjectPage,
    exact: false,
    route: '/power-bi-tables/:guid',
  },
  {
    component: MetadataObjectPage,
    exact: false,
    route: '/datasets/:guid',
  },
  {
    component: BINotebooksPageRouted,
    route: '/notebooks/ds/:dsGuid',
  },
  {
    component: BINotebooksPageRouted,
    route: '/notebooks/fl/:guid',
  },
  {
    component: MetadataObjectPage,
    exact: false,
    route: '/notebooks/:guid',
  },
  {
    component: TablesPage,
    exact: false,
    route: '/schemas/:guid',
  },
  {
    component: MetadataObjectPage,
    exact: false,
    route: '/bi-table/:guid',
  },
  {
    component: MetadataObjectPage,
    exact: false,
    route: '/tables/:guid',
  },
  {
    component: MetadataObjectPage,
    exact: false,
    route: '/jobs/:guid',
  },
  {
    component: JobsPageRouted,
    route: '/job-list/fl/:guid',
  },
  {
    component: DefaultProfilePage,
    route: '/profile',
  },
  {
    component: ProfilePage,
    exact: false,
    route: '/profiles/:guid',
  },
  {
    component: TeamProfilePage,
    exact: false,
    route: '/teams/:guid',
  },
  {
    component: DashboardBIToolPageRouted,
    route: '/mode/:dsGuid/:containerId?/spaces',
  },
  {
    component: DashboardBIToolPageRouted,
    route: '/spaces/:guid',
  },
  {
    component: MetadataObjectPage,
    exact: false,
    route: '/dashboards/:guid',
  },
  {
    component: DashboardBIToolPageRouted,
    exact: false,
    route: '/periscope/:guid/dashboards',
  },
  {
    component: DashboardBIToolPageRouted,
    exact: false,
    route: '/periscope/:dsGuid/:containerId?',
  },
  {
    component: DashboardBIToolPageRouted,
    exact: false,
    route: '/sigma/:guid/folder',
  },
  {
    component: PowerBIDashboardPage,
    exact: false,
    route: '/power-bi/:guid/folder_dashboards',
  },
  {
    component: PowerBIDashboardPage,
    exact: false,
    route: '/power-bi/:guid/folder_reports',
  },
  {
    component: PowerBIDashboardPage,
    exact: false,
    route: '/power-bi/:dsGuid/:containerId?/dashboards',
  },
  {
    component: PowerBIDashboardPage,
    exact: false,
    route: '/power-bi/:dsGuid/:containerId?/reports',
  },
  {
    component: PowerBIDashboardPage,
    exact: false,
    route: '/power-bi/:guid/folder',
  },
  {
    component: PowerBIDashboardPage,
    exact: false,
    route: '/power-bi/:dsGuid/:containerId?/datasource',
  },
  {
    component: DashboardBIToolPageRouted,
    exact: false,
    route: '/metabase/:guid/folder',
  },
  {
    component: DashboardBIToolPageRouted,
    exact: false,
    route: '/metabase/:dsGuid/:containerId?/datasource',
  },
  {
    component: DashboardBIToolPageRouted,
    exact: false,
    route: '/data-studio/:guid/folder',
  },
  {
    component: DashboardBIToolPageRouted,
    exact: false,
    route: '/data-studio/:dsGuid/:containerId?/datasource',
  },
  {
    component: MetadataObjectPage,
    exact: false,
    route: '/workbooks/:guid',
  },
  {
    component: MetadataObjectPage,
    exact: false,
    route: '/tableaudatasources/:guid',
  },
  {
    component: DashboardBIToolPageRouted,
    route: '/looker/:dsGuid/:containerId?/dashboards',
  },
  {
    component: ExploresPage,
    route: '/looker/:dsGuid/:containerId?/explores',
  },
  {
    component: LookMLViewsPage,
    route: '/looker/:dsGuid/:containerId?/views',
  },
  {
    component: LookMLViewsPage,
    route: '/lookmlprojects/:guid',
  },
  {
    component: BITablesPage,
    route: '/bi-tables/:dsGuid?/folder_container/:guid?',
  },
  {
    component: QuickSightListPage,
    route: '/quicksight/:dsGuid?/folder/:guid?',
  },
  {
    component: DashboardBIToolPageRouted,
    route: '/hex/:dsGuid?/folder/:guid?',
  },
  {
    component: DashboardsPage,
    route: '/thoughtspot/:dsGuid?/folder_container/:guid?',
  },
  {
    component: MetadataObjectPage,
    exact: false,
    route: '/views/:guid',
  },
  {
    component: MetadataObjectPage,
    exact: false,
    route: '/explores/:guid',
  },
  {
    component: ExploresPage,
    route: '/groups/:guid',
  },
  {
    component: DashboardBIToolPageRouted,
    route: '/folders/:guid',
  },
  {
    component: ExploresPage,
    route: '/models/:guid',
  },
  {
    component: DSUserPage,
    exact: false,
    route: '/dsusers/:guid',
  },
  {
    component: TagsPage,
    route: '/tags',
  },
  {
    component: DocumentsPage,
    exact: false,
    route: '/docs/tabs',
  },
  {
    component: DocumentPage,
    exact: false,
    route: '/docs/:guid',
  },
  {
    component: TeamsPage,
    route: '/teams',
  },
  {
    component: MetadataObjectPage,
    exact: false,
    route: '/terms/:guid',
  },
  {
    component: MetadataObjectPage,
    exact: false,
    route: '/collections/:guid',
  },
  {
    component: CollectionsPage,
    exact: false,
    route: '/collections',
  },
  {
    component: MainPage,
    route: '/',
  },
];
