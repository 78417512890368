import styled from '@emotion/styled';

import Box from '@components/Box';

export const StyledWeeklyUsageCostChart = styled(Box)`
  position: relative;
  height: 410px;
  .recharts-rectangle:hover {
    fill: #33b1ff;
  }
`;
