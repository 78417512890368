import React from 'react';

import CreateTermModal from '@components/AppMainSidebar/Hierarchy/DocumentsHierarchy/CreateDocMenu/CreateTermModal';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import CircularLoader from '@components/CircularLoader';
import Icon from '@components/UI/Icon';
import { useModal } from '@context/Modal';
import theme from '@styles/theme';

import UploadCSVFile from '../UploadCSVFile';
import { StyledUploadCSVFileStyledLink } from '../UploadCSVFile/UploadCSVFile.styles';

export const CSV_EXPORT_FILE_NAME = 'glossary_terms.csv';

interface CreateNewTermProps {
  disableUploadCsvButton: boolean;
}

const CreateNewTerm: React.FC<CreateNewTermProps> = ({ disableUploadCsvButton }) => {
  const { MODAL_IDS, checkModalOpened, closeModal, openModal } = useModal();

  return (
    <>
      <Box compDisplay="flex" flexDirection="column">
        <Button
          onClick={() => {
            openModal(MODAL_IDS.createTerm);
          }}
          startIcon={<Icon color={theme.colors.v1.gray[700]} name="plus" />}
          variant="outlined"
        >
          Add a new Term
        </Button>
        <UploadCSVFile>
          {({ handleSelectFile, isLoading: isLoadingCsv }) => (
            <Box alignItems="center" compDisplay="flex" flexDirection="column" gap={0.8} mt={1}>
              <Button
                disabled={disableUploadCsvButton}
                onClick={handleSelectFile}
                startIcon={
                  isLoadingCsv ? (
                    <CircularLoader compSize={2} />
                  ) : (
                    <Icon color={theme.colors.v1.gray[700]} name="upload" />
                  )
                }
                variant="outlined"
              >
                Upload the CSV file
              </Button>
              <StyledUploadCSVFileStyledLink
                download="glossary_terms_template.csv"
                href={`${process.env.PUBLIC_URL}/glossary_terms_template.csv`}
                rel="noopener noreferrer"
              >
                Download CSV Template
              </StyledUploadCSVFileStyledLink>
            </Box>
          )}
        </UploadCSVFile>
      </Box>
      {checkModalOpened(MODAL_IDS.createTerm) && (
        <CreateTermModal onClose={() => closeModal(MODAL_IDS.createTerm)} />
      )}
    </>
  );
};

export default CreateNewTerm;
