import styled from '@emotion/styled';
import * as CSS from 'csstype';

interface EditableHeaderInputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  color?: CSS.Property.Color;
}

export const EditableHeaderInput = styled.input<EditableHeaderInputProps>`
  -webkit-tap-highlight-color: transparent;
  appearance: none;
  background: none;
  color: ${({ color = 'inherit' }) => color};
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  height: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  min-width: 2rem;
  outline: none;
  width: 100%;
  max-width: 100%;
  word-wrap: break-word;
  word-break: break-all;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray[400]};
  }
`;

export const EditableHeaderHiddenText = styled.span`
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  height: inherit;
  letter-spacing: inherit;
  margin: 0;
  min-width: 2rem;
  word-break: break-word;
  overflow: hidden;
  visibility: hidden;
  word-wrap: break-word;
  width: 100%;
`;
