import styled from '@emotion/styled';

import Box from '@components/Box';
import { StyledButtonProps } from '@components/Button/Button.styles';
import { sizeVariants } from '@components/Button/Button.styles.variants';

export const StyledButtonGroupBox = styled(Box)`
  width: ${({ compWidth = 'fit-content' }) => compWidth};
  height: fit-content;
  border-radius: ${({ theme }) => theme.radius.md};
  box-shadow: ${({ theme }) => theme.shadow.xs};
`;

export interface StyledButtonGroupButtonProps extends Pick<StyledButtonProps, 'compSize'> {
  active?: boolean;
  noBackGround?: boolean;
}

export const StyledButtonGroupButton = styled.button<StyledButtonGroupButtonProps>`
  display: flex;
  align-items: center;
  border: ${({ theme }) => `1px solid ${theme.colors.v1.gray[200]}`};
  border-right: none;
  background-color: ${({ active, noBackGround, theme }) =>
    active && !noBackGround ? theme.colors.v1.gray[200] : 'white'};
  color: ${({ active, theme }) =>
    active ? theme.colors.v1.primary[600] : theme.colors.v1.gray[700]};
  gap: ${({ theme }) => theme.space(0.5)};
  font-weight: ${({ theme }) => theme.typography.fontWeights.semibold};
  font-size: ${({ theme }) => theme.typography.fontSizes.body2};
  justify-content: center;
  flex: 1 1 auto;
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};

  ${({ compSize = 'default' }) => sizeVariants[compSize]}

  &:first-child {
    border-top-left-radius: ${({ theme }) => theme.radius.md};
    border-bottom-left-radius: ${({ theme }) => theme.radius.md};
  }

  &:last-child {
    border-top-right-radius: ${({ theme }) => theme.radius.md};
    border-bottom-right-radius: ${({ theme }) => theme.radius.md};
    border-right: ${({ theme }) => `1px solid ${theme.colors.v1.gray[200]}`};
  }
`;
