import React, { memo } from 'react';

import Tooltip from '@components/Tooltip.v1';
import { TooltipProps } from '@components/Tooltip.v1/Tooltip';

import { REACT_FLOW_CONTAINER_SELECTOR } from '../Nodes/config';

interface LineageTooltipProps extends TooltipProps {
  children: React.ReactElement;
}

const LineageTooltip: React.FC<LineageTooltipProps> = (tooltipProps) => {
  const reactFlowContainer = document.querySelector(REACT_FLOW_CONTAINER_SELECTOR) as HTMLElement;
  return <Tooltip appendTo={reactFlowContainer} {...tooltipProps} />;
};

export default memo(LineageTooltip);
