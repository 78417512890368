import styled from '@emotion/styled';
import type { RouterLinkProps } from '@routing/router';
import type { ElementType } from 'react';

import type { StyledBoxProps } from '@components/Box';
import Box from '@components/Box';

const marginX = 2;
const marginY = 0.7;

export const StyledTeamsMenuSectionTitle = styled.button`
  padding: ${({ theme }) => theme.space(marginY, marginX)};
  display: flex;
  align-items: center;
  width: 100%;

  svg {
    margin-left: -6px;
    margin-right: 4px;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    background: ${({ theme }) => theme.colors.hierarchyHoverBackground};
    font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
  }
`;

interface StyledTeamsMenuLinkProps extends StyledBoxProps {
  as?: ElementType;
  to?: RouterLinkProps['to'];
}

export const StyledTeamsMenuLink = styled(Box)<StyledTeamsMenuLinkProps>`
  padding: ${({ theme }) => theme.space(marginY, marginX)};
  padding-left: ${({ theme }) => theme.space(4)};
  color: currentColor;
  &:hover,
  &.active {
    color: ${({ theme }) => theme.colors.primary};
    background: ${({ theme }) => theme.colors.hierarchyHoverBackground};
    font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
  }
`;

export const StyledTeamsEmptyMessage = styled.div`
  padding: ${({ theme }) => theme.space(marginY, marginX)};
  padding-left: ${({ theme }) => theme.space(4.5)};
`;
