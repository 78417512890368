import React from 'react';

import Icon from '@components/UI/Icon';
import { HierarchyData } from '@models/HierarchyModel';

export const userSettingsHierarchy: HierarchyData[] = [
  {
    breadcrumbLabelList: [],
    children: [],
    guid: '/settings/account',
    icon: 'user',
    name: 'Account',
    objectType: 'menu-item',
    url: '/settings/account',
  },
  {
    breadcrumbLabelList: [],
    children: [],
    guid: '/settings/user',
    icon: 'user-square',
    name: 'User Settings',
    objectType: 'menu-item',
    url: '/settings/user',
  },
];

export const dataManagerHierarchy: HierarchyData[] = [
  {
    breadcrumbLabelList: [],
    children: [],
    guid: 'discussion',
    icon: 'discussion',
    name: 'Discussion',
    objectType: 'menu-item',
    url: '/admin/discussion',
  },
  {
    breadcrumbLabelList: [],
    children: [],
    guid: 'metadata-upload',
    icon: 'uploadV1',
    name: 'Metadata Upload',
    objectType: 'menu-item',
    url: '/admin/metadata-upload',
  },
];

export const selectStarHierarchy: HierarchyData[] = [
  {
    breadcrumbLabelList: [],
    children: [],
    guid: '/mask',
    icon: 'switch-horizontal',
    name: 'Mask',
    objectType: 'menu-item',
    url: '/mask',
  },
  {
    breadcrumbLabelList: [],
    children: [],
    guid: '/feature-flags',
    icon: 'flagV1',
    name: 'Feature Flags',
    objectType: 'menu-item',
    url: '/feature-flags',
  },
];

const adminMenuItems = {
  analytics: {
    breadcrumbLabelList: [],
    children: [],
    guid: '/admin/analytics',
    iconEl: <Icon color="currentColor" name="analytics" size="14px" />,
    name: 'Analytics',
    objectType: 'menu-item',
    url: '/admin/analytics',
  },
  apps: {
    breadcrumbLabelList: [],
    children: [],
    guid: '/admin/apps',
    name: 'Apps',
    objectType: 'apps-menu-item',
    url: '/admin/apps',
  },
  automatedPiiMenuItem: {
    breadcrumbLabelList: [],
    children: [],
    guid: '/admin/automated-pii',
    iconEl: <Icon color="currentColor" name="lock-filled" size="14px" />,
    name: 'Automated PII Detection',
    objectType: 'menu-item',
    url: '/admin/automated-pii',
  },
  billingMenuItem: {
    breadcrumbLabelList: [],
    children: [],
    guid: '/admin/billing',
    iconEl: <Icon color="currentColor" name="credit-card" size="16px" />,
    name: 'Billing',
    objectType: 'menu-item',
    url: '/admin/billing',
  },
  constAnalysisMenuItem: {
    breadcrumbLabelList: [],
    children: [],
    guid: '/admin/cost-analysis',
    iconEl: <Icon color="currentColor" name="dollar-circle" size="16px" />,
    name: 'Snowflake Cost Analysis',
    objectType: 'menu-item',
    url: '/admin/cost-analysis',
  },
  customAttributeMenuItem: {
    breadcrumbLabelList: [],
    children: [],
    guid: '/admin/data/custom-attribute',
    iconEl: <Icon color="currentColor" name="submenu" size="16px" />,
    name: 'Custom Attribute',
    objectType: 'menu-item',
    url: '/admin/data/custom-attribute',
  },
  data: {
    breadcrumbLabelList: [],
    children: [],
    guid: '/admin/data',
    iconEl: <Icon color="currentColor" name="database" size="18px" />,
    name: 'Data',
    objectType: 'menu-item',
    url: '/admin/data',
  },
  githubMenuItem: {
    breadcrumbLabelList: [],
    children: [],
    guid: '/admin/github',
    iconEl: <Icon color="currentColor" name="github" size="16px" />,
    name: 'GitHub',
    objectType: 'menu-item',
    url: '/admin/github',
  },
  monteCarloMenuItem: {
    breadcrumbLabelList: [],
    children: [],
    guid: '/admin/monte-carlo',
    iconEl: <Icon color="currentColor" name="monte-carlo" size="16px" />,
    name: 'Monte Carlo',
    objectType: 'menu-item',
    url: '/admin/monte-carlo',
  },
  rbacMenuItem: {
    breadcrumbLabelList: [],
    children: [],
    guid: '/admin/access-control',
    iconEl: <Icon color="currentColor" name="access-control" size="16px" />,
    name: 'Access Control',
    objectType: 'menu-item',
    url: '/admin/access-control',
  },
  slackMenuItem: {
    breadcrumbLabelList: [],
    children: [],
    guid: '/admin/slack',
    iconEl: <Icon color="currentColor" name="slack" size="16px" />,
    name: 'Slack',
    objectType: 'menu-item',
    url: '/admin/slack',
  },
  sso: {
    breadcrumbLabelList: [],
    children: [],
    guid: '/admin/sso',
    iconEl: <Icon color="currentColor" name="key-filled" size="14px" />,
    name: 'SSO',
    objectType: 'menu-item',
    url: '/admin/sso',
  },
  teams: {
    breadcrumbLabelList: [],
    children: [],
    guid: '/admin/teams',
    iconEl: <Icon color="currentColor" name="team" size="18px" />,
    name: 'Teams',
    objectType: 'menu-item',
    url: '/admin/teams',
  },
  users: {
    breadcrumbLabelList: [],
    children: [],
    guid: '/admin/users',
    iconEl: <Icon color="currentColor" name="user" size="16px" />,
    name: 'Users',
    objectType: 'menu-item',
    url: '/admin/users',
  },
};

const transformMenuItem = (menuItem: any, icon: string): HierarchyData => ({
  ...menuItem,
  icon,
  iconEl: undefined,
});

const newLayoutIcons = {
  analytics: 'analyticsV1',
  apps: 'apps',
  automatedPiiMenuItem: 'lock-outlined',
  billingMenuItem: 'credit-cardV1',
  constAnalysisMenuItem: 'dollar-circleV1',
  customAttributeMenuItem: 'submenu',
  data: 'database',
  githubMenuItem: 'github',
  monteCarloMenuItem: 'monte-carlo',
  rbacMenuItem: 'access-control',
  slackMenuItem: 'slack',
  sso: 'key-outlined',
  teams: 'team',
  users: 'user',
};

const getTransformedAdminMenuItems = () => {
  return (Object.keys(adminMenuItems) as Array<keyof typeof adminMenuItems>).reduce((acc, key) => {
    acc[key] = transformMenuItem(adminMenuItems[key], newLayoutIcons[key]);
    return acc;
  }, {} as Record<keyof typeof adminMenuItems, HierarchyData>);
};
export const getAdminHierarchyItems = (shouldUseNewLayout = false) => {
  if (shouldUseNewLayout) {
    return getTransformedAdminMenuItems();
  }

  return adminMenuItems;
};
