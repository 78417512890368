import styled from '@emotion/styled';

import Box from '@components/Box';
import rootTheme from '@styles/theme';

export const StyledSearchBarShortcut = styled(Box)``;

StyledSearchBarShortcut.defaultProps = {
  alignItems: 'center',
  compDisplay: 'flex',
  gap: 0.5,
  pointerEvents: 'none',
  zIndex: 2,
};

interface StyledSearchBarShortcutItemProps {
  isMacOs?: boolean;
}

export const StyledSearchBarShortcutItem = styled(Box)<StyledSearchBarShortcutItemProps>`
  padding-left: ${({ isMacOs, theme }) => theme.space(isMacOs ? 0.5 : 0.75)};
  padding-right: ${({ isMacOs, theme }) => theme.space(isMacOs ? 0.5 : 0.75)};
`;

StyledSearchBarShortcutItem.defaultProps = {
  alignItems: 'center',
  backgroundColor: 'gray.200',
  borderRadius: 'xs',
  color: 'gray.400',
  compDisplay: 'flex',
  compHeight: rootTheme.space(2.5),
  justifyContent: 'center',
  minWidth: rootTheme.space(2.5),
};
