import { PostTermsCsvUploadCreateResponse } from '@api/openapi.generated';
import paginatedTransform from '@api/paginatedTransform';
import rawTransform from '@api/rawTransform';
import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import type { UseMutationOptions } from '@hooks/useMutation';
import useMutation from '@hooks/useMutation';
import type { OwnersUpdateData } from '@models/Owners';
import { PaginatedResponse } from '@models/PaginatedResponse';
import type { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';

import * as cacheKeys from './cacheKeys';
import { TermModel } from './TermModel';

const fetchTermsSelect = paginatedTransform(TermModel);
export const useFetchTerms = (options?: UseFetchOptions<PaginatedResponse<TermModel>>) => {
  return useFetch<PaginatedResponse<TermModel>>({
    ...options,
    queryKey: [...cacheKeys.all, options?.params],
    select: fetchTermsSelect,
    url: '/terms/',
  });
};

const fetchTermSelect = rawTransform(TermModel);
export const useFetchTerm = (guid: string, options?: UseFetchOptions<TermModel>) => {
  return useFetch<TermModel>({
    ...options,
    queryKey: [...cacheKeys.term(guid), options?.params],
    select: fetchTermSelect,
    url: `/terms/${guid}/`,
  });
};

export const useFetchTermsExport = (options?: UseFetchOptions) => {
  return useFetch({
    ...options,
    queryKey: [...cacheKeys.exportData, options?.params],
    responseType: 'blob',
    url: `/terms/export/`,
  });
};

export const usePostTerms = (options?: UseMutationOptions<TermModel>) => {
  return useMutation<TermModel>({
    ...options,
    method: 'POST',
    onSuccess: (data, variables, context) => {
      const transformedData = rawTransform(TermModel)(data);
      options?.onSuccess?.(transformedData, variables, context);
    },
    url: '/terms/',
  });
};

export const usePatchTerm = (id: string, options?: UseMutationOptions<TermModel>) => {
  return useMutation<TermModel>({
    ...options,
    method: 'PATCH',
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(fetchTermSelect(data), variables, context);
    },
    url: `/terms/${id}/`,
  });
};

export const usePostTermsCSVUpload = (
  options?: UseMutationOptions<PostTermsCsvUploadCreateResponse>,
) => {
  return useMutation<PostTermsCsvUploadCreateResponse>({
    ...options,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: 'POST',
    url: '/terms/csv-upload/',
  });
};

export const usePatchTermsOwners = (
  options?: UseMutationOptions<never, EnhancedErrorResult, OwnersUpdateData>,
) => {
  return useMutation({
    ...options,
    method: 'PATCH',
    url: `/terms/owners/`,
  });
};
