import type { GetJobsEventsListPathParams, GetJobsListQueryParams } from '@api/openapi.generated';
import { paginatedSelect, rawSelect } from '@api/utils';
import type { UseFetchOptions } from '@hooks/useFetch';
import useFetch from '@hooks/useFetch';
import type { PaginatedResponse } from '@models/PaginatedResponse';

import * as cacheKeys from './cacheKeys';
import type { JobEventModel } from './JobEventModel.v1';
import mapJobEventModel from './JobEventModel.v1';
import type { JobModel } from './JobModel.v1';
import mapJobModel from './JobModel.v1';

export const fetchJobsSelect = paginatedSelect(mapJobModel);
interface JobsResponse extends PaginatedResponse<JobModel> {}

export const useFetchJobs = (options: UseFetchOptions<JobsResponse, GetJobsListQueryParams>) => {
  return useFetch<JobsResponse>({
    ...options,
    queryKey: [...cacheKeys.all, options.params],
    select: fetchJobsSelect,
    url: `/jobs/`,
  });
};

export const fetchJobSelect = rawSelect(mapJobModel);
interface JobResponse extends JobModel {}

export const useFetchJob = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  guid: string = '',
  options: UseFetchOptions<JobResponse, GetJobsListQueryParams>,
) => {
  return useFetch<JobResponse>({
    ...options,
    queryKey: [...cacheKeys.job(guid), options.params],
    select: fetchJobSelect,
    url: `/jobs/${guid}`,
  });
};

export const fetchJobEventsSelect = paginatedSelect(mapJobEventModel);
interface JobEventsResponse extends PaginatedResponse<JobEventModel> {}

export const useFetchJobEvents = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  guid: string = '',
  options: UseFetchOptions<JobEventsResponse, GetJobsEventsListPathParams>,
) => {
  return useFetch<JobEventsResponse>({
    ...options,
    queryKey: [...cacheKeys.jobEvents(guid), options.params],
    select: fetchJobEventsSelect,
    url: `/jobs/${guid}/events`,
  });
};
