import { useMemo } from 'react';

import { useFetchDataSourcesHierarchy } from '@api/dataSources';
import useEditableDataSources from '@components/SettingsSidebar/useEditableDataSources';
import { useUserContext } from '@context/User';
import flags from '@features';
import { ConnectableApps, useConnectableApps } from '@hooks/useConnectableApps';
import useNewLayout from '@hooks/useNewLayout';
import { HierarchyData } from '@models/HierarchyModel';

import { getAdminHierarchyItems } from './SettingsSidebar.config';

const useAdminHierarchy = () => {
  const { isOrgAdmin, organization } = useUserContext();
  const { shouldUseNewLayout } = useNewLayout();

  const { data } = useFetchDataSourcesHierarchy({
    params: {
      exclude_docs: false,
      max_level: 'datasource',
      org_guid: organization?.guid ?? '',
    },
  });

  const { dataSources: allowedDataSources } = useEditableDataSources({ data: data?.data ?? [] });

  const { connectedApps } = useConnectableApps();

  const hierarchyItems = useMemo(
    () => getAdminHierarchyItems(shouldUseNewLayout),
    [shouldUseNewLayout],
  );

  const appsMenuChildren = useMemo(() => {
    const apps = [hierarchyItems.slackMenuItem];
    if (connectedApps.includes(ConnectableApps.github)) apps.push(hierarchyItems.githubMenuItem);
    if (connectedApps.includes(ConnectableApps.monteCarlo))
      apps.push(hierarchyItems.monteCarloMenuItem);

    return apps;
  }, [hierarchyItems, connectedApps]);

  const dataSourcesItem = useMemo(() => {
    const dataSourcesItemChildren = [
      ...allowedDataSources.filter((ds) => ds.type !== 'monte_carlo'),
      ...(isOrgAdmin ? [hierarchyItems.customAttributeMenuItem] : []),
    ];

    return dataSourcesItemChildren.length > 0
      ? [
          {
            ...hierarchyItems.data,
            children: dataSourcesItemChildren,
          },
        ]
      : [];
  }, [hierarchyItems, isOrgAdmin, allowedDataSources]);

  const adminHierarchy = useMemo(() => {
    if (flags.billing && (organization?.isTrialExpired || organization?.isSubscriptionEnded))
      return [hierarchyItems.billingMenuItem];

    const settings = organization?.settings;
    const dataSources = data?.data ?? [];
    const hasSnowflake = dataSources.some((ds) => ds.dataTypes?.dataSourceType === 'snowflake');

    const orgAdminItems: HierarchyData[] = [
      hierarchyItems.analytics,
      hierarchyItems.users,
      hierarchyItems.teams,
      hierarchyItems.sso,
      {
        ...hierarchyItems.apps,
        children: appsMenuChildren,
      },
      ...(flags.billing ? [hierarchyItems.billingMenuItem] : []),
      ...(settings?.enableCostAnalysis && hasSnowflake
        ? [hierarchyItems.constAnalysisMenuItem]
        : []),
      ...(settings?.automatedPii ? [hierarchyItems.automatedPiiMenuItem] : []),
      ...(settings?.useRbac ? [hierarchyItems.rbacMenuItem] : []),
    ];

    const hierarchy: HierarchyData[] = [...dataSourcesItem, ...(isOrgAdmin ? orgAdminItems : [])];

    return hierarchy;
  }, [hierarchyItems, data, organization, appsMenuChildren, isOrgAdmin, dataSourcesItem]);

  return adminHierarchy;
};

export default useAdminHierarchy;
