import React, { forwardRef } from 'react';

import CircularLoader from '@components/CircularLoader';
import IconButton from '@components/IconButton';

import { StyledCaretButtonIcon } from './CaretButton.styles';

interface CaretButtonProps {
  isLoading?: boolean;
  isOpen?: boolean;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const CaretButton = forwardRef<HTMLDivElement, CaretButtonProps>(
  ({ isLoading, isOpen, onClick }, ref) => {
    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      onClick?.(e);
    };

    return (
      <IconButton
        ref={ref}
        aria-label="caret-button"
        compDisplay="flex"
        compHeight="16px"
        compWidth="16px"
        onClick={handleClick}
        type="button"
        variant="clear"
      >
        {isLoading ? (
          <CircularLoader compDisplay="inline" compSize={2.5} />
        ) : (
          <StyledCaretButtonIcon color="currentColor" isOpen={isOpen} name="down" size="16px" />
        )}
      </IconButton>
    );
  },
);

export default CaretButton;
