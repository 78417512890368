import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';

import { BreadcrumbItem } from './Breadcrumbs.types';

// Expose class getters
// eslint-disable-next-line import/prefer-default-export
export const breadcrumbsToList = (
  breadcrumbs?: BreadcrumbModel[],
): BreadcrumbItem[] | undefined => {
  return breadcrumbs?.map((item) => ({
    guid: item.guid,
    label: item.label,
    url: item.url,
  }));
};
