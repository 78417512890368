import styled from '@emotion/styled';
import { RouterLink } from '@routing/router';

import Box from '@components/Box';
import px from '@styles/mixins/px';

import { SidebarSection } from '../AppMainSidebar.types';

const PRIMARY_SIDEBAR_EXPANDED_WIDTH = 230;
const PRIMARY_SIDEBAR_COLLAPSED_WIDTH = 65;
export const PRIMARY_SIDEBAR_TRANSITION_DURATION = 300; // in ms
export const PRIMARY_SIDEBAR_LOGO_CONTAINER_HEIGHT = 48;

interface StyledPrimarySidebarProps {
  isCollapsed?: boolean;
}

export const StyledPrimarySidebar = styled.nav<StyledPrimarySidebarProps>`
  background-color: ${({ theme }) => theme.colors.white};
  border-right: 1px solid ${({ theme }) => theme.colors.v1.gray[200]};
  height: 100%;
  transition: width ${PRIMARY_SIDEBAR_TRANSITION_DURATION}ms ease;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: ${({ theme }) => theme.space(1)};
  width: ${({ isCollapsed }) =>
    px(isCollapsed ? PRIMARY_SIDEBAR_COLLAPSED_WIDTH : PRIMARY_SIDEBAR_EXPANDED_WIDTH)};
`;

interface StyledPrimarySidebarLogoContainerProps {
  shouldCenterContent?: boolean;
}

export const StyledPrimarySidebarLogoContainer = styled(
  Box,
)<StyledPrimarySidebarLogoContainerProps>`
  justify-content: ${({ shouldCenterContent }) => (shouldCenterContent ? 'center' : 'flex-start')};
  padding-left: ${({ theme }) => theme.space(2.75)};
  padding-right: ${({ shouldCenterContent, theme }) => theme.space(shouldCenterContent ? 0 : 2.25)};
`;

StyledPrimarySidebarLogoContainer.defaultProps = {
  alignItems: 'center',
  borderBottom: '1px',
  borderColor: 'gray.200',
  borderStyle: 'solid',
  compDisplay: 'flex',
  compHeight: `${PRIMARY_SIDEBAR_LOGO_CONTAINER_HEIGHT}px`,
  compWidth: '100%',
  minHeight: `${PRIMARY_SIDEBAR_LOGO_CONTAINER_HEIGHT}px`,
};

interface StyledPrimarySidebarLinksSectionProps {
  sectionPosition?: SidebarSection['position'];
}

export const StyledPrimarySidebarLinksSection = styled(Box)<StyledPrimarySidebarLinksSectionProps>`
  ${({ sectionPosition }) =>
    sectionPosition === 'top' ? 'border-bottom' : 'border-top'}: 1px solid;
  border-color: ${({ theme }) => theme.colors.v1.gray[200]};
`;

StyledPrimarySidebarLinksSection.defaultProps = {
  compDisplay: 'flex',
  compWidth: '100%',
  flexDirection: 'column',
  gap: 0.5,
  px: 1.5,
  py: 1,
};

interface StyledPrimarySidebarLinkProps {
  isActive?: boolean;
  isSidebarCollapsed?: boolean;
}

export const StyledPrimarySidebarLink = styled(Box)<StyledPrimarySidebarLinkProps>`
  transition: all 0.15s ease;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.v1.primary[100] : theme.colors.white};
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.v1.primary[500] : theme.colors.v1.gray[700]};
  justify-content: ${({ isSidebarCollapsed }) => (isSidebarCollapsed ? 'center' : 'flex-start')};

  &:hover {
    color: ${({ isActive, theme }) =>
      isActive ? theme.colors.v1.primary[500] : theme.colors.v1.gray[700]};
    background-color: ${({ isActive, theme }) =>
      isActive ? theme.colors.v1.primary[100] : theme.colors.v1.gray[100]};
  }
`;

StyledPrimarySidebarLink.defaultProps = {
  alignItems: 'center',
  borderRadius: 'md',
  compDisplay: 'flex',
  compHeight: '36px',
  compWidth: '100%',
  fontSize: 'body2',
  fontWeight: 'medium',
  gap: 1.5,
  overflow: 'hidden',
  position: 'relative',
  whiteSpace: 'nowrap',
};

interface StyledLogoProps {
  height?: string;
  isCollapsed?: boolean;
}

export const StyledLogo = styled.img<StyledLogoProps>`
  height: ${({ height = '20px' }) => height};
  object-fit: cover;
  object-position: left;
  transition: all 0.15s ease;
  width: ${({ isCollapsed }) => (isCollapsed ? '26px' : 'auto')};
`;

interface LogoLinkProps {
  disableAccess: boolean;
  isCollapsed: boolean;
}

export const StyledLogoLink = styled(RouterLink)<LogoLinkProps>`
  display: flex;
  width: 100%;
  gap: ${({ isCollapsed, theme }) => theme.space(isCollapsed ? 3 : 1)};
  transition: all 0.15s ease;
  align-items: center;
  flex-wrap: nowrap;
  pointer-events: ${({ disableAccess }) => (disableAccess ? 'none' : 'auto')};
`;

interface StyledPrimarySidebarItemLabelProps {
  isCollapsed?: boolean;
}

export const StyledPrimarySidebarItemLabel = styled(Box)<StyledPrimarySidebarItemLabelProps>`
  transition: all 0.15s ease;
  gap: ${({ isCollapsed, theme }) => theme.space(isCollapsed ? 1.5 : 1)};
`;

StyledPrimarySidebarItemLabel.defaultProps = {
  alignItems: 'center',
  compDisplay: 'flex',
  compHeight: '100%',
  flexWrap: 'nowrap',
  fontSize: 'body2',
  fontWeight: 'medium',
  left: '10px',
  position: 'absolute',
  right: '10px',
  whiteSpace: 'nowrap',
};
