import paginatedTransform from '@api/paginatedTransform';
import rawTransform from '@api/rawTransform';
import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import type { UseMutationOptions } from '@hooks/useMutation';
import useMutation from '@hooks/useMutation';
import fetchClient from '@lib/fetchClient';
import { HierarchyData, HierarchyModel } from '@models/HierarchyModel';
import type { OwnersUpdateData } from '@models/Owners';
import { PaginatedResponse } from '@models/PaginatedResponse';
import type { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';

import * as cacheKeys from './cacheKeys';
import { DocumentModel } from './DocumentModel';

export const fetchDocumentsSelect = paginatedTransform(DocumentModel);
export const useFetchDocuments = (options?: UseFetchOptions<PaginatedResponse<DocumentModel>>) => {
  return useFetch<PaginatedResponse<DocumentModel>>({
    ...options,
    queryKey: [...cacheKeys.all, options?.params],
    select: fetchDocumentsSelect,
    url: '/documents/',
  });
};

const fetchDocumentSelect = rawTransform(DocumentModel);
export const useFetchDocument = (id: string, options?: UseFetchOptions<DocumentModel>) => {
  return useFetch<DocumentModel>({
    ...options,
    queryKey: cacheKeys.document(id),
    select: fetchDocumentSelect,
    url: `/documents/${id}/`,
  });
};

export const useDeleteDocuments = (options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    method: 'DELETE',
    onSuccess(data, variables, context) {
      fetchClient.invalidateQueries(cacheKeys.hierarchy);
      fetchClient.invalidateQueries(cacheKeys.all);
      options?.onSuccess?.(data, variables, context);
    },
    url: '/documents/',
  });
};

export const usePostDocument = (options?: UseMutationOptions) => {
  return useMutation<DocumentModel>({
    ...options,
    method: 'POST',
    onSuccess: (data, variables, context) => {
      fetchClient.invalidateQueries(cacheKeys.hierarchy);
      fetchClient.invalidateQueries(cacheKeys.all);
      const transformedData = rawTransform(DocumentModel)(data);
      options?.onSuccess?.(transformedData, variables, context);
    },
    url: '/documents/',
  });
};

export const usePatchDocument = (id?: string, options?: UseMutationOptions) => {
  return useMutation<DocumentModel>({
    ...options,
    method: 'PATCH',
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(fetchDocumentSelect(data), variables, context);
    },
    url: id ? `/documents/${id}/` : undefined,
  });
};

const documentsHierarchySelect = rawTransform(HierarchyModel);
export const useFetchDocumentsHierarchy = (options?: UseFetchOptions<HierarchyData[]>) => {
  return useFetch<HierarchyData[]>({
    ...options,
    queryKey: [...cacheKeys.hierarchy],
    select: documentsHierarchySelect,
    url: '/documents/hierarchy/',
  });
};

export const usePatchDocumentsOwners = (
  options?: UseMutationOptions<never, EnhancedErrorResult, OwnersUpdateData>,
) => {
  return useMutation({
    ...options,
    method: 'PATCH',
    url: `/documents/owners/`,
  });
};
