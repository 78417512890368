import React from 'react';

import Text from '@components/Text';

import { StyledMainPageSection } from './MainPageSection.styles';

interface MainPageSectionProps {
  label: React.ReactNode;
}

const MainPageSection: React.FC<MainPageSectionProps> = ({ children, label }) => {
  return (
    <StyledMainPageSection>
      <Text color="gray.900" fontSize="default" fontWeight="semibold" mb={2.5}>
        {label}
      </Text>
      {children}
    </StyledMainPageSection>
  );
};

export default MainPageSection;
