import { Expose, Type } from 'class-transformer';

import { OrganizationModel } from '@api/organizations/OrganizationModel';

export class BillingCustomerModel {
  guid!: string;

  @Type(() => OrganizationModel)
  organization?: OrganizationModel;

  @Expose({ name: 'stripe_customer_id' })
  stripeCustomerId?: string;

  @Expose({ name: 'stripe_price_id' })
  stripePriceId?: string;

  @Expose({ name: 'plan_name' })
  planName?: string;

  @Expose({ name: 'max_allowed_tables' })
  maxAllowedTables?: number;

  @Expose({ name: 'max_allowed_data_sources' })
  maxAllowedDataSources?: number;

  @Expose({ name: 'max_allowed_users' })
  maxAllowedUsers?: number;
}
