import React, { useMemo } from 'react';

import type { PopularityProps } from '@components/Popularity';
import Popularity from '@components/Popularity';
import { PopularityModel } from '@models/PopularityModel';
import { getPopularityNormalized } from '@utils/popularity';

import { TypedCell } from '../types';

type PopularityCellProps = Partial<TypedCell> &
  Pick<PopularityProps, 'dataTypes'> & {
    isDashboard?: boolean;
    isJoin?: boolean;
    isReportQuery?: boolean;
    isTableauDataSource?: boolean;
    isTableauField?: boolean;
    isTableauView?: boolean;
    isWorkbook?: boolean;
    popularity?: PopularityModel;
    rawPopularity?: number;
  };

const PopularityCell: React.FC<PopularityCellProps> = ({
  dataTypes,
  isDashboard,
  isJoin,
  isReportQuery,
  isTableauDataSource,
  isTableauField,
  isTableauView,
  isWorkbook,
  popularity,
  rawPopularity,
}) => {
  const popularityTooltip = useMemo(() => {
    /**
     * TODO FIXME this duplicates the logic from TablePopularityTooltip.tsx
     * There are a lot of discrepancies in implementation of both methods
     * but there should be just one place where the popularity tooltip
     * generation is defined.
     */
    if (rawPopularity && !popularity) {
      return `Popularity score: ${rawPopularity - 1}`;
    }

    if (!popularity) {
      return '';
    }

    const user = popularity.userCount === 1 ? 'user' : 'users';
    const times = popularity.count === 1 ? 'time' : 'times';

    // Tableau items
    if (isTableauView) {
      return `Viewed ${popularity.count} ${times} by ${
        popularity.userCount
      } ${user} in the last ${popularity.timeframe?.valueOf()} days`;
    }

    if (isTableauDataSource) {
      return `Accessed ${
        popularity.count
      } ${times} in the last ${popularity.timeframe?.valueOf()} days`;
    }
    if (isTableauField) {
      return `Accessed ${
        popularity.count
      } ${times} in the last ${popularity.timeframe?.valueOf()} days`;
    }
    if (isWorkbook || isTableauView) {
      return `Viewed ${popularity.count} ${times} by ${
        popularity.userCount
      } ${user} in the last ${popularity.timeframe?.valueOf()} days`;
    }
    if (isReportQuery) {
      return `Queried ${
        popularity.count
      } ${times} in the last ${popularity.timeframe?.valueOf()} days`;
    }
    if (isDashboard) {
      if (popularity.userCount && popularity.userCount > 0) {
        return `Viewed ${popularity.count} ${times} by ${
          popularity.userCount
        } ${user} in the last ${popularity.timeframe?.valueOf()} days`;
      }
      return `Viewed ${
        popularity.count
      } ${times} in the last ${popularity.timeframe?.valueOf()} days`;
    }

    return `${isJoin ? 'Used' : 'Queried'} ${popularity.count} ${times} by ${
      popularity.userCount
    } ${user} in the last ${popularity.timeframe?.valueOf()} days`;
  }, [
    rawPopularity,
    popularity,
    isTableauView,
    isTableauDataSource,
    isTableauField,
    isWorkbook,
    isReportQuery,
    isDashboard,
    isJoin,
  ]);

  const popularityNormalized = useMemo(() => {
    return popularity?.formattedPopularity || getPopularityNormalized(rawPopularity);
  }, [popularity, rawPopularity]);

  return (
    <Popularity dataTypes={dataTypes} priority={popularityNormalized} text={popularityTooltip} />
  );
};

export default React.memo(PopularityCell);
