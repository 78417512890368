import getUrl from '@configs/urls/getUrl';

import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import { DashboardModel } from '@api/dashboards/DashboardModel';
import { DsUserModel } from '@api/dsusers/DsUserModel';
import type { BIDashboardElement } from '@api/openapi.generated';
import { TaggedItemModel } from '@api/tags/TaggedItemModel';
import type { ObjectType } from '@api/types';
import { rawClassArrayMap, rawClassMap } from '@api/utils';
import DataTypesModel from '@models/DataTypesModel';

const objectType: ObjectType = 'bidashboardelement';

const mapBiDashboardElementModel = (value: BIDashboardElement) => {
  const dataTypes = rawClassMap(DataTypesModel, value.data_types);
  const dsuserCreatedBy = rawClassMap(DsUserModel, value?.dsuser_created_by);
  const breadcrumbs = rawClassArrayMap(BreadcrumbModel, value?.breadcrumbs);
  const dashboard = rawClassMap(DashboardModel, value.dashboard);
  const taggedItems = rawClassArrayMap(TaggedItemModel, value.tagged_items);

  return {
    aiDescription: value.ai_description ?? '',
    breadcrumbList: breadcrumbs,
    breadcrumbs,
    dashboard,
    dataSourceGuid: value.datasource_guid,
    dataTypes,
    description: value.description ?? '',
    descriptionSource: value.description_source ?? undefined,
    dsuserCreatedBy,
    externalId: value.external_id,
    externalType: value.external_type,
    formattedDsuserCreatedBy: dsuserCreatedBy?.user ?? dsuserCreatedBy,
    formattedTaggedItems: taggedItems?.map((item) => item.tag.name).join(','),
    guid: value.guid!,
    ingestedDescription: value.ingested_description ?? '',
    isMention: Boolean(value.is_mention),
    isMetric: Boolean(value.is_metric),
    name: value.name ?? '',
    objectType: objectType as ObjectType,
    pageName: value.page_name,
    parentGuid: value.parent_guid,
    rawSql: value.raw_sql,
    richtextDescription: value.richtext_description ?? '',
    routePath: getUrl({ dataTypes, guid: value.guid!, parentGuid: value.parent_guid }),
    taggedItems,
    url: value.external_url,
    userDescription: value.user_description ?? '',
  };
};

export default mapBiDashboardElementModel;

mapBiDashboardElementModel.objectType = objectType;

export type BiDashboardElementModel = ReturnType<typeof mapBiDashboardElementModel>;
