import { StackIndex } from '@components/ExploreTree/types';

import { ExploreEdge, ExploreNode } from '../../LineageExplore.types';

export interface AlgorithmResult {
  biggestConflictEndPerStack: Record<number, number>;
  edgesById: EdgesById;
  nodesById: NodesById;
  nodesByStack: StackGroups;
}

export interface EdgesById {
  [key: string]: ExploreEdge;
}
export interface NodesById {
  [key: string]: ExploreNode;
}

export interface StackGroups {
  [key: string]: Set<string>;
}

export interface CreateStacksResult {
  edgesById: EdgesById;
  nodesById: NodesById;
  stackGroups: StackGroups;
  stackedAt: StackIndex;
  startingTableId: string;
}

export interface AlgorithmResultProps {
  initialYPositionPerStack?: Record<number, number>;
  isColumnLevelLineage?: boolean;
  stacksData?: CreateStacksResult;
}

export enum DataNodeTypes {
  bi = 'bi',
  column = 'column',
  database = 'database',
  schema = 'schema',
  table = 'table',
}

export type ConflictArray = [number, number];
