import React from 'react';

import { AppMainSidebarContextProvider } from '@components/AppMainSidebar/AppMainSidebar.v1/useAppMainSidebar';
import { CollectionsHierarchyProvider } from '@context/CollectionsHierarchy';
import { FetchedMentionsProvider } from '@context/FetchedMentions';
import { ObjectPermissionsProvider } from '@context/ObjectPermissions';
import { SessionProvider } from '@context/Session';
import { TagProvider } from '@context/Tag';
import { TagsHierarchyProvider } from '@context/TagsHierarchy';
import { UserProvider } from '@context/User';
import { WorkspacesProvider } from '@context/Workspaces';

const DataProviders: React.FC = ({ children }) => {
  return (
    <UserProvider>
      <ObjectPermissionsProvider>
        <SessionProvider>
          <FetchedMentionsProvider>
            <TagsHierarchyProvider>
              <TagProvider>
                <CollectionsHierarchyProvider>
                  <WorkspacesProvider>
                    <AppMainSidebarContextProvider>{children}</AppMainSidebarContextProvider>
                  </WorkspacesProvider>
                </CollectionsHierarchyProvider>
              </TagProvider>
            </TagsHierarchyProvider>
          </FetchedMentionsProvider>
        </SessionProvider>
      </ObjectPermissionsProvider>
    </UserProvider>
  );
};

export default DataProviders;
