import React from 'react';

import forms from './forms';
import type { DataSourceAddActivityDataStepProps } from './forms/types';

const DataSourceAddActivityDataStep: React.FC<DataSourceAddActivityDataStepProps> = ({
  dataSource,
  dataType,
  onDataSourceAdded,
  prevStep,
}) => {
  const DataSourceAddActivityDataStepForm = forms?.[dataType] ?? (() => null);

  return (
    <DataSourceAddActivityDataStepForm
      dataSource={dataSource}
      dataType={dataType}
      onDataSourceAdded={onDataSourceAdded}
      prevStep={prevStep}
    />
  );
};

export default DataSourceAddActivityDataStep;
