import styled from '@emotion/styled';

import Box from '../Box';
import Button, { ButtonProps } from '../Button/Button';
import IconButton from '../IconButton/IconButton';

export const StyledDropdownButton = styled(Box)`
  width: fit-content;
  height: 36px;
  border-radius: 3px;
  color: ${({ theme }) => theme.colors.white};
`;

export const StyledDropdownButtonMainButtonContainer = styled(Box)`
  width: fit-content;
  height: 100%;
  display: flex;
  align-items: center;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
`;

interface StyledDropdownButtonMainButtonProps extends ButtonProps {
  isMainButton?: boolean;
}

export const StyledDropdownButtonMainButton = styled(Button)<StyledDropdownButtonMainButtonProps>`
  padding-left: ${({ theme }) => theme.space(1.75)};
  padding-right: ${({ theme }) => theme.space(1.75)};
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
  gap: ${({ theme }) => theme.space(0.5)};
  transition: all 300ms linear;
  width: 100%;
  display: flex;
  justify-content: flex-start;

  &:hover {
    ${({ isMainButton, theme }) =>
      isMainButton ? 'opacity: 90%' : `background-color: ${theme.colors.v1.gray[100]};`}
  }
`;

export const StyledDropdownButtonDivisor = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  opacity: 0.7;
`;

export const StyledDropdownButtonIconButton = styled(IconButton)`
  padding-left: 4px;
  padding-right: 4px;
  height: 36px;
  transition: opacity 300ms;
  background-color: ${({ theme }) => theme.colors.primary};
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;

  &:hover {
    opacity: 90%;
  }
`;

export const StyledDropdownButtonOptionsContainer = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors.v1.gray[200]};
`;

StyledDropdownButtonOptionsContainer.defaultProps = {
  alignItems: 'flex-start',
  backgroundColor: 'white',
  borderRadius: 'lg',
  boxShadow: 'lg',
  compDisplay: 'flex',
  compWidth: 'fit-content',
  flexDirection: 'column',
  maxWidth: '300px',
  mt: 0.5,
  overflow: 'hidden',
  position: 'fixed',
};

export const StyledDropdownButtonOptionsOverlay = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
`;
