/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';

import { NotificationModel, NotificationType } from '@api/notifications/NotificationModel';
import { shortFromNow } from '@utils/moment';
import { getDiscussionUrl, urlFor } from '@utils/routing';

import ActionNotification from './ActionNotification';
import {
  StyledNotificationItem,
  StyledNotificationText,
  StyledNotificationTime,
} from './Notification.styles';
import SystemNotification from './SystemNotification';

interface NotificationItemProps {
  item: NotificationModel;
  markAsRead: (config: Record<string, string>) => void;
}

const NotificationItem = ({ item, markAsRead }: NotificationItemProps) => {
  if (!item.target || !item.actor) return null;

  const isCommentNotification = item.verb.includes('comment');
  const isSystemNotification = item.notificationType === NotificationType.system;

  const url = urlFor(item.target, true);

  return (
    <StyledNotificationItem key={item.id} unread={item.unread}>
      <StyledNotificationText
        onClick={() =>
          item.unread
            ? markAsRead({ httpClientUrl: `/notifications/mark-as-read/${item.id}/` })
            : null
        }
        to={isCommentNotification ? getDiscussionUrl(url, item.actionObject?.guid) : url}
      >
        {isSystemNotification ? (
          <SystemNotification item={item} />
        ) : (
          <ActionNotification item={item} />
        )}
      </StyledNotificationText>
      <StyledNotificationTime>{shortFromNow(item.timestamp)}</StyledNotificationTime>
    </StyledNotificationItem>
  );
};

export default NotificationItem;
