import paginatedTransform from '@api/paginatedTransform';
import rawTransform from '@api/rawTransform';
import type { UseFetchOptions } from '@hooks/useFetch';
import useFetch from '@hooks/useFetch';
import type { UseMutationOptions } from '@hooks/useMutation';
import useMutation from '@hooks/useMutation';
import { DataSourceModel } from '@models/DataSourceModel';
import { PaginatedResponse } from '@models/PaginatedResponse';

import * as cacheKeys from './cacheKeys';
import DatabaseIngestionConfigModel from './DatabaseIngestionConfigModel';
import DataSourceIngestionConfigModel from './DataSourceIngestionConfigModel';
import IngestionLogModel from './IngestionLogModel';

const fetchIngestionLogsSelect = paginatedTransform(IngestionLogModel);

export const useFetchIngestionLogs = (
  options?: UseFetchOptions<PaginatedResponse<IngestionLogModel>>,
) => {
  return useFetch<PaginatedResponse<IngestionLogModel>>({
    ...options,
    queryKey: [...cacheKeys.logs, options?.params],
    select: fetchIngestionLogsSelect,
    url: `/ingestion/logs/`,
  });
};

const fetchIngestionBiLogsSelect = paginatedTransform(IngestionLogModel);

export const useFetchIngestionBiLogs = (
  options?: UseFetchOptions<PaginatedResponse<IngestionLogModel>>,
) => {
  return useFetch<PaginatedResponse<IngestionLogModel>>({
    ...options,
    queryKey: [...cacheKeys.logs, options?.params],
    select: fetchIngestionBiLogsSelect,
    url: `/ingestion/logs/bi/`,
  });
};

const fetchDataSourceConfigInitSelect = rawTransform(DataSourceIngestionConfigModel);

export const useFetchDataSourceConfigInit = (
  id: string,
  options?: UseFetchOptions<DataSourceIngestionConfigModel>,
) => {
  return useFetch<DataSourceIngestionConfigModel>({
    ...options,
    queryKey: cacheKeys.configDataSource(id),
    select: fetchDataSourceConfigInitSelect,
    url: `/ingestion/configs/${id}/init/`,
  });
};

const ingestDataSourceSelect = rawTransform(DataSourceModel);

export const useIngestDataSource = (
  id: string,
  options?: UseFetchOptions<DataSourceIngestionConfigModel>,
) => {
  return useFetch<DataSourceIngestionConfigModel>({
    ...options,
    queryKey: [cacheKeys.ingestion(id), options?.params],
    select: ingestDataSourceSelect,
    url: `/ingestion/configs/${id}/ingest/`,
  });
};

type DatabaseIngestionConfigPaginatedResponse = {
  active_count: number;
} & PaginatedResponse<DatabaseIngestionConfigModel>;

const fetchDatabaseConfigsSelect = paginatedTransform(DatabaseIngestionConfigModel);

export const useFetchDatabaseConfigs = (
  id: string,
  options?: UseFetchOptions<DatabaseIngestionConfigPaginatedResponse>,
) => {
  return useFetch<DatabaseIngestionConfigPaginatedResponse>({
    ...options,
    queryKey: [cacheKeys.configDatabase(id), options?.params],
    select: fetchDatabaseConfigsSelect,
    url: `/ingestion/configs/${id}/databases/`,
  });
};

export const usePatchDatabaseConfigs = (id: string, options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    method: 'PATCH',
    url: `/ingestion/configs/${id}/databases/`,
  });
};

export const usePatchDatabaseConfig = (
  id: string,
  configId: string,
  options?: UseMutationOptions,
) => {
  return useMutation({
    ...options,
    method: 'PATCH',
    url: `ingestion/configs/${id}/databases/${configId}/schema/`,
  });
};

const patchIngestionConfigSelect = rawTransform(DataSourceIngestionConfigModel);

export const usePatchIngestionConfig = (id: string, options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    method: 'PATCH',
    onSuccess: (data, variables, context) => {
      const transformedData = patchIngestionConfigSelect(data);
      options?.onSuccess?.(transformedData, variables, context);
    },
    url: `/ingestion/configs/${id}/`,
  });
};
