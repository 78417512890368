import React from 'react';

import { GridContainer } from '@components/Grid';
import useNewLayout from '@hooks/useNewLayout';

const LayoutMainGrid: React.FC = ({ children }) => {
  const { shouldUseNewLayout } = useNewLayout();

  return (
    <GridContainer fluid hPaddingSpace={5} vPaddingSpace={shouldUseNewLayout ? 2.5 : 5}>
      {children}
    </GridContainer>
  );
};

export default LayoutMainGrid;
