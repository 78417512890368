import React, { useEffect, useMemo, useState } from 'react';
import { useDebounce, useDebouncedCallback } from 'use-debounce';

import { usePostQuickSearch } from '@api/search';
import type { SearchModel } from '@api/search/SearchModel';
import type { QuickSearchIndexFilter, SearchResult as SearchResultType } from '@api/search/types';
import type { DatasourceTabsV1Props } from '@components/SearchBar/DatasourceTabs/DatasourceTabsV1';
import DatasourceTabsV1 from '@components/SearchBar/DatasourceTabs/DatasourceTabsV1';
import SearchResult from '@components/SearchBar/SearchResult/SearchResult';
import { SearchResultContainer } from '@components/SearchBar/SearchResult/SearchResult.styles';
import Search from '@components/UI/Search';
import { SearchProps } from '@components/UI/Search/Search';
import type { Option } from '@components/UI/Select/types';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';

import { DatasourceTabV1 } from '../SearchBar/DatasourceTabs';

interface QuickSearchInputProps extends Pick<SearchProps, 'placeholder' | 'leftIcon'> {
  filters?: QuickSearchIndexFilter[];
  noExternalTags?: boolean;
  onBeforeResult?: (
    items: SearchModel[],
    searchText: string,
    activeTab: DatasourceTabV1,
  ) => SearchModel[];
  onResultSelect?: (item: SearchModel) => void;
  tabs?: DatasourceTabsV1Props['tabs'];
}

const QuickSearchInput: React.FC<QuickSearchInputProps> = ({
  filters,
  leftIcon,
  noExternalTags = false,
  onBeforeResult,
  onResultSelect,
  placeholder,
  tabs,
}) => {
  const [text, setText] = useState<string>('');
  const [isChangingText, setIsChangingText] = useState(false);
  const [textDebounced] = useDebounce(text, 500);
  const [activeTab, setActiveTab] = useState<DatasourceTabV1 | undefined>(tabs?.[0]);
  const [searchResult, setSearchResult] = useState<SearchResultType<SearchModel>>();
  const segment = useSegmentContext();
  const [facets, setFacets] = useState<SearchResultType<SearchModel>['facets']>();
  const [filter, setFilter] = useState(filters ?? tabs?.[0].filters);

  const { isLoading: isLoadingResults, mutate: search } = usePostQuickSearch({
    onSuccess: (result) => {
      setIsChangingText(false);
      if (!facets) {
        setFacets(result.facets);
      }

      if (onBeforeResult)
        setSearchResult({
          ...result,
          data: onBeforeResult(result.data, text, activeTab as DatasourceTabV1),
        });
      else setSearchResult(result);
    },
  });

  const debouncedSearch = useDebouncedCallback((term: string) => {
    search({
      all_buckets_search: true,
      index_filters: filter,
      no_external_tags: noExternalTags,
      query: term,
    });
  }, 500);

  const resetSearch = () => {
    setIsChangingText(false);
    setFacets(undefined);
    setActiveTab(tabs?.[0]);
    setFilter(filters ?? tabs?.[0].filters);
    setSearchResult(undefined);
  };

  const trackSelectedValue = (result: SearchModel) => {
    const { guid, indexPosition } = result;

    if (indexPosition !== undefined) {
      segment?.track(SegmentTrackEventName.QuickSearchResultClicked, {
        guid,
        rank: indexPosition,
        tab: activeTab?.name,
      });
    }
  };

  const handleResultSelect = (option: Option) => {
    trackSelectedValue(option.original);
    if (onResultSelect) {
      onResultSelect(option.original);
    }
  };

  const handleSearchChange = (value = '') => {
    setText(value);

    if (value.length < 1) {
      resetSearch();
    }
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const ResultRenderer = ({ option }: { option: Option }) => (
    <SearchResultContainer id={option.original.id}>
      <SearchResult
        clickableBreadcrumbs={false}
        isQuickSearch
        searchItem={option.original}
        searchText={text}
      />
    </SearchResultContainer>
  );

  useEffect(() => {
    setIsChangingText(true);
    setActiveTab(tabs?.[0]);
    setFilter(filters ?? tabs?.[0].filters);
    setFacets(undefined);
    setSearchResult(undefined);
    debouncedSearch(textDebounced);
  }, [textDebounced]);

  const filterTabs = tabs && (
    <DatasourceTabsV1
      data={searchResult}
      facets={facets}
      onFilterChange={(f, tab) => {
        setFilter(f);
        debouncedSearch(text);
        setActiveTab(tab);
      }}
      tabs={tabs}
    />
  );

  const options: Option[] = useMemo(
    () =>
      searchResult?.data?.map((el) => ({
        bordered: true,
        original: el,
        text: el.title,
        value: el.guid,
      })) ?? [],
    [searchResult],
  );

  const isLoading = isLoadingResults || isChangingText;
  return (
    <Search
      isLoading={isLoading}
      leftIcon={leftIcon}
      onResultSelect={handleResultSelect}
      onSearchChange={handleSearchChange}
      options={options}
      optionsListHeaderElement={filterTabs}
      placeholder={placeholder}
      renderCustomOption={ResultRenderer}
      searchValue={text}
    />
  );
};

export default QuickSearchInput;
