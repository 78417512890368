import { Expose, Transform, Type } from 'class-transformer';
import moment from 'moment';

import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import { DimensionItemModel } from '@api/metrics/DimensionItemModel';
import { DisplayedInItemModel } from '@api/metrics/DisplayedInItemModel';
import { RepresentatedAsFieldModel } from '@api/metrics/RepresentatedAsFieldModel';
import { TaggedItemModel } from '@api/tags/TaggedItemModel';
import { TeamModel } from '@api/teams/TeamModel';
import { ObjectType } from '@api/types';
import { UserModel } from '@api/user/UserModel';
import { breadcrumbsToLabelList, breadcrumbsToList } from '@components/Breadcrumbs';
import isEmptyRichText from '@components/RichTextEditor/helpers/isEmptyRichText';
import DataTypesModel from '@models/DataTypesModel';
import { Reference } from '@models/Reference';
import { urlFor } from '@utils/routing';

import { DimensionItemType } from './DimensionItemModel';

export class MetricModel {
  static objectType: ObjectType = 'metric';

  objectType: ObjectType = MetricModel.objectType;

  static typeDisplay: string = 'Metric';

  typeDisplay: string = MetricModel.typeDisplay;

  guid!: string;

  name!: string;

  description?: string;

  @Expose({ name: 'data_type' })
  dataType: string = 'metric';

  // TODO: Remove transform https://app.shortcut.com/select-star/story/55620
  @Expose({ name: 'richtext_description' })
  @Transform((richtextDescription) =>
    isEmptyRichText(richtextDescription) ? '' : richtextDescription,
  )
  richtextDescription?: string;

  @Expose({ name: 'technical_owner' })
  @Type(() => Reference)
  technicalOwner?: Reference<UserModel | TeamModel>;

  @Expose({ name: 'business_owner' })
  @Type(() => Reference)
  businessOwner?: Reference<UserModel | TeamModel>;

  @Expose({ name: 'represented_as' })
  representedAs?: string;

  @Expose({ name: 'richtext_represented_as' })
  richtextRepresentedAs?: string;

  @Expose({ name: 'created_by' })
  @Type(() => UserModel)
  createdBy!: UserModel;

  @Expose({ name: 'displayed_in' })
  @Type(() => DisplayedInItemModel)
  displayedIn?: DisplayedInItemModel[];

  @Expose({ name: 'represented_as_fields' })
  @Type(() => RepresentatedAsFieldModel)
  representedAsFields?: RepresentatedAsFieldModel[];

  @Expose({ name: 'dimensions' })
  @Type(() => DimensionItemModel)
  dimensions?: DimensionItemModel[];

  @Expose({ name: 'updated_on' })
  @Transform((value) => moment(value))
  updatedOn?: moment.Moment;

  sql?: string;

  @Expose({ name: 'tagged_items' })
  @Type(() => TaggedItemModel)
  taggedItems?: TaggedItemModel[];

  @Expose({ name: 'full_path' })
  fullPath?: string;

  @Type(() => BreadcrumbModel)
  breadcrumbs?: BreadcrumbModel[];

  @Expose({ name: 'data_types' })
  @Type(() => DataTypesModel)
  dataTypes?: DataTypesModel;

  rawPopularity?: number;

  /**
   * @experimental
   * Read TableModel for more info.
   */

  get lastUpdated() {
    return this.updatedOn;
  }

  get breadcrumbList() {
    const breadcrumbsList = breadcrumbsToList(this.breadcrumbs);
    breadcrumbsList?.unshift({
      label: 'All Docs',
      url: '/docs/tabs',
    });
    return breadcrumbsList;
  }

  get breadcrumbLabelList() {
    const url = urlFor(this, false, '');
    return breadcrumbsToLabelList(this.name, url, this.breadcrumbs);
  }

  get formattedDimensions() {
    return this?.dimensions?.reduce((results: DimensionItemType[], { field }) => {
      if (field.obj) results.push(field.obj);
      return results;
    }, []);
  }
}
