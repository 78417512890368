import isUrl from 'is-url';
import { Editor } from 'slate';

import { insertLink } from '../helpers/linkHelpers';

/**
 * Plugin for handling pasting links into the editor. Any new link logic should extend this plugin.
 */
const withLinksPlugin = (editor: Editor) => {
  const { insertData } = editor;

  editor.insertData = (data: DataTransfer) => {
    if (editor.selection) {
      const text = data.getData('text/plain');
      if (isUrl(text)) {
        insertLink(editor, text, text);
        return;
      }
    }
    insertData(data);
  };

  return editor;
};

export default withLinksPlugin;
