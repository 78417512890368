/* eslint-disable sort-keys-fix/sort-keys-fix */
// disabled since we want to render a specific order

import type { BISource, DataSources, Monitor, TransformerTool, WarehouseSource } from './types';

export const warehouseSources = {
  snowflake: { text: 'Snowflake', value: 'snowflake' },
  bigquery: { text: 'BigQuery', value: 'bigquery' },
  redshift: { text: 'Redshift', value: 'redshift' },
  postgres: { text: 'PostgreSQL', value: 'postgres' },
  databricks: { text: 'Databricks', value: 'databricks' },
  mssql: { text: 'MS SQL', value: 'mssql' },
  oracle: { text: 'Oracle', value: 'oracle' },
  mysql: { text: 'MySQL', value: 'mysql' },
  salesforce: { text: 'Salesforce', value: 'salesforce' },
  db2: { text: 'DB2', value: 'db2' },
  glue: { text: 'AWS Glue', value: 'glue' },
  airflow: { text: 'Apache Airflow', value: 'airflow' },
} as const;

export const biSources = {
  tableau: { text: 'Tableau', value: 'tableau' },
  looker: { text: 'Looker', value: 'looker' },
  power_bi: { text: 'Power BI', value: 'power_bi' },
  sigma: { text: 'Sigma', value: 'sigma' },
  metabase: { text: 'Metabase', value: 'metabase' },
  quicksight: { text: 'QuickSight', value: 'quicksight' },
  mode: { text: 'Mode', value: 'mode' },
  data_studio: { text: 'Looker Studio', value: 'data_studio' },
  thoughtspot: { text: 'ThoughtSpot', value: 'thoughtspot' },
  periscope: { text: 'Sisense', value: 'periscope' },
  hex: { text: 'Hex', value: 'hex' },
} as const;

export const transformerTools = {
  dbt: { text: 'dbt', value: 'dbt' },
  monte_carlo: { text: 'Monte Carlo', value: 'monte_carlo' },
} as const;

export const monitors = {
  monte_carlo: { text: 'Monte Carlo', value: 'monte_carlo' },
} as const;

export const dataSources = [
  'snowflake',
  'bigquery',
  'redshift',
  'postgres',
  'databricks',
  'dbt',
  'mssql',
  'oracle',
  'mysql',
  'salesforce',
  'db2',
  'glue',
  'airflow',
  'tableau',
  'looker',
  'power_bi',
  'sigma',
  'metabase',
  'quicksight',
  'mode',
  'data_studio',
  'thoughtspot',
  'periscope',
  'hex',
  'monte_carlo',
].reduce((acc, key) => {
  return {
    ...acc,
    [key]:
      warehouseSources[key as WarehouseSource] ??
      biSources[key as BISource] ??
      transformerTools[key as TransformerTool] ??
      monitors[key as Monitor],
  };
}, {} as DataSources);
