import React, { memo, useMemo, useState } from 'react';
import {
  autoUpdate,
  FloatingPortal,
  offset as offsetMiddleware,
  safePolygon,
  shift,
  useFloating,
  useHover,
  useInteractions,
} from '@floating-ui/react';
import * as CSS from 'csstype';

import type { LineageEdgeJobResponse } from '@api/jobs/LineageEdgeJobModel.v1';
import mapLineageEdgeJobModel from '@api/jobs/LineageEdgeJobModel.v1';
import Box from '@components/Box';
import DateTime from '@components/DateTime';
import IconButton from '@components/IconButton';
import { REACT_FLOW_CONTAINER_SELECTOR } from '@components/LineageExplore/components/Nodes/config';
import Status from '@components/Status';
import Icon from '@components/UI/Icon';
import useStep from '@utils/step';

import {
  StyledJobEdgeLabelButton,
  StyledJobEdgeLabelCard,
  StyledJobEdgeLabelCardFooter,
  StyledJobEdgeLabelCardLabel,
} from './JobEdgeLabel.styles';

const root = document.querySelector(REACT_FLOW_CONTAINER_SELECTOR) as HTMLElement;

interface JobEdgeLabelProps {
  dynamicStyles: {
    borderWidth: CSS.Property.BorderWidth;
    stroke: CSS.Property.Color;
  };
  jobs: LineageEdgeJobResponse[];
  labelX?: number;
  labelY?: number;
}

const JobEdgeLabel = ({ dynamicStyles, jobs, labelX, labelY }: JobEdgeLabelProps) => {
  const [open, setOpen] = useState(false);
  const { currStep, hasNext, hasPrev, nextStep, prevStep } = useStep<number>({
    steps: Array.from({ length: jobs.length }, (_, i) => i + 1),
  });
  const { context, floatingStyles, refs } = useFloating({
    middleware: [offsetMiddleware({ crossAxis: 0, mainAxis: 8 }), shift()],
    onOpenChange: setOpen,
    open,
    placement: 'bottom',
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(context, {
    delay: { open: 50 },
    handleClose: safePolygon(),
  });

  const { getFloatingProps, getReferenceProps } = useInteractions([hover]);

  const jobModel = useMemo(() => {
    const el = jobs[currStep - 1];
    return mapLineageEdgeJobModel(el);
  }, [currStep, jobs]);

  return (
    <>
      <StyledJobEdgeLabelButton
        ref={refs.setReference}
        isOpen={open}
        {...getReferenceProps()}
        aria-label={`JobEdgeLabelButton ${jobModel.name}`}
        borderColor={dynamicStyles.stroke}
        borderWidth={dynamicStyles.borderWidth}
        onClick={(e) => {
          e.stopPropagation();
        }}
        transform={`translate(-50%, -50%) translate(${labelX}px,${labelY}px)`}
      >
        <Icon color="gray.700" name="airflow" size="12px" />
      </StyledJobEdgeLabelButton>
      {open && (
        <FloatingPortal root={root}>
          <StyledJobEdgeLabelCard
            ref={refs.setFloating}
            aria-label={jobModel.name}
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <StyledJobEdgeLabelCardLabel
              color="gray.700"
              compDisplay="inline-block"
              fontWeight="semibold"
              verticalAlign={undefined}
            >
              <Icon
                color="currentColor"
                compDisplay="inline-block"
                mr={0.5}
                name="job"
                size="16px"
                verticalAlign="middle"
              />
              {jobModel.name}
            </StyledJobEdgeLabelCardLabel>
            <StyledJobEdgeLabelCardLabel>
              <Box>Last Run</Box>
              <DateTime datetime={jobModel.lastRun} format="fullDateFormat" />
            </StyledJobEdgeLabelCardLabel>
            <StyledJobEdgeLabelCardLabel>
              <Box>Status</Box>
              <Status status={jobModel.formattedLastRunStatus}>{jobModel.lastRunStatus}</Status>
            </StyledJobEdgeLabelCardLabel>
            {jobs.length >= 2 && (
              <StyledJobEdgeLabelCardFooter>
                Job {currStep} of {jobs.length}
                <IconButton
                  ariaLabel="Back"
                  disabled={!hasPrev}
                  ml={0.5}
                  onClick={prevStep}
                  variant="clear"
                >
                  <Icon name="left-chevron" size="16px" />
                </IconButton>
                <IconButton ariaLabel="Next" disabled={!hasNext} onClick={nextStep} variant="clear">
                  <Icon name="right-chevron" size="16px" />
                </IconButton>
              </StyledJobEdgeLabelCardFooter>
            )}
          </StyledJobEdgeLabelCard>
        </FloatingPortal>
      )}
    </>
  );
};

const MemoizedEdge = memo(JobEdgeLabel);

export default MemoizedEdge;
