import React from 'react';

import useNewLayout from '@hooks/useNewLayout';

import SearchBar from './SearchBar';
import SearchBarV1 from './SearchBarV1';

const SearchBarHandler = (props: any) => {
  const { shouldUseNewLayout } = useNewLayout();
  if (shouldUseNewLayout) return <SearchBarV1 {...props} />;
  return <SearchBar {...props} />;
};

export default SearchBarHandler;
