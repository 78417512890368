import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import { ObjectType } from '@api/types';

import { BreadcrumbItem } from './Breadcrumbs.types';

interface BreadcrumbsToSimplifiedLabelListParams {
  breadcrumbs?: BreadcrumbModel[];
  label: string;
  pageObjectType?: ObjectType;
  url: string;
}
/**
 * Calculates a breacrumb label list removing repeated information based on the page dataTypes
 * @param breadcrumbs existing breadcrumbs to append to the list
 * @param pageObjectType object type of the page which is rendering the breadcrumbs
 * @param label label for the current item
 * @param url for the current item
 * @returns a list of breadcrumbs removing repeated information based on the page dataTypes
 */
export const breadcrumbsToSimplifiedLabelList = ({
  breadcrumbs,
  label,
  pageObjectType,
  url,
}: BreadcrumbsToSimplifiedLabelListParams): BreadcrumbItem[] => {
  const childParentMap: PartialRecord<ObjectType, ObjectType> = {
    column: 'table',
    schema: 'database',
    table: 'schema',
  };

  const getParents = (type: ObjectType): ObjectType[] => {
    const parents: ObjectType[] = [];

    const recursivelyFindParents = (objectType: ObjectType): void => {
      const parentObjectType = childParentMap[objectType];
      if (parentObjectType) {
        parents.push(parentObjectType);
        getParents(parentObjectType);
      }
    };

    recursivelyFindParents(type);

    return parents;
  };

  let breadcrumbsToParse: BreadcrumbModel[] = breadcrumbs ?? [];

  if (pageObjectType && breadcrumbs) {
    const dataTypesToRemove = [...getParents(pageObjectType), pageObjectType];
    breadcrumbsToParse = breadcrumbs.filter(
      (breadcrumb) => !dataTypesToRemove.includes(breadcrumb?.dataTypes.objectType),
    );
  }

  const breadcrumbList = breadcrumbsToParse.map((item) => ({
    guid: item.guid,
    label: item.label,
    url: item.url,
  }));

  return [
    ...breadcrumbList,
    {
      label,
      url,
    },
  ];
};
