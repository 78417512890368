import React from 'react';

import { DashboardElementModel } from '@api/dashboards/DashboardElementModel';
import Box from '@components/Box';
import { onCopyClick } from '@components/CopyFullPathButton/CopyFullPathButton';
import IconButton from '@components/IconButton';
import { TableItem } from '@components/Table/Cells/LinkedCell';
import { TypedCell } from '@components/Table/Cells/types';
import DeletedLabel from '@components/Table/DeletedLabel/DeletedLabel';
import { CellWithButtons } from '@components/Table/TableStyled/TableStyled';
import Tooltip from '@components/Tooltip';
import DropdownMenu from '@components/UI/DropdownMenu';
import { OptionType } from '@components/UI/DropdownMenu/DropdownMenu';
import Icon from '@components/UI/Icon';
import theme from '@styles/theme';

interface TileNameTableCellProps {
  openTilePage: () => void;
  tile: DashboardElementModel;
  tooltipText?: string;
}

const getMenuItems = (elem: DashboardElementModel) => {
  const menuItems = [];
  if (elem?.fullName) {
    const textToCopy = elem?.fullName?.split('.').join(' / ');
    menuItems.push({
      icon: 'copy',
      key: 'copy',
      label: 'Copy to Clipboard',
      onClick: () => textToCopy && onCopyClick(textToCopy),
    });
  }

  if (elem?.url) {
    menuItems.push({
      icon: 'looker',
      key: 'open',
      label: 'Open in Looker',
      onClick: () => window.open(elem.url, '_blank', 'noopener,noreferrer'),
    });
  }

  return menuItems as OptionType[];
};

const TileNameTableCell: React.FC<TypedCell & TileNameTableCellProps> = ({
  column,
  openTilePage,
  tile,
  tooltipText,
}) => {
  const dataTypeTooltip = tile.externalType || tile.dataType || tile.name || '';

  return (
    <CellWithButtons alignItems="center" compDisplay="flex" flexWrap="wrap" onClick={openTilePage}>
      <Box alignItems="center" compDisplay="flex">
        <Tooltip content={dataTypeTooltip}>
          <Icon mr={0.5} name={tile.dataTypes?.icons.dataType!} size="16px" />
        </Tooltip>
        <TableItem
          filterValue={column.filterValue}
          item={tile}
          name={tile.name}
          showNameTooltip
          tooltipText={tooltipText}
        />
        <DropdownMenu id={tile.guid} options={getMenuItems(tile)}>
          <IconButton
            className="inline-button"
            color={theme.colors.v1.gray[600]}
            iconName="ellipsis"
          />
        </DropdownMenu>
      </Box>
      {tile.deactivationScheduledOn && <DeletedLabel />}
    </CellWithButtons>
  );
};

export default React.memo(TileNameTableCell);
