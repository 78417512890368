import React from 'react';

import Box from '@components/Box';
import Text from '@components/Text';
import theme from '@styles/theme';

interface SidebarMetadataObjectDetailsItemProps {
  label: string;
}

const SidebarMetadataObjectDetailsItem: React.FC<SidebarMetadataObjectDetailsItemProps> = ({
  children,
  label,
}) => {
  return (
    <Box alignItems="center" color={theme.colors.text} compDisplay="flex">
      {label && (
        <Box as="span" flexShrink={0}>
          <Text
            as="span"
            color="gray.500"
            fontSize={theme.typography.fontSizes.body2}
            pr={2}
            whiteSpace="nowrap"
          >
            {label}
          </Text>
        </Box>
      )}
      {children}
    </Box>
  );
};

export default SidebarMetadataObjectDetailsItem;
