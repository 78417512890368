import React from 'react';

import { useFetchMetrics } from '@api/metrics';
import TabError from '@components/TabContent/TabError';
import OwnsTable from '@components/Table/OwnsTable';
import { metricOwnsQuery } from '@models/Owners';
import { ProfileType } from '@pages/ProfilePage/ProfilePage.types';
import { Filter } from '@utils';

import { OwnedItemsSubTabProps } from '../types';

const METRICS_FILTER_OPTIONS: Filter.FilterOptions = {
  query: metricOwnsQuery,
};

const METRICS_SEARCH_CONFIG: { [key: string]: keyof Filter.FilterOptions } = {
  dataObject: 'search_name',
};

const OwnedMetricsSubTab = ({ guid, profileType }: OwnedItemsSubTabProps) => {
  const filterService = Filter.useUpdateFilters(
    METRICS_FILTER_OPTIONS,
    METRICS_SEARCH_CONFIG,
    {},
    '',
  );
  const { filter } = filterService;

  const {
    data: metrics,
    isError,
    isLoading,
  } = useFetchMetrics({
    params: {
      ...Filter.setParams(filter),
      owners: profileType === ProfileType.user ? guid : undefined,
      teams: profileType === ProfileType.team ? guid : undefined,
    },
  });

  if (isError) return <TabError />;

  return (
    <OwnsTable
      data={metrics?.results}
      filterService={filterService}
      itemCount={metrics?.results?.length}
      loading={isLoading}
      stickyHeader
    />
  );
};

export default OwnedMetricsSubTab;
