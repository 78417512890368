import styled from '@emotion/styled';

import TableStyled from '@components/Table/TableStyled';

const AdminRBACPolicyTableStyled = styled(TableStyled)`
  .ui.table tr td {
    &.accessTo {
      padding-top: ${({ theme }) => theme.space(2)};
      padding-bottom: ${({ theme }) => theme.space(2)};
    }
  }
`;

export default AdminRBACPolicyTableStyled;
