import { useFetchTableauTable } from '@api/tableau';
import { useFetchTableEvents } from '@api/tableEvents';
import DataTypesModel from '@models/DataTypesModel';
import stripSpaces from '@utils/stripSpaces';

const tableEventsQuery = stripSpaces(`{
  event_on,
  query_record {
    guid,
    query {
      guid,
      raw_sql
    },
    executed_on,
    duration,
    external_id,
    rows_produced,
    dsuser {
      guid,
      name,
      display_name,
      data_source,
      is_active,
      user {
        guid,
        first_name,
        last_name,
        avatar,
        is_active
      }
    }
  }
}`);

const tableauTableQuery = '{query}';

interface UseFetchTableSqlParams {
  dataTypes?: DataTypesModel;
  enabled?: boolean;
  guid: string;
}

const useFetchTableSql = ({ dataTypes, enabled, guid }: UseFetchTableSqlParams) => {
  const isTableauCustomSql = dataTypes?.dataType === 'custom-sql';
  const isStandardTable = dataTypes?.dataType === 'table';

  const {
    data: tableData,
    isError: isTableEventsError,
    isLoading: isLoadingTableEvents,
  } = useFetchTableEvents({
    enabled: enabled && isStandardTable,
    params: {
      order: '-event_on',
      page: 1,
      page_size: 10,
      query: tableEventsQuery,
      tables: guid,
    },
  });

  const {
    data: tableauCustomSqlData,
    isError: isTableauCustomSqlError,
    isLoading: isLoadingTableauCustomSql,
  } = useFetchTableauTable(guid, {
    enabled: enabled && isTableauCustomSql,
    params: {
      query: tableauTableQuery,
    },
  });

  const queryLogRecords = tableData?.results.map((item) => item.queryRecord);
  const isError = isTableEventsError || isTableauCustomSqlError;
  const isLoading = isLoadingTableEvents || isLoadingTableauCustomSql;
  const { query } = tableauCustomSqlData ?? {};

  return { isError, isLoading, query, queryLogRecords };
};

export default useFetchTableSql;
