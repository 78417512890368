import React from 'react';

import { ActivityModel } from '@api/activity/ActivityModel';
import { activityTypeToUserAction } from '@api/activity/utils';
import Box from '@components/Box';
import Highlighter from '@components/Highlighter';
import { TypedCell } from '@components/Table/Cells/types';
import Text from '@components/Text';
import { StyledTextProps } from '@components/Text/Text';
import { MakeSpaceProps } from '@styles/mixins/makeSpace';
import theme from '@styles/theme';

const textDefaultProps: StyledTextProps & MakeSpaceProps = {
  fontSize: theme.typography.fontSizes.body2,
  m: 0,
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
};

const ActivityCell: React.FC<Partial<TypedCell>> = ({ globalFilter, row }) => {
  const activity = row?.original as ActivityModel;

  return (
    <Box alignItems="center" compDisplay="flex" flexWrap="wrap" gap={0.5}>
      <Text fontWeight="bold" {...textDefaultProps}>
        <Highlighter
          searchWords={globalFilter?.split(/[.| ]+/) || []}
          textToHighlight={activity?.user?.fullName || ''}
        />
      </Text>
      <Text {...textDefaultProps}>
        <Highlighter
          searchWords={globalFilter?.split(/[.| ]+/) || []}
          textToHighlight={activityTypeToUserAction(activity.activityType)}
        />
      </Text>
      <Text fontWeight="bold" {...textDefaultProps}>
        <Highlighter
          searchWords={globalFilter?.split(/[.| ]+/) || []}
          textToHighlight={activity.cachedTargetName}
        />
      </Text>
    </Box>
  );
};

export default ActivityCell;
