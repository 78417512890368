import { DashboardModel } from '@api/dashboards/DashboardModel';
import paginatedTransform from '@api/paginatedTransform';
import rawTransform from '@api/rawTransform';
import type { UseFetchOptions } from '@hooks/useFetch';
import useFetch from '@hooks/useFetch';
import type { UseMutationOptions } from '@hooks/useMutation';
import useMutation from '@hooks/useMutation';
import { FrequentDSUserModel } from '@models/FrequentDSUserModel';
import type { OwnersUpdateData } from '@models/Owners';
import { PaginatedResponse } from '@models/PaginatedResponse';
import type { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';

import * as cacheKeys from './cacheKeys';
import { DashboardElementModel } from './DashboardElementModel';
import { DashboardPaginatedResponse } from './DashboardPaginatedResponse';

export const fetchDashboardSelect = rawTransform(DashboardModel);
export const useFetchDashboard = (id: string, options?: UseFetchOptions<DashboardModel>) => {
  return useFetch<DashboardModel>({
    ...options,
    queryKey: cacheKeys.dashboard(id),
    select: fetchDashboardSelect,
    url: `/bi/dashboards/${id}/`,
  });
};

const fetchDashboardElementsSelect = paginatedTransform(DashboardElementModel);
export const useFetchDashboardElements = (
  id: string,
  options?: UseFetchOptions<PaginatedResponse<DashboardElementModel>>,
) => {
  return useFetch<PaginatedResponse<DashboardElementModel>>({
    ...options,
    queryKey: cacheKeys.elements(id),
    select: fetchDashboardElementsSelect,
    url: `/bi/dashboards/${id}/elements/`,
  });
};

export const fetchDashboardsSelect = paginatedTransform(DashboardModel);
export const useFetchDashboards = (
  options?: UseFetchOptions<DashboardPaginatedResponse<DashboardModel>>,
) => {
  return useFetch<DashboardPaginatedResponse<DashboardModel>>({
    ...options,
    queryKey: [...cacheKeys.all, options?.params],
    select: fetchDashboardsSelect,
    url: `/bi/dashboards/`,
  });
};

const fetchDashboardFrequentDSUsersSelect = paginatedTransform(FrequentDSUserModel);
export const useFetchDashboardFrequentDSUsers = (id: string, options?: UseFetchOptions) => {
  return useFetch<PaginatedResponse<FrequentDSUserModel>>({
    ...options,
    queryKey: [...cacheKeys.dashboardsFrequentDsUsers(id), options?.params],
    select: fetchDashboardFrequentDSUsersSelect,
    url: `bi/dashboards/${id}/frequent-dsusers/`,
  });
};

export const usePatchDashboardsOwners = (
  options?: UseMutationOptions<never, EnhancedErrorResult, OwnersUpdateData>,
) => {
  return useMutation({
    ...options,
    method: 'PATCH',
    url: `/bi/dashboards/owners/`,
  });
};
