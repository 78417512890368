import { Expose, Transform, Type } from 'class-transformer';
import moment from 'moment';

import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import type { DescriptionSource } from '@api/description/description.types';
import { DsUserModel } from '@api/dsusers/DsUserModel';
import { breadcrumbsToLabelList, breadcrumbsToList } from '@components/Breadcrumbs';
import isEmptyRichText from '@components/RichTextEditor/helpers/isEmptyRichText';
import DataTypesModel from '@models/DataTypesModel';
import { PopularityModel } from '@models/PopularityModel';
import RelatedObjectsCountsModel from '@models/RelatedObjectsCountsModel';
import { urlFor } from '@utils/routing';

const objectType = 'thoughtspottable' as const;

class BiTableModel {
  static objectType = objectType;

  objectType = objectType;

  @Type(() => BreadcrumbModel)
  breadcrumbs?: BreadcrumbModel[];

  @Expose({ name: 'data_types' })
  @Type(() => DataTypesModel)
  dataTypes?: DataTypesModel = new DataTypesModel();

  @Expose({ name: 'datasource_guid' })
  dataSourceGuid?: string;

  @Expose({ name: 'ai_description' })
  aiDescription?: string;

  description?: string;

  @Expose({ name: 'description_source' })
  descriptionSource?: DescriptionSource;

  @Expose({ name: 'ingested_description' })
  ingestedDescription?: string;

  // TODO: Remove transform https://app.shortcut.com/select-star/story/55620
  @Expose({ name: 'richtext_description' })
  @Transform((richtextDescription) =>
    isEmptyRichText(richtextDescription) ? '' : richtextDescription,
  )
  richtextDescription?: string;

  @Expose({ name: 'user_description' })
  userDescription?: string;

  showDescriptionSelector = true;

  @Expose({ name: 'downstream_objects_counts' })
  @Type(() => RelatedObjectsCountsModel)
  downstreamObjectsCounts?: RelatedObjectsCountsModel;

  @Expose({ name: 'dsuser_created_by' })
  @Type(() => DsUserModel)
  dsuserCreatedBy?: DsUserModel;

  @Expose({ name: 'external_id' })
  externalId!: string;

  guid!: string;

  @Expose({ name: 'last_updated' })
  @Transform((value) => (value ? moment(value) : value))
  lastUpdated?: moment.Moment;

  name!: string;

  @Type(() => PopularityModel)
  popularity?: PopularityModel;

  @Expose({ name: 'raw_sql' })
  rawSql?: string;

  @Expose({ name: 'row_count' })
  rowCount?: number;

  @Expose({ name: 'source_tables_count' })
  sourceTablesCount?: number;

  @Expose({ name: 'table_created_at' })
  @Transform((value) => (value ? moment(value) : value))
  tableCreatedAt?: moment.Moment;

  @Expose({ name: 'table_updated_at' })
  @Transform((value) => (value ? moment(value) : value))
  tableUpdatedAt?: moment.Moment;

  @Expose({ name: 'search_name' })
  searchName?: string;

  @Expose({ name: 'upstream_objects_counts' })
  @Type(() => RelatedObjectsCountsModel)
  upstreamObjectsCounts?: RelatedObjectsCountsModel;

  get breadcrumbList() {
    return breadcrumbsToList(this.breadcrumbs);
  }

  get routePath() {
    return urlFor({
      dataSourceGuid: this.dataSourceGuid,
      dataTypes: this.dataTypes,
      guid: this.guid,
      objectType: this.objectType,
    });
  }

  get breadcrumbLabelList() {
    return breadcrumbsToLabelList(this.name, this.routePath, this.breadcrumbs);
  }

  get updatedAt() {
    return this.tableUpdatedAt;
  }

  showMentionedBy = true;

  showEditTags = true;

  showCustomAttributes = true;
}

export default BiTableModel;
