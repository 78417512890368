import React, { useEffect } from 'react';

import { HierarchyState } from '@models/HierarchyState';

import Tree from '../Tree';

import useAdminHierarchy from './useAdminHierarchy';

interface AdminSidebarProps {
  adminState: HierarchyState;
}

const AdminSidebar: React.FC<AdminSidebarProps> = ({ adminState }) => {
  const adminHierarchy = useAdminHierarchy();

  useEffect(() => {
    adminState.updateHierarchy(adminHierarchy);
  }, [adminState, adminHierarchy]);

  if (adminHierarchy.length === 0) return null;

  return (
    <>
      <div className="left-nav-title2">Admin</div>
      <div className="left-nav-list2">
        <Tree data={adminHierarchy} state={adminState} />
      </div>
    </>
  );
};

export default React.memo(AdminSidebar);
