import React from 'react';

import Button from '@components/Button/Button';
import { useModal } from '@context/Modal';
import useNewLayout from '@hooks/useNewLayout';
import theme from '@styles/theme';

const CreateTagButton: React.FC = () => {
  const { MODAL_IDS, openModal } = useModal();
  const { shouldUseNewLayout } = useNewLayout();

  const handleModalOpen = () => openModal(MODAL_IDS.createTag, undefined);

  return (
    <Button
      compHeight={theme.space(4.5)}
      compSize="lg"
      compWidth="100%"
      justifyContent={shouldUseNewLayout ? 'center' : 'flex-start'}
      onClick={handleModalOpen}
      pl={shouldUseNewLayout ? 1.25 : 1.5}
      spacing={shouldUseNewLayout ? 0.5 : undefined}
      startIconName="plus"
      startIconSize={shouldUseNewLayout ? '16px' : '20px'}
      textAlign="left"
      variant="outlined"
    >
      {shouldUseNewLayout ? 'New Tag' : 'Create a New Tag'}
    </Button>
  );
};

export default CreateTagButton;
