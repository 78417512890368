import React, { forwardRef } from 'react';

import type { IconProps } from '@components/UI/Icon';
import Icon from '@components/UI/Icon';

import type { StyledButtonIconWrapperProps, StyledButtonProps } from './Button.styles';
import StyledButton, { StyledButtonIconWrapper } from './Button.styles';

type IconType = 'startIcon' | 'endIcon';
type IconName = Record<`${IconType}Name`, IconProps['name']>;
type IconSize = Record<`${IconType}Size`, IconProps['size']>;
type IconAttr = IconName & IconSize;

export interface ButtonProps
  extends Partial<IconAttr>,
    StyledButtonProps,
    StyledButtonIconWrapperProps {
  endIcon?: React.ReactNode;
  spacing?: number;
  startIcon?: React.ReactNode;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      endIcon = null,
      endIconName,
      endIconSize,
      href,
      iconCurrentColor,
      spacing = 1,
      startIcon = null,
      startIconName,
      startIconSize,
      variant = 'default',
      ...other
    },
    ref,
  ) => {
    const withIconsChildren = (
      <>
        {Boolean(startIcon || startIconName) && (
          <StyledButtonIconWrapper iconCurrentColor={iconCurrentColor} mr={spacing}>
            {startIcon}
            {startIconName && <Icon name={startIconName} size={startIconSize} />}
          </StyledButtonIconWrapper>
        )}
        {children}
        {Boolean(endIcon || endIconName) && (
          <StyledButtonIconWrapper iconCurrentColor={iconCurrentColor} ml={spacing}>
            {endIcon}
            {endIconName && <Icon name={endIconName} size={endIconSize} />}
          </StyledButtonIconWrapper>
        )}
      </>
    );

    return (
      <StyledButton as={href ? 'a' : undefined} href={href} variant={variant} {...other} ref={ref}>
        {withIconsChildren}
      </StyledButton>
    );
  },
);

export default Button;
