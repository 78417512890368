import { Type } from 'class-transformer';

import { SchemaModel } from '@api/schema/SchemaModel';
import { TableModel } from '@api/tables/TableModel';

class SourceTablesSchemaTablesModel {
  @Type(() => SchemaModel)
  schema!: SchemaModel;

  @Type(() => TableModel)
  tables!: TableModel[];
}

class SourceTablesModel {
  @Type(() => SourceTablesSchemaTablesModel)
  upstream!: SourceTablesSchemaTablesModel[];
}

export default SourceTablesModel;
