import { fetchReportsNewestItemsSelect } from '@api/reports/reports';

export const fetchReportsNewestItemsMock = fetchReportsNewestItemsSelect([
  {
    data_types: {
      data_source_type: 'tableau',
      data_type: 'workbook',
      object_type: 'dashboard',
    },
    description: null,
    description_source: null,
    guid: 'da_WJHcFgopn4fQistb75WvXn',
    ai_description: null,
    ingested_description: '',
    data_type: 'workbook',
    name: 'Select Star Admin Insights (1)',
    data_source_type: 'tableau',
    suggested_description: null,
    external_type: null,
    object_type: 'dashboard',
    breadcrumbs: [
      {
        guid: 'br_4oRKRzbyEZdFyFwdcfpuK9',
        target_data_type: 'tableau',
        target_data_source_guid: 'ds_Vtc8y2aTUWyKbpK9EfNb95',
        target_guid: 'ds_Vtc8y2aTUWyKbpK9EfNb95.projects_container',
        ordinal: 0,
        target_name: 'Projects',
        target_data_source_type: 'tableau',
        target_object_type: 'projects_container',
      },
      {
        guid: 'br_WSBywhLXaaCxydMiVi63uq',
        target_data_type: 'project',
        target_data_source_guid: 'ds_Vtc8y2aTUWyKbpK9EfNb95',
        target_guid: 'fl_TbK5DRzQJtAvMqJA7tymfT',
        ordinal: 1,
        target_name: 'Admin Insights',
        target_data_source_type: 'tableau',
        target_object_type: 'bifolder',
      },
    ],
    parent_guid: 'fl_TbK5DRzQJtAvMqJA7tymfT',
    short_name: null,
    downstream_objects_counts: {
      chart: 0,
      column: 0,
      dashboard: 0,
      dashboardelement: 0,
      explore: 0,
      explorefield: 0,
      lookmlfield: 0,
      lookmlview: 0,
      metabasecolumn: 0,
      metabasetable: 0,
      powerbicolumn: 0,
      powerbitable: 0,
      reportquery: 0,
      table: 0,
      tableaucolumn: 0,
      tableaudatasource: 0,
      source_table: 0,
      tableaufield: 0,
      tableautable: 0,
      tableauview: 0,
      total: 0,
    },
    popularity: {
      popularity: 77,
      service_query_count: 0,
      timeframe: 90,
      user_count: 3,
      view_count: 19,
    },
    user_description: null,
    deactivation_scheduled_on: null,
    raw_popularity: null,
    search_name: 'Admin Insights.Select Star Admin Insights (1)',
    richtext_description: null,
    tagged_items: [],
    upstream_objects_counts: {
      chart: 0,
      column: 0,
      dashboard: 0,
      dashboardelement: 0,
      explore: 0,
      explorefield: 0,
      lookmlfield: 0,
      lookmlview: 0,
      metabasecolumn: 0,
      metabasetable: 0,
      powerbicolumn: 0,
      powerbitable: 0,
      reportquery: 0,
      source_table: 0,
      table: 0,
      tableaucolumn: 0,
      tableaudatasource: 3,
      tableaufield: 0,
      tableautable: 0,
      tableauview: 0,
      total: 3,
    },
    url: 'https://10ay.online.tableau.com/#/site/selectstartest/workbooks/1289463',
  },
  {
    data_types: {
      data_source_type: 'tableau',
      data_type: 'workbook',
      object_type: 'dashboard',
    },
    description: null,
    description_source: null,
    guid: 'da_7EKs3RYr5AKmwmzC7rVEXZ',
    ai_description: null,
    ingested_description: '',
    data_type: 'workbook',
    name: 'Select Star Admin Insights',
    data_source_type: 'tableau',
    suggested_description: null,
    external_type: null,
    object_type: 'dashboard',
    breadcrumbs: [
      {
        guid: 'br_2nmBSqszqTzo7WNmoSx2qJ',
        target_data_type: 'tableau',
        target_data_source_guid: 'ds_Vtc8y2aTUWyKbpK9EfNb95',
        target_guid: 'ds_Vtc8y2aTUWyKbpK9EfNb95.projects_container',
        ordinal: 0,
        target_name: 'Projects',
        target_data_source_type: 'tableau',
        target_object_type: 'projects_container',
      },
      {
        guid: 'br_6i42UHaKu8nHi6mvFMrUFe',
        target_data_type: 'project',
        target_data_source_guid: 'ds_Vtc8y2aTUWyKbpK9EfNb95',
        target_guid: 'fl_UBpfe23CvC5s2BWyDJwNRq',
        ordinal: 1,
        target_name: 'default',
        target_data_source_type: 'tableau',
        target_object_type: 'bifolder',
      },
    ],
    parent_guid: 'fl_UBpfe23CvC5s2BWyDJwNRq',
    short_name: null,
    downstream_objects_counts: {
      chart: 0,
      column: 0,
      dashboard: 0,
      dashboardelement: 0,
      explore: 0,
      explorefield: 0,
      lookmlfield: 0,
      lookmlview: 0,
      metabasecolumn: 0,
      metabasetable: 0,
      powerbicolumn: 0,
      powerbitable: 0,
      reportquery: 0,
      table: 0,
      tableaucolumn: 0,
      tableaudatasource: 0,
      source_table: 0,
      tableaufield: 0,
      tableautable: 0,
      tableauview: 0,
      total: 0,
    },
    popularity: {
      popularity: 32,
      service_query_count: 0,
      timeframe: 90,
      user_count: 1,
      view_count: 1,
    },
    user_description: null,
    deactivation_scheduled_on: null,
    raw_popularity: null,
    search_name: 'default.Select Star Admin Insights',
    richtext_description: null,
    tagged_items: [],
    upstream_objects_counts: {
      chart: 0,
      column: 0,
      dashboard: 0,
      dashboardelement: 0,
      explore: 0,
      explorefield: 0,
      lookmlfield: 0,
      lookmlview: 0,
      metabasecolumn: 0,
      metabasetable: 0,
      powerbicolumn: 0,
      powerbitable: 0,
      reportquery: 0,
      source_table: 0,
      table: 0,
      tableaucolumn: 0,
      tableaudatasource: 3,
      tableaufield: 0,
      tableautable: 0,
      tableauview: 0,
      total: 3,
    },
    url: 'https://10ay.online.tableau.com/#/site/selectstartest/workbooks/1034484',
  },
  {
    data_types: {
      data_source_type: 'tableau',
      data_type: 'workbook',
      object_type: 'dashboard',
    },
    description: null,
    description_source: null,
    guid: 'da_PmdAatEb2ZAovjn3EMNLq8',
    ai_description: null,
    ingested_description: '',
    data_type: 'workbook',
    name: 'BQ - Olist Dashboards - Part 2',
    data_source_type: 'tableau',
    suggested_description: null,
    external_type: null,
    object_type: 'dashboard',
    breadcrumbs: [
      {
        guid: 'br_eKocqjxJ7JiNxSNEcNXFMf',
        target_data_type: 'tableau',
        target_data_source_guid: 'ds_Vtc8y2aTUWyKbpK9EfNb95',
        target_guid: 'ds_Vtc8y2aTUWyKbpK9EfNb95.projects_container',
        ordinal: 0,
        target_name: 'Projects',
        target_data_source_type: 'tableau',
        target_object_type: 'projects_container',
      },
      {
        guid: 'br_2WGuv78FuRr2tRYpZRhozg',
        target_data_type: 'project',
        target_data_source_guid: 'ds_Vtc8y2aTUWyKbpK9EfNb95',
        target_guid: 'fl_Gp4fc7v5acmWJ97yW2e2dS',
        ordinal: 1,
        target_name: 'Olist',
        target_data_source_type: 'tableau',
        target_object_type: 'bifolder',
      },
      {
        guid: 'br_nToMSUQwDaXgYbSPjvJ8dL',
        target_data_type: 'project',
        target_data_source_guid: 'ds_Vtc8y2aTUWyKbpK9EfNb95',
        target_guid: 'fl_JTnWgb33CcJQrZNwN9RDJd',
        ordinal: 2,
        target_name: 'BQ - Olist Dashboards',
        target_data_source_type: 'tableau',
        target_object_type: 'bifolder',
      },
    ],
    parent_guid: 'fl_JTnWgb33CcJQrZNwN9RDJd',
    short_name: null,
    downstream_objects_counts: {
      chart: 0,
      column: 0,
      dashboard: 0,
      dashboardelement: 0,
      explore: 0,
      explorefield: 0,
      lookmlfield: 0,
      lookmlview: 0,
      metabasecolumn: 0,
      metabasetable: 0,
      powerbicolumn: 0,
      powerbitable: 0,
      reportquery: 0,
      table: 0,
      tableaucolumn: 0,
      tableaudatasource: 0,
      source_table: 0,
      tableaufield: 0,
      tableautable: 0,
      tableauview: 0,
      total: 0,
    },
    popularity: null,
    user_description: null,
    deactivation_scheduled_on: null,
    raw_popularity: null,
    search_name: 'Olist.BQ - Olist Dashboards.BQ - Olist Dashboards - Part 2',
    richtext_description: null,
    tagged_items: [],
    upstream_objects_counts: {
      chart: 0,
      column: 0,
      dashboard: 0,
      dashboardelement: 0,
      explore: 0,
      explorefield: 0,
      lookmlfield: 0,
      lookmlview: 0,
      metabasecolumn: 0,
      metabasetable: 0,
      powerbicolumn: 0,
      powerbitable: 0,
      reportquery: 0,
      source_table: 6,
      table: 0,
      tableaucolumn: 0,
      tableaudatasource: 5,
      tableaufield: 0,
      tableautable: 0,
      tableauview: 0,
      total: 5,
    },
    url: 'https://10ay.online.tableau.com/#/site/selectstartest/workbooks/1018865',
  },
  {
    data_types: {
      data_source_type: 'tableau',
      data_type: 'workbook',
      object_type: 'dashboard',
    },
    description: null,
    description_source: null,
    guid: 'da_Mgc57828zjFjBXbtmjjQhn',
    ai_description: null,
    ingested_description: '',
    data_type: 'workbook',
    name: 'Olist Dashboards',
    data_source_type: 'tableau',
    suggested_description: null,
    external_type: null,
    object_type: 'dashboard',
    breadcrumbs: [
      {
        guid: 'br_ZVYJRoGAAWt3rXbFP3XK9B',
        target_data_type: 'tableau',
        target_data_source_guid: 'ds_Vtc8y2aTUWyKbpK9EfNb95',
        target_guid: 'ds_Vtc8y2aTUWyKbpK9EfNb95.projects_container',
        ordinal: 0,
        target_name: 'Projects',
        target_data_source_type: 'tableau',
        target_object_type: 'projects_container',
      },
      {
        guid: 'br_cQQz4HJk6aeoWbeFcHBHXc',
        target_data_type: 'project',
        target_data_source_guid: 'ds_Vtc8y2aTUWyKbpK9EfNb95',
        target_guid: 'fl_iS6pwHZQbNEbknFh6NNMd9',
        ordinal: 1,
        target_name: 'Backup',
        target_data_source_type: 'tableau',
        target_object_type: 'bifolder',
      },
    ],
    parent_guid: 'fl_iS6pwHZQbNEbknFh6NNMd9',
    short_name: null,
    downstream_objects_counts: {
      chart: 0,
      column: 0,
      dashboard: 0,
      dashboardelement: 0,
      explore: 0,
      explorefield: 0,
      lookmlfield: 0,
      lookmlview: 0,
      metabasecolumn: 0,
      metabasetable: 0,
      powerbicolumn: 0,
      powerbitable: 0,
      reportquery: 0,
      table: 0,
      tableaucolumn: 0,
      tableaudatasource: 0,
      source_table: 0,
      tableaufield: 0,
      tableautable: 0,
      tableauview: 0,
      total: 0,
    },
    popularity: null,
    user_description: null,
    deactivation_scheduled_on: null,
    raw_popularity: null,
    search_name: 'Backup.Olist Dashboards',
    richtext_description: null,
    tagged_items: [],
    upstream_objects_counts: {
      chart: 0,
      column: 0,
      dashboard: 0,
      dashboardelement: 0,
      explore: 0,
      explorefield: 0,
      lookmlfield: 0,
      lookmlview: 0,
      metabasecolumn: 0,
      metabasetable: 0,
      powerbicolumn: 0,
      powerbitable: 0,
      reportquery: 0,
      source_table: 9,
      table: 0,
      tableaucolumn: 0,
      tableaudatasource: 16,
      tableaufield: 0,
      tableautable: 0,
      tableauview: 0,
      total: 16,
    },
    url: 'https://10ay.online.tableau.com/#/site/selectstartest/workbooks/825155',
  },
]);
