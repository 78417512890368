import React, { useLayoutEffect, useRef } from 'react';
import * as d3 from 'd3';

interface UseInitZoomProps {
  id: string;

  svgRootRef: React.RefObject<SVGSVGElement>;
}

/**
 * D3 rendering block
 * All D3 rendering are implemented with `useLayoutEffect` that happens in sync
 * preventing potential rendering of tables/columns background and position to go
 * out of sync from arrows
 */
const useInitZoom = ({ id, svgRootRef }: UseInitZoomProps) => {
  const zoom = useRef<{ lastZoom: string; zoom: d3.ZoomBehavior<any, any> }>({} as any);

  useLayoutEffect(() => {
    const svg = d3.select(svgRootRef.current);
    const newZoom = d3.zoom().on('zoom', (e) => {
      svg.select(`#${id}`).attr('transform', e.transform);
    });
    svg.call(newZoom as any);
    zoom.current.zoom = newZoom; // store for sidebar events use
  }, [id, svgRootRef]);

  return zoom;
};

export default useInitZoom;
