import React from 'react';

import { useFetchIndexes } from '@api/indexes';
import Box from '@components/Box';
import IndexTable from '@components/Table/IndexTable';
import Text from '@components/Text';
import Card, { CardProps } from '@components/UI/Card';
import theme from '@styles/theme';
import stripSpaces from '@utils/stripSpaces';

const query = stripSpaces(`{
    definition,
    guid,
    name,
    conditions{
      column{
        guid,
        data_types,
        name,
        breadcrumbs,
        is_primary_key,
        is_foreign_key,
        is_unique,
        is_nullable,
        parent_guid,
      }
    }
}`);

interface IndexesSectionProps {
  cardProps?: CardProps;
  guid: string;
}

const IndexesSection: React.FC<IndexesSectionProps> = ({ cardProps, guid }) => {
  const { data, isLoading } = useFetchIndexes({
    params: { query, tables: guid },
  });

  return (
    <Card px={3.5} py={2.5} {...cardProps}>
      <Box alignItems="flex-start" compDisplay="flex" justifyContent="flex-start" mb={1}>
        <Text
          as="h3"
          color="gray.700"
          fontSize={theme.typography.h4.fontSize}
          fontWeight="medium"
          m={0}
        >
          Indexes
        </Text>
      </Box>
      <IndexTable data={data} isLoading={isLoading} />
    </Card>
  );
};

export default IndexesSection;
