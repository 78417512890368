import { Expose, Type } from 'class-transformer';

import { UserModel } from '@api/user/UserModel';
import { OrganizationUserRole } from '@models/OrganizationUserRole';

import { AccessToModel } from './accessToModel';
import { AssignedToModel } from './assignedToModel';
import { ExceptForModel } from './ExceptForModel';
/**
 * RBAC: Role based access control is the
 * policy we use to filter out data to be shown in
 * the FE. This is saved in the BE as a model
 * with the same name
 */

export enum PolicyStatusType {
  active = 'active',
  inactive = 'inactive',
}

const defaultPolicyNames = [
  'Default Admin Role',
  'Default Data Manager Role',
  'Default Viewer Role',
];
export class RBACPolicyModel {
  static objectType: string = 'rbacpolicy';

  objectType: string = RBACPolicyModel.objectType;

  static typeDisplay: string = 'RBACPolicy';

  typeDisplay: string = RBACPolicyModel.typeDisplay;

  guid!: string;

  name!: string;

  @Expose({ name: 'created_by' })
  @Type(() => UserModel)
  createdBy!: UserModel;

  @Expose({ name: 'assigned_to' })
  @Type(() => AssignedToModel)
  assignedTo: AssignedToModel[] = [];

  @Expose({ name: 'access_to' })
  @Type(() => AccessToModel)
  accessTo: AccessToModel[] = [];

  @Expose({ name: 'except_for' })
  @Type(() => ExceptForModel)
  exceptFor: ExceptForModel[] = [];

  status!: PolicyStatusType;

  role!: OrganizationUserRole;

  get isDefaultPolicy() {
    return defaultPolicyNames.includes(this.name);
  }
}
