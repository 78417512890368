import styled from '@emotion/styled';

interface StyledToastContainerProps {
  fixed?: boolean;
}

export const StyledToastContainer = styled.div<StyledToastContainerProps>`
  &.toast-container {
    display: flex;
    position: ${(props) => (props.fixed ? 'fixed' : 'absolute')};
    top: 1rem;
    width: 100vw;
    justify-content: center;
    z-index: ${({ theme }) => theme.zIndex.toast};
    pointer-events: none;

    .message {
      z-index: 100;
      box-shadow: none;
      padding: 0.75rem 2rem 0.75rem 1.5rem;
      border-radius: 0.375rem;

      > .icon {
        margin-right: 0.5rem;

        &.close {
          display: none;
          position: absolute;
        }

        &:not(.close) {
          font-size: 0.75rem;
          line-height: 0.625rem;
        }
      }

      > .content {
        line-height: 1.25rem;
      }

      .header {
        font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
        font-size: 0.9375rem;
        font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
        color: #44464a;

        + p:empty {
          margin-top: 0;
        }
      }

      &.info {
        color: #44464a;
        background-color: #e2e9f0;

        .header {
          color: #44464a;
        }
      }
    }
  }
`;
