import React from 'react';
import { LOADING_TEXT } from '@constants';

import { useFetchQuery } from '@api/queries';
import { QueryModel } from '@api/queries/QueryModel';
import ItemLink from '@components/ItemLink';
import { urlFor } from '@utils/routing';
import stripSpaces from '@utils/stripSpaces';

import {
  SectionInfo,
  SectionTitle,
  SidebarSection,
  Subsection,
  SubsectionNested,
} from './SidebarStyled';

interface Props {
  query: QueryModel;
}

const q = stripSpaces(`{
  guid,
  tables{
    guid,
    name,
    data_types,
    schema{
      guid,
      name
    }
  },
  columns{
    guid,
    name,
    data_types,
    table{
      guid,
      name
    },
    field_type,
    external_type
  },
  joins{
    guid,
    source_table{
      guid,
      name
    },
    joined_table{
      guid,
      name
    }
    conditions{
      raw
    }
  },
}`);

const TablesQueriedSection: React.FC<Props> = ({ query }) => {
  const { data: tablesQueried, isLoading } = useFetchQuery(query.guid, {
    params: {
      query: q,
    },
  });

  return (
    <SidebarSection grow>
      <SectionTitle>Tables queried:</SectionTitle>
      {isLoading ? (
        <Subsection>
          <SectionInfo>{LOADING_TEXT}</SectionInfo>
        </Subsection>
      ) : (
        <>
          {tablesQueried && tablesQueried.tables!.length === 0 && (
            <Subsection>
              <SectionInfo>No data.</SectionInfo>
            </Subsection>
          )}
          {tablesQueried &&
            tablesQueried.tables!.map((table) => (
              <Subsection key={table.guid} flexDirection="column" gutterBottom>
                <ItemLink
                  block
                  icon={table.dataTypes?.icons.dataType!}
                  linkUrl={urlFor(table, true)}
                  text={`${table.schema.name}.${table.name}`}
                />
                <SubsectionNested flexDirection="column">
                  {tablesQueried
                    .columns!.filter((c) => c.table!.guid === table.guid)
                    .map((column) => (
                      <ItemLink
                        key={column.guid}
                        block
                        icon={column.dataTypes?.icons.objectType!}
                        text={column.name}
                      />
                    ))}
                </SubsectionNested>
                <SubsectionNested flexDirection="column">
                  {tablesQueried
                    .joins!.filter(
                      (j) =>
                        j.sourceTable!.guid === table.guid || j.joinedTable!.guid === table.guid,
                    )
                    .map((join) =>
                      join.conditions.map((joinCondition) => (
                        <ItemLink
                          key={joinCondition.raw}
                          block
                          icon="join"
                          iconTooltip={join.typeDisplay}
                          text={joinCondition.raw}
                        />
                      )),
                    )}
                </SubsectionNested>
              </Subsection>
            ))}
        </>
      )}
    </SidebarSection>
  );
};

export default TablesQueriedSection;
