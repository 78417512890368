import React from 'react';
import { RenderElementProps } from 'slate-react';

import Mention from '@components/Mention';

const MentionElement = ({ attributes, children, element }: RenderElementProps) => {
  const { mention } = element;
  const { dataTypes, guid, name, parentGuid } = mention;

  return (
    <span {...attributes} contentEditable={false}>
      <Mention dataTypes={dataTypes} guid={guid} name={name} parentGuid={parentGuid}>
        <span>{mention?.name}</span>
        {children}
      </Mention>
    </span>
  );
};

export default MentionElement;
