import React from 'react';

import Link from '@components/Link';

import { StyledTermsOfService } from './TermsOfService.styles';

interface TermsOfServiceProps {
  isLogin?: boolean;
}

const TermsOfService: React.FC<TermsOfServiceProps> = ({ isLogin }) => {
  return (
    <StyledTermsOfService isLogin={isLogin}>
      {isLogin ? 'By logging in, you' : 'I'}
      &nbsp;agree to the&nbsp;
      <Link
        fontSize="inherit"
        href="https://selectstar.com/terms-of-service"
        rel="noopener noreferrer"
        target="_blank"
        underline
      >
        Terms of Service
      </Link>
      &nbsp;and&nbsp;
      <Link
        fontSize="inherit"
        href="https://selectstar.com/privacy"
        rel="noopener noreferrer"
        target="_blank"
        underline
      >
        Privacy Policy
      </Link>
      <span>&nbsp;</span>
      of Select Star&nbsp;
    </StyledTermsOfService>
  );
};

export default TermsOfService;
