import React from 'react';

import { LookerExploreModel } from '@api/explores/LookerExploreModel';
import Box from '@components/Box';
import Mention from '@components/Mention';
import Text from '@components/Text';
import theme from '@styles/theme';

interface LookerViewLinksToProps {
  linksToObjects?: LookerExploreModel[];
}

const LookerViewLinksTo: React.FC<LookerViewLinksToProps> = ({ linksToObjects }) => {
  if (linksToObjects?.length === 0) {
    return (
      <Text color="gray.400" fontSize={theme.typography.fontSizes.body2}>
        Link not found
      </Text>
    );
  }

  return (
    <Box compDisplay="flex" flexWrap="wrap" gap={0.25} overflow="hidden">
      {linksToObjects?.map(({ dataTypes, guid, name }) => (
        <Mention key={guid} dataTypes={dataTypes} guid={guid} name={name} />
      ))}
    </Box>
  );
};

export default LookerViewLinksTo;
