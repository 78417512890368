import React from 'react';

import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader/CircularLoader';
import Text from '@components/Text';
import { useWizard } from '@components/Wizard/Wizard/useWizard';
import theme from '@styles/theme';

const GithubFinishingStep = () => {
  useWizard({
    backButtonState: 'hidden',
  });

  return (
    <Box
      alignItems="center"
      compDisplay="flex"
      flex={1}
      flexDirection="column"
      justifyContent="center"
      minHeight={theme.space(30)}
    >
      <CircularLoader />
      <Text color="gray.900" fontSize={theme.typography.fontSizes.body2} mt={6.5}>
        Sync in progress - You can close this window
      </Text>
    </Box>
  );
};

export default GithubFinishingStep;
