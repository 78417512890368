import { useFetchDashboard } from '@api/dashboards/dashboards';

import DashboardsTabContent from './DashboardsTabContent';
import query from './query';

export default {
  Content: DashboardsTabContent,
  enableNotifications: true,
  fetchHook: useFetchDashboard,
  query,
};
