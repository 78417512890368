import React, { useMemo } from 'react';

import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import { DataType, ObjectType } from '@api/types';
import Breadcrumbs from '@components/Breadcrumbs';
import MetadataGridCard from '@components/MetadataGridCard';
import { useTagContext } from '@context/Tag';
import DataTypesModel from '@models/DataTypesModel';
import theme from '@styles/theme';
import { urlFor } from '@utils/routing';

export const EXCLUDED_SECONDARY_ICON_DATA_SOURCE_TYPES = [
  'tag',
  'metric',
  'glossary',
  'document',
  'team',
  'term',
];

interface MetadataGridHomepageCardObj {
  breadcrumbs?: BreadcrumbModel[];
  dataType: DataType;
  dataTypes?: DataTypesModel;
  description?: string;
  guid: string;
  name: string;
  objectType: ObjectType;
  parentGuid?: string;
}

interface MetadataGridHomepageCardProps {
  isLoading?: boolean;
  obj: MetadataGridHomepageCardObj;
  showDescription?: boolean;
}

const MetadataGridHomepageCard: React.FC<MetadataGridHomepageCardProps> = ({
  isLoading,
  obj,
  showDescription = false,
}) => {
  const icons = obj?.dataTypes?.icons;
  const { findTag } = useTagContext();

  const secondaryIcon = useMemo(() => {
    if (EXCLUDED_SECONDARY_ICON_DATA_SOURCE_TYPES.includes(obj?.dataTypes?.objectType ?? '')) {
      return undefined;
    }

    return icons?.dataSource;
  }, [icons?.dataSource, obj?.dataTypes?.objectType]);

  const icon = useMemo(() => {
    if (obj?.dataTypes?.objectType === 'tag') {
      return findTag?.(obj.guid)?.icon;
    }

    return icons?.dataType;
  }, [findTag, icons?.dataType, obj?.dataTypes?.objectType, obj.guid]);

  const iconColor = useMemo(() => {
    if (obj?.dataTypes?.objectType === 'tag') {
      return findTag?.(obj.guid)?.color;
    }

    return undefined;
  }, [findTag, obj?.dataTypes?.objectType, obj.guid]);

  return (
    <MetadataGridCard
      description={showDescription ? obj.description : undefined}
      icon={icon}
      iconColor={iconColor}
      isEditable={false}
      isLoading={isLoading}
      name={obj.name}
      nameFontSize="default"
      secondaryIcon={secondaryIcon}
      secondaryName={
        <Breadcrumbs
          color={theme.colors.v1.gray[600]}
          fontSize={theme.typography.fontSizes.sm}
          fontWeight="normal"
          items={obj.breadcrumbs}
          showTrailingSlash={false}
        />
      }
      url={urlFor(obj)}
    />
  );
};

export default MetadataGridHomepageCard;
