import { folderCacheKeys } from '@api/bifolder';
import { exploresCacheKeys } from '@api/explores';
import { fieldsCacheKeys } from '@api/fields';
import invalidateCache from '@api/invalidateCache';
import { tableauCacheKeys } from '@api/tableau';
import { viewsCacheKeys } from '@api/views';
import fetchClient from '@lib/fetchClient';

export const cacheDependenciesKeys = [
  folderCacheKeys.folders,
  exploresCacheKeys.explores,
  viewsCacheKeys.views,
  tableauCacheKeys.datasources,
  fieldsCacheKeys.fields,
];

export const invalidateTagsDependenciesQueries = () => {
  invalidateCache((keys) => [
    keys.columns.all,
    keys.biTables.all,
    keys.biNotebooks.all,
    keys.biColumns.all,
    keys.biDashboardElements.all,
    keys.dashboards.all,
    keys.tableau.all,
    keys.metadata.all,
    keys.metrics.all,
    keys.documents.all,
    keys.tables.all,
    keys.terms.all,
    keys.datasets.all,
    keys.databases.all,
    keys.schemas.all,
    keys.queries.all,
    keys.dbt.tests,
    keys.lookML.views,
  ]);

  cacheDependenciesKeys.forEach((key) => {
    fetchClient.invalidateQueries(key);
  });
};
