import { Expose } from 'class-transformer';

export class AccountBindingModel {
  objectType: string = 'accountBinding';

  typeDisplay: string = 'AccountBinding';

  @Expose({ name: 'user_guid' })
  userGuid!: string;

  @Expose({ name: 'organization_guid' })
  organizationGuid!: string;

  @Expose({ name: 'slack_user_id' })
  slackUserId!: string;

  @Expose({ name: 'slack_team_id' })
  slackTeamId!: string;
}
