import React from 'react';

import Box from '@components/Box';
import IconButton from '@components/IconButton';
import PageLink from '@components/PageLink';
import Avatar from '@components/UI/Avatar';
import theme from '@styles/theme';

import { OwnerType } from '../DetailsSection.types';

interface OwnerItemProps {
  hasEditPermissions: boolean;
  onEdit: () => void;
  owner?: OwnerType | null;
}

const OwnerItem: React.FC<OwnerItemProps> = ({ hasEditPermissions, onEdit, owner }) => (
  <>
    <Box alignItems="center" compDisplay="flex" overflow="hidden" pr={0.5} whiteSpace="nowrap">
      {owner && (
        <Box alignItems="center" compDisplay="flex" gap={0.5}>
          <Avatar {...owner.mappedAvatar} size="1.25rem" />
          <PageLink color={theme.colors.text} item={owner} />
        </Box>
      )}
    </Box>
    {hasEditPermissions && (
      <IconButton
        iconColor={theme.colors.v1.gray[500]}
        iconName="edit-outline"
        iconSize="16px"
        onClick={onEdit}
        size="sm"
        variant="clear"
      />
    )}
  </>
);

export default OwnerItem;
