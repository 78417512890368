import React from 'react';

import Box from '@components/Box';
import Text from '@components/Text';
import Card from '@components/UI/Card';
import aiIcon from '@components/UI/Icon/sprites/individual-icons/ai.svg';
import Image from '@components/UI/Image';

import ChatBotPageTitle from '../ChatBotPageTitle';

import {
  StyledChatBotInitialPromptsArrowIcon,
  StyledChatBotInitialPromptsItem,
} from './ChatBotInitialPrompts.styles';

export const PROMPTS_CONFIGS = {
  Example: [
    {
      icon: '🔎',
      prompt: 'What are the 5 most popular tables?',
    },
    {
      icon: '🤔',
      prompt: 'How do I calculate revenue?',
    },
    {
      icon: '📊',
      prompt: 'What information do we have on retention?',
    },
  ],
};

interface ChatBotInitialPromptsProps {
  onSelect: (val: string) => void;
}

const ChatBotInitialPrompts: React.FC<ChatBotInitialPromptsProps> = ({ onSelect }) => {
  return (
    <Box>
      <Box
        alignItems="center"
        compDisplay="flex"
        flexDirection="column"
        gap={1}
        justifyContent="center"
        mb={5}
      >
        <ChatBotPageTitle />
        <Image src={aiIcon} width="24px" />
        <Text color="inherit" fontSize="h1" fontWeight="semibold">
          Elevate your Search
        </Text>
      </Box>
      <Card boxShadow="lg" maxWidth="500px" mx="auto" py={0}>
        {PROMPTS_CONFIGS.Example.map((config) => {
          return (
            <StyledChatBotInitialPromptsItem
              key={config.prompt}
              alignItems="center"
              as="button"
              borderRadius="md"
              compDisplay="flex"
              compWidth="100%"
              gap={1}
              my={2}
              onClick={() => onSelect(config.prompt)}
              p={2}
            >
              <Box flexShrink={0} fontSize="24px">
                {config.icon}
              </Box>
              <Text color="inherit" flexGrow={1} fontWeight="medium" m={0}>
                {config.prompt}
              </Text>
              <StyledChatBotInitialPromptsArrowIcon name="arrow-right" size="24px" />
            </StyledChatBotInitialPromptsItem>
          );
        })}
      </Card>
    </Box>
  );
};

export default ChatBotInitialPrompts;
