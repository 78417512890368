import styled from '@emotion/styled';

interface StyledRichTextEditorProps {
  editable?: boolean;
  fluid?: boolean;
  isSuggested?: boolean;
  showToolbar?: boolean;
}

export const StyledRichTextEditor = styled.div<StyledRichTextEditorProps>`
  width: 100%;
  height: ${({ fluid = true }) => (fluid ? '100%' : 'auto')};

  div.slate-editable {
    padding-top: ${({ showToolbar, theme }) => (showToolbar ? theme.space(2) : '0')};
    min-width: 100%;
    min-height: 100%;

    color: ${({ isSuggested, theme }) =>
      isSuggested ? theme.colors.v1.gray[500] : theme.colors.text};
    font-size: ${({ theme }) => theme.typography.fontSizes.inherit};
    font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
    font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
    line-height: ${({ theme }) => theme.typography.lineHeights.default};
    word-break: break-word;

    *::selection {
      color: inherit;
      background-color: #e8f2ff;
    }
  }

  span[data-slate-placeholder='true'] {
    font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
    font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
    color: ${({ theme }) => theme.colors.v1.gray[300]};
    font-size: 'inherit';
    opacity: 1 !important;
  }

  :hover {
    div.slate-editable {
      color: ${({ isSuggested, theme }) => (isSuggested ? theme.colors.v1.primary[600] : '')};
    }
  }
`;
