import getIcons from '@configs/icons/getIcons';
import { Expose, Transform, Type } from 'class-transformer';

import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import { DatabaseData, DatabaseModel } from '@api/databases/DatabaseModel';
import { TaggedItemModel } from '@api/tags/TaggedItemModel';
import { TeamModel } from '@api/teams/TeamModel';
import type { DataType, ObjectType } from '@api/types';
import { UserModel } from '@api/user/UserModel';
import { breadcrumbsToLabelList, breadcrumbsToList } from '@components/Breadcrumbs';
import isEmptyRichText from '@components/RichTextEditor/helpers/isEmptyRichText';
import type { DataSourceTypesType } from '@models/DataSourceCredentials';
import DataTypesModel from '@models/DataTypesModel';
import { PopularityData, PopularityModel } from '@models/PopularityModel';
import { Reference } from '@models/Reference';
import { urlFor } from '@utils/routing';

export interface SchemaData {
  database: DatabaseData;
  description: string;
  guid: string;
  name: string;
  popularity?: PopularityData;
  richtextDescription: string;
}

export class SchemaModel {
  static objectType: ObjectType = 'schema';

  objectType: ObjectType = SchemaModel.objectType;

  static typeDisplay: string = 'Schema';

  typeDisplay: string = SchemaModel.typeDisplay;

  guid!: string;

  name!: string;

  @Expose({ name: 'data_types' })
  @Type(() => DataTypesModel)
  dataTypes?: DataTypesModel = new DataTypesModel();

  @Type(() => PopularityModel)
  popularity?: PopularityModel;

  @Expose({ name: 'search_name' })
  fullName?: string;

  description!: string;

  // TODO: Remove transform https://app.shortcut.com/select-star/story/55620
  @Expose({ name: 'richtext_description' })
  @Transform((richtextDescription) =>
    isEmptyRichText(richtextDescription) ? '' : richtextDescription,
  )
  richtextDescription?: string;

  @Type(() => DatabaseModel)
  database!: DatabaseModel;

  @Expose({ name: 'data_type' })
  dataType?: DataType;

  @Expose({ name: 'technical_owner' })
  @Type(() => Reference)
  technicalOwner?: Reference<UserModel | TeamModel>;

  @Expose({ name: 'business_owner' })
  @Type(() => Reference)
  businessOwner?: Reference<UserModel | TeamModel>;

  @Expose({ name: 'data_source_type' })
  dataSourceType?: DataSourceTypesType;

  @Type(() => BreadcrumbModel)
  breadcrumbs?: BreadcrumbModel[];

  @Expose({ name: 'tagged_items' })
  @Type(() => TaggedItemModel)
  taggedItems?: TaggedItemModel[];

  get breadcrumbList() {
    return breadcrumbsToList(this.breadcrumbs);
  }

  get breadcrumbLabelList() {
    const url = urlFor(this, false, '');
    return breadcrumbsToLabelList(this.name, url, this.breadcrumbs);
  }

  // @todo sc-23123 Refactor
  get icons() {
    return getIcons({
      dataSourceType: this.database?.dataTypes?.dataSourceType,
      dataType: this.database?.dataTypes?.dataType,
      objectType: this.objectType,
    });
  }
}
