import paginatedTransform from '@api/paginatedTransform';
import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import HierarchyModelV1 from '@models/HierarchyModelV1';
import { PaginatedResponse } from '@models/PaginatedResponse';

export const historyCacheKeys = {
  hierarchy: ['hierarchy'],
};

const fetchHierarchySelect = paginatedTransform(HierarchyModelV1);
export const useFetchHierarchy = (
  options?: UseFetchOptions<PaginatedResponse<HierarchyModelV1>>,
) => {
  return useFetch<PaginatedResponse<HierarchyModelV1>>({
    ...options,
    queryKey: [...historyCacheKeys.hierarchy, options?.params],
    select: fetchHierarchySelect,
    url: `/hierarchy/`,
  });
};
