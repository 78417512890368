import 'semantic-ui-css/semantic.min.css';
import 'reactflow/dist/style.css';

import React from 'react';

import { CreateTagModal, DeleteTagModal } from '@components/Modal';
import CreateAnotherTagModal from '@components/Modal/CreateTagModal/CreateTagContent/CreateAnotherTagModal';
import { useModal } from '@context/Modal';

const CreateTagModalContainer: React.FC = () => {
  const { MODAL_IDS, checkModalOpened, closeModal, getModalContent, openModal } = useModal();
  const createTagModalProps = getModalContent(MODAL_IDS.createTag);
  const deleteTagModalProps = getModalContent(MODAL_IDS.deleteTag);
  const createAnotherTagModalProps = getModalContent(MODAL_IDS.createAnotherTag);

  return (
    <>
      {checkModalOpened(MODAL_IDS.createTag) && (
        <CreateTagModal
          {...createTagModalProps}
          onClose={() => {
            closeModal(MODAL_IDS.createTag);
            createTagModalProps?.onClose?.();
          }}
        />
      )}
      {checkModalOpened(MODAL_IDS.deleteTag) && deleteTagModalProps && (
        <DeleteTagModal {...deleteTagModalProps} onClose={() => closeModal(MODAL_IDS.deleteTag)} />
      )}
      {checkModalOpened(MODAL_IDS.createAnotherTag) && (
        <CreateAnotherTagModal
          newTag={createAnotherTagModalProps}
          onClose={() => closeModal(MODAL_IDS.createAnotherTag)}
          onCreateAnother={() => {
            closeModal(MODAL_IDS.createAnotherTag);
            openModal(MODAL_IDS.createTag);
          }}
        />
      )}
    </>
  );
};

export default CreateTagModalContainer;
