import React, { forwardRef } from 'react';

import pluralize from '@utils/pluralize';

import ScaleIndicator from '../ScaleIndicator';
import { ScaleIndicatorProps } from '../ScaleIndicator/ScaleIndicator';

const PRIORITY_COLORS = [
  { color: '#DDD6FE', id: 1 },
  { color: '#DDD6FE', id: 2 },
  { color: '#A48AFB', id: 3 },
  { color: '#A48AFB', id: 4 },
  { color: '#5720B7', id: 5 },
  { color: '#5720B7', id: 6 },
];
export interface ImpactScoreProps
  extends Pick<ScaleIndicatorProps, 'className' | 'tooltipText' | 'showNotApplicable'> {
  impactedValue?: number;
  value?: number;
}

const ImpactScore = forwardRef<HTMLSpanElement, ImpactScoreProps>(
  (
    {
      className,
      impactedValue = 0,
      showNotApplicable,
      tooltipText = `Impacts ${pluralize(impactedValue, 'downstream dependency')} with usage.`,
      value,
      ...other
    },
    ref,
  ) => {
    const normalizedPriority = value ? (value / 100) * PRIORITY_COLORS.length : undefined;

    return (
      <ScaleIndicator
        {...other}
        ref={ref}
        className={className}
        colors={PRIORITY_COLORS}
        priority={normalizedPriority}
        showNotApplicable={showNotApplicable}
        testId="impact-score"
        tooltipText={tooltipText}
      />
    );
  },
);

export default ImpactScore;
