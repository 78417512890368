import React from 'react';

import { useFetchCustomAttributesWithValue } from '@api/customAttributesWithValue';
import Box from '@components/Box';
import { EditCustomAttributeValueInline } from '@components/Forms/EditCustomAttributeValueForm';
import Text from '@components/Text';
import { StyledTextProps } from '@components/Text/Text';
import Card, { CardProps } from '@components/UI/Card';
import { useUserContext } from '@context/User';

import { MAX_LENGTH } from '../Forms/EditCustomAttributeValueForm/useEditCustomAttributeValueForm';

const fetchCustomAttributesQuery = `{
    guid,
    type,
    name,
    value
  }`;

interface EditableCustomAttributesSectionProps {
  cardProps?: CardProps;
  guid: string;
  isEditable: boolean;
  titleFontSize?: StyledTextProps['fontSize'];
}

const EditableCustomAttributesSection: React.FC<EditableCustomAttributesSectionProps> = ({
  cardProps,
  guid,
  isEditable,
  titleFontSize = 'h4',
}) => {
  const { hasEditPermissions, organization } = useUserContext();
  const useRbac = organization?.settings?.useRbac;

  const { data } = useFetchCustomAttributesWithValue(guid, {
    params: {
      page: 1,
      page_size: 100,
      query: fetchCustomAttributesQuery,
    },
  });

  const customAttributesWithValue = data?.results || [];

  if (customAttributesWithValue.length === 0) return null;

  return (
    <Card px={3.5} py={2.5} {...cardProps}>
      <Box alignItems="flex-start" compDisplay="flex" justifyContent="flex-start" mb={1}>
        <Text as="h3" color="gray.700" fontSize={titleFontSize} fontWeight="medium" m={0}>
          Custom fields
        </Text>
      </Box>
      <Box alignItems="flex-start" compDisplay="flex" flexDirection="column" gap={0.5}>
        {customAttributesWithValue.map((customAttributeWithValue) => (
          <EditCustomAttributeValueInline
            key={customAttributeWithValue.guid}
            customAttribute={customAttributeWithValue}
            customAttributeValue={customAttributeWithValue.value}
            editIconVariant="always"
            isEditable={useRbac ? isEditable : hasEditPermissions}
            itemGuid={guid}
            wrapTextAt={MAX_LENGTH}
          />
        ))}
      </Box>
    </Card>
  );
};

export default EditableCustomAttributesSection;
