import React, { useEffect, useMemo, useState } from 'react';

import { SearchModel } from '@api/search/SearchModel';
import type { SearchResult } from '@api/search/types';
import { tabConfigDefault } from '@components/SearchBar/DatasourceTabs/config';
import Icon from '@components/UI/Icon';

import { defaultSearchContext } from '../SearchContext';
import { SearchFacetsResultsType } from '../SearchTypes';

import { StyledDatasourceTabs, Tab } from './DatasourceTabs.styles';
import { DatasourceTab } from './DatasourceTabs.types';

export const tabs: DatasourceTab[] = tabConfigDefault.map((item) => {
  const filterIndexes = item.filters.map((filter) => filter.indexes).flat(1) ?? [];
  return {
    filters: filterIndexes.reduce((prev, filterIndex) => ({ ...prev, [filterIndex]: true }), {}),
    icon: item.icon,
    name: item.name,
  };
});

const tabHasResults = (tab: DatasourceTab, facetsWithResults?: string[]) => {
  return facetsWithResults?.some((facetName) => facetName in tab.filters);
};

interface DatasourceTabsProps {
  activeTab: DatasourceTab;
  data?: SearchResult<SearchModel>;
  facets?: SearchResult<SearchModel>['facets'];
  onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void;
  setActiveTab: React.Dispatch<React.SetStateAction<DatasourceTab>>;
  showResultCount?: boolean;
}

const DatasourceTabs: React.FC<DatasourceTabsProps> = ({
  activeTab,
  data,
  facets,
  onKeyDown,
  setActiveTab,
  showResultCount,
}) => {
  const [initialData, setInitialData] = useState<SearchResult<SearchModel> | undefined>();

  const facetsWithResults = useMemo(() => {
    if (!facets) {
      return undefined;
    }

    return Object.keys(facets).filter((facetName) => {
      const facet = facets[facetName as keyof SearchFacetsResultsType];
      return facet && facet > 0;
    });
  }, [facets]);

  const tabsToDisplay = useMemo(() => {
    return tabs.filter((tab) => tab.name === 'All' || tabHasResults(tab, facetsWithResults));
  }, [facetsWithResults]);

  // Check if currently selected tab has results and if not switch to the 'All' tab.
  useEffect(() => {
    if (
      activeTab.name !== 'All' &&
      facetsWithResults &&
      !tabHasResults(activeTab, facetsWithResults)
    ) {
      setActiveTab({
        filters: defaultSearchContext.filters,
        name: 'All',
      });
    }
  }, [activeTab, facetsWithResults, defaultSearchContext, tabHasResults, setActiveTab]);

  useEffect(() => {
    if (!initialData && data) {
      setInitialData(data);
    }

    if (!data?.total) {
      setInitialData(undefined);
    }
  }, [data, initialData]);

  return (
    <StyledDatasourceTabs>
      {tabsToDisplay?.map((tab) => {
        return (
          <Tab
            key={tab.name}
            active={tab.name === activeTab.name}
            border={tab.name === activeTab.name}
            onClick={() => setActiveTab(tab)}
            onKeyDown={tab.name === 'All' && onKeyDown ? onKeyDown : undefined}
            tabIndex={0}
          >
            {tab.icon && <Icon mr={0.25} name={tab.icon} size="16px" />}
            {tab.name}
          </Tab>
        );
      })}
      {initialData && initialData?.total > 0 && showResultCount && (
        <div className="result-count">
          {initialData?.total > 1000 ? '1000+' : initialData?.total} results ({initialData?.shown}{' '}
          shown)
        </div>
      )}
    </StyledDatasourceTabs>
  );
};

export default DatasourceTabs;
