import React, { useState } from 'react';

import { api, setApiUrl } from '@api';

const useActiveApi = (): [string | undefined, (apiUrl: string) => void] => {
  // react hook
  const [activeApi, setActiveApi] = useState(api.defaults.baseURL);

  React.useEffect(() => {
    const storageChangedListener = (event: StorageEvent) => {
      if (event.key === 'apiUrl' && event.newValue) {
        setActiveApi(event.newValue);
      }
    };

    window.addEventListener('storage', storageChangedListener);
    return () => {
      window.removeEventListener('storage', storageChangedListener);
    };
  }, []);

  const setUrl = (apiUrl: string) => {
    setActiveApi(apiUrl);
    setApiUrl(apiUrl);
  };

  return [activeApi, setUrl];
};

export default useActiveApi;
