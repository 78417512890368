import React, { useMemo } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from '@routing/router';
import * as CSS from 'csstype';

import { TagCounts } from '@api/tags/TagModel';
import Box from '@components/Box';
import AiBulkAssist from '@components/BulkButtons/AiBulkAssist';
import Button from '@components/Button/Button';
import CircularLoader from '@components/CircularLoader';
import ErdExplore from '@components/ErdExplore';
import { ToggleHideItemsModal } from '@components/Modal';
import BulkEditOwnersModal from '@components/Modal/BulkEditOwnersModal';
import type { OwnerSelectItem } from '@components/OwnerSelect/types';
import TagsSelect from '@components/TagsSelect';
import Icon from '@components/UI/Icon';
import { useModal } from '@context/Modal';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import { useUserContext } from '@context/User';
import type { SelectedBulkItem } from '@hooks/useBulkEditSelected';
import theme from '@styles/theme';

import SelectedItemsMessage from './SelectedItemsMessage';

export interface BulkButtonsProps {
  canCopyData?: boolean;
  canDelete?: boolean;
  canEditOwner?: boolean;
  canEditTags?: boolean;
  canExportCSV?: boolean;
  canRemove?: boolean;
  canShowERD?: boolean;
  canUnHide?: boolean;
  canUseBulkAssist?: boolean;
  guid?: string;
  hideCategoryTags?: boolean;
  isDataSourceEditable: boolean;
  justifyContent?: CSS.Property.JustifyContent;
  onCopyDataClick?: () => void;
  onEdit?: () => void;
  onExportCSVClick?: () => void;
  selectedEditableItems: SelectedBulkItem[];
  selectedItems: SelectedBulkItem[];
  showText?: boolean;
  tagsCounts?: TagCounts;
  toggleDelete?: () => void;
}

const BulkButtons: React.FC<BulkButtonsProps> = ({
  canCopyData,
  canDelete,
  canEditOwner,
  canEditTags,
  canExportCSV,
  canRemove,
  canShowERD,
  canUnHide,
  canUseBulkAssist,
  guid,
  hideCategoryTags = false,
  isDataSourceEditable,
  justifyContent = 'flex-end',
  onCopyDataClick,
  onEdit,
  onExportCSVClick,
  selectedEditableItems,
  selectedItems,
  showText,
  tagsCounts = {},
  toggleDelete,
}) => {
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const { organization } = useUserContext();
  const { useAiDescriptions } = organization?.settings ?? {};
  const { MODAL_IDS, checkModalOpened, closeModal, openModal } = useModal();
  const {
    hasEditPermissions,
    isFetching: isPermissionsFetching,
    isPbac,
  } = useObjectPermissionsContext({ ids: selectedItems.map((item) => item.guid) });

  const isEditable = useMemo(() => {
    if (isPermissionsFetching) {
      return false;
    }

    return (
      isDataSourceEditable &&
      (isPbac ? selectedEditableItems && selectedEditableItems?.length > 0 : hasEditPermissions)
    );
  }, [
    selectedEditableItems,
    hasEditPermissions,
    isDataSourceEditable,
    isPermissionsFetching,
    isPbac,
  ]);

  const openHideItemsModal = () => {
    openModal(MODAL_IDS.hideItems);
  };

  const columnsIds = selectedItems
    ?.filter((item) => item?.objectType === 'column')
    ?.map((item) => item.guid);

  const tablesIds = selectedItems
    ?.filter((item) => item?.objectType === 'table')
    ?.map((item) => item.guid);

  const userHasNonEditableItems =
    isPbac && selectedEditableItems && selectedItems.length > selectedEditableItems.length;

  return (
    <>
      {selectedItems?.length > 0 && (
        <Box
          alignItems="center"
          compDisplay="flex"
          flexWrap="wrap"
          gap={1}
          justifyContent={justifyContent}
          my={1.5}
        >
          {showText && (
            <SelectedItemsMessage
              isLoadingPermissions={isPermissionsFetching}
              nonEditableItemsCount={selectedItems.length - selectedEditableItems.length}
              selectedItemsCount={selectedItems.length}
              showWarning={isEditable && userHasNonEditableItems}
            />
          )}
          {(tablesIds?.length > 0 || columnsIds?.length > 0) && canShowERD && (
            <Button
              color="#0C3476"
              onClick={() => {
                if (process.env.REACT_APP_CHROME_EXTENSION_BUILD) {
                  window.open(`${process.env.REACT_APP_SS_APP_URL}${url}/erd`, '_blank');
                } else {
                  history.push({ pathname: `${url}/erd` });
                }
              }}
              startIcon={<Icon color="currentColor" name="schema" size="16px" />}
              variant="colorize"
            >
              ERD
            </Button>
          )}
          {canEditTags && isEditable && (
            <TagsSelect
              hideCategoryTags={hideCategoryTags}
              isEditable={isEditable}
              objects={selectedItems}
              renderCustomAnchor={({ anchorProps, anchorRef, isLoading, isOpen }) => (
                <div ref={anchorRef}>
                  <Button
                    {...anchorProps}
                    backgroundColor={isOpen ? 'gray.50' : undefined}
                    disabled={isLoading}
                    startIcon={
                      isLoading ? (
                        <Box compWidth="16px">
                          <CircularLoader
                            borderWidth={2}
                            color={theme.colors.gray[500]}
                            compSize={1.75}
                          />
                        </Box>
                      ) : (
                        <Icon name="tag-outline" size="16px" />
                      )
                    }
                    type="button"
                    variant="outlined"
                  >
                    Edit Tags
                  </Button>
                </div>
              )}
              taggedItemsCounts={tagsCounts}
            />
          )}
          {canEditOwner && isEditable && (
            <Button
              onClick={() => {
                openModal(MODAL_IDS.editOwnersBulk);
              }}
              startIcon={<Icon name="user" size="16px" />}
              variant="outlined"
            >
              Edit Owners
            </Button>
          )}
          {useAiDescriptions && canUseBulkAssist && guid && <AiBulkAssist guid={guid} />}
          {canExportCSV && (
            <Button
              onClick={onExportCSVClick}
              startIcon={<Icon name="export" size="16px" />}
              variant="outlined"
            >
              Export To CSV
            </Button>
          )}
          {canCopyData && (
            <Button
              onClick={onCopyDataClick}
              startIcon={<Icon name="copy" size="16px" />}
              variant="outlined"
            >
              Copy Data
            </Button>
          )}
          {canRemove && isEditable && (
            <Button
              backgroundColor="#8a8a8a"
              onClick={openHideItemsModal}
              startIcon={<Icon color="currentColor" name="trash" size="16px" />}
            >
              Hide
            </Button>
          )}
          {canUnHide && isEditable && (
            <Button
              backgroundColor="#8a8a8a"
              onClick={openHideItemsModal}
              startIcon={<Icon color="currentColor" name="undo" size="16px" />}
            >
              Unhide
            </Button>
          )}
          {canDelete && isEditable && (
            <Button
              backgroundColor="#8a8a8a"
              onClick={toggleDelete}
              startIcon={<Icon color="currentColor" name="trash" size="16px" />}
            >
              Delete
            </Button>
          )}
        </Box>
      )}
      <Switch>
        <Route path={`${path}/erd`}>
          <ErdExplore
            columnsIds={columnsIds}
            getStackStartingTableId={(tables) => {
              return (
                tables?.find((t) => t?.sourceTableGuids?.length === 0)?.guid || tables[0]?.guid
              );
            }}
            onModalClose={() =>
              history.push({
                pathname: url.replace('/erd', '/'),
              })
            }
            tablesIds={tablesIds}
          />
        </Route>
      </Switch>
      {checkModalOpened(MODAL_IDS.editOwnersBulk) && (
        <BulkEditOwnersModal
          editableItems={selectedEditableItems as unknown as OwnerSelectItem[]}
          onClose={() => closeModal(MODAL_IDS.editOwnersBulk)}
          reloadData={onEdit}
          selectedItems={selectedItems as unknown as OwnerSelectItem[]}
        />
      )}
      {checkModalOpened(MODAL_IDS.hideItems) && (
        <ToggleHideItemsModal
          isShowItemsModal={canUnHide}
          onClose={() => closeModal(MODAL_IDS.hideItems)}
          reloadData={onEdit}
          selectedItems={selectedEditableItems}
        />
      )}
    </>
  );
};

export default BulkButtons;
