import styled from '@emotion/styled';

export const StyledTableMenu = styled.div`
  align-items: flex-start;
  justify-content: space-between;
  background: #fff;
  border-radius: ${({ theme }) => theme.radius.default};
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: ${({ theme }) => theme.space(2)};
  transition: all 0.065s cubic-bezier(0.2, 0.75, 0.1, 0.5) 0s;
  z-index: ${({ theme }) => theme.zIndex.floatingElement};
`;
