import React from 'react';
import { Cell } from 'react-table';

import AnalyticsUserModel from '@api/analytics/AnalyticsUserModel';
import DateTime from '@components/DateTime/DateTime';
import UserCell from '@components/Table/Cells/UserCell';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import { FilterServiceInterface } from '@utils/filters';

interface TopContributorsTableProps {
  detailed?: boolean;
  filterService?: FilterServiceInterface;
  loading: boolean;
  rows?: AnalyticsUserModel[];
  totalPages?: number;
}

const TopContributorsTable: React.FC<TopContributorsTableProps> = ({
  detailed = false,
  filterService,
  loading,
  rows = [],
  totalPages,
}) => {
  const columns: ColumnConfig<AnalyticsUserModel>[] = [
    {
      Cell: (props: Cell<AnalyticsUserModel>) => {
        const { row } = props;
        return <UserCell {...props} user={row.original} />;
      },
      Header: 'Name',
      accessor: (d) => d.fullName,
      disableFilters: true,
      disableHiding: true,
      id: 'name',
      width: detailed ? '25%' : '50%',
    },
    {
      Cell: (props: Cell<AnalyticsUserModel>) => {
        const { row } = props;
        return <UserCell {...props} user={row.original.team} />;
      },
      Header: 'Team',
      accessor: (d) => d.team?.name,
      disableFilters: true,
      disableHiding: true,
      id: 'team',
      width: detailed ? '25%' : '50%',
    },
    ...(detailed
      ? [
          {
            Cell: ({ row }: Cell<AnalyticsUserModel>) => row.original.activityCount,
            Header: 'Edit Count',
            accessor: (d: Partial<AnalyticsUserModel>) => d.activityCount,
            disableFilters: true,
            disableHiding: true,
            id: 'activityCount',
            width: '25%',
          },
          {
            Cell: ({ row }: Cell<AnalyticsUserModel>) => (
              <DateTime datetime={row.original.lastActivity} format="fullDateFormat" />
            ),
            Header: 'Last Activity',
            accessor: (d: Partial<AnalyticsUserModel>) => d.lastActivity?.toDate(),
            disableFilters: true,
            disableHiding: true,
            id: 'lastActivity',
            width: '25%',
          },
        ]
      : []),
  ];

  return (
    <TableStyled>
      <Table
        basic="very"
        changePage={filterService?.changePage}
        className="table-full"
        columns={columns}
        compact
        data={rows}
        disableColumnFiltering
        disableFilters
        disablePagination={!detailed}
        disableRowSelect
        disableSortBy={!detailed}
        initialState={{
          pageIndex: filterService ? filterService!.filter.page! - 1 : 0,
          sortBy: [
            {
              desc: true,
              id: 'activityCount',
            },
          ],
        }}
        loading={loading}
        manualPagination={detailed}
        manualSortBy={detailed}
        setSortBy={filterService?.sort}
        sortable={detailed}
        stickyHeader={detailed}
        totalPages={totalPages}
        unstackable
      />
    </TableStyled>
  );
};

export default TopContributorsTable;
