import React, { ReactElement } from 'react';

import Highlighter from '@components/Highlighter';
import Tooltip from '@components/Tooltip';

import { TypedCell } from './types';

interface TooltipCellProps {
  elementToRender?: ReactElement;
  text?: string;
}

const TooltipCell = React.memo<TypedCell & TooltipCellProps>(
  ({ column, elementToRender, globalFilter, text, value }) => {
    if (!value && !text) {
      return null;
    }

    const textToShow = text || value;

    const searchWords = globalFilter?.split(/[.| ]+/) || column.filterValue?.split(/[.| ]+/) || [];

    return (
      <Tooltip content={textToShow}>
        <span>
          {elementToRender || (
            <Highlighter autoEscape searchWords={searchWords} textToHighlight={textToShow || ''} />
          )}
        </span>
      </Tooltip>
    );
  },
);

export default TooltipCell;
