import React from 'react';

import Box from '@components/Box';
import IconButton from '@components/IconButton';
import { AddCollectionDataAssetsModalProps } from '@components/Modal/AddCollectionDataAssetsModal/AddCollectionDataAssetsModal';
import DropdownMenu from '@components/UI/DropdownMenu';

import AssetsMenu from './AssetsMenu';

interface CollectionTabsRightElementProps
  extends Pick<AddCollectionDataAssetsModalProps, 'collection'> {}

const CollectionTabsRightElement: React.FC<CollectionTabsRightElementProps> = ({ collection }) => {
  return (
    <Box alignItems="center" compDisplay="flex" gap={1}>
      <AssetsMenu collection={collection} />
      <DropdownMenu
        id="collection-menu"
        options={[
          { icon: 'edit', label: 'Edit (mock)', onClick: () => {} },
          { icon: 'trash', label: 'Delete (mock)', onClick: () => {} },
        ]}
      >
        <IconButton
          iconColor="gray.700"
          iconName="settings"
          iconSize="18px"
          size="sm"
          variant="outlined"
        />
      </DropdownMenu>
    </Box>
  );
};

export default CollectionTabsRightElement;
