import { css } from '@emotion/react';
import styled from '@emotion/styled';

import IconButton from '@components/IconButton';
import { ReactComponent as AiIconOriginal } from '@components/UI/Icon/sprites/individual-icons/ai.svg';
import { ReactComponent as AiIconFilledOriginal } from '@components/UI/Icon/sprites/individual-icons/ai-filled.svg';

interface StyledAppMainSidebarProps {
  top?: number;
  zIndex: number;
}

export const StyledAppMainSidebar = styled.aside<StyledAppMainSidebarProps>`
  display: flex;
  height: 100vh;
  position: sticky;
  z-index: ${({ zIndex }) => zIndex};
  top: 0;
  ${({ top }) =>
    top &&
    css`
      top: ${top}px;
      height: calc(100vh - ${top}px);
    `};
`;

export const StyledAppMainSidebarCollapseButton = styled(IconButton)`
  position: absolute;
  top: 9px;
  right: ${({ theme }) => theme.space(-1.75)};
  z-index: 1;
  width: ${({ theme }) => theme.space(3.5)};
  height: ${({ theme }) => theme.space(3.5)};
  border-radius: 50%;
  box-shadow: ${({ theme }) => theme.shadow.sm};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.v1.gray[200]};
  overflow: hidden;
`;

StyledAppMainSidebarCollapseButton.defaultProps = {
  size: 'lg',
  variant: 'opaque',
};

export const AiIcon = styled(AiIconOriginal)`
  min-width: min-content;
  min-height: min-content;
`;

export const AiIconFilled = styled(AiIconFilledOriginal)`
  min-width: min-content;
  min-height: min-content;
`;
