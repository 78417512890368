import React from 'react';

import Box, { StyledBoxProps } from '@components/Box';
import Input from '@components/Input/Input.v1';
import Icon from '@components/UI/Icon';

interface TableTopBarProps {
  customContent?: React.ReactNode;
  mb?: StyledBoxProps['mb'];
  onSearchValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  searchValue: string;
}

const TableTopBar: React.FC<TableTopBarProps> = ({
  customContent,
  mb = 0.9,
  onSearchValueChange,
  searchValue,
}) => {
  return (
    <Box alignItems="center" compDisplay="flex" gap={1} mb={mb}>
      <Input
        aria-label="search"
        compWidth="396px"
        onChange={onSearchValueChange}
        placeholder="Search"
        startIcon={<Icon color="gray.800" name="search" size="20px" />}
        value={searchValue}
      />
      <Box flex={1}>{customContent}</Box>
    </Box>
  );
};

export default TableTopBar;
