import isUrl from 'is-url';
import { BaseSelection, Editor, Element, Range, Text, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import { getClickableLink } from '../RenderElements/LinkElement/LinkElement';
import { CustomElement } from '../RichTextEditor.types';

export const isLinkNodeAtSelection = (editor: Editor, selection: BaseSelection) => {
  if (!selection || !editor) {
    return false;
  }

  return !!Editor.above(editor, {
    at: selection,
    match: (n) => Element.isElement(n) && n.type === 'link',
  });
};

const removeLink = (editor: Editor) => {
  Transforms.removeNodes(editor, {
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === 'link',
  });
};

const createLinkNode = (url = '', text = ''): CustomElement => ({
  children: [{ text }],
  type: 'link',
  url,
});

export const insertLink = (editor: Editor, text = '', url = '') => {
  if (!editor.selection) {
    return;
  }
  const ancestor = Editor.above(editor, {
    at: editor.selection,
    match: (n) => (n as CustomElement).type === 'link',
  });

  ReactEditor.focus(editor);

  const path = ancestor?.[1];

  // If there is a link node already at our currect selection.
  if (isLinkNodeAtSelection(editor, editor.selection)) {
    // First we will remove the link we'll be updating.
    removeLink(editor);
    // If there is no url provided we should replace link with plain text, effectively removing the link.
    if (!url) {
      Transforms.insertNodes(
        editor,
        { children: [{ text }], type: 'span' },
        {
          at: path,
        },
      );
      return;
    }
    // If a url is provided we should insert a new link
    Transforms.insertNodes(editor, [createLinkNode(url, text), { text: '' }], {
      at: editor.selection,
      select: true,
    });
    // If there is no link at the selection and the selection is collapsed(user has not selected text).
  } else if (Range.isCollapsed(editor.selection)) {
    // If a url was not provided there is no reason to insert a link and we should do nothing.
    if (!url) {
      return;
    }
    // Otherwise insert a link here.
    Transforms.insertNodes(editor, [createLinkNode(url, text), { text: '' }], {
      at: path,
      select: true,
    });
    // We are not at a link but the user has selected text.
  } else {
    // If no url is provided we should do nothing.
    if (!url) {
      return;
    }
    // Otherwise we should wrap the selected text in a link.
    Transforms.wrapNodes(editor, createLinkNode(url, text), {
      at: editor.selection,
      split: true,
    });
  }
  Transforms.move(editor, { distance: 0, edge: 'end', unit: 'word' });
  Transforms.collapse(editor, { edge: 'end' });
};

export const getSelectedLink = (editor: Editor) => {
  const { selection } = editor;
  if (selection == null) {
    return null;
  }

  const node = Editor.above(editor, {
    at: selection,
    match: (n) => Element.isElement(n) && n.type === 'link',
  });

  return node;
};

export const getLinkText = (link?: CustomElement) => {
  if (!link) return null;
  const { children } = link;
  if (children.length > 0 && Text.isText(children[0])) {
    return children[0].text;
  }
  return null;
};

export const getLinkURLText = (linkElement?: CustomElement, selectedText?: string) => {
  if (linkElement?.url) {
    return linkElement.url;
  }
  if (selectedText) {
    if (isUrl(getClickableLink(selectedText))) {
      return selectedText;
    }
  }
  return '';
};
