import React from 'react';

import Highlighter from '@components/Highlighter';
import Tooltip from '@components/Tooltip';

import { StyledSidebarTreeDescription } from './SidebarTreeItem.styles';
import type { SidebarTreeItemProps } from './types';

const SidebarTreeDescriptionCell: React.FC<SidebarTreeItemProps> = ({
  description,
  enableHighlight = true,
  searchKeyword,
}) => {
  return (
    <Tooltip content={description ?? ''}>
      <StyledSidebarTreeDescription className="description">
        {enableHighlight && searchKeyword !== undefined ? (
          <Highlighter searchWords={[searchKeyword]} textToHighlight={description} />
        ) : (
          description
        )}
      </StyledSidebarTreeDescription>
    </Tooltip>
  );
};

export default SidebarTreeDescriptionCell;
