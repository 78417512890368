import { Editor } from 'slate';

const VOID_ELEMENTS = ['br', 'image', 'mention'];

/**
 * Declare any custom void elements in this plugin.
 * For more info about voids see: https://docs.slatejs.org/api/nodes/element#void-vs-not-void
 */
const withVoidElementsPlugin = (editor: Editor) => {
  const { isVoid } = editor;

  editor.isVoid = (element) => {
    return VOID_ELEMENTS.includes(element.type) ? true : isVoid(element);
  };

  return editor;
};

export default withVoidElementsPlugin;
