import React from 'react';

import { useFetchMetadataComments } from '@api/metadata';
import AssetsTab from '@components/TabContent/AssetsTab';
import DiscussionTab from '@components/TabContent/DiscussionTab';
import TabsRouter, { TabRoutesConfig } from '@components/TabsRouter';
import { useUserContext } from '@context/User';
import wrapString from '@utils/wrapString';

import type { TabContentProps } from '../types';

interface TagsTabContentProps extends TabContentProps {}

const TagsTabContent: React.FC<TagsTabContentProps> = ({ guid, overviewTabConfig }) => {
  const { data: commentData } = useFetchMetadataComments(guid);
  const { organization, user } = useUserContext();
  const userDefaultTab = user?.settings.defaultTableDashboardTab;

  const tabsRouterConfig: TabRoutesConfig[] = [
    { ...overviewTabConfig, default: userDefaultTab === 'overview' },
    {
      component: () => <AssetsTab guid={guid} />,
      default: userDefaultTab === 'columns_charts',
      label: 'Assets',
      route: '/assets',
    },
    ...(organization?.settings?.useDiscussions
      ? [
          {
            component: () => <DiscussionTab guid={guid} />,
            contentOffset: 2,
            label: `Discussion ${wrapString(commentData?.length)}`,
            path: '/discussion/:itemId?',
            route: '/discussion',
          },
        ]
      : []),
  ];

  return <TabsRouter config={tabsRouterConfig} sticky />;
};

export default TagsTabContent;
