import React from 'react';

import Box from '@components/Box';
import theme from '@styles/theme';

import { BackgroundCircle, MiddleText, ProgressCircle, StyledSVG } from './ProgressBar.styles';

interface ProgressBarProps {
  currentValue?: number;
  size?: number;
  totalValue?: number;
}

/*
 * Based on https://blog.logrocket.com/how-to-build-an-svg-circular-progress-component-using-react-and-react-hooks/
 */
const ProgressBar: React.FC<ProgressBarProps> = ({
  currentValue = 0,
  size = 80,
  totalValue = 0,
}) => {
  const percentComplete = Math.round((currentValue / totalValue) * 100);
  const hasExceededMaximum = percentComplete > 100;
  const finalPercent = hasExceededMaximum ? 100 : percentComplete;
  const strokeWidth = size * 0.1;
  const center = size / 2;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;
  const progress = ((100 - finalPercent) / 100) * circumference;

  return (
    <Box>
      <StyledSVG height={size} width={size}>
        <BackgroundCircle cx={center} cy={center} r={radius} strokeWidth={strokeWidth} />
        <ProgressCircle
          cx={center}
          cy={center}
          r={radius}
          stroke={hasExceededMaximum ? theme.colors.v1.primary[800] : theme.colors.v1.primary[400]}
          strokeDasharray={circumference}
          strokeDashoffset={progress}
          strokeWidth={strokeWidth}
        />
        <MiddleText dominantBaseline="middle" textAnchor="middle" x={`${center}`} y={`${center}`}>
          {`${percentComplete}%`}
        </MiddleText>
      </StyledSVG>
    </Box>
  );
};

export default ProgressBar;
