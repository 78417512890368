import React from 'react';

import { useFetchAnalyticsSearches } from '@api/analytics';
import DateRangePicker from '@components/DateRangePicker';
import { Column } from '@components/Grid';
import TabError from '@components/TabContent/TabError';
import SectionTitle, {
  SectionTitleWrapper,
} from '@pages/Admin/AdminCostAnalysisPage/tabs/SectionTitle';
import { Filter } from '@utils';

import { DateRangeProps } from '../../Analytics.types';

import TopSearchTermsTable from './tables/TopSearchTermsTable';

export const topSearchTermsDefaultFiltersConfig: Filter.FilterOptions = {
  order: '-count',
  page: 1,
  page_size: 50,
  sortColumn: 'count',
  sortDirection: 'descending',
};

export const topSearchTermsSortConfig: { [key: string]: string } = {
  count: 'count',
  term: 'term',
};

const AnalyticsActivitiesTopSearchTerms: React.FC<DateRangeProps> = ({
  dateRange: [start_date, end_date],
  onDateRangeChange,
}) => {
  const filterService = Filter.useUpdateFilters(
    topSearchTermsDefaultFiltersConfig,
    {},
    topSearchTermsSortConfig,
    '-count',
  );

  const { data, isError, isLoading } = useFetchAnalyticsSearches({
    params: {
      ...Filter.setParams(filterService.filter),
      end_date,
      start_date,
    },
  });

  const handleFilterChange = (range: [string, string]) => {
    onDateRangeChange(range);
    filterService.changePage(1);
  };

  const totalPages = data
    ? Math.ceil(data.count / topSearchTermsDefaultFiltersConfig.page_size!)
    : 1;

  if (isError) {
    return <TabError />;
  }

  return (
    <>
      <SectionTitleWrapper my={3}>
        <SectionTitle subtitle="Based on which keywords are searched" title="Top Search Terms" />
        <DateRangePicker initialRange={[start_date, end_date]} onChange={handleFilterChange} />
      </SectionTitleWrapper>
      <Column xs={12}>
        <TopSearchTermsTable
          detailed
          filterService={filterService}
          loading={isLoading}
          rows={data?.results}
          totalPages={totalPages}
        />
      </Column>
    </>
  );
};

export default AnalyticsActivitiesTopSearchTerms;
