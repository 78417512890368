import React from 'react';

import { useFetchAnalyticsUsersContributing } from '@api/analytics';
import DateRangePicker from '@components/DateRangePicker';
import { Column } from '@components/Grid';
import TabError from '@components/TabContent/TabError';
import SectionTitle, {
  SectionTitleWrapper,
} from '@pages/Admin/AdminCostAnalysisPage/tabs/SectionTitle';
import { Filter } from '@utils';

import { DateRangeProps } from '../../Analytics.types';

import TopContributorsTable from './tables/TopContributorsTable';

export const topContributorsDefaultFiltersConfig: Filter.FilterOptions = {
  order: '-activity_count',
  page: 1,
  page_size: 50,
  sortColumn: 'activityCount',
  sortDirection: 'descending',
};

export const topContributorsSortConfig: { [key: string]: string } = {
  activityCount: 'activity_count',
  lastActivity: 'last_activity_in_range',
  name: 'name',
  team: 'team',
};

const AnalyticsUsersTopContributors: React.FC<DateRangeProps> = ({
  dateRange: [start_date, end_date],
  onDateRangeChange,
}) => {
  const filterService = Filter.useUpdateFilters(
    topContributorsDefaultFiltersConfig,
    {},
    topContributorsSortConfig,
    '-activity_count',
  );

  const { data, isError, isLoading } = useFetchAnalyticsUsersContributing({
    params: {
      ...Filter.setParams(filterService.filter),
      end_date,
      start_date,
    },
  });

  const handleFilterChange = (range: [string, string]) => {
    onDateRangeChange(range);
    filterService.changePage(1);
  };

  const totalPages = data
    ? Math.ceil(data.count / topContributorsDefaultFiltersConfig.page_size!)
    : 1;

  if (isError) {
    return <TabError />;
  }

  return (
    <>
      <SectionTitleWrapper my={3}>
        <SectionTitle
          subtitle="Based on all activity logs excluding views (edits, comments)"
          title="Top Contributors"
        />
        <DateRangePicker initialRange={[start_date, end_date]} onChange={handleFilterChange} />
      </SectionTitleWrapper>
      <Column xs={12}>
        <TopContributorsTable
          detailed
          filterService={filterService}
          loading={isLoading}
          rows={data?.results}
          totalPages={totalPages}
        />
      </Column>
    </>
  );
};

export default AnalyticsUsersTopContributors;
