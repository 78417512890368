import React from 'react';

import useNewLayout from '@hooks/useNewLayout';

import ChatBotHierarchyV0 from './ChatBotHierarchy';
import ChatBotHierarchyV1 from './ChatBotHierarchy.v1';

const ChatBotHierarchy: React.FC = () => {
  const { shouldUseNewLayout } = useNewLayout();

  return shouldUseNewLayout ? <ChatBotHierarchyV1 /> : <ChatBotHierarchyV0 />;
};

export default ChatBotHierarchy;
