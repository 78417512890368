import React from 'react';
import { BaseEditor, BasePoint, Editor } from 'slate';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-react';

import Icon from '@components/UI/Icon';

export type CustomElementType =
  | 'blockquote'
  | 'br'
  | 'code'
  | 'codeblock'
  | 'em'
  | 'fragment'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'image'
  | 'link'
  | 'li'
  | 'mention'
  | 'ol'
  | 'paragraph'
  | 'pre'
  | 'span'
  | 'strong'
  | 'table'
  | 'tbody'
  | 'td'
  | 'th'
  | 'thead'
  | 'tr'
  | 'u'
  | 'ul';

export type RichTextEditorElement = CustomElementType | 'bold' | 'italic' | 'underline';

export type CustomElement = {
  children: (CustomElement | CustomText)[];
  level?: number;
  mention?: any;
  type: CustomElementType;
  url?: string;
};

export type FormattedText = {
  bold?: boolean;
  code?: boolean;
  highlight?: boolean;
  italic?: boolean;
  text: string;
  underline?: boolean;
};

type CustomText = FormattedText;

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor & HistoryEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

interface ControlButton {
  icon: React.ReactElement;
  label: string;
  style: string;
  type: string;
}

interface EditorButtons {
  [key: string]: ControlButton;
}

const buttonTypesDefinitions: EditorButtons = {
  blockquote: {
    icon: <Icon name="blockquote" />,
    label: 'Blockquote',
    style: 'blockquote',
    type: 'block',
  },
  bold: { icon: <Icon name="bold" />, label: 'Bold', style: 'bold', type: 'inline' },
  code: { icon: <Icon name="code" />, label: 'Code', style: 'code', type: 'inline' },
  codeblock: {
    icon: <Icon name="code-block" />,
    label: 'Codeblock',
    style: 'codeblock',
    type: 'block',
  },
  h1: { icon: <Icon name="h1" />, label: 'H1', style: 'h1', type: 'block' },
  h2: { icon: <Icon name="h2" />, label: 'H2', style: 'h2', type: 'block' },
  h3: { icon: <Icon name="h3" />, label: 'H3', style: 'h3', type: 'block' },
  hyper_link: {
    icon: <Icon name="hyperlink" />,
    label: 'Hyper link',
    style: 'link',
    type: 'inline',
  },
  image: {
    icon: <Icon name="insert-image" />,
    label: 'Image',
    style: 'image',
    type: 'void',
  },
  italic: { icon: <Icon name="italic" />, label: 'Italic', style: 'italic', type: 'inline' },
  ordered_list: {
    icon: <Icon name="numbered-list" />,
    label: 'Ordered list',
    style: 'ol',
    type: 'block',
  },
  table: {
    icon: <Icon name="insert-table" />,
    label: 'Table',
    style: 'table',
    type: 'block',
  },
  underline: {
    icon: <Icon name="underline" />,
    label: 'Underline',
    style: 'underline',
    type: 'inline',
  },
  unordered_list: {
    icon: <Icon name="unordered-list" />,
    label: 'Unordered list',
    style: 'ul',
    type: 'block',
  },
};

const buttonsOrder = [
  'h1',
  'h2',
  'h3',
  'bold',
  'italic',
  'underline',
  'unordered_list',
  'ordered_list',
  'hyper_link',
  'image',
  'code',
  'codeblock',
  'blockquote',
  'table',
];

export const BUTTON_TYPES: EditorButtons = buttonsOrder.reduce(
  (orderedButtons, currentButtonKey) => {
    return {
      ...orderedButtons,
      [currentButtonKey]: buttonTypesDefinitions[currentButtonKey],
    };
  },
  {},
);

export type SlatePluginType = (editor: Editor) => Editor;

export interface BasePoints {
  anchor: BasePoint;
  focus: BasePoint;
}
