import React from 'react';
import { RouterLink } from '@routing/router';

import { ActivityModel } from '@api/activity/ActivityModel';
import TargetObjectName from '@components/ActivityMessage/TargetObjectName';
import Icon from '@components/UI/Icon';
import { getDiscussionUrl, urlFor } from '@utils/routing';

import {
  StyledActivityLink,
  StyledActivityLinkLink,
  StyledActivityPostTarget,
  StyledActivityPreTarget,
} from './ActivityLink.styles';
import { activityToActionText } from './utils';

interface ActivityLinkProps {
  activity: ActivityModel;
}

/*
 * We don't always have enough information to determine the data type icon for the delete
 * activity (since the target no longer exists), but we can use cached target data type
 * to show the correct icon in some simple cases (e.g. for the column type).
 */
const cachedDataTypeIcons: { [dataType: string]: string } = {
  boolean: 'boolean',
  date: 'calendar',
  geo: 'geography',
  number: 'number',
  object: 'variant',
  string: 'text',
  table: 'table',
  view: 'view',
};

const ActivityLink: React.FC<ActivityLinkProps> = ({ activity }) => {
  const { activityType, cachedTargetDataType, cachedTargetName, comment, target } = activity;
  const targetUrl = urlFor(target.obj);
  const targetDataType =
    (target?.obj.isDeleted && cachedDataTypeIcons[cachedTargetDataType]) ||
    target.obj?.dataTypes?.icons?.dataType;
  const linkUrl =
    activityType === 'comment' ? getDiscussionUrl(targetUrl, comment?.guid) : targetUrl;
  const { postTarget, preTarget } = activityToActionText(activity);
  const name: string = (target?.obj.isDeleted && cachedTargetName) || target?.obj?.name;

  return (
    <StyledActivityLink>
      {preTarget && <StyledActivityPreTarget>{preTarget}</StyledActivityPreTarget>}
      <StyledActivityLinkLink as={RouterLink} to={linkUrl}>
        <Icon compDisplay="inline-block" mx={0.25} name={targetDataType} size="14px" />
        <TargetObjectName text={name} />
      </StyledActivityLinkLink>
      {postTarget && <StyledActivityPostTarget>{postTarget}</StyledActivityPostTarget>}
    </StyledActivityLink>
  );
};

export default ActivityLink;
