import styled from '@emotion/styled';

import LayoutMainGrid from '@components/Layout/LayoutMainGrid';

export const StyledGridContainer = styled(LayoutMainGrid)`
  height: auto;
  ${({ theme }) => theme.media.down('md')`
     padding: ${theme.space(2)}
  `}
`;
