import styled from '@emotion/styled';

import Box from '@components/Box/Box.styles';

interface StyledChatBotPageFormWrapperProps {
  isError: boolean;
}

export const StyledChatBotPageFormWrapper = styled(Box)<StyledChatBotPageFormWrapperProps>`
  background: linear-gradient(white, white) padding-box,
    linear-gradient(-70deg, #33b1ff, #ff23ef, #875bf7, #33b1ff) border-box;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ isError, theme }) => (isError ? theme.colors.v1.error[500] : 'transparent')};
`;
