import React from 'react';
import getContainerPageConfig from '@configs/container/getContainerConfig';
import { useHistory, useLocation, useParams } from '@routing/router';

import { useFetchFolder } from '@api/bifolder';
import { useFetchBiTables } from '@api/biTables';
import invalidateCache from '@api/invalidateCache';
import type { TablesTableProps } from '@pages/TablesPage/TablesTable';
import {
  TABLES_TABLE_SEARCH_CONFIG,
  TABLES_TABLE_SORT_CONFIG,
} from '@pages/TablesPage/TablesTable';
import TablesPageTemplate from '@pages/TablesPageTemplate';
import { setParams, useUpdateFilters } from '@utils/filters';
import stripSpaces from '@utils/stripSpaces';
import useSearchParamsDataTypes from '@utils/useSearchParamsDataTypes';

import { CONTAINER_CONFIG, CONTAINER_CONFIG_DEFAULT } from './config';

const VISIBLE_COLUMNS: TablesTableProps['visibleColumns'] = [
  'search',
  'name',
  'description',
  'createdBy',
  'sourceTablesCount',
  'updatedAt',
  'tags',
  'downstreamObjectsCounts',
  'upstreamObjectsCounts',
];

const QUERY = stripSpaces(`{
  breadcrumbs,
  data_types,
  datasource_guid,
  datasource_type,
  description,
  dsuser_created_by {
    data_types,
    display_name,
    guid,
    name,
    user {
      guid,
      first_name,
      last_name
    }
  },
  external_id,
  guid,
  ingested_description,
  last_updated,
  name,
  richtext_description,
  row_count,
  source_tables_count,
  table_created_at,
  table_updated_at,
  user_description,
  ai_description,
  description_source,
  upstream_objects_counts,
  downstream_objects_counts,
}`);

const BITablesPage: React.FC = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { dsGuid, guid } = useParams<{ dsGuid?: string; guid: string }>();

  const { data: folderData } = useFetchFolder(guid, {
    enabled: Boolean(guid),
    params: { query: '{breadcrumbs, data_source{guid}}' },
  });

  const searchParamsTypes = useSearchParamsDataTypes();
  const dataSourceGuid = dsGuid ?? folderData?.dataSource.guid ?? '';

  const config = getContainerPageConfig({
    configObject: CONTAINER_CONFIG,
    dataTypes: searchParamsTypes,
    pathname,
  });

  const dataTypes = searchParamsTypes ?? config?.dataTypes;

  const filterService = useUpdateFilters(
    {
      order: CONTAINER_CONFIG_DEFAULT.initialSort?.parameter,
      page: 1,
      page_size: 20,
      query: QUERY,
    },
    TABLES_TABLE_SEARCH_CONFIG,
    TABLES_TABLE_SORT_CONFIG,
    CONTAINER_CONFIG_DEFAULT.initialSort?.parameter,
  );

  const { data, error, isLoading } = useFetchBiTables({
    enabled: !!dataSourceGuid,
    params: {
      ...setParams(filterService.filter),
      ...(searchParamsTypes?.dataType && { data_type: searchParamsTypes?.dataType }),
      dataSourceType: dataTypes?.dataSourceType!,
      datasources: dataSourceGuid,
      keepPreviousData: true,
    },
  });

  const refetch = () => {
    invalidateCache((keys) => [keys.biTables.all]);
  };

  return (
    <TablesPageTemplate
      breadcrumbs={folderData?.breadcrumbList}
      countItems={config?.countItems}
      data={data}
      dataTypes={dataTypes}
      dsGuid={dataSourceGuid}
      error={error}
      filterProps={{
        ...config?.filter,
        onSelectDataType: (value) => {
          history.replace({ pathname, search: value });
        },
      }}
      filterService={filterService}
      guid={guid}
      hideNameLink={false}
      initialSortState={[
        {
          desc: CONTAINER_CONFIG_DEFAULT.initialSort?.desc!,
          id: CONTAINER_CONFIG_DEFAULT.initialSort?.id as any,
        },
      ]}
      isLoading={isLoading}
      refetch={refetch}
      title={config?.title}
      useGlobalSearch={false}
      visibleColumns={VISIBLE_COLUMNS}
    />
  );
};

export default BITablesPage;
