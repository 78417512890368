import { Expose, Transform, Type } from 'class-transformer';
import moment from 'moment';

import { TeamModel } from '@api/teams/TeamModel';
import mapObjectToAvatar from '@models/mapObjectToAvatar';

class AnalyticsUserModel {
  static objectType: string = 'analyticsuser';

  objectType: string = AnalyticsUserModel.objectType;

  guid!: string;

  email!: string;

  @Expose({ name: 'avatar' })
  avatar?: string;

  @Expose({ name: 'first_name' })
  firstName?: string;

  @Expose({ name: 'last_name' })
  lastName?: string;

  @Expose({ name: 'created_on' })
  @Transform((value) => moment(value))
  createdOn?: moment.Moment;

  @Expose({ name: 'last_activity_in_range' })
  @Transform((value) => moment(value))
  lastActivity?: moment.Moment;

  @Expose({ name: 'activity_count' })
  activityCount?: number;

  @Type(() => TeamModel)
  team?: TeamModel;

  get fullName() {
    if (this.firstName && this.lastName) return `${this.firstName} ${this.lastName}`;
    if (this.firstName) return this.firstName;
    if (this.lastName) return this.lastName;
    return this.email;
  }

  get mappedAvatar() {
    return mapObjectToAvatar(this);
  }
}

export default AnalyticsUserModel;
