import { Editor, Element, Range, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import deleteTable from './deleteTable';

const deelteColumn = (editor: Editor) => {
  if (!editor.selection) return;

  // Find the table that we will be inserting into.
  const table = Editor.above(editor, {
    match: (n) => Element.isElement(n) && n.type === 'table',
  });

  // Find the table cell that we will use as a reference point for inserting the new column.
  const tableCell = Editor.above(editor, {
    match: (n) => Element.isElement(n) && (n.type === 'td' || n.type === 'th'),
  });

  const isCollapsed = Range.isCollapsed(editor.selection);

  if (table && tableCell && isCollapsed) {
    const tableCellPath = tableCell[1];
    const tableCellPositionPoint = tableCellPath[tableCellPath.length - 1];
    let shouldBreak = false;
    // Iterate through the table children. These could be a table header and/or a table body.
    table[0].children.forEach((child, tableChildPathPoint) => {
      if (shouldBreak) {
        return;
      }
      if (Element.isElement(child)) {
        if (child.type === 'thead') {
          /**
           * For every child row of the table header, generate the path to the cell we want to delete
           * and then remove that cell. Path is formed by copying the path to the table then pushing the index of
           * each child we've encountered to get to this point.
           *
           * Example:
           * Path to table: [2, 1]
           * Index of table header: 0
           * Index of row within header: 0 (represents the first row within the header)
           * Index of cell position we want to delete: 3 (represents the fourth cell position in the row);
           * Final path to insertion: [2, 1, 0, 0, 3]
           *
           * The same process is repeated for the table body below.
           */
          child.children.forEach((headerChild, tableHeadRowPathPoint) => {
            if (shouldBreak) {
              return;
            }

            // If this is the last column in the table we should just remove the entire table.
            if (
              Element.isElement(headerChild) &&
              headerChild.type === 'tr' &&
              headerChild.children.length === 1
            ) {
              deleteTable(editor, table);
              shouldBreak = true;
              return;
            }
            const cellPath = Array.from(table[1]);
            cellPath.push(tableChildPathPoint, tableHeadRowPathPoint, tableCellPositionPoint);
            Transforms.removeNodes(editor, { at: cellPath });
          });
        }

        if (child.type === 'tbody') {
          child.children.forEach((bodyChild, tableBodyChildPathPoint) => {
            const cellPath = Array.from(table[1]);
            cellPath.push(tableChildPathPoint, tableBodyChildPathPoint, tableCellPositionPoint);
            Transforms.removeNodes(editor, { at: cellPath });
          });
        }
      }
    });
  }
  ReactEditor.focus(editor);
};

export default deelteColumn;
