import { URL_CONFIG } from '@configs/urls/config';

import DataTypesModel from '@models/DataTypesModel';
import { makeUrl, urlFor } from '@utils/routing';

interface GetUrlArgs {
  dataType?: string;
  dataTypes?: DataTypesModel;
  defaultUrl?: string;
  dsGuid?: string;
  guid: string;
  objectType: string;
  parentGuid?: string;
  rawDataSourceGuid?: string;
  showSchemataPage?: boolean;
  type?: string;
  url?: string | null;
}

/** @todo Architecture. Discuss on how we can to avoid extending that function. */
const getUrl = ({
  dataType,
  dataTypes,
  defaultUrl = '#',
  dsGuid,
  guid,
  objectType,
  parentGuid,
  rawDataSourceGuid,
  showSchemataPage,
  type,
  url,
}: GetUrlArgs) => {
  // skip if it exists in the config file
  if (dataTypes?.dataSourceType! in URL_CONFIG) {
    return urlFor({
      dataTypes,
      guid,
      parentGuid,
      rawDataSourceGuid,
    });
  }

  /*
   * for the following, we don't need to load pages only
   * open or close the structure.
   */
  if (
    (objectType === 'schema' && !showSchemataPage) ||
    objectType === 'status_tag_container' ||
    objectType === 'category_tag_container'
  ) {
    return undefined;
  }

  if (objectType === 'projects_container') {
    return makeUrl('projects', guid.replace('.', '/'));
  }

  if (objectType === 'datasource') {
    return url || undefined;
  }

  if (objectType === 'dashboard') {
    return makeUrl('dashboard', guid);
  }

  if (objectType === 'lookmlproject') {
    return makeUrl('lookmlproject', guid);
  }

  if (objectType === 'lookmlmodel') {
    return makeUrl('lookmlmodel', guid);
  }

  if (objectType === 'schema' && showSchemataPage) {
    return makeUrl('schema', guid);
  }

  if (objectType === 'workbook') {
    return makeUrl('workbook', guid);
  }

  if (
    objectType === 'group' ||
    (objectType === 'bifolder' && type === 'looker' && dataType === 'group')
  ) {
    return makeUrl('groups', guid);
  }

  if (objectType === 'bifolder' && type === 'looker') {
    return makeUrl('folders', guid);
  }

  if (objectType === 'bifolder' && type === 'mode') {
    return makeUrl('spaces', guid);
  }

  if (objectType === 'bifolder' && type === 'metabase') {
    return makeUrl('metabase_bi_folder', guid);
  }

  if (objectType === 'data_studio_dashboard') {
    return makeUrl('dashboard', guid);
  }

  if (objectType === 'metabase_folders_container' && dsGuid) {
    return makeUrl('metabase', dsGuid);
  }

  if (objectType === 'bifolder' && type === 'data_studio') {
    return makeUrl('data_studio_bi_folder', guid);
  }

  if (objectType === 'data_studio_folders_container' && dsGuid) {
    return makeUrl('data_studio', dsGuid);
  }

  if (objectType === 'bifolder' && type === 'aws_s3') {
    return makeUrl('s3_folder', guid);
  }

  if (objectType === 'tag' && type === 'status') {
    return makeUrl('statusTag', guid);
  }

  if (objectType === 'tag' && type === 'category') {
    return makeUrl('categoryTag', guid);
  }

  if (objectType === 'collections_container' && dsGuid) {
    return makeUrl('allSpaces', dsGuid);
  }

  if (objectType === 'models_container' && dsGuid) {
    return makeUrl('allExplores', dsGuid);
  }

  if (objectType === 'views_container' && dsGuid) {
    return makeUrl('allViews', dsGuid);
  }

  if (objectType === 'folders_container' && dsGuid) {
    return makeUrl('allDashboards', dsGuid);
  }

  if (objectType === 'periscope_folders_container' && dsGuid) {
    return makeUrl('periscope_dashboards', dsGuid);
  }

  if (objectType === 'power_bi_folders_container' && dsGuid) {
    return makeUrl('power_bi', dsGuid);
  }

  if (objectType === 'dbt_test_container' && dsGuid) {
    return makeUrl('dbttests', dsGuid);
  }

  if (objectType === 'bifolder' && type === 'power_bi') {
    return makeUrl('power_bi_folder', guid);
  }

  if (objectType === 'sigma_folders_container' && dsGuid) {
    return makeUrl('sigma_dashboards', guid);
  }

  if (objectType === 'bifolder' && type === 'periscope') {
    return makeUrl('periscope_folder', guid);
  }

  if (objectType === 'folder' && type === 'dbt') {
    return makeUrl('project', guid);
  }

  if (objectType === 'document') {
    if (type && type === 'metric') {
      return makeUrl('metric', guid);
    }
    if (type && type === 'term') {
      return makeUrl('term', guid);
    }
    return makeUrl('docs', guid);
  }

  if (['menu-item', 'apps-menu-item'].includes(objectType)) {
    return url || '';
  }

  return urlFor(
    {
      dataSourceGuid: dsGuid,
      dataType,
      dataTypes,
      guid,
      objectType,
      parentGuid,
      rawDataSourceGuid,
      type,
    },
    false,
    defaultUrl,
  );
};

export default getUrl;
