import React, { useRef, useState } from 'react';
import xor from 'lodash/xor';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useDebouncedCallback } from 'use-debounce';

import TableLineageModel from '@api/lineage/TableLineageModel';
import Box from '@components/Box';
import { SortByPanel } from '@components/ExploreSidebar/SidebarTree/SidebarTree.styles';
import { desiredZoomTable, SearchOptions, searchOptions } from '@components/ExploreTree/atoms';
import IconButton from '@components/IconButton';
import Input from '@components/Input/Input.v1';
import OrderByButton from '@components/OrderByButton';
import { OrderBy } from '@components/OrderByButton/OrderByButton.styles';
import Text from '@components/Text';
import Checkbox from '@components/UI/Form/Checkbox';
import InputLabel from '@components/UI/Form/InputLabel';
import Icon from '@components/UI/Icon';
import useOverrideCmdCtrlKey from '@hooks/useOverrideCmdCtrlKey';
import theme from '@styles/theme';
import sortByType from '@utils/sortByType';
import wrapString from '@utils/wrapString';

import ErdExploreSidebarTreeItem from './ErdExploreSidebarTreeItem';

export interface ErdExploreConnectedTablesProps {
  onAllCheckboxesClick: (ids: string[]) => void;
  onCheckboxItemClick: (ids: string[]) => void;
  startingTableId: string | null;
  tables: TableLineageModel[];
  visibleTablesIds: string[];
}

const ErdExploreConnectedTables: React.FC<ErdExploreConnectedTablesProps> = ({
  onAllCheckboxesClick,
  onCheckboxItemClick,
  startingTableId,
  tables,
  visibleTablesIds,
}) => {
  const setZoomToTableId = useSetRecoilState(desiredZoomTable);
  const [search, setSearch] = useRecoilState(searchOptions);
  const [isInputVisible, setIsInputVisible] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const setSearchDebounced = useDebouncedCallback((val: SearchOptions) => {
    setSearch(val);
  }, 500);

  const handleShowInputToggle = useDebouncedCallback(() => {
    setIsInputVisible(!isInputVisible);

    if (!isInputVisible) {
      searchInputRef?.current?.focus();
    }
  }, 50);

  useOverrideCmdCtrlKey({ onPress: handleShowInputToggle });

  const updateSearch = (sortBy: SearchOptions['sortBy'], orderBy: OrderBy) => {
    setSearch((prev) => ({
      ...prev,
      orderBy,
      sortBy,
    }));
  };

  const filteredItems =
    tables
      ?.filter(({ key, name }) => {
        if (!search?.keyword || startingTableId === key) {
          return true;
        }

        const searchString = search.keyword?.toLocaleLowerCase()?.toLocaleLowerCase();
        const stringToMatch = name?.toLocaleLowerCase();
        const match = stringToMatch?.includes(searchString);

        if (search.exclude) {
          return !match;
        }

        return match;
      })
      ?.sort((a, b) => {
        const valuesMap = {
          description: { a: a?.description, b: b?.description },
          impactScore: {},
          name: { a: a?.name, b: b?.name },
          popularity: { a: a?.popularity?.popularity ?? 0, b: b?.popularity?.popularity ?? 0 },
          usage: {},
        };

        return sortByType({ ...valuesMap[search.sortBy], orderBy: search.orderBy });
      }) ?? [];

  return (
    <Box compHeight="100%" overflowY="auto">
      <Box>
        {isInputVisible && (
          <Box alignItems="center" compDisplay="flex" flexGrow={1} my={1} px={2} spacing={2}>
            <Input
              ref={searchInputRef as any}
              endIcon={
                <InputLabel
                  alignItems="center"
                  compDisplay="flex"
                  fontSize="body1"
                  verticalAlign="middle"
                >
                  <Checkbox
                    checked={search.exclude}
                    onChange={(e) => setSearch({ ...search, exclude: Boolean(e.target.checked) })}
                  />
                  Exclude
                </InputLabel>
              }
              onChange={({ target }) => setSearchDebounced({ ...search, keyword: target.value })}
              placeholder="Filter by text"
              type="text"
            />
          </Box>
        )}
        <SortByPanel px={2} spacing={1}>
          <Box compHeight="18px">
            <Checkbox
              checked={visibleTablesIds?.length >= tables?.length}
              name="select all"
              onClick={(e) => {
                onAllCheckboxesClick(
                  e.currentTarget.checked ? tables?.map((table) => table.key) ?? [] : [],
                );
              }}
              title="Select all"
            />
          </Box>
          <Box backgroundColor="#f2f2f2" px={0.5} py={1}>
            <IconButton onClick={handleShowInputToggle} variant="clear">
              <Icon name="search" size="16px" />
            </IconButton>
          </Box>
          <Box compDisplay="flex" compWidth="100%" py={1}>
            <Box alignItems="center" compDisplay="flex" compWidth="auto" flexGrow={1} pl={1}>
              <OrderByButton
                onClick={(orderBy) => {
                  updateSearch('name', orderBy);
                }}
                orderBy={search.sortBy === 'name' ? search.orderBy : 'default'}
              >
                Name {wrapString(filteredItems?.length)}
              </OrderByButton>
            </Box>
            <Box alignItems="center" compDisplay="flex" justifyContent="end">
              <OrderByButton
                onClick={(orderBy) => {
                  updateSearch('popularity', orderBy);
                }}
                orderBy={search.sortBy === 'popularity' ? search.orderBy : 'default'}
              >
                Popularity
              </OrderByButton>
            </Box>
          </Box>
        </SortByPanel>
      </Box>
      <ul>
        {filteredItems && filteredItems?.length > 0 ? (
          filteredItems?.map((table) => (
            <ErdExploreSidebarTreeItem
              key={table.key}
              dataTypes={table.dataTypes}
              guid={table.guid}
              hasDbtDwhLink={table.dataSourceType === 'dbt' ? false : Boolean(table.linkedObjs)}
              id={table.key}
              isChecked={visibleTablesIds.includes(table.key)}
              label={table.name ?? table.key}
              onCheckboxItemClick={(_e, id) => onCheckboxItemClick(xor(visibleTablesIds, [id]))}
              onClick={() => {
                setZoomToTableId(table.key);
              }}
              popularity={table.popularity}
              query={table.query}
            />
          ))
        ) : (
          <Text color={theme.colors.gray[600]} fontSize="13px" px={2}>
            No results found.
          </Text>
        )}
      </ul>
    </Box>
  );
};

export default ErdExploreConnectedTables;
