import React, { forwardRef } from 'react';

import type { InputProps } from '@components/Input/Input.v1';
import Input from '@components/Input/Input.v1';
import Icon from '@components/UI/Icon';

export interface SearchInputProps
  extends Pick<InputProps, 'disabled' | 'onChange' | 'value' | 'variantColor'> {
  onClear?: () => void;
}

const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  ({ disabled, onClear, value, ...other }, ref) => {
    return (
      <Input
        ref={ref}
        disabled={disabled}
        endIcon={
          value && (
            <Icon color="currentColor" name="close" onClick={onClear} role="button" size="12px" />
          )
        }
        startIcon={<Icon color="currentColor" name="search" size="16px" />}
        value={value}
        {...other}
      />
    );
  },
);

export default SearchInput;
