import React from 'react';
import { DBT_DOCS_SYNC_DOCS_URL } from '@constants';

import { useFetchSyncBackRepositories } from '@api/syncBack';
import Box from '@components/Box';
import DocsButton from '@components/Button/BIButtons/DocsButton';
import IconButton from '@components/IconButton';
import LayoutMainGrid from '@components/Layout/LayoutMainGrid';
import AdminGitReposTable from '@components/Table/AdminGitReposTable';
import Tabs from '@components/Tabs/Tabs';
import GithubConfigurationWizard from '@components/Wizard/GithubConfigurationWizard';
import { useModal } from '@context/Modal';
import AdminTitleView from '@pages/Admin/AdminTitleView/AdminTitleView';
import theme from '@styles/theme';
import { Filter } from '@utils';
import { FilterOptions } from '@utils/filters';
import MetadataDecorator from '@utils/MetadataDecorator';
import stripSpaces from '@utils/stripSpaces';

import DeleteRepositoryConfirmationModal from './DeleteRepositoryConfirmationModal';

const PAGE_TITLE = 'GitHub Configuration';

export const REPOSITORIES_REQUEST_CONFIG: FilterOptions = {
  order: '-name',
  page: 1,
  page_size: 20,
  query: stripSpaces(`{
    guid,
    scm_provider,
    url,
    repository,
    data_source{
      name,
      guid,
      type,
    },
    created_by: {
      guid,
      first_name,
      last_name,
      team: {
        guid,
        name
      },
      email,
      avatar,
      is_active
    },
    last_successful_sync,
  }`),
};

const SORT_CONFIG: { [key: string]: string } = {
  creator: 'created_by_name',
  dataSource: 'data_source_name',
  lastSuccessfulSync: 'last_successful_sync',
  name: 'name',
};

const AdminGithubPage: React.FC = () => {
  const { MODAL_IDS, checkModalOpened, closeModal, getModalContent, openModal } = useModal();

  const FilterService = Filter.useUpdateFilters(
    REPOSITORIES_REQUEST_CONFIG,
    {},
    SORT_CONFIG,
    '-name',
  );
  const { filter } = FilterService;

  const { data: repositories, isLoading } = useFetchSyncBackRepositories({
    params: {
      ...Filter.setParams(filter),
      provider: 'github',
    },
    staleTime: 0,
  });

  const totalPages =
    repositories && filter.page_size ? Math.ceil(repositories.count / filter.page_size) : 1;

  const content = () => (
    <AdminGitReposTable
      data={repositories?.results}
      filterService={FilterService}
      itemCount={repositories?.count}
      loading={isLoading}
      totalPages={totalPages}
    />
  );
  const createGithubRepositoryProps = getModalContent(MODAL_IDS.createGithubRepository);
  const deleteGithubRepositoryProps = getModalContent(MODAL_IDS.deleteGithubRepository);

  return (
    <>
      <MetadataDecorator title={PAGE_TITLE} />
      <LayoutMainGrid>
        <Box alignItems="start" position="relative">
          <AdminTitleView
            action={
              <>
                <Box alignItems="center" compDisplay="flex" ml={1} mr={0.75}>
                  <DocsButton
                    href={DBT_DOCS_SYNC_DOCS_URL}
                    rel="noopener noreferrer"
                    target="_blank"
                  />
                </Box>
                <IconButton
                  iconColor={theme.colors.gray[500]}
                  iconName="plus"
                  iconSize="18px"
                  label="Add"
                  onClick={() => {
                    openModal(MODAL_IDS.createGithubRepository, undefined);
                  }}
                />
              </>
            }
            subtitle="Manage dbt Git repository connections"
            title={PAGE_TITLE}
          />
          <Box>
            <Tabs
              config={[
                {
                  component: content,
                  id: '0',
                  label: 'Git Repo',
                },
              ]}
            />
          </Box>
        </Box>
      </LayoutMainGrid>
      {checkModalOpened(MODAL_IDS.createGithubRepository) && (
        <GithubConfigurationWizard
          onClose={() => closeModal(MODAL_IDS.createGithubRepository)}
          repository={createGithubRepositoryProps?.repository}
        />
      )}
      {checkModalOpened(MODAL_IDS.deleteGithubRepository) && deleteGithubRepositoryProps && (
        <DeleteRepositoryConfirmationModal
          onClose={() => closeModal(MODAL_IDS.deleteGithubRepository)}
          redirectOnDelete={repositories?.count === 1}
          repository={deleteGithubRepositoryProps.repository}
        />
      )}
    </>
  );
};

export default AdminGithubPage;
