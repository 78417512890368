import styled from '@emotion/styled';

const iconMap = {
  asc: '\\f0d8',
  default: '\\f0dc',
  desc: '\\f0d7',
};

export type OrderBy = 'default' | 'asc' | 'desc';

export interface OrderByButtonStylesProps {
  orderBy: OrderBy;
}

export const StyledOrderByButton = styled.button<OrderByButtonStylesProps>`
  white-space: nowrap;
  font-size: ${({ theme }) => theme.typography.fontSizes.body1};
  color: ${({ theme }) => theme.colors.v1.gray[800]};
  font-weight: ${({ theme }) => theme.typography.fontWeights.bold};

  &::after {
    color: ${({ theme }) => theme.colors.v1.gray[800]};
    content: "${({ orderBy }) => iconMap[orderBy as OrderBy]}";
    display: inline-block;
    font-family: 'Icons';
    font-style: normal;
    height: auto;
    margin-left: ${({ theme }) => theme.space(0.5)};
    opacity: ${({ orderBy }) => (orderBy === 'default' ? 0.2 : 0.8)};
    text-decoration: inherit;
    vertical-align: middle;
    width: 1em;
  }


  &:hover::after {
    content: "${({ orderBy }) => iconMap[orderBy]}";
    opacity: 0.8;
  }

  &:focus {
    outline: none;
  }
}
`;
