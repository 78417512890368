import { plainToClass } from 'class-transformer';
import moment from 'moment';

import { DataSourceModel } from '@models/DataSourceModel';
import { PaginatedResponse } from '@models/PaginatedResponse';

export type ConnectionAlertLevel = 'warn' | 'error';

export type ConnectionAlertModel = ReturnType<typeof mapConnectionAlert>;

export interface RawConnectionAlert {
  data_source: {
    guid: string;
    last_ingested_on?: string;
    name: string;
    type: string;
  };
  description: string;
  guid: string;
  last_seen: string;
  level: ConnectionAlertLevel;
  title: string;
}

const mapConnectionAlert = (data: RawConnectionAlert) => {
  const { data_source: dataSource, description, guid, last_seen: lastSeen, level, title } = data;
  return {
    dataSource: plainToClass(DataSourceModel, dataSource),
    description,
    guid,
    lastSeen: lastSeen ? moment(lastSeen) : undefined,
    level,
    objectType: 'connectionAlert',
    title,
  };
};

export type MappedConnectioAlerts = Record<string, Array<ConnectionAlertModel>>;

export const mapConnectionAlerts = (data: PaginatedResponse<RawConnectionAlert>) => {
  const alertsByDS = data.results.reduce((acc, cur) => {
    const dataSourceGuid = cur.data_source.guid;
    if (!acc[dataSourceGuid]) {
      acc[dataSourceGuid] = [];
    }

    acc[dataSourceGuid].push(mapConnectionAlert(cur));
    return acc;
  }, {} as MappedConnectioAlerts);

  return alertsByDS;
};

export default ConnectionAlertModel;
