import React from 'react';
import { Cell } from 'react-table';

import { DsUserModel } from '@api/dsusers/DsUserModel';
import type { ColumnConfig } from '@components/Table/Table/types';
import { Filter } from '@utils';

import LinkedCell from '../Cells/LinkedCell';
import SearchHeader from '../Cells/SearchHeader';
import UserCell from '../Cells/UserCell';
import Table from '../Table';
import TableStyled from '../TableStyled';

const INITIAL_SORT_STATE = [
  {
    desc: false,
    id: 'name',
  },
];

interface ModeConnectionsTableProps {
  data?: DsUserModel[];
  filterService: Filter.FilterServiceInterface;
  loading: boolean;
  selectedRowIds: { [guid: string]: boolean };
  setSelectedRowIds: React.Dispatch<React.SetStateAction<{}>>;
  totalPages?: number;
}

const ModeConnectionsTable: React.FC<ModeConnectionsTableProps> = ({
  data,
  filterService,
  loading,
  selectedRowIds,
  setSelectedRowIds,
  totalPages,
}) => {
  const [isShowFilter, setShowFilter] = React.useState(false);
  const { changePage, filter, search } = filterService;

  const toggleFilter = React.useCallback(() => setShowFilter((prev) => !prev), [setShowFilter]);

  const columns: ColumnConfig<DsUserModel>[] = React.useMemo(
    () => [
      {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      {
        Cell: (props: Cell<DsUserModel>) => {
          const { dataSource } = props.row.original;
          if (!dataSource) return null;
          return <LinkedCell {...props} item={dataSource} noLink showIcon />;
        },
        Header: 'Data Source',
        accessor: (d) => d.dataSource?.name,
        disableFilters: true,
        id: 'datasource',
        width: '50%',
      },
      {
        Cell: (props: Cell<DsUserModel>) => {
          const dsUser = props.row.original;
          return <UserCell {...props} user={dsUser} />;
        },
        Header: 'Data Source User',
        accessor: (d) => d.name,
        disableHiding: true,
        id: 'name',
        width: '50%',
      },
    ],
    [],
  );

  const getRowId = React.useCallback((row: Partial<DsUserModel>) => row.guid!, []);

  return (
    <TableStyled>
      <Table
        basic="very"
        changePage={changePage}
        className="table-full"
        columns={columns}
        data={data || []}
        disableColumnFiltering
        disablePageRefresh
        getRowId={getRowId}
        initialState={{
          pageIndex: filter.page ? filter.page - 1 : 0,
          selectedRowIds,
          sortBy: INITIAL_SORT_STATE,
        }}
        loading={loading}
        manualFilters
        manualPagination
        selectable
        setFilters={search}
        setSelectedRowIds={setSelectedRowIds}
        showFilter={isShowFilter}
        sortable
        toggleFilter={toggleFilter}
        totalPages={totalPages}
      />
    </TableStyled>
  );
};

export default React.memo<ModeConnectionsTableProps>(ModeConnectionsTable);
