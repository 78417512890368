import React from 'react';

import { DatabaseModel } from '@api/databases/DatabaseModel';
import type { TabRoutesConfig } from '@components/TabsRouter';
import TabsRouter from '@components/TabsRouter';

import MetricRelatedDashboards from './MetricsRelatedDashboards';

export interface MetricTabProps {
  database?: DatabaseModel;
  fullTableName: string;
  guid: string;
  isEditable?: boolean;
  loading?: boolean;
  measureFieldObjectType?: string;
  parentGuid?: string;
}

export default function RealatedSimilarTab(props: MetricTabProps) {
  const tabObjects: TabRoutesConfig[] = [
    {
      component: () => <MetricRelatedDashboards {...props} />,
      default: true,
      label: 'Dashboards',
      route: '/related-dashboards',
    },
  ];

  return <TabsRouter config={tabObjects} subTabs />;
}
