import paginatedTransform from '@api/paginatedTransform';
import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import { PaginatedResponse } from '@models/PaginatedResponse';

import { MentionModel } from './MentionModel';

export const mentionsCacheKeys = {
  mentions: ['mentions'],
};

export const fetchMentionsSelect = paginatedTransform(MentionModel);
export const useFetchMentions = (options?: UseFetchOptions<PaginatedResponse<MentionModel>>) => {
  return useFetch<PaginatedResponse<MentionModel>>({
    ...options,
    cacheTime: 0,
    queryKey: [...mentionsCacheKeys.mentions, options?.params],
    select: fetchMentionsSelect,
    url: '/mentions/',
  });
};
