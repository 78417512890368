import React, { useEffect, useMemo, useRef, useState } from 'react';
import { RouterLink, useHistory } from '@routing/router';
import { useDebounce, useDebouncedCallback } from 'use-debounce';

import { useFetchActivity } from '@api/activity';
import { searchCacheKeys, useFetchSearch, useFetchSearchSuggestions } from '@api/search';
import { SearchModel } from '@api/search/SearchModel';
import type { SearchResult as SearchResultType } from '@api/search/types';
import {
  DEFAULT_SIDEBAR_Z_INDEX,
  useAppMainSidebar,
} from '@components/AppMainSidebar/AppMainSidebar.v1/useAppMainSidebar';
import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import getUrl from '@components/Tree/getUrl';
import Icon from '@components/UI/Icon';
import type { Option } from '@components/UI/Select/types';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import { useUserContext } from '@context/User';
import fetchClient from '@lib/fetchClient';
import theme from '@styles/theme';
import stripSpaces from '@utils/stripSpaces';

import { DatasourceTabV1, tabConfig } from './DatasourceTabs/config';
import SearchBarShortcut from './SearchBarShortcut';
import {
  StyledSearchBarBackdrop,
  StyledSearchBarDatasourceTabsV1,
  StyledSearchBarSearch,
} from './SearchBarV1.styles';
import SearchResult from './SearchResult';
import AboveSearchContainer from './SearchResult/AboveSearchContainer.styles';
import { SearchResultContainer } from './SearchResult/SearchResult.styles';
import SearchSuggestionItem from './SearchResult/SearchSuggestionItem';

export const PLACEHOLDER_TEXT = 'Search for datasets, dashboards, or users';

const DEFAULT_ACTIVE_TAB = tabConfig.all;
const emptyResult: SearchModel[] = [];

const query = stripSpaces(`{
  guid,
  target{
    object_type,
    obj{
      tagged_items{
        tag
      },
    -downstream_objects_counts,
    -upstream_objects_counts
    }
  }
}`);

const defaultSearchParams = {
  no_popularity: false,
  tags_search: true,
};

interface IconRendererParams {
  isLoading: boolean;
  isShow: boolean | undefined;
}

const LeftIcon = ({ isLoading, isShow }: IconRendererParams) => {
  const searchIconColor = 'gray.700';
  const loaderIconColor = 'primary.500';
  const loaderBackgroundColor = undefined;
  const loadingSpinnerSize = 3;
  const searchIconSize = '24px';
  const searchIconMarginLeft = 0.5;

  return (
    <Box
      alignItems="center"
      compDisplay="flex"
      compWidth={searchIconSize}
      justifyContent="center"
      mx={searchIconMarginLeft}
      noDefault
    >
      {isShow && isLoading ? (
        <CircularLoader
          bgColor={loaderBackgroundColor}
          borderWidth={2}
          color={loaderIconColor}
          compSize={loadingSpinnerSize}
        />
      ) : (
        <Icon color={searchIconColor} name="search" size={searchIconSize} />
      )}
    </Box>
  );
};

const filtersToString = (filters: DatasourceTabV1['filters']) =>
  filters
    .map((filter) => filter.indexes)
    .flat(1)
    .join(',');

const SearchBar: React.FC = () => {
  const history = useHistory();
  const { organization } = useUserContext();
  const [text, setText] = useState<string>('');
  const [requestId, setRequestId] = useState<string | undefined>();
  const [textDebounced] = useDebounce(text, 500);
  const [facets, setFacets] = useState<SearchResultType<SearchModel>['facets']>();
  const [isShow, setIsShow] = useState<boolean | undefined>(false);
  const [filters, setFilters] = useState(DEFAULT_ACTIVE_TAB.filters);
  const allowSearchPageOnEnterRef = useRef(true);
  const segment = useSegmentContext();
  const [activeTab, setActiveTab] = useState(DEFAULT_ACTIVE_TAB);
  const shouldUpdateFacets = useRef(true);
  const isSuperKey = useRef(false);
  const { setZIndex: setAppMainSidebarZIndex } = useAppMainSidebar();

  const searchParams = {
    ...defaultSearchParams,
    custom_attributes_search: organization?.settings?.useCustomAttributesSearch,
    include: filtersToString(filters),
    q: textDebounced,
  };

  const { data: activityData } = useFetchActivity({
    params: {
      distinct: true,
      order: '-performed_on',
      page_size: 10,
      query,
      types: 'view',
      users: 'me',
    },
  });

  const { data: normalData, isFetching: isFetchingNormalData } = useFetchSearch({
    enabled: Boolean(searchParams.q),
    keepPreviousData: true,
    onSuccess: ({ requestId: internalRequestId }) => {
      setRequestId(internalRequestId);
    },
    params: {
      no_fuzzy: true,
      ...searchParams,
    },
  });

  const { data: fuzzyData, isFetching: isFetchingFuzzyData } = useFetchSearch({
    enabled: !isFetchingNormalData && normalData?.total === 0 && Boolean(searchParams.q),
    keepPreviousData: true,
    onSuccess: ({ requestId: internalRequestId }) => {
      setRequestId(internalRequestId);
    },
    params: {
      no_fuzzy: false,
      ...searchParams,
    },
  });

  const { data: suggestionsData } = useFetchSearchSuggestions({
    enabled: !isFetchingNormalData && normalData?.total === 0 && Boolean(searchParams.q),
    params: {
      q: searchParams.q,
    },
  });

  const activities = activityData?.results
    ?.map((item) => item?.target?.obj)
    .filter((item) => item.name && item.name.toLowerCase() !== 'deleted item');
  const data = useMemo(
    () => (normalData && normalData?.total > 0 ? normalData : fuzzyData),
    [fuzzyData, normalData],
  );
  const isFetchingData = isFetchingNormalData || isFetchingFuzzyData;
  const showActivities = !data && !textDebounced;
  const showFuzzySearchSuggestion =
    Boolean(textDebounced) &&
    (!normalData || normalData?.total === 0) &&
    suggestionsData &&
    suggestionsData?.texts?.length > 0;

  useEffect(() => {
    shouldUpdateFacets.current = true;
    setFilters(DEFAULT_ACTIVE_TAB.filters);
    setActiveTab(DEFAULT_ACTIVE_TAB);
  }, [textDebounced]);

  useEffect(() => {
    if (shouldUpdateFacets.current && !isFetchingData) {
      setFacets(data?.facets);
      shouldUpdateFacets.current = false;
    }
  }, [data, isFetchingData]);

  const resetSearch = () => {
    setText('');
    setIsShow(false);
    setActiveTab(DEFAULT_ACTIVE_TAB);
    shouldUpdateFacets.current = true;
    setFilters(DEFAULT_ACTIVE_TAB.filters);
  };

  const trackSelectedValue = (result: SearchModel) => {
    const { guid, indexPosition } = result;

    if (indexPosition !== undefined) {
      segment?.track(SegmentTrackEventName.RegularSearchResultClicked, {
        guid,
        rank: indexPosition,
        tab: activeTab?.name,
        uuid: requestId,
      });
    }
  };

  const handleResultSelect = (option?: Option) => {
    if (!option) {
      // Send to search results page on enter if we haven't already selected a result.
      if (allowSearchPageOnEnterRef.current) {
        // Send to search results page on enter if we haven't already selected a result.
        const fuzzySearchParam = showFuzzySearchSuggestion
          ? `&fuzzy=${showFuzzySearchSuggestion}`
          : '';
        segment?.track(SegmentTrackEventName.FullSearchPageOpened, {
          fuzzy: showFuzzySearchSuggestion,
          query: text,
        });
        history.push(`/search?q=${encodeURIComponent(text)}${fuzzySearchParam}`);
        resetSearch();
      }
    } else {
      trackSelectedValue(option?.original);

      if (isSuperKey.current) {
        window.open(option?.key, '_blank');
      } else {
        history.push({ pathname: option?.key });
        resetSearch();
      }
    }
  };

  const invalidateSearchResultsDebounced = useDebouncedCallback(() => {
    fetchClient.invalidateQueries(searchCacheKeys.recentSearches);
  }, 1000);

  const handleSearchChange = (value = ''): void => {
    setText(value);
    setIsShow(true);
    allowSearchPageOnEnterRef.current = true;

    if (value.length < 1) {
      resetSearch();
    }

    if (value) {
      invalidateSearchResultsDebounced();
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Control' || event.key === 'Meta') {
        isSuperKey.current = true;
      }

      if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        event.preventDefault();
        setIsShow(true);
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === 'Control' || event.key === 'Meta') {
        isSuperKey.current = false;
      }
    };

    document.body.addEventListener('keydown', handleKeyDown);
    document.body.addEventListener('keyup', handleKeyUp);
    return () => {
      document.body.removeEventListener('keydown', handleKeyDown);
      document.body.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const results = useMemo(() => {
    if (showActivities) return !activities ? emptyResult : activities;

    return data?.data ?? emptyResult;
  }, [activities, data, showActivities]);

  // eslint-disable-next-line react/no-unstable-nested-components
  const ResultRenderer = ({ option }: { option: Option }) => {
    return (
      <SearchResultContainer key={option.value} as={RouterLink} to={option.key}>
        <SearchResult
          searchItem={option.original}
          searchSuggestions={suggestionsData?.texts}
          searchText={text}
        />
      </SearchResultContainer>
    );
  };

  const aboveResults = useMemo(() => {
    const filterTabs = (
      <StyledSearchBarDatasourceTabsV1
        data={data}
        facets={facets}
        hideCount
        onFilterChange={(f, tab) => {
          setFilters(f);
          setActiveTab(tab);
        }}
        showResultCount
        subTabs={false}
      />
    );

    if (showFuzzySearchSuggestion) {
      return (
        <>
          <SearchSuggestionItem searchSuggestionTexts={suggestionsData?.texts} text={text} />
          {filterTabs}
        </>
      );
    }

    if (data && Boolean(text)) {
      return filterTabs;
    }

    if (showActivities && activities && activities.length > 0) {
      return <AboveSearchContainer>Recently Viewed</AboveSearchContainer>;
    }

    return null;
  }, [
    activities,
    data,
    facets,
    showActivities,
    showFuzzySearchSuggestion,
    suggestionsData?.texts,
    text,
  ]);

  const options: Option[] = useMemo(
    () =>
      results?.map((el) => ({
        bordered: true,
        key: el.routePath ?? el.routerUrl ?? getUrl({ ...el, showSchemataPage: true }),
        original: el,
        text: el.title ?? el.name,
        value: el.guid,
      })) ?? [],
    [results],
  );

  useEffect(() => {
    setAppMainSidebarZIndex(isShow ? theme.zIndex.sidebar : DEFAULT_SIDEBAR_Z_INDEX);
  }, [isShow, setAppMainSidebarZIndex]);

  return (
    <>
      {isShow && <StyledSearchBarBackdrop />}
      <StyledSearchBarSearch
        allowEmptySelection
        closeOnScroll={false}
        highlightFirstOnOptionsUpdate={false}
        isLoading
        isOpen={isShow}
        leftIcon={<LeftIcon isLoading={isFetchingData} isShow={isShow} />}
        onResultSelect={handleResultSelect}
        onSearchChange={handleSearchChange}
        optionListMaxHeight="432px"
        options={options}
        optionsListHeaderElement={aboveResults}
        placeholder={PLACEHOLDER_TEXT}
        renderCustomOption={ResultRenderer}
        rightIcon={
          isShow ? (
            <Icon
              className="search-clear"
              color="gray.800"
              mr={0.5}
              name="close"
              onClick={() => resetSearch()}
              size="14px"
            />
          ) : (
            <SearchBarShortcut />
          )
        }
        searchValue={text}
        setIsOpen={setIsShow}
        showClearButton={false}
        showOptionsOnSearch={false}
      />
    </>
  );
};

export default SearchBar;
