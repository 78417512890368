import React from 'react';

import { useFetchDataSourceConfigInit, usePatchIngestionConfig } from '@api/ingestion';
import DataSourceIngestionConfigModel from '@api/ingestion/DataSourceIngestionConfigModel';
import Alert from '@components/Alert';
import { CenterLoader, IndeterminateLoaderModal } from '@components/IndeterminateLoader';
import Text, { defaultParagraphStyles } from '@components/Text';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import { DataSourceModel } from '@models/DataSourceModel';

import { BoxWrapper } from '../DataSourceAddStep';
import ErrorDescriptionMarkdown from '../ErrorDescriptionMarkdown';

interface Props {
  dataSource: DataSourceModel;
  onConfigUpdated: (config: DataSourceIngestionConfigModel) => void;
  prevStep: () => void;
}

const DatabaseIngestionConfigStep: React.FC<Props> = ({ dataSource, onConfigUpdated }) => {
  const segment = useSegmentContext();
  const { error: errorSave, mutate } = usePatchIngestionConfig(dataSource.guid);

  useFetchDataSourceConfigInit(dataSource.guid, {
    onSuccess: (ingestionConfig) => {
      const configToSave = {
        databases: ingestionConfig.databases.map((db) => ({
          name: `${db.name}`,
          shouldIngest: true,
        })),
      };
      mutate(configToSave, { onSuccess: onConfigUpdated });
      segment?.track(SegmentTrackEventName.ImportData_SelectDatabasesNextButtonClicked, {
        selectedDatabases: configToSave.databases.length,
        totalDatabases: ingestionConfig.databases.length,
      });
    },
  });

  return (
    <BoxWrapper>
      {errorSave && errorSave.status === 500 && (
        <Alert title="Couldn't save changes" type="error">
          {errorSave.data || 'Please try again later.'}
        </Alert>
      )}
      <ErrorDescriptionMarkdown error={errorSave} />
      {errorSave &&
        errorSave.data &&
        errorSave.data.databases &&
        Object.entries(errorSave.data.databases).map(([name, error]) => (
          <Alert key={name} title="Invalid database" type="error">
            {`${name} - ${error}`}
          </Alert>
        ))}
      <CenterLoader>
        <IndeterminateLoaderModal
          active
          content={
            <Text {...defaultParagraphStyles} textAlign="center">
              Detecting databases and schemas from data source
            </Text>
          }
          indeterminate
          inline="centered"
          size="big"
        />
      </CenterLoader>
    </BoxWrapper>
  );
};

export default DatabaseIngestionConfigStep;
