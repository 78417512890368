import React from 'react';

import Icon from '@components/UI/Icon';

import { StyledSearchBarShortcut, StyledSearchBarShortcutItem } from './SearchBarShortcut.styles';

const SearchBarShortcut = () => {
  const isMacOs = navigator.userAgent.toLowerCase().includes('mac');
  return (
    <StyledSearchBarShortcut>
      <StyledSearchBarShortcutItem isMacOs={isMacOs}>
        {isMacOs ? <Icon color="currentcolor" name="command" size="14px" /> : 'Ctrl'}
      </StyledSearchBarShortcutItem>
      <StyledSearchBarShortcutItem>K</StyledSearchBarShortcutItem>
    </StyledSearchBarShortcut>
  );
};

export default SearchBarShortcut;
