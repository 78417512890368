import styled from '@emotion/styled';

const ConnectedAccountsStyled = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 17rem;
  background-color: #f6f9fc;
  max-width: 17rem;
  padding: 16px 20px;
  margin-top: 0.375rem;
  line-height: 1.25rem;

  .heading {
    font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
    font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
    margin-bottom: 0.25rem;
  }

  .user-item {
    color: inherit;
    height: 1.75rem;
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
  }
`;

export default ConnectedAccountsStyled;
