const useSearchHighlightedWords = (query: string, searchSuggestions: string[] = []) => {
  const wordsList = [query, ...searchSuggestions];

  const words = wordsList.reduce((acc, word) => {
    const splitWords = word.split(/[.| ]+/).filter((splitWord) => splitWord);
    acc.push(...splitWords);
    return acc;
  }, [] as string[]);

  return words;
};

export default useSearchHighlightedWords;
