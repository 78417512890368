import React from 'react';
import getDebug from 'debug';

import type { TableSnapshotModel } from '@api/tables/TableSnapshotModel';
import Box from '@components/Box';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import Icon from '@components/UI/Icon';

const debug = getDebug('selectstar:preview-table');

interface PreviewTableProps {
  columns: TableSnapshotModel['columns'];
  loading?: boolean;
  rows: TableSnapshotModel['rows'];
}

const PreviewTable: React.FC<PreviewTableProps> = ({ columns, loading, rows }) => {
  const allColumns: ColumnConfig<PreviewTableProps['rows'][number]>[] = React.useMemo(
    () =>
      columns.map((col) => ({
        Header: () => (
          <Box alignItems="center" compDisplay="flex" compWidth="max-content" gap={0.5}>
            <Icon name={col.dataTypes?.icons.dataType!} size="16px" />
            {col.name}
          </Box>
        ),
        accessor: (d) =>
          col.dataTypes?.dataType === 'object' ? JSON.stringify(d[col.name]) : d[col.name],
        disableFilters: true,
        disableResizing: true,
        id: col.name,
        width: 'max-content',
      })),
    [columns],
  );

  debug('rows', rows);

  return (
    <TableStyled scroll>
      <Table
        basic="very"
        columns={allColumns}
        compact
        data={rows}
        disableColumnFiltering
        disableFilters
        disablePagination
        disableResizing
        disableRowSelect
        disableSortBy
        loading={loading}
        unstackable
      />
    </TableStyled>
  );
};

export default PreviewTable;
