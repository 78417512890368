import React from 'react';

import Box from '@components/Box';
import Text from '@components/Text';
import { TreeProps } from '@components/Tree/Tree.v2';
import { TreeKey } from '@components/Tree/Tree.v2/types';
import HR from '@components/UI/HR';
import type { Option as OptionType } from '@components/UI/Select';
import Option from '@components/UI/Select/Option';
import { GetItemPropsParams } from '@components/UI/Select/useSelect';
import theme from '@styles/theme';

import { StyledTreeOptionsItem, StyledTreeV2 } from './TreeOptions.styles';

export interface TreeOptionsProps extends Pick<TreeProps<OptionType>, 'offset'> {
  closeMenu?: () => void;
  expandedKeys?: TreeKey[];
  filteredItems: OptionType[];
  flatOptions: OptionType[];
  getItemProps: (params: GetItemPropsParams) => Record<string, any>;
  isMulti?: boolean;
  selectedItems: OptionType[];
  setSelectedItems?: (items: OptionType[]) => void;
  updateFlatOptions: (expandedOption: OptionType, isExpanded: boolean) => void;
  useNewLayout?: boolean;
}

const TreeOptions = ({
  closeMenu,
  expandedKeys = [],
  filteredItems,
  flatOptions,
  getItemProps,
  isMulti = false,
  offset,
  selectedItems,
  setSelectedItems,
  updateFlatOptions,
  useNewLayout,
}: TreeOptionsProps) => {
  return (
    <StyledTreeV2
      allowCaret={({ original: item }) => item?.children?.length > 0 && !item.isDividerItem}
      allowDataLoad={({ original: item }) => Boolean(item?.children?.length > 0)}
      checkboxProps={({ original }) => ({
        checked: selectedItems.some((selected) => selected.value === original.value),
        indeterminate: selectedItems.find((item) => item.value === original.value)
          ?.checkboxIndeterminate,
        variantColor: selectedItems.find((item) => item.value === original.value)?.checkboxColor,
      })}
      disableHover={({ original: item }) => item.isDisabled}
      expandedKeys={expandedKeys}
      expandOnNodeClick={false}
      getKey={(item) => item.value}
      getNodeExtraProps={({ original: item }) => {
        const { original } = item;

        const itemIndex = flatOptions.findIndex((treeNode) => treeNode.value === item.key);

        if (itemIndex === -1) {
          return {};
        }

        const itemProps = getItemProps({
          disabled: item.isDisabled,
          index: itemIndex,
          item: original,
        });

        return { ...itemProps };
      }}
      highlightedKeys={[]}
      hoverBackgroundColor={theme.colors.v1.gray[100]}
      hoverFontWeight="normal"
      hoverLetterSpacing="normal"
      offset={offset}
      onCaretClick={(node, isExpanded) => {
        updateFlatOptions(node.original, isExpanded);
      }}
      onNodeClick={(node) => {
        const { original } = node;
        if (!node.original.isDividerItem) {
          const isSelected = selectedItems.some((selected) => selected.value === original.value);

          if (!isMulti) {
            setSelectedItems?.([original]);
            closeMenu?.();
          } else if (isSelected) {
            setSelectedItems?.(
              selectedItems.filter((i) => {
                return i.value !== node.original.value;
              }),
            );
          } else {
            setSelectedItems?.([...selectedItems, node.original]);
          }
        }
      }}
      renderLabel={(node, isCaretVisible) => {
        const { key, level, original: item } = node;
        const { original, ...rest } = item;

        if (item.isDividerItem) {
          return (
            <Box compWidth="100%">
              {filteredItems[0].value !== key && <HR mb={1} mt={0} />}
              <Box alignItems="center" compDisplay="flex" compHeight="32px">
                <Text
                  color="gray.500"
                  fontSize={theme.typography.fontSizes.sm}
                  fontWeight="bold"
                  lineHeight={theme.typography.lineHeights.md}
                  textTransform="uppercase"
                >
                  {item.text}
                </Text>
              </Box>
            </Box>
          );
        }
        return (
          <StyledTreeOptionsItem isCaretVisible={isCaretVisible} level={level}>
            <Option {...rest} fontWeight="normal" />
          </StyledTreeOptionsItem>
        );
      }}
      showCheckbox={({ original }) => isMulti && !original.isDividerItem}
      treeData={filteredItems}
      useNewLayout={useNewLayout}
    />
  );
};

export default TreeOptions;
