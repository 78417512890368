import { ValidDSType } from '@models/DataSourceCredentials';
import { DataSourceOptions } from '@models/DataSourceOptions';

export const sortDataSource = (a: ValidDSType, b: ValidDSType) => {
  const aLabel = DataSourceOptions.find((ds) => ds.value === a)?.text.toUpperCase() || '';
  const bLabel = DataSourceOptions.find((ds) => ds.value === b)?.text.toUpperCase() || '';

  if (aLabel < bLabel) {
    return -1;
  }
  if (aLabel > bLabel) {
    return 1;
  }

  return 0;
};
