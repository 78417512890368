import React, { useEffect } from 'react';

import { useIngestDataSource } from '@api/ingestion';
import Alert from '@components/Alert';
import NextStepButton from '@components/DataSourceSetup/components/Buttons/NextStepButton';
import { BoxWrapper } from '@components/DataSourceSetup/components/DataSourceAddStep';
import { CenterLoader, IndeterminateLoaderModal } from '@components/IndeterminateLoader';
import Text, { defaultParagraphStyles } from '@components/Text';
import { ModalFooter } from '@components/UI/Modal';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackPageName } from '@context/Segment/Segment.types';
import { DataSourceModel } from '@models/DataSourceModel';

import ErrorDescriptionMarkdown from '../ErrorDescriptionMarkdown';

interface Props {
  dataSource: DataSourceModel;
  onFinish: () => void;
  waitForFinish?: boolean;
}

const IngestDataStep: React.FC<Props> = ({ dataSource, onFinish, waitForFinish }) => {
  const segment = useSegmentContext();
  const { error, isLoading } = useIngestDataSource(dataSource.guid, {
    onSuccess: onFinish,
  });

  useEffect(() => {
    segment?.page(SegmentTrackPageName.ImportData_LoadData);
    if (!waitForFinish) {
      segment?.page(SegmentTrackPageName.ImportDataCompleted);
    }
  }, []);

  const loaderContent = !waitForFinish ? (
    <>
      <Text {...defaultParagraphStyles} textAlign="center">
        We are starting to load your metadata and query logs.
      </Text>
      <Text {...defaultParagraphStyles} textAlign="center">
        This may take up to 24 hours to fully update everything.
      </Text>
      <Text {...defaultParagraphStyles} textAlign="center">
        We’ll email you when it’s done!
      </Text>
    </>
  ) : (
    <Text {...defaultParagraphStyles} textAlign="center">
      Sync in progress - You can close this window
    </Text>
  );

  return (
    <>
      <BoxWrapper>
        {isLoading && (
          <CenterLoader>
            <IndeterminateLoaderModal
              active
              content={loaderContent}
              indeterminate
              inline="centered"
              size="big"
            />
          </CenterLoader>
        )}
        {!isLoading && error && (
          <>
            <Alert title={error?.statusText || 'Something went wrong'} type="error">
              {error?.data}
            </Alert>
            <ErrorDescriptionMarkdown error={error} />
          </>
        )}
      </BoxWrapper>
      {!waitForFinish && (
        <ModalFooter>
          <NextStepButton onClick={onFinish} />
        </ModalFooter>
      )}
    </>
  );
};

export default IngestDataStep;
