import { useFetchBiTable } from '@api/biTables';

import BiTablesTabContent from './BiTablesTabContent';

export default {
  Content: BiTablesTabContent,
  CustomTitle: undefined,
  OverviewCustomContent: undefined,
  fetchHook: useFetchBiTable,
  query: undefined,
};
