import React from 'react';

import DownstreamOwnersTab from '@components/TabContent/DownstreamOwnersTab';
import TopUsersTableContent from '@components/TabContent/TopUsersTab';
import { TabContentProps } from '@components/Tabs/types';
import type { TabRoutesConfig } from '@components/TabsRouter';
import TabsRouter from '@components/TabsRouter';
import { useUserContext } from '@context/User';
import { TableViewTabProps } from '@pages/TabbedTableViewPage/TableViewTab';

interface TabUserProp {
  userType?: 'bi' | 'table';
}

const TopUsersTab = (props: TableViewTabProps & TabContentProps) => {
  const { organization } = useUserContext();
  const useDownstreamNotifications = organization?.settings?.useDownstreamNotifications;
  const tabComponentProps: TableViewTabProps = props;
  const biTabComponentProps: TableViewTabProps & TabUserProp = {
    ...props,
    userType: 'bi',
  };

  const { guid, stickyHeader } = props;

  const tabsRouterConfig: TabRoutesConfig[] = [
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <TopUsersTableContent {...tabComponentProps} />,
      default: true,
      label: 'Table Users',
      route: '/users',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <TopUsersTableContent {...biTabComponentProps} />,
      label: 'Dashboard Users',
      route: '/bi-users',
    },
    ...(useDownstreamNotifications
      ? [
          {
            // eslint-disable-next-line react/no-unstable-nested-components
            component: () => <DownstreamOwnersTab itemGuid={guid} />,
            label: 'Downstream Owners',
            route: '/downstream-owners',
          },
        ]
      : []),
  ];

  return <TabsRouter config={tabsRouterConfig} sticky={stickyHeader} subTabs />;
};

export default TopUsersTab;
