import type { ResponseType } from 'axios';
import { AxiosError, AxiosRequestConfig } from 'axios';
import {
  useQuery as useReactQuery,
  UseQueryOptions as UseReactQueryOptions,
  UseQueryResult as UseReactQueryResult,
} from 'react-query';

import { CustomAxiosRequestConfig } from '@api/refreshToken';
import httpClient from '@lib/httpClient';
import createEnhancedError from '@utils/createEnhancedError';
import type { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';
import enrichApiResponseWithMetadata from '@utils/enrichApiResponseWithMetadata';

import handleRetry from './handleRetry';

export interface UseCustomFetchOptions<T> {
  method?: 'GET' | 'POST';
  onDownloadProgress?: AxiosRequestConfig['onDownloadProgress'];
  params?: T;
  responseType?: ResponseType;
  url?: string;
}

export type UseFetchOptions<
  TData = any,
  TParams = { [key: string]: any },
  TParsedData = TData,
> = UseCustomFetchOptions<TParams> & UseReactQueryOptions<TData, EnhancedErrorResult, TParsedData>;

const useFetch = <TData = unknown, TParams = { [key: string]: any }, TParsedData = TData>({
  method = 'GET',
  onDownloadProgress,
  params,
  queryKey,
  responseType,
  url,
  ...options
}: UseFetchOptions<TData, TParams, TParsedData>): UseReactQueryResult<
  TParsedData,
  EnhancedErrorResult
> => {
  return useReactQuery<TData, EnhancedErrorResult, TParsedData>({
    queryFn: async ({ signal }) => {
      try {
        const controller = new AbortController();
        signal?.addEventListener('abort', () => {
          controller.abort();
        });

        const result = await httpClient({
          method,
          onDownloadProgress,
          params,
          signal,
          url,
        });

        const resultData = result?.data ?? result;

        const enrichedData = enrichApiResponseWithMetadata(
          resultData,
          (result.config as CustomAxiosRequestConfig)?.metadata,
        );

        return enrichedData;
      } catch (err) {
        throw createEnhancedError({
          data: (err as AxiosError)?.response?.data,
          message: (err as AxiosError)?.response?.statusText ?? (err as Error)?.message,
          status: (err as AxiosError)?.response?.status,
        });
      }
    },
    queryKey,
    retry: handleRetry,
    ...options,
  });
};

export default useFetch;
