import styled from '@emotion/styled';

import IconButton from '@components/IconButton';

export const StyledDownstreamChartsTabOptionsButton = styled(IconButton)`
  visibility: hidden;
`;

export const StyledDownstreamChartsTabParentWrapper = styled.div`
  padding: ${({ theme }) => theme.space(0.25, 0)};

  &:hover {
    ${StyledDownstreamChartsTabOptionsButton} {
      visibility: visible;
    }
  }
`;

export const StyledDownstreamChartsTabChildrenItem = styled.div`
  &:hover {
    ${StyledDownstreamChartsTabOptionsButton} {
      visibility: visible;
    }
  }
`;
