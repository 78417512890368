import React, { useCallback, useState } from 'react';
import { Cell } from 'react-table';

import { RBACPolicyModel } from '@api/rbacPolicy/rbacPolicyModel';
import { TeamModel } from '@api/teams/TeamModel';
import { UserModel } from '@api/user/UserModel';
import Box from '@components/Box';
import IconButton from '@components/IconButton';
import LinkedCell from '@components/Table/Cells/LinkedCell';
import { StyledText } from '@components/Table/Cells/LinkedCell.styles';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import TooltipCell from '@components/Table/Cells/TooltipCell';
import UserCell from '@components/Table/Cells/UserCell';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import Icon from '@components/UI/Icon';
import { useModal } from '@context/Modal';
import flags from '@features';
import { OrganizationUserRoleMap } from '@models/OrganizationUserRole';
import theme from '@styles/theme';

import AdminRBACPolicyTableStyled from './AdminRBACPolicyTable.styles';
import StatusDropdown from './StatusDropdown';

interface AdminRBACPolicyTableProps {
  itemCount?: number;
  items?: RBACPolicyModel[];
}

const AdminRBACPolicyTable: React.FC<AdminRBACPolicyTableProps> = ({ itemCount = 0, items }) => {
  const [isShowFilter, setShowFilter] = useState(false);
  const { MODAL_IDS, openModal } = useModal();
  const toggleFilter = useCallback(() => setShowFilter((prev) => !prev), [setShowFilter]);

  const getHiddenColumns = () => {
    return ['exceptFor', 'createdBy'];
  };
  const columns: ColumnConfig<RBACPolicyModel>[] = React.useMemo(() => {
    const cols: ColumnConfig<RBACPolicyModel>[] = [
      {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      {
        Cell: (props: Cell<RBACPolicyModel>) => {
          const policy = props.row.original;

          return (
            <TooltipCell
              {...props}
              elementToRender={
                <StyledText
                  color="#2C2E36"
                  cursor="pointer"
                  display="block"
                  fontFamily="primary"
                  fontSize="13px"
                  onClick={() => openModal(MODAL_IDS.addPolicy, { policy })}
                >
                  {policy.name}
                </StyledText>
              }
              text="Show Policy Details"
            />
          );
        },
        Header: `Name (${itemCount})`,
        accessor: 'name',
        disableHiding: true,
        id: 'name',
        width: '120%',
      },
      {
        Cell: (props: Cell<RBACPolicyModel>) => {
          const policy = props.row.original;

          if (policy.accessTo?.length === 0) {
            return null;
          }

          return (
            <Box compDisplay="flex" flexDirection="column" gap={0.5}>
              {policy.accessTo?.map((target) => {
                if (target?.wildcardType === '*') {
                  return (
                    <Box key="wildcard" alignItems="center" compDisplay="flex" lineHeight="normal">
                      <Icon mr={0.5} name="select-star-colored" size="16px" />
                      All
                    </Box>
                  );
                }
                if (target?.wildcardType === 'all-docs') {
                  return (
                    <Box key="all-docs" alignItems="center" compDisplay="flex" lineHeight="normal">
                      <Icon mr={0.5} name="all-docs" size="16px" />
                      All Documents
                    </Box>
                  );
                }
                if (target?.target?.obj) {
                  return (
                    <LinkedCell
                      key={target?.target?.obj?.guid}
                      {...props}
                      item={target?.target?.obj}
                      noBold
                      noLink
                      showDataSourceIcon={
                        target?.target?.obj.dataTypes?.objectType !== 'datasource'
                      }
                      showIcon
                      showNameTooltip
                    />
                  );
                }
                return null;
              })}
            </Box>
          );
        },
        Header: `Access To`,
        accessor: (d) => {
          if (d.accessTo?.length === 0) {
            return null;
          }
          return d.accessTo
            ?.map((target) => {
              if (target?.wildcardType === '*') {
                return 'All';
              }
              if (target?.wildcardType === 'all-docs') {
                return 'All Documents';
              }
              if (target?.target?.obj) {
                return target?.target?.obj?.name;
              }
              return '';
            })
            .join(',');
        },
        disableHiding: true,
        disableSortBy: true,
        id: 'accessTo',
        width: '120%',
      },
      {
        Cell: (props: Cell<RBACPolicyModel>) => {
          const policy = props.row.original;

          if (policy?.role) {
            return <span>{OrganizationUserRoleMap[policy.role]}</span>;
          }
          return null;
        },
        Header: `Role`,
        accessor: 'role',
        disableFilters: true,
        disableHiding: true,
        id: 'role',
      },
      {
        Cell: (props: Cell<RBACPolicyModel>) => {
          const policy = props.row.original;

          if (policy.assignedTo?.length === 0) {
            return null;
          }

          return (
            <Box compDisplay="flex" flexDirection="column" gap={1} py={2}>
              {policy.assignedTo?.map((assignedTo) => {
                if (assignedTo?.isWildcard) {
                  return (
                    <Box key="wildcard" alignItems="center" compDisplay="flex" lineHeight="normal">
                      <Icon mr={0.5} name="select-star-colored" size="16px" />
                      Everyone
                    </Box>
                  );
                }
                if (assignedTo?.assignee?.obj) {
                  return (
                    <Box key={assignedTo?.assignee?.obj?.guid}>
                      <UserCell {...props} user={assignedTo.assignee.obj} />
                    </Box>
                  );
                }
                return null;
              })}
            </Box>
          );
        },
        Header: `Assigned To`,
        accessor: (d) => {
          if (d.assignedTo?.length === 0) {
            return null;
          }
          return d.assignedTo
            ?.map((assignedTo) => {
              if (assignedTo?.isWildcard) {
                return 'Everyone';
              }
              const item = assignedTo?.assignee?.obj;
              if (item?.objectType === 'user') {
                const user = item as UserModel;
                return `${user.fullName},${user.email}`;
              }
              if (item?.objectType === 'team') {
                const team = item as TeamModel;
                return team.fullName;
              }
              return '';
            })
            .join(',');
        },
        disableHiding: true,
        disableSortBy: true,
        id: 'user',
        width: '115%',
      },
    ];

    if (flags.show_pbac_except_for_tags) {
      cols.push({
        Cell: (props: Cell<RBACPolicyModel>) => {
          const policy = props.row.original;

          if (policy.exceptFor?.length === 0) {
            return null;
          }

          return policy.exceptFor?.map((obj) => {
            if (obj?.exceptFor?.obj) {
              return (
                <LinkedCell
                  {...props}
                  key={obj?.exceptFor?.obj?.guid}
                  item={obj?.exceptFor?.obj}
                  noBold
                  noLink
                  showIcon
                />
              );
            }
            return null;
          });
        },
        Header: `Except For`,
        accessor: 'exceptFor',
        disableFilters: true,
        disableSortBy: true,
        id: 'exceptFor',
        width: '115%',
      });
    }
    return cols.concat([
      {
        Cell: (props: Cell<RBACPolicyModel>) => {
          const policy = props.row.original;
          return <UserCell {...props} user={policy.createdBy} />;
        },
        Header: `Created By`,
        accessor: (d) => `${d?.createdBy?.fullName},${d?.createdBy?.email}`,
        disableSortBy: true,
        id: 'createdBy',
        width: '115%',
      },
      {
        Cell: ({ row }: Cell<RBACPolicyModel>) => {
          const item = row.original;

          if (item?.isDefaultPolicy) {
            return null;
          }

          return <StatusDropdown rbacPolicyItem={item} />;
        },
        Header: 'Status',
        accessor: 'status',
        disableFilters: true,
        disableHiding: true,
        id: 'status',
        width: '115%',
      },
      {
        Cell: (props: Cell<RBACPolicyModel>) => {
          const policy = props.row.original;

          return (
            <IconButton
              iconColor={theme.colors.v1.gray[600]}
              iconName="edit-outline"
              iconSize="20px"
              onClick={() => openModal(MODAL_IDS.addPolicy, { policy })}
              variant="clear"
              verticalAlign="middle"
            />
          );
        },
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'edit',
        width: '2rem',
      },
      {
        Cell: ({ row }: Cell<RBACPolicyModel>) => {
          const item = row.original;

          if (item.isDefaultPolicy) {
            return null;
          }

          return (
            <IconButton
              iconColor={theme.colors.v1.gray[600]}
              iconName="trash"
              iconSize="20px"
              onClick={() => openModal(MODAL_IDS.deletePolicy, { policy: item })}
              variant="clear"
              verticalAlign="middle"
            />
          );
        },
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'delete',
        width: '2rem',
      },
    ]);
  }, [itemCount, MODAL_IDS, openModal]);

  return (
    <AdminRBACPolicyTableStyled>
      <Table
        basic="very"
        className="table-full"
        columns={columns}
        compact
        data={items || []}
        disablePagination
        disableRowSelect
        initialState={{
          hiddenColumns: getHiddenColumns(),
        }}
        loading={items === undefined}
        selectable
        showFilter={isShowFilter}
        singleLine
        sortable
        toggleFilter={toggleFilter}
        unstackable
      />
    </AdminRBACPolicyTableStyled>
  );
};

export default AdminRBACPolicyTable;
