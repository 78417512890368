import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';
import uniq from 'lodash/uniq';

import type { FilterIndex } from '@api/search/types';
import type { DatasourceTab } from '@components/SearchBar/DatasourceTabs/DatasourceTabs.types';

import {
  findSearchFacet,
  getSearchFacetOrder,
  SearchFacetsResultsType,
  SearchFacetType,
} from '../SearchTypes';

import { FacetNamesFiltersTypes } from './SearchFacetCheckbox';

export const getFilters = (checkedFilters: FacetNamesFiltersTypes, activeTab: DatasourceTab) => {
  // Get the key of the checked checkboxes.
  const newFilters = pickBy(checkedFilters);
  const checkedKeys = keys(newFilters);

  // If no checkboxes are checked, set all the filter in the active tab to true.
  if (checkedKeys.length === 0) {
    return {
      ...activeTab.filters,
    };
  }

  return newFilters;
};

// Returns an ordered array of search facet objects that correspond to the active tab's facets.
export const orderFacets = (activeTab: DatasourceTab) => {
  // We do not want to display any facets for the All tab.
  if (activeTab.name === 'All') return [];

  // Extracts all the keys from the filter object with truthy values.
  const facets = keys(pickBy(activeTab.filters)) as FilterIndex[];

  const sorted = facets.sort((a, b) => getSearchFacetOrder(a) - getSearchFacetOrder(b));

  return uniq(sorted.map(findSearchFacet).filter((x): x is SearchFacetType => x !== undefined));
};

// Returns a map of facets and their result counts.
export const getFacetCountsMap = (
  facets: SearchFacetType[],
  searchResultFacets: Partial<SearchFacetsResultsType>,
) => {
  const countMap: { [index: string]: number } = {};
  facets.forEach((facet) => {
    // If multiple filters exist under one facet label combine them into one count.
    countMap[facet.name[0]] = facet.name.reduce((acc: number, name) => {
      const currFacetCount = searchResultFacets[name as keyof SearchFacetsResultsType];
      if (currFacetCount) {
        return currFacetCount + acc;
      }
      return acc;
    }, 0);
  });
  return countMap;
};
