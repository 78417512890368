import { Expose, Type } from 'class-transformer';

import { TeamModel } from '@api/teams/TeamModel';
import { UserModel } from '@api/user/UserModel';
import { Reference } from '@models/Reference';

export class AssignedToModel {
  static objectType: string = 'assignedTo';

  objectType: string = AssignedToModel.objectType;

  @Type(() => Reference)
  assignee?: Reference<UserModel | TeamModel>;

  @Expose({ name: 'is_wildcard' })
  isWildcard?: boolean;
}
