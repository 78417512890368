import styled from '@emotion/styled';

interface StyledGreetingsSectionProps {
  useNewLayout?: boolean;
}

export const StyledGreetingsSection = styled.section<StyledGreetingsSectionProps>`
  padding: ${({ theme, useNewLayout }) =>
    useNewLayout ? theme.space(2.5, 5, 2.5, 5) : theme.space(5, 5, 2.5, 5)};
`;
