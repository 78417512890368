import React from 'react';

import type BiTableModel from '@api/biTables/BiTableModel';
import type { TableModel } from '@api/tables/TableModel';
import Box from '@components/Box';
import type { BreadcrumbItem } from '@components/Breadcrumbs';
import Breadcrumbs from '@components/Breadcrumbs';
import BulkButtons from '@components/BulkButtons/BulkButtons';
import NotFoundError from '@components/Error/NotFoundError';
import type { FiltersSidebarProps } from '@components/FiltersSidebar';
import { FiltersSidebar } from '@components/FiltersSidebar';
import { ToggleHideItemsModal } from '@components/Modal';
import Tooltip from '@components/Tooltip';
import HR from '@components/UI/HR';
import Icon from '@components/UI/Icon';
import { useModal } from '@context/Modal';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import { useUserContext } from '@context/User';
import useBulkEditSelected from '@hooks/useBulkEditSelected';
import type DataTypesModel from '@models/DataTypesModel';
import {
  StyledGridContainer,
  StyledPageHeader,
  StyledTablesPage,
} from '@pages/TablesPage/TablesPage.styles';
import type { TablesTableProps } from '@pages/TablesPage/TablesTable';
import TablesTable from '@pages/TablesPage/TablesTable';
import type { FilterServiceInterface } from '@utils/filters';
import MetadataDecorator from '@utils/MetadataDecorator';
import pluralize from '@utils/pluralize';

interface TablesPageTemplateProps
  extends Pick<
    TablesTableProps,
    'visibleColumns' | 'initialSortState' | 'useGlobalSearch' | 'hideNameLink'
  > {
  breadcrumbs?: BreadcrumbItem[];
  countItems?: Array<{ key: string; text: string }>;
  data?: {
    count: number;
    results: TableModel[] | BiTableModel[];
  };
  dataTypes?: DataTypesModel;
  dsGuid?: string;
  error?: any;
  filterProps?: Partial<FiltersSidebarProps>;
  filterService: FilterServiceInterface;
  guid?: string;
  isLoading?: boolean;
  refetch: () => void;
  title?: string;
  tooltipText?: string;
}

const TablesPageTemplate: React.FC<TablesPageTemplateProps> = ({
  breadcrumbs,
  countItems,
  data,
  dataTypes,
  dsGuid,
  error,
  filterProps,
  filterService,
  guid,
  hideNameLink,
  initialSortState,
  isLoading,
  refetch,
  title,
  tooltipText,
  useGlobalSearch,
  visibleColumns,
}) => {
  const { dataSources } = useUserContext();
  const { isPbac, permissions } = useObjectPermissionsContext({ id: dsGuid });
  const { MODAL_IDS, checkModalOpened } = useModal();
  const { selected, tagsCounts, toggleAll, toggleItem } = useBulkEditSelected<TableModel>({
    key: `${dsGuid} ${guid} ${dataTypes?.dataType}`,
  });
  const { filter } = filterService;

  const selectedEditableItems = selected.items.filter((item) =>
    isPbac ? permissions[item.guid]?.isEditable : true,
  );

  if (error) {
    return <NotFoundError />;
  }

  const isDataSourceEditable = Boolean(dataSources?.[dsGuid!]?.settings?.isEditable);

  const suffix =
    !isLoading &&
    countItems
      ?.map(({ key, text }) => {
        const count = (data as any)?.[key];
        return `${pluralize(count, text)}`;
      })
      .join(' & ');

  return (
    <>
      <MetadataDecorator title={title} />
      <Box compDisplay="flex">
        <StyledGridContainer>
          <StyledTablesPage>
            <StyledPageHeader
              icon={
                dataTypes && (
                  <Tooltip content={tooltipText}>
                    <Icon name={dataTypes?.icons.dataType} />
                  </Tooltip>
                )
              }
              supIcon={dataTypes && <Icon name={dataTypes?.icons.dataSource} />}
              supTitle={breadcrumbs && <Breadcrumbs items={breadcrumbs} />}
              title={title}
              titleSuffix={suffix}
            />
            <HR />
            {selected.items.length > 0 && (
              <BulkButtons
                canEditTags
                canExportCSV
                canRemove={!filter.is_hidden}
                canShowERD
                canUnHide={filter.is_hidden}
                isDataSourceEditable={isDataSourceEditable}
                selectedEditableItems={selectedEditableItems}
                selectedItems={selected.items}
                showText
                tagsCounts={tagsCounts}
              />
            )}
            <Box mb={0.5} mt={1.5}>
              <TablesTable
                data={data?.results as TableModel[]}
                dataSourceType={dataTypes?.dataSourceType!}
                filterService={filterService}
                hideNameLink={hideNameLink}
                initialSortState={initialSortState}
                isDataSourceEditable={isDataSourceEditable}
                isLoading={isLoading}
                itemCount={data?.count}
                selectedRowIds={selected.ids}
                toggleAll={(checked) => {
                  toggleAll(data?.results as TableModel[], checked);
                }}
                toggleItem={toggleItem}
                useGlobalSearch={useGlobalSearch}
                visibleColumns={visibleColumns}
              />
            </Box>
          </StyledTablesPage>
        </StyledGridContainer>
        <FiltersSidebar
          dataSourceType={dataTypes?.dataSourceType!}
          filterService={filterService}
          {...filterProps}
        />
      </Box>
      {checkModalOpened(MODAL_IDS.hideItems) && (
        <ToggleHideItemsModal
          isShowItemsModal={filter.is_hidden}
          reloadData={refetch}
          selectedItems={selectedEditableItems}
        />
      )}
    </>
  );
};

export default React.memo(TablesPageTemplate);
