import paginatedTransform from '@api/paginatedTransform';
import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import { PaginatedResponse } from '@models/PaginatedResponse';

import { JoinModel } from './JoinModel';

export const joinsCacheKeys = {
  joins: ['joins'],
};

export const fetchJoinsSelect = paginatedTransform(JoinModel);
export const useFetchJoins = (options?: UseFetchOptions<PaginatedResponse<JoinModel>>) => {
  return useFetch<PaginatedResponse<JoinModel>>({
    ...options,
    cacheTime: 0,
    queryKey: [...joinsCacheKeys.joins, options?.params],
    select: fetchJoinsSelect,
    url: '/joins/',
  });
};
