import React, { useMemo } from 'react';

import { useFetchDataSourcesInfo } from '@api/dataSources';
import Alert from '@components/Alert';
import { useDateRangePickerContext } from '@components/DateRangePicker/DateRangePicker.context';
import DashboardByCostTable from '@components/Table/DashboardByCostTable';
import type { ValidDSType } from '@models/DataSourceCredentials';

import DashboardByCostChart from '../charts/DashboardByCostChart';
import CostAnalysisSharedFilter from '../CostAnalysisSharedFilter';
import useWarehouseSelect from '../CostAnalysisSharedFilter/useWarehouseSelect';

import { SectionTitleWrapper, Subtitle, Title } from './SectionTitle';

const allowedSources: PartialRecord<ValidDSType, true> = { mode: true, sigma: true };

const CostAnalysisDashboardByCostTab: React.FC = () => {
  const { dateRange } = useDateRangePickerContext();
  const { selectedWarehouses } = useWarehouseSelect();
  const { data } = useFetchDataSourcesInfo();

  const hasValidTools = useMemo(
    () => data?.results.some((d) => Boolean(allowedSources[d.dataTypes?.dataSourceType!])),
    [data],
  );

  return (
    <>
      <SectionTitleWrapper my={2}>
        <Subtitle>Top 50 Dashboards by cost</Subtitle>
        <CostAnalysisSharedFilter />
      </SectionTitleWrapper>
      {!hasValidTools && <Alert type="warning">Currently we only support Sigma and Mode.</Alert>}
      {hasValidTools && (
        <>
          <Title mb={4}>Dashboard by Usage & Cost</Title>
          <DashboardByCostChart range={dateRange} warehouses={selectedWarehouses} />
          <DashboardByCostTable range={dateRange} warehouses={selectedWarehouses} />
        </>
      )}
    </>
  );
};

export default CostAnalysisDashboardByCostTab;
