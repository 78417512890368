import styled from '@emotion/styled';

import Ellipsis from '@components/Ellipsis';
import Card from '@components/UI/Card';
import { FontSize } from '@styles/theme/typography';

export const StyledMetadataGrid = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.space(2.5)};
`;

export const StyledMetadataGridCardHeader = styled.div`
  align-items: flex-start;
  display: flex;
  gap: ${({ theme }) => theme.space(1)};
`;

export const StyledMetadataGridCardIconWrapper = styled.div`
  width: ${({ theme }) => theme.space(6)};
  height: ${({ theme }) => theme.space(6)};
  border-radius: ${({ theme }) => theme.radius.lg};
  position: relative;
  background-color: ${({ theme }) => theme.colors.v1.gray[100]};
  align-items: center;
  justify-content: center;
  display: flex;
  flex-shrink: 0;
`;

export const StyledMetadataGridCardSecondaryIconWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.v1.gray[50]};
  border-radius: ${({ theme }) => theme.radius.default};
  border: ${({ theme }) => `1px solid ${theme.colors.gray[200]}`};
  display: flex;
  height: ${({ theme }) => theme.space(3)};
  justify-content: center;
  left: ${({ theme }) => theme.space(-1.5)};
  position: absolute;
  top: ${({ theme }) => theme.space(-0.5)};
  width: ${({ theme }) => theme.space(3)};
`;

export const StyledMetadataGridCardNameWrapper = styled.div`
  color: ${({ theme }) => theme.colors.v1.gray[700]};
  display: grid;
  flex-grow: 1;
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  align-self: center;
`;

interface StyledMetadataGridCardNameProps {
  fontSize?: FontSize;
}

export const StyledMetadataGridCardName = styled(Ellipsis)<StyledMetadataGridCardNameProps>`
  font-size: ${({ fontSize = 'h3', theme }) => theme.typography.fontSizes[fontSize]};
  font-weight: ${({ theme }) => theme.typography.fontWeights.semibold};
  margin: 0;
`;

export const StyledMetadataGridCardDescription = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  font-size: ${({ theme }) => theme.typography.fontSizes.body2};
  font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
  line-height: ${({ theme }) => theme.typography.lineHeights.md};
  color: ${({ theme }) => theme.colors.v1.gray[600]};
  max-height: 56px;
  margin-top: ${({ theme }) => theme.space(1)};
`;
