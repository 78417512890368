import paginatedTransform from '@api/paginatedTransform';
import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import { PaginatedResponse } from '@models/PaginatedResponse';

import { TableEventModel } from './TableEventModel';

const tableEventsCacheKeys = {
  tableEvents: ['tableEvents'],
};

export const fetchTableEventsSelect = paginatedTransform(TableEventModel);
export const useFetchTableEvents = (
  options?: UseFetchOptions<PaginatedResponse<TableEventModel>>,
) => {
  return useFetch<PaginatedResponse<TableEventModel>>({
    ...options,
    queryKey: [...tableEventsCacheKeys.tableEvents, options?.params],
    select: fetchTableEventsSelect,
    url: '/table-events/',
  });
};
