import type { UseFetchOptions } from '@hooks/useFetch';
import useFetch from '@hooks/useFetch';
import type { UseMutationOptions } from '@hooks/useMutation';
import useMutation from '@hooks/useMutation';
import { PaginatedResponse } from '@models/PaginatedResponse';
import type { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';

import paginatedTransform from '../paginatedTransform';

import type { PartialCustomAttributeValue } from './CustomAttributeValueModel';
import { CustomAttributeValueModel } from './CustomAttributeValueModel';

export const customAttributeValuesCacheKeys = {
  customAttributeValue: (id: string) => ['customAttributeValues', id],
  customAttributeValues: ['customAttributeValues'],
};

const selectCustomAttributeValue = paginatedTransform(CustomAttributeValueModel);
export const useFetchCustomAttributeValue = (
  id: string,
  options?: UseFetchOptions<CustomAttributeValueModel>,
) => {
  return useFetch<CustomAttributeValueModel>({
    ...options,
    queryKey: customAttributeValuesCacheKeys.customAttributeValue(id),
    select: selectCustomAttributeValue,
    url: `/custom-attribute-values/`,
  });
};

const selectCustomAttributeValues = paginatedTransform(CustomAttributeValueModel);
export const useFetchCustomAttributeValues = (
  options?: UseFetchOptions<PaginatedResponse<CustomAttributeValueModel>>,
) => {
  return useFetch<PaginatedResponse<CustomAttributeValueModel>>({
    ...options,
    queryKey: [...customAttributeValuesCacheKeys.customAttributeValues, options?.params],
    select: selectCustomAttributeValues,
    url: `/custom-attribute-values/`,
  });
};

export interface UsePatchCustomAttributeValueOptions
  extends UseMutationOptions<never, EnhancedErrorResult, PartialCustomAttributeValue> {}

export const usePatchCustomAttributeValue = (
  id: string,
  options: UsePatchCustomAttributeValueOptions,
) => {
  return useMutation({
    ...options,
    method: 'PATCH',
    url: `/custom-attribute-values/${id}/`,
  });
};

export interface UsePostCustomAttributeValueOptions
  extends UseMutationOptions<
    never,
    EnhancedErrorResult,
    { custom_attribute: string; item: string; richtext_value: string; value: string }
  > {}

export const usePostCustomAttributeValue = (options?: UsePostCustomAttributeValueOptions) => {
  return useMutation({
    ...options,
    method: 'POST',
    url: `/custom-attribute-values/`,
  });
};
