import React, { useState } from 'react';

import TabsRouter from '@components/TabsRouter';

import { defaultAnalyticsRange } from '../../Analytics.constants';

import AnalyticsActivitiesTab from './AnalyticsActivitiesTab';
import AnalyticsActivitiesTopPages from './AnalyticsActivitiesTopPages';
import AnalyticsActivitiesTopPagesEdited from './AnalyticsActivitiesTopPagesEdited';
import AnalyticsActivitiesTopSearchTerms from './AnalyticsActivitiesTopSearchTerms';

const AnalyticsActivities: React.FC = () => {
  const [dateRange, setDateRange] = useState<[string, string]>(defaultAnalyticsRange);

  const tabsRouterConfig = [
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => (
        <AnalyticsActivitiesTab dateRange={dateRange} onDateRangeChange={setDateRange} />
      ),
      contentOffset: 2,
      default: true,
      label: 'Overview',
      route: '/overview',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => (
        <AnalyticsActivitiesTopPages dateRange={dateRange} onDateRangeChange={setDateRange} />
      ),
      contentOffset: 2,
      label: 'Top Pages',
      route: '/top-pages',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => (
        <AnalyticsActivitiesTopPagesEdited dateRange={dateRange} onDateRangeChange={setDateRange} />
      ),
      contentOffset: 2,
      label: 'Top Pages Edited',
      route: '/top-edited',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => (
        <AnalyticsActivitiesTopSearchTerms dateRange={dateRange} onDateRangeChange={setDateRange} />
      ),
      contentOffset: 2,
      label: 'Top Search Terms',
      route: '/top-search-terms',
    },
  ];

  return <TabsRouter config={tabsRouterConfig} subTabs />;
};

export default AnalyticsActivities;
