import React from 'react';

import client, { availableApiUrls } from '@api';
import Button from '@components/Button/Button';
import Icon from '@components/UI/Icon';
import useActiveApi from '@hooks/useActiveApi';

const SwitchApi: React.FC = () => {
  const [activeApi, setActiveApi] = useActiveApi();

  const handleButtonClick = (apiUrl: string) => () => {
    client.logout();
    setActiveApi(apiUrl);
  };

  return availableApiUrls.map((url: string) => (
    <Button
      key={url}
      disabled={url === activeApi}
      fluid
      onClick={handleButtonClick(url)}
      startIcon={<Icon compHeight="1.2rem" compWidth="2rem" name="django" />}
      variant="outlined"
    >
      <span>{url}</span>
    </Button>
  ));
};

export default SwitchApi;
