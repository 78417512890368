import React from 'react';

import { GridContainer } from '@components/Grid';
import HR from '@components/UI/HR';
import RecentlyAdded from '@pages/MainPage/RecentlyAdded';
import MetadataDecorator from '@utils/MetadataDecorator';

import GreetingsSection from './GreetingsSection';
import PopularInOrg from './PopularInOrg';
import RecentlyViewed from './RecentlyViewed';
import TopViewedPages from './TopViewedPages';

const MainPage: React.FC = () => {
  return (
    <>
      <MetadataDecorator title="Home" />
      <GridContainer fluid hPaddingSpace={0} vPaddingSpace={0}>
        <GreetingsSection />
        <HR mb={3} mt={2} />
        <RecentlyViewed />
        <TopViewedPages topBy="user" />
        <TopViewedPages showDescription topBy="team" />
        <TopViewedPages showDescription topBy="org" />
        <PopularInOrg />
        <RecentlyAdded />
      </GridContainer>
    </>
  );
};

export default MainPage;
