import React from 'react';
import { LOADING_TEXT } from '@constants';
import copy from 'copy-to-clipboard';

import Box from '@components/Box';
import OwnerItem from '@components/Owners/OwnerItem';
import { renderInfoToast } from '@components/Toast';
import Icon from '@components/UI/Icon';
import { QueryLogRecordModel } from '@models/QueryLogRecordModel';
import { dateTimeFormat, displayDuration } from '@utils/moment';

import {
  SectionInfo,
  SectionTitle,
  SectionValue,
  SidebarSection,
  Subsection,
} from './SidebarStyled';

interface Props {
  loading?: boolean;
  queryLogRecord?: QueryLogRecordModel;
  title?: string;
}

const QuerySection: React.FC<Props> = ({ loading, queryLogRecord, title = 'Query run:' }) => {
  const rowsProducedText = queryLogRecord?.rowsProduced
    ? `${queryLogRecord.rowsProduced} rows`
    : 'Unknown';

  const copyId = (stringToCopy: string) => {
    copy(stringToCopy);
    renderInfoToast('Copied To Clipboard');
  };

  return (
    <SidebarSection>
      <SectionTitle>{title}</SectionTitle>
      {loading && (
        <Subsection>
          <SectionInfo>{LOADING_TEXT}</SectionInfo>
        </Subsection>
      )}
      {!loading && !queryLogRecord && (
        <Subsection>
          <SectionInfo>Query information not found.</SectionInfo>
        </Subsection>
      )}
      {queryLogRecord && (
        <React.Fragment key={queryLogRecord.guid}>
          <Subsection flexDirection="row">
            <SectionValue>{queryLogRecord?.executedOn?.format(dateTimeFormat())}</SectionValue>
          </Subsection>
          <Subsection flexDirection="row">
            <SectionInfo>by:</SectionInfo>
            <SectionValue>
              <OwnerItem
                avatarMargin={0.5}
                height="1.125rem"
                owner={queryLogRecord.dsuser?.user || queryLogRecord.dsuser}
              />
            </SectionValue>
          </Subsection>
          <Subsection flexDirection="row">
            <SectionInfo>produced: </SectionInfo>
            <SectionValue>{rowsProducedText}</SectionValue>
          </Subsection>
          <Subsection flexDirection="row">
            <SectionInfo>run time: </SectionInfo>
            <SectionValue>{displayDuration(queryLogRecord?.duration)}</SectionValue>
          </Subsection>
          {queryLogRecord.externalId && (
            <Subsection flexDirection="row">
              <SectionInfo>query id: </SectionInfo>
              <SectionValue>
                <Box
                  compDisplay="block"
                  flexGrow={1}
                  overflowX="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {queryLogRecord.externalId}
                </Box>
                <Box compDisplay="flex" flexGrow={0}>
                  <span onClick={() => copyId(queryLogRecord.externalId || '')} role="button">
                    <Icon name="copy" size="14px" />
                  </span>
                </Box>
              </SectionValue>
            </Subsection>
          )}
        </React.Fragment>
      )}
    </SidebarSection>
  );
};

export default QuerySection;
