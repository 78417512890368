import React from 'react';
import { ReactEditor, useSlate } from 'slate-react';

import Text, { defaultParagraphStyles } from '@components/Text';

import { StyledTableCellMenuOption } from './TableCellMenuOption.styles';
import type { TableCellMenuOptionType } from './TableCellMenuOption.types';

interface TableCellMenuOptionProps {
  onClose: () => void;
  option: TableCellMenuOptionType;
}

const TableCellMenuOption: React.FC<TableCellMenuOptionProps> = ({ onClose, option }) => {
  const { label, onSelect } = option;
  const editor = useSlate();

  return (
    <StyledTableCellMenuOption
      onMouseDown={() => {
        onSelect(editor);
        setTimeout(() => ReactEditor.focus(editor), 10);
        onClose();
      }}
    >
      <Text {...defaultParagraphStyles} mb={0}>
        {label}
      </Text>
    </StyledTableCellMenuOption>
  );
};

export default TableCellMenuOption;
