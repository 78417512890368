import styled from '@emotion/styled';

export const StyledTableCellMenuOption = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.space(0, 2)};
  height: 2.5rem;
  cursor: pointer;

  &:hover {
    background-color: #f2f5f9;
  }
`;

export const MenuOptionDivider = styled.hr`
  width: 100%;
  border-color: ${({ theme }) => theme.colors.gray[300]};
`;
