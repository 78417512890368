import React from 'react';

import { StatusType } from '@api/types';
import NumberBadge from '@components/NumberBadge';
import Icon from '@components/UI/Icon';

import { NumberBadgeProps } from '../NumberBadge/NumberBadge';
import { statusMap } from '../Status/Status';

export type StatusCountsType = Record<StatusType, number> | null;

interface StatusBadgeProps extends NumberBadgeProps {
  counts?: StatusCountsType;
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ counts, ...rest }) => {
  if (!counts) {
    return null;
  }
  if (counts.error === 0 && counts.warning === 0 && counts.success === 0) {
    return null;
  }

  if (counts?.error === 0 && counts.warning === 0) {
    return <Icon color={statusMap.success.badgeBackgroundColor} name="success" size="20px" />;
  }

  const status = counts?.error > 0 ? 'error' : 'warning';
  const count = counts?.error > 0 ? counts?.error : counts?.warning;

  return (
    <NumberBadge
      aria-label={statusMap[status].text}
      backgroundColor={statusMap[status].badgeBackgroundColor}
      color={statusMap[status].badgeTextColor}
      role="status"
      value={count}
      {...rest}
    />
  );
};

export default StatusBadge;
