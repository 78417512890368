import React, { useEffect, useRef, useState } from 'react';

import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import type { CheckboxProps } from '@components/UI/Form/Checkbox';
import { Checkbox } from '@components/UI/Form/Checkbox';
import Icon from '@components/UI/Icon';

import {
  StyledTreeNode,
  StyledTreeNodeCaret,
  StyledTreeNodeIndentationLine,
  StyledTreeNodeItemWrapper,
  StyledTreeNodeLabelWrapper,
  StyledTreeNodeProps,
} from './TreeNode.v2.styles';
import type { TreeNodeType } from './types';

export interface TreeNodeProps
  extends Omit<StyledTreeNodeProps, 'offset'>,
    Pick<TreeNodeType<any>, 'level'> {
  checkboxProps?: CheckboxProps;
  expandOnNodeClick?: boolean;
  isCaretVisible?: boolean;
  isDefaultExpanded: boolean;
  isFirstChild?: boolean;
  isFlatHierarchy?: boolean;
  isHighlighted: boolean;
  isLastChild?: boolean;
  isLoading: boolean;
  label: React.ReactNode;
  nodeExtraProps?: any;
  offset?: () => number | undefined;
  onCaretClick?: (isExpanded: boolean) => void;
  onExpand: (expanded: boolean) => void;
  onMount: () => void;
  onNodeClick?: () => void;
  showCheckbox?: boolean;
  showIndentationLines?: boolean;
  useNewLayout?: boolean;
}

const calcCheckboxMarginLeft = (level: number) => (level === 1 ? 0 : (level - 1) * 0.5);

interface RenderIndentationLinesParams {
  isExpanded?: boolean;
  isFirstChild?: boolean;
  isLastChild?: boolean;
  level: number;
}

const renderIndentationLines = ({
  isExpanded,
  isFirstChild,
  isLastChild,
  level,
}: RenderIndentationLinesParams) => {
  const indentationLines = Array.from({ length: level }, (_, index) => {
    return (
      <StyledTreeNodeIndentationLine
        key={index}
        isFirstChild={isFirstChild && level - 1 === index}
        isLastChild={isLastChild && level - 1 === index && !isExpanded}
        level={index}
      />
    );
  });

  return indentationLines;
};

const TreeNode: React.FC<TreeNodeProps> = ({
  checkboxProps,
  children,
  disableHover = false,
  expandOnNodeClick = true,
  hoverBackgroundColor,
  hoverFontWeight,
  hoverLetterSpacing,
  isCaretVisible,
  isDefaultExpanded = false,
  isFirstChild,
  isFlatHierarchy = false,
  isHighlighted,
  isLastChild,
  isLoading,
  label,
  level,
  nodeExtraProps,
  offset,
  onCaretClick,
  onExpand,
  onMount,
  onNodeClick,
  showCheckbox,
  showIndentationLines,
  useNewLayout,
}) => {
  const [isExpanded, setExpanded] = useState(isDefaultExpanded);
  const onExpandRef = useRef<(expanded: boolean) => void>();
  onExpandRef.current = onExpand;

  useEffect(() => {
    onExpandRef.current?.(isExpanded);
  }, [isExpanded]);

  const onMountRef = useRef<() => void>();
  onMountRef.current = onMount;

  useEffect(() => {
    onMountRef.current?.();
  }, []);

  const handleNodeClick = () => {
    onNodeClick?.();

    if (expandOnNodeClick && !isExpanded) {
      setExpanded(true);
    }
  };

  const handleCaretClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setExpanded((prev) => {
      const newExpanded = !prev;
      onCaretClick?.(newExpanded);
      return newExpanded;
    });
  };

  const getCompWidth = () => {
    if (useNewLayout) {
      let width = '18px';
      if (isFlatHierarchy) {
        width = 'auto';
      }
      return width;
    }
    if (showCheckbox) {
      return isCaretVisible ? '20px' : '0px';
    }

    return level > 0 || isCaretVisible ? '20px' : '0px';
  };

  useEffect(() => {
    if (isDefaultExpanded) {
      setExpanded(isDefaultExpanded);
    }
  }, [isDefaultExpanded]);

  const chevronIcon = useNewLayout ? 'right-chevron' : 'arrow-right-filled';

  return (
    <>
      <StyledTreeNode
        aria-expanded={isExpanded}
        as="li"
        disableHover={disableHover}
        hoverBackgroundColor={hoverBackgroundColor}
        hoverFontWeight={hoverFontWeight}
        hoverLetterSpacing={hoverLetterSpacing}
        isCaretVisible={isCaretVisible}
        isFlatHierarchy={isFlatHierarchy}
        isHighlighted={isHighlighted}
        offset={offset?.() ?? level}
        onClick={handleNodeClick}
        role="treeitem"
        useNewLayout={useNewLayout}
        {...nodeExtraProps}
      >
        {showIndentationLines &&
          renderIndentationLines({ isExpanded, isFirstChild, isLastChild, level })}
        <StyledTreeNodeItemWrapper alignItems="center" compDisplay="flex">
          {showCheckbox && (
            <Checkbox ml={calcCheckboxMarginLeft(level)} mr={0.75} {...checkboxProps} />
          )}
          <Box
            alignItems="center"
            compDisplay="flex"
            compWidth={getCompWidth()}
            flexShrink={0}
            justifyContent="center"
          >
            {isCaretVisible && (
              <StyledTreeNodeCaret
                isExpanded={isExpanded}
                onClick={handleCaretClick}
                useNewLayout={useNewLayout}
              >
                {isLoading ? (
                  <CircularLoader borderWidth={1} color="primary.500" compSize={1.5} />
                ) : (
                  <Icon color="currentcolor" name={chevronIcon} size="16px" />
                )}
              </StyledTreeNodeCaret>
            )}
          </Box>
          <StyledTreeNodeLabelWrapper>{label}</StyledTreeNodeLabelWrapper>
        </StyledTreeNodeItemWrapper>
      </StyledTreeNode>
      {isExpanded && children}
    </>
  );
};

export default TreeNode;
