import { format } from 'sql-formatter';

const dataSourceToLanguageMap = {
  postgres: 'postgresql',
  bigquery: 'bigquery',
  redshift: 'redshift',
} as const;

type DataSources = keyof typeof dataSourceToLanguageMap;

const sqlFormat = (sql: string, dataSourceType: string = '') => {
  try {
    return format(sql, {
      language: dataSourceToLanguageMap[dataSourceType as DataSources] || 'sql',
    });
  } catch {
    return sql;
  }
};

export default sqlFormat;
