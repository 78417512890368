import React from 'react';

import Box from '@components/Box';
import Modal, { ModalHeader } from '@components/UI/Modal';

import CreateCollectionForm from '../CreateCollectionForm';

export interface CreateCollectionModalProps {
  guid?: string;
  onClose?: () => void;
}

const CreateCollectionModal: React.FC<CreateCollectionModalProps> = ({ guid, onClose }) => {
  return (
    <Modal id="create-collection-modal" onClose={onClose}>
      <ModalHeader
        onClose={onClose}
        title={
          <Box alignItems="center" compDisplay="flex">
            <Box mr={1}>{guid ? 'Edit Collection' : 'Create Collection'}</Box>
          </Box>
        }
      />
      <CreateCollectionForm guid={guid} onClose={onClose} onSuccess={onClose} />
    </Modal>
  );
};

export default CreateCollectionModal;
