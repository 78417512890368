import React, { memo, useContext, useState } from 'react';

import { UseAvatarInitialsEnum } from '@api/openapi.generated';
import type { IconProps } from '@components/UI/Icon';
import Icon from '@components/UI/Icon';
import { UserContext } from '@context/User/User';
import theme from '@styles/theme';
import getInitials from '@utils/getInitials';
import teamColorGenerator from '@utils/teamColorGenerator';

import type { StyledAvatarProps } from './Avatar.styles';
import { StyledAvatar, StyledAvatarImage, StyledAvatarInitials } from './Avatar.styles';

const sizes = {
  lg: [9, 7],
  md: [8, 6],
  sm: [7, 5],
  xs: [6, 5],
};

export interface AvatarProps extends Omit<StyledAvatarProps, 'isError' | 'isGray' | 'fontSize'> {
  avatarInitialsConfig?: UseAvatarInitialsEnum;
  backgroundColor?: string;
  guid?: string;
  icon?: Pick<IconProps, 'name' | 'color' | 'size'>;
  initialSize?: 'xs' | 'sm' | 'md' | 'lg';
  isActive?: boolean;
  isDeleted?: boolean;
  name?: string;
  src?: string;
}

const Avatar: React.FC<AvatarProps> = ({
  avatarInitialsConfig = 'english',
  backgroundColor,
  guid,
  icon,
  initialSize = 'lg',
  isActive = true,
  isDeleted = false,
  name,
  size = '1.25rem',
  src = '',
  ...other
}) => {
  const [imageStatus, setImageStatus] = useState<'loaded' | 'error' | 'initial'>('initial');

  const onLoad = () => {
    setImageStatus('loaded');
  };

  const onError = () => {
    setImageStatus('error');
  };

  const isLoaded = imageStatus === 'loaded';
  const isError = imageStatus === 'error';
  const showError = isError || isDeleted;
  const errorMsg = isDeleted ? 'deactivated user' : 'error loading image';
  const showImage = Boolean(src) && !showError;
  const initials = getInitials(name, avatarInitialsConfig);
  const ariaLabel = showError ? errorMsg : initials;

  const getColor = () => {
    if (backgroundColor) return backgroundColor;
    if (isDeleted || !isActive) return theme.colors.gray[200];
    if (showImage && !icon?.name) return 'transparent';
    if (!isDeleted && icon?.name) return 'transparent';
    return teamColorGenerator(guid);
  };

  const getStatus = () => {
    if (icon?.name) return 'icon';
    if (showImage) return 'image';
    return 'initials';
  };

  const bgColor = getColor();
  const showing = getStatus();

  const defaultFontSize = sizes[initialSize];

  return (
    <StyledAvatar
      aria-label={ariaLabel}
      backgroundColor={bgColor}
      isGrey={!isActive || isDeleted}
      size={size}
      {...other}
    >
      {showing === 'initials' && (
        <StyledAvatarInitials viewBox="0 0 14 14">
          <text
            dominantBaseline="central"
            fontSize={initials.length === 1 ? defaultFontSize[0] : defaultFontSize[1]}
            textAnchor="middle"
            x="50%"
            y="50%"
          >
            {initials}
          </text>
        </StyledAvatarInitials>
      )}
      {showing === 'icon' && icon && <Icon {...icon} />}
      {showing === 'image' && (
        <StyledAvatarImage
          alt={name}
          isLoaded={isLoaded}
          onError={onError}
          onLoad={onLoad}
          src={src}
        />
      )}
    </StyledAvatar>
  );
};

/**
 * We are using the UserContext to get the organization settings and the
 * avatar configuration. We can not use the useUserContext directly because
 * the Avatar component is also used outside the UserContextProvider.
 */
const EnrichedAvatar: React.FC<AvatarProps> = (props) => {
  const userContext = useContext(UserContext);
  const { avatarInitialsConfig } = props;

  let orgAvatarConfiguration: UseAvatarInitialsEnum | undefined = avatarInitialsConfig;

  if (userContext) {
    const { organization } = userContext;
    const { useAvatarInitials } = organization?.settings ?? {};
    orgAvatarConfiguration = useAvatarInitials;
  }

  return <Avatar {...props} avatarInitialsConfig={orgAvatarConfiguration} />;
};

export default memo(EnrichedAvatar);
