import styled from '@emotion/styled';

import Box from '@components/Box';

export const StyledDropdownMenuList = styled(Box)`
  background-color: white;
  border-color: ${({ theme }) => theme.colors.v1.gray[100]};
  border-radius: ${({ theme }) => theme.radius.default};
  border-style: solid;
  border-width: 1px;
`;

StyledDropdownMenuList.defaultProps = {
  as: 'ul',
  boxShadow: 'sm',
};

export const StyledDropdownMenuListItem = styled.li`
  align-items: center;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.v1.gray[600]};
  cursor: pointer;
  display: flex;
  font-size: ${({ theme }) => theme.typography.fontSizes.body2};
  height: 36px;
  min-width: max-content;
  overflow: hidden;
  padding: ${({ theme }) => theme.space(0, 1.25)};

  &:hover {
    background-color: ${({ theme }) => theme.colors.dropdownHighlight};
  }

  &:first-of-type {
    border-radius: ${({ theme }) => `${theme.radius.default} ${theme.radius.default} 0 0`};
  }

  &:last-of-type {
    border-radius: ${({ theme }) => `0 0 ${theme.radius.default} ${theme.radius.default}`};
  }
`;
