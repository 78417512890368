import React from 'react';
import { MONTE_CARLO_DOCS_URL } from '@constants';

import Alert from '@components/Alert';
import Link from '@components/Link';
import theme from '@styles/theme';

const MonteCarloDocumentationAlert = () => {
  return (
    <Alert type="info">
      See instructions in our{' '}
      <Link
        color={theme.colors.hyperlink}
        fontSize="inherit"
        href={MONTE_CARLO_DOCS_URL}
        rel="noopener noreferrer"
        target="_blank"
      >
        documentation
      </Link>
      .
    </Alert>
  );
};

export default MonteCarloDocumentationAlert;
