import React from 'react';

import { useFetchDashboardFrequentDSUsers } from '@api/dashboards';
import { useFetchDatasetFrequentDSUsers } from '@api/datasets';
import { useFetchExploreFrequentDSUsers } from '@api/explores';
import { useFetchLookmlViewFrequentDSUsers } from '@api/lookML';
import { useFetchTableBiFrequentDSUsers, useFetchTableFrequentDSUsers } from '@api/tables';
import type { ObjectType } from '@api/types';
import TopUsersTable from '@components/Table/TopUsersTable';
import { TabContentProps } from '@components/Tabs/types';
import { useMetadataObjectContext } from '@context/MetadataObject';
import DataTypesModel from '@models/DataTypesModel';
import { MetadataData } from '@models/MetadataModel';
import { Filter } from '@utils';
import stripSpaces from '@utils/stripSpaces';

import TabError from './TabError';

const searchConfig: { [key: string]: keyof Filter.FilterOptions } = {
  name: 'search_name',
};

const sortConfig: { [key: string]: string } = {
  lastRunDate: 'last_queried',
  name: 'name',
  queryCount: 'query_count',
};

const query = stripSpaces(`{
  guid,
  name,
  display_name,
  user {
    guid,
    email,
    first_name,
    last_name,
    is_active,
    avatar,
    team
  },
  query_count,
  last_queried,
  data_source {
    guid,
    name,
    type
  }
}`);

const frequentUsersConfig: Filter.FilterOptions = {
  order: '-query_count',
  page: 1,
  page_size: 25,
  query,
  sortColumn: 'query_count',
  sortDirection: 'descending',
};

interface UseGetTopUsersProps {
  config: {
    enabled?: boolean;
    keepPreviousData: boolean;
    params: {
      datasources?: string;
    };
  };
  dataTypes?: DataTypesModel;
  guid: string;
  objectType?: ObjectType;
  userType?: 'table' | 'bi';
}

export const useGetTopUsers = ({
  config,
  dataTypes,
  guid,
  objectType,
  userType,
}: UseGetTopUsersProps) => {
  const IS_DATASET = ['powerbidataset'].includes(dataTypes?.objectType!);

  const dashboardQuery = useFetchDashboardFrequentDSUsers(guid, {
    enabled: objectType === 'dashboard',
    ...config,
  });

  const exploreQuery = useFetchExploreFrequentDSUsers(guid, {
    enabled: objectType === 'explore',
    ...config,
  });

  const lookmlViewQuery = useFetchLookmlViewFrequentDSUsers(guid, {
    enabled: objectType === 'lookmlview',
    ...config,
  });

  const tableQuery = useFetchTableFrequentDSUsers(guid, {
    enabled: objectType === 'table' && userType !== 'bi',
    ...config,
  });

  const biTableQuery = useFetchTableBiFrequentDSUsers(guid, {
    enabled: objectType === 'table' && userType === 'bi',
    ...config,
  });

  const datasetQuery = useFetchDatasetFrequentDSUsers(guid, {
    enabled: IS_DATASET,
    ...config,
  });

  if (objectType === 'dashboard') {
    return dashboardQuery;
  }

  if (objectType === 'explore') {
    return exploreQuery;
  }

  if (objectType === 'lookmlview') {
    return lookmlViewQuery;
  }

  if (objectType === 'table' && userType === 'bi') {
    return biTableQuery;
  }

  if (IS_DATASET) {
    return datasetQuery;
  }

  return tableQuery;
};

interface TopUsersTabProps
  extends TabContentProps,
    Pick<UseGetTopUsersProps, 'objectType' | 'userType' | 'dataTypes'> {
  guid: string;
  itemMetadata?: MetadataData;
}

const TopUsersTab: React.FC<TopUsersTabProps> = ({
  dataTypes,
  guid,
  itemMetadata,
  objectType,
  stickyHeader,
  userType,
}) => {
  const FilterService = Filter.useUpdateFilters(
    frequentUsersConfig,
    searchConfig,
    sortConfig,
    '-query_count',
  );
  const { filter } = FilterService;
  const { dataSourceGuid } = useMetadataObjectContext();

  const { data, error, isLoading } = useGetTopUsers({
    config: {
      keepPreviousData: true,
      params: {
        ...Filter.setParams(filter),
        datasources: userType === 'bi' ? undefined : dataSourceGuid,
      },
    },
    dataTypes,
    guid,
    objectType,
    userType,
  });

  const totalPages = data && filter.page_size ? Math.ceil(data.count / filter.page_size) : 1;

  if (error) return <TabError />;

  return (
    <TopUsersTable
      data={data?.results}
      dataType={itemMetadata?.dataType}
      filterService={FilterService}
      isLoading={isLoading}
      itemCount={data?.count}
      stickyHeader={stickyHeader}
      totalPages={totalPages}
    />
  );
};

export default React.memo<TopUsersTabProps>(TopUsersTab);
