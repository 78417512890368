import { Expose, Type } from 'class-transformer';

import { DataSourceModel } from '@models/DataSourceModel';

import DatabaseIngestionConfigModel from './DatabaseIngestionConfigModel';

class DataSourceIngestionConfigModel {
  static objectType: 'datasourceingestionconfig' = 'datasourceingestionconfig';

  objectType = DataSourceIngestionConfigModel.objectType;

  static typeDisplay: 'Data Source Ingestion Config' = 'Data Source Ingestion Config';

  typeDisplay = DataSourceIngestionConfigModel.typeDisplay;

  guid!: string;

  @Expose({ name: 'data_source' })
  @Type(() => DataSourceModel)
  dataSource!: DataSourceModel;

  @Expose({ name: 'query_type_skip_list' })
  queryTableSkipList?: string;

  @Expose({ name: 'default_batch_size' })
  defaultBatchSize?: number;

  @Expose({ name: 'processed_through' })
  processedThrough?: string;

  @Type(() => DatabaseIngestionConfigModel)
  databases!: DatabaseIngestionConfigModel[];
}

export default DataSourceIngestionConfigModel;
