import { useEffect, useState } from 'react';

import breakpoints from '@styles/theme/breakpoints';

interface WindowSize {
  windowHeight: number;
  windowWidth: number;
}

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth,
  });

  const changeWindowSize = () => {
    setWindowSize({ windowHeight: window.innerHeight, windowWidth: window.innerWidth });
  };

  useEffect(() => {
    window.addEventListener('resize', changeWindowSize);

    return () => {
      window.removeEventListener('resize', changeWindowSize);
    };
  }, []);

  return {
    isDownLg: windowSize.windowWidth < breakpoints.lg,
    isDownMd: windowSize.windowWidth < breakpoints.md,
    isDownSm: windowSize.windowWidth < breakpoints.sm,
    isDownXl: windowSize.windowWidth < breakpoints.xl,
    isUpLg: windowSize.windowWidth >= breakpoints.lg,
    isUpMd: windowSize.windowWidth >= breakpoints.md,
    isUpSm: windowSize.windowWidth >= breakpoints.sm,
    isUpXs: windowSize.windowWidth >= breakpoints.xs,
    ...windowSize,
  };
};

export default useWindowSize;
