import React from 'react';

import type { TabRoutesConfig } from '@components/TabsRouter';
import TabsRouter from '@components/TabsRouter';

import CostAnalysisDashboardByCostTab from './CostAnalysisDashboardByCostTab';
import CostAnalysisQueryByCostTab from './CostAnalysisQueryByCostTab';
import CostAnalysisTableByQueryCountTab from './CostAnalysisTableByQueryCountTab';
import CostAnalysisWarehouseTab from './CostAnalysisWarehouseTab';

const CostAnalysisDataTab: React.FC = () => {
  const tabsRouterConfig: TabRoutesConfig[] = [
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <CostAnalysisQueryByCostTab />,
      default: true,
      label: 'Query',
      route: '/query',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <CostAnalysisDashboardByCostTab />,
      label: 'Dashboard',
      route: '/dashboard',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <CostAnalysisWarehouseTab />,
      label: 'Warehouse',
      route: '/warehouse',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <CostAnalysisTableByQueryCountTab />,
      label: 'Table',
      route: '/table',
    },
  ];

  return <TabsRouter config={tabsRouterConfig} mt={1.6} subTabs />;
};

export default CostAnalysisDataTab;
