import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from '@routing/router';

import { usePostPasswordResetVerify } from '@api/password';
import CircularLoader from '@components/CircularLoader';
import TextScreen from '@components/TextScreen';
import TwoColumnDecorativeComponent from '@components/TwoColumnDecorativeComponent';
import TwoColumnLayout from '@components/TwoColumnLayout';
import MetadataDecorator from '@utils/MetadataDecorator';

import ChangePasswordForm from './ChangePasswordForm';

const PasswordChangePage: React.FC = () => {
  const { guid, token } = useParams<{ guid: string; token: string }>();
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);
  const history = useHistory();
  const { isError, isLoading, mutate } = usePostPasswordResetVerify({ retry: false });

  useEffect(() => {
    if (guid && token) {
      mutate({ token, user: guid });
    }
  }, [guid, token, mutate]);

  let leftComponent = <CircularLoader centered />;

  if (!guid || !token || (!isLoading && isError)) {
    leftComponent = (
      <TextScreen
        text="Unable to verify your password reset link."
        title="Invalid password change link"
      />
    );
  }

  if (guid && token && !isLoading && !isError) {
    leftComponent = (
      <ChangePasswordForm onSuccess={() => setShowSuccessScreen(true)} token={token} user={guid} />
    );
  }

  if (showSuccessScreen) {
    leftComponent = (
      <TextScreen
        buttonText="Sign in"
        onClick={() => history.push('/')}
        text="Password has been reset successfully."
        title="Password Changed"
      />
    );
  }

  return (
    <>
      <MetadataDecorator title="Change Password" />
      <TwoColumnLayout left={leftComponent} right={<TwoColumnDecorativeComponent />} />
    </>
  );
};

export default PasswordChangePage;
