import React, { useEffect, useState } from 'react';

import { StyledCircularLoader, StyledCircularLoaderProps } from './CircularLoader.styles';

const CircularLoader: React.FC<StyledCircularLoaderProps> = ({ delay, ...rest }) => {
  const [showLoader, setShowLoader] = useState(!delay);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (delay) {
      timer = setTimeout(() => {
        setShowLoader(true);
      }, delay);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [delay]);

  if (!showLoader) {
    return null;
  }

  return <StyledCircularLoader {...rest} />;
};

export default CircularLoader;
