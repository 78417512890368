import React from 'react';
import { useHistory, useRouteMatch } from '@routing/router';

import Breadcrumbs from '@components/Breadcrumbs';
import CircularLoader from '@components/CircularLoader';
import MetadataObjectButtonsToolbar from '@components/MetadataObjectButtonsToolbar';
import MetadataObjectTemplate from '@components/MetadataObjectTemplate';
import { NotificationProvider } from '@components/Modal/NotifyUsersModal/Notification.context';
import { isNotificationAllowed } from '@components/Modal/NotifyUsersModal/utils';
import createPopularityDescription from '@components/Popularity/createPopularityDescription';
import SidebarMetadataObjectDetails from '@components/SidebarMetadataObjectDetails';
import OverviewTab from '@components/TabContent/OverviewTab';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import { useSessionContext } from '@context/Session';
import { useUserContext } from '@context/User';
import MetadataEditableName from '@pages/MetadataObjectPage/MetadataEditableName';
import theme from '@styles/theme';

import useFetchObjectMetadata from './useFetchObjectMetadata';
import useMutateMetadata from './useMutateMetadata';

const defaultMetadataObjectButtonsToolbarConfig = [
  'external' as const,
  'lineage' as const,
  'erd' as const,
  'sql' as const,
  'share' as const,
  'notify' as const,
];

const MetadataObjectPage: React.FC = () => {
  const { params, path } = useRouteMatch<{ guid: string }>();
  const { guid } = params;
  const history = useHistory();
  const { isEditable: isObjectEditable } = useObjectPermissionsContext({ id: guid });
  const { dataSources, isOrgAdmin } = useUserContext();
  const { sidebarItems } = useSessionContext();

  const { config, fetchData } = useFetchObjectMetadata({ guid, path });

  /** @todo Architecture. Discuss why do we need miniTable? */
  const miniTable = guid && sidebarItems?.[guid];
  const {
    Content,
    OverviewCustomContent,
    descriptionAllowedElements,
    enableSidebarMetadataObjectDetails,
    metadataObjectButtonToolbarConfig,
    patchHook,
    redirectOnDelete,
  } = config;

  const { data, error, isError, isFetching, isLoading, refetch } = fetchData;

  const dataSourceGuid = data?.dataSourceGuid ?? '';
  const dataSourceType = data?.dataTypes.dataSourceType;
  const isDatasourceEditable = Boolean(dataSources?.[dataSourceGuid]?.settings?.isEditable ?? true);
  let isEditable = isDatasourceEditable && isObjectEditable;

  if (data?.dataTypes?.objectType === 'tag') {
    isEditable &&= isOrgAdmin;
  }

  const enableNotifications = isNotificationAllowed(dataSourceType);

  const { name = '' } = {
    ...(miniTable || {}),
    ...(data || {}),
  };

  const {
    isError: isPatchMetadataError,
    isLoading: isPatchMetadataLoading,
    mutate: mutatePatchMetadata,
  } = useMutateMetadata({ guid, refetch });

  if (isLoading) {
    return <CircularLoader centered compSize={5} cover data-testid="page-loading" />;
  }

  return (
    <NotificationProvider>
      <MetadataObjectTemplate
        data={data}
        dataSourceGuid={dataSourceGuid}
        dataSourceType={dataSourceType}
        dataTypes={data?.dataTypes}
        deleteAllowed={data?.deleteAllowed && isEditable}
        guid={guid}
        headerProps={{
          icon: (
            <Tooltip content={data?.dataTypes?.tooltips.dataType}>
              {data?.objectTypeV1 === 'tags' ? (
                <Icon color={data?.color} name={data.icon} size="32px" />
              ) : (
                <Icon name={data?.dataTypes?.icons.dataType} size="32px" />
              )}
            </Tooltip>
          ),
          rightEdgeEl: (
            <MetadataObjectButtonsToolbar
              buttons={
                metadataObjectButtonToolbarConfig?.buttons ??
                defaultMetadataObjectButtonsToolbarConfig
              }
              dataTypes={data?.dataTypes}
              enableNotifications={enableNotifications}
              externalUrl={data?.externalUrl}
              guid={guid}
              showLastQuery={Boolean(data?.showTableLastQuery)}
              sql={data?.rawSql}
            />
          ),
          supIcon: data?.dataTypes && (
            <Icon
              color={theme.colors.gray[600]}
              name={data.dataTypes.icons.dataSource}
              size="16px"
            />
          ),
          supTitle: data?.breadcrumbList ? (
            <Breadcrumbs items={data?.breadcrumbList} />
          ) : (
            data?.supTitle
          ),
          title:
            data?.editNameAllowed && patchHook ? (
              <MetadataEditableName data={data} isEditable={isEditable} patchHook={patchHook} />
            ) : (
              name
            ),
        }}
        isAuthorized={!(error && error?.status === 403)}
        isError={isError || !guid}
        metaTitle={name}
        objectName={name}
        objectType={data?.objectTypeV1}
        onDeleteSuccess={() => {
          history.push(redirectOnDelete || '/');
        }}
        onTagsEdit={() => refetch()}
        tagsEditAllowed={data?.showEditTags && isEditable}
      >
        <Content
          data={data}
          guid={guid}
          isEditable={isEditable}
          name={name}
          overviewTabConfig={{
            // eslint-disable-next-line react/no-unstable-nested-components
            component: () => (
              <OverviewTab
                canEditDescription={isEditable}
                data={data}
                description={data?.description}
                descriptionAllowedElements={descriptionAllowedElements}
                enableSidebarMetadataObjectDetails={enableSidebarMetadataObjectDetails}
                guid={guid}
                isEditable={isEditable}
                isError={isPatchMetadataError}
                isLoading={isFetching || isPatchMetadataLoading}
                onDescriptionSave={(richtextDesc, plainTextDesc, descSource) =>
                  mutatePatchMetadata({
                    description: plainTextDesc,
                    description_source: descSource,
                    richtext_description: richtextDesc,
                  })
                }
                reloadData={() => refetch()}
                renderBelowEditor={() =>
                  OverviewCustomContent && (
                    <OverviewCustomContent canEdit={isEditable} data={data} />
                  )
                }
                richtextDescription={data?.richtextDescription}
                showCustomAttributes={data?.showCustomAttributes}
                suggestedDescriptionSourceObject={data?.suggestedDescriptionSourceObject?.obj}
              >
                <SidebarMetadataObjectDetails
                  businessOwner={data?.businessOwner === null ? null : data?.businessOwner?.obj}
                  bytes={data?.bytes}
                  connections={data?.connections}
                  created={data?.created}
                  data={data}
                  editOwnersItems={data ? [data] : undefined}
                  guid={guid}
                  isEditable={isEditable}
                  itemsType={data?.itemsType}
                  lastRefreshed={data?.lastRefreshed}
                  lastRun={data?.lastRun}
                  lastUpdated={data?.lastUpdated}
                  loadingStatus={data?.loadingStatus}
                  materialization={data?.materialization}
                  objectType={data?.objectType}
                  owner={data?.owner}
                  popularity={data?.popularity}
                  popularityDescription={createPopularityDescription(
                    data?.popularity,
                    data?.dataType,
                  )}
                  reloadData={() => refetch()}
                  rowCount={data?.rowCount}
                  showLinkedFrom={data?.showLinkedFrom}
                  showLinksTo={data?.showLinksTo}
                  showMentionedBy={data?.showMentionedBy}
                  technicalOwner={data?.technicalOwner === null ? null : data?.technicalOwner?.obj}
                />
              </OverviewTab>
            ),
            label: 'Overview',
            path: '/overview',
            route: '/overview',
          }}
        />
      </MetadataObjectTemplate>
    </NotificationProvider>
  );
};

export default MetadataObjectPage;
