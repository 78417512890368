import { Expose, Transform, Type } from 'class-transformer';
import moment from 'moment';

import { ActivityMessageModel } from '@api/activity/ActivityMessageModel';
import type { ActivityType } from '@api/activity/types';
import { GeneratedByType } from '@api/activity/types';
import { activityTypeToUserAction } from '@api/activity/utils';
import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import { breadcrumbsToLabelList } from '@components/Breadcrumbs';
import { DataSourceModel } from '@models/DataSourceModel';
import DataTypesModel from '@models/DataTypesModel';
import { urlFor } from '@utils/routing';

import AnalyticsUserModel from './AnalyticsUserModel';

class AnalyticsActivityModel {
  static objectType: string = 'analyticsactivity';

  objectType: string = AnalyticsActivityModel.objectType;

  field?: string;

  // analytics only use user generated activities
  generatedBy: GeneratedByType = 'user';

  @Expose({ name: 'activity_type' })
  activityType!: ActivityType;

  get userAction() {
    return activityTypeToUserAction(this.activityType);
  }

  @Type(() => AnalyticsUserModel)
  user!: AnalyticsUserModel;

  @Type(() => ActivityMessageModel)
  message!: ActivityMessageModel;

  @Expose({ name: 'target_name' })
  targetName!: string;

  @Expose({ name: 'target' })
  targetGuid!: string;

  @Expose({ name: 'target_object_type' })
  targetObjectType!: string;

  @Expose({ name: 'target_data_type' })
  targetDataType!: string;

  @Expose({ name: 'target_parent_guid' })
  targetParentGuid?: string;

  @Type(() => DataSourceModel)
  @Expose({ name: 'target_data_source' })
  targetDataSource?: DataSourceModel;

  @Expose({ name: 'performed_on' })
  @Transform((value) => moment(value))
  performedOn!: moment.Moment;

  @Expose({ name: 'data_types' })
  @Type(() => DataTypesModel)
  dataTypes?: DataTypesModel = new DataTypesModel();

  get targetRoutePath() {
    return urlFor({
      dataType: this.dataTypes?.dataType,
      dataTypes: this.dataTypes,
      guid: this.targetGuid,
      objectType: this.dataTypes?.objectType,
      parentGuid: this.targetParentGuid,
    });
  }

  @Type(() => BreadcrumbModel)
  breadcrumbs?: BreadcrumbModel[];

  get breadcrumbLabelList() {
    return breadcrumbsToLabelList(this.targetName, this.targetRoutePath, this.breadcrumbs);
  }
}

export default AnalyticsActivityModel;
