import stripSpaces from '@utils/stripSpaces';

const DASHBOARD_QUERY = stripSpaces(`{
    guid,
    source_tables_count,
    bifolder {
      guid,
      name,
      description
    },
    name,
    data_type,
    data_types,
    url,
    description,
    user_description,
    richtext_description,
    dsuser_created_by {
      guid,
      name,
      data_types,
      display_name,
      user {
        guid,
        first_name,
        last_name
      }
    },
    dsuser_owned_by {
      data_types,
      display_name,
      email,
      user,
      guid,
      name,
    },
    popularity,
    last_query_error {
      message,
      event_on
    },
    tagged_items,
    was_last_run_successful,
    last_successful_run,
    last_run_at,
    is_hidden,
    dashboard_created_at,
    dashboard_updated_at,
    ai_description,
    ingested_description,
    description_source,
  }`);

export default DASHBOARD_QUERY;
