import React from 'react';

import { exploresCacheKeys } from '@api/explores';
import { fieldsCacheKeys } from '@api/fields';
import invalidateCache from '@api/invalidateCache';
import EditableCustomAttributesSection from '@components/EditableCustomAttributesSection';
import RichTextDescriptionEditor from '@components/RichTextDescriptionEditor';
import fetchClient from '@lib/fetchClient';
import { MetadataModel } from '@models/MetadataModel';
import useMutateMetadata from '@pages/MetadataObjectPage/useMutateMetadata';

interface MetadataOverviewProps {
  guid: string;
  isEditable: boolean;
  selectedNodeMetadata?: MetadataModel;
}

const MetadataOverview: React.FC<MetadataOverviewProps> = ({
  guid,
  isEditable,
  selectedNodeMetadata,
}) => {
  const { isLoading: isPatchMetadataLoading, mutate: mutatePatchMetadata } = useMutateMetadata({
    guid,
    refetch: () => {
      invalidateCache((keys) => [
        keys.columns.all,
        keys.dashboards.elements(selectedNodeMetadata?.parentGuid ?? ''),
        keys.metadata.metadata(guid),
        keys.dbt.tests,
        keys.activity.all,
      ]);
      fetchClient.invalidateQueries(fieldsCacheKeys.fields);
      fetchClient.invalidateQueries(
        exploresCacheKeys.exploreFields(selectedNodeMetadata?.parentGuid ?? ''),
      );
    },
  });

  return (
    <>
      <EditableCustomAttributesSection
        key="customAttributes"
        cardProps={{ px: 1.75, py: 1.25 }}
        guid={guid ?? ''}
        isEditable={isEditable}
        titleFontSize="body2"
      />
      <RichTextDescriptionEditor
        key="description"
        dataSourceType={selectedNodeMetadata?.dataTypes?.dataSourceType}
        descriptions={{
          aiDescription: selectedNodeMetadata?.aiDescription,
          description: selectedNodeMetadata?.description,
          ingestedDescription: selectedNodeMetadata?.ingestedDescription,
          richTextDescription: selectedNodeMetadata?.richtextDescription,
          suggestedDescription: selectedNodeMetadata?.suggestedDescription,
          userDescription: selectedNodeMetadata?.userDescription,
        }}
        descriptionSource={selectedNodeMetadata?.descriptionSource}
        guid={guid}
        isEditable={isEditable}
        isLoading={isPatchMetadataLoading}
        onDescriptionSave={(richtextDesc, plainTextDesc, descriptionSource) =>
          mutatePatchMetadata({
            description: plainTextDesc,
            description_source: descriptionSource,
            richtext_description: richtextDesc,
          })
        }
        showDescriptionSelector
        suggestedDescriptionSource={selectedNodeMetadata?.suggestedDescriptionSource}
        suggestedDescriptionSourceObject={
          selectedNodeMetadata?.suggestedDescriptionSourceObject?.obj
        }
        titleFontSize="body2"
        variant="block"
        wrapperProps={{ px: 1.75, py: 1.25 }}
      />
    </>
  );
};

export default MetadataOverview;
