import React from 'react';

import { useFetchDsUserMostQueriedDashboards } from '@api/dsusers';
import TabError from '@components/TabContent/TabError';
import MostUsedTable from '@components/Table/MostUsedTable';
import { TabContentProps } from '@components/Tabs/types';
import { DataSourceTypesType } from '@models/DataSourceCredentials';

const querySummaryConfig = {
  page_size: 20,
};
interface LookerDashboardTabProps extends TabContentProps {
  dataSourceType?: DataSourceTypesType;
  guid: string;
}

const LookerDashboardTab: React.FC<LookerDashboardTabProps> = ({ dataSourceType, guid }) => {
  const { data, isError, isLoading } = useFetchDsUserMostQueriedDashboards(guid, {
    params: querySummaryConfig,
  });

  if (isError) return <TabError />;

  return (
    <MostUsedTable
      data={data?.results}
      dataSourceType={dataSourceType}
      itemCount={data?.count}
      loading={isLoading}
    />
  );
};

export default LookerDashboardTab;
