import React from 'react';
import getIcons from '@configs/icons/getIcons';

import Box from '@components/Box';
import { BreadcrumbItem } from '@components/Breadcrumbs';
import Icon from '@components/UI/Icon';
import { ValidDSType } from '@models/DataSourceCredentials';
import { MetadataModel } from '@models/MetadataModel';
import theme from '@styles/theme';
import { urlFor } from '@utils/routing';

import { StyledBreadcrumbs } from './SourceLink.styles';

export interface SourceLinkProps {
  dataSourceType?: ValidDSType;
  suggestedDescriptionSourceObject?: MetadataModel;
}

const SourceLink: React.FC<SourceLinkProps> = ({
  dataSourceType,
  suggestedDescriptionSourceObject,
}) => {
  const parentBreadcrumb =
    suggestedDescriptionSourceObject?.breadcrumbs?.[
      (suggestedDescriptionSourceObject?.breadcrumbs?.length ?? 0) - 1
    ];

  const url = urlFor({
    dataTypes: suggestedDescriptionSourceObject?.dataTypes,
    guid: suggestedDescriptionSourceObject?.guid,
    objectType: suggestedDescriptionSourceObject?.objectType,
    parentGuid: parentBreadcrumb?.targetGuid ?? suggestedDescriptionSourceObject?.parentGuid,
  });

  const sourceWithoutPage = url === '#';

  const breadcrumbs = [
    ...(suggestedDescriptionSourceObject?.breadcrumbList || []),
    {
      guid: suggestedDescriptionSourceObject?.guid,
      label: suggestedDescriptionSourceObject?.name,
    } as BreadcrumbItem,
  ];

  return (
    <Box compDisplay="flex" flexDirection="row" gap={0.5}>
      <Box mt={-0.1}>
        <Icon
          name={
            getIcons({
              dataSourceType:
                dataSourceType ?? suggestedDescriptionSourceObject?.dataTypes?.dataSourceType,
            }).dataSource
          }
          size="16px"
        />
      </Box>
      <StyledBreadcrumbs
        clickable={!sourceWithoutPage}
        color={theme.colors.v1.gray[600]}
        fixedUrl={sourceWithoutPage ? undefined : url}
        items={breadcrumbs}
        showTrailingSlash={false}
      />
    </Box>
  );
};

export default SourceLink;
