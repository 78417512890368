export const USAGE_WEIGHT: { [key: string]: number } = {
  '': 5,
  aggregated: 2,
  asis: -50, // We need a negative value in the asis to make sure it is always the first option when calculating the total weight
  filter: 4,
  transformed: 3,
};

const calculateTotalWeight = (total: number, newValue: number) => {
  if (total === 0) {
    return total + newValue;
  }
  return total - newValue;
};

const getUsageWeight = (usage: string) => {
  let total = 0;

  if (usage.includes('asis')) {
    total = calculateTotalWeight(total, USAGE_WEIGHT.asis);
  }
  if (usage.includes('transformed')) {
    total = calculateTotalWeight(total, USAGE_WEIGHT.transformed);
  }
  if (usage.includes('aggregated')) {
    total = calculateTotalWeight(total, USAGE_WEIGHT.aggregated);
  }
  if (usage.includes('filter')) {
    total = calculateTotalWeight(total, USAGE_WEIGHT.filter);
  }

  return total;
};

const sortByName = (a: string, b: string, order: 'asc' | 'desc') => {
  return order === 'asc' ? a.localeCompare(b) : b.localeCompare(a);
};

const sortByPopularity = (a: number, b: number, order: 'asc' | 'desc') => {
  return order === 'asc' ? b - a : a - b;
};

const sortByUsageAndPopularity = (
  valuesMap: {
    popularity: {
      a: any;
      b: any;
    };
    usage: {
      a: any;
      b: any;
    };
  },
  options: {
    order: 'default' | 'asc' | 'desc';
  } = { order: 'default' },
) => {
  const usageA = valuesMap.usage.a;
  const usageB = valuesMap.usage.b;
  const { order } = options;
  const isAscendedOrder = ['default', 'asc'].includes(order);

  if (!usageA && !usageB) {
    return sortByPopularity(
      valuesMap.popularity.a,
      valuesMap.popularity.b,
      isAscendedOrder ? 'asc' : 'desc',
    );
  }

  if (usageA && !usageB) {
    return isAscendedOrder ? -1 : 1;
  }

  if (usageB && !usageA) {
    return isAscendedOrder ? 1 : -1;
  }

  const aWeight = getUsageWeight(usageA);
  const bWeight = getUsageWeight(usageB);
  const difference = isAscendedOrder ? aWeight - bWeight : bWeight - aWeight;

  if (difference === 0) {
    if (valuesMap.popularity.a === valuesMap.popularity.b) {
      return sortByName(valuesMap.usage.a, valuesMap.usage.b, isAscendedOrder ? 'asc' : 'desc');
    }

    return sortByPopularity(
      valuesMap.popularity.a,
      valuesMap.popularity.b,
      isAscendedOrder ? 'asc' : 'desc',
    );
  }

  return difference;
};

export default sortByUsageAndPopularity;
