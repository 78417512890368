import styled from '@emotion/styled';

interface StyledDataSourceItem {
  isSelected?: boolean;
}

export const StyledDataSourceSelectionList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.space(1)};

  ${({ theme }) => theme.media.up('md')`
    gap: ${theme.space(2)};
  `};
`;

StyledDataSourceSelectionList.defaultProps = {
  role: 'listbox',
};

export const StyledDataSourceSelectionOption = styled.li<StyledDataSourceItem>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: calc((100% / 3) - 8px);
  border-radius: 6px;
  outline: ${({ isSelected, theme }) =>
    isSelected
      ? `2px solid ${theme.colors.v1.primary[500]}`
      : `1px solid ${theme.colors.v1.gray[200]}`};
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.space(1.5, 1)};
  cursor: pointer;
  user-select: none;
  text-align: center;
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
  font-size: ${({ theme }) => theme.typography.fontSizes.body1};
  color: ${({ theme }) => theme.colors.v1.gray[600]};
  gap: ${({ theme }) => theme.space(0.5)};

  ${({ theme }) => theme.media.up('md')`
    width: calc((100% / 6) - 14px);
    min-width: 85px;
  `};
`;

StyledDataSourceSelectionOption.defaultProps = {
  role: 'option',
};
