import React from 'react';

import { useFetchMostPopularItems } from '@api/mostPopularItems';
import MetadataGrid from '@components/MetadataGrid';
import { useUserContext } from '@context/User';

import MainPageSection from '../MainPageSection';
import MetadataGridHomepageCard from '../MetadataGridHomepageCard';

const PopularInOrg = () => {
  const { organization } = useUserContext();
  const { data, isLoading } = useFetchMostPopularItems({
    params: {
      page_size: 4,
    },
  });

  if (isLoading || !data?.length) {
    return null;
  }

  return (
    <MainPageSection label={`Popular in ${organization?.name}`}>
      <MetadataGrid data={data}>
        {(obj) => {
          return <MetadataGridHomepageCard obj={obj} />;
        }}
      </MetadataGrid>
    </MainPageSection>
  );
};

export default PopularInOrg;
