import React from 'react';
import { Descendant, Editor } from 'slate';

import Box from '@components/Box';
import MarkdownRichTextEditor from '@components/MarkdownRichTextEditor';
import convertStringToSlate from '@components/RichTextEditor/helpers/convertStringToSlate';
import { serializeSlateToPlainText } from '@components/RichTextEditor/helpers/serializationHelpers';
import { RichTextEditorElement } from '@components/RichTextEditor/RichTextEditor.types';
import { renderErrorToast } from '@components/Toast';
import { useFetchedMentions } from '@context/FetchedMentions';
import useMarkdownConverter from '@hooks/useMarkdownConverter';

import { EditorVariant } from '../RichTextDescriptionEditor.styles.variants';
import ToggleMarkdown from '../ToggleMarkdown';

export interface ConversionCache {
  markdown?: string;
  richText?: string;
}

interface UseMarkdownDescriptionEditorParams {
  allowedElements?: RichTextEditorElement[];
  descriptionValue: {
    convertedRichText: string;
    currentMarkdown: string;
  };
  editor: Editor;
  editorVariant: EditorVariant;
  onCancel: () => void;
  onDescriptionSave?: (description: string, plainTextDescription?: string) => void;
  onSaveMarkdown: () => void;
  onToggleEditor: (targetType: 'markdown' | 'richText') => void;
  operationTypeRef: React.MutableRefObject<'save' | 'convert' | undefined>;
  setCache: React.Dispatch<
    React.SetStateAction<{
      toMarkdown?: ConversionCache | undefined;
      toRichText?: ConversionCache | undefined;
    }>
  >;
  setDescriptionValue: React.Dispatch<
    React.SetStateAction<{
      convertedRichText: string;
      currentMarkdown: string;
    }>
  >;
  setIsEditing: (value: React.SetStateAction<boolean>) => void;
  setShowMarkdownEditor: React.Dispatch<React.SetStateAction<boolean>>;
  setSlateEditorState: React.Dispatch<React.SetStateAction<Descendant[]>>;
  textValue: string;
}

const useMarkdownDescriptionEditor = ({
  allowedElements,
  descriptionValue,
  editor,
  editorVariant,
  onCancel,
  onDescriptionSave,
  onSaveMarkdown,
  onToggleEditor,
  operationTypeRef,
  setCache,
  setDescriptionValue,
  setIsEditing,
  setShowMarkdownEditor,
  setSlateEditorState,
  textValue,
}: UseMarkdownDescriptionEditorParams) => {
  const { getMentionFromCacheById } = useFetchedMentions();

  const {
    convertMarkdownToRichText,
    convertRichTextToMarkdown,
    isLoading: isConversionLoading,
  } = useMarkdownConverter({
    onConvertToMarkdownSuccess: (data) => {
      const oldRichText = JSON.stringify(editor.children);

      setCache((prevCache) => ({
        ...prevCache,
        toMarkdown: {
          markdown: data.richtextDescription,
          richText: oldRichText,
        },
      }));

      setDescriptionValue((prevDescriptionValue) => ({
        ...prevDescriptionValue,
        currentMarkdown: data.richtextDescription,
      }));
      setShowMarkdownEditor(true);
    },
    onConvertToRichTextSuccess: (data) => {
      const oldDescriptionValue = { ...descriptionValue };

      setCache((prevCache) => ({
        ...prevCache,
        toRichText: {
          markdown: oldDescriptionValue.currentMarkdown,
          richText: data.richtextDescription,
        },
      }));

      setDescriptionValue((prevDescriptionValue) => ({
        ...prevDescriptionValue,
        convertedRichText: data.richtextDescription || '',
      }));

      const newSlateState = convertStringToSlate(data.richtextDescription);
      setSlateEditorState(newSlateState);

      if (operationTypeRef.current === 'save') {
        if (data.richtextDescription !== textValue) {
          const slateState = convertStringToSlate(data.richtextDescription);
          const plainTextDescription = serializeSlateToPlainText({
            getMentionFromCacheById,
            nodes: slateState,
          });
          onDescriptionSave?.(data.richtextDescription, plainTextDescription);
        }
        setIsEditing(false);
      }

      setShowMarkdownEditor(false);
    },
    onError: () => {
      renderErrorToast('Operation failed');
    },
  });

  const handleMarkdownChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    event.persist();

    setDescriptionValue((prevDescriptionValue) => ({
      ...prevDescriptionValue,
      currentMarkdown: event.target.value,
    }));
  };

  const ToggleMarkdownComponent = (
    <ToggleMarkdown
      checked={false}
      onChange={() => onToggleEditor('markdown')}
      showLoading={isConversionLoading && operationTypeRef.current === 'convert'}
      variant={editorVariant === 'inline' ? 'small' : 'default'}
    />
  );

  const MarkdownEditorComponent = (
    <MarkdownRichTextEditor
      allowedElements={allowedElements}
      footerComponent={
        <Box px={1}>
          <ToggleMarkdown
            checked
            onChange={() => onToggleEditor('richText')}
            showLoading={isConversionLoading && operationTypeRef.current === 'convert'}
            variant={editorVariant === 'inline' ? 'small' : 'default'}
          />
        </Box>
      }
      initialValue={descriptionValue.currentMarkdown}
      onCancel={onCancel}
      onChange={handleMarkdownChange}
      onSave={onSaveMarkdown}
      variant={editorVariant === 'inline' ? 'inline' : 'block'}
    />
  );

  return {
    MarkdownEditorComponent,
    ToggleMarkdownComponent,
    convertMarkdownToRichText,
    convertRichTextToMarkdown,
    isConversionLoading,
  };
};

export default useMarkdownDescriptionEditor;
