import React from 'react';

import LeavePageConfirmationModal from '@components/Modal/LeavePageConfirmationModal';
import NavigationBlocker from '@components/NavigationBlocker';
import { useModal } from '@context/Modal';

interface EditorNavigationBlockerProps {
  guid?: string;
  onClose?: () => void;
  onConfirm: () => void;
  onDiscard: () => void;
  shouldBlockNavigation?: boolean;
}
const EditorNavigationBlocker: React.FC<EditorNavigationBlockerProps> = ({
  guid,
  onClose,
  onConfirm,
  onDiscard,
  shouldBlockNavigation,
}) => {
  const { MODAL_IDS } = useModal();
  return (
    <>
      <NavigationBlocker
        key={guid}
        confirmButtonText="Save Changes"
        message="You have unsaved changes. Do you want to save the changes?"
        navigateOnCancel
        onConfirm={onConfirm}
        shouldBlockNavigation={shouldBlockNavigation}
      />
      <LeavePageConfirmationModal
        cancelButtonText="Keep Editing"
        confirmButtonText="Save Changes"
        message="You have unsaved changes. Would you like to save them before exiting edit mode?"
        modalId={MODAL_IDS.leavePageConfirmationEditorNavigationBlocker}
        navigateOnCancel
        onClose={onClose}
        onConfirm={onConfirm}
        onDiscard={onDiscard}
        title="Cancel edit?"
      />
    </>
  );
};

export default EditorNavigationBlocker;
