import styled from '@emotion/styled';
import { RouterLink } from '@routing/router';

interface Props {
  lowLine?: boolean;
  underline?: boolean;
}

const StyledLink = styled(RouterLink)<Props>`
  line-height: ${({ lowLine }) => (lowLine ? '1.15rem' : 'inherit')};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
  }
`;

export default StyledLink;
