import { Editor, Element, Transforms } from 'slate';

import deleteTable from './deleteTable';

const deleteRow = (editor: Editor) => {
  if (editor.selection) {
    const row = Editor.above(editor, {
      match: (n) => Element.isElement(n) && n.type === 'tr',
    });

    if (row) {
      const rowParent = Editor.parent(editor, row[1]);
      const isHeaderRow = Element.isElement(rowParent[0]) && rowParent[0].type === 'thead';

      if (isHeaderRow) {
        const body = Editor.next(editor, { at: rowParent[1] });
        const shouldRemoveTable =
          !body ||
          (Element.isElement(body[0]) &&
            (body[0].children.length === 0 || !Element.isElement(body[0].children[0])));
        if (!body || shouldRemoveTable) {
          deleteTable(editor);
          return;
        }

        const bodyRow = Editor.node(editor, [...body[1], 0]);
        if (bodyRow && Element.isElement(bodyRow[0]) && bodyRow[0].type === 'tr') {
          bodyRow[0].children.forEach((_, i) => {
            Transforms.setNodes(editor, { type: 'th' }, { at: [...bodyRow[1], i] });
          });
          const headerPath = [...rowParent[1], 0];

          Transforms.removeNodes(editor, { at: row[1] });
          Transforms.moveNodes(editor, { at: bodyRow[1], to: headerPath });
          return;
        }
      }
      Transforms.removeNodes(editor, {
        at: row[1],
      });
    }
  }
};

export default deleteRow;
