import React from 'react';
import { Components, Options as ReactMarkdownOptions } from 'react-markdown';
import remarkGfm from 'remark-gfm';

import Box from '@components/Box';
import { BlockquoteElement } from '@components/RichTextEditor/RenderElements/BlockquoteElement.styles';
import { CodeElement } from '@components/RichTextEditor/RenderElements/CodeElement.styles';
import {
  H1Element,
  H2Element,
  H3Element,
  H4Element,
  H5Element,
  H6Element,
} from '@components/RichTextEditor/RenderElements/HeadingElement.styles';
import { StyledLinkElement } from '@components/RichTextEditor/RenderElements/LinkElement.styles';
import { OLElement } from '@components/RichTextEditor/RenderElements/OLElement.styles';
import { ParagraphElement } from '@components/RichTextEditor/RenderElements/ParagraphElement.styles';
import {
  StyledTableElement,
  TDElement,
  THeadElement,
  THElement,
  TRElement,
} from '@components/RichTextEditor/RenderElements/TableElements.styles';
import { ULElement } from '@components/RichTextEditor/RenderElements/ULElement.styles';
import { FontSize } from '@styles/theme/typography';

import { StyledReactMarkdown } from './PreviewMarkdown.styles';

const DEFAULT_MARKDOWN_COMPONENTS: Components = {
  a: ({ node, ...props }) => <StyledLinkElement {...props} />,
  blockquote: ({ node, ...props }) => <BlockquoteElement {...props} />,
  code: ({ node, ...props }) => <CodeElement {...props} />,
  h1: ({ node, ...props }) => <H1Element {...props} />,
  h2: ({ node, ...props }) => <H2Element {...props} />,
  h3: ({ node, ...props }) => <H3Element {...props} />,
  h4: ({ node, ...props }) => <H4Element {...props} />,
  h5: ({ node, ...props }) => <H5Element {...props} />,
  h6: ({ node, ...props }) => <H6Element {...props} />,
  img: ({ alt, src }) => {
    //  Transform images into strings since we won't support images for now.
    return <ParagraphElement>{`!(${alt})[${src}]`}</ParagraphElement>;
  },
  ol: ({ node, ...props }) => <OLElement {...props} />,
  p: ({ node, ...props }) => <ParagraphElement {...props} />,
  table: ({ node, ...props }) => <StyledTableElement {...props} />,
  tbody: ({ node, ...props }) => <tbody {...props} />,
  td: ({ node, ...props }) => <TDElement {...props} />,
  th: (props) => <THElement {...props} />,
  thead: ({ node, ...props }) => <THeadElement {...props} />,
  tr: ({ node, ...props }) => <TRElement {...props} />,
  ul: ({ node, ...props }) => <ULElement {...props} />,
};

export interface PreviewMarkdownProps extends Pick<ReactMarkdownOptions, 'linkTarget'> {
  customComponents?: Components;
  fontSize?: FontSize;
  markdown: string;
  minHeight?: string;
}

const PreviewMarkdown: React.FC<PreviewMarkdownProps> = ({
  customComponents,
  fontSize = 'body2',
  linkTarget,
  markdown = '',
  minHeight = 'auto',
}) => {
  return (
    <Box>
      <Box
        data-testid="preview-markdown"
        fontSize={fontSize}
        minHeight={minHeight}
        overflowX="auto"
      >
        <StyledReactMarkdown
          components={{ ...DEFAULT_MARKDOWN_COMPONENTS, ...customComponents }}
          linkTarget={linkTarget}
          remarkPlugins={[remarkGfm]}
        >
          {markdown}
        </StyledReactMarkdown>
      </Box>
    </Box>
  );
};

export default PreviewMarkdown;
