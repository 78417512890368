import { Expose, Type } from 'class-transformer';
import { immerable } from 'immer';
import type { ReactNode } from 'react';

import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import { BreadcrumbItem, breadcrumbsToLabelList } from '@components/Breadcrumbs';
import type { IconProps } from '@components/UI/Icon';
import DataTypesModel from '@models/DataTypesModel';
import { urlFor } from '@utils/routing';

export interface HierarchyData {
  active?: boolean;
  breadcrumbLabelList: BreadcrumbItem[];
  breadcrumbs?: BreadcrumbModel[];
  children: HierarchyData[];
  color?: string;
  dataTypes?: DataTypesModel;
  guid: string;
  icon?: IconProps['name'];
  iconEl?: ReactNode;
  level?: number;
  name: string;
  objectType: string;
  open?: boolean;
  ordinal?: number;
  type?: string;
  url?: string | null;
}

export class HierarchyModel {
  @Expose({ name: 'object_type' })
  objectType?: string;

  guid?: string;

  name?: string;

  type?: string;

  url?: string | null;

  open?: boolean;

  active?: boolean;

  icon?: IconProps['name'];

  iconEl?: ReactNode;

  color?: string;

  @Type(() => HierarchyModel)
  children?: HierarchyModel[];

  @Type(() => BreadcrumbModel)
  breadcrumbs?: BreadcrumbModel[];

  @Expose({ name: 'data_types' })
  @Type(() => DataTypesModel)
  dataTypes?: DataTypesModel = new DataTypesModel();

  shouldIngest?: boolean;

  get breadcrumbLabelList() {
    const url = urlFor(this, false, '');
    return breadcrumbsToLabelList(this.name || '', url, this.breadcrumbs);
  }
}

(HierarchyModel as any)[immerable] = true;
