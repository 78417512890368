import styled from '@emotion/styled';

export const StyledError = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  justify-content: center;
  text-align: center;
  width: 50%;
  height: 100%;
  display: flex;
  margin: auto;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.div`
  font-size: 1rem;
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  margin-bottom: 1em;
  line-height: 1.4285em;
`;

export const Link = styled.a`
  color: #0c3476;
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  margin-bottom: 1em !important;

  svg {
    display: inline;
    margin-top: -0.2em;
    color: #0c3476;
  }

  &:hover {
    color: #07152b;

    svg {
      color: #07152b;
    }
  }
`;

export const Heading = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  font-size: 1.71428571rem;
  color: rgba(0, 0, 0, 0.85);
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
  line-height: 1.28571429em;
  margin-bottom: 1rem;
`;
