import React, { forwardRef } from 'react';

import type { StyledTagProps } from './Tag.styles';
import { StyledTag, StyledTagIconWrapper } from './Tag.styles';

export interface TagProps extends StyledTagProps {
  children?: React.ReactNode;
  className?: string;
  icon?: React.ReactNode;
}

const Tag = forwardRef<HTMLDivElement, TagProps>(
  ({ backgroundColor, bolded, children, className, color, icon, variant }, ref) => {
    return (
      <StyledTag
        ref={ref}
        backgroundColor={backgroundColor}
        bolded={bolded}
        className={className}
        color={color}
        variant={variant}
      >
        {icon && <StyledTagIconWrapper>{icon}</StyledTagIconWrapper>} {children}
      </StyledTag>
    );
  },
);

export default Tag;
