import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import { PaginatedResponse } from '@models/PaginatedResponse';

import * as cacheKeys from './cacheKeys';
import {
  mapConnectionAlerts,
  MappedConnectioAlerts,
  RawConnectionAlert,
} from './ConnectionAlertModel';

export const useFetchConnectionAlerts = (
  options?: UseFetchOptions<PaginatedResponse<RawConnectionAlert>, unknown, MappedConnectioAlerts>,
) => {
  return useFetch<PaginatedResponse<RawConnectionAlert>, unknown, MappedConnectioAlerts>({
    ...options,
    queryKey: cacheKeys.all,
    select: mapConnectionAlerts,
    url: '/connection_alerts/',
  });
};
