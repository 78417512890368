import React from 'react';

import { useFetchActivity } from '@api/activity';
import MetadataGrid from '@components/MetadataGrid';
import Skeleton from '@components/Skeleton';
import stripSpaces from '@utils/stripSpaces';

import MainPageSection from '../MainPageSection';
import MetadataGridHomepageCard from '../MetadataGridHomepageCard';

const query = stripSpaces(`{
  guid,
  target {
    object_type,
    obj {
      -tagged_items,
      -data_source_type,
      -search_name,
      -downstream_objects_counts,
      -upstream_objects_counts
    }
  }
}`);

export const RECENTLY_VIEWED_SECTION_LABEL = 'Recently Viewed';

const RecentlyViewed = () => {
  const { data, isLoading } = useFetchActivity({
    keepPreviousData: true,
    params: {
      distinct: true,
      order: '-performed_on',
      page_size: 4,
      query,
      types: 'view',
      users: 'me',
    },
    staleTime: 0,
  });

  if (!isLoading && !data?.results?.length) {
    return null;
  }

  const finalData = data?.results?.filter(
    (item) => item?.target?.obj?.name?.toLowerCase?.() !== 'deleted item',
  );

  return (
    <MainPageSection
      label={
        isLoading ? <Skeleton compWidth="100px" variant="text" /> : RECENTLY_VIEWED_SECTION_LABEL
      }
    >
      <MetadataGrid data={finalData} isLoading={isLoading}>
        {(item) => {
          return <MetadataGridHomepageCard isLoading={isLoading} obj={item.target.obj} />;
        }}
      </MetadataGrid>
    </MainPageSection>
  );
};

export default RecentlyViewed;
