import React from 'react';

import Box from '@components/Box';

interface UserListLabelProps {
  listName: string;
  usersCount: number;
}

const UserListLabel = ({ listName, usersCount }: UserListLabelProps) => {
  return (
    <Box color="inherit" fontWeight="inherit" lineHeight="20px">
      {listName}&nbsp;&nbsp;
      <Box as="span" color="gray.400" fontWeight="semibold" lineHeight="inherit">
        {usersCount}
      </Box>
    </Box>
  );
};

export default UserListLabel;
