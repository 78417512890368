import styled from '@emotion/styled';
import * as CSS from 'csstype';

import type { StyledBoxProps } from '@components/Box';
import getColor from '@styles/mixins/getColor';
import { FontSize, FontWeight } from '@styles/theme/typography';

export interface StyledLinkProps extends StyledBoxProps {
  boldOnHover?: boolean;
  compDisplay?: CSS.Property.Display;
  compWidth?: string;
  disabled?: boolean;
  fontSize?: FontSize | CSS.Property.FontSize;
  href?: string;
  pointerEvents?: CSS.Property.PointerEvents;
  to?: string;
  underline?: boolean;
  underlineOnHover?: boolean;
}

const StyledLink = styled.a<StyledLinkProps>`
  color: ${({ color, theme }) => getColor(color) ?? theme.colors.hyperlink};
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  font-size: ${({ fontSize = 'default', theme }) =>
    theme.typography.fontSizes[fontSize as FontSize] ?? fontSize};
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
  width: ${({ compWidth }) => compWidth};
  pointer-events: ${({ disabled, pointerEvents }) => (disabled ? 'none' : pointerEvents)};
  font-weight: ${({ fontWeight = 'regular', theme }) =>
    theme.typography.fontWeights[fontWeight as FontWeight] ?? fontWeight};

  &:hover,
  &:active {
    text-decoration: ${({ underline, underlineOnHover }) =>
      underline || underlineOnHover ? 'underline' : 'none'};
    color: ${({ color, theme }) => getColor(color) ?? theme.colors.hyperlink};

    font-weight: ${({ boldOnHover, fontWeight = 'regular', theme }) =>
      boldOnHover
        ? theme.typography.fontWeights.bold
        : theme.typography.fontWeights[fontWeight as FontWeight] ?? fontWeight};
  }

  &:focus {
    outline-width: thin;
    outline-style: auto;
    color: ${({ color = '#4c288f' }) => getColor(color)};
  }
`;

export default StyledLink;
