import React, { useMemo } from 'react';
import getDebug from 'debug';

import { useFetchBiFields } from '@api/fields';
import { LookMLFieldModel } from '@api/fields/LookMLFieldModel';
import BulkButtons from '@components/BulkButtons';
import TabError from '@components/TabContent/TabError';
import LookMLFieldsTable from '@components/Table/LookMLFieldsTable';
import { TabContentProps } from '@components/Tabs/types';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import useBulkEditSelected from '@hooks/useBulkEditSelected';
import { Filter } from '@utils';
import stripSpaces from '@utils/stripSpaces';

const debug = getDebug('selectstar:tab-loading');

const query = stripSpaces(`{
  guid,
  name,
  label,
  description,
  richtext_description,
  suggested_description,
  project_name,
  field_type,
  type,
  external_type,
  data_type,
  view_label,
  popularity,
  is_used_as_filter,
  is_used_as_pivot,
  is_metric,
  is_mention,
  tagged_items,
  sql,
  suggested_description_source,
  suggested_description_source_object,
  view {
    guid
  },
  description_source,
  ai_description,
  ingested_description,
  data_types,
  user_description
}`);

const searchConfig: { [key: string]: keyof Filter.FilterOptions } = {
  description: 'search_description',
  name: 'search_name',
  sql: 'search_sql',
};

const sortConfig: { [key: string]: string } = {
  description: 'description',
  name: 'name',
  popularity: 'popularity',
};

const defaultConfig = {
  order: '-popularity',
  page_size: 100,
  query,
};

interface LookMLFieldsTabProps extends TabContentProps {
  guid: string;
  isDataSourceEditable: boolean;
}

const LookMLFieldsTab: React.FC<LookMLFieldsTabProps> = ({ guid, isDataSourceEditable }) => {
  const { isEditable: isObjectEditable } = useObjectPermissionsContext({ id: guid });
  const FilterService = Filter.useUpdateFilters(defaultConfig, searchConfig, sortConfig);
  const { filter } = FilterService;

  const effectiveConfig = useMemo(() => {
    return {
      force_showing_suggested_description: true,
      params: Filter.setParams({ ...filter, views: [guid] }),
    };
  }, [filter, guid]);

  const { data, isError } = useFetchBiFields(effectiveConfig);

  const { selected } = useBulkEditSelected<LookMLFieldModel>({
    defaultSelected: data?.results,
  });

  const totalPages = data && filter.page_size ? Math.ceil(data.count / filter.page_size) : 1;

  if (isError) return <TabError />;

  debug('display Fields');

  return (
    <>
      <BulkButtons
        canUseBulkAssist={isObjectEditable}
        guid={guid}
        isDataSourceEditable
        selectedEditableItems={selected.items}
        selectedItems={selected.items}
      />
      <LookMLFieldsTable
        data={data?.results}
        filterService={FilterService}
        isDataSourceEditable={isDataSourceEditable}
        itemCount={data?.count}
        totalPages={totalPages}
      />
    </>
  );
};

export default React.memo(LookMLFieldsTab);
