import React, { useMemo } from 'react';

import { MetadataObjContext, MetadataObjContextProps } from './MetadataObject';

const MetadataObjectProvider: React.FC<MetadataObjContextProps> = ({
  children,
  dataSourceGuid,
  dataSourceType,
  dataTypes,
  objectType,
}) => {
  const state: MetadataObjContextProps = useMemo(
    () => ({
      dataSourceGuid,
      dataSourceType,
      dataTypes,
      objectType,
    }),
    [dataSourceGuid, dataSourceType, dataTypes, objectType],
  );

  return <MetadataObjContext.Provider value={state}>{children}</MetadataObjContext.Provider>;
};

export default MetadataObjectProvider;
