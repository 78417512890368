import { useEffect, useState } from 'react';
import { useParams } from '@routing/router';

const useHighlight = () => {
  const [isHighlightedRowActive, setIsHighlightedRowActive] = useState(true);
  const { itemId: highlightedRowId } = useParams<{ itemId: string }>();

  useEffect(() => {
    setIsHighlightedRowActive(true);
  }, [highlightedRowId]);

  return { highlightedRowId, isHighlightedRowActive, setIsHighlightedRowActive };
};

export default useHighlight;
