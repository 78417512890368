import styled from '@emotion/styled';

import rawTheme, { Theme } from '@styles/theme';

export interface StyledTagProps {
  backgroundColor?: string;
  bolded?: boolean;
  color?: string;
  compWidth?: string;
  minHeight?: string;
  variant?: 'outlined' | 'label';
}

const getTagVariant = ({ backgroundColor, variant }: StyledTagProps, theme: Theme) => {
  if (variant === 'outlined') {
    return {
      backgroundColor: backgroundColor ?? '#ffffff',
      border: 'solid 1px #e2e8f0',
      borderRadius: theme.radius.default,
      fontSize: '13px',
      margin: theme.space(0.15),
    };
  }

  if (variant === 'label') {
    return {
      color: theme.colors.text,
      margin: 0,
      padding: 0,
    };
  }

  return undefined;
};

export const StyledTag = styled.div<StyledTagProps>`
  align-items: flex-start;
  color: ${({ color = rawTheme.colors.text }) => color};
  display: inline-flex;
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  font-weight: ${({ bolded, theme }) => (bolded ? theme.typography.fontWeights.bold : undefined)};
  line-height: ${({ theme }) => theme.typography.lineHeights.sm};
  max-height: ${({ minHeight }) => minHeight};
  overflow: hidden;
  padding: ${({ theme }) => theme.space(0.5)};
  width: ${({ compWidth }) => compWidth};

  ${({ backgroundColor, theme, variant }) => getTagVariant({ backgroundColor, variant }, theme)};
`;

export const StyledTagIconWrapper = styled.span`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.space(0.25)};
`;
