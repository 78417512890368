import React, { useCallback, useState } from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Sector, Tooltip } from 'recharts';

import { useFetchCostAnalysisTeams } from '@api/costAnalysis';
import CircularLoader from '@components/CircularLoader';
import theme from '@styles/theme';

import LegendLabel from '../LegendLabel';
import TooltipBoxValue from '../Tooltip/TooltipBoxValue';
import TooltipContent from '../Tooltip/TooltipContent';
import type { TooltipContentPayload } from '../Tooltip/types';

import { StyledTeamCostChart } from './TeamCostChart.styles';
import { formatValue } from './utils';

interface CustomTooltipPayload extends Array<{ name: string; value: string }> {}

const CustomTooltipItem = ({ value }: TooltipContentPayload<CustomTooltipPayload>) => {
  return (
    <>
      {value.map((payload: { name: string; value: string }) => (
        <TooltipBoxValue key={payload.name} name={payload.name} value={payload.value} />
      ))}
    </>
  );
};

const CustomTooltipContent = (props: TooltipContentPayload<CustomTooltipPayload>) => (
  <TooltipContent {...props} Child={CustomTooltipItem} />
);

const renderActiveShape = ({
  cx,
  cy,
  endAngle,
  fill,
  innerRadius,
  outerRadius,
  startAngle,
}: any) => (
  <g>
    <Sector
      cx={cx}
      cy={cy}
      endAngle={endAngle}
      fill={fill}
      innerRadius={innerRadius}
      outerRadius={outerRadius + 5}
      startAngle={startAngle}
    />
  </g>
);

export interface TeamCostChartProps {
  range: [string, string];
}

const TeamCostChart: React.FC<TeamCostChartProps> = ({ range }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const onMouseOver = useCallback((_, index) => {
    setActiveIndex(index);
  }, []);

  const onMouseLeave = useCallback(() => {
    setActiveIndex(null);
  }, []);

  const { data, isLoading } = useFetchCostAnalysisTeams({
    keepPreviousData: true,
    params: {
      end_date: range[1],
      start_date: range[0],
    },
  });

  const sum = data?.reduce((acc, team) => team?.totalCreditsUsed ?? 0 + acc, 0);
  const isEmpty = sum === 0;
  const teamColors = ['#567AF2', '#33B1FF', '#88E1D2', '#D98266', '#FFEA7A', '#B1D0FF'];

  return (
    <StyledTeamCostChart compHeight="400px" compWidth="100%" position="relative">
      {isLoading && <CircularLoader centered cover />}
      {!isLoading && (
        <ResponsiveContainer height="100%" width="100%">
          <PieChart>
            {!isEmpty && (
              <Tooltip
                content={CustomTooltipContent as any}
                formatter={formatValue}
                isAnimationActive={false}
              />
            )}
            <Pie
              activeIndex={activeIndex!}
              activeShape={renderActiveShape}
              cx="50%"
              cy="50%"
              data={isEmpty ? [{ name: 'No Credits used', totalCreditsUsed: 1 }] : data}
              dataKey="totalCreditsUsed"
              endAngle={-270}
              fill={theme.colors.v1.gray[300]}
              innerRadius={60}
              minAngle={5}
              onMouseLeave={onMouseLeave}
              onMouseOver={onMouseOver}
              outerRadius={140}
              startAngle={90}
            >
              {!isEmpty &&
                data?.map((entry, i) => (
                  <Cell key={entry.guid} fill={teamColors[i]} name={entry.name} />
                ))}
            </Pie>
            <Legend
              align="right"
              formatter={LegendLabel}
              iconSize={8}
              iconType="circle"
              layout="vertical"
              verticalAlign="top"
              wrapperStyle={{ right: '15%', top: '15%' }}
            />
          </PieChart>
        </ResponsiveContainer>
      )}
    </StyledTeamCostChart>
  );
};

export default TeamCostChart;
