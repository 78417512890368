import type { DataTypesConfig } from '@configs/dataTypes/types';
import { plainToClass } from 'class-transformer';

import DataTypesModel from '@models/DataTypesModel';

import type { ContainerConfigItem } from './types';

const DATA_TYPE_OPTIONS = [
  {
    key: 'all',
    text: 'All',
    value: null,
  },
  {
    key: 'answer',
    text: 'Answer',
    value: 'dataSourceType=thoughtspot&objectType=bifolder&dataType=answer',
  },
  {
    key: 'liveboard',
    text: 'Liveboard',
    value: 'dataSourceType=thoughtspot&objectType=bifolder&dataType=liveboard',
  },
];

const CONTAINER_CONFIG_DEFAULT: ContainerConfigItem = {
  countItems: [
    { key: 'reports_count', text: 'Answer' },
    { key: 'dashboard_count', text: 'Liveboard' },
  ],
  dataTypes: plainToClass(DataTypesModel, {
    data_source_type: 'thoughtspot',
    object_type: 'thoughtspot_folders_container',
  }),
  filter: {
    dataTypeInitialSelected: null,
    dataTypeOptions: DATA_TYPE_OPTIONS,
    isCategoryTags: true,
    isCreatedBy: true,
    isPopularity: false,
    isSelectStarDetected: true,
    isStatusTags: true,
    showHidden: true,
  },
  order: '-popularity',
  path: '/thoughtspot/:dsGuid/folder_container',
  title: 'Answers and Liveboards',
};

const CONTAINER_CONFIG: DataTypesConfig<ContainerConfigItem> = {
  thoughtspot: {
    bifolder: {
      answer: {
        ...CONTAINER_CONFIG_DEFAULT,
        countItems: [CONTAINER_CONFIG_DEFAULT.countItems[0]],
        filter: {
          ...CONTAINER_CONFIG_DEFAULT.filter,
          dataTypeInitialSelected: DATA_TYPE_OPTIONS[1].value,
        },
        title: 'Answers',
      },
      default: CONTAINER_CONFIG_DEFAULT,
      liveboard: {
        ...CONTAINER_CONFIG_DEFAULT,
        countItems: [CONTAINER_CONFIG_DEFAULT.countItems[1]],
        filter: {
          ...CONTAINER_CONFIG_DEFAULT.filter,
          dataTypeInitialSelected: DATA_TYPE_OPTIONS[2].value,
        },
        title: 'Liveboards',
      },
    },
    default: CONTAINER_CONFIG_DEFAULT,
  },
};

export default CONTAINER_CONFIG;
