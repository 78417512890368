import styled from '@emotion/styled';

export const StyledScatterChartItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledScatterChartItemText = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.v1.gray[600]};
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  font-size: ${({ theme }) => theme.typography.fontSizes.sm};
  gap: ${({ theme }) => theme.space(0.5)};
`;

interface StyledScatterChartItemCircleProps {
  backgroundColor: string;
}

export const StyledScatterChartItemCircle = styled.div<StyledScatterChartItemCircleProps>`
  border-radius: 2rem;
  stroke: white;
  cursor: pointer;
  background-color: ${({ backgroundColor }) => backgroundColor};
  width: 11px;
  height: 11px;

  &:hover + ${StyledScatterChartItemText} {
    color: ${({ theme }) => theme.colors.v1.primary[500]};
  }
`;
