import React from 'react';

import { usePatchDescriptionAiBulkApply } from '@api/description';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import CircularLoader from '@components/CircularLoader';
import { renderErrorToast, renderInfoToast } from '@components/Toast';
import DropdownMenu from '@components/UI/DropdownMenu';
import Icon from '@components/UI/Icon';
import { useModal } from '@context/Modal';

import AiBulkAssistDeleteModal, {
  AI_BULK_ASSIST_DELETE_MODAL_TITLE,
  invalidate,
} from './AiBulkAssistDeleteModal';

export const AI_BULK_ASSIST_FILL_EMPTY_OPTION_TITLE = 'Fill empty descriptions with AI';

export const AI_BULK_ASSIST_FILLED_MSG = 'All empty descriptions have been filled with AI';

export const AI_BULK_ASSIST_NOTHING_TO_FILL = 'All descriptions are already filled';

interface AiBulkAssistProps {
  guid: string;
}

const AiBulkAssist: React.FC<AiBulkAssistProps> = ({ guid }) => {
  const { MODAL_IDS, checkModalOpened, closeModal, openModal } = useModal();
  const { isLoading, mutate: addDescription } = usePatchDescriptionAiBulkApply(guid, {
    onError: () => {
      renderErrorToast('Failed to fill empty descriptions with AI');
    },
    onSuccess: (d) => {
      if (d?.updated_objects_count > 0) {
        renderInfoToast(AI_BULK_ASSIST_FILLED_MSG);
        invalidate();
      } else {
        renderInfoToast(AI_BULK_ASSIST_NOTHING_TO_FILL);
      }
    },
  });

  return (
    <>
      <DropdownMenu
        id="AiBulkAssist"
        options={[
          { label: AI_BULK_ASSIST_FILL_EMPTY_OPTION_TITLE, onClick: () => addDescription(null) },
          {
            label: AI_BULK_ASSIST_DELETE_MODAL_TITLE,
            onClick: () => openModal(MODAL_IDS.aiBulkAssistDelete),
          },
        ]}
      >
        <Box>
          <Button
            endIcon={<Icon name="down" size="16px" />}
            startIcon={
              isLoading ? (
                <Box compWidth="16px">
                  <CircularLoader borderWidth={2} color="gray.500" compSize={1.75} />
                </Box>
              ) : (
                <Icon name="wand" size="16px" />
              )
            }
            variant="outlined"
          >
            AI Assist
          </Button>
        </Box>
      </DropdownMenu>
      {checkModalOpened(MODAL_IDS.aiBulkAssistDelete) && (
        <AiBulkAssistDeleteModal
          guid={guid}
          onClose={() => closeModal(MODAL_IDS.aiBulkAssistDelete)}
        />
      )}
    </>
  );
};

export default AiBulkAssist;
