import React from 'react';

import Box, { StyledBoxProps } from '@components/Box';
import Text from '@components/Text';
import theme from '@styles/theme';

interface SettingsSubSectionProps extends StyledBoxProps {
  subTitle?: string;
}

const SettingsSubSection: React.FC<SettingsSubSectionProps> = ({
  children,
  subTitle,
  ...props
}) => {
  return (
    <Box borderBottom={`1px solid ${theme.colors.v1.gray[200]}`} pb={2} {...props}>
      {subTitle && (
        <Text as="h4" color="gray.600" fontSize="body2" lineHeight={theme.space(2.5)} mb={2}>
          {subTitle}
        </Text>
      )}
      {children}
    </Box>
  );
};

export default SettingsSubSection;
