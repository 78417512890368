import React from 'react';

import Box from '@components/Box';
import { StyledCloseButton } from '@components/CloseButton/CloseButton.styles';
import { useUndoRedo } from '@components/LineageExplore/useUndoRedo';
import Icon from '@components/UI/Icon';

const MINIMUM_SAVED_STATES_COUNT = 1;
const INITIAL_POSITION = 0;
const UndoRedoControls = () => {
  const { currStatePosition, savedStatesCount, undoRedoExploreAction } = useUndoRedo();

  return (
    <Box compDisplay="flex" gap={2}>
      <StyledCloseButton
        aria-label="Undo"
        disabled={
          currStatePosition === INITIAL_POSITION || savedStatesCount <= MINIMUM_SAVED_STATES_COUNT
        }
        onClick={() => undoRedoExploreAction('undo')}
      >
        <Icon color="gray.500" name="flip" size="20px" />
      </StyledCloseButton>
      <StyledCloseButton
        aria-label="Redo"
        disabled={currStatePosition >= savedStatesCount - 1}
        onClick={() => undoRedoExploreAction('redo')}
      >
        <Icon color="gray.500" name="flip" size="20px" transform="scale(-1, 1)" />
      </StyledCloseButton>
    </Box>
  );
};

export default UndoRedoControls;
