import React from 'react';

import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';

interface SelectedItemsMessageProps {
  isLoadingPermissions?: boolean;
  nonEditableItemsCount: number;
  selectedItemsCount: number;
  showWarning?: boolean;
}

const SelectedItemsMessage: React.FC<SelectedItemsMessageProps> = ({
  isLoadingPermissions,
  nonEditableItemsCount,
  selectedItemsCount,
  showWarning,
}) => {
  const accessWarnMessage =
    showWarning && nonEditableItemsCount > 0 ? (
      <Tooltip content={`You don't have edit access to ${nonEditableItemsCount} item(s).`}>
        <Icon name="warning" size="16px" />
      </Tooltip>
    ) : null;

  return (
    <Box alignItems="center" compDisplay="flex" flexShrink={0} gap={0.5}>
      {isLoadingPermissions ? (
        <Tooltip content="Permission verification in progress.">
          <CircularLoader borderWidth={2} compSize={1.5} />
        </Tooltip>
      ) : (
        accessWarnMessage
      )}
      <span>{selectedItemsCount} selected</span>
    </Box>
  );
};

export default SelectedItemsMessage;
