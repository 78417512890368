import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import { breadcrumbsToList } from '@components/Breadcrumbs/breadcrumbsToList';

import { BreadcrumbItem } from './Breadcrumbs.types';

// Expose class getters
// eslint-disable-next-line import/prefer-default-export
export const breadcrumbsToLabelList = (
  label: string,
  url: string,
  breadcrumbs?: BreadcrumbModel[],
): BreadcrumbItem[] => {
  const breadcrumbList = breadcrumbsToList(breadcrumbs);
  return [...(breadcrumbList ?? []), { label, url }];
};
