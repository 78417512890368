import styled from '@emotion/styled';

import Box from '@components/Box';

interface StyledEditorControlsProps {
  scrollable?: boolean;
}

export const StyledEditorControls = styled(Box)<StyledEditorControlsProps>`
  border-color: transparent;
  border-style: solid;
  border-bottom-color: ${({ theme }) => theme.colors.v1.gray[200]};
  border-width: 1px;
  padding: ${({ theme }) => theme.space(0, 0, 1, 0)};
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.space(1)};
  background-color: #fff;
  padding: ${({ theme }) => theme.space(0, 0, 1, 0)};
`;

interface EditorControlsButtonProps {
  isActive: boolean;
}

export const EditorControlsButton = styled.button<EditorControlsButtonProps>`
  align-items: center;
  background-color: ${({ isActive }) => (isActive ? '#e8f2ff' : 'transparent')};
  border-radius: ${({ theme }) => theme.radius.default};
  color: ${({ isActive, theme }) => (isActive ? theme.colors.primary : theme.colors.gray[600])};
  display: flex;
  height: ${({ theme }) => theme.space(3)};
  justify-content: center;
  margin: ${({ theme }) => theme.space(0, 1)};
  outline: none !important; // Remove !important with sc-16264
  width: ${({ theme }) => theme.space(3)};
  font-size: 20px;

  ${({ theme }) => theme.media.down('md')`
      margin: ${theme.space(0, 0.75)};
  `}

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  svg {
    display: block;
    fill: currentColor;

    path,
    rect {
      transition: fill 0.1s ease;
      fill: currentColor;
    }
  }
`;
