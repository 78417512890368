import styled from '@emotion/styled';

import Box from '@components/Box';
import type { StyledBoxProps } from '@components/Box/Box.styles';
import Button from '@components/Button/Button';
import rootTheme from '@styles/theme';

interface StyledOptionsListProps {}

export const StyledButton = styled(Button)`
  padding: ${({ theme }) => theme.space(0, 1)};
  font-style: italic;
  font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
`;

export const StyledOptionsList = styled(Box)<StyledOptionsListProps>`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.v1.gray[300]};
  overflow: hidden;
  z-index: ${({ theme }) => theme.zIndex.floatingElement};
  box-shadow: ${({ theme }) => theme.shadow.md};
`;

StyledOptionsList.defaultProps = {
  borderRadius: 'default',
  noDefault: true,
};

export interface StyledOptionsListItemProps extends StyledBoxProps {
  bordered?: boolean;
  isHighlighted?: boolean;
  isSelected?: boolean;
}

export const StyledOptionsListItem = styled(Box)<StyledOptionsListItemProps>`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.v1.gray[700]};

  background-color: ${({ isHighlighted, isSelected, theme }) =>
    isHighlighted || isSelected ? theme.colors.v1.gray[100] : rootTheme.colors.white};

  border-bottom: ${({ bordered, theme }) =>
    bordered ? `1px solid ${theme.colors.v1.gray[300]}` : ''};
`;

StyledOptionsListItem.defaultProps = {
  alignItems: 'center',
  compDisplay: 'flex',
  fontFamily: 'primary',
  fontSize: 'body2',
  gap: 1,
  justifyContent: 'space-between',
  noDefault: true,
  overflow: 'hidden',
  px: 1.25,
  py: 1,
  textOverflow: 'ellipsis',
};

export const NoResultsMessage = styled(StyledOptionsListItem)`
  font-size: ${({ theme }) => theme.typography.fontSizes.sm};
  padding: ${({ theme }) => theme.space(1.75)};
`;

export const SelectAllOption = styled(StyledOptionsListItem)``;

SelectAllOption.defaultProps = {
  ...StyledOptionsListItem.defaultProps,
  borderColor: 'gray.300',
  borderWidth: '0 0 1px 0',
};
