import React from 'react';

import Box from '@components/Box';
import { TextareaProps } from '@components/UI/Form/Textarea';

import { MarkdownRichTextEditorProps } from '../MarkdownRichTextEditor.types';

import { StyledTextarea } from './WriteMarkdown.styles';

interface WriteMarkdownProps extends Pick<TextareaProps, 'onChange' | 'value' | 'onKeyDown'> {
  variant?: MarkdownRichTextEditorProps['variant'];
}

const WriteMarkdown: React.FC<WriteMarkdownProps> = ({ onChange, onKeyDown, value, variant }) => {
  const isInlineEditor = variant === 'inline';
  return (
    <Box data-testid="write-markdown" mt={isInlineEditor ? 0 : 2}>
      <StyledTextarea
        autoHeight
        borderWidth={0}
        data-testid="markdown-textarea"
        onChange={onChange}
        onKeyDown={onKeyDown}
        resize="none"
        value={value}
        variant={variant}
      />
    </Box>
  );
};

export default WriteMarkdown;
