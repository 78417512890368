import { MetricModel } from '@api/metrics/MetricModel';
import paginatedTransform from '@api/paginatedTransform';
import rawTransform from '@api/rawTransform';
import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import type { UseMutationOptions } from '@hooks/useMutation';
import useMutation from '@hooks/useMutation';
import type { OwnersUpdateData } from '@models/Owners';
import { PaginatedResponse } from '@models/PaginatedResponse';
import type { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';

import invalidateCache from '../invalidateCache';

import * as cacheKeys from './cacheKeys';
import { DisplayedInItemModel } from './DisplayedInItemModel';

export const fetchMetricsSelect = paginatedTransform(MetricModel);
export const useFetchMetrics = (options?: UseFetchOptions<PaginatedResponse<MetricModel>>) => {
  return useFetch<PaginatedResponse<MetricModel>>({
    ...options,
    queryKey: [...cacheKeys.all, options?.params],
    select: fetchMetricsSelect,
    url: '/metrics/',
  });
};

export const useFetchMetric = (id: string, options?: UseFetchOptions<MetricModel>) => {
  return useFetch<MetricModel>({
    ...options,
    queryKey: [...cacheKeys.metric(id), options?.params],
    select: rawTransform(MetricModel),
    url: `/metrics/${id}/`,
  });
};

export const useDeleteMetrics = (options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    method: 'DELETE',
    url: '/metrics/',
  });
};

export const usePatchMetric = (id: string, options?: UseMutationOptions<MetricModel>) => {
  return useMutation<MetricModel>({
    ...options,
    method: 'PATCH',
    url: `/metrics/${id}/`,
  });
};

export const usePostMetrics = (options?: UseMutationOptions<MetricModel>) => {
  return useMutation<MetricModel>({
    ...options,
    method: 'POST',
    onSuccess: (data, variables, context) => {
      invalidateCache((keys) => [keys.documents.hierarchy]);
      const transformedData = rawTransform(MetricModel)(data);
      options?.onSuccess?.(transformedData, variables, context);
    },
    url: '/metrics/',
  });
};

export const usePatchMetricsDimensions = (options?: UseMutationOptions<MetricModel>) => {
  return useMutation<MetricModel>({
    ...options,
    method: 'PATCH',
    url: '/metrics/dimensions/',
  });
};

const useFetchMetricsRelatedDashboardsSelect = paginatedTransform(DisplayedInItemModel);
export const useFetchMetricsRelatedDashboards = (
  id: string,
  options?: UseFetchOptions<PaginatedResponse<DisplayedInItemModel>>,
) => {
  return useFetch<PaginatedResponse<DisplayedInItemModel>>({
    ...options,
    queryKey: [...cacheKeys.metricsRelatedDashboards(id), options?.params],
    select: useFetchMetricsRelatedDashboardsSelect,
    url: `/metrics/${id}/related-dashboards/`,
  });
};

export const usePatchMetricsOwners = (
  options?: UseMutationOptions<never, EnhancedErrorResult, OwnersUpdateData>,
) => {
  return useMutation({
    ...options,
    method: 'PATCH',
    url: `/metrics/owners/`,
  });
};
