import React from 'react';

import { FiltersSidebar } from '@components/FiltersSidebar';
import type { TableFiltersProps } from '@pages/TablesPage/Filters';

const ViewFilters: React.FC<TableFiltersProps> = ({ filterService }) => {
  return (
    <FiltersSidebar
      filterService={filterService}
      isCategoryTags
      isPopularity
      isSelectStarDetected
      isStatusTags
      showHidden
    />
  );
};

export default React.memo(ViewFilters);
