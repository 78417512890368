import type { FindConfigsProps } from '@configs/dataTypes/findConfigs';
import findConfigs from '@configs/dataTypes/findConfigs';
import type { FEDataSourceType } from '@configs/dataTypes/types';
import * as Sentry from '@sentry/browser';

import type { IconProps } from '@components/UI/Icon';
import InvalidDataException from '@utils/sentry/errors/invalidDataException';

import config from './config';

const getIcons = (dataTypes: FindConfigsProps<IconProps['name']>['dataTypes']) => {
  const result = findConfigs<IconProps['name']>({
    dataTypes,
    defaultValue: 'question',
    ref: config,
  });
  const feDataSourceType = (dataTypes.dataSourceType ?? dataTypes.objectType) as FEDataSourceType;

  if ((feDataSourceType && !result.dataSource) || (dataTypes.objectType && !result.objectType)) {
    Sentry.captureException(new InvalidDataException('Error getting icon'), {
      extra: {
        dataSourceType: dataTypes.dataSourceType,
        dataType: dataTypes.dataType,
        feDataSourceType,
        objectType: dataTypes.objectType,
      },
    });
  }

  return result;
};

export default getIcons;
