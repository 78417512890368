import { css } from '@emotion/react';
import styled from '@emotion/styled';

import rem from '@styles/mixins/rem';
import getLayoutComponent from '@utils/getLayoutComponent';

import { getTreeNodeHoverColor } from './Tree.v2/TreeNode.v2.styles';

/**
 * @deprecated. Use Tree.v2 instead.
 */
const StyledTreeV0 = styled.div`
  .tree-treenode {
    align-items: center;
    display: flex;
    outline: none;
    min-height: fit-content;
    /* padding: 4px 0; */
    position: relative;
    font-size: ${rem(14)};
    padding-left: ${({ theme }) => theme.space(2)};
    padding-right: 0;

    &.dragging {
      opacity: 0.75;
      user-select: none;
    }

    &.drag-over {
      background: #e2e9f0;
    }

    &.tree-treenode-selected,
    &:hover {
      font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
      background-color: #e2e9f0;
      color: ${({ theme }) => theme.colors.primary};
    }

    .tree-node-content-wrapper {
      width: 100%;
      align-items: stretch;
      color: inherit;
      display: flex;
      line-height: ${({ theme }) => theme.typography.lineHeights.default};
    }

    .tree-switcher {
      cursor: pointer;
      display: flex;
      justify-content: center;
      flex-shrink: 0;
      width: 16px;
      &_open {
        svg {
          transform: rotate(90deg);
        }
      }
    }

    .tree-iconEle {
      flex-shrink: 0;

      a {
        height: 100%;
        display: flex;
        align-items: center;
        color: inherit;
      }
    }

    .tree-title {
      width: 100%;
      margin: 0;

      a {
        color: inherit;
        display: block;
        padding-left: ${({ theme }) => theme.space(0.5)};
        padding-right: ${({ theme }) => theme.space(2)};
        padding-top: ${({ theme }) => theme.space(0.6)};
        padding-bottom: ${({ theme }) => theme.space(0.6)};

        &:hover {
          color: inherit;
        }
      }
    }

    .tree-indent {
      display: inline-block;
      user-select: none;
      white-space: nowrap;

      .tree-indent-unit {
        display: inline-block;
        width: 0;
      }
    }
  }
`;

/**
 * @deprecated. Use Tree.v2 instead.
 */
const StyledTreeV1 = styled.div`
  transition: all 0.1s ease, color 0.1s ease;

  .tree-treenode {
    position: relative;
    color: ${({ theme }) => theme.colors.v1.gray[700]};
    align-items: center;
    display: flex;
    outline: none;
    min-height: fit-content;
    position: relative;
    font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
    font-size: ${({ theme }) => theme.typography.fontSizes.body2};
    border-radius: ${({ theme }) => theme.radius.md};
    padding-right: 0 !important;

    &.dragging {
      opacity: 0.75;
      user-select: none;
    }

    &.drag-over {
      background: #e2e9f0;
    }

    &.tree-treenode-selected {
      .tree-node-content-wrapper,
      .tree-switcher {
        color: ${({ theme }) => theme.colors.v1.primary[500]};
        background-color: ${({ theme }) => theme.colors.v1.primary[100]};
      }
    }

    &:hover {
      .tree-node-content-wrapper,
      .tree-switcher {
        ${getTreeNodeHoverColor()}
      }
    }

    .tree-node-content-wrapper {
      margin-left: 18px;
      width: 100%;
      align-items: stretch;
      color: inherit;
      display: flex;
      border-top-right-radius: inherit;
      border-bottom-right-radius: inherit;
      line-height: ${({ theme }) => theme.typography.lineHeights.default};
    }

    .tree-switcher {
      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit;
      cursor: pointer;
      display: flex;
      justify-content: center;
      flex-shrink: 0;
      width: 18px;
      height: 100%;
      align-items: center;
      position: absolute;
      svg {
        margin: 0;
      }
      &_open {
        svg {
          transform: rotate(90deg);
        }
      }
    }

    .tree-iconEle {
      flex-shrink: 0;

      a {
        height: 100%;
        display: flex;
        align-items: center;
        color: inherit;
      }
    }

    .tree-title {
      width: 100%;
      margin: 0;

      a {
        color: inherit;
        display: block;
        padding-left: ${({ theme }) => theme.space(0.5)};
        padding-top: ${({ theme }) => theme.space(0.6)};
        padding-bottom: ${({ theme }) => theme.space(0.6)};

        &:hover {
          color: inherit;
        }
      }
    }

    .tree-indent {
      display: inline-block;
      user-select: none;
      white-space: nowrap;

      .tree-indent-unit {
        display: inline-block;
        width: 0;
      }
    }
  }
`;

/**
 * @deprecated. Use Tree.v2 instead.
 */
export const StyledTree = getLayoutComponent(StyledTreeV0, StyledTreeV1);

export const TreeDropIndicator = styled.div`
  position: absolute;
  height: 4px;
  background: #7490be;
  pointer-events: none;
  left: 0;
  right: 0;
`;

interface StyledTreeNodeIndentationLineProps {
  isFirstChild?: boolean;
  level: number;
}

export const StyledTreeNodeIndentationLine = styled.div<StyledTreeNodeIndentationLineProps>`
  position: absolute;
  pointer-events: none;
  left: ${({ level, theme }) => theme.space(2.15 + level * 2.5)};
  width: 1px;
  background-color: ${({ theme }) => theme.colors.v1.gray[300]};
  ${({ isFirstChild }) => {
    if (isFirstChild) {
      return css`
        bottom: 0;
        height: 90%;
      `;
    }
    return css`
      height: 100%;
      top: 0;
    `;
  }}
`;
