import { Expose } from 'class-transformer';

export enum ReceiveNotificationTypeEnum {
  all = 'all',
  owners = 'owners',
}

export class SlackSettingsModel {
  static typeDisplay: string = 'Slack Settings';

  typeDisplay: string = SlackSettingsModel.typeDisplay;

  @Expose({ name: 'allow_link_unfurling' })
  allowLinkUnfurling?: boolean;

  @Expose({ name: 'slack_channels_for_notifications' })
  slackChannelsForNotifications?: string[];

  @Expose({ name: 'receive_notification_type' })
  receiveNotificationType?: ReceiveNotificationTypeEnum = ReceiveNotificationTypeEnum.owners;
}
