import React from 'react';
import { SemanticToastContainer } from 'react-semantic-toasts';

import { StyledToastContainer } from './ToastContainer.styles';

interface ToastContainerProps {
  fixed?: boolean;
}

const ToastContainer: React.FC<ToastContainerProps> = ({ fixed = false }) => {
  return (
    <StyledToastContainer className="toast-container" fixed={fixed}>
      <SemanticToastContainer />
    </StyledToastContainer>
  );
};

export default ToastContainer;
