import styled from '@emotion/styled';
import * as CSS from 'csstype';

import rem from '@styles/mixins/rem';

export interface PopperContainerProps {
  /** Sets container width styling. */
  containerWidth?: CSS.Property.Width;
  /** Sets maximum width on the tooltip container. */
  maxWidth?: CSS.Property.MaxWidth;
}

export const PopperContainer = styled.div<PopperContainerProps>`
  display: inline-block;
  max-width: ${({ maxWidth }) => (maxWidth ? rem(maxWidth) : 'none')};
  width: ${({ containerWidth }) => containerWidth};
  z-index: ${({ theme }) => theme.zIndex.floatingElement};
`;
