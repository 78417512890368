import React from 'react';

import IconButton, { IconButtonProps } from '@components/IconButton';
import Tooltip from '@components/Tooltip.v1';
import Icon from '@components/UI/Icon';

interface OpenInNewTabButtonProps extends IconButtonProps {
  'data-testid'?: string;
  url: string;
}

const OpenInNewTabButton: React.FC<OpenInNewTabButtonProps> = ({
  'data-testid': testId,
  url,
  ...rest
}) => {
  return (
    <IconButton
      data-testid={testId ?? 'open-in-new-tab'}
      href={url}
      onClick={(e) => {
        e.stopPropagation();
      }}
      rel="noopener noreferrer"
      target="_blank"
      {...rest}
    >
      <Tooltip action content="Open in new tab">
        <Icon color="gray.400" name="open-new-tab" size="16px" />
      </Tooltip>
    </IconButton>
  );
};

export default OpenInNewTabButton;
