import { CoordinatesMap, TreeContext } from '../../types';
import findRelatedColumns from '../findRelatedColumns';
import getColumnsCoordinates from '../getColumnsCoordinates';
import { generateColumnsLinks } from '../index';

interface DrawColumnLinksArgs {
  context: TreeContext;
  displayColLinks: string[];
  linkClass: 'hoveredColumnLink' | 'pinnedColumnLink';
  root: d3.Selection<d3.BaseType, unknown, null, undefined>;
  tableCoordinatesMap: CoordinatesMap;
}

export const drawColumnLinks = ({
  context,
  displayColLinks,
  linkClass,
  root,
  tableCoordinatesMap,
}: DrawColumnLinksArgs) => {
  const {
    columnsById,
    curveGenerator,
    lineGenerator,
    rectHeight,
    rectWidth,
    sortedColumnIdsByTableId,
    tablesById,
    visibleColumns,
    xScale,
    yScale,
  } = context;

  root.selectAll(`.${linkClass}`).remove();
  root.selectAll(`.${linkClass}.arrowCircle`).remove();

  displayColLinks.forEach((columnId) => {
    const columnIds = findRelatedColumns({ columnId, columns: visibleColumns })?.map(
      (column) => column.key,
    );
    const { circles, links } = generateColumnsLinks(
      rectWidth,
      rectHeight,
      getColumnsCoordinates({
        columns: columnIds,
        columnsById,
        rectHeight,
        root,
        sortedColumnIdsByTableId,
        tableCoordinatesMap,
        tablesById,
      }),
      columnsById,
    );

    links.forEach(({ edgeType, path, type }) => {
      const generator = type === 'line' ? lineGenerator : curveGenerator;
      const line = generator(path);

      if (!line) return;

      root
        .append('path')
        .attr('class', `${linkClass} ${edgeType}`)
        .datum({ root: columnId })
        .attr('d', line)
        .attr('marker-start', 'url(#circle)')
        .attr('marker-end', `url(#${edgeType === 'manual' ? edgeType : ''}${linkClass}Arrow)`);
    });

    // generate arrowheads' circles
    root
      .selectAll(`.${linkClass}.arrowCircle`)
      .data(circles)
      .join('circle')
      .attr('class', `${linkClass} arrowCircle`)
      .attr('r', 3)
      .attr('cx', (d) => xScale(d.x))
      .attr('cy', (d) => yScale(d.y));
  });
};

export default drawColumnLinks;
