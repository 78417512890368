import React from 'react';
import { HighlighterProps as HighlighterLibraryProps } from 'react-highlight-words';

import type { StyledHighlighterProps } from './Highlighter.styles';
import { StyledHighlighter } from './Highlighter.styles';

export type HighlighterProps = HighlighterLibraryProps & StyledHighlighterProps;

const Highlighter: React.FC<HighlighterProps> = (props) => {
  return <StyledHighlighter autoEscape {...props} />;
};

export default Highlighter;
