import { plainToClass } from 'class-transformer';

import { BreadcrumbPayload } from '@api/breadcrumbs/BreadcrumbModel';
import { TaggedItemModel } from '@api/tags/TaggedItemModel';
import { TeamModel } from '@api/teams/TeamModel';
import type { ObjectType } from '@api/types';
import type { BreadcrumbItem } from '@components/Breadcrumbs';
import getUrl from '@components/Tree/getUrl';
import type { ValidDSType } from '@models/DataSourceCredentials';
import { DataSourceTypesType } from '@models/DataSourceCredentials';
import DataTypesModel from '@models/DataTypesModel';
import { PopularityModel } from '@models/PopularityModel';

import { getBreadcrumbList, getLink, getPath, getTypeLabel } from './utils';

export interface RawSearchData {
  // Only defined for users
  avatar?: string;
  breadcrumbs: BreadcrumbPayload[];
  color?: string;
  custom_attribute_values?: CustomAttributeSearchResult[];
  data_source_type?: DataSourceTypesType;
  // Target data type for data source column and bi tool field types
  data_type?: string;
  data_types?: {
    data_source_type: string;
    data_type: string;
    object_type: string;
  };
  database_name?: string;
  description: string;
  email?: string;
  //  Only defined for explore_fields. To be replaced by parent_guid.
  explore_guid?: string;
  field_guid?: string;
  // Only defined for columns, BI folders, and dashboards. `field_type` will phase out field_type and table_type.
  field_type?: ObjectType | string;
  guid: string;
  is_active?: boolean;
  is_invitation_pending?: string;
  // Only for LookMLFields to figure what Icon needs to show for what items
  looker_field_type?: string;
  name: string;
  object_type: ObjectType;
  //  Only defined for dashboard_elements. This should replace table_guid, explore_guid.
  parent_guid?: string;
  // Defined for comments. Needed to link to correct page comment is on.
  parent_object_type?: ObjectType;
  popularity?: number;
  schema_name?: string;
  score: number;
  search_name: string;
  suggested_description?: string;
  // Only defined for columns. To be replaced by parent_guid
  table_guid?: string;
  table_name?: string;
  // Only defined for tables
  table_type?: 'table' | 'view';
  tagged_items?: TaggedItemModel[];
  team?: TeamModel;
  // External URL that is present for some objects (e.g. Looker Dashboard)
  url?: string;
  user_guid?: string;
}

export class CustomAttributeSearchResult {
  name!: string;

  value!: string;
}

export interface SearchModel {
  avatar?: string;
  breadcrumbList?: BreadcrumbItem[];
  color?: string;
  customAttributeValues?: CustomAttributeSearchResult[];
  dataBaseName?: string;
  dataSourceType?: ValidDSType;
  dataType?: string;
  dataTypes?: DataTypesModel;
  description: string;
  email?: string;
  exploreGuid?: string;
  fieldGuid?: string;
  fieldType?: string;
  guid: string;
  icon?: string;
  id: string;
  indexPosition?: number;
  isActive?: boolean;
  isInvitePending?: string;
  lookerFieldType?: string;
  name: string;
  objectType: ObjectType;
  parentGuid?: string;
  parentObjectType?: string;
  path?: string;
  popularity?: PopularityModel;
  routerUrl?: string;
  schemaName?: string;
  score: number;
  searchName: string;
  suggestedDescription?: string;
  tableGuid?: string;
  tableName?: string;
  tableType?: 'table' | 'view';
  taggedItems?: TaggedItemModel[];
  team?: TeamModel;
  title: string;
  typeDisplay: string;
  typeLabel: string;
  url?: string;
  userGuid?: string;
}

export const mapSearchModel = (el: RawSearchData): SearchModel => {
  const result = {
    avatar: el.avatar,
    breadcrumbList: getBreadcrumbList(el),
    customAttributeValues: plainToClass(
      CustomAttributeSearchResult,
      el.custom_attribute_values || [],
    ),
    dataBaseName: el.database_name,
    dataSourceType: el.data_source_type,
    dataType: el.data_type,
    dataTypes: plainToClass(DataTypesModel, el.data_types),
    description: el.description,
    email: el.email,
    exploreGuid: el.explore_guid,
    fieldGuid: el.field_guid,
    fieldType: el.field_type,
    guid: el.guid,
    id: el.guid,
    isActive: el.is_active,
    isInvitePending: el.is_invitation_pending,
    lookerFieldType: el.looker_field_type,
    name: el.object_type === 'comment' ? `"${el.name}"` : el.name,
    objectType: el.object_type,
    parentGuid: el.parent_guid,
    parentObjectType: el.parent_object_type,
    path: getPath(el.object_type, el.search_name, el.name, el.description),
    popularity: plainToClass(PopularityModel, { popularity: el.popularity }),
    schemaName: el.schema_name,
    score: el.score,
    searchName: el.search_name,
    suggestedDescription: el.suggested_description,
    tableGuid: el.table_guid,
    tableName: el.table_name,
    tableType: el.table_type,
    taggedItems: plainToClass(TaggedItemModel, el.tagged_items || []),
    team: plainToClass(TeamModel, el.team),
    title: el.name,
    typeDisplay: 'searchItem',
    typeLabel: getTypeLabel(el),
    url: el.url,
    userGuid: el.user_guid,
  };

  return {
    ...result,
    routerUrl:
      getLink(el) ??
      getUrl({
        dataType: result.dataType,
        dataTypes: result.dataTypes,
        guid: result.guid,
        objectType: result.objectType,
        parentGuid: result.parentGuid,
        showSchemataPage: true,
        type: result.dataSourceType,
      }),
  };
};
