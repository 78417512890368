import React, { useMemo } from 'react';

import { useDeleteWorkspace, usePostWorkspaces } from '@api/workspaces';
import IconButton, { IconButtonProps } from '@components/IconButton';
import Icon from '@components/UI/Icon';
import { useUserContext } from '@context/User';
import { useWorkspacesContext } from '@context/Workspaces';
import theme from '@styles/theme';

import CircularLoader from '../CircularLoader';
import { renderErrorToast, renderInfoToast } from '../Toast';

interface Props {
  iconButtonSize?: IconButtonProps['size'];
  iconSize?: string;
  objGuid: string;
  objName: string;
}

const FavoriteButton: React.FC<Props> = ({
  iconButtonSize,
  iconSize = '16px',
  objGuid,
  objName,
}) => {
  const { user } = useUserContext();
  const { setWorkspaces, workspaces } = useWorkspacesContext();
  const userGuid = user?.guid || '';

  const isFavorite = useMemo(
    () => workspaces && workspaces.some((item: string) => item === objGuid),
    [objGuid, workspaces],
  );

  const { isLoading: addFavoriteIsLoading, mutate: addFavorite } = usePostWorkspaces(userGuid, {
    onError: () => {
      renderErrorToast('Operation failed');
    },
    onSuccess: () => {
      renderInfoToast(`${objName} has been added to Favorites`);
      setWorkspaces?.((prev) => (prev ? [...prev, objGuid] : [objGuid]));
    },
  });

  const { isLoading: removeFavoriteIsLoading, mutate: removeFavorite } = useDeleteWorkspace(
    userGuid,
    objGuid,
    {
      onSuccess: () => {
        renderInfoToast(`${objName} has been removed from Favorites`);
        setWorkspaces?.((prev) => (prev ? prev.filter((guid) => guid !== objGuid) : undefined));
      },
    },
  );

  const isLoading = addFavoriteIsLoading || removeFavoriteIsLoading;

  const onToggleFavorite = (
    e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
  ) => {
    e.preventDefault();
    if (isLoading) {
      return;
    }
    if (isFavorite) {
      removeFavorite(null);
    } else {
      addFavorite({ item: objGuid });
    }
  };

  if (!user?.guid || workspaces === undefined) {
    return null;
  }

  const favoriteIcon = isFavorite ? (
    <Icon color="#f7ca2e" name="favorite" size={iconSize} />
  ) : (
    <Icon color={theme.colors.v1.gray[400]} name="favorite-outlined" size={iconSize} />
  );

  return (
    <IconButton
      data-testid="favorite-button"
      onClick={onToggleFavorite}
      onKeyPress={onToggleFavorite}
      size={iconButtonSize}
    >
      {isLoading ? (
        <CircularLoader borderWidth={2} color="gray.500" compSize={1.4} />
      ) : (
        favoriteIcon
      )}
    </IconButton>
  );
};

export default FavoriteButton;
