import styled from '@emotion/styled';
import type { RouterLinkProps } from '@routing/router';
import type { ElementType } from 'react';

import type { StyledBoxProps } from '@components/Box';
import Box from '@components/Box';
import { DateRangePickerContainer } from '@components/DateRangePicker/DateRangePicker.styles';

export const Title = styled(Box)`
  color: ${({ theme }) => theme.colors.text};
`;

Title.defaultProps = {
  fontFamily: 'primary',
  fontSize: 'h3',
  fontWeight: 'bold',
  noDefault: true,
  role: 'heading',
};

interface SubtitleProps extends StyledBoxProps {
  as?: ElementType;
  to?: RouterLinkProps['to'];
}

export const Subtitle = styled(Box)<SubtitleProps>`
  color: ${({ theme }) => theme.colors.v1.gray[600]};
  min-width: fit-content;

  &:hover {
    color: ${({ theme }) => theme.colors.v1.gray[600]};
  }
`;

Subtitle.defaultProps = {
  fontFamily: 'primary',
  fontSize: 'body2',
  noDefault: true,
  role: 'heading',
};

export const SectionTitleWrapper = styled(Box)`
  ${({ theme }) => theme.media.down('sm')`
    width: 100%;
    & > *, > ${DateRangePickerContainer} {
      width: 100%;
    }
  `};

  ${({ theme }) => theme.media.up('sm')`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `};
`;

SectionTitleWrapper.defaultProps = {
  alignItems: 'flex-start',
  compDisplay: 'flex',
  flexDirection: 'column',
  gap: 1,
};
