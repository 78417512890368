import React, { Fragment, useEffect, useMemo, useState } from 'react';
import groupBy from 'lodash/groupBy';
import xor from 'lodash/xor';

import { useFetchColumnsDownstreamCharts } from '@api/columns';
import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import FiltersList from '@components/FiltersList';
import type { FiltersListFilterType } from '@components/FiltersList/FiltersList';
import { StyledSourceTablesTabItemWrapper } from '@components/TabContent/SourceTablesTab/SourceTablesTab.styles';
import TabError from '@components/TabContent/TabError';
import Text from '@components/Text';
import { BIType } from '@models/DataSourceCredentials';
import wrapString from '@utils/wrapString';

import DownstreamChartsTabChartRow from './DownstreamChartsTabChartRow';
import DownstreamChartsTabDashboardRow from './DownstreamChartsTabDashboardRow';
import DownstreamChartsTabRow from './DownstreamChartsTabRow';

export const NO_DASHBOARDS_MESSAGE = 'No downstream charts detected.';

interface DownstreamChartsTabFilter extends FiltersListFilterType {
  dataSourceType: BIType | 'all';
  type: 'dashboard' | 'chart' | 'all';
}

const defaultFilter: DownstreamChartsTabFilter = {
  dataSourceType: 'all',
  label: 'All',
  name: 'all',
  type: 'all',
};

type AllowedDataSourceTypesForFilters = PartialRecord<Exclude<BIType, 'dbt'>, string>;

const dashboardsFilterLabelList: AllowedDataSourceTypesForFilters = {
  data_studio: 'Dashboards',
  looker: 'Dashboards',
  metabase: 'Dashboards',
  mode: 'Reports',
  periscope: 'Dashboards',
  power_bi: 'Dashboards / Reports',
  sigma: 'Workbooks',
  tableau: 'Workbooks',
};

const chartsFilterLabelList: AllowedDataSourceTypesForFilters = {
  data_studio: 'Pages',
  looker: 'Tiles',
  metabase: 'Cards',
  mode: 'Queries',
  periscope: 'Charts',
  power_bi: 'Tiles',
  sigma: 'Elements',
  tableau: 'Views',
};

interface DownstreamChartsTabProps {
  guid: string;
}

const DownstreamChartsTab: React.FC<DownstreamChartsTabProps> = ({ guid }) => {
  const [selectedFilter, setSelectedFilter] = useState<DownstreamChartsTabFilter>(defaultFilter);
  const [openedItemsIds, setOpenedItemsIds] = useState<string[]>([]);
  const { data, isError, isLoading } = useFetchColumnsDownstreamCharts(guid, {
    enabled: Boolean(guid),
  });

  const downstreamChartsFilters = useMemo(() => {
    if (!data?.downstream || data?.downstream?.length === 0) {
      return [];
    }

    const groupedDashboards = groupBy(data?.downstream, (el) => el?.dashboard?.obj?.dataSourceType);
    const dataSourceTypeKeys = Object.keys(groupedDashboards) as Exclude<BIType, 'dbt'>[];
    const dashboardFilters = dataSourceTypeKeys?.reduce((acc, dataSourceType) => {
      const chartsDashboardsItem = groupedDashboards?.[dataSourceType];

      if (!chartsDashboardsItem || chartsDashboardsItem?.length === 0) {
        return acc;
      }

      const newFilters: DownstreamChartsTabFilter[] = [
        ...acc,
        {
          count: groupedDashboards?.[dataSourceType]?.length,
          dataSourceType,
          dataTypes: chartsDashboardsItem[0].dashboard?.obj?.dataTypes,
          label: dashboardsFilterLabelList[dataSourceType] ?? 'Dashboards',
          name: `${dataSourceType}-dashboard`,
          type: 'dashboard',
        },
      ];

      const chartsCount = chartsDashboardsItem?.reduce(
        (sum, item) => (item?.charts ? sum + item?.charts?.length ?? 0 : sum),
        0,
      );

      if (chartsCount > 0) {
        newFilters.push({
          count: chartsCount,
          dataSourceType,
          dataTypes: chartsDashboardsItem[0].charts[0]?.obj?.dataTypes,
          label: chartsFilterLabelList[dataSourceType] || 'Charts',
          name: `${dataSourceType}-chart`,
          type: 'chart',
        });
      }

      return newFilters;
    }, [] as DownstreamChartsTabFilter[]);

    return [defaultFilter, ...dashboardFilters];
  }, [data?.downstream]);

  const toggleOpenedItemId = (id?: string) => {
    if (id) {
      setOpenedItemsIds((prev) => xor(prev, [id]));
    }
  };

  const allKeys = useMemo(() => {
    const dashboardKeys: string[] = [];

    data?.downstream?.forEach((item) => {
      const itemGuid = item?.dashboard?.obj?.guid;
      if (itemGuid) dashboardKeys.push(itemGuid);
    });

    return dashboardKeys;
  }, [data?.downstream]);

  const handleFilterChange = (value: DownstreamChartsTabFilter) => {
    if (value.type === 'all') {
      setOpenedItemsIds(allKeys);
    } else {
      setOpenedItemsIds([]);
    }
    setSelectedFilter(value);
  };

  /** Shows all by default. */
  useEffect(() => {
    setOpenedItemsIds(allKeys);
  }, [allKeys, data?.downstream, setOpenedItemsIds]);

  if (isLoading) {
    return <CircularLoader centered compSize={5} data-testid="data-loading" />;
  }

  if (isError) return <TabError />;

  if (data?.downstream?.length === 0 || (!isLoading && !data)) {
    return <Text color="inherit">{NO_DASHBOARDS_MESSAGE}</Text>;
  }

  return (
    <>
      <FiltersList
        data={downstreamChartsFilters}
        onClick={handleFilterChange}
        selectedFilter={selectedFilter}
      />
      <Box borderBottom="1px solid" borderColor="#d8dbe0" mb={0.5} pb={0.5} pt={1}>
        <DownstreamChartsTabRow
          firstColumn={
            <Text color="inherit" fontSize="13px" fontWeight="bold" pl={5.5}>
              Name
            </Text>
          }
          secondColumn={
            <Text color="inherit" fontSize="13px" fontWeight="bold">
              Popularity
            </Text>
          }
        />
      </Box>
      {data?.downstream
        ?.filter((item) => {
          return (
            selectedFilter.type === 'all' ||
            item?.dashboard?.obj?.dataSourceType === selectedFilter?.dataSourceType
          );
        })
        .map((item) => {
          const dashboard = item?.dashboard?.obj;
          const id = dashboard?.guid;
          const isChartsSelected = selectedFilter.type === 'chart';
          const isOpen = isChartsSelected || openedItemsIds.includes(id as string);
          const dataSourceType = dashboard?.dataSourceType;

          if (!dashboard) {
            return null;
          }

          return (
            <Fragment key={id}>
              <DownstreamChartsTabDashboardRow
                breadcrumbs={[
                  ...(dashboard?.breadcrumbList ?? []),
                  {
                    label: `${dashboard?.name} ${wrapString(item?.charts?.length)}`,
                    url: '',
                  },
                ]}
                copyPath={dashboard?.fullName?.replace(/\./g, ' / ') ?? ''}
                dataSourceType={dataSourceType}
                dataTypes={dashboard?.dataTypes}
                externalUrl={dashboard?.externalUrl ?? ''}
                isOpen={isOpen}
                isToggleDisabled={isChartsSelected}
                onToggle={() => toggleOpenedItemId(id)}
                popularity={dashboard?.popularity}
                routePath={dashboard?.routePath}
              />
              {isOpen && (
                <StyledSourceTablesTabItemWrapper>
                  {item.charts?.map(
                    (chart) =>
                      Boolean(chart?.obj) && (
                        <DownstreamChartsTabChartRow
                          key={chart?.obj?.guid}
                          copyPath={chart?.obj?.fullName?.replace(/\./g, ' / ') ?? ''}
                          dataSourceType={dataSourceType}
                          dataTypes={chart?.obj?.dataTypes}
                          externalUrl={dashboard?.externalUrl ?? ''}
                          name={chart?.obj?.name ?? ''}
                          popularity={chart?.obj?.popularity}
                          routePath={chart?.obj?.routePath!}
                        />
                      ),
                  )}
                </StyledSourceTablesTabItemWrapper>
              )}
            </Fragment>
          );
        })}
    </>
  );
};

export default DownstreamChartsTab;
