import React from 'react';

import { ExploreNode } from '@components/LineageExplore/LineageExplore.types';
import { SPACE } from '@styles/theme/grid';

import IntegrationNode from '../../Common/IntegrationNode';
import { SIZES } from '../config';

const DatabaseNode: React.FC<ExploreNode> = ({ children, data, style }) => {
  const { dataTypes, isOpen = false, isUnfocused, key, name, onExpandNode, tablesCount = 0 } = data;

  return (
    <IntegrationNode
      contentGap={SIZES.rowGap.schema / SPACE}
      dataTypes={dataTypes}
      isOpen={isOpen}
      isUnfocused={isUnfocused}
      name={name ?? ''}
      nodeKey={key}
      onExpandNode={onExpandNode}
      style={style}
      tablesCount={tablesCount}
    >
      {children}
    </IntegrationNode>
  );
};

export default DatabaseNode;
