import React from 'react';

import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import Text from '@components/Text';
import Toggle from '@components/Toggle';
import Icon from '@components/UI/Icon';
import theme from '@styles/theme';

import { StyledLabel } from './ToggleMarkdown.styles';

interface ToggleMarkdownProps {
  checked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  showLoading?: boolean;
  variant?: 'small' | 'default';
}

const ToggleMarkdown: React.FC<ToggleMarkdownProps> = ({
  checked,
  onChange,
  showLoading,
  variant = 'default',
}) => {
  return (
    <StyledLabel>
      {showLoading ? (
        <Box compDisplay="flex" compWidth="44px" justifyContent="center">
          <CircularLoader borderWidth={2} compSize={3} />
        </Box>
      ) : (
        <Toggle checked={checked} name="toggle-markdown" onChange={onChange} />
      )}
      {variant === 'default' ? (
        <Text color="gray.700" fontSize={theme.typography.fontSizes.body2} pl={1}>
          Markdown (.md)
        </Text>
      ) : (
        <Box pl={0.5}>
          <Icon color={theme.colors.v1.gray[500]} name="markdown" size="28px" />
        </Box>
      )}
    </StyledLabel>
  );
};

export default ToggleMarkdown;
