import styled from '@emotion/styled';

export interface StyledScaleIndicatorProps {
  compWidth?: string;
}

export const StyledScaleIndicator = styled.span<StyledScaleIndicatorProps>`
  display: inline-flex;
  align-items: center;
`;

export interface StyledScaleIndicatorItemProps {
  color?: string;
}

export const StyledScaleIndicatorItem = styled.span<StyledScaleIndicatorItemProps>`
  width: 0.75rem;
  height: 0.9375rem;
  margin-right: 1px !important;
  display: inline-block;
  background: ${(props) => props.color};
`;
