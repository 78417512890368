import { usePatchDashboardsOwners } from '@api/dashboards';
import { usePatchDatabasesOwners } from '@api/databases';
import { usePatchDocumentsOwners } from '@api/documents';
import { usePatchExploresOwners } from '@api/explores';
import { usePatchLookMLViewsOwners } from '@api/lookML';
import { usePatchMetricsOwners } from '@api/metrics';
import { usePatchScemasOwners } from '@api/schema';
import { usePatchTablesOwners } from '@api/tables';
import { usePatchTagsOwners } from '@api/tags';
import { TeamModel } from '@api/teams/TeamModel';
import { usePatchTermsOwners } from '@api/terms';
import { UserModel } from '@api/user/UserModel';
import type { OwnerType } from '@components/Owners/OwnerItem';
import type { OwnerSelectItem } from '@components/OwnerSelect/types';
import { mapOwnerToOption } from '@components/OwnerSelect/utils';
import { DeletedModel } from '@models/DeletedModel';
import { OrganizationUserModel } from '@models/OrganizationUserModel';
import { Reference } from '@models/Reference';

import { CurrentOwner, UsePatchUpdateOwnersProps } from './BulkEditOwnersModal.types';

interface FormatDefaultOptionsProps {
  currentOwner?: CurrentOwner;
  loggedUser?: UserModel;
  organizationUsers?: OrganizationUserModel[];
}

const DEFAULT_OPTIONS_LIST_SIZE = 5;

export const formatDefaultOptions = ({
  currentOwner,
  loggedUser,
  organizationUsers,
}: FormatDefaultOptionsProps) => {
  const teamUsers = (organizationUsers ?? []).map((orgUser) => orgUser.user);
  const mappedDefaultUsers = [loggedUser, loggedUser?.team, ...teamUsers];

  const filteredUsers = mappedDefaultUsers.filter((mappedUser) =>
    Boolean(mappedUser),
  ) as OwnerType[];

  const validUsers = filteredUsers.filter(
    (mappedUser) => mappedUser.guid !== (currentOwner as OwnerType)?.guid,
  );

  const mappedOptions = validUsers.map((mappedUser) => mapOwnerToOption(mappedUser));

  const currentOwnerOption =
    currentOwner && !(currentOwner instanceof DeletedModel)
      ? mapOwnerToOption(currentOwner)
      : undefined;

  const options = [...(currentOwnerOption ? [currentOwnerOption] : []), ...mappedOptions].slice(
    0,
    DEFAULT_OPTIONS_LIST_SIZE,
  );

  return {
    currentOwnerOption,
    options,
  };
};

export const getCommonOwner = (
  selection: OwnerSelectItem[],
  ownerType: 'businessOwner' | 'technicalOwner',
) => {
  const owners = selection
    .map((item) => item[ownerType])
    .filter((owner): owner is Reference<UserModel | TeamModel> => Boolean(owner));

  const sameOwner =
    selection.length > 0 &&
    owners.length === selection.length &&
    owners.every((o) => o.obj?.guid === owners[0].obj?.guid);

  return sameOwner ? owners[0].obj : undefined;
};

export const usePatchUpdateOwners = ({ itemsType, ...options }: UsePatchUpdateOwnersProps) => {
  const dashboardQuery = usePatchDashboardsOwners(options);
  const databaseQuery = usePatchDatabasesOwners(options);
  const documentQuery = usePatchDocumentsOwners(options);
  const exploreQuery = usePatchExploresOwners(options);
  const lookMLQuery = usePatchLookMLViewsOwners(options);
  const metricQuery = usePatchMetricsOwners(options);
  const schemaQuery = usePatchScemasOwners(options);
  const tableQuery = usePatchTablesOwners(options);
  const tagQuery = usePatchTagsOwners(options);
  const termQuery = usePatchTermsOwners(options);

  const queryMap: Record<UsePatchUpdateOwnersProps['itemsType'], typeof termQuery> = {
    dashboards: dashboardQuery,
    database: databaseQuery,
    documents: documentQuery,
    explores: exploreQuery,
    lookmlviews: lookMLQuery,
    metrics: metricQuery,
    schema: schemaQuery,
    tables: tableQuery,
    tags: tagQuery,
    terms: termQuery,
  };

  return queryMap[itemsType] ?? {};
};
