import React from 'react';

import CircularLoader from '@components/CircularLoader';

import type { StyledFormProps } from './Form.styles';
import { StyledForm } from './Form.styles';

export interface FormProps extends StyledFormProps {
  isLoading?: boolean;
}

const Form: React.FC<FormProps> = ({ children, isLoading, onSubmit, ...others }) => {
  return (
    <StyledForm onSubmit={onSubmit} {...others}>
      {children}
      {isLoading && <CircularLoader compDisplay="block" compSize={6} cover />}
    </StyledForm>
  );
};

export default Form;
