import React from 'react';

import { useFetchDocuments } from '@api/documents';
import TabError from '@components/TabContent/TabError';
import DocumentsTable from '@components/Table/DocumentsTable';
import {
  defaultFilterOptions,
  searchConfig,
  sortConfig,
} from '@pages/DocumentsPage/docsRequestConfigs';
import { ProfileType } from '@pages/ProfilePage/ProfilePage.types';
import { Filter } from '@utils';

import { OwnedItemsSubTabProps } from '../types';

const OwnedDocumentsSubTab = ({ guid, profileType }: OwnedItemsSubTabProps) => {
  const filterService = Filter.useUpdateFilters(
    { ...defaultFilterOptions, doc_type: 'document', order: 'name' },
    searchConfig,
    sortConfig,
    '',
  );
  const { filter } = filterService;

  const { data: documents, isError } = useFetchDocuments({
    params: {
      ...Filter.setParams(filter),
      owners: profileType === ProfileType.user ? guid : undefined,
      teams: profileType === ProfileType.team ? guid : undefined,
    },
  });

  if (isError) return <TabError />;

  return (
    <DocumentsTable
      data={documents?.results}
      disableRowSelect
      filterService={filterService}
      isDataSourceEditable
      itemCount={documents?.results?.length}
    />
  );
};

export default OwnedDocumentsSubTab;
