import styled from '@emotion/styled';

import Box from '@components/Box';
import Text from '@components/Text';
import Image from '@components/UI/Image';

export const StyledHeaderWrapper = styled(Box)`
  align-items: start;

  ${({ theme }) => theme.media.down('sm')`
    align-items: center;
  `};
`;

export const StyledImage = styled(Image)`
  width: 228px;

  ${({ theme }) => theme.media.down('sm')`
    width: 169px;

  `};
`;

export const StyledFreeTrialText = styled(Text)`
  font-size: ${({ theme }) => theme.typography.fontSizes.h1};

  ${({ theme }) => theme.media.down('sm')`
    font-size: 24px;
    text-align: center;
  `};
`;

export const StyledNoCreditCardMessage = styled(Text)`
  font-size: ${({ theme }) => theme.typography.fontSizes.default};

  ${({ theme }) => theme.media.down('sm')`
    font-size: ${theme.typography.fontSizes.body2};
    text-align: center;
  `};
`;
