import { Type } from 'class-transformer';

import { ColumnModel } from '@api/columns/ColumnModel';
import { LookerExploreFieldModel } from '@api/explores/LookerExploreFieldModel';
import { LookerExploreModel } from '@api/explores/LookerExploreModel';
import { TableauDataSourceModel } from '@api/tableau/TableauDataSourceModel';
import { TableauFieldModel } from '@api/tableau/TableauFieldModel';
import { TableModel } from '@api/tables/TableModel';
import { Reference } from '@models/Reference';

export class RepresentatedAsFieldModel {
  static objectType: string = 'representatedasfield';

  objectType: string = RepresentatedAsFieldModel.objectType;

  static typeDisplay: string = 'Representated As Field';

  typeDisplay: string = RepresentatedAsFieldModel.typeDisplay;

  @Type(() => Reference)
  item!: Reference<LookerExploreFieldModel | TableauFieldModel | ColumnModel>;

  @Type(() => Reference)
  parent!: Reference<LookerExploreModel | TableauDataSourceModel | TableModel>;
}
