import styled from '@emotion/styled';

import Box from '@components/Box';

const NotApplicable = styled(Box)``;

NotApplicable.defaultProps = {
  children: 'N/A',
  color: 'gray.400',
  fontSize: 'body1',
  fontStyle: 'italic',
  fontWeight: 'normal',
  noDefault: true,
};

export default NotApplicable;
