import React from 'react';
import { Helmet } from 'react-helmet';

/*
 *This Component can be used to set metadata in the head of the application from anywhere it is used.
 *Currently it can set page titles but could easliy be expanded to cover all metadata contained in
 *the <head> element.
 */

interface Props {
  title?: string;
}

const MetadataDecorator: React.FC<Props> = ({ title }) => {
  const titleText = title ? `${title} | Select Star` : 'Select Star';
  return (
    <Helmet>
      <title>{titleText}</title>
    </Helmet>
  );
};

export default MetadataDecorator;
