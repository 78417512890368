import React from 'react';

import { useFetchActivity } from '@api/activity';
import TabError from '@components/TabContent/TabError';
import AutomatedPiiHistoryTable from '@components/Table/AutomatedPiiHistoryTable';
import { Filter } from '@utils';

const defaultFiltersConfig: Filter.FilterOptions = {
  order: '-performed_on',
  page: 1,
  page_size: 50,
  sortColumn: 'date',
  sortDirection: 'descending',
  target_models: 'piirule',
};

export const sortConfig: { [key: string]: string } = {
  date: 'performed_on',
};

const AutomatedPiiHistoryTab: React.FC = () => {
  const filterService = Filter.useUpdateFilters(
    defaultFiltersConfig,
    {},
    sortConfig,
    '-performed_on',
  );

  const { data, isError, isLoading } = useFetchActivity({
    params: Filter.setParams(filterService.filter),
  });

  if (isError) {
    return <TabError />;
  }

  return (
    <AutomatedPiiHistoryTable
      filterService={filterService}
      loading={isLoading}
      rows={data?.results}
    />
  );
};

export default AutomatedPiiHistoryTab;
