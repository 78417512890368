import React from 'react';
import { RouteComponentProps } from '@routing/router';

import { IconProps } from '@components/UI/Icon';
import type { Option } from '@components/UI/Select/types';
import { UserContextProps } from '@context/User/User';
import { MakeSpaceProps } from '@styles/mixins/makeSpace';

export interface SidebarSection {
  grow?: boolean;
  id: string;
  items: SidebarItemConfig[];
  maxItems?: number;
  order: number;
  position?: 'top' | 'bottom';
}

export interface SidebarItemIcons {
  active: IconProps['name'] | React.ReactElement;
  default: IconProps['name'] | React.ReactElement;
}

export interface SidebarItemOnClickParams {
  dataSources: UserContextProps['dataSources'];
  id: string;
  push: RouteComponentProps['history']['push'];
}

export enum SidebarItemId {
  chatbot = 'chatbot',
  collections = 'collections',
  docs = 'docs',
  favorites = 'favorites',
  helpDocs = 'helpDocs',
  notifications = 'notifications',
  profileMenu = 'profileMenu',
  settings = 'settings',
  showMore = 'showMore',
  tags = 'tags',
  teams = 'teams',
  user = 'user',
}

export interface SidebarItemAnchorProps {
  activeMenuItemId?: string;
  id: string;
  isActive: boolean;
  isCollapsed: boolean;
  label?: string;
  onClick?: (params: SidebarItemOnClickParams) => void;
  useLlmChatBot?: boolean;
}

export interface SidebarItemConfig extends Pick<SidebarItemAnchorProps, 'onClick'> {
  anchor?: (props: SidebarItemAnchorProps) => React.ReactElement | null;
  baseRoute?: string[];
  children?: React.ReactNode;
  id: string;
  label?: string;
  options?: Option[];
  secondarySidebarLabel?: string;
  secondarySidebarTitleHeight?: 'normal' | 'small';
  titleSpacing?: MakeSpaceProps;
}
