import styled from '@emotion/styled';

import { CellWithButtons } from '../../TableStyled/TableStyled';

export const StyledCellWithButtons = styled(CellWithButtons)`
  line-height: normal;

  .inline-button {
    display: none;
  }

  .inline-button:focus-within {
    display: block;
  }

  :hover {
    .inline-button {
      display: block;
    }
  }
`;
