import { Expose, Transform, Type } from 'class-transformer';

import { OrganizationData, OrganizationModel } from '@api/organizations/OrganizationModel';
import { UserData, UserModel } from '@api/user/UserModel';
import isEmptyRichText from '@components/RichTextEditor/helpers/isEmptyRichText';
import DataTypesModel from '@models/DataTypesModel';
import type { MapObjectToAvatarReturnType } from '@models/mapObjectToAvatar';
import formatNumber from '@utils/formatNumber';
import teamColorGenerator from '@utils/teamColorGenerator';

import { TeamSettingsModel } from './TeamSettingsModel';

export interface TeamData {
  avatar?: string;
  color: string;
  description: string;
  fullName: string;
  guid: string;
  mappedAvatar?: MapObjectToAvatarReturnType;
  name: string;
  organization?: OrganizationData;
  richTextDescription: string;
  sampleUsers?: UserData[];
  users?: UserData[];
}

export class TeamModel {
  static objectType: string = 'team';

  objectType: string = TeamModel.objectType;

  static typeDisplay: string = 'Team';

  typeDisplay: string = TeamModel.typeDisplay;

  guid!: string;

  name!: string;

  get fullName() {
    return this.name;
  }

  avatar = undefined;

  @Type(() => UserModel)
  users?: UserModel[];

  @Expose({ name: 'sample_users' })
  @Type(() => UserModel)
  sampleUsers?: UserModel[];

  @Type(() => OrganizationModel)
  organization?: OrganizationModel;

  @Expose({ name: 'members_count' })
  membersCount?: number = 0;

  get formattedMembersCount() {
    return formatNumber({ value: this.membersCount });
  }

  get mappedAvatar() {
    return {
      guid: this.guid,
      name: this.name,
    };
  }

  description = '';

  // TODO: Remove transform https://app.shortcut.com/select-star/story/55620
  @Expose({ name: 'richtext_description' })
  @Transform((richtextDescription) =>
    isEmptyRichText(richtextDescription) ? '' : richtextDescription,
  )
  richTextDescription = '';

  @Expose({ name: 'total_run' })
  totalRun: number = 0;

  get formattedTotalRun() {
    return formatNumber({ value: this.totalRun });
  }

  @Expose({ name: 'total_credits_used' })
  totalCreditsUsed?: number = 0;

  get formattedTotalCreditsUsed() {
    return formatNumber({ value: this.totalCreditsUsed });
  }

  get color() {
    return teamColorGenerator(this.guid);
  }

  @Expose({ name: 'data_types' })
  @Type(() => DataTypesModel)
  dataTypes?: DataTypesModel = new DataTypesModel();

  @Expose({ name: 'downstream_tables' })
  downstreamTables?: number = 0;

  @Expose({ name: 'downstream_dashboards' })
  downstreamDashboards?: number = 0;

  @Type(() => TeamSettingsModel)
  settings!: TeamSettingsModel;
}
