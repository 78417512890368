import React, { useCallback, useState } from 'react';
import { Cell } from 'react-table';

import { usePatchDatabaseConfig } from '@api/ingestion';
import DatabaseIngestionConfigModel from '@api/ingestion/DatabaseIngestionConfigModel';
import SchemataIngestionConfigModel from '@api/ingestion/SchemataIngestionConfigModel';
import Alert from '@components/Alert';
import NextStepButton from '@components/DataSourceSetup/components/Buttons/NextStepButton';
import PreviousStepButton from '@components/DataSourceSetup/components/Buttons/PreviousStepButton';
import {
  getPayload,
  SelectedRows,
} from '@components/DataSourceSetup/components/DatabaseIngestionConfigStep/DatabaseIngestionConfigStep';
import { BoxWrapper } from '@components/DataSourceSetup/components/DataSourceAddStep';
import Table from '@components/Table/Table';
import { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import { renderInfoToast } from '@components/Toast';
import Icon from '@components/UI/Icon';
import { ModalFooter } from '@components/UI/Modal';

import ErrorDescriptionMarkdown from '../ErrorDescriptionMarkdown';
import SchemaHeader from '../SchemaHeader';

interface Props {
  databaseConfig: DatabaseIngestionConfigModel;
  dsGuid: string;
  loading: boolean;
  onSave: (config: DatabaseIngestionConfigModel) => void;
  prevStep: () => void;
  showBackButton?: boolean;
}

const SchemaIngestionConfigTable: React.FC<Props> = ({
  databaseConfig,
  dsGuid,
  loading,
  onSave,
  prevStep,
  showBackButton = true,
}) => {
  const { guid, name, schemata } = databaseConfig;

  const [selectedRowIds, setSelectedRowIds] = useState<SelectedRows>(() => {
    const state: SelectedRows = {};
    schemata
      .filter((schema) => schema.shouldIngest)
      .forEach((schema) => (state[schema.guid] = true));
    return state;
  });

  const {
    error,
    isLoading: loadingSave,
    mutate,
  } = usePatchDatabaseConfig(dsGuid, guid, {
    onSuccess: () => {
      renderInfoToast('Ingestion Configuration Saved');
    },
  });

  const handleSetSelectedRowIds = (schemasSelected: SelectedRows) => {
    const payload = getPayload(selectedRowIds, schemasSelected);
    if (payload !== null) {
      mutate(payload);
    }

    setSelectedRowIds(schemasSelected);
  };

  const handleNextButtonClick = () => {
    const state = databaseConfig;
    state.schemata.forEach((schemaConfig) => {
      schemaConfig.shouldIngest = selectedRowIds[schemaConfig.guid] || false;
    });
    onSave(state);
  };

  const columns: ColumnConfig<SchemataIngestionConfigModel>[] = React.useMemo(
    () => [
      {
        Cell: (props: Cell<SchemataIngestionConfigModel>) => {
          const { row } = props;
          const schemaConfig = row.original;
          return (
            <>
              <Icon compDisplay="inline-block" mr={0.5} name="schema" size="16px" />
              {schemaConfig.name}
            </>
          );
        },
        accessor: (d) => d?.name,
        disableHiding: true,
        id: 'name',
        width: '200%',
      },
    ],
    [],
  );

  const getRowId = useCallback((row: Partial<SchemataIngestionConfigModel>) => row.guid!, []);

  return (
    <>
      <SchemaHeader
        icon="database"
        name={name}
        selectedCount={Object.keys(selectedRowIds).length}
      />
      <BoxWrapper pt={0}>
        <TableStyled>
          <Table
            basic="very"
            className="table-full"
            columns={columns}
            compact
            data={schemata || []}
            disableColumnFiltering
            disableHeaders
            disablePagination
            disableResizing
            disableSortBy
            getRowId={getRowId}
            initialState={{
              selectedRowIds,
            }}
            loading={schemata === undefined}
            selectable
            setSelectedRowIds={handleSetSelectedRowIds}
            showFilter
            showSelectionHeader
            sortable
          />
        </TableStyled>
        {error && (
          <Alert title="Couldn't save changes" type="error">
            {error.data || 'Please try again later.'}
          </Alert>
        )}
        <ErrorDescriptionMarkdown error={error} />
      </BoxWrapper>
      <ModalFooter>
        {showBackButton && (
          <PreviousStepButton disabled={loading || loadingSave} onClick={prevStep} />
        )}
        <NextStepButton disabled={loading || loadingSave} onClick={handleNextButtonClick} />
      </ModalFooter>
    </>
  );
};

export default SchemaIngestionConfigTable;
