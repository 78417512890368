import React from 'react';

import Box from '@components/Box';
import Text from '@components/Text';
import Image from '@components/UI/Image';

const NoContentMessage: React.FC = () => {
  return (
    <Box
      alignItems="center"
      compDisplay="flex"
      flexDirection="column"
      justifyContent="start"
      pb={2}
    >
      <Image alt="No content" height="auto" src="/images/add-new-doc.svg" width="120px" />
      <Text color="gray.400" fontSize="h3" fontWeight="medium" mt={2}>
        No content yet
      </Text>
    </Box>
  );
};
export default NoContentMessage;
