import React from 'react';

import Box from '@components/Box';
import Skeleton from '@components/Skeleton';

interface HierarchyLoadingSkeletonProps {
  nesting?: boolean;
}

const HierarchyLoadingSkeleton: React.FC<HierarchyLoadingSkeletonProps> = ({ nesting = true }) => {
  return (
    <Box m={2} vSpacing={1}>
      <Skeleton compWidth="80%" grayShade={200} variant="text" />
      {nesting && (
        <Box pl={2}>
          <Skeleton compWidth="60%" grayShade={200} variant="text" />
        </Box>
      )}
      {nesting && (
        <Box pl={2}>
          <Skeleton compWidth="40%" grayShade={200} variant="text" />
        </Box>
      )}
      <Skeleton compWidth="30%" grayShade={200} variant="text" />
      {nesting && (
        <Box pl={2}>
          <Skeleton compWidth="60%" grayShade={200} variant="text" />
        </Box>
      )}
    </Box>
  );
};

export default HierarchyLoadingSkeleton;
