import { RelatedDashboardModel } from '@api/dashboards/RelatedDashboardModel';
import rawTransform from '@api/rawTransform';
import { TableauDataSourcePaginatedResponse } from '@api/views/TableauDataSourcePaginatedResponse';
import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import { PaginatedResponse } from '@models/PaginatedResponse';

import {
  GetBiTableauDatasourceRelatedDashboardsCsvRetrievePathParams,
  GetBiTableauDatasourceRelatedDashboardsListQueryParams,
} from '../openapi.generated';
import paginatedTransform from '../paginatedTransform';
import { TableauTableModel } from '../views/TableauTableModel';

import * as cacheKeys from './cacheKeys';
import { TableauDataSourceModel } from './TableauDataSourceModel';
import { TableauFieldModel } from './TableauFieldModel';

export const tableauCacheKeys = {
  datasource: cacheKeys.datasource,
  datasources: cacheKeys.all,
  tableauDataSourceRelatedDashboards: cacheKeys.tableauDataSourceRelatedDashboards,
  tableauFields: cacheKeys.tableauFields,
};

const fetchTableauDataSourcesSelect = paginatedTransform(TableauDataSourceModel);
export const useFetchTableauDataSources = (
  options?: UseFetchOptions<TableauDataSourcePaginatedResponse<TableauDataSourceModel>>,
) => {
  return useFetch<TableauDataSourcePaginatedResponse<TableauDataSourceModel>>({
    ...options,
    queryKey: [...cacheKeys.all, options?.params],
    select: fetchTableauDataSourcesSelect,
    url: `/bi/data-sources/`,
  });
};

export const fetchTableauTableSelect = rawTransform(TableauTableModel);
export const useFetchTableauTable = (id: string, options?: UseFetchOptions<TableauTableModel>) => {
  return useFetch<TableauTableModel>({
    ...options,
    queryKey: [...cacheKeys.table(id), options?.params],
    select: fetchTableauTableSelect,
    url: `/bi/tableau/tables/${id}`,
  });
};

const fetchTableauDataSourceSelect = rawTransform(TableauDataSourceModel);
export const useFetchTableauDataSource = (
  id: string,
  options?: UseFetchOptions<TableauDataSourceModel>,
) => {
  return useFetch<TableauDataSourceModel>({
    ...options,
    queryKey: [...cacheKeys.datasource(id), options?.params],
    select: fetchTableauDataSourceSelect,
    /**
     * @todo Architecture. Change endpoint to /bi/tableau/datasources/
     * @see https://selectstarhq.slack.com/archives/C01SL94HM0S/p1652888770729649?thread_ts=1652888607.410819&cid=C01SL94HM0S
     */
    url: `/bi/data-sources/${id}/`,
  });
};

export const fetchTableauFieldsSelect = paginatedTransform(TableauFieldModel);
export const useFetchTableauFields = (
  id: string,
  options?: UseFetchOptions<PaginatedResponse<TableauFieldModel>>,
) => {
  return useFetch<PaginatedResponse<TableauFieldModel>>({
    ...options,
    queryKey: [...cacheKeys.tableauFields(id), options?.params],
    select: fetchTableauFieldsSelect,
    url: `/bi/data-sources/${id}/fields/`,
  });
};

const fetchTableauDataSourceRelatedDashboardsSelect = paginatedTransform(RelatedDashboardModel);
export const useFetchTableauDataSourceRelatedDashboards = (
  id: string,
  options?: UseFetchOptions<
    PaginatedResponse<RelatedDashboardModel>,
    GetBiTableauDatasourceRelatedDashboardsListQueryParams
  >,
) => {
  return useFetch<
    PaginatedResponse<RelatedDashboardModel>,
    GetBiTableauDatasourceRelatedDashboardsListQueryParams
  >({
    ...options,
    queryKey: [...cacheKeys.tableauDataSourceRelatedDashboards(id), options?.params],
    select: fetchTableauDataSourceRelatedDashboardsSelect,
    url: `/bi/tableau/datasource/${id}/related-dashboards/`,
  });
};

export const useFetchTableauDataSourceRelatedDashboardsCsv = (
  id: string,
  options?: UseFetchOptions<any, GetBiTableauDatasourceRelatedDashboardsCsvRetrievePathParams>,
) => {
  return useFetch({
    ...options,
    queryKey: [...cacheKeys.tableauDataSourceRelatedDashboardsCsv(id), options?.params],
    url: `/bi/tableau/datasource/${id}/related-dashboards/csv/`,
  });
};
