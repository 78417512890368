import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from '@routing/router';
import ReCAPTCHA from 'react-google-recaptcha';

import { availableApiUrls } from '@api';
import { usePostTokenLogin } from '@api/login';
import { useFetchSSOAuthURL } from '@api/sso';
import Alert from '@components/Alert';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import CenterAlignedBox from '@components/CenterAlignedBox';
import DecoratedText from '@components/DecoratedText';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import GoogleButton from '@components/GoogleButton';
import Input from '@components/Input/Input.v1';
import { StyledLink } from '@components/Label/Label';
import SwitchApi from '@components/SwitchApi';
import TermsOfService from '@components/TermsOfService';
import Text, { defaultParagraphStyles } from '@components/Text';
import Title from '@components/Title/Title';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import flags from '@features';
import useUrlSearchParams from '@hooks/useUrlSearchParams';
import fetchClient from '@lib/fetchClient';
import theme from '@styles/theme';

const Login: React.FC = ({ children }) => {
  const [captchaValue, setCaptchaValue] = useState<string | null>('');
  const passwordFieldRef = useRef<HTMLInputElement>(null);
  const [showPasswordField, setShowPasswordField] = useState<boolean>(false);
  const [inputError, setInputError] = useState<string>();
  const segment = useSegmentContext();
  const query = useUrlSearchParams();
  const redirectTo = query.get('redirect') || '/';
  const history = useHistory();
  const recaptchaRef = useRef<any>();

  const {
    error,
    isLoading,
    mutate: login,
  } = usePostTokenLogin({
    onError: () => {
      recaptchaRef?.current?.reset();
    },
    onSuccess: () => {
      fetchClient.clear();
      history.push(redirectTo);
    },
    retry: false,
  });

  const { handleChange, handleSubmit, values } = useForm({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (val) => {
      const { email, password } = val;

      if (showPasswordField) {
        if (email && password && (window.env.GOOGLE_CAPTCHA_SITE_KEY ? captchaValue : true)) {
          login({
            email: {
              type: 'v2',
              value: btoa(email),
            },
            password: {
              type: 'v2',
              value: btoa(password),
            },
            ...(window.env.GOOGLE_CAPTCHA_SITE_KEY
              ? {
                  captcha: captchaValue,
                }
              : {}),
          });
          segment?.track(SegmentTrackEventName.HomepageSignInButtonClicked, { loginType: 'email' });
          setInputError('');
        }
      } else if (email && !password) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        getSSOAuthUrl();
        segment?.track(SegmentTrackEventName.HomepageSignInButtonClicked, { loginType: 'sso' });
        setInputError('');
      } else {
        setInputError('Email is required');
      }
    },
  });

  const { isLoading: ssoLoading, refetch: getSSOAuthUrl } = useFetchSSOAuthURL({
    cacheTime: 0,
    enabled: false,
    onError: (e) => {
      if (e.status === 404) {
        setShowPasswordField(true);
      } else {
        setInputError(
          e.data?.detail || 'Something went wrong with SSO. Please contact Select Star support',
        );
      }
    },
    onSuccess: (d) => {
      window.location.href = d;
    },
    params: {
      email: values.email,
      state: redirectTo,
    },
    staleTime: 0,
  });

  useEffect(() => {
    if (showPasswordField) {
      passwordFieldRef?.current?.focus?.();
    }
  }, [showPasswordField]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <CenterAlignedBox maxWidth="460px" minWidth="360px">
      <Title showLogo />
      {!flags.hide_google_login && (
        <>
          <Box alignItems="flex-start" compWidth="100%" mb={1.5}>
            <GoogleButton disabled={isLoading || ssoLoading} isLogin redirectTo={redirectTo} />
          </Box>
          <Box my={1}>
            <DecoratedText compWidth="100%">or</DecoratedText>
          </Box>
        </>
      )}
      <Form onSubmit={handleSubmit}>
        <Box my={2}>
          <Input
            error={Boolean(inputError) || Boolean(error)}
            helperText={Boolean(inputError) && inputError}
            name="email"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder="Email"
            type="text"
          />
        </Box>
        {showPasswordField && (
          <>
            <Box my={2}>
              <Input
                key="password"
                ref={passwordFieldRef}
                endIcon={
                  <StyledLink to="/password/reset/">
                    <Text
                      color={theme.colors.primary}
                      fontSize="13px"
                      fontWeight="medium"
                      textAlign="center"
                      whiteSpace="nowrap"
                    >
                      Forgot Password?
                    </Text>
                  </StyledLink>
                }
                error={Boolean(error)}
                name="password"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                placeholder="Password"
                type="password"
              />
            </Box>
            {window.env.GOOGLE_CAPTCHA_SITE_KEY && (
              <ReCAPTCHA
                ref={recaptchaRef}
                onChange={setCaptchaValue}
                sitekey={window.env.GOOGLE_CAPTCHA_SITE_KEY}
              />
            )}
          </>
        )}
        {error && (
          <Box my={2}>
            <Alert type="error">
              {error?.data?.detail || 'Username and password do not match!'}
            </Alert>
          </Box>
        )}
        <Box my={2}>
          <Text {...defaultParagraphStyles} fontSize="12px">
            <TermsOfService isLogin />
          </Text>
        </Box>
        <Button disabled={isLoading || ssoLoading} fluid>
          Sign in
        </Button>
      </Form>
      {availableApiUrls.length > 1 && (
        <>
          <Box my={1}>
            <DecoratedText compWidth="100%">Swtich active API</DecoratedText>
          </Box>
          <Box alignItems="flex-start" compWidth="100%" mb={1.5}>
            <SwitchApi />
          </Box>
        </>
      )}
      {children}
    </CenterAlignedBox>
  );
};

export default Login;
