import type { CacheKeyType } from '@api/allCacheKeys';
import allCacheKeys from '@api/allCacheKeys';
import fetchClient from '@lib/fetchClient';

const setCacheData = (cb: (args: CacheKeyType) => [(string | undefined)[], any][]) => {
  const cacheKeys = cb(allCacheKeys);

  cacheKeys.forEach(([key, data]) => {
    fetchClient.setQueryData(key, data);
  });
};

export default setCacheData;
