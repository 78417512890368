import React from 'react';

import { IconProps } from '@components/UI/Icon';

import TrialInfoItem from '../TrialInfoItem';

import {
  StyledBox,
  StyledItemsWrapper,
  StyledNote,
  StyledTitle,
  StyledWrapper,
} from './TrialInfo.styles';

const items: Array<{
  description: string;
  icon: IconProps['name'];
  title: string;
}> = [
  {
    description:
      'Connect your data source and Select Star will get you started with Google-like search, data lineage, and documentation right away.',
    icon: 'search',
    title: 'Automated Data Catalog & Discovery',
  },
  {
    description:
      'See end-to-end data dependencies and how your columns are being queried, joined and transformed all the way to the dashboard.',
    icon: 'lineage',
    title: 'Column-level Lineage & ER-Diagrams',
  },
  {
    description:
      'Easily understand which are the most important datasets, who’s using what data, and how.',
    icon: 'dashboards',
    title: 'Data Warehouse & BI Usage Analysis',
  },
];

const TrialInfo: React.FC = () => {
  return (
    <StyledWrapper>
      <StyledBox>
        <StyledTitle as="h2" fontWeight="bold">
          Find, Understand and Manage Your Data in One, Easy to Use Platform
        </StyledTitle>
        <StyledItemsWrapper compDisplay="flex" flexDirection="column" gap={4}>
          {items.map((item) => (
            <TrialInfoItem
              key={item.title}
              description={item.description}
              icon={item.icon}
              title={item.title}
            />
          ))}
        </StyledItemsWrapper>
      </StyledBox>
      <StyledNote fontStyle="italic">
        Start your full access trial of Select Star for 14 days — no strings attached.
      </StyledNote>
    </StyledWrapper>
  );
};

export default TrialInfo;
