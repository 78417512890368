export const isValidRatio = (number1?: number, number2?: number) =>
  !(
    number1 == null ||
    number2 == null ||
    number1 < 0 ||
    number2 < 0 ||
    (number1 > 0 && number2 === 0)
  );

export const calculateRatio = (number1?: number, number2?: number, decimalPlaces: number = 1) => {
  if (
    !isValidRatio(number1, number2) ||
    (number1 === 0 && number2! > 0) ||
    (number1 === 0 && number2 === 0)
  ) {
    return '0';
  }

  const precision = Number('1'.padEnd(1 + decimalPlaces, '0'));
  return `${Math.round((number1! / number2!) * 100 * precision) / precision}%`;
};
