import React, { MouseEvent, useMemo, useState } from 'react';

import { DescriptionSource } from '@api/description/description.types';
import Popper from '@components/Popper';
import { useUserContext } from '@context/User';
import useWindowScroll from '@hooks/useWindowScroll';
import { ValidDSType } from '@models/DataSourceCredentials';
import DataTypesModel from '@models/DataTypesModel';
import { MetadataModel } from '@models/MetadataModel';

import DescriptionIcon from './DescriptionIcon';
import DescriptionList, {
  DescriptionListProps,
  DescriptionObj,
} from './DescriptionList/DescriptionList';
import { StyledButton } from './DescriptionSelector.styles';

interface DescriptionSelectorProps {
  dataSourceType?: ValidDSType;
  dataTypes?: DataTypesModel;
  descriptionSource?: DescriptionSource;
  descriptions: DescriptionObj[];
  guid?: string;
  isEditable?: boolean;
  onSelectDescription?: DescriptionListProps['onSelectDescription'];
  parentGuid?: string;
  small?: boolean;
  suggestedDescriptionSourceObject?: MetadataModel;
}

export const ANCHOR_ARIA_LABEL = 'open description options';

const DescriptionSelector: React.FC<DescriptionSelectorProps> = ({
  dataSourceType,
  dataTypes,
  descriptionSource,
  descriptions,
  guid,
  isEditable,
  onSelectDescription,
  parentGuid,
  small = true,
  suggestedDescriptionSourceObject,
}) => {
  const { organization } = useUserContext();
  const useAiDescriptions =
    !['notebook', 'job'].includes(dataTypes?.dataType) && organization?.settings?.useAiDescriptions;
  const [isOpen, setIsOpen] = useState(false);

  const handleSelectorOpen = (e: MouseEvent) => {
    e.stopPropagation();
    setIsOpen(true);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleSelectDescriptionClick = (data: {
    description?: string;
    descriptionSource?: DescriptionSource;
    richTextDescription?: string;
  }) => {
    if (isEditable) {
      if (data.descriptionSource !== descriptionSource) {
        onSelectDescription?.(data);
      }
      closeMenu();
    }
  };

  const finalDescriptions = useMemo(() => {
    const newDescriptions = [...descriptions];

    if (!useAiDescriptions) {
      return newDescriptions.filter((description) => description.source !== 'ai');
    }

    return newDescriptions;
  }, [descriptions, useAiDescriptions]);

  useWindowScroll({ onScroll: closeMenu });

  const userDescription = descriptions.find((description) => description.source === 'user');

  return finalDescriptions.length > 0 || useAiDescriptions ? (
    <Popper
      anchorComp={
        <StyledButton aria-label={ANCHOR_ARIA_LABEL} onClick={handleSelectorOpen} type="button">
          <DescriptionIcon
            dataSourceType={dataTypes?.dataSourceType ?? dataSourceType}
            descriptionSource={descriptionSource}
            guid={guid}
            plainUserDescription={userDescription?.plainValue}
            small={small}
          />
        </StyledButton>
      }
      boundary={document.getElementById('main-content') as Element}
      boundaryPadding={8}
      fallbackPlacements={['top-end', 'top-end', 'bottom-end']}
      isOpen={isOpen}
      offset={[15, 2]}
      placement="bottom-start"
    >
      <DescriptionList
        dataSourceType={dataTypes?.dataSourceType ?? dataSourceType}
        descriptions={finalDescriptions}
        descriptionSource={descriptionSource}
        guid={guid}
        onClickOutside={() => setIsOpen(false)}
        onSelectDescription={handleSelectDescriptionClick}
        parentGuid={parentGuid}
        suggestedDescriptionSourceObject={suggestedDescriptionSourceObject}
        useAiDescriptions={useAiDescriptions}
      />
    </Popper>
  ) : null;
};

export default DescriptionSelector;
