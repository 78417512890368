import React, { useEffect, useState } from 'react';

import { usePostInvitation } from '@api/invitations';
import {
  FullPageVerticallyCenteredLoader,
  IndeterminateLoaderModal,
} from '@components/IndeterminateLoader';
import TextScreen from '@components/TextScreen';
import TwoColumnDecorativeComponent from '@components/TwoColumnDecorativeComponent';
import TwoColumnLayout from '@components/TwoColumnLayout';
import { useUserContext } from '@context/User';
import useUrlSearchParams from '@hooks/useUrlSearchParams';
import MetadataDecorator from '@utils/MetadataDecorator';

const SendInvitationRedirectPage: React.FC = () => {
  const query = useUrlSearchParams();
  const email: string | null = query.get('email');
  const { user } = useUserContext();
  const team = user?.team?.guid;
  const [showSuccessScreen, setShowSuccessScreen] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const { isError, mutate } = usePostInvitation({
    onError: (data) => {
      setError(data?.data?.email);
    },
    onSuccess: () => {
      setShowSuccessScreen(true);
    },
  });

  useEffect(() => {
    if (email && team) {
      /*
       * the email we get from the url can have spaces in it.
       * eg: sakina test3@getselectstar.com
       * we need to manually fix these spaces to get full email
       *
       */
      const validEmail = email.replace(' ', '+');
      mutate({ email: validEmail, team });
    }
  }, []);

  let leftComponent = (
    <FullPageVerticallyCenteredLoader>
      <IndeterminateLoaderModal active indeterminate inline="centered" size="large" />
    </FullPageVerticallyCenteredLoader>
  );

  if (showSuccessScreen) {
    leftComponent = (
      <TextScreen
        text="Invitation has been sent. You will be able to see it in the admin portal."
        title="Invitation sent!"
      />
    );
  }

  if (isError) {
    leftComponent = (
      <TextScreen
        text={
          error ||
          'There was an error sending the invite. Please go to your admin portal to send the request again'
        }
        title="Invitation was not sent"
      />
    );
  }

  return (
    <>
      <MetadataDecorator title="Send Invitation" />
      <TwoColumnLayout left={leftComponent} right={<TwoColumnDecorativeComponent />} />
    </>
  );
};

export default SendInvitationRedirectPage;
