import { useMemo } from 'react';

import { useFetchSubscribersSlack } from '@api/slack/subscribers';
import { useFetchSyncBackRepositories } from '@api/syncBack';
import { useUserContext } from '@context/User';

export enum ConnectableApps {
  github = 'github',
  monteCarlo = 'monte-carlo',
  slack = 'slack',
}

interface UseConnectableAppsResponse {
  connectedApps: ConnectableApps[];
  isLoading: boolean;
  unconnectedApps: ConnectableApps[];
}

export const useConnectableApps = (): UseConnectableAppsResponse => {
  const { data: subscribers, isLoading: isLoadingSlackSubscribers } = useFetchSubscribersSlack();
  const { data: repositories, isLoading: isLoadingRepositories } = useFetchSyncBackRepositories();
  const { dataSources, isDataSourcesLoading } = useUserContext();
  const dataSourcesArray = Object.values(dataSources ?? {});

  const isLoading =
    isLoadingSlackSubscribers || isLoadingRepositories || Boolean(isDataSourcesLoading);

  const { connectedApps, unconnectedApps } = useMemo(() => {
    if (isLoading) return {};
    const connected: ConnectableApps[] = [];
    const unconnected: ConnectableApps[] = [];

    if (subscribers && subscribers?.count > 0) {
      connected.push(ConnectableApps.slack);
    }

    if (repositories && repositories.count > 0) {
      const hasGithubRepo = repositories.results.some((repo) => repo.provider === 'github');

      if (hasGithubRepo) {
        connected.push(ConnectableApps.github);
      }
    }

    if (dataSourcesArray && dataSourcesArray.length > 0) {
      const hasMonteCarloIntegration = dataSourcesArray.some((ds) => ds.type === 'monte_carlo');

      if (hasMonteCarloIntegration) {
        connected.push(ConnectableApps.monteCarlo);
      }
    }

    Object.values(ConnectableApps).forEach((app) => {
      if (!connected.includes(app)) {
        unconnected.push(app);
      }
    });

    return { connectedApps: connected, unconnectedApps: unconnected };
  }, [isLoading, subscribers, repositories, dataSourcesArray]);

  return {
    connectedApps: connectedApps ?? [],
    isLoading,
    unconnectedApps: unconnectedApps ?? [],
  };
};
