import ChatBotConversationModel from '@api/chatbot/ChatBotConversationModel';
import { ChatBotRatingType } from '@api/chatbot/ChatBotMessageModel';
import paginatedTransform from '@api/paginatedTransform';
import rawTransform from '@api/rawTransform';
import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import useMutation, { UseMutationOptions } from '@hooks/useMutation';
import { PaginatedResponse } from '@models/PaginatedResponse';
import { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';

import * as cacheKeys from './cacheKeys';

export const fetchChatBotConversationsSelect = paginatedTransform(ChatBotConversationModel);
export const useFetchChatBotConversations = (
  options?: UseFetchOptions<PaginatedResponse<ChatBotConversationModel>>,
) => {
  return useFetch<PaginatedResponse<ChatBotConversationModel>>({
    ...options,
    queryKey: [...cacheKeys.all, options?.params],
    select: fetchChatBotConversationsSelect,
    url: `/chatbot/conversations/`,
  });
};

export const fetchChatBotConversationByIdSelect = rawTransform(ChatBotConversationModel);
export const useFetchChatBotConversationById = (
  id: string,
  options?: UseFetchOptions<ChatBotConversationModel>,
) => {
  return useFetch<ChatBotConversationModel>({
    ...options,
    queryKey: cacheKeys.conversation(id),
    select: fetchChatBotConversationByIdSelect,
    url: `/chatbot/conversations/${id}/`,
  });
};

interface UsePostChatBotConversationParams {
  user_input?: string;
}

export const usePostChatBotConversation = (
  options?: UseMutationOptions<
    ChatBotConversationModel,
    EnhancedErrorResult,
    UsePostChatBotConversationParams
  >,
) => {
  return useMutation({
    ...options,
    method: 'POST',
    url: `/chatbot/conversations/`,
  });
};

export const useDeleteChatBotConversations = (options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    method: 'DELETE',
    url: `/chatbot/conversations/`,
  });
};

export const useDeleteChatBotConversationById = (id: string, options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    method: 'DELETE',
    url: `/chatbot/conversations/${id}/`,
  });
};

interface UsePatchChatBotConversationByIdMessageByIdParams {
  rating: ChatBotRatingType;
}

export const usePatchChatBotConversationByIdMessageById = (
  conversationId: string,
  id: string,
  options?: UseMutationOptions<
    never,
    EnhancedErrorResult,
    UsePatchChatBotConversationByIdMessageByIdParams
  >,
) => {
  return useMutation({
    ...options,
    method: 'PATCH',
    url: `/chatbot/conversations/${conversationId}/messages/${id}/`,
  });
};

interface UsePostChatBotConversationByIdMessageStreamParams {
  user_input: string;
}

export const usePostChatBotConversationByIdMessagesStream = (
  conversationId: string,
  options?: UseMutationOptions<
    never,
    EnhancedErrorResult,
    UsePostChatBotConversationByIdMessageStreamParams
  >,
) => {
  return useMutation({
    ...options,
    method: 'POST',
    url: `/chatbot/conversations/${conversationId}/messages/stream/`,
  });
};
