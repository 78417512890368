import moment from 'moment';

/**
 * Define 'shortFromNow' locale for support of relative time short format
 * used in Activity notifications.
 *
 * Note: current moment.defineLocale() implementation changes current locale
 * to the newly defined one, we need to undo this, so other parts of the app
 * still use proper locale.
 */
const currentLocale = moment.locale();
export const shortFromNowLocaleName = 'shortFromNow';
moment.defineLocale(shortFromNowLocaleName, {
  parentLocale: moment.locale(),
  relativeTime: {
    M: '1mo',
    d: '1d',
    MM: '%dmo',
    future: 'in %s',
    dd: '%dd',
    h: '1h',
    hh: '%dh',
    m: '1m',
    mm: '%dm',
    past: '%s ago',
    s: '1s',
    ss: '%ds',
    y: '1y',
    yy: '%dy',
  },
});
moment.locale(currentLocale);

export function shortFromNow(dt: moment.Moment) {
  return dt.locale(shortFromNowLocaleName).fromNow(true);
}

export function dateTimeFormat() {
  return 'YYYY-MM-DD HH:mm:ss';
}

export function dateFormat(textMonth?: boolean) {
  if (textMonth) {
    return 'MMM Do, YYYY';
  }
  return 'YYYY-MM-DD';
}

export function timeFormat(withMillis?: boolean) {
  if (withMillis) {
    return 'HH:mm:ss.SSS';
  }
  return 'HH:mm:ss';
}

export function displayDuration(duration?: moment.Duration) {
  if (!duration) {
    return undefined;
  }

  return `${duration.asSeconds().toFixed(2)}s`;
}
