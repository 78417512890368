import styled from '@emotion/styled';

import Box from '@components/Box';

export const StyledRelevantLineageToggleText = styled(Box)``;
StyledRelevantLineageToggleText.defaultProps = {
  alignItems: 'center',
  color: 'gray.800',
  compDisplay: 'flex',
  fontSize: 'body1',
  fontWeight: 'medium',
  gap: 0.5,
};
