import find from 'lodash/find';

import ColumnLineageModel from '@api/lineage/ColumnLineageModel';

import { Direction, NodeExtraMeta } from '../../types';

export interface FindRelatedColumnsArgs {
  allowExtraMetaUpdate?: boolean;
  columnId: string;
  columns: ColumnLineageModel[];
  direction?: Direction;
}

/**
 * Recursively finds all parent/child columns for the specified one.
 */
const findRelatedColumns = ({
  allowExtraMetaUpdate = false,
  columnId,
  columns,
  direction = 'both',
}: FindRelatedColumnsArgs): ColumnLineageModel[] => {
  const foundColumns: ColumnLineageModel[] = [];
  const seenIds = {} as { [id: string]: boolean };

  const recursiveRelated = (
    id: string,
    currDirection: Direction,
    extraMeta: NodeExtraMeta = {},
  ) => {
    if (!seenIds[id]) {
      const column = find(columns, ['key', id]);
      seenIds[id] = true;

      if (column) {
        if (allowExtraMetaUpdate) {
          column.runtimeMeta = { direction: currDirection, ...extraMeta };
        }

        foundColumns.push(column);

        if (currDirection !== 'right') {
          Object.keys(column?.sourceEdges)?.forEach((c) => {
            const source = c.split('/')[1];
            const target = column.key.split('/')[1];
            const sourceJoinQueriesEdge = { source, target };
            const hasSourceJoinQueries =
              column?.sourceEdges?.[c]?.has_join_queries && Boolean(source) && Boolean(target);

            recursiveRelated(c, 'left', {
              hasJoinQueries: hasSourceJoinQueries,
              joinQueriesEdge: sourceJoinQueriesEdge,
              usageType: column?.sourceEdges?.[c]?.usage_type,
            });
          });
        }

        if (currDirection !== 'left') {
          Object.keys(column?.targetEdges)?.forEach((c) => {
            const source = column.key.split('/')[1];
            const target = c.split('/')[1];
            const targetJoinQueriesEdge = { source, target };
            const hasTargetJoinQueries =
              column?.targetEdges?.[c]?.has_join_queries && Boolean(source) && Boolean(target);

            recursiveRelated(c, 'right', {
              hasJoinQueries: hasTargetJoinQueries,
              joinQueriesEdge: targetJoinQueriesEdge,
              usageType: column?.targetEdges?.[c]?.usage_type,
            });
          });
        }
      }
    }
  };

  recursiveRelated(columnId, direction);

  return foundColumns;
};

export default findRelatedColumns;
