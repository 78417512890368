// eslint-disable-next-line max-classes-per-file
import { Expose, Type } from 'class-transformer';

import { DashboardModel } from '@api/dashboards/DashboardModel';
import { ReportQueryModel } from '@api/queries/ReportQueryModel';

class DownstreamChartsItemModel {
  @Expose({ name: 'object_type' })
  objectType!: string;

  @Type(() => ReportQueryModel)
  obj?: ReportQueryModel;
}

class DownstreamDashboardItemModel {
  @Expose({ name: 'object_type' })
  objectType!: string;

  @Type(() => DashboardModel)
  obj?: DashboardModel;
}

class DashboardsChartsModel {
  @Type(() => DownstreamDashboardItemModel)
  dashboard!: DownstreamDashboardItemModel;

  @Type(() => DownstreamChartsItemModel)
  charts!: DownstreamChartsItemModel[];
}

export class DownstreamChartsModel {
  @Expose({ name: 'downstream' })
  @Type(() => DashboardsChartsModel)
  downstream!: DashboardsChartsModel[];
}
