import type { UseFetchOptions } from '@hooks/useFetch';
import useFetch from '@hooks/useFetch';
import { PaginatedResponse } from '@models/PaginatedResponse';

import paginatedTransform from '../paginatedTransform';
import rawTransform from '../rawTransform';

import { ITotalMembersCount } from './analytics.types';
import AnalyticsActivityModel from './AnalyticsActivityModel';
import AnalyticsOrganizationMetricsModel from './AnalyticsOrganizationMetricsModel';
import AnalyticsPageModel from './AnalyticsPageModel';
import AnalyticsSearchTermModel from './AnalyticsSearchTermModel';
import AnalyticsUserModel from './AnalyticsUserModel';
import * as analyticsCacheKeys from './cacheKeys';

export const useFetchAnalyticsUsersTotalCount = (options?: UseFetchOptions<ITotalMembersCount>) => {
  return useFetch<ITotalMembersCount>({
    ...options,
    queryKey: [...analyticsCacheKeys.usersTotalCount, options?.params],
    url: '/data-metrics/users/total_count/',
  });
};

const fetchAnalyticsUsersSelect = paginatedTransform(AnalyticsUserModel);

export const useFetchAnalyticsUsersActive = (
  options?: UseFetchOptions<PaginatedResponse<AnalyticsUserModel>>,
) => {
  return useFetch<PaginatedResponse<AnalyticsUserModel>>({
    ...options,
    queryKey: [...analyticsCacheKeys.usersActive, options?.params],
    select: fetchAnalyticsUsersSelect,
    url: '/data-metrics/users/active/',
  });
};

export const useFetchAnalyticsUsersNew = (
  options?: UseFetchOptions<PaginatedResponse<AnalyticsUserModel>>,
) => {
  return useFetch<PaginatedResponse<AnalyticsUserModel>>({
    ...options,
    queryKey: [...analyticsCacheKeys.usersNew, options?.params],
    select: fetchAnalyticsUsersSelect,
    url: '/data-metrics/users/new/',
  });
};

export const useFetchAnalyticsUsersContributing = (
  options?: UseFetchOptions<PaginatedResponse<AnalyticsUserModel>>,
) => {
  return useFetch<PaginatedResponse<AnalyticsUserModel>>({
    ...options,
    queryKey: [...analyticsCacheKeys.usersContributing, options?.params],
    select: fetchAnalyticsUsersSelect,
    url: '/data-metrics/users/contributing/',
  });
};

const fetchAnalyticsOrganizationMetricsSelect = rawTransform(AnalyticsOrganizationMetricsModel);
export const useFetchAnalyticsOrganizationMetrics = (
  options?: UseFetchOptions<AnalyticsOrganizationMetricsModel>,
) => {
  return useFetch<AnalyticsOrganizationMetricsModel>({
    ...options,
    queryKey: [...analyticsCacheKeys.organizationMetrics, options?.params],
    select: fetchAnalyticsOrganizationMetricsSelect,
    url: '/data-metrics/organization/metrics/',
  });
};

const fetchAnalyticsActivitiesGroupedSelect = paginatedTransform(AnalyticsPageModel);
export const useFetchAnalyticsActivitiesGrouped = (
  options?: UseFetchOptions<PaginatedResponse<AnalyticsPageModel>>,
) => {
  return useFetch<PaginatedResponse<AnalyticsPageModel>>({
    ...options,
    queryKey: [...analyticsCacheKeys.activitiesGrouped, options?.params],
    select: fetchAnalyticsActivitiesGroupedSelect,
    url: '/data-metrics/activities/grouped/',
  });
};

export const fetchAnalyticsActivitiesHomepageSelect = paginatedTransform(AnalyticsPageModel);
export const useFetchAnalyticsActivitiesHomepage = (
  options?: UseFetchOptions<PaginatedResponse<AnalyticsPageModel>>,
) => {
  return useFetch<PaginatedResponse<AnalyticsPageModel>>({
    ...options,
    queryKey: [...analyticsCacheKeys.activitiesGrouped, options?.params],
    select: fetchAnalyticsActivitiesHomepageSelect,
    url: '/data-metrics/activities/homepage/',
  });
};

const fetchAnalyticsSearchesSelect = paginatedTransform(AnalyticsSearchTermModel);
export const useFetchAnalyticsSearches = (
  options?: UseFetchOptions<PaginatedResponse<AnalyticsSearchTermModel>>,
) => {
  return useFetch<PaginatedResponse<AnalyticsSearchTermModel>>({
    ...options,
    queryKey: [...analyticsCacheKeys.searches, options?.params],
    select: fetchAnalyticsSearchesSelect,
    url: '/data-metrics/searches/',
  });
};

export const fetchAnalyticsActivitiesSelect = paginatedTransform(AnalyticsActivityModel);
export const useFetchAnalyticsActivities = (
  options?: UseFetchOptions<PaginatedResponse<AnalyticsActivityModel>>,
) => {
  return useFetch<PaginatedResponse<AnalyticsActivityModel>>({
    ...options,
    queryKey: [...analyticsCacheKeys.all, options?.params],
    select: fetchAnalyticsActivitiesSelect,
    url: '/data-metrics/activities/',
  });
};

export const useFetchAnalyticsActivitiesCsv = (options?: UseFetchOptions) => {
  return useFetch({
    ...options,
    queryKey: [...analyticsCacheKeys.activitiesCsv, options?.params],
    url: '/data-metrics/activities/csv/',
  });
};

export const fetchAnalyticsSchemaChangesSelect = paginatedTransform(AnalyticsActivityModel);
export const useFetchAnalyticsSchemaChanges = (options?: UseFetchOptions) => {
  return useFetch({
    ...options,
    queryKey: [...analyticsCacheKeys.schemaChanges, options?.params],
    select: fetchAnalyticsSchemaChangesSelect,
    url: '/data-metrics/activities/schema_changes/',
  });
};

export const useFetchSchemaChangesCsv = (options?: UseFetchOptions) => {
  return useFetch({
    ...options,
    queryKey: [...analyticsCacheKeys.schemaChangesCsv, options?.params],
    url: '/data-metrics/activities/schema_changes_csv/',
  });
};
