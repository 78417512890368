import React, { useMemo } from 'react';

import { TaggedItemModel } from '@api/tags/TaggedItemModel';
import { TagModel } from '@api/tags/TagModel';
import Box from '@components/Box';
import Breadcrumbs, { breadcrumbsToList } from '@components/Breadcrumbs';
import Highlighter from '@components/Highlighter';
import Tag from '@components/Tag';
import type { TagProps } from '@components/Tag/Tag';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import { useTagContext } from '@context/Tag';
import { GetColorProps } from '@styles/mixins/getColor';
import theme from '@styles/theme';
import { urlFor } from '@utils/routing';

import { StyledLink } from './AppTag.styles';

interface AppTagProps extends TagProps {
  alwaysVisible?: boolean;
  bolded?: boolean;
  className?: string;
  ignoreLinksTo?: boolean;
  item?: TaggedItemModel;
  noLink?: boolean;
  searchTermsToHighlight?: string;
  showBreadcrumbs?: boolean;
  showBreadcrumbsTooltip?: boolean;
  tag: TagModel;
  tagCount?: number;
  tagTextColor?: GetColorProps;
}

const AppTag: React.FC<AppTagProps> = ({
  tag: rawTag,
  variant,
  bolded,
  alwaysVisible,
  noLink,
  ignoreLinksTo,
  tagCount,
  searchTermsToHighlight,
  item,
  showBreadcrumbs,
  showBreadcrumbsTooltip = showBreadcrumbs,
  className,
  tagTextColor,
}) => {
  const { findTag } = useTagContext();
  const linkedTag = rawTag.linksTo ? findTag?.(rawTag.linksTo) : undefined;
  const linkedFromTag = rawTag.linkedFrom ? findTag?.(rawTag.linkedFrom) : undefined;
  const tag = !ignoreLinksTo && linkedTag ? linkedTag : rawTag;
  const tagName = tagCount !== undefined ? `${tag.name} (${tag.count})` : tag.name;

  const tooltip = useMemo(() => {
    if (item?.mappedSuggestionSource) {
      if (item.mappedSuggestionSource?.objectType === 'piirule') {
        return `Auto-filled from: "${item?.mappedSuggestionSource?.name}" PII Rule`;
      }
      const breadcrumbList = breadcrumbsToList(item?.mappedSuggestionSource?.breadcrumbs) || [];
      const breadcrumbs = breadcrumbList.map((a) => `${a.label} / `).join('');
      return `Auto-filled from: ${breadcrumbs}${item?.mappedSuggestionSource?.name}`;
    }
    if (rawTag.type === 'dbt' && linkedTag && !ignoreLinksTo) {
      return `Linked to dbt tag ${rawTag.name}`;
    }
    if (rawTag.type === 'dbt' && linkedFromTag) {
      return `Linked from dbt tag ${rawTag.name}`;
    }
    if (showBreadcrumbsTooltip) {
      return (
        <Breadcrumbs
          clickable={false}
          color="white"
          fontSize="13px"
          fontWeight={400}
          items={tag.breadcrumbLabelList}
          showTrailingSlash={false}
        />
      );
    }

    return undefined;
  }, [
    ignoreLinksTo,
    linkedTag,
    rawTag.name,
    rawTag.type,
    showBreadcrumbsTooltip,
    item?.mappedSuggestionSource,
    tag.breadcrumbLabelList,
  ]);

  const backgroundColor = useMemo(() => {
    if ((item?.suggestionSource || item?.kind === 'suggestion') && item?.kind !== 'user-defined') {
      return theme.colors.gray[100];
    }

    return theme.colors.white;
  }, [item?.suggestionSource, item?.kind]);

  if (!(alwaysVisible || tag.visible)) {
    return null;
  }

  const appTag = (
    <Tooltip content={tooltip} forceDefaultBackground>
      <Tag
        backgroundColor={backgroundColor}
        bolded={bolded}
        className={className}
        color={tagTextColor}
        compWidth={showBreadcrumbs ? 'auto' : 'max-content'}
        icon={<Icon color={tag.color} name={tag.icon} size="1rem" />}
        minHeight={showBreadcrumbs ? 'auto' : '24px'}
        variant={variant}
      >
        {showBreadcrumbs ? (
          <Breadcrumbs
            clickable={false}
            color={tagTextColor ?? theme.colors.text}
            filterValue={searchTermsToHighlight}
            fontSize="13px"
            fontWeight="normal"
            items={tag?.breadcrumbLabelList}
            showTrailingSlash={false}
          />
        ) : (
          <Box as="span" overflow="hidden" verticalAlign="middle">
            <Highlighter
              autoEscape
              searchWords={searchTermsToHighlight?.split(/[.| ]+/) || []}
              textToHighlight={tagName || ''}
            />
          </Box>
        )}
      </Tag>
    </Tooltip>
  );

  if (noLink) {
    return appTag;
  }

  return <StyledLink to={urlFor(tag, true)}>{appTag}</StyledLink>;
};

export default AppTag;
