import { Transform, Type } from 'class-transformer';

import { CustomAttribute, CustomAttributeModel } from '../customAttributes/CustomAttributeModel';
import { CustomAttributeValueModel } from '../customAttributeValues';

export interface CustomAttributeWithValue extends CustomAttribute {
  value: string;
}

export class CustomAttributeWithValueModel extends CustomAttributeModel {
  @Type(() => CustomAttributeValueModel)
  @Transform((value) => (value === null ? undefined : value))
  value?: CustomAttributeValueModel;
}
