import React, { useState } from 'react';
import type { ModalId } from '@constants';

import { useFetchCustomAttributes } from '@api/customAttributes';
import { CustomAttributeModel } from '@api/customAttributes/CustomAttributeModel';
import Box from '@components/Box';
import TitleView from '@components/UI/TitleView';
import { useModal } from '@context/Modal';
import { Filter } from '@utils';

import AddButton from '../../AddButton';

import AddCustomAttributeModal from './AddCustomAttributeModal';
import CustomAttributesTable from './CustomAttributesTable';
import DeleteCustomAttributeModal from './DeleteCustomAttributeModal';

const fetchCustomAttributesConfig: Filter.FilterOptions = {
  page: 1,
  page_size: 100,
  query: '{guid,type,name,data_source_types,object_types,data_types,created_by}',
};

interface CustomAttributeModal {
  customAttribute?: CustomAttributeModel;
}

const AdminCustomAttributes: React.FC = () => {
  const [modal, setModal] = useState<CustomAttributeModal>({
    customAttribute: undefined,
  });
  const { MODAL_IDS, checkModalOpened, closeModal, openModal } = useModal();
  const { data, isLoading } = useFetchCustomAttributes({
    params: fetchCustomAttributesConfig,
  });

  const handleEditClick = (customAttribute: CustomAttributeModel) => {
    openModal(MODAL_IDS.addCustomAttributeModal);
    setModal({ customAttribute });
  };

  const handleDeleteClick = (customAttribute: CustomAttributeModel) => {
    openModal(MODAL_IDS.deleteCustomAttributeModal);
    setModal({ customAttribute });
  };

  const handleModalClose = (modalId: ModalId) => {
    closeModal(modalId);
    setModal({ customAttribute: undefined });
  };

  return (
    <Box maxWidth="100%" mb={5}>
      <TitleView
        action={<AddButton onClick={() => openModal(MODAL_IDS.addCustomAttributeModal)} />}
        mb={2.5}
        pt={1}
        title="Custom Attribute"
      />
      <CustomAttributesTable
        data={data?.results}
        loading={isLoading}
        onDeleteClick={handleDeleteClick}
        onEditClick={handleEditClick}
      />
      {checkModalOpened(MODAL_IDS.addCustomAttributeModal) && (
        <AddCustomAttributeModal
          initialData={modal.customAttribute}
          onClose={() => handleModalClose(MODAL_IDS.addCustomAttributeModal)}
        />
      )}
      {checkModalOpened(MODAL_IDS.deleteCustomAttributeModal) && modal.customAttribute && (
        <DeleteCustomAttributeModal
          customAttribute={modal.customAttribute}
          onClose={() => handleModalClose(MODAL_IDS.deleteCustomAttributeModal)}
        />
      )}
    </Box>
  );
};

export default AdminCustomAttributes;
