import styled from '@emotion/styled';
import type { Property } from 'csstype';

import type { Theme } from '@styles/theme';
import type { Breakpoint } from '@styles/theme/breakpoints';

import Column from './Column.styles';

const makeReverseUp = (theme: Theme, breakpoints: Breakpoint[] | undefined) => {
  if (breakpoints === undefined) {
    return null;
  }

  return breakpoints.map(
    (breakName) => theme.media.up(breakName)`
      flex-direction: row-reverse;
    `,
  );
};

const DEFAULT_SPACE = 1;

export interface RowProps {
  alignItems?: Property.AlignItems;
  display?: Property.Display;
  flexDirection?: Property.FlexDirection;
  flexGrow?: Property.FlexGrow;
  flexWrap?: Property.FlexWrap;
  justifyContent?: Property.JustifyContent;
  margin?: string;
  reverseUp?: Breakpoint[];
  space?: number;
}

const Row = styled.div<RowProps>`
  ${({ reverseUp, theme }) => makeReverseUp(theme, reverseUp)};
  display: ${({ display = 'flex' }) => display};
  align-items: ${({ alignItems }) => alignItems};
  flex-direction: ${({ flexDirection }) => flexDirection};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  justify-content: ${({ justifyContent }) => justifyContent};
  flex-grow: ${({ flexGrow }) => flexGrow};
  width: ${({ space = DEFAULT_SPACE, theme }) => `calc(100% + ${theme.space(space * 2)})`};
  height: ${({ space = DEFAULT_SPACE, theme }) => `calc(100% + ${theme.space(space * 2)})`};
  margin: ${({ margin, space = DEFAULT_SPACE, theme }) =>
    margin !== undefined ? margin : theme.space(-space)};

  > ${Column} {
    padding: ${({ space = DEFAULT_SPACE, theme }) => theme.space(space)};
  }
`;

export default Row;
