import React from 'react';
import uniq from 'lodash/uniq';

import type { FilterIndex } from '@api/search/types';
import { AccordionItem } from '@components/Accordion';
import { checkItemChecked } from '@components/FiltersSidebar/FiltersSidebar';
import { tabs as dataSourceFacetTabs } from '@components/SearchBar/DatasourceTabs/DatasourceTabs';
import type { DatasourceTab } from '@components/SearchBar/DatasourceTabs/DatasourceTabs.types';
import {
  findSearchFacet,
  getSearchFacetOrder,
  SearchFacetType,
} from '@components/SearchBar/SearchTypes';
import Icon from '@components/UI/Icon';

const getSearchFacetsForTab = (
  facets: { [key: string]: number } = {},
  tab: DatasourceTab,
  selectedValues?: string[],
) => {
  // Necessary for grouping facets together.
  const uniqueFacets = uniq(
    Object.keys(facets)
      .map((facet) => findSearchFacet(facet as FilterIndex))
      .filter((x): x is SearchFacetType => x !== undefined),
  );

  const facetsForTab = uniqueFacets.filter((facet) => tab.filters[facet.name[0]] !== undefined);

  return facetsForTab.map((facet) => {
    const facetCount = facet.name.reduce((acc: number, name) => {
      if (facets[name]) {
        return facets[name] + acc;
      }
      return acc;
    }, 0);

    return {
      checked: facet.name.some((facetName) => checkItemChecked(facetName, selectedValues)),
      id: facet.name[0],
      value: `${facet?.label} (${facetCount})`,
    };
  });
};

interface SearchFacetFiltersProps {
  query?: string;
  searchFacets: { [key: string]: number };
  selectedValues?: string[];
  update: (guid: string) => void;
}

const SearchFacetFilters: React.FC<SearchFacetFiltersProps> = ({
  query,
  searchFacets,
  selectedValues,
  update,
}) => {
  return (
    <>
      {dataSourceFacetTabs
        .map((tab) => {
          // We don't want to show anything for all.
          if (tab.name === 'All') return null;

          const searchFacetsForTab = getSearchFacetsForTab(searchFacets, tab, selectedValues).sort(
            (a, b) => getSearchFacetOrder(a.id) - getSearchFacetOrder(b.id),
          );

          if (!searchFacetsForTab.length) return null;

          return (
            <AccordionItem
              key={`${query}${tab.name}`}
              accordionItems={searchFacetsForTab}
              title={
                <>
                  {tab.icon && <Icon mr={0.5} name={tab.icon} size="16px" />}
                  {tab.name}
                </>
              }
              update={(key) => update(key)}
            />
          );
        })
        .filter(Boolean)}
    </>
  );
};

export default SearchFacetFilters;
