import styled from '@emotion/styled';

import TableStyled from '@components/Table/TableStyled';

export const StyledTableWrapper = styled(TableStyled)`
  .ui.table
    tr
    td:not(.description):not(.user):not(.datasource):not(.status):not(.ss_tag):not(.configure) {
    line-height: normal;
  }
`;
