import React from 'react';

import { CustomAttributeModel } from '@api/customAttributes/CustomAttributeModel';
import { CustomAttributeValueModel } from '@api/customAttributeValues';
import RichTextDescriptionEditor, {
  RichTextDescriptionEditorProps,
} from '@components/RichTextDescriptionEditor/RichTextDescriptionEditor';
import { renderErrorToast } from '@components/Toast';
import { useUserContext } from '@context/User';
import theme from '@styles/theme';

import CustomAttributeTitle from './CustomAttributeTitle';
import useEditCustomAttributeValueForm, { MAX_LENGTH } from './useEditCustomAttributeValueForm';

export interface FormValues {
  value: string;
}

export interface EditCustomAttributeValueInlineProps {
  customAttribute: CustomAttributeModel;
  customAttributeValue?: CustomAttributeValueModel;
  editIconVariant?: RichTextDescriptionEditorProps['editIconVariant'];
  isEditable?: boolean;
  itemGuid: string;
  onError?: () => void;
  onSuccess?: () => void;
  showLabel?: boolean;
  wrapTextAt?: number;
}

const EditCustomAttributeValueInline: React.FC<EditCustomAttributeValueInlineProps> = ({
  customAttribute,
  customAttributeValue,
  editIconVariant = 'hover',
  isEditable,
  itemGuid,
  onError,
  onSuccess,
  showLabel = true,
  wrapTextAt,
}) => {
  const { hasEditPermissions, organization } = useUserContext();
  const useRbac = organization?.settings?.useRbac;
  const isPatch = Boolean(customAttributeValue?.guid);
  const { patchQuery, postQuery } = useEditCustomAttributeValueForm({
    customAttribute,
    guid: customAttributeValue?.guid,
    onError,
    onSuccess,
  });

  const handleSave = (richtextDesc?: string, plainTextDesc?: string) => {
    if (plainTextDesc && plainTextDesc.length > MAX_LENGTH) {
      renderErrorToast(`Please enter less than ${MAX_LENGTH} characters`);
      return;
    }
    if (isPatch) {
      patchQuery.mutate({
        richtext_value: richtextDesc,
        value: plainTextDesc,
      });
    } else {
      postQuery.mutate({
        custom_attribute: customAttribute.guid,
        item: itemGuid,
        richtext_value: richtextDesc || '',
        value: plainTextDesc || '',
      });
    }
  };

  return (
    <>
      {showLabel && (
        <CustomAttributeTitle
          color={theme.colors.v1.gray[500]}
          iconColor={theme.colors.v1.gray[500]}
          item={customAttribute}
        />
      )}
      <RichTextDescriptionEditor
        descriptions={{
          description: customAttributeValue?.value,
          richTextDescription: customAttributeValue?.richTextValue,
        }}
        editIconVariant={editIconVariant}
        guid={customAttribute.guid}
        isEditable={useRbac ? isEditable : isEditable && hasEditPermissions}
        isSuccess={patchQuery.isSuccess || postQuery.isSuccess}
        maxLength={wrapTextAt}
        onDescriptionSave={handleSave}
      />
    </>
  );
};

export default EditCustomAttributeValueInline;
