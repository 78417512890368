import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { usePostSubscribersSlack } from '@api/slack/subscribers';
import CircularLoader from '@components/CircularLoader';
import TextScreen from '@components/TextScreen';
import TwoColumnDecorativeComponent from '@components/TwoColumnDecorativeComponent';
import TwoColumnLayout from '@components/TwoColumnLayout';
import useUrlSearchParams from '@hooks/useUrlSearchParams';
import MetadataDecorator from '@utils/MetadataDecorator';

/**
 * These are know error messages from Slack. Any new errors
 * seen should be added here and have a proper error message
 * related to them. Slack only gives basic errors i.e
 * access_denied or invalid_browser.
 */
const ERROR_MESSAGES = {
  access_denied: 'The user has denied access to the scopes requested by the application.',
  invalid_browser:
    'There was an error with installation. This can occur due to page reload, not beginning the OAuth flow from the valid starting URL, or the /slack/install URL not using https://',
};

const SlackInstallPage: React.FC = () => {
  const query = useUrlSearchParams();
  const history = useHistory();
  const state = query.get('state') as string;
  const [error, setError] = useState(query.get('error'));

  let leftComponent = <CircularLoader centered />;

  if (error) {
    const errorMessage =
      ERROR_MESSAGES[error as keyof typeof ERROR_MESSAGES] ??
      `Something went wrong. Please contact support.`;

    leftComponent = (
      <TextScreen text={errorMessage} title="Slack installation was not successful" />
    );
  }

  const { mutate: activateSlackSubscriber } = usePostSubscribersSlack({
    onError: () => {
      setError('Something went wrong. Please contact support.');
    },
    onSuccess: () => {
      if (state) {
        const params = new URLSearchParams();
        params.append('state', state);
        history.push(`/authorize/slack?${params.toString()}`);
      }
    },
  });

  useEffect(() => {
    if (!error) {
      activateSlackSubscriber(null);
    }
  }, [activateSlackSubscriber, error]);

  if (state && !error) {
    leftComponent = <TextScreen title="Slack installation was successful" />;
  }

  return (
    <>
      <MetadataDecorator title="Slack Install" />
      <TwoColumnLayout left={leftComponent} right={<TwoColumnDecorativeComponent />} />
    </>
  );
};

export default SlackInstallPage;
