import React, { Fragment, useEffect, useMemo, useState } from 'react';
import type { WarehouseSource } from '@configs/dataSources/types';
import groupBy from 'lodash/groupBy';
import xor from 'lodash/xor';

import { useFetchSourceTables } from '@api/lineage';
import Box from '@components/Box/Box.styles';
import CircularLoader from '@components/CircularLoader';
import FiltersList from '@components/FiltersList';
import type { FiltersListFilterType } from '@components/FiltersList/FiltersList';
import RelevantLineageToggle from '@components/LineageExplore/components/RelevantLineageToggle';
import TabError from '@components/TabContent/TabError';
import Text from '@components/Text';
import { useUserContext } from '@context/User';

import { StyledSourceTablesTabItemWrapper } from './SourceTablesTab.styles';
import SourceTablesTabRow from './SourceTablesTabRow';
import SourceTablesTabSchemaRow from './SourceTablesTabSchemaRow';
import SourceTablesTabTableRow from './SourceTablesTabTableRow';

interface SourceTablesTabFilter extends FiltersListFilterType {
  dataSourceType: WarehouseSource | 'all';
  type: 'schema' | 'table' | 'all';
}

export const NO_SOURCE_TABLES_MESSAGE = 'No source tables detected.';

const defaultFilter: SourceTablesTabFilter = {
  dataSourceType: 'all',
  label: 'All',
  name: 'all',
  type: 'all',
};

interface SourceTablesTabProps {
  guid: string;
}

const SourceTablesTab: React.FC<SourceTablesTabProps> = ({ guid }) => {
  const { organization } = useUserContext();
  const [isRelevantLineage, setIsRelevantLineage] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<SourceTablesTabFilter>(defaultFilter);
  const [openedItemsIds, setOpenedItemsIds] = useState<string[] | null>(null);
  const { data, isError, isLoading } = useFetchSourceTables(guid, {
    enabled: Boolean(guid),
    params: {
      relevant_lineage: organization?.settings?.useRelevantLineage ? isRelevantLineage : undefined,
    },
  });

  const sourceTablesFilters = useMemo(() => {
    if (!data?.upstream || data?.upstream?.length === 0) {
      return [];
    }

    const groupedSchemas = groupBy(data?.upstream, (el) => el?.schema?.dataSourceType);
    const dataSourceTypeKeys = Object.keys(groupedSchemas) as WarehouseSource[];
    const sourceTableFilters = dataSourceTypeKeys?.reduce((acc, dataSourceType) => {
      const schemaTablesItem = groupedSchemas?.[dataSourceType];

      if (!schemaTablesItem || schemaTablesItem?.length === 0) {
        return acc;
      }

      const newFilters: SourceTablesTabFilter[] = [
        ...acc,
        {
          count: groupedSchemas?.[dataSourceType]?.length,
          dataSourceType,
          dataTypes: schemaTablesItem[0].schema.dataTypes,
          label: 'Schemas',
          name: `${dataSourceType}-schema`,
          type: 'schema',
        },
      ];

      const tablesCount = schemaTablesItem?.reduce(
        (sum, item) => (item?.tables ? sum + (item?.tables?.length ?? 0) : sum),
        0,
      );

      if (tablesCount > 0) {
        newFilters.push({
          count: tablesCount,
          dataSourceType,
          dataTypes: schemaTablesItem[0].tables[0].dataTypes,
          label: 'Tables',
          name: `${dataSourceType}-table`,
          type: 'table',
        });
      }

      return newFilters;
    }, [] as SourceTablesTabFilter[]);

    return [defaultFilter, ...sourceTableFilters];
  }, [data?.upstream]);

  const toggleOpenedItemId = (id?: string) => {
    if (id) {
      setOpenedItemsIds((prev) => xor(prev, [id]));
    }
  };

  const allKeys = useMemo(() => {
    const schemaKeys: string[] = [];

    data?.upstream?.forEach((item) => {
      schemaKeys.push(item.schema.guid);
    });

    return schemaKeys;
  }, [data?.upstream]);

  const handleFilterChange = (value: SourceTablesTabFilter) => {
    if (value.type === 'all') {
      setOpenedItemsIds(allKeys);
    } else {
      setOpenedItemsIds(null);
    }

    setSelectedFilter(value);
  };

  /** Shows all by default. */
  useEffect(() => {
    setOpenedItemsIds(allKeys);
  }, [allKeys, data?.upstream, setOpenedItemsIds]);

  if (isLoading) {
    return <CircularLoader centered compSize={5} data-testid="data-loading" />;
  }

  if (isError) return <TabError />;

  if (data?.upstream?.length === 0 || (!isLoading && !data) || (!isLoading && !data?.upstream)) {
    return <Text color="inherit">{NO_SOURCE_TABLES_MESSAGE}</Text>;
  }

  return (
    <Box compDisplay="flex" flexDirection="column">
      <Box alignItems="center" compDisplay="flex" justifyContent="space-between">
        <FiltersList
          data={sourceTablesFilters}
          onClick={handleFilterChange}
          selectedFilter={selectedFilter}
        />
        {organization?.settings?.useRelevantLineage && (
          <RelevantLineageToggle
            checked={isRelevantLineage}
            onChange={(e) => setIsRelevantLineage(e.target.checked)}
          />
        )}
      </Box>
      <Box borderBottom="1px solid" borderColor="#d8dbe0" mb={0.5} pb={0.5} pt={1}>
        <SourceTablesTabRow
          firstColumn={
            <Text color="inherit" fontSize="13px" fontWeight="bold" pl={5.5}>
              Name
            </Text>
          }
          secondColumn={
            <Text color="inherit" fontSize="13px" fontWeight="bold">
              Description
            </Text>
          }
          thirdColumn={
            <Text color="inherit" fontSize="13px" fontWeight="bold">
              Popularity
            </Text>
          }
        />
      </Box>
      {data?.upstream
        ?.filter((item) => {
          return (
            selectedFilter.type === 'all' ||
            item?.schema?.dataSourceType === selectedFilter.dataSourceType
          );
        })
        .map((item) => {
          const schema = item?.schema;
          const id = item?.schema?.guid;
          const isTablesSelected = selectedFilter.type === 'table';
          const isOpen = openedItemsIds?.includes(schema.guid) || isTablesSelected;
          const firstTable = item?.tables?.[0];

          return (
            <Fragment key={id}>
              <SourceTablesTabSchemaRow
                breadcrumbs={firstTable?.breadcrumbList}
                dataTypes={schema?.dataTypes}
                description={schema?.description}
                isOpen={isOpen}
                isToggleDisabled={isTablesSelected}
                onToggle={() => toggleOpenedItemId(id)}
                popularity={schema.popularity}
              />
              {isOpen && (
                <StyledSourceTablesTabItemWrapper>
                  {item?.tables?.map((table) => (
                    <SourceTablesTabTableRow
                      key={table.guid}
                      dataTypes={table.dataTypes}
                      description={table?.description}
                      name={table?.name}
                      popularity={table?.popularity}
                      routePath={table?.routePath}
                    />
                  ))}
                </StyledSourceTablesTabItemWrapper>
              )}
            </Fragment>
          );
        })}
    </Box>
  );
};

export default SourceTablesTab;
