import React, { useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import {
  useFetchSlackConnectedChannels,
  useFetchSlackSettings,
  useFetchSlackWorkspace,
  usePatchSlackSettings,
} from '@api/slack';
import { ReceiveNotificationTypeEnum } from '@api/slack/SlackSettingsModel';
import { useFetchSubscribersSlack } from '@api/slack/subscribers';
import { tagColorMap } from '@components/AppTag/helpers';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import CircularLoader from '@components/CircularLoader';
import useForm from '@components/Form/useForm';
import { Column, Row } from '@components/Grid';
import LayoutMainGrid from '@components/Layout/LayoutMainGrid';
import Link from '@components/Link';
import DisconnectSlackModal from '@components/Modal/DisconnectSlackModal/DisconnectSlackModal';
import Skeleton from '@components/Skeleton';
import Text from '@components/Text';
import { renderErrorToast, renderInfoToast } from '@components/Toast';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import Select, { Option, SelectValue } from '@components/UI/Select';
import { useModal } from '@context/Modal';
import { useUserContext } from '@context/User';
import { ConnectableApps } from '@hooks/useConnectableApps';
import AdminAppCard from '@pages/Admin/AdminAppsPage/AdminAppCard';
import { appsBaseConfiguration } from '@pages/Admin/AdminAppsPage/AdminAppsPage';
import { SectionTitleWrapper, Title } from '@pages/Admin/AdminCostAnalysisPage/tabs/SectionTitle';
import AdminTitleView from '@pages/Admin/AdminTitleView/AdminTitleView';
import AppSettingsInput from '@pages/UserSettingsPage/AppSettingsInput';
import SettingsSection from '@pages/UserSettingsPage/SettingsSection';
import theme from '@styles/theme';
import MetadataDecorator from '@utils/MetadataDecorator';

export const SLACKAPP_INSTALL_URL = `https://slack.${
  window.env?.ENVIRONMENT ?? 'production'
}.selectstar.com/slack/install`;

export const NO_WORKSPACE_MSG = 'No workspace connected.';

const slackConfig = appsBaseConfiguration[ConnectableApps.slack];

const RECEIVE_NOTIFICATIONS_TYPE_OPTIONS = [
  {
    subtitle: 'Only objects with a business or technical owner',
    title: 'Assets with a business or technical owner',
    value: 'owners',
  },
  {
    subtitle: 'All assets in the Select Star catalog',
    title: 'All assets',
    value: 'all',
  },
];

const UPDATE_DEBOUNCE_TIME = 1000;
interface SlackSettingsForm {
  enableUnfurl: boolean;
  receiveNotificationType?: ReceiveNotificationTypeEnum;
  slackChannelsForNotifications?: string[];
}

const AdminSlackPage: React.FC = () => {
  const { organization } = useUserContext();

  const {
    data: subscribers,
    isLoading,
    refetch: refetchSlackSubscribers,
  } = useFetchSubscribersSlack();

  const {
    data: settings,
    isLoading: isLoadingSettings,
    refetch: refetchSlackSettings,
  } = useFetchSlackSettings();

  const { data: workspace, isLoading: isLoadingWorkspace } = useFetchSlackWorkspace();

  const { data: connectedChannelsData, isLoading: isLoadingConnectedChannels } =
    useFetchSlackConnectedChannels();

  const { handleChange, setValues, values } = useForm<SlackSettingsForm>({
    initialValues: {
      enableUnfurl: Boolean(settings?.allowLinkUnfurling),
      receiveNotificationType: settings?.receiveNotificationType,
      slackChannelsForNotifications: settings?.slackChannelsForNotifications,
    },
  });

  useEffect(() => {
    setValues({
      enableUnfurl: Boolean(settings?.allowLinkUnfurling),
      receiveNotificationType: settings?.receiveNotificationType,
      slackChannelsForNotifications: settings?.slackChannelsForNotifications,
    });
  }, [settings, setValues]);

  const { mutate: updateSlackSettings } = usePatchSlackSettings({
    onError: () => {
      renderErrorToast("Couldn't save settings.");
    },
    onSuccess: () => {
      renderInfoToast('Changes saved.');
      refetchSlackSettings();
    },
  });

  const update = useDebouncedCallback(() => {
    updateSlackSettings({
      allow_link_unfurling: values.enableUnfurl,
      receive_notification_type: values.receiveNotificationType,
      slack_channels_for_notifications: values.slackChannelsForNotifications,
    });
  }, UPDATE_DEBOUNCE_TIME);

  const { MODAL_IDS, checkModalOpened, closeModal, openModal } = useModal();

  const onClick = () => {
    window.location.href = SLACKAPP_INSTALL_URL;
  };

  const handleSettingChange = (name: string, value: string | boolean) => {
    handleChange({ target: { name, value } });
    update();
  };

  const handleChannelChange = (newValue: Option[]) => {
    setValues((prevValues) => ({
      ...prevValues,
      slackChannelsForNotifications: newValue.map((value) => value.value as string),
    }));
    update();
  };

  const connectedChannelsOptions = (connectedChannelsData?.results ?? []).map((channel) => {
    return {
      selected: values.slackChannelsForNotifications?.includes(channel.channel_id),
      text: `# ${channel.name}`,
      value: channel.channel_id,
    };
  });

  return (
    <>
      <MetadataDecorator title="Slack Integration" />
      <LayoutMainGrid>
        <AdminTitleView title="Slack Integration" />
        <Box alignItems="start" position="relative">
          {isLoading && <CircularLoader centered />}
          {!isLoading && (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {subscribers?.count === 0 ? (
                <Row flexWrap="wrap">
                  <Column flexShrink={0} lg={4} md={6} sm={12}>
                    <AdminAppCard
                      connected={false}
                      description={slackConfig.description}
                      icon={slackConfig.icon}
                      name={slackConfig.name}
                      onClick={onClick}
                      onConnect={onClick}
                    />
                  </Column>
                </Row>
              ) : (
                <>
                  {organization?.settings?.useDataQualityTab ? (
                    <Box alignItems="center" compDisplay="flex" gap={1.5} mb={2}>
                      <SectionTitleWrapper>
                        <Title fontWeight={600}>Status</Title>
                      </SectionTitleWrapper>
                      <Tooltip content="Connected">
                        <Icon color={tagColorMap.endorsed} name="endorsed" size="20px" />
                      </Tooltip>
                    </Box>
                  ) : (
                    <Box alignItems="center" compDisplay="flex" gap={5.5} mb={2}>
                      <SectionTitleWrapper>
                        <Title fontWeight={600}>Status</Title>
                      </SectionTitleWrapper>
                      <Button startIcon={<Icon name="check" />} variant="outlined">
                        Connected
                      </Button>
                    </Box>
                  )}
                  <Box mb={5.5}>
                    <SettingsSection>
                      <Text alignItems="center" display="flex" fontSize="body2" gap={0.5} mb={0}>
                        Connected Workspace:
                        {isLoadingWorkspace ? (
                          <Skeleton compWidth="120px" variant="text" />
                        ) : (
                          // eslint-disable-next-line react/jsx-no-useless-fragment
                          <>
                            {workspace?.name ? (
                              <Link
                                fontSize="inherit"
                                href={workspace?.url}
                                rel="noopener noreferrer"
                                target="_blank"
                                underlineOnHover
                              >
                                {workspace?.name}
                              </Link>
                            ) : (
                              <Text color="gray.500" fontSize="inherit">
                                {NO_WORKSPACE_MSG}
                              </Text>
                            )}
                          </>
                        )}
                      </Text>
                    </SettingsSection>
                  </Box>
                  <Box mb={5.5}>
                    <SettingsSection title="App Settings">
                      {isLoadingSettings && <CircularLoader centered />}
                      {!isLoadingSettings && (
                        <Box compDisplay="flex" flex={1} flexDirection="column" gap={3}>
                          <AppSettingsInput
                            onChange={(newValue) => handleSettingChange('enableUnfurl', newValue)}
                            title="Show object name and description in unfurl"
                            titleAlignment="center"
                            type="toggle"
                            value={values.enableUnfurl}
                          />
                          <AppSettingsInput
                            title="Send schema change notifications to slack"
                            titleAlignment="center"
                          >
                            <Select
                              isLoading={isLoadingConnectedChannels}
                              isMulti
                              onChange={(value: SelectValue) => {
                                handleChannelChange(value as Option[]);
                              }}
                              options={connectedChannelsOptions}
                              placeholder="Choose channel"
                              showSelectAllButton={false}
                              value={connectedChannelsOptions?.filter((option) => option.selected)}
                            />
                          </AppSettingsInput>
                          <AppSettingsInput
                            onChange={(newValue) =>
                              handleSettingChange('receiveNotificationType', newValue)
                            }
                            options={RECEIVE_NOTIFICATIONS_TYPE_OPTIONS}
                            title="Receive Notifications For"
                            value={values.receiveNotificationType}
                          />
                        </Box>
                      )}
                    </SettingsSection>
                  </Box>
                  <Box mb={5.5}>
                    <SettingsSection title="Connected Channels">
                      {isLoadingConnectedChannels && <CircularLoader centered />}
                      {!isLoadingConnectedChannels &&
                        connectedChannelsData?.results?.map((channel) => (
                          <Text key={channel.channel_id} fontSize="body2" mb={0}>
                            #{channel.name}
                          </Text>
                        ))}
                    </SettingsSection>
                  </Box>
                  <Box mb={5.5}>
                    <Text
                      color={theme.colors.error}
                      cursor="pointer"
                      fontSize="body2"
                      fontWeight="medium"
                      onClick={() => openModal(MODAL_IDS.disconnectSlack)}
                      role="button"
                    >
                      Disconnect Select Star from Slack
                    </Text>
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
      </LayoutMainGrid>
      {checkModalOpened(MODAL_IDS.disconnectSlack) && (
        <DisconnectSlackModal
          onClose={() => closeModal(MODAL_IDS.disconnectSlack)}
          onSuccess={() => closeModal(MODAL_IDS.disconnectSlack)}
          reloadData={() => refetchSlackSubscribers()}
        />
      )}
    </>
  );
};

export default AdminSlackPage;
