import React, { useEffect, useMemo, useState } from 'react';

import { SearchModel } from '@api/search/SearchModel';
import { SearchResult } from '@api/search/types';
import type { DatasourceTab } from '@components/SearchBar/DatasourceTabs/DatasourceTabs.types';

import type { SearchFacetType, SearchFiltersType } from '../SearchTypes';

import { getFacetCountsMap, getFilters, orderFacets } from './helpers';
import SearchFacetCheckbox, { FacetNamesFiltersTypes } from './SearchFacetCheckbox';
import { StyledFacetContainer } from './StyledFilterContainer';

interface SearchFilterProps {
  activeTab: DatasourceTab;
  searchResult?: SearchResult<SearchModel>;
  setFilters: (filters: SearchFiltersType) => void;
}

const SearchFilter: React.FC<SearchFilterProps> = ({ activeTab, searchResult, setFilters }) => {
  const [checkedFilters, setCheckedFilters] = useState<FacetNamesFiltersTypes>({});
  const [originalSearchFacets, setOriginalSearchFacets] = useState<
    SearchResult<SearchModel>['facets'] | undefined
  >(undefined);

  // Set filters when checkboxes get checked or unchecked.
  useEffect(() => {
    setFilters(getFilters(checkedFilters, activeTab));
  }, [checkedFilters]);

  // Reset checked filters when tab changes.
  useEffect(() => {
    setCheckedFilters({});
  }, [activeTab]);

  const facetsOrdered = useMemo(() => orderFacets(activeTab), [activeTab]);

  // Returns a map of result counts for the previously ordered facets
  const facetCounts = useMemo(() => {
    if (!originalSearchFacets) {
      return undefined;
    }

    return getFacetCountsMap(facetsOrdered, originalSearchFacets);
  }, [originalSearchFacets, facetsOrdered]);

  // Only display facets that have a count > 0 or if the user has them checked.
  const displayedFacets: SearchFacetType[] = useMemo(
    () =>
      facetsOrdered.filter(
        (facet) => !!facetCounts?.[facet.name[0]] || checkedFilters[facet.name[0]],
      ),
    [facetCounts],
  );

  useEffect(() => {
    if (!originalSearchFacets) {
      setOriginalSearchFacets(searchResult?.facets);
    }
    if (searchResult?.total === 0) {
      setOriginalSearchFacets(undefined);
    }
  }, [searchResult, originalSearchFacets]);

  if (!displayedFacets || displayedFacets?.length === 0) {
    return null;
  }

  return (
    <StyledFacetContainer>
      {displayedFacets.map((facet) => (
        <SearchFacetCheckbox
          key={facet.name[0]}
          checkedFilters={checkedFilters}
          facet={facet}
          facetCount={facetCounts?.[facet.name[0]] ?? 0}
          setCheckedFilters={setCheckedFilters}
        />
      ))}
    </StyledFacetContainer>
  );
};

export default SearchFilter;
