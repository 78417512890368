import getDebug from 'debug';

import { calculateOpenTableHeight } from '@components/ExploreTree/helpers/index';

import type { Coordinates, TreeContext } from '../types';

import getTableIds from './getTableIds';

const debug = getDebug('selectstar:explore');
const debugGraph = debug.extend('graph');

interface SpaceTakenArgs
  extends Pick<
    TreeContext,
    | 'rectHeight'
    | 'ySpacing'
    | 'sortedColumnIdsByTableId'
    | 'pinnableColumnsById'
    | 'activeSearchTables'
  > {
  id: string;
  openedTables: string[];
  tableIdsShowAllColumns: string[];
}

const spaceTaken = ({
  activeSearchTables,
  id,
  openedTables,
  pinnableColumnsById,
  rectHeight,
  sortedColumnIdsByTableId,
  tableIdsShowAllColumns,
  ySpacing,
}: SpaceTakenArgs) => {
  const isTableOpen = openedTables.includes(id);
  const isSearchOpen = activeSearchTables.includes(id);
  let y = rectHeight;

  if (isTableOpen) {
    y = calculateOpenTableHeight({
      columns: sortedColumnIdsByTableId[id],
      pinnableColumnsById,
      rectHeight,
      showAllColumns: tableIdsShowAllColumns.includes(id),
    });
  }

  if (isSearchOpen) {
    y += rectHeight;
  }

  return y + ySpacing;
};

interface GenerateStackCoordinatesArgs
  extends Pick<
    TreeContext,
    | 'stack'
    | 'stackedAt'
    | 'rectWidth'
    | 'xSpacing'
    | 'columnsById'
    | 'tablesById'
    | 'rectHeight'
    | 'ySpacing'
    | 'sortedColumnIdsByTableId'
    | 'pinnableColumnsById'
    | 'activeSearchTables'
  > {
  flow: Coordinates;
  hoverTargetedColumns: string[];
  offsetX?: number;
  offsetY?: number;
  openedTables: string[];
  tableIdsShowAllColumns: string[];
}

const generateStackCoordinates = ({
  activeSearchTables,
  columnsById,
  flow,
  hoverTargetedColumns,
  offsetX = 0,
  offsetY = 0,
  openedTables,
  pinnableColumnsById,
  rectHeight,
  rectWidth,
  sortedColumnIdsByTableId,
  stack,
  stackedAt,
  tableIdsShowAllColumns,
  tablesById,
  xSpacing,
  ySpacing,
}: GenerateStackCoordinatesArgs) => {
  const coords = [] as Coordinates[];
  const tablesTargetedByColumns = getTableIds(hoverTargetedColumns, columnsById);
  let yMax = 0;
  let xMax = 0;
  let yMin = 0;
  let xMin = 0;

  Object.keys(stack).forEach((posStr) => {
    const pos = Number(posStr);
    const x = offsetX + (rectWidth + xSpacing) * pos;
    let y = offsetY;
    let flowPos = 0;
    const visibleStack = stack[pos]?.filter((stackedTable) =>
      Object.keys(tablesById).includes(stackedTable[0]),
    );

    if (pos === stackedAt[flow.key]) {
      y = flow.y;
      flowPos = visibleStack.findIndex((_) => _[0] === flow.key);
    } else {
      const firstTarget = visibleStack.findIndex((_) => tablesTargetedByColumns.includes(_[0]));
      if (firstTarget !== -1) {
        y = flow.y;
        flowPos = firstTarget;
      }
    }

    /**
     * When flowPos > 0, it means we have a table with a yPos requirement
     * so we calculate the negative space before we push first stack coordinate.
     */
    for (let i = 0; i < flowPos; i += 1) {
      y -= spaceTaken({
        activeSearchTables,
        id: visibleStack[i][0],
        openedTables,
        pinnableColumnsById,
        rectHeight,
        sortedColumnIdsByTableId,
        tableIdsShowAllColumns,
        ySpacing,
      });
    }

    visibleStack.forEach(([key]) => {
      coords.push({ key, stackPos: pos, x, y });
      y += spaceTaken({
        activeSearchTables,
        id: key,
        openedTables,
        pinnableColumnsById,
        rectHeight,
        sortedColumnIdsByTableId,
        tableIdsShowAllColumns,
        ySpacing,
      });
    });

    if (y < yMin) {
      yMin = y;
    }

    if (y > yMax) {
      yMax = y;
    }

    if (x < xMin) {
      xMin = x;
    }

    if (x > xMax) {
      xMax = x;
    }
  });

  debugGraph('coords', coords);

  return { coords, xMax, xMin, yMax, yMin };
};

export default generateStackCoordinates;
