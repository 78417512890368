import React from 'react';

import Box from '@components/Box';

import type { TooltipContentPayload } from './types';

interface TooltipBoxValueProps extends Omit<TooltipContentPayload, 'value'> {
  value: React.ReactNode;
}

const TooltipBoxValue = ({ divisor = ': ', name, value }: TooltipBoxValueProps) => (
  <Box compDisplay="flex" fontSize="body1" gap={0.5}>
    {name}
    {divisor && divisor}
    <Box as="span" compDisplay="flex" fontWeight="bold" noDefault>
      {value}
    </Box>
  </Box>
);

export default TooltipBoxValue;
