import React from 'react';

import { SearchModel } from '@api/search/SearchModel';
import Breadcrumbs from '@components/Breadcrumbs';
import Highlighter from '@components/Highlighter';
import { getIconTooltipText } from '@components/SearchBar/SearchResult/SearchResult';
import {
  SearchResultPath,
  SearchResultTitle,
  SearchResultTitleRow,
} from '@components/SearchBar/SearchResult/SearchResult.styles';
import SearchResultIcon from '@components/SearchBar/SearchResult/SearchResultIcon';
import { SearchTopIcon } from '@components/SearchBar/SearchResult/SearchTopIcon';
import Tooltip from '@components/Tooltip';

import { StyledSearchResultCell } from './SearchResultCell.styles';
import { TypedCell } from './types';

export interface ItemProps {
  item: SearchModel;
  searchText?: string;
  searchWords: string[];
}

const SearchResultCell = React.memo((props: TypedCell & ItemProps) => {
  const { item, searchText, searchWords } = props;
  const { avatar, dataTypes, guid, isActive, name, objectType, routerUrl } = item;

  const mappedAvatar =
    dataTypes?.objectType === 'user' || objectType === 'user'
      ? {
          guid,
          isActive,
          isDeleted: false,
          name,
          src: avatar,
        }
      : undefined;

  return (
    <StyledSearchResultCell to={routerUrl || '#'}>
      <SearchResultPath>
        <Tooltip content={getIconTooltipText(item)}>
          <SearchTopIcon dataTypes={dataTypes} mr={0.5} />
        </Tooltip>
        <Breadcrumbs
          filterValue={searchText}
          fontSize="12px"
          fontWeight={500}
          items={item.breadcrumbList}
        />
      </SearchResultPath>
      <SearchResultTitleRow isSearchResultCell>
        <Tooltip content={getIconTooltipText(item)}>
          <span>
            <SearchResultIcon
              dataTypes={dataTypes}
              guid={guid}
              mappedAvatar={mappedAvatar}
              name={name}
            />
          </span>
        </Tooltip>
        <SearchResultTitle isSearchResultCell>
          <Tooltip content={name}>
            <div>
              <Highlighter autoEscape searchWords={searchWords} textToHighlight={name} />
            </div>
          </Tooltip>
        </SearchResultTitle>
      </SearchResultTitleRow>
    </StyledSearchResultCell>
  );
});

export default SearchResultCell;
