import React from 'react';

import { useFetchDashboardElements } from '@api/dashboards';
import { DashboardElementModel } from '@api/dashboards/DashboardElementModel';
import BulkButtons from '@components/BulkButtons';
import TabError from '@components/TabContent/TabError';
import { TabContentProps } from '@components/Tabs/types';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import useBulkEditSelected from '@hooks/useBulkEditSelected';
import stripSpaces from '@utils/stripSpaces';

import TileTable from './TileTable';

const query = stripSpaces(`{
  guid,
  external_id,
  name,
  description,
  richtext_description,
  data_type,
  data_types,
  external_type,
  search_name,
  url,
  tagged_items,
  description_source,
  ingested_description,
  ai_description,
  user_description
}`);

interface DashboardTilesProps extends TabContentProps {
  guid: string;
  isDataSourceEditable: boolean;
}

const DashboardTiles: React.FC<DashboardTilesProps> = ({ guid, isDataSourceEditable }) => {
  const { isEditable: isObjectEditable } = useObjectPermissionsContext({ id: guid });
  const { data, error, isLoading } = useFetchDashboardElements(guid, {
    enabled: Boolean(guid),
    keepPreviousData: true,
    params: {
      order: 'name',
      query,
    },
  });

  const { selected } = useBulkEditSelected<DashboardElementModel>({
    defaultSelected: data?.results,
  });

  if (error) return <TabError />;

  return (
    <>
      <BulkButtons
        canUseBulkAssist={isObjectEditable}
        guid={guid}
        isDataSourceEditable
        selectedEditableItems={selected.items}
        selectedItems={selected.items}
      />
      <TileTable
        data={data?.results}
        isDataSourceEditable={isDataSourceEditable}
        isLoading={isLoading}
        itemCount={data?.count}
      />
    </>
  );
};

export default React.memo(DashboardTiles);
