import React from 'react';
import { LOADING_TEXT } from '@constants';

import HR from '@components/UI/HR';
import Select, { SelectValue } from '@components/UI/Select';
import { NoResultsMessage } from '@components/UI/Select/OptionsList/OptionsList.styles';
import type { SelectProps } from '@components/UI/Select/Select';
import type { Option } from '@components/UI/Select/types';

export interface SearchProps
  extends Pick<
    SelectProps,
    | 'error'
    | 'label'
    | 'helperText'
    | 'options'
    | 'placeholder'
    | 'isDisabled'
    | 'isLoading'
    | 'searchValue'
    | 'renderCustomOption'
    | 'optionsListHeaderElement'
    | 'disableFiltering'
    | 'isOpen'
    | 'setIsOpen'
    | 'leftIcon'
    | 'rightIcon'
    | 'showOptionsOnSearch'
    | 'optionListMaxHeight'
    | 'showClearButton'
    | 'allowEmptySelection'
    | 'highlightFirstOnOptionsUpdate'
    | 'closeOnScroll'
  > {
  onResultSelect?: (option: Option) => void;
  onSearchChange?: SelectProps['onSearchValueChange'];
}

const Search: React.FC<SearchProps> = ({
  disableFiltering = true,
  isLoading,
  onResultSelect,
  onSearchChange,
  optionListMaxHeight = '250px',
  optionsListHeaderElement,
  placeholder = 'Search...',
  showClearButton = true,
  showOptionsOnSearch = true,
  ...other
}) => {
  const handleChange = (newOption: SelectValue) => {
    const [selectedItem] = newOption ?? [];
    onResultSelect?.(selectedItem);
  };

  return (
    <Select
      disableFiltering={disableFiltering}
      fixedClearButton
      hideCaret
      isDropdown={false}
      isLoading={isLoading}
      isMulti
      onChange={handleChange}
      onSearchValueChange={onSearchChange}
      optionListMaxHeight={optionListMaxHeight}
      optionsFitAnchorWidth
      optionsListHeaderElement={optionsListHeaderElement}
      placeholder={placeholder}
      renderEmptyMessage={() => (
        <>
          <HR mb={0} mt={0} />
          <NoResultsMessage>{isLoading ? LOADING_TEXT : 'No matches'}</NoResultsMessage>
        </>
      )}
      shouldBlockOnLoading={false}
      showClearButton={showClearButton}
      showOptionsOnSearch={showOptionsOnSearch}
      showSelectAllButton={false}
      value={[]}
      {...other}
    />
  );
};

export default Search;
