import stripSpaces from '@utils/stripSpaces';

export default stripSpaces(`{
  guid,
  name,
  description,
  search_name,
  richtext_description,
  suggested_description,
  suggested_description_source,
  suggested_description_source_object,
  row_count,
  bytes,
  table_type,
  data_types,
  database {
    guid,
    name,
    data_source {
      guid,
      name,
      type
    }
  },
  schema {
    guid,
    name
  },
  created_by,
  dsuser_created_by {
    guid,
    data_source,
    name,
    display_name
  },
  created_on,
  last_updated_on,
  db_created_on,
  technical_owner {
    object_type,
    obj
  },
  business_owner {
    object_type,
    obj
  },
  popularity,
  tagged_items,
  last_refreshed_on,
  extra,
  downstream_objects_counts,
  upstream_objects_counts,
  cloud_object,
  breadcrumbs,
  description_source,
  ingested_description,
  ai_description,
  user_description
}`);
