import { EdgeType } from '@api/lineage/types';

import { TableLink, TableRichGraph, TableRichGraphItem, TablesMap } from '../../types';

interface GetCoordinatesArgs {
  rectHeight: number;
  rectWidth: number;
  rootY: number;
  smallDistanceForArrowHead: number;
  tableData: TableRichGraphItem;
  targetTable: TableRichGraphItem;
  targetY: number;
  x: number;
  xSpacing: number;
}

const getCoordinates = {
  children: ({
    rectHeight,
    rectWidth,
    rootY,
    smallDistanceForArrowHead,
    tableData,
    targetTable,
    targetY,
    x,
    xSpacing,
  }: GetCoordinatesArgs) => {
    const childDelta = targetTable.stackPos - tableData.stackPos;

    let coordinates: [number, number][] = [
      [x + rectWidth, rootY],
      [x + rectWidth + xSpacing / 2, rootY],
      [x + rectWidth + xSpacing / 2, targetY],
      [x + rectWidth + xSpacing - smallDistanceForArrowHead, targetY],
    ];

    if (childDelta === 0) {
      coordinates = [
        [x + rectWidth, rootY],
        [x + rectWidth + xSpacing / 2, rootY],
        [x + rectWidth + xSpacing / 2, targetY],
        [x + rectWidth + smallDistanceForArrowHead, targetY],
      ];
    }

    if (childDelta < 0 || childDelta > 1) {
      coordinates = [
        [x + rectWidth, rootY],
        [x + rectWidth + xSpacing / 2, rootY],
        [x + rectWidth + xSpacing / 2, 0 - rectHeight],
        [targetTable.x - xSpacing / 2, 0 - rectHeight],
        [targetTable.x - xSpacing / 2, targetY],
        [targetTable.x - smallDistanceForArrowHead, targetY],
      ];
    }

    return coordinates;
  },
  parents: ({
    rectHeight,
    rectWidth,
    rootY,
    smallDistanceForArrowHead,
    tableData,
    targetTable,
    targetY,
    x,
    xSpacing,
  }: GetCoordinatesArgs) => {
    const sameStack = tableData.stackPos === targetTable.stackPos;
    const parentToRight = tableData.stackPos < targetTable.stackPos;
    const parentTooFar = tableData.stackPos - targetTable.stackPos > 1;
    let coordinates: [number, number][] = [
      [targetTable.x + rectWidth, targetY],
      [x - xSpacing / 2, targetY],
      [x - xSpacing / 2, rootY],
      [x - smallDistanceForArrowHead, rootY],
    ];

    if (sameStack) {
      coordinates = [
        [x + rectWidth / 2, targetY],
        [x + rectWidth + xSpacing / 2, targetY],
        [x + rectWidth + xSpacing / 2, rootY],
        [x + rectWidth + smallDistanceForArrowHead, rootY],
      ];
    }

    if (parentToRight || parentTooFar) {
      coordinates = [
        [targetTable.x + rectWidth, targetY],
        [targetTable.x + rectWidth + xSpacing / 2, targetY],
        [targetTable.x + rectWidth + xSpacing / 2, 0 - rectHeight],
        [x - xSpacing / 2, 0 - rectHeight],
        [x - xSpacing / 2, rootY],
        [x - smallDistanceForArrowHead, rootY],
      ];
    }

    return coordinates;
  },
};

const getChildrenOrParentTablesGraph = (
  activeTableId: string | null,
  graph: TableRichGraph,
  relation: 'children' | 'parents',
  seenIds: string[] = [],
): TableRichGraph => {
  const output: TableRichGraph = {};

  if (!activeTableId) {
    return graph;
  }

  const activeTable = graph[activeTableId];

  if (activeTable) {
    output[activeTableId] = activeTable;

    activeTable[relation].forEach((t) => {
      if (!seenIds.includes(t) && graph[t] !== undefined) {
        output[t] = graph[t];
        seenIds.push(t);

        if (Array.isArray(graph[t]![relation])) {
          Object.assign(output, getChildrenOrParentTablesGraph(t, graph, relation, seenIds));
        }
      }
    });
  }

  return output;
};

interface GenerateParentTablesLinksArgs {
  activeTableId: string | null;
  filterByEdgeType?: EdgeType;
  graph: TableRichGraph;
  rectHeight: number;
  rectWidth: number;
  relation: 'parents' | 'children';
  smallDistanceForArrowHead: number;
  tablesById: TablesMap;
}

const generateTablesLinksCoordinates = ({
  activeTableId,
  filterByEdgeType,
  graph,
  rectHeight,
  rectWidth,
  relation,
  smallDistanceForArrowHead,
  tablesById,
}: GenerateParentTablesLinksArgs): TableLink[] => {
  const xSpacing = rectWidth / 5;
  const output: TableLink[] = [];
  const seenIds: { [key: string]: string[] } = {}; // store ID's of tables that already have links to avoid loops
  const graphByRelation = getChildrenOrParentTablesGraph(activeTableId, graph, relation);

  Object.entries(graphByRelation).forEach(([tableId, tableData]) => {
    if (!tableData) return;

    const { x, y } = tableData;
    const rootY = y + rectHeight / 2; // y of parent table

    seenIds[tableId] = seenIds[tableId] || [];

    for (let i = 0; i < tableData[relation].length; i += 1) {
      const targetTableId = tableData[relation][i];
      const targetTable = graph[targetTableId];

      if (!targetTable || seenIds[tableId].includes(targetTableId) || !graph[targetTableId]) {
        continue;
      }
      const { sourceEdges, targetEdges } = tablesById[tableId] || {};
      const targetEdge =
        relation === 'children' ? targetEdges?.[targetTableId] : sourceEdges?.[targetTableId];
      const edgeType = targetEdge?.edge_type ?? 'derived';
      const targetY = targetTable.y + rectHeight / 2;
      const coordinates = getCoordinates[relation]({
        rectHeight,
        rectWidth,
        rootY,
        smallDistanceForArrowHead,
        tableData,
        targetTable,
        targetY,
        x,
        xSpacing,
      });

      if (!filterByEdgeType) {
        output.push({ coordinates, edgeType, tableId, targetTableId });
      }

      if (filterByEdgeType && filterByEdgeType === edgeType) {
        output.push({ coordinates, edgeType, tableId, targetTableId });
      }

      seenIds[tableId].push(targetTableId);
    }
  });

  return output;
};

export default generateTablesLinksCoordinates;
