import { DashboardUserQuerySummaryModel } from '@api/dashboards/DashboardUserQuerySummaryModel';
import { DsUserModel } from '@api/dsusers/DsUserModel';
import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import { PaginatedResponse } from '@models/PaginatedResponse';
import { TableQueryModel } from '@models/TableQueryModel';

import paginatedTransform from '../paginatedTransform';

import { EmployeeRoleModel } from './EmployeeRoleModel';

export const usersCacheKeys = {
  employeeRoles: ['employeeRoles'],
  userDSUsers: (id: string) => [...usersCacheKeys.userDSUsersBase, id],
  userDSUsersBase: ['users', 'dsusers'],
  userMostQueriedDashboards: (id: string) => ['users', 'userMostQueriedDashboards', id],
  userMostQueriedTables: (id: string) => ['users', 'userMostQueriedTables', id],
  users: ['users'],
  usersCsv: ['users', 'csv'],
};

const fetchUserMostQueriedDashboardsSelect = paginatedTransform(DashboardUserQuerySummaryModel);
export const useFetchUserMostQueriedDashboards = (
  id: string,
  options?: UseFetchOptions<PaginatedResponse<DashboardUserQuerySummaryModel>>,
) => {
  return useFetch<PaginatedResponse<DashboardUserQuerySummaryModel>>({
    ...options,
    queryKey: [...usersCacheKeys.userMostQueriedDashboards(id), options?.params],
    select: fetchUserMostQueriedDashboardsSelect,
    url: `/users/${id}/most-queried-dashboards/`,
  });
};

const fetchUserMostQueriedTablesSelect = paginatedTransform(TableQueryModel);
export const useFetchUserMostQueriedTables = (
  id: string,
  options?: UseFetchOptions<PaginatedResponse<TableQueryModel>>,
) => {
  return useFetch<PaginatedResponse<TableQueryModel>>({
    ...options,
    queryKey: [...usersCacheKeys.userMostQueriedTables(id), options?.params],
    select: fetchUserMostQueriedTablesSelect,
    url: `/users/${id}/most-queried-tables/`,
  });
};

const fetchUserDSUsersSelect = paginatedTransform(DsUserModel);
export const useFetchUserDSUsers = (
  id: string,
  options?: UseFetchOptions<PaginatedResponse<DsUserModel>>,
) => {
  return useFetch<PaginatedResponse<DsUserModel>>({
    ...options,
    queryKey: [...usersCacheKeys.userDSUsers(id), options?.params],
    select: fetchUserDSUsersSelect,
    url: `/users/${id}/dsusers/`,
  });
};

export const useFetchUsersCsv = (options?: UseFetchOptions) => {
  return useFetch({
    ...options,
    queryKey: [...usersCacheKeys.usersCsv, options?.params],
    url: '/users/csv/',
  });
};

export const fetchUsersEmployeeRolesSelect = paginatedTransform(EmployeeRoleModel);
export const useFetchUsersEmployeeRoles = (
  options?: UseFetchOptions<PaginatedResponse<EmployeeRoleModel>>,
) => {
  return useFetch<PaginatedResponse<EmployeeRoleModel>>({
    ...options,
    queryKey: [...usersCacheKeys.employeeRoles, options?.params],
    select: fetchUsersEmployeeRolesSelect,
    url: '/users/employee-roles/',
  });
};
