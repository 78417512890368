import React from 'react';

import { useFetchCostAnalysisTotal } from '@api/costAnalysis';
import { useDateRangePickerContext } from '@components/DateRangePicker/DateRangePicker.context';
import CostByWarehouseTable from '@components/Table/CostByWarehouseTable';
import HR from '@components/UI/HR';
import StatSummaryBar from '@components/UI/StatSummaryBar';

import CostAnalysisSharedFilter from '../CostAnalysisSharedFilter';
import useWarehouseSelect from '../CostAnalysisSharedFilter/useWarehouseSelect';

import SectionTitle, { SectionTitleWrapper } from './SectionTitle';

const CostAnalysisWarehouseTab: React.FC = () => {
  const { dateRange } = useDateRangePickerContext();
  const { selectedWarehouses } = useWarehouseSelect();

  const { data: totalData, isLoading } = useFetchCostAnalysisTotal({
    params: {
      end_date: dateRange[1],
      start_date: dateRange[0],
      warehouses: selectedWarehouses,
    },
  });

  return (
    <>
      <SectionTitleWrapper my={2.5}>
        <SectionTitle subtitle="Warehouse by cost" title="Summary" />
        <CostAnalysisSharedFilter />
      </SectionTitleWrapper>
      <StatSummaryBar
        data={[
          {
            description: 'Warehouses',
            tooltip: 'Count of warehouses with >0 credit usage',
            value: totalData?.warehouse?.formattedActive,
          },
          {
            description: 'Total Spend (Credit)',
            tooltip: 'Total compute spend credit from warehouses',
            value: totalData?.warehouse?.formattedCreditsUsed,
          },
          {
            description: 'Total Query Count',
            tooltip: 'Count of successful queries from warehouses',
            value: totalData?.run?.formattedTotal,
          },
          {
            description: 'Avg Cost/Query',
            tooltip: 'Total compute spend credit over count of successful queries',
            value: totalData?.warehouse?.formattedAverageCreditsUsed,
          },
        ]}
        loading={isLoading}
      />
      <HR />
      <CostByWarehouseTable range={dateRange} warehouses={selectedWarehouses} />
    </>
  );
};

export default CostAnalysisWarehouseTab;
