import styled from '@emotion/styled';

import InputLabel from '@components/UI/Form/InputLabel';

export const StyledInviteUserModalFieldLabel = styled(InputLabel)``;

StyledInviteUserModalFieldLabel.defaultProps = {
  alignItems: 'baseline',
  as: 'div' as const,
  color: 'gray.700',
  compDisplay: 'grid',
  cursor: 'default',
  fontWeight: 'medium',
  gap: 2,
  gridTemplateColumns: '40px 1fr',
};
