import React from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';
import { Label } from 'semantic-ui-react';

import { Step } from '@components/DataSourceSetup/steps';
import Icon from '@components/UI/Icon';

interface Props {
  activeStep: Step<any>;
  steps: Step<any>[];
}

const SidebarStepsStyled = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
  font-size: 0.8125rem;
  padding: ${({ theme }) => theme.space(2, 3)};

  .step {
    align-items: center;
    display: flex;
    margin: 0.5rem 0;

    &.done {
      .label {
        position: relative;
        background-color: #7490be;
        &::after {
          content: '';
          display: block;
          position: absolute;
          bottom: -0.5rem;
          left: 11px;
          width: 0.125rem;
          height: 0.5rem;
          background-color: #7490be;
        }
      }
    }
    &.active {
      .label {
        background-color: #0c3476;
        border-color: #0c3476;
      }
    }

    .label {
      margin-right: 0.5rem;

      font-size: 0.75rem;
      color: #ffffff;

      background-color: #cacfd6;
    }
  }
`;
const SidebarSteps: React.FC<Props> = ({ activeStep, steps }) => {
  return (
    <SidebarStepsStyled>
      {steps
        .filter((step) => !step.hidden)
        .map((step, index) => {
          const activeIdx = steps.indexOf(activeStep);
          return (
            <div
              key={step.key}
              className={clsx('step', activeStep === step && 'active', activeIdx > index && 'done')}
            >
              <Label
                circular
                content={
                  activeIdx > index ? (
                    <Icon color="currentColor" name="check" size="12px" />
                  ) : (
                    index + 1
                  )
                }
              />
              <span className="text">{step.name}</span>
            </div>
          );
        })}
    </SidebarStepsStyled>
  );
};

export default SidebarSteps;
