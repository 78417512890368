import React, { Dispatch } from 'react';

import { SearchModel } from '@api/search/SearchModel';
import type { SearchResult } from '@api/search/types';
import Box from '@components/Box';
import type { MentionType } from '@components/Mention/Mention.types';
import Popper from '@components/Popper';
import {
  DatasourceTabV1 as DatasourceTabV1Type,
  tabConfigDefault,
} from '@components/SearchBar/DatasourceTabs/config';
import DatasourceTabsV1 from '@components/SearchBar/DatasourceTabs/DatasourceTabsV1';
import type { SearchFiltersType } from '@components/SearchBar/SearchTypes';
import Text, { defaultParagraphStyles } from '@components/Text';
import useClickOutside from '@hooks/useClickOutside';
import theme from '@styles/theme';

import MentionItem from './MentionItem';
import { MentionSuggestions } from './Mentions.styles';

interface MentionSuggestionsPopupProps {
  index: number;
  isOpen?: boolean;
  mentionSearchFacets?: SearchResult<SearchModel>['facets'];
  mentionSearchResult?: SearchResult<SearchModel>;
  onClickOutside?: () => void;
  onMentionSelect: (index: number) => void;
  position: { left: string; top: string } | null;
  searchTabs: DatasourceTabV1Type[];
  searchValue?: string;
  setFilters:
    | Dispatch<React.SetStateAction<SearchFiltersType>>
    | ((value: DatasourceTabV1Type['filters']) => void);
}

const MentionSuggestionsPopup: React.FC<MentionSuggestionsPopupProps> = ({
  index,
  isOpen,
  mentionSearchFacets,
  mentionSearchResult,
  onClickOutside,
  onMentionSelect,
  position,
  searchTabs = tabConfigDefault,
  searchValue,
  setFilters,
}) => {
  const useClickOutsideRef = useClickOutside<HTMLDivElement>({
    onClick: () => {
      onClickOutside?.();
    },
  });
  const { left, top } = position ?? {};

  return (
    <Popper
      anchorComp={<Box compHeight="24px" left={left} position="fixed" top={top} />}
      fallbackPlacements={['top-start', 'bottom-end', 'top-end']}
      isOpen={isOpen}
      offset={[0, 2]}
      placement="bottom-start"
    >
      <MentionSuggestions ref={useClickOutsideRef}>
        {!mentionSearchResult ? (
          <Box p={1}>
            <Text {...defaultParagraphStyles} color={theme.colors.gray[500]}>
              Type for suggestions...
            </Text>
          </Box>
        ) : (
          <>
            <DatasourceTabsV1
              data={mentionSearchResult}
              facets={mentionSearchFacets}
              onFilterChange={(f) => {
                setFilters(f);
              }}
              tabs={searchTabs}
            />
            <Box
              compHeight="auto"
              maxHeight="13rem"
              minHeight={0}
              overflowX="hidden"
              overflowY="auto"
            >
              {(mentionSearchResult.data as MentionType[]).map((mention, i) => (
                <MentionItem
                  key={mention.id || mention.guid}
                  activeIndex={index}
                  itemIndex={i}
                  mention={mention}
                  onMentionSelect={() => onMentionSelect(i)}
                  searchValue={searchValue}
                />
              ))}
            </Box>
          </>
        )}
      </MentionSuggestions>
    </Popper>
  );
};

export default MentionSuggestionsPopup;
