import React from 'react';

import { useFetchUserDSUsers } from '@api/users';
import Box from '@components/Box';
import { renderErrorToast } from '@components/Toast';
import Avatar from '@components/UI/Avatar';
import stripSpaces from '@utils/stripSpaces';

import { DSUserHeader, Section, SectionInfo, SectionRows } from './AccountSettingsPage.styles';

const query = stripSpaces(`{
  guid,
  data_source{
    name,
    type
  },
  display_name,
  name
}`);

interface ConnectedDSUsersProps {
  guid: string;
}

const ConnectedDSUsers: React.FC<ConnectedDSUsersProps> = ({ guid }) => {
  const { data } = useFetchUserDSUsers(guid, {
    onError: () => {
      renderErrorToast("Couldn't retrieve Data Source User information.");
    },
    params: {
      query,
    },
  });

  return data?.results && data.results.length > 0 ? (
    <Box mt={4}>
      <DSUserHeader>
        <span className="title small">Connected Accounts</span>
      </DSUserHeader>
      <SectionRows>
        {data.results.map((dsUser) => (
          <Section key={dsUser.guid}>
            {dsUser.dataSource?.name}
            <Box alignItems="center" compDisplay="flex">
              <Avatar {...dsUser.mappedAvatar} mr={0.5} size="20px" />
              <SectionInfo>{dsUser.name}</SectionInfo>
            </Box>
          </Section>
        ))}
      </SectionRows>
    </Box>
  ) : null;
};

export default ConnectedDSUsers;
