import React, { useMemo, useState } from 'react';
import type { FC } from 'react';
import type { Cell } from 'react-table';

import {
  piiRulesCacheKeys,
  useFetchPiiRules,
  usePatchPiiRule,
  usePatchPiiRules,
} from '@api/piiRules';
import type { PiiRuleModel } from '@api/piiRules/PiiRuleModel';
import Box from '@components/Box';
import Highlighter from '@components/Highlighter';
import IconButton from '@components/IconButton';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import UserCell from '@components/Table/Cells/UserCell';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import { renderInfoToast } from '@components/Toast';
import Toggle from '@components/Toggle';
import { useModal } from '@context/Modal';
import fetchClient from '@lib/fetchClient';
import type { AutomatedPIIPageModalState } from '@pages/AutomatedPIIPage';
import theme from '@styles/theme';
import { FilterOptions, setParams, useUpdateFilters } from '@utils/filters';
import stripSpaces from '@utils/stripSpaces';

import { Clickable } from './AutomatedPiiTable.styles';

const searchConfig: { [key: string]: keyof FilterOptions } = {
  createdBy: 'search_created_by',
  itemCount: 'search_item_count',
  name: 'search_name',
  type: 'search_type',
};

const sortConfig: { [key: string]: string } = {
  createdBy: 'created_by',
  itemCount: 'item_count',
  name: 'name',
  type: 'type',
};

const requestConfig: FilterOptions = {
  order: 'name',
  page: 1,
  page_size: 20,
  query: stripSpaces(`{
    guid,
    name,
    type,
    active,
    regex,
    item_count,
    created_by{
      guid,
      avatar,
      is_active,
      first_name,
      last_name
    }
  }`),
};

const getInitialState = (rules: PiiRuleModel[] = []): { [id: string]: boolean } =>
  Object.fromEntries(rules.map(({ active, guid }) => [guid, active]));

const getRowId = (row: Partial<PiiRuleModel>) => row.guid!;

interface AutomatedPiiTableProps {
  setModal?: (value: AutomatedPIIPageModalState) => void;
}

const AutomatedPiiTable: FC<AutomatedPiiTableProps> = ({ setModal }) => {
  const [isShowFilter, setShowFilter] = useState(false);
  const { MODAL_IDS, openModal } = useModal();

  const { changePage, filter, search, sort } = useUpdateFilters(
    requestConfig,
    searchConfig,
    sortConfig,
    'name',
  );

  const { data, isLoading } = useFetchPiiRules({
    keepPreviousData: true,
    params: setParams(filter),
  });

  const patchRuleQuery = usePatchPiiRule('', {
    onSuccess: (rule) => {
      renderInfoToast(
        `${rule.name} has been ${rule.active ? 'added to' : 'removed from'} PII Rules`,
      );
      fetchClient.invalidateQueries(piiRulesCacheKeys.piiRules, {
        refetchActive: false,
      });
    },
  });

  const patchRulesQuery = usePatchPiiRules({
    onSuccess: ({ active }) => {
      renderInfoToast(`All ${active ? 'added to' : 'removed from'} PII Rules`);
      fetchClient.invalidateQueries(piiRulesCacheKeys.piiRules, {
        refetchActive: false,
      });
    },
  });

  const totalPages = data && filter.page_size ? Math.ceil(data.count / filter.page_size) : 1;

  const columns: ColumnConfig<PiiRuleModel>[] = useMemo(
    () => [
      {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      {
        Cell: ({ column, row: { original: pii } }: Cell<PiiRuleModel>) => (
          <Clickable
            onClick={() => {
              setModal?.({ pii });
              openModal(MODAL_IDS.createPII);
            }}
          >
            <Highlighter autoEscape searchWords={[column?.filterValue]} textToHighlight={pii.name}>
              {pii.name}
            </Highlighter>
          </Clickable>
        ),
        Header: 'Rule Name',
        accessor: (d) => d?.name,
        id: 'name',
        width: '50%',
      },
      {
        Cell: ({ column, row: { original: pii } }: Cell<PiiRuleModel>) => (
          <Highlighter autoEscape searchWords={[column?.filterValue]} textToHighlight={pii.type}>
            {pii.type}
          </Highlighter>
        ),
        Header: 'Rule Type',
        accessor: (d) => d?.type,
        id: 'type',
        width: '50%',
      },
      {
        Cell: ({ column, row: { original: pii } }: Cell<PiiRuleModel>) => (
          <Clickable
            onClick={() => {
              setModal?.({ pii });
              openModal(MODAL_IDS.matchPII);
            }}
          >
            <Highlighter
              autoEscape
              searchWords={[column?.filterValue]}
              textToHighlight={String(pii.itemCount)}
            >
              {pii.itemCount}
            </Highlighter>
          </Clickable>
        ),
        Header: 'Matched Data',
        accessor: (d) => d?.itemCount,
        id: 'itemCount',
        width: '50%',
      },
      {
        Cell: (props: Cell<PiiRuleModel>) => (
          <UserCell {...props} user={props.row.original.createdBy} />
        ),
        Header: 'Creator',
        accessor: (d) => d?.createdBy?.fullName,
        id: 'createdBy',
        width: '50%',
      },
      {
        Cell: (props: Cell<PiiRuleModel>) => {
          const { row } = props;
          const { checked, onChange } = row.getToggleRowSelectedProps();

          return (
            <Box compDisplay="flex" justifyContent="center">
              <Toggle
                checked={checked}
                name={`toggle ${row.original.name}`}
                onChange={(e) => {
                  patchRuleQuery.mutate({
                    active: e.target.checked,
                    httpClientUrl: `/pii/${row.original.guid}/`,
                  });
                  onChange?.(e);
                }}
              />
            </Box>
          );
        },

        Header: (props) => {
          const { getToggleAllRowsSelectedProps } = props;
          const { checked, onChange } = getToggleAllRowsSelectedProps();

          return (
            <Box alignItems="center" compDisplay="flex" gap={0.5} justifyContent="center">
              Active
              <Toggle
                checked={checked}
                name="all"
                onChange={(e) => {
                  patchRulesQuery.mutate({
                    active: e.target.checked,
                    items: data?.results.map((rule) => rule.guid),
                  });
                  onChange?.(e);
                }}
              />
            </Box>
          );
        },
        disableResizing: true,
        disableSortBy: true,
        id: 'toggle',
        width: '7rem',
      },
      {
        Cell: ({ row: { original: pii } }: Cell<PiiRuleModel>) => (
          <IconButton
            iconColor={theme.colors.v1.gray[600]}
            iconName="edit-outline"
            iconSize="20px"
            onClick={() => {
              setModal?.({ pii });
              openModal(MODAL_IDS.createPII);
            }}
            variant="clear"
            verticalAlign="middle"
          />
        ),
        disableResizing: true,
        disableSortBy: true,
        id: 'edit',
        width: '3rem',
      },
      {
        Cell: ({ row: { original: pii } }: Cell<PiiRuleModel>) => (
          <IconButton
            iconColor={theme.colors.v1.gray[600]}
            iconName="trash"
            iconSize="20px"
            onClick={() => {
              setModal?.({ pii });
              openModal(MODAL_IDS.deletePII);
            }}
            variant="clear"
            verticalAlign="middle"
          />
        ),
        disableResizing: true,
        disableSortBy: true,
        id: 'delete',
        width: '3rem',
      },
    ],
    [MODAL_IDS, data?.results, openModal, patchRuleQuery, patchRulesQuery, setModal],
  );

  return (
    <TableStyled>
      <Table
        basic="very"
        changePage={changePage}
        columns={columns}
        compact
        data={data?.results ?? []}
        disableColumnFiltering
        disableRowSelect
        emptyMessage="No Rule is created yet."
        getRowId={getRowId}
        initialState={{
          selectedRowIds: getInitialState(data?.results),
          sortBy: [{ desc: false, id: 'name' }],
        }}
        loading={isLoading}
        manualFilters
        manualPagination
        manualRowSelect
        manualSortBy
        setFilters={search}
        setSortBy={sort}
        showFilter={isShowFilter}
        sortable
        toggleFilter={() => {
          setShowFilter((prev) => !prev);
        }}
        totalPages={totalPages}
        unstackable
      />
    </TableStyled>
  );
};

export default AutomatedPiiTable;
