const getParentName = (name: string, fullPath?: string) => {
  if (!fullPath) {
    return '';
  }

  const itemNameIndex = fullPath!.indexOf(name);
  if (itemNameIndex === 0) {
    return '';
  }
  return fullPath?.slice(0, itemNameIndex - 1);
};

export default getParentName;
