import React, { useState } from 'react';
import { Cell } from 'react-table';

import { CommentModel } from '@api/comments/CommentModel';
import DateTime from '@components/DateTime';
import type { ColumnConfig } from '@components/Table/Table/types';
import { Filter } from '@utils';
import { urlFor } from '@utils/routing';

import CommentCell from '../Cells/CommentCell';
import LinkedCell from '../Cells/LinkedCell';
import SearchHeader from '../Cells/SearchHeader';
import UserCell from '../Cells/UserCell';
import Table from '../Table';
import TableStyled from '../TableStyled';

const initialSortState = [
  {
    desc: true,
    id: 'lastEdited',
  },
];

interface Props {
  comments?: CommentModel[];
  filterService: Filter.FilterServiceInterface;
  itemCount?: number;
  totalPages: number;
}

const AdminDiscussionTable: React.FC<Props> = ({
  comments,
  filterService,
  itemCount = 0,
  totalPages,
}) => {
  const [isShowFilter, setShowFilter] = useState(false);
  const toggleFilter = React.useCallback(() => setShowFilter((prev) => !prev), [setShowFilter]);

  const { changePage, filter, search, sort } = filterService;

  const columns: ColumnConfig<CommentModel>[] = React.useMemo(
    () => [
      {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      {
        Cell: (props: Cell<CommentModel>) => {
          const comment = props.row.original;
          const target = comment.target.obj;
          const url = urlFor(target, true);
          const customUrl = `${url}/discussion`;
          return <LinkedCell {...props} customUrl={customUrl} item={target} showIcon />;
        },
        Header: 'Data Asset',
        accessor: (d) => d.target?.obj.name,
        disableHiding: true,
        disableSortBy: true,
        id: 'target',
        width: '20%',
      },
      {
        Cell: ({ row }: Cell<CommentModel>) => {
          const comment = row.original;
          return <CommentCell comment={comment} />;
        },
        Header: `Comment (${itemCount})`,
        accessor: (d) => d.message,
        disableSortBy: true,
        id: 'comment',
        width: '50%',
      },
      {
        Cell: (props: Cell<CommentModel>) => {
          const comment = props.row.original;
          return <UserCell {...props} user={comment.commentedBy} />;
        },
        Header: 'User',
        accessor: (d) => d.commentedBy,
        disableSortBy: true,
        id: 'user',
        width: '20%',
      },
      {
        Cell: (props: Cell<CommentModel>) => {
          const comment = props.row.original;
          return <DateTime datetime={comment.commentedOn} />;
        },
        Header: 'Last Edited',
        accessor: (d) => d.commentedOn,
        disableFilters: true,
        id: 'lastEdited',
        sortDescFirst: true,
        width: '10%',
      },
    ],
    [itemCount],
  );

  return (
    <TableStyled>
      <Table
        basic="very"
        changePage={changePage}
        className="table-full"
        columns={columns}
        compact
        data={comments || []}
        disableColumnFiltering
        disableRowSelect
        initialState={{
          pageIndex: filter.page ? filter.page - 1 : 0,
          sortBy: initialSortState,
        }}
        loading={comments === undefined}
        manualFilters
        manualPagination
        manualSortBy
        setFilters={search}
        setSortBy={sort}
        showFilter={isShowFilter}
        sortable
        toggleFilter={toggleFilter}
        totalPages={totalPages}
      />
    </TableStyled>
  );
};

export default AdminDiscussionTable;
