import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react';

import type { Option } from './types';

interface ContextValue {
  selectedItems: Option[];
  setSelectedItems: (options: Option[]) => void;
}
const defaultContextValue: ContextValue = {
  selectedItems: [],
  setSelectedItems: () => {},
};

export const MultiSelectContext = createContext(defaultContextValue);

export const useMultiSelectContext = () => {
  const context = useContext(MultiSelectContext);
  if (context === undefined) {
    throw new Error('useMultiSelectContext missing its provider');
  }

  const { selectedItems } = context;
  return { selectedItems };
};

interface MultiSelectProviderProps {
  children: ReactNode;
}

export const MultiSelectProvider = ({ children }: MultiSelectProviderProps) => {
  const [selectedItems, setSelectedItems] = useState<Option[]>([]);

  const value = useMemo(
    () => ({
      selectedItems,
      setSelectedItems,
    }),
    [selectedItems, setSelectedItems],
  );

  return <MultiSelectContext.Provider value={value}>{children}</MultiSelectContext.Provider>;
};
