import React from 'react';

import Text from '@components/Text';

const FormLabel: React.FC = ({ children }) => (
  <Text fontSize="14px" fontWeight="bold" lineHeight="16px" m={0} textAlign="left">
    {children}
  </Text>
);

export default FormLabel;
