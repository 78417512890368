import styled from '@emotion/styled';

import Textarea from '@components/UI/Form/Textarea';

import { EDITOR_MIN_HEIGHT } from '../MarkdownRichTextEditor.styles';
import { MarkdownRichTextEditorProps } from '../MarkdownRichTextEditor.types';

export const StyledTextarea = styled(Textarea)<Pick<MarkdownRichTextEditorProps, 'variant'>>`
  min-height: ${({ variant }) => EDITOR_MIN_HEIGHT[variant!]};
  height: auto;
  padding: ${({ theme, variant }) => (variant === 'inline' ? theme.space(1) : 0)};
  font-size: ${({ theme, variant }) =>
    variant === 'inline' ? theme.typography.fontSizes.body1 : theme.typography.fontSizes.body2};
`;
