import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import type { UseMutationOptions } from '@hooks/useMutation';
import useMutation from '@hooks/useMutation';
import { BillingCustomerModel } from '@models/BillingCustomerModel';

import rawTransform from './rawTransform';

export const billingCacheKeys = {
  customer: ['customer'],
  portal: ['portal'],
};

interface CreateCheckoutResponse {
  session_id?: string;
}

interface CreateCustomerPortalResponse {
  session_url?: string;
}

export const usePostBillingSession = (options?: UseMutationOptions) => {
  return useMutation<CreateCheckoutResponse>({
    ...options,
    url: '/billing/create-checkout-session/',
    method: 'POST',
  });
};

export const usePostCustomerPortal = (options?: UseFetchOptions<CreateCustomerPortalResponse>) => {
  return useFetch<CreateCustomerPortalResponse>({
    ...options,
    url: '/billing/create-customer-portal/',
    method: 'POST',
    queryKey: [...billingCacheKeys.portal, options?.params],
    onSuccess: (data) => {
      options?.onSuccess?.(data);
    },
  });
};

export const useFetchBillingCustomer = (options?: UseFetchOptions<BillingCustomerModel>) => {
  return useFetch<BillingCustomerModel>({
    ...options,
    retry: false,
    queryKey: [...billingCacheKeys.customer, options?.params],
    url: `/billing/customer/default/`,
    select: rawTransform(BillingCustomerModel),
  });
};
