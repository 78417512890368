import React from 'react';

import Button from '@components/Button';
import AddCollectionDataAssetsModal from '@components/Modal/AddCollectionDataAssetsModal';
import { AddCollectionDataAssetsModalProps } from '@components/Modal/AddCollectionDataAssetsModal/AddCollectionDataAssetsModal';
import Icon from '@components/UI/Icon';
import { useModal } from '@context/Modal';

interface AssetsMenuProps extends Pick<AddCollectionDataAssetsModalProps, 'collection'> {}

const AssetsMenu: React.FC<AssetsMenuProps> = ({ collection }) => {
  const { MODAL_IDS, checkModalOpened, closeModal, openModal } = useModal();

  const handleAddAssetClick = () => {
    openModal(MODAL_IDS.addCollectionDataAssets);
  };

  return (
    <>
      <Button
        compSize="xxs"
        onClick={handleAddAssetClick}
        startIcon={<Icon name="plus" />}
        variant="outlined"
      >
        Add Asset
      </Button>
      {checkModalOpened(MODAL_IDS.addCollectionDataAssets) && (
        <AddCollectionDataAssetsModal
          collection={collection}
          onClose={() => {
            closeModal(MODAL_IDS.addCollectionDataAssets);
          }}
        />
      )}
    </>
  );
};

export default AssetsMenu;
