import styled from '@emotion/styled';

import { Row } from '@components/Grid';

export const StyledSidebarMetadataObjectDetails = styled(Row)`
  height: auto;

  ${({ theme }) => theme.media.up('md')`
    > :not(style) + :not(style) {
       margin: ${theme.space(5, 0, 0, 0)};
    }
  `}
`;
