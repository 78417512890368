import React from 'react';
import { Cell } from 'react-table';

import AnalyticsSearchTermModel from '@api/analytics/AnalyticsSearchTermModel';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import { FilterServiceInterface } from '@utils/filters';

interface TopSearchTermsTableProps {
  detailed?: boolean;
  filterService?: FilterServiceInterface;
  loading: boolean;
  rows?: AnalyticsSearchTermModel[];
  totalPages?: number;
}

const TopSearchTermsTable: React.FC<TopSearchTermsTableProps> = ({
  detailed = false,
  filterService,
  loading,
  rows = [],
  totalPages,
}) => {
  const columns: ColumnConfig<AnalyticsSearchTermModel>[] = [
    {
      Cell: (props: Cell<AnalyticsSearchTermModel>) => {
        const { row } = props;
        return <>{row.original.term}</>;
      },
      Header: 'Term',
      accessor: (d) => d.term,
      disableFilters: true,
      disableHiding: true,
      id: 'term',
      width: '50%',
    },
    {
      Cell: (props: Cell<AnalyticsSearchTermModel>) => {
        const { row } = props;
        return <>{row.original.count}</>;
      },
      Header: 'Count',
      accessor: (d) => d.count,
      disableFilters: true,
      disableHiding: true,
      id: 'count',
      width: '50%',
    },
  ];

  return (
    <TableStyled>
      <Table
        basic="very"
        changePage={filterService?.changePage}
        className="table-full"
        columns={columns}
        compact
        data={rows}
        disableColumnFiltering
        disableFilters
        disablePagination={!detailed}
        disableRowSelect
        disableSortBy={!detailed}
        initialState={{
          pageIndex: filterService ? filterService!.filter.page! - 1 : 0,
          sortBy: [
            {
              desc: true,
              id: 'count',
            },
          ],
        }}
        loading={loading}
        manualPagination={detailed}
        manualSortBy={detailed}
        setSortBy={filterService?.sort}
        sortable={detailed}
        stickyHeader={detailed}
        totalPages={totalPages}
        unstackable
      />
    </TableStyled>
  );
};

export default TopSearchTermsTable;
