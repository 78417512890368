import React from 'react';

import Button from '@components/Button/Button';
import CircularLoader from '@components/CircularLoader';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';

interface ExportToCSVButtonProps {
  isLoading?: boolean;
  onClick: () => void;
}

const ExportToCsvButton = ({ isLoading, onClick }: ExportToCSVButtonProps) => {
  return (
    <Tooltip action content="Export to CSV">
      <Button
        disabled={isLoading}
        name="Export to CSV"
        onClick={onClick}
        px={1.25}
        variant="outlined"
      >
        {isLoading ? (
          <CircularLoader borderWidth={1} compSize={2} />
        ) : (
          <Icon name="export" size="16px" />
        )}
      </Button>
    </Tooltip>
  );
};

export default ExportToCsvButton;
