import styled from '@emotion/styled';

import Box from '@components/Box';
import rootTheme from '@styles/theme';

import { StyledPrimarySidebarLink } from '../PrimarySidebar/PrimarySidebar.styles';

interface StyledProfileMenuProps {
  isActive?: boolean;
}

export const StyledProfileMenu = styled(Box)<StyledProfileMenuProps>`
  transition: all 0.15s ease;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.v1.primary[100] : theme.colors.white};
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.v1.primary[500] : theme.colors.v1.gray[700]};
  &:hover {
    color: ${({ isActive, theme }) =>
      isActive ? theme.colors.v1.primary[500] : theme.colors.v1.gray[700]};
    background-color: ${({ isActive, theme }) =>
      isActive ? theme.colors.v1.primary[100] : theme.colors.v1.gray[100]};
  }
`;

StyledProfileMenu.defaultProps = {
  ...StyledPrimarySidebarLink.defaultProps,
  borderRadius: 'md',
  compHeight: rootTheme.space(4.5),
  compWidth: 'auto',
  cursor: 'pointer',
  fontSize: 'body2',
  fontWeight: 'medium',
  gap: 1,
  px: 1,
  textAlign: 'left',
};
