import React from 'react';

import Box from '@components/Box';

import type { OverviewTabConfigItem } from './config';
import OVERVIEW_TAB_CONFIG from './config';
import OverviewContent, { OverviewContentProps } from './OverviewContent';
import { OverviewTabSidebar, StyledOverviewTab } from './OverviewTab.styles';

interface OverviewTabProps extends Omit<OverviewContentProps, 'sections' | 'config'> {
  enableSidebarMetadataObjectDetails?: boolean;
  renderBelowEditor?: () => React.ReactNode;
  showCustomAttributes?: boolean;
}

const OverviewTab: React.FC<OverviewTabProps> = ({
  children,
  data,
  enableSidebarMetadataObjectDetails = true,
  renderBelowEditor,
  showCustomAttributes,
  ...contentProps
}) => {
  const config = data?.dataTypes?.findConfig<OverviewTabConfigItem>(OVERVIEW_TAB_CONFIG);

  const enabledOverviewSections = [
    'details',
    ...(showCustomAttributes ? ['customAttributes'] : []),
    'description',
    ...(config?.showIndexes ? ['indexes'] : []),
    ...(config?.showDbt ? ['dbt'] : []),
  ] as OverviewContentProps['sections'];

  return (
    <StyledOverviewTab compDisplay="flex" mt={-0.125}>
      <Box
        compDisplay="flex"
        flexDirection="column"
        flexGrow={1}
        gap={2}
        minWidth="300px"
        mt={2}
        pr={2}
      >
        <OverviewContent {...contentProps} data={data} sections={enabledOverviewSections} />
        {renderBelowEditor?.()}
      </Box>
      {enableSidebarMetadataObjectDetails && <OverviewTabSidebar>{children}</OverviewTabSidebar>}
    </StyledOverviewTab>
  );
};

export default OverviewTab;
