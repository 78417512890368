import { MarkerType, ProOptions } from 'reactflow';

import Edge from '../Edge';
import BINode from '../Nodes/BINode/BINode';
import ColumnNode from '../Nodes/ColumnNode';
import DatabaseNode from '../Nodes/DatabaseNode';
import SchemaNode from '../Nodes/SchemaNode';
import ShowMoreNode from '../Nodes/ShowMoreNode';
import TableNode from '../Nodes/TableNode';

export const nodeTypes = {
  bi: BINode,
  column: ColumnNode,
  database: DatabaseNode,
  schema: SchemaNode,
  showMore: ShowMoreNode,
  table: TableNode,
};

export const defaultEdgeOptions = {
  markerEnd: { type: MarkerType.Arrow },
  pathOptions: { offset: 5 },
  type: 'custom-edge',
};

export const edgeTypes = {
  'custom-edge': Edge,
};

export const proOptions: ProOptions = { account: 'paid-pro', hideAttribution: true };
