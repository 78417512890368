import React from 'react';
import type { TreeNodeProps as RcTreeNodeProps } from 'rc-tree';

import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import Icon from '@components/UI/Icon';

export interface RenderSwitcherIconProps extends RcTreeNodeProps {
  useNewLayout?: boolean;
}

const renderSwitcherIcon = ({ isLeaf, loading, useNewLayout }: RenderSwitcherIconProps) => {
  if (loading) {
    return (
      <Box mr={0.25}>
        <CircularLoader borderWidth={1} compSize={1.5} />
      </Box>
    );
  }

  if (isLeaf) {
    return null;
  }

  const chevronIcon = useNewLayout ? 'right-chevron' : 'caret-right';
  const chevronSize = useNewLayout ? '16px' : '14px';
  const chevronMarginLeft = useNewLayout ? 0 : -0.25;

  return <Icon ml={chevronMarginLeft} mr={0.5} name={chevronIcon} size={chevronSize} />;
};

export default renderSwitcherIcon;
