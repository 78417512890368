import { UseAvatarInitialsEnum } from '@api/openapi.generated';

const getNameLetter = (text: string, position = 0) => {
  const [name] = text.match(/[^'/`~!#*$@_%+=.,^&(){}[\]|;:"<>?\\]+/) ?? [];

  return name ? name[position] : '';
};

const getInitials = (name: string | undefined, initialsConfig?: UseAvatarInitialsEnum) => {
  if (!name) return '';
  const shouldUseFirstNameInitials = initialsConfig === 'korean';

  const splittedName = name.split(/\s/).filter((v) => !!v);
  const firstName = splittedName[0] ? splittedName[0] : '';
  const lastName = splittedName.length > 1 ? splittedName[splittedName.length - 1] : '';
  const firstNameInitial = getNameLetter(firstName);
  const firstNameSecondLetter = getNameLetter(firstName, 1);
  const lastNameInitial = getNameLetter(lastName);

  const initials = [firstNameInitial];

  if (shouldUseFirstNameInitials) {
    initials.push(firstNameSecondLetter);
  } else {
    initials.push(lastNameInitial);
  }

  return initials.join('').toUpperCase();
};

export default getInitials;
