import React from 'react';

import Box from '@components/Box';
import Toggle from '@components/Toggle';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';

import { StyledRelevantLineageToggleText } from './RelevantLineageToggle.styles';

interface RelevantLineageToggleProps {
  checked?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const RelevantLineageToggle = ({ checked, onChange }: RelevantLineageToggleProps) => {
  return (
    <Box alignItems="center" compDisplay="flex" gap={1}>
      <StyledRelevantLineageToggleText>
        Relevant Lineage
        <Tooltip content="Relevant lineage shows only lineage that has impact at the column level">
          <Icon color="gray.400" name="info-outline" size="16px" />
        </Tooltip>
      </StyledRelevantLineageToggleText>
      <Toggle checked={checked} compSize="xs" name="relevant-lineage-toggle" onChange={onChange} />
    </Box>
  );
};

export default RelevantLineageToggle;
