import React from 'react';

import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import theme from '@styles/theme';

import { colors, StyledExpandCollapseButton } from './ExpandCollapseButton.styles';

export type ExpandCollapseState = 'collapsed' | 'expanded';

interface ExpandCollapseButtonProps {
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
  state?: ExpandCollapseState;
  tooltipText?: string;
}

const ExpandCollapseButton: React.FC<ExpandCollapseButtonProps> = ({
  children,
  disabled,
  isLoading,
  onClick,
  state = 'collapsed',
  tooltipText,
}) => {
  const isExpanded = state === 'expanded';

  return (
    <Tooltip content={tooltipText}>
      <Box>
        <StyledExpandCollapseButton
          borderRadius="10px"
          disabled={disabled}
          onClick={() => {
            if (!isLoading) {
              onClick?.();
            }
          }}
          spacing={0.5}
          startIcon={
            isLoading ? (
              <CircularLoader
                bgColor={theme.colors.v1.primary[100]}
                borderWidth={1}
                color={theme.colors.v1.primary[500]}
                compSize={1.5}
              />
            ) : (
              <Icon
                color={colors[state].default.icon}
                name={isExpanded ? 'chevron-up-double' : 'chevron-down-double'}
                size="16px"
              />
            )
          }
          state={state}
          variant="outlined"
        >
          {children}
        </StyledExpandCollapseButton>
      </Box>
    </Tooltip>
  );
};

export default ExpandCollapseButton;
