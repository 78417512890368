import React from 'react';

import { useFetchSSOConnection } from '@api/sso';
import { SSODomain } from '@api/sso/OrganizationSSOModel';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import LayoutMainGrid from '@components/Layout/LayoutMainGrid';
import CreateSSOModal from '@components/Modal/CreateSSOModal';
import AdminSSOTable from '@components/Table/AdminSSOTable';
import TitleView from '@components/UI/TitleView';
import { useModal } from '@context/Modal';
import useUrlSearchParams from '@hooks/useUrlSearchParams';
import AdminTitleView from '@pages/Admin/AdminTitleView/AdminTitleView';
import theme from '@styles/theme';
import MetadataDecorator from '@utils/MetadataDecorator';

import Logout from '../Logout';

const AdminSSOPage: React.FC = () => {
  const { MODAL_IDS, checkModalOpened, closeModal, openModal } = useModal();
  const [isSetupComplete, setSetupComplete] = React.useState(false);
  const { data: organizationSSOConnections, refetch: refetchConnections } = useFetchSSOConnection();
  const [targetDomains, setTargetDomains] = React.useState<SSODomain[] | undefined>();
  const query = useUrlSearchParams();

  React.useEffect(() => {
    const queryCode: string | null = query.get('back-from');
    if (
      queryCode === 'workos' &&
      organizationSSOConnections?.results[0]?.connectionState === 'active'
    ) {
      setSetupComplete(true);
    }
  }, [query, organizationSSOConnections]);

  if (isSetupComplete) {
    return <Logout />;
  }

  return (
    <>
      <MetadataDecorator title="SSO Admin" />
      <LayoutMainGrid>
        <Box maxWidth="100%" mb={8} px={1.5} py={0}>
          <AdminTitleView as="h1" compSize="lg" mb={3} title="Single Sign On (SSO)" />
        </Box>
        {organizationSSOConnections && organizationSSOConnections.count > 0 ? (
          <Box maxWidth="100%" mb={8} px={1.5} py={0}>
            <TitleView
              action={
                <Box fontSize={theme.typography.fontSizes.default} fontWeight="bold">
                  In order to update any SSO settings, please contact Select Star.
                </Box>
              }
              mb={2.5}
              pt={1}
              title="Current SSO Settings"
            />
            <AdminSSOTable
              connections={organizationSSOConnections ? organizationSSOConnections.results : []}
            />
            {organizationSSOConnections.results[0].connectionState !== 'active' && (
              <Button
                onClick={() => {
                  setTargetDomains(organizationSSOConnections.results[0].domains);
                  openModal(MODAL_IDS.createSSO);
                }}
                variant="outlined"
              >
                Complete Connection
              </Button>
            )}
          </Box>
        ) : (
          <Box maxWidth="100%" mb={8} px={1.5} py={0}>
            <Button onClick={() => openModal(MODAL_IDS.createSSO)}>Connect SSO</Button>
          </Box>
        )}
      </LayoutMainGrid>
      {checkModalOpened(MODAL_IDS.createSSO) && (
        <CreateSSOModal
          onClose={() => {
            closeModal(MODAL_IDS.createSSO);
            refetchConnections();
          }}
          targetDomains={targetDomains}
        />
      )}
    </>
  );
};

export default AdminSSOPage;
