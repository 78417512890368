import React from 'react';

import { usePostPasswordResetConfirm } from '@api/password';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import CenterAlignedBox from '@components/CenterAlignedBox';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import Text, { defaultParagraphStyles } from '@components/Text';
import Title from '@components/Title/Title';

interface ChangePasswordFields {
  name: 'new_password' | 'confirm_password';
  placeholder: string;
  required: boolean;
  type: 'password';
}

const changePasswordFields: ChangePasswordFields[] = [
  {
    name: 'new_password',
    placeholder: 'New Password',
    required: true,
    type: 'password',
  },
  {
    name: 'confirm_password',
    placeholder: 'Confirm New Password',
    required: true,
    type: 'password',
  },
];

interface PasswordResetComponentProps {
  onSuccess: () => void;
  token: string;
  user: string;
}

const ChangePasswordForm: React.FC<PasswordResetComponentProps> = ({ onSuccess, token, user }) => {
  const { error, isLoading, mutate } = usePostPasswordResetConfirm({ onSuccess });
  const { handleChange, handleSubmit, values } = useForm({
    initialValues: {
      confirm_password: '',
      new_password: '',
    },
    onSubmit: (v) => {
      mutate({
        token,
        user,
        ...v,
      });
    },
  });

  return (
    <CenterAlignedBox maxWidth="460px" minWidth="360px">
      <Form onSubmit={handleSubmit}>
        <Box alignItems="flex-start" compWidth="100%">
          <Title showLogo>Confirm your password change</Title>
          <Text as="p" py={0.5} {...defaultParagraphStyles} my={1.5} textAlign="left">
            Enter your new password
          </Text>
        </Box>
        {changePasswordFields.map(({ name, placeholder, required, type }) => {
          return (
            <Box key={name} compWidth="100%" mt={1.5}>
              <Input
                error={Boolean(error?.data[name])}
                helperText={error?.data?.[name]?.[0] || ''}
                name={name}
                onChange={handleChange}
                placeholder={placeholder}
                required={required}
                type={type}
                value={values[name]}
              />
            </Box>
          );
        })}
        <Box mt={2}>
          <Button
            disabled={isLoading || !values.new_password || !values.confirm_password}
            fluid
            type="submit"
          >
            Change Password
          </Button>
        </Box>
      </Form>
    </CenterAlignedBox>
  );
};

export default ChangePasswordForm;
