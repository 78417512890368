import moment from 'moment';

import type { DbtProject } from '@api/openapi.generated';
import type { ObjectType } from '@api/types';
import { rawClassMap, rawMap } from '@api/utils';
import { DataSourceModel } from '@models/DataSourceModel';
import DataTypesModel from '@models/DataTypesModel';

const objectType: ObjectType = 'dbtproject';

const mapDbtProjectModel = (value: GetDbtProjectsRetrieveResponse) => {
  const dataTypes = rawClassMap(DataTypesModel, value.data_types);
  const dataSource = rawClassMap(DataSourceModel, value.data_source);
  const targetDataSource = rawClassMap(DataSourceModel, value.target_data_source);

  return {
    dataSource,
    dataTypes,
    dbtType: value.dbt_type,
    dialect: value.dialect,
    guid: value.guid,
    name: value.name,
    targetDataSource,
    updatedAt: rawMap(moment, value.updated_on),
  };
};

export default mapDbtProjectModel;

mapDbtProjectModel.objectType = objectType;

export type DbtProjectModelV1 = ReturnType<typeof mapDbtProjectModel>;
export type GetDbtProjectsRetrieveResponse = DbtProject;
