import React, { createContext, useContext, useMemo } from 'react';

import { useFetchCollectionsHierarchy } from '@api/collections';
import { Option } from '@components/UI/Select/types';
import { HierarchyData } from '@models/HierarchyModel';

const defaultContextValue: {
  collectionsHierarchy?: HierarchyData[];
  collectionsHierarchyOptions: Option[];
  isCollectionsLoading: boolean;
} = {
  collectionsHierarchy: [],
  collectionsHierarchyOptions: [],
  isCollectionsLoading: false,
};

const CollectionsHierarchyContext = createContext(defaultContextValue);

export const useCollectionsHierarchy = () => useContext(CollectionsHierarchyContext);

type CollectionsHierarchyContextProps = {
  children: React.ReactNode;
};

export const CollectionsHierarchyProvider = ({ children }: CollectionsHierarchyContextProps) => {
  const { data: collectionsHierarchy, isLoading: isCollectionsHierarchyLoading } =
    useFetchCollectionsHierarchy();

  const collectionsHierarchyOptions = useMemo(() => {
    const transformToSelectValue = (items: HierarchyData[]): Option[] => {
      return items.map((item) => {
        const selectValue: Option = {
          children: item.children ? transformToSelectValue(item.children) : undefined,
          icon: item.icon,
          text: item.name,
          value: item.guid,
        };
        return selectValue;
      });
    };

    return transformToSelectValue(collectionsHierarchy ?? []);
  }, [collectionsHierarchy]);

  const value = useMemo(
    () => ({
      collectionsHierarchy,
      collectionsHierarchyOptions: collectionsHierarchyOptions ?? [],
      isCollectionsLoading: isCollectionsHierarchyLoading,
    }),
    [collectionsHierarchy, collectionsHierarchyOptions, isCollectionsHierarchyLoading],
  );

  return (
    <CollectionsHierarchyContext.Provider value={value}>
      {children}
    </CollectionsHierarchyContext.Provider>
  );
};
