import styled from '@emotion/styled';

import Box from '@components/Box';
import rootTheme from '@styles/theme';

export const StyledUsageTypeBadge = styled(Box)`
  color: #4976f4;
  background-color: #edf2fe;
  border-style: solid;
  border-color: #b9caf9;
  text-wrap: nowrap;
`;

StyledUsageTypeBadge.defaultProps = {
  alignItems: 'center',
  borderRadius: 'default',
  borderWidth: '1px',
  compDisplay: 'flex',
  compHeight: rootTheme.space(2.5),
  fontSize: 'sm',
  fontWeight: 'medium',
  justifyContent: 'center',
  px: 0.5,
};

export const StyledFilterUsageBadge = styled(StyledUsageTypeBadge)`
  color: ${({ theme }) => theme.colors.v1.primary['400']};
  background-color: ${({ theme }) => theme.colors.v1.primary['50']};
  border-color: ${({ theme }) => theme.colors.v1.primary['200']};
`;
