import { Expose, Transform } from 'class-transformer';
import moment from 'moment';

import type { IconProps } from '@components/UI/Icon';

export interface BiConnectionData {
  connectionType: string;
  createdAt: moment.Moment;
  description?: string;
  externalId: string;
  guid: string;
  name: string;
  updatedAt: moment.Moment;
}

export class BiConnectionModel {
  static objectType: string = 'biConnection';

  objectType: string = BiConnectionModel.objectType;

  static typeDisplay: string = 'Bi Connection';

  typeDisplay: string = BiConnectionModel.typeDisplay;

  guid!: string;

  @Expose({ name: 'external_id' })
  externalId!: string;

  name!: string;

  description?: string;

  @Expose({ name: 'data_source_created_at' })
  @Transform((value) => moment(value))
  createdAt!: moment.Moment;

  @Expose({ name: 'data_source_updated_at' })
  @Transform((value) => moment(value))
  updatedAt!: moment.Moment;

  @Expose({ name: 'connection_type' })
  connectionType!: string;

  get icon(): IconProps['name'] | undefined {
    if (!this.connectionType) return undefined;
    if (this.connectionType === 'excel-direct') return 'tableau-data-excel';
    if (this.connectionType === 'textscan') return 'file';
    return 'database';
  }
}
