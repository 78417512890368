import React, { useEffect, useRef, useState } from 'react';
import type { Analytics, InitOptions } from '@segment/analytics-next';
import { AnalyticsBrowser } from '@segment/analytics-next';
import * as Sentry from '@sentry/react';

import { SegmentContext } from './Segment';
import { SegmentTrackEventName, SegmentTrackPageName } from './Segment.types';

interface SegmentProviderProps {
  /**
   * @link https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#load-options
   */
  options?: InitOptions;

  segmentId: string;
}

const SegmentProvider: React.FC<SegmentProviderProps> = ({ children, options, segmentId }) => {
  const [, setLoaded] = useState<boolean>(false);
  const [isBlocked, setIsBlocked] = useState<boolean>(false);
  const analytics = useRef<Analytics | null>(null);

  useEffect(() => {
    if (!segmentId || analytics.current !== null) {
      return;
    }

    const loadAnalytics = async () => {
      const [response] = await AnalyticsBrowser.load({ writeKey: segmentId }, options);

      analytics.current = response;

      setLoaded(true);
    };

    const checkIfBlocked = async (callback: () => void) => {
      try {
        await fetch(`https://cdn.segment.com/v1/projects/${segmentId}/settings`, {
          method: 'HEAD',
        });
        await callback();
      } catch (err) {
        Sentry.captureException(err);
        setIsBlocked(true);
      }
    };

    checkIfBlocked(loadAnalytics);
  }, []);

  if (isBlocked || !segmentId) {
    return <>{children}</>;
  }

  if (analytics.current === null) {
    return null;
  }

  return (
    <SegmentContext.Provider
      value={{
        identify: (id: string, properties?: object) => {
          return analytics.current?.identify(id, properties);
        },
        page: (page: SegmentTrackPageName, properties?: object) => {
          return analytics.current?.page(page, properties);
        },
        reload: () => {
          return analytics.current?.page();
        },
        track: (event: SegmentTrackEventName, properties?: object) => {
          return analytics.current?.track(event, properties);
        },
      }}
    >
      {children}
    </SegmentContext.Provider>
  );
};

export default SegmentProvider;
