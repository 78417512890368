import React, { useState } from 'react';
import copy from 'copy-to-clipboard';
import hljs from 'highlight.js/lib/core';
import sql from 'highlight.js/lib/languages/sql';

import Icon from '@components/UI/Icon';

import { StyledCodeEditor, StyledEditor, StyledEditorCopyButton } from './CodeEditor.styles';

hljs.registerLanguage('sql', sql);

const SHOW_EMPTY_LINE_SYMBOL = '\u00A0';

interface CodeEditorProps {
  className?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  placeholderText?: string;
  showCopyButton: boolean;
  value: string;
}

const CodeEditor: React.FC<CodeEditorProps> = ({
  className,
  disabled = false,
  onChange = () => {},
  placeholderText,
  showCopyButton,
  value,
}) => {
  const [copyButtonLabel, setCopyButtonLabel] = useState<'Copy' | 'Copied'>('Copy');

  const copyCode = () => {
    copy(value);
    setCopyButtonLabel('Copied');
    setTimeout(() => {
      setCopyButtonLabel('Copy');
    }, 1000);
  };

  return (
    <StyledCodeEditor className={className}>
      {showCopyButton && (
        <StyledEditorCopyButton
          compSize="sm"
          iconCurrentColor
          onClick={copyCode}
          startIcon={<Icon name="copy" />}
          variant="outlined"
        >
          {copyButtonLabel}
        </StyledEditorCopyButton>
      )}
      <StyledEditor
        disabled={disabled}
        highlight={(code) =>
          hljs
            .highlightAuto(code)
            .value.split('\n')
            .map((line, i) => `<span class='editorLineNumber'>${i + 1}</span>${line}`)
            .join('\n')
        }
        onValueChange={onChange}
        placeholder={placeholderText}
        value={value || SHOW_EMPTY_LINE_SYMBOL}
      />
    </StyledCodeEditor>
  );
};

export default CodeEditor;
