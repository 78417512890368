import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Box from '@components/Box';
import { StyledMainInputContainer } from '@components/UI/Select/MainInput/MainInput.styles';

interface StyledCreateNewDocMenuProps {
  useNewLayout?: boolean;
}

export const StyledCreateNewDocMenu = styled(Box)<StyledCreateNewDocMenuProps>`
  ${({ useNewLayout }) =>
    useNewLayout &&
    css`
      ${StyledMainInputContainer} {
        justify-content: center;
      }
    `}
`;
