import React, { useMemo } from 'react';

import { FULL_DESCRIPTION_QUERY } from '@api/constants';
import { useFetchDashboards } from '@api/dashboards';
import TabError from '@components/TabContent/TabError';
import { useUserContext } from '@context/User';
import type { DashboardsTableProps } from '@pages/DashboardsPage/DashboardsTable';
import DashboardsTable, {
  DASHBOARD_TABLE_SEARCH_CONFIG,
  DASHBOARD_TABLE_SORT_CONFIG,
} from '@pages/DashboardsPage/DashboardsTable';
import { ProfileType } from '@pages/ProfilePage/ProfilePage.types';
import type { FilterOptions } from '@utils/filters';
import { setParams, useUpdateFilters } from '@utils/filters';
import stripSpaces from '@utils/stripSpaces';

import { OwnedItemsSubTabProps } from '../types';

const DASHBOARDS_FILTER_OPTIONS: FilterOptions = {
  order: 'name',
  page_size: 100,
  query: stripSpaces(
    `{
        guid,
        name,
        full_path,
        dashboard_updated_at,
        data_types,
        dsuser_created_by,
        bifolder{
          data_source{
            guid,
            type
          }
        },
        breadcrumbs,
        ${FULL_DESCRIPTION_QUERY}
    }`,
  ),
  sortColumn: 'name',
  sortDirection: 'ascending',
};

const VISIBLE_COLUMNS: DashboardsTableProps['visibleColumns'] = [
  'search',
  'name',
  'description',
  'updatedAt',
];

const VISIBLE_COLUMNS_CREATED: DashboardsTableProps['visibleColumns'] = [
  'search',
  'name',
  'dsuser',
  'description',
  'updatedAt',
];

const HEADER_NAME_MAP: { [index: string]: string } = {
  mode: 'Report',
  tableau: 'Workbook',
};

const HIDDEN_COLUMNS: DashboardsTableProps['hiddenColumns'] = [];

const OwnedDashboardsSubTab = ({
  dsuserCreatedBy,
  dsuserType,
  guid,
  profileType,
}: OwnedItemsSubTabProps) => {
  const filterService = useUpdateFilters(
    DASHBOARDS_FILTER_OPTIONS,
    DASHBOARD_TABLE_SEARCH_CONFIG,
    DASHBOARD_TABLE_SORT_CONFIG,
  );

  const { data, isError, isLoading } = useFetchDashboards({
    params: {
      ...setParams(filterService.filter),
      dsuser_created_by: dsuserCreatedBy,
      teams: profileType === ProfileType.team ? guid : undefined,
      user: profileType === ProfileType.user ? guid : undefined,
    },
  });

  const { organization } = useUserContext();
  const showCreatedByColumn = profileType === ProfileType.team;
  const useDownstreamNotifications = organization?.settings?.useDownstreamNotifications;
  const shouldShowCreatedBy = showCreatedByColumn && useDownstreamNotifications;

  const tableConfig = useMemo(
    () => ({
      customColumProps: {
        name: {
          Header: HEADER_NAME_MAP[dsuserType!] ?? 'Dashboard',
          showDataSourceIcon: true,
        },
      },
      visibleColumns: shouldShowCreatedBy ? VISIBLE_COLUMNS_CREATED : VISIBLE_COLUMNS,
    }),
    [dsuserType, shouldShowCreatedBy],
  );

  if (isError) return <TabError />;

  return (
    <DashboardsTable
      customColumProps={tableConfig.customColumProps}
      data={data}
      disableRowSelect
      filterService={filterService}
      hiddenColumns={HIDDEN_COLUMNS}
      isLoading={isLoading}
      visibleColumns={tableConfig.visibleColumns}
    />
  );
};

export default OwnedDashboardsSubTab;
