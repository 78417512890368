import { ConnectionAlertLevel } from '@api/connectionAlerts/ConnectionAlertModel';
import { IconProps } from '@components/UI/Icon';
import { GetColorProps } from '@styles/mixins/getColor';

export const ALERT_ICON_NAME_MAP: Record<ConnectionAlertLevel, IconProps['name']> = {
  error: 'error',
  warn: 'warning',
};

export const ALERT_ICON_COLOR_MAP: Record<ConnectionAlertLevel, GetColorProps> = {
  error: '#CA3544',
  warn: '',
};

export const ALERT_LABEL_MAP: Record<ConnectionAlertLevel, string> = {
  error: 'error',
  warn: 'warning',
};
