const truncateNameBreadcrumbs = ({
  enabled = false,
  max = 32,
  str = '',
}: {
  enabled?: boolean;
  max?: number;
  str?: string;
}) => {
  if (!enabled) {
    return { breadcrumbs: '', name: str };
  }
  const nameSource = str.split('.');
  const lastIndex = nameSource.length - 1;
  const name = nameSource[lastIndex];
  const breadcrumbs = nameSource.slice(0, lastIndex).join('.');

  if (str.length > max) {
    return { breadcrumbs: '...', name };
  }

  return { breadcrumbs, name: `.${name}` };
};

export default truncateNameBreadcrumbs;
