import { ConnectionAlertLevel } from '@api/connectionAlerts/ConnectionAlertModel';

import { ALERT_LABEL_MAP } from './AlertTooltipContent.constants';

export const generateAlertLabel = (level: ConnectionAlertLevel, alertsCount: number) => {
  const rawLabel = ALERT_LABEL_MAP[level];

  const label = alertsCount > 1 ? `${rawLabel}s` : rawLabel;

  return `${alertsCount} ${label}`;
};
