import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { Cell } from 'react-table';

import DateTime from '@components/DateTime';
import Highlighter from '@components/Highlighter';
import Table from '@components/Table/Table';
import { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';

import { FeatureFlag } from './featureFlagsList';

export const defaultFlagState = (flagValue: string) => {
  const envVarName = `react_app_ff_${flagValue}`.toLocaleUpperCase();
  return window.env[envVarName] === 'on';
};

interface FeatureFlagsTableProps {
  data: FeatureFlag[];
  selectedRowIds: { [guid: string]: boolean };
  setSelectedRowIds: (flags: { [key: string]: boolean }) => void;
}

const initialSortState = [
  {
    desc: true,
    id: 'createdAt',
  },
];

const FeatureFlagsTable: React.FC<FeatureFlagsTableProps> = ({
  data,
  selectedRowIds,
  setSelectedRowIds,
}) => {
  const [isShowFilter, setIsShowFilter] = useState(false);
  const toggleFilter = useCallback(() => setIsShowFilter((prev) => !prev), [setIsShowFilter]);

  const columns: ColumnConfig<FeatureFlag>[] = React.useMemo(
    () => [
      {
        Cell: (props: Cell<any>) => {
          // @ts-ignore
          const { globalFilter, row } = props;
          return (
            <Highlighter
              autoEscape
              searchWords={[globalFilter]}
              textToHighlight={row.original.mainFeature}
            />
          );
        },
        Header: 'Main Feature',
        accessor: (d) => d.mainFeature?.toLowerCase(),
        disableResizing: true,
        id: 'mainFeature',
        sortDescFirst: true,
        width: '75%',
      },
      {
        Cell: (props: Cell<any>) => {
          // @ts-ignore
          const { globalFilter, row } = props;
          return (
            <Highlighter
              autoEscape
              searchWords={[globalFilter]}
              textToHighlight={row.original.value}
            />
          );
        },
        Header: 'Value',
        accessor: (d) => d.value?.toLowerCase(),
        disableResizing: true,
        id: 'value',
        width: '135%',
      },
      {
        Cell: (props: Cell<any>) => {
          // @ts-ignore
          const { globalFilter, row } = props;
          return (
            <Highlighter
              autoEscape
              searchWords={[globalFilter]}
              textToHighlight={row.original.description}
            />
          );
        },
        Header: 'Description',
        accessor: (d) => d.description?.toLowerCase(),
        disableResizing: true,
        id: 'description',
        width: '180%',
      },
      {
        Cell: ({ row }: Cell<any>) => {
          return <DateTime datetime={moment(row.original.createdAt)} />;
        },
        Header: 'Created at',
        accessor: (d) => d?.createdAt,
        disableResizing: true,
        id: 'createdAt',
        width: '50%',
      },
    ],
    [],
  );

  const getRowId = React.useCallback((row: Partial<FeatureFlag>) => row.value!, []);

  return (
    <TableStyled>
      <Table
        basic="very"
        className="table-full"
        columns={columns}
        compact
        data={data}
        disableColumnFiltering
        disablePagination
        getRowId={getRowId}
        initialState={{
          selectedRowIds,
          sortBy: initialSortState,
        }}
        selectable
        setSelectedRowIds={setSelectedRowIds}
        showFilter={isShowFilter}
        showGlobalFilterV1
        sortable
        toggleFilter={toggleFilter}
        unstackable
      />
    </TableStyled>
  );
};

export default FeatureFlagsTable;
