import type { DataTypes, DataTypesConfig } from '../types';

const findConfig = <T>(
  ref: DataTypesConfig<any>,
  { dataSourceType, dataType, objectType }: DataTypes,
): T | undefined => {
  const source = ref[dataSourceType];
  const object = source?.[objectType!];
  const data = object?.[dataType!];

  if (data) {
    return data;
  }

  if (object) {
    return object.default;
  }
  if (source) {
    return source.default;
  }

  return undefined;
};

export default findConfig;
