import React, { useContext } from 'react';

import { OpenLineageCallback } from '@components/ExploreTree/types';

import ExploreTreeContext from './context';
import type { ExploreColumnProps } from './ExploreColumn';
import ExploreColumn from './ExploreColumn';
import { effectiveColumnIds } from './helpers';

interface ExploreColumnsProps
  extends Pick<ExploreColumnProps, 'onMouseEnter' | 'onMouseLeave' | 'onClick'> {
  allHoveredColumns: string[];
  allPinnedColumns: string[];
  columnPositionOffset?: number;
  columnsIds: string[];
  componentIdentifier?: string;
  isTableHidden: boolean;
  openLineage: OpenLineageCallback;
  searchColumnName: string;
  showAllColumns: boolean;
  tableKey: string;
  userPinnedColumn: string | null;
}

const ExploreColumns: React.FC<ExploreColumnsProps> = ({
  allHoveredColumns,
  allPinnedColumns,
  columnPositionOffset = 0,
  columnsIds,
  componentIdentifier,
  isTableHidden,
  onClick,
  onMouseEnter,
  onMouseLeave,
  openLineage,
  searchColumnName,
  showAllColumns,
  tableKey,
  userPinnedColumn,
}) => {
  const {
    columnsById,
    pinnableColumnsById,
    rectHeight,
    rectWidth,
    renderContext,
    sortedColumnIdsByTableId,
    xScale,
    yScale,
  } = useContext(ExploreTreeContext);

  const displayedColumns = effectiveColumnIds({
    columns: sortedColumnIdsByTableId[tableKey],
    pinnableColumnsById,
    showAllColumns,
  });

  return (
    <g className="columnGroup">
      {columnsIds.map((columnId, index) => {
        const column = columnsById[columnId];
        const columnName = columnsById[columnId]?.name ?? columnId;
        const finalColumnName = renderContext !== 'looker' ? columnName.toLowerCase() : columnName;
        const columnPos = index + columnPositionOffset;
        const removeLinkedObjs = (id: string) => !column?.linkedObjs?.some((guid) => guid === id);
        const sourceColumnGuids = Object.keys(column?.sourceEdges ?? {})?.filter(removeLinkedObjs);
        const targetColumnGuids = Object.keys(column?.targetEdges ?? {})?.filter(removeLinkedObjs);
        const filterMissingColumns = (id: string) => !columnsById[id];
        const notLoadedLeft = sourceColumnGuids.filter(filterMissingColumns);
        const notLoadedRight = targetColumnGuids.filter(filterMissingColumns);

        if (!displayedColumns.includes(columnId)) {
          return null;
        }

        return (
          <ExploreColumn
            key={columnId}
            canColumnPin={pinnableColumnsById[columnId]}
            columnId={columnId}
            columnName={finalColumnName}
            columnPos={columnPos}
            compHeight={yScale(rectHeight)}
            componentIdentifier={componentIdentifier}
            compWidth={xScale(rectWidth)}
            dataTypes={column?.dataTypes}
            direction={column?.runtimeMeta?.direction}
            guid={column?.guid ?? ''}
            hasJoinQueries={column?.runtimeMeta?.hasJoinQueries}
            isColumnHovered={allHoveredColumns.includes(columnId)}
            isColumnPinned={allPinnedColumns.includes(columnId)}
            isColumnPinnedByUser={columnId === userPinnedColumn}
            isForeignKey={column?.isForeignKey}
            isPrimaryKey={column?.isPrimaryKey}
            isTableHidden={isTableHidden}
            joinQueriesEdge={column?.runtimeMeta?.joinQueriesEdge}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            openLineage={openLineage}
            searchColumnName={searchColumnName}
            showLoadErd={notLoadedLeft.length > 0 || notLoadedRight.length > 0}
            yOffset={yScale((columnPos + 1) * rectHeight) + 0.3}
          />
        );
      })}
    </g>
  );
};

export default ExploreColumns;
