import type { ContainerConfig, ContainerConfigItem } from '@configs/container/types';
import { plainToClass } from 'class-transformer';

import DataTypesModel from '@models/DataTypesModel';
import { TABLES_TABLE_SORT_CONFIG } from '@pages/TablesPage/TablesTable';

const DATA_TYPE_OPTIONS = [
  {
    key: 'all',
    text: 'All',
    value: null,
  },
  {
    key: 'table',
    text: 'Table',
    value: 'dataSourceType=thoughtspot&objectType=bifolder&dataType=one_to_one_logical',
  },
  {
    key: 'sql_view',
    text: 'View',
    value: 'dataSourceType=thoughtspot&objectType=bifolder&dataType=sql_view',
  },
  {
    key: 'worksheet',
    text: 'Worksheet',
    value: 'dataSourceType=thoughtspot&objectType=bifolder&dataType=worksheet',
  },
];

export const CONTAINER_CONFIG_DEFAULT: ContainerConfigItem = {
  countItems: [
    { key: 'ts_tables_count', text: 'Table' },
    { key: 'ts_views_count', text: 'View' },
    {
      key: 'ts_worksheets_count',
      text: 'Worksheet',
    },
  ],
  dataTypes: plainToClass(DataTypesModel, {
    data_source_type: 'thoughtspot',
    object_type: 'ts_data_container',
  }),
  filter: {
    dataTypeInitialSelected: null,
    dataTypeOptions: DATA_TYPE_OPTIONS,
    isCategoryTags: true,
    isCreatedBy: true,
    isSelectStarDetected: true,
    isStatusTags: true,
    showHidden: true,
  },
  initialSort: {
    desc: true,
    id: 'updatedAt' as const,
    parameter: TABLES_TABLE_SORT_CONFIG.updatedAt!,
  },
  path: '/thoughtspot/:dsGuid/data_container',
  title: 'All Data Objects',
};

export const CONTAINER_CONFIG: ContainerConfig = {
  thoughtspot: {
    bifolder: {
      default: CONTAINER_CONFIG_DEFAULT,
      one_to_one_logical: {
        ...CONTAINER_CONFIG_DEFAULT,
        countItems: [CONTAINER_CONFIG_DEFAULT.countItems[0]],
        filter: {
          ...CONTAINER_CONFIG_DEFAULT.filter,
          dataTypeInitialSelected: DATA_TYPE_OPTIONS[1].value,
        },
        title: 'Tables',
      },
      sql_view: {
        ...CONTAINER_CONFIG_DEFAULT,
        countItems: [CONTAINER_CONFIG_DEFAULT.countItems[1]],
        filter: {
          ...CONTAINER_CONFIG_DEFAULT.filter,
          dataTypeInitialSelected: DATA_TYPE_OPTIONS[2].value,
        },
        title: 'Views',
      },
      worksheet: {
        ...CONTAINER_CONFIG_DEFAULT,
        countItems: [CONTAINER_CONFIG_DEFAULT.countItems[2]],
        filter: {
          ...CONTAINER_CONFIG_DEFAULT.filter,
          dataTypeInitialSelected: DATA_TYPE_OPTIONS[3].value,
        },
        title: 'Worksheets',
      },
    },
    default: CONTAINER_CONFIG_DEFAULT,
  },
};
