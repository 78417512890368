import React, { useState } from 'react';
import moment from 'moment';

import Box from '@components/Box';
import Icon from '@components/UI/Icon';
import { useModal } from '@context/Modal';

import type { CalendarModalProps } from './CalendarModal';
import type { VariantProps } from './DateRangePicker.styles';
import { DateRangePickerContainer, DateRangePickerDateSeparator } from './DateRangePicker.styles';

interface DateRangePickerProps
  extends VariantProps,
    Pick<CalendarModalProps, 'maxRangeDays' | 'presetDateOptions'> {
  disabled?: boolean;
  format?: string;
  initialRange?: [string, string];
  onChange?: (range: [string, string]) => void;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  disabled,
  format = 'YYYY-MM-DD',
  initialRange,
  maxRangeDays,
  onChange,
  presetDateOptions,
  variant,
}) => {
  const initialState: [moment.Moment, moment.Moment] = initialRange
    ? [moment(initialRange[0]), moment(initialRange[1])]
    : [moment().startOf('day'), moment().endOf('day')];

  const [rangeValue, setRangeValue] = useState<[moment.Moment, moment.Moment]>(initialState);

  const { MODAL_IDS, openModal } = useModal();

  const handleCalendarRangeChange = (range: [moment.Moment, moment.Moment]) => {
    setRangeValue(range);
    onChange?.([range[0].format(format), range[1].format(format)]);
  };

  const handleOpenModal = () => {
    openModal(MODAL_IDS.calendar, {
      initialRange: rangeValue,
      maxRangeDays,
      onChange: handleCalendarRangeChange,
      presetDateOptions,
    });
  };

  return (
    <DateRangePickerContainer
      as="button"
      disabled={disabled}
      gap={0.5}
      justifyContent="space-between"
      onClick={handleOpenModal}
      variant={variant}
    >
      <Box alignItems="center" compDisplay="flex" gap={0.5}>
        {rangeValue[0].format('MMM D YYYY')}
        <DateRangePickerDateSeparator>to</DateRangePickerDateSeparator>
        {rangeValue[1].format('MMM D YYYY')}
      </Box>
      <Icon color="currentColor" name="calendar" />
    </DateRangePickerContainer>
  );
};

export default DateRangePicker;
