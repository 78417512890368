import styled from '@emotion/styled';

import type { HiddenProps } from '@components/Hidden';
import Hidden from '@components/Hidden';
import staticTheme, { Theme } from '@styles/theme';

export type ColumnsCount = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | false | 'auto';

interface ColumnProps {
  '2xl'?: ColumnsCount;
  compHeight?: string;
  lg?: ColumnsCount;
  md?: ColumnsCount;
  sm?: ColumnsCount;
  xl?: ColumnsCount;
  xs?: ColumnsCount;
}

/*
 * We need to sort the breakpoints because we need to generate the css from the smaller to the
 * biggest screen. The order is important to have the css with the correct priority.
 */
const sortedBreakpoints = Object.keys(staticTheme.breakpoints).sort((a, b) => {
  const breakpointNameA = a as keyof Theme['breakpoints'];
  const breakpointNameB = b as keyof Theme['breakpoints'];
  if (staticTheme.breakpoints[breakpointNameA] < staticTheme.breakpoints[breakpointNameB])
    return -1;
  if (staticTheme.breakpoints[breakpointNameA] > staticTheme.breakpoints[breakpointNameB]) return 1;
  return 0;
});

const Column = styled(Hidden)<ColumnProps & HiddenProps>`
  height: ${({ compHeight }) => compHeight};
  ${({ theme, ...props }) => {
    return sortedBreakpoints
      .filter((breakName) => breakName in props)
      .map((breakName) => {
        const breakpointName = breakName as keyof Theme['breakpoints'];
        const columnsCount = props[breakpointName];

        if (columnsCount === undefined || columnsCount === false) {
          return null;
        }

        if (columnsCount === 'auto') {
          return theme.media.up(breakpointName)`
          width: auto;

         `;
        }

        return theme.media.up(breakpointName)`
        width: ${Math.round(((100 * columnsCount) / theme.grid.system) * 100000) / 100000}%;
      `;
      });
  }};
`;

export default Column;
