import React from 'react';

import { usePostDataSourceBIFoldersHierarchyConfig } from '@api/dataSources';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import { DataSourceModel } from '@models/DataSourceModel';
import { HierarchyModel } from '@models/HierarchyModel';

import BIFolderEditConfigTable from './BIFolderEditConfigTable';

interface BIFolderEditConfigStepProps {
  dataSource: DataSourceModel;
  onBIFoldersUpdated: () => void;
  prevStep: () => void;
}

const BIFolderEditConfigStep: React.FC<BIFolderEditConfigStepProps> = ({
  dataSource,
  onBIFoldersUpdated,
  prevStep,
}) => {
  const segment = useSegmentContext();
  const { isLoading, mutate } = usePostDataSourceBIFoldersHierarchyConfig(dataSource.guid);

  const handleSave = (folders: HierarchyModel[]) => {
    if (!isLoading) {
      mutate(prepareDataToSave(folders), {
        onSuccess: () => {
          onBIFoldersUpdated();
        },
      });
    }
  };

  const prepareDataToSave = (folders: HierarchyModel[]) => {
    return {
      items: folders.map((value) => value.guid),
      should_ingest: folders.map((value) => value.shouldIngest),
    };
  };

  const goBack = () => {
    segment?.track(SegmentTrackEventName.ImportData_SelectSchemasPrevButtonClicked);
    prevStep();
  };

  return <BIFolderEditConfigTable dataSource={dataSource} onSave={handleSave} prevStep={goBack} />;
};

export default BIFolderEditConfigStep;
