import React from 'react';

import type { ChatBotMessageRole } from '@api/chatbot/ChatBotMessageModel';
import Box from '@components/Box/Box.styles';
import CodeEditor from '@components/CodeEditor';
import DotsLoader from '@components/DotsLoader';
import PreviewMarkdown from '@components/PreviewMarkdown';
import { PreviewMarkdownProps } from '@components/PreviewMarkdown/PreviewMarkdown';
import { CodeElement } from '@components/RichTextEditor/RenderElements/CodeElement.styles';
import Text from '@components/Text';

import { StyledChatBotMessageWrapper } from './ChatBotMessage.styles';

const CUSTOM_PREVIEW_MARKDOWN_COMPONENTS: PreviewMarkdownProps['customComponents'] = {
  code: ({ children, className, inline }) => {
    if (inline) {
      return <CodeElement>{children}</CodeElement>;
    }

    if (children?.[0]) {
      return <CodeEditor className={className} showCopyButton value={children?.[0] as string} />;
    }

    return null;
  },
};

interface ChatBotMessageProps {
  avatar?: React.ReactNode;
  content?: string;
  error?: boolean;
  loading?: boolean;
  messageRole?: ChatBotMessageRole;
  title?: React.ReactNode;
}

const ChatBotMessage: React.FC<ChatBotMessageProps> = ({
  avatar,
  children,
  content = '',
  error,
  loading,
  messageRole = 'assistant',
  title,
}) => {
  const isAssistantRole = messageRole === 'assistant';

  return (
    <Box compDisplay="flex" compWidth="100%" my={4}>
      <Box compDisplay="flex" gap={1}>
        {avatar}
        <Box>
          <Box
            alignItems="baseline"
            compDisplay="flex"
            compWidth="100%"
            flexWrap="wrap"
            justifyContent="space-between"
            mb={1}
          >
            <Text color={error ? 'error.500' : 'inherit'} fontWeight="semibold" mb={0} mr={4}>
              {title}
            </Text>
          </Box>
          {loading && !error ? (
            <DotsLoader />
          ) : (
            <StyledChatBotMessageWrapper
              backgroundColor={isAssistantRole ? 'primary.50' : 'primary.600'}
              color={isAssistantRole ? 'text' : 'white'}
              error={error}
              p={1.5}
            >
              <PreviewMarkdown
                customComponents={CUSTOM_PREVIEW_MARKDOWN_COMPONENTS}
                linkTarget="_blank"
                markdown={content}
              />
              {children}
            </StyledChatBotMessageWrapper>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ChatBotMessage;
