import React, { memo } from 'react';

import Highlighter from '@components/Highlighter';
import Tooltip from '@components/Tooltip';

import { TypedCell } from './types';

type TextCellProps = Partial<TypedCell> & {
  text?: string;
  tooltip?: string;
};

const TextCell: React.FC<TextCellProps> = ({ column, globalFilter, text, tooltip }) => {
  if (!text) {
    return null;
  }

  const cellContent = (
    <Tooltip content={tooltip}>
      <span>{text}</span>
    </Tooltip>
  );

  if (!globalFilter && !column?.filterValue) {
    return cellContent;
  }

  return (
    <Highlighter
      searchWords={column?.filterValue?.split(/[.| ]+/) || globalFilter?.split(/[.| ]+/) || []}
      textToHighlight={text || ''}
    >
      {cellContent}
    </Highlighter>
  );
};

export default memo(TextCell);
