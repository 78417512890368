import styled from '@emotion/styled';

const levelStyleMap: { [key: string]: string } = {
  0: 'disc',
  1: 'circle',
  2: 'square',
  3: 'disc',
  4: 'circle',
};

export const ULElement = styled.ul<{ level?: number }>`
  font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  list-style: ${({ level }) => (level ? levelStyleMap[level] : 'disc')};

  li {
    margin-left: ${({ level, theme }) => theme.space(((level ?? 0) + 1) * 2.65)};
    padding-left: ${({ theme }) => theme.space(0.35)};
  }
`;
