import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

import rootTheme from '@styles/theme';

export type VariantColor = 'red' | 'black';

export const colorVariants: Record<VariantColor, SerializedStyles> = {
  black: css`
    background-color: ${rootTheme.colors.v1.gray[100]};
    font-family: ${rootTheme.typography.fontFamilies.mono};
    font-size: ${rootTheme.typography.fontSizes.xs};
    font-weight: ${rootTheme.typography.fontWeights.regular};
    padding: ${rootTheme.space(0.5)};
    line-height: normal;
    border: none;
  `,
  red: css`
    font-family: monospace;
    font-size: 12px;
    padding: ${rootTheme.space(0.25)};
    white-space: pre-wrap;
    color: #e1265f;
    background-color: #f6f6f6;
    border: 1px solid #dadada;
    border-radius: 3px;
  `,
};
