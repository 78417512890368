import React, { useState } from 'react';
import copy from 'copy-to-clipboard';

import { usePatchChatBotConversationByIdMessageById } from '@api/chatbot';
import type { ChatBotConversationData } from '@api/chatbot/ChatBotConversationModel';
import { ChatBotMessageRole, ChatBotRatingType } from '@api/chatbot/ChatBotMessageModel';
import getCacheData from '@api/getCacheData';
import setCacheData from '@api/setCacheData';
import Box from '@components/Box/Box.styles';
import IconButton from '@components/IconButton';
import { renderErrorToast } from '@components/Toast';

export const UNABLE_TO_RATE_ERROR_MESSAGE = 'Unable to assign a rating to the message.';
const COPY_DEBOUNCE_TIMEOUT = 500;

enum ChatBotRating {
  LIKE = 1,
  DISLIKE = -1,
}

interface ChatBotMessageToolbarProps {
  content: string;
  conversationId: string;
  id: string;
  messageRole: ChatBotMessageRole;
  rating?: ChatBotRatingType;
}

const ChatBotMessageToolbar: React.FC<ChatBotMessageToolbarProps> = ({
  content,
  conversationId,
  id,
  messageRole = 'assistant',
  rating,
}) => {
  const [latestRating, setLatestRating] = useState(rating);
  const isAssistantRole = messageRole === 'assistant';
  const [copyIcon, setCopyIcon] = useState<'copy' | 'check'>('copy');

  const { mutate } = usePatchChatBotConversationByIdMessageById(conversationId, id, {
    onError: () => {
      renderErrorToast(UNABLE_TO_RATE_ERROR_MESSAGE);
      setLatestRating(rating);
    },
    onSuccess: () => {
      const prevData = getCacheData<ChatBotConversationData>((keys) =>
        keys.chatbot.conversation(conversationId),
      );

      const messageIndex = prevData?.messages?.findIndex((m) => m.guid === id);

      if (messageIndex !== undefined) {
        prevData?.messages?.splice(messageIndex, 1, {
          ...prevData?.messages[messageIndex],
          rating: latestRating,
        });

        setCacheData((keys) => [[keys.chatbot.conversation(conversationId), prevData]]);
      }
    },
  });

  const handleCopyOnClick = () => {
    copy(content);
    setCopyIcon('check');
    setTimeout(() => {
      setCopyIcon('copy');
    }, COPY_DEBOUNCE_TIMEOUT);
  };

  const handleRateOnClick = (newRating: ChatBotRatingType) => {
    if (latestRating !== newRating) {
      mutate({ rating: newRating });
      setLatestRating(newRating);
    }
  };

  if (!isAssistantRole || latestRating === undefined) {
    return null;
  }

  return (
    <Box alignItems="center" compDisplay="flex" gap={0.25} ml={-0.5} mt={1}>
      <IconButton
        disabled={latestRating === ChatBotRating.DISLIKE}
        iconColor="gray.700"
        iconName="thumbs-down-outlined"
        iconSize="16px"
        onClick={() => handleRateOnClick(ChatBotRating.DISLIKE)}
      />
      <IconButton
        disabled={latestRating === ChatBotRating.LIKE}
        iconColor="gray.700"
        iconName="thumbs-up-outlined"
        iconSize="16px"
        onClick={() => handleRateOnClick(ChatBotRating.LIKE)}
      />
      <IconButton
        iconColor="gray.700"
        iconName={copyIcon}
        iconSize="16px"
        onClick={handleCopyOnClick}
      />
    </Box>
  );
};

export default ChatBotMessageToolbar;
