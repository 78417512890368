const zIndex = {
  contentHeaders: 2, // subtabs, editor tools
  floatingElement: 5, // above everything
  header: 4, // app header
  sidebar: 3, // app sidebar
  stickyElement: 1, // table sticky header
  toast: 6, // toast notifications
} as const;

export type ZIndex = typeof zIndex;

export default zIndex;
