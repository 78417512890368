import React, { useMemo } from 'react';
import { ErrorBoundary } from '@sentry/react';
import { Background, BackgroundVariant, Controls, MiniMap } from 'reactflow';

import UnexpectedError from '@components/Error/UnexpectedError';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import flags from '@features';
import theme from '@styles/theme';
import { SPACE } from '@styles/theme/grid';

import { useLineageExplore } from '../../useLineageExplore';
import useUserTracking from '../../useUserTracking';
import ArrowHeads from '../ArrowHeads';
import LineageExploreDevOptions from '../LineageExploreDevOptions';

import {
  defaultEdgeOptions,
  edgeTypes,
  nodeTypes,
  proOptions,
} from './LineageExploreGraph.constants';
import { StyledReactFlow } from './LineageExploreGraph.styles';

const LineageExploreGraph: React.FC = () => {
  const { edgesById, nodesById, selectedEdge, setSelectedEdge } = useLineageExplore();

  const nodes = useMemo(() => Object.values(nodesById), [nodesById]);
  const edges = useMemo(() => Object.values(edgesById), [edgesById]);

  const { track } = useUserTracking();

  const handleZoomIn = () => {
    track(SegmentTrackEventName.LineageZoomInClicked);
  };

  const handleZoomOut = () => {
    track(SegmentTrackEventName.LineageZoomOutClicked);
  };

  const handleFitView = () => {
    track(SegmentTrackEventName.LineageFitViewClicked);
  };

  return (
    <ErrorBoundary fallback={UnexpectedError}>
      <ArrowHeads />
      <StyledReactFlow
        defaultEdgeOptions={defaultEdgeOptions}
        edges={edges}
        edgeTypes={edgeTypes}
        fitView
        fitViewOptions={{ maxZoom: 1, minZoom: 0.75 }}
        maxZoom={2}
        minZoom={0.25}
        nodes={nodes}
        // @ts-ignore nodeTypes receives NodeProps<T> but we need Node<T> to include all the props we need (e.g. styles)
        nodeTypes={nodeTypes}
        onEdgeClick={(data, edge) => {
          if (!edge.data.isUnfocused) {
            if (edge.id === selectedEdge?.id) {
              setSelectedEdge(undefined);
            } else {
              setSelectedEdge(edge);
            }
          }
        }}
        onNodeClick={() => {
          setSelectedEdge(undefined);
        }}
        onPaneClick={() => {
          setSelectedEdge(undefined);
        }}
        panOnScroll
        proOptions={proOptions}
        zoomOnDoubleClick={false}
      >
        <Controls
          onFitView={handleFitView}
          onZoomIn={handleZoomIn}
          onZoomOut={handleZoomOut}
          position="bottom-right"
          showInteractive={false}
        />
        <MiniMap
          ariaLabel="Explore mini map"
          maskColor={`${theme.colors.v1.gray[100]}60`}
          maskStrokeColor={theme.colors.v1.gray[600]}
          maskStrokeWidth={15}
          nodeColor={theme.colors.v1.gray[300]}
          nodeStrokeWidth={3}
          pannable
          zoomable
        />
        <Background
          color={`${theme.colors.gray[200]}50`}
          gap={SPACE * 2}
          size={SPACE / 2}
          variant={BackgroundVariant.Dots}
        />
        {flags.show_lineage_dev_options && <LineageExploreDevOptions />}
      </StyledReactFlow>
    </ErrorBoundary>
  );
};

export default LineageExploreGraph;
