/**
 * Cleans the DOM by removing comment nodes and text nodes that are nothing but whitespace.
 * Useful when converting markdown to html as many useless text nodes are created that can
 * cause bugs in slate editor.
 * Borrowed from: https://www.sitepoint.com/removing-useless-nodes-from-the-dom/
 */
const cleanDOM = (node: ChildNode) => {
  for (let n = 0; n < node.childNodes.length; n += 1) {
    const child = node.childNodes[n];

    // If node type is comment or text node that has nothing but whitespace they are removed
    if (
      child.nodeType === 8 ||
      (child.nodeType === 3 && child.nodeValue && !/\S/.test(child.nodeValue))
    ) {
      node.removeChild(child);
      n -= 1;
    } else if (child.nodeType === 1) {
      cleanDOM(child);
    }
  }
};

export default cleanDOM;
