import {
  biSources,
  dataSources,
  transformerTools,
  warehouseSources,
} from '@configs/dataSources/config';

export type DataSourceOptionType<T> = {
  text: string;
  value: T;
};

export const WarehouseOptions = Object.values(warehouseSources);
export const TransformerOptions = Object.values(transformerTools);
export const BITypeOptions = Object.values(biSources);
export const DataSourceOptions = Object.values(dataSources);

export const TableauActivityDataSourceOptions = [
  warehouseSources.snowflake,
  warehouseSources.postgres,
];

export type TableauTypeType = 'online' | 'server';

export const TableauTypeOptions: Array<DataSourceOptionType<TableauTypeType>> = [
  { text: 'Tableau Online', value: 'online' },
  { text: 'Tableau Server', value: 'server' },
];
