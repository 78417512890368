import React from 'react';

import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import type DataTypesModel from '@models/DataTypesModel';

interface SearchTopIconProps {
  dataTypes?: Omit<DataTypesModel, 'findConfig'>;
  mr?: number;
  showTooltip?: boolean;
  size?: string;
}
export const SearchTopIcon = ({
  dataTypes,
  mr = 0.5,
  showTooltip,
  size = '16px',
}: SearchTopIconProps) => {
  return (
    <Tooltip content={showTooltip ? dataTypes?.tooltips.dataSource : undefined}>
      <Icon compDisplay="flex" mr={mr} name={dataTypes?.icons.dataSource!} size={size} />
    </Tooltip>
  );
};

export default SearchTopIcon;
