import { css } from '@emotion/react';
import styled from '@emotion/styled';

import type { StyledBoxProps } from '@components/Box';
import Box from '@components/Box';

import {
  CONTAINER_STATE_VARIANTS,
  DOT_STATE_VARIANTS,
  RadioButtonState,
} from './RadioButton.styles.variants';

export interface StyledRadioButtonProps extends StyledBoxProps {
  disabled?: boolean;
  state?: RadioButtonState;
}

export const StyledRadioButton = styled(Box)<StyledRadioButtonProps>`
  height: 1rem;
  width: 1rem;
  border: 1px solid;
  background-color: ${({ theme }) => theme.colors.white};
  border-color: ${({ theme }) => theme.colors.gray[300]};
  border-radius: ${({ theme }) => theme.radius.lg};
  transition: all 100ms ease, outline-color 300ms ease;
  outline: 4px solid transparent;
  cursor: pointer;

  &:active {
    outline-color: ${({ theme }) => theme.colors.v1.primary[300]}50;
  }

  ${({ disabled, state = 'default' }) => {
    const mappedVariant = CONTAINER_STATE_VARIANTS[state];
    return css`
      ${disabled ? mappedVariant.disabled : mappedVariant.default}
      &:hover {
        ${!disabled && mappedVariant.hover};
      }
    `;
  }};
`;

StyledRadioButton.defaultProps = {
  alignItems: 'center',
  compDisplay: 'flex',
  justifyContent: 'center',
};

interface StyledRadioButtonDotProps {
  checked?: boolean;
  state?: 'default' | 'disabled' | 'error';
}

export const StyledRadioButtonDot = styled.div<StyledRadioButtonDotProps>`
  height: ${({ theme }) => theme.space(0.75)};
  width: ${({ theme }) => theme.space(0.75)};
  transition: all 100ms ease, outline-color 300ms ease;
  border-radius: ${({ theme }) => theme.radius.lg};

  ${({ checked, state = 'default' }) => {
    const mappedVariant = checked ? DOT_STATE_VARIANTS.checked : DOT_STATE_VARIANTS.default;
    return css`
      ${mappedVariant[state]}
    `;
  }};
`;

export const StyledRadioButtonInput = styled.input`
  display: none;
`;
