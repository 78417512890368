import React from 'react';

import { CollectionModel } from '@api/collections/CollectionModel';
import Box from '@components/Box';
import MetadataGrid from '@components/MetadataGrid';
import TableTopBar from '@components/Table/Table/TableTopBar';
import { Filter } from '@utils';

import CollectionCard from '../CollectionCard';

interface CollectionsGridViewProps {
  data?: CollectionModel[];
  filterService: Filter.FilterServiceInterface;
  isLoading?: boolean;
  totalPages?: number;
  viewSelector?: React.ReactNode;
}

const CollectionsGridView: React.FC<CollectionsGridViewProps> = ({
  data = [],
  filterService,
  isLoading,
  totalPages,
  viewSelector,
}) => {
  return (
    <Box compDisplay="flex" flexDirection="column">
      <TableTopBar
        customContent={viewSelector}
        mb={2.5}
        onSearchValueChange={(e) => {
          filterService.globalSearch(e.target.value);
        }}
        searchValue={filterService.filter.search_all ?? ''}
      />
      <MetadataGrid
        changePage={filterService.changePage}
        columnsCount={{
          '2xl': 3,
          lg: 6,
          md: 12,
          sm: 12,
          xl: 6,
          xs: 12,
        }}
        data={data}
        isLoading={isLoading}
        label="collections-grid"
        totalPages={totalPages}
      >
        {(item) => <CollectionCard collection={item} />}
      </MetadataGrid>
    </Box>
  );
};

export default CollectionsGridView;
