import { plainToClass } from 'class-transformer';

import DataTypesModel from '@models/DataTypesModel';

export const DATA_TYPE_OPTIONS = [
  {
    key: 'dashboard',
    text: 'Workbooks',
    value: 'dashboard',
  },
  {
    key: 'tableauview',
    text: 'Views',
    value: 'tableauview',
  },
  {
    key: 'tableaudatasource',
    text: 'Data Sources',
    value: 'tableaudatasource',
  },
];

export const CONTAINER_CONFIG = {
  dataTypes: plainToClass(DataTypesModel, {
    data_source_type: 'tableau',
    object_type: 'projects_container',
  }),
  name: 'All Projects',
};
