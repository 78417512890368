import React from 'react';

import { useFetchTags } from '@api/tags';
import TabError from '@components/TabContent/TabError';
import OwnedTagsTable from '@components/Table/OwnedTagsTable';
import { tagsOwnedQuery } from '@models/Owners';

import { OwnedItemsSubTabProps } from '../types';

const OwnedTagsSubTab = ({ guid }: OwnedItemsSubTabProps) => {
  const {
    data: tags,
    isError,
    isLoading,
  } = useFetchTags({
    params: {
      owners: guid,
      query: tagsOwnedQuery,
    },
  });

  if (isError) return <TabError />;

  return (
    <OwnedTagsTable
      data={tags?.results}
      itemCount={tags?.results.length}
      loading={isLoading}
      stickyHeader
    />
  );
};

export default OwnedTagsSubTab;
