import React, { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Cell } from 'react-table';

import { dsUsersCacheKeys } from '@api/dsusers';
import { DsUserModel } from '@api/dsusers/DsUserModel';
import type { ColumnConfig } from '@components/Table/Table/types';
import fetchClient from '@lib/fetchClient';
import { Filter } from '@utils';
import wrapString from '@utils/wrapString';

import SearchHeader from '../Cells/SearchHeader';
import UserCell from '../Cells/UserCell';
import Table from '../Table';
import TableStyled from '../TableStyled';

import CheckboxCell from './CheckboxCell';
import OrganizationUsersDropdownCell from './OrganizationUsersDropdownCell';

const initialSortState = [
  {
    desc: false,
    id: 'serviceAccount',
  },
];

const StyledDSUsersTable = styled(TableStyled)`
  .ui.table tr {
    height: 48px;
  }

  .ui.table tr th {
    .serviceAccount,
    .name,
    .user {
      text-align: center;
    }
  }
`;

interface DSUsersTableProps {
  data?: DsUserModel[];
  filterService: Filter.FilterServiceInterface;
  itemsCount: number;
  loading: boolean;
  stickyHeader?: boolean;
  totalPages?: number;
}

const DSUsersTable: React.FC<DSUsersTableProps> = ({
  data,
  filterService,
  itemsCount,
  loading,
  stickyHeader,
  totalPages,
}) => {
  const [isShowFilter, setShowFilter] = useState(false);
  const { changePage, filter, search, sort } = filterService;
  const toggleFilter = useCallback(() => setShowFilter((prev) => !prev), [setShowFilter]);

  const invalidateData = useCallback(() => {
    fetchClient.invalidateQueries(dsUsersCacheKeys.dsUsers);
  }, []);

  const columns: ColumnConfig<DsUserModel>[] = useMemo(
    () => [
      {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      {
        Cell: ({ row }: Cell<DsUserModel>) => {
          const item = row.original;
          return <CheckboxCell dsuser={item} onSuccess={invalidateData} />;
        },
        Header: 'Service Account',
        accessor: 'isServiceAccount',
        centerHeader: true,
        disableFilters: true,
        disableResizing: true,
        id: 'serviceAccount',
        width: '15%',
      },
      {
        Cell: (props: Cell<DsUserModel>) => {
          const user = props.row.original;
          return <UserCell {...props} user={user} />;
        },
        Header: `User ${wrapString(itemsCount)}`,
        accessor: 'fullName',
        disableHiding: true,
        id: 'name',
        width: '40%',
      },
      {
        Cell: ({ row }: Cell<DsUserModel>) => {
          const user = row.original;
          return <OrganizationUsersDropdownCell dsUser={user} />;
        },
        Header: 'Select Star User',
        accessor: (d) => d?.user?.fullName,
        id: 'user',
        width: '43%',
      },
    ],
    [invalidateData, itemsCount],
  );

  const getRowId = useCallback((row: Partial<DsUserModel>) => row.guid!, []);

  return (
    <StyledDSUsersTable>
      <Table
        basic="very"
        changePage={changePage}
        className="table-full"
        columns={columns}
        data={data || []}
        disableColumnFiltering
        disablePageRefresh
        disableRowSelect
        getRowId={getRowId}
        initialState={{
          pageIndex: filter.page ? filter.page - 1 : 0,
          sortBy: initialSortState,
        }}
        loading={loading}
        manualFilters
        manualPagination
        manualSortBy
        setFilters={search}
        setSortBy={sort}
        showFilter={isShowFilter}
        sortable
        stickyHeader={stickyHeader}
        toggleFilter={toggleFilter}
        totalPages={totalPages}
      />
    </StyledDSUsersTable>
  );
};

export default React.memo(DSUsersTable);
