import { useFetchExplore } from '@api/explores';

import ExploresTabContent from './ExploresTabContent';

export default {
  Content: ExploresTabContent,
  fetchHook: useFetchExplore,
  fetchHookExtraParams: {
    force_showing_suggested_description: true,
  },
};
