import React from 'react';

import { DashboardTypes } from '@api/dashboards/DashboardTypes';
import { useFetchBiQueries } from '@api/queries';
import { ReportQueryModel } from '@api/queries/ReportQueryModel';
import type { DataType } from '@api/types';
import BulkButtons from '@components/BulkButtons';
import TabError from '@components/TabContent/TabError';
import type { BIChartsTableProps } from '@components/Table/BIChartsTable';
import BIChartsTable, {
  BI_CHARTS_TABLE_SEARCH_CONFIG,
  BI_CHARTS_TABLE_SORT_CONFIG,
} from '@components/Table/BIChartsTable';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import useBulkEditSelected from '@hooks/useBulkEditSelected';
import { setParams, useUpdateFilters } from '@utils/filters';

type ConfigItem = {
  customAttributesAssetType: string;
  routedColumnPage?: boolean;
  urlSubstring: string;
} & Pick<BIChartsTableProps, 'visibleColumns' | 'customColumProps'>;

const BI_CHART_TABLE_CONFIG: PartialRecord<DataType | string, ConfigItem> = {
  [DashboardTypes.periscope_dashboard]: {
    customAttributesAssetType: 'periscope,dashboardelement,',
    customColumProps: {
      name: { Header: 'Charts' },
    },
    urlSubstring: 'charts',
    visibleColumns: ['search', 'name', 'customAttribute', 'description', 'tags'],
  },
  [DashboardTypes.sigma_dashboard]: {
    customAttributesAssetType: 'sigma,reportquery,element',
    customColumProps: {
      name: { Header: 'Element' },
      pageName: { Header: 'Page' },
    },
    urlSubstring: 'elements',
    visibleColumns: [
      'search',
      'pageName',
      'name',
      'customAttribute',
      'description',
      'rawSql',
      'tags',
      'popularity',
    ],
  },
  [DashboardTypes.power_bi_dashboard]: {
    customAttributesAssetType: 'power_bi,reportquery,tile',
    customColumProps: {
      name: { Header: 'Tile' },
    },
    urlSubstring: 'tiles',
    visibleColumns: ['search', 'name', 'customAttribute', 'description', 'tags'],
  },
  [DashboardTypes.power_bi_report]: {
    customAttributesAssetType: 'power_bi,reportquery,page',
    customColumProps: {
      name: { Header: 'Page' },
    },
    urlSubstring: 'pages',
    visibleColumns: ['search', 'name', 'customAttribute', 'description', 'tags'],
  },
  // @todo: Remove on next PR https://app.shortcut.com/select-star/story/101780/fe-metabase-question-support
  metabase_dashboard: {
    customAttributesAssetType: 'metabase,reportquery,card',
    customColumProps: {
      name: { Header: 'Card' },
    },
    urlSubstring: 'cards',
    visibleColumns: ['search', 'name', 'customAttribute', 'description', 'tags'],
  },
  [DashboardTypes.data_studio_dashboard]: {
    customAttributesAssetType: 'data_studio,reportquery,page',
    customColumProps: {
      name: { Header: 'Page' },
    },
    urlSubstring: 'pages',
    visibleColumns: ['search', 'name', 'customAttribute', 'description', 'tags'],
  },
  [DashboardTypes.answer]: {
    customAttributesAssetType: 'thoughtspot,reportquery,column',
    customColumProps: {
      name: { Header: 'Fields' },
    },
    urlSubstring: 'fields',
    visibleColumns: ['search', 'name', 'customAttribute', 'description', 'tags'],
  },
  [DashboardTypes.liveboard]: {
    customAttributesAssetType: 'thoughtspot,reportquery,chart',
    customColumProps: {
      name: { Header: 'Charts' },
    },
    urlSubstring: 'charts',
    visibleColumns: [
      'search',
      'pageName',
      'name',
      'customAttribute',
      'description',
      'rawSql',
      'tags',
    ],
  },
  [DashboardTypes.report]: {
    customAttributesAssetType: 'mode,dashboardelement,',
    urlSubstring: 'queries',
    visibleColumns: [
      'search',
      'name',
      'rawSql',
      'customAttribute',
      'description',
      'tags',
      'updatedAt',
    ],
  },
};

interface QueriesTabProps {
  dataType?: DataType | string;
  guid: string;
  isDataSourceEditable: boolean;
}

const QueriesTab: React.FC<QueriesTabProps> = ({
  dataType = DashboardTypes.report,
  guid,
  isDataSourceEditable,
}) => {
  const { isEditable: isObjectEditable } = useObjectPermissionsContext({ id: guid });
  const config = BI_CHART_TABLE_CONFIG[dataType]!;

  const defaultSort = config?.visibleColumns?.includes('updatedAt')
    ? BI_CHARTS_TABLE_SORT_CONFIG.updatedAt
    : BI_CHARTS_TABLE_SORT_CONFIG.name;

  const filterService = useUpdateFilters(
    { order: defaultSort },
    BI_CHARTS_TABLE_SEARCH_CONFIG,
    BI_CHARTS_TABLE_SORT_CONFIG,
    defaultSort,
  );

  const { data, isError, isLoading } = useFetchBiQueries({
    enabled: Boolean(guid),
    keepPreviousData: true,
    params: {
      ...setParams(filterService.filter),
      active: true,
      dashboards: guid,
    },
  });

  const { selected } = useBulkEditSelected<ReportQueryModel>({
    defaultSelected: data?.results,
  });

  if (isError) return <TabError />;

  return (
    <>
      {config?.visibleColumns?.includes('description') && (
        <BulkButtons
          canUseBulkAssist={isObjectEditable}
          guid={guid}
          isDataSourceEditable
          selectedEditableItems={selected.items}
          selectedItems={selected.items}
        />
      )}
      <BIChartsTable
        customAttributesAssetType={config.customAttributesAssetType}
        customColumProps={config?.customColumProps}
        data={data?.results}
        filterService={filterService}
        isDataSourceEditable={isDataSourceEditable}
        isLoading={isLoading}
        itemCount={data?.count}
        visibleColumns={config.visibleColumns}
      />
    </>
  );
};

export default QueriesTab;
