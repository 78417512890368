import React, { useCallback, useMemo } from 'react';
import { Cell } from 'react-table';

import { CustomAttributeModel } from '@api/customAttributes/CustomAttributeModel';
import IconButton from '@components/IconButton';
import UserCell from '@components/Table/Cells/UserCell';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import Text from '@components/Text';
import theme from '@styles/theme';

import AppliesTo from '../AppliesTo';
import TypeElement from '../TypeElement';

import { StyledTableWrapper } from './CustomAttributesTable.styles';

const initialSortState = [
  {
    desc: false,
    id: 'name',
  },
];

interface Props {
  data?: CustomAttributeModel[];
  loading: boolean;
  onDeleteClick: (customAttribute: CustomAttributeModel) => void;
  onEditClick: (customAttribute: CustomAttributeModel) => void;
}

const CustomAttributesTable: React.FC<Props> = ({ data, loading, onDeleteClick, onEditClick }) => {
  const columns: ColumnConfig<CustomAttributeModel>[] = useMemo(
    () => [
      {
        Cell: ({ row: { original: customAttribute } }: Cell<CustomAttributeModel>) => {
          return <Text fontSize={theme.typography.fontSizes.body1}>{customAttribute.name}</Text>;
        },
        Header: 'Custom Attribute',
        accessor: (d) => d.name?.toLowerCase(),
        disableHiding: true,
        id: 'name',
        width: '20%',
      },
      {
        Cell: ({ row: { original: customAttribute } }: Cell<CustomAttributeModel>) => {
          return <TypeElement type={customAttribute.type} />;
        },
        Header: 'Attribute Data Type',
        accessor: (d) => d.type?.toLowerCase(),
        disableHiding: true,
        id: 'type',
        width: '20%',
      },
      {
        Cell: (props: Cell<CustomAttributeModel>) => {
          return <UserCell {...props} user={props.row.original.createdBy} />;
        },
        Header: 'Creator',
        accessor: (d) => d?.createdBy?.fullName,
        disableHiding: true,
        id: 'creator',
        width: '15%',
      },
      {
        Cell: ({ row: { original: customAttribute } }: Cell<CustomAttributeModel>) => {
          return <AppliesTo appliesToData={customAttribute.appliesTo} />;
        },
        Header: 'Applies to',
        disableHiding: true,
        id: 'applies-to',
        width: '45%',
      },
      {
        Cell: ({ row: { original: customAttribute } }: Cell<CustomAttributeModel>) => {
          return (
            <IconButton
              iconColor="gray.600"
              iconName="edit-outline"
              iconSize="20px"
              onClick={() => onEditClick(customAttribute)}
              variant="clear"
              verticalAlign="middle"
            />
          );
        },
        disableResizing: true,
        disableSortBy: true,
        id: 'edit',
        width: '2rem',
      },
      {
        Cell: ({ row: { original: customAttribute } }: Cell<CustomAttributeModel>) => {
          return (
            <IconButton
              iconColor="gray.600"
              iconName="trash"
              iconSize="20px"
              onClick={() => onDeleteClick(customAttribute)}
              variant="clear"
              verticalAlign="middle"
            />
          );
        },
        disableResizing: true,
        disableSortBy: true,
        id: 'delete',
        width: '2rem',
      },
    ],
    [onDeleteClick, onEditClick],
  );

  const getRowId = useCallback((row: Partial<CustomAttributeModel>) => row.guid!, []);

  return (
    <StyledTableWrapper>
      <Table
        basic="very"
        className="table-full"
        columns={columns}
        compact
        data={data || []}
        disableColumnFiltering
        disableFilters
        disablePagination
        disableRowSelect
        getRowId={getRowId}
        initialState={{
          sortBy: initialSortState,
        }}
        loading={loading}
        selectable
        sortable
        unstackable
      />
    </StyledTableWrapper>
  );
};

export default React.memo(CustomAttributesTable);
