import React from 'react';

import Box from '@components/Box';
import theme from '@styles/theme';

import Button from './Button';

interface ToggleButtonProps {
  labelOptionOne?: string;
  labelOptionTwo?: string;
  onToggle?: () => void;
  toggled?: boolean;
}

const getButtonProps = (active?: boolean) => {
  const inactiveButtonProps = {
    backgroundColor: theme.colors.v1.gray[100],
    color: theme.colors.text,
  };

  const activeButtonProps = {
    backgroundColor: theme.colors.v1.primary[600],
    color: theme.colors.white,
  };

  return active ? activeButtonProps : inactiveButtonProps;
};

const ToggleButton: React.FC<ToggleButtonProps> = ({
  labelOptionOne,
  labelOptionTwo,
  onToggle,
  toggled,
}) => {
  return (
    <Box
      backgroundColor={theme.colors.v1.gray[100]}
      borderRadius="100px"
      compDisplay="flex"
      compWidth="160px"
      onClick={onToggle}
    >
      <Button borderRadius="100px" {...getButtonProps(!toggled)}>
        {labelOptionOne}
      </Button>
      <Button borderRadius="100px" {...getButtonProps(toggled)}>
        {labelOptionTwo}
      </Button>
    </Box>
  );
};

export default ToggleButton;
