import React from 'react';

import Popularity from '@components/Popularity';
import createPopularityDescription from '@components/Popularity/createPopularityDescription';
import { PopularityModel } from '@models/PopularityModel';

interface PopularityItemProps {
  dataType?: string;
  popularity?: PopularityModel;
}

const PopularityItem: React.FC<PopularityItemProps> = ({ dataType, popularity }) => (
  <Popularity
    priority={popularity?.formattedPopularity}
    text={createPopularityDescription(popularity, dataType)}
    topUsersLink
  />
);

export default PopularityItem;
