import React from 'react';
import getDebug from 'debug';

import { useFetchSimilarTables } from '@api/tables';
import { RelatedTableList } from '@components/RelatedTableList';
import TabError from '@components/TabContent/TabError';
import { TabContentProps } from '@components/Tabs/types';
import { TableViewTabProps } from '@pages/TabbedTableViewPage/TableViewTab';

const debug = getDebug('selectstar:tab-loading');

export default React.memo<TableViewTabProps & TabContentProps>(function SimilarTablesTab({ guid }) {
  const { data: similarTableResponse, error, isLoading } = useFetchSimilarTables(guid);

  if (error) return <TabError />;

  debug('display SimilarTablesTab');

  return <RelatedTableList loading={isLoading} tables={similarTableResponse?.results} />;
});
