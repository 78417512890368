import React from 'react';

import Box from '@components/Box';
import Button from '@components/Button/Button';
import Card from '@components/UI/Card/Card';
import type { IconProps } from '@components/UI/Icon';
import Icon from '@components/UI/Icon';
import stripSpaces from '@utils/stripSpaces';

export interface AdminAppCardProps {
  connected?: boolean;
  description: string;
  icon: IconProps['name'];
  name: string;
  onClick?: () => void;
  onConnect?: () => void;
}

const AdminAppCard = ({
  connected,
  description,
  icon,
  name,
  onClick,
  onConnect,
}: AdminAppCardProps) => {
  const cardLabelId = stripSpaces(`${name}-card-label`);

  return (
    <Card
      aria-labelledby={cardLabelId}
      clickable={connected}
      onClick={onClick}
      role={connected ? 'button' : undefined}
    >
      <Box
        alignItems="center"
        compDisplay="flex"
        fontSize="h4"
        fontWeight="semibold"
        gap={1}
        id={cardLabelId}
        mb={2}
      >
        <Icon name={icon} size="24px" />
        {name}
      </Box>
      <Box compHeight="20px" mb={2} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
        {description}
      </Box>
      <Button
        compSize="sm"
        onClick={!connected ? onConnect : onClick}
        startIcon={
          <Icon color={!connected ? 'white' : 'gray.700'} name={connected ? 'check' : 'plus'} />
        }
        variant={connected ? 'outlined' : 'default'}
      >
        {connected ? 'Connected' : 'Connect'}
      </Button>
    </Card>
  );
};

export default AdminAppCard;
