import { Editor, Element, Transforms } from 'slate';

import { CustomElementType } from '../RichTextEditor.types';

export const isBlockActive = (editor: Editor, format: CustomElementType) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === format,
    }),
  );

  return Boolean(match);
};

/**
 * Toggles the current block to the spcified format.
 */
export const toggleBlock = (editor: Editor, format: CustomElementType) => {
  const LIST_TYPES = ['ol', 'ul'];
  const BLOCK_WRAP_TYPES = ['codeblock', 'blockquote'];
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);
  const isBlockWrapType = BLOCK_WRAP_TYPES.includes(format);

  /**
   * These steps must occur without normalization or slate
   * will normalize during the unwrapping and wrapping process and remove our elements.
   * See: https://docs.slatejs.org/concepts/11-normalizing for more info.
   *
   */
  Editor.withoutNormalizing(editor, () => {
    Transforms.unwrapNodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) &&
        Element.isElement(n) &&
        (LIST_TYPES.includes(n.type) || BLOCK_WRAP_TYPES.includes(n.type)),
      split: true,
    });

    const getToggleType = () => {
      if (isActive || isBlockWrapType) {
        return 'paragraph';
      }
      if (isList) {
        return 'li';
      }
      return format;
    };

    const newProperties: Partial<Element> = {
      type: getToggleType(),
    };

    Transforms.setNodes<Element>(editor, newProperties);

    if (!isActive && (isList || isBlockWrapType)) {
      const block = { type: format, children: [] };
      Transforms.wrapNodes(editor, block);
    }
  });
};
