import { NewLineageModel } from '@api/lineage/mapNewLineage';
import { NewLineageItem } from '@api/openapi.generated';

import { OneDirectionParseExceptions } from '../../../useLineageExplore/LineageExplore.context.types';

const createWalkedEdgeId = (targetSourceId?: string, id?: string) =>
  [targetSourceId, id]
    .filter((node) => node)
    .sort()
    .join('-');

export interface TraversalNode {
  direction?: 'left' | 'right';
  id: string;
  isInitialNode?: boolean;
  stackId: number;
  targetSourceId?: string;
  targetSourceStackId?: number;
}

interface TraversalArgs {
  action?: (queueNode: TraversalNode, currentNode: NewLineageItem) => void;
  calculateStraightPath?: boolean;
  initialNodeId: string;
  initialStackId?: number;
  nodesById: NewLineageModel['data'];
  oneDirectionParseExceptions?: OneDirectionParseExceptions;
  skipColumns?: boolean;
}

/*
 * Breadth First Search Traversal
 *
 * In the one direction parse, we start parsing from the root node. If the node is in the root node upstream, we add it to the left.
 * If it's in root node the downstream, we add it to the right. But we can add exceptions for this rule using the oneDirectionParseExceptions.
 * If the node guid is in the oneDirectionParseExceptions we we add it to left or right based on based on the edge direction
 * instead of the direction related to the root node.
 */
const bfsTraversal = ({
  action,
  calculateStraightPath = false,
  initialNodeId,
  initialStackId = 0,
  nodesById,
  oneDirectionParseExceptions = {},
  skipColumns = true,
}: TraversalArgs) => {
  const walked: { [id: string]: boolean } = {};
  const stackedAt: { [id: string]: number } = {};

  const visitNode = (queueNode: TraversalNode, currentNode: NewLineageItem) => {
    const { id, stackId } = queueNode;
    if (nodesById[id] && typeof stackedAt[id] === 'undefined') {
      stackedAt[id] = stackId;
    }
    action?.(queueNode, currentNode);
  };

  const queue: TraversalNode[] = [];

  if (initialNodeId) {
    queue.push({
      id: initialNodeId,
      isInitialNode: true,
      stackId: initialStackId,
    });
  }

  while (queue.length > 0) {
    const queueNode = queue.shift();

    if (!queueNode) continue;

    const { direction, id, targetSourceId } = queueNode;
    const table = nodesById[id];
    const walkedId = createWalkedEdgeId(targetSourceId, id);

    if (!table || walked[walkedId]) continue;

    walked[walkedId] = true;

    visitNode(queueNode, table);

    const sources = Object.keys(table?.source_edges ?? {}).filter((sourceId) => {
      if (nodesById[sourceId]) {
        return !skipColumns || nodesById[sourceId].node_type === 'table';
      }
      return false;
    });

    const targets = Object.keys(table?.target_edges ?? {}).filter((targetId) => {
      if (nodesById[targetId]) {
        return !skipColumns || nodesById[targetId].node_type === 'table';
      }
      return false;
    });

    if (!calculateStraightPath || (calculateStraightPath && direction !== 'left')) {
      targets.forEach((targetId) => {
        if (!oneDirectionParseExceptions[targetId]) {
          visitNode(
            {
              id: targetId,
              stackId: stackedAt[id] + (stackedAt[id] >= initialStackId ? +1 : -1),
            },
            table,
          );
        } else {
          visitNode({ id: targetId, stackId: stackedAt[id] + 1 }, table);
        }

        queue.push({
          direction: 'right',
          id: targetId,
          stackId: stackedAt[targetId],
          targetSourceId: id,
          targetSourceStackId: stackedAt[id],
        });
      });
    }

    if (!calculateStraightPath || (calculateStraightPath && direction !== 'right')) {
      sources.forEach((sourceId) => {
        if (!oneDirectionParseExceptions[sourceId]) {
          visitNode(
            {
              id: sourceId,
              stackId: stackedAt[id] + (stackedAt[id] <= initialStackId ? -1 : +1),
            },
            table,
          );
        } else {
          visitNode({ id: sourceId, stackId: stackedAt[id] - 1 }, table);
        }

        queue.push({
          direction: 'left',
          id: sourceId,
          stackId: stackedAt[sourceId],
          targetSourceId: id,
          targetSourceStackId: stackedAt[id],
        });
      });
    }
  }

  return Object.keys(walked);
};

export default bfsTraversal;
