import React from 'react';
import { RenderElementProps } from 'slate-react';

import Ellipsis from '@components/Ellipsis';
import Mention from '@components/Mention';
import { StyledMention } from '@components/Mention/Mention.styles';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import theme from '@styles/theme';

import { useFetchedMentions } from './FetchedMentionsContext';

const FetchedMention = ({ attributes, children, element }: RenderElementProps) => {
  const { isAccessDenied, isError, isFetching, mention } = useFetchedMentions(
    element.mention.guid || element.mention.id,
  );
  const { avatar, dataTypes, description, guid, isActive, name, parentGuid, routerUrl } =
    mention ?? {};

  if (isError) {
    return (
      <span {...attributes} contentEditable={false}>
        <Tooltip content="Please reload the page or try later.">
          <StyledMention color={theme.colors.gray[500]}>
            <Icon name="warning" size="16px" />
            <Ellipsis>Load failure</Ellipsis>
            {children}
          </StyledMention>
        </Tooltip>
      </span>
    );
  }

  if (!isFetching && !guid) {
    return (
      <span {...attributes} contentEditable={false}>
        <Tooltip
          content={isAccessDenied ? undefined : 'The mention may refer to a non-existent object.'}
        >
          <StyledMention color={theme.colors.v1.gray[isAccessDenied ? 900 : 500]}>
            <Icon
              color="gray.700"
              name={isAccessDenied ? 'lock-outlined' : 'warning'}
              size="16px"
            />
            <Ellipsis>
              {isAccessDenied ? 'Restricted content' : element.mention.name ?? 'Invalid mention'}
            </Ellipsis>
            {children}
          </StyledMention>
        </Tooltip>
      </span>
    );
  }

  return (
    <span {...attributes} contentEditable={false}>
      <Mention
        avatar={avatar}
        dataTypes={dataTypes}
        description={description}
        guid={guid}
        isActive={isActive}
        link={routerUrl}
        loading={isFetching}
        name={name}
        parentGuid={parentGuid}
      >
        <span>{name}</span>
        {children}
      </Mention>
    </span>
  );
};

export default FetchedMention;
