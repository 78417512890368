import styled from '@emotion/styled';

import Text from '@components/Text';

export const GroupParticipantsTooltipUser = styled(Text)`
  color: ${({ theme }) => theme.colors.v1.gray[500]};
  font-size: ${({ theme }) => theme.typography.fontSizes.sm};
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
  line-height: ${({ theme }) => theme.typography.lineHeights.default};
`;
