import React, { useState } from 'react';
import { Placement } from '@popperjs/core';

import Popper from '@components/Popper';
import Icon, { IconProps } from '@components/UI/Icon';
import useClickOutside from '@hooks/useClickOutside';
import theme from '@styles/theme';

import { StyledDropdownMenuList, StyledDropdownMenuListItem } from './DropdownMenu.styles';

export type OptionType = {
  icon?: IconProps['name'];
  label: string;
  onClick?: () => void;
};

export interface DropdownMenuProps {
  children: React.ReactElement;
  fallbackPlacements?: Placement[];
  id: string;
  isOpen?: boolean;
  options: OptionType[];
  placement?: Placement;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  children,
  fallbackPlacements = ['bottom-end', 'top-start', 'top-end'],
  id,
  isOpen = false,
  options,
  placement = 'bottom-start',
}) => {
  const [menuOpen, setMenuOpen] = useState(isOpen);
  const useClickOutsideRef = useClickOutside<HTMLUListElement>({
    disabled: !menuOpen,
    excludedTargets: () => document.querySelectorAll(`[id='dropdown-menu-anchor-${id}']`),
    onClick: () => {
      setMenuOpen(false);
    },
  });

  return (
    <Popper
      anchorComp={React.cloneElement(children, {
        id: `dropdown-menu-anchor-${id}`,
        onClick: (event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation();
          event.preventDefault();
          setMenuOpen((previous) => {
            return !previous;
          });
        },
      })}
      arrowPadding={5}
      fallbackPlacements={fallbackPlacements}
      flipPadding={{ top: 20 }}
      isOpen={menuOpen}
      offset={[0, 5]}
      placement={placement}
      usePortal
    >
      <StyledDropdownMenuList ref={useClickOutsideRef as never}>
        {options.map((item) => (
          <StyledDropdownMenuListItem
            key={item.label}
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              event.stopPropagation();
              item.onClick?.();
              setMenuOpen(false);
            }}
          >
            {item.icon && (
              <Icon color={theme.colors.v1.gray[600]} mr={1} name={item.icon} size="16px" />
            )}
            {item.label}
          </StyledDropdownMenuListItem>
        ))}
      </StyledDropdownMenuList>
    </Popper>
  );
};

export default DropdownMenu;
