import React from 'react';

import Box from '@components/Box';

const DataSourceSetupBox: React.FC = ({ children }) => {
  return (
    <Box backgroundColor="white" borderRadius="8px" m="auto" maxWidth="820px" position="relative">
      {children}
    </Box>
  );
};

export default DataSourceSetupBox;
