import { cleanLocalStorage } from '@api';

export const unmask = () => {
  // copy over masker tokens
  localStorage.setItem('Access-Token', localStorage.getItem('Masker-Access-Token') || '');
  localStorage.setItem('Access-Token-Time', localStorage.getItem('Masker-Access-Token-Time') || '');
  localStorage.setItem('Refresh-Token', localStorage.getItem('Masker-Refresh-Token') || '');
  localStorage.setItem(
    'defaultOrganization',
    localStorage.getItem('Masker-defaultOrganization') || '',
  );
  localStorage.setItem('organizations', localStorage.getItem('Masker-organizations') || '');
  localStorage.setItem('isStaff', localStorage.getItem('Masker-isStaff') || '');
  localStorage.setItem('isSuperUser', localStorage.getItem('Masker-isSuperUser') || '');

  // unset masker tokens
  cleanLocalStorage(true);
};
