import sortBy from 'lodash/sortBy';

import { TagModel } from '@api/tags/TagModel';
import { HierarchyData } from '@models/HierarchyModel';

import { TagsArray } from './Tag';

const getHierarchyOrder = (tree: any, result: any = []) => {
  if (tree == null) {
    return result;
  }

  tree.forEach((node: any) => {
    result.push(node.guid);

    if (node?.children?.length > 0) {
      getHierarchyOrder(node.children, result);
    }
  });

  return result;
};

const splitTagsByType = (tags: TagModel[] = []) => {
  const statusTags = {} as { [id: string]: TagModel };
  const categoryTags = {} as { [id: string]: TagModel };
  const dbtTags = {} as { [id: string]: TagModel };

  tags?.forEach((t) => {
    if (t.type === 'status') {
      if (statusTags[t.guid]) return;
      statusTags[t.guid] = t;
    }
    if (t.type === 'category') {
      if (categoryTags[t.guid]) return;
      categoryTags[t.guid] = t;
    }
    if (t.type === 'dbt') {
      if (dbtTags[t.guid]) return;
      dbtTags[t.guid] = t;
    }
  });

  return { category: categoryTags, dbt: dbtTags, status: statusTags };
};

export const getSortedTagsByType = ({
  tags,
  tagsHierarchy,
}: {
  tags?: TagModel[];
  tagsHierarchy?: HierarchyData[];
}) => {
  const tagsObjByType = splitTagsByType(tags);

  const sortedTags: TagsArray = {
    category: [],
    dbt: [],
    status: [],
  };

  const statusTagsArray = Object.values(tagsObjByType.status);
  const categoryTagsArray = Object.values(tagsObjByType.category);
  sortedTags.dbt = Object.values(tagsObjByType.dbt);

  const categoryHierarchyOrder = getHierarchyOrder(tagsHierarchy?.[0]?.children);
  sortedTags.category = sortBy(categoryTagsArray, (v) => categoryHierarchyOrder.indexOf(v.guid));

  const statusHierarchyOrder = getHierarchyOrder(tagsHierarchy?.[1]?.children);
  sortedTags.status = sortBy(statusTagsArray, (v) => statusHierarchyOrder.indexOf(v.guid));

  return {
    array: sortedTags,
    obj: tagsObjByType,
  };
};
