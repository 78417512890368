import React from 'react';

import { useFetchGoogleOAuthRedirect } from '@api/google';
import type { ButtonProps } from '@components/Button/Button';
import Button from '@components/Button/Button';
import { renderErrorToast } from '@components/Toast';
import Icon from '@components/UI/Icon';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import theme from '@styles/theme';

interface GoogleButtonProps extends ButtonProps {
  employeeRole?: string;
  isInviteSignUp?: boolean;
  isLogin?: boolean;
  redirectTo?: string;
  utmCampaign?: string | null;
}

const GoogleButton: React.FC<GoogleButtonProps> = ({
  disabled,
  employeeRole,
  isInviteSignUp,
  isLogin,
  redirectTo = '/',
  utmCampaign,
}) => {
  const segment = useSegmentContext();

  const { isFetching, refetch } = useFetchGoogleOAuthRedirect({
    enabled: false,
    onError: (err) => {
      if (err) {
        renderErrorToast(err.data ?? err.message);
      }
    },
    onSuccess: (d) => {
      if (d) {
        const urlParams = new URLSearchParams({
          ...d.params,
          state: JSON.stringify({
            employee_role: employeeRole,
            isLogin,
            redirect: redirectTo,
            utmCampaign,
          }),
        }).toString();

        window.location.href = `${d.url}?${urlParams}`;
      }
    },
    retry: false,
  });

  const handleButtonClick = () => {
    refetch();
    segment?.track(
      isLogin
        ? SegmentTrackEventName.LoginGoogleSignInButtonClicked
        : SegmentTrackEventName.SignUpGoogleSignUpButtonClicked,
    );
  };

  const showOutlineButton = isLogin || isInviteSignUp;

  return (
    <Button
      backgroundColor={!showOutlineButton ? theme.colors.v1.primary[800] : undefined}
      compSize={isLogin ? undefined : 'lg'}
      disabled={disabled || isFetching}
      fluid
      onClick={handleButtonClick}
      startIcon={<Icon name="google" size={isLogin ? '1.2rem' : '22px'} />}
      variant={showOutlineButton ? 'outlined' : 'default'}
    >
      {isLogin ? 'Sign in' : 'Sign up'} with Google
    </Button>
  );
};

export default GoogleButton;
