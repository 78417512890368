import styled from '@emotion/styled';
import { Header } from 'semantic-ui-react';

import PageLink from '@components/PageLink';

export const StyledPageLink = styled(PageLink)`
  span {
    font-size: ${({ theme }) => theme.typography.fontSizes.body1};
  }

  :hover {
    color: ${({ theme }) => theme.colors.gray[800]};
  }
`;

interface StyledUserCellContentProps {
  ellipsis?: boolean;
}

export const StyledUserCellContent = styled(Header.Content)<StyledUserCellContentProps>`
  padding-left: ${({ theme }) => theme.space(0.5)};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: ${({ ellipsis }) => (ellipsis ? 'nowrap' : undefined)};
`;
