import type { OpenlineageAuthToken } from '@api/openapi.generated';
import camelize from '@utils/camelize';

const mapOpenLineageAuthTokenModel = (value: OpenlineageAuthToken) => {
  return camelize(value);
};

export default mapOpenLineageAuthTokenModel;

export type OpenLineageAuthTokenModel = ReturnType<typeof mapOpenLineageAuthTokenModel>;
