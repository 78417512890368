import { Expose, Transform, Type } from 'class-transformer';

import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import { TaggedItemModel } from '@api/tags/TaggedItemModel';
import { TeamModel } from '@api/teams/TeamModel';
import { ObjectType } from '@api/types';
import { UserModel } from '@api/user/UserModel';
import { breadcrumbsToList } from '@components/Breadcrumbs';
import isEmptyRichText from '@components/RichTextEditor/helpers/isEmptyRichText';
import type { DataSourceTypesType } from '@models/DataSourceCredentials';
import { DataSourceData, DataSourceModel } from '@models/DataSourceModel';
import DataTypesModel from '@models/DataTypesModel';
import { PopularityData, PopularityModel } from '@models/PopularityModel';
import { Reference } from '@models/Reference';

export interface DatabaseData {
  dataSource?: DataSourceData;
  description?: string;
  guid: string;
  name: string;
  popularity?: PopularityData;
  richtextDescription?: string;
}

export class DatabaseModel {
  static objectType: ObjectType = 'database';

  objectType: ObjectType = DatabaseModel.objectType;

  static typeDisplay: string = 'Database';

  typeDisplay: string = DatabaseModel.typeDisplay;

  guid!: string;

  name!: string;

  description?: string;

  @Type(() => PopularityModel)
  popularity?: PopularityModel;

  @Expose({ name: 'search_name' })
  fullName?: string;

  // TODO: Remove transform https://app.shortcut.com/select-star/story/55620
  @Expose({ name: 'richtext_description' })
  @Transform((richtextDescription) =>
    isEmptyRichText(richtextDescription) ? '' : richtextDescription,
  )
  richtextDescription?: string;

  @Type(() => DataSourceModel)
  @Expose({ name: 'data_source' })
  dataSource?: DataSourceModel;

  @Expose({ name: 'data_type' })
  dataType?: string;

  @Expose({ name: 'data_source_type' })
  dataSourceType?: DataSourceTypesType;

  @Type(() => BreadcrumbModel)
  breadcrumbs?: BreadcrumbModel[];

  @Expose({ name: 'data_types' })
  @Type(() => DataTypesModel)
  dataTypes?: DataTypesModel;

  @Expose({ name: 'technical_owner' })
  @Type(() => Reference)
  technicalOwner?: Reference<UserModel | TeamModel>;

  @Expose({ name: 'business_owner' })
  @Type(() => Reference)
  businessOwner?: Reference<UserModel | TeamModel>;

  @Expose({ name: 'tagged_items' })
  @Type(() => TaggedItemModel)
  taggedItems?: TaggedItemModel[];

  get type() {
    return this.dataSource?.type;
  }

  get breadcrumbList() {
    return breadcrumbsToList(this.breadcrumbs);
  }
}
