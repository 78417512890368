import styled from '@emotion/styled';

import Box from '@components/Box';

interface ExcerptTextContainerProps {
  isSuggestion?: boolean;
  lineBreak?: boolean;
}

export const ExcerptTextContainer = styled.span<ExcerptTextContainerProps>`
  word-break: break-word;
  white-space: ${({ lineBreak }) => (lineBreak ? 'pre-wrap' : 'initial')};
  line-height: ${({ theme }) => theme.typography.lineHeights.default};
  display: inline-block;
  padding: ${({ theme }) => theme.space(0.5, 0)};
  color: ${({ isSuggestion }) => (isSuggestion ? '#8c939b' : 'inherit')};
  text-align: start;
`;

export const ShowMoreDescriptionButton = styled(Box)`
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.gray[500]};
  display: inline;
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  font-size: inherit;
  font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: none;
  }
`;

ShowMoreDescriptionButton.defaultProps = {
  as: 'button',
  noDefault: true,
};
