import type { StyledBoxProps } from '@components/Box/Box.styles';

export interface TreeColumnsConfigProps extends StyledBoxProps {}

export type TreeColumnsConfigResult = [
  TreeColumnsConfigProps | null,
  TreeColumnsConfigProps | null,
  TreeColumnsConfigProps | null,
  TreeColumnsConfigProps | null,
  TreeColumnsConfigProps | null,
];

interface GetWidthsConfigArgs {
  showDescriptions?: boolean;
  showUsage?: boolean;
}

const shareStyles = {
  alignItems: 'center',
  compDisplay: 'flex',
};

const nameColumnStyles = {
  flexGrow: 1,
  minWidth: '38%',
  pl: 2,
  pr: 2,
};

const descriptionColumnStyles = {
  compWidth: '28%',
  maxWidth: '28%',
  minWidth: '28%',
};

const usageColumnStyles = {
  minWidth: '100px',
  pl: 1,
  pr: 1,
};

const popularityColumnStyles = {
  minWidth: '120px',
  pl: 1,
  pr: 1,
};

const impactScoreColumnStyles = {
  minWidth: '135px',
  pl: 1,
  pr: 1,
};

const columns = {
  description: { ...shareStyles, ...descriptionColumnStyles },
  impactScore: { ...shareStyles, ...impactScoreColumnStyles },
  name: { ...shareStyles, ...nameColumnStyles },
  popularity: { ...shareStyles, ...popularityColumnStyles },
  usage: { ...shareStyles, ...usageColumnStyles },
};

/**
 * @deprecated use treeColumns configuration.
 * null means that column shouldn't be rendered.
 */
const getTreeColumnsConfig = ({
  showDescriptions,
  showUsage,
}: GetWidthsConfigArgs): TreeColumnsConfigResult => {
  if (showDescriptions && !showUsage) {
    return [columns.name, columns.description, null, columns.popularity, columns.impactScore];
  }

  if (showUsage && !showDescriptions) {
    return [columns.name, null, columns.usage, columns.popularity, columns.impactScore];
  }

  if (showUsage && showDescriptions) {
    return [
      columns.name,
      columns.description,
      columns.usage,
      columns.popularity,
      columns.impactScore,
    ];
  }

  return [columns.name, null, null, columns.popularity, columns.impactScore];
};

export default getTreeColumnsConfig;
