import styled from '@emotion/styled';

import Box from '@components/Box';
import type { StyledBoxProps } from '@components/Box/Box.styles';

import type { VariantColor } from './Code.styles.variants';
import { colorVariants } from './Code.styles.variants';

export interface StyledCodeProps extends Omit<StyledBoxProps, 'ref'> {
  variantColor?: VariantColor;
}

export const StyledCode = styled(Box)<StyledCodeProps>`
  ${({ variantColor = 'black' }) => colorVariants[variantColor]}
`;

StyledCode.defaultProps = {
  as: 'code',
  compDisplay: 'inline-block',
  noDefault: true,
  role: 'code',
};
