import React from 'react';
import { RouterLink } from '@routing/router';

import AppHeader from '@components/AppHeader';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import Link from '@components/Link';
import Text from '@components/Text';
import Title from '@components/Title/Title';
import useUserContext from '@context/User/User';
import { StyledContent, Wrapper } from '@pages/FreeTrialExpired/FreeTrialExpired.styles';
import MetadataDecorator from '@utils/MetadataDecorator';

const SubscriptionExpired: React.FC = () => {
  const { organization } = useUserContext();
  return (
    <Wrapper>
      <MetadataDecorator title="Your subscription has expired" />
      <AppHeader disableAccess />
      <StyledContent>
        <Box compDisplay="flex" flexDirection="column" maxWidth="30vw">
          <Title>Subscription expired </Title>
          <Text display="inline" fontSize="16px" fontWeight="regular" mb={2}>
            Your subscription has expired on{' '}
            {organization?.subscriptionEndsAt?.local(true).format('LL')}. If you would like to keep
            using Select Star please renew your subscription in{' '}
            <Link as={(props) => <RouterLink {...props} to="/admin/billing" />} underline>
              Billing
            </Link>
            , or{' '}
            <Link
              href="https://calendly.com/selectstar/next-steps"
              rel="noopener noreferrer"
              target="_blank"
              underline
            >
              schedule time
            </Link>{' '}
            to talk to us.
          </Text>
          <Text fontSize="16px" fontWeight="regular" m={0}>
            Thanks,
          </Text>
          <Text fontSize="16px" fontWeight="regular" mb={1}>
            Select Star Team
          </Text>
          <Box compDisplay="flex" justifyContent="flex-end">
            <Button
              as="a"
              href="https://calendly.com/selectstar/next-steps"
              rel="noopener noreferrer"
              target="_blank"
            >
              Contact Sales
            </Button>
          </Box>
        </Box>
      </StyledContent>
    </Wrapper>
  );
};

export default SubscriptionExpired;
