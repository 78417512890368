import React, { useState } from 'react';

import { useFetchCostAnalysisTotal } from '@api/costAnalysis';
import type TimeModel from '@api/costAnalysis/TimeModel';
import { useDateRangePickerContext } from '@components/DateRangePicker/DateRangePicker.context';
import CostByDayOfWeekTable from '@components/Table/CostByDayOfWeekTable';
import HR from '@components/UI/HR';
import StatSummaryBar from '@components/UI/StatSummaryBar';

import DailyUsageCostChart from '../charts/DailyUsageCostChart';
import WeeklyUsageCostChart from '../charts/WeeklyUsageCostChart';
import CostAnalysisSharedFilter from '../CostAnalysisSharedFilter';
import useWarehouseSelect from '../CostAnalysisSharedFilter/useWarehouseSelect';

import SectionTitle, { SectionTitleWrapper, Title } from './SectionTitle';

const CostAnalysisOverviewTab: React.FC = () => {
  const [active, setActive] = useState<TimeModel | undefined>(undefined);
  const { dateRange } = useDateRangePickerContext();
  const { selectedWarehouses } = useWarehouseSelect();

  const { data: totalData, isLoading } = useFetchCostAnalysisTotal({
    params: {
      end_date: dateRange[1],
      start_date: dateRange[0],
      warehouses: selectedWarehouses,
    },
  });

  return (
    <>
      <SectionTitleWrapper my={3}>
        <Title>Overview</Title>
        <CostAnalysisSharedFilter />
      </SectionTitleWrapper>
      <StatSummaryBar
        data={[
          {
            description: 'Warehouses',
            tooltip: 'Count of warehouses with >0 credit usage',
            value: totalData?.warehouse?.formattedActive,
          },
          {
            description: 'Total Spend',
            tooltip: 'Total compute credit spend from Warehouses',
            value: totalData?.warehouse?.formattedCreditsUsed,
          },
          {
            description: 'Total Queries',
            tooltip: 'Count of successful queries from Warehouses',
            value: totalData?.run?.formattedTotal,
          },
          {
            description: 'Active Dashboards',
            tooltip: 'Count of dashboards with at least 1 successful query run',
            value: totalData?.dashboard?.formattedActive,
          },
          {
            description: 'Active Users',
            tooltip: 'Count of users with at least 1 successful query run',
            value: totalData?.user?.formattedActive,
          },
        ]}
        loading={isLoading}
      />
      <HR />
      <SectionTitle
        my={4}
        subtitle="Total compute spend & query count from all Warehouses"
        title="Total Cost & Query Count by Day"
      />
      <DailyUsageCostChart range={dateRange} warehouses={selectedWarehouses} />
      <HR />
      <SectionTitle
        my={4}
        subtitle="Average compute spend per hour and day of the week"
        title="Average Cost per Hour and Day of the Week"
      />
      <WeeklyUsageCostChart
        active={active}
        onBarClick={setActive}
        range={dateRange}
        warehouses={selectedWarehouses}
      />
      {active && (
        <CostByDayOfWeekTable active={active} range={dateRange} warehouses={selectedWarehouses} />
      )}
    </>
  );
};

export default CostAnalysisOverviewTab;
