class AnalyticsSearchTermModel {
  static objectType: string = 'analyticssearchterm';

  objectType: string = AnalyticsSearchTermModel.objectType;

  term!: string;

  count!: number;
}

export default AnalyticsSearchTermModel;
