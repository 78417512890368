import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

import type { Theme } from '@styles/theme';

const dotAnimation = (theme: Theme) => keyframes`
  0%,49%  {
    background-color: ${theme.colors.v1.primary[25]};
    color: white;
  }

  50%,80%  {
    background-color: ${theme.colors.v1.primary[600]};
    color: ${theme.colors.v1.primary[25]};
  }

  81%,100%  {
    background-color: ${theme.colors.v1.primary[25]};
    color: white;
  }
`;

export const LoadingDotsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15px;
  background: ${({ theme }) => theme.colors.v1.gray[400]};
  border-radius: 8px 8px 8px 0px;
  width: 30px;
`;

export const Dot = styled.div<{ delay: number }>`
  width: 3px;
  height: 3px;
  border-radius: 50%;
  margin: 0 2px;
  background-color: ${({ theme }) => theme.colors.v1.primary[25]};
  animation: ${({ theme }) => dotAnimation(theme)} 1.5s infinite;
  animation-delay: ${(props) => props.delay}s;
`;
