import React, { useState } from 'react';

import { useFetchAnalyticsActivities, useFetchAnalyticsActivitiesCsv } from '@api/analytics';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import CircularLoader from '@components/CircularLoader';
import DateRangePicker from '@components/DateRangePicker';
import { Column, Row } from '@components/Grid';
import TabError from '@components/TabContent/TabError';
import { renderErrorToast } from '@components/Toast';
import theme from '@styles/theme';
import { Filter } from '@utils';
import downloadCSV from '@utils/downloadCSV';

import { defaultAnalyticsRange } from '../../Analytics.constants';

import ActivityLogTable from './tables/ActivityLogTable';

export const DEFAULT_FILTERS: Filter.FilterOptions = {
  order: '-performed_on',
  page: 1,
  page_size: 30,
  sortColumn: 'date',
  sortDirection: 'descending',
};

const SEARCH_CONFIG: { [key: string]: keyof Filter.FilterOptions } = {
  event: 'message',
  type: 'type',
  user: 'user',
};

export const SORT_CONFIG: { [key: string]: string } = {
  date: 'performed_on',
  event: 'message,-performed_on',
  type: 'activity_type,-performed_on',
  user: 'user,-performed_on',
};

const AnalyticsActivityLogTab: React.FC = () => {
  const [[startDate, endDate], setDateRange] = useState<[string, string]>(defaultAnalyticsRange);

  const filterService = Filter.useUpdateFilters(
    DEFAULT_FILTERS,
    SEARCH_CONFIG,
    SORT_CONFIG,
    '-performed_on',
  );

  const { data, isError, isLoading } = useFetchAnalyticsActivities({
    params: {
      ...Filter.setParams(filterService.filter),
      end_date: endDate,
      start_date: startDate,
    },
  });

  const { isLoading: csvIsLoading, refetch: fetchCsvData } = useFetchAnalyticsActivitiesCsv({
    enabled: false,
    onError: (error) => {
      const errorMsg = error?.data?.[0] || 'Sorry, something went wrong';
      renderErrorToast(errorMsg);
    },
    onSuccess: (csvResponse: string) => {
      if (!csvResponse) return;
      downloadCSV(csvResponse, `activity-log-${startDate}-${endDate}.csv`);
    },
    params: {
      end_date: endDate,
      start_date: startDate,
    },
    refetchOnWindowFocus: false,
  });

  const handleFilterChange = (range: [string, string]) => {
    setDateRange(range);
    filterService.changePage(1);
  };

  const handleCsvExportClick = () => {
    fetchCsvData();
  };

  const totalPages = data ? Math.ceil(data.count / DEFAULT_FILTERS.page_size!) : 1;

  if (isError) {
    return <TabError />;
  }

  return (
    <Box compDisplay="flex" flexDirection="column" gap={1}>
      <Row>
        <Column xs={12}>
          <Box alignItems="center" compDisplay="flex" gap={1} justifyContent="flex-end">
            <Button
              color={theme.colors.gray[800]}
              disabled={csvIsLoading || isLoading}
              onClick={handleCsvExportClick}
              startIcon={csvIsLoading && <CircularLoader compSize={1} />}
              startIconName={!csvIsLoading ? 'export' : undefined}
              variant="text"
            >
              Export to CSV
            </Button>
            <DateRangePicker initialRange={[startDate, endDate]} onChange={handleFilterChange} />
          </Box>
        </Column>
      </Row>
      <Row>
        <Column xs={12}>
          <ActivityLogTable
            filterService={filterService}
            loading={isLoading}
            rows={data?.results}
            totalPages={totalPages}
          />
        </Column>
      </Row>
    </Box>
  );
};

export default AnalyticsActivityLogTab;
