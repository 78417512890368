import styled from '@emotion/styled';

export const ColumnsSearchBar = styled.div`
  position: relative;
  margin: ${({ theme }) => theme.space(0, 1)};
`;

export const CleanIconButton = styled.button`
  position: absolute;
  right: ${({ theme }) => theme.space(1)};
  top: 0;
  bottom: 0;
`;
