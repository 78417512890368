import { Theme } from '../theme';

const spaceMap = {
  m: ['margin'],
  mb: ['margin-bottom'],
  ml: ['margin-left'],
  mr: ['margin-right'],
  mt: ['margin-top'],
  my: ['margin-top', 'margin-bottom'],
  mx: ['margin-left', 'margin-right'],
  p: ['padding'],
  pb: ['padding-bottom'],
  pl: ['padding-left'],
  pr: ['padding-right'],
  pt: ['padding-top'],
  py: ['padding-top', 'padding-bottom'],
  px: ['padding-left', 'padding-right'],
};

export type AllowedSpace = keyof typeof spaceMap;

export type MakeSpaceProps = Partial<Record<AllowedSpace, number | 'auto'>>;

const makeSpace = (theme: Theme, props: MakeSpaceProps) => {
  return Object.keys(spaceMap)
    .filter((prop) => prop in spaceMap)
    .map((spaceName) => {
      const cssPropNames = spaceMap[spaceName as AllowedSpace];
      const spaceValue = props[spaceName as AllowedSpace];

      return cssPropNames !== undefined && spaceValue !== undefined
        ? cssPropNames.map((prop) => `${prop}: ${theme.space(spaceValue)};`)
        : null;
    });
};

export default makeSpace;
