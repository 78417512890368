import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useMultipleSelection } from 'downshift';

import { MultiSelectContext } from './MultiSelect.context';
import type { Option, SelectValue } from './types';

interface UseSelectStateProps {
  isDropdown?: boolean;
  onChange?: (newValue: SelectValue) => void;
  value?: SelectValue;
}

const useSelectState = (props?: UseSelectStateProps) => {
  const { isDropdown, onChange, value } = props ?? {};
  const [flatOptions, setFlatOptions] = useState<Option[]>([]);
  const {
    getDropdownProps,
    selectedItems,
    setSelectedItems: internalSetSelectedItems,
  } = useMultipleSelection<Option>({ selectedItems: value });

  const { setSelectedItems: contextSetSelectedItems } = useContext(MultiSelectContext);

  const multiOptionsUpdateFunctions = useMemo(
    () => [contextSetSelectedItems, onChange, internalSetSelectedItems],
    [contextSetSelectedItems, internalSetSelectedItems, onChange],
  );

  const updateFlatOptions = useCallback(
    (expandedOption: Option, isExpanded: boolean) => {
      const expandedItemIndex = flatOptions.findIndex(
        (item) => item.value === expandedOption.value,
      );

      let newFlatTree: Option[] = [];

      if (isExpanded && expandedOption.children) {
        newFlatTree = [
          ...flatOptions.slice(0, expandedItemIndex + 1),
          ...expandedOption.children,
          ...flatOptions.slice(expandedItemIndex + 1),
        ];
      } else {
        const childrenLength = expandedOption.children?.length ?? 0;
        newFlatTree = [
          ...flatOptions.slice(0, expandedItemIndex + 1),
          ...flatOptions.slice(expandedItemIndex + 1 + childrenLength),
        ];
      }

      setFlatOptions(newFlatTree);
    },
    [flatOptions],
  );

  const setSelectedItems = useCallback(
    (arg: Option[]) => {
      if (isDropdown) {
        onChange?.(arg);
      } else {
        multiOptionsUpdateFunctions.forEach((func) => func?.(arg));
      }
    },
    [multiOptionsUpdateFunctions, isDropdown, onChange],
  );

  const addSelectedItem = useCallback(
    (arg: Option) => {
      const newSelectedItems = [...selectedItems, arg];
      setSelectedItems(newSelectedItems);
    },
    [selectedItems, setSelectedItems],
  );

  const removeSelectedItem = useCallback(
    (arg: Option) => {
      const newSelectedItems = selectedItems.filter((item) => item.value !== arg.value);
      setSelectedItems(newSelectedItems);
    },
    [selectedItems, setSelectedItems],
  );

  useEffect(() => {
    internalSetSelectedItems(value ?? []);
  }, [value, internalSetSelectedItems]);

  return {
    addSelectedItem,
    flatOptions,
    getDropdownProps,
    removeSelectedItem,
    selectedItems,
    setFlatOptions,
    setSelectedItems,
    updateFlatOptions,
  };
};

export default useSelectState;
