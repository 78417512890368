import React, { useMemo, useState } from 'react';
import type { Cell } from 'react-table';

import { useFetchCostAnalysisWarehouses } from '@api/costAnalysis';
import type WarehouseModel from '@api/costAnalysis/WarehouseModel';
import Highlighter from '@components/Highlighter';
import NotApplicable from '@components/NotApplicable';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import type { CostAnalysisSharedFilterParams } from '@pages/Admin/AdminCostAnalysisPage/CostAnalysisSharedFilter';
import { FilterOptions, setParams, useUpdateFilters } from '@utils/filters';

const sortConfig = {
  averageCreditsUsed: 'average_credits_used',
  name: 'name',
  size: 'size',
  totalCreditsUsed: 'total_credits_used',
  totalRun: 'total_run',
} as const;

const searchConfig: { [key: string]: keyof FilterOptions } = {
  name: 'search',
};

const requestConfig: FilterOptions = {
  order: `-${sortConfig.totalCreditsUsed}`,
  page: 1,
  page_size: 20,
};

const initialTableSort = [{ desc: true, id: 'totalCreditsUsed' }];

interface CostByWarehouseTableProps extends CostAnalysisSharedFilterParams {}

const CostByWarehouseTable: React.FC<CostByWarehouseTableProps> = ({ range, warehouses }) => {
  const [showFilter, setShowFilter] = useState(false);
  const { changePage, filter, search, sort } = useUpdateFilters(
    requestConfig,
    searchConfig,
    sortConfig,
    requestConfig.order,
  );

  const { data, isLoading } = useFetchCostAnalysisWarehouses({
    params: { ...setParams(filter), end_date: range[1], start_date: range[0], warehouses },
  });

  const columns: ColumnConfig<WarehouseModel>[] = useMemo(
    () => [
      {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      {
        Cell: ({ column, row: { original } }: Cell<WarehouseModel>) => (
          <Highlighter
            autoEscape
            searchWords={[column?.filterValue]}
            textToHighlight={original.name}
          >
            {original.name}
          </Highlighter>
        ),
        Header: 'Warehouse',
        accessor: (d) => d.name,
        disableHiding: true,
        id: 'name',
        width: '150%',
      },
      {
        Header: 'Size',
        accessor: (d) => (d.size ? d.size : <NotApplicable />),
        disableFilters: true,
        id: 'size',
        width: '10rem',
      },
      {
        Header: 'Credit',
        accessor: (d) => d.formattedTotalCreditsUsed,
        disableFilters: true,
        id: 'totalCreditsUsed',
        width: '200%',
      },
      {
        Header: 'Query Count',
        accessor: (d) => d.formattedTotalRun,
        disableFilters: true,
        id: 'totalRun',
        width: '10rem',
      },
      {
        Header: 'Avg Credit/Run',
        accessor: (d) => d.formattedAverageCreditsUsed,
        id: 'averageCreditsUsed',
        width: '10rem',
      },
    ],
    [],
  );

  const totalPages = data && filter.page_size ? Math.ceil(data.count / filter.page_size) : 1;

  return (
    <TableStyled>
      <Table
        basic="very"
        changePage={changePage}
        columns={columns}
        compact
        data={data?.results ?? []}
        disableRowSelect
        initialState={{
          sortBy: initialTableSort,
        }}
        loading={isLoading}
        manualFilters
        manualPagination
        manualSortBy
        setFilters={search}
        setSortBy={sort}
        showFilter={showFilter}
        sortable
        toggleFilter={() => {
          setShowFilter((prev) => !prev);
        }}
        totalPages={totalPages}
        unstackable
      />
    </TableStyled>
  );
};

export default CostByWarehouseTable;
