import { Expose, Type } from 'class-transformer';

import { ColumnData, ColumnModel } from '@api/columns/ColumnModel';

export interface ConditionData {
  leftColumn?: ColumnData;
  raw: string;
  rightColumn?: ColumnData;
}

export class ConditionModel {
  raw!: string;

  @Expose({ name: 'right_column' })
  @Type(() => ColumnModel)
  rightColumn?: ColumnModel;

  @Expose({ name: 'left_column' })
  @Type(() => ColumnModel)
  leftColumn?: ColumnModel;
}
