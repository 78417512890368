import React from 'react';
import getIcons from '@configs/icons/getIcons';

import { AppliesTo as AppliesToType } from '@api/customAttributes/CustomAttributeModel';
import Box from '@components/Box';
import Text from '@components/Text';
import Icon from '@components/UI/Icon';
import { DataSourceOptions } from '@models/DataSourceOptions';
import theme from '@styles/theme';

import { getCustomAttributesAssetsConfig } from '../customAttributesAssetsConfig';

import { StyledWrapper } from './AppliesTo.styles';

interface AppliesToProps {
  appliesToData: AppliesToType[];
}

const AppliesTo: React.FC<AppliesToProps> = ({ appliesToData }) => {
  return (
    <Box compDisplay="flex" flexDirection="column" gap={1} justifyContent="center" py={1}>
      {appliesToData?.map((appliesToItem) => (
        <StyledWrapper
          key={appliesToItem.dataSourceType}
          compDisplay="flex"
          data-testid={`applies-to-${appliesToItem.dataSourceType}`}
          gap={1}
        >
          <Box alignItems="center" compDisplay="flex">
            <Icon name={getIcons({ dataSourceType: appliesToItem.dataSourceType }).dataSource} />
            <Box ml={1}>
              <Text fontSize={theme.typography.fontSizes.body1} fontWeight="bold">
                {DataSourceOptions.find((ds) => ds.value === appliesToItem.dataSourceType)?.text}
              </Text>
            </Box>
          </Box>
          <Box compDisplay="flex" flexWrap="wrap" id="applies-to">
            {appliesToItem.objectTypes.map((objectType, index) => {
              const dataType = appliesToItem.dataTypes[index];
              return (
                <Box key={`${objectType}-${dataType}`} mr={0.5}>
                  <Text fontSize={theme.typography.fontSizes.body1}>
                    {
                      getCustomAttributesAssetsConfig(appliesToItem.dataSourceType)?.find(
                        (configAsset) =>
                          dataType
                            ? configAsset.objectType === objectType &&
                              configAsset?.dataType === dataType
                            : configAsset.objectType === objectType,
                      )?.label
                    }
                  </Text>
                </Box>
              );
            })}
          </Box>
        </StyledWrapper>
      ))}
    </Box>
  );
};

export default AppliesTo;
