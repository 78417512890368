export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
export type Breakpoints<T = number> = Record<Breakpoint, T>;

const breakpoints: Breakpoints = {
  '2xl': 1800,
  lg: 992,
  md: 768,
  sm: 576,
  xl: 1200,
  xs: 0,
};

export default breakpoints;
