import React from 'react';
import { Cell } from 'react-table';

import QueryLogRecordModel from '@api/queryLog/QueryLogRecordModel';
import Box from '@components/Box';
import DateTime from '@components/DateTime';
import Link from '@components/Link';
import QueryModalWithTargetBox from '@components/Modal/QueryModal/QueryModalWithTargetBox';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import UserCell from '@components/Table/Cells/UserCell';
import type { ColumnConfig } from '@components/Table/Table/types';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import { displayDuration } from '@utils/moment';

const getRecentQueriesColumns = (userColName: string, basic?: boolean) => {
  const columns: ColumnConfig<QueryLogRecordModel>[] = [
    {
      Header: SearchHeader,
      disableFilters: true,
      disableResizing: true,
      disableSortBy: true,
      id: 'search',
      width: 32,
    },
    {
      Cell: ({ row }: Cell<QueryLogRecordModel>) => {
        const queryRecord = row.original;
        return (
          <Box alignItems="center" compDisplay="flex" gap={0.5}>
            <Box alignItems="center" compDisplay="flex" gap={0.5}>
              <Tooltip content={queryRecord?.dataTypes?.tooltips.dataSource}>
                <Icon name={queryRecord?.dataTypes?.icons.dataSource!} size="16px" />
              </Tooltip>
              <Icon name="query" size="15px" />
            </Box>
            <QueryModalWithTargetBox
              dataSourceType={queryRecord.query.database?.dataSourceType}
              displayText={queryRecord?.query?.displayName}
              queryLogRecords={[queryRecord]}
              tooltipText={queryRecord?.query?.rawSql}
            />
          </Box>
        );
      },
      Header: `Query`,
      accessor: (d) => d.query?.displayName,
      disableHiding: true,
      id: 'query',
      width: '160%',
    },
    {
      Cell: (props: Cell<QueryLogRecordModel>) => {
        const queryRecord = props.row.original;
        return (
          <UserCell
            {...props}
            user={
              queryRecord?.dsuser?.user?.fullName ? queryRecord?.dsuser?.user : queryRecord?.dsuser
            }
          />
        );
      },
      Header: () => userColName || 'User',
      accessor: (d) => (d?.dsuser?.user?.fullName ? d?.dsuser?.user.fullName : d?.dsuser?.fullName),
      disableResizing: true,
      id: 'user',
      width: '140%',
    },
    {
      Cell: ({ row }: Cell<QueryLogRecordModel>) => {
        const queryRecord = row.original;
        return <DateTime datetime={queryRecord.executedOn} format="fullDateFormat" />;
      },
      Header: 'Start Time',
      accessor: (d) => d.executedOn?.toDate(),
      disableFilters: true,
      disableResizing: true,
      id: 'startTime',
      sortDescFirst: true,
      sortType: 'datetime',
      width: 150,
    },
    {
      Cell: ({ row }: Cell<QueryLogRecordModel>) => {
        const queryRecord = row.original;
        return queryRecord.duration ? displayDuration(queryRecord.duration) : null;
      },
      Header: 'Run Time',
      accessor: (d) => (d.duration ? displayDuration(d.duration) : null),
      disableFilters: true,
      disableResizing: true,
      id: 'runTime',
      sortDescFirst: true,
    },
  ];

  if (basic) {
    const basicColumns: ColumnConfig<QueryLogRecordModel>[] = [
      {
        Cell: ({ row }: Cell<QueryLogRecordModel>) => {
          const queryRecord = row?.original;

          return (
            <Box alignItems="center" compDisplay="flex" spacing={0.5}>
              <Icon name="query" size="15px" />
              <QueryModalWithTargetBox
                dataSourceType={queryRecord.query.database?.dataSourceType}
                queryLogRecords={[queryRecord]}
              >
                <Link as="span" color="inherit" fontSize="inherit" role="button" underlineOnHover>
                  {queryRecord?.query?.displayName}
                </Link>
              </QueryModalWithTargetBox>
            </Box>
          );
        },
        Header: `Query`,
        accessor: (d) => d.query?.displayName,
        id: 'query',
      },
    ];

    return basicColumns;
  }

  return columns;
};

export default getRecentQueriesColumns;
