import styled from '@emotion/styled';

interface StyledMetadataObjectButtonsToolbarGroupProps {
  hasExternalButton: boolean;
}

export const StyledMetadataObjectButtonsToolbarGroup = styled.div<StyledMetadataObjectButtonsToolbarGroupProps>`
  display: grid;
  grid-template-columns: ${({ hasExternalButton }) => (hasExternalButton ? 'max-content' : '1fr')};
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-gap: 1px;
  background-color: ${({ theme }) => theme.colors.v1.gray[200]};
  border: 1px solid ${({ theme }) => theme.colors.v1.gray[200]};
  border-radius: ${({ theme }) => theme.radius.lg};
  overflow: hidden;
`;

interface StyledMetadataObjectButtonsToolbarButtonProps {
  isFirst: boolean;
  isLast: boolean;
}

export const StyledMetadataObjectButtonsToolbarButton = styled.button<StyledMetadataObjectButtonsToolbarButtonProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.space(0.5)};
  padding: ${({ theme }) => theme.space(0, 1)};
  height: 58px;
  min-width: 65px;
  background-color: ${({ theme }) => theme.colors.white};

  &:hover {
    background-color: ${({ theme }) => theme.colors.v1.gray[100]};

    svg {
      transform: scale(1.2);
    }
  }
`;
