import { Editor } from 'slate';

export const getActiveInlineStyles = (editor: Editor) => {
  return new Set(Object.keys(Editor.marks(editor) ?? {}));
};

/**
 * Toggles the active inline style.
 */
export const toggleStyle = (editor: Editor, style: string) => {
  const activeStyles = getActiveInlineStyles(editor);
  if (activeStyles.has(style)) {
    Editor.removeMark(editor, style);
    // The link will be active only after the user add the URL in the AddLinkModal
  } else if (style !== 'link') {
    Editor.addMark(editor, style, true);
  }
};
