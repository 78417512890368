import React from 'react';

import Box from '@components/Box';
import Ellipsis from '@components/Ellipsis/Ellipsis.styles';
import IconButton from '@components/IconButton';
import OpenInNewTabButton from '@components/OpenInNewTabButton';
import SourceColumnsTabRow from '@components/TabContent/SourceColumnsTab/SourceColumnsTabRow';
import PopularityCell from '@components/Table/Cells/PopularityCell';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import DataTypesModel from '@models/DataTypesModel';
import { PopularityModel } from '@models/PopularityModel';

interface SourceColumnsTabTablesRowProps {
  dataTypes?: DataTypesModel;
  isOpen: boolean;
  isToggleDisabled: boolean;
  name: string;
  onToggle: () => void;
  popularity?: PopularityModel;
  routePath: string;
}

const SourceColumnsTabTableRow: React.FC<SourceColumnsTabTablesRowProps> = ({
  dataTypes,
  isOpen,
  isToggleDisabled,
  name,
  onToggle,
  popularity,
  routePath,
}) => {
  return (
    <SourceColumnsTabRow
      firstColumn={
        <Box alignItems="center" compDisplay="flex" pl={5}>
          <Box alignItems="center" compDisplay="flex" flexShrink={0} mr={0.5}>
            <IconButton
              color="transparent"
              disabled={isToggleDisabled}
              onClick={onToggle}
              title="Toggle"
            >
              <Icon color="#0C3476" name={isOpen ? 'down' : 'right'} size="16px" />
            </IconButton>
            <Tooltip content={dataTypes?.tooltips.dataSource}>
              <Icon name={dataTypes?.icons.dataSource!} size="16px" />
            </Tooltip>
            <Tooltip content={dataTypes?.tooltips.dataType}>
              <Icon name={dataTypes?.icons.dataType!} size="16px" />
            </Tooltip>
          </Box>
          <Ellipsis multipleLinesNumber="2">{name}</Ellipsis>
          <OpenInNewTabButton
            url={
              process.env.REACT_APP_CHROME_EXTENSION_BUILD
                ? `${process.env.REACT_APP_SS_APP_URL}${routePath}`
                : routePath
            }
          />
        </Box>
      }
      secondColumn={<PopularityCell popularity={popularity} />}
    />
  );
};

export default SourceColumnsTabTableRow;
