import React from 'react';
import { RouterLink } from '@routing/router';

import Box from '@components/Box';
import Ellipsis from '@components/Ellipsis';
import Icon from '@components/UI/Icon';
import { useModal } from '@context/Modal';
import theme from '@styles/theme';

import {
  StyledChatBotHierarchyItem,
  StyledChatBotHierarchyItemIconButton,
  StyledChatBotHierarchyItemLink,
} from './ChatBotHierarchyItem.styles';

interface ChatBotItemItemProps {
  guid: string;
  name: string;
  useNewLayout?: boolean;
}

const ChatBotHierarchyItem: React.FC<ChatBotItemItemProps> = ({ guid, name, useNewLayout }) => {
  const { MODAL_IDS, openModal } = useModal();

  const handleOnDeleteClick = () => {
    openModal(MODAL_IDS.deleteChatbotConversation, { guid, name });
  };

  return (
    <StyledChatBotHierarchyItem alignItems="center" compDisplay="flex" compWidth="100%" gap={0.5}>
      <StyledChatBotHierarchyItemLink
        as={RouterLink}
        color="inherit"
        compDisplay="block"
        compWidth="100%"
        fontSize="inherit"
        to={`/chatbot/${guid}`}
      >
        <Box
          alignItems="center"
          as="span"
          compDisplay="flex"
          gap={0.5}
          pl={useNewLayout ? 0 : 0.625}
          py={0.625}
        >
          <Icon color={useNewLayout ? 'currentcolor' : 'gray.700'} name="message" size="14px" />
          <Box as="span" compDisplay="grid" flexGrow="1">
            <Ellipsis>{name}</Ellipsis>
          </Box>
        </Box>
      </StyledChatBotHierarchyItemLink>
      <StyledChatBotHierarchyItemIconButton
        backgroundColor="transparent"
        iconColor={theme.colors.v1.gray[500]}
        iconName="trash"
        iconSize="16px"
        onClick={handleOnDeleteClick}
        size="sm"
      />
    </StyledChatBotHierarchyItem>
  );
};

export default React.memo(ChatBotHierarchyItem);
