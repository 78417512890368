import React, { useRef, useState } from 'react';
import { useLocation, useParams } from '@routing/router';

import { useFetchFolder } from '@api/bifolder';
import CircularLoader from '@components/CircularLoader';
import NotFoundError from '@components/Error/NotFoundError';
import stripSpaces from '@utils/stripSpaces';

import { CONTAINER_CONFIG, DATA_TYPE_OPTIONS } from './constants';
import DashboardPage from './DashboardPage';
import DatasetPage from './DatasetPage';
import { TestViewProps } from './types';

const FOLDER_QUERY = stripSpaces(`{
  name,
  breadcrumbs,
  data_types,
  data_source{
    guid
  }
}`);

const DATASET_OPTIONS = [DATA_TYPE_OPTIONS[3].value, DATA_TYPE_OPTIONS[4].value];

const PowerBIDashboardPage: React.FC = () => {
  const lastPath = useRef<string>('');
  const location = useLocation();
  const { dsGuid, guid } = useParams<{ dsGuid?: string; guid: string }>();

  const { data, isError, isLoading } = useFetchFolder(guid, {
    enabled: Boolean(guid),
    params: { query: FOLDER_QUERY },
  });

  const defaultValue = DATA_TYPE_OPTIONS[0].value;
  const [dataTypeFilter, setDataTypeFilter] = useState<string>(defaultValue);

  if (lastPath.current && location.pathname !== lastPath.current) {
    setDataTypeFilter(defaultValue);
    lastPath.current = location.pathname;
  }

  const viewProps: TestViewProps = {
    breadcrumbList: data?.breadcrumbList,
    dataTypeFilter,
    dataTypeOptions: DATA_TYPE_OPTIONS,
    dataTypes: guid ? data?.dataTypes : CONTAINER_CONFIG?.dataTypes,
    dsGuid: dsGuid ?? data?.dataSource.guid,
    guid,
    key: guid,
    onSelectDataType: setDataTypeFilter,
    title: guid ? data?.name : CONTAINER_CONFIG?.title,
  };

  if ((!dsGuid && !guid) || isError) {
    return <NotFoundError />;
  }

  if (guid && isLoading) {
    return <CircularLoader centered cover />;
  }

  if (DATASET_OPTIONS.includes(dataTypeFilter)) {
    return <DatasetPage {...viewProps} />;
  }

  return <DashboardPage {...viewProps} />;
};

export default PowerBIDashboardPage;
