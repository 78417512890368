import { Expose, plainToClass, Transform, Type } from 'class-transformer';
import moment from 'moment';

import { TeamData, TeamModel } from '@api/teams/TeamModel';
import DataTypesModel from '@models/DataTypesModel';
import type { MapObjectToAvatarReturnType } from '@models/mapObjectToAvatar';
import mapObjectToAvatar from '@models/mapObjectToAvatar';
import { UserSettingsData, UserSettingsModel } from '@models/UserSettingsModel';

export interface UserData {
  avatar?: string;
  createdOn?: moment.Moment;
  email: string;
  firstName?: string;
  fullName?: string;
  guid: string;
  isActive?: boolean;
  isAgreedToTerms?: boolean;
  isInvitationPending?: boolean;
  lastActivity?: moment.Moment;
  lastLogin?: moment.Moment;
  lastName?: string;
  lastSSOUse?: moment.Moment;
  location?: string;
  mappedAvatar?: MapObjectToAvatarReturnType;
  settings?: UserSettingsData;
  team?: TeamData;
  title?: string;
}

export class UserModel {
  static objectType: string = 'user';

  objectType: string = UserModel.objectType;

  static typeDisplay: string = 'User';

  typeDisplay: string = UserModel.typeDisplay;

  guid!: string;

  @Expose({ name: 'first_name' })
  firstName?: string;

  @Expose({ name: 'last_name' })
  lastName?: string;

  email!: string;

  title?: string;

  location?: string;

  @Expose({ name: 'is_active' })
  isActive!: boolean;

  @Type(() => UserSettingsModel)
  settings!: UserSettingsModel;

  get fullName() {
    if (this.firstName && this.lastName) return `${this.firstName} ${this.lastName}`;
    if (this.firstName) return this.firstName;
    if (this.lastName) return this.lastName;
    return this.email;
  }

  get mappedAvatar() {
    return mapObjectToAvatar(this);
  }

  dataTypes: DataTypesModel = plainToClass(DataTypesModel, {
    object_type: 'user',
  });

  @Expose({ name: 'avatar' })
  avatar?: string;

  @Type(() => TeamModel)
  team?: TeamModel;

  @Expose({ name: 'last_login' })
  @Transform((value) => value && moment(value))
  lastLogin?: moment.Moment;

  @Expose({ name: 'last_activity' })
  @Transform((value) => value && moment(value))
  lastActivity?: moment.Moment;

  @Expose({ name: 'has_usable_password' })
  hasUsablePassword?: boolean;

  @Expose({ name: 'is_invitation_pending' })
  isInvitationPending?: boolean;

  @Expose({ name: 'created_on' })
  @Transform((value) => value && moment(value))
  createdOn?: moment.Moment;

  @Expose({ name: 'is_agreed_to_terms' })
  isAgreedToTerms?: boolean;

  @Expose({ name: 'downstream_tables' })
  downstreamTables?: number = 0;

  @Expose({ name: 'downstream_dashboards' })
  downstreamDashboards?: number = 0;
}
