import React, { useCallback, useContext, useEffect, useRef } from 'react';

import Box from '@components/Box';
import ErdOptionsDropdown from '@components/ExploreTree/ErdOptionsDropdown';
import Input from '@components/Input/Input.v1';
import QueryModalWithTargetBox from '@components/Modal/QueryModal/QueryModalWithTargetBox';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import DataTypesModel from '@models/DataTypesModel';
import TableLastQuery from '@pages/TabbedTableViewPage/TableLastQuery';

import ExploreTreeContext from './context';
import { CleanIconButton, ColumnsSearchBar } from './ExploreColumns.styles';
import { OpenLineageCallback } from './types';

interface ExploreHeadingProps {
  chevronLeft: 'none' | 'open' | 'close';
  chevronRight: 'none' | 'open' | 'close';
  componentIdentifier?: string;
  dataTypes?: DataTypesModel;
  fullTableName?: string;
  guid: string;
  hasDbtModel: boolean | undefined;
  isSearchActivated: boolean;
  isTableHidden?: boolean;
  isTablePinned?: boolean;
  onChange: (value: string, key: string) => void;
  onClick: (tableKey: string) => void;
  onSearchIconClick: (key: string) => void;
  openLineage: OpenLineageCallback;
  searchColumnName: string;
  setHoveringTableHeader: (tableKey: string | null) => void;
  showInputSearch: boolean;
  showSearchIcon: boolean;
  tableKey: string;
  tableName: string;
}

const ExploreHeading: React.FC<ExploreHeadingProps> = ({
  chevronLeft,
  chevronRight,
  componentIdentifier,
  dataTypes,
  fullTableName,
  guid,
  hasDbtModel,
  isSearchActivated,
  isTableHidden,
  isTablePinned,
  onChange,
  onClick,
  onSearchIconClick,
  openLineage,
  searchColumnName,
  setHoveringTableHeader,
  showInputSearch,
  showSearchIcon,
  tableKey,
  tableName,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { rectHeight, rectWidth, tablesById, xScale, yScale } = useContext(ExploreTreeContext);
  const table = tablesById[tableKey];

  const openErd = useCallback(() => {
    openLineage({ componentIdentifier, direction: 'all', tables: tableKey });
  }, [componentIdentifier, openLineage, tableKey]);

  const enter = useCallback(() => {
    setHoveringTableHeader(tableKey);
  }, [setHoveringTableHeader, tableKey]);

  const leave = useCallback(() => {
    setHoveringTableHeader(null);
  }, [setHoveringTableHeader]);

  useEffect(() => {
    const handleActivateSearch = (e: KeyboardEvent) => {
      if (e.metaKey && e.key === 'f' && showSearchIcon && !isSearchActivated) {
        e.preventDefault();
        onSearchIconClick(guid);
        inputRef?.current?.focus();
      }
    };

    window.addEventListener('keydown', handleActivateSearch);
    return () => {
      window.removeEventListener('keydown', handleActivateSearch);
    };
  }, [showSearchIcon, onSearchIconClick, isSearchActivated, guid]);

  if (!table) return null;

  const showTableEventsQuery = dataTypes?.objectType === 'table';
  const showTableQuery = !showTableEventsQuery && table.query;
  const isImplicit = dataTypes?.dataType === 'implicit';

  return (
    <foreignObject
      height={yScale(rectHeight * (showInputSearch ? 2 : 1))}
      width={xScale(rectWidth)}
      x={0}
      y={0}
    >
      <div className="explore-heading">
        <div
          className="explore-heading-wrapper"
          onMouseEnter={enter}
          onMouseLeave={leave}
          role="button"
          tabIndex={-1}
        >
          <div className="erd-offset-placeholder" />
          <div
            className="icons-container"
            onClick={() => onClick(tableKey)}
            role="button"
            tabIndex={-1}
          >
            <Tooltip content={dataTypes?.tooltips?.dataSource}>
              <Icon name={dataTypes?.icons.dataSource!} size="15px" />
            </Tooltip>
            {hasDbtModel && (
              <Tooltip content={dataTypes?.tooltips?.dataType}>
                <Icon name="dbt" size="15px" />
              </Tooltip>
            )}
            <Tooltip
              content={isImplicit ? 'Temporary table (inferred)' : dataTypes?.tooltips?.dataType}
            >
              <Icon name={dataTypes?.icons.dataType!} size="15px" />
            </Tooltip>
          </div>
          <Tooltip content={fullTableName ?? tableName}>
            <span className="heading-text ellipses" onClick={() => onClick(tableKey)}>
              <span className="ellipses">{tableName}</span>
              <Icon
                className={isTablePinned ? 'pinActive' : 'pin'}
                name={isTablePinned ? 'pin-selected' : 'pin-unselected'}
                size="12px"
              />
            </span>
          </Tooltip>
          {isTablePinned && showTableQuery && (
            <Tooltip action content="Open SQL">
              <Box as="span" compHeight="100%" noDefault>
                <QueryModalWithTargetBox
                  codeString={table.query}
                  dataSourceType={dataTypes?.dataSourceType}
                >
                  <button className="iconGroup" type="button">
                    <Icon name="code-block" size="15px" />
                  </button>
                </QueryModalWithTargetBox>
              </Box>
            </Tooltip>
          )}
          {isTablePinned && showTableEventsQuery && (
            <Tooltip action content="Open SQL">
              <Box as="span" compHeight="100%" noDefault>
                <TableLastQuery dataSourceType={dataTypes?.dataSourceType} guid={guid}>
                  <button className="iconGroup" type="button">
                    <Icon name="code-block" size="15px" />
                  </button>
                </TableLastQuery>
              </Box>
            </Tooltip>
          )}
          {showSearchIcon && (
            <Tooltip action content={showInputSearch ? 'Close search' : 'Open search'}>
              <button
                className="iconGroup"
                data-testid="column-search"
                onClick={() => {
                  onSearchIconClick(guid);

                  if (!showInputSearch) {
                    setTimeout(() => {
                      inputRef?.current?.focus();
                    }, 50);
                  } else {
                    onChange('', guid);
                  }
                }}
                type="button"
              >
                <Icon name="search" size="15px" />
              </button>
            </Tooltip>
          )}
          {(chevronLeft === 'open' || chevronRight === 'open') && !isTableHidden && (
            <ErdOptionsDropdown
              guid={guid}
              optionsSelections={[
                {
                  id: '0',
                  options: [
                    {
                      icon: 'plus-circle' as const,
                      id: 'open-direct-lineage',
                      label: 'Open connected tables',
                      onClick: openErd,
                    },
                  ],
                },
              ]}
            >
              {({ onBlur, triggerId }) => (
                <button className="iconGroup" id={triggerId} onBlur={onBlur} type="button">
                  <Icon name="settings" size="15px" />
                </button>
              )}
            </ErdOptionsDropdown>
          )}
        </div>
        {showInputSearch && (
          <ColumnsSearchBar>
            <Input
              ref={inputRef}
              compSize="xs"
              endIcon={
                searchColumnName && (
                  <CleanIconButton onClick={() => onChange('', guid)}>
                    <Icon name="close" size="12px" />
                  </CleanIconButton>
                )
              }
              onChange={(e) => onChange(e.target.value, guid)}
              placeholder="Search..."
              value={searchColumnName}
            />
          </ColumnsSearchBar>
        )}
      </div>
    </foreignObject>
  );
};

export default React.memo<ExploreHeadingProps>(ExploreHeading);
