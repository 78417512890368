import { Type } from 'class-transformer';

import { Reference } from '@models/Reference';

export class MentionModel {
  static objectType: string = 'mention';

  objectType: string = MentionModel.objectType;

  static typeDisplay: string = 'Mention';

  typeDisplay: string = MentionModel.typeDisplay;

  guid!: string;

  @Type(() => Reference)
  source!: Reference<any>;

  @Type(() => Reference)
  target!: Reference<any>;
}
