import styled from '@emotion/styled';

export const StyledImage = styled.img`
  border-style: none;
  display: block;
  vertical-align: middle;
  max-width: 100%;
  height: ${({ height = 'auto' }) => height};
  width: ${({ width = 'auto' }) => width};
`;
