import React from 'react';

import RecentQueriesTab from '@components/TabContent/RecentQueriesTab';
import { TabContentProps } from '@components/Tabs/types';

import { ProfileType } from '../ProfilePage.types';

import { MostQueriedTabProps } from './MostQueriedMainTab';

const query = `{
  guid,
  external_id,
  executed_on,
  duration,
  query {
    name,
    guid,
    raw_sql,
    database,
    last_executed_on,
    created_by {
      guid,
      first_name,
      last_name,
      avatar
    },
    dsuser_created_by {
      guid,
      name,
      display_name
    }
  },
  dsuser {
    guid,
    name,
    display_name,
    user {
      guid,
      first_name,
      last_name,
      avatar
    },
    data_source{
      guid,
      name,
      type
    }
  }
}`;

interface ProfileRecentQueriesTabProps extends TabContentProps {
  guid: string;
  profileType: ProfileType;
}

const ProfileRecentQueriesTab: React.FC<ProfileRecentQueriesTabProps> = ({
  guid,
  profileType,
  stickyHeader,
}) => {
  return (
    <RecentQueriesTab
      hiddenColumns={profileType === ProfileType.user ? ['user'] : undefined}
      requestConfig={{
        order: '-executed_on',
        page_size: profileType === ProfileType.user ? 100 : 20,
        query,
        teams: profileType === ProfileType.team ? [guid] : undefined,
        users: profileType === ProfileType.user ? [guid] : undefined,
      }}
      stickyHeader={stickyHeader}
      userColName="Member"
    />
  );
};

export default React.memo<MostQueriedTabProps>(ProfileRecentQueriesTab);
