import styled from '@emotion/styled';
import * as CSS from 'csstype';

import { FontWeight } from '@styles/theme/typography';

const StyledFilterContainer = styled.div({
  alignItems: 'center',
  color: 'rgba(0, 0, 0, 0.3)',

  display: 'flex',
});

interface FilterContainerProps {
  backgroundColour?: CSS.Property.BackgroundColor;
  fontColor?: CSS.Property.Color;
  fontSize?: CSS.Property.FontSize;
  fontWeight?: FontWeight;
}

// eslint-disable-next-line import/prefer-default-export
export const StyledFacetContainer = styled(StyledFilterContainer)<FilterContainerProps>`
  padding: ${({ theme }) => theme.space(2)};
  height: 100%;
  width: 100%;
  z-index: 1;
  background-color: ${({ backgroundColour = '#f2f5f9' }) => backgroundColour};
  font-weight: ${({ fontWeight = 'regular', theme }) => theme.typography.fontWeights[fontWeight]};
  color: ${({ fontColor }) => fontColor};
  font-size: ${({ fontSize }) => fontSize};
  flex-wrap: wrap;
`;
