import React from 'react';

import { TabContentProps } from '@components/Tabs/types';
import type { TabRoutesConfig } from '@components/TabsRouter';
import TabsRouter from '@components/TabsRouter';
import { DataSourceTypesType } from '@models/DataSourceCredentials';

import { ProfileType } from '../ProfilePage.types';

import MostQueriedSubTabDashboards from './MostQueriedSubTabDashboards';
import MostQueriedSubTabTables from './MostQueriedSubTabTables';

export interface MostQueriedTabProps extends TabContentProps {
  dataSourceType?: DataSourceTypesType;
  guid: string;
  profileType: ProfileType;
  stickyHeader?: boolean;
}

const MostQueriedMainTab: React.FC<MostQueriedTabProps> = ({
  dataSourceType,
  guid,
  profileType,
  stickyHeader,
}) => {
  const tabsRouterConfig: TabRoutesConfig[] = [
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => (
        <MostQueriedSubTabTables
          dataSourceType={dataSourceType}
          guid={guid}
          profileType={profileType}
        />
      ),
      default: true,
      label: 'Tables',
      route: '/tables',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => (
        <MostQueriedSubTabDashboards
          dataSourceType={dataSourceType}
          guid={guid}
          profileType={profileType}
        />
      ),
      label: 'Dashboards',
      route: '/dashboards',
    },
  ];

  return <TabsRouter config={tabsRouterConfig} sticky={stickyHeader} subTabs />;
};

export default React.memo<MostQueriedTabProps>(MostQueriedMainTab);
