interface TransformArrayToObjectResult<T> {
  [key: string]: T;
}

const transformArrayToObject = <T extends { key: string }>(
  data: T[],
): TransformArrayToObjectResult<T> => {
  const acc: TransformArrayToObjectResult<T> = {};

  for (let i = 0; i < data.length; i += 1) {
    const item = data[i];

    if (item.key) {
      acc[item.key] = data[i];
    }
  }

  return acc;
};

export default transformArrayToObject;
