import { BasePoints } from '../RichTextEditor.types';

interface Distances {
  distanceFromAnchor: number;
  distanceFromFocus: number;
}

interface Options {
  direction?: 'left' | 'right';
}

const calculateCharsSelection = (
  { distanceFromAnchor, distanceFromFocus }: Distances,
  { anchor, focus }: BasePoints,
  options: Options = { direction: 'right' },
) => {
  const { direction } = options;
  const directionModifier = direction === 'right' ? 1 : -1;
  return {
    anchor: {
      path: anchor.path,
      offset: anchor.offset + directionModifier * distanceFromAnchor,
    },
    focus: {
      path: focus.path,
      offset: focus.offset + directionModifier * distanceFromFocus,
    },
  };
};

export default calculateCharsSelection;
