import React from 'react';

import { CustomAttributeSearchResult, SearchModel } from '@api/search/SearchModel';
import { TaggedItemModel } from '@api/tags/TaggedItemModel';
import AppTag from '@components/AppTag';
import Box from '@components/Box';
import Breadcrumbs from '@components/Breadcrumbs';
import Highlighter from '@components/Highlighter';
import { MentionData } from '@components/Mention';
import Popularity from '@components/Popularity';
import Tooltip from '@components/Tooltip';
import capitalizeFirstChar from '@utils/capitalizeFirstChar';
import truncateDescription from '@utils/truncateDescription';

import useSearchHighlightedWords from '../useSearchHighlightedWords';

import {
  SearchResultDescription,
  SearchResultPath,
  SearchResultPopularity,
  SearchResultTags,
  SearchResultTitle,
  SearchResultTitleRow,
  StyledSearchResultBreadcrumbs,
} from './SearchResult.styles';
import SearchResultIcon from './SearchResultIcon';
import { SearchTopIcon } from './SearchTopIcon';

export const getIconTooltipText = (item: SearchModel | MentionData) => {
  const { dataType, dataTypes, objectType, typeLabel } = item;
  const label = capitalizeFirstChar(typeLabel || objectType);

  if (dataTypes) {
    return dataTypes?.tooltips?.dataType;
  }

  if (dataType) {
    return `${label} (${dataType})`;
  }

  return label;
};

const isSearchedTag = (tagName: string, searchedText: string) => {
  return tagName.trim().toLowerCase() === searchedText.trim().toLowerCase();
};

interface SearchResultProps {
  clickableBreadcrumbs?: boolean;
  hideDescriptions?: boolean;
  isMentionItem?: boolean;
  isQuickSearch?: boolean;
  searchItem: SearchModel | MentionData;
  searchSuggestions?: string[];
  searchText: string;
}

const SearchResult: React.FC<SearchResultProps> = ({
  clickableBreadcrumbs = true,
  hideDescriptions,
  isMentionItem,
  isQuickSearch,
  searchItem,
  searchSuggestions,
  searchText,
}) => {
  const {
    avatar,
    customAttributeValues,
    dataSourceType,
    dataTypes,
    description,
    guid,
    isActive,
    name,
    objectType,
    popularity,
    suggestedDescription,
    taggedItems,
  } = searchItem;

  const highlightedWords = useSearchHighlightedWords(searchText, searchSuggestions);

  const mappedAvatar =
    dataTypes?.objectType === 'user'
      ? {
          guid,
          isActive,
          isDeleted: false,
          name,
          src: avatar,
        }
      : undefined;

  const shortenedDescription = truncateDescription(
    description || suggestedDescription,
    218,
    searchText,
  );

  const customAttributeString = customAttributeValues
    ? truncateDescription(
        customAttributeValues
          .map((cav: CustomAttributeSearchResult) => `${cav.name}: ${cav.value}`)
          .join(' | '),
        218,
        searchText,
      )
    : null;

  return (
    <>
      <SearchResultPath isMentionItem={isMentionItem} isQuickSearch={isQuickSearch}>
        {objectType !== 'wildcard' && (
          <Tooltip content={getIconTooltipText(searchItem)}>
            <>
              <SearchTopIcon dataTypes={dataTypes} mr={0.5} />
              {objectType === 'datasource' && dataSourceType}
            </>
          </Tooltip>
        )}
        <StyledSearchResultBreadcrumbs>
          {objectType === 'comment' ? (
            description
          ) : (
            <Breadcrumbs
              clickable={clickableBreadcrumbs}
              color="#8C939B"
              filterValue={searchText}
              fontSize="14px"
              fontWeight={400}
              items={(searchItem as SearchModel).breadcrumbList}
            />
          )}
        </StyledSearchResultBreadcrumbs>
      </SearchResultPath>
      <SearchResultTitleRow>
        <Tooltip content={getIconTooltipText(searchItem)}>
          <span>
            <SearchResultIcon
              dataTypes={dataTypes}
              guid={guid}
              mappedAvatar={mappedAvatar}
              name={name}
            />
          </span>
        </Tooltip>
        <SearchResultTitle>
          <Highlighter
            autoEscape
            className="title-highlighter"
            searchWords={highlightedWords}
            textToHighlight={name}
          />
        </SearchResultTitle>
        {taggedItems && (
          <SearchResultTags>
            {taggedItems.map((item: TaggedItemModel) => (
              <AppTag
                key={item.tag.guid}
                bolded={isSearchedTag(item.tag.name, searchText)}
                item={item}
                tag={item.tag}
                variant="outlined"
              />
            ))}
          </SearchResultTags>
        )}
        <SearchResultPopularity isMentionItem={isMentionItem}>
          {Boolean(popularity?.popularity) && (
            <Popularity
              dataTypes={dataTypes}
              priority={popularity?.formattedPopularity}
              text={`Popularity score: ${popularity?.popularity}`}
            />
          )}
        </SearchResultPopularity>
      </SearchResultTitleRow>
      {description && objectType !== 'comment' && !hideDescriptions && (
        <SearchResultDescription>
          <Highlighter
            autoEscape
            searchWords={highlightedWords}
            textToHighlight={shortenedDescription || ''}
          />
        </SearchResultDescription>
      )}
      {customAttributeValues && !hideDescriptions && (
        <Box mt={1}>
          <SearchResultDescription>
            <Highlighter
              autoEscape
              searchWords={highlightedWords}
              textToHighlight={customAttributeString || ''}
            />
          </SearchResultDescription>
        </Box>
      )}
    </>
  );
};

export default React.memo(SearchResult);
