import useMutation, { UseMutationOptions } from '@hooks/useMutation';

import { identifyWithExternalTools, setTokenResponseData } from '.';

export interface TokenResponseType {
  access: string;
  default_organization?: string;
  email: string;
  first_name: string;
  is_staff: boolean;
  is_superuser: boolean;
  organizations: { [orgGuid: string]: { role: string } };
  refresh: string;
}

export const usePostTokenLogin = (options?: UseMutationOptions<TokenResponseType>) => {
  return useMutation<TokenResponseType>({
    ...options,
    method: 'POST',
    onSuccess: (data, variables, context) => {
      setTokenResponseData(data);
      identifyWithExternalTools(data);
      options?.onSuccess?.(data, variables, context);
    },
    url: '/token/',
  });
};
