import type { DataSource } from '@configs/dataSources/types';
import { matchPath } from 'react-router';

import type { DataType, ObjectType } from '@api/types';

import CONTAINER_CONFIG from './config';
import type { ContainerConfig } from './types';

type GetContainerPageConfigArgs = {
  configObject?: ContainerConfig;
  dataTypes?: {
    dataSourceType?: DataSource;
    dataType?: DataType;
    objectType?: ObjectType;
  };
  pathname?: string;
};

const getContainerPageConfig = ({
  configObject = CONTAINER_CONFIG,
  dataTypes,
  pathname,
}: GetContainerPageConfigArgs) => {
  if (dataTypes) {
    const { dataSourceType, dataType, objectType } = dataTypes;
    const source = configObject?.[dataSourceType!];
    const object = source?.[objectType!];
    const data = object?.[dataType!];

    return data || object?.default || source?.default;
  }

  if (pathname) {
    return Object.values(configObject).find((el) =>
      matchPath(pathname, {
        path: el.default.path,
      }),
    )?.default;
  }
  return undefined;
};

export default getContainerPageConfig;
