import React from 'react';
import getDebug from 'debug';

import { DashboardModel } from '@api/dashboards/DashboardModel';
import { useFetchMetricsRelatedDashboards } from '@api/metrics';
import TabError from '@components/TabContent/TabError';
import MetricsRelatedDashboardsTable from '@components/Table/RelatedDashboardsTable/MetricsRelatedDashboardsTable';
import { TabContentProps } from '@components/Tabs/types';

const debug = getDebug('selectstar:tab-loading');

interface Props extends TabContentProps {
  fullTableName: string;
  guid: string;
}

export default React.memo<Props>(function RelatedDashboardTab({ guid }) {
  const { data: relatedDashboardsResponse, isError } = useFetchMetricsRelatedDashboards(guid);

  if (isError) return <TabError />;

  const relatedDashboards: DashboardModel[] =
    relatedDashboardsResponse?.results
      .map((entry) => (entry.item && entry.item.obj) || false)
      .filter((item): item is DashboardModel => Boolean(item)) || [];

  if (relatedDashboards) debug('display RelatedDashboardsTab');
  return !relatedDashboards ? null : (
    <MetricsRelatedDashboardsTable data={relatedDashboards} itemCount={relatedDashboards?.length} />
  );
});
