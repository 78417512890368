import { NodeSource, SidebarProps } from './types';

const sidebarInit = (props: SidebarProps) => {
  const tableProps = ['sourceTableGuids', 'targetTableGuids'];
  const columnProps = ['sourceEdges', 'targetEdges'];
  const { columns, nodeKey, tables, type } = props;
  const traversalProps = type === 'table' ? tableProps : columnProps;
  const allNodes = (type === 'table' ? tables : columns) as NodeSource[];
  const currentNode = allNodes.find((_) => _.key === nodeKey);

  return { allNodes, currentNode, traversalProps };
};

export default sidebarInit;
