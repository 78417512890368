import { ActivityModel } from '@api/activity/ActivityModel';
import { activityTypeToUserAction } from '@api/activity/utils';
import AnalyticsActivityModel from '@api/analytics/AnalyticsActivityModel';
import capitalizeFirstChar from '@utils/capitalizeFirstChar';

/**
 * We generate pre- and post-target text separately because in some situations we want the action text to wrap
 * the target link. For example, for a column ("#NUMBER") description update we'd want to generate:
 * { preTarget: 'updated', postTarget: 'description' }, which is displayed as "User updated #NUMBER description".
 */
interface ActionText {
  postTarget?: string;
  preTarget?: string;
}

export const activityToActionText = (
  activity: ActivityModel | AnalyticsActivityModel,
): ActionText => {
  const { activityType, generatedBy, targetObjectType } = activity;
  const action = activityTypeToUserAction(activityType);

  if (generatedBy === 'ingestion') {
    // if activity was generated by an ingestion, we know that activity is an ActivityModel, and that it has a data field
    const { data } = activity as ActivityModel;
    const preTarget = capitalizeFirstChar(targetObjectType);
    if (['create', 'delete'].includes(activityType!) && targetObjectType === 'table') {
      // when a table is created or deleted, we want to mention that it was created or deleted with N columns
      const { column_count: columnCount } = JSON.parse(data!);
      return {
        postTarget: `${action} with ${columnCount} ${columnCount === 1 ? 'column' : 'columns'}`,
        preTarget,
      };
    }
    if (activityType === 'update') {
      if (targetObjectType === 'column' && activity.field === 'external_type') {
        // when a column's external type is updated, we want to mention both the old and the new type
        const { new_value: newValue, old_value: oldValue } = JSON.parse(data!);
        return { postTarget: `data type changed from ${oldValue} to ${newValue}`, preTarget };
      }
    }
    return { postTarget: action, preTarget };
  }

  if (generatedBy === 'user' && activityType === 'update') {
    /*
     * when a user updates an object, for example modifies a column description, we want to be more precise
     * when we display the activity, for example: "User updated #NUMBER description"
     */
    if (activity.field === 'name') {
      return { preTarget: 'renamed' };
    }
    return {
      postTarget: {
        description: 'description',
        is_primary_key: 'primary key status',
        represented_as: 'represented as',
        richtext_represented_as: 'represented as',
        tag_set: 'tags',
      }[activity.field!],
      preTarget: action,
    };
  }

  return { preTarget: action };
};
