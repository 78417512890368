import mapLineageEdgeJobModel, { LineageEdgeJobModel } from '@api/jobs/LineageEdgeJobModel.v1';
import type { NodeSource } from '@components/ExploreSidebar/types';

const getEdgeJobs = (sourceEdges: NodeSource['sourceEdges']) => {
  if (!sourceEdges) {
    return undefined;
  }

  const jobs: LineageEdgeJobModel[] = [];
  Object.keys(sourceEdges).forEach((key) => {
    sourceEdges[key]?.jobs?.forEach((job) => {
      if (job) {
        jobs.push(mapLineageEdgeJobModel(job));
      }
    });
  });

  return jobs.length > 0 ? jobs : undefined;
};

export default getEdgeJobs;
