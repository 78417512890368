import React from 'react';

import Box from '@components/Box';
import type { SidebarInfoProps } from '@components/ExploreSidebar/SidebarInfo';
import SidebarInfo from '@components/ExploreSidebar/SidebarInfo';
import { StyledTab, StyledTabs } from '@components/Tabs';

import type { ErdExploreConnectedTablesProps } from './ErdExploreConnectedTables';
import ErdExploreConnectedTables from './ErdExploreConnectedTables';

interface ErdExploreSidebarProps extends SidebarInfoProps {
  showTabs?: boolean;
}

const ErdExploreSidebar: React.FC<ErdExploreSidebarProps & ErdExploreConnectedTablesProps> = ({
  columns,
  nodeKey,
  onAllCheckboxesClick,
  onCheckboxItemClick,
  showTabs,
  startingTableId,
  tables,
  type,
  visibleTablesIds,
}) => {
  return (
    <Box compDisplay="flex" compHeight="100%" flexDirection="column">
      <Box mb={1}>
        <SidebarInfo columns={columns} nodeKey={nodeKey} type={type} />
      </Box>
      <StyledTabs>
        <StyledTab className="active" style={{ fontSize: 15 }}>
          Connected Tables
        </StyledTab>
      </StyledTabs>
      {showTabs && (
        <ErdExploreConnectedTables
          onAllCheckboxesClick={onAllCheckboxesClick}
          onCheckboxItemClick={onCheckboxItemClick}
          startingTableId={startingTableId}
          tables={tables}
          visibleTablesIds={visibleTablesIds}
        />
      )}
    </Box>
  );
};

export default ErdExploreSidebar;
