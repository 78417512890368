import React from 'react';

import Box from '@components/Box';
import IntegrationNode from '@components/LineageExplore/components/Common/IntegrationNode';
import { ExploreNode } from '@components/LineageExplore/LineageExplore.types';
import { SPACE } from '@styles/theme/grid';

import { SIZES } from '../config';

import { getBINodeLabel } from './BINode.utils';

const BINode: React.FC<ExploreNode> = ({ children, data }) => {
  const {
    dataTypes,
    isOpen = false,
    isUnfocused,
    key,
    onExpandNode,
    style,
    tablesCount = 0,
  } = data;

  const name = getBINodeLabel(dataTypes);

  return (
    <IntegrationNode
      contentGap={SIZES.rowGap.table / SPACE}
      dataTypes={dataTypes}
      isOpen={isOpen}
      isUnfocused={isUnfocused}
      name={name}
      nodeKey={key}
      onExpandNode={onExpandNode}
      style={style}
      tablesCount={tablesCount}
      type="bi"
    >
      <Box compDisplay="flex" flexDirection="column" gap={SIZES.rowGap.table / SPACE}>
        {children}
      </Box>
    </IntegrationNode>
  );
};

export default BINode;
