import React from 'react';

import { SearchOptions } from '@components/ExploreTree/atoms';
import OrderByButton from '@components/OrderByButton';
import { OrderBy } from '@components/OrderByButton/OrderByButton.styles';
import HeaderWithInfo from '@components/Table/Headers/HeaderWithInfo';

interface SidebarTreeTableHeaderProps {
  description?: string;
  id: SearchOptions['sortBy'];
  label: string;
  onClick: (sortBy: SearchOptions['sortBy'], orderBy: OrderBy) => void;
  orderBy: OrderBy;
  sortBy: SearchOptions['sortBy'];
}

const SidebarTreeTableHeader: React.FC<SidebarTreeTableHeaderProps> = ({
  description,
  id,
  label,
  onClick,
  orderBy,
  sortBy,
}) => {
  return (
    <OrderByButton
      onClick={(sortByProp) => onClick(id, sortByProp)}
      orderBy={sortBy === id ? orderBy : 'default'}
    >
      <HeaderWithInfo description={description} name={label} />
    </OrderByButton>
  );
};

export default SidebarTreeTableHeader;
