import styled from '@emotion/styled';

import Box from '@components/Box';
import IconButton from '@components/IconButton';
import Text from '@components/Text';

export const StyledCollectionCard = styled(Box)`
  transition: all 0.2s ease-in-out;
  border-color: ${({ theme }) => theme.colors.v1.gray[400]};

  &:hover {
    border-color: ${({ theme }) => theme.colors.v1.gray[600]};
  }
`;

StyledCollectionCard.defaultProps = {
  backgroundColor: 'white',
  border: '1px solid',
  borderRadius: 'lg',
  boxShadow: 'xs',
  compDisplay: 'flex',
  compHeight: '188px',
  compWidth: '100%',
  cursor: 'pointer',
  flexDirection: 'column',
  gap: 1,
  px: 2.5,
  py: 2.25,
};

interface StyledCollectionCardIconButtonProps {
  backgroundColor: string;
  isSelected?: boolean;
}

export const StyledCollectionCardIconButton = styled(
  IconButton,
)<StyledCollectionCardIconButtonProps>`
  width: ${({ theme }) => theme.space(6)};
  height: ${({ theme }) => theme.space(6)};
  min-width: ${({ theme }) => theme.space(6)};
  min-height: ${({ theme }) => theme.space(6)};
  border-radius: 50%;
  border: 1px solid;
  border-color: ${({ backgroundColor, isSelected, theme }) =>
    isSelected ? theme.colors.v1.gray[300] : backgroundColor};
  overflow: hidden;
`;

StyledCollectionCardIconButton.defaultProps = {
  size: 'xl',
  variant: 'opaque',
};

const COLLECTION_CARD_DESCRIPTION_LINE_HEIGHT = 20;
const COLLECTION_CARD_DESCRIPTION_LINES_COUNT = 3;
const COLLECTION_CARD_DESCRIPTION_MAX_HEIGHT =
  COLLECTION_CARD_DESCRIPTION_LINE_HEIGHT * COLLECTION_CARD_DESCRIPTION_LINES_COUNT;

export const StyledCollectionCardDescription = styled(Text)`
  max-height: ${COLLECTION_CARD_DESCRIPTION_MAX_HEIGHT}px;
  -webkit-line-clamp: ${COLLECTION_CARD_DESCRIPTION_LINES_COUNT};
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
`;

StyledCollectionCardDescription.defaultProps = {
  color: 'gray.500',
  fontSize: 'body2',
  lineHeight: `${COLLECTION_CARD_DESCRIPTION_LINE_HEIGHT}px`,
};

const COLLECTION_CARD_TITLE_LINE_HEIGHT = 24;
const COLLECTION_CARD_TITLE_LINES_COUNT = 2;
const COLLECTION_CARD_TITLE_MAX_HEIGHT =
  COLLECTION_CARD_TITLE_LINE_HEIGHT * COLLECTION_CARD_TITLE_LINES_COUNT;

export const StyledCollectionCardTitle = styled(Text)`
  max-height: ${COLLECTION_CARD_TITLE_MAX_HEIGHT}px;
  -webkit-line-clamp: ${COLLECTION_CARD_TITLE_LINES_COUNT};
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
`;

StyledCollectionCardTitle.defaultProps = {
  color: 'gray.700',
  fontSize: 'h2',
  fontWeight: 'medium',
  lineHeight: `${COLLECTION_CARD_TITLE_LINE_HEIGHT}px`,
};
