import React from 'react';

import { useFetchAnalyticsActivitiesHomepage } from '@api/analytics';
import Box from '@components/Box';
import MetadataGrid from '@components/MetadataGrid';
import Avatar from '@components/UI/Avatar';
import { useUserContext } from '@context/User';

import MainPageSection from '../MainPageSection';
import MetadataGridHomepageCard from '../MetadataGridHomepageCard';

interface TopViewedPagesProps {
  showDescription?: boolean;
  topBy: 'team' | 'user' | 'org';
}

const TopViewedPages: React.FC<TopViewedPagesProps> = ({ showDescription, topBy }) => {
  const { organization, user } = useUserContext();
  const getParams = () => {
    if (topBy === 'user') {
      return {
        target_models: '-document,-tag',
        users: user?.guid,
      };
    }

    if (topBy === 'team') {
      return {
        target_models: '-document,-tag',
        teams: user?.team?.guid,
      };
    }

    return {
      target_models: '-document,-tag',
    };
  };

  const { data, isLoading } = useFetchAnalyticsActivitiesHomepage({
    params: {
      order: '-count',
      page: 1,
      page_size: 4,
      types: 'view',
      ...getParams(),
    },
  });

  if (isLoading || !data?.results?.length) {
    return null;
  }

  const getLabel = () => {
    if (topBy === 'user') {
      return `${user?.firstName}'s Top Items`;
    }

    if (topBy === 'team') {
      return (
        <Box alignItems="center" compDisplay="flex" flexWrap="wrap">
          Most Viewed in{' '}
          <Avatar guid={user?.team?.guid} mx={0.5} name={user?.team?.name} size="24px" />
          {user?.team?.name}
        </Box>
      );
    }

    return `Top Pages in ${organization?.name}`;
  };

  return (
    <MainPageSection label={getLabel()}>
      <MetadataGrid data={data?.results}>
        {(obj) => {
          return (
            <MetadataGridHomepageCard
              obj={{
                breadcrumbs: obj.breadcrumbs,
                dataType: obj.pageDataType,
                dataTypes: obj.dataTypes,
                description: obj.description,
                guid: obj.pageGuid,
                name: obj.pageName,
                objectType: obj.pageObjectType,
                parentGuid: obj.pageParentGuid,
              }}
              showDescription={showDescription}
            />
          );
        }}
      </MetadataGrid>
    </MainPageSection>
  );
};

export default TopViewedPages;
