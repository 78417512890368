import { QueryClient } from 'react-query';

const MINUTE = 1000 * 60;

const fetchClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: MINUTE * 20,
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      staleTime: MINUTE * 15,
    },
  },
});

export { useQueryClient as useFetchClient } from 'react-query';

export default fetchClient;
