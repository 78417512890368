import { DashboardTypes } from '@api/dashboards/DashboardTypes';
import { PopularityModel } from '@models/PopularityModel';

/**
 * @todo Architecture. Discuss if BE can return tooltip data.
 */
const createPopularityDescription = (
  popularity: PopularityModel | undefined,
  dataType?: string,
) => {
  const user = popularity?.userCount === 1 ? 'user' : 'users';
  const times = popularity?.count === 1 ? 'time' : 'times';
  const lastDaysStr = `in the last ${popularity?.timeframe?.valueOf()} days.`;

  /** @todo Architecture. Make sure this is a correct dataType for TableauDataSourceModel. */
  if (dataType === 'tableaudatasource') {
    return `Accessed ${
      popularity?.count
    } ${times} in the last ${popularity?.timeframe?.valueOf()} days`;
  }

  if (Object.values(DashboardTypes)?.includes(dataType as DashboardTypes)) {
    if (
      (popularity?.userCount && popularity?.userCount > 0) ||
      dataType === DashboardTypes.workbook
    ) {
      return `Viewed ${popularity?.count} ${times} by ${popularity?.userCount} ${user} ${lastDaysStr}`;
    }

    return `Viewed ${popularity?.count} ${times} ${lastDaysStr}`;
  }

  return `Queried ${popularity?.count} ${times} by ${popularity?.userCount} ${user} ${lastDaysStr}`;
};

export default createPopularityDescription;
