import { toast } from 'react-semantic-toasts';

export const renderInfoToast = (toastString: string) => {
  toast({
    title: toastString,
    type: 'info',
    icon: 'check',
    time: 2000,
  });
};

export const renderErrorToast = (toastString: string) => {
  toast({
    title: toastString,
    type: 'error',
    icon: 'exclamation triangle',
    time: 5000,
  });
};
