import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import type { UseMutationOptions } from '@hooks/useMutation';
import useMutation from '@hooks/useMutation';
import { OrganizationUserModel } from '@models/OrganizationUserModel';
import type { OrganizationUserRole } from '@models/OrganizationUserRole';
import { PaginatedResponse } from '@models/PaginatedResponse';
import type { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';

import paginatedTransform from '../paginatedTransform';
import rawTransform from '../rawTransform';

import * as cacheKeys from './cacheKeys';

export const fetchOrganizationUsersSelect = paginatedTransform(OrganizationUserModel);
export const useFetchOrganizationUsers = (
  options?: UseFetchOptions<PaginatedResponse<OrganizationUserModel>>,
) => {
  return useFetch<PaginatedResponse<OrganizationUserModel>>({
    ...options,
    queryKey: [...cacheKeys.all, options?.params],
    select: fetchOrganizationUsersSelect,
    url: '/users/',
  });
};

const fetchOrganizationUserSelect = rawTransform(OrganizationUserModel);
export const useFetchOrganizationUser = (
  id: string = 'me',
  options?: UseFetchOptions<OrganizationUserModel>,
) => {
  return useFetch<OrganizationUserModel>({
    ...options,
    queryKey: cacheKeys.organizationUser(id),
    select: fetchOrganizationUserSelect,
    url: `/users/${id}/`,
  });
};

export const useDeleteOrganizationUser = (id: string, options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    method: 'DELETE',
    url: `/users/${id}/`,
  });
};

export interface UsePatchOrganizationUserOptions
  extends UseMutationOptions<
    never,
    EnhancedErrorResult,
    { role?: OrganizationUserRole; team?: string }
  > {}

export const usePatchOrganizationUser = (id: string, options?: UsePatchOrganizationUserOptions) => {
  return useMutation({
    ...options,
    method: 'PATCH',
    url: `/users/${id}/`,
  });
};
