interface PartialSlateState {
  children: PartialSlateState[];
  type: string;
}

const hasElement = (slateState: PartialSlateState[], elements: string[]) => {
  const matchedItem = slateState.some((item: PartialSlateState) => {
    if (elements.includes(item.type)) {
      return true;
    }

    if (item.children && item.children.length > 0) {
      return hasElement(item.children, elements);
    }

    return false;
  });

  return matchedItem;
};

export default hasElement;
