import React, { useState } from 'react';

import IconButton from '@components/IconButton';
import Popper from '@components/Popper';
import Icon from '@components/UI/Icon';
import useClickOutside from '@hooks/useClickOutside';

import deleteColumn from '../helpers/deleteColumn';
import deleteRow from '../helpers/deleteRow';
import deleteTable from '../helpers/deleteTable';
import insertColumn from '../helpers/insertColumn';
import insertRow from '../helpers/insertRow';

import { FadeIn, StyledTableCellMenu } from './TableCellMenu.styles';
import TableCellMenuOption from './TableCellMenuOption';
import { MenuOptionDivider } from './TableCellMenuOption.styles';
import type { TableCellMenuOptionType } from './TableCellMenuOption.types';

const INSERT_ROW_OPTIONS: TableCellMenuOptionType[] = [
  {
    label: 'Insert row above',
    onSelect: (editor) => insertRow(editor, 'above'),
  },
  {
    label: 'Insert row below',
    onSelect: (editor) => insertRow(editor, 'below'),
  },
];

const INSERT_COLUMN_OPTIONS: TableCellMenuOptionType[] = [
  {
    label: 'Insert column left',
    onSelect: (editor) => insertColumn(editor, 'left'),
  },
  {
    label: 'Insert column right',
    onSelect: (editor) => insertColumn(editor, 'right'),
  },
];

const DELETE_OPTIONS: TableCellMenuOptionType[] = [
  {
    label: 'Delete row',
    onSelect: (editor) => deleteRow(editor),
  },
  {
    label: 'Delete column',
    onSelect: (editor) => deleteColumn(editor),
  },
  {
    label: 'Delete table',
    onSelect: (editor) => deleteTable(editor),
  },
];

interface TableCellMenuProps {
  showButton?: boolean;
}

const TableCellMenu: React.FC<TableCellMenuProps> = ({ showButton }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const useClickOutsideRef = useClickOutside<HTMLDivElement>({
    onClick: () => {
      handleClose();
    },
  });

  return (
    <Popper
      anchorComp={
        <FadeIn
          contentEditable={false}
          onMouseDown={() => {
            setOpen(!open);
          }}
          show={showButton}
        >
          <IconButton>
            <Icon name="plus" size="14px" />
          </IconButton>
        </FadeIn>
      }
      fallbackPlacements={['left-start', 'right-end']}
      isOpen={open}
      offset={[0, 2]}
      placement="right-start"
    >
      <StyledTableCellMenu ref={useClickOutsideRef} contentEditable={false}>
        {INSERT_ROW_OPTIONS.map((option) => (
          <TableCellMenuOption key={option.label} onClose={handleClose} option={option} />
        ))}
        <MenuOptionDivider />
        {INSERT_COLUMN_OPTIONS.map((option) => (
          <TableCellMenuOption key={option.label} onClose={handleClose} option={option} />
        ))}
        <MenuOptionDivider />
        {DELETE_OPTIONS.map((option) => (
          <TableCellMenuOption key={option.label} onClose={handleClose} option={option} />
        ))}
      </StyledTableCellMenu>
    </Popper>
  );
};

export default TableCellMenu;
