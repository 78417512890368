import React from 'react';

import { useFetchMetadataComments } from '@api/metadata';
import CollectionAssetsTab from '@components/TabContent/CollectionAssetsTab';
import DiscussionTab from '@components/TabContent/DiscussionTab';
import TabsRouter, { TabRoutesConfig } from '@components/TabsRouter';
import { useUserContext } from '@context/User';
import wrapString from '@utils/wrapString';

import type { TabContentProps } from '../types';

import CollectionTabsRightElement from './CollectionTabsRightElement';
import SubCollectionsSection from './SubCollectionsSection';

interface CollectionsTabContentProps extends TabContentProps {}

const CollectionsTabContent: React.FC<CollectionsTabContentProps> = ({
  data,
  guid,
  isEditable,
  overviewTabConfig,
}) => {
  const { data: commentData } = useFetchMetadataComments(guid);
  const { organization, user } = useUserContext();
  const userDefaultTab = user?.settings.defaultTableDashboardTab;

  const tabsRouterConfig: TabRoutesConfig[] = [
    {
      ...overviewTabConfig,
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => (
        <SubCollectionsSection guid={guid}>{overviewTabConfig.component()}</SubCollectionsSection>
      ),
      default: userDefaultTab === 'overview',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <CollectionAssetsTab guid={guid} />,
      default: userDefaultTab === 'columns_charts',
      label: 'Assets',
      route: '/assets',
    },
    ...(organization?.settings?.useDiscussions
      ? [
          {
            // eslint-disable-next-line react/no-unstable-nested-components
            component: () => <DiscussionTab guid={guid} />,
            contentOffset: 2,
            label: `Discussion ${wrapString(commentData?.length)}`,
            path: '/discussion/:itemId?',
            route: '/discussion',
          },
        ]
      : []),
  ];

  return (
    <TabsRouter
      action={isEditable && <CollectionTabsRightElement collection={data} />}
      config={tabsRouterConfig}
      sticky
    />
  );
};

export default CollectionsTabContent;
