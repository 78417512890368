import React from 'react';
import { useLocation, useParams } from '@routing/router';

import { useFetchFolder } from '@api/bifolder';
import CircularLoader from '@components/CircularLoader';
import NotFoundError from '@components/Error/NotFoundError';
import mapDataTypesModel from '@models/DataTypesModel/DataTypesModel.v1';
import stripSpaces from '@utils/stripSpaces';

import type { BINotebooksPageProps } from './BINotebooksPage';
import BINotebooksPage from './BINotebooksPage';

const CONTAINER_CONFIG = {
  dataTypes: mapDataTypesModel({
    data_source_type: 'databricks',
    object_type: 'databricks_notebooks_container',
  }),
  title: 'All Notebooks',
};

const FOLDER_QUERY = stripSpaces(`{
  name,
  breadcrumbs,
  data_types,
  data_source{
    guid
  }
}`);

const COUNT_ITEMS_CONFIG: BINotebooksPageProps['countItemsConfig'] = [
  { key: 'count', text: 'Notebook' },
];

const BINotebooksPageRouted: React.FC = () => {
  const location = useLocation();
  const { dsGuid = '', guid = '' } = useParams<{ dsGuid?: string; guid?: string }>();
  const { data, isError, isLoading } = useFetchFolder(guid, {
    enabled: Boolean(guid),
    params: { query: FOLDER_QUERY },
  });

  const viewProps = {
    breadcrumbList: data?.breadcrumbList,
    dataTypes: guid ? data?.dataTypes : CONTAINER_CONFIG?.dataTypes,
    dsGuid: dsGuid || data?.dataSource.guid,
    guid,
    key: location.pathname,
    title: guid ? data?.name : CONTAINER_CONFIG?.title,
  };

  if ((!dsGuid && !guid) || isError) {
    return <NotFoundError />;
  }

  if (isLoading) {
    return <CircularLoader centered cover />;
  }

  return <BINotebooksPage countItemsConfig={COUNT_ITEMS_CONFIG} {...viewProps} />;
};

export default BINotebooksPageRouted;
