import React from 'react';

import Box from '@components/Box';
import useWindowSize from '@hooks/useWindowSize';

interface CenterAlignedBoxProps {
  maxWidth: string;
  minWidth?: string;
}

const CenterAlignedBox: React.FC<CenterAlignedBoxProps> = ({ children, maxWidth, minWidth }) => {
  const { isUpMd } = useWindowSize();

  return (
    <Box alignItems="center" compDisplay="flex" compHeight="100%" justifyContent="center" m="auto">
      <Box
        compDisplay="flex"
        flexDirection="column"
        maxWidth={maxWidth}
        minWidth={isUpMd ? minWidth : 'auto'}
        p={2}
      >
        {children}
      </Box>
    </Box>
  );
};

export default CenterAlignedBox;
