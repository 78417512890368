import { Expose, Transform, Type } from 'class-transformer';

import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import { TaggedItemModel } from '@api/tags/TaggedItemModel';
import { ObjectType } from '@api/types';
import { breadcrumbsToLabelList, breadcrumbsToList } from '@components/Breadcrumbs';
import type { DataSourceTypesType } from '@models/DataSourceCredentials';
import DataTypesModel from '@models/DataTypesModel';
import { PopularityModel } from '@models/PopularityModel';
import { urlFor } from '@utils/routing';

import { LookMLFieldModel } from '../fields/LookMLFieldModel';

export class LookerExploreFieldModel {
  static objectType: ObjectType = 'explorefield';

  objectType: ObjectType = LookerExploreFieldModel.objectType;

  static typeDisplay: string = 'ExploreField';

  typeDisplay: string = LookerExploreFieldModel.typeDisplay;

  guid!: string;

  @Expose({ name: 'data_types' })
  @Type(() => DataTypesModel)
  dataTypes?: DataTypesModel = new DataTypesModel();

  @Type(() => LookMLFieldModel)
  field!: LookMLFieldModel;

  @Expose({ name: 'joined_view_label' })
  viewLabel!: string;

  @Type(() => PopularityModel)
  popularity?: PopularityModel;

  @Expose({ name: 'url' })
  externalUrl?: string;

  @Expose({ name: 'parent_guid' })
  parentGuid!: string;

  @Expose({ name: 'data_source_type' })
  dataSourceType?: DataSourceTypesType;

  @Expose({ name: 'tagged_items' })
  @Type(() => TaggedItemModel)
  taggedItems?: TaggedItemModel[];

  @Expose({ name: 'search_name' })
  fullName?: string;

  @Expose({ name: 'name' })
  @Transform((value, obj) => value ?? obj?.field.name)
  name!: string;

  // Getters to expose common fields that are nested in the field object.
  get description() {
    return this.field.description;
  }

  get richtextDescription() {
    return this.field.richtextDescription;
  }

  get suggestedDescription() {
    return this.field.suggestedDescription;
  }

  get suggestedDescriptionSourceObject() {
    return this.field.suggestedDescriptionSourceObject;
  }

  get dataType() {
    return this.field.dataType;
  }

  get fieldType() {
    return this.field.fieldType;
  }

  get externalType() {
    return this.field.externalType;
  }

  get label() {
    return this.field.label;
  }

  get aiDescription() {
    return this.field.aiDescription;
  }

  get descriptionSource() {
    return this.field.descriptionSource;
  }

  get ingestedDescription() {
    return this.field.ingestedDescription;
  }

  get suggestedDescriptionSource() {
    return this.field.suggestedDescriptionSource;
  }

  get userDescription() {
    return this.field.userDescription;
  }

  @Expose({ name: 'is_metric' })
  isMetric?: boolean;

  @Expose({ name: 'is_mention' })
  isMention?: boolean;

  @Type(() => BreadcrumbModel)
  breadcrumbs?: BreadcrumbModel[];

  get breadcrumbList() {
    return breadcrumbsToList(this.breadcrumbs);
  }

  get breadcrumbLabelList() {
    const url = urlFor(this, false, '');
    return breadcrumbsToLabelList(this.name, url, this.breadcrumbs);
  }
}
