import { XYPosition } from 'reactflow';

import { SIZES } from '@components/LineageExplore/components/Nodes/config';

import {
  calculateDeltaYPerStack,
  calculateOverlappedConflicts,
  createCountMapByStacks,
  getHighestAvailableY,
  getOccupiedColumnsByNode,
  getTopLevelParentNode,
  splitGroupsByType,
} from './algorithm.utils';
import { parseNode } from './parsers';
import { AlgorithmResult, EdgesById, NodesById, StackGroups } from './types';

const parseLineageNodes = ({
  edgesById,
  initialPosition = { x: 0, y: 0 } as XYPosition,
  initialYPositionPerStack = {},
  isColumnLevelLineage = false,
  nodesById,
  operationPivotNodeKey,
  preCalculatedConflictEndPerStack,
  preCalculatedNodesById = {},
  shouldHideFilterLineage = false,
  stackGroups,
}: {
  edgesById: EdgesById;
  initialPosition?: XYPosition;
  initialYPositionPerStack?: Record<number, number>;
  isColumnLevelLineage?: boolean;
  nodesById: NodesById;
  operationPivotNodeKey?: string;
  preCalculatedConflictEndPerStack?: Record<number, number>;
  preCalculatedNodesById?: NodesById;
  shouldHideFilterLineage?: boolean;
  stackGroups?: StackGroups;
}): AlgorithmResult => {
  let modifiedEdgesById = { ...edgesById };

  if (!nodesById || !stackGroups) {
    return {
      biggestConflictEndPerStack: {},
      edgesById: modifiedEdgesById,
      nodesById: {},
      nodesByStack: {},
    };
  }

  let allowedCalculationStacks = new Set(Object.keys(stackGroups).map((stack) => Number(stack)));

  if (operationPivotNodeKey && preCalculatedConflictEndPerStack && preCalculatedNodesById) {
    const topLevelParentNode = getTopLevelParentNode(operationPivotNodeKey, nodesById);
    const biggestConflictPerStack = calculateOverlappedConflicts(preCalculatedConflictEndPerStack);

    const [conflictRangeStart, conflictRangeEnd] =
      biggestConflictPerStack[topLevelParentNode?.stackedAt!];

    const pivotNodeConflictingStacks = Array.from({
      length: conflictRangeEnd - conflictRangeStart + 1,
    }).map((_, index) => index + conflictRangeStart);

    allowedCalculationStacks = new Set(pivotNodeConflictingStacks);
  }

  const stackGroupKeys = Object.keys(stackGroups).map((stack) => Number(stack));
  const highestYAvailablePerStack = {
    ...createCountMapByStacks(stackGroupKeys),
    ...initialYPositionPerStack,
  };
  const nodesByStack = {} as StackGroups;

  const biggestConflictEndPerStack: Record<number, number> = Object.keys(stackGroups).reduce(
    (acc, cur) => {
      return { ...acc, [cur]: Number(cur) };
    },
    {},
  );

  const newNodesById: NodesById = {};
  const groups = splitGroupsByType(stackGroups);

  groups.forEach((group) => {
    const biggestStackLength = Math.max(...Object.values(group).map((stack) => stack.length));
    const stackGroupsKeysSorted = Object.keys(group).sort((a, b) => Number(a) - Number(b));

    Array.from({ length: biggestStackLength }).forEach((_, row) => {
      const currentRowNodes = stackGroupsKeysSorted
        .map((stackKey) => group[Number(stackKey)][row])
        .map((nodeId) => nodesById[nodeId])
        .filter((node) => node);

      for (let i = 0; i < currentRowNodes.length; i += 1) {
        const rawNode = currentRowNodes[i];
        const highestAvailableY = getHighestAvailableY(rawNode, highestYAvailablePerStack);

        if (!allowedCalculationStacks.has(rawNode.stackedAt!)) {
          const nodeId = rawNode.id;
          const preCalculatedNode = preCalculatedNodesById[nodeId];
          newNodesById[nodeId] = preCalculatedNode;

          (preCalculatedNode?.allChildrenKeys ?? []).forEach((childKey) => {
            newNodesById[childKey] = preCalculatedNodesById[childKey];
          });

          const nodeStackNewAvailableY =
            highestAvailableY + Number(preCalculatedNode?.style?.height) + SIZES.rowGap.database;
          highestYAvailablePerStack[rawNode.stackedAt!] = nodeStackNewAvailableY;

          continue;
        }

        const {
          children = [],
          extraEdgesById,
          node,
          nodeHeight,
        } = parseNode({
          edgesById,
          initialPosition: {
            ...initialPosition,
            y: initialPosition.y + highestAvailableY,
          },
          isColumnLevelLineage,
          nodeIndex: row,
          nodesById,
          rawNode,
          shouldHideFilterLineage,
        });

        const nodeStacks = getOccupiedColumnsByNode(node);
        const nodeStackNewAvailableY = highestAvailableY + nodeHeight + SIZES.rowGap.database;

        nodeStacks.forEach((stack, index, originalArray) => {
          nodesByStack[stack] = (nodesByStack[stack] ?? new Set<string>()).add(node.id);
          highestYAvailablePerStack[stack] = nodeStackNewAvailableY;

          if (originalArray.length > 1) {
            biggestConflictEndPerStack[node.stackedAt!] = Math.max(
              biggestConflictEndPerStack[node.stackedAt!],
              Number(stack),
            );
          }
        });

        const childrenKeys = children.map((child) => child.id);
        newNodesById[node.id] = { ...node, allChildrenKeys: childrenKeys };

        children.forEach((child) => {
          newNodesById[child.id] = child;
        });

        modifiedEdgesById = { ...modifiedEdgesById, ...extraEdgesById };
      }
    });
  });

  // Center lineage
  const deltaYPerStack = calculateDeltaYPerStack(
    highestYAvailablePerStack,
    biggestConflictEndPerStack,
  );
  Object.entries(stackGroups).forEach(([stack, nodes]) => {
    if (allowedCalculationStacks.has(Number(stack))) {
      nodes.forEach((nodeKey) => {
        if (newNodesById[nodeKey]) {
          newNodesById[nodeKey].position.y += deltaYPerStack[Number(stack)];
        }
      });
    }
  });

  return {
    biggestConflictEndPerStack,
    edgesById: modifiedEdgesById,
    nodesById: newNodesById,
    nodesByStack,
  };
};

export default parseLineageNodes;
