import React from 'react';
import type { DataSource } from '@configs/dataSources/types';

import getUserDocsUrl from '@components/DataSourceSetup/components/DataSourceAddStep/userDocsUrlConfig';
import { TextLink } from '@components/Text';
import Icon from '@components/UI/Icon';

import type { StyledAddDSHeaderProps } from './AddDSHeader.styles';
import { StyledAddDSHeader } from './AddDSHeader.styles';

interface AddDSHeaderProps extends StyledAddDSHeaderProps {
  dataSourceType?: DataSource;
}

const AddDSHeader: React.FC<AddDSHeaderProps> = ({ dataSourceType, ...props }) => {
  const url = getUserDocsUrl(dataSourceType);
  return (
    <StyledAddDSHeader {...props}>
      Add Data Source
      <TextLink href={url} rel="noopener noreferrer" target="_blank">
        <Icon name="help-outlined" size="20px" />
      </TextLink>
    </StyledAddDSHeader>
  );
};
export default AddDSHeader;
