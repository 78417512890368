import styled from '@emotion/styled';

import Box from '@components/Box';

const BoxWrapper = styled(Box)``;

BoxWrapper.defaultProps = {
  compDisplay: 'flex',
  compHeight: 'auto',
  flexDirection: 'column',
  maxHeight: '60vh',
  minHeight: '350px',
  overflowX: 'hidden',
  overflowY: 'auto',
  p: 3,
};

export default BoxWrapper;
