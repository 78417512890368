import { Expose, Type } from 'class-transformer';

import { DashboardModel } from '@api/dashboards/DashboardModel';

export class RelatedDashboardModel {
  @Type(() => DashboardModel)
  dashboard!: DashboardModel;

  distance!: number;

  @Expose({ name: 'view_count' })
  viewCount!: number;

  @Expose({ name: 'field_count' })
  fieldCount!: number;

  rank!: number;
}
