import styled from '@emotion/styled';

import { StyledTextProps } from './Text';

const StyledTextLink = styled.a<StyledTextProps>`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: #d12771;
  }
`;

export default StyledTextLink;
