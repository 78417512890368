import React, { ReactNode } from 'react';
import { dataSources } from '@configs/dataSources/config';

import Alert from '@components/Alert';
import Link from '@components/Link';
import { DataSourceTypesType } from '@models/DataSourceCredentials';

import getUserDocsUrl, { USER_DOCS_URL } from './userDocsUrlConfig';

const CONTENT_CONFIG: PartialRecord<DataSourceTypesType, ReactNode> = {
  airflow: (
    <>
      See the instructions in our{' '}
      <Link
        fontSize="inherit"
        href={USER_DOCS_URL.airflow}
        rel="noopener noreferrer"
        target="_blank"
        underline
      >
        documentation
      </Link>{' '}
      to configure {dataSources.airflow.text}.
    </>
  ),
};

interface DataSourceAddStepInfoProps {
  dataType: DataSourceTypesType;
}

const DataSourceAddStepInfo: React.FC<DataSourceAddStepInfoProps> = ({ dataType }) => {
  const url = getUserDocsUrl(dataType);
  return (
    <Alert>
      {CONTENT_CONFIG[dataType] ?? (
        <>
          We recommend creating a service user account for Select Star in your DWH or BI. See the
          instructions in our&nbsp;
          <Link fontSize="inherit" href={url} rel="noopener noreferrer" target="_blank" underline>
            documentation
          </Link>
          .
        </>
      )}
    </Alert>
  );
};

export default DataSourceAddStepInfo;
