import { UsageTypeType } from '@api/lineage/types';
import { NewLineageItemSourceEdges, NewLineageItemTargetEdges } from '@api/openapi.generated';

import { InputNodesById } from '../LineageExplore.types';

interface AreUsagesCompliantParams {
  columnUsages?: UsageTypeType[];
  validUsageTypes: UsageTypeType[];
}

const areUsagesCompliant = ({ columnUsages = [], validUsageTypes }: AreUsagesCompliantParams) => {
  return (
    (columnUsages.length === 1 && columnUsages[0] === null && validUsageTypes.includes('none')) ||
    columnUsages.some((usageType) => validUsageTypes.includes(usageType))
  );
};

interface GetCompliantEdgesParams {
  edges?: NewLineageItemTargetEdges | NewLineageItemSourceEdges;
  validUsageTypes: UsageTypeType[];
}

const getCompliantEdges = ({ edges, validUsageTypes }: GetCompliantEdgesParams) => {
  const newEdges: NewLineageItemTargetEdges | NewLineageItemSourceEdges = {};

  if (!edges) return newEdges;

  const edgesKeys = Object.keys(edges);
  for (let i = 0; i < edgesKeys.length; i += 1) {
    const key = edgesKeys[i];
    const edge = edges[key];

    const { usage_type: usageTypes = [] } = edge;
    const edgeIsCompliant = areUsagesCompliant({
      columnUsages: usageTypes,
      validUsageTypes,
    });

    if (edgeIsCompliant) {
      newEdges[key] = edge;
    }
  }

  return newEdges;
};

interface FilterInputNodesByUsageTypeParams {
  inputNodesById?: InputNodesById;
  validUsageTypes: UsageTypeType[];
}

export const filterInputNodesByUsageType = ({
  inputNodesById,
  validUsageTypes,
}: FilterInputNodesByUsageTypeParams) => {
  const newInputNodesById = { ...inputNodesById };

  const inputNodesKeys = Object.keys(newInputNodesById);
  for (let i = 0; i < inputNodesKeys.length; i += 1) {
    const key = inputNodesKeys[i];
    const node = newInputNodesById[key];

    if (node && node.node_type === 'column') {
      const { source_edges: sourceEdges, target_edges: targetEdges } = node;
      const targetCompliantEdges = getCompliantEdges({ edges: targetEdges, validUsageTypes });
      const sourceCompliantEdges = getCompliantEdges({ edges: sourceEdges, validUsageTypes });

      newInputNodesById[key] = {
        ...node,
        source_edges: sourceCompliantEdges,
        target_edges: targetCompliantEdges,
      };
    }
  }

  return newInputNodesById;
};
