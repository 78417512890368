import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

import rootTheme from '@styles/theme';

type ButtonStatus = 'default' | 'hover';

export type VariantColor = 'default' | 'outlined' | 'text' | 'colorize' | 'constraint';
type VariantColorStyle = Record<ButtonStatus, SerializedStyles>;

export type VariantSize = 'xxs' | 'xs' | 'sm' | 'default' | 'lg';

export const colorVariants: Record<VariantColor, VariantColorStyle> = {
  colorize: {
    default: css`
      background-color: color-mix(in srgb, currentColor 7%, transparent);
      border-color: transparent;
      border-radius: 3px;
      font-weight: ${rootTheme.typography.fontWeights.medium};
    `,
    hover: css``,
  },
  constraint: {
    default: css`
      background-color: ${rootTheme.colors.v1.gray[100]};
      border-color: ${rootTheme.colors.gray[200]};
      border-radius: ${rootTheme.radius.md};
      color: ${rootTheme.colors.violet};
      font-size: ${rootTheme.typography.fontSizes.sm};
      font-weight: ${rootTheme.typography.fontWeights.bold};
      padding: ${rootTheme.space(0.5)};
      height: unset;
      gap: ${rootTheme.space(0.5)};
    `,
    hover: css`
      color: ${rootTheme.colors.violet};
      background: ${rootTheme.colors.v1.gray[50]};
    `,
  },
  default: {
    default: css`
      background-color: ${rootTheme.colors.primary};
      border-radius: 3px;
      color: ${rootTheme.colors.white};
      font-weight: ${rootTheme.typography.fontWeights.medium};
    `,
    hover: css`
      color: ${rootTheme.colors.white};
    `,
  },
  outlined: {
    default: css`
      background-color: ${rootTheme.colors.white};
      border-color: ${rootTheme.colors.v1.gray[300]};
      border-radius: 3px;
      color: ${rootTheme.colors.text};
      font-weight: ${rootTheme.typography.fontWeights.medium};
    `,
    hover: css`
      background-color: ${rootTheme.colors.v1.gray[50]};
      color: ${rootTheme.colors.text};
    `,
  },
  text: {
    default: css`
      background-color: transparent;
      border-color: transparent;
      border-radius: 3px;
      color: ${rootTheme.colors.primary};
      font-weight: ${rootTheme.typography.fontWeights.medium};
    `,
    hover: css`
      color: ${rootTheme.colors.primary};
    `,
  },
};

export const sizeVariants: Record<VariantSize, SerializedStyles> = {
  default: css`
    font-size: ${rootTheme.typography.fontSizes.body2};
    height: 36px;
    padding: ${rootTheme.space(0, 2)};
  `,
  lg: css`
    font-size: ${rootTheme.typography.fontSizes.body2};
    height: 42px;
    padding: 0 12px;
  `,
  sm: css`
    font-size: ${rootTheme.typography.fontSizes.body2};
    height: 32px;
    padding: ${rootTheme.space(0, 1)};
  `,
  xs: css`
    height: 28px;
    padding: ${rootTheme.space(0, 0.75)};
    font-size: ${rootTheme.typography.fontSizes.xs};
  `,
  xxs: css`
    font-size: ${rootTheme.typography.fontSizes.xs};
    height: 20px;
    padding: ${rootTheme.space(0, 0.75)};
  `,
};
