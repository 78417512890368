import React from 'react';

import Tooltip from '@components/Tooltip';
import type { IconProps } from '@components/UI/Icon';
import Icon from '@components/UI/Icon';

interface ImageProps {
  imageName: IconProps['name'];
  tooltipText?: string | JSX.Element;
}

const ImageCell = React.memo<ImageProps>(({ imageName, tooltipText }) => {
  if (!imageName) {
    return null;
  }

  if (!tooltipText) {
    return <Icon className="key-icon" name={imageName} />;
  }

  return (
    <Tooltip content={tooltipText} forceDefaultBackground>
      <Icon className="key-icon" name={imageName} size="1rem" />
    </Tooltip>
  );
});

export default ImageCell;
