import breakpoints, { Breakpoints } from './breakpoints';
import colors, { Colors } from './colors';
import grid, { Grid } from './grid';
import heights, { Heights } from './heights';
import media, { Media } from './media';
import radius, { Radius } from './radius';
import shadow, { Shadow } from './shadow';
import space, { Space } from './space';
import typography, { Typography } from './typography';
import zIndex, { ZIndex } from './zIndex';

export interface Theme {
  breakpoints: Breakpoints;
  colors: Colors;
  grid: Grid;
  heights: Heights;
  media: Media;
  radius: Radius;
  shadow: Shadow;
  space: Space;
  typography: Typography;
  zIndex: ZIndex;
}

const theme: Theme = {
  breakpoints,
  colors,
  grid,
  heights,
  media,
  radius,
  shadow,
  space,
  typography,
  zIndex,
};

export default theme;
