import 'semantic-ui-css/semantic.min.css';
import 'reactflow/dist/style.css';

import React from 'react';

import CalendarModal from '@components/DateRangePicker/CalendarModal';
import { useModal } from '@context/Modal';

const CalendarModalContainer: React.FC = () => {
  const { MODAL_IDS, checkModalOpened, closeModal, getModalContent } = useModal();
  const props = getModalContent(MODAL_IDS.calendar)!;

  if (!checkModalOpened(MODAL_IDS.calendar)) return null;
  return <CalendarModal {...props} close={() => closeModal(MODAL_IDS.calendar)} />;
};

export default CalendarModalContainer;
