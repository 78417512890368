import React from 'react';

import { CustomAttributeModel } from '@api/customAttributes/CustomAttributeModel';
import type { StyledBoxProps } from '@components/Box';
import Box from '@components/Box';
import Icon from '@components/UI/Icon';
import { typeElementMap } from '@pages/Admin/AdminCustomAttributePage/AdminCustomAttributes/TypeElement/TypeElement';

export interface CustomAttributeTitleProps extends StyledBoxProps {
  iconColor?: string;
  item: CustomAttributeModel;
}

const CustomAttributeTitle: React.FC<CustomAttributeTitleProps> = ({
  iconColor,
  item,
  ...other
}) => (
  <Box compDisplay="initial" spacing={0.5} {...other}>
    <Icon color={iconColor} compDisplay="inline-block" name={typeElementMap[item.type].icon} />
    <Box as="span" verticalAlign="middle">
      {item.name}
    </Box>
  </Box>
);

export default CustomAttributeTitle;
