import styled from '@emotion/styled';

const RightNavTitle = styled.h3`
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
  font-size: 1rem;
  color: #6f747c;
  margin: 0 0 1.5rem 0;
`;

export default RightNavTitle;
