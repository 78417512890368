import { BaseEditor } from 'slate';

import { DescriptionSource } from '@api/description/description.types';

export const isSuggestedDescription = (descriptionSource?: DescriptionSource | null) => {
  if (descriptionSource === null) {
    return true;
  }
  return ['public_docs', 'downstream_lineage', 'upstream_lineage', 'similarity'].includes(
    descriptionSource || '',
  );
};

export const isRichTextMessageValid = (richTextMessage: string, message: string) => {
  try {
    const [richTextObject] = JSON.parse(richTextMessage);

    /*
     * We need to check both richTextMessage and message because checking just
     * one of them could generate wrong validations. For instance:
     * 1. If the field contains only a mention, the message value is empty,
     * but the object from the richtextMessage value will have 3 children
     * 2. On the other hand, we can't check only richtextMessage because if the
     * field is completely empty, the object from the richtextMessage field will
     * have 1 child still (it will be something like children: [{ text: ""}])
     */
    return (richTextObject as BaseEditor)?.children?.length > 1 || Boolean(message);
  } catch {
    return false;
  }
};
