import React, { useCallback, useState } from 'react';
import { useTheme } from '@emotion/react';
import { Editor } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';

import Box from '@components/Box';
import AddLinkModal from '@components/RichTextEditor/RichTextEditorControls/AddLinkModal/AddLinkModal';
import useStickyContext from '@components/Sticky';
import Tooltip from '@components/Tooltip';
import { useModal } from '@context/Modal';

import { isBlockActive, toggleBlock } from '../helpers/blockHelpers';
import { getActiveInlineStyles, toggleStyle } from '../helpers/inlineStyleHelpers';
import {
  getLinkText,
  getLinkURLText,
  getSelectedLink,
  insertLink,
  isLinkNodeAtSelection,
} from '../helpers/linkHelpers';
import useImageUpload from '../Hooks/useImageUpload';
import {
  BUTTON_TYPES,
  CustomElement,
  CustomElementType,
  RichTextEditorElement,
} from '../RichTextEditor.types';
import InsertTableMenu from '../Tables/InsertTableMenu/InsertTableMenu';

import { EditorControlsButton, StyledEditorControls } from './RichTextEditorControls.styles';

export interface RichTextEditorControlsProps {
  allowedElements?: RichTextEditorElement[];
  rightElement: React.ReactNode;
  scrollable?: boolean;
}

const RichTextEditorControls: React.FC<RichTextEditorControlsProps> = ({
  allowedElements,
  rightElement,
  scrollable = false,
}) => {
  const { zIndex } = useTheme();
  const stickyProps = useStickyContext({ enabled: scrollable });
  const [tableMenuOpen, setTableMenuOpen] = useState(false);
  const editor = useSlate();
  const { MODAL_IDS, checkModalOpened, openModal } = useModal();
  const linkModalOpen = checkModalOpened(MODAL_IDS.addLinkRichTextEditorControls);
  const selectedLink = linkModalOpen ? (getSelectedLink(editor)?.[0] as CustomElement) : undefined;
  const selectedText = linkModalOpen ? Editor.string(editor, editor.selection || []) : undefined;

  const { FileSizeExceededModal, handleSelectImage, uploadInputComponent } = useImageUpload(editor);

  const getButtons = useCallback(() => {
    const buttons = Object.values(BUTTON_TYPES);

    if (allowedElements) {
      return buttons.filter((button) =>
        allowedElements?.includes(button.style as CustomElementType),
      );
    }

    return buttons;
  }, [allowedElements]);

  return (
    <StyledEditorControls {...stickyProps} zIndex={zIndex.contentHeaders}>
      <Box compDisplay="flex" flexWrap="wrap">
        {getButtons().map((type) => {
          const isActive =
            getActiveInlineStyles(editor).has(type.style) ||
            isBlockActive(editor, type.style as CustomElementType) ||
            (type.style === 'link' && isLinkNodeAtSelection(editor, editor.selection));

          const controlButton = (
            <EditorControlsButton
              key={type.label}
              isActive={isActive}
              onMouseDown={(e) => {
                e.preventDefault();

                if (type.style === 'image') {
                  handleSelectImage();
                }

                if (type.style === 'link') {
                  openModal(MODAL_IDS.addLinkRichTextEditorControls);
                }

                if (type.style === 'table') {
                  if (!isActive) {
                    setTableMenuOpen(!tableMenuOpen);
                  }
                  return;
                }

                if (type.type === 'block') {
                  toggleBlock(editor, type.style as CustomElementType);
                }

                if (type.type === 'inline') {
                  toggleStyle(editor, type.style);
                }
              }}
            >
              {type.style === 'image' && uploadInputComponent}
              {type.icon}
            </EditorControlsButton>
          );

          return (
            <Tooltip key={type.label} content={type.label}>
              {type.style === 'table' ? (
                <span>
                  <InsertTableMenu
                    anchor={controlButton}
                    editor={editor}
                    onClose={() => setTableMenuOpen(false)}
                    open={tableMenuOpen}
                  />
                </span>
              ) : (
                controlButton
              )}
            </Tooltip>
          );
        })}
      </Box>
      {rightElement}
      <AddLinkModal
        modalId={MODAL_IDS.addLinkRichTextEditorControls}
        onClose={() => {
          ReactEditor.focus(editor);
        }}
        onSave={(text, url) => insertLink(editor, text, url)}
        selectedLink={getLinkURLText(selectedLink, selectedText)}
        selectedText={getLinkText(selectedLink) ?? selectedText ?? ''}
      />
      {FileSizeExceededModal}
    </StyledEditorControls>
  );
};

export default RichTextEditorControls;
