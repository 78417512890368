import React from 'react';

import Box from '@components/Box';
import PopularityCell from '@components/Table/Cells/PopularityCell';
import Text from '@components/Text';
import Icon from '@components/UI/Icon';
import { ValidDSType } from '@models/DataSourceCredentials';
import { PopularityModel } from '@models/PopularityModel';

import { StyledDownstreamChartsTabChildrenItem } from './DownstreamChartsTab.styles';
import type { DownstreamChartsTabDropdownProps } from './DownstreamChartsTabDropdown';
import DownstreamChartsTabDropdown from './DownstreamChartsTabDropdown';
import DownstreamChartsTabRow from './DownstreamChartsTabRow';

interface DownstreamChartsTabChartRowProps
  extends Pick<DownstreamChartsTabDropdownProps, 'dataTypes'> {
  copyPath: string;
  dataSourceType?: ValidDSType;
  externalUrl: string;
  name: string;
  popularity?: PopularityModel;
  routePath: string;
}

const DownstreamChartsTabChartRow: React.FC<DownstreamChartsTabChartRowProps> = ({
  copyPath,
  dataSourceType,
  dataTypes,
  externalUrl,
  name,
  popularity,
  routePath,
}) => {
  return (
    <StyledDownstreamChartsTabChildrenItem>
      <DownstreamChartsTabRow
        firstColumn={
          <Box alignItems="center" compDisplay="flex" pl={5}>
            <Icon mr={0.5} name={dataTypes?.icons.dataType!} />
            <Text color="inherit" fontSize="14px" mb={0} mr={0.25}>
              {name}
            </Text>
            <DownstreamChartsTabDropdown
              copyPath={copyPath}
              dataSourceType={dataSourceType}
              dataTypes={dataTypes}
              externalUrl={externalUrl}
              routePath={routePath}
            />
          </Box>
        }
        secondColumn={popularity && <PopularityCell popularity={popularity} />}
      />
    </StyledDownstreamChartsTabChildrenItem>
  );
};

export default DownstreamChartsTabChartRow;
