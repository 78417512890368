import React from 'react';

import Skeleton from '@components/Skeleton';
import theme from '@styles/theme';

const OverviewSkeleton = () => {
  return (
    <>
      <Skeleton compHeight={theme.space(20)} compWidth="100%" grayShade={200} variant="text" />
      <Skeleton compHeight={theme.space(20)} compWidth="100%" grayShade={200} variant="text" />
      <Skeleton compHeight={theme.space(20)} compWidth="100%" grayShade={200} variant="text" />
    </>
  );
};

export default OverviewSkeleton;
