import { Editor, Point, Range } from 'slate';

import calculateCharsSelection from './calculateCharsSelection';

/**
 * Finds the current word in slate. Normally this could be accomplished with native slate apis but
 * currently there is no way to specify what to include in a word(spaces or underscores for example).
 * This function is an implementation found in this github issue that allows for including characters:
 * https://github.com/ianstormtaylor/slate/issues/4162
 */
const getCurrentWord = (
  editor: Editor,
  location: Range,
  options: {
    directions?: 'both' | 'left' | 'right';
    include?: boolean;
    terminator?: string[];
  } = {},
): Range | undefined => {
  const { directions = 'both', include = false, terminator = [' '] } = options;

  const { selection } = editor;

  if (!selection) {
    return undefined;
  }

  // Get start and end, modify it as we move along.
  let [start, end] = Range.edges(location);

  let point: Point = start;

  const move = (direction: 'right' | 'left'): boolean => {
    const next =
      direction === 'right'
        ? Editor.after(editor, point, {
            unit: 'character',
          })
        : Editor.before(editor, point, { unit: 'character' });

    const wordNext =
      next &&
      Editor.string(
        editor,
        direction === 'right' ? { anchor: point, focus: next } : { anchor: next, focus: point },
      );

    const wordPrevious =
      next &&
      Editor.string(
        editor,
        direction === 'right'
          ? calculateCharsSelection(
              {
                distanceFromAnchor: 1,
                distanceFromFocus: 1,
              },
              { anchor: point, focus: next },
              { direction: 'left' },
            )
          : calculateCharsSelection(
              {
                distanceFromAnchor: 1,
                distanceFromFocus: 1,
              },
              { anchor: next, focus: point },
              { direction: 'right' },
            ),
      );

    const last = wordNext && wordNext[direction === 'right' ? 0 : wordNext.length - 1];

    if (
      next &&
      last &&
      !terminator.includes(last) &&
      !terminator.includes(`${last}${wordPrevious}`)
    ) {
      point = next;

      if (point.offset === 0) {
        // Means we've wrapped to beginning of another block
        return false;
      }
    } else {
      return false;
    }

    return true;
  };

  // Move point and update start & end ranges

  // Move forwards
  if (directions !== 'left') {
    point = end;
    while (move('right'));
    end = point;
  }

  // Move backwards
  if (directions !== 'right') {
    point = start;
    while (move('left'));
    start = point;
  }

  if (include) {
    return {
      anchor: Editor.before(editor, start, { unit: 'offset' }) ?? start,
      focus: end,
    };
  }

  return { anchor: start, focus: end };
};

export default getCurrentWord;
