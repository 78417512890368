import getDebug from 'debug';

import ColumnLineageModel from '@api/lineage/ColumnLineageModel';
import TableLineageModel from '@api/lineage/TableLineageModel';

import {
  CircleCoordinates,
  ColumnCoordinates,
  ColumnLink,
  ColumnLinksData,
  ColumnsMap,
  Coordinates,
  CoordinatesMap,
  IDExists,
  TableRichGraph,
  TreeContext,
} from '../types';

const debug = getDebug('selectstar:explore');
const debugGraph = debug.extend('graph');

export const showAllRawHeight = (rectHeight: number) => rectHeight * 0.75;

interface EffectiveColumnIdsArgs {
  columns: string[];
  pinnableColumnsById: IDExists;
  showAllColumns: boolean;
}

export const effectiveColumnIds = ({
  columns = [],
  pinnableColumnsById,
  showAllColumns,
}: EffectiveColumnIdsArgs) => {
  return columns.filter((c) => {
    return showAllColumns || pinnableColumnsById[c];
  });
};

interface CalculateOpenTableHeightArgs {
  columns: string[];
  pinnableColumnsById: IDExists;
  rectHeight: number;
  showAllColumns: boolean;
}

export const calculateOpenTableHeight = ({
  columns,
  pinnableColumnsById,
  rectHeight,
  showAllColumns,
}: CalculateOpenTableHeightArgs): number => {
  const allCols = effectiveColumnIds({
    columns,
    pinnableColumnsById,
    showAllColumns: true,
  });
  const essentialCols = effectiveColumnIds({
    columns,
    pinnableColumnsById,
    showAllColumns: false,
  });
  const activeCols = showAllColumns ? allCols : essentialCols;
  const headerHeight = rectHeight;
  const activeColsHeight = rectHeight * activeCols.length;
  const showAllHeight = essentialCols.length === allCols.length ? 0 : showAllRawHeight(rectHeight);

  return headerHeight + activeColsHeight + showAllHeight;
};

/** Creates a map of table ids and its coordinates, columns, parents and children */
export const tableGraphState = (coords: Coordinates[], context: TreeContext): TableRichGraph => {
  const graphSt = coords.reduce((output, table) => {
    if (!context.tablesById[table.key]) return output;

    const columns = context.sortedColumnIdsByTableId[table.key];
    return Object.assign(output, {
      [table.key]: {
        children: context.tablesById[table.key]?.targetTableGuids || [],
        columns,
        parents: context.tablesById[table.key]?.sourceTableGuids || [],
        stackPos: table.stackPos,
        x: table.x,
        y: table.y,
      },
    });
  }, {} as TableRichGraph);

  debugGraph('graphSt', graphSt);
  return graphSt;
};

/** Simplified version of tableGraphState but only with coordinates to avoid unnecessary copies */
export const getCoordinatesOnly = (coordinates: Coordinates[]) => {
  return coordinates.reduce(
    (out, table) => Object.assign(out, { [table.key]: table }),
    {} as CoordinatesMap,
  );
};

/** Takes all presented tables and extract column ids into an array */
export const extractColumnsIdsFromTables = (tables: TableLineageModel[]): IDExists => {
  const output: IDExists = {};
  tables?.forEach((table) => table.columns?.forEach((c) => (output[c] = true)));
  return output;
};

export const getPinnableColumnsById = (columnsById: ColumnsMap): IDExists => {
  const map: IDExists = {};
  Object.keys(columnsById).forEach((id) => {
    const col = columnsById[id];

    if (!col) {
      return;
    }

    const lineageIds = { ...(col?.sourceEdges ?? {}), ...(col?.targetEdges ?? {}) };

    // eslint-disable-next-line no-restricted-syntax
    for (const colId in lineageIds) {
      if (colId) {
        map[colId] = Boolean(columnsById[colId]);
      }
    }
  });
  return map;
};

/** Takes a sorted array of columns and generates coordinates for links between them */
export const generateColumnsLinks = (
  rectWidth: number,
  rectHeight: number,
  coordinates: ColumnCoordinates[],
  columnsById: ColumnsMap,
): ColumnLinksData => {
  const links: ColumnLink[] = [];
  const circles: CircleCoordinates[] = [];
  const xSpacing = rectWidth / 5;

  for (let i = 0; i < coordinates.length; i += 1) {
    const sourceColumn = coordinates[i];

    sourceColumn.targets.forEach((col) => {
      const edgeType =
        columnsById[sourceColumn.key]?.targetEdges?.[col.key]?.edge_type ?? 'derived';
      const stackDiff = col.stackPos - sourceColumn.stackPos;
      if (stackDiff === 0) {
        // since stacked, x is the right side of all cols
        const x = sourceColumn.x + rectWidth;
        // 1/4 of the spacing + small shift for multiple columns
        const x2 = x + xSpacing / 4;
        // source column vertical center
        const y1 = sourceColumn.y + rectHeight / 2;
        // target color vertical center
        const y2 = col.y + rectHeight / 2;
        const yDirection = y1 - y2 > 0 ? -1 : 1;
        const yHalfStep = rectHeight / 2;
        links.push({
          edgeType,
          path: [
            [x, y1], // middle right source column
            [x2, sourceColumn.y + yHalfStep + yDirection * yHalfStep], // bottom right of source column
            [x2, sourceColumn.y + yHalfStep + yDirection * 2 * yHalfStep], // bottom right of NEXT to source column
            [x2, col.y + yHalfStep - yHalfStep * 2 * yDirection], // top right of PREV target column
            [x2, col.y + yHalfStep - yHalfStep * yDirection], // top right of target column
            [x, y2], // middle right target column
          ],
          type: 'curve',
        });
        circles.push({
          x,
          y: y2,
        });
      } else if (stackDiff === -1) {
        const startX = sourceColumn.x;
        const startY = sourceColumn.y + rectHeight / 2;
        const endY = col.y + rectHeight / 2;
        const endX = col.x + rectWidth;
        links.push({
          edgeType,
          path: [
            [startX, startY],
            [endX, endY],
          ],
          type: 'line',
        });
        circles.push({
          x: endX,
          y: endY,
        });
      } else if (stackDiff < -1) {
        const startX = sourceColumn.x;
        const startX2 = startX - xSpacing / 4;
        const startY = sourceColumn.y + rectHeight / 2;
        const overTopY = 0 - rectHeight / 2;
        const endY = col.y + rectHeight / 2;
        const endX = col.x + rectWidth;
        const endX2 = endX + xSpacing / 4;
        links.push({
          edgeType,
          path: [
            [startX, startY],
            [startX2, startY - rectHeight / 2],
            [startX2, overTopY + rectHeight / 2],
            [startX2 - xSpacing / 4, overTopY],
            [endX2 + xSpacing / 4, overTopY],
            [endX2, overTopY + rectHeight / 2],
            [endX2, col.y],
            [endX, endY],
          ],
          type: 'curve',
        });
        circles.push({
          x: endX,
          y: endY,
        });
      } else if (stackDiff > 1) {
        const startX = sourceColumn.x + rectWidth;
        const startX2 = startX + xSpacing / 4;
        const startY = sourceColumn.y + rectHeight / 2;
        const overTopY = 0 - rectHeight / 2;
        const endY = col.y + rectHeight / 2;
        const endX = col.x;
        const endX2 = endX - xSpacing / 4;
        links.push({
          edgeType,
          path: [
            [startX, startY],
            [startX2, startY - rectHeight / 2],
            [startX2, overTopY + rectHeight / 2],
            [startX2 + xSpacing / 4, overTopY],
            [endX2 - xSpacing / 4, overTopY],
            [endX2, overTopY + rectHeight / 2],
            [endX2, col.y],
            [endX, endY],
          ],
          type: 'curve',
        });
        circles.push({
          x: endX,
          y: endY,
        });
      } else {
        links.push({
          edgeType,
          path: [
            [sourceColumn.x + rectWidth, sourceColumn.y + rectHeight / 2],
            [col.x, col.y + rectHeight / 2],
          ],
          type: 'line',
        });
        circles.push({
          x: col.x,
          y: col.y + rectHeight / 2,
        });
      }
    });
  }

  return {
    circles,
    links,
  };
};

/** Takes a columnId and finds a root column. */
export const getPinnedColumnRoot = (
  columnId: string,
  pinCols: string[],
  columnsById: ColumnsMap,
): string => {
  const column = pinCols
    .map((col) => columnsById[col] as ColumnLineageModel)
    .find(
      (col) => col.key === columnId || columnId in col.targetEdges || columnId in col.sourceEdges,
    );

  return column ? column.key : columnId;
};

export function columnTableSources(
  { columnsById, tablesById }: TreeContext,
  columnId: string | null,
) {
  if (!columnId) {
    return [];
  }

  const filteredSourceTableGuids = columnsById?.[columnId]?.sourceTableGuids?.filter(
    (sourceTableGuid) => !!tablesById[sourceTableGuid],
  );

  return filteredSourceTableGuids ?? [];
}
