import React, { useEffect, useRef } from 'react';

import Tooltip from '@components/Tooltip.v1';
import Icon from '@components/UI/Icon';

import { REACT_FLOW_CONTAINER_SELECTOR } from '../../Nodes/config';
import OptionsMenu, { OptionsMenuProps } from '../OptionsMenu/OptionsMenu';

import { StyledOptionsButton } from './OptionsButton.styles';

const OPTIONS_MENU_PORTAL_ID = 'options-menu-portal';
const OPTIONS_MENU_TRIGGER_ID = 'options-button';
const OPTIONS_MENU_TOOLTIP_ANCHOR_CLASS = 'menu-tooltip-container';

const DefaultChildren: OptionsButtonProps['children'] = ({ onBlur, triggerId }) => (
  <StyledOptionsButton as="button" id={triggerId} onBlur={onBlur}>
    <Icon color="gray.500" name="settings" size="16px" />
  </StyledOptionsButton>
);

export interface OptionsButtonProps {
  children?: (args: { onBlur: any; triggerId: string }) => React.ReactElement;
  containerSelector?: string;
  isAnchorVisible?: boolean;
  isOpen: boolean;
  optionsSections: OptionsMenuProps['sections'];
  setIsOpen: (isOpen: boolean) => void;
  triggerId?: string;
}

const OptionsButton = ({
  children = DefaultChildren,
  containerSelector = REACT_FLOW_CONTAINER_SELECTOR,
  isAnchorVisible,
  isOpen,
  optionsSections,
  setIsOpen,
  triggerId = OPTIONS_MENU_TRIGGER_ID,
}: OptionsButtonProps) => {
  const optionsMenuRef = useRef<HTMLDivElement>(null);
  const menuContainer = (document.querySelector(containerSelector) ?? undefined) as HTMLElement;

  const handleTriggerClick = (event: Event) => {
    event.stopPropagation();
  };

  const handleButtonBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    const { current: optionsMenu } = optionsMenuRef;
    const { relatedTarget } = event;
    const isMenuElement =
      relatedTarget === optionsMenu ||
      Boolean(optionsMenu?.contains(relatedTarget as Node)) ||
      relatedTarget?.classList?.contains(OPTIONS_MENU_TOOLTIP_ANCHOR_CLASS); // This is needed otherwise the tooltip won't work properly on Safari

    if (!isMenuElement) setIsOpen(false);
  };

  const handleOptionClick = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      const optionsButton = document.getElementById(triggerId);
      optionsButton?.focus();
    }
  }, [isOpen, triggerId]);

  if (!isAnchorVisible && !isOpen) return null;

  return (
    <Tooltip
      anchorClassName={OPTIONS_MENU_TOOLTIP_ANCHOR_CLASS}
      appendTo={menuContainer}
      content={
        <div>
          <OptionsMenu
            ref={optionsMenuRef}
            onOptionClick={handleOptionClick}
            sections={optionsSections}
          />
        </div>
      }
      fallbackPlacements={['top-end']}
      isOpen={isOpen}
      offset={[0, 4]}
      onOpenChange={setIsOpen}
      onTriggerClick={handleTriggerClick}
      placement="right"
      portalId={OPTIONS_MENU_PORTAL_ID}
      trigger="click"
    >
      {children({ onBlur: handleButtonBlur, triggerId })}
    </Tooltip>
  );
};

export default OptionsButton;
