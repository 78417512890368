import React from 'react';
import { Cell } from 'react-table';

import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import { LookMLProjectModel } from '@models/LookMLProjectModel';
import { FilterServiceInterface } from '@utils/filters';

import { StyledLookMLProjectTable } from './LookerProjectTable.styles';
import SshPublicKey from './SshPublicKey';

interface Props {
  data?: LookMLProjectModel[];
  filterService: FilterServiceInterface;
  selectedRowIds: { [guid: string]: boolean };
  setSelectedRowIds: React.Dispatch<React.SetStateAction<{}>>;
  totalPages: number;
}

const lookMLProjectWarning = (project: LookMLProjectModel) => {
  const repo = project.repository.repositoryUrl.toLowerCase();
  let message;

  if (repo.startsWith('https://')) {
    /*
     * we only allow ssh repositories, so you won't be able to connect a repository
     * unless it was connected via SSH in looker.
     */
    message = `Project connected to a git repository via HTTP, not the required SSH`;
  } else if (!repo.startsWith('git@')) {
    // any repository that doesn't have a `git@` prefix is managed by looker
    message = `Project not connected to a SSH git repository`;
  }

  if (!message) {
    return null;
  }

  return (
    <Tooltip content={message}>
      <Icon name="warning" size="16px" />
    </Tooltip>
  );
};

const LookMLProjectTable: React.FC<Props> = ({
  data,
  filterService,
  selectedRowIds,
  setSelectedRowIds,
  totalPages,
}) => {
  const { changePage, filter, search } = filterService;

  const columns: ColumnConfig<LookMLProjectModel>[] = React.useMemo(
    () => [
      {
        Cell: ({ row }: Cell<LookMLProjectModel>) => lookMLProjectWarning(row.original),
        Header: 'Warnings',
        disableFilters: true,
        disableHiding: true,
        disableResizing: true,
        id: 'warnings',
        width: '5%',
      },
      {
        Cell: ({ row }: Cell<LookMLProjectModel>) => {
          return (
            <Tooltip content={row.original.repository.repositoryUrl}>
              <p>{row.original.name}</p>
            </Tooltip>
          );
        },
        Header: 'Project Name',
        accessor: (d) => d.name,
        disableHiding: true,
        disableResizing: true,
        id: 'name',
        width: '75%',
      },
      {
        Cell: ({ row }: Cell<LookMLProjectModel>) => {
          const item = row.original;
          const selected = Boolean(selectedRowIds[item.guid]);

          if (lookMLProjectWarning(row.original)) {
            return null;
          }

          if (selected) return <SshPublicKey project={item} />;
          return null;
        },
        Header: 'Git SSH Key',
        disableFilters: true,
        disableResizing: true,
        id: 'publicKey',
        width: '20%',
      },
    ],
    [selectedRowIds],
  );

  const getRowId = React.useCallback((row: Partial<LookMLProjectModel>) => row.guid!, []);

  return (
    <StyledLookMLProjectTable>
      <Table
        basic="very"
        changePage={changePage}
        className="table-full"
        columns={columns}
        compact
        data={data || []}
        disableColumnFiltering
        disableHeaders
        disableResizing
        disableSortBy
        getRowId={getRowId}
        initialState={{
          pageIndex: filter.page ? filter.page - 1 : 0,
          selectedRowIds,
        }}
        loading={data === undefined}
        manualFilters
        manualPagination
        selectable
        setFilters={search}
        setSelectedRowIds={setSelectedRowIds}
        showFilter
        showSelectionHeader
        sortable
        totalPages={totalPages}
      />
    </StyledLookMLProjectTable>
  );
};

export default LookMLProjectTable;
