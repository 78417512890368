import React, { useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from '@routing/router';
import { Cell } from 'react-table';

import { TableauFieldModel } from '@api/tableau/TableauFieldModel';
import ExcerptText from '@components/ExcerptText';
import QueryModalWithTargetBox from '@components/Modal/QueryModal/QueryModalWithTargetBox';
import DescriptionCell from '@components/Table/Cells/DescriptionCell';
import EditableTaggedItemCell from '@components/Table/Cells/EditableTaggedItemCell';
import PopularityCell from '@components/Table/Cells/PopularityCell';
import PopularityCellHeader from '@components/Table/Cells/PopularityCell/PopularityCellHeader';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import FieldNameTableCell from '@components/Table/ExploreFieldTable/FieldNameTableCell';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import { useModal } from '@context/Modal';
import { useUserContext } from '@context/User';
import useCustomAttributesColumns from '@hooks/useCustomAttributesColumns';
import BIColumnPageRouted from '@pages/BIColumnPageRouted';
import { Filter } from '@utils';
import { getPopularityNormalized } from '@utils/popularity';

const initialSortState = [
  {
    desc: true,
    id: 'popularity',
  },
];

interface TableauFieldsTableProps {
  data?: TableauFieldModel[];
  filterService: Filter.FilterServiceInterface;
  guid?: string;
  isDataSourceEditable: boolean;
  isDimensionSelect?: boolean;
  itemCount?: number;
  selectedRowIds?: { [guid: string]: boolean };
  setSelectedRowIds?: React.Dispatch<React.SetStateAction<{}>>;
  stickyHeader?: boolean;
  totalPages?: number;
}

const TableauFieldsTable: React.FC<TableauFieldsTableProps> = ({
  data,
  filterService,
  guid,
  isDataSourceEditable,
  isDimensionSelect,
  itemCount = 0,
  selectedRowIds,
  setSelectedRowIds,
  stickyHeader,
  totalPages,
}) => {
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const { organization } = useUserContext();
  const { MODAL_IDS, openModal } = useModal();
  const [isShowFilter, setShowFilter] = useState(false);
  const toggleFilter = React.useCallback(() => setShowFilter((prev) => !prev), [setShowFilter]);
  const customAttributesAssetType = `tableau,tableaufield,`;

  const { customAttributesColumns } = useCustomAttributesColumns({
    assetType: customAttributesAssetType,
    isDataSourceEditable,
    tableData: data ?? [],
  });

  const { changePage, filter, search, sort } = filterService;

  const getHiddenColumns = () => {
    if (isDimensionSelect) {
      return ['tags', 'description'];
    }
    return [];
  };

  const columns: ColumnConfig<TableauFieldModel>[] = React.useMemo(
    () => [
      {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      {
        Cell: (props: Cell<TableauFieldModel>) => {
          const { row } = props;
          const field = row.original;

          return (
            <FieldNameTableCell
              {...props}
              field={field}
              fullName={field.fullName}
              guid={field.guid}
              isMentioned={Boolean(field.isMention)}
              isMetric={Boolean(field.isMetric)}
              noLink={false}
              onClick={() => {
                history.push(`${url}/${field.guid}`);
              }}
              onSqlButtonClick={() => {
                openModal(MODAL_IDS.query, {
                  codeString: field?.query,
                });
              }}
            />
          );
        },
        Header: `Field (${itemCount})`,
        accessor: (d) => d?.name,
        disableHiding: true,
        id: 'name',
        width: '125%',
      },
      {
        Cell: (props: Cell<TableauFieldModel>) => {
          const { row } = props;
          const field = row.original;

          if (isDimensionSelect) {
            return (
              <ExcerptText
                enabled={organization?.settings?.useShowMoreButton}
                value={field.description || null}
              />
            );
          }

          return (
            <DescriptionCell
              {...props}
              aiDescription={field.aiDescription}
              dataSourceType={field.dataTypes?.dataSourceType}
              description={field?.description}
              descriptionSource={field.descriptionSource}
              guid={field.guid}
              ingestedDescription={field.ingestedDescription}
              isDataSourceEditable={isDataSourceEditable}
              name={field.name}
              parentGuid={guid}
              richtextDescription={field?.richtextDescription}
              suggestedDescription={field?.suggestedDescription}
              suggestedDescriptionSource={field.suggestedDescriptionSource}
              suggestedDescriptionSourceObj={field?.suggestedDescriptionSourceObject?.obj}
              truncateDisabled={!organization?.settings?.useShowMoreButton}
              userDescription={field.userDescription}
            />
          );
        },
        Header: 'Description',
        accessor: (d) => d?.description,
        id: 'description',
        width: '130%',
      },
      {
        Cell: (props: Cell<TableauFieldModel>) => {
          const { column, row } = props;
          const field = row.original;

          return (
            <QueryModalWithTargetBox
              codeString={field.calculation}
              dataSourceType={field.dataTypes?.dataSourceType}
              displayText={field.calculation}
              hideSidebar
              highlighterProps={{
                searchWords: [column?.filterValue],
              }}
              tooltipText={field.calculation}
            />
          );
        },
        Header: 'Calculation',
        accessor: (d) => d.calculation,
        disableFilters: true,
        disableHiding: true,
        disableSortBy: true,
        id: 'calculation',
        width: '125%',
      },
      ...customAttributesColumns,
      {
        Cell: (props: Cell<TableauFieldModel>) => {
          const { row } = props;
          const field = row.original;
          return (
            <EditableTaggedItemCell
              {...props}
              isDataSourceEditable={isDataSourceEditable}
              obj={field}
            />
          );
        },
        Header: 'Tags',
        accessor: (d) => d?.taggedItems,
        disableSortBy: true,
        id: 'tags',
        width: '120%',
      },
      {
        Cell: (props: Cell<TableauFieldModel>) => {
          const { row } = props;
          const field = row.original;
          return <PopularityCell {...props} isTableauField popularity={field.popularity} />;
        },
        Header: PopularityCellHeader,
        accessor: (d) => getPopularityNormalized(d?.popularity?.popularity),
        disableFilters: true,
        disableResizing: true,
        dropdownCheckboxLabel: 'Popularity',
        id: 'popularity',
        width: isDimensionSelect ? '5.367rem' : 120,
      },
    ],
    [
      isDimensionSelect,
      itemCount,
      customAttributesColumns,
      isDataSourceEditable,
      MODAL_IDS,
      openModal,
    ],
  );

  const getRowId = React.useCallback((row: Partial<TableauFieldModel>) => row.guid!, []);

  return (
    <>
      <TableStyled>
        <Table
          basic="very"
          changePage={changePage}
          className="table-full"
          columns={columns}
          compact
          data={data || []}
          disablePagination={isDimensionSelect}
          disableRowSelect={setSelectedRowIds === undefined}
          getRowId={getRowId}
          initialState={{
            hiddenColumns: getHiddenColumns(),
            pageIndex: filter.page ? filter.page - 1 : 0,
            selectedRowIds,
            sortBy: initialSortState,
          }}
          loading={data === undefined}
          manualFilters
          manualPagination
          manualSortBy
          setFilters={search}
          setSelectedRowIds={setSelectedRowIds}
          setSortBy={sort}
          showFilter={isShowFilter}
          sortable
          stickyHeader={stickyHeader}
          toggleFilter={toggleFilter}
          totalPages={totalPages}
          unstackable
        />
      </TableStyled>
      <Switch>
        <Route path={`${path}/:columnGuid`}>
          <BIColumnPageRouted isDataSourceEditable={isDataSourceEditable} />
        </Route>
      </Switch>
    </>
  );
};

export default React.memo(TableauFieldsTable);
