import styled from '@emotion/styled';

import Box from '@components/Box';
import Text from '@components/Text';

export const StyledWrapper = styled(Box)`
  margin: ${({ theme }) => theme.space(0, 3, 0, 6)};
  color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.media.down('sm')`
    margin: ${theme.space(4, 2)};
  `}
`;

export const StyledBox = styled(Box)`
  max-width: 620px;

  ${({ theme }) => theme.media.down('sm')`
    max-width: 100%;
  `}
`;

export const StyledTitle = styled(Text)`
  font-size: 36px;
  color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.media.down('sm')`
    font-size: ${theme.typography.fontSizes.h1};
    text-align: center;
  `};
`;

export const StyledItemsWrapper = styled(Box)`
  margin: ${({ theme }) => theme.space(9, 1, 11, 4)};

  ${({ theme }) => theme.media.down('sm')`
    margin: ${theme.space(4, 0)};
  `}
`;

export const StyledNote = styled(Text)`
  font-size: ${({ theme }) => theme.typography.fontSizes.h2};
  color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.media.down('sm')`
      font-size: ${theme.typography.fontSizes.h3};
      text-align: center;
  `};
`;
