import stripSpaces from '@utils/stripSpaces';

export default stripSpaces(`{
  guid,
  name,
  description,
  richtext_description,
  tagged_items,
  technical_owner {
    object_type,
    obj
  },
  business_owner {
    object_type,
    obj
  },
  updated_on,
  breadcrumbs,
  data_types
}`);
