import React, { useState } from 'react';

import usePopper from '@components/Popper/usePopper';
import Portal from '@components/Portal';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import { IconName } from '@components/UI/Icon/sprites/icon.list.generated';
import { GetColorProps } from '@styles/mixins/getColor';

import {
  StyledDropdownButton,
  StyledDropdownButtonDivisor,
  StyledDropdownButtonIconButton,
  StyledDropdownButtonMainButton,
  StyledDropdownButtonMainButtonContainer,
  StyledDropdownButtonOptionsContainer,
  StyledDropdownButtonOptionsOverlay,
} from './DropdownButton.styles';

interface DropdownButtonProps {
  defaultIsOpen?: boolean;
  onClick?: () => void;
  options: Array<{
    icon?: IconName;
    iconColor?: GetColorProps;
    id: string;
    label: React.ReactNode;
    name: string;
    onClick?: () => void;
  }>;
  startIcon?: React.ReactNode;
  tooltipMessage?: string;
}

const DropdownButton: React.FC<DropdownButtonProps> = ({
  children,
  defaultIsOpen = false,
  onClick,
  options,
  startIcon,
  tooltipMessage,
}) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);
  const { anchorProps, popperProps } = usePopper({
    offset: [0, 0],
    placement: 'bottom-start',
  });

  const handleToggleDropdown = () => {
    setIsOpen((current) => !current);
  };

  const handleOverlayClick = () => {
    setIsOpen(false);
  };

  return (
    <StyledDropdownButton
      alignItems="center"
      compDisplay="flex"
      compWidth="fit-content"
      id="dropdown-button-container"
      {...anchorProps}
    >
      <StyledDropdownButtonMainButtonContainer>
        <StyledDropdownButtonMainButton isMainButton onClick={onClick} startIcon={startIcon}>
          {children}
        </StyledDropdownButtonMainButton>
        <StyledDropdownButtonDivisor />
      </StyledDropdownButtonMainButtonContainer>
      <Tooltip content={tooltipMessage}>
        <StyledDropdownButtonIconButton
          iconColor="white"
          iconName="down"
          iconSize="16px"
          onClick={handleToggleDropdown}
          variant="clear"
        />
      </Tooltip>
      {isOpen && (
        <Portal>
          <StyledDropdownButtonOptionsOverlay onClick={handleOverlayClick} role="presentation">
            <StyledDropdownButtonOptionsContainer
              aria-label="dropdown-button-options"
              role="listbox"
              {...popperProps}
            >
              {options.map(({ icon, iconColor, id, label, name, onClick: optionOnClick }) => (
                <StyledDropdownButtonMainButton
                  key={id}
                  color="gray.800"
                  compSize="sm"
                  fontWeight={400}
                  minHeight="100%"
                  name={name}
                  onClick={optionOnClick}
                  variant="text"
                >
                  {icon && <Icon color={iconColor} name={icon} size="16px" />} {label}
                </StyledDropdownButtonMainButton>
              ))}
            </StyledDropdownButtonOptionsContainer>
          </StyledDropdownButtonOptionsOverlay>
        </Portal>
      )}
    </StyledDropdownButton>
  );
};

export default DropdownButton;
