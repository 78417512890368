import { Expose, Transform, Type } from 'class-transformer';
import moment from 'moment';

import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import type { DescriptionSource } from '@api/description/description.types';
import { TaggedItemModel } from '@api/tags/TaggedItemModel';
import { ObjectType } from '@api/types';
import { BiConnectionModel } from '@api/views/BiConnectionModel';
import { breadcrumbsToLabelList, breadcrumbsToList } from '@components/Breadcrumbs';
import isEmptyRichText from '@components/RichTextEditor/helpers/isEmptyRichText';
import type { DataSourceTypesType } from '@models/DataSourceCredentials';
import { DataSourceModel } from '@models/DataSourceModel';
import DataTypesModel from '@models/DataTypesModel';
import { PopularityModel } from '@models/PopularityModel';
import RelatedObjectsCountsModel from '@models/RelatedObjectsCountsModel';
import getParentName from '@utils/getParentName/getParentName';
import { urlFor } from '@utils/routing';
import titlelize from '@utils/titlelize';

type TableauDataSourceType = 'published' | 'unknown';

export class TableauDataSourceModel {
  static objectType: ObjectType = 'tableaudatasource';

  objectType: ObjectType = TableauDataSourceModel.objectType;

  objectTypeV1 = 'tableauDataSources' as const;

  static typeDisplay: string = 'Tableau Data Source';

  typeDisplay: string = TableauDataSourceModel.typeDisplay;

  guid!: string;

  @Expose({ name: 'external_id' })
  externalId!: string;

  name!: string;

  @Expose({ name: 'search_name' })
  fullName?: string;

  description?: string;

  // TODO: Remove transform https://app.shortcut.com/select-star/story/55620
  @Expose({ name: 'richtext_description' })
  @Transform((richtextDescription) =>
    isEmptyRichText(richtextDescription) ? '' : richtextDescription,
  )
  richtextDescription?: string;

  suggestedDescriptionSource?: undefined;

  suggestedDescriptionSourceObject?: undefined;

  @Expose({ name: 'data_source_updated_at' })
  @Transform((value) => moment(value))
  updatedAt!: moment.Moment;

  @Expose({ name: 'data_source' })
  dataSource?: DataSourceModel;

  @Type(() => BiConnectionModel)
  databases?: BiConnectionModel[];

  @Expose({ name: 'tds_type' })
  tableauDataSourceType?: TableauDataSourceType;

  @Type(() => PopularityModel)
  popularity?: PopularityModel;

  @Expose({ name: 'tagged_items' })
  @Type(() => TaggedItemModel)
  taggedItems?: TaggedItemModel[];

  @Expose({ name: 'url' })
  externalUrl?: string;

  @Expose({ name: 'full_path' })
  fullPath?: string;

  @Expose({ name: 'is_hidden' })
  isHidden?: boolean;

  @Expose({ name: 'downstream_objects_counts' })
  @Type(() => RelatedObjectsCountsModel)
  downstreamObjectsCounts?: RelatedObjectsCountsModel;

  @Expose({ name: 'upstream_objects_counts' })
  @Type(() => RelatedObjectsCountsModel)
  upstreamObjectsCounts?: RelatedObjectsCountsModel;

  @Expose({ name: 'data_type' })
  dType?: string;

  @Expose({ name: 'data_source_type' })
  dataSourceType?: DataSourceTypesType;

  @Expose({ name: 'data_types' })
  @Type(() => DataTypesModel)
  dataTypes?: DataTypesModel = new DataTypesModel();

  @Expose({ name: 'query' })
  rawSql?: string;

  /**
   * @experimental
   * @see TableModel for more info.
   */

  get supTitle() {
    return getParentName(this?.name ?? '', this?.fullPath);
  }

  get lastUpdated() {
    return this.updatedAt;
  }

  get connections() {
    if (!this?.databases || this?.databases?.length === 0) {
      return [{ icon: undefined, name: 'no connections' }];
    }

    return this.databases?.map((item) => ({
      icon: item?.icon,
      name: item?.name ?? item?.typeDisplay,
    }));
  }

  /** @todo Architecture. Clarify why dataType is missed. Same for LookerExploreModel */
  get dataType() {
    return this.dType || ('tableaudatasource' as const);
  }

  get dataSourceGuid() {
    return this.dataSource?.guid;
  }

  @Type(() => BreadcrumbModel)
  breadcrumbs?: BreadcrumbModel[];

  get breadcrumbList() {
    return breadcrumbsToList(this.breadcrumbs);
  }

  get breadcrumbLabelList() {
    const url = urlFor(this, false, '');
    return breadcrumbsToLabelList(this.name, url, this.breadcrumbs);
  }

  bytes = undefined;

  rowCount = undefined;

  lastRefreshed = undefined;

  materialization = undefined;

  showLinksTo = undefined;

  linksToObjects = undefined;

  showLinkedFrom = false;

  linkedFromObjects = undefined;

  showMentionedBy = true;

  showTableLastQuery = undefined;

  suggestedDescription = undefined;

  technicalOwner = undefined;

  businessOwner = undefined;

  owner = undefined;

  loadingStatus = undefined;

  created = undefined;

  itemsType = undefined;

  lastRun = undefined;

  showEditTags = true;

  showCustomAttributes = true;

  cloudObject = undefined;

  showDescriptionSelector = true;

  @Expose({ name: 'description_source' })
  descriptionSource?: DescriptionSource;

  @Expose({ name: 'ai_description' })
  aiDescription?: string;

  @Expose({ name: 'ingested_description' })
  ingestedDescription?: string;

  @Expose({ name: 'user_description' })
  userDescription?: string;

  @Expose({ name: 'connection_method' })
  connectionMethod?: string;

  type = undefined;

  get formattedConnectionMethod() {
    return titlelize(this.connectionMethod);
  }
}
