import React, { useEffect, useState } from 'react';

import { useStickyProvider } from '@components/Sticky/Sticky';
import Icon from '@components/UI/Icon';
import useNewLayout from '@hooks/useNewLayout';
import useWindowSize from '@hooks/useWindowSize';

import { StyledRightSidebar, StyledRightSidebarButton } from './RightSidebar.styles';

interface RightSidebarContainerProps {
  'aria-label'?: string;
  hideToggle?: boolean;
  noPadding?: boolean;
  width?: number;
}

const RightSidebarContainer: React.FC<RightSidebarContainerProps> = ({
  children,
  hideToggle = false,
  noPadding = false,
  width = 300,
  ...other
}) => {
  const { isUpMd } = useWindowSize();
  const [sideBarWidth, setSideBarWidth] = useState(width);
  const stickyProvider = useStickyProvider();
  const { shouldUseNewLayout } = useNewLayout();
  const headerHeight = stickyProvider?.elementsRef.current?.AppHeader?.current?.clientHeight;
  const [isActive, setActive] = useState<{ status: 'default' | 'dirty'; value: boolean }>({
    status: 'default',
    value: isUpMd,
  });

  useEffect(() => {
    setActive((prev) => ({ status: prev.status, value: isUpMd }));
  }, [isUpMd]);

  const toggleMenu = () => {
    setActive((prev) => ({ status: 'dirty', value: !prev.value }));
  };

  return (
    <StyledRightSidebar
      className="resizableRight"
      defaultSize={{ height: 'auto', width: isActive.value ? sideBarWidth : 'auto' }}
      enable={{ left: true }}
      isActive={isActive.value}
      maxWidth={isActive.value ? '50%' : '20px'}
      minWidth={isActive.value ? width : '20px'}
      noPadding={noPadding}
      onResizeStop={(_e: any, _direction: any, ref: any) => {
        if (ref.offsetWidth < width) {
          setSideBarWidth(width);
        } else {
          setSideBarWidth(ref.offsetWidth);
        }
      }}
      role="complementary"
      top={headerHeight}
      useNewLayout={shouldUseNewLayout}
      width={width}
      {...other}
    >
      {!hideToggle && (
        <StyledRightSidebarButton
          animate={isActive.status === 'dirty'}
          isActive={isActive.value}
          onClick={toggleMenu}
          onKeyDown={toggleMenu}
          role="button"
          tabIndex={-1}
          useNewLayout={shouldUseNewLayout}
        >
          <Icon
            className="icon"
            color={shouldUseNewLayout ? 'primary.500' : 'currentColor'}
            name="right"
            size={shouldUseNewLayout ? '16px' : '19px'}
          />
        </StyledRightSidebarButton>
      )}
      <div className={isActive.value ? 'right-sidebar-content-active' : 'right-sidebar-content'}>
        {isActive.value && children}
      </div>
    </StyledRightSidebar>
  );
};

export default RightSidebarContainer;
