import moment from 'moment';

import { PopularityModel } from '@models/PopularityModel';
import { getPopularityNormalized } from '@utils/popularity';

export interface PopularityData {
  endsOn: moment.Moment;
  popularity?: number;
  queryCount?: number;
  timeframe?: moment.Moment;
  userCount: number;
  viewCount?: number;
}

const mapPopularityModel = (data?: any): PopularityModel | undefined => {
  if (!data) {
    return undefined;
  }

  const obj = {
    endsOn: (() => moment(data?.ends_on))(),
    formattedPopularity: getPopularityNormalized(data?.popularity),
    objectType: 'popularity',
    popularity: data?.popularity,
    queryCount: data?.query_count,
    serviceQueryCount: data?.service_query_count,
    timeframe: (() => (data?.timeframe ? moment(data?.timeframe) : data?.timeframe))(),
    typeDisplay: 'Popularity',
    userCount: data?.user_count,
    viewCount: data?.view_count,
  };

  return {
    ...obj,
    count: (() => {
      const queryOrViewCount = obj.queryCount || obj.viewCount || 0;

      return queryOrViewCount + obj.serviceQueryCount;
    })(),
  };
};

export default mapPopularityModel;
