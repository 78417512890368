import { dataSources as dataSourcesConfig } from '@configs/dataSources/config';
import { DataSources } from '@configs/dataSources/types';
import * as Sentry from '@sentry/react';

import { IconProps } from '@components/UI/Icon';
import { DataSourceModel } from '@models/DataSourceModel';
import InvalidDataException from '@utils/sentry/errors/invalidDataException';

export type GetDataSourceTypesOption = {
  icon: IconProps['name'];
  key: keyof DataSources;
  text: string;
  value: keyof DataSources;
};

export type GetDatasourceTypesResult = Record<keyof DataSources, GetDataSourceTypesOption>;

export const getDatasourceTypesOptions = (
  dataSources: Record<string, DataSourceModel> = {},
): GetDataSourceTypesOption[] => {
  const results = {} as GetDatasourceTypesResult;

  Object.values(dataSources).forEach((ds) => {
    if (ds.isEmailSent !== false && ds.dataTypes && ds.shouldShow) {
      const type = ds?.dataTypes?.dataSourceType as keyof DataSources;

      results[type] = {
        icon: ds.dataTypes?.icons?.dataSource ?? 'question',
        key: type,
        text: dataSourcesConfig?.[type]?.text ?? type,
        value: type,
      };

      if (!type) {
        Sentry.captureException(
          new InvalidDataException(
            `Newly added/unhandled DS type "${type}" discovered in getDatasourceTypesOptions(). It could lead to incorrect icons or text in the UI.`,
          ),
          {
            extra: {
              ...ds?.dataTypes,
            },
          },
        );
      }
    }
  });

  return Object.values(results);
};

export default getDatasourceTypesOptions;
