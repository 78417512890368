const formatByte = (bytes: number = 0): string => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  const limit = 1024;

  const unitIndex = Math.max(
    0,
    Math.min(Math.floor(Math.log(bytes) / Math.log(limit)), units.length - 1),
  );

  const value = Intl.NumberFormat(undefined, {
    maximumFractionDigits: 2,
  }).format(bytes / limit ** unitIndex);

  if (value === '0') {
    return value;
  }

  return `${value} ${units[unitIndex]}`;
};

export default formatByte;
