import { Expose, Transform } from 'class-transformer';

import isEmptyRichText from '@components/RichTextEditor/helpers/isEmptyRichText';

type ConversionDescriptionFormat = 'markdown' | 'slatejs';

export class DescriptionConversionModel {
  @Expose({ name: 'source_format' })
  sourceFormat!: ConversionDescriptionFormat;

  @Expose({ name: 'target_format' })
  targetFormat!: ConversionDescriptionFormat;

  // TODO: Remove transform https://app.shortcut.com/select-star/story/55620
  @Expose({ name: 'richtext_description' })
  @Transform((richtextDescription) =>
    isEmptyRichText(richtextDescription) ? '' : richtextDescription,
  )
  richtextDescription!: string;
}
