import React, { useEffect } from 'react';

import { usePostCustomerPortal } from '@api/billing';
import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import TabError from '@components/TabContent/TabError';
import { TabContentProps } from '@components/Tabs/types';

const CustomerPortalTab: React.FC<TabContentProps> = () => {
  const { data, isError, isLoading } = usePostCustomerPortal();

  useEffect(() => {
    if (data?.session_url && window.location) {
      window.location.href = data.session_url;
    }
  }, [data]);

  if (isLoading) return <CircularLoader compDisplay="block" mx="auto" my={2} />;
  if (isError) return <TabError />;

  return (
    <Box p={2}>
      <p>
        If you aren't redirected to the stripe customer portal click{' '}
        <a href={data?.session_url}>here.</a>
      </p>
    </Box>
  );
};

export default React.memo(CustomerPortalTab);
