import findConfig from '@configs/dataTypes/findConfig';
import type { DataTypesConfig } from '@configs/dataTypes/types';
import getIcons from '@configs/icons/getIcons';
import getTooltips from '@configs/tooltips/getTooltips';
import { Expose } from 'class-transformer';

import type { DataType, ObjectType } from '@api/types';
import type { DataSourceTypesType } from '@models/DataSourceCredentials';
import getTooltip from '@utils/getTooltip';
import titlelize from '@utils/titlelize';

export interface DataTypesPayload {
  data_source_type: string;
  data_type?: string;
  object_type: string;
}

class DataTypesModel {
  @Expose({ name: 'data_source_type' })
  dataSourceType!: DataSourceTypesType;

  @Expose({ name: 'data_type' })
  rawDataType?: DataType;

  @Expose({ name: 'object_type' })
  objectType!: ObjectType;

  /** TODO: update hierarchy to stop sending the data source as data_type */

  get dataType() {
    if (this.dataSourceType === this.rawDataType) {
      return '' as any;
    }

    return this.rawDataType;
  }

  get icons() {
    return getIcons(this);
  }

  get tooltips() {
    const dataTypesTooltips = getTooltips(this);
    const dataType = getTooltip(this.objectType, this.dataType);
    const objectType = getTooltip(this.objectType);
    return {
      dataSource: dataTypesTooltips.dataSource ?? titlelize(this.dataSourceType),
      dataType: dataTypesTooltips.dataType ?? dataType ?? objectType,
      objectType: dataTypesTooltips.objectType ?? objectType,
    };
  }

  findConfig<T>(ref: DataTypesConfig<any>) {
    return findConfig<T>(ref, this);
  }
}

export default DataTypesModel;
