import styled from '@emotion/styled';
import * as CSS from 'csstype';

import getColor, { GetColorProps } from '@styles/mixins/getColor';
import makeSpace, { MakeSpaceProps } from '@styles/mixins/makeSpace';
import rawTheme from '@styles/theme';
import type { FontFamily, FontSize, FontWeight, LineHeight } from '@styles/theme/typography';

export interface StyledTextProps {
  alignItems?: CSS.Property.AlignItems;
  color?: GetColorProps | CSS.Property.Color;
  cursor?: CSS.Property.Cursor;
  display?: CSS.Property.Display;
  flexGrow?: CSS.Property.FlexGrow;
  fontFamily?: FontFamily;
  fontSize?: FontSize | CSS.Property.FontSize;
  fontStyle?: CSS.Property.FontStyle;
  fontWeight?: FontWeight;
  gap?: number;
  lineBreak?: CSS.Property.LineBreak;
  lineHeight?: LineHeight | CSS.Property.LineHeight;
  textAlign?: CSS.Property.TextAlign;
  textDecoration?: CSS.Property.TextDecoration;
  textDecorationHover?: CSS.Property.TextDecoration;
  textTransform?: CSS.Property.TextTransform;
  verticalAlign?: CSS.Property.VerticalAlign;
  whiteSpace?: CSS.Property.WhiteSpace;
  wordBreak?: CSS.Property.WordBreak;
}

const Text = styled.p<StyledTextProps & MakeSpaceProps>`
  ${({ theme, ...props }) => makeSpace(theme, props)};
  font-family: ${({ fontFamily = 'primary', theme }) => theme.typography.fontFamilies[fontFamily]};
  font-size: ${({ fontSize = '16px', theme }) =>
    theme.typography.fontSizes[fontSize as FontSize] ?? fontSize};
  font-weight: ${({ fontWeight = 'regular', theme }) => theme.typography.fontWeights[fontWeight]};
  color: ${({ color = rawTheme.colors.text }) => getColor(color)};
  text-align: ${({ textAlign = 'left' }) => textAlign};
  display: ${({ display = 'inline-flex' }) => display};
  line-break: ${({ lineBreak = 'normal' }) => lineBreak};
  line-height: ${({ lineHeight = 'normal', theme }) =>
    theme.typography.lineHeights[lineHeight as LineHeight] ?? lineHeight};
  text-decoration: ${({ textDecoration = 'none' }) => textDecoration};
  font-style: ${({ fontStyle = 'initial' }) => fontStyle};
  word-break: ${({ wordBreak = 'break-word' }) => wordBreak};
  text-transform: ${({ textTransform = 'none' }) => textTransform};
  white-space: ${({ whiteSpace = 'normal' }) => whiteSpace};
  cursor: ${({ cursor = 'inherit' }) => cursor};
  vertical-align: ${({ verticalAlign }) => verticalAlign};
  flex-grow: ${({ flexGrow }) => flexGrow};
  gap: ${({ gap, theme }) => (gap ? theme.space(gap) : undefined)};
  align-items: ${({ alignItems }) => alignItems};

  &:hover {
    text-decoration: ${({ textDecorationHover = 'none' }) => textDecorationHover};
  }
`;

export default Text;

/*
 *  The default styles have been implemented here because
 *  1. Semantic UI styles can be easily over written
 *  2. We don't have to keep repeating the styles all over the application
 *  TODO: update this so we better have separation
 *  of styles and components.
 */
export const defaultParagraphStyles: StyledTextProps = {
  display: 'block',
  fontSize: '16px',
  fontWeight: 'regular',
};
