import React from 'react';

import { DescriptionSource } from '@api/description/description.types';
import Box from '@components/Box';
import DotsLoader from '@components/DotsLoader';
import Ellipsis from '@components/Ellipsis';
import Text from '@components/Text';
import Icon from '@components/UI/Icon';
import { ValidDSType } from '@models/DataSourceCredentials';
import { MetadataModel } from '@models/MetadataModel';
import theme from '@styles/theme';

import { StyledDescriptionItemWrapper } from './DescriptionSelectorItem.styles';
import SourceLabel from './SourceLabel';

export interface DescriptionSelectorItemProps {
  dataSourceType?: ValidDSType;
  guid?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  plainDescription?: string;
  plainUserDescription?: string;
  selected?: boolean;
  source?: DescriptionSource;
  suggestedDescriptionSourceObject?: MetadataModel;
}

const DescriptionSelectorItem: React.FC<DescriptionSelectorItemProps> = ({
  dataSourceType,
  guid,
  onClick,
  plainDescription,
  plainUserDescription,
  selected = false,
  source,
  suggestedDescriptionSourceObject,
}) => {
  const isLoading = source === 'ai' && plainDescription === undefined;
  return (
    <StyledDescriptionItemWrapper
      isLoading={isLoading}
      onClick={(e) => {
        if (!isLoading) {
          onClick?.(e);
        }
      }}
      role="option"
      selected={selected}
      source={source}
    >
      {isLoading ? (
        <DotsLoader />
      ) : (
        <Text
          color={selected ? 'primary.500' : 'gray.700'}
          fontSize={theme.typography.fontSizes.body1}
          fontWeight="medium"
        >
          <Ellipsis multipleLinesNumber="2">{plainDescription}</Ellipsis>
          {selected && <Icon color={theme.colors.v1.primary[900]} name="check" size="12px" />}
        </Text>
      )}
      <Box compDisplay="flex" flexDirection="column" pt={1}>
        <SourceLabel
          dataSourceType={dataSourceType}
          guid={guid}
          plainUserDescription={plainUserDescription}
          source={source}
          suggestedDescriptionSourceObject={suggestedDescriptionSourceObject}
        />
      </Box>
    </StyledDescriptionItemWrapper>
  );
};

export default DescriptionSelectorItem;
