import { Expose, Type } from 'class-transformer';

import { ProcessedThroughEntry } from '@models/ingestionConfig/ProcessedThroughEntry';

export interface ProcessedThroughModel {
  startTime?: ProcessedThroughEntry[];
}

export class ProcessedThroughModel {
  static objectType: string = 'processedThroughModel';

  objectType: string = ProcessedThroughModel.objectType;

  @Type(() => ProcessedThroughEntry)
  @Expose({ name: 'start_time' })
  startTime?: ProcessedThroughEntry[];
}
