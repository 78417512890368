import React from 'react';
import { Cell } from 'react-table';

import { MappedConnectioAlerts } from '@api/connectionAlerts/ConnectionAlertModel';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import { getConfigureStepIndex } from '@components/DataSourceSetup/steps';
import DateTime from '@components/DateTime';
import IconButton from '@components/IconButton';
import Skeleton from '@components/Skeleton';
import Table from '@components/Table/Table';
import { sortDates } from '@components/Table/Table/sortDates';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import { useModal } from '@context/Modal';
import { DataSourceModel } from '@models/DataSourceModel';
import { ProcessedThroughEntry } from '@models/ingestionConfig/ProcessedThroughEntry';
import theme from '@styles/theme';

import { DataSourceLink } from './AdminDatasourcesTable.styles';
import ConnectionStatus from './ConnectionStatus';

const initialSortState = [
  {
    desc: false,
    id: 'name',
  },
];

interface AdminDataSourcesTableProps {
  connectionAlerts?: MappedConnectioAlerts;
  data?: DataSourceModel[];
  'data-testid'?: string;
  isIncompleteConnection?: boolean;
  isLoading?: boolean;
  isLoadingConnectionAlerts?: boolean;
  onDataSourceConfigure?: (dataSource: DataSourceModel) => void;
  onDataSourceUpdate?: (dataSource: DataSourceModel) => void;
}

const AdminDataSourcesTable: React.FC<AdminDataSourcesTableProps> = ({
  connectionAlerts,
  data,
  'data-testid': testId,
  isIncompleteConnection,
  isLoading,
  isLoadingConnectionAlerts,
  onDataSourceConfigure,
  onDataSourceUpdate,
}) => {
  const { MODAL_IDS, openModal } = useModal();

  const itemsCount: number = data?.length || 0;

  const columns: ColumnConfig<DataSourceModel>[] = React.useMemo(
    () => [
      {
        Cell: (props: Cell<DataSourceModel>) => {
          const {
            row: { original: ds },
          } = props;

          return (
            <Box alignItems="center" compDisplay="flex" justifyContent="flex-start">
              <Icon mr={0.5} name={ds.dataTypes?.icons.dataSource!} size="16px" />
              <DataSourceLink to={`/admin/${ds.type}/${ds.guid}`}>{ds.name}</DataSourceLink>
            </Box>
          );
        },
        Header: `Data Source (${itemsCount})`,
        accessor: (d) => d.name?.toLowerCase(),
        disableHiding: true,
        id: 'name',
        width: '35%',
      },
      {
        Cell: (props: Cell<DataSourceModel>) => {
          const {
            row: { original: ds },
          } = props;

          if (isLoadingConnectionAlerts) {
            return <Skeleton compWidth="50%" grayShade={200} variant="text" />;
          }

          return (
            <ConnectionStatus
              alerts={connectionAlerts?.[ds.guid]}
              isIncompleteConnection={isIncompleteConnection}
              isSyncingPaused={ds.isSyncingPaused}
              onClick={(alerts) =>
                openModal(MODAL_IDS.connectionStatus, { connectionAlerts: alerts, dataSource: ds })
              }
            />
          );
        },
        Header: 'Connection Status',
        accessor: (ds: Partial<DataSourceModel>) => {
          const alerts = connectionAlerts?.[ds.guid ?? ''];

          if (!alerts || alerts.length === 0) return 1;

          if (alerts.some((alert) => alert.level === 'error')) return 3;

          return 2;
        },
        disableHiding: true,
        id: 'status',
        width: '10rem',
      },
      {
        Cell: (props: Cell<DataSourceModel>) => {
          const {
            row: { original: ds },
          } = props;

          return (
            <Box as="button" onClick={() => openModal(MODAL_IDS.ingestionLog, { dataSource: ds })}>
              <DateTime datetime={ds.lastIngestedOn} format="fullDateFormat" />
            </Box>
          );
        },
        Header: 'Last Updated',
        accessor: (d) => d.lastIngestedOn?.toDate(),
        id: 'lastUpdated',
        sortDescFirst: true,
        sortType: sortDates,
        width: '35%',
      },
      {
        Cell: (props: Cell<DataSourceModel>) => {
          const {
            row: { original: ds },
          } = props;

          if (ds.processedThrough?.startTime && ds.processedThrough?.startTime?.length > 0) {
            let maxMoment: moment.Moment | undefined;
            const timeList: Array<string> = ds.processedThrough?.startTime
              .filter((entry: ProcessedThroughEntry) => entry.date)
              .map((entry: ProcessedThroughEntry) => {
                if (maxMoment && entry.date!.isAfter(maxMoment)) {
                  maxMoment = entry.date!;
                } else {
                  maxMoment = entry.date!;
                }
                return `(${entry.key}: ${entry.date?.format('YYYY-MM-DD HH:mm:ss')})`;
              });

            const timeTooltipText: string =
              ds.type === 'bigquery'
                ? timeList.join(', ')
                : maxMoment?.format('YYYY-MM-DD HH:mm:ss') || '';

            return maxMoment ? (
              <Tooltip content={timeTooltipText} data-html="true">
                <span>{maxMoment.format('YYYY-MM-DD HH:mm:ss')}</span>
              </Tooltip>
            ) : null;
          }
          return null;
        },
        Header: 'Query history processed until',
        disableResizing: true,
        id: 'processedThrough',
        width: '35%',
      },
      {
        Cell: (props: Cell<DataSourceModel>) => {
          const {
            row: { original: ds },
          } = props;

          return getConfigureStepIndex(ds.type) !== undefined && onDataSourceConfigure ? (
            <Button
              onClick={() => {
                onDataSourceConfigure(ds);
              }}
              px={0}
              type="button"
              variant="text"
            >
              Configure
            </Button>
          ) : null;
        },
        center: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'configure',
        width: '6.5rem',
      },
      {
        Cell: (props: Cell<DataSourceModel>) => {
          const {
            row: { original: ds },
          } = props;

          if (!onDataSourceUpdate) return null;
          return ds.isIngesting ? (
            <Button disabled px={0} type="button" variant="text">
              Sync in Progress
            </Button>
          ) : (
            <Button onClick={() => onDataSourceUpdate(ds)} px={0} type="button" variant="text">
              Sync metadata
            </Button>
          );
        },
        center: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'sync',
        width: '8.3rem',
      },
      {
        Cell: (props: Cell<DataSourceModel>) => {
          const {
            row: { original: ds },
          } = props;

          return (
            <IconButton
              iconColor={theme.colors.v1.gray[600]}
              iconName="trash"
              iconSize="20px"
              onClick={() => openModal(MODAL_IDS.deleteDataSource, { item: ds })}
              variant="clear"
              verticalAlign="middle"
            />
          );
        },
        center: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'delete',
        width: '3rem',
      },
    ],
    [
      connectionAlerts,
      itemsCount,
      openModal,
      MODAL_IDS,
      onDataSourceConfigure,
      onDataSourceUpdate,
      isLoadingConnectionAlerts,
    ],
  );

  const getRowId = React.useCallback((row: Partial<DataSourceModel>) => row.guid!, []);

  return (
    <TableStyled>
      <Table
        basic="very"
        className="table-full"
        columns={columns}
        compact
        data={data || []}
        data-testid={testId}
        disableColumnFiltering
        disableFilters
        disablePagination
        disableRowSelect
        getRowId={getRowId}
        initialState={{
          sortBy: initialSortState,
        }}
        loading={isLoading}
        selectable
        singleLine
        sortable
        unstackable
      />
    </TableStyled>
  );
};

export default AdminDataSourcesTable;
