import React from 'react';

import Box from '@components/Box';
import Button from '@components/Button/Button';
import InviteUserModal from '@components/Modal/InviteUserModal';
import { useModal } from '@context/Modal';
import { useUserContext } from '@context/User';
import theme from '@styles/theme';

import HierarchyLoadingSkeleton from '../Hierarchy/HierarchyLoadingSkeleton';

import TeamsMenuSection from './TeamsMenuSection';
import useTeamsSections from './useTeamsSections';

const TeamsMenu: React.FC = () => {
  const { isLoading, sections, teams } = useTeamsSections();
  const { MODAL_IDS, checkModalOpened, closeModal, openModal } = useModal();
  const { isOrgAdmin } = useUserContext();

  if (isLoading) {
    return <HierarchyLoadingSkeleton />;
  }

  return (
    <Box overflowX="hidden" overflowY="auto">
      {isOrgAdmin && (
        <Button
          compDisplay="flex"
          compHeight={theme.space(4.5)}
          compSize="lg"
          compWidth={`calc(100% - ${theme.space(4)})`}
          justifyContent="flex-start"
          m="auto"
          mb={1}
          onClick={() => {
            openModal(MODAL_IDS.inviteUserTeamsMenu);
          }}
          startIconName="plus"
          startIconSize="20px"
          textAlign="left"
          variant="outlined"
        >
          Invite a New User
        </Button>
      )}
      {sections.map((section) => (
        <TeamsMenuSection key={section.key} section={section} />
      ))}
      {checkModalOpened(MODAL_IDS.inviteUserTeamsMenu) && (
        <InviteUserModal
          onClose={() => {
            closeModal(MODAL_IDS.inviteUserTeamsMenu);
          }}
          teams={teams}
        />
      )}
    </Box>
  );
};

export default TeamsMenu;
