import React from 'react';

import { BIFolderModel } from '@api/bifolder/BIFolderModel';
import { ColumnModel } from '@api/columns/ColumnModel';
import { DsUserModel } from '@api/dsusers/DsUserModel';
import { SchemaModel } from '@api/schema/SchemaModel';
import { TableModel } from '@api/tables/TableModel';
import Icon from '@components/UI/Icon';
import { Option } from '@components/UI/Select';
import { DataSourceModel } from '@models/DataSourceModel';

import { StyledOption } from './Dropdown.styles';

export const getDatasouceContent = (datasource: DataSourceModel) => (
  <StyledOption className="table-option">
    <span className="name">{datasource.name}</span>
  </StyledOption>
);

export const getStringOptions = (items: string[]) =>
  items.map((el) => ({
    key: el,
    name: el,
    text: el,
    value: el,
  }));

export const getDsUsersOptionsV1 = (items: DsUserModel[] = []): Option[] =>
  items.map((du) => ({
    avatar: du.mappedAvatar,
    text: du.fullName ?? '',
    value: du.guid,
  }));

export const getDatasourceOptions = (items: DataSourceModel[]) =>
  items.map((ds) => ({
    content: getDatasouceContent(ds),
    image: (
      <Icon compDisplay="inline-flex" mr={0.5} name={ds.dataTypes?.icons.dataSource!} size="16px" />
    ),
    key: ds.guid,
    name: ds.name,
    text: ds.name,
    value: ds.guid,
  }));

export const getCollectionsOptions = (items: BIFolderModel[]): Option[] =>
  items.map((folder) => ({
    icon: folder.dataTypes?.icons.dataType,
    text: folder.name,
    value: folder.guid,
  }));

export const getSchemaOptions = (items: SchemaModel[]): Option[] =>
  items.map((s) => ({
    icon: s.dataTypes?.icons.dataType,
    text: s.name,
    value: s.guid,
  }));

export const getTableOptions = (items: TableModel[]): Option[] =>
  items.map((t) => ({
    icon: t.dataTypes?.icons.dataType,
    text: t.schema?.name ? `${t.schema?.name}.${t.name}` : t.name,
    value: t.guid,
  }));

export const getColumnOptions = (items?: ColumnModel[]) =>
  items?.map((c) => {
    const fullName = `${c.table?.schema.name}.${c.table?.name}.${c.name}`;
    return {
      icon: c.dataTypes?.icons.dataType!,
      original: {
        dataTypes: c.dataTypes,
        fullName,
        guid: c.guid,
      },
      text: fullName,
      value: c.guid,
    };
  }) ?? [];
