import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from '@routing/router';
import uniqBy from 'lodash/uniqBy';
import { useSetRecoilState } from 'recoil';

import { useFetchTerms, useFetchTermsExport } from '@api/terms';
import { TermModel } from '@api/terms/TermModel';
import { metadataType } from '@atoms/MetadataType';
import CreateTermModal from '@components/AppMainSidebar/Hierarchy/DocumentsHierarchy/CreateDocMenu/CreateTermModal';
import Box from '@components/Box';
import BulkButtons from '@components/BulkButtons';
import DropdownButton from '@components/DropdownButton';
import MetadataGrid from '@components/MetadataGrid';
import MetadataGridCard from '@components/MetadataGridCard';
import TabError from '@components/TabContent/TabError';
import TagGroup from '@components/TagGroup';
import { renderErrorToast } from '@components/Toast';
import getUrl from '@components/Tree/getUrl';
import { useModal } from '@context/Modal';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import { useUserContext } from '@context/User';
import useBulkEditSelected from '@hooks/useBulkEditSelected';
import useGridListViewConfig from '@hooks/useGridListViewConfig';
import useNewLayout from '@hooks/useNewLayout';
import downloadCSV from '@utils/downloadCSV';
import { Filter } from '@utils/index';
import stripSpaces from '@utils/stripSpaces';

import { isSearchResult } from '../BaseDocsTab/BaseDocsTab';
import DeleteDocumentsModal from '../DeleteDocumentsModal';
import { searchConfig, sortConfig } from '../docsRequestConfigs';
import NoContentMessage from '../NoContentMessage';
import NoDocumentMessage from '../NoDocumentMessage';

import CreateNewTerm from './CreateNewTerm';
import ExportToCsvButton from './ExportToCsvButton';
import { StyledGlossaryTab } from './GlossaryTab.styles';
import TermsTable from './TermsTable';
import UploadCSVFile from './UploadCSVFile';

export const filterOptions: Filter.FilterOptions = {
  order: 'name',
  page_size: 20,
  query: stripSpaces(`{
    guid,
    name,
    description,
    tagged_items {
      kind,
      tag {
        breadcrumbs,
        color,
        guid,
        name,
        type,
        visible
      }
    },
    technical_owner {
      object_type,
      obj,
    },
    business_owner {
      object_type,
      obj,
    },
    created_on,
    updated_on,
    breadcrumbs,
    data_types
  }`),
  sortColumn: 'name',
  sortDirection: 'ascending',
};

export const CSV_EXPORT_FILE_NAME = 'glossary_terms.csv';

const GlossaryTab: React.FC = () => {
  const { shouldUseNewLayout } = useNewLayout();
  const { activeView, selectorComponent: viewTypeSelectorComponent } = useGridListViewConfig();
  const allFetchedTerms = useRef<TermModel[]>([]);
  const history = useHistory();
  const setMetadataType = useSetRecoilState(metadataType);
  const { organization } = useUserContext();
  const { isEditable } = useObjectPermissionsContext({ id: 'all-docs' });
  const { MODAL_IDS, checkModalOpened, closeModal, openModal } = useModal();
  const { selected, setSelected, tagsCounts } = useBulkEditSelected<TermModel>();
  const [showBulkButtons, setShowBulkButtons] = useState(false);

  const filterService = Filter.useUpdateFilters({ ...filterOptions }, searchConfig, sortConfig, '');
  const { filter } = filterService;
  const { data, isError, isLoading, refetch } = useFetchTerms({
    params: Filter.setParams(filter),
  });

  const { isLoading: isLoadingExportData, refetch: exportData } = useFetchTermsExport({
    enabled: false,
    onError: (e) => {
      renderErrorToast(e.data?.detail ?? 'Sorry, something went wrong.');
    },
    onSuccess: (d) => {
      downloadCSV(d, CSV_EXPORT_FILE_NAME);
    },
  });

  const { isPbac, permissions } = useObjectPermissionsContext({
    ids: (data?.results ?? []).map((el) => el.guid),
  });
  const useRbac = organization?.settings?.useRbac;
  const selectedEditableItems = selected.items.filter((item) =>
    isPbac ? permissions[item.guid]?.isEditable : true,
  );

  const totalPages = data && filter.page_size ? Math.ceil(data.count / filter.page_size) : 1;

  const handleSetSelectRowIds = useCallback(
    (selectedRowIds) => {
      setSelected(allFetchedTerms.current?.filter((i) => selectedRowIds?.[i.guid]));
      setShowBulkButtons(Object.keys(selectedRowIds).length > 0);
    },
    [setSelected],
  );

  const handleOnEdit = (item: TermModel) => {
    history.push(`/terms/${item.guid}`);
  };

  const handleOnDelete = (item: TermModel) => {
    setSelected([item]);
    openModal(MODAL_IDS.deleteTerm);
  };

  const resetDataAfterBulkChange = () => {
    refetch();
    handleSetSelectRowIds({});
  };

  useEffect(() => {
    setMetadataType('terms');
  }, [setMetadataType]);

  useEffect(() => {
    allFetchedTerms.current = uniqBy(
      [...(data?.results ?? []), ...allFetchedTerms.current],
      'guid',
    );
  }, [data?.results]);

  if (isError) {
    return <TabError />;
  }

  return (
    <>
      {data?.results.length === 0 && !isSearchResult(filter) ? (
        <StyledGlossaryTab
          alignItems="center"
          compDisplay="flex"
          flexDirection="column"
          justifyContent="center"
          shouldUseNewLayout={shouldUseNewLayout}
        >
          {shouldUseNewLayout ? (
            <NoDocumentMessage
              customCreateNewDocument={<CreateNewTerm disableUploadCsvButton={isLoading} />}
            />
          ) : (
            <NoContentMessage />
          )}
          {isEditable && !shouldUseNewLayout && (
            <CreateNewTerm disableUploadCsvButton={isLoading} />
          )}
        </StyledGlossaryTab>
      ) : (
        <Box>
          <Box
            compDisplay="flex"
            justifyContent={isEditable ? 'space-between' : 'flex-end'}
            mb={4.5}
            mt={2}
          >
            {!isLoading && isEditable && (
              <UploadCSVFile>
                {({ handleSelectFile }) => (
                  <DropdownButton
                    onClick={() => openModal(MODAL_IDS.createTerm)}
                    options={[
                      {
                        icon: 'upload',
                        iconColor: 'currentcolor',
                        id: '0',
                        label: (
                          <Box compWidth="120px" textAlign="start">
                            Upload CSV file
                          </Box>
                        ),
                        name: 'upload-csv',
                        onClick: handleSelectFile,
                      },
                    ]}
                  >
                    Add a new Term
                  </DropdownButton>
                )}
              </UploadCSVFile>
            )}
            {!isLoading && (
              <Box alignItems="center" compDisplay="flex" gap={0.5}>
                {viewTypeSelectorComponent}
                <ExportToCsvButton isLoading={isLoadingExportData} onClick={() => exportData()} />
              </Box>
            )}
          </Box>
          {showBulkButtons && (
            <BulkButtons
              canDelete
              canEditOwner
              canEditTags
              isDataSourceEditable
              onEdit={resetDataAfterBulkChange}
              selectedEditableItems={selectedEditableItems}
              selectedItems={selected.items}
              showText
              tagsCounts={tagsCounts}
              toggleDelete={() => openModal(MODAL_IDS.deleteTerm)}
            />
          )}
          {activeView === 'list' ? (
            <TermsTable
              data={data?.results}
              filterService={filterService}
              isDataSourceEditable
              itemCount={data?.count ?? 0}
              selectedRowIds={selected.ids}
              setSelectedRowIds={handleSetSelectRowIds}
              totalPages={totalPages}
            />
          ) : (
            <MetadataGrid
              changePage={filterService.changePage}
              data={data?.results}
              isLoading={isLoading}
              totalPages={totalPages}
            >
              {(item) => (
                <MetadataGridCard
                  description={item.description}
                  footer={
                    Boolean(item?.taggedItems?.length) && (
                      <Box alignItems="center" compDisplay="flex" mt={1}>
                        <TagGroup
                          tag={item?.taggedItems?.[0]?.tag}
                          totalTags={item?.taggedItems?.length}
                        />
                      </Box>
                    )
                  }
                  isEditable={useRbac ? permissions?.[item.guid]?.isEditable : isEditable}
                  minHeight="180px"
                  name={item.name}
                  onDelete={() => handleOnDelete(item)}
                  onEdit={() => handleOnEdit(item)}
                  url={getUrl(item)}
                />
              )}
            </MetadataGrid>
          )}
        </Box>
      )}
      {checkModalOpened(MODAL_IDS.deleteTerm) && (
        <DeleteDocumentsModal
          onClose={() => closeModal(MODAL_IDS.deleteTerm)}
          reloadData={resetDataAfterBulkChange}
          selectedItems={selectedEditableItems}
        />
      )}
      {checkModalOpened(MODAL_IDS.createTerm) && (
        <CreateTermModal onClose={() => closeModal(MODAL_IDS.createTerm)} />
      )}
    </>
  );
};

export default GlossaryTab;
