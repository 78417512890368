import React from 'react';

import useNewLayout from '@hooks/useNewLayout';

import AppHeaderV0 from './AppHeader';
import AppHeaderV1, { AppHeaderProps } from './AppHeader.v1';

const AppMainSidebar: React.FC<AppHeaderProps> = (props) => {
  const { shouldUseNewLayout } = useNewLayout();

  return shouldUseNewLayout ? <AppHeaderV1 {...props} /> : <AppHeaderV0 {...props} />;
};

export default AppMainSidebar;
