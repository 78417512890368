import styled from '@emotion/styled';

import Box from '@components/Box';

export const StyledUserSettingsPageInputContainer = styled(Box)`
  grid-template-columns: 200px 1fr;
  display: grid;
  align-items: center;
  margin-bottom: 1rem;
`;
