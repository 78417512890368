import React from 'react';
import YouTube from 'react-youtube';

interface YoutubePlayerProps {
  id: string;
}

const YoutubePlayer: React.FC<YoutubePlayerProps> = ({ id }) => {
  return <YouTube videoId={id} />;
};

export default YoutubePlayer;
