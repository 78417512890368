import React from 'react';
import { LOADING_TEXT } from '@constants';
import styled from '@emotion/styled';

import { HierarchyData } from '@models/HierarchyModel';
import { HierarchyState } from '@models/HierarchyState';

import TreeNode from './TreeNode';

const TreeStyle = styled.div`
  font-size: 0.875rem;
  color: #44464a;
`;

interface Props {
  data?: HierarchyData[];
  filter?: string;
  loading?: boolean;
  state: HierarchyState;
}

/**
 * @deprecated. Use Tree.v2 instead.
 */
const Tree: React.FC<Props> = (props) => {
  const { data, filter, loading = false, state } = props;

  if (loading) {
    return <div>{LOADING_TEXT}</div>;
  }

  return (
    <TreeStyle>
      {data &&
        !!data.length &&
        data.map((node) => (
          <TreeNode
            key={node.guid}
            filter={filter}
            level={0}
            node={node}
            path={[node.guid]}
            state={state}
          />
        ))}
    </TreeStyle>
  );
};

export default Tree;
