import styled from '@emotion/styled';

export const StyledActivityLink = styled.span`
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.typography.fontSizes.body2};
  line-height: ${({ theme }) => theme.typography.lineHeights.md};
`;

interface StyledActivityLinkLinkProps {
  as: React.ElementType;
  to: string;
}

export const StyledActivityLinkLink = styled.span<StyledActivityLinkLinkProps>`
  font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
  cursor: pointer;
  color: inherit !important;
  word-break: break-word;
  overflow-wrap: break-word;
`;

export const StyledActivityPreTarget = styled.span`
  margin-right: ${({ theme }) => theme.space(0.25)};
`;

export const StyledActivityPostTarget = styled.span`
  margin-left: ${({ theme }) => theme.space(0.25)};
`;
