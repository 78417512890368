import { NodeDragEventParams } from 'rc-tree/lib/contextTypes';
import { EventDataNode, Key } from 'rc-tree/lib/interface';

import type { TreeKey } from '../types';

const UNKNOWN_TREE_KEY = 'UNKNOWN_TREE_KEY';

export const extractKey = (obj: { [key: string]: any } | undefined, keyAlias: string) => {
  return obj?.[keyAlias] ?? obj?.key ?? UNKNOWN_TREE_KEY;
};

export type DropNodeInfo = NodeDragEventParams & {
  dragNode: EventDataNode;
  dragNodesKeys: Key[];
  dropPosition: number;
  dropToGap: boolean;
};

interface DropNodeProps<T> {
  info: DropNodeInfo;
  initialData?: T;
  keyAlias?: string;
}

const calculateNodeDrop = <T extends { children?: T[]; key?: string }>({
  info,
  initialData = [],
  keyAlias = '',
}: DropNodeProps<T[]>): { data: any; ordinal: number; parent: TreeKey | null } => {
  const dropKey = info.node.key;
  const dragKey = info.dragNode.key;
  const dropPos = info.node.pos.split('-');
  const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
  const data = [...initialData];
  let ordinal = 0;
  let dragObj: T | undefined;
  let parent: TreeKey | null = null;

  const loop = (
    d: T[],
    key: number | string,
    callback: (item: T, index: number, arr: T[], parentNode?: T) => void,
    parentNode?: T,
  ) => {
    for (let i = 0; i < d.length; i += 1) {
      const node = d[i];
      const keyFromTree = extractKey(node, keyAlias);

      if (keyFromTree === key) {
        return callback(d[i], i, d, parentNode);
      }

      if (node.children) {
        loop(node.children, key, callback, node);
      }
    }
  };

  // Find dragObject
  loop(data, dragKey, (item, index, arr) => {
    arr.splice(index, 1);
    dragObj = item;
  });

  if (dropPosition === 0) {
    // Drop on the content
    loop(data, dropKey, (item) => {
      item.children = item.children || [];

      if (dragObj) {
        item.children.unshift(dragObj);
      }
    });
  } else {
    // Drop on the gap (insert before or insert after)
    let ar: T[] = [];
    let i = 0;

    loop(data, dropKey, (item, index, arr) => {
      ar = arr;
      i = index;
    });

    if (ar && dragObj) {
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
        ordinal = i;
      } else {
        ar.splice(i + 1, 0, dragObj);
        ordinal = i + 1;
      }
    }
  }

  // Set parent;
  loop(data, dragKey, (item, index, arr, parentNode) => {
    parent = parentNode ? extractKey(parentNode, keyAlias) : null;
  });

  return { data, ordinal, parent };
};

export default calculateNodeDrop;
