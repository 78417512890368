import { useFetchTerm, usePatchTerm } from '@api/terms';
import { TERM_DESCRIPTION_ELEMENTS } from '@components/AppMainSidebar/Hierarchy/DocumentsHierarchy/CreateDocMenu/CreateTermModal/CreateTermModal';

import MentionedBySection from './MentionedBySection';
import query from './query';
import TermsTabContent from './TermsTabContent';

export default {
  Content: TermsTabContent,
  OverviewCustomContent: MentionedBySection,
  descriptionAllowedElements: TERM_DESCRIPTION_ELEMENTS,
  fetchHook: useFetchTerm,
  metadataObjectButtonToolbarConfig: {
    buttons: [],
  },
  patchHook: usePatchTerm,
  query,
  redirectOnDelete: '/docs/tabs/all',
};
