import styled from '@emotion/styled';

import Link from '@components/Link';

export const ProfileMenuWrapper = styled.nav`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  position: relative;

  .profile-menu-list {
    position: absolute;
    width: 120px;
    top: calc(100% + 0.8rem);
    right: -0.25rem;
    max-width: 7.5rem;
    padding: 0;
    margin: 0;
    list-style: none;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
    z-index: ${({ theme }) => theme.zIndex.floatingElement};

    &::before {
      top: -1rem;
      right: 0.625rem;
      left: auto;
      border: 0.5rem solid transparent;
      border-bottom-color: #fff;
      position: absolute;
      display: inline-block;
      content: '';
    }

    svg {
      position: relative;
      top: 0.1875rem;
      margin-right: 0.5rem;
    }

    li {
      a {
        display: flex;
        width: 100%;
        padding: 0.5rem 0.75rem;
        font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
        font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
        font-size: 0.8125rem;
        text-decoration: none;
        color: #4c288f;
        &:hover {
          color: #fff;
          background: #4c288f;
        }
      }

      &:first-of-type a {
        margin-top: 0.5rem;
      }

      &:last-of-type a {
        margin-bottom: 0.5rem;
      }
    }
  }

  .avatar {
    filter: drop-shadow(0 0 1.5px white);
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    color: #243b5d;
    line-height: 1.25rem;

    .acc-name {
      font-size: 0.875rem;
      font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
      font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
    }

    .role-name {
      font-size: 0.625rem;
    }
  }

  &:hover {
    cursor: pointer;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;
