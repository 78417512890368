import { OrganizationBillingDataModel } from '@api/organizations/OrganizationBillingDataModel';
import { OrganizationModel } from '@api/organizations/OrganizationModel';
import type { UseFetchOptions } from '@hooks/useFetch';
import useFetch from '@hooks/useFetch';
import type { UseMutationOptions } from '@hooks/useMutation';
import useMutation from '@hooks/useMutation';
import { PaginatedResponse } from '@models/PaginatedResponse';

import paginatedTransform from '../paginatedTransform';
import rawTransform from '../rawTransform';

import * as cacheKeys from './cacheKeys';
import { OrganizationIndustryModel } from './OrganizationIndustryModel';

export const usePostOrganization = (options?: UseMutationOptions<OrganizationModel>) => {
  return useMutation<OrganizationModel>({
    ...options,
    method: 'POST',
    url: '/organizations/',
  });
};

export const useFetchOrganizationBillingData = (
  options?: UseFetchOptions<OrganizationBillingDataModel>,
) => {
  return useFetch<OrganizationBillingDataModel>({
    ...options,
    queryKey: [...cacheKeys.billing, options?.params],
    select: rawTransform(OrganizationBillingDataModel),
    url: `/organizations/billing-data/`,
  });
};

const defaultOrganizationSelect = rawTransform(OrganizationModel);
export const useFetchDefaultOrganization = (options?: UseFetchOptions<OrganizationModel>) => {
  return useFetch<OrganizationModel>({
    ...options,
    queryKey: [...cacheKeys.defaultOrg, options?.params],
    select: defaultOrganizationSelect,
    url: `/organizations/default/`,
  });
};

const defaultOrganizationIndustrySelect = paginatedTransform(OrganizationIndustryModel);
export const useFetchOrganizationIndustry = (
  options?: UseFetchOptions<PaginatedResponse<OrganizationIndustryModel>>,
) => {
  return useFetch<PaginatedResponse<OrganizationIndustryModel>>({
    ...options,
    queryKey: [...cacheKeys.organizationIndustry, options?.params],
    select: defaultOrganizationIndustrySelect,
    url: `/organizations/industries/`,
  });
};
