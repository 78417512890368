import React, { useCallback, useContext, useMemo } from 'react';
import clsx from 'clsx';

import ExploreTreeContext from './context';
import { StyledExploreTableFrame } from './ExploreTableFrame.styles';
import { calculateOpenTableHeight, showAllRawHeight } from './helpers';

export interface ExploreTableFrameProps {
  children: React.ReactNode;
  columnsIds: string[];
  guid: string;
  isColumnPinned?: boolean;
  isTableActive: boolean;
  isTableGroup: boolean;
  isTableImplicit?: boolean;
  isTableOpen: boolean;
  isTablePinned: boolean;
  isTableTargeted: boolean;
  offset: number;
  setHoveringTable: (id: string | null) => void;
  showAllColumns: boolean;
  toggleShowAll: (id: string) => void;
  x: number;
  y: number;
}

const ExploreTableFrame: React.FC<ExploreTableFrameProps> = ({
  children,
  columnsIds,
  guid,
  isColumnPinned,
  isTableActive,
  isTableGroup,
  isTableImplicit,
  isTableOpen,
  isTablePinned,
  isTableTargeted,
  offset = 0,
  setHoveringTable,
  showAllColumns,
  toggleShowAll,
  x,
  y,
}) => {
  const { pinnableColumnsById, rectHeight, rectWidth, xScale, yScale } =
    useContext(ExploreTreeContext);
  const className = clsx(
    isTablePinned && 'pinned',
    isColumnPinned && 'pinned-column',
    isTableTargeted && 'hoveredTableTarget',
    isTableActive && 'active',
    isTableOpen && 'open',
  );

  const openTableHeight = useMemo(() => {
    return calculateOpenTableHeight({
      columns: columnsIds,
      pinnableColumnsById,
      rectHeight,
      showAllColumns,
    });
  }, [rectHeight, columnsIds, pinnableColumnsById, showAllColumns]);

  const inverseTableHeight = useMemo(() => {
    return calculateOpenTableHeight({
      columns: columnsIds,
      pinnableColumnsById,
      rectHeight,
      showAllColumns: !showAllColumns,
    });
  }, [rectHeight, columnsIds, pinnableColumnsById, showAllColumns]);

  const tableHeight = isTableOpen ? openTableHeight + offset : rectHeight;
  const showAllHeight = showAllRawHeight(rectHeight);
  const isShowAllVisible = openTableHeight !== inverseTableHeight;

  const handleOnMouseEnter = useCallback(() => {
    setHoveringTable(guid);
  }, [setHoveringTable, guid]);

  const handleOnMouseLeave = useCallback(() => {
    setHoveringTable(null);
  }, [setHoveringTable]);

  const width = xScale(rectWidth);
  const height = yScale(tableHeight);
  const headerHeight = 32;

  const MockOffset = 6;
  const showGroupUI = isTableGroup;
  return (
    <StyledExploreTableFrame
      key={guid}
      className={className}
      id={guid}
      isTableImplicit={isTableImplicit}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      transform={`translate(${x}, ${y})`}
    >
      {showGroupUI && (
        <>
          <rect
            className="explore-heading-mock"
            height={headerHeight}
            width={width}
            x={MockOffset}
            y={-MockOffset}
          />
          <rect
            className="column-row-mock"
            height={height - headerHeight}
            width={width}
            x={MockOffset}
            y={headerHeight - MockOffset}
          />
          <path
            className="table-wrapper-mock"
            d={`
              m${MockOffset},${headerHeight}
              L${MockOffset},${-MockOffset}
              L${width + MockOffset}, ${-MockOffset}
              L${width + MockOffset}, ${height - MockOffset}
              L${width}, ${height - MockOffset}
              `}
          />
          <path className="table-wrapper" d={`m${width},26  L${width + MockOffset}, 26`} />
        </>
      )}
      <g height={height} width={width}>
        {children}
      </g>
      <rect className="table-wrapper" height={height} width={width} />
      {isShowAllVisible && (
        <g
          className="columnsToggle"
          onClick={() => toggleShowAll(guid)}
          transform={`translate(${xScale(0)}, ${yScale(openTableHeight - showAllHeight + offset)})`}
          x="0"
          y="0"
        >
          <rect height={yScale(showAllHeight)} width={width} />
          <text
            dominantBaseline="middle"
            height={yScale(showAllHeight)}
            textAnchor="middle"
            width={width}
            x={xScale(rectWidth / 2)}
            y={yScale(showAllHeight / 2)}
          >
            {showAllColumns ? 'Show less columns' : 'Show all columns'}
          </text>
        </g>
      )}
    </StyledExploreTableFrame>
  );
};

export default React.memo<ExploreTableFrameProps>(ExploreTableFrame);
