import React from 'react';

import Box from '@components/Box';
import Link from '@components/Link';
import Text from '@components/Text';
import theme from '@styles/theme';

import GithubDocumentationAlert from './GithubDocumentationAlert';

const GITHUB_DOCS_SYNC_APP_URL = 'https://github.com/apps/select-star-dbt-docs-sync';

const GithubInfoStep = () => {
  return (
    <Box>
      <GithubDocumentationAlert />
      <Box compDisplay="flex" flexDirection="column" ml={1.25} mt={3}>
        <Text color="gray.900" fontSize={theme.typography.fontSizes.body2}>
          Step 1. Install the GitHub App:&nbsp;
          <Link
            color={theme.colors.hyperlink}
            fontSize="inherit"
            href={GITHUB_DOCS_SYNC_APP_URL}
            rel="noopener noreferrer"
            target="_blank"
            underline
          >
            Select Star docs Sync
          </Link>
        </Text>
        <Text color="gray.900" fontSize={theme.typography.fontSizes.body2}>
          Step 2. Grant access to the dbt repository
        </Text>
      </Box>
    </Box>
  );
};

export default GithubInfoStep;
