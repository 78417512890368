import React from 'react';

import Box from '@components/Box';
import { representedAsFilters } from '@components/Modal/CreateMetricModal/CreateMetricModal';

import { StyledRichtextDescriptionEditor } from './RepresentedAsCell.styles';

interface RepresentedAsCellProps {
  guid: string;
  representedAs?: string;
  richtextRepresentedAs?: string;
}

const RepresentedAsCell: React.FC<RepresentedAsCellProps> = ({
  guid,
  representedAs,
  richtextRepresentedAs,
}) => {
  return (
    <Box compDisplay="flex">
      <StyledRichtextDescriptionEditor
        key={`${guid}.representedAs`}
        customMentionFilters={representedAsFilters}
        descriptions={{ description: representedAs, richTextDescription: richtextRepresentedAs }}
        guid={guid}
        isEditable={false}
        showToolbar={false}
      />
    </Box>
  );
};

export default RepresentedAsCell;
