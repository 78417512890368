import styled from '@emotion/styled';

const ProfileRecentActivityStyled = styled.div`
  width: 100%;
  border-radius: 6px;
  background-color: #f6f9fc;
  padding: 0.625rem 1rem;
  margin-top: 0.375rem;
  line-height: 1.25rem;
  .column-1 {
    width: 100%;
    align-items: center;
    .text {
      color: #2c2e36;
      font-size: 0.875rem;
    }
    .text--action {
      display: inline;
      font-size: 0.875rem;
    }
    .target--control {
      cursor: pointer;
      display: inline;
      margin: 0 0.125rem 0 0;
      .target-name {
        font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
        font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
        word-break: break-all;
        overflow-wrap: anywhere;
      }
    }
  }
  .column-2 {
    width: 100%;
    .text--time {
      margin-top: 2px;
      font-size: 0.8125rem;
      color: #6f747c;
    }
  }
`;

export default ProfileRecentActivityStyled;
