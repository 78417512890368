import { Expose, Type } from 'class-transformer';

import { ColumnData, ColumnModel } from '@api/columns/ColumnModel';
import { TableData, TableModel } from '@api/tables/TableModel';

export interface ForeignKeyData {
  guid: string;
  source: TableData;
  sourceColumns: ColumnData[];
  target: TableData;
  targetColumns: ColumnData[];
}

export class ForeignKeyModel {
  static objectType: string = 'foreignKey';

  objectType: string = ForeignKeyModel.objectType;

  static typeDisplay: string = 'ForeignKey';

  typeDisplay: string = ForeignKeyModel.typeDisplay;

  guid!: string;

  @Type(() => TableModel)
  source!: TableModel;

  @Type(() => TableModel)
  target!: TableModel;

  @Expose({ name: 'source_columns' })
  @Type(() => ColumnModel)
  sourceColumns!: ColumnModel[];

  @Expose({ name: 'target_columns' })
  @Type(() => ColumnModel)
  targetColumns!: ColumnModel[];
}
