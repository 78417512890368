import type { FilterIndex } from '@api/search/types';
import { tabConfigDefault } from '@components/SearchBar/DatasourceTabs/config';

export interface SearchFacetType {
  label: string;
  name: FilterIndex[];
}

export type SearchFacetsResultsType = Record<FilterIndex, number>;
export type SearchFiltersType = Partial<Record<FilterIndex, boolean>>;

const extractSubtabs = () => {
  const result: SearchFacetType[] = [];

  tabConfigDefault.forEach((item) => {
    const subtabs = item.subtabs ?? [];
    subtabs.forEach((subtab) => {
      result.push({
        label: subtab.name,
        name: subtab.indexes,
      });
    });
  });

  return result;
};

export const SearchFacets = extractSubtabs();

// This function surfaces the SearchFacets object for outside use
export function findSearchFacet(facetName: FilterIndex): SearchFacetType | undefined {
  return SearchFacets.find((f) => {
    return f.name.find((name) => name === facetName);
  });
}

// This function is helpful when sorting facet search results by facet order
export function getSearchFacetOrder(facetName: FilterIndex) {
  return SearchFacets.findIndex((f) => f.name.indexOf(facetName) > -1);
}

export const getInitSearchFacets: () => SearchFacetsResultsType = () => {
  const initFacets: Partial<SearchFacetsResultsType> = {};
  SearchFacets.forEach((item) => item.name.forEach((name) => (initFacets[name] = 0)));
  return initFacets as SearchFacetsResultsType;
};

export const getInitSearchFilters: () => SearchFiltersType = () => {
  const initFacets: SearchFiltersType = {};
  SearchFacets.forEach((item) => item.name.forEach((name) => (initFacets[name] = true)));

  return initFacets;
};
