import { Editor, Path, Range } from 'slate';

import { toggleBlock } from '../helpers/blockHelpers';
import { CustomElement } from '../RichTextEditor.types';

/**
 * Plugin that fixes bug where lists at the start of editor can't be deleted.
 * Adapted from https://github.com/ianstormtaylor/slate/issues/3048 and
 * https://github.com/ianstormtaylor/slate/issues/3469
 */
const withDeleteEmptyBlockPlugin = (editor: Editor) => {
  const { deleteBackward } = editor;
  editor.deleteBackward = (unit) => {
    const { selection } = editor;
    const start = Editor.start(editor, []);
    if (
      selection &&
      Range.isCollapsed(selection) &&
      selection.anchor.offset === 0 &&
      Path.equals(selection.anchor.path, start.path)
    ) {
      const node = editor.children[selection.anchor.path[0]] as CustomElement | undefined;
      const type = node?.type;

      // Blocktypes that should be toggled/removed when editor is empty and user presses backspace.
      const shouldBeToggled =
        type &&
        ['codeblock', 'blockquote', 'ol', 'ul', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(type);

      if (shouldBeToggled) {
        toggleBlock(editor, type);
      }
    } else {
      deleteBackward(unit);
    }
  };

  return editor;
};

export default withDeleteEmptyBlockPlugin;
