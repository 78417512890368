import mapLineageExploreModel from '@api/lineage/LineageExploreModel.v1';
import {
  EnrichedLineageNodesData,
  EnrichedNewLineageData,
  mapNewLineage,
  mapNewLineageNodes,
  NewLineageModel,
  PaginatedLineageNodesResponse,
} from '@api/lineage/mapNewLineage';
import {
  GetLineageCsvExportQueryParams,
  GetLineageNewRetrieveQueryParams,
  GetLineageNodesListQueryParams,
  GetLineageRetrieveQueryParams,
  GetLineageSourceTablesListPathParams,
} from '@api/openapi.generated';
import useFetch, { UseFetchOptions } from '@hooks/useFetch';

import rawTransform from '../rawTransform';

import * as cacheKeys from './cacheKeys';
import { LineageExploreModel } from './LineageExploreModel';
import SourceColumnsModel from './SourceColumnsModel';
import SourceTablesModel from './SourceTablesModel';
import { LineageType } from './types';

export const useFetchLineage = (
  id: string,
  options?: UseFetchOptions<LineageType, GetLineageRetrieveQueryParams>,
) => {
  return useFetch<LineageType, GetLineageRetrieveQueryParams>({
    ...options,
    queryKey: [...cacheKeys.lineage(id), options?.params],
    select: mapLineageExploreModel,
    url: `/lineage/${id}/`,
  });
};

export const useFetchLineageCsv = (
  id: string,
  options?: UseFetchOptions<any, GetLineageCsvExportQueryParams>,
) => {
  return useFetch({
    ...options,
    queryKey: [...cacheKeys.lineageCsv(id), options?.params],
    url: `/lineage/${id}/csv/`,
  });
};

export const useFetchLineageNodes = (
  options?: UseFetchOptions<
    EnrichedLineageNodesData,
    GetLineageNodesListQueryParams,
    PaginatedLineageNodesResponse
  >,
) => {
  return useFetch<
    EnrichedLineageNodesData,
    GetLineageNodesListQueryParams,
    PaginatedLineageNodesResponse
  >({
    ...options,
    queryKey: [...cacheKeys.lineageNodes, options?.params],
    select: mapNewLineageNodes,
    url: `/lineage/nodes/`,
  });
};

export const useFetchLineageNew = (
  id: string,
  options?: UseFetchOptions<
    EnrichedNewLineageData,
    GetLineageNewRetrieveQueryParams,
    NewLineageModel
  >,
) => {
  return useFetch<EnrichedNewLineageData, GetLineageNewRetrieveQueryParams, NewLineageModel>({
    ...options,
    queryKey: [...cacheKeys.lineageNew(id), options?.params],
    select: mapNewLineage,
    url: `/lineage/${id}/new/`,
  });
};

export interface FetchErdParams {
  column_level_traversal?: GetLineageRetrieveQueryParams['relevant_lineage'];
  columns?: string;
  component_identifier_override?: string;
  direction?: GetLineageRetrieveQueryParams['direction'];
  include_borderline_edges?: GetLineageRetrieveQueryParams['include_borderline_edges'];
  mode: GetLineageRetrieveQueryParams['mode'];
  tables?: string;
}

const fetchErdSelect = rawTransform(LineageExploreModel);
export const useFetchErd = (options?: UseFetchOptions<LineageType, FetchErdParams>) => {
  return useFetch<LineageType, FetchErdParams>({
    ...options,
    queryKey: [...cacheKeys.erd, options?.params],
    select: fetchErdSelect,
    url: `/erd/`,
  });
};

const fetchSourceTablesSelect = rawTransform(SourceTablesModel);
export const useFetchSourceTables = (
  id: string,
  options?: UseFetchOptions<SourceTablesModel, GetLineageSourceTablesListPathParams>,
) => {
  return useFetch<SourceTablesModel, GetLineageSourceTablesListPathParams>({
    ...options,
    queryKey: [...cacheKeys.sourceTables(id), options?.params],
    select: fetchSourceTablesSelect,
    url: `/lineage/${id}/source-tables/`,
  });
};

const fetchSourceColumnsSelect = rawTransform(SourceColumnsModel);
export const useFetchSourceColumns = (
  id: string,
  options?: UseFetchOptions<SourceColumnsModel>,
) => {
  return useFetch<SourceColumnsModel>({
    ...options,
    queryKey: [...cacheKeys.sourceColumns(id), options?.params],
    select: fetchSourceColumnsSelect,
    url: `/lineage/${id}/source-columns/`,
  });
};
