import React, { useEffect, useState } from 'react';
import { useHistory } from '@routing/router';

import Box from '@components/Box';
import SignUp from '@components/Forms/Signup';
import GoogleSignUp from '@components/GoogleSignUp';
import { Column, GridContainer, Row } from '@components/Grid';
import ToastContainer from '@components/ToastContainer';
import TrialInfo from '@components/TwoColumnDecorativeComponent/TrialInfo/TrialInfo';
import {
  customBrandConfig,
  CustomBrandOrigin,
} from '@components/TwoColumnDecorativeComponent/TwoColumnDecorativeComponent';
import { StyledBox } from '@components/TwoColumnDecorativeComponent/TwoColumnDecorativeComponent.styles';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackPageName } from '@context/Segment/Segment.types';
import MetadataDecorator from '@utils/MetadataDecorator';

interface SignUpProps {
  errorMessage?: string;
}

const SignUpPage: React.FC<SignUpProps> = ({ errorMessage }) => {
  const history = useHistory();
  const [showSignUpWithEmail, setShowSignUpWithEmail] = useState<boolean>(false);
  const segment = useSegmentContext();

  const showTrialInfo = !Object.keys(customBrandConfig).includes(window.location.origin);
  const brandConfig = showTrialInfo
    ? undefined
    : customBrandConfig[window.location.origin as CustomBrandOrigin];

  const onSuccess = (currEmail: string) => {
    history.push({
      pathname: '/signup-success',
      state: {
        email: currEmail,
      },
    });
  };

  useEffect(() => {
    segment?.page(SegmentTrackPageName.SignUp);
  }, []);

  const defaultSignUp = showSignUpWithEmail ? (
    <SignUp onSuccess={onSuccess} />
  ) : (
    <GoogleSignUp
      errorMessage={errorMessage}
      signUpWithEmail={() => setShowSignUpWithEmail(true)}
    />
  );

  return (
    <>
      <MetadataDecorator title="Sign Up" />
      <GridContainer fluid hPaddingSpace={0}>
        <ToastContainer fixed />
        <Row flexWrap="wrap" space={0}>
          <Column compWidth={showSignUpWithEmail ? '100%' : undefined} md={5} sm={12}>
            {defaultSignUp}
          </Column>
          <Column hideDown={showSignUpWithEmail ? ['sm'] : []} md={7} sm={12}>
            <StyledBox alignItems="center" compDisplay="flex" justifyContent="center">
              {showTrialInfo ? (
                <TrialInfo />
              ) : (
                <Box m="auto" maxWidth="440px" p={4}>
                  <img
                    alt={brandConfig?.imgAltText}
                    height="auto"
                    src={brandConfig?.img}
                    width="240px"
                  />
                </Box>
              )}
            </StyledBox>
          </Column>
        </Row>
      </GridContainer>
    </>
  );
};

export default SignUpPage;
