import moment from 'moment';

export function alphabetically(ascending: boolean) {
  return (a?: string | number, b?: string | number) => {
    // equal items sort equally
    if (a === b) {
      return 0;
    }
    // nulls sort after anything else
    if (a === null || a === undefined) {
      return 1;
    }
    if (b === null || b === undefined) {
      return -1;
    }
    // otherwise, if we're ascending, lowest sorts first
    if (ascending) {
      return a < b ? -1 : 1;
    }
    // if descending, highest sorts first
    return a < b ? 1 : -1;
  };
}

export function numerically(ascending: boolean) {
  return (a?: number, b?: number) => {
    // equal items sort equally
    if (a === b) {
      return 0;
    }
    if (ascending) {
      // nulls sort first. Nulls === 0
      if (a === null || a === undefined) {
        return -1;
      }
      if (b === null || b === undefined) {
        return 1;
      }
    } else {
      // nulls sort after anything else
      if (a === null || a === undefined) {
        return 1;
      }
      if (b === null || b === undefined) {
        return -1;
      }
    }
    return ascending ? a - b : b - a;
  };
}

export function sortMomentDates(ascending: boolean) {
  return (a?: moment.Moment, b?: moment.Moment) => {
    // equal items sort equally
    if (a === b) {
      return 0;
    }
    // nulls sort after anything else
    if (a === null || a === undefined) {
      return 1;
    }
    if (b === null || b === undefined) {
      return -1;
    }
    return ascending ? a.valueOf() - b.valueOf() : b.valueOf() - a.valueOf();
  };
}
