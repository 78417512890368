import React from 'react';
import { RouterLink } from '@routing/router';

import AppHeader from '@components/AppHeader';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import Link from '@components/Link';
import Text from '@components/Text';
import Title from '@components/Title/Title';
import useUserContext from '@context/User/User';
import MetadataDecorator from '@utils/MetadataDecorator';

import { StyledContent, Wrapper } from './FreeTrialExpired.styles';

const FreeTrialExpired: React.FC = () => {
  const { organization } = useUserContext();

  return (
    <Wrapper>
      <MetadataDecorator title="Your free trial has expired" />
      <AppHeader disableAccess />
      <StyledContent>
        <Box compDisplay="flex" flexDirection="column" maxWidth="30vw">
          <Title>Trial has expired </Title>
          <Text fontSize="16px" fontWeight="regular" mb={1} mt={1}>
            We hope you enjoyed trying out Select Star!
          </Text>
          <Text display="inline" fontSize="16px" fontWeight="regular" mb={2}>
            Your trial has expired on {organization?.trialEndsAt?.local(true).format('LL')}. Please
            upgrade your account in{' '}
            <Link as={(props) => <RouterLink {...props} to="/admin/billing" />} underline>
              Billing
            </Link>
            , or{' '}
            <Link
              href="https://calendly.com/ricky-ebm/30min"
              rel="noopener noreferrer"
              target="_blank"
              underline
            >
              schedule time
            </Link>{' '}
            to talk to us.
          </Text>
          <Text fontSize="16px" fontWeight="regular" m={0}>
            Thanks,
          </Text>
          <Text fontSize="16px" fontWeight="regular" mb={1}>
            Select Star Team
          </Text>
          <Box compDisplay="flex" justifyContent="flex-end">
            <Button
              as="a"
              href="https://calendly.com/ricky-ebm/30min"
              rel="noopener noreferrer"
              target="_blank"
            >
              Contact Sales
            </Button>
          </Box>
        </Box>
      </StyledContent>
    </Wrapper>
  );
};

export default FreeTrialExpired;
