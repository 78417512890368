import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

import rootTheme from '@styles/theme';

export type VariantColor = 'default' | 'modalForm';

export const colorVariants: Record<VariantColor, SerializedStyles> = {
  default: css``,
  modalForm: css`
    color: ${rootTheme.colors.v1.gray[700]};
    font-weight: ${rootTheme.typography.fontWeights.medium};
  `,
};
