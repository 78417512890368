import React, { useCallback, useState } from 'react';
import { Cell } from 'react-table';

import DateTime from '@components/DateTime';
import type { ColumnConfig } from '@components/Table/Table/types';
import { FrequentDSUserModel } from '@models/FrequentDSUserModel';
import { Filter } from '@utils';

import SearchHeader from '../Cells/SearchHeader';
import UserCell from '../Cells/UserCell';
import Table from '../Table';
import TableStyled from '../TableStyled';

const initialSortState = [
  {
    desc: true,
    id: 'queryCount',
  },
];
interface TopUsersTableProps {
  data?: FrequentDSUserModel[];
  dataType?: string;
  filterService: Filter.FilterServiceInterface;
  isLoading?: boolean;
  isTopUsersModal?: boolean;
  itemCount?: number;
  stickyHeader?: boolean;
  totalPages: number;
}

const TopUsersTable: React.FC<TopUsersTableProps> = ({
  data,
  dataType,
  filterService,
  isLoading,
  isTopUsersModal,
  itemCount = 0,
  stickyHeader,
  totalPages,
}) => {
  const [isShowFilter, setShowFilter] = useState(false);
  const { changePage, filter, search, sort } = filterService;

  const columns = React.useMemo(() => {
    const cols: ColumnConfig<FrequentDSUserModel>[] = [
      {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      {
        Cell: (props: Cell<FrequentDSUserModel>) => {
          const { row } = props;
          const dsUser = row.original;
          const validUser = dsUser?.user?.isActive && dsUser?.user?.fullName ? dsUser.user : dsUser;
          return <UserCell {...props} user={validUser} />;
        },
        Header: `User (${itemCount})`,
        accessor: (d) => d.fullName,
        disableHiding: true,
        id: 'name',
        width: '135%',
      },
      {
        Cell: (props: Cell<FrequentDSUserModel>) => {
          const { row } = props;
          const { user } = row.original;
          return <UserCell {...props} user={user?.team} />;
        },
        Header: 'Team',
        accessor: (d) => d?.user?.team?.name,
        disableFilters: true,
        disableSortBy: true,
        id: 'team',
        width: '135%',
      },
      {
        Cell: (props: Cell<FrequentDSUserModel>) => {
          const { row } = props;
          const dsUser = row.original;
          return dsUser?.queryCount;
        },
        Header: dataType === 'workbook' ? 'Views' : 'Runs',
        accessor: (d) => d?.queryCount,
        disableFilters: true,
        id: 'queryCount',
        sortDescFirst: true,
        width: '115%',
      },
      {
        Cell: (props: Cell<FrequentDSUserModel>) => {
          const { row } = props;
          const dsUser = row.original;
          return <DateTime datetime={dsUser.lastQueried} />;
        },
        Header: dataType === 'workbook' ? 'Last Viewed' : 'Last Run',
        accessor: (d) => (d?.lastQueried?.isValid() ? d.lastQueried.toDate() : 'unknown'),
        disableFilters: true,
        id: 'lastRunDate',
        sortDescFirst: true,
        width: '115%',
      },
    ];

    return cols;
  }, [dataType, itemCount]);

  const getRowId = useCallback((row: Partial<FrequentDSUserModel>) => row.guid!, []);

  return (
    <TableStyled>
      <Table
        basic="very"
        changePage={changePage}
        className="table-full"
        columns={columns}
        compact
        data={data || []}
        disableColumnFiltering
        disableRowSelect
        getRowId={getRowId}
        initialState={{
          hiddenColumns: isTopUsersModal ? ['search', 'lastRunDate'] : [],
          pageIndex: filter.page ? filter.page - 1 : 0,
          sortBy: initialSortState,
        }}
        loading={isLoading}
        manualFilters
        manualPagination
        manualSortBy
        selectable
        setFilters={search}
        setSortBy={sort}
        showFilter={isShowFilter}
        singleLine
        sortable
        stickyHeader={stickyHeader}
        toggleFilter={() => setShowFilter((prev) => !prev)}
        totalPages={totalPages}
        unstackable
      />
    </TableStyled>
  );
};

export default TopUsersTable;
