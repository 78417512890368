import styled from '@emotion/styled';

interface Props {
  hidePlaceholderOnFocus?: boolean;
  /**
   * Label width in 'rem' units.
   */
  labelWidth?: number;
}

const FormStyled = styled.div<Props>`
  height: 100%;

  .ui.form {
    height: 100%;
    font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
    display: flex;
    flex-direction: column;

    .fields {
      margin-bottom: ${({ theme }) => theme.space(1.25)};
    }

    .field {
      width: 100%;
      > label {
        display: inline-block;
        width: ${(props) => props.labelWidth || 4}rem;
        vertical-align: middle;
      }
      .prompt {
        margin-left: ${(props) => props.labelWidth || 4}rem;
      }
      .ui.input,
      .ui.selection.dropdown,
      textarea {
        width: calc(100% - ${(props) => props.labelWidth || 4}rem);
      }
      .ui.selection.visible.dropdown > .text:not(.default) {
        font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
        color: #0c3476;
      }

      .ui.input input {
        width: 100%;
        font-size: 0.9375rem;
        padding: 0.5rem 1rem;
        font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
        &:focus::placeholder {
          ${({ hidePlaceholderOnFocus }) => (hidePlaceholderOnFocus ? 'color: transparent' : '')};
        }
      }
      .ui.default.dropdown:not(.button) > .text {
        color: #0c3476;
      }
      .ui.dropdown,
      .ui.dropdown .menu > .item {
        background-color: #f2f5f9;
        border: none;
        color: #0c3476;
        font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
        font-size: 0.8125rem;
      }
      .ui.dropdown {
        &.active,
        .menu {
          border-radius: 0.28571429rem !important;
        }
        .menu {
          margin-top: 0.25rem;
          border: none;
          > .item {
            padding: 0.75rem 1.25rem !important;
            &:hover {
              color: #ffffff;
              background-color: #375fa0;
            }
          }
        }
      }
    }
    .error {
      .ui.dropdown {
        background: #fff6f6 !important;
        .text,
        .default.text {
          color: #9f3a38;
        }
        .menu {
          .item {
            background: #f2f5f9 !important;
            &.active,
            &.selected {
              background: #f2f5f9 !important;
            }
            &:hover {
              background-color: #375fa0 !important;
              .text:not(.default) {
                color: #fff;
              }
            }
            .text {
              background: unset;
              color: #0c3476;
            }
          }
        }
      }
    }
  }

  .ui.form .disabled.field,
  .ui.form .disabled.fields .field,
  .ui.form .field :disabled,
  .ui.form .field.disabled > label,
  .ui.form .fields.disabled > label,
  .ui.disabled.dropdown,
  .ui.dropdown .menu > .disabled.item {
    opacity: 1;
  }
`;

export default FormStyled;
