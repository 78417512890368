import React, { cloneElement } from 'react';
import { useHistory, useRouteMatch } from '@routing/router';

import { useNotificationContext } from '@components/Modal/NotifyUsersModal/Notification.context';
import Text from '@components/Text';
import Icon from '@components/UI/Icon';
import { useUserContext } from '@context/User';
import DataTypesModel from '@models/DataTypesModel';

import config from './config';
import {
  StyledMetadataObjectButtonsToolbarButton,
  StyledMetadataObjectButtonsToolbarGroup,
} from './MetadataObjectButtonsToolbar.styles';
import { ButtonGroupItem, ButtonName } from './MetadataObjectButtonsToolbar.types';

export interface MetadataObjectButtonsToolbarProps {
  buttons: ButtonName[];
  dataTypes?: DataTypesModel;
  enableNotifications?: boolean;
  externalUrl?: string;
  guid: string;
  showLastQuery?: boolean;
  sql?: string;
  sqlEditable?: boolean;
}

const MetadataObjectButtonsToolbar: React.FC<MetadataObjectButtonsToolbarProps> = ({
  buttons: buttonNames,
  dataTypes,
  enableNotifications,
  externalUrl = '',
  guid,
  showLastQuery = false,
  sql = '',
  sqlEditable = false,
}) => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { organization } = useUserContext();
  const { applyDraftComment } = useNotificationContext();
  const useDownstreamNotifications = organization?.settings?.useDownstreamNotifications;

  const buttonsConfig = config({
    dataTypes,
    enableNotifications: enableNotifications && useDownstreamNotifications,
    externalUrl,
    guid,
    navigate: history.push,
    openNotificationModal: applyDraftComment,
    path: history.location.pathname,
    search: history.location.search,
    showLastQuery,
    showShareButton: !useDownstreamNotifications,
    sql,
    sqlEditable,
    url,
  });

  const buttons = buttonNames
    .map((name) => buttonsConfig[name])
    .filter(Boolean) as ButtonGroupItem[];

  return (
    <StyledMetadataObjectButtonsToolbarGroup hasExternalButton={Boolean(externalUrl)}>
      {buttons.map(({ icon, iconColor, label, onClick, wrapper = <></>, ...rest }, index) =>
        cloneElement(wrapper, {
          children: (
            <StyledMetadataObjectButtonsToolbarButton
              isFirst={index === 0}
              isLast={index === buttons.length - 1}
              onClick={onClick}
              {...rest}
            >
              <Icon color={iconColor || 'gray.700'} name={icon} size="20px" />
              <Text
                as="label"
                color="gray.700"
                fontSize="12px"
                fontWeight="semibold"
                whiteSpace="nowrap"
              >
                {label}
              </Text>
            </StyledMetadataObjectButtonsToolbarButton>
          ),
          key: label,
        }),
      )}
    </StyledMetadataObjectButtonsToolbarGroup>
  );
};

export default MetadataObjectButtonsToolbar;
