import { FullStory, init as initFullStory } from '@fullstory/browser';
import { fullStoryIntegration } from '@sentry/fullstory';
import * as Sentry from '@sentry/react';
import getDebug from 'debug';

import flags from '@features';

const debug = getDebug('selectstar:root');

const SENTRY_ORG_ID = 'select-star';

/**
 * Fullstory integration
 * @link https://www.npmjs.com/package/@fullstory/browser
 */
initFullStory({
  debug: false,
  host: 'fullstory.com',
  orgId: 'XBH4D',
});

const initSentry = () => {
  /**
   * Set up sentry for all env except local and testing
   * Browser Tracing is enabled for performance bench marks
   * @link https://docs.sentry.io/platforms/javascript/guides/react/
   */
  if (!['local', 'testing'].includes(window.env.ENVIRONMENT)) {
    const ignoreErrors: Array<string> = ['Query was cancelled by React Query', 'CanceledError'];
    const environment = window.env.ENVIRONMENT;

    debug(`Initializing Sentry with environment: ${environment}`);
    Sentry.init({
      beforeSend: (event, hint) => {
        if (
          ignoreErrors.find((item) => (hint?.originalException as any)?.message?.includes(item))
        ) {
          return null;
        }

        return event;
      },
      dsn: 'https://87fa6e276ea044e09550c371b1697889@o407979.ingest.sentry.io/5495204',
      environment,
      ignoreErrors,
      integrations: [
        Sentry.browserTracingIntegration({}),
        fullStoryIntegration(SENTRY_ORG_ID, { client: FullStory }),
        Sentry.replayIntegration({
          blockAllMedia: false,
          maskAllText: false,
          useCompression: true,
        }),
      ],
      release: process.env?.REACT_APP_VERSION ?? 'UNKNOWN_RELEASE',
      replaysOnErrorSampleRate: 1.0,
      replaysSessionSampleRate: 0,
      tracePropagationTargets: ['localhost', /^\//, /api\.\w+\.selectstar\.com/],
      tracesSampleRate: 0.3,
    });
  }
};

const initObservabilityTools = () => {
  if (flags.enable_fullstory_tracking) {
    /**
     * We need to load Sentry only after FullStory to avoid
     * problems with rendering sessions according to their support
     * @link https://app.shortcut.com/select-star/story/69465/fullstory-rendering-issue#activity-72672
     */
    FullStory('observe', {
      callback: () => {
        initSentry();
      },
      type: 'start',
    });
  } else {
    initSentry();
  }
};

export default initObservabilityTools;
