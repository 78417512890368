import { useContext, useEffect } from 'react';

import { NextActionFunction, WizardButtonState, WizardContextProps } from './types';
import WizardContext from './WizardContext';

export interface UseWizardProps {
  backButtonState?: WizardButtonState;
  loading?: boolean;
  nextButtonAction?: NextActionFunction;
  nextButtonState?: WizardButtonState;
}

export const useWizard = (props?: UseWizardProps) => {
  const { backButtonState, loading, nextButtonAction, nextButtonState } = props || {};

  const userContext = useContext(WizardContext) as WizardContextProps;

  if (userContext === undefined) {
    throw new Error(`useWizard must be used within <WizardContext>.`);
  }

  useEffect(() => {
    userContext.setNextButtonAction(nextButtonAction);
  }, [nextButtonAction, userContext]);

  useEffect(() => {
    if (nextButtonState) {
      userContext.setNextButtonState(nextButtonState);
    }
  }, [nextButtonState, userContext]);

  useEffect(() => {
    if (backButtonState) {
      userContext.setBackButtonState(backButtonState);
    }
  }, [backButtonState, userContext]);

  useEffect(() => {
    if (loading !== undefined) {
      userContext.setLoadingState(loading);
    }
  }, [loading, userContext]);

  return userContext;
};
