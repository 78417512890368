import { Editor, Element, Transforms } from 'slate';

const LIST_TYPES = ['ol', 'ul'];

export const getSelectedListItem = (editor: Editor) => {
  const { selection } = editor;
  if (selection == null) {
    return null;
  }

  const node = Editor.above(editor, {
    at: selection,
    match: (n) => Element.isElement(n) && n.type === 'li',
  });

  return node;
};

export const increaseListLevel = (editor: Editor, event?: KeyboardEvent) => {
  const listItem = getSelectedListItem(editor);
  if (listItem) {
    event?.preventDefault();
    const [, path] = listItem;
    const parent = Editor.above(editor, { at: path });
    const parentNode = parent?.[0];
    if (Element.isElement(parentNode)) {
      const { level, type } = parentNode;
      if (level && level > 3) {
        return;
      }
      Transforms.wrapNodes(editor, { children: [{ text: '' }], level: (level ?? 0) + 1, type });
    }
  }
};

export const decreaseListLevel = (editor: Editor, event?: KeyboardEvent) => {
  const listItem = getSelectedListItem(editor);
  if (listItem) {
    event?.preventDefault();
    const [, path] = listItem;
    const parent = Editor.above(editor, { at: path });
    const parentNode = parent?.[0];
    if (parentNode && Element.isElement(parentNode) && LIST_TYPES.includes(parentNode.type)) {
      if (!parentNode.level) {
        Transforms.setNodes(editor, { type: 'paragraph' }, { at: path });
        Transforms.liftNodes(editor, { at: path });
      } else {
        Transforms.liftNodes(editor, { at: path });
      }
    }
  }
};
