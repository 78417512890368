import React from 'react';

import { TagModel } from '@api/tags/TagModel';
import AppTag from '@components/AppTag';
import Breadcrumbs, { breadcrumbsToList } from '@components/Breadcrumbs';
import Popularity from '@components/Popularity';

import {
  SearchResultDescription,
  SearchResultPath,
  SearchResultPopularity,
  SearchResultTags,
  SearchResultTitle,
  SearchResultTitleRow,
} from './SearchResult.styles';
import SearchResultIcon from './SearchResultIcon';
import { SearchTopIcon } from './SearchTopIcon';

interface HistoryItemProps {
  historyItem: any;
}

const HistoryItem: React.FC<HistoryItemProps> = ({ historyItem }) => {
  const { breadcrumbs, dataTypes, description, guid, name, objectType, popularity, tags } =
    historyItem;

  const shortenedDescription =
    description && description?.length > 218 ? `${description?.slice(0, 215)}...` : description;

  return (
    <>
      <SearchResultPath>
        <SearchTopIcon dataTypes={dataTypes} mr={0.5} />
        {/* It isn't possible to use the breadcrumbList getter because the Search component from semantic-ui-react removes the getters from the object */}
        <Breadcrumbs
          color="#8C939B"
          fontSize="14px"
          fontWeight={400}
          items={breadcrumbsToList(breadcrumbs)}
        />
      </SearchResultPath>
      <SearchResultTitleRow>
        <SearchResultIcon dataTypes={dataTypes} guid={guid} name={name} />
        <SearchResultTitle>{name}</SearchResultTitle>
        {tags && (
          <SearchResultTags>
            {tags.map((tag: TagModel) => (
              <AppTag key={tag.guid} tag={tag} variant="outlined" />
            ))}
          </SearchResultTags>
        )}
        <SearchResultPopularity>
          {popularity && (
            <Popularity
              priority={popularity?.formattedPopularity}
              text={`Popularity score: ${popularity?.popularity}`}
            />
          )}
        </SearchResultPopularity>
      </SearchResultTitleRow>
      {description && objectType !== 'comment' && (
        <SearchResultDescription>{shortenedDescription}</SearchResultDescription>
      )}
    </>
  );
};

export default HistoryItem;
