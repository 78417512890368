export const all = ['dbt'];

export const projects = [...all, 'project'];

export const project = (id: string) => [...projects, id];

export const warehouseLink = (id: string) => [...all, 'warehouse-link', id];

export const runs = [...all, 'runs'];

export const tests = [...all, 'tests'];

export const test = (id: string) => [...tests, id];
