const rawKeyValueObjTransform = <T, R>(data: { [key: string]: T }, mapper: (value: T) => R) => {
  const result: { [key: string]: R } = {};
  const keys = Object.keys(data);

  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    const value = data[key];
    result[key] = mapper(value);
  }

  return result;
};

export default rawKeyValueObjTransform;
