import { useCallback } from 'react';

import { useSegmentContext } from '@context/Segment';
import { SegmentProps } from '@context/Segment/Segment.types';
import { useUserContext } from '@context/User';

const useUserTracking = () => {
  const segment = useSegmentContext();
  const { organization, role, user } = useUserContext();

  const track: SegmentProps['track'] = useCallback(
    (eventName, eventProperties) => {
      const enrichedEventProperties = {
        ...eventProperties,
        organizationId: organization?.guid,
        organizationName: organization?.name,
        userEmail: user?.email,
        userId: user?.guid,
        userRole: role,
      };

      segment?.track(eventName, enrichedEventProperties);
    },
    [organization?.guid, organization?.name, role, segment, user?.email, user?.guid],
  );

  return { track };
};

export default useUserTracking;
