import { plainToClass } from 'class-transformer';

import DataTypesModel from '@models/DataTypesModel';
import { HierarchyResponseData } from '@models/HierarchyResponse';
import { getUrlForDataSource } from '@utils/routing';

const transformHierarchyResponse = (obj: { [key: string]: any }): HierarchyResponseData => {
  const toHierarchy = (d: any) => {
    const result = {
      ...d,
      active: d?.active,
      children: d?.children.map((c: any) => toHierarchy(c)).filter(Boolean),
      dataTypes: plainToClass(DataTypesModel, d?.data_types),
      guid: d.guid,
      name: d.name,
      objectType: d.object_type,
      open: d?.open,
      type: d?.type,
      url: getUrlForDataSource(d.type!, d.guid),
    };
    if (result.data_types) delete result.data_types;
    return result;
  };

  return {
    data: obj?.data.map((c: any) => toHierarchy(c)).filter(Boolean),
    inactive: obj?.inactive || 0,
    indented: obj?.indented,
    total: obj?.total || 0,
  };
};

export default transformHierarchyResponse;
