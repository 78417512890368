import React, { FunctionComponent, ReactNode } from 'react';
import type { TooltipProps } from 'recharts/types/component/Tooltip';

import type { BaseValue, Name, Value } from '../types';

import TooltipBox from './TooltipBox';
import TooltipBoxValue from './TooltipBoxValue';
import type { TooltipContentPayload } from './types';

export interface TooltipContentProps<T extends Value = BaseValue> extends TooltipProps<T, Name> {
  Child: FunctionComponent<TooltipContentPayload<T>>;
  name?: ReactNode;
}

const TooltipContent = <T extends Value = BaseValue>({
  Child = TooltipBoxValue,
  formatter,
  name,
  payload,
}: TooltipContentProps<T>) => {
  return (
    <TooltipBox>
      {name}
      {payload?.map((item, i) => {
        // the formatter can return the value or a [value, name] array, here we only accept the array to simplify things
        const [formattedValue, formattedName] = (formatter?.(
          item.value!,
          item.name!,
          item,
          i,
          payload,
        ) as [T, Name]) ?? [item.value, item.name];

        return <Child key={item.name} name={formattedName} value={formattedValue} />;
      })}
    </TooltipBox>
  );
};

export default TooltipContent;
