import { XYPosition } from 'reactflow';

import { SIZES } from '../../../components/Nodes/config';
import { ExploreNode } from '../../../LineageExplore.types';
import { EXPLORE_NODE_PAGE_SIZE } from '../../../useShowMoreNodes/useShowMore.constants';
import createShowMore from '../../utils/createShowMore';
import {
  calculateGroupChildX,
  showMoreTotalHeight,
  sortNodeKeysByColumnsRelevance,
  sortNodeKeysByPopularity,
  tableX,
} from '../algorithm.utils';
import { EdgesById } from '../types';

import parseNode from './parseNode';
import { NodeParser } from './types';

const schemaNodeParser: NodeParser = ({
  edgesById,
  initialPosition = { x: 0, y: 0 } as XYPosition,
  isColumnLevelLineage = false,
  nodesById,
  rawNode,
  shouldHideFilterLineage,
}) => {
  const style = {
    height: SIZES.height.schema,
    width: SIZES.width.schema,
  };
  const schemaNode: ExploreNode = {
    ...rawNode,
    data: {
      ...rawNode?.data,
      hiddenChildren: new Set([]),
      hiddenChildrenCount: 0,
      style,
    },
    position: {
      x: calculateGroupChildX(
        { startStackId: rawNode.data.stackedAt! } as ExploreNode,
        rawNode.data.stackedAt!,
        isColumnLevelLineage,
      ),
      y: initialPosition?.y as number,
    },
    style,
  };

  const schemaChildrenNodes: ExploreNode[] = [];
  let extraEdgesById: EdgesById = {};

  if (schemaNode.data.isOpen) {
    schemaNode.style!.height = SIZES.openedContentHeight.schema + SIZES.paddingBottom.schema;
    const sortingFunction =
      isColumnLevelLineage && shouldHideFilterLineage
        ? sortNodeKeysByColumnsRelevance
        : sortNodeKeysByPopularity;
    const schemaChildren = sortingFunction(
      Array.from(schemaNode.data.children ?? []),
      nodesById,
    ).map((childId) => nodesById[childId]);

    const currentInitialPosition = { x: tableX, y: SIZES.openedContentHeight.schema };
    const shownChildrenCount = schemaNode.data.shownChildrenCount ?? EXPLORE_NODE_PAGE_SIZE;

    schemaChildren.forEach((childNode, index) => {
      if (index < shownChildrenCount) {
        const {
          children = [],
          extraEdgesById: childExtraEdgesById,
          node,
          nodeHeight,
        } = parseNode({
          edgesById,
          initialPosition: currentInitialPosition,
          isColumnLevelLineage,
          nodeIndex: index,
          nodesById,
          rawNode: childNode,
          shouldHideFilterLineage,
        });

        schemaChildrenNodes.push(node, ...children);
        extraEdgesById = { ...extraEdgesById, ...childExtraEdgesById };
        currentInitialPosition.y += nodeHeight;
        schemaNode.style!.height = Number(schemaNode.style!.height) + nodeHeight;

        if (index < schemaChildren.length - 1 && index < shownChildrenCount - 1) {
          schemaNode.style!.height = Number(schemaNode.style!.height) + SIZES.rowGap.table;
          currentInitialPosition.y += SIZES.rowGap.table;
        }
      } else {
        schemaNode.data.hiddenChildrenCount = (schemaNode.data.hiddenChildrenCount ?? 0) + 1;
        schemaNode.data.hiddenChildren?.add(String(childNode.key));
      }
    });
  }

  if (schemaNode.data.hiddenChildrenCount! >= 1) {
    const showMore = createShowMore({
      data: schemaNode.data,
      edgesById,
      hiddenChildren: schemaNode.data.hiddenChildren,
      hiddenChildrenCount: schemaNode.data.hiddenChildrenCount,
      nodesById,
      parent: String(schemaNode.key),
      position: {
        x: tableX,
        y: Number(schemaNode.style!.height) + SIZES.rowGap.table - SIZES.paddingBottom.schema,
      },
      width: SIZES.width.table,
    });

    schemaNode.style!.height = Number(schemaNode.style!.height) + showMoreTotalHeight;
    schemaChildrenNodes.push(showMore.node);
    extraEdgesById = { ...extraEdgesById, ...showMore.edges };
  }

  /*
   * We need to update the height and width of the node outside the style object
   * since react-flow uses these values to calculate the nodes overlaping and
   * remove the ones not visible on screen
   */
  schemaNode.height = Number(schemaNode.style?.height);
  schemaNode.width = Number(schemaNode.style?.width);

  return {
    children: schemaChildrenNodes,
    extraEdgesById,
    node: schemaNode,
    nodeHeight: Number(schemaNode.style!.height),
  };
};

export default schemaNodeParser;
