import React from 'react';

import { DataSourcesMutatePayload } from '@api/dataSources';
import {
  StyledFormHorizontalLabelGrid,
  StyledLabel,
} from '@components/DataSourceSetup/DataSourceSetup.styles';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';

import useDataSourceMutation from '../useDataSourceMutation';

import { DataSourceFormProps } from './types';

const REQUIRED_FIELDS = ['name', 'token', 'host'] as const;

const DatabricksForm: React.FC<DataSourceFormProps> = ({
  children,
  dataSource,
  dataType,
  name = '',
  onSuccess,
  renderBefore,
}) => {
  const segment = useSegmentContext();
  const { error, isLoading, mutate } = useDataSourceMutation({ dataSource, onSuccess });

  const { handleChange, handleSubmit, values } = useForm({
    initialValues: {
      host: '',
      name: dataSource?.name || name,
      token: '',
    },
    onSubmit: (val) => {
      const payload = {} as DataSourcesMutatePayload;
      if (dataSource) {
        payload.guid = dataSource.guid;
        payload.type = dataSource.type;
      } else {
        payload.type = dataType;
      }
      payload.name = val.name;
      payload.credentials = {
        host: val.host,
        token: val.token,
      };
      mutate(payload);

      segment?.track(SegmentTrackEventName.CreateServiceAccountConnectButtonClicked, { dataType });
    },
  });

  const isInvalid = REQUIRED_FIELDS.some((key) => Boolean(values[key]) === false);

  return (
    <Form isLoading={isLoading} onSubmit={handleSubmit}>
      <StyledFormHorizontalLabelGrid>
        {renderBefore?.({ error, loading: isLoading })}
        <StyledLabel>
          Display Name
          <Input
            error={error?.data?.name}
            helperText={error?.data?.name}
            maxLength={50}
            name="name"
            onChange={handleChange}
            placeholder="Databricks"
            type="text"
            value={values.name}
          />
        </StyledLabel>
        <StyledLabel>
          Workspace URL
          <Input
            error={error?.data?.host}
            helperText={error?.data?.host}
            name="host"
            onChange={handleChange}
            placeholder="https://[deployment name].cloud.databricks.com"
            type="text"
            value={values.host}
          />
        </StyledLabel>
        <StyledLabel>
          Access Token
          <Input
            error={error?.data?.token}
            helperText={error?.data?.token}
            name="token"
            onChange={handleChange}
            placeholder="Access Token"
            type="password"
            value={values.token}
          />
        </StyledLabel>
      </StyledFormHorizontalLabelGrid>
      {children?.({ error, loading: isLoading || isInvalid })}
    </Form>
  );
};

export default DatabricksForm;
