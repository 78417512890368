import React, { useState } from 'react';
import copy from 'copy-to-clipboard';

import { useFetchDataSourceKeygen } from '@api/dataSources';
import Alert from '@components/Alert';
import Box from '@components/Box';
import { USER_DOCS_URL } from '@components/DataSourceSetup/components/DataSourceAddStep/userDocsUrlConfig';
import { StyledText } from '@components/DataSourceSetup/components/LookMLProjectSetup/LookMLProjectSetup';
import {
  StyledFormHorizontalLabelGrid,
  StyledLabel,
} from '@components/DataSourceSetup/DataSourceSetup.styles';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import IconButton from '@components/IconButton';
import Input from '@components/Input/Input.v1';
import Link from '@components/Link';
import { renderInfoToast } from '@components/Toast';
import Icon from '@components/UI/Icon';
import Select, { Option, SelectValue } from '@components/UI/Select';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import { useUserContext } from '@context/User';

import useDataSourceMutation from '../useDataSourceMutation';

import { DataSourceFormProps } from './types';

enum FieldKey {
  account = 'account',
  authentication = 'authentication',
  name = 'name',
  password = 'password',
  publicKey = 'public_key',
  role = 'role',
  user = 'user',
  warehouse = 'warehouse',
}

enum AuthOptionKey {
  keyPair = 'key-pair',
  password = 'password',
}

interface FormValues {
  [FieldKey.account]: string;
  [FieldKey.authentication]: SelectValue;
  [FieldKey.name]?: string;
  [FieldKey.password]?: '';
  [FieldKey.publicKey]?: string;
  [FieldKey.role]: string;
  [FieldKey.user]: string;
  [FieldKey.warehouse]: string;
}

const AUTH_OPTIONS: Option[] = [
  {
    text: 'Password',
    value: AuthOptionKey.password,
  },
  {
    text: 'Key Pair',
    value: AuthOptionKey.keyPair,
  },
];

const REQUIRED_FIELDS = [
  FieldKey.name,
  FieldKey.account,
  FieldKey.authentication,
  FieldKey.user,
  FieldKey.warehouse,
];

const SnowflakeForm: React.FC<DataSourceFormProps> = ({
  children,
  dataSource,
  dataType,
  name: dsName,
  onSuccess,
  renderBefore,
}) => {
  const segment = useSegmentContext();
  const { error, isLoading, mutate, reset } = useDataSourceMutation({
    dataSource,
    onSuccess,
  });

  const [isRoleWarningMessageShown, setRoleWarningMessage] = useState<boolean>(false);
  const { handleChange, handleSubmit, setValues, values } = useForm<FormValues>({
    initialValues: {
      [FieldKey.account]: '',
      [FieldKey.authentication]: [AUTH_OPTIONS[0]],
      [FieldKey.name]: dataSource?.name ?? dsName,
      [FieldKey.role]: '',
      [FieldKey.user]: '',
      [FieldKey.warehouse]: '',
    },
    onSubmit: ({ authentication, name, ...credentials }) => {
      // add authentication to credentials
      mutate({
        credentials: {
          ...credentials,
          authentication: authentication?.[0].value as string,
        },
        name,
        type: dataType,
      });

      segment?.track(SegmentTrackEventName.CreateServiceAccountConnectButtonClicked, { dataType });
    },
  });

  const { organization } = useUserContext();
  const { useSnowflakeKeyPair } = organization?.settings ?? {};

  const authentication = values?.authentication?.[0].value as AuthOptionKey;
  const isKeyPair = useSnowflakeKeyPair && authentication === AuthOptionKey.keyPair;
  useFetchDataSourceKeygen({
    enabled: isKeyPair,
    onSuccess: (data) => {
      setValues((prev) => ({
        ...prev,
        [FieldKey.publicKey]: data?.publicKey,
      }));
    },
    params: { type: 'rsa_der' },
  });

  const authConfig = {
    [AuthOptionKey.keyPair]: {
      [FieldKey.publicKey]: {
        Component: Input,
        disabled: !values?.[FieldKey.publicKey],
        endIcon: (
          <IconButton
            disabled={!values?.[FieldKey.publicKey]}
            ml={0.5}
            onClick={() => {
              copy(values?.[FieldKey.publicKey]!);
              renderInfoToast('Copied to clipboard');
            }}
          >
            <Icon name="copy" />
          </IconButton>
        ),
        key: FieldKey.publicKey,
        label: 'Public Key',
      },
    },
    [AuthOptionKey.password]: {
      [FieldKey.password]: {
        Component: Input,
        key: FieldKey.password,
        label: 'Password',
        onChange: handleChange,
        type: 'password',
      },
    },
  };
  const authContent = authConfig[authentication];

  const isInvalid = REQUIRED_FIELDS.some((key) => Boolean(values[key]) === false);

  return (
    <Form isLoading={isLoading} onSubmit={handleSubmit}>
      <StyledFormHorizontalLabelGrid>
        {renderBefore?.({ error, loading: isLoading })}
        <StyledLabel>
          Display Name
          <Input
            error={error?.data?.name}
            helperText={error?.data?.name}
            maxLength={50}
            name="name"
            onChange={handleChange}
            placeholder="Snowflake"
            type="text"
            value={values.name}
          />
        </StyledLabel>
        <StyledLabel>
          Account
          <Input
            error={error?.data?.account}
            helperText={error?.data?.account}
            name="account"
            onChange={handleChange}
            placeholder="account.us-east-1"
            type="text"
            value={values.account}
          />
        </StyledLabel>
        <StyledLabel>
          Username
          <Input
            error={error?.data?.user}
            helperText={error?.data?.user}
            name="user"
            onChange={handleChange}
            placeholder="Username"
            type="text"
            value={values.user}
          />
        </StyledLabel>
        {useSnowflakeKeyPair && (
          <StyledLabel as="div">
            Authentication
            <Select
              label="Authentication"
              maxOptionsVisible={7}
              onChange={(change) => {
                if (change !== values.authentication) {
                  setValues((prev) => ({
                    ...prev,
                    authentication: change as Option[],
                  }));
                  reset(); // reset errors
                  setRoleWarningMessage(false);
                }
              }}
              options={AUTH_OPTIONS}
              value={values.authentication}
            />
          </StyledLabel>
        )}
        {Object.values(authContent).map(({ Component, key, label, renderAfter, ...other }) => (
          <>
            <StyledLabel key={key}>
              {label}
              <Component
                error={error?.data?.[key]}
                helperText={error?.data?.[key]}
                name={key}
                placeholder={label}
                value={values[key as FieldKey]}
                {...other}
              />
            </StyledLabel>
            {renderAfter}
          </>
        ))}
        <StyledLabel>
          Role
          <Input
            error={error?.data?.role}
            helperText={error?.data?.role}
            name="role"
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              if (!e.currentTarget.value.toLowerCase().includes('selectstar')) {
                setRoleWarningMessage(true);
              } else {
                setRoleWarningMessage(false);
              }
            }}
            onChange={handleChange}
            placeholder="'selectstar_role' by default"
            type="text"
            value={values.role}
          />
        </StyledLabel>
        {isRoleWarningMessageShown && (
          <Box gridColumn="1/3">
            <Alert>
              <StyledText as="span" display="inline">
                If you don’t have access to the system QUERY_HISTORY view, we can still load the
                metadata but we will not be able to generate your popularity and lineage correctly.
                &nbsp;
                <Link
                  fontSize="inherit"
                  href={USER_DOCS_URL.snowflake}
                  rel="noopener noreferrer"
                  target="_blank"
                  underline
                >
                  Read More
                </Link>
                .
              </StyledText>
            </Alert>
          </Box>
        )}
        <StyledLabel>
          Warehouse
          <Input
            error={error?.data?.warehouse}
            helperText={error?.data?.warehouse}
            name="warehouse"
            onChange={handleChange}
            placeholder="Warehouse"
            type="text"
            value={values.warehouse}
          />
        </StyledLabel>
      </StyledFormHorizontalLabelGrid>
      {children?.({ error, loading: isLoading || isInvalid })}
    </Form>
  );
};

export default SnowflakeForm;
