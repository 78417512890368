import { useFetchTableauDataSource } from '@api/tableau';

import TableauDataSourcesTabContent from './TableauDataSourcesTabContent';

export default {
  Content: TableauDataSourcesTabContent,
  enableNotifications: true,
  fetchHook: useFetchTableauDataSource,
  fetchHookExtraParams: { force_showing_suggested_description: true },
};
