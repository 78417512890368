import type { DataTypesConfig } from '@configs/dataTypes/types';

import type { BiColumnsTabProps } from '@components/TabContent/BiColumnsTab';

const FIELDS_VISIBLE_COLUMNS: BiColumnsTabProps['visibleColumns'] = [
  'search',
  'tableName',
  'name',
  'description',
  'calculation',
  'tags',
];

export interface DatasetsTabContentConfigItem {
  visibleColumns?: BiColumnsTabProps['visibleColumns'];
}

const DATASETS_TAB_CONTENT_CONFIG: DataTypesConfig<DatasetsTabContentConfigItem> = {
  power_bi: {
    default: {},
    powerbidataset: {
      default: {
        visibleColumns: FIELDS_VISIBLE_COLUMNS,
      },
      powerbidataflow: {
        visibleColumns: FIELDS_VISIBLE_COLUMNS.filter((str) => str !== 'calculation'),
      },
    },
  },
};

export default DATASETS_TAB_CONTENT_CONFIG;
