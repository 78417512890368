import React from 'react';
import { useLocation } from 'react-router-dom';

import ButtonGroup from '@components/ButtonGroup';
import useLocalStorage from '@utils/useLocalStorage';

export type ViewType = 'grid' | 'list';

interface UseGridListViewConfigProps {
  defaultView?: ViewType;
}

const useGridListViewConfig = (props?: UseGridListViewConfigProps) => {
  const { defaultView = 'grid' } = props ?? {};
  const { pathname } = useLocation();
  const [activeView, setActiveView] = useLocalStorage<ViewType>(pathname, defaultView);

  const selectorComponent = (
    <ButtonGroup
      active={activeView}
      compSize="sm"
      options={[
        {
          icon: 'list',
          id: 'list',
          onClick: () => {
            setActiveView('list');
          },
          title: 'List view',
        },
        {
          icon: 'grid',
          id: 'grid',
          onClick: () => {
            setActiveView('grid');
          },
          title: 'Grid view',
        },
      ]}
    />
  );

  return {
    activeView,
    selectorComponent,
    setActiveView,
  };
};

export default useGridListViewConfig;
