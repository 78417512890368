import React from 'react';

import Box from '@components/Box';
import Highlighter from '@components/Highlighter';
import Text from '@components/Text';
import theme from '@styles/theme';

interface LocationCellProps {
  globalFilter?: string;
  location?: string;
}

const LocationCell: React.FC<LocationCellProps> = ({ globalFilter, location }) => {
  const searchWords = globalFilter?.split(/[.| ]+/) || [];

  return (
    <Box compDisplay="flex">
      <Text color="inherit" fontSize={theme.typography.fontSizes.body1}>
        <Highlighter autoEscape searchWords={searchWords} textToHighlight={location || ''} />
      </Text>
    </Box>
  );
};

export default LocationCell;
