import type { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';

const IGNORED_RETRY_STATUSES = [400, 404, 403];

const RETRY_COUNT = 2;

const handleRetry = (failureCount: number, { status }: EnhancedErrorResult) => {
  if (failureCount >= RETRY_COUNT) {
    return false;
  }

  if (status) {
    return !IGNORED_RETRY_STATUSES.includes(status);
  }

  return true;
};

export default handleRetry;
