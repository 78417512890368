import React from 'react';

import Status from '@components/Status';

const BetaBadge: React.FC = () => {
  return (
    <Status noIcon status="success">
      Beta
    </Status>
  );
};

export default BetaBadge;
