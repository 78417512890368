import React from 'react';

import { UsageTypeType } from '@api/lineage/types';
import Box from '@components/Box';
import Tooltip from '@components/Tooltip';

import UsageTypeBadge from '../LineageExplore/components/Common/UsageTypeBadge';

const USAGE_ORDER: { [key: string]: number } = {
  '': 5,
  aggregated: 2,
  asis: 1,
  filter: 4,
  transformed: 3,
};

interface UsageTypeProps {
  direction?: 'left' | 'right';
  hideTooltips?: boolean;
  types?: UsageTypeType[];
}

const validLabel: PartialRecord<UsageTypeType, string | undefined> = {
  aggregated: 'Aggr',
  asis: 'As Is',
  filter: 'Filter',
  transformed: 'Transformed',
};

const tooltipTypeTextMap = {
  left: {
    aggregated: `This field is aggregated`,
    asis: `This field is used as is`,
    filter: `This field is used as a filter`,
    none: '',
    transformed: `This field is transformed`,
  },
  right: {
    aggregated: `Source field is aggregated`,
    asis: `Source field is used as is`,
    filter: `Source field is used as a filter`,
    none: '',
    transformed: `Source field is transformed`,
  },
};

const UsageType: React.FC<UsageTypeProps> = ({ direction = 'left', hideTooltips, types = [] }) => {
  if (!types) {
    return null;
  }

  return (
    <Box compDisplay="flex" gap={0.25}>
      {types
        .sort((a, b) => USAGE_ORDER[a] - USAGE_ORDER[b])
        .map((type) => {
          const label = validLabel[type];
          const tooltipText = tooltipTypeTextMap[direction]?.[type];
          if (!label) {
            return null;
          }

          const usageLabel = (
            <UsageTypeBadge key={type} hideFilterIcon showFilterFullText type={type} />
          );

          if (hideTooltips || !tooltipText) {
            return usageLabel;
          }
          return (
            <Tooltip key={type} content={tooltipText}>
              {usageLabel}
            </Tooltip>
          );
        })}
    </Box>
  );
};

export default React.memo(UsageType);
