import styled from '@emotion/styled';

import Box from '@components/Box';
import type { StyledBoxProps } from '@components/Box/Box.styles';
import { BoxStyles } from '@components/Box/Box.styles';

export interface StyledFormProps extends StyledBoxProps {
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
}

export const StyledForm = styled.form<StyledFormProps>`
  ${BoxStyles}
`;

StyledForm.defaultProps = {
  borderRadius: 'inherit',
  compWidth: '100%',
  position: 'relative',
  role: 'form',
};

export const FormHorizontalLabelGrid = styled(Box)``;

FormHorizontalLabelGrid.defaultProps = {
  alignItems: 'center',
  compDisplay: 'grid',
  gap: 2,
  gridAutoRows: 'max-content',
  gridTemplateColumns: 'minmax(auto, 7rem) 1fr',
  p: 3,
};
