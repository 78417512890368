import React from 'react';

import { useFetchTerms } from '@api/terms';
import OwnsTable from '@components/Table/OwnsTable';
import { termsOwnsQuery } from '@models/Owners';
import { Filter } from '@utils';

import { ProfileType } from '../../../ProfilePage.types';
import { OwnedItemsSubTabProps } from '../types';

const TERMS_FILTER_OPTIONS: Filter.FilterOptions = {
  query: termsOwnsQuery,
};

const TERMS_SEARCH_CONFIG: { [key: string]: keyof Filter.FilterOptions } = {
  dataObject: 'search_name',
};

const OwnedMetricsSubTab = ({ guid, profileType }: OwnedItemsSubTabProps) => {
  const filterService = Filter.useUpdateFilters(TERMS_FILTER_OPTIONS, TERMS_SEARCH_CONFIG, {}, '');
  const { filter } = filterService;

  const { data: metrics, isLoading } = useFetchTerms({
    params: {
      ...Filter.setParams(filter),
      owners: profileType === ProfileType.user ? guid : undefined,
      teams: profileType === ProfileType.team ? guid : undefined,
    },
  });

  return (
    <OwnsTable
      data={metrics?.results}
      filterService={filterService}
      itemCount={metrics?.results?.length}
      loading={isLoading}
      stickyHeader
    />
  );
};

export default OwnedMetricsSubTab;
