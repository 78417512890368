import React from 'react';
import type { DataSource } from '@configs/dataSources/types';

import type { StepProps } from '@components/DataSourceSetup/types';

import airflow from './AirflowConfigureStepForm';

const forms: PartialRecord<DataSource, React.FC<StepProps>> = {
  airflow,
};

export default forms;
