import React from 'react';

import Box from '@components/Box';
import Button from '@components/Button/Button';
import CenterAlignedBox from '@components/CenterAlignedBox';
import Text, { defaultParagraphStyles } from '@components/Text';
import Title from '@components/Title/Title';

interface TextScreenProps {
  buttonText?: string;
  disabled?: boolean;
  onClick?: () => void;
  text?: string | React.ReactNode;
  title?: string;
}
const TextScreen: React.FC<TextScreenProps> = ({ buttonText, disabled, onClick, text, title }) => {
  return (
    <CenterAlignedBox maxWidth="460px" minWidth="360px">
      {title && (
        <Box alignItems="flex-start" compWidth="100%" pb={2}>
          <Title showLogo>{title}</Title>
        </Box>
      )}
      {text && (
        <Box alignItems="flex-start" compWidth="100%" pb={2}>
          <Text as="p" py={0.5} {...defaultParagraphStyles} lineHeight={1.5}>
            {text}
          </Text>
        </Box>
      )}
      {onClick && (
        <Button disabled={disabled} fluid onClick={onClick}>
          {buttonText || 'Get Started'}
        </Button>
      )}
    </CenterAlignedBox>
  );
};

export default TextScreen;
