import { Expose, Type } from 'class-transformer';

import { QueryData, QueryModel } from '@api/queries/QueryModel';
import { TableData, TableModel } from '@api/tables/TableModel';
import { PopularityData, PopularityModel } from '@models/PopularityModel';

import { ConditionData, ConditionModel } from './ConditionModel';

export interface JoinData {
  conditions: ConditionData[];
  guid: string;
  joinedTable: TableData;
  objectType: string;
  popularity?: PopularityData;
  sampleQuery: QueryData;
  sourceTable: TableData;
  totalRuns: number;
}

export class JoinModel {
  static objectType: string = 'join';

  objectType: string = JoinModel.objectType;

  static typeDisplay: string = 'Join';

  typeDisplay: string = JoinModel.typeDisplay;

  guid!: string;

  @Expose({ name: 'source_table' })
  @Type(() => TableModel)
  sourceTable!: TableModel;

  @Expose({ name: 'joined_table' })
  @Type(() => TableModel)
  joinedTable!: TableModel;

  @Expose({ name: 'total_runs' })
  totalRuns: number = 0;

  @Expose({ name: 'sample_query' })
  @Type(() => QueryModel)
  sampleQuery!: QueryModel;

  @Type(() => ConditionModel)
  conditions!: ConditionModel[];

  @Type(() => PopularityModel)
  popularity?: PopularityModel;
}
