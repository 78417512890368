import React from 'react';
import getIcons from '@configs/icons/getIcons';

import { DescriptionSource } from '@api/description/description.types';
import Icon from '@components/UI/Icon';
import { ValidDSType } from '@models/DataSourceCredentials';
import theme from '@styles/theme';

import DescriptionAvatar from '../DescriptionAvatar/DescriptionAvatar';

interface DescriptionIconProps {
  dataSourceType?: ValidDSType;
  descriptionSource?: DescriptionSource;
  guid?: string;
  plainUserDescription?: string;
  showLabel?: boolean;
  small?: boolean;
}

const DescriptionIcon: React.FC<DescriptionIconProps> = ({
  dataSourceType,
  descriptionSource,
  guid,
  plainUserDescription,
  showLabel = false,
  small = true,
}) => {
  const size = small ? '16px' : '20px';

  if (descriptionSource === 'ingestion') {
    return (
      <Icon
        name={
          getIcons({
            dataSourceType,
          }).dataSource
        }
        size={size}
      />
    );
  }

  if (descriptionSource === 'user') {
    return (
      <DescriptionAvatar
        guid={guid}
        plainUserDescription={plainUserDescription}
        showLabel={showLabel}
        size={size}
      />
    );
  }

  if (descriptionSource === 'ai') {
    return <Icon name="wand" size={size} />;
  }

  return (
    <Icon
      color={theme.colors.v1.primary[500]}
      hoverColor={theme.colors.v1.primary[900]}
      name="auto-description"
      size={size}
    />
  );
};

export default DescriptionIcon;
