import styled from '@emotion/styled';

import TreeV2 from '@components/Tree/Tree.v2';

import { StyledOptionsListItem } from '../OptionsList.styles';

export const StyledTreeV2 = styled(TreeV2)`
  > li[aria-selected='true'] {
    background-color: ${({ theme }) => theme.colors.v1.gray[100]};
  }
`;

export const StyledTreeOptionsItem = styled(StyledOptionsListItem)<{
  isCaretVisible?: boolean;
  level: number;
}>`
  background-color: transparent;
  padding-top: ${({ theme }) => theme.space(1)};
  padding-bottom: ${({ theme }) => theme.space(1)};
  padding-left: ${({ isCaretVisible, level, theme }) => {
    if (level > 0) {
      return theme.space(1.25);
    }
    if (level === 0 && !isCaretVisible) {
      return theme.space(0.5);
    }
    return theme.space(0.5);
  }};
  padding-right: ${({ theme }) => theme.space(0.25)};
`;
