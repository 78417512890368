import { useCallback, useState } from 'react';
import * as Sentry from '@sentry/react';

const useLocalStorage = <T>(key: string, initialValue: T): [T, (v: T | ((v: T) => T)) => void] => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (err) {
      Sentry.captureException(err);
      return initialValue;
    }
  });

  const setValue = useCallback(
    (value: T | ((v: T) => T)) => {
      try {
        const valueToStore = value instanceof Function ? value(storedValue) : value;
        setStoredValue(valueToStore);
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      } catch (err) {
        Sentry.captureException(err);
      }
    },
    [key, setStoredValue, storedValue],
  );

  return [storedValue, setValue];
};

export default useLocalStorage;
