import React from 'react';

import Button from '@components/Button/Button';
import Icon from '@components/UI/Icon';
import DataTypesModel from '@models/DataTypesModel';

import { getBiLinkLabel } from './biLinksPropsMap';

interface BILinkProps {
  dataTypes?: DataTypesModel;
  href: string;
}

const BILink: React.FC<BILinkProps> = ({ dataTypes, href }) => {
  const dataSourceProps = getBiLinkLabel(dataTypes);

  return (
    <Button
      as="a"
      compSize="sm"
      href={href}
      rel="noopener noreferrer"
      startIcon={<Icon name={dataTypes?.icons?.dataSource!} size="16px" />}
      target="_blank"
      variant="outlined"
    >
      {dataSourceProps?.label}
    </Button>
  );
};

export default BILink;
