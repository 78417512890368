import styled from '@emotion/styled';

import Box from '@components/Box';

interface DecorativeProps {
  showFullCircle?: boolean;
  showSimpleBackground?: boolean;
}

const Decorative = styled(Box)<DecorativeProps>`
  background-image: url(${({ showFullCircle }) =>
      showFullCircle ? '/images/circle-full.svg' : '/images/circle.svg'})
    ${({ showSimpleBackground }) =>
      !showSimpleBackground &&
      ', radial-gradient(327.34% 127.14% at 17.66% 5.78%, #261447 12.51%, #662880 62.41%, #942481 85.77%)'};
  background-color: ${({ showSimpleBackground }) =>
    showSimpleBackground ? '#261447' : 'transparent'};
  background-position: right bottom;
  background-repeat: no-repeat;
  backdrop-filter: blur(150px);
  flex: 1 1 auto;
  min-height: 100%;
`;
export default Decorative;
