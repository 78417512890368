import styled from '@emotion/styled';

import Box from '@components/Box';

const HR = styled(Box)``;

HR.defaultProps = {
  as: 'hr',
  borderColor: 'gray.200',
  borderWidth: '1px 0 0 0',
  mb: 4,
  mt: 4,
  mx: 0,
  noDefault: true,
};

export default HR;
