import paginatedTransform from '@api/paginatedTransform';
import rawTransform from '@api/rawTransform';
import type { UseFetchOptions } from '@hooks/useFetch';
import useFetch from '@hooks/useFetch';
import type { UseMutationOptions } from '@hooks/useMutation';
import useMutation from '@hooks/useMutation';
import type { OwnersUpdateData } from '@models/Owners';
import { PaginatedResponse } from '@models/PaginatedResponse';
import type { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';

import * as cacheKeys from './cacheKeys';
import { SchemaModel } from './SchemaModel';

export const fetchSchemasSelect = paginatedTransform(SchemaModel);
export const useFetchSchemas = (options?: UseFetchOptions<PaginatedResponse<SchemaModel>>) => {
  return useFetch<PaginatedResponse<SchemaModel>>({
    ...options,
    queryKey: [...cacheKeys.all, options?.params],
    select: fetchSchemasSelect,
    url: '/schemas/',
  });
};

export const fetchSchemaSelect = rawTransform(SchemaModel);
export const useFetchSchema = (id: string, options?: UseFetchOptions<SchemaModel>) => {
  return useFetch<SchemaModel>({
    ...options,
    queryKey: [...cacheKeys.schema(id), options?.params],
    select: fetchSchemaSelect,
    url: `/schemas/${id}/`,
  });
};

export const usePatchScemasOwners = (
  options?: UseMutationOptions<never, EnhancedErrorResult, OwnersUpdateData>,
) => {
  return useMutation({
    ...options,
    method: 'PATCH',
    url: `/schemas/owners/`,
  });
};
