import { DataTypes } from '@configs/dataTypes/types';

import { UsageTypeType } from '@api/lineage/types';
import { SIZES } from '@components/LineageExplore/components/Nodes/config';
import {
  ExploreNode,
  InputNode,
  InputNodesById,
  NodeStacksConfig,
} from '@components/LineageExplore/LineageExplore.types';

import { EXPLORE_NODE_PAGE_SIZE } from '../../useShowMoreNodes/useShowMore.constants';
import {
  BI_GROUP_SEPARATOR,
  BI_GROUP_STACK_INDICATOR,
  COLUMNS_ORDER_BY_USAGE_TYPE,
  DWH_GROUP_REGEX,
  GROUP_ORDER_BY_DATA_TYPE,
} from '../useCreateNodesEdges.contants';

import { ConflictArray, NodesById, StackGroups } from './types';

export const columnX = SIZES.paddingLeft.table + SIZES.borderWidth.initialNode;

export const tableX = SIZES.paddingLeft.schema;

export const calcColumnInitialY = (isSearchEnabled = false) => {
  return (
    SIZES.height.table +
    SIZES.borderWidth.table / 2 +
    (isSearchEnabled ? SIZES.height.columnsSearchBar + SIZES.paddingTop.table : 0)
  );
};

export const calcGroupX = (stackIndex: number, isColumnLevelLineage: boolean) => {
  const stackGap = isColumnLevelLineage
    ? SIZES.columnGap.columnLevelStack
    : SIZES.columnGap.tableLevelStack;

  return stackIndex * (SIZES.width.database + stackGap);
};

export const initialGroupHeight = SIZES.height.database - SIZES.borderWidth.database;

export const showMoreTotalHeight = SIZES.height.showMoreButton + SIZES.rowGap.table;

const childMatchesQuery = ({ childName, query }: { childName: string; query?: string }) => {
  if (!query) return true;
  return childName.toLowerCase().includes(query.toLowerCase());
};

export const filterChildrenByQuery = ({
  childrenKeys,
  nodesById,
  query,
}: {
  childrenKeys: string[];
  nodesById: NodesById;
  query?: string;
}) => {
  if (!query) return childrenKeys;

  return childrenKeys.filter((childKey) => {
    const child = nodesById[childKey];
    return childMatchesQuery({ childName: child?.data?.name ?? '', query });
  });
};

export const calculateGroupWidth = (
  node: ExploreNode,
  stackIndex: number,
  isColumnLevelLineage: boolean,
) => {
  const { endStackId = stackIndex, startStackId = stackIndex } = node;

  const stackRange = endStackId - startStackId;
  const stackGap = isColumnLevelLineage
    ? SIZES.columnGap.columnLevelStack
    : SIZES.columnGap.tableLevelStack;

  return SIZES.width.database + stackRange * (SIZES.width.database + stackGap);
};

const getGroupDataSourceType = (group: string) => {
  const stackIndicatorRegex = new RegExp(`^[0-9]${BI_GROUP_STACK_INDICATOR}`);
  const noStackIndicatorGroup = group.replace(stackIndicatorRegex, '');

  const [dataSourceType] = noStackIndicatorGroup.split(BI_GROUP_SEPARATOR);

  return dataSourceType;
};

const getBIGroupsSortingIndex = (a: string, b: string) => {
  const aGroupDataSourceType = getGroupDataSourceType(a);
  const aOrder = Math.max(
    GROUP_ORDER_BY_DATA_TYPE[aGroupDataSourceType as DataTypes['dataSourceType']],
    0,
  );

  const bGroupDataSourceType = getGroupDataSourceType(b);
  const bOrder = Math.max(
    GROUP_ORDER_BY_DATA_TYPE[bGroupDataSourceType as DataTypes['dataSourceType']],
    0,
  );

  return aOrder - bOrder;
};

const sortBIGroups = (groups: Array<string>) => {
  const sorted = [...groups].sort(getBIGroupsSortingIndex);

  return sorted;
};

export const splitGroupsByType = (stackGroups: StackGroups) => {
  const dwhGroupsStacks: Record<number, string[]> = {};
  const biGroupsStacks: Record<number, string[]> = {};

  Object.entries(stackGroups).forEach(([stack, groups]) => {
    groups.forEach((group) => {
      const isDwh = DWH_GROUP_REGEX.test(group);
      if (isDwh) {
        const currentStack = dwhGroupsStacks[Number(stack)] ?? [];
        currentStack.push(group);
        dwhGroupsStacks[Number(stack)] = currentStack;
      } else {
        const currentStack = biGroupsStacks[Number(stack)] ?? [];
        currentStack.push(group);
        biGroupsStacks[Number(stack)] = currentStack;
      }
    });
  });

  const biGroupsSorted = Object.entries(biGroupsStacks).reduce((acc, [key, stack]) => {
    return { ...acc, [key]: sortBIGroups(stack) };
  }, {} as Record<number, Array<string>>);

  return [dwhGroupsStacks, biGroupsSorted];
};

/**
 * Sort nodes by popularity (descending) and then by name (ascending)
 * @param nodesKeys Array of keys of the nodes to sort
 * @param nodesById Object with the nodes data
 * @returns Array of keys of the sorted nodes
 */
export const sortNodeKeysByPopularity = (nodesKeys: Array<string>, nodesById: NodesById) => {
  const nodes = nodesKeys.map((key) => nodesById[key]).filter((node) => node);
  const sortedNodes = nodes.sort((a, b) => {
    return (
      Number(b?.data?.popularity ?? 0) - Number(a?.data?.popularity ?? 0) ||
      (a?.data?.name ?? '').localeCompare(b?.data?.name ?? '')
    );
  });

  return sortedNodes.map((node) => node.id);
};

export const sortNodeKeysByColumnsRelevance = (nodesKeys: Array<string>, nodesById: NodesById) => {
  const nodes = nodesKeys.map((key) => nodesById[key]).filter((node) => node);

  const sortedNodes = nodes.sort((a, b) => {
    if (a.data.hasRelevantColumnLineage && !b.data.hasRelevantColumnLineage) {
      return -1;
    }
    if (!a.data.hasRelevantColumnLineage && b.data.hasRelevantColumnLineage) {
      return 1;
    }
    return (a.data.name ?? '').localeCompare(b.data.name ?? '');
  });

  return sortedNodes.map((node) => node.id);
};

export const getOccupiedColumnsByNode = (node: ExploreNode) => {
  const { stackedAt, startStackId = stackedAt, endStackId = stackedAt } = node;

  return Array.from({ length: Number(endStackId) - Number(startStackId) + 1 }).map(
    (_, i) => i + Number(startStackId),
  );
};

export const createCountMapByStacks = (stacks: Array<number>, initialValue = 0) => {
  return stacks.reduce((acc, cur) => {
    return { ...acc, [cur]: initialValue };
  }, {} as Record<number, number>);
};

const getBiggestHeightAmongStacks = (
  heightPerStack: Record<number, number>,
  startStack: number,
  endStack: number,
) => {
  const heights = Object.entries(heightPerStack)
    .filter(([stack]) => Number(stack) >= startStack && Number(stack) <= endStack)
    .map(([, height]) => height);

  return Math.max(...heights);
};

const isSameConflict = (conflictA: ConflictArray, conflictB: ConflictArray) => {
  return conflictA[0] === conflictB[0] && conflictA[1] === conflictB[1];
};

const isConflictInside = (baseConflict: ConflictArray, conflictToCheck: ConflictArray) => {
  const [baseStart, baseEnd] = baseConflict;
  const [checkStart, checkEnd] = conflictToCheck;

  return checkStart >= baseStart && checkEnd <= baseEnd;
};

const getOverlappedConflictEnd = (
  stack: number,
  biggestConflictEndPerStack: Record<number, number>,
): number => {
  const currentEnd = biggestConflictEndPerStack[stack];

  if (currentEnd === stack) return currentEnd;

  return getOverlappedConflictEnd(currentEnd, biggestConflictEndPerStack);
};

export const calculateOverlappedConflicts = (
  biggestConflictEndPerStack: Record<number, number>,
) => {
  const biggestConflictPerStack: Record<number, ConflictArray> = {};

  const overlappedConflictsRanges = Object.entries(biggestConflictEndPerStack).reduce(
    (acc, cur) => {
      const [stack] = cur;
      const overlappedEndStack = getOverlappedConflictEnd(
        Number(stack),
        biggestConflictEndPerStack,
      );

      return { ...acc, [stack]: overlappedEndStack };
    },
    {} as Record<number, number>,
  );

  const existingConflicts = Object.entries(overlappedConflictsRanges).reduce(
    (acc, [start, end]) => [...acc, [Number(start), end] as ConflictArray],
    [] as Array<ConflictArray>,
  );

  Object.entries(overlappedConflictsRanges).forEach(([start, end]) => {
    const currentConflict = [Number(start), end] as ConflictArray;

    const overlappingConflicts = existingConflicts.filter((conflict) => {
      return (
        !isSameConflict(currentConflict, conflict) && isConflictInside(conflict, currentConflict)
      );
    });

    const [biggerOverlappingConflict] = overlappingConflicts.sort((conflictA, conflictB) => {
      const [startA, endA] = conflictA;
      const [startB, endB] = conflictB;
      const sizeA = endA - startA;
      const sizeB = endB - startB;
      return sizeB - sizeA;
    });

    biggestConflictPerStack[Number(start)] = biggerOverlappingConflict ?? currentConflict;
  });

  return biggestConflictPerStack;
};

export const calculateDeltaYPerStack = (
  heightPerStack: Record<number, number>,
  biggestConflictEndPerStack: Record<number, number>,
) => {
  const deltaYPerStack: Record<number, number> = {};
  const originalStackHeight = Math.min(...Object.values(heightPerStack));

  const biggestConflictPerStack = calculateOverlappedConflicts(biggestConflictEndPerStack);

  Object.entries(biggestConflictEndPerStack).forEach(([stack]) => {
    const [start, end] = biggestConflictPerStack[Number(stack)];
    const biggerHeightAmongConflictStacks = getBiggestHeightAmongStacks(heightPerStack, start, end);
    deltaYPerStack[Number(stack)] = (originalStackHeight - biggerHeightAmongConflictStacks) / 2;
  });

  return deltaYPerStack;
};

export const createGroupNodeInitialStacksConfig = (node: ExploreNode) => {
  const groupStacks = getOccupiedColumnsByNode(node);

  return groupStacks.reduce((acc, stackId) => {
    return {
      ...acc,
      [stackId]: {
        hiddenChildren: new Set<string>(),
        hiddenChildrenCount: 0,
        shownChildrenCount:
          node.nodeStacksConfig?.[stackId]?.shownChildrenCount ?? EXPLORE_NODE_PAGE_SIZE,
      },
    };
  }, {} as NodeStacksConfig);
};

export const calculateGroupChildX = (
  parentNode: ExploreNode,
  stackIndex: number,
  isColumnLevelLineage: boolean,
) => {
  const { startStackId = stackIndex } = parentNode;
  const positionInsideStack = stackIndex - Number(startStackId);

  const schemaX = SIZES.paddingLeft.database + SIZES.borderWidth.integration;
  const stackGap = isColumnLevelLineage
    ? SIZES.columnGap.columnLevelStack
    : SIZES.columnGap.tableLevelStack;

  return (
    schemaX + positionInsideStack * (SIZES.width.schema + stackGap + 2 * SIZES.paddingLeft.database)
  );
};

export const getHighestAvailableY = (node: ExploreNode, heightPerStack: Record<number, number>) => {
  const nodeStacks = getOccupiedColumnsByNode(node);
  return Math.max(...nodeStacks.map((stack) => heightPerStack[stack]));
};

interface SplitColumnsByUsageResponse {
  hidden: (ExploreNode | InputNode)[];
  shown: (ExploreNode | InputNode)[];
}

interface SplitColumnsByUsageParams {
  columnsKeys: string[];
  nodesById: NodesById | InputNodesById;
  usageFiltersToHide?: UsageTypeType[];
}

export const splitColumnsByUsage = ({
  columnsKeys,
  nodesById,
  usageFiltersToHide = ['filter'],
}: SplitColumnsByUsageParams): SplitColumnsByUsageResponse => {
  const columnNodes = columnsKeys.map((key) => nodesById[key]).filter((node) => node);
  const columnNodesByFilterUsage: SplitColumnsByUsageResponse = { hidden: [], shown: [] };

  for (let i = 0; i < columnNodes.length; i += 1) {
    const cur = columnNodes[i];
    const usage = 'data' in cur ? cur?.data?.usage : cur?.usage;

    if (
      usage &&
      usage.length > 0 &&
      usage.every((usageType) => usageFiltersToHide.includes(usageType))
    ) {
      columnNodesByFilterUsage.hidden.push(cur);
    } else {
      columnNodesByFilterUsage.shown.push(cur);
    }
  }

  return columnNodesByFilterUsage;
};

interface SortColumnsByUsageTypeParams {
  columnsKeys: string[];
  nodesById: NodesById;
}

export const sortColumnsByUsageType = ({
  columnsKeys,
  nodesById,
}: SortColumnsByUsageTypeParams): Array<ExploreNode> => {
  const columnNodes = columnsKeys.map((key) => nodesById[key]).filter((node) => node);

  const sorted = [...columnNodes].sort((a, b) => {
    const aUsage = a?.data?.usage ?? [];
    const bUsage = b?.data?.usage ?? [];

    const aOrder =
      aUsage.length > 0
        ? Math.max(Math.min(...aUsage.map((usage) => COLUMNS_ORDER_BY_USAGE_TYPE[usage])), 0)
        : 0;
    const bOrder =
      bUsage.length > 0
        ? Math.max(Math.min(...bUsage.map((usage) => COLUMNS_ORDER_BY_USAGE_TYPE[usage])), 0)
        : 0;

    const result =
      aOrder - bOrder || Number(b?.data?.popularity ?? 0) - Number(a?.data?.popularity ?? 0);

    return result;
  });

  return sorted;
};

export const getTopLevelParentNode = (nodeKey: string, nodesById: NodesById) => {
  const findTopLevelParent = (key: string): ExploreNode | undefined => {
    const node = nodesById[key];
    if (!node) {
      return undefined;
    }
    const parentKey = node.parent;
    if (!parentKey) {
      return node;
    }
    return findTopLevelParent(parentKey);
  };

  return findTopLevelParent(nodeKey);
};
