import React from 'react';

import Box from '@components/Box';
import Text from '@components/Text';

import { StyledTileTooltip } from './TileTooltip.styles';

export interface TileTooltipProps {
  content: React.ReactNode;
  title?: React.ReactNode;
}

const TileTooltip = ({ content, title }: TileTooltipProps) => {
  return (
    <StyledTileTooltip>
      <Box compWidth="100%" gap={0.5} maxHeight="11.2rem" noDefault overflow="auto" pl={2} py={1.2}>
        {title && (
          <Text as="span" color="gray.700" fontSize="12px" fontWeight="semibold" lineHeight="18px">
            {title}
          </Text>
        )}
        {content}
      </Box>
    </StyledTileTooltip>
  );
};

export default TileTooltip;
