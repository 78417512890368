import React, { useRef } from 'react';
import findConfig from '@configs/dataTypes/findConfig';

import useExpandLineage from '@components/LineageExplore/useExpandLineage';
import { useLineageExplore } from '@components/LineageExplore/useLineageExplore';
import { LineageModeChangeTrigger } from '@components/LineageExplore/useLineageExplore/LineageExplore.context.types';
import useSearchChildren from '@components/LineageExplore/useSearchChildren';
import useUserTracking from '@components/LineageExplore/useUserTracking';
import { useModal } from '@context/Modal';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import DataTypesModel from '@models/DataTypesModel';

import OptionsButton from '../../Common/OptionsButton';
import { OptionsMenuProps } from '../../Common/OptionsMenu/OptionsMenu';
import {
  CLOSE_ALL_DOWNSTREAM_OPTION,
  CLOSE_ALL_UPSTREAM_OPTION,
  OPEN_ALL_DOWNSTREAM_OPTION,
  OPEN_ALL_UPSTREAM_OPTION,
  SHOW_SQL_OPTION,
  TRIGGER_COLUMN_SEARCH_OPTION,
} from '../../Common/OptionsMenu/OptionsMenu.constants';

import {
  DEFAULT_BI_TABLE_NODE_SQL_CONFIG,
  DEFAULT_TABLE_NODE_SQL_CONFIG,
  NODE_TYPE_CONFIG,
  TableNodeSqlConfig,
} from './TableNodeOptions.constants';
import useFetchTableSql from './useFetchTableSql';

interface TableNodeOptionsProps {
  dataTypes?: DataTypesModel;
  disableSearch?: boolean;
  downstreamObjectsCount?: number;
  guid: string;
  isBITable?: boolean;
  isOpen: boolean;
  nodeKey: string;
  setIsOpen: (isOpen: boolean) => void;
  show: boolean;
  trackingData: Record<string, any>;
  upstreamObjectsCount?: number;
}

const TableNodeOptions = ({
  dataTypes,
  disableSearch,
  downstreamObjectsCount = 0,
  guid,
  isBITable = false,
  isOpen,
  nodeKey,
  setIsOpen,
  show,
  trackingData,
  upstreamObjectsCount = 0,
}: TableNodeOptionsProps) => {
  const { MODAL_IDS, openModal } = useModal();
  const { createWaitingAction, isColumnLevelLineage, isFetchingExpandColumnLineageModeAll } =
    useLineageExplore();
  const { track } = useUserTracking();

  const { closeAllDownstream, closeAllUpstream, openAllDownstream, openAllUpstream } =
    useExpandLineage({ guid, nodeKey });

  const { triggerColumnSearch } = useSearchChildren(nodeKey);
  const defaultSqlConfig = isBITable
    ? DEFAULT_BI_TABLE_NODE_SQL_CONFIG
    : DEFAULT_TABLE_NODE_SQL_CONFIG;
  const { hasSql, shouldShowSidebar } = dataTypes
    ? findConfig<TableNodeSqlConfig>(NODE_TYPE_CONFIG, dataTypes) ?? defaultSqlConfig
    : defaultSqlConfig;

  const isSqlRequestEnabled = isOpen && hasSql && show;

  const {
    isError: isErrorSql,
    isLoading: isLoadingSql,
    query,
    queryLogRecords,
  } = useFetchTableSql({ dataTypes, enabled: isSqlRequestEnabled, guid });

  const handleShowSql = () => {
    track(SegmentTrackEventName.LineageSQLToggleClicked, trackingData);
    openModal(MODAL_IDS.query, {
      codeString: query,
      dataSourceType: dataTypes?.dataSourceType,
      hideSidebar: !shouldShowSidebar,
      metricGuid: guid,
      queryLogRecords,
    });
  };

  const getSqlOption = () => {
    if (!hasSql) return [];

    const isDisabled =
      !isLoadingSql && (isErrorSql || !queryLogRecords || queryLogRecords?.length === 0) && !query;

    return [
      {
        options: [
          {
            ...SHOW_SQL_OPTION,
            isDisabled,
            isLoading: isLoadingSql,
            onClick: handleShowSql,
            tooltipMessage: isDisabled ? 'No SQL available' : '',
          },
        ],
      },
    ];
  };

  const handleOpenAllUpstream = () => {
    openAllUpstream();

    if (isColumnLevelLineage) {
      track(SegmentTrackEventName.LineageTableLevelLoaded, {
        trigger: LineageModeChangeTrigger.OpenAllUpstream,
      });
    }
  };

  const handleOpenAllDownstream = () => {
    openAllDownstream();

    if (isColumnLevelLineage) {
      track(SegmentTrackEventName.LineageTableLevelLoaded, {
        trigger: LineageModeChangeTrigger.OpenAllDownstream,
      });
    }
  };

  const handleOpenAllUpstreamRef = useRef(handleOpenAllUpstream);
  handleOpenAllUpstreamRef.current = handleOpenAllUpstream;
  const handleCloseAllUpstreamRef = useRef(closeAllUpstream);
  handleCloseAllUpstreamRef.current = closeAllUpstream;
  const handleOpenAllDownstreamRef = useRef(handleOpenAllDownstream);
  handleOpenAllDownstreamRef.current = handleOpenAllDownstream;
  const closeAllDownstreamRef = useRef(closeAllDownstream);
  closeAllDownstreamRef.current = closeAllDownstream;

  const handleChangeLineage = (callback: React.RefObject<Function>) => {
    if (isFetchingExpandColumnLineageModeAll) {
      createWaitingAction(callback);
    } else {
      callback.current?.();
    }
  };

  const getUpstreamOptions = () => {
    const isDisabled = upstreamObjectsCount === 0;
    const tooltipMessage = isDisabled ? 'No upstream available' : '';

    const closeTooltipMessage =
      isColumnLevelLineage && !isDisabled
        ? 'Only available in table-level lineage'
        : tooltipMessage;

    return [
      {
        options: [
          {
            ...OPEN_ALL_UPSTREAM_OPTION,
            count: upstreamObjectsCount,
            isDisabled,
            onClick: () => handleChangeLineage(handleOpenAllUpstreamRef),
            tooltipMessage,
          },
          {
            ...CLOSE_ALL_UPSTREAM_OPTION,
            count: upstreamObjectsCount,
            isDisabled: isDisabled || isColumnLevelLineage,
            onClick: () => handleChangeLineage(handleCloseAllUpstreamRef),
            tooltipMessage: closeTooltipMessage,
          },
        ],
      },
    ];
  };

  const getDownstreamOptions = () => {
    const isDisabled = downstreamObjectsCount === 0;
    const tooltipMessage = isDisabled ? 'No downstream available' : '';

    const closeTooltipMessage =
      isColumnLevelLineage && !isDisabled
        ? 'Only available in table-level lineage'
        : tooltipMessage;

    return [
      {
        options: [
          {
            ...OPEN_ALL_DOWNSTREAM_OPTION,
            count: downstreamObjectsCount,
            isDisabled,
            onClick: () => handleChangeLineage(handleOpenAllDownstreamRef),
            tooltipMessage,
          },
          {
            ...CLOSE_ALL_DOWNSTREAM_OPTION,
            count: downstreamObjectsCount,
            isDisabled: isDisabled || isColumnLevelLineage,
            onClick: () => handleChangeLineage(closeAllDownstreamRef),
            tooltipMessage: closeTooltipMessage,
          },
        ],
      },
    ];
  };

  const menuOptions: OptionsMenuProps['sections'] = [];
  [
    ...getSqlOption(),
    { options: [{ ...TRIGGER_COLUMN_SEARCH_OPTION, onClick: triggerColumnSearch }] },
    ...getUpstreamOptions(),
    ...getDownstreamOptions(),
  ].forEach((section, id) => {
    if (disableSearch && section.options[0].id === TRIGGER_COLUMN_SEARCH_OPTION.id) {
      return;
    }

    menuOptions.push({ ...section, id: String(id) });
  });

  return (
    <OptionsButton
      isAnchorVisible={show}
      isOpen={isOpen}
      optionsSections={menuOptions}
      setIsOpen={setIsOpen}
    />
  );
};

export default TableNodeOptions;
