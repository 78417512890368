import React from 'react';
import { DataTypesConfig } from '@configs/dataTypes/types';
import { IMPACT_SCORE_DESCRIPTION } from '@constants';
import { isUndefined } from 'lodash';
import noop from 'lodash/noop';

import Box from '@components/Box';
import DateTime from '@components/DateTime';
import ImpactScore from '@components/ImpactScore';
import LinksTo from '@components/SidebarMetadataObjectDetails/LinksTo';
import LoadingStatus from '@components/SidebarMetadataObjectDetails/LoadingStatus';
import Status from '@components/Status';
import { POPULARITY_DESCRIPTION } from '@components/Table/Cells/PopularityCell/PopularityCellHeader';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import theme from '@styles/theme';
import titlelize from '@utils/titlelize';

import OwnerSelect from '../OwnerSelect';

import { StyledDetailsListItemValue } from './DetailsSection.styles';
import { ConfigObject, ConfigType } from './DetailsSection.types';
import DbtLinksTo from './fields/DbtLinksTo';
import Jobs from './fields/Jobs';
import OwnerItem from './fields/OwnerItem';
import PopularityItem from './fields/PopularityItem';
import TableSizeItem from './fields/TableSizeItem';

export const disabledDetailsV1ObjectTypes = ['documents', 'metrics', 'tags', 'terms'];

export const FIELDS: Record<string, ConfigObject> = {
  businessOwner: {
    hidden: (item) => item.businessOwner === undefined,
    label: 'Business Owner',
    renderValue: (item, options) => (
      <OwnerSelect
        hasEditPermissions={options.hasEditPermissions}
        items={[item]}
        itemsType={item.itemsType ?? 'tables'}
        owner={item.businessOwner?.obj}
        ownerUpdateType="business"
        reloadData={options.reloadData}
      />
    ),
  },
  connection: {
    hidden: (item) => !item.connections,
    label: 'Connection',
    renderValue: (item) => (
      <Box gap={0.25}>
        {item.connections?.map((connection) => (
          <Box key={connection.name} alignItems="center" as="span" compDisplay="flex" gap={0.5}>
            {connection?.icon && <Icon name={connection?.icon} />}
            {connection?.name}
          </Box>
        ))}
      </Box>
    ),
  },
  connectionMethod: {
    hidden: (item) => !item?.formattedConnectionMethod,
    label: 'Type',
    renderValue: (item) => (
      <StyledDetailsListItemValue>
        {'formattedConnectionMethod' in item && item.formattedConnectionMethod}
      </StyledDetailsListItemValue>
    ),
  },
  created: {
    hidden: (item) => !item.created && !item.createdAt,
    label: 'Created',
    renderValue: (item) => <DateTime datetime={item.created ?? item.createdAt} />,
  },
  createdBy: {
    hidden: (item) => !item.owner && !item.dsuserCreatedBy,
    label: 'Created by',
    renderValue: (item) => (
      <OwnerItem
        hasEditPermissions={false}
        onEdit={noop}
        owner={item.owner ?? item.dsuserCreatedBy}
      />
    ),
  },
  dbtLinksTo: {
    customRenderSection: (item) => <DbtLinksTo guid={item.guid} />,
    hidden: (item) => !item.guid,
  },
  extraIsCustom: {
    hidden: (item) => isUndefined(item?.extra?.isCustom),
    label: 'Type',
    renderValue: (item) => (
      <StyledDetailsListItemValue>
        {item?.extra?.isCustom ? 'Custom Object' : 'Standard Object'}
      </StyledDetailsListItemValue>
    ),
  },
  extraLabel: {
    hidden: (item) => !item?.extra?.label,
    label: 'Label',
    renderValue: (item) => (
      <StyledDetailsListItemValue>{item?.extra?.label}</StyledDetailsListItemValue>
    ),
  },
  impactScore: {
    hidden: () => false,
    info: (
      <Tooltip content={IMPACT_SCORE_DESCRIPTION}>
        <Icon color={theme.colors.gray[500]} name="info-outline" size="16px" />
      </Tooltip>
    ),
    label: 'Impact Score',
    renderValue: (item) => (
      <ImpactScore
        impactedValue={item.downstreamObjectsCounts?.impactedObjectCount}
        value={item.downstreamObjectsCounts?.impactScore}
      />
    ),
  },
  jobs: {
    customRenderSection: (item) => <Jobs guid={item.guid} />,
    hidden: (item) => !item.guid,
  },
  language: {
    hidden: (item) => !item.language,
    label: 'Language',
    renderValue: (item) => item.language,
  },
  lastRefreshed: {
    hidden: (item) => !item.lastRefreshed,
    label: 'Last Refreshed',
    renderValue: (item) => <DateTime datetime={item.lastRefreshed} />,
  },
  lastRun: {
    hidden: (item) => !item.lastRun,
    label: 'Last Run',
    renderValue: (item) => <DateTime datetime={item.lastRun} />,
  },
  linksTo: {
    hidden: (item) => !item.guid || !item.showLinksTo,
    label: 'Links to',
    renderValue: (item) => <LinksTo guid={item.guid} linksToObjects={item.linksToObjects} />,
  },
  loadingStatus: {
    hidden: (item) => !item.loadingStatus,
    label: 'Loading Status',
    renderValue: (item) => (
      <LoadingStatus status={item.loadingStatus?.status} text={item.loadingStatus?.text} />
    ),
  },
  location: {
    hidden: (item) => !item?.cloudObject?.location,
    label: 'Location',
    renderValue: (item) => (
      <StyledDetailsListItemValue>{item?.cloudObject?.location}</StyledDetailsListItemValue>
    ),
  },
  materialization: {
    hidden: (item) => !item.materialization,
    label: 'Materialization',
    renderValue: (item) => item.materialization,
  },
  ownedBy: {
    hidden: (item) => item === undefined,
    label: 'Owner',
    renderValue: (item) => (
      <OwnerItem hasEditPermissions={false} onEdit={noop} owner={item.dsuserOwnedBy} />
    ),
  },
  popularity: {
    hidden: () => false,
    info: (
      <Tooltip content={POPULARITY_DESCRIPTION}>
        <Icon color={theme.colors.gray[500]} name="info-outline" size="16px" />
      </Tooltip>
    ),
    label: 'Popularity',
    renderValue: (item) => <PopularityItem dataType={item.dataType} popularity={item.popularity} />,
  },
  rowCount: {
    hidden: (item) => !item.rowCount,
    label: 'Row Count',
    renderValue: (item) => <TableSizeItem rowCount={item?.rowCount} />,
  },
  sourceFileType: {
    hidden: (item) => !item?.cloudObject?.format,
    label: 'Source File Type',
    renderValue: (item) => (
      <StyledDetailsListItemValue>
        {item?.cloudObject?.format.toUpperCase()}
      </StyledDetailsListItemValue>
    ),
  },
  tableSize: {
    hidden: (item) => !item.rowCount && !item.bytes,
    label: 'Table Size',
    renderValue: (item) => <TableSizeItem bytes={item?.bytes} rowCount={item?.rowCount} />,
  },
  technicalOwner: {
    hidden: (item) => item.technicalOwner === undefined,
    label: 'Technical Owner',
    renderValue: (item, options) => (
      <OwnerSelect
        hasEditPermissions={options.hasEditPermissions}
        items={[item]}
        itemsType={item.itemsType ?? 'tables'}
        owner={item.technicalOwner?.obj}
        ownerUpdateType="technical"
        reloadData={options.reloadData}
      />
    ),
  },
  totalObjects: {
    hidden: (item) => !item?.cloudObject?.objectsCount,
    label: 'Total Objects',
    renderValue: (item) => (
      <StyledDetailsListItemValue>{item?.cloudObject?.objectsCount}</StyledDetailsListItemValue>
    ),
  },
  type: {
    hidden: (item) => !item?.dataTypes,
    label: 'Type',
    renderValue: (item) => (
      <StyledDetailsListItemValue>
        {titlelize(item?.dataTypes?.dataType)}
      </StyledDetailsListItemValue>
    ),
  },
  updatedAt: {
    hidden: (item) => !item.updatedAt,
    label: 'Last Modified',
    renderValue: (item) => <DateTime datetime={item.updatedAt} />,
  },
  views: {
    hidden: (item: any) => item?.viewCount,
    label: 'Views',
    renderValue: (item: any) => (
      <StyledDetailsListItemValue>{item?.viewCount}</StyledDetailsListItemValue>
    ),
  },
};

const DEFAULT_BI_FIELDS = [
  FIELDS.createdBy,
  FIELDS.popularity,
  FIELDS.impactScore,
  FIELDS.loadingStatus,
  FIELDS.created,
  FIELDS.lastRun,
  FIELDS.updatedAt,
];

const BI_FIELDS_WITHOUT_IMPACT_SCORE = DEFAULT_BI_FIELDS.filter(
  (field) => field !== FIELDS.impactScore,
);

const WAREHOUSE_TABLE_FIELDS = [
  FIELDS.createdBy,
  FIELDS.businessOwner,
  FIELDS.technicalOwner,
  FIELDS.materialization,
  FIELDS.popularity,
  FIELDS.impactScore,
  FIELDS.tableSize,
  FIELDS.created,
  FIELDS.extraLabel,
  FIELDS.extraIsCustom,
  FIELDS.lastRefreshed,
  FIELDS.totalObjects,
  FIELDS.dbtLinksTo,
  FIELDS.sourceFileType,
  FIELDS.location,
  FIELDS.jobs,
];

const DATASET_FIELDS = [
  FIELDS.createdBy,
  FIELDS.popularity,
  FIELDS.impactScore,
  FIELDS.created,
  FIELDS.updatedAt,
];

const DETAIL_SECTION_FIELD_CONFIG_BY_OBJECT: ConfigType = {
  collection: [FIELDS.businessOwner, FIELDS.technicalOwner],
  dashboard: DEFAULT_BI_FIELDS,
  default: [],
  table: WAREHOUSE_TABLE_FIELDS,
};

const THOUGHTSPOT_DASHBOARDS_MAIN_FIELDS = [
  FIELDS.createdBy,
  FIELDS.created,
  FIELDS.updatedAt,
  FIELDS.views,
  FIELDS.type,
];

export const DETAIL_SECTION_FIELD_CONFIG: DataTypesConfig<Array<ConfigObject | undefined>> = {
  airflow: {
    default: [
      FIELDS.ownedBy,
      FIELDS.created,
      FIELDS.lastRun,
      FIELDS.updatedAt,
      {
        hidden: (item) => !item.lastRunStatus,
        label: 'Last Run Status',
        renderValue: (item) => (
          <Status status={item.formattedLastRunStatus}>{item.lastRunStatus}</Status>
        ),
      },
      {
        hidden: (item) => !item.lastRunDuration,
        label: 'Last Run Duration',
        renderValue: (item) => item.lastRunDuration,
      },
      {
        hidden: (item) => !item.schedule,
        label: 'Schedule',
        renderValue: (item) => item.schedule,
      },
    ],
  },
  data_studio: {
    dashboard: {
      default: BI_FIELDS_WITHOUT_IMPACT_SCORE,
    },
    default: [],
  },
  databricks: {
    default: [],
    notebook: { default: [FIELDS.language, FIELDS.created, FIELDS.updatedAt, FIELDS.lastRun] },
    table: { default: WAREHOUSE_TABLE_FIELDS },
  },
  dbt: {
    default: [],
    table: {
      default: [
        FIELDS.createdBy,
        FIELDS.businessOwner,
        FIELDS.technicalOwner,
        FIELDS.materialization,
        FIELDS.popularity,
        FIELDS.impactScore,
        FIELDS.tableSize,
        FIELDS.created,
        FIELDS.lastRefreshed,
        FIELDS.totalObjects,
        FIELDS.linksTo,
        FIELDS.sourceFileType,
        FIELDS.location,
      ],
    },
  },
  looker: {
    dashboard: {
      default: BI_FIELDS_WITHOUT_IMPACT_SCORE,
    },
    default: [],
    explore: {
      default: [FIELDS.businessOwner, FIELDS.technicalOwner, FIELDS.popularity, FIELDS.impactScore],
    },
    lookmlview: {
      default: [
        FIELDS.businessOwner,
        FIELDS.technicalOwner,
        FIELDS.popularity,
        FIELDS.impactScore,
        FIELDS.linksTo,
      ],
    },
  },
  metabase: {
    dashboard: {
      default: BI_FIELDS_WITHOUT_IMPACT_SCORE,
    },
    default: [],
  },
  mode: {
    dashboard: {
      default: [],
      report: BI_FIELDS_WITHOUT_IMPACT_SCORE,
    },
    default: [],
  },
  power_bi: {
    dashboard: { default: BI_FIELDS_WITHOUT_IMPACT_SCORE },
    default: [],
    powerbidataset: { default: DATASET_FIELDS },
  },
  quicksight: {
    bidataset: { default: DATASET_FIELDS },
    dashboard: {
      default: [
        FIELDS.createdBy,
        FIELDS.popularity,
        FIELDS.created,
        FIELDS.lastRun,
        FIELDS.updatedAt,
        FIELDS.ownedBy,
      ],
    },
    default: [],
  },
  sigma: {
    dashboard: {
      default: [],
      sigma_dashboard: BI_FIELDS_WITHOUT_IMPACT_SCORE,
    },
    default: [],
  },
  tableau: {
    dashboard: {
      default: [
        FIELDS.createdBy,
        FIELDS.popularity,
        FIELDS.impactScore,
        FIELDS.loadingStatus,
        FIELDS.created,
        FIELDS.lastRun,
        FIELDS.updatedAt,
      ],
      workbook: [
        FIELDS.createdBy,
        FIELDS.popularity,
        FIELDS.loadingStatus,
        FIELDS.created,
        FIELDS.lastRun,
        FIELDS.updatedAt,
      ],
    },
    default: [],
    tableaudatasource: {
      default: [
        FIELDS.connectionMethod,
        FIELDS.connection,
        FIELDS.popularity,
        FIELDS.impactScore,
        FIELDS.updatedAt,
      ],
    },
  },
  thoughtspot: {
    dashboard: {
      answer: THOUGHTSPOT_DASHBOARDS_MAIN_FIELDS,
      default: [FIELDS.impactScore, ...THOUGHTSPOT_DASHBOARDS_MAIN_FIELDS],
      liveboard: THOUGHTSPOT_DASHBOARDS_MAIN_FIELDS,
    },
    default: [],
    thoughtspottable: {
      default: [
        FIELDS.impactScore,
        FIELDS.createdBy,
        FIELDS.created,
        FIELDS.updatedAt,
        FIELDS.type,
      ],
    },
  },
};

export default DETAIL_SECTION_FIELD_CONFIG_BY_OBJECT;
