import React from 'react';

import { useFetchTagChild } from '@api/tags';
import Box from '@components/Box';
import TagGroup from '@components/TagGroup';

interface TagCardFooterProps {
  guid: string;
}

const TagCardFooter: React.FC<TagCardFooterProps> = ({ guid }) => {
  const { data } = useFetchTagChild(guid);

  if (!data?.results?.length) {
    return null;
  }

  return (
    <Box>
      <TagGroup tag={data?.results[0]} totalTags={data?.count} />
    </Box>
  );
};

export default TagCardFooter;
