import React, { useState } from 'react';
import { Cell } from 'react-table';

import AnalyticsActivityModel from '@api/analytics/AnalyticsActivityModel';
import ActivityMessage from '@components/ActivityMessage';
import DateTime from '@components/DateTime';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import TextCell from '@components/Table/Cells/TextCell';
import UserCell from '@components/Table/Cells/UserCell';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import { FilterServiceInterface } from '@utils/filters';

interface ActivityLogTableProps {
  filterService: FilterServiceInterface;
  loading: boolean;
  rows?: AnalyticsActivityModel[];
  totalPages?: number;
}

const ActivityLogTable: React.FC<ActivityLogTableProps> = ({
  filterService,
  loading,
  rows = [],
  totalPages,
}) => {
  const [searchVisible, setSearchVisible] = useState(false);

  const columns: ColumnConfig<AnalyticsActivityModel>[] = [
    {
      Header: SearchHeader,
      disableFilters: true,
      disableResizing: true,
      disableSortBy: true,
      id: 'search',
      width: 32,
    },
    {
      Cell: (props: Cell<AnalyticsActivityModel>) => {
        const { row } = props;
        return <UserCell {...props} user={row.original.user} />;
      },
      Header: 'User',
      accessor: (d) => d.user?.fullName,
      disableHiding: true,
      id: 'user',
      width: '20%',
    },
    {
      Cell: (props: Cell<AnalyticsActivityModel>) => {
        const { row } = props;
        return <TextCell {...props} text={row.original.activityType} />;
      },
      Header: 'Activity Type',
      accessor: (d) => d.activityType,
      disableHiding: true,
      id: 'type',
      width: '10%',
    },
    {
      Cell: (props: Cell<AnalyticsActivityModel>) => {
        const {
          column,
          row: { original: activity },
        } = props;

        return <ActivityMessage globalFilter={column.filterValue} message={activity.message} />;
      },
      Header: 'Event Description',
      accessor: (d) => d.targetName,
      disableHiding: true,
      disableSortBy: true,
      id: 'event',
      width: '50%',
    },
    {
      Cell: (props: Cell<AnalyticsActivityModel>) => {
        const { row } = props;
        return <DateTime {...props} datetime={row.original.performedOn} format="fullDateFormat" />;
      },
      Header: 'Date',
      accessor: (d) => d.performedOn?.toDate(),
      disableFilters: true,
      disableHiding: true,
      id: 'date',
      width: '20%',
    },
  ];

  return (
    <TableStyled>
      <Table
        basic="very"
        changePage={filterService?.changePage}
        className="table-full"
        columns={columns}
        compact
        data={rows}
        disableColumnFiltering
        disableRowSelect
        initialState={{
          pageIndex: filterService ? filterService!.filter.page! - 1 : 0,
          sortBy: [
            {
              desc: true,
              id: 'date',
            },
          ],
        }}
        loading={loading}
        manualFilters
        manualPagination
        manualSortBy
        setFilters={filterService.search}
        setSortBy={filterService.sort}
        showFilter={searchVisible}
        sortable
        stickyHeader
        toggleFilter={() => setSearchVisible((prev) => !prev)}
        totalPages={totalPages}
        unstackable
      />
    </TableStyled>
  );
};

export default ActivityLogTable;
