import React from 'react';

import Alert from '@components/Alert';
import PreviewMarkdown from '@components/PreviewMarkdown';

export const UNEXPECTED_ERROR_DESCRIPTION = 'An unexpected error occurred. Please try again later.';
export const UNEXPECTED_ERROR_TITLE = 'Data Invalid.';

interface ErrorCredentialAlertProps {
  error: { description: string; title: string } | string | [string];
}

const ErrorCredentialAlert: React.FC<ErrorCredentialAlertProps> = ({ error }) => {
  if (error === null || error === undefined) return null;

  let title;
  let description;

  if (Array.isArray(error)) {
    description = error.join(', ');
  } else if (typeof error === 'object') {
    title = error?.title;
    description = error?.description;
  } else if (typeof error === 'string' && error !== '') {
    description = error;
  }

  return (
    <Alert title={title || UNEXPECTED_ERROR_TITLE} type="error">
      {description ? (
        <PreviewMarkdown fontSize="body1" markdown={description} minHeight="unset" />
      ) : (
        UNEXPECTED_ERROR_DESCRIPTION
      )}
    </Alert>
  );
};

export default ErrorCredentialAlert;
