import { folderCacheKeys } from '@api/bifolder/bifolder';
import transformHierarchyResponse from '@api/dataSources/transformHierarchyResponse';
import { exploresCacheKeys } from '@api/explores/explores';
import invalidateCache from '@api/invalidateCache';
import type { KeyPair } from '@api/openapi.generated';
import paginatedTransform from '@api/paginatedTransform';
import rawTransform from '@api/rawTransform';
import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import type { UseMutationOptions } from '@hooks/useMutation';
import useMutation from '@hooks/useMutation';
import fetchClient from '@lib/fetchClient';
import {
  BIFoldersHierarchyResponseData,
  toBIFoldersHierarchyResponse,
} from '@models/BIFoldersHierarchyResponse';
import type { DataSourceCredentials } from '@models/DataSourceCredentials';
import { DataSourceModel } from '@models/DataSourceModel';
import { HierarchyResponseData } from '@models/HierarchyResponse';
import { PaginatedResponse } from '@models/PaginatedResponse';
import { SnowflakeTagSyncModel } from '@models/SnowflakeTagSyncModel';
import camelize from '@utils/camelize';
import { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';

import * as cacheKeys from './cacheKeys';

export const useFetchDataSourcesHierarchy = (options?: UseFetchOptions<HierarchyResponseData>) => {
  return useFetch<HierarchyResponseData>({
    ...options,
    queryKey: [...cacheKeys.hierarchy, options?.params],
    /** provide a custom transform function as immer.js doesn't work with class instances */
    select: transformHierarchyResponse,
    url: `/data-sources/hierarchy/`,
  });
};

export const useDeleteDataSource = (id: string, options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    method: 'DELETE',
    onSuccess: (data, variables, context) => {
      invalidateCache((keys) => [keys.dashboards.all, keys.tables.all]);
      fetchClient.invalidateQueries(folderCacheKeys.folders);
      fetchClient.invalidateQueries(exploresCacheKeys.explores);
      options?.onSuccess?.(data, variables, context);
    },
    url: `/data-sources/${id}/`,
  });
};

export interface DataSourcesMutatePayload {
  credentials: DataSourceCredentials;
  guid?: string;
  name?: string;
  type: string;
}

type UsePostDataSourceOptions = UseMutationOptions<
  DataSourceModel,
  EnhancedErrorResult,
  DataSourcesMutatePayload
>;

export const usePostDataSource = (options?: UsePostDataSourceOptions) => {
  return useMutation({
    ...options,
    method: 'POST',
    onSuccess: (data, variables, context) => {
      const transformedData: DataSourceModel = rawTransform(DataSourceModel)(data);
      options?.onSuccess?.(transformedData, variables, context);
    },
    url: `/data-sources/`,
  });
};

export const usePatchDataSource = (id: string, options?: UsePostDataSourceOptions) => {
  return useMutation({
    ...options,
    method: 'PATCH',
    onSuccess: (data, variables, context) => {
      const transformedData: DataSourceModel = rawTransform(DataSourceModel)(data);
      options?.onSuccess?.(transformedData, variables, context);
    },
    url: `/data-sources/${id}/`,
  });
};

export const fetchDataSourcesSelect = paginatedTransform(DataSourceModel);

export const useFetchDataSources = (
  options?: UseFetchOptions<PaginatedResponse<DataSourceModel>>,
) => {
  return useFetch<PaginatedResponse<DataSourceModel>>({
    ...options,
    queryKey: [...cacheKeys.all, options?.params],
    select: fetchDataSourcesSelect,
    url: `/data-sources/`,
  });
};

export const fetchDataSourcesInfoSelect = paginatedTransform(DataSourceModel);
export const useFetchDataSourcesInfo = (
  options?: UseFetchOptions<PaginatedResponse<DataSourceModel>>,
) => {
  return useFetch<PaginatedResponse<DataSourceModel>>({
    ...options,
    queryKey: cacheKeys.dataSourcesInfo,
    select: fetchDataSourcesInfoSelect,
    url: `/data-sources/info/`,
  });
};

export const usePostIncompleteDataSource = (options?: UseMutationOptions<DataSourceModel>) => {
  return useMutation<DataSourceModel>({
    ...options,
    method: 'POST',
    onSuccess: (data, variables, context) => {
      const transformedData = rawTransform(DataSourceModel)(data);
      options?.onSuccess?.(transformedData, variables, context);
    },
    retry: (_failureCount: number, { status }: EnhancedErrorResult) =>
      status ? ![400, 404, 403].includes(status) : true,
    url: `/data-sources/incomplete/`,
  });
};

export const usePatchIncompleteDataSource = (
  id: string,
  options?: UseMutationOptions<DataSourceModel>,
) => {
  return useMutation<DataSourceModel>({
    ...options,
    method: 'PATCH',
    url: `/data-sources/incomplete/${id}/`,
  });
};

const fetchDataSourceBIFoldersHierarchySelect = (data: any) => toBIFoldersHierarchyResponse(data);
export const useFetchDataSourceBIFoldersHierarchy = (
  id: string,
  options?: UseFetchOptions<BIFoldersHierarchyResponseData>,
) => {
  return useFetch<BIFoldersHierarchyResponseData>({
    ...options,
    queryKey: cacheKeys.biFoldersHierarchy(id),
    select: fetchDataSourceBIFoldersHierarchySelect,
    url: `/data-sources/bi-folders-hierarchy/${id}/`,
  });
};

export const usePostDataSourceBIFoldersHierarchyConfig = (
  guid: string,
  options?: UseMutationOptions,
) => {
  return useMutation({
    ...options,
    method: 'PATCH',
    onSuccess: (data, variables, context) => {
      fetchClient.invalidateQueries(cacheKeys.biFoldersHierarchy(guid));
      options?.onSuccess?.(data, variables, context);
    },
    url: `/data-sources/bi-folders-hierarchy/${guid}/`,
  });
};

const fetchDataSourceTagSyncSelect = rawTransform(SnowflakeTagSyncModel);
export const useFetchDataSourceTagSync = (
  id: string,
  options?: UseFetchOptions<SnowflakeTagSyncModel>,
) => {
  return useFetch<SnowflakeTagSyncModel>({
    ...options,
    queryKey: cacheKeys.dataSourceTagSync(id),
    select: fetchDataSourceTagSyncSelect,
    url: `/data-sources/${id}/tag-sync/`,
  });
};

export const usePatchDataSourceTagSync = (
  id: string,
  options?: UseMutationOptions<SnowflakeTagSyncModel>,
) => {
  return useMutation<SnowflakeTagSyncModel>({
    ...options,
    method: 'PATCH',
    onSuccess: (data, variables, context) => {
      const transformedData = fetchDataSourceTagSyncSelect(data);
      options?.onSuccess?.(transformedData, variables, context);
    },
    url: `/data-sources/${id}/tag-sync/`,
  });
};

const fetchDataSourceKeygenSelect = (data: any) => camelize(data)!;
export const useFetchDataSourceKeygen = (
  options?: UseFetchOptions<ConvertKeysToCamelCase<KeyPair>>,
) => {
  return useFetch<ConvertKeysToCamelCase<KeyPair>>({
    ...options,
    queryKey: [...cacheKeys.keygen, options?.params],
    select: fetchDataSourceKeygenSelect,
    url: `/data-sources/keygen/`,
  });
};
