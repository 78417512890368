import { Expose } from 'class-transformer';

export class SnowflakeTagSyncModel {
  @Expose({ name: 'snowflake_tag_sync_enabled' })
  snowflakeTagSyncEnabled!: boolean;

  @Expose({ name: 'snowflake_tag_sync_role_name' })
  snowflakeTagSyncRoleName!: string;

  @Expose({ name: 'snowflake_tag_sync_db_name' })
  snowflakeTagSyncDbName!: string;

  @Expose({ name: 'snowflake_tag_sync_schema_name' })
  snowflakeTagSyncSchemaName!: string;

  @Expose({ name: 'snowflake_tag_sync_error_message' })
  snowflakeTagSyncErrorMessage!: string;

  @Expose({ name: 'snowflake_tag_sync_status' })
  snowflakeTagSyncStatus!: 'DONE' | 'UNK' | 'ERROR' | 'PROG';
}
