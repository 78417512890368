import DataTypesModel from '@models/DataTypesModel';

import { NODE_COLORS } from './config';
import { NodeColor } from './types';

export const getColorsBasedOnDataTypes = (dataTypes?: DataTypesModel): NodeColor => {
  return (
    NODE_COLORS[dataTypes?.dataSourceType!] ?? {
      primary: '#000',
      secondary: '#fff',
    }
  );
};
