export const all = ['datasources'];

export const biFoldersHierarchy = (id: string) => [...all, id, 'BiFoldersHierarchy'];

export const dataSourceTagSync = (id: string) => [...all, id, 'tagSync'];

export const dataSourcesInfo = [...all, 'info'];

export const hierarchy = [...all, 'hierarchy'];

export const keygen = [...all, 'keygen'];
