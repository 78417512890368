import React from 'react';

import Box from '@components/Box/Box.styles';
import Breadcrumbs, { BreadcrumbItem } from '@components/Breadcrumbs';
import IconButton from '@components/IconButton';
import SourceColumnsTabRow from '@components/TabContent/SourceColumnsTab/SourceColumnsTabRow';
import PopularityCell from '@components/Table/Cells/PopularityCell';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import DataTypesModel from '@models/DataTypesModel';
import { PopularityModel } from '@models/PopularityModel';

interface SourceColumnsTabSchemaRowProps {
  breadcrumbs?: BreadcrumbItem[];
  dataTypes?: DataTypesModel;
  isOpen: boolean;
  isToggleDisabled: boolean;
  onToggle: () => void;
  popularity?: PopularityModel;
}

const SourceColumnsTabSchemaRow: React.FC<SourceColumnsTabSchemaRowProps> = ({
  breadcrumbs,
  dataTypes,
  isOpen,
  isToggleDisabled,
  onToggle,
  popularity,
}) => {
  return (
    <SourceColumnsTabRow
      firstColumn={
        <Box alignItems="center" compDisplay="flex">
          <Box alignItems="center" compDisplay="flex" flexShrink={0} mr={0.5}>
            <IconButton
              color="transparent"
              disabled={isToggleDisabled}
              onClick={onToggle}
              title="Toggle"
            >
              <Icon color="#0C3476" name={isOpen ? 'down' : 'right'} size="16px" />
            </IconButton>
            <Tooltip content={dataTypes?.tooltips.dataSource}>
              <Icon name={dataTypes?.icons.dataSource!} size="16px" />
            </Tooltip>
            <Tooltip content={dataTypes?.tooltips.dataType}>
              <Icon name={dataTypes?.icons.dataType!} size="16px" />
            </Tooltip>
          </Box>
          <Breadcrumbs
            color="inherit"
            fontSize="inherit"
            fontWeight="normal"
            items={breadcrumbs}
            showTrailingSlash={false}
          />
        </Box>
      }
      secondColumn={<PopularityCell popularity={popularity} />}
    />
  );
};

export default SourceColumnsTabSchemaRow;
