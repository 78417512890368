import { DataTypesConfig } from '@configs/dataTypes/types';

export interface TableNodeSqlConfig {
  hasSql: boolean;
  shouldShowSidebar?: boolean;
}

export const NODE_TYPE_CONFIG: DataTypesConfig<TableNodeSqlConfig> = {
  tableau: {
    default: { hasSql: false, shouldShowSidebar: false },
    tableautable: {
      'custom-sql': { hasSql: true, shouldShowSidebar: false },
      default: { hasSql: false, shouldShowSidebar: false },
    },
  },
};

export const DEFAULT_TABLE_NODE_SQL_CONFIG: TableNodeSqlConfig = {
  hasSql: true,
  shouldShowSidebar: true,
};

export const DEFAULT_BI_TABLE_NODE_SQL_CONFIG: TableNodeSqlConfig = {
  hasSql: false,
  shouldShowSidebar: false,
};
