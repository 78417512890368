import styled from '@emotion/styled';

import Box from '@components/Box';
import rootTheme from '@styles/theme';

export const StyledActivityMessage = styled(Box)`
  color: ${rootTheme.colors.text};
  font-size: ${rootTheme.typography.fontSizes.body1};
  line-height: ${rootTheme.typography.lineHeights.md};
  display: inline-block;
  margin: auto 0;

  & * {
    vertical-align: middle;
  }
`;

StyledActivityMessage.defaultProps = {
  as: 'span',
  verticalAlign: 'middle',
  whiteSpace: 'break-spaces',
};

export const StyledActivityMessageTargetLink = styled(Box)`
  display: inline;
  font-weight: ${({ theme }) => theme.typography.fontWeights.semibold};

  :hover {
    color: inherit;
  }
`;

StyledActivityMessageTargetLink.defaultProps = {
  noDefault: true,
};
