import { useCallback } from 'react';
import { BaseRange, Editor, Node, NodeEntry, Text, Transforms } from 'slate';

import { toggleStyle } from '../helpers/inlineStyleHelpers';
import { handleArrowDown, handleArrowRight } from '../helpers/keyBindingHandlers';
import { getSelectedLink } from '../helpers/linkHelpers';
import { decreaseListLevel, increaseListLevel } from '../helpers/listHelpers';
import renderElement from '../RenderElements/RenderElement';
import renderLeaf from '../RenderElements/renderLeaf';

const keyBindings = {
  onKeyDown: (editor: Editor, event: KeyboardEvent, onCancel?: () => void) => {
    event.stopPropagation();
    const hasModifier = event.metaKey || event.ctrlKey;

    if (hasModifier) {
      if (event.key === 'b') {
        toggleStyle(editor, 'bold');
        return;
      }
      if (event.key === 'i') {
        toggleStyle(editor, 'italic');
        return;
      }
      if (event.key === 'u') {
        toggleStyle(editor, 'underline');
      }
      if (event.key === 'z') {
        event.preventDefault();

        if ((event.metaKey || event.ctrlKey) && !event.shiftKey) {
          editor.undo();
        }

        if (event.metaKey && event.shiftKey) {
          editor.redo();
        }
      }

      if (event.key === 'y' && event.ctrlKey) {
        event.preventDefault();
        editor.redo();
      }
    }

    if (event.key === ' ') {
      const link = getSelectedLink(editor);
      if (link) {
        Transforms.splitNodes(editor);
        Transforms.move(editor, { distance: 1, edge: 'end', unit: 'character' });
        Transforms.delete(editor);
      }
    }

    if (event.key === 'Escape') {
      onCancel?.();
    }

    if (event.key === 'ArrowDown') {
      handleArrowDown(editor);
    }

    if (event.key === 'ArrowRight') {
      handleArrowRight(editor);
    }

    if (event.key === 'Tab') {
      if (event.shiftKey) {
        decreaseListLevel(editor, event);
      } else {
        increaseListLevel(editor, event);
      }
    }
  },
};

const handleDecorate = ([node, path]: NodeEntry<Node>, textToHighlight?: string) => {
  const ranges: ({ highlight?: boolean } & BaseRange)[] = [];

  // Logic related to highlighting search text in the editor.
  if (textToHighlight && Text.isText(node)) {
    const { text } = node;
    const lowerText = text.toLocaleLowerCase();
    const lowerTextToHighlight = textToHighlight.toLocaleLowerCase();
    const parts = lowerText.split(lowerTextToHighlight);
    let offset = 0;

    parts.forEach((part, i) => {
      if (i !== 0) {
        ranges.push({
          anchor: { offset: offset - lowerTextToHighlight.length, path },
          focus: { offset, path },
          highlight: true,
        });
      }

      offset = offset + part.length + lowerTextToHighlight.length;
    });
  }

  return ranges;
};

/**
 * Hook for configuring the richtext editor.
 * Here we can add key handlers and custom decorations.
 */

const useEditorConfig = (editor: Editor, textToHighlight?: string, onCancel?: () => void) => {
  const onKeyDown = useCallback(
    (event) => keyBindings.onKeyDown(editor, event, onCancel),
    [editor, onCancel],
  );

  const decorate = useCallback(
    (event) => handleDecorate(event, textToHighlight),
    [textToHighlight],
  );

  return { decorate, onKeyDown, renderElement, renderLeaf };
};

export default useEditorConfig;
