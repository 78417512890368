import moment from 'moment';

import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import { Collection } from '@api/openapi.generated';
import { TaggedItemModel } from '@api/tags/TaggedItemModel';
import { TeamModel } from '@api/teams/TeamModel';
import type { ObjectType } from '@api/types';
import { UserModel } from '@api/user/UserModel';
import { rawClassArrayMap, rawClassMap, rawMap } from '@api/utils';
import { breadcrumbsToList } from '@components/Breadcrumbs';
import isEmptyRichText from '@components/RichTextEditor/helpers/isEmptyRichText';
import mapDataTypesModel from '@models/DataTypesModel/DataTypesModel.v1';
import { Reference } from '@models/Reference';
import { urlFor } from '@utils/routing';

const objectType: ObjectType = 'collection';

const mapCollectionModel = (
  data: Omit<Partial<Collection>, 'guid' | 'name'> & { guid: string; name: string },
) => {
  const breadcrumbs = rawClassArrayMap(BreadcrumbModel, data?.breadcrumbs);
  const breadcrumbsList = breadcrumbsToList(breadcrumbs);

  if (data?.breadcrumbs) {
    breadcrumbsList?.unshift({
      label: 'Collections',
      url: '/collections',
    });
  }

  const dataTypes = mapDataTypesModel(data?.data_types);

  return {
    assetCount: data?.asset_count,
    breadcrumbList: breadcrumbsList,
    breadcrumbs,
    businessOwner: rawClassMap<Reference<UserModel | TeamModel>>(Reference, data?.business_owner),
    createdOn: rawMap(moment, data?.created_on),
    dataType: data?.data_types?.data_type,
    dataTypes,
    description: data?.description,
    editNameAllowed: true,
    guid: data.guid,
    icon: data?.icon ?? 'collection',
    name: data.name,
    objectType,
    parentGuid: data?.parent_guid,
    richtextDescription: isEmptyRichText(data?.richtext_description)
      ? ''
      : data?.richtext_description,
    routePath: urlFor({
      dataTypes,
      guid: data.guid,
      objectType,
    }),
    // @ts-ignore [sc-101183]
    taggedItems: rawClassArrayMap(TaggedItemModel, data?.tagged_items),
    technicalOwner: rawClassMap<Reference<UserModel | TeamModel>>(Reference, data?.technical_owner),
    updatedOn: rawMap(moment, data?.updated_on),
  };
};

export default mapCollectionModel;

mapCollectionModel.objectType = objectType;

export type CollectionModel = ReturnType<typeof mapCollectionModel>;
