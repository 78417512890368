import { Ancestor, Editor, Element, NodeEntry, Path, Range, Transforms } from 'slate';

import { createRow } from './createTable';

/**
 * Finds the insert point for a row inside a slate table.
 */
const getInsertLocation = (
  startNode: NodeEntry<Ancestor>,
  parent: NodeEntry<Ancestor>,
  direction: 'below' | 'above' = 'below',
) => {
  /**
   * If we want to insert above the current row, inserting at the current path will push current node down,
   *  effectively inserting above.
   */
  if (direction === 'above') {
    return startNode[1];
  }

  // If inserting below the table header we should insert into the first position of table body instead.
  if (Element.isElement(parent[0]) && parent[0].type === 'thead') {
    return [...Path.next(parent[1]), 0];
  }

  // If inserting below the current node we should insert at the next position in the path.
  const path = Array.from(startNode[1]);
  path[path.length - 1] += 1;
  return path;
};

const insertRow = (editor: Editor, direction: 'below' | 'above' = 'below') => {
  if (!editor.selection) return;

  // Find the row that selection is currently in.
  const row = Editor.above(editor, {
    match: (n) => Element.isElement(n) && n.type === 'tr',
  });

  const isCollapsed = Range.isCollapsed(editor.selection);

  if (row && isCollapsed) {
    const rowParent = Editor.parent(editor, row[1]);
    const insertLocation = getInsertLocation(row, rowParent, direction);

    // Get the number of columns needed for our new row by filtering current row for table cells.
    const columnCount = row[0].children.filter(
      (n) => Element.isElement(n) && (n.type === 'td' || n.type === 'th'),
    ).length;

    const isHeaderRow = Element.isElement(rowParent[0]) && rowParent[0].type === 'thead';
    const newRow = createRow(columnCount, isHeaderRow && direction === 'above');

    // Make sure there is a table body to insert nodes into. Could be missing if a table with only one row(header) was created.
    const body = Editor.next(editor, { at: rowParent[1] });
    if (!body) {
      Transforms.insertNodes(
        editor,
        { children: [{ text: '' }], type: 'tbody' },
        { at: Path.next(rowParent[1]) },
      );
    }

    // If inserting above the table header we should make the new row a table header and move current row to the table body.
    if (isHeaderRow && direction === 'above') {
      const bodyInsertLocation = [...Path.next(rowParent[1]), 0];
      // convert th cells to td cells before moving to the table body.
      row[0].children.forEach((child, i) => {
        Transforms.setNodes(editor, { type: 'td' }, { at: [...row[1], i] });
      });
      Transforms.moveNodes(editor, { at: row[1], to: bodyInsertLocation });
    }
    Transforms.insertNodes(editor, [newRow], { at: insertLocation });
  }
};

export default insertRow;
