import React, { useCallback, useState } from 'react';
import type { Cell } from 'react-table';

import type { DashboardModel } from '@api/dashboards/DashboardModel';
import DateTime from '@components/DateTime';
import DescriptionCell from '@components/Table/Cells/DescriptionCell';
import EditableTaggedItemCell from '@components/Table/Cells/EditableTaggedItemCell';
import FormattedNumberCell from '@components/Table/Cells/FormattedNumberCell';
import LinkedCell from '@components/Table/Cells/LinkedCell';
import PopularityCell from '@components/Table/Cells/PopularityCell';
import PopularityCellHeader from '@components/Table/Cells/PopularityCell/PopularityCellHeader';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import UserCell from '@components/Table/Cells/UserCell';
import type { TableProps } from '@components/Table/Table';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import Tooltip from '@components/Tooltip';
import type { FilterServiceInterface } from '@utils/filters';
import { FilterOptions } from '@utils/filters';

export enum ColumnKey {
  createdAt = 'createdAt',
  description = 'description',
  dsuser = 'dsuser',
  dsuserOwnedBy = 'dsuserOwnedBy',
  lastRun = 'lastRun',
  name = 'name',
  popularity = 'popularity',
  search = 'search',
  sourceTablesCount = 'sourceTablesCount',
  tags = 'tags',
  updatedAt = 'updatedAt',
}

type ColumnKeyType = keyof typeof ColumnKey;
const DEFAULT_VISIBLE_COLUMNS = [
  'search',
  'name',
  'description',
  'dsuser',
  'createdAt',
  'updatedAt',
  'lastRun',
  'tags',
  'sourceTablesCount',
  'popularity',
] as const;

const DEFAULT_HIDDEN_COLUMNS = Object.keys(ColumnKey).filter((str) => {
  const NON_HIDDEN_COLUMNS: PartialRecord<ColumnKey, true> = {
    description: true,
    dsuser: true,
    dsuserOwnedBy: true,
    name: true,
    popularity: true,
    search: true,
  };
  return !NON_HIDDEN_COLUMNS[str as ColumnKey];
});

export const DASHBOARD_TABLE_SEARCH_CONFIG: PartialRecord<ColumnKeyType, keyof FilterOptions> = {
  description: 'search_description',
  dsuser: 'search_dsuser_created_by',
  name: 'search_name',
  tags: 'search_tags',
  [ColumnKey.dsuserOwnedBy]: 'search_dsuser_owned_by',
};

export const DASHBOARD_TABLE_SORT_CONFIG: PartialRecord<ColumnKeyType, string> = {
  createdAt: 'dashboard_created_at',
  description: 'description',
  dsuser: 'dsuser_created_by',
  lastRun: 'last_run_at',
  name: 'name',
  popularity: 'popularity',
  sourceTablesCount: 'source_tables_count',
  updatedAt: 'dashboard_updated_at',
  [ColumnKey.dsuserOwnedBy]: 'dsuser_owned_by',
};

export interface DashboardsTableProps
  extends Pick<TableProps, 'disableRowSelect' | 'selectedRowIds'> {
  customColumProps?: PartialRecord<
    ColumnKeyType,
    { Header?: string; showDataSourceIcon?: boolean }
  >;
  data:
    | {
        count: number;
        results: DashboardModel[];
      }
    | undefined;
  filterService: FilterServiceInterface;
  hiddenColumns?: Array<ColumnKeyType>;
  isDataSourceEditable?: boolean;
  isLoading?: boolean;
  toggleAll?: (checked: boolean) => void;
  toggleItem?: (item: DashboardModel, checked: boolean) => void;
  visibleColumns?: Array<ColumnKeyType>;
}

const DashboardsTable: React.FC<DashboardsTableProps> = ({
  customColumProps,
  data,
  disableRowSelect,
  filterService,
  hiddenColumns = DEFAULT_HIDDEN_COLUMNS,
  isDataSourceEditable = false,
  isLoading,
  selectedRowIds,
  toggleAll,
  toggleItem,
  visibleColumns = DEFAULT_VISIBLE_COLUMNS,
}) => {
  const [isShowFilter, setShowFilter] = useState(false);
  const { changePage, filter, initialTableSortState, search, sort } = filterService;
  const toggleFilter = useCallback(() => setShowFilter((prev) => !prev), [setShowFilter]);

  const columns: ColumnConfig<DashboardModel>[] = React.useMemo(() => {
    const all: Record<ColumnKey, ColumnConfig<DashboardModel>> = {
      createdAt: {
        Cell: ({ row: { original } }: Cell<DashboardModel>) => {
          return <DateTime datetime={original.createdAt} />;
        },
        Header: 'Created',
        accessor: (d) => (d?.createdAt?.isValid() ? d.createdAt.toDate() : 'unknown'),
        disableFilters: true,
        id: 'createdAt',
        sortDescFirst: true,
        width: '115%',
      },
      description: {
        Cell: ({ column, row: { original }, state }: Cell<DashboardModel>) => {
          return (
            <DescriptionCell
              {...original}
              column={column}
              dataSourceType={original.dataTypes?.dataSourceType}
              isDataSourceEditable={isDataSourceEditable}
              state={state}
            />
          );
        },
        Header: 'Description',
        accessor: 'description',
        id: 'description',
        width: '124.5%',
      },
      dsuser: {
        Cell: ({ column, row: { original }, state }: Cell<DashboardModel>) => {
          return (
            <UserCell column={column} state={state} user={original?.formattedDsuserCreatedBy} />
          );
        },
        Header: 'Created By',
        accessor: (d) => d.formattedDsuserCreatedBy?.fullName,
        id: 'dsuser',
        width: '120%',
      },
      lastRun: {
        Cell: ({ row: { original } }: Cell<DashboardModel>) => (
          <DateTime datetime={original.lastRunAt} />
        ),
        Header: 'Last Run',
        accessor: (d: Partial<DashboardModel>) =>
          d?.lastRunAt?.isValid() ? d.lastRunAt.toDate() : 'unknown',
        disableFilters: true,
        id: 'lastRun',
        width: '115%',
      },
      name: {
        Cell: ({ column, row: { original }, state }: Cell<DashboardModel>) => {
          return (
            <LinkedCell
              column={column}
              item={original}
              itemName={original.name}
              showDataSourceIcon={customColumProps?.name?.showDataSourceIcon}
              showDataTypeTooltip
              showIcon
              state={state}
            />
          );
        },
        Header: `${customColumProps?.name?.Header ?? 'Name'} (${data?.count ?? 0})`,
        accessor: 'name',
        disableHiding: true,
        id: 'name',
        width: '135%',
      },
      [ColumnKey.dsuserOwnedBy]: {
        Cell: ({ column, row: { original }, state }: Cell<DashboardModel>) => (
          <UserCell column={column} state={state} user={original?.dsuserOwnedBy} />
        ),
        Header: 'Owner',
        accessor: 'dsuserOwnedBy',
        id: ColumnKey.dsuserOwnedBy,
        width: '120%',
      },
      popularity: {
        Cell: ({ row: { original } }: Cell<DashboardModel>) => {
          return <PopularityCell isDashboard popularity={original?.popularity} />;
        },
        Header: PopularityCellHeader,
        accessor: (d) => d.popularity?.formattedPopularity,
        disableFilters: true,
        disableResizing: true,
        dropdownCheckboxLabel: 'Popularity',
        id: 'popularity',
        sortDescFirst: true,
        width: 120,
      },
      search: {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      sourceTablesCount: {
        Cell: ({ row: { original } }: Cell<DashboardModel>) => (
          <FormattedNumberCell number={original.sourceTablesCount} />
        ),
        Header: (
          <Tooltip content="Count of upstream data tables connected to this dashboard / report">
            <span>Source Tables</span>
          </Tooltip>
        ),
        accessor: (d) => d.sourceTablesCount,
        centerHeader: true,
        disableFilters: true,
        dropdownCheckboxLabel: 'Source Tables',
        id: 'sourceTablesCount',
        sortDescFirst: true,
        width: 150,
      },
      tags: {
        Cell: (props: Cell<DashboardModel>) => {
          const { row } = props;
          const dashboard = row.original;

          return (
            <EditableTaggedItemCell
              {...props}
              isDataSourceEditable={isDataSourceEditable}
              obj={dashboard}
            />
          );
        },
        Header: 'Tags',
        accessor: (d) => d.taggedItems,
        disableSortBy: true,
        id: 'tags',
        width: '130%',
      },
      updatedAt: {
        Cell: ({ row: { original } }: Cell<DashboardModel>) => {
          return <DateTime datetime={original.updatedAt} />;
        },
        Header: 'Last Modified',
        accessor: (d) => d.updatedAt,
        disableFilters: true,
        id: 'updatedAt',
        sortDescFirst: true,
        width: '115%',
      },
    };

    return visibleColumns.map((col) => all[col]);
  }, [customColumProps, data?.count, isDataSourceEditable, visibleColumns]);

  const getRowId = useCallback((row: Partial<DashboardModel>) => row.guid!, []);
  const totalPages = data && filter.page_size ? Math.ceil(data.count / filter.page_size) : 1;

  return (
    <TableStyled>
      <Table
        basic="very"
        changePage={changePage}
        className="table-full"
        columns={columns}
        compact
        data={data?.results || []}
        disableRowSelect={disableRowSelect}
        getRowId={getRowId}
        initialState={{
          hiddenColumns,
          ...(selectedRowIds && { selectedRowIds }),
          sortBy: initialTableSortState,
        }}
        loading={isLoading}
        manualFilters
        manualPagination
        manualSortBy
        rangeSelectConfig={{
          onCellCheckChange: toggleItem,
          onHeaderCheckChange: toggleAll,
        }}
        selectable
        setFilters={search}
        setSortBy={sort}
        showFilter={isShowFilter}
        sortable
        stickyHeader
        toggleFilter={toggleFilter}
        totalPages={totalPages}
        unstackable
      />
    </TableStyled>
  );
};

export default React.memo<DashboardsTableProps>(DashboardsTable);
