import { Expose, Transform, Type } from 'class-transformer';
import moment from 'moment';

import { CommentData } from '@api/comments/CommentModel';
import { DsUserModel } from '@api/dsusers/DsUserModel';
import { QueryModel } from '@api/queries/QueryModel';
import DataTypesModel from '@models/DataTypesModel';
import formatNumber from '@utils/formatNumber';

class QueryLogRecordModel {
  static objectType: 'querylogrecord' = 'querylogrecord';

  objectType: 'querylogrecord' = 'querylogrecord';

  guid!: string;

  @Type(() => QueryModel)
  query!: QueryModel;

  @Type(() => DsUserModel)
  dsuser?: DsUserModel;

  @Expose({ name: 'executed_on' })
  @Transform((value) => moment.utc(value))
  executedOn?: moment.Moment;

  @Transform((value) => moment.duration(value))
  duration?: moment.Duration;

  result?: string;

  @Expose({ name: 'error_message' })
  errorMessage?: string;

  comment?: CommentData;

  @Expose({ name: 'rows_produced' })
  rowsProduced?: number;

  @Expose({ name: 'external_id' })
  externalId?: string;

  @Expose({ name: 'credits_used' })
  creditsUsed?: number = 0;

  @Expose({ name: 'data_types' })
  @Type(() => DataTypesModel)
  dataTypes?: DataTypesModel;

  get formattedCreditsUsed() {
    return formatNumber({ value: this.creditsUsed });
  }
}

export default QueryLogRecordModel;
