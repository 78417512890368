import React from 'react';

import { useFetchCostAnalysisTables } from '@api/costAnalysis';

import ScatterChart from '../ScatterChart';

export interface DashboardByCostChartProps {
  range: [string, string];
}

const TableScatterChart: React.FC<DashboardByCostChartProps> = ({ range }) => {
  const { data, isLoading } = useFetchCostAnalysisTables({
    params: {
      end_date: range[1],
      order: '-bytes',
      page: 1,
      page_size: 20,
      start_date: range[0],
    },
  });

  return <ScatterChart data={data?.results} isLoading={isLoading} />;
};

export default TableScatterChart;
