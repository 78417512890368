import styled from '@emotion/styled';

import Box from '@components/Box';

export const StyledWrapper = styled(Box)`
  align-items: center;

  ${({ theme }) => theme.media.down('lg')`
    flex-direction: column;
    align-items: flex-start;
  `};
`;
