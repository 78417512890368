import React from 'react';

import type { TagIconOption } from '@components/AppTag/helpers';
import { categoryIcons, statusIcons, tagColorMap } from '@components/AppTag/helpers';
import Icon from '@components/UI/Icon';

export const TAG_TYPE_OPTIONS = [
  {
    text: 'Status',
    value: 'status',
  },
  {
    text: 'Category',
    value: 'category',
  },
];

export const defaultIconForType: Record<string, TagIconOption> = {
  category: { color: tagColorMap.gray, icon: 'tag' },
  status: { color: tagColorMap.endorsed, icon: 'endorsed' },
};

const mapIconToOption = ({ color, icon }: TagIconOption) => ({
  key: `${icon}${color}`,
  text: <Icon color={color} name={icon} size="20px" />,
  value: { color, icon },
});

export const statusTagOptions = statusIcons.map(mapIconToOption);
export const categoryTagOptions = categoryIcons.map(mapIconToOption);
