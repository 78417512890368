import useParsedMessage, { UseParsedMessageProps } from './useParsedMessage';

interface ActivityMessageProps extends UseParsedMessageProps {}

const ActivityMessage = ({
  globalFilter,
  hideUserAvatar,
  isShownOnTable,
  message,
}: ActivityMessageProps) => {
  const parsedContent = useParsedMessage({
    globalFilter,
    hideUserAvatar,
    isShownOnTable,
    message,
  });
  return parsedContent;
};

export default ActivityMessage;
