import React from 'react';
import { RouterLink } from '@routing/router';

import Box from '@components/Box';

import { ChatBotPageTitleLink } from './ChatBotPageTitle.styles';

const ChatBotPageTitle = () => {
  return (
    <Box alignItems="center" compDisplay="flex" flexWrap="wrap" gap={1}>
      <ChatBotPageTitleLink
        as={RouterLink}
        cursor="pointer"
        fontWeight="semibold"
        mb={0}
        to="/chatbot"
      >
        Select Star AI Assistant
      </ChatBotPageTitleLink>
    </Box>
  );
};

export default ChatBotPageTitle;
