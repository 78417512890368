import React from 'react';

import Box from '@components/Box/Box.styles';
import Breadcrumbs, { BreadcrumbItem } from '@components/Breadcrumbs';
import Ellipsis from '@components/Ellipsis/Ellipsis.styles';
import IconButton from '@components/IconButton';
import SourceTablesTabRow from '@components/TabContent/SourceTablesTab/SourceTablesTabRow';
import PopularityCell from '@components/Table/Cells/PopularityCell';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import DataTypesModel from '@models/DataTypesModel';
import { PopularityModel } from '@models/PopularityModel';
import theme from '@styles/theme';

interface SourceTablesTabSchemaRowProps {
  breadcrumbs?: BreadcrumbItem[];
  dataTypes?: DataTypesModel;
  description?: string;
  isOpen?: boolean;
  isToggleDisabled: boolean;
  onToggle: () => void;
  popularity?: PopularityModel;
}

const SourceTablesTabSchemaRow: React.FC<SourceTablesTabSchemaRowProps> = ({
  breadcrumbs,
  dataTypes,
  description,
  isOpen,
  isToggleDisabled,
  onToggle,
  popularity,
}) => {
  return (
    <SourceTablesTabRow
      firstColumn={
        <Box alignItems="center" compDisplay="flex">
          <Box alignItems="center" compDisplay="flex" flexShrink={0} mr={0.5}>
            <IconButton
              color="transparent"
              disabled={isToggleDisabled}
              onClick={onToggle}
              title="Toggle"
            >
              <Icon color={theme.colors.primary} name={isOpen ? 'down' : 'right'} size="16px" />
            </IconButton>
            <Tooltip content={dataTypes?.tooltips.dataSource}>
              <Icon name={dataTypes?.icons.dataSource!} size="16px" />
            </Tooltip>
            <Tooltip content={dataTypes?.tooltips.dataType}>
              <Icon name={dataTypes?.icons.dataType!} size="16px" />
            </Tooltip>
          </Box>
          <Breadcrumbs
            color="inherit"
            fontSize="inherit"
            fontWeight="normal"
            items={breadcrumbs}
            showTrailingSlash={false}
          />
        </Box>
      }
      secondColumn={<Ellipsis compWidth="100%">{description}</Ellipsis>}
      thirdColumn={<PopularityCell popularity={popularity} />}
    />
  );
};

export default SourceTablesTabSchemaRow;
