import React, { useRef, useState } from 'react';
import { useLocation, useParams } from '@routing/router';

import { useFetchFolder } from '@api/bifolder';
import Breadcrumbs from '@components/Breadcrumbs';
import CircularLoader from '@components/CircularLoader';
import NotFoundError from '@components/Error/NotFoundError';

import { CONTAINER_CONFIG, DATA_TYPE_OPTIONS } from './constants';
import DataSourcePage from './DataSourcePage';
import ViewPage from './ViewPage';
import WorkbookPage from './WorkbookPage';

const ProjectPage: React.FC = () => {
  const lastPath = useRef<string>('');
  const location = useLocation();
  const { dsGuid, guid } = useParams<{ dsGuid?: string; guid: string }>();
  const [objectType, setObjectType] = useState<string>(DATA_TYPE_OPTIONS[0].value);

  const { data, isLoading } = useFetchFolder(guid, {
    enabled: Boolean(guid),
    params: { query: '{name, breadcrumbs, data_types}' },
  });

  const viewProps = {
    dataTypes: guid ? data?.dataTypes : CONTAINER_CONFIG?.dataTypes,
    dsGuid,
    guid,
    onSelectDataType: setObjectType,
    subtitle: data?.breadcrumbList && <Breadcrumbs items={data?.breadcrumbList} />,
    title: guid ? data?.name : CONTAINER_CONFIG?.name,
  };

  if (lastPath.current && location.pathname !== lastPath.current) {
    setObjectType(DATA_TYPE_OPTIONS[0].value);
    lastPath.current = location.pathname;
  }

  if (!dsGuid && !guid) {
    return <NotFoundError />;
  }

  if (guid && isLoading) {
    return <CircularLoader centered cover />;
  }

  if (objectType === 'tableauview') {
    return <ViewPage {...viewProps} />;
  }

  if (objectType === 'dashboard') {
    return <WorkbookPage {...viewProps} />;
  }

  if (objectType === 'tableaudatasource') {
    return <DataSourcePage dataTypeInitialSelected={DATA_TYPE_OPTIONS[2].value} {...viewProps} />;
  }

  return null;
};

export default React.memo(ProjectPage);
