import { useMemo } from 'react';

import parseLineageNodes from '@components/LineageExplore/useCreateNodesEdges/algorithm/parseLineageNodes';
import useGetConfigQueryParams from '@components/LineageExplore/useGetConfigQueryParams';

import { AlgorithmResult, AlgorithmResultProps } from './types';

const useCustomAlgorithm = ({
  initialYPositionPerStack,
  isColumnLevelLineage,
  stacksData,
}: AlgorithmResultProps): AlgorithmResult => {
  const { shouldHideFilterLineage } = useGetConfigQueryParams();
  const { edgesById = {}, nodesById = {}, stackGroups } = stacksData ?? {};

  const result = useMemo(() => {
    return parseLineageNodes({
      edgesById,
      initialYPositionPerStack,
      isColumnLevelLineage,
      nodesById,
      shouldHideFilterLineage,
      stackGroups,
    });
  }, [
    edgesById,
    initialYPositionPerStack,
    isColumnLevelLineage,
    nodesById,
    shouldHideFilterLineage,
    stackGroups,
  ]);

  return result;
};

export default useCustomAlgorithm;
