import React from 'react';

import Box from '@components/Box';
import Icon from '@components/UI/Icon';
import Image from '@components/UI/Image';

import { Heading, Link, StyledError, Text, Wrapper } from './StyledError';

const NotFoundError = () => {
  return (
    <StyledError>
      <Wrapper>
        <Box compWidth="fit-content" mb={2}>
          <Image alt="No content" height="auto" src="/images/item-not-found.svg" width="130px" />
        </Box>
        <Heading>Page could not be found</Heading>
        <Text>
          The object you requested does not exist or you don&apos;t have permissions to view it. If
          you think this is an error, please contact your organization admin.
        </Text>
        <Text>
          <Link href="/">
            Return Home <Icon color="currentColor" name="arrow-right" />
          </Link>
        </Text>
      </Wrapper>
    </StyledError>
  );
};

export default NotFoundError;
