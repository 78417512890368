import { DbtTypes } from '@api/dbt/types';
import type { Option } from '@components/UI/Select';
import { isTransformerType, isWarehouseType } from '@models/DataSourceCredentials';
import { DataSourceModel } from '@models/DataSourceModel';

import type { FormValues } from './CreateDbtProjectForm';

export enum FieldKey {
  cloudBaseUrl = 'cloudBaseUrl',
  cloudJobId = 'cloudJobId',
  cloudToken = 'cloudToken',
  coreCatalogJson = 'coreCatalogJson',
  coreManifestJson = 'coreManifestJson',
  coreRunResultsJson = 'coreRunResultsJson',
  dbtType = 'dbtType',
  dialect = 'dialect',
  name = 'name',
  targetDataSource = 'targetDataSource',
}

export type DataSourceOption = Option<DataSourceModel>;

export const getDatasourceOptions = (datasources: Record<string, DataSourceModel> = {}) => {
  const results: DataSourceOption[] = [];

  Object.values(datasources).forEach((ds) => {
    if (ds.isEmailSent !== false && isWarehouseType(ds.type) && !isTransformerType(ds.type)) {
      results.push({
        icon: ds.dataTypes?.icons.dataSource!,
        key: ds.guid,
        text: ds.name,
        value: ds,
      });
    }
  });

  return results;
};

export const getCredentialsByType = (values: FormValues) => {
  const PAYLOAD_CONFIG = {
    both: {},
    cloud: {
      cloud_base_url: values.cloudBaseUrl,
      cloud_job_id: values.cloudJobId,
      cloud_token: values.cloudToken,
    },
    core: {
      core_catalog_json: values.coreCatalogJson,
      core_manifest_json: values.coreManifestJson,
      core_run_results_json: values.coreRunResultsJson,
    },
    empty: {},
  };

  return PAYLOAD_CONFIG[values.dbtType];
};

export const DEFAULT_URL = 'https://cloud.getdbt.com';

export const TYPE_OPTIONS: Option<DbtTypes>[] = [
  {
    key: DbtTypes.cloud,
    text: 'dbt Cloud',
    value: DbtTypes.cloud,
  },
  {
    key: DbtTypes.core,
    text: 'dbt Core (open source)',
    value: DbtTypes.core,
  },
];

export const REQUIRED_FIELDS = {
  both: [],
  cloud: [FieldKey.name, FieldKey.cloudBaseUrl, FieldKey.cloudJobId, FieldKey.cloudToken],
  core: [FieldKey.name, FieldKey.coreCatalogJson, FieldKey.coreManifestJson],
  empty: [],
};
