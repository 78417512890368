import React from 'react';
import { useHistory, useParams } from '@routing/router';

import { useDeleteChatBotConversations, useFetchChatBotConversations } from '@api/chatbot';
import invalidateCache from '@api/invalidateCache';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import DeleteConfirmationModal from '@components/Modal/DeleteConfirmationModal';
import Text from '@components/Text';
import TreeV2 from '@components/Tree/Tree.v2';
import HR from '@components/UI/HR';
import Icon from '@components/UI/Icon';
import { useModal } from '@context/Modal';
import theme from '@styles/theme';
import stripSpaces from '@utils/stripSpaces';

import HierarchyLoadingSkeleton from '../HierarchyLoadingSkeleton';

import ChatBotHierarchyItem from './ChatBotHierarchyItem';
import DeleteChatBotHierarchyItemModal from './DeleteChatBotHierarchyItemModal';

export const ERROR_MESSAGE = 'Sorry, something went wrong.';

const query = stripSpaces(`{
  title,
  guid
}`);

const ChatBotHierarchy: React.FC = () => {
  const { MODAL_IDS, checkModalOpened, closeModal, getModalContent, openModal } = useModal();
  const history = useHistory();

  const { guid } = useParams<{ guid: string }>();

  const { data, isError, isLoading } = useFetchChatBotConversations({
    params: {
      query,
    },
  });

  const { isLoading: isLoadingDeletion, mutate: deleteChats } = useDeleteChatBotConversations({
    onSuccess: () => {
      invalidateCache((keys) => [keys.chatbot.all]);
      closeModal(MODAL_IDS.deleteChatbotConversations);
      history.push('/chatbot');
    },
  });

  const deleteChatBotHierarchyItemModalProps = getModalContent(MODAL_IDS.deleteChatbotConversation);

  if (isLoading) {
    return <HierarchyLoadingSkeleton />;
  }

  return (
    <>
      <Box overflowX="hidden" overflowY="auto">
        {isLoading && <HierarchyLoadingSkeleton nesting={false} />}
        {!isLoading && (
          <Button
            compDisplay="flex"
            compHeight={theme.space(4.5)}
            compSize="lg"
            compWidth={`calc(100% - ${theme.space(4)})`}
            justifyContent="flex-start"
            m="auto"
            mb={1}
            onClick={() => history.push('/chatbot/')}
            startIconName="plus"
            startIconSize="20px"
            textAlign="left"
            variant="outlined"
          >
            Create a New Chat
          </Button>
        )}
        {!isLoading && Boolean(data?.results?.length) && (
          <>
            <Button
              backgroundColor="transparent"
              color="inherit"
              compWidth={`calc(100% - ${theme.space(4)})`}
              fontWeight="semibold"
              iconCurrentColor
              justifyContent="flex-start"
              m="auto"
              onClick={() => openModal(MODAL_IDS.deleteChatbotConversations)}
              spacing={0.5}
              startIcon={<Icon name="refresh" />}
            >
              Clear the Chat
            </Button>
            <HR borderColor="gray.300" mb={0.5} mt={0} mx={2} />
            <Text color="inherit" fontSize=" body1" fontWeight="semibold" my={1} px={2}>
              Activity
            </Text>
            <TreeV2
              allowCaret={() => false}
              allowDataLoad={() => false}
              getKey={(item) => item.guid}
              highlightedKeys={guid ? [guid] : ['no_guid']}
              renderLabel={({ original }) => (
                <ChatBotHierarchyItem guid={original.guid} name={original.title} />
              )}
              treeData={data?.results}
            />
          </>
        )}
        {!isLoading && !data?.results?.length && <Box p={2}>No data.</Box>}
        {!isLoading && isError && (
          <Text color="inherit" fontSize={theme.typography.fontSizes.body2} px={2}>
            {ERROR_MESSAGE}
          </Text>
        )}
      </Box>
      {checkModalOpened(MODAL_IDS.deleteChatbotConversations) && (
        <DeleteConfirmationModal
          loading={isLoadingDeletion}
          onClose={() => closeModal(MODAL_IDS.deleteChatbotConversations)}
          onRemove={() => deleteChats(null)}
          title="Clear the Chat?"
        >
          This will erase all chat history.
        </DeleteConfirmationModal>
      )}
      {checkModalOpened(MODAL_IDS.deleteChatbotConversation) &&
        deleteChatBotHierarchyItemModalProps && (
          <DeleteChatBotHierarchyItemModal
            guid={deleteChatBotHierarchyItemModalProps.guid}
            name={deleteChatBotHierarchyItemModalProps.name}
            onClose={() => closeModal(MODAL_IDS.deleteChatbotConversation)}
          />
        )}
    </>
  );
};

export default ChatBotHierarchy;
