import paginatedTransform from '@api/paginatedTransform';
import { paginatedSelect, rawSelect } from '@api/utils';
import type { UseFetchOptions } from '@hooks/useFetch';
import useFetch from '@hooks/useFetch';
import { FrequentDSUserModel } from '@models/FrequentDSUserModel';
import type { PaginatedResponse } from '@models/PaginatedResponse';

import * as cacheKeys from './cacheKeys';
import type { DatasetModel } from './DatasetModel.v1';
import mapDatasetModel from './DatasetModel.v1';

interface FetchDatasetParams {
  bifolders?: string;
  datasources?: string;
  type?: string;
}

export const fetchDatasetsSelect = paginatedSelect(mapDatasetModel);
/**
 * We want to use the more generic API used with QuickSight to continue BI integrations.
 * @todo Migrate once the new BI tools is finished testing
 * @deprecated
 */
export const useFetchDatasets = (
  options: UseFetchOptions<PaginatedResponse<DatasetModel>, FetchDatasetParams>,
) => {
  return useFetch<PaginatedResponse<DatasetModel>>({
    ...options,
    queryKey: [...cacheKeys.all, options.params],
    select: fetchDatasetsSelect,
    url: `/bi/power_bi/datasets/`,
  });
};

export const fetchDatasetSelect = rawSelect(mapDatasetModel);
/**
 * We want to use the more generic API used with QuickSight to continue BI integrations.
 * @todo Migrate once the new BI tools is finished testing
 * @deprecated
 */
export const useFetchDataset = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  guid: string = '',
  options: UseFetchOptions<DatasetModel, FetchDatasetParams>,
) => {
  return useFetch<DatasetModel>({
    ...options,
    queryKey: [...cacheKeys.dataset(guid), options.params],
    select: fetchDatasetSelect,
    url: `/bi/power_bi/datasets/${guid}/`,
  });
};

export const fetchDatasetFrequentDSUsersSelect = paginatedTransform(FrequentDSUserModel);
/**
 * We want to use the more generic API used with QuickSight to continue BI integrations.
 * @todo Migrate once the new BI tools is finished testing
 * @deprecated
 */
export const useFetchDatasetFrequentDSUsers = (
  guid: string,
  options: UseFetchOptions<PaginatedResponse<FrequentDSUserModel>, FetchDatasetParams>,
) => {
  return useFetch({
    ...options,
    queryKey: [...cacheKeys.frequentBiDsUsers(guid), options.params],
    select: fetchDatasetFrequentDSUsersSelect,
    url: `/bi/power_bi/datasets/${guid}/frequent-dsusers/`,
  });
};
