import React, { memo } from 'react';
import styled from '@emotion/styled';

import Box from '@components/Box';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import theme from '@styles/theme';

export const StyledIcon = styled(Icon)`
  flex-shrink: 0;
`;

interface SearchHeaderProps {}
const SearchHeader: React.FC<SearchHeaderProps> = () => {
  return (
    <Tooltip content="Search">
      <Box
        alignItems="center"
        aria-label="Search"
        compDisplay="flex"
        compHeight="100%"
        compWidth="100%"
        justifyContent="center"
      >
        <StyledIcon color={theme.colors.gray[700]} name="search" size="20px" />
      </Box>
    </Tooltip>
  );
};

export default memo<SearchHeaderProps>(SearchHeader);
