import 'semantic-ui-css/semantic.min.css';
import 'reactflow/dist/style.css';

import React from 'react';

import CalendarModalContainer from './CalendarModalContainer';
import CreateTagModalContainer from './CreateTagModalContainer';
import MentionedByModalContainer from './MentionedByModalContainer';
import QueryModalContainer from './QueryModalContainer';

const ModalContainer: React.FC = () => (
  <>
    <MentionedByModalContainer />
    <CalendarModalContainer />
    <CreateTagModalContainer />
    <QueryModalContainer />
  </>
);

export default ModalContainer;
