import escapeHtml from 'escape-html';
import { Descendant, Element, Node as SlateNode, Text } from 'slate';
import { jsx } from 'slate-hyperscript';

import { FormattedText } from '../RichTextEditor.types';

export const DEFAULT_SLATE_STATE: Descendant[] = [
  {
    children: [{ text: '' }],
    type: 'paragraph',
  },
];

export const parseStringToHTML = (string: string) => {
  return new DOMParser().parseFromString(string, 'text/html');
};

const nodeAttributesMap: { [key: string]: string } = {
  CODE: 'code',
  EM: 'italic',
  STRONG: 'bold',
  U: 'underline',
};

/**
 * Map used for converting html to slate elements.
 * Maps html element types to our custom slate elements type.
 * If we want to handle any specific html tags in a custom way it should be added here.
 */
const nodeElementMap: {
  [key: string]: (
    children: Descendant[],
    el?: HTMLElement,
    level?: number,
  ) => Descendant | Descendant[];
} = {
  A: (children, el) => jsx('element', { type: 'link', url: el?.getAttribute('href') }, children),
  BLOCKQUOTE: (children) => jsx('element', { type: 'blockquote' }, children),
  BODY: (children) => jsx('fragment', {}, children),
  BR: (children) => jsx('element', { type: 'br' }, children),
  CODE: (children) => jsx('fragment', {}, children),
  DEFAULT: (children) => jsx('element', { type: 'paragraph' }, children),
  EM: (children) => jsx('element', { type: 'span' }, children),
  H1: (children) => jsx('element', { type: 'h1' }, children),
  H2: (children) => jsx('element', { type: 'h2' }, children),
  H3: (children) => jsx('element', { type: 'h3' }, children),
  H4: (children) => jsx('element', { type: 'h4' }, children),
  H5: (children) => jsx('element', { type: 'h5' }, children),
  H6: (children) => jsx('element', { type: 'h6' }, children),
  IMG: (children, el) =>
    jsx('element', { type: 'image', url: (el as HTMLImageElement).src }, children),
  LI: (children) => jsx('element', { type: 'li' }, children),
  OL: (children, _, level) => jsx('element', { level, type: 'ol' }, children),
  P: (children) => jsx('element', { type: 'paragraph' }, children),
  PRE: (children) => jsx('element', { type: 'codeblock' }, children),
  SPAN: (children, el) => {
    const rawMention = el?.dataset && 'mention' in el.dataset ? el.dataset.value : undefined;
    if (rawMention) {
      const mention = JSON.parse(rawMention);
      return jsx('element', { mention, type: 'mention' }, children);
    }
    return jsx('element', { type: 'span' }, children);
  },
  STRONG: (children) => jsx('element', { type: 'span' }, children),
  TABLE: (children) => jsx('element', { type: 'table' }, children),
  TBODY: (children) => jsx('element', { type: 'tbody' }, children),
  TD: (children) => jsx('element', { type: 'td' }, children),
  TH: (children) => jsx('element', { type: 'th' }, children),
  THEAD: (children) => jsx('element', { type: 'thead' }, children),
  TR: (children) => jsx('element', { type: 'tr' }, children),
  U: (children) => jsx('element', { type: 'span' }, children),
  UL: (children, _, level) => jsx('element', { level, type: 'ul' }, children),
};

/**
 * Deserializes html element tree to slate recursively.
 * Based on: https://docs.slatejs.org/concepts/10-serializing#deserializing
 */
export const deserializeToSlate = (
  el: HTMLElement,
  markAttributes = {},
  level = 0,
  isCodeblockChild = false,
): Descendant[] => {
  if (el.nodeType === Node.TEXT_NODE) {
    return [jsx('text', markAttributes, el.textContent)].flat();
  }
  if (el.nodeType !== Node.ELEMENT_NODE) {
    return [];
  }

  const nodeAttributes: { [key: string]: boolean } = { ...markAttributes };

  if (el.nodeName in nodeAttributesMap) {
    nodeAttributes[nodeAttributesMap[el.nodeName]] = true;
    if (isCodeblockChild) {
      delete nodeAttributes.code;
    }
  }

  const isList = ['OL', 'UL'].includes(el.nodeName);
  const children: Descendant[] = Array.from(el.childNodes)
    .map((node) =>
      deserializeToSlate(
        node as HTMLElement,
        nodeAttributes,
        isList ? level + 1 : level,
        el.nodeName === 'PRE' ? true : isCodeblockChild,
      ),
    )
    .flat();

  if (children.length === 0) {
    children.push(jsx('text', nodeAttributes, ''));
  }

  const node =
    el.nodeName in nodeElementMap
      ? nodeElementMap[el.nodeName]?.(children, el, level)
      : nodeElementMap.DEFAULT(children, el);
  return [node].flat();
};

/**
 * Serializes slate text nodes to custom defined html.
 */
const serializeTextNodeToHTML = (node: FormattedText) => {
  let string = escapeHtml(node.text);
  if (node.bold) {
    string = `<strong>${string}</strong>`;
  }
  if (node.code) {
    string = `<code>${string}</code>`;
  }
  if (node.italic) {
    string = `<em>${string}</em>`;
  }
  if (node.underline) {
    string = `<u>${string}</u>`;
  }
  return string;
};

/**
 * Serializes slate state to custom defined html output.
 */
export const serializeSlateToHTML = (node: Descendant) => {
  if (Text.isText(node)) {
    return serializeTextNodeToHTML(node);
  }

  const children: string = node.children.map((n) => serializeSlateToHTML(n)).join('');

  switch (node.type) {
    case 'blockquote':
      return `<blockquote>${children}</blockquote>`;
    case 'code':
      return `<code>${children}</code>`;
    case 'codeblock':
      return `<pre>${children}</pre>`;
    case 'em':
      return `<em>${children}</em>`;
    case 'h1':
      return `<h1>${children}</h1>`;
    case 'h2':
      return `<h2>${children}</h2>`;
    case 'h3':
      return `<h3>${children}</h3>`;
    case 'h4':
      return `<h4>${children}</h4>`;
    case 'h5':
      return `<h5>${children}</h5>`;
    case 'h6':
      return `<h6>${children}</h6>`;
    case 'link':
      return `<a href="${escapeHtml(node.url)}">${children}</a>`;
    case 'li':
      return `<li>${children}</li>`;
    case 'mention':
      return `<span data-mention data-value="${escapeHtml(JSON.stringify(node.mention))}">${
        node.mention.name
      }</span>`;
    case 'ol':
      return `<ol>${children}</ol>`;
    case 'paragraph':
      return `<p>${children}</p>`;
    case 'span':
      return `<span>${children}</span>`;
    case 'strong':
      return `<strong>${children}</strong>`;
    case 'u':
      return `<u>${children}</u>`;
    case 'ul':
      return `<ul>${children}</ul>`;
    default:
      return children;
  }
};

interface SerializeSlateToPlainTextArgs {
  getMentionFromCacheById: (id: string) => { name: string };
  nodes: Descendant[];
}
export const serializeSlateToPlainText = ({
  getMentionFromCacheById,
  nodes,
}: SerializeSlateToPlainTextArgs): string => {
  if (!nodes) {
    return '';
  }
  const stringArray = nodes.map((node) => {
    if (Element.isElement(node)) {
      if (node.type === 'mention') {
        return getMentionFromCacheById?.(node.mention.guid)?.name;
      }
      return serializeSlateToPlainText({
        getMentionFromCacheById,
        nodes: node.children,
      });
    }
    return SlateNode.string(node);
  });
  return stringArray.join('');
};
