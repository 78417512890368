import React from 'react';

import { getBiLinkLabel } from '@components/BILink/biLinksPropsMap';
import { isNotificationAllowed } from '@components/Modal/NotifyUsersModal/utils';
import QueryModalWithTargetBox from '@components/Modal/QueryModal/QueryModalWithTargetBox';
import { DataSourceTypesType, isWarehouseType } from '@models/DataSourceCredentials';
import TableLastQuery from '@pages/TabbedTableViewPage/TableLastQuery';

import { ButtonGroupItem, ButtonName, ConfigArgs } from './MetadataObjectButtonsToolbar.types';
import { handleShareClick, navigateToPath } from './MetadataObjectButtonsToolbar.utils';

const config = ({
  dataTypes,
  enableNotifications,
  externalUrl,
  guid,
  navigate,
  openNotificationModal,
  path,
  search,
  showLastQuery,
  showShareButton = true,
  sql,
  sqlEditable,
  url,
}: ConfigArgs): Record<ButtonName, ButtonGroupItem | null> => {
  const isChromeExtension = process.env.REACT_APP_CHROME_EXTENSION_BUILD;

  const lineageButtonDefaultProps = {
    dataTestid: 'lineage-button',
    icon: 'lineage' as const,
    label: 'Lineage',
  };

  const lineageButton = isChromeExtension
    ? {
        ...lineageButtonDefaultProps,
        as: 'a' as React.ElementType,
        href: `${process.env.REACT_APP_SS_APP_URL}${`${url}/lineage/explore${search}`}`,
        rel: 'noopener noreferrer',
        target: '_blank',
      }
    : {
        ...lineageButtonDefaultProps,
        onClick: navigateToPath.bind(null, {
          finalPath: `/lineage/explore${search}`,
          guid,
          navigate,
          path,
        }),
      };

  const erdButton = {
    icon: 'erd' as const,
    label: 'ERD',
    onClick: navigateToPath.bind(null, {
      finalPath: `/queries/erd?stids=${guid}`,
      guid,
      navigate,
      path,
    }),
  };

  const shareButton = showShareButton
    ? {
        icon: 'share' as const,
        label: 'Share',
        onClick: handleShareClick,
      }
    : null;

  const notifyButton =
    enableNotifications && isNotificationAllowed(dataTypes?.dataSourceType as DataSourceTypesType)
      ? {
          icon: 'announcement' as const,
          iconColor: 'transparent',
          label: 'Notify',
          onClick: openNotificationModal,
        }
      : null;

  const externalButton = externalUrl
    ? {
        as: 'a' as React.ElementType,
        href: externalUrl,
        icon: dataTypes?.icons.dataSource ?? 'question',
        label: getBiLinkLabel(dataTypes).label,
        rel: 'noopener noreferrer',
        target: '_blank',
      }
    : null;

  const lastQuerySqlButton = {
    icon: 'query' as const,
    label: 'SQL',
    wrapper: <TableLastQuery dataSourceType={dataTypes?.dataSourceType} guid={guid} />,
  };

  const rawSqlButton =
    sql || sqlEditable
      ? {
          icon: 'query' as const,
          label: 'SQL',
          wrapper: (
            <QueryModalWithTargetBox
              codeString={sql}
              dataSourceType={dataTypes?.dataSourceType}
              editable={sqlEditable}
              guid={guid}
            />
          ),
        }
      : null;

  return {
    erd:
      isWarehouseType(dataTypes?.dataSourceType ?? '') && guid.includes('ta_') ? erdButton : null,
    external: externalButton,
    lineage: lineageButton,
    notify: notifyButton,
    share: shareButton,
    sql: showLastQuery ? lastQuerySqlButton : rawSqlButton,
  };
};

export default config;
