import React from 'react';

import type { StyledBoxProps } from '@components/Box';
import Box from '@components/Box';

import { Subtitle, Title } from './SectionTitle.styles';

const SectionTitle = ({
  subtitle,
  title,
  ...other
}: StyledBoxProps & { subtitle: string; title: string }) => (
  <Box minWidth="fit-content" noDefault {...other}>
    <Title mb={1}>{title}</Title>
    <Subtitle>{subtitle}</Subtitle>
  </Box>
);

export default SectionTitle;
