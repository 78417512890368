import React from 'react';
import { useHistory } from '@routing/router';

import { usePatchCollection, usePostCollection } from '@api/collections';
import mapCollectionModel, { CollectionModel } from '@api/collections/CollectionModel';
import invalidateCache from '@api/invalidateCache';
import Alert from '@components/Alert';
import Box from '@components/Box';
import Button from '@components/Button';
import CollectionsSelect from '@components/CollectionsSelect';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import BaseOwnerSelect from '@components/OwnerSelect/BaseOwnerSelect';
import RichTextDescriptionEditor from '@components/RichTextDescriptionEditor';
import { renderInfoToast } from '@components/Toast';
import InputLabel from '@components/UI/Form/InputLabel';
import { ModalContent, ModalFooter } from '@components/UI/Modal';
import useBulkEditSelected from '@hooks/useBulkEditSelected';
import theme from '@styles/theme';

import useDefaultOwnerOptions from '../CreateTagModal/CreateTagContent/useDefaultOwnerOptions';

import { StyledCreateCollectionContentFieldLabel } from './CreateCollectionForm.styles';
import {
  // COLLECTION_QUERY,
  CreateCollectionFormProps,
  CreateCollectionFormValues,
  getCollectionInitialState,
} from './CreateCollectionForm.utils';

const CreateCollectionForm: React.FC<CreateCollectionFormProps> = ({
  initialData,
  onClose,
  onSuccess,
}) => {
  const history = useHistory();
  const isEdit = Boolean(initialData?.guid);

  const { selected } = useBulkEditSelected<CollectionModel>({
    defaultSelected: initialData ? [initialData] : undefined,
  });

  const { options: defaultOwnerOptions, selectedOption: currentBusinessOwner } =
    useDefaultOwnerOptions({
      isEdit,
      owner: initialData?.businessOwner?.obj,
    });

  const { selectedOption: currentTechnicalOwner } = useDefaultOwnerOptions({
    enableUserDefaultOwner: false,
    isEdit,
    owner: initialData?.technicalOwner?.obj,
  });

  const currentParent = initialData?.parentGuid
    ? {
        text: initialData.parentGuid, // TODO: Edit collection [sc-101088]
        value: initialData.parentGuid,
      }
    : undefined;

  const handleOnSuccess = (data: CollectionModel) => {
    renderInfoToast(isEdit ? `${data.name} Collection Updated` : `${data.name} Collection Created`);
    invalidateCache((keys) => [keys.collections.all]);
    history.push(data.routePath);
    onSuccess?.();
  };

  const postQuery = usePostCollection({
    onSuccess: handleOnSuccess,
  });

  const patchQuery = usePatchCollection(initialData?.guid ?? '', {
    onSuccess: handleOnSuccess,
  });

  const { error, isLoading } = isEdit ? patchQuery : postQuery;

  const initialValues = getCollectionInitialState({
    businessOwnerOption: currentBusinessOwner,
    initialState: isEdit ? initialData : undefined,
    parentOption: currentParent ? [currentParent] : [],
    technicalOwnerOption: currentTechnicalOwner,
  });

  const { handleChange, handleSubmit, setValues, touched, values } =
    useForm<CreateCollectionFormValues>({
      initialValues,
      onSubmit: (payload) => {
        const getFieldValue = (field: keyof CreateCollectionFormValues) => {
          return !isEdit || initialValues?.[field] !== payload[field]
            ? String(payload[field])
            : undefined;
        };

        const getOwnerFieldValue = (field: 'businessOwner' | 'technicalOwner') => {
          const [newOwner] = payload[field] ?? [];

          let newOwnerValue: string | undefined;

          if (!isEdit || newOwner?.value !== initialData?.[field]?.obj?.guid) {
            newOwnerValue = newOwner?.value ? String(newOwner?.value) : undefined;
          } else {
            newOwnerValue = undefined;
          }

          if (isEdit && newOwner?.value !== initialData?.[field]?.obj?.guid && !newOwner?.value) {
            newOwnerValue = undefined;
          }

          return newOwnerValue;
        };

        const getParentFieldValue = () => {
          const [newParent] = payload.parent ?? [];
          return newParent && (!isEdit || initialData?.parentGuid !== newParent.value)
            ? String(newParent.value)
            : undefined;
        };

        const newBusinessOwnerValue = getOwnerFieldValue('businessOwner');
        const newTechnicalOwner = getOwnerFieldValue('technicalOwner');

        const body = {
          business_owner: newBusinessOwnerValue,
          description: getFieldValue('description'),
          name: getFieldValue('name'),
          parent: getParentFieldValue(),
          richtext_description: getFieldValue('richtextDescription'),
          technical_owner: newTechnicalOwner,
        };

        if (isEdit) {
          patchQuery.mutate(body);
        } else {
          postQuery.mutate({ ...body, name: body.name ?? '' });
        }
      },
    });

  const handleNameBlur = () => {
    if (values.name && !values.description) {
      setValues((prev) => ({
        ...prev,
        description: `Collection of ${values.name} assets`,
      }));
    }
  };

  const handleDescriptionChange = (richTextDescription: string, description?: string) => {
    setValues((prevValues) => ({
      ...prevValues,
      description: description ?? '',
      richtextDescription: richTextDescription ?? '',
    }));
  };

  return (
    <Form isLoading={isLoading} onSubmit={handleSubmit}>
      <ModalContent maxHeight="unset" py={2}>
        <StyledCreateCollectionContentFieldLabel htmlFor="name">
          Name
          <Input
            autoFocus
            compWidth="100%"
            error={!touched.name && error?.data?.name}
            helperText={!touched.name && error?.data?.name}
            name="name"
            onBlur={handleNameBlur}
            onChange={handleChange}
            placeholder="Enter Collection Name"
            type="text"
            value={values.name}
          />
        </StyledCreateCollectionContentFieldLabel>
        <StyledCreateCollectionContentFieldLabel htmlFor="businessOwnerSelect">
          Business Owner
          <BaseOwnerSelect
            defaultOptions={defaultOwnerOptions}
            error={Boolean(error?.data?.business_owner)}
            fitAnchorWidth
            helperText={error?.data?.business_owner?.[0]}
            label="businessOwnerSelect"
            onChange={(newValue) => {
              setValues((prev) => ({
                ...prev,
                businessOwner: newValue,
              }));
            }}
            placeholder="Search for teams or users"
            showSearchOnOptions
            value={values.businessOwner}
          />
        </StyledCreateCollectionContentFieldLabel>
        <StyledCreateCollectionContentFieldLabel htmlFor="technicalOwnerSelect">
          Technical Owner
          <BaseOwnerSelect
            defaultOptions={defaultOwnerOptions}
            error={Boolean(error?.data?.technical_owner)}
            fitAnchorWidth
            helperText={error?.data?.technical_owner?.[0]}
            label="technicalOwnerSelect"
            onChange={(newValue) => {
              setValues((prev) => ({
                ...prev,
                technicalOwner: newValue,
              }));
            }}
            placeholder="Search for teams or users"
            showClearSelection
            showSearchOnOptions
            value={values.technicalOwner}
          />
        </StyledCreateCollectionContentFieldLabel>
        <StyledCreateCollectionContentFieldLabel htmlFor="parentCollection">
          Parent Collection
          <CollectionsSelect
            initialSelected={
              currentParent
                ? [mapCollectionModel({ guid: currentParent.value, name: currentParent.text })]
                : undefined
            }
            objects={selected.items}
            onChange={(newValue) => {
              setValues((prev) => ({
                ...prev,
                parent: newValue,
              }));
            }}
            placeholder="Set parent collection (optional)"
          />
        </StyledCreateCollectionContentFieldLabel>
        <Box compDisplay="flex" flexDirection="column">
          <InputLabel
            cursor="default"
            fontWeight="medium"
            htmlFor="description"
            mb={1}
            variantColor="modalForm"
          >
            Description
          </InputLabel>
          <RichTextDescriptionEditor
            descriptions={{
              description: values.description,
              richTextDescription: values.richtextDescription,
            }}
            disableSaveOnEnter
            editMode
            fontSize="body2"
            isEditable
            maxHeight={theme.space(45)}
            onDescriptionChange={handleDescriptionChange}
            placeholder="Enter description"
            shouldFocusOnEdit={false}
            shouldStartFocused={false}
            title=""
            variant="inline"
          />
        </Box>
        <Box compDisplay="flex" flexDirection="column" gap={0.5} gridColumn="1/3">
          {error?.data.core_catalog_json && (
            <Alert title="catalog.json" type="error">
              {error?.data.core_catalog_json}
            </Alert>
          )}
        </Box>
      </ModalContent>
      <ModalFooter p={3}>
        <Button onClick={() => onClose?.()} type="button" variant="outlined">
          Cancel
        </Button>
        <Button disabled={isLoading || !values.name} type="submit">
          Create
        </Button>
      </ModalFooter>
    </Form>
  );
};

export default CreateCollectionForm;
