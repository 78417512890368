import React from 'react';
import { useTheme } from '@emotion/react';

import type { IconButtonProps } from '@components/IconButton';
import IconButton from '@components/IconButton';
import Icon from '@components/UI/Icon';

const AddButton: React.FC<IconButtonProps> = (props) => {
  const { colors } = useTheme();

  return (
    <IconButton label="Add" {...props}>
      <Icon color={colors.gray[500]} name="plus" size="16px" />
    </IconButton>
  );
};

export default AddButton;
