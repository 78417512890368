import React from 'react';
import { useHistory, useParams } from '@routing/router';

import { useDeleteChatBotConversations, useFetchChatBotConversations } from '@api/chatbot';
import invalidateCache from '@api/invalidateCache';
import Box from '@components/Box';
import DeleteConfirmationModal from '@components/Modal/DeleteConfirmationModal';
import Text from '@components/Text';
import TreeV2 from '@components/Tree/Tree.v2';
import Icon from '@components/UI/Icon';
import { useModal } from '@context/Modal';
import theme from '@styles/theme';
import stripSpaces from '@utils/stripSpaces';

import HierarchyLoadingSkeleton from '../../HierarchyLoadingSkeleton';
import ChatBotHierarchyItem from '../ChatBotHierarchyItem';
import DeleteChatBotHierarchyItemModal from '../DeleteChatBotHierarchyItemModal';

import ChatBotEmptyState from './ChatBotEmptyState';
import {
  StyledChatBotHierarchyClearAllButton,
  StyledChatBotHierarchyNewChatButton,
} from './ChatBotHierarchy.styles';

export const ERROR_MESSAGE = 'Sorry, something went wrong.';

const query = stripSpaces(`{
  title,
  guid
}`);

const ChatBotHierarchy: React.FC = () => {
  const { MODAL_IDS, checkModalOpened, closeModal, getModalContent, openModal } = useModal();
  const history = useHistory();

  const { guid } = useParams<{ guid: string }>();

  const { data, isError, isLoading } = useFetchChatBotConversations({
    params: {
      query,
    },
  });

  const { isLoading: isLoadingDeletion, mutate: deleteChats } = useDeleteChatBotConversations({
    onSuccess: () => {
      invalidateCache((keys) => [keys.chatbot.all]);
      closeModal(MODAL_IDS.deleteChatbotConversations);
      history.push('/chatbot');
    },
  });

  const deleteChatBotHierarchyItemModalProps = getModalContent(MODAL_IDS.deleteChatbotConversation);

  if (isLoading) {
    return <HierarchyLoadingSkeleton />;
  }

  const areResultsReady = !isLoading && Boolean(data?.results?.length);

  return (
    <>
      <Box
        compDisplay="flex"
        flex={1}
        flexDirection="column"
        overflow="hidden"
        pb={4.5}
        position="relative"
      >
        {isLoading && <HierarchyLoadingSkeleton nesting={false} />}
        {!isLoading && (
          <StyledChatBotHierarchyNewChatButton
            onClick={() => history.push('/chatbot/')}
            startIconName="plus"
            startIconSize="16px"
            variant="outlined"
          >
            New Chat
          </StyledChatBotHierarchyNewChatButton>
        )}
        <Box compDisplay="flex" compHeight="100%" flexDirection="column" overflow="auto">
          {areResultsReady && (
            <TreeV2
              allowCaret={() => false}
              allowDataLoad={() => false}
              getKey={(item) => item.guid}
              highlightedKeys={guid ? [guid] : ['no_guid']}
              isFlatHierarchy
              renderLabel={({ original }) => (
                <ChatBotHierarchyItem guid={original.guid} name={original.title} useNewLayout />
              )}
              treeData={data?.results}
              useNewLayout
            />
          )}
          {!isLoading && !data?.results?.length && <ChatBotEmptyState />}
          {!isLoading && isError && (
            <Text color="inherit" fontSize={theme.typography.fontSizes.body2} px={2}>
              {ERROR_MESSAGE}
            </Text>
          )}
        </Box>
        {areResultsReady && (
          <StyledChatBotHierarchyClearAllButton
            onClick={() => openModal(MODAL_IDS.deleteChatbotConversations)}
            startIcon={<Icon name="refresh" />}
          >
            Clear the chat history
          </StyledChatBotHierarchyClearAllButton>
        )}
      </Box>
      {checkModalOpened(MODAL_IDS.deleteChatbotConversations) && (
        <DeleteConfirmationModal
          loading={isLoadingDeletion}
          onClose={() => closeModal(MODAL_IDS.deleteChatbotConversations)}
          onRemove={() => deleteChats(null)}
          title="Clear the Chat?"
        >
          This will erase all chat history.
        </DeleteConfirmationModal>
      )}
      {checkModalOpened(MODAL_IDS.deleteChatbotConversation) &&
        deleteChatBotHierarchyItemModalProps && (
          <DeleteChatBotHierarchyItemModal
            guid={deleteChatBotHierarchyItemModalProps.guid}
            name={deleteChatBotHierarchyItemModalProps.name}
            onClose={() => closeModal(MODAL_IDS.deleteChatbotConversation)}
          />
        )}
    </>
  );
};

export default ChatBotHierarchy;
