import React from 'react';

import { useFetchMentions } from '@api/mentions';
import Box from '@components/Box';
import UnexpectedError from '@components/Error/UnexpectedError';
import Text from '@components/Text';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import { MetadataData } from '@models/MetadataModel';
import theme from '@styles/theme';
import { Filter } from '@utils/index';
import stripSpaces from '@utils/stripSpaces';

import MentionedByTable from './MentionedByTable';

interface MentionedBySectionProps {
  data?: MetadataData;
}

export const FILTER_OPTIONS: Filter.FilterOptions = {
  order: 'source_name',
  page_size: 20,
  query: stripSpaces(`{
    guid,
    source
  }`),
  sortColumn: 'source_name',
  sortDirection: 'ascending',
};

const SEARCH_CONFIG: { [key: string]: keyof Filter.FilterOptions } = {
  description: 'search_source_description',
  name: 'search_source_name',
};

const SORT_CONFIG: { [key: string]: string } = {
  description: 'source_description',
  name: 'source_name',
  popularity: 'source_popularity',
};

const MentionedBySection: React.FC<MentionedBySectionProps> = ({ data }) => {
  // TODO: implement search and sort
  const filterService = Filter.useUpdateFilters(FILTER_OPTIONS, SEARCH_CONFIG, SORT_CONFIG, '');
  const { filter } = filterService;
  const {
    data: mentionsData,
    isError,
    isLoading,
  } = useFetchMentions({
    params: { ...Filter.setParams(filter), targets: data?.guid },
  });

  const totalPages =
    mentionsData && filter.page_size ? Math.ceil(mentionsData.count / filter.page_size) : 1;

  if (isError) {
    return <UnexpectedError />;
  }

  return (
    <Box mt={2.5}>
      <Box alignItems="center" compDisplay="flex" mb={2}>
        <Text
          as="h3"
          color="gray.700"
          fontSize={theme.typography.h4.fontSize}
          fontWeight="medium"
          m={0}
        >
          Mentioned by
        </Text>
        <Tooltip content="This is mentioned in">
          <Box ml={1}>
            <Icon color="gray.700" name="info-outline" size="16px" />
          </Box>
        </Tooltip>
      </Box>
      <MentionedByTable
        data={mentionsData?.results}
        filterService={filterService}
        isLoading={isLoading}
        itemCount={mentionsData?.count || 0}
        totalPages={totalPages}
      />
    </Box>
  );
};

export default MentionedBySection;
