import React from 'react';
import type { DataSource } from '@configs/dataSources/types';

import AirflowForm from './AirflowForm';
import BigQueryForm from './BigQueryForm';
import DatabricksForm from './DatabricksForm';
import DataStudioForm from './DataStudioForm';
import Db2Form from './Db2Form';
import DbtForm from './DbtForm';
import GlueForm from './GlueForm';
import HexForm from './HexForm';
import LookerForm from './LookerForm';
import MetabaseForm from './MetabaseForm';
import ModeForm from './ModeForm';
import MssqlForm from './MssqlForm';
import MysqlForm from './MysqlForm';
import OracleForm from './OracleForm';
import PeriscopeForm from './PeriscopeForm';
import PostgresForm from './PostgresForm';
import PowerBIForm from './PowerBIForm';
import QuickSightForm from './QuickSightForm';
import RedshiftForm from './RedshiftForm.v1';
import SalesforceForm from './SalesforceForm';
import SigmaForm from './SigmaForm';
import SnowflakeForm from './SnowflakeForm';
import TableauForm from './TableauForm';
import ThoughtSpotForm from './ThoughtSpotForm';
import { DataSourceFormProps } from './types';

const forms: PartialRecord<DataSource, React.FC<DataSourceFormProps>> = {
  airflow: AirflowForm,
  bigquery: BigQueryForm,
  data_studio: DataStudioForm,
  databricks: DatabricksForm,
  db2: Db2Form,
  dbt: DbtForm,
  glue: GlueForm,
  hex: HexForm,
  looker: LookerForm,
  metabase: MetabaseForm,
  mode: ModeForm,
  mssql: MssqlForm,
  mysql: MysqlForm,
  oracle: OracleForm,
  periscope: PeriscopeForm,
  postgres: PostgresForm,
  power_bi: PowerBIForm,
  quicksight: QuickSightForm,
  redshift: RedshiftForm,
  salesforce: SalesforceForm,
  sigma: SigmaForm,
  snowflake: SnowflakeForm,
  tableau: TableauForm,
  thoughtspot: ThoughtSpotForm,
};

export default forms;
