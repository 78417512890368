import React from 'react';

import UsageType from '@components/UsageType';

import type { SidebarTreeItemProps } from './types';

const SidebarTreeUsageCell: React.FC<SidebarTreeItemProps> = ({ direction, usageType }) => {
  return <UsageType direction={direction} types={usageType} />;
};

export default SidebarTreeUsageCell;
