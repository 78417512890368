import { useCallback, useState } from 'react';

import type { GetKeyType, TreeNodeType } from './types';

type LoadedTreeDataType = { [key: string]: any };

interface UseLoadDataArgs<T> {
  getKey: GetKeyType<T>;
  onDataLoad?: (node: any) => Promise<any>;
}

interface UseLoadDataResult {
  handleDataLoad: (node: any) => void;
  loadedTreeData: LoadedTreeDataType;
  loadingNodes: { [key: string]: boolean };
}

const useLoadData = <T>({ getKey, onDataLoad }: UseLoadDataArgs<T>): UseLoadDataResult => {
  const [loadedTreeData, setLoadedTreeData] = useState<LoadedTreeDataType>({});
  const [loadingNodes, setLoadingNodes] = useState<{ [key: string]: boolean }>({});

  const handleDataLoad = useCallback(
    async (node: TreeNodeType<T>) => {
      const key = getKey(node.original);

      if (onDataLoad && !loadedTreeData?.[key]) {
        setLoadingNodes((prev) => ({ ...prev, [key]: true }));
        try {
          const response = await onDataLoad(node);

          setLoadingNodes((prev) => ({ ...prev, [key]: false }));

          if (response) {
            setLoadedTreeData((prev) => ({ ...prev, [key]: response }));
          }
        } catch {
          setLoadingNodes((prev) => ({ ...prev, [key]: false }));
        }
      }
    },
    [loadedTreeData, onDataLoad, getKey],
  );

  return { handleDataLoad, loadedTreeData, loadingNodes };
};

export default useLoadData;
