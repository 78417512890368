import { Expose, Transform } from 'class-transformer';
import moment from 'moment';

export interface QueryErrorData {
  count: number;
  dashboardUserCount: number;
  eventOn: moment.Moment;
  issuerSource: string;
  message: string;
  source: string;
}

export class QueryErrorModel {
  @Expose({ name: 'event_on' })
  @Transform((value) => moment(value))
  eventOn!: moment.Moment;

  message!: string;

  source!: string;

  @Expose({ name: 'issuer_source' })
  issuerSource!: string;

  @Expose({ name: 'count' })
  count!: number;

  @Expose({ name: 'dashboard_user' })
  dashboardUserCount!: number;
}
