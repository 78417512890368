import { createContext, useContext, useEffect, useRef } from 'react';

import { useFetchSearchById } from '@api/search';
import { SearchModel } from '@api/search/SearchModel';

interface MentionsContextProps {
  checkDeniedAccess: (id: string) => boolean;
  getIsFetching: (id: string) => boolean;
  getMentionFromCacheById: (id: string) => SearchModel;
  isError: boolean;
  searchMentionById: (id: string) => void;
}

export const FetchedMentionsContext = createContext<MentionsContextProps>(undefined as never);

export const mentionsProviderErrorMessage =
  'useFetchedMentions must be used within <FetchedMentionsProvider>.';

interface UseFetchedMentionsResults {
  getMentionFromCacheById: (id: string) => SearchModel;
  isAccessDenied: boolean;
  isError: boolean;
  isFetching: boolean;
  mention?: SearchModel;
}

export const useFetchedMentions = (id?: string): UseFetchedMentionsResults => {
  const mentionsContext = useContext(FetchedMentionsContext);

  if (!mentionsContext) {
    throw new Error(mentionsProviderErrorMessage);
  }

  const { checkDeniedAccess, getIsFetching, getMentionFromCacheById, isError, searchMentionById } =
    mentionsContext;
  const searchMentionByIdRef = useRef(searchMentionById);

  /**
   * The hook is used solely to set cacheTime to infinity.
   * Bulk fetching occurs in the FetchedMentions context, after which we manually set the data.
   * However, we cannot define a specific cache policy for ['search', id] key
   * as react-query lacks this functionality.
   */
  useFetchSearchById(id ?? '', {
    cacheTime: Infinity,
    enabled: false,
    params: {
      guids: id ?? '',
    },
  });

  useEffect(() => {
    if (id) {
      searchMentionByIdRef.current(id);
    }
  }, [id]);

  const isFetching = id ? getIsFetching(id) : false;
  const mention = id ? getMentionFromCacheById(id) : undefined;

  return {
    getMentionFromCacheById,
    isAccessDenied: id ? checkDeniedAccess(id) : false,
    isError: !isFetching && !mention && isError,
    isFetching,
    mention,
  };
};
