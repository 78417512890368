import React, { KeyboardEvent, useCallback, useMemo, useState } from 'react';

import Box from '@components/Box';
import Button from '@components/Button/Button';
import Tabs from '@components/Tabs';
import theme from '@styles/theme';

import { handlePlainTextKeyDown } from '../InlinePlainTextEditor/InlinePlainTextEditor.utils';

import { StyledFooterBox } from './MarkdownRichTextEditor.styles';
import { MarkdownRichTextEditorProps } from './MarkdownRichTextEditor.types';
import PreviewMarkdownRichTextEditor from './PreviewMarkdownRichTextEditor';
import WriteMarkdown from './WriteMarkdown';

const editorHeight = {
  block: '200px',
  inline: 'auto',
};

const footerPadding = {
  bottom: {
    block: 1.5,
    inline: 1,
  },
  top: {
    block: 1.5,
    inline: 1,
  },
};

const MarkdownRichTextEditor: React.FC<MarkdownRichTextEditorProps> = ({
  allowedElements,
  disableSaveButton,
  footerComponent,
  initialValue = '',
  onCancel,
  onChange,
  onSave,
  variant = 'block',
}) => {
  const isInlineEditor = variant === 'inline';
  const [value, setValue] = useState(initialValue);

  const handleValueChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setValue(e.target.value);
      onChange?.(e);
    },
    [onChange],
  );

  const handleSave = () => {
    onSave?.(value || '');
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLTextAreaElement>) => {
      if (isInlineEditor) {
        handlePlainTextKeyDown(event, {
          onPressEnter: () => {
            onSave?.(value);
          },
          onPressEsc: () => {
            onCancel?.();
          },
        });
      }
    },
    [isInlineEditor, onCancel, onSave, value],
  );

  const tabsConfig = useMemo(() => {
    return [
      {
        // eslint-disable-next-line react/no-unstable-nested-components
        component: () => (
          <WriteMarkdown
            onChange={handleValueChange}
            onKeyDown={handleKeyDown}
            value={value}
            variant={variant}
          />
        ),
        id: 'write-markdown',
        label: 'Write',
      },
      {
        // eslint-disable-next-line react/no-unstable-nested-components
        component: () => (
          <PreviewMarkdownRichTextEditor
            allowedElements={allowedElements}
            markdown={value}
            variant={variant}
          />
        ),
        id: 'preview-markdown',
        label: 'Preview',
      },
    ];
  }, [allowedElements, handleKeyDown, handleValueChange, value, variant]);

  return (
    <Box data-testid="markdown-editor">
      <Box mb={isInlineEditor ? 0 : 2} position="relative">
        {!isInlineEditor && (
          <Box
            compDisplay="flex"
            data-testid="markdown-editor-actions"
            flexWrap="nowrap"
            gap={1}
            position="absolute"
            right={theme.space(0.25)}
            zIndex={1}
          >
            <Button compSize="sm" onClick={onCancel} variant="outlined">
              Cancel
            </Button>
            <Button compSize="sm" disabled={disableSaveButton} onClick={handleSave}>
              Save
            </Button>
          </Box>
        )}
        <Box minHeight={editorHeight[variant]}>
          <Tabs config={tabsConfig} subTabs />
        </Box>
      </Box>
      {footerComponent && (
        <StyledFooterBox pb={footerPadding.bottom[variant]} pt={footerPadding.top[variant]}>
          {footerComponent}
        </StyledFooterBox>
      )}
    </Box>
  );
};

export default MarkdownRichTextEditor;
