import React, { useMemo } from 'react';
import { useLocation } from '@routing/router';

import Box from '@components/Box';
import Text from '@components/Text';
import TreeV2 from '@components/Tree/Tree.v2';
import useNewLayout from '@hooks/useNewLayout';
import { HierarchyData } from '@models/HierarchyModel';

import SettingsSidebarHierarchyItem from './SettingsSidebarHierarchyItem';

interface SettingsSectionProps {
  items: HierarchyData[];
  title?: React.ReactNode;
}

const SettingsSection: React.FC<SettingsSectionProps> = ({ items, title }) => {
  const { pathname } = useLocation();
  const { shouldUseNewLayout } = useNewLayout();

  const highlightedItemData = useMemo(() => {
    const getHighlightedItemData = (
      hierarchyItems: HierarchyData[],
      parents: string[] = [],
    ): { key: string | null; parents: string[] } => {
      let result: { key: string | null; parents: string[] } = { key: null, parents: [] };
      hierarchyItems.some((item) => {
        if (pathname.includes(item.guid)) {
          result = { key: item.guid, parents };
          return true;
        }
        /*
         * Highlight the Users item when the user is on the Users page
         * or any Profile page.
         */
        if (item.guid === '/admin/users' && pathname.includes('/profiles/')) {
          result = { key: item.guid, parents };
          return true;
        }
        if (item.children) {
          result = getHighlightedItemData(item.children, [...parents, item.guid]);
          return result.key !== null;
        }
        return false;
      });
      return result;
    };

    return getHighlightedItemData(items);
  }, [items, pathname]);

  return (
    <Box mb={3}>
      <Box mb={title ? 0.5 : 0} px={title ? 1.5 : 0}>
        {title && (
          <Text color="gray.700" fontSize="body2" fontWeight="medium">
            {title}
          </Text>
        )}
      </Box>
      <TreeV2
        allowDataLoad={() => false}
        expandedKeys={[...highlightedItemData.parents]}
        getKey={(item) => item.guid}
        highlightedKeys={[highlightedItemData.key ?? pathname]}
        renderLabel={({ original }) => {
          return <SettingsSidebarHierarchyItem item={original} />;
        }}
        showIndentationLines={shouldUseNewLayout}
        treeData={items}
        useNewLayout
      />
    </Box>
  );
};

export default SettingsSection;
