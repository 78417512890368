import React from 'react';

import client from '@api';
import Box from '@components/Box';
import { useUserContext } from '@context/User';

import SettingsSection from './SettingsSection';
import {
  dataManagerHierarchy,
  selectStarHierarchy,
  userSettingsHierarchy,
} from './SettingsSidebar.config';
import useAdminHierarchy from './useAdminHierarchy';

const SettingsSidebar: React.FC = () => {
  const { hasEditPermissions, isOrgAdmin, organization } = useUserContext();
  const adminHierarchy = useAdminHierarchy();
  const accessRestricted =
    organization?.isTrialExpired === true || organization?.isSubscriptionEnded === true;

  return (
    <Box compHeight="100%" mt={0.5} overflowX="hidden" overflowY="auto">
      {!accessRestricted && <SettingsSection items={userSettingsHierarchy} />}
      {isOrgAdmin && <SettingsSection items={adminHierarchy} title="Admin" />}
      {!accessRestricted && hasEditPermissions && (
        <SettingsSection items={dataManagerHierarchy} title="Data Manager" />
      )}
      {!accessRestricted && client.isStaff() && (
        <SettingsSection items={selectStarHierarchy} title="Select Star" />
      )}
    </Box>
  );
};

export default SettingsSidebar;
