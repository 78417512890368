import React, { useMemo } from 'react';

import Wizard from '@components/Wizard/Wizard';

import MonteCarloFinishingStep from './MonteCarloFinishingStep';
import MonteCarloFormStep from './MonteCarloFormStep';

interface MonteCarloConfigurationWizardProps {
  guid?: string;
  onClose: () => void;
}

const MonteCarloConfigurationWizard = ({ guid, onClose }: MonteCarloConfigurationWizardProps) => {
  const steps = useMemo(
    () => [
      {
        // eslint-disable-next-line react/no-unstable-nested-components
        Content: () => <MonteCarloFormStep guid={guid} />,
        id: '0',
        title: 'Add Connection Information',
      },
      {
        Content: MonteCarloFinishingStep,
        id: '1',
        title: 'Finish',
      },
    ],
    [guid],
  );

  return <Wizard onClose={onClose} onDone={onClose} steps={steps} title="Connect Monte Carlo" />;
};

export default MonteCarloConfigurationWizard;
