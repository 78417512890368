import React from 'react';

import Box from '@components/Box';

import { colors } from './constants';

const LegendLabel = (legend: string) => (
  <Box as="span" color={colors.legend} fontFamily="primary" fontSize="body2">
    {legend}
  </Box>
);

export default LegendLabel;
