import { SearchModel } from '@api/search/SearchModel';

export const defaultPlainTextMetricDescription = (selectedMeasure?: SearchModel) => `Definition:
  ${
    selectedMeasure?.description ||
    'The Core_Metrics01 is Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam lacinia elit velit. Aenean rutrum, lorem vitae suscipit consectetur, nisl neque faucibus sapien, ac viverra augue lectus non metus. Maecenas ut nulla sem.'
  }
  Business Questions to Answer:
  How is our business doing overall?
  Customer Revenue over time
  How it’s calculated:
  Revenue = Sum (Total Sales)
  Aggregated by Day, Week, and Month`;

export const defaultMetricDescription = (selectedMeasure?: SearchModel) => {
  const data = [
    {
      children: [
        {
          text: 'Definition:',
        },
      ],
      type: 'h4',
    },
    {
      children: [
        {
          text:
            selectedMeasure?.description ||
            'The Core_Metrics01 is Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam lacinia elit velit. Aenean rutrum, lorem vitae suscipit consectetur, nisl neque faucibus sapien, ac viverra augue lectus non metus. Maecenas ut nulla sem.',
        },
      ],
      type: 'paragraph',
    },
    {
      children: [
        {
          text: '',
        },
      ],
      type: 'paragraph',
    },
    {
      children: [
        {
          text: 'Business Questions to Answer:',
        },
      ],
      type: 'h4',
    },
    {
      children: [
        {
          children: [
            {
              text: 'How is our business doing overall?',
            },
          ],
          type: 'li',
        },
        {
          children: [
            {
              text: 'Customer Revenue over time',
            },
          ],
          type: 'li',
        },
      ],
      level: 0,
      type: 'ul',
    },
    {
      children: [
        {
          text: '',
        },
      ],
      type: 'paragraph',
    },
    {
      children: [
        {
          text: 'How it’s calculated:',
        },
      ],
      type: 'h4',
    },
    {
      children: [
        {
          text: 'Revenue = Sum (Total Sales)',
        },
        {
          children: [
            {
              text: '',
            },
          ],
          type: 'br',
        },
        {
          text: 'Aggregated by Day, Week, and Month',
        },
      ],
      type: 'paragraph',
    },
  ];
  return JSON.stringify(data);
};

export const defaultPlainTextRepresentedAs = (selectedMeasure?: SearchModel) =>
  selectedMeasure?.searchName || '';

export const defaultMetricRepresentedAs = (selectedMeasure?: SearchModel) => {
  if (!selectedMeasure) return '';

  const data = [
    {
      children: [
        {
          children: [
            {
              text: selectedMeasure.searchName || '',
            },
          ],
          mention: selectedMeasure,
          type: 'mention',
        },
        { text: '' },
      ],
      type: 'paragraph',
    },
  ];

  return JSON.stringify(data);
};
