import styled from '@emotion/styled';

export const H1Element = styled.h1`
  font-size: 30px !important;
  font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  line-height: ${({ theme }) => theme.typography.lineHeights.md};
  margin: 0;
`;

export const H2Element = styled.h2`
  font-size: 23px !important;
  font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  line-height: ${({ theme }) => theme.typography.lineHeights.md};
  margin: 0;
`;

export const H3Element = styled.h3`
  font-size: 20px !important;
  font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  line-height: ${({ theme }) => theme.typography.lineHeights.md};
  margin: 0;
`;

export const H4Element = styled.h4`
  font-size: 18px !important;
  font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  line-height: ${({ theme }) => theme.typography.lineHeights.md};
  margin: 0;
`;

export const H5Element = styled.h5`
  font-size: 16px !important;
  font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  line-height: ${({ theme }) => theme.typography.lineHeights.md};
  margin: 0;
`;

export const H6Element = styled.h6`
  font-size: 14px !important;
  font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  line-height: ${({ theme }) => theme.typography.lineHeights.md};
  margin: 0;
`;
