import { useFetchJob } from '@api/jobs';

import JobsTabContent from './JobsTabContent';

export default {
  Content: JobsTabContent,
  CustomTitle: undefined,
  OverviewCustomContent: undefined,
  enableNotifications: true,
  fetchHook: useFetchJob,
  metadataObjectButtonToolbarConfig: {
    buttons: ['external' as const, 'notify' as const],
  },
  query: undefined,
};
