import React, { useCallback } from 'react';

/**
 * Tracks selection of items in a collection. Supports single & multi select
 * modes (with shift key).
 *
 * depracated use @react-table useRowSelect
 *
 * @param collection a collection of elements on which the selection is made.
 *                   Used for multi select to determine items
 *                   in range <begin, end>
 * @param initialSelection a collection of elements that are should be selected
 *                         after initialization.
 * @returns `{isSelected, selectedItems, toggleSelect, toggleSelectAll, resetSelection}`
 *            methods to interact with selection
 *          - `isSelected(item: T): boolean` - check if item is selected
 *          - `selectedItems: Set<T>` - set of all selected
 *          - `toggleSelect(item: T, index: number, isShift: boolean)` - handle select
 *          - `toggleSelectAll()` - select/unselect all items
 *          - `resetSelection(selection: T[])` - reset selection to provided list of selected items
 */
export default function useMultiselect<T>(collection: T[], initialSelection: T[] = []) {
  const [anchor, setAnchor] = React.useState<number>();
  const [selectedItems, setSelectedItems] = React.useState<Set<T>>(new Set(initialSelection));

  /**
   * Check if item is selected.
   *
   * @param item an item to check
   * @returns true if @param item is selected
   */
  const isSelected = useCallback(
    (item: T) => {
      return selectedItems.has(item);
    },
    [selectedItems],
  );

  /**
   * Select/un-select a single item or select a range of items (with shift).
   *
   * @param item clicked item
   * @param index clicked item index in the list
   * @param isShift true to use range selection (from previously clicked item)
   */
  const toggleSelect = useCallback(
    (item: T, index: number, isShift: boolean) => {
      if (isShift && anchor !== undefined) {
        // Multi select wiht shift
        const rangeBegin = Math.min(anchor, index);
        const rangeEnd = Math.max(anchor, index);

        const items = collection.slice(rangeBegin, rangeEnd + 1);

        const newSelectedItems = new Set(selectedItems);
        items.forEach((i) => newSelectedItems.add(i));
        setSelectedItems(newSelectedItems);
      } else {
        setAnchor(index);
        const itemSelected = selectedItems.has(item);
        if (!itemSelected) {
          const newSelectedItems = new Set(selectedItems);
          newSelectedItems.add(item);
          setSelectedItems(newSelectedItems);
        } else {
          const newSelectedItems = new Set(selectedItems);
          newSelectedItems.delete(item);
          setSelectedItems(newSelectedItems);
        }
      }
    },
    [anchor, collection, selectedItems],
  );

  const toggleSelectAll = useCallback(
    (items: T[]) => {
      const allSelected = !!items.length && items.every((i) => isSelected(i));
      if (allSelected) {
        // Unselect all
        const newSelectedItems = new Set(selectedItems);
        items.forEach((i) => newSelectedItems.delete(i));
        setSelectedItems(newSelectedItems);
      } else {
        // Select all
        const newSelectedItems = new Set(selectedItems);
        items.forEach((i) => newSelectedItems.add(i));
        setSelectedItems(newSelectedItems);
      }
    },
    [selectedItems, isSelected],
  );

  const resetSelection = useCallback((selection: T[] = []) => {
    setSelectedItems(new Set([...selection]));
  }, []);

  return { isSelected, selectedItems, toggleSelect, toggleSelectAll, resetSelection };
}
