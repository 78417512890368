import { RbackRolesResponse } from '@api/rbacPolicy/RbackRolesModel';
import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import useMutation, { UseMutationOptions } from '@hooks/useMutation';
import fetchClient from '@lib/fetchClient';
import { PaginatedResponse } from '@models/PaginatedResponse';

import paginatedTransform from '../paginatedTransform';

import { RBACPolicyModel } from './rbacPolicyModel';

export const policyCacheKeys = {
  policies: ['rbac'],
  policy: (id: string) => ['rbac', id],
  roles: ['rbac', 'roles'],
};

export const usePostRbacPolicy = (options?: UseMutationOptions<RBACPolicyModel>) => {
  return useMutation<RBACPolicyModel>({
    ...options,
    method: 'POST',
    url: '/rbac/',
  });
};

export const useFetchRbacPolicies = (
  options?: UseFetchOptions<PaginatedResponse<RBACPolicyModel>>,
) => {
  return useFetch<PaginatedResponse<RBACPolicyModel>>({
    ...options,
    queryKey: [...policyCacheKeys.policies, options?.params],
    select: paginatedTransform(RBACPolicyModel),
    url: '/rbac/',
  });
};

export const useFetchRbacRoles = (
  options?: UseFetchOptions<RbackRolesResponse, { guids: string[] }>,
) => {
  return useFetch<RbackRolesResponse>({
    ...options,
    queryKey: [...policyCacheKeys.roles, options?.params],
    url: '/rbac/roles/',
  });
};

export const usePatchRbacPolicy = (id: string, options?: UseMutationOptions<RBACPolicyModel>) => {
  return useMutation<RBACPolicyModel>({
    ...options,
    method: 'PATCH',
    onSuccess: (data, variables, context) => {
      fetchClient.invalidateQueries(policyCacheKeys.policies);
      options?.onSuccess?.(data, variables, context);
    },
    url: `/rbac/${id}/`,
  });
};

export const useDeleteRbacPolicy = (id: string, options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    method: 'DELETE',
    url: `/rbac/${id}/`,
  });
};
