import { createContext, useContext } from 'react';

import { MetadataObjectType } from '@atoms';
import { DataSourceTypesType } from '@models/DataSourceCredentials';
import DataTypesModel from '@models/DataTypesModel';

export interface MetadataObjContextProps {
  dataSourceGuid?: string;
  dataSourceType?: DataSourceTypesType;
  dataTypes?: DataTypesModel;
  objectType?: MetadataObjectType;
}

export const MetadataObjContext = createContext<MetadataObjContextProps>({});

export const useMetadataObjectContext = () => {
  const metadataObjContext = useContext(MetadataObjContext);

  if (metadataObjContext === undefined) {
    throw new Error(`useMetadataObjectContext must be used within <MetadataObjectProvider>.`);
  }

  return metadataObjContext;
};

export default useMetadataObjectContext;
