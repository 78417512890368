import { Expose, Type } from 'class-transformer';

import { DataSourceData, DataSourceModel } from '@models/DataSourceModel';

export interface LookMLModelData {
  dataSource: DataSourceData;
  guid: string;
  label: string;
  name: string;
  projectName: string;
}

export class LookMLModel {
  static objectType: string = 'lookmlmodel';

  objectType: string = LookMLModel.objectType;

  static typeDisplay: string = 'LookMLModel';

  typeDisplay: string = LookMLModel.typeDisplay;

  guid!: string;

  name!: string;

  label!: string;

  @Expose({ name: 'data_source' })
  @Type(() => DataSourceModel)
  dataSource!: DataSourceModel;

  @Expose({ name: 'project_name' })
  projectName!: string;
}
