import styled from '@emotion/styled';

import Resizable from '@components/Resizable';
import Modal from '@components/UI/Modal';

export const StyledPageColumnTemplate = styled(Modal)`
  border-radius: 6px 0 0 6px;
  height: 100%;
  margin-left: auto;

  ${({ theme }) => theme.media.down('md')`
    border-radius: 6px;
    width: calc(100% - 2rem);
    height: calc(100% - 2rem);
    margin-left: unset;
  `}
`;

StyledPageColumnTemplate.defaultProps = {
  overflow: 'auto',
  size: 'unset',
};

export const StyledPageColumnTemplateResize = styled(Resizable)`
  padding: ${({ theme }) => theme.space(2.5)};
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.down('md')`
    width: 100%!important;

    .resizable-line {
      &:hover,
      &:active {
        background-color: transparent!important;
        cursor: default!important;
      }
    }
  `}
`;
