import React from 'react';

import Box from '@components/Box';
import Icon, { IconProps } from '@components/UI/Icon';
import theme from '@styles/theme';

import { StyledDescription, StyledIconWrapper, StyledTitle } from './TrialInfoItem.styles';

interface TrialInfoItemProps {
  description: string;
  icon: IconProps['name'];
  title: string;
}

const TrialInfoItem: React.FC<TrialInfoItemProps> = ({ description, icon, title }) => {
  return (
    <Box>
      <Box compDisplay="flex">
        <StyledIconWrapper
          alignItems="center"
          backgroundColor="white"
          borderRadius="4px"
          compDisplay="flex"
          compHeight="50px"
          compWidth="50px"
          flexShrink={0}
          justifyContent="center"
        >
          <Icon color={theme.colors.v1.primary[700]} name={icon} size="32px" />
        </StyledIconWrapper>
        <Box compDisplay="flex" flexDirection="column" pl={3}>
          <StyledTitle as="h3" fontSize="24px" fontWeight="semibold" lineHeight={1.4} m={0}>
            {title}
          </StyledTitle>
          <StyledDescription as="h3" lineHeight={1.4} m={0}>
            {description}
          </StyledDescription>
        </Box>
      </Box>
    </Box>
  );
};

export default TrialInfoItem;
