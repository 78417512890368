import React, { useState } from 'react';

import { useFetchCollections } from '@api/collections';
import ExpandCollapseButton from '@components/ExpandCollapseButton';

import SubCollectionsCard from './SubCollectionCard';
import {
  StyledSubCollectionsSection,
  StyledSubCollectionsSectionContainer,
  StyledSubCollectionsSectionExpansionButtonContainer,
} from './SubCollectionsSection.styles';

interface SubCollectionsSectionProps {
  children?: React.ReactNode;
  guid: string;
}

const NUMBER_OF_FIRST_PAGE_ELEMENTS = 8;

const SubCollectionsSection = ({ children, guid }: SubCollectionsSectionProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { data, isError, isLoading } = useFetchCollections({
    params: {
      parent: guid,
    },
  });

  if (isLoading || isError || data?.results.length === 0) {
    return <div>{children}</div>;
  }

  const { results = [] } = data ?? {};

  const firstPageElements = results.slice(0, NUMBER_OF_FIRST_PAGE_ELEMENTS);
  const lastElements = results.slice(NUMBER_OF_FIRST_PAGE_ELEMENTS);

  return (
    <StyledSubCollectionsSection>
      <StyledSubCollectionsSectionContainer data-testid="sub-collections">
        {firstPageElements.map((collection) => (
          <SubCollectionsCard key={collection.guid} collection={collection} />
        ))}
        {isExpanded &&
          lastElements.map((collection) => (
            <SubCollectionsCard key={collection.guid} collection={collection} />
          ))}
        {lastElements.length > 0 && (
          <StyledSubCollectionsSectionExpansionButtonContainer>
            <ExpandCollapseButton
              onClick={() => setIsExpanded((current) => !current)}
              state={isExpanded ? 'expanded' : 'collapsed'}
            >
              {isExpanded ? 'Show less collections' : 'Show all sub-collections'}
            </ExpandCollapseButton>
          </StyledSubCollectionsSectionExpansionButtonContainer>
        )}
      </StyledSubCollectionsSectionContainer>
      {children}
    </StyledSubCollectionsSection>
  );
};

export default SubCollectionsSection;
