import { Expose } from 'class-transformer';

class AnalyticsOrganizationMetricsModel {
  static objectType: string = 'analyticsorganizationmetrics';

  objectType: string = AnalyticsOrganizationMetricsModel.objectType;

  @Expose({ name: 'columns_count' })
  columnsCount!: number;

  @Expose({ name: 'columns_empty_desc_count' })
  columnsWithEmptyDescriptionCount!: number;

  @Expose({ name: 'comments_count' })
  commentsCount!: number;

  @Expose({ name: 'documents_count' })
  docsCount!: number;

  @Expose({ name: 'tables_count' })
  tablesCount!: number;

  @Expose({ name: 'tables_empty_desc_count' })
  tablesWithEmptyDescriptionCount!: number;

  @Expose({ name: 'tables_empty_owners_count' })
  tablesWithEmptyOwner!: number;
}

export default AnalyticsOrganizationMetricsModel;
