import React from 'react';
import styled from '@emotion/styled';
import { Grid, Segment, Table } from 'semantic-ui-react';

import { OrganizationSSOModel } from '@api/sso/OrganizationSSOModel';
import { dateTimeFormat } from '@utils/moment';

const SectionHeader = styled.div`
  color: #2c2e36;
  font-size: 0.8125rem;
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
  margin-bottom: 0.75rem;
`;
const SectionValue = styled.div`
  color: #2c2e36;
  font-size: 0.8125rem;
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  margin-bottom: 0.75rem;
`;

interface Props {
  connections: OrganizationSSOModel[];
}

const AdminSSOTable: React.FC<Props> = ({ connections }) => {
  return (
    <Segment>
      {connections && connections.length > 0 ? (
        connections.map((connection) => {
          return (
            <Grid key={connection.guid} columns={2}>
              <Grid.Column>
                <SectionHeader>Domain</SectionHeader>
                <SectionHeader>SSO Connection</SectionHeader>
                <SectionHeader>Connected By</SectionHeader>
                <SectionHeader>Type</SectionHeader>
                <SectionHeader>Connection State</SectionHeader>
                <SectionHeader>Last Update</SectionHeader>
              </Grid.Column>
              <Grid.Column>
                <SectionValue>{connection.domains[0].domain || 'no domain'}</SectionValue>
                <SectionValue>{connection.name || 'no name'}</SectionValue>
                <SectionValue>{connection.userCreatedBy?.email || 'unknown'}</SectionValue>
                <SectionValue>{connection.connectionType || 'notype'}</SectionValue>
                <SectionValue>{connection.connectionState || 'init'}</SectionValue>
                <SectionValue>
                  {connection.updatedOn?.format(dateTimeFormat()) || 'never'}
                </SectionValue>
              </Grid.Column>
            </Grid>
          );
        })
      ) : (
        <Table.Row>
          <Table.Cell colSpan="2">No SSO Connections.</Table.Cell>
        </Table.Row>
      )}
    </Segment>
  );
};

export default AdminSSOTable;
