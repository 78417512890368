import React from 'react';

import type { DatasetModel } from '@api/datasets/DatasetModel.v1';
import { useFetchMetadataComments } from '@api/metadata';
import { isNotificationAllowed } from '@components/Modal/NotifyUsersModal/utils';
import BiColumnsTabV1 from '@components/TabContent/BiColumnsTabV1';
import DiscussionTab from '@components/TabContent/DiscussionTab';
import TabbedLineage from '@components/TabContent/TabbedLineage';
import type { TabRoutesConfig } from '@components/TabsRouter';
import { useMetadataObjectContext } from '@context/MetadataObject';
import { useUserContext } from '@context/User';
import MetadataTabsRouter from '@pages/MetadataObjectPage/MetadataTabsRouter';
import wrapString from '@utils/wrapString';

import type { TabContentProps } from '../types';

interface DatasetsTabContentProps extends Pick<TabContentProps, 'guid' | 'overviewTabConfig'> {
  data?: DatasetModel;
}

const DatasetsTabContent: React.FC<DatasetsTabContentProps> = ({
  data,
  guid,
  overviewTabConfig,
}) => {
  const { data: commentData } = useFetchMetadataComments(guid);
  const { dataSources, organization, user } = useUserContext();
  const { dataSourceGuid, dataTypes } = useMetadataObjectContext();
  const isDataSourceEditable = Boolean(dataSources?.[dataSourceGuid ?? '']?.settings?.isEditable);
  const userDefaultTab = user?.settings.defaultTableDashboardTab;

  const tabsRouterConfig: TabRoutesConfig[] = [
    { ...overviewTabConfig, default: userDefaultTab === 'overview' },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => (
        <BiColumnsTabV1
          datasetGuid={guid}
          dataSourceType={dataTypes?.dataSourceType!}
          isDataSourceEditable={isDataSourceEditable}
        />
      ),
      default: userDefaultTab === 'columns_charts',
      label: 'Fields',
      route: '/fields',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => (
        <TabbedLineage
          guid={guid}
          itemMetadata={data}
          objectType="table"
          showDownstreamDashboards={false}
          sourceTables
        />
      ),
      contentOffset: 1.6,
      label: 'Lineage',
      route: '/lineage',
    },
    ...(organization?.settings?.useDiscussions
      ? [
          {
            // eslint-disable-next-line react/no-unstable-nested-components
            component: () => (
              <DiscussionTab
                enableNotifications={isNotificationAllowed(dataTypes?.dataSourceType!)}
                guid={guid}
              />
            ),
            contentOffset: 2,
            label: `Discussion ${wrapString(commentData?.length)}`,
            path: '/discussion/:itemId?',
            route: '/discussion',
          },
        ]
      : []),
  ];

  return <MetadataTabsRouter config={tabsRouterConfig} />;
};

export default DatasetsTabContent;
