import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

interface PortalProps {
  children: JSX.Element | null;
  disable?: boolean;
  selector?: string;
}

/**
 * The Portal component renders its children into a DOM node that exists
 * outside of current component hierarchy.
 */
const Portal = ({ children, disable = false, selector = 'body' }: PortalProps) => {
  const ref = useRef<Element | null>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.querySelector(selector);
    setMounted(true);
  }, [selector]);

  if (disable) {
    return children;
  }

  const container = mounted && ref.current ? ref.current : document.body;

  return createPortal(children, container);
};

export default Portal;
