import React, { useState } from 'react';
import markdownToTxt from 'markdown-to-text';

import Highlighter from '@components/Highlighter';
import type { TypedCell } from '@components/Table/Cells/types';
import truncateDescription from '@utils/truncateDescription';

import { ExcerptTextContainer, ShowMoreDescriptionButton } from './ExcerptText.styles';

const getMarkdownMaxLength = (markdownText: string, maxExcerptLength: number): number => {
  const plainTextLength = markdownToTxt(markdownText).length;
  const markdownLength = markdownText.length;
  return markdownLength - plainTextLength + maxExcerptLength;
};

interface ExcerptTextChildProps {
  value: string;
}

interface ExcerptTextProps {
  children?: ({ value }: ExcerptTextChildProps) => React.ReactNode;
  enabled?: boolean;
  isMarkdown?: boolean;
  isSuggestion?: boolean;
  lineBreak?: boolean;
  maxLength?: number;
  searchTerm?: string;
  value: string | null;
}

const ExcerptText: React.FC<Partial<TypedCell> & ExcerptTextProps> = ({
  children,
  column,
  enabled = true,
  globalFilter,
  isMarkdown,
  isSuggestion,
  lineBreak,
  maxLength = 100,
  searchTerm,
  value,
}) => {
  const [isShowMoreEnabled, setShowMoreEnabled] = useState(false);

  if (value === null) {
    return null;
  }

  const finalMaxLength = isMarkdown ? getMarkdownMaxLength(value, maxLength) : maxLength;
  const isShowMoreButtonVisible = `${value}`?.substr(finalMaxLength) !== '' && enabled;
  const finalValue =
    isShowMoreButtonVisible && !isShowMoreEnabled
      ? truncateDescription(value, maxLength, searchTerm)
      : value;

  return (
    <ExcerptTextContainer isSuggestion={isSuggestion} lineBreak={lineBreak}>
      {typeof children === 'function' ? (
        children?.({ value: finalValue })
      ) : (
        <Highlighter
          autoEscape
          searchWords={column?.filterValue?.split(/[.| ]+/) || globalFilter?.split(/[.| ]+/) || []}
          textToHighlight={finalValue || ''}
        />
      )}{' '}
      {isShowMoreButtonVisible && (
        <ShowMoreDescriptionButton
          onClick={(e) => {
            e.stopPropagation();
            setShowMoreEnabled(!isShowMoreEnabled);
          }}
        >
          {isShowMoreEnabled ? 'Show less' : 'Show more'}
        </ShowMoreDescriptionButton>
      )}
    </ExcerptTextContainer>
  );
};

export default ExcerptText;
