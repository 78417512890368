import paginatedTransform from '@api/paginatedTransform';
import rawTransform from '@api/rawTransform';
import { useTagContext } from '@context/Tag';
import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import type { UseMutationOptions } from '@hooks/useMutation';
import useMutation from '@hooks/useMutation';
import { HierarchyData, HierarchyModel } from '@models/HierarchyModel';
import type { OwnersUpdateData } from '@models/Owners';
import { PaginatedResponse } from '@models/PaginatedResponse';
import type { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';

import * as cacheKeys from './cacheKeys';
import { TagModel } from './TagModel';

export const fetchTagsHierarchySelect = rawTransform(HierarchyModel);
export const useFetchTagsHierarchy = (options?: UseFetchOptions<HierarchyData[]>) => {
  const { tags } = useTagContext();

  return useFetch<HierarchyData[]>({
    ...options,
    queryKey: [...cacheKeys.hierarchy, tags],
    select: fetchTagsHierarchySelect,
    url: '/tags/hierarchy/',
  });
};

export const fetchTagsSelect = paginatedTransform(TagModel);
export const useFetchTags = (options?: UseFetchOptions<PaginatedResponse<TagModel>>) => {
  return useFetch<PaginatedResponse<TagModel>>({
    ...options,
    queryKey: [...cacheKeys.all, options?.params],
    select: fetchTagsSelect,
    url: '/tags/',
  });
};

const fetchTagSelect = rawTransform(TagModel);
export const useFetchTag = (id: string, options?: UseFetchOptions<TagModel>) => {
  return useFetch<TagModel>({
    ...options,
    queryKey: [...cacheKeys.tag(id), options?.params],
    select: fetchTagSelect,
    url: `/tags/${id}/`,
  });
};

export const useFetchTagChild = (
  id: string,
  options?: UseFetchOptions<PaginatedResponse<TagModel>>,
) => {
  return useFetch<PaginatedResponse<TagModel>>({
    ...options,
    queryKey: cacheKeys.tagChild(id),
    select: fetchTagsSelect,
    url: `/tags/?parents=${id}`,
  });
};

export const usePatchTags = (options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    method: 'PATCH',
    url: '/tags/items/',
  });
};

export const usePatchTagModels = (options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    method: 'PATCH',
    url: '/tags/',
  });
};

export const usePatchTag = (id?: string, options?: UseMutationOptions<TagModel>) => {
  return useMutation({
    ...options,
    method: 'PATCH',
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(fetchTagSelect(data), variables, context);
    },
    url: id ? `/tags/${id}/` : undefined,
  });
};

export const usePostTag = (options?: UseMutationOptions<TagModel>) => {
  return useMutation({
    ...options,
    method: 'POST',
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(fetchTagSelect(data), variables, context);
    },
    url: '/tags/',
  });
};

export const useDeleteTag = (id: string, options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    method: 'DELETE',
    url: `/tags/${id}/`,
  });
};

export const usePatchTagsOwners = (
  options?: UseMutationOptions<never, EnhancedErrorResult, OwnersUpdateData>,
) => {
  return useMutation({
    ...options,
    method: 'PATCH',
    url: `/tags/owners/`,
  });
};
