interface ItemRandomGeneratorParams<T> {
  itemsList?: Array<T>;
  representativeText?: string;
}

const itemRandomGenerator = <T = string>({
  itemsList = [],
  representativeText,
}: ItemRandomGeneratorParams<T>) => {
  const getRandomItemFromList = (string: string | undefined, colors: Array<T>): T | undefined => {
    let hash = 0;
    if (!string) return undefined;
    if (string.length === 0) return undefined;
    for (let i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
      // Convert to 32bit integer
      hash &= hash;
    }
    const index = Math.abs(hash % colors.length);
    return colors[index];
  };

  return getRandomItemFromList(representativeText, itemsList);
};

export default itemRandomGenerator;
