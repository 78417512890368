import React, { useMemo } from 'react';

import { useFetchTableauFields } from '@api/tableau';
import { TableauFieldModel } from '@api/tableau/TableauFieldModel';
import BulkButtons from '@components/BulkButtons';
import TabError from '@components/TabContent/TabError';
import TableauFieldsTable from '@components/Table/TableauFieldsTable';
import { TabContentProps } from '@components/Tabs/types';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import useBulkEditSelected from '@hooks/useBulkEditSelected';
import { Filter } from '@utils';
import stripSpaces from '@utils/stripSpaces';

const searchConfig: { [key: string]: keyof Filter.FilterOptions } = {
  description: 'search_description',
  name: 'search_name',
};

const sortConfig: { [key: string]: string } = {
  description: 'description',
  name: 'name',
  popularity: 'popularity',
};

const query = stripSpaces(`{
	guid,
  external_id,
  name,
  description,
  richtext_description,
  suggested_description,
  suggested_description_source_object,
  field_type,
  data_type,
  role_type,
  table,
  popularity,
  is_metric,
  is_mention,
  tagged_items,
  query,
  search_name,
  calculation,
  description_source,
  ai_description,
  ingested_description,
  suggested_description_source,
  user_description,
  data_types
}`);

const defaultConfig = {
  order: '-popularity',
  page_size: 100,
  query,
};

interface TableauFieldsTabProps extends TabContentProps {
  guid: string;
  isDataSourceEditable: boolean;
}

const TableauFieldsTab: React.FC<TableauFieldsTabProps> = ({
  guid,
  isDataSourceEditable,
  stickyHeader,
}) => {
  const { isEditable: isObjectEditable } = useObjectPermissionsContext({ id: guid });
  const FilterService = Filter.useUpdateFilters(defaultConfig, searchConfig, sortConfig);
  const { filter } = FilterService;

  const effectiveConfig = useMemo(() => {
    return { params: Filter.setParams(filter) };
  }, [filter]);

  const { data, isError } = useFetchTableauFields(guid, effectiveConfig);

  const totalPages = data && filter.page_size ? Math.ceil(data.count / filter.page_size) : 1;

  const { selected } = useBulkEditSelected<TableauFieldModel>({
    defaultSelected: data?.results,
  });

  if (isError) return <TabError />;

  return (
    <>
      <BulkButtons
        canUseBulkAssist={isObjectEditable}
        guid={guid}
        isDataSourceEditable
        selectedEditableItems={selected.items}
        selectedItems={selected.items}
      />
      <TableauFieldsTable
        data={data?.results}
        filterService={FilterService}
        guid={guid}
        isDataSourceEditable={isDataSourceEditable}
        itemCount={data?.count}
        stickyHeader={stickyHeader}
        totalPages={totalPages}
      />
    </>
  );
};

export default React.memo<TableauFieldsTabProps>(TableauFieldsTab);
