import styled from '@emotion/styled';
import VendorHighlighter from 'react-highlight-words';

export interface StyledHighlighterProps {
  bgColor?: string;
  breakWords?: boolean;
}

export const StyledHighlighter = styled(VendorHighlighter)`
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: ${({ breakWords }) => (breakWords ? 'break-all' : 'normal')};

  mark {
    background: transparent;
    font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
    padding: 0;
    background-color: ${({ bgColor, theme }) => bgColor ?? theme.colors.highlight};
    color: inherit;
    font-weight: inherit;
  }
`;
