import type { UseFetchOptions } from '@hooks/useFetch';
import useFetch from '@hooks/useFetch';
import { PaginatedResponse } from '@models/PaginatedResponse';

import paginatedTransform from '../paginatedTransform';

import { CustomAttributeWithValueModel } from './CustomAttributeWithValueModel';

export const customAttributesWithValueCacheKeys = {
  customAttributesWithValue: (id: string) => ['customAttributesWithValues', id],
  customAttributesWithValues: ['customAttributesWithValues'],
};

const selectCustomAttributesWithValue = paginatedTransform(CustomAttributeWithValueModel);
export const useFetchCustomAttributesWithValue = (
  id: string,
  options?: UseFetchOptions<PaginatedResponse<CustomAttributeWithValueModel>>,
) => {
  return useFetch<PaginatedResponse<CustomAttributeWithValueModel>>({
    ...options,
    queryKey: customAttributesWithValueCacheKeys.customAttributesWithValue(id),
    url: `/custom-attributes-with-value/${id}/`,
    select: selectCustomAttributesWithValue,
  });
};
