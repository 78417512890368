import { Expose, Type } from 'class-transformer';

import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import { breadcrumbsToLabelList, breadcrumbsToList } from '@components/Breadcrumbs';
import { urlFor } from '@utils/routing';

import { Repository } from './Repository';

export class LookMLProjectModel {
  selected: boolean = false;

  guid!: string;

  name!: string;

  @Type(() => Repository)
  repository!: Repository;

  @Expose({ name: 'should_ingest' })
  shouldIngest: boolean | undefined;

  @Type(() => BreadcrumbModel)
  breadcrumbs?: BreadcrumbModel[];

  get breadcrumbList() {
    return breadcrumbsToList(this.breadcrumbs);
  }

  get breadcrumbLabelList() {
    const url = urlFor(this, false, '');
    return breadcrumbsToLabelList(this.name, url, this.breadcrumbs);
  }
}
