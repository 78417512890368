import styled from '@emotion/styled';

import Box from '@components/Box';

export const EDITOR_MIN_HEIGHT = {
  block: '80px',
  inline: '91px',
};

export const StyledFooterBox = styled(Box)`
  border-top: 1px solid ${({ theme }) => theme.colors.v1.gray[200]};
`;
