/* eslint-disable import/prefer-default-export */
import type { Formatter } from 'recharts/types/component/DefaultTooltipContent';

import type { BaseValue, Name } from '../types';

export const formatValue: Formatter<BaseValue, Name> = (value, name, item) => {
  const result: [BaseValue, Name] = [value, name];

  if (name === 'Credit') {
    result[0] = item.payload.formattedTotalCreditsUsed;
  }

  if (name === 'Query Count') {
    result[0] = item.payload.formattedTotalRun;
  }

  return result;
};
