import React from 'react';

import { usePatchDataSource, usePostDataSource } from '@api/dataSources';
import { useIngestDataSource } from '@api/ingestion';
import invalidateCache from '@api/invalidateCache';
import Alert from '@components/Alert';
import Box from '@components/Box';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import InputLabel from '@components/UI/Form/InputLabel/InputLabel';
import { useWizard } from '@components/Wizard/Wizard/useWizard';
import { DataSourceModel } from '@models/DataSourceModel';
import theme from '@styles/theme';

import MonteCarloDocumentationAlert from './MonteCarloDocumentationAlert';

const INPUT_GRID_TEMPLATE = `${theme.space(12)} 1fr`;

export interface MonteCarloFormStepProps {
  guid?: string;
}

interface MonteCarloFormValues {
  key: string;
  secret: string;
}

const MonteCarloFormStep: React.FC<MonteCarloFormStepProps> = ({ guid }) => {
  const [dataSource, setDataSource] = React.useState<DataSourceModel | undefined>();
  const [shouldInitIngestion, setShouldInitIngestion] = React.useState<boolean>(false);

  const {
    error: postError,
    isLoading: isPostLoading,
    mutate: mutatePost,
  } = usePostDataSource({
    onSuccess: (data) => {
      setDataSource(data);
      setShouldInitIngestion(true);
    },
  });

  const {
    error: patchError,
    isLoading: isPatchLoading,
    mutate: mutatePatch,
  } = usePatchDataSource(guid ?? '', {
    onSuccess: () => {
      setShouldInitIngestion(true);
    },
    params: {
      meta_types: 'data_quality',
    },
  });

  const { error: initIngestionError, isFetching: isInitIngestionFetching } = useIngestDataSource(
    dataSource?.guid ?? guid ?? '',
    {
      enabled: shouldInitIngestion,
      keepPreviousData: false,
      onSuccess: () => {
        handleSuccess();
      },
      staleTime: 0,
    },
  );

  const mutate = guid ? mutatePatch : mutatePost;

  const { handleChange, values } = useForm<MonteCarloFormValues>({
    initialValues: {
      key: '',
      secret: '',
    },
  });

  const wizardNextButtonAction = () => {
    const { key, secret } = values;

    const payload = {
      credentials: {
        key,
        secret,
      },
      name: 'monte_carlo',
      type: 'monte_carlo',
    };

    mutate(payload);
  };
  const { goNextStep } = useWizard({
    nextButtonAction: wizardNextButtonAction,
  });

  const handleSuccess = () => {
    goNextStep();
    invalidateCache((keys) => [keys.dataSources.dataSourcesInfo]);
  };

  const error = postError ?? patchError ?? initIngestionError;
  const isLoading = isPostLoading || isPatchLoading || isInitIngestionFetching;
  const errorMessage = error?.data?.message || error?.data?.credentials?.[0];

  return (
    <Box compDisplay="flex" flexDirection="column">
      <MonteCarloDocumentationAlert />
      {(error && error.status === 500) ||
        (error?.data?.non_field_errors?.length > 0 && (
          <Alert mt={2} title="Unexpected error" type="error">
            An unexpected error occurred. Please retry your action, or contact
            support@getselectstar.com if the issue persists.
          </Alert>
        ))}
      {errorMessage && (
        <Alert mt={2} type="error">
          {errorMessage}
        </Alert>
      )}
      {error?.data?.credentials?.description && (
        <Alert mt={2} title={error.data?.credentials.title} type="error">
          {error.data?.credentials.description}
        </Alert>
      )}
      <Box mt={1.75}>
        <Form isLoading={isLoading}>
          <Box
            compDisplay="grid"
            flexDirection="column"
            gap={1.5}
            gridTemplateRows="repeat(2, min-content)"
          >
            <InputLabel
              compDisplay="grid"
              fontWeight="semibold"
              gridTemplateColumns={INPUT_GRID_TEMPLATE}
            >
              API Key
              <Box>
                <Input
                  error={error?.data?.key}
                  helperText={error?.data?.key}
                  name="key"
                  onChange={handleChange}
                  placeholder="API Key"
                  type="password"
                  value={values.key}
                />
              </Box>
            </InputLabel>
            <InputLabel
              compDisplay="grid"
              fontWeight="semibold"
              gridTemplateColumns={INPUT_GRID_TEMPLATE}
            >
              Secret
              <Box>
                <Input
                  error={error?.data?.secret}
                  helperText={error?.data?.secret}
                  name="secret"
                  onChange={handleChange}
                  placeholder="Secret"
                  type="password"
                  value={values.secret}
                />
              </Box>
            </InputLabel>
          </Box>
        </Form>
      </Box>
    </Box>
  );
};

export default MonteCarloFormStep;
