import styled from '@emotion/styled';

import Box from '@components/Box';
import Text from '@components/Text';
import themeValues from '@styles/theme';

import { IndicatorState } from './types';

export const StyledWizard = styled(Box)`
  border-radius: ${({ theme }) => theme.radius.md};
  background: ${({ theme }) => theme.colors.white};
`;

interface StyledWizardStepProps {
  $state?: IndicatorState;
}

const stepIndicatorColor: Record<IndicatorState, string> = {
  current: themeValues.colors.primary,
  incomplete: themeValues.colors.gray[300],
  passed: themeValues.colors.inlineInputOutline,
};

export const StyledWizardStepIndicator = styled(Box)<StyledWizardStepProps>`
  width: ${({ theme }) => theme.space(3)};
  height: ${({ theme }) => theme.space(3)};
  border-radius: ${({ theme }) => theme.space(3)};
  background: ${({ $state = 'current' }) => stepIndicatorColor[$state]};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.typography.fontSizes.sm};
  font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
`;

const stepTextColor: Record<IndicatorState, string> = {
  current: themeValues.colors.text,
  incomplete: themeValues.colors.gray[600],
  passed: themeValues.colors.text,
};

const stepTextWeight: Record<IndicatorState, number> = {
  current: themeValues.typography.fontWeights.regular,
  incomplete: themeValues.typography.fontWeights.regular,
  passed: themeValues.typography.fontWeights.medium,
};

export const StyledWizardStepText = styled(Text)<StyledWizardStepProps>`
  color: ${({ $state = 'current' }) => stepTextColor[$state]};
  font-weight: ${({ $state = 'current' }) => stepTextWeight[$state]};
  font-size: ${({ theme }) => theme.typography.fontSizes.body1};
`;
