import React from 'react';
import styled from '@emotion/styled';

import Icon from '@components/UI/Icon';

const TabErrorStyles = styled.div`
  padding: 40px;
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  text-align: center;

  h2 {
    font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
  }

  svg {
    display: inline;
    margin-top: -0.2em;
  }
`;

const TabError = () => {
  return (
    <TabErrorStyles>
      <h2>Sorry, something went wrong</h2>
      <p>
        We had a problem loading this tab. This error will be automatically reported but please let
        us know if it persists.
      </p>
      <p>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            document.location.reload();
          }}
        >
          Try again? <Icon color="currentColor" name="refresh" />
        </a>
      </p>
    </TabErrorStyles>
  );
};

export default TabError;
