import React from 'react';
import { RenderElementProps } from 'slate-react';

import { StyledTableElement } from './TableElements.styles';

const TableElement = ({ attributes, children }: RenderElementProps) => {
  return <StyledTableElement {...attributes}>{children}</StyledTableElement>;
};

export default TableElement;
