import { BIFolderModel } from '@api/bifolder/BIFolderModel';
import type { UseFetchOptions } from '@hooks/useFetch';
import useFetch from '@hooks/useFetch';
import { PaginatedResponse } from '@models/PaginatedResponse';

import paginatedTransform from '../paginatedTransform';
import rawTransform from '../rawTransform';

export const folderCacheKeys = {
  folder: (id: string) => ['bi', 'folders', id],
  folders: ['bi', 'folders'],
};

const fetchFoldersSelect = paginatedTransform(BIFolderModel);
export const useFetchFolders = (options: UseFetchOptions) => {
  return useFetch<PaginatedResponse<BIFolderModel>>({
    ...options,
    queryKey: [...folderCacheKeys.folders, options?.params],
    select: fetchFoldersSelect,
    url: `/bi/folders/`,
  });
};

const fetchFolderSelect = rawTransform(BIFolderModel);
export const useFetchFolder = (id: string, options?: UseFetchOptions<BIFolderModel>) => {
  return useFetch<BIFolderModel>({
    ...options,
    queryKey: folderCacheKeys.folder(id),
    select: fetchFolderSelect,
    url: `/bi/folders/${id}/`,
  });
};
