import styled from '@emotion/styled';

export const StyledRelatedTableList = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3.75rem;
  padding: ${({ theme }) => theme.space(2, 0)};
  gap: ${({ theme }) => theme.space(2)};
`;

export const StyledRelatedTableListItem = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.space(1.3)};
  border: ${({ theme }) => `1px solid ${theme.colors.hierarchyHoverBackground}`};
  border-radius: ${({ theme }) => theme.radius.md};
  cursor: pointer;
  font-size: ${({ theme }) => theme.typography.fontSizes.default};
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  color: ${({ theme }) => theme.colors.text};
  font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
  word-break: break-all;
  gap: ${({ theme }) => theme.space(0.5)};
`;
