import React from 'react';

import { ColumnModel } from '@api/columns/ColumnModel';
import { ObjectType } from '@api/types';
import Box from '@components/Box';
import { breadcrumbsToSimplifiedLabelList } from '@components/Breadcrumbs/Breadcrumbs.utils';
import CopyFullPathButton from '@components/CopyFullPathButton';
import IconButton from '@components/IconButton';
import ColumnConstraint from '@components/TabContent/ColumnsTab/ColumnTable/ColumnConstraint';
import type { LinkedCellProps } from '@components/Table/Cells/LinkedCell';
import LinkedCell from '@components/Table/Cells/LinkedCell';
import { TypedCell } from '@components/Table/Cells/types';
import Tooltip from '@components/Tooltip';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';

import {
  StyledColumnNameTableCell,
  StyledColumnNameTableCellSection,
} from './ColumnNameTableCell.styles';

export interface ColumnNameTableCellProps
  extends Pick<LinkedCellProps, 'breadcrumbFixedUrl' | 'customUrl' | 'showBreadcrumbs'> {
  columnItem: ColumnModel;
  editPk?: () => void;
  hideConstraints?: boolean;
  isDataSourceEditable: boolean;
  openColumnsPage?: () => void;
  pageObjectType?: ObjectType;
  showConstraint?: boolean;
  showCopyFullPathButton?: boolean;
}

const ColumnNameTableCell: React.FC<ColumnNameTableCellProps & Partial<TypedCell>> = ({
  columnItem,
  customUrl,
  editPk,
  hideConstraints,
  isDataSourceEditable,
  openColumnsPage,
  pageObjectType,
  showBreadcrumbs,
  showConstraint = true,
  showCopyFullPathButton = true,
  ...other
}) => {
  const { breadcrumbs, fullName: columnFullName, guid, name, routePath } = columnItem ?? {};
  const { isEditable: isObjectEditable } = useObjectPermissionsContext({ id: guid });
  const isEditable = isDataSourceEditable && isObjectEditable;
  const showAddButton = isEditable && !hideConstraints && editPk;
  const simplifiedBreadcrumbs = breadcrumbsToSimplifiedLabelList({
    breadcrumbs,
    label: name,
    pageObjectType,
    url: routePath,
  });

  const item = {
    ...columnItem,
    breadcrumbLabelList: simplifiedBreadcrumbs,
  };

  return (
    <StyledColumnNameTableCell withInlineButton={Boolean(showAddButton ?? columnFullName)}>
      <StyledColumnNameTableCellSection>
        <LinkedCell
          {...other}
          customUrl={customUrl}
          item={item}
          onClick={openColumnsPage}
          showBreadcrumbs={showBreadcrumbs}
          showDataTypeTooltip
          showIcon
          showNameTooltip
        />
        <Box alignItems="center" compDisplay="flex">
          {showAddButton && (
            <Tooltip content="Add PK/FK Key">
              <IconButton
                className="inline-button"
                color="gray.700"
                hoverColor="gray.500"
                iconColor="currentColor"
                iconName="plus"
                iconSize="16px"
                onClick={editPk}
                variant="default"
              />
            </Tooltip>
          )}
          {showCopyFullPathButton && columnFullName && (
            <CopyFullPathButton
              className="inline-button"
              hoverColor="gray.500"
              iconColor="gray.700"
              iconSize="16px"
              text={columnFullName}
            />
          )}
        </Box>
      </StyledColumnNameTableCellSection>
      {showConstraint && (
        <ColumnConstraint
          columnItem={columnItem}
          flexWrap="wrap"
          gap={0.25}
          hideConstraints={hideConstraints}
          showUsageBadges
        />
      )}
    </StyledColumnNameTableCell>
  );
};

export default React.memo(ColumnNameTableCell);
