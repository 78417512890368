import { Expose, Type } from 'class-transformer';

import { TagModel } from '@api/tags/TagModel';
import { Reference } from '@models/Reference';

export class ExceptForModel {
  static objectType: string = 'exceptFor';

  objectType: string = ExceptForModel.objectType;

  @Expose({ name: 'except_for' })
  @Type(() => Reference)
  exceptFor?: Reference<TagModel>;
}
