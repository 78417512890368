import styled from '@emotion/styled';

import Box from '@components/Box';

export const StyledBox = styled(Box)`
  background-image: url('/images/gradient-background.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex: 1 1 auto;
  min-height: 100%;
`;
