import React, { ReactNode } from 'react';

import Box from '@components/Box';

import { colors } from '../constants';

const TooltipBox = ({ children }: { children?: ReactNode }) => {
  return (
    <Box
      backgroundColor="#ffffff"
      borderRadius="md"
      boxShadow="0px 16px 12px rgba(16, 24, 40, 0.08), 0px 4px 17px rgba(16, 24, 40, 0.08)"
      color={colors.tooltip}
      noDefault
      p={2}
    >
      {children}
    </Box>
  );
};

export default TooltipBox;
