import type { IconProps } from '@components/UI/Icon';
import theme from '@styles/theme';

export type MapObjectToAvatarProps = {
  avatar?: string;
  fullName?: string;
  guid?: string;
  isActive?: boolean;
  name?: string;
  objectType?: string;
};

export interface MapObjectToAvatar {
  guid?: string;
  icon?: Pick<IconProps, 'name' | 'color' | 'size'>;
  isActive?: boolean;
  isDeleted?: boolean;
  name?: string;
  src?: string;
}

const mapObjectToAvatar = (object: MapObjectToAvatarProps | undefined): MapObjectToAvatar => {
  const src = object?.avatar;
  const isDeleted = !object || object?.name === 'Deleted Item';

  return {
    guid: object?.guid,
    icon:
      object?.isActive === false
        ? ({ color: theme.colors.v1.gray[500], name: 'deactivated-avatar', size: '100%' } as const)
        : undefined,
    isActive: object?.isActive !== false,
    isDeleted,
    name: object?.fullName,
    src,
  };
};

export type MapObjectToAvatarReturnType = MapObjectToAvatar;

export default mapObjectToAvatar;
