import { PatchSettingsPartialUpdateBodyParams } from '@api/openapi.generated';
import useMutation, { UseMutationOptions } from '@hooks/useMutation';
import { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';

export const usePostSettingsAvatar = (options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: 'POST',
    url: `/settings/avatar-upload/`,
  });
};

export interface UsePatchUserSettingsOptions
  extends UseMutationOptions<never, EnhancedErrorResult, PatchSettingsPartialUpdateBodyParams> {}

export const usePatchUserSettings = (options?: UsePatchUserSettingsOptions) => {
  return useMutation({
    ...options,
    method: 'PATCH',
    url: '/settings/',
  });
};
