import React from 'react';
import { LOADING_TEXT } from '@constants';
import { useHistory } from '@routing/router';

import { RelatedDashboardModel } from '@api/dashboards/RelatedDashboardModel';
import { urlFor } from '@utils/routing';

import Item from './Item';
import { StyledRelatedTableList, StyledRelatedTableListItem } from './RelatedTableListStyle';

interface Props {
  items?: RelatedDashboardModel[];
}

/**
 * List dashboards
 * @deprecated use RelatedDashboardTable
 */
const RelatedDashboardsList: React.FC<Props> = ({ items }) => {
  const history = useHistory();

  return (
    <StyledRelatedTableList>
      {!!items?.length &&
        items.map((item) => {
          const url = urlFor(item.dashboard, true);
          return (
            <span
              key={item.dashboard.guid}
              className="target--control"
              onClick={() => history.push(url)}
              onKeyDown={() => history.push(url)}
              role="button"
              tabIndex={-1}
            >
              <Item dataTypes={item.dashboard.dataTypes} name={item.dashboard.name} />
            </span>
          );
        })}
      {items?.length === 0 && (
        <StyledRelatedTableListItem>None detected yet.</StyledRelatedTableListItem>
      )}
      {items === undefined && (
        <StyledRelatedTableListItem>{LOADING_TEXT}</StyledRelatedTableListItem>
      )}
    </StyledRelatedTableList>
  );
};

export default React.memo(RelatedDashboardsList);
