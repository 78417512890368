import { find } from 'lodash';

import { useFetchTeams } from '@api/teams';
import { TeamModel } from '@api/teams/TeamModel';
import { useUserContext } from '@context/User';
import stripSpaces from '@utils/stripSpaces';

export interface TeamSection {
  emptyMessage: string;
  isEmpty: boolean;
  key: string;
  teams: TeamModel[];
  title: string;
}

const query = stripSpaces(`{
  guid,
  name,
  description,
  richtext_description,
  sample_users,
  members_count
}`);

const useTeamsSections = () => {
  const { user } = useUserContext();
  const { data, isLoading } = useFetchTeams({
    params: {
      query,
    },
  });
  const teams = data?.results ?? [];
  const myTeam = find(teams, (item) => item.guid === user?.team?.guid);

  const sections: TeamSection[] = [
    {
      emptyMessage: 'No team assigned yet',
      isEmpty: Boolean(!myTeam),
      key: '1',
      teams: myTeam ? [myTeam] : [],
      title: 'My Team',
    },
    {
      emptyMessage: 'No teams created yet',
      isEmpty: teams.length === 0,
      key: '2',
      teams,
      title: 'All Teams',
    },
  ];

  return { isLoading, sections, teams };
};

export default useTeamsSections;
