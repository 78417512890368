import { useEffect, useState } from 'react';

const useComponentHover = (ref: React.RefObject<any>) => {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const setHovered = () => setIsHovered(true);
    const setNotHovered = () => setIsHovered(false);

    ref.current?.addEventListener('mouseenter', setHovered);
    ref.current?.addEventListener('mouseleave', setNotHovered);

    return () => {
      ref.current?.removeEventListener('mouseenter', setHovered);
      ref.current?.removeEventListener('mouseleave', setNotHovered);
    };
  }, [ref]);

  return { isHovered };
};

export default useComponentHover;
