import React from 'react';

import IconButton from '@components/IconButton';
import Icon from '@components/UI/Icon';
import Select from '@components/UI/Select';

import { ColumnInstance } from './types';

export const ariaLabel = 'Select Visible Columns';

interface FilterColumnsButtonProps {
  columns: ColumnInstance[];
  onClick: (id: ColumnInstance) => void;
}

const FilterColumnButton: React.FC<FilterColumnsButtonProps> = ({ columns, onClick }) => {
  const filteredColumns = columns.filter(
    (column) =>
      Boolean(column.dropdownCheckboxLabel) ||
      (typeof column.Header === 'string' && !column.disableHiding),
  );

  const availableOptions = filteredColumns.map((column) => ({
    onClick: () => {
      column.toggleHidden();
      onClick?.(column);
    },
    text: column.dropdownCheckboxLabel ?? (column.Header as string),
    value: column.id,
  }));

  const selectedOptions = filteredColumns
    .filter((column) => column.isVisible)
    .map((column) => ({
      value: column.id,
    }));

  return (
    <Select
      isMulti
      options={availableOptions}
      optionsFitAnchorWidth={false}
      renderCustomAnchor={({ anchorProps }) => (
        <IconButton {...anchorProps} aria-label={ariaLabel} hoverBackgroundColor="transparent">
          <Icon name="filter" size="16px" />
        </IconButton>
      )}
      showSelectAllButton={false}
      value={selectedOptions}
    />
  );
};

export default FilterColumnButton;
