import React, { useEffect } from 'react';
import { FilterProps } from 'react-table';

import Input from '@components/Input/Input.v1';

const DefaultColumnFilter = <T extends Record<string, unknown>>({
  column,
  columns,
  manualGlobalFilter,
  setGlobalFilter,
  showFilter,
}: FilterProps<T>) => {
  const ref = React.useRef<HTMLInputElement>(null);

  // Find the first column that can be filtered on and match with current column id.
  const isFirstFilter = column.id === columns[columns.findIndex((col) => col.canFilter)].id;

  // Focus the input if filters are showing and this is the first input.
  useEffect(() => {
    if (showFilter && isFirstFilter) {
      if (ref && ref.current) {
        ref.current.focus();
      }
    }
  }, [column.id, showFilter, isFirstFilter]);

  return (
    <Input
      ref={ref}
      aria-label={`${column.id} filter`}
      compSize="sm"
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        if (manualGlobalFilter) {
          setGlobalFilter(e.target.value || undefined);
        } else {
          column.setFilter(e.target.value || undefined);
        }
      }}
      placeholder="Filter"
      value={column.filterValue}
    />
  );
};

export default DefaultColumnFilter;
