import { CollectionIconColors } from './CollectionCard.types';

export const COLLECTION_ICON_DEFAULT_COLORS: Array<CollectionIconColors> = [
  {
    backgroundColor: '#EAFBEB',
    iconColor: '#386639',
  },
  {
    backgroundColor: '#F8F1DB',
    iconColor: '#412800',
  },
  {
    backgroundColor: '#EEEAFE',
    iconColor: '#11007D',
  },
  {
    backgroundColor: '#FDF6E7',
    iconColor: '#711271',
  },
  {
    backgroundColor: '#EAF2FE',
    iconColor: '#052A65',
  },
];
