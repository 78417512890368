import React from 'react';

import wrapString from '@utils/wrapString/wrapString';

interface CountLabelProps {
  count?: number;
  hideCount?: boolean;
  text?: string;
}

const CountLabel: React.FC<CountLabelProps> = ({ count, hideCount, text }) => {
  return (
    <>
      {text} {hideCount ? null : wrapString(count)}
    </>
  );
};

export default CountLabel;
