export interface Replacements {
  [index: string]: string | undefined;
}

const replaceAll = (template: string, replacements: Replacements): string =>
  Object.entries(replacements).reduce((acc, item) => {
    const [placeholder, replacement] = item;

    return replacement ? acc.replace(`{${placeholder}}`, replacement) : acc;
  }, template);

export default replaceAll;
