import React from 'react';

import Box from '@components/Box';
import Button from '@components/Button/Button';
import LayoutMainGrid from '@components/Layout/LayoutMainGrid';
import CSVUploadModal from '@components/Modal/CSVUploadModal';
import TitleView from '@components/UI/TitleView';
import { useModal } from '@context/Modal';
import { useUserContext } from '@context/User';
import AdminTitleView from '@pages/Admin/AdminTitleView/AdminTitleView';
import MetadataDecorator from '@utils/MetadataDecorator';

const AdminMetadataUploadPage: React.FC = () => {
  const { MODAL_IDS, checkModalOpened, closeModal, openModal } = useModal();
  const { dataSources } = useUserContext();
  const options =
    dataSources &&
    Object.values(dataSources)
      .filter(
        (ds) => ds?.type && ['snowflake', 'postgres', 'bigquery', 'redshift'].includes(ds.type),
      )
      .filter((ds) => ds?.settings?.isEditable)
      .map((ds) => ({
        text: ds?.name,
        value: ds?.guid,
      }));

  return (
    <>
      <MetadataDecorator title="Metadata Upload Admin" />
      <LayoutMainGrid>
        <AdminTitleView title="Metadata Upload" />
        <Box mb={2.5}>
          {options ? (
            <>
              <TitleView
                compSize="sm"
                mb={2}
                pt={1.3}
                title="Update field descriptions with CSV. All fields must be filled in order for descriptions to update correctly."
              />
              <Button mr={0.5} variant="outlined">
                <a
                  download="MetadataUpload.csv"
                  href={`${process.env.PUBLIC_URL}/MetadataUpload.csv`}
                  id="download-link"
                  rel="noopener noreferrer"
                  style={{ color: 'inherit' }}
                >
                  Download CSV Template
                </a>
              </Button>
              <Button onClick={() => openModal(MODAL_IDS.uploadCSV)}>Upload CSV</Button>
            </>
          ) : (
            <TitleView
              compSize="sm"
              mb={2}
              pt={1.3}
              title="Update field descriptions with CSV. This feature is not enabled."
            />
          )}
        </Box>
      </LayoutMainGrid>
      {checkModalOpened(MODAL_IDS.uploadCSV) && (
        <CSVUploadModal onClose={() => closeModal(MODAL_IDS.uploadCSV)} options={options} />
      )}
    </>
  );
};

export default AdminMetadataUploadPage;
