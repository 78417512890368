import { Expose, Type } from 'class-transformer';

import SchemataIngestionConfigModel from './SchemataIngestionConfigModel';

class DatabaseIngestionConfigModel {
  static objectType: 'databaseingestionconfig' = 'databaseingestionconfig';

  objectType = DatabaseIngestionConfigModel.objectType;

  static typeDisplay: 'Database Ingestion Config' = 'Database Ingestion Config';

  typeDisplay = DatabaseIngestionConfigModel.typeDisplay;

  guid!: string;

  name!: string;

  @Expose({ name: 'should_ingest' })
  shouldIngest!: boolean;

  @Type(() => SchemataIngestionConfigModel)
  schemata!: SchemataIngestionConfigModel[];
}

export default DatabaseIngestionConfigModel;
