import { useState } from 'react';
import { createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { withReact } from 'slate-react';

import withCodeblocksPlugin from '../Plugins/withCodeblocksPlugin';
import { withCorrectVoidBehavior } from '../Plugins/withCorrectVoidBehavior';
import withDeleteEmptyBlockPlugin from '../Plugins/withDeleteEmptyBlockPlugin';
import withImagesPlugin from '../Plugins/withImagesPlugin';
import withInlineElementsPlugin from '../Plugins/withInlineElementsPlugin';
import withInsertBreak from '../Plugins/withInsertBreak';
import withLinksPlugin from '../Plugins/withLinksPlugin';
import withListsPlugin from '../Plugins/withListsPlugin';
import withMDShortcuts from '../Plugins/withMDShortcuts';
import withTablesPlugin from '../Plugins/withTablesPlugin';
import withVoidElementsPlugin from '../Plugins/withVoidElementsPlugin';
import type { SlatePluginType } from '../RichTextEditor.types';

const DEFAULT_SLATE_PLUGINS: SlatePluginType[] = [
  withHistory,
  withReact,
  withDeleteEmptyBlockPlugin,
  withImagesPlugin,
  withTablesPlugin,
  withCorrectVoidBehavior,
  withInlineElementsPlugin,
  withListsPlugin,
  withCodeblocksPlugin,
  withVoidElementsPlugin,
  withLinksPlugin,
  withMDShortcuts,
  withInsertBreak,
];

/**
 * This is a for applying custom plugins to the slate editor. Any custom plugins we want to be applied to all editors should be added here.
 */
const useSlateEditor = (customPlugins: SlatePluginType[] = []) => {
  const plugins: SlatePluginType[] = [...DEFAULT_SLATE_PLUGINS, ...customPlugins];

  const initialEditor = plugins.reduce((prevEditor, plugin) => {
    return plugin(prevEditor);
  }, createEditor());

  const [editor] = useState(initialEditor);

  return [editor];
};

export default useSlateEditor;
