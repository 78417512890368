import React, { forwardRef } from 'react';

import NotApplicable from '@components/NotApplicable';
import type { TooltipProps } from '@components/Tooltip';
import Tooltip from '@components/Tooltip';

import type { StyledScaleIndicatorProps } from './ScaleIndicator.styles';
import { StyledScaleIndicator, StyledScaleIndicatorItem } from './ScaleIndicator.styles';

interface Color {
  color: string;
  id: number;
}

const DEFAULT_COLORS: Array<Color> = [
  { color: '#e2e9f0', id: 1 },
  { color: '#e2e9f0', id: 2 },
  { color: '#7490be', id: 3 },
  { color: '#7490be', id: 4 },
  { color: '#0c3476', id: 5 },
  { color: '#0c3476', id: 6 },
];
export const NO_SCALEINDICATOR_OBJECTS = ['document', 'metric', 'term', 'tag'];

export interface ScaleIndicatorProps extends StyledScaleIndicatorProps {
  className?: string;
  colors?: Array<Color>;
  onClick?: () => void;
  priority?: number;
  showNotApplicable?: boolean;
  style?: React.CSSProperties;
  testId?: string;
  tooltipText?: TooltipProps['content'];
}

const ScaleIndicator = forwardRef<HTMLSpanElement, ScaleIndicatorProps>(
  (
    {
      className,
      colors = DEFAULT_COLORS,
      compWidth,
      onClick,
      priority,
      showNotApplicable = true,
      style,
      testId,
      tooltipText,
      ...other
    },
    ref,
  ) => {
    if (priority === undefined || priority === 0) {
      return showNotApplicable ? <NotApplicable /> : null;
    }

    return (
      <Tooltip content={tooltipText}>
        <StyledScaleIndicator
          {...other}
          ref={ref}
          className={className}
          compWidth={compWidth}
          data-testid={testId}
          onClick={onClick}
          style={style}
        >
          {colors.map((color, index) => {
            if (index <= priority) {
              return (
                <StyledScaleIndicatorItem
                  key={color.id}
                  color={color.color}
                  data-testid="scale-item"
                />
              );
            }
            return null;
          })}
        </StyledScaleIndicator>
      </Tooltip>
    );
  },
);

export default ScaleIndicator;
