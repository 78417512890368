import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

import Box from '@components/Box';
import Text from '@components/Text';
import rootTheme from '@styles/theme';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

interface StyledExploreLoaderProps {
  delay: number;
}

export const StyledExploreLoader = styled(Box)<StyledExploreLoaderProps>`
  animation: ${fadeIn} 500ms cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation-delay: ${({ delay }) => `${delay}ms`};
`;

StyledExploreLoader.defaultProps = {
  alignItems: 'center',
  bottom: 0,
  compDisplay: 'flex',
  justifyContent: 'center',
  left: 0,
  position: 'absolute',
  right: 0,
  top: 0,
  zIndex: rootTheme.zIndex.floatingElement,
};

interface StyledExploreLoaderBackdropProps {
  isTranslucent?: boolean;
}

export const StyledExploreLoaderBackdrop = styled(Box)<StyledExploreLoaderBackdropProps>`
  opacity: ${({ isTranslucent }) => (isTranslucent ? 0.7 : 1)};
`;

StyledExploreLoaderBackdrop.defaultProps = {
  backgroundColor: 'white',
  bottom: 0,
  left: 0,
  position: 'absolute',
  right: 0,
  top: 0,
};

export const StyledExploreLoaderContent = styled(Box)``;

StyledExploreLoaderContent.defaultProps = {
  alignItems: 'center',
  compDisplay: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative',
  zIndex: 1,
};

export const StyledExploreLoaderTitle = styled(Text)``;

StyledExploreLoaderTitle.defaultProps = {
  color: 'gray.800',
  fontSize: 'h2',
  fontWeight: 'medium',
  lineHeight: 'md',
  mb: 1,
  mt: 2,
  textAlign: 'center',
};

export const StyledExploreLoaderSubtitle = styled(Text)`
  height: ${({ theme }) => theme.space(2.5)};
`;

StyledExploreLoaderSubtitle.defaultProps = {
  color: 'gray.700',
  fontSize: 'h4',
  textAlign: 'center',
};

const fadeInBottom = keyframes`
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
`;

export const StyledExploreLoaderAnimatedSubtitle = styled.span<StyledExploreLoaderProps>`
  animation: ${fadeInBottom} 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation-delay: ${({ delay }) => `${delay}ms`};
`;
