import rawKeyValueObjTransform from '@api/rawKeyValueObjTransform';
import mapDataTypesModel from '@models/DataTypesModel/DataTypesModel.v1';
import { PaginatedResponse } from '@models/PaginatedResponse';

import { GetLineageNodesListResponse, NewLineageData, NewLineageItem } from '../openapi.generated';
import { CustomAxiosMetadataConfig } from '../refreshToken';

export type NewLineageModel = ReturnType<typeof mapNewLineage>;

export type NewLineageItemModel = ReturnType<typeof mapper>;

const mapper = (value: NewLineageItem) => {
  return {
    ...value,
    metadata: {
      ...value.metadata,
      dataTypes: mapDataTypesModel(value.metadata.data_types),
    },
  };
};

export interface EnrichedNewLineageData extends NewLineageData {
  requestMetadata: CustomAxiosMetadataConfig;
}

export const mapNewLineage = (data: EnrichedNewLineageData) => {
  return {
    data: rawKeyValueObjTransform<NewLineageItem, ReturnType<typeof mapper>>(data.data, mapper),
    requestMetadata: data.requestMetadata,
  };
};

export interface EnrichedLineageNodesData extends GetLineageNodesListResponse {
  requestMetadata: CustomAxiosMetadataConfig;
}

export type PaginatedLineageNodesResponse = PaginatedResponse<NewLineageItemModel>;

export const mapNewLineageNodes = (
  data: EnrichedLineageNodesData,
): PaginatedLineageNodesResponse => {
  return {
    count: data.count,
    next: data.next ?? null,
    previous: data.previous ?? null,
    requestMetadata: data.requestMetadata,
    results: data.results.map(mapper),
  };
};
