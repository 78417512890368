import { Expose } from 'class-transformer';

export class OrganizationIndustryModel {
  static objectType: string = 'organizationIndustry';

  objectType: string = OrganizationIndustryModel.objectType;

  static typeDisplay: string = 'Organization Industry';

  typeDisplay: string = OrganizationIndustryModel.typeDisplay;

  @Expose({ name: 'industry_name' })
  industryName!: string;
}
