export type Colors = typeof colors;

export type ColorV1KeyValue = {
  [K in keyof Colors['v1']]: keyof Colors['v1'][K];
};
export type ColorV1Value = {
  [K in keyof ColorV1KeyValue]: `${K}.${ColorV1KeyValue[K]}`;
};

export type ColorV1ValueString = ColorV1Value[keyof ColorV1Value];
const colors = {
  accent: '#ffb238',
  black: '#000',
  border: '#E2E8F0',
  code: '#fcf6f0',
  darkBackground: '#261447',
  dropdownHighlight: '#F9FAFB',
  error: '#D12771',
  gray: {
    100: '#F2F5F9',
    200: '#D8DBE0',
    300: '#CACFD6',
    400: '#AEB4BB',
    500: '#8C939B',
    600: '#6F747C',
    700: '#44464A',
    800: '#2C2E36',
    calm: 'rgba(0, 0, 0, 0.54)',
    copy: 'hsla(270, 15.797828016000002%, 0%, 0.88)',
    dark: 'hsla(270, 17.119554496%, 0%, 0.92)',
  },
  hierarchyHoverBackground: '#e2e9f0',
  highlight: 'rgba(241, 203, 0, 0.3)',
  hover: '#e8f2ff',
  hyperlink: '#2E53CC',
  inlineInputOutline: '#7490be',
  inputBorderLightBlue: '#85b7d9',
  lightblue: '#33B1FF',
  lilac: '#9d7cbf',
  primary: '#0C3476',
  purple: '#5C2677',
  success: '#37b635',
  text: '#101828',
  v1: {
    error: {
      500: '#D12771',
    },
    gray: {
      100: '#F2F5F9',
      200: '#EAECF0',
      25: '#FCFCFD',
      300: '#D0D5DD',
      400: '#98A2B3',
      50: '#F9FAFB',
      500: '#667085',
      600: '#475467',
      700: '#344054',
      800: '#1D2939',
      900: '#101828',
    },
    primary: {
      100: '#ECE9FE',
      1000: '#261447',
      200: '#DDD6FE',
      25: '#FBFAFF',
      300: '#C3B5FD',
      400: '#A48AFB',
      50: '#F5F3FF',
      500: '#875BF7',
      600: '#7839EE',
      700: '#6927DA',
      800: '#5720B7',
      900: '#4C288F',
    },
  },
  violet: '#4C288F',
  warning: '#ec1818',
  white: '#fff',
} as const;

export default colors;
