import React, { useMemo, useState } from 'react';
import {
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Scatter,
  ScatterChart as BaseScatterChart,
  XAxis,
  YAxis,
  ZAxis,
} from 'recharts';

import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import formatNumber from '@utils/formatNumber';
import teamColorGenerator from '@utils/teamColorGenerator';

import { colors } from '../constants';
import { formatN } from '../utils';

import type { ScatterChartItemProps } from './ScatterChartItem';
import ScatterChartItem from './ScatterChartItem';

export interface ScatterChartProps {
  data?: Array<any>;
  isLoading?: boolean;
}

const ScatterChart: React.FC<ScatterChartProps> = ({ data, isLoading }) => {
  const [open, setOpen] = useState('');
  const filtered = useMemo(
    () => data?.filter(({ formattedTotalCreditsUsed }) => formattedTotalCreditsUsed !== '0'),
    [data],
  );

  return (
    <ResponsiveContainer height={450} width="100%">
      {isLoading ? (
        <Box compHeight="100%" compWidth="100%" noDefault position="relative">
          <CircularLoader cover />
        </Box>
      ) : (
        <BaseScatterChart data={filtered} margin={{ bottom: 50, left: 10, right: 0, top: 7 }}>
          <CartesianGrid stroke={colors.grid} />
          <XAxis
            axisLine={false}
            dataKey="popularity.popularity"
            label={{
              dx: -23,
              dy: 20,
              fill: colors.axisLabel,
              value: 'Popularity',
            }}
            padding={{ right: 60 }}
            stroke={colors.axisText}
            tickFormatter={formatN}
            tickLine={false}
            type="number"
          />
          <YAxis
            axisLine={false}
            dataKey="totalCreditsUsed"
            label={{
              angle: -90,
              dx: 5,
              dy: -18,
              fill: colors.axisLabel,
              position: 'left',
              value: 'Credit',
            }}
            stroke={colors.axisText}
            tickFormatter={(v: number) =>
              formatNumber({ options: { notation: 'compact' }, value: v })
            }
            tickLine={false}
            type="number"
          />
          <ZAxis range={[100, 100]} />
          <Scatter
            // eslint-disable-next-line react/no-unstable-nested-components
            shape={(props: Omit<ScatterChartItemProps, 'open' | 'setOpen'>) => (
              <ScatterChartItem {...props} open={open} setOpen={setOpen} />
            )}
          >
            {filtered?.map((entry) => (
              <Cell key={entry.guid} fill={teamColorGenerator(entry.guid)} />
            ))}
          </Scatter>
        </BaseScatterChart>
      )}
    </ResponsiveContainer>
  );
};

export default ScatterChart;
