import React from 'react';

import Box from '@components/Box/Box.styles';
import Ellipsis from '@components/Ellipsis';
import OpenInNewTabButton from '@components/OpenInNewTabButton';
import PopularityCell from '@components/Table/Cells/PopularityCell';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import DataTypesModel from '@models/DataTypesModel';
import { PopularityModel } from '@models/PopularityModel';

import SourceColumnsTabRow from './SourceColumnsTabRow';

interface SourceColumnsTabColumnsRowProps {
  dataTypes?: DataTypesModel;
  name: string;
  popularity?: PopularityModel;
  routePath: string;
}

const SourceColumnsTabColumnsRow: React.FC<SourceColumnsTabColumnsRowProps> = ({
  dataTypes,
  name,
  popularity,
  routePath,
}) => {
  return (
    <SourceColumnsTabRow
      firstColumn={
        <Box alignItems="center" compDisplay="flex" pl={8}>
          <Tooltip content={dataTypes?.tooltips.dataSource}>
            <Icon name={dataTypes?.icons.dataSource!} size="16px" />
          </Tooltip>
          <Tooltip content={dataTypes?.tooltips.dataType}>
            <Icon mr={0.5} name={dataTypes?.icons.dataType!} size="16px" />
          </Tooltip>
          <Ellipsis multipleLinesNumber="2">{name}</Ellipsis>
          <OpenInNewTabButton
            url={
              process.env.REACT_APP_CHROME_EXTENSION_BUILD
                ? `${process.env.REACT_APP_SS_APP_URL}${routePath}`
                : routePath
            }
          />
        </Box>
      }
      secondColumn={<PopularityCell popularity={popularity} />}
    />
  );
};

export default SourceColumnsTabColumnsRow;
