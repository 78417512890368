import type { IconProps } from '@components/UI/Icon';

import type { DataTypesConfig, ObjectTypeConfig } from '../dataTypes/types';

export type IconDataConfig = DataTypesConfig<IconProps['name']>;

/*
 * const configExample: IconDataConfig = {
 *   dataSource: {
 *     default: 'dataSource icon',
 *     objectType: {
 *       default: 'objectType icon',
 *       dataType: 'dataType icon',
 *     },
 *   },
 * };
 */

const columIcons = {
  boolean: 'boolean',
  date: 'calendar',
  default: 'column',
  geo: 'geography',
  number: 'number',
  object: 'variant',
  string: 'text',
} as const;

export const ICONS_CONFIG_BY_OBJECT: ObjectTypeConfig<IconProps['name']> = {
  bicolumn: {
    BIT: 'boolean',
    DATETIME: 'calendar',
    DECIMAL: 'number',
    INTEGER: 'number',
    STRING: 'text',
    default: 'column',
  },
  bidashboardelement: { default: 'looker-look' },
  bidataset: { default: 'tableau-data-source' },
  bifolder: { default: 'folder' },
  column: columIcons,
  columngroup: columIcons,
  comment: { default: 'comments' },
  dashboard: { default: 'dashboard' },
  database: { default: 'database' },
  datasource: { default: 'database' },
  default: 'question',
  deleted: { default: 'close' },
  dsuser: { default: 'user' },
  folder: { default: 'folder' },
  querylogrecord: { default: 'query' },
  schema: { default: 'schema' },
  table: { default: 'table', implicit: 'implicit-table', view: 'view' },
  tablegroup: { default: 'table-stacked' },
  tag: { default: 'tag' },
  team: { default: 'team' },
  user: { default: 'user' },
};

// FE DATA SOURCES GENERATED FROM OBJECT TYPES
const iconsConfigFE: IconDataConfig = {
  app: { ...ICONS_CONFIG_BY_OBJECT, default: 'select-star-colored' },
  tag: { ...ICONS_CONFIG_BY_OBJECT, default: 'tag-outline' },
  team: { ...ICONS_CONFIG_BY_OBJECT, default: 'team' },
  user: { ...ICONS_CONFIG_BY_OBJECT, default: 'team' },
};

const iconsConfigMap: IconDataConfig = {
  airflow: { ...ICONS_CONFIG_BY_OBJECT, default: 'airflow', job: { default: 'job' } },
  aws_s3: {
    ...ICONS_CONFIG_BY_OBJECT,
    cloudobject: { default: 'folder' },
    default: 'S3',
  },
  bigquery: { ...ICONS_CONFIG_BY_OBJECT, default: 'big-query' },
  collection: {
    ...ICONS_CONFIG_BY_OBJECT,
    collection: { default: 'collection' },
    default: 'collection',
  },
  comment: { ...ICONS_CONFIG_BY_OBJECT, default: 'user' },
  data_studio: {
    ...ICONS_CONFIG_BY_OBJECT,
    bifolder: { default: 'mode-collection', folder: 'folder' },
    datastudiochart: { default: 'chart' },
    default: 'data-studio',
    page: { default: 'page' },
    reportquery: { default: 'code-block', page: 'page' },
  },
  databricks: {
    ...ICONS_CONFIG_BY_OBJECT,
    databricks_notebooks_container: { default: 'folder' },
    default: 'databricks',
    notebook: { default: 'notebook' },
  },
  db2: {
    ...ICONS_CONFIG_BY_OBJECT,
    default: 'db2',
  },
  dbt: {
    ...ICONS_CONFIG_BY_OBJECT,
    dbt_test_container: { default: 'dbt-test' },
    dbtproject: { default: 'dbt' },
    dbttest: { default: 'dbt-test' },
    default: 'dbt',
    folder: { default: 'folder' },
    table: {
      default: 'table',
      ephemeral: 'dbt-ephemeral',
      implicit: 'implicit-table',
      incremental: 'dbt-incremental',
      seed: 'dbt-seed-table',
      view: 'view',
    },
  },
  document: {
    ...ICONS_CONFIG_BY_OBJECT,
    default: 'all-docs',
    document: { default: 'document', metric: 'metric', term: 'term' },
  },
  glue: {
    ...ICONS_CONFIG_BY_OBJECT,
    cloudobject: { default: 'table', unknown: 'question' },
    default: 'glue',
    table: { default: 'table', external_table: 'external-table', implicit: 'implicit-table' },
  },
  hex: {
    ...ICONS_CONFIG_BY_OBJECT,
    default: 'hex',
    hex_folders_container: ICONS_CONFIG_BY_OBJECT.bifolder,
  },
  looker: {
    ...ICONS_CONFIG_BY_OBJECT,
    bifolder: { default: 'mode-collection', folder: 'folder', group: 'folder' },
    dashboard: { default: 'dashboard', look: 'looker-look' },
    dashboardelement: {
      chart: 'chart',
      default: 'chart',
      object: 'variant',
      string: 'text',
    },
    default: 'looker',
    explore: { default: 'looker-explore' },
    explorefield: columIcons,
    folders_container: { default: 'multiple-dashboards' },
    lookmlfield: columIcons,
    lookmlmodel: {
      default: 'folder',
    },
    lookmlproject: { default: 'folder' },
    lookmlview: { default: 'code-block' },
    models_container: { default: 'looker-explores' },
    views_container: { default: 'looker-views' },
  },
  metabase: {
    ...ICONS_CONFIG_BY_OBJECT,
    bifolder: {
      collection: 'folder',
      database: 'database',
      default: 'mode-collection',
      schema: 'schema',
      table: 'table',
    },
    dashboard: { dashboard: 'dashboard', default: 'dashboard' },
    default: 'metabase',
    metabase_folders_container: { default: 'folder' },
    metabase_metrics_container: { default: 'folder' },
    metabasecolumn: { default: 'column' },
    metabasetable: { default: 'table', implicit: 'implicit-table', metric: 'metric' },
    reportquery: { card: 'chart', default: 'code-block' },
  },
  metric: {
    ...ICONS_CONFIG_BY_OBJECT,
    default: 'all-docs',
    document: { default: 'metric' },
    metric: { default: 'metric' },
  },
  mode: {
    ...ICONS_CONFIG_BY_OBJECT,
    collections_container: { default: 'folder' },
    default: 'mode',
    reportquery: { default: 'code-block' },
  },
  monte_carlo: {
    default: 'monte-carlo',
    monitor: { default: 'monitor' },
  },
  mssql: { ...ICONS_CONFIG_BY_OBJECT, default: 'mssql' },
  mysql: { ...ICONS_CONFIG_BY_OBJECT, default: 'mysql' },
  oracle: { ...ICONS_CONFIG_BY_OBJECT, default: 'oracle' },
  periscope: {
    ...ICONS_CONFIG_BY_OBJECT,
    default: 'periscope',
  },
  postgres: {
    ...ICONS_CONFIG_BY_OBJECT,
    default: 'postgres',
    table: {
      default: 'table',
      implicit: 'implicit-table',
      materialized_view: 'dbt-materialized',
      view: 'view',
    },
  },
  power_bi: {
    ...ICONS_CONFIG_BY_OBJECT,
    dashboard: { default: 'dashboard', power_bi_report: 'report' },
    default: 'powerbi',
    power_bi_folders_container: { default: 'folder' },
    powerbicolumn: {
      binary: 'text',
      boolean: 'boolean',
      datetime: 'calendar',
      decimal: 'number',
      default: 'column',
      double: 'number',
      int64: 'number',
      measure: 'measure',
      string: 'text',
      unknown: 'column',
      variant: 'variant',
    },
    powerbidataset: { default: 'tableau-data-source', powerbidataflow: 'dataflow' },
    powerbitable: { default: 'table', implicit: 'implicit-table' },
    reportquery: { default: 'code-block', tile: 'chart' },
  },
  quicksight: {
    ...ICONS_CONFIG_BY_OBJECT,
    dashboard: { dashboard: 'report', default: 'dashboard', report: 'page' },
    default: 'quicksight',
    quicksight_folders_container: {
      default: 'folder',
    },
  },
  redshift: { ...ICONS_CONFIG_BY_OBJECT, default: 'redshift' },
  salesforce: { ...ICONS_CONFIG_BY_OBJECT, default: 'salesforce' },
  sigma: {
    ...ICONS_CONFIG_BY_OBJECT,
    bifolder: { default: 'tableau-workbooks' },
    dashboard: { default: 'tableau-workbook' },
    default: 'sigma',
    reportquery: { default: 'code-block', element: 'dashboard' },
  },
  snowflake: {
    ...ICONS_CONFIG_BY_OBJECT,
    cloudobject: { default: 'folder', unknown: 'question' },
    default: 'snowflake',
    table: {
      default: 'table',
      external_table: 'external-table',
      implicit: 'implicit-table',
      view: 'view',
    },
  },
  tableau: {
    ...ICONS_CONFIG_BY_OBJECT,
    dashboard: { default: 'dashboard', workbook: 'tableau-workbook' },
    data_sources_container: { default: 'tableau-data-sources' },
    default: 'tableau',
    projects_container: { default: 'folder' },
    tableaucolumn: columIcons,
    tableaudatasource: { default: 'tableau-data-source' },
    tableaufield: {
      boolean: 'boolean',
      date: 'calendar',
      default: 'tableau-views',
      number: 'number',
      object: 'variant',
      string: 'text',
    },
    tableautable: {
      'custom-sql': 'query',
      default: 'table',
      'excel-direct': 'tableau-data-excel',
      implicit: 'implicit-table',
    },
    tableauview: { dashboard: 'dashboard', default: 'tableau-views', sheet: 'tableau-worksheet' },
  },
  term: {
    ...ICONS_CONFIG_BY_OBJECT,
    default: 'all-docs',
    document: { default: 'term' },
    term: { default: 'term' },
  },
  thoughtspot: {
    ...ICONS_CONFIG_BY_OBJECT,
    bifolder: {
      answer: 'thoughtspot-answer',
      default: 'question',
      liveboard: 'thoughtspot-liveboard',
      one_to_one_logical: 'table',
      sql_view: 'view',
      worksheet: 'thoughtspot-worksheet',
    },
    dashboard: {
      answer: 'thoughtspot-answer',
      default: 'thoughtspot-liveboard',
      liveboard: 'thoughtspot-liveboard',
    },
    default: 'thoughtspot',
    reportquery: {
      column: 'column',
      default: 'chart',
      visual: 'chart',
    },
    thoughtspot_folders_container: { default: 'thoughtspot-liveboard' },
    thoughtspotcolumn: {
      BOOL: columIcons.boolean,
      DATE: columIcons.date,
      DATE_TIME: columIcons.date,
      DOUBLE: columIcons.number,
      FLOAT: columIcons.number,
      INT64: columIcons.number,
      TIME: columIcons.date,
      TIMESTAMP: columIcons.date,
      VARCHAR: columIcons.string,
      default: columIcons.default,
    },
    thoughtspottable: {
      default: 'table',
      implicit: 'implicit-table',
      one_to_one_logical: 'table',
      sql_view: 'view',
      worksheet: 'thoughtspot-worksheet',
    },
    ts_data_container: { default: 'tableau-data-sources' },
  },
  ...iconsConfigFE,
};

export default iconsConfigMap;
