import React, { memo } from 'react';

import Box from '@components/Box';
import IconButton from '@components/IconButton';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import { MetadataModel } from '@models/MetadataModel';

export const getSuggestionTooltip = (item?: MetadataModel) => {
  if (item) {
    const crumbs = item?.breadcrumbs?.map((crumb) => crumb.targetName) || [];
    crumbs?.push(item?.name);
    return `Auto-filled from: ${crumbs?.join(' / ')}`;
  }
};

interface SuggestedDescriptionSourceObjectProps {
  sourceObj?: MetadataModel;
}

const SuggestedDescriptionSourceObject: React.FC<SuggestedDescriptionSourceObjectProps> = ({
  sourceObj,
}) => {
  return (
    <Tooltip content={getSuggestionTooltip(sourceObj)}>
      <Box as="span" compDisplay="inline-flex">
        <IconButton>
          <Icon color="#D7CDE4" hoverColor="#4C288F" name="auto-description" size="14px" />
        </IconButton>
      </Box>
    </Tooltip>
  );
};

export default memo(SuggestedDescriptionSourceObject);
