import styled from '@emotion/styled';

import Box from '@components/Box';
import Icon from '@components/UI/Icon';

export const StyledChatBotInitialPromptsArrowIcon = styled(Icon)`
  transition: 0.1s ease;
`;

export const StyledChatBotInitialPromptsItem = styled(Box)`
  outline: none;
  background-color: ${({ theme }) => theme.colors.v1.primary[50]};
  transition: background-color 0.1s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.v1.primary[100]};

    ${StyledChatBotInitialPromptsArrowIcon} {
      transform: translateX(3px);
    }
  }
`;
