import React from 'react';

import Box from '@components/Box';
import Text from '@components/Text';
import Icon from '@components/UI/Icon';
import theme from '@styles/theme';

const InlineUnexpectedError = () => {
  return (
    <Box compDisplay="flex" gap={0.5}>
      <Icon name="error" />
      <Text color={theme.colors.warning} fontSize={theme.typography.fontSizes.body1}>
        Sorry, something went wrong. This error will be automatically reported but please let us
        know if it persists.
      </Text>
    </Box>
  );
};

export default InlineUnexpectedError;
