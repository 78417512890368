interface CreateEnhancedErrorArgs {
  code?: string;
  data?: any;
  message: string;
  status?: number;
  statusText?: string;
}

export type EnhancedErrorResult = Error & CreateEnhancedErrorArgs;

const createEnhancedError = ({
  code,
  data,
  message,
  status,
}: CreateEnhancedErrorArgs): EnhancedErrorResult => {
  const error: EnhancedErrorResult = new Error(message) as EnhancedErrorResult;

  error.code = code;
  error.status = status;
  error.data = data;

  /**
   * We keep this field for backward compatibility.
   * As a target solution, we have to use a message field instead.
   */
  error.statusText = message;

  return error;
};

export default createEnhancedError;
