import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import type { UseMutationOptions } from '@hooks/useMutation';
import useMutation from '@hooks/useMutation';
import type { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';

import rawTransform from '../rawTransform';

import * as cacheKeys from './cacheKeys';
import { UserModel } from './UserModel';

export const fetchMyUserSelect = rawTransform(UserModel);
export const useFetchMyUser = (options?: UseFetchOptions<UserModel>) => {
  return useFetch<UserModel>({
    ...options,
    queryKey: cacheKeys.me,
    select: fetchMyUserSelect,
    url: '/user/me/',
  });
};

export interface UsePatchMyUserOptions
  extends UseMutationOptions<never, EnhancedErrorResult, { is_agreed_to_terms: boolean }> {}

export const usePatchMyUser = (options?: UsePatchMyUserOptions) => {
  return useMutation({
    ...options,
    method: 'PATCH',
    url: '/user/me/',
  });
};

export const usePatchUserVerification = (code: string, options?: UseMutationOptions<UserModel>) => {
  return useMutation<UserModel>({
    ...options,
    method: 'PATCH',
    url: `/user/verification/${code}/`,
  });
};

const postUserSelect = rawTransform(UserModel);
export const usePostUser = (options?: UseMutationOptions<UserModel>) => {
  return useMutation<UserModel>({
    ...options,
    method: 'POST',
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(postUserSelect(data), variables, context);
    },
    url: '/user/',
  });
};
