import React from 'react';
import { RouterLink } from '@routing/router';

import Box from '@components/Box/Box.styles';
import Link from '@components/Link';
import Icon from '@components/UI/Icon';
import { useUserContext } from '@context/User';
import { HierarchyData } from '@models/HierarchyModel';

import getUrl from '../Tree/getUrl';

interface DataSourceHierarchyItemProps {
  item: HierarchyData;
}

const SettingsSidebarHierarchyItem: React.FC<DataSourceHierarchyItemProps> = ({ item }) => {
  const iconName = item.icon ?? item.dataTypes?.icons.dataSource;
  const { organization } = useUserContext();
  const showSchemataPage = organization?.settings?.showSchemataPage ?? false;
  const url = getUrl({ ...item, showSchemataPage });

  return (
    <Link
      as={RouterLink}
      color="inherit"
      compDisplay="block"
      compWidth="100%"
      fontSize="inherit"
      to={url ?? item.url ?? ''}
    >
      <Box alignItems="center" as="span" compDisplay="flex" gap={1} pl={0} py={0.75}>
        {iconName && <Icon color="currentcolor" name={iconName} size="14px" />}
        {item.name}
      </Box>
    </Link>
  );
};

export default React.memo(SettingsSidebarHierarchyItem);
