import * as d3 from 'd3';

import type { CoordinatesMap, TreeContext } from '../types';

import getColumnsCoordinates from './getColumnsCoordinates';
import { columnTableSources } from './index';

interface DrawColumnsToTableLinksArgs {
  context: TreeContext;
  displayColLinks: string[];
  linkClassType: string;
  root: d3.Selection<d3.BaseType, unknown, null, undefined>;
  tableCoordinatesMap: CoordinatesMap;
}

const drawColumnsToTableLinks = ({
  context,
  displayColLinks,
  linkClassType,
  root,
  tableCoordinatesMap,
}: DrawColumnsToTableLinksArgs) => {
  const linkClass = `${linkClassType}ColumnLink`;
  const {
    columnsById,
    lineGenerator,
    rectHeight,
    rectWidth,
    sortedColumnIdsByTableId,
    tablesById,
  } = context;

  /*
   * for now we rely on the class matching and that drawColumnLinks run the cleanup bellow
   * can't run twice or will remove columns drawn on this frame
   * root.selectAll(`.${linkClass}`).remove();
   */
  const tableToColumnIds: [string, string][] = [];

  displayColLinks.forEach((columnId) => {
    columnTableSources(context, columnId).forEach((tableId) => {
      tableToColumnIds.push([tableId, columnId]);
    });
  });

  if (displayColLinks.length === 0) return undefined;

  tableToColumnIds.forEach(([tableId, columnId]) => {
    const table = tableCoordinatesMap[tableId];

    if (!table) return undefined;

    const column = getColumnsCoordinates({
      columns: [columnId],
      columnsById,
      rectHeight,
      recursive: false,
      root,
      sortedColumnIdsByTableId,
      tableCoordinatesMap,
      tablesById,
    })[0];

    if (!column) return undefined;

    const line = lineGenerator([
      [table.x + rectWidth, table.y + rectHeight / 2],
      [column.x, column.y + rectHeight / 2],
    ]);

    if (!line) return undefined;

    root
      .append('path')
      .attr('class', linkClass)
      .datum({ root: columnId })
      .attr('d', line)
      .attr('marker-start', 'url(#circle)')
      .attr('marker-end', `url(#${linkClass}Arrow)`);
  });

  return undefined;
};

export default drawColumnsToTableLinks;
