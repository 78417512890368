import { createContext, useContext } from 'react';

import { SegmentProps } from './Segment.types';

const defaultState = null;

export const SegmentContext = createContext<SegmentProps | null>(defaultState);

export const useSegmentContext = () => {
  const segmentContext = useContext(SegmentContext);

  if (segmentContext === undefined) {
    throw new Error(`useSegmentContext must be used within <SegmentProvider>.`);
  }

  return segmentContext;
};

export default useSegmentContext;
