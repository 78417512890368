import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from '@routing/router';

import Box from '@components/Box';
import Select from '@components/UI/Select/Select';
import type { Option, SelectValue } from '@components/UI/Select/types';
import { useUserContext } from '@context/User';
import theme from '@styles/theme';

import { SidebarItemConfig, SidebarItemId } from '../AppMainSidebar.types';
import PrimarySidebarItem from '../PrimarySidebar/PrimarySidebarItem';

const SHOW_MORE_LABEL = 'See more';

interface ShowMoreProps {
  activeMenuItemId?: string;
  isCollapsed: boolean;
  items: SidebarItemConfig[];
}

const ShowMore: React.FC<ShowMoreProps> = ({ activeMenuItemId, isCollapsed, items }) => {
  const [selectedShowMoreItem, setSelectedShowMoreItem] = useState<Option | undefined>();
  const [showMoreIsOpen, setShowMoreIsOpen] = useState(false);
  const { dataSources } = useUserContext();
  const history = useHistory();
  const isActive = Boolean(items.find((item) => item.id === activeMenuItemId));

  const showMoreOptions = useMemo(
    () =>
      items.map((item) => ({
        icon: Object.values(dataSources ?? {}).find((dataSource) => item.id === dataSource.guid)
          ?.dataTypes?.icons.dataSource,
        text: item.label ?? '',
        value: item.id,
      })),
    [dataSources, items],
  );

  const handleShowMoreChange = (newValue: SelectValue) => {
    const [selected] = newValue as Option<string>[];
    const sidebarItem = items.find((item) => item.id === selected.value);
    setSelectedShowMoreItem(selected);
    sidebarItem?.onClick?.({ dataSources, id: selected.value, push: history.push });
  };

  useEffect(() => {
    const activeItem = showMoreOptions.find((item) => item.value === activeMenuItemId);
    if (activeItem) {
      setSelectedShowMoreItem(activeItem);
    } else {
      setSelectedShowMoreItem(undefined);
    }
  }, [activeMenuItemId, showMoreOptions]);

  return (
    <Select
      isDropdown
      isOpen={showMoreIsOpen}
      label="See more data sources"
      maxOptionsVisible={8}
      onChange={handleShowMoreChange}
      onClose={() => setShowMoreIsOpen(false)}
      options={showMoreOptions}
      optionsFitAnchorWidth={false}
      pl={0.5}
      popperConfigProps={{
        customPopperStyles: {
          borderRadius: theme.radius.lg,
          boxShadow: theme.shadow['4xl'],
          marginLeft: '14px',
          width: '200px',
        },
        placement: 'right-end',
      }}
      renderCustomAnchor={({ anchorProps, anchorRef, isOpen }) => (
        <Box ref={anchorRef} alignItems="center" compDisplay="flex" flexWrap="wrap" my={0.5}>
          <Box {...anchorProps} compWidth="100%">
            <PrimarySidebarItem
              icons={{
                active: 'chevron-right-double',
                default: 'chevron-right-double',
              }}
              id={SidebarItemId.showMore}
              isActive={isActive || isOpen}
              isCollapsed={isCollapsed}
              label={SHOW_MORE_LABEL}
              onClick={() => {
                setShowMoreIsOpen((prev) => !prev);
              }}
            />
          </Box>
        </Box>
      )}
      value={selectedShowMoreItem ? [selectedShowMoreItem] : []}
    />
  );
};

export default ShowMore;
