import React from 'react';

import { useFetchBiViews } from '@api/views';
import { BiViewModel } from '@api/views/BiViewModel';
import BulkButtons from '@components/BulkButtons';
import TabError from '@components/TabContent/TabError';
import { TabContentProps } from '@components/Tabs/types';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import useBulkEditSelected from '@hooks/useBulkEditSelected';
import stripSpaces from '@utils/stripSpaces';

import TableauViewsTable from './TableauViewsTable';

const query = stripSpaces(
  `{
    guid,
    name,
    search_name,
    view_type,
    description,
    richtext_description,
    data_types,
    tagged_items{
      kind,
      tag
    },
    popularity,
    workbook{
      guid
    },
    description_source,
    ingested_description,
    ai_description,
    user_description
  }`,
);

interface TableauViewsTabProps extends TabContentProps {
  guid: string;
  isDataSourceEditable: boolean;
}

const TableauViewsTab: React.FC<TableauViewsTabProps> = ({ guid, isDataSourceEditable }) => {
  const { isEditable: isObjectEditable } = useObjectPermissionsContext({ id: guid });
  const viewsConfig = {
    params: {
      all: true,
      order: '-popularity',
      query,
      workbooks: `${guid}`,
    },
  };

  const { data, isError } = useFetchBiViews(viewsConfig);

  const { selected } = useBulkEditSelected<BiViewModel>({
    defaultSelected: data?.results,
  });

  if (isError) return <TabError />;

  return (
    <>
      <BulkButtons
        canUseBulkAssist={isObjectEditable}
        guid={guid}
        isDataSourceEditable
        selectedEditableItems={selected.items}
        selectedItems={selected.items}
      />
      <TableauViewsTable data={data?.results} isDataSourceEditable={isDataSourceEditable} />
    </>
  );
};

export default React.memo(TableauViewsTab);
