import styled from '@emotion/styled';
import ReactMarkdown from 'react-markdown';

export const StyledReactMarkdown = styled(ReactMarkdown)`
  white-space: pre-wrap;
  word-wrap: break-word;

  ol,
  ul {
    white-space: normal;
  }
`;
