import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from '@routing/router';

import { useFetchMetadataById } from '@api/metadata';
import { ObjectType } from '@api/types';
import theme from '@styles/theme';
import stripSpaces from '@utils/stripSpaces';

import defaultSections, {
  MainSidebarSections,
  SETTINGS_BASE_ROUTE,
} from '../AppMainSidebar.config';
import { SidebarItemId, SidebarSection } from '../AppMainSidebar.types';
import useDataSourcesPrimarySidebar from '../useDataSourcesPrimarySidebar';
import { ActiveDataSource } from '../useDataSourcesPrimarySidebar/useDataSourcesPrimarySidebar';

interface GetInitialActiveMenuItemIdParams {
  objectGuid?: string;
  pathname: string;
  sections: MainSidebarSections;
}
const getInitialActiveMenuItemId = ({ pathname, sections }: GetInitialActiveMenuItemIdParams) => {
  const allItems = Object.values(sections).flatMap((section) => section.items);
  const activeItem = allItems.find(({ baseRoute }) =>
    baseRoute?.some((route) => pathname.startsWith(route)),
  );

  return activeItem?.id;
};

export const DEFAULT_SIDEBAR_Z_INDEX = theme.zIndex.sidebar + 2; // adding 2 to be above the main content, we should change this when we deprecate the old layout

const defaultContextValue: {
  activeDataSource?: ActiveDataSource;
  activeMenuItemId?: string;
  dataSourcesSection?: SidebarSection;
  setActiveMenuItemId: (id: string | undefined) => void;
  setZIndex: (zIndex: number) => void;
  zIndex: number;
} = {
  activeDataSource: undefined,
  activeMenuItemId: undefined,
  dataSourcesSection: undefined,
  setActiveMenuItemId: () => {},
  setZIndex: () => {},
  zIndex: DEFAULT_SIDEBAR_Z_INDEX,
};

const OBJECT_TYPE_MENU_MAP: PartialRecord<ObjectType, SidebarItemId> = {
  chatbotconversation: SidebarItemId.chatbot,
  document: SidebarItemId.docs,
  dsuser: SidebarItemId.teams,
  metric: SidebarItemId.docs,
  tag: SidebarItemId.tags,
  team: SidebarItemId.teams,
  term: SidebarItemId.docs,
  user: SidebarItemId.teams,
};

const HIERARCHY_ITEM_CHANGE_BLACKLIST: SidebarItemId[] = [SidebarItemId.favorites];

const query = stripSpaces(`{
  guid,
  data_types,
  data_source {
    guid
  }
}`);

const AppMainSidebarContext = createContext(defaultContextValue);
export const useAppMainSidebar = () => useContext(AppMainSidebarContext);

export const AppMainSidebarContextProvider = ({
  children,
  sections = defaultSections,
}: {
  children: React.ReactNode;
  sections?: MainSidebarSections;
}) => {
  const { pathname } = useLocation();
  const { dsGuid, guid } = useParams<{ dsGuid?: string; guid?: string }>();
  const objectGuid = guid ?? dsGuid ?? '';
  const [activeDataSource, setActiveDataSource] = useState<ActiveDataSource | undefined>();
  const { dataSourcesSection } = useDataSourcesPrimarySidebar({
    activeDataSource,
    setActiveDataSource,
  });
  const [zIndex, setZIndex] = useState<number>(DEFAULT_SIDEBAR_Z_INDEX);

  const [activeMenuItemId, setActiveMenuItemId] = useState<string | undefined>(
    getInitialActiveMenuItemId({ pathname, sections }),
  );

  const { data: metadata, isLoading: isLoadingMetadata } = useFetchMetadataById(objectGuid, {
    enabled:
      Boolean(objectGuid) &&
      !HIERARCHY_ITEM_CHANGE_BLACKLIST.includes(activeMenuItemId as SidebarItemId),
    params: {
      query,
    },
  });

  /*
   * The dataSourcesSection changes every render because it uses the dataSources from
   * the userContext, which ins't memoized. To avoid a re-render loop, we need to use a
   * ref to store the dataSourcesSection.items and use it in the useEffect bellow.
   */
  const dataSourceItemsRef = useRef(dataSourcesSection.items);
  dataSourceItemsRef.current = dataSourcesSection.items;

  useEffect(() => {
    const updateDynamicActiveMenuItemId = () => {
      /** Forces showing settings 2nd sidebar for all admin related sections. */
      if (SETTINGS_BASE_ROUTE?.some((path) => pathname.startsWith(path))) {
        setActiveMenuItemId(SidebarItemId.settings);
        return;
      }

      // TODO: remove once the API is ready [sc-101183]
      if (['/collections'].some((path) => pathname.startsWith(path))) {
        setActiveMenuItemId(SidebarItemId.collections);
        return;
      }

      if (objectGuid && metadata && !isLoadingMetadata) {
        let activeMenuId;
        const { dataTypes } = metadata;
        const { dataSourceType, objectType } = dataTypes ?? {};
        const mappedMenuId = OBJECT_TYPE_MENU_MAP[objectType as ObjectType];

        if (dataSourceType) {
          const dataSourceGuid = metadata?.dataSource?.guid;
          Object.values(dataSourceItemsRef.current).some((item) => {
            if (item.id === dataSourceType) {
              activeMenuId = dataSourceType;
              setActiveDataSource({ changedOn: 'redirection', dataSourceGuid });
              return true;
            }

            if (item.id === dataSourceGuid) {
              activeMenuId = dataSourceGuid;
              setActiveDataSource({ changedOn: 'redirection', dataSourceGuid });
              return true;
            }

            if (item.id === metadata?.guid) {
              activeMenuId = metadata?.guid;
              setActiveDataSource({ changedOn: 'redirection', dataSourceGuid: metadata?.guid });
              return true;
            }

            return false;
          });

          if (objectType === 'dsuser') {
            activeMenuId = mappedMenuId;
          }
        } else {
          activeMenuId = mappedMenuId ?? objectType;
        }

        setActiveMenuItemId(activeMenuId);
      }
    };

    updateDynamicActiveMenuItemId();
  }, [metadata, isLoadingMetadata, pathname, objectGuid]);

  const value = useMemo(
    () => ({
      activeDataSource,
      activeMenuItemId,
      dataSourcesSection,
      setActiveMenuItemId,
      setZIndex,
      zIndex,
    }),
    [activeMenuItemId, zIndex, setActiveMenuItemId, activeDataSource, dataSourcesSection],
  );

  return <AppMainSidebarContext.Provider value={value}>{children}</AppMainSidebarContext.Provider>;
};
