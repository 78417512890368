import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Box from '@components/Box';
import staticTheme from '@styles/theme';

import type { DescriptionSelectorItemProps } from './DescriptionSelectorItem';

const selectedStyles = {
  selected: css`
    border-color: ${staticTheme.colors.v1.primary[500]};
    background-color: ${staticTheme.colors.v1.primary[50]};
    border-width: 2px;
  `,
  unselected: css`
    border-color: ${staticTheme.colors.v1.gray[100]};
    background-color: ${staticTheme.colors.v1.gray[100]};
    border-width: 1px;
  `,
};

interface StyledDescriptionItemWrapperProps
  extends Pick<DescriptionSelectorItemProps, 'source' | 'selected'> {
  isLoading?: boolean;
}

export const StyledDescriptionItemWrapper = styled(Box)<StyledDescriptionItemWrapperProps>`
  ${({ selected }) => selectedStyles[selected ? 'selected' : 'unselected']};

  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.space(1, 1.5)};
  border-style: solid;
  border-radius: ${({ theme }) => theme.radius.lg};
  cursor: ${({ isLoading }) => (isLoading ? 'wait' : 'default')};

  &:hover {
    border-color: ${({ theme }) => theme.colors.v1.primary[500]};
  }

  * {
    margin-block-end: 0;
  }
`;
