import { Expose, Type } from 'class-transformer';

import formatNumber from '@utils/formatNumber';

class TotalSectionModel {
  active: number = 0;

  get formattedActive() {
    return formatNumber({ value: this.active });
  }

  @Expose({ name: 'credits_used' })
  creditsUsed: number = 0;

  get formattedCreditsUsed() {
    return formatNumber({ value: this.creditsUsed });
  }

  total: number = 0;

  get formattedTotal() {
    return formatNumber({ value: this.total });
  }

  @Expose({ name: 'average_credits_used' })
  averageCreditsUsed: number = 0;

  get formattedAverageCreditsUsed() {
    return formatNumber({ value: this.averageCreditsUsed });
  }
}

class TotalModel {
  static objectType: 'total' = 'total';

  @Type(() => TotalSectionModel)
  dashboard?: TotalSectionModel;

  objectType = TotalModel.objectType;

  @Type(() => TotalSectionModel)
  run?: TotalSectionModel;

  @Type(() => TotalSectionModel)
  user?: TotalSectionModel;

  @Type(() => TotalSectionModel)
  warehouse?: TotalSectionModel;
}

export default TotalModel;
