import React from 'react';
import styled from '@emotion/styled';

import Alert from '@components/Alert';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import Text from '@components/Text';
import { ModalContent, ModalFooter } from '@components/UI/Modal';
import type { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';

interface Props {
  error?: EnhancedErrorResult | null;
  goBack: () => void;
  notFound?: string[];
  onClose: () => void;
  uploadedCount: number;
}

const StyledList = styled.ul`
  overflow: hidden;
  overflow-y: auto;
`;

const CSVUploaded: React.FC<Props> = ({ error, goBack, notFound, onClose, uploadedCount }) => {
  const errorHeader =
    Number(error?.code) === 500 ? 'Metadata update is not successful' : 'Invalid CSV Uploaded';
  const errorMessage = error?.data.data_source || error?.data.items || 'There was an error';

  return (
    <>
      <ModalContent
        compDisplay="flex"
        compHeight="100%"
        flexDirection="column"
        maxHeight="unset"
        position="relative"
        py={3}
      >
        <Box compDisplay="flex" flexDirection="column" flexGrow="1">
          <Text fontSize="1rem" lineHeight={1.5} mb={2}>
            {!error && `Metadata update is complete. Processed ${uploadedCount} rows`}
          </Text>
          {error && (
            <Alert title={errorHeader} type="error">
              {errorMessage}
            </Alert>
          )}
          {notFound && notFound.length > 0 && (
            <>
              <Text fontSize="1rem" lineHeight={1.5} mb={2}>
                The following rows were not updated:
              </Text>
              <StyledList>
                {notFound.length > 0 && notFound.map((obj) => <li key={obj}>{obj}</li>)}
              </StyledList>
            </>
          )}
        </Box>
      </ModalContent>
      <ModalFooter>
        {error && (
          <Button onClick={goBack} type="submit" variant="outlined">
            Go Back
          </Button>
        )}
        <Button onClick={onClose} type="submit">
          Finish
        </Button>
      </ModalFooter>
    </>
  );
};

export default CSVUploaded;
