import { plainToClass } from 'class-transformer';

const paginatedTransform = (Model: any) => {
  return (data: any) => {
    const response = data;
    if (
      !response ||
      !response.results ||
      !response.results.length ||
      response.results[0] instanceof Model
    ) {
      return response;
    }
    response.results = plainToClass(Model, response.results);
    return response;
  };
};

export default paginatedTransform;
