import { useEffect, useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';

import type { TreeKey } from './types';

const useHighlighted = (
  highlightedKeys: TreeKey[],
): [TreeKey[], Dispatch<SetStateAction<TreeKey[]>>] => {
  const [highlighted, setHighlighted] = useState<TreeKey[]>(highlightedKeys);

  /** Updates highlighted on new props received. */
  useEffect(() => {
    const filteredKeys = highlightedKeys?.filter((key) => Boolean(key));

    if (
      filteredKeys &&
      filteredKeys.length !== 0 &&
      JSON.stringify(filteredKeys) !== JSON.stringify(highlighted)
    ) {
      setHighlighted(filteredKeys);
    }
    /**
     * We should observe only highlightedKeys change.
     * Otherwise, tree will be blocking updates on click.
     */
    // eslint-disable-next-line
  }, [highlightedKeys]);

  return [highlighted, setHighlighted];
};

export default useHighlighted;
