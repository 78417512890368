import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Descendant, Editor } from 'slate';

import Box from '@components/Box';
import { ShowMoreDescriptionButton } from '@components/ExcerptText/ExcerptText.styles';
import { resetEditorValue } from '@components/RichTextEditor/helpers/resetEditorValue';
import { serializeSlateToPlainText } from '@components/RichTextEditor/helpers/serializationHelpers';
import { useFetchedMentions } from '@context/FetchedMentions';

import truncateSlate from './truncateSlate';

const ELLIPSIS = '...';

const useTruncateSlate = (
  editor: Editor,
  slateEditorState: Descendant[],
  isEditing?: boolean,
  maxLength?: number,
  truncateDisabled?: boolean,
) => {
  const [truncatedSlateState, setTruncatedSlateState] = useState<Descendant[]>(() =>
    truncateSlate(slateEditorState, maxLength),
  );
  const [showMore, setShowMore] = useState(!maxLength);

  const { getMentionFromCacheById } = useFetchedMentions();

  const slateEditorStatePlainText = serializeSlateToPlainText({
    getMentionFromCacheById,
    nodes: slateEditorState,
  });
  const contentLength = serializeSlateToPlainText({
    getMentionFromCacheById,
    nodes: editor.children,
  }).length;
  const shouldTruncate = maxLength && !isEditing && !showMore && !truncateDisabled;

  useEffect(() => {
    if (shouldTruncate && !showMore) {
      resetEditorValue(editor, truncatedSlateState);
    } else if (showMore || isEditing) {
      resetEditorValue(editor, slateEditorState);
    }
  }, [shouldTruncate, showMore, isEditing, slateEditorState, truncatedSlateState]);

  const handleShowMore = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setShowMore(!showMore);
  };

  /*
   * If the text has maxLength + 3 (ELLIPSIS.length) and finishes with "..." we
   * don't want to show the show more button
   */
  const isEndingWithEllipsis =
    maxLength &&
    slateEditorStatePlainText.length === maxLength + ELLIPSIS.length &&
    slateEditorStatePlainText.substring(contentLength - 3) === ELLIPSIS;

  const isShowButtonVisible =
    maxLength &&
    contentLength > maxLength &&
    !isEditing &&
    !truncateDisabled &&
    !isEndingWithEllipsis;

  const showMoreButton = isShowButtonVisible ? (
    <Box>
      <ShowMoreDescriptionButton onClick={handleShowMore}>
        {shouldTruncate ? 'Show more' : 'Show less'}
      </ShowMoreDescriptionButton>
    </Box>
  ) : null;

  return {
    handleShowMore,
    isShowButtonVisible,
    setShowMore,
    setTruncatedSlateState,
    shouldTruncate,
    showMoreButton,
  };
};

export default useTruncateSlate;
