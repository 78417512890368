import React from 'react';

import useExpandLineage from '@components/LineageExplore/useExpandLineage';
import { useLineageExplore } from '@components/LineageExplore/useLineageExplore';
import { LineageNodeOptionsMenuState } from '@components/LineageExplore/useLineageExplore/LineageExplore.context.types';
import useUserTracking from '@components/LineageExplore/useUserTracking';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';

import OptionsButton from '../../Common/OptionsButton';
import {
  CLOSE_ALL_DOWNSTREAM_OPTION,
  CLOSE_ALL_UPSTREAM_OPTION,
  OPEN_ALL_DOWNSTREAM_OPTION,
  OPEN_ALL_UPSTREAM_OPTION,
} from '../../Common/OptionsMenu/OptionsMenu.constants';

interface ColumnNodeOptionsProps {
  downstreamObjectsCount?: number;
  guid: string;
  isColumnSelected: boolean;
  isOpen: boolean;
  nodeKey: string;
  setIsOpen: (isOpen: boolean) => void;
  show: boolean;
  trackingData: Record<string, any>;
  upstreamObjectsCount?: number;
}

const ColumnNodeOptions = ({
  downstreamObjectsCount = 0,
  guid,
  isColumnSelected,
  isOpen,
  nodeKey,
  setIsOpen,
  show,
  trackingData,
  upstreamObjectsCount = 0,
}: ColumnNodeOptionsProps) => {
  const { track } = useUserTracking();

  const { isColumnLevelLineage, setSelectedEdge } = useLineageExplore();

  const { closeAllDownstream, closeAllUpstream, openAllDownstream, openAllUpstream } =
    useExpandLineage({ guid, nodeKey, origin: 'column' });

  const getUpstreamOptions = () => {
    const hasUpstreamObjects = upstreamObjectsCount > 0;
    const tooltipMessage = !hasUpstreamObjects ? 'No upstream available' : '';

    const closeTooltipMessage =
      !isColumnLevelLineage && hasUpstreamObjects
        ? 'Only available in column-level lineage'
        : tooltipMessage;

    return [
      {
        id: '1',
        options: [
          {
            ...OPEN_ALL_UPSTREAM_OPTION,
            count: upstreamObjectsCount,
            isDisabled: !hasUpstreamObjects,
            onClick: openAllUpstream,
            tooltipMessage,
          },
          {
            ...CLOSE_ALL_UPSTREAM_OPTION,
            count: upstreamObjectsCount,
            isDisabled: !hasUpstreamObjects || !isColumnLevelLineage || !isColumnSelected,
            onClick: closeAllUpstream,
            tooltipMessage: closeTooltipMessage,
          },
        ],
      },
    ];
  };

  const getDownstreamOptions = () => {
    const hasDownstreamObjects = downstreamObjectsCount > 0;
    const tooltipMessage = !hasDownstreamObjects ? 'No downstream available' : '';

    const closeTooltipMessage =
      !isColumnLevelLineage && hasDownstreamObjects
        ? 'Only available in column-level lineage'
        : tooltipMessage;

    return [
      {
        id: '2',
        options: [
          {
            ...OPEN_ALL_DOWNSTREAM_OPTION,
            count: downstreamObjectsCount,
            isDisabled: !hasDownstreamObjects,
            onClick: openAllDownstream,
            tooltipMessage,
          },
          {
            ...CLOSE_ALL_DOWNSTREAM_OPTION,
            count: downstreamObjectsCount,
            isDisabled: !hasDownstreamObjects || !isColumnLevelLineage || !isColumnSelected,
            onClick: closeAllDownstream,
            tooltipMessage: closeTooltipMessage,
          },
        ],
      },
    ];
  };

  const menuOptions = [...getUpstreamOptions(), ...getDownstreamOptions()];

  const handleSetIsOpen = (newState: boolean) => {
    track(SegmentTrackEventName.LineageColumnOptionMenuToggled, {
      ...trackingData,
      state: newState ? LineageNodeOptionsMenuState.Open : LineageNodeOptionsMenuState.Closed,
    });
    setIsOpen(newState);
    setSelectedEdge(undefined);
  };

  return (
    <OptionsButton
      isAnchorVisible={show}
      isOpen={isOpen}
      optionsSections={menuOptions}
      setIsOpen={handleSetIsOpen}
    />
  );
};

export default ColumnNodeOptions;
