import React, { useEffect } from 'react';

import Box, { StyledBoxProps } from '@components/Box';

import { StyledSidebarTreeItem } from './SidebarTreeItem.styles';
import type { SidebarTreeItemProps } from './types';

const SidebarTreeItem: React.FC<
  SidebarTreeItemProps & {
    commonCellStyles?: StyledBoxProps;
  }
> = (props) => {
  const {
    commonCellStyles,
    direction,
    focused,
    isExpandingAll,
    level,
    loadLineage,
    loadMore,
    onClick,
    tableId,
    treeColumns,
  } = props;

  useEffect(() => {
    if (!isExpandingAll && loadMore) {
      loadLineage?.(tableId, direction);
    }
  }, [isExpandingAll, tableId, loadMore, direction, loadLineage]);

  return (
    <StyledSidebarTreeItem
      className={focused ? 'focused' : ''}
      isBold={level === 0}
      onClick={onClick}
    >
      {treeColumns.map((column) => {
        return (
          <Box key={column.id} {...commonCellStyles} {...column.styles}>
            {column.Cell ? <column.Cell {...props} key={column.id} /> : null}
          </Box>
        );
      })}
    </StyledSidebarTreeItem>
  );
};

export default SidebarTreeItem;
