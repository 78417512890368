import React from 'react';
import styled from '@emotion/styled';

import { useFetchMetric } from '@api/metrics';
import Button from '@components/Button/Button';
import CreateMetricModal from '@components/Modal/CreateMetricModal';
import TabError from '@components/TabContent/TabError';
import DimensionsTable from '@components/Table/DimensionsTable/DimensionsTable';
import { TabContentProps } from '@components/Tabs/types';
import { useModal } from '@context/Modal';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';

const DimensionsTabContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 0.5rem 0;

  .ui.button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    padding: 0 !important;

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`;

const dimensionConfig = {
  params: {
    query: '{guid, dimensions, represented_as_fields}',
  },
};

interface DimensionTabProps extends TabContentProps {
  guid: string;
}

const DimensionsTab: React.FC<DimensionTabProps> = ({ guid }) => {
  const { data, isError } = useFetchMetric(guid, dimensionConfig);
  const { MODAL_IDS, checkModalOpened, closeModal, openModal } = useModal();
  const { isEditable } = useObjectPermissionsContext({ id: guid });

  if (isError) return <TabError />;

  return (
    <DimensionsTabContainer>
      {isEditable && (
        <Button
          compSize="sm"
          onClick={() => openModal(MODAL_IDS.metricDimensionsTab)}
          startIconName="edit-outline"
          variant="outlined"
        >
          Edit
        </Button>
      )}
      <DimensionsTable
        data={data?.formattedDimensions}
        itemCount={data?.formattedDimensions?.length}
      />
      {checkModalOpened(MODAL_IDS.metricDimensionsTab) && (
        <CreateMetricModal
          metric={data}
          onClose={() => {
            closeModal(MODAL_IDS.metricDimensionsTab);
          }}
        />
      )}
    </DimensionsTabContainer>
  );
};

export default DimensionsTab;
