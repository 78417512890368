import { Editor, Element, Node, Text, Transforms } from 'slate';

/**
 * Ensures that codeblocks have at least one paragraph inside.
 * Needed to correct for issues with importing codeblocks from markdown.
 */
const withCodeblocksPlugin = (editor: Editor) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;

    if (Element.isElement(node) && node.type === 'codeblock') {
      const listItemChildren = Array.from(Node.children(editor, path));
      for (let i = 0; i < listItemChildren.length; i += 1) {
        const [child, childPath] = listItemChildren[i];
        if (Text.isText(child)) {
          Transforms.wrapNodes(
            editor,
            { type: 'paragraph', children: [{ text: '' }] },
            { at: childPath },
          );
          return;
        }
        if (Element.isElement(child) && child.type === 'span') {
          Transforms.setNodes(editor, { type: 'paragraph' }, { at: childPath });
          return;
        }
      }
    }
    normalizeNode(entry);
  };
  return editor;
};
export default withCodeblocksPlugin;
