import { identifyWithExternalTools, setTokenResponseData } from '@api';
import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import useMutation, { UseMutationOptions } from '@hooks/useMutation';

export const useFetchGoogleOAuthRedirect = (
  options?: UseFetchOptions<{ params: object; url: string }>,
) => {
  return useFetch<{ params: object; url: string }>({
    ...options,
    url: `/google/oauth/`,
    staleTime: 0,
  });
};

export const usePostGoogleOAuth = (options: UseMutationOptions) => {
  return useMutation({
    ...options,
    url: `/google/oauth/`,
    onSuccess: (data, variables, context) => {
      setTokenResponseData(data);
      identifyWithExternalTools(data);
      options?.onSuccess?.(data, variables, context);
    },
  });
};
