import * as Sentry from '@sentry/react';
import {
  AxiosHeaderValue,
  AxiosInstance,
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
} from 'axios';
import throttle from 'lodash/throttle';
import moment from 'moment';
import pRetry, { AbortError } from 'p-retry';
import Url from 'url-parse';

import shallowCloneObject from '@utils/shallowCloneObject/shallowCloneObject';

import {
  ACCESS_TOKEN_TIMEOUT_SECONDS,
  DeauthorizedCallback,
  REFRESH_TOKEN_TIMEOUT_SECONDS,
} from './api.utils';

export interface RefreshTokenAdapter {
  clearAuthData: () => void;
  setAuthData: (authData: { accessToken: string; accessTokenTime: string }) => void;
}

export interface CustomAxiosMetadataConfig {
  duration?: number;
  endTime?: Date;
  startTime?: Date;
}

export interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  metadata?: CustomAxiosMetadataConfig;
}

interface RefreshTokenInterceptor {
  accessToken: string | null;
  accessTokenTimeDateString: string | null;
  api: AxiosInstance;
  config: CustomAxiosRequestConfig;
  deauthorizedCallbacks?: DeauthorizedCallback[];
  refreshToken: string | null;
  storage: RefreshTokenAdapter;
}

interface ExecuteTokenRefresh {
  accessTokenTimeDateString: string;
  api: AxiosInstance;
  deauthorizedCallbacks?: DeauthorizedCallback[];
  storage: RefreshTokenAdapter;
}

const URL_WITH_NO_HEADERS = [
  '/token/',
  '/sso/callback/',
  '/google/oauth/',
  '/user/',
  '/verification/resend/',
  '/google/oauth/',
];

const HTTP_STATUS = {
  FORBIDDEN: 403,
  INTERNAL_SERVER_ERROR: 500,
  UNAUTHORIZED: 401,
};

const refresh = async (
  config: AxiosRequestConfig,
  api: AxiosInstance,
  storage: RefreshTokenAdapter,
) => {
  try {
    const response = await api(config);

    if (response.status === 200) {
      const newAccessToken = response.data.access;
      const accessTokenTime = moment().toISOString();

      api.defaults.headers.common.Authorization = `Bearer ${newAccessToken}`;

      storage.setAuthData({ accessToken: newAccessToken, accessTokenTime });
    }

    return response;
  } catch (error: any) {
    if (error?.response?.status) {
      const shouldNotRetryStatus = Object.values(HTTP_STATUS);
      if (shouldNotRetryStatus.includes(error.response.status)) {
        throw new AbortError(error);
      }
    }

    throw error;
  }
};

export const executeTokenRefresh = throttle(
  async (
    config: AxiosRequestConfig,
    { accessTokenTimeDateString, api, deauthorizedCallbacks = [], storage }: ExecuteTokenRefresh,
  ) => {
    try {
      /*
       * We can't use the last version of p-retry it is pure ESM.
       * Jest doesn't support it and most of the tests will break.
       * https://gist.github.com/sindresorhus/a39789f98801d908bbc7ff3ecc99d99c
       */
      await pRetry(() => refresh(config, api, storage), { retries: 2 });
    } catch (error) {
      Sentry.captureException(error, { extra: { detail: 'Error refreshing the token' } });
      const clearAuthInfo =
        accessTokenTimeDateString && config.data.refreshToken
          ? moment().diff(moment(accessTokenTimeDateString), 'seconds') >
            REFRESH_TOKEN_TIMEOUT_SECONDS
          : true;

      if (clearAuthInfo) {
        storage.clearAuthData();
        for (let i = 0; i < deauthorizedCallbacks.length; i += 1) {
          deauthorizedCallbacks[i]();
        }
      }
    }
  },

  1000,
  { leading: true },
);

export const refreshTokenInterceptor = async ({
  accessToken,
  accessTokenTimeDateString,
  api,
  config,
  deauthorizedCallbacks = [],
  refreshToken,
  storage,
}: RefreshTokenInterceptor): Promise<CustomAxiosRequestConfig> => {
  if (!config.url) {
    return Promise.reject();
  }

  const url = Url(config.url);

  if (URL_WITH_NO_HEADERS.includes(url.pathname)) {
    return config;
  }

  let newAccessToken: AxiosHeaderValue = accessToken ? `Bearer ${accessToken}` : null;

  if (accessTokenTimeDateString) {
    const date = moment(accessTokenTimeDateString);

    if (
      refreshToken &&
      moment().diff(date, 'seconds') > ACCESS_TOKEN_TIMEOUT_SECONDS - 2 && // give a 2-second buffer
      url.pathname !== '/token/refresh/'
    ) {
      await executeTokenRefresh(
        {
          data: { refresh: refreshToken },
          method: 'POST',
          url: '/token/refresh/',
        },
        {
          accessTokenTimeDateString,
          api,
          deauthorizedCallbacks,
          storage,
        },
      );

      newAccessToken = api.defaults.headers.common.Authorization ?? null;
    }
  }

  const enrichedHeaders = shallowCloneObject(config.headers);
  enrichedHeaders.Authorization = newAccessToken;

  return {
    ...config,
    headers: enrichedHeaders,
  };
};
