import React, { useCallback } from 'react';
import styled from '@emotion/styled';

import { usePatchDsUser } from '@api/dsusers';
import { DsUserModel } from '@api/dsusers/DsUserModel';
import CircularLoader from '@components/CircularLoader';
import { renderErrorToast, renderInfoToast } from '@components/Toast';
import Checkbox from '@components/UI/Form/Checkbox';

const CheckBoxWrapper = styled.span`
  width: 100%;
  display: flex;
  justify-content: center;
`;

interface CheckboxCellProps {
  dsuser: DsUserModel;
  onSuccess: () => void;
}

const CheckboxCell: React.FC<CheckboxCellProps> = ({ dsuser, onSuccess }) => {
  const { isLoading, mutate: updateServiceAccount } = usePatchDsUser(dsuser.guid, {
    onError: (err) => {
      renderErrorToast(err?.data.is_service_account);
    },
    onSuccess: (d) => {
      onSuccess();
      renderInfoToast(
        d.isServiceAccount
          ? `${d.fullName} has been added to service account.`
          : `${d.fullName} has been removed from service account.`,
      );
    },
  });

  const handleOnChange = useCallback(
    (_e: React.SyntheticEvent, _data: { checked?: boolean | undefined }) => {
      updateServiceAccount({
        is_service_account: _data.checked,
      });
    },
    [updateServiceAccount],
  );

  return (
    <CheckBoxWrapper>
      {isLoading ? (
        <CircularLoader compSize={2} />
      ) : (
        <Checkbox
          checked={dsuser.isServiceAccount}
          onChange={(e) => {
            handleOnChange({} as React.SyntheticEvent, { checked: e.target.checked });
          }}
        />
      )}
    </CheckBoxWrapper>
  );
};

export default React.memo(CheckboxCell);
