import styled from '@emotion/styled';

export const Tabs = styled.div`
  display: flex;
  flex-shrink: 0;
`;

export const TabItem = styled.div`
  cursor: pointer;
  width: 50%;
  border-bottom: 1px solid #e2e8f0;
  text-align: center;
  padding: 6px;

  &.active {
    font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
    color: #0c3476;
    border-bottom: 2px solid #0c3476;
  }
`;

export const TabsContainer = styled.div`
  font-size: 13px;
`;
