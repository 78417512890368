import stripSpaces from '@utils/stripSpaces';

export interface OwnersUpdateData {
  business_owner?: string | null;
  items: string[];
  technical_owner?: string | null;
}

export const tableOwnsQuery = stripSpaces(
  `{
    guid,
    name,
    search_name,
    business_owner,
    technical_owner,
    last_updated_on,
    data_types,
    database{
      data_source{
        guid,
        type
      }
    },
    breadcrumbs
  }`,
);

export const metricOwnsQuery = stripSpaces(
  `{
    guid,
    name,
    business_owner,
    technical_owner,
    breadcrumbs,
    data_types,
    updated_on
  }`,
);

export const tagsOwnedQuery = stripSpaces(
  `{
    guid,
    name,
    icon,
    color,
    description,
    breadcrumbs,
  }`,
);

export const termsOwnsQuery = stripSpaces(
  `{
    guid,
    name,
    business_owner,
    technical_owner,
    breadcrumbs,
    data_types,
    updated_on
  }`,
);
