import { useCallback } from 'react';

import { usePostDescriptionRichtextConvert } from '@api/description';
import { DescriptionConversionModel } from '@api/description/DescriptionConversionModel';
import { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';

interface UseMarkdownConverterParams {
  onConvertToMarkdownSuccess?: (data: DescriptionConversionModel) => void;
  onConvertToRichTextSuccess?: (data: DescriptionConversionModel) => void;
  onError: (error: EnhancedErrorResult) => void;
}

const useMarkdownConverter = ({
  onConvertToMarkdownSuccess,
  onConvertToRichTextSuccess,
  onError,
}: UseMarkdownConverterParams) => {
  const { error, isError, isLoading, mutate } = usePostDescriptionRichtextConvert({
    onError: (e) => {
      onError(e);
    },
    onSuccess: (data) => {
      if (data.targetFormat === 'slatejs') {
        onConvertToRichTextSuccess?.(data);
      } else {
        onConvertToMarkdownSuccess?.(data);
      }
    },
  });

  const convertRichTextToMarkdown = useCallback(
    (richText: string) => {
      mutate({
        richtext_description: richText,
        source_format: 'slatejs',
        target_format: 'markdown',
      });
    },
    [mutate],
  );

  const convertMarkdownToRichText = useCallback(
    (markdown: string) => {
      mutate({
        richtext_description: markdown,
        source_format: 'markdown',
        target_format: 'slatejs',
      });
    },
    [mutate],
  );

  return { convertMarkdownToRichText, convertRichTextToMarkdown, error, isError, isLoading };
};

export default useMarkdownConverter;
