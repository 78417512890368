import React from 'react';

import { useFetchQueryLog } from '@api/queryLog';
import TabError from '@components/TabContent/TabError';
import RecentQueriesTable from '@components/Table/RecentQueriesTable/RecentQueriesTable';
import { Filter } from '@utils';
import stripSpaces from '@utils/stripSpaces';

import type { QueriesAndJoinsProps } from './QueriesJoinsTab/QueriesJoinsTab';

const SEARCH_CONFIG: { [key: string]: keyof Filter.FilterOptions } = {
  query: 'search_query',
  user: 'search_user',
};

const SORT_CONFIG: { [key: string]: string } = {
  query: 'query',
  runTime: 'duration',
  startTime: 'executed_on',
  user: 'dsuser',
};

const DEFAULT_QUERY = stripSpaces(`{
  guid,
  external_id,
  executed_on,
  duration,
  rows_produced,
  data_types,
  query {
    guid,
    name,
    raw_sql,
    database,
    last_executed_on,
    created_by {
      guid,
      first_name,
      last_name,
      avatar
    },
    dsuser_created_by {
      guid,
      name,
      display_name
      }
    },
    dsuser {
      guid,
      name,
      display_name,
      is_active,
      user {
        guid,
        first_name,
        last_name,
        email,
        avatar,
        team {
          name
        },
        is_active
    },
    data_source {
      guid,
      name,
      type
    }
  }
}`);

interface RecentQueriesTabProps extends QueriesAndJoinsProps {
  hiddenColumns?: string[];
  userColName?: string;
}

const RecentQueriesTab: React.FC<RecentQueriesTabProps> = ({
  basic,
  hiddenColumns,
  requestConfig,
  stickyHeader,
  userColName,
}) => {
  const config = {
    order: '-executed_on',
    page_size: 30,
    query: DEFAULT_QUERY,
    ...requestConfig,
  };
  const FilterService = Filter.useUpdateFilters(config, SEARCH_CONFIG, SORT_CONFIG, '-executed_on');
  const { filter } = FilterService;

  const { data, error, isLoading } = useFetchQueryLog({
    params: Filter.setParams(filter),
  });

  if (error) return <TabError />;

  return (
    <RecentQueriesTable
      basic={basic}
      data={data?.results}
      filterService={FilterService}
      hiddenColumns={hiddenColumns}
      loading={isLoading}
      stickyHeader={stickyHeader}
      userColName={userColName}
    />
  );
};

export default React.memo(RecentQueriesTab);
