import styled from '@emotion/styled';

export const StyledPiiMatchedModalItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space(0.5)};
  padding: ${({ theme }) => theme.space(1, 0)};
  font-size: ${({ theme }) => theme.typography.fontSizes.body1};
  font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.v1.gray[200]};
  }
`;
