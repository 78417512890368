import React, { useMemo, useRef } from 'react';
import { isString } from 'lodash';

import Box from '@components/Box';
import Icon, { IconProps } from '@components/UI/Icon';

import Option from '../Option';
import Tile from '../Tile';
import type { FieldState, MultiOptionTypes, Option as OptionType } from '../types';

import {
  StyledMainInput,
  StyledMainInputContainer,
  StyledMainInputField,
  StyledMainInputFieldContainer,
  StyledMainInputPlaceholder,
} from './MainInput.styles';

export interface MainInputProps {
  addSelectedItem: (newOption: OptionType) => void;
  fieldState: FieldState;
  id?: string;
  inputProps?: Record<string, any>;
  isCreatable?: boolean;
  isDropdown?: boolean;
  isLoading?: boolean;
  isMulti?: boolean;
  leftIcon?: IconProps['name'] | React.ReactNode;
  multiOptionsType?: MultiOptionTypes;
  newOptionValidator?: (newOption: OptionType) => boolean;
  onNewOption?: (newOption: OptionType) => void;
  options: OptionType[];
  placeholder?: string;
  removeSelectedItem: (newOption: OptionType) => void;
  selectedItems: OptionType[];
  setInputValue: (inputValue: string) => void;
  showSearch?: boolean;
  value: string;
}

const MAX_STRING_OPTIONS = 3;

const MainInput = ({
  addSelectedItem,
  fieldState,
  id,
  inputProps,
  isCreatable,
  isDropdown = false,
  isLoading,
  isMulti = false,
  leftIcon = null,
  multiOptionsType = 'tiles',
  newOptionValidator,
  onNewOption,
  options,
  placeholder,
  removeSelectedItem,
  selectedItems,
  setInputValue,
  showSearch,
  value,
}: MainInputProps) => {
  const isMultiTiles = !isDropdown && isMulti && multiOptionsType === 'tiles';
  const isMultiString = !isDropdown && isMulti && multiOptionsType === 'string';
  const inputContainer = useRef<HTMLDivElement>(null);

  const addNewOption = (newOptionValue: string) => {
    if (!onNewOption) return;
    const optionValue = newOptionValue.trim();
    const newOption: OptionType = {
      hasError: newOptionValidator
        ? !newOptionValidator({ text: optionValue, value: optionValue })
        : undefined,
      text: optionValue,
      value: optionValue,
    };
    setInputValue('');

    const isSelectedOption = selectedItems.some((item) => item.text === optionValue);
    const isExistingOption = options.some((item) => item.text === optionValue);
    if (!isSelectedOption && !isExistingOption) {
      onNewOption(newOption);
    }

    if (!isSelectedOption) {
      addSelectedItem(newOption);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newInputValue = e.target.value || '';
    if (newInputValue === ' ') return;

    const lastTypedChar = newInputValue.slice(-1)[0];

    if (isCreatable && onNewOption && lastTypedChar === ' ') {
      addNewOption(newInputValue);
    } else {
      setInputValue(newInputValue);
    }
  };

  const handleInputKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (isMultiTiles) {
      if (e.key === 'Backspace' && value === '' && selectedItems.length > 0) {
        removeSelectedItem(selectedItems.slice(-1)[0]);
      }
      if (e.key === 'Enter' && value.length > 0) {
        addNewOption(value);
      }
    }

    inputProps?.onKeyDown(e);
  };

  const handleInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    return e;
  };

  const stringLabel = useMemo(() => {
    const isEmpty = selectedItems.length === 0;
    const shouldTruncate = selectedItems.length > MAX_STRING_OPTIONS;
    if (!isEmpty && shouldTruncate) return `Selected (${selectedItems.length})`;
    if (!isEmpty && !shouldTruncate) return selectedItems.map((item) => item.text).join(', ');

    return null;
  }, [selectedItems]);

  const option = useMemo(() => {
    const [selectedItem] = selectedItems ?? [];

    if (isDropdown) {
      return null;
    }

    if (!isMulti) {
      return selectedItem ? <Option {...selectedItem} /> : null;
    }

    if (multiOptionsType === 'string') {
      return (
        <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          {stringLabel}
        </Box>
      );
    }

    if (multiOptionsType === 'tiles') {
      return selectedItems.map((item) => (
        <Box key={item.value} mr={0.5}>
          <Tile item={item} onRemove={removeSelectedItem} />
        </Box>
      ));
    }

    return null;
  }, [selectedItems, isDropdown, isMulti, multiOptionsType, stringLabel, removeSelectedItem]);

  const hasItemsSelected = selectedItems.length > 0;

  const containerCursor = useMemo(() => {
    const cursors = {
      'not-allowed': fieldState === 'disabled' && !isLoading,
      pointer: isDropdown,
      progress: isLoading,
      text: (!hasItemsSelected || (isMulti && multiOptionsType === 'tiles')) && !isLoading,
    };

    return Object.entries(cursors).find(([, condition]) => Boolean(condition))?.[0] ?? 'pointer';
  }, [fieldState, hasItemsSelected, isLoading, multiOptionsType, isMulti, isDropdown]);

  const handleContainerClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isMultiTiles && inputContainer.current?.firstChild) {
      e.stopPropagation();
      (inputContainer.current.firstChild as HTMLInputElement).focus();
    }
  };

  const showPlaceholder = useMemo(() => {
    if (isDropdown) {
      return true;
    }

    return (!value || showSearch) && selectedItems.length === 0;
  }, [value, showSearch, selectedItems.length, isDropdown]);

  return (
    <StyledMainInput
      aria-label={placeholder}
      cursor={containerCursor}
      isMultiString={isMultiString}
      onClick={handleContainerClick}
      role="textbox"
    >
      {option}
      <StyledMainInputContainer
        allowFullWidth={!isMulti}
        flexWrap={isMultiTiles ? 'wrap' : undefined}
        hasSelectedValue={!isMulti && hasItemsSelected}
        isDropdown={isDropdown}
        rowGap={0.5}
      >
        {isString(leftIcon) ? (
          <Icon color="currentColor" name={leftIcon as IconProps['name']} size="16px" />
        ) : (
          leftIcon
        )}
        {showPlaceholder && (
          <StyledMainInputPlaceholder
            fontWeight="medium"
            hasLeftIcon={Boolean(leftIcon)}
            isDropdown={isDropdown}
          >
            {placeholder}
          </StyledMainInputPlaceholder>
        )}
        <StyledMainInputFieldContainer
          ref={inputContainer}
          allowFullWidth={!isMulti && !hasItemsSelected}
          data-value={value}
          fontWeight="regular"
          hasDecorationIcon={Boolean(leftIcon)}
          hide={isDropdown}
        >
          {!showSearch && (
            <StyledMainInputField
              spellCheck={false}
              {...inputProps}
              disabled={fieldState === 'disabled' || isDropdown}
              id={id}
              onChange={handleInputChange}
              onClick={handleInputClick}
              onKeyDown={handleInputKeydown}
            />
          )}
        </StyledMainInputFieldContainer>
      </StyledMainInputContainer>
    </StyledMainInput>
  );
};

export default MainInput;
