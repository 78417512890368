import React, { useEffect, useRef, useState } from 'react';
import type { TreeNodeProps as RcTreeNodeProps, TreeProps as RcTreeProps } from 'rc-tree';
import RcTree from 'rc-tree';

import calculateNodeDrop from './calculateNodeDrop';
import type { DropNodeInfo } from './calculateNodeDrop/calculateNodeDrop';
import defaultRenderSwitcherIcon, { RenderSwitcherIconProps } from './renderSwitcherIcon';
import { StyledTree, StyledTreeNodeIndentationLine, TreeDropIndicator } from './Tree.v1.styles';
import type { TreeKey } from './types';

interface RenderIndentationLinesParams {
  isFirstChild?: boolean;
  level: number;
}

export const renderIndentationLines = ({ isFirstChild, level }: RenderIndentationLinesParams) => {
  const indentationLines = Array.from({ length: level }, (_, index) => {
    return (
      <StyledTreeNodeIndentationLine
        key={index}
        isFirstChild={isFirstChild && level - 1 === index}
        level={index}
      />
    );
  });

  return indentationLines;
};

interface TreeState<T> {
  data: T[];
}

export interface TreeProps<T> extends Omit<RcTreeProps<T>, 'prefixCls' | 'onDrop'> {
  children: ({ children, item }: { children: React.ReactNode; item: T }) => React.ReactNode;
  /**
   * Creates a key from any field.
   * The key is required for drag n drop.
   */
  keyAlias?: string;
  onDrop?: (props: DropNodeInfo & { ordinal: number; parent: TreeKey | null }) => void;
  renderSwitcherIcon?: React.ReactNode | ((props: RenderSwitcherIconProps) => React.ReactNode);
  useNewLayout?: boolean;
}

/**
 * @deprecated. Use Tree.v2 instead.
 */
const Tree = <T,>({
  children,
  keyAlias = '',
  onDrop,
  renderSwitcherIcon = defaultRenderSwitcherIcon,
  showLine,
  treeData = [],
  useNewLayout,
  ...other
}: TreeProps<T>) => {
  const [state, setState] = useState<TreeState<T>>({
    data: treeData,
  });
  const preventTreeDataUpdate = useRef(false);

  useEffect(() => {
    if (treeData && !preventTreeDataUpdate.current) {
      setState((prev) => ({ ...prev, data: treeData }));
    }
  }, [treeData, state.data]);

  const handleDrop = (info: DropNodeInfo) => {
    const nodeDrop = calculateNodeDrop({ info, initialData: state.data, keyAlias });

    setState((prev) => ({
      ...prev,
      data: nodeDrop.data,
    }));
    preventTreeDataUpdate.current = true;

    /**
     * To prevent two state updates when dropping, while maintaining rendering new data when documents are updated
     * elsewhere in the app we must prevent state update from backend immediately on drop.
     */
    setTimeout(() => {
      preventTreeDataUpdate.current = false;
    }, 1500);

    onDrop?.({
      ...info,
      ordinal: nodeDrop.ordinal,
      parent: nodeDrop.parent,
    });
  };

  const renderDeeply = (d: any, cb: any, level = 1) => {
    return d?.map((el: any) => {
      const key = el?.[keyAlias] ?? el?.key;
      const item = { ...el, key, level };
      if (item?.children?.length !== 0) {
        return cb({ children: renderDeeply(item.children, cb, level + 1), item });
      }

      return cb({ item });
    });
  };

  const switcherIcon =
    renderSwitcherIcon instanceof Function
      ? (params: RcTreeNodeProps) => renderSwitcherIcon({ ...params, useNewLayout })
      : renderSwitcherIcon;

  const tree = (
    <RcTree
      {...other}
      dropIndicatorRender={({ dropPosition }) => {
        const dropStylesConfig = {
          '-1': {
            top: 0,
          },
          0: {
            display: 'none',
          },
          1: {
            bottom: 0,
          },
        };

        return <TreeDropIndicator style={dropStylesConfig[dropPosition]} />;
      }}
      onDrop={handleDrop}
      prefixCls="tree"
      switcherIcon={switcherIcon}
    >
      {renderDeeply(state.data, children)}
    </RcTree>
  );

  return <StyledTree useNewLayout={useNewLayout}>{tree}</StyledTree>;
};

export default Tree;
