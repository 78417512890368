import React from 'react';

import formatByte from '@utils/formatByte';

interface TableSizeItemProps {
  bytes?: number;
  rowCount?: number;
}

const TableSizeItem: React.FC<TableSizeItemProps> = ({ bytes = 0, rowCount = 0 }) => {
  return <>{`${rowCount?.toLocaleString()} rows | ${formatByte(bytes)}`}</>;
};

export default TableSizeItem;
