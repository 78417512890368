import React, { HTMLAttributes, useRef } from 'react';
import { uniqueId } from 'lodash';

import Box, { StyledBoxProps } from '@components/Box';
import Text from '@components/Text';
import Icon, { IconProps } from '@components/UI/Icon';
import theme from '@styles/theme';

type StatusType = 'error' | 'warning' | 'success' | 'paused';

interface StatusConfig {
  backgroundColor: StyledBoxProps['color'];
  badgeBackgroundColor: StyledBoxProps['color'];
  badgeTextColor: StyledBoxProps['color'];
  icon: IconProps['name'];
  iconColor: StyledBoxProps['color'];
  text: string;
}

export const statusMap: Record<StatusType, StatusConfig> = {
  error: {
    backgroundColor: '#FFE9E9',
    badgeBackgroundColor: '#FF3544',
    badgeTextColor: '#FFE9E9',
    icon: 'error',
    iconColor: '#FF3544',
    text: 'Failed',
  },
  paused: {
    backgroundColor: '#EAECF0',
    badgeBackgroundColor: '#1D2939',
    badgeTextColor: '#1D2939',
    icon: 'pause-square',
    iconColor: '#1D2939',
    text: 'Paused',
  },
  success: {
    backgroundColor: '#C6FFE9',
    badgeBackgroundColor: '#35D39D',
    badgeTextColor: '#FFE9E9',
    icon: 'success',
    iconColor: '#35D39D',
    text: 'Passed',
  },
  warning: {
    backgroundColor: '#FFEFBE',
    badgeBackgroundColor: '#FFD130',
    badgeTextColor: theme.colors.v1.gray[700],
    icon: 'warning',
    iconColor: '#FFD130',
    text: 'Warning',
  },
};

interface StatusProps extends Pick<HTMLAttributes<HTMLDivElement>, 'aria-label'> {
  icon?: IconProps['name'];
  noIcon?: boolean;
  status?: StatusType;
}

const Status: React.FC<StatusProps> = ({
  'aria-label': ariaLabel,
  children,
  icon,
  noIcon,
  status,
}) => {
  const contentId = useRef(uniqueId('status-content-'));
  if (!status) {
    return null;
  }

  const props = statusMap?.[status];

  if (!props) {
    return null;
  }

  const startIcon = () => {
    if (!noIcon && (icon || props.icon)) {
      return <Icon color="currentColor" name={icon ?? props.icon} size="16px" />;
    }

    return null;
  };

  const content = children ?? props.text;

  return (
    <Box
      alignItems="center"
      aria-label={ariaLabel}
      aria-labelledby={ariaLabel ? undefined : contentId.current}
      backgroundColor={props.backgroundColor}
      borderRadius="default"
      compDisplay="flex"
      compHeight={theme.space(2.5)}
      gap={0.25}
      maxWidth="max-content"
      pl={0.5}
      pr={0.625}
      role="status"
    >
      {startIcon()}
      <Text
        color="gray.700"
        fontSize="sm"
        fontWeight="medium"
        id={contentId.current}
        lineHeight="16px"
      >
        {content}
      </Text>
    </Box>
  );
};

export default Status;
