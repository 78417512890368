import React, { useEffect, useState } from 'react';
import { difference } from 'lodash';

import {
  useFetchDatabaseConfigs,
  useFetchDataSourceConfigInit,
  usePatchDatabaseConfigs,
} from '@api/ingestion';
import Alert from '@components/Alert';
import Button from '@components/Button/Button';
import NextStepButton from '@components/DataSourceSetup/components/Buttons/NextStepButton';
import PreviousStepButton from '@components/DataSourceSetup/components/Buttons/PreviousStepButton';
import { BoxWrapper } from '@components/DataSourceSetup/components/DataSourceAddStep';
import { CenterLoader, IndeterminateLoaderModal } from '@components/IndeterminateLoader';
import { renderInfoToast } from '@components/Toast';
import { ModalFooter } from '@components/UI/Modal';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import { DataSourceModel } from '@models/DataSourceModel';
import { Filter } from '@utils';

import ErrorDescriptionMarkdown from '../ErrorDescriptionMarkdown';
import SchemaHeader from '../SchemaHeader';

import DatabaseIngestionTable from './DatabaseIngestionTable';

const dbConfigRequest: Filter.FilterOptions = {
  page: 1,
  page_size: 50,
  query: '{guid,name,should_ingest}',
};

const searchConfig: { [key: string]: keyof Filter.FilterOptions } = {
  name: 'search_name',
};

interface DatabaseIngestionConfigStepProps {
  dataSource: DataSourceModel;
  onConfigUpdated: () => void;
  prevStep: () => void;
}

export type SelectedRows = { [guid: string]: boolean };

export const getPayload = (prev: SelectedRows, curr: SelectedRows) => {
  const prevSelectedRows = Object.keys(prev);
  const currSelectedRows = Object.keys(curr);
  const dbsAdded = difference(currSelectedRows, prevSelectedRows);
  const dbsDeleted = difference(prevSelectedRows, currSelectedRows);

  if (dbsAdded.length > 0 || dbsDeleted.length > 0) {
    const payload = {
      items: [
        ...dbsAdded.map((row) => ({
          config: row,
          should_ingest: true,
        })),
        ...dbsDeleted.map((row) => ({
          config: row,
          should_ingest: false,
        })),
      ],
    };
    return payload;
  }

  return null;
};

export const DatabaseIngestionConfigStep: React.FC<DatabaseIngestionConfigStepProps> = ({
  dataSource,
  onConfigUpdated,
  prevStep,
}) => {
  const segment = useSegmentContext();
  const [selectedRowIds, setSelectedRowIds] = useState<SelectedRows>({});
  const [selectedRowCount, setSelectedRowCounts] = useState<number>(0);
  const FilterService = Filter.useUpdateFilters(dbConfigRequest, searchConfig, {});
  const [nextButtonClicked, setNextButtonClicked] = useState<boolean>(false);
  const [isFirstTime, setIsFirstTime] = useState<boolean>(true);
  const { changePage, filter } = FilterService;
  const {
    data: dbConfigsResponse,
    error,
    isLoading,
    refetch,
  } = useFetchDatabaseConfigs(dataSource.guid, {
    cacheTime: 0,
    onSuccess: (d) => {
      if (d?.count === 0 && isFirstTime) {
        refreshDataSourceIngestionConfig();
      }
      if (d.count > 0 && isFirstTime) {
        setSelectedRowCounts(d?.active_count || d?.count);
        setIsFirstTime(false);
      }
      if (d.count > 0) {
        const state: { [guid: string]: boolean } = {};
        d?.results?.filter((ds) => ds.shouldIngest).forEach((ds) => (state[ds.guid] = true));
        setSelectedRowIds((prev) => ({ ...prev, ...state }));
      }
    },
    params: Filter.setParams(filter),
    staleTime: 0,
  });

  const {
    error: errorRefetchIngestionConfig,
    isLoading: loadingDSIngestionConfig,
    refetch: refreshDataSourceIngestionConfig,
  } = useFetchDataSourceConfigInit(dataSource.guid, {
    cacheTime: 0,
    enabled: false,
    onSuccess: () => {
      refetch();
    },
    staleTime: 0,
  });

  const {
    error: errorSave,
    isLoading: loadingSave,
    mutate,
  } = usePatchDatabaseConfigs(dataSource.guid, {
    onSuccess: () => {
      renderInfoToast('Ingestion Configuration Saved');
    },
  });

  const ingestionConfig = dbConfigsResponse && !isLoading ? dbConfigsResponse?.results : undefined;

  const totalPages =
    dbConfigsResponse && filter.page_size
      ? Math.ceil(dbConfigsResponse.count / filter.page_size)
      : 1;

  const handleRefresh = () => {
    refreshDataSourceIngestionConfig();
  };

  const onPageChange = (page: number) => {
    changePage(page);
  };

  const handleCheckboxClick = (dbs: SelectedRows) => {
    const payload = getPayload(selectedRowIds, dbs);
    /**
     * we need to send the request at this point
     * At this point, though only one of dbsAdded or
     * dbsDeleted should be filled.
     * We can also assume these will have only one element
     * since this is happening onClick of a checkbox.
     */
    if (payload !== null) {
      mutate(payload);
    }
    /**
     * we have to be a bit smart in figuring out the selected row counts.
     * if the currCount is 5 and newCount is 3, that means 2 rows
     * need to be subtracted.
     * We do it like this since initial count comes from the backend
     * as active_count and we never have the full state in memory
     * due to pagination
     */
    const prevSelectedRowsLength = Object.keys(selectedRowIds).length;
    const currSelectedRowsLength = Object.keys(dbs).length;
    const diffSelectedRowsLength = prevSelectedRowsLength - currSelectedRowsLength;
    setSelectedRowCounts((prev) => prev - diffSelectedRowsLength);
    setSelectedRowIds(dbs);
  };

  const handleNextButtonClick = () => {
    if (ingestionConfig) {
      /**
       * we only pass what is added to segment
       */
      const addedDbs = Object.keys(selectedRowIds).map((config) => ({
        config,
        should_ingest: true,
      }));
      segment?.track(SegmentTrackEventName.ImportData_SelectDatabasesNextButtonClicked, {
        selectedDatabases: addedDbs.length,
        totalDatabases: ingestionConfig.length,
      });
    }
  };

  useEffect(() => {
    if (nextButtonClicked) {
      handleNextButtonClick();
      onConfigUpdated();
    }
  }, [nextButtonClicked]);

  return (
    <>
      <SchemaHeader selectedCount={ingestionConfig ? selectedRowCount : undefined} />
      <BoxWrapper pt={0}>
        {loadingDSIngestionConfig ? (
          <CenterLoader>
            <IndeterminateLoaderModal
              active
              content="Loading databases..."
              indeterminate
              inline="centered"
              size="medium"
            />
          </CenterLoader>
        ) : (
          <DatabaseIngestionTable
            changePage={onPageChange}
            currentPage={filter.page || 1}
            data={ingestionConfig}
            filterService={FilterService}
            selectedRowIds={selectedRowIds}
            setSelectedRowIds={handleCheckboxClick}
            totalPages={totalPages}
          />
        )}
        {error && (
          <Alert title="Something went wrong" type="error">
            {error.data || 'Please try again later.'}
          </Alert>
        )}
        {errorRefetchIngestionConfig && (
          <Alert title="Something went wrong" type="error">
            {errorRefetchIngestionConfig.data || 'Please try again later.'}
          </Alert>
        )}
        {errorSave && errorSave.status === 500 && (
          <Alert title="Couldn't save changes" type="error">
            {errorSave.data || 'Please try again later.'}
          </Alert>
        )}
        {errorSave && errorSave.data.items && (
          <Alert title="Invalid database" type="error">
            {errorSave.data.items}
          </Alert>
        )}
        <ErrorDescriptionMarkdown error={error} />
        <ErrorDescriptionMarkdown error={errorRefetchIngestionConfig} />
        <ErrorDescriptionMarkdown error={errorSave} />
      </BoxWrapper>
      <ModalFooter>
        <PreviousStepButton
          disabled={loadingSave || !ingestionConfig}
          onClick={() => {
            segment?.track(SegmentTrackEventName.ImportData_SelectDatabasesPrevButtonClicked);
            prevStep();
          }}
        />
        <Button
          compWidth="100px"
          disabled={loadingSave || !ingestionConfig}
          onClick={handleRefresh}
        >
          Refresh
        </Button>
        <NextStepButton
          disabled={!selectedRowCount || loadingSave || !ingestionConfig}
          onClick={() => setNextButtonClicked(true)}
        />
      </ModalFooter>
    </>
  );
};

export default DatabaseIngestionConfigStep;
