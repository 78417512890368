import React from 'react';

import { NotificationModel } from '@api/notifications/NotificationModel';
import {
  StyledActivityMessage,
  StyledActivityMessageTargetLink,
} from '@components/ActivityMessage/ActivityMessage.styles';
import TargetObjectName from '@components/ActivityMessage/TargetObjectName';
import Box from '@components/Box';
import Text from '@components/Text';
import Tooltip from '@components/Tooltip';
import Avatar from '@components/UI/Avatar';
import Icon from '@components/UI/Icon';
import theme from '@styles/theme';

interface ActionNotificationProps {
  item: NotificationModel;
}

const ActionNotification = ({ item }: ActionNotificationProps) => {
  const { actor, target, verb } = item;
  return (
    <Box compDisplay="flex" gap={1}>
      <Avatar {...actor.mappedAvatar} mt={0.25} size={theme.space(4)} />
      <StyledActivityMessage>
        <Text
          as="span"
          display="inline"
          fontSize="body1"
          fontWeight="semibold"
          verticalAlign="middle"
        >
          {actor.fullName}{' '}
        </Text>
        <Text as="span" display="inline" fontSize="body1" verticalAlign="middle">
          {verb}{' '}
        </Text>
        <Tooltip content={target.name}>
          <StyledActivityMessageTargetLink>
            <Icon
              compDisplay="inline"
              mr={0.5}
              name={target.dataTypes?.icons.objectType!}
              size="13px"
              verticalAlign="middle"
            />
            <TargetObjectName text={target.name} />
          </StyledActivityMessageTargetLink>
        </Tooltip>
      </StyledActivityMessage>
    </Box>
  );
};

export default ActionNotification;
