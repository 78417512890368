import React from 'react';
import { DBT_DOCS_SYNC_DOCS_URL } from '@constants';

import Alert from '@components/Alert';
import Link from '@components/Link';
import theme from '@styles/theme';

const GithubDocumentationAlert = () => {
  return (
    <Alert type="info">
      See instructions in our{' '}
      <Link
        color={theme.colors.hyperlink}
        fontSize="inherit"
        href={DBT_DOCS_SYNC_DOCS_URL}
        rel="noopener noreferrer"
        target="_blank"
        underline
      >
        documentation.
      </Link>
    </Alert>
  );
};

export default GithubDocumentationAlert;
