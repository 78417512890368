import styled from '@emotion/styled';

import Box from '@components/Box';

export const StyledOverviewTab = styled(Box)`
  ${({ theme }) => theme.media.down('md')`
    flex-direction: column;
  `}
`;

export const OverviewTabSidebar = styled.div`
  padding: ${({ theme }) => theme.space(2)};
  margin-top: ${({ theme }) => theme.space(2)};
  flex-shrink: 0;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.v1.gray[200]};

  ${({ theme }) => theme.media.up('md')`
    width: 300px;
    border-top: none;
    border-left: 1px solid ${theme.colors.v1.gray[200]};
    margin-top: 0;
  `}
`;
