import getDebug from 'debug';
import compact from 'lodash/compact';
import head from 'lodash/head';
import union from 'lodash/union';

import TableLineageModel from '@api/lineage/TableLineageModel';

const debug = getDebug('selectstar:explore:relatedTables');

type Direction = 'upstream' | 'downstream';

const recursiveOneWayDFS = (
  currentTable: TableLineageModel,
  tables: TableLineageModel[],
  visited: string[],
  direction?: Direction,
): TableLineageModel[] => {
  let downstreamTables: TableLineageModel[][] = [];
  let upstreamTables: TableLineageModel[][] = [];

  if (!direction || direction === 'downstream') {
    downstreamTables = currentTable.targetTableGuids
      .filter((t) => !visited.includes(t))
      .map((tableGuid) => {
        visited.push(tableGuid);
        const table = head(tables.filter((t) => t.guid === tableGuid));
        if (table === undefined) return [];
        return recursiveOneWayDFS(table, tables, visited, 'downstream');
      });
  }

  if (!direction || direction === 'upstream') {
    upstreamTables = currentTable.sourceTableGuids
      .filter((t) => !visited.includes(t))
      .map((tableGuid) => {
        visited.push(tableGuid);
        const table = head(tables.filter((t) => t.guid === tableGuid));
        if (table === undefined) return [];
        return recursiveOneWayDFS(table, tables, visited, 'upstream');
      });
  }

  // Union arrays (deduplicate) and remove false values (undefined)
  return compact(union([currentTable], ...upstreamTables, ...downstreamTables));
};

const findRelatedTables = (
  pinnedTable: string | null,
  tables: TableLineageModel[],
): TableLineageModel[] => {
  if (pinnedTable === null) {
    debug('relatedTables null', { guid: pinnedTable, related: tables });
    return tables;
  }

  const startingTable = head(tables.filter((t) => t.guid === pinnedTable));

  if (startingTable === undefined) {
    return tables;
  }

  const relatedTables = recursiveOneWayDFS(startingTable, tables, []);
  debug('relatedTables', { guid: pinnedTable, related: relatedTables });

  return relatedTables;
};

export default findRelatedTables;
