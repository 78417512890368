import React from 'react';

import { useFetchDsUserMostQueriedDashboards } from '@api/dsusers';
import TabError from '@components/TabContent/TabError';
import MostUsedTable from '@components/Table/MostUsedTable';
import { TabContentProps } from '@components/Tabs/types';
import type { TabRoutesConfig } from '@components/TabsRouter';
import TabsRouter from '@components/TabsRouter';
import flags from '@features';
import { DataSourceTypesType } from '@models/DataSourceCredentials';

import LookerDashboardTab from './LookerDashboardTab';
import LookerExploreTab from './LookerExploreTab';

const querySummaryConfig = {
  page_size: 20,
};
interface MostQueriedTabProps extends TabContentProps {
  dataSourceType?: DataSourceTypesType;
  guid: string;
}

const MostQueriedTab: React.FC<MostQueriedTabProps> = (props) => {
  const { dataSourceType, guid } = props;
  const isDsLooker = dataSourceType === 'looker';
  const { data, isError, isLoading } = useFetchDsUserMostQueriedDashboards(guid, {
    params: querySummaryConfig,
  });

  const tabsRouterConfig: TabRoutesConfig[] = [
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <LookerDashboardTab {...props} />,
      default: true,
      label: 'Looker Dashboards',
      route: '/looker-dashboards',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <LookerExploreTab {...props} />,
      label: 'Looker Explores',
      route: '/looker-explores',
    },
  ];

  if (isDsLooker && flags.dashboards_explores_sub_tabs_dsuser_profile) {
    return <TabsRouter config={tabsRouterConfig} />;
  }

  if (isError) return <TabError />;

  return (
    <MostUsedTable
      data={data?.results}
      dataSourceType={dataSourceType}
      itemCount={data?.count}
      loading={isLoading}
    />
  );
};

export default React.memo(MostQueriedTab);
