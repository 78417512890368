import styled from '@emotion/styled';

export const StyledTableElement = styled.table`
  display: block;
  border-radius: ${({ theme }) => theme.radius.default};
  overflow-x: hidden;

  &:hover {
    overflow-x: auto;
  }
`;

export const TDElement = styled.td`
  && {
    position: relative;
    border: 1px solid #d8dbe0 !important;
    display: table-cell;
    padding: ${({ theme }) => theme.space(1.25, 1.25)} !important;
    min-width: 8rem;
    line-height: 1 !important;
    height: auto !important;
  }
`;

export const TRElement = styled.tr`
  && {
    border: none !important;
  }
`;

export const THElement = styled.th`
  && {
    position: relative;
    border: 1px solid #d8dbe0 !important;
    display: table-cell;
    font-weight: ${({ theme }) => theme.typography.fontWeights.regular} !important;
    padding: ${({ theme }) => theme.space(1.25, 1.25)} !important;
    text-align: inherit !important;
    background-color: transparent !important;
  }
`;

export const THeadElement = styled.thead`
  background-color: #f2f5f9;
  height: 36px;
`;
