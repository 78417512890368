import { createContext, useContext } from 'react';

import { HierarchyResponseData } from '@models/HierarchyResponse';

export interface SidebarCacheObject {
  guid: string;
  icon?: string;
  name: string;
  objectType: string;
  type?: string;
}
export interface Node extends SidebarCacheObject {
  children: Node[];
}
export interface SidebarItems {
  [guid: string]: SidebarCacheObject | undefined;
}
export interface SessionContextProps {
  setSidebarItems?: (data: HierarchyResponseData) => void;
  sidebarItems?: SidebarItems;
}

export const SessionContext = createContext<SessionContextProps>({});

export const useSessionContext = () => {
  const sessionContext = useContext(SessionContext);

  if (sessionContext === undefined) {
    throw new Error(`useSessionContext must be used within <SessionProvider>.`);
  }

  return sessionContext;
};

export default useSessionContext;
