import { XYPosition } from 'reactflow';

import { UsageTypeType } from '@api/lineage/types';
import { MetadataModel } from '@models/MetadataModel';

import {
  ExploreEdge,
  ExploreNode,
  ExploreNodeData,
  InputNodesById,
  LineageLoadingState,
  LineageSidebarTabs,
  UserInteraction,
} from '../LineageExplore.types';
import {
  CreateStacksResult,
  EdgesById,
  NodesById,
  StackGroups,
} from '../useCreateNodesEdges/algorithm/types';

export type BiggestConflictEndPerStack = Record<number, number>;

export interface LineageState {
  biggestConflictEndPerStack: BiggestConflictEndPerStack;
  edgesById: EdgesById;
  initialPosition: XYPosition;
  inputNodesById?: InputNodesById;
  nodesById: NodesById;
  nodesByStack: StackGroups;
  stackData?: CreateStacksResult;
}
export interface SelectedNode {
  data?: ExploreNodeData;
  guid: string;
  key: string;
  metadata?: MetadataModel;
  type: 'table' | 'column';
}

export type OneDirectionParseExceptions = Record<string, boolean>;

export interface EnableColumnLevelLineageParams extends LineageState {
  rootColumnKey: string;
}

export interface SetStacksDataOptions {
  fitView?: boolean;
}

export interface UsageTypesState {
  shouldNotRecalculate?: boolean;
  usageTypes: UsageTypeType[];
}

interface AllColumnLineage {
  inputNodesById?: InputNodesById;
  previousLineageState?: LineageState;
}

export interface ContextDefaultValues {
  biggestConflictEndPerStack: BiggestConflictEndPerStack;
  centeredTableKey: string;
  columnLevelLineageRootKey?: string;
  createWaitingAction: (action: React.RefObject<Function>) => void;
  disableColumnLevelLineage: () => void;
  edgesById: EdgesById;
  enableColumnLevelLineage: (
    params: EnableColumnLevelLineageParams,
    updatePreviousTableLineageState?: boolean,
  ) => void;
  expandLineageModeAllState?: AllColumnLineage;
  focusedNodeKey: string;
  getAllParents: (
    nodeKey: string,
    currentStacksData?: CreateStacksResult,
  ) => ExploreNode[] | undefined;
  initialPosition: XYPosition;
  initialSelectedNodeGuid?: string;
  initialSelectedNodeParentGuid: string;
  inputNodesById?: InputNodesById;
  isCollapseAllButtonEnabled: boolean;
  isColumnLevelLineage: boolean;
  isColumnLevelLoading: boolean;
  isFetchingExpandColumnLineageModeAll: boolean;
  isLoadingSelectedNode?: boolean;
  loadingState: LineageLoadingState;
  nextUserInteraction: React.MutableRefObject<undefined | UserInteraction>;
  nodesById: NodesById;
  nodesByStack: StackGroups;
  oneDirectionParseExceptions: OneDirectionParseExceptions;
  openOverviewTab: (origin: SidebarOpeningOrigin) => void;
  previousTableLineageState: LineageState;
  selectedEdge?: ExploreEdge;
  selectedNode?: SelectedNode;
  selectedSidebarTab?: LineageSidebarTabs;
  selectedUsageTypesState: UsageTypesState;
  setBiggestConflictEndPerStack: React.Dispatch<BiggestConflictEndPerStack>;
  setCenteredTableKey: React.Dispatch<string>;
  setEdgesById: React.Dispatch<React.SetStateAction<EdgesById>>;
  setExpandLineageModeAllState: React.Dispatch<AllColumnLineage | undefined>;
  setFocusedNodeKey: React.Dispatch<string>;
  setInitialPosition: (position: XYPosition) => void;
  setInputNodesById: React.Dispatch<React.SetStateAction<InputNodesById | undefined>>;
  setIsCollapseAllButtonEnabled: React.Dispatch<boolean>;
  setIsColumnLevelLineage: React.Dispatch<boolean>;
  setIsColumnLevelLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFetchingExpandColumnLineageModeAll: React.Dispatch<React.SetStateAction<boolean>>;
  setLoadingState: React.Dispatch<React.SetStateAction<LineageLoadingState>>;
  setNextUserInteraction: (interaction?: UserInteraction) => void;
  setNodesById: React.Dispatch<NodesById>;
  setNodesByStack: React.Dispatch<StackGroups>;
  setOneDirectionParseExceptions: React.Dispatch<OneDirectionParseExceptions>;
  setPreviousTableLineageState: React.Dispatch<LineageState>;
  setSelectedEdge: React.Dispatch<ExploreEdge | undefined>;
  setSelectedNode: React.Dispatch<SelectedNode>;
  setSelectedUsageTypesState: React.Dispatch<UsageTypesState>;
  setSidebarTab: React.Dispatch<LineageSidebarTabs | undefined>;
  setStacksData: (stacksData?: CreateStacksResult, options?: SetStacksDataOptions) => void;
  setUseRelevantLineage: React.Dispatch<boolean>;
  stackData?: CreateStacksResult;
  useRelevantLineage: boolean;
}

// TRACKING

export enum SidebarOpeningOrigin {
  Button = 'button',
  Column = 'column',
  Table = 'table',
}

export enum LineageModeChangeTrigger {
  Click = 'click',
  OpenAllDownstream = 'open_all_downstream',
  OpenAllUpstream = 'open_all_upstream',
  OpenOneLevelDownstreamLineage = 'open_open_1_level_downstream_lineage',
  OpenOneLevelUpstreamLineage = 'open_1_level_upstream_lineage',
  OpenTwoLevelsDownstreamLineage = 'open_2_levels_downstream_lineage',
  OpenTwoLevelsUpstreamLineage = 'open_2_levels_upstream_lineage',
}

export enum LineageExpansionType {
  CloseAllDownstream = 'close_all_downstream',
  CloseAllUpstream = 'close_all_upstream',
  OpenAllDownstream = 'open_all_downstream',
  OpenAllUpstream = 'open_all_upstream',
  OpenOneLevelDownstreamLineage = 'open_open_1_level_downstream_lineage',
  OpenOneLevelUpstreamLineage = 'open_1_level_upstream_lineage',
  OpenTwoLevelsDownstreamLineage = 'open_2_levels_downstream_lineage',
  OpenTwoLevelsUpstreamLineage = 'open_2_levels_upstream_lineage',
}

export enum LineageLevelType {
  Column = 'column',
  Table = 'table',
}

export enum LineageSearchToggleState {
  Off = 'off',
  On = 'on',
}

export enum LineageNodeOptionsMenuState {
  Closed = 'closed',
  Open = 'open',
}

export enum LineageNodeOpeningState {
  Collapsed = 'collapsed',
  Expanded = 'expanded',
}
