import React, { useEffect, useState } from 'react';

import { CollectionModel } from '@api/collections/CollectionModel';
import Box from '@components/Box';
import Text from '@components/Text';
import type { SelectValue } from '@components/UI/Select';
import Select, { SelectProps } from '@components/UI/Select/Select';
import { useCollectionsHierarchy } from '@context/CollectionsHierarchy';
import { SelectedBulkItem } from '@hooks/useBulkEditSelected';

export interface CollectionsSelectProps
  extends Pick<SelectProps, 'popperConfigProps' | 'placeholder'> {
  initialSelected?: CollectionModel[];
  isMulti?: boolean;
  /*
   * Objects that the collections will be edited
   * To build the objects array use the useBulkEditSelected hook
   */
  objects: SelectedBulkItem[];
  onChange?: (selectedItems: SelectValue) => void;
}

const CollectionsSelect: React.FC<CollectionsSelectProps> = ({
  initialSelected,
  isMulti,
  objects,
  onChange,
  placeholder,
  popperConfigProps,
}) => {
  const [selectedItems, setSelectedItems] = useState<SelectValue>([]);
  const { collectionsHierarchyOptions } = useCollectionsHierarchy();

  const handleSelectedItemsChange = (value: SelectValue) => {
    setSelectedItems(value);
    onChange?.(value);
  };

  useEffect(() => {
    const initialSelectedCollections =
      initialSelected?.map((item) => ({
        text: item.name,
        value: item.guid,
      })) ?? [];

    setSelectedItems(initialSelectedCollections);
  }, [objects?.length, setSelectedItems, initialSelected]);

  return (
    <Box alignItems="center" compDisplay="flex">
      <Select
        clearInputOnSelect={false}
        isMulti={isMulti}
        isTree
        maxWidth="100%"
        onChange={handleSelectedItemsChange}
        optionListMaxHeight="378px"
        options={collectionsHierarchyOptions}
        placeholder={placeholder}
        popperConfigProps={{
          fallbackPlacements: ['bottom-end', 'top-start', 'top-end'],
          ...popperConfigProps,
        }}
        renderEmptyMessage={() => (
          <Text color="gray.600" fontSize="sm" mb={0} px={1.75} py={1.75}>
            No Result Found.
          </Text>
        )}
        showSearchOnOptions
        showSelectAllButton={false}
        useNewLayout
        value={selectedItems}
      />
    </Box>
  );
};

export default CollectionsSelect;
