export const all = ['tableau'];

export const datasource = (id: string) => [...all, id];

export const table = (id: string) => [...all, 'tables', id];

export const tableauDataSourceRelatedDashboards = (id: string) => [...all, id, 'relatedDashboards'];

export const tableauDataSourceRelatedDashboardsCsv = (id: string) => [
  ...all,
  id,
  'relatedDashboards',
  'csv',
];

export const tableauFields = (id: string) => [...all, id, 'fields'];
