import React from 'react';

import Icon from '@components/UI/Icon';
import flags from '@features';

import { StyledLink } from './OpenInDjango.styles';

interface OpenInDjangoProps {
  guid?: string;
  size?: string;
}

const OpenInDjango: React.FC<OpenInDjangoProps> = ({ guid, size }) => {
  return (
    <>
      {flags.open_in_django && guid && (
        <StyledLink
          href={`${window.env.DJANGO_ADMIN_URL}/object/${guid}/change/`}
          target="_blank"
          title="Open in django"
        >
          <Icon name="django" size={size} />
        </StyledLink>
      )}
    </>
  );
};

export default OpenInDjango;
