import { Type } from 'class-transformer';

import { ColumnModel } from '@api/columns/ColumnModel';
import { SchemaModel } from '@api/schema/SchemaModel';
import { TableModel } from '@api/tables/TableModel';

class SourceColumnsTablesModel {
  @Type(() => TableModel)
  table!: TableModel;

  @Type(() => ColumnModel)
  columns!: ColumnModel[];
}

class SourceColumnsSchemaTablesModel {
  @Type(() => SchemaModel)
  schema!: SchemaModel;

  @Type(() => SourceColumnsTablesModel)
  tables!: SourceColumnsTablesModel[];
}

class SourceColumnsModel {
  @Type(() => SourceColumnsSchemaTablesModel)
  upstream!: SourceColumnsSchemaTablesModel[];
}

export default SourceColumnsModel;
