import React from 'react';

import ImpactScore from '@components/ImpactScore';

import type { SidebarTreeItemProps } from './types';

const SidebarTreePopularityCell: React.FC<SidebarTreeItemProps> = ({
  dataTypes,
  impactScore,
  impactedObjectCount,
}) => {
  if (dataTypes?.dataType === 'implicit') {
    return null;
  }

  return (
    <ImpactScore
      impactedValue={impactedObjectCount}
      showNotApplicable={false}
      value={impactScore}
    />
  );
};

export default SidebarTreePopularityCell;
