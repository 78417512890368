import React from 'react';

interface EmojiProps {
  className?: string;
  type: string;
}

export const emojis: Record<string, { char: string; label: string }> = {
  check: { char: '✅', label: 'check' },
  fire: { char: '🔥', label: 'fire' },
  key: { char: '🔑', label: 'key' },
  pointingDown: { char: '👇', label: 'pointingDown' },
  progress: { char: '⏳', label: 'progress' },
  sparkles: { char: '✨', label: 'sparkles' },
  tada: { char: '🎉', label: 'tada' },
  warning: { char: '⚠️', label: 'warning' },
};

const Emoji: React.FC<EmojiProps> = ({ className = '', type }) => {
  return (
    <span aria-label={emojis[type].label} className={className} role="img">
      {emojis[type].char}
    </span>
  );
};

export default Emoji;
