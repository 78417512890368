import { EdgeType } from '@api/lineage/types';

import type { Direction, TableLink, TableRichGraph, TreeContext } from '../../types';

import generateTablesLinksCoordinates from './generateTablesLinksCoordinates';

interface GenerateTableRelationsPathsArgs {
  activeTableId: string | null;
  context: TreeContext;
  direction?: Direction;
  filterByEdgeType?: EdgeType;
  graph: TableRichGraph;
}

/**
 * Generates an array of coordinates to visualize paths between tables based on direction.
 */
const generateTableRelationsPaths = ({
  activeTableId,
  context,
  direction = 'both',
  filterByEdgeType,
  graph,
}: GenerateTableRelationsPathsArgs): TableLink[] => {
  const { rectHeight, rectWidth, tablesById, xScale } = context;
  const output: TableLink[] = [];

  if (activeTableId === undefined) {
    return output;
  }

  /**
   *  The arrow has 7 units, we need to invert the scale to have it fixed size instead of proportional.
   */
  const smallDistanceForArrowHead = xScale.invert(7);

  if (direction !== 'right') {
    output.push(
      ...generateTablesLinksCoordinates({
        activeTableId,
        filterByEdgeType,
        graph,
        rectHeight,
        rectWidth,
        relation: 'parents',
        smallDistanceForArrowHead,
        tablesById,
      }),
    );
  }

  if (direction !== 'left') {
    output.push(
      ...generateTablesLinksCoordinates({
        activeTableId,
        filterByEdgeType,
        graph,
        rectHeight,
        rectWidth,
        relation: 'children',
        smallDistanceForArrowHead,
        tablesById,
      }),
    );
  }

  return output;
};

export default generateTableRelationsPaths;
