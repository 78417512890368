import React, { useMemo, useState } from 'react';

import { useFetchDsUsers } from '@api/dsusers';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import TabError from '@components/TabContent/TabError';
import ModeConnectionsTable from '@components/Table/ModeConnectionsTable';
import { TabContentProps } from '@components/Tabs/types';
import Icon from '@components/UI/Icon';
import { useModal } from '@context/Modal';
import { Filter } from '@utils';
import stripSpaces from '@utils/stripSpaces';

import AddConnectionRow from './AddConnectionRow';
import RemoveModeServiceAccountModal from './RemoveModeServiceAccountModal';

const searchConfig: { [key: string]: keyof Filter.FilterOptions } = {
  name: 'search_name',
};

const sortConfig: { [key: string]: string } = {
  name: 'name',
};

const query = stripSpaces(`{
  guid,
  name,
  data_types,
  data_source {
    guid,
    name,
    type
  },
}`);

const DS_USERS_CONFIG_MODE: Filter.FilterOptions = {
  mode_service_account: true,
  page: 1,
  page_size: 20,
  query,
};

const DS_USERS_CONFIG_PERISCOPE: Filter.FilterOptions = {
  page: 1,
  page_size: 20,
  periscope_service_account: true,
  query,
};

const DS_USERS_CONFIG_SIGMA: Filter.FilterOptions = {
  page: 1,
  page_size: 20,
  query,
  sigma_service_account: true,
};

interface ConnectionsTabProps extends TabContentProps {
  dsType: string;
}

const ConnectionsTab: React.FC<ConnectionsTabProps> = ({ dsType }) => {
  const [showAddConnection, setShowAddConnection] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState({});
  const { MODAL_IDS, checkModalOpened, closeModal, openModal } = useModal();

  const getConfigByBIType = (type: string) => {
    if (type === 'periscope') {
      return DS_USERS_CONFIG_PERISCOPE;
    }
    if (type === 'sigma') {
      return DS_USERS_CONFIG_SIGMA;
    }
    return DS_USERS_CONFIG_MODE;
  };

  const FilterService = Filter.useUpdateFilters(
    getConfigByBIType(dsType),
    searchConfig,
    sortConfig,
    'name',
  );

  const { filter } = FilterService;

  const effectiveConfig = React.useMemo(() => {
    return { params: Filter.setParams(filter) };
  }, [filter]);

  const { data, isError, isLoading } = useFetchDsUsers({
    ...effectiveConfig,
  });

  const totalPages = data && filter.page_size ? Math.ceil(data.count / filter.page_size) : 1;
  const selectedRowKeys = useMemo(() => Object.keys(selectedRowIds), [selectedRowIds]);

  if (isError) return <TabError />;

  return (
    <>
      <Box compDisplay="flex" compWidth="100%" justifyContent="flex-end" mb={1}>
        {selectedRowKeys.length > 0 ? (
          <Button
            backgroundColor="gray.500"
            onClick={() => openModal(MODAL_IDS.removeModeServiceAccountModal)}
          >
            <Box alignItems="center" compDisplay="flex" gap={0.5}>
              <Icon color="currentColor" name="trash" size="16px" />
              Remove
            </Box>
          </Button>
        ) : (
          <Button onClick={() => setShowAddConnection(true)} variant="outlined">
            <Box alignItems="center" compDisplay="flex" gap={0.5}>
              <Icon name="user" />
              Add Data Source User
            </Box>
          </Button>
        )}
      </Box>
      <ModeConnectionsTable
        data={data?.results}
        filterService={FilterService}
        loading={isLoading}
        selectedRowIds={selectedRowIds}
        setSelectedRowIds={setSelectedRowIds}
        totalPages={totalPages}
      />
      {showAddConnection && (
        <AddConnectionRow dsType={dsType} onClose={() => setShowAddConnection(false)} />
      )}
      {checkModalOpened(MODAL_IDS.removeModeServiceAccountModal) && (
        <RemoveModeServiceAccountModal
          onClose={() => {
            closeModal(MODAL_IDS.removeModeServiceAccountModal);
          }}
          onSuccess={() => {
            setSelectedRowIds({});
          }}
          selectedItems={data?.results?.filter((dsUser) => dsUser.guid in selectedRowIds) || []}
        />
      )}
    </>
  );
};

export default React.memo(ConnectionsTab);
