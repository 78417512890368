import { DataTypesConfig } from '@configs/dataTypes/types';

export interface OverviewTabConfigItem {
  showDbt?: boolean;
  showDescriptionSelector?: boolean;
  showIndexes?: boolean;
}

const WAREHOUSE_TABLE_VIEW = { showDbt: true };

const OVERVIEW_TAB_CONFIG: DataTypesConfig<OverviewTabConfigItem> = {
  bigquery: { default: WAREHOUSE_TABLE_VIEW },
  databricks: { default: WAREHOUSE_TABLE_VIEW, notebook: { default: { showDbt: false } } },
  glue: { default: WAREHOUSE_TABLE_VIEW },
  postgres: {
    default: {
      // indexes are supported only for PostgreSQL data source
      showIndexes: true,
    },
  },
  redshift: { default: WAREHOUSE_TABLE_VIEW },
  snowflake: { default: WAREHOUSE_TABLE_VIEW },
};

export default OVERVIEW_TAB_CONFIG;
