import { DataNodeTypes } from '../types';

import biNodeParser from './biNodeParser';
import columnNodeParser from './columnNodeParser';
import databaseNodeParser from './databaseNodeParser';
import schemaNodeParser from './schemaNodeParser';
import tableNodeParser from './tableNodeParser';
import { NodeParser } from './types';

const parseNode: NodeParser = (args) => {
  const { rawNode } = args;
  const parserByType: Record<DataNodeTypes, NodeParser> = {
    [DataNodeTypes.database]: databaseNodeParser,
    [DataNodeTypes.bi]: biNodeParser,
    [DataNodeTypes.schema]: schemaNodeParser,
    [DataNodeTypes.table]: tableNodeParser,
    [DataNodeTypes.column]: columnNodeParser,
  };

  const parser = parserByType[rawNode?.type as DataNodeTypes];

  return parser(args);
};

export default parseNode;
