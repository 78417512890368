import React, { useCallback, useEffect, useRef } from 'react';
import uniqBy from 'lodash/uniqBy';

import { useFetchCollections } from '@api/collections';
import { CollectionModel } from '@api/collections/CollectionModel';
import invalidateCache from '@api/invalidateCache';
import { GetCollectionsListQueryParams } from '@api/openapi.generated';
import Box from '@components/Box';
import BulkButtons from '@components/BulkButtons';
import UnexpectedError from '@components/Error/UnexpectedError';
import LayoutMainGrid from '@components/Layout/LayoutMainGrid';
import PageHeaderBar from '@components/PageHeaderBar';
import CollectionsTable from '@components/Table/CollectionsTable';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import useBulkEditSelected from '@hooks/useBulkEditSelected';
import useGridListViewConfig from '@hooks/useGridListViewConfig';
import { StyledTablesPage } from '@pages/TablesPage/TablesPage.styles';
import { Filter } from '@utils/index';
import MetadataDecorator from '@utils/MetadataDecorator';

import CollectionsGridView from './CollectionsGridView';
import CollectionsPageEmptyState from './CollectionsPageEmptyState';
import { defaultFilterOptions, searchConfig, sortConfig } from './collectionsRequestConfigs';

type ViewType = 'grid' | 'list';

const CollectionsPage: React.FC = () => {
  const { activeView, selectorComponent: viewTypeSelectorComponent } = useGridListViewConfig();
  const allFetchedCollections = useRef<CollectionModel[]>([]);
  const { selected, setSelected, tagsCounts } = useBulkEditSelected<CollectionModel>();
  const { isPbac, permissions } = useObjectPermissionsContext();
  const selectedEditableItems = selected.items.filter((item) =>
    isPbac ? permissions[item.guid]?.isEditable : true,
  );
  const filterService = Filter.useUpdateFilters(
    { ...defaultFilterOptions },
    searchConfig,
    sortConfig,
    '',
  );
  const { filter } = filterService;
  const filtersWithParams = Filter.setParams(filter);

  const { data, isError, isLoading } = useFetchCollections({
    params: {
      ...filtersWithParams,
      order: filtersWithParams?.order as GetCollectionsListQueryParams['order'],
      parent: 'null',
    },
  });

  const totalPages = data && filter.page_size ? Math.ceil(data.count / filter.page_size) : 1;

  useEffect(() => {
    allFetchedCollections.current = uniqBy(
      [...(data?.results ?? []), ...allFetchedCollections.current],
      'guid',
    );
  }, [data?.results]);

  const handleSetSelectRowIds = useCallback(
    (selectedRowIds) => {
      setSelected(allFetchedCollections.current?.filter((i) => selectedRowIds?.[i.guid]));
    },
    [setSelected],
  );

  const resetDataAfterBulkChange = () => {
    invalidateCache((keys) => [keys.collections.all]);
    handleSetSelectRowIds({});
  };

  const viewSelector = (
    <Box compDisplay="flex" flex={1} justifyContent="flex-end">
      {viewTypeSelectorComponent}
    </Box>
  );

  const tableView = (
    <StyledTablesPage>
      <CollectionsTable
        customTopBarContent={viewSelector}
        data={data?.results}
        filterService={filterService}
        isDataSourceEditable
        isLoading={isLoading}
        itemCount={data?.count ?? 0}
        selectedRowIds={selected.ids}
        setSelectedRowIds={handleSetSelectRowIds}
        totalPages={totalPages}
      />
    </StyledTablesPage>
  );

  const gridView = (
    <CollectionsGridView
      data={data?.results}
      filterService={filterService}
      isLoading={isLoading}
      totalPages={totalPages}
      viewSelector={viewSelector}
    />
  );

  const viewContentMap: Record<ViewType, JSX.Element> = {
    grid: gridView,
    list: tableView,
  };

  if (isError) {
    return <UnexpectedError />;
  }

  return (
    <>
      <MetadataDecorator title="Collections" />
      <LayoutMainGrid>
        {data?.results.length === 0 && !filter.search_all ? (
          <CollectionsPageEmptyState />
        ) : (
          <>
            <PageHeaderBar title="Collections" titleFontSize="h4" titleFontWeight="medium" />
            <BulkButtons
              canDelete
              canEditOwner
              canEditTags
              isDataSourceEditable
              onEdit={resetDataAfterBulkChange}
              selectedEditableItems={selectedEditableItems}
              selectedItems={selected.items}
              showText
              tagsCounts={tagsCounts}
            />
            {viewContentMap[activeView]}
          </>
        )}
      </LayoutMainGrid>
    </>
  );
};

export default CollectionsPage;
