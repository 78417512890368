import React from 'react';

import { CustomAttributeModel } from '@api/customAttributes/CustomAttributeModel';
import { CustomAttributeValueModel } from '@api/customAttributeValues';
import Box from '@components/Box';
import { EditCustomAttributeValueInline } from '@components/Forms/EditCustomAttributeValueForm';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';

import type { TypedCell } from '../types';

interface CustomAttributeCellProps {
  customAttribute: CustomAttributeModel;
  customAttributeValue?: CustomAttributeValueModel;
  guid: string;
  isDataSourceEditable: boolean;
}

const CustomAttributeCell: React.FC<TypedCell & CustomAttributeCellProps> = ({
  customAttribute,
  customAttributeValue,
  guid,
  isDataSourceEditable,
}) => {
  const { isEditable: isObjectEditable } = useObjectPermissionsContext({ id: guid });
  const isEditable = isDataSourceEditable && isObjectEditable;

  return (
    <Box compDisplay="flex">
      <EditCustomAttributeValueInline
        customAttribute={customAttribute}
        customAttributeValue={customAttributeValue}
        isEditable={isEditable}
        itemGuid={guid}
        showLabel={false}
        wrapTextAt={50}
      />
    </Box>
  );
};

export default CustomAttributeCell;
