import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import useMutation, { UseMutationOptions } from '@hooks/useMutation';

import rawTransform from '../rawTransform';

import { DescriptionConversionModel } from './DescriptionConversionModel';

export const descriptionCacheKeys = {
  ai: (id: string) => ['ai', id],
};

export const usePostDescriptionRichtextConvert = (
  options?: UseMutationOptions<DescriptionConversionModel>,
) => {
  return useMutation<DescriptionConversionModel>({
    ...options,
    method: 'POST',
    onSuccess: (data, variables, context) => {
      const transformedData = rawTransform(DescriptionConversionModel)(data);
      options?.onSuccess?.(transformedData, variables, context);
    },
    url: '/description/richtext/convert/',
  });
};

interface FetchDescriptionAiResponse {
  ai_description: string;
}

export const useFetchDescriptionAi = (
  guid: string,
  options?: UseFetchOptions<FetchDescriptionAiResponse>,
) => {
  return useFetch<FetchDescriptionAiResponse>({
    ...options,
    queryKey: descriptionCacheKeys.ai(guid),
    url: `/description/ai/${guid}/`,
  });
};

interface PatchDescriptionAiBulkApplyResponse {
  updated_objects_count: number;
}

export const usePatchDescriptionAiBulkApply = (guid: string, options?: UseMutationOptions) => {
  return useMutation<PatchDescriptionAiBulkApplyResponse>({
    ...options,
    method: 'PATCH',
    url: `description/ai/${guid}/bulk/apply/`,
  });
};

export const usePatchDescriptionAiBulkRemove = (guid: string, options?: UseMutationOptions) => {
  return useMutation<PatchDescriptionAiBulkApplyResponse>({
    ...options,
    method: 'PATCH',
    url: `description/ai/${guid}/bulk/remove/`,
  });
};
