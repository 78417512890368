import React, { useCallback, useMemo, useState } from 'react';
import { Cell } from 'react-table';

import type { LookMLViewModel } from '@api/lookML/LookMLViewModel';
import type { BiViewModel } from '@api/views/BiViewModel';
import DescriptionCell from '@components/Table/Cells/DescriptionCell';
import EditableTaggedItemCell from '@components/Table/Cells/EditableTaggedItemCell';
import LinkedCell from '@components/Table/Cells/LinkedCell';
import PopularityCell from '@components/Table/Cells/PopularityCell';
import PopularityCellHeader from '@components/Table/Cells/PopularityCell/PopularityCellHeader';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import type { FilterOptions, FilterServiceInterface } from '@utils/filters';
import { getPopularityNormalized } from '@utils/popularity';

export const VIEW_TABLE_SEARCH_CONFIG: { [key: string]: keyof FilterOptions } = {
  description: 'search_description',
  view: 'search_name',
};

export const VIEW_TABLE_SORT_CONFIG = {
  description: 'description',
  popularity: 'popularity',
  updatedAt: 'updated_at',
  view: 'name',
};

interface ViewTableProps {
  data:
    | {
        count: number;
        results: BiViewModel[];
      }
    | undefined;
  filterService: FilterServiceInterface;
  isDataSourceEditable: boolean;
  isLoading?: boolean;
  selectedRowIds: Record<string, boolean>;
  toggleAll?: (checked: boolean) => void;
  toggleItem?: (item: BiViewModel, checked: boolean) => void;
}

const ViewTable: React.FC<ViewTableProps> = ({
  data,
  filterService,
  isDataSourceEditable,
  isLoading,
  selectedRowIds,
  toggleAll,
  toggleItem,
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const toggleFilter = useCallback(() => setShowFilter((prev) => !prev), [setShowFilter]);
  const { changePage, filter, initialTableSortState, search, sort } = filterService;

  const columns: ColumnConfig<BiViewModel>[] = useMemo(
    () => [
      {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      {
        Cell: (props: Cell<BiViewModel>) => {
          const { row } = props;
          const view = row.original;
          const displayName = `${view.workbook.name} / ${view.name}`;
          return <LinkedCell {...props} item={view} itemName={displayName} showIcon />;
        },
        Header: `Name (${data?.count ?? 0})`,
        accessor: (d) => `${d?.workbook?.name}.${d.name}`,
        disableHiding: true,
        id: 'view',
        width: '130%',
      },
      {
        Cell: (props: Cell<LookMLViewModel>) => {
          const { row } = props;
          const view = row.original;

          return (
            <DescriptionCell
              {...props}
              aiDescription={view.aiDescription}
              dataSourceType={view.dataTypes?.dataSourceType}
              description={view.description}
              descriptionSource={view.descriptionSource}
              guid={view.guid}
              ingestedDescription={view.ingestedDescription}
              isDataSourceEditable={isDataSourceEditable}
              name={view.name}
              richtextDescription={view.richtextDescription}
              suggestedDescription={view.suggestedDescription}
              suggestedDescriptionSource={view.suggestedDescriptionSource}
              suggestedDescriptionSourceObj={view.suggestedDescriptionSourceObject}
              userDescription={view.userDescription}
            />
          );
        },
        Header: 'Description',
        accessor: 'description',
        id: 'description',
        width: '150%',
      },
      {
        Cell: (props: Cell<BiViewModel>) => {
          const { row } = props;
          const view = row.original;

          return <EditableTaggedItemCell {...props} isDataSourceEditable obj={view} />;
        },
        Header: 'Tags',
        accessor: (d) => d.taggedItems,
        disableSortBy: true,
        id: 'tags',
        width: '120%',
      },
      {
        Cell: (props: Cell<BiViewModel>) => {
          const { row } = props;
          const view = row.original;
          return <PopularityCell {...props} isTableauView popularity={view.popularity} />;
        },
        Header: PopularityCellHeader,
        accessor: (d) => getPopularityNormalized(d?.popularity?.popularity),
        disableFilters: true,
        disableResizing: true,
        dropdownCheckboxLabel: 'Popularity',
        id: 'popularity',
        sortDescFirst: true,
        width: 120,
      },
    ],
    [data?.count, isDataSourceEditable],
  );

  const getRowId = useCallback((row: Partial<BiViewModel>) => row.guid!, []);
  const totalPages = data && filter.page_size ? Math.ceil(data.count / filter.page_size) : 1;

  return (
    <TableStyled>
      <Table
        basic="very"
        changePage={changePage}
        className="table-full"
        columns={columns}
        compact
        data={data?.results ?? []}
        disableColumnFiltering
        getRowId={getRowId}
        initialState={{
          pageIndex: filter.page ? filter.page - 1 : 0,
          selectedRowIds,
          sortBy: initialTableSortState,
        }}
        loading={isLoading}
        manualFilters
        manualPagination
        manualSortBy
        rangeSelectConfig={{
          onCellCheckChange: toggleItem,
          onHeaderCheckChange: toggleAll,
        }}
        setFilters={search}
        setSortBy={sort}
        showFilter={showFilter}
        sortable
        stickyHeader
        toggleFilter={toggleFilter}
        totalPages={totalPages}
        unstackable
      />
    </TableStyled>
  );
};

export default React.memo(ViewTable);
