import React from 'react';
import { RouterLink } from '@routing/router';

import {
  useFetchAnalyticsActivitiesGrouped,
  useFetchAnalyticsOrganizationMetrics,
  useFetchAnalyticsSearches,
} from '@api/analytics';
import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import DateRangePicker from '@components/DateRangePicker';
import { Column, Row } from '@components/Grid';
import TabError from '@components/TabContent/TabError';
import StatSummaryBar from '@components/UI/StatSummaryBar';
import {
  SectionTitleWrapper,
  Subtitle,
  Title,
} from '@pages/Admin/AdminCostAnalysisPage/tabs/SectionTitle';
import theme from '@styles/theme';
import formatNumber from '@utils/formatNumber';

import { DateRangeProps } from '../../Analytics.types';
import { calculateRatio, isValidRatio } from '../../Analytics.utils';

import TopPagesEditedTable from './tables/TopPagesEditedTable';
import TopPagesTable from './tables/TopPagesTable';
import TopSearchTermsTable from './tables/TopSearchTermsTable';

const AnalyticsActivitiesTab: React.FC<DateRangeProps> = ({
  dateRange: [start_date, end_date],
  onDateRangeChange,
}) => {
  const {
    data: {
      columnsCount = 0,
      columnsWithEmptyDescriptionCount = 0,
      commentsCount = 0,
      docsCount = 0,
      tablesCount = 0,
      tablesWithEmptyDescriptionCount = 0,
      tablesWithEmptyOwner = 0,
    } = {},
    isError: organizationMetricsIsError,
    isLoading: organizationMetricsIsLoading,
  } = useFetchAnalyticsOrganizationMetrics();

  const {
    data: topPagesData,
    isError: topPagesIsError,
    isLoading: topPagesIsLoading,
  } = useFetchAnalyticsActivitiesGrouped({
    params: { end_date, page_size: 5, start_date, types: 'view' },
  });

  const {
    data: topPagesEditedData,
    isError: topPagesEditedIsError,
    isLoading: topPagesEditedIsLoading,
  } = useFetchAnalyticsActivitiesGrouped({
    params: { end_date, page_size: 5, start_date, types: 'update' },
  });

  const {
    data: topSearchTermsData,
    isError: topSearchTermsIsError,
    isLoading: topSearchTermsIsLoading,
  } = useFetchAnalyticsSearches({ params: { end_date, page_size: 5, start_date } });

  if (
    organizationMetricsIsError ||
    topPagesIsError ||
    topPagesEditedIsError ||
    topSearchTermsIsError
  ) {
    return <TabError />;
  }

  if (organizationMetricsIsLoading) {
    return <CircularLoader centered compSize={5} cover />;
  }

  const borderColor = theme.colors.v1.gray[200];

  return (
    <Box compDisplay="flex" flexDirection="column">
      <SectionTitleWrapper my={3}>
        <Title> Published Resources</Title>
        <DateRangePicker initialRange={[start_date, end_date]} onChange={onDateRangeChange} />
      </SectionTitleWrapper>
      <StatSummaryBar
        data={[
          {
            description: 'Table Description Fill Rate',
            isValid: isValidRatio(tablesCount - tablesWithEmptyDescriptionCount, tablesCount),
            subvalue: `(${formatNumber({
              value: tablesCount - tablesWithEmptyDescriptionCount,
            })}/${formatNumber({ value: tablesCount })})`,
            tooltip:
              'This is calculated by dividing the total number of the table with filled descriptions by the total number of tables',
            value: calculateRatio(tablesCount - tablesWithEmptyDescriptionCount, tablesCount),
          },
          {
            description: 'Column Description Fill Rate',
            isValid: isValidRatio(columnsCount - columnsWithEmptyDescriptionCount, columnsCount),
            subvalue: `(${formatNumber({
              value: columnsCount - columnsWithEmptyDescriptionCount,
            })}/${formatNumber({ value: columnsCount })})`,
            tooltip:
              'This is calculated by dividing the total number of columns with filled description by the total number of columns',
            value: calculateRatio(columnsCount - columnsWithEmptyDescriptionCount, columnsCount),
          },
          {
            description: 'Total Number of Docs',
            tooltip: 'Count of documents',
            value: formatNumber({ value: docsCount }),
          },
          {
            description: '% of Tables with an Owner',
            isValid: isValidRatio(tablesCount - tablesWithEmptyOwner, tablesCount),
            subvalue: `(${formatNumber({
              value: tablesCount - tablesWithEmptyOwner,
            })}/${formatNumber({
              value: tablesCount,
            })})`,
            tooltip:
              'This is calculated by dividing the total number of tables with owners by the total number of tables',
            value: calculateRatio(tablesCount - tablesWithEmptyOwner, tablesCount),
          },
          {
            description: 'Total Number of Discussions',
            tooltip: 'The sum of all threads',
            value: formatNumber({ value: commentsCount }),
          },
        ]}
        showSubvalue
      />
      <Row flexWrap="wrap" space={1.5}>
        <Column md={6} xs={12}>
          <Box py={2}>
            <Box alignItems="center" compDisplay="flex" justifyContent="space-between">
              <Title>Top Pages</Title>
              <Subtitle as={RouterLink} to="/admin/analytics/activities/top-pages">
                Show more
              </Subtitle>
            </Box>
          </Box>
          <Box border={`1px solid ${borderColor}`} borderRadius={theme.radius.md} p={2}>
            <TopPagesTable loading={topPagesIsLoading} rows={topPagesData?.results} />
          </Box>
        </Column>
        <Column md={6} xs={12}>
          <Box py={2}>
            <Box alignItems="center" compDisplay="flex" justifyContent="space-between">
              <Title>Top Pages Edited</Title>
              <Subtitle as={RouterLink} to="/admin/analytics/activities/top-edited">
                Show more
              </Subtitle>
            </Box>
          </Box>
          <Box border={`1px solid ${borderColor}`} borderRadius={theme.radius.md} p={2}>
            <TopPagesEditedTable
              loading={topPagesEditedIsLoading}
              rows={topPagesEditedData?.results}
            />
          </Box>
        </Column>
      </Row>
      <Row space={1.5}>
        <Column md={6} xs={12}>
          <Box py={2}>
            <Box alignItems="center" compDisplay="flex" justifyContent="space-between">
              <Title>Top Search Terms</Title>
              <Subtitle as={RouterLink} to="/admin/analytics/activities/top-search-terms">
                Show more
              </Subtitle>
            </Box>
          </Box>
          <Box border={`1px solid ${borderColor}`} borderRadius={theme.radius.md} p={2}>
            <TopSearchTermsTable
              loading={topSearchTermsIsLoading}
              rows={topSearchTermsData?.results}
            />
          </Box>
        </Column>
      </Row>
    </Box>
  );
};

export default AnalyticsActivitiesTab;
