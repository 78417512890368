import type { CacheKeyType } from '@api/allCacheKeys';
import allCacheKeys from '@api/allCacheKeys';
import fetchClient from '@lib/fetchClient';

const getCacheData = <T>(cb: (args: CacheKeyType) => (string | undefined)[]): T | undefined => {
  const cacheKey = cb(allCacheKeys);

  return fetchClient.getQueryData(cacheKey);
};

export default getCacheData;
