import { Expose, Type } from 'class-transformer';

import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import { TableModel } from '@api/tables/TableModel';
import { breadcrumbsToLabelList } from '@components/Breadcrumbs';

class DbtWarehouseLinkModel {
  @Expose({ name: 'data_source' })
  dataSource!: string;

  @Expose({ name: 'target_data_source' })
  targetDataSource!: string;

  guid!: string;

  @Expose({ name: 'dbt_model' })
  @Type(() => TableModel)
  dbtModel!: TableModel;

  @Expose({ name: 'warehouse_table' })
  @Type(() => TableModel)
  warehouseTable!: TableModel;

  @Type(() => BreadcrumbModel)
  breadcrumbs?: BreadcrumbModel[];

  get breadcrumbLabelList() {
    return breadcrumbsToLabelList(this.dbtModel.name, this.dbtModel.routePath, this.breadcrumbs);
  }
}

export default DbtWarehouseLinkModel;
