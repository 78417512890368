import React, { useState } from 'react';
import { useHistory, useParams } from '@routing/router';
import { Cell } from 'react-table';

import { LookMLFieldModel } from '@api/fields/LookMLFieldModel';
import QueryModalWithTargetBox from '@components/Modal/QueryModal/QueryModalWithTargetBox';
import DescriptionCell from '@components/Table/Cells/DescriptionCell';
import EditableTaggedItemCell from '@components/Table/Cells/EditableTaggedItemCell';
import PopularityCell from '@components/Table/Cells/PopularityCell';
import PopularityCellHeader from '@components/Table/Cells/PopularityCell/PopularityCellHeader';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import FieldNameTableCell from '@components/Table/ExploreFieldTable/FieldNameTableCell';
import Table from '@components/Table/Table';
import { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import { useUserContext } from '@context/User';
import useCustomAttributesColumns from '@hooks/useCustomAttributesColumns';
import BIColumnPage from '@pages/BIColumnPage';
import { Filter } from '@utils';
import { getPopularityNormalized } from '@utils/popularity';
import { getObjectTypeFromGuid, makeUrl } from '@utils/routing';

const initialSortState = [
  {
    desc: true,
    id: 'popularity',
  },
];

interface LookMLFieldsTableProps {
  data?: LookMLFieldModel[];
  filterService: Filter.FilterServiceInterface;
  isDataSourceEditable: boolean;
  itemCount?: number;
  totalPages: number;
}

const LookMLFieldsTable: React.FC<LookMLFieldsTableProps> = ({
  data,
  filterService,
  isDataSourceEditable,
  itemCount = 0,
  totalPages,
}) => {
  const history = useHistory();
  const { organization } = useUserContext();
  const { guid, itemId } = useParams<{ guid: string; itemId: string }>();
  const [isShowFilter, setShowFilter] = useState(false);
  const [currItem, setCurrItem] = useState<string | undefined>(itemId);
  const toggleFilter = React.useCallback(() => setShowFilter((prev) => !prev), [setShowFilter]);
  const { changePage, filter, search, sort } = filterService;
  const customAttributesAssetType = 'looker,lookmlfield,';

  const { customAttributesColumns } = useCustomAttributesColumns({
    assetType: customAttributesAssetType,
    isDataSourceEditable,
    tableData: data ?? [],
  });

  const columns: ColumnConfig<LookMLFieldModel>[] = React.useMemo(
    () => [
      {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      {
        Cell: (props: Cell<LookMLFieldModel>) => {
          const { row } = props;
          const field = row.original;
          return (
            <FieldNameTableCell
              {...props}
              field={field}
              fullName={field.fullName}
              guid={field.guid}
              isMentioned={Boolean(field.isMention)}
              isMetric={Boolean(field.isMetric)}
              noLink={false}
              onClick={() => {
                history.push(`fields/${field.guid}`);
                setCurrItem(field.guid);
              }}
            />
          );
        },
        Header: `Field (${itemCount})`,
        accessor: (d) => d.name,
        disableHiding: true,
        id: 'name',
        width: '130%',
      },
      {
        Cell: (props: Cell<LookMLFieldModel>) => {
          const { row } = props;
          const field = row.original;

          return (
            <DescriptionCell
              {...props}
              aiDescription={field.aiDescription}
              dataSourceType={field.dataTypes?.dataSourceType}
              description={field?.description}
              descriptionSource={field.descriptionSource}
              guid={field.guid}
              ingestedDescription={field.ingestedDescription}
              isDataSourceEditable={isDataSourceEditable}
              name={field.name}
              richtextDescription={field?.richtextDescription}
              suggestedDescription={field?.suggestedDescription}
              suggestedDescriptionSource={field.suggestedDescriptionSource}
              suggestedDescriptionSourceObj={field?.suggestedDescriptionSourceObject?.obj}
              truncateDisabled={!organization?.settings?.useShowMoreButton}
              userDescription={field.userDescription}
            />
          );
        },
        Header: 'Description',
        accessor: (d) => d?.description,
        id: 'description',
        width: '150%',
      },
      {
        Cell: (props: Cell<LookMLFieldModel>) => {
          const { column, row } = props;
          const field = row.original;

          return (
            <QueryModalWithTargetBox
              codeString={field.sql}
              dataSourceType={field.dataTypes?.dataSourceType}
              displayText={field.sql}
              hideSidebar
              highlighterProps={{
                searchWords: [column?.filterValue],
              }}
              tooltipText={field.sql}
            />
          );
        },
        Header: 'SQL',
        accessor: 'sql',
        disableSortBy: true,
        id: 'sql',
        width: '110%',
      },
      ...customAttributesColumns,
      {
        Cell: (props: Cell<LookMLFieldModel>) => {
          const { row } = props;
          const field = row.original;

          return (
            <EditableTaggedItemCell
              {...props}
              isDataSourceEditable={isDataSourceEditable}
              obj={field}
            />
          );
        },
        Header: 'Tags',
        accessor: (d) => d?.taggedItems,
        disableSortBy: true,
        id: 'tags',
        width: '120%',
      },
      {
        Cell: (props: Cell<LookMLFieldModel>) => {
          const { row } = props;
          const field = row.original;
          return <PopularityCell {...props} popularity={field.popularity} />;
        },
        Header: PopularityCellHeader,
        accessor: (d) => getPopularityNormalized(d?.popularity?.popularity),
        disableFilters: true,
        disableResizing: true,
        dropdownCheckboxLabel: 'Popularity',
        id: 'popularity',
        sortDescFirst: true,
        width: 120,
      },
    ],
    [isDataSourceEditable, itemCount, history, customAttributesColumns],
  );

  const getRowId = React.useCallback((row: Partial<LookMLFieldModel>) => row.guid!, []);

  return (
    <>
      <TableStyled>
        <Table
          basic="very"
          changePage={changePage}
          className="table-full"
          columns={columns}
          compact
          data={data || []}
          disableRowSelect
          getRowId={getRowId}
          initialState={{
            pageIndex: filter.page ? filter.page - 1 : 0,
            sortBy: initialSortState,
          }}
          loading={data === undefined}
          manualFilters
          manualPagination
          manualSortBy
          selectable
          setFilters={search}
          setSortBy={sort}
          showFilter={isShowFilter}
          sortable
          toggleFilter={toggleFilter}
          totalPages={totalPages}
        />
      </TableStyled>
      {currItem && (
        <BIColumnPage
          guid={currItem}
          isDataSourceEditable={isDataSourceEditable}
          onClose={() => {
            const type = getObjectTypeFromGuid(guid);
            if (type) {
              const url = makeUrl(type, guid);
              history.push(`${url}/fields`);
            }
            setCurrItem(undefined);
          }}
        />
      )}
    </>
  );
};

export default React.memo<LookMLFieldsTableProps>(LookMLFieldsTable);
