import styled from '@emotion/styled';

import Box from '@components/Box';
import type { StyledBoxProps } from '@components/Box/Box.styles';

interface StyledNameProps {
  index?: number;
  isActive?: boolean;
  isCompleted?: boolean;
}

const StyledIndex = styled(Box)<StyledBoxProps & StyledNameProps>`
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  border-radius: 40px;
  margin-right: ${({ theme }) => theme.space(-2.2)};
  margin-left: ${({ index, theme }) => (index === 1 ? '0' : theme.space(-2.2))};
  z-index: 2002;
  background: ${({ isActive, isCompleted }) => (isCompleted || isActive ? '#662880' : '#ffffff')};
  box-shadow: ${({ isActive, isCompleted }) =>
    isCompleted || isActive
      ? '3px 4px 10px rgba(38, 20, 71, 0.4)'
      : '0px 4px 4px rgba(0, 0, 0, 0.1)'};
  font-weight: ${({ isActive, theme }) =>
    isActive ? theme.typography.fontWeights.medium : theme.typography.fontWeights.regular};
`;

export default StyledIndex;

export const StyledName = styled(Box)<StyledBoxProps & StyledNameProps>`
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  background: ${({ isActive, isCompleted }) => (isCompleted || isActive ? '#662880' : '#edeef6')};
  font-weight: ${({ isActive, theme }) =>
    isActive ? theme.typography.fontWeights.medium : theme.typography.fontWeights.regular};

  &:last-of-type {
    border-radius: 0 40px 40px 0px;
  }
`;
