import React, { useState } from 'react';
import { Editor } from 'slate';

import Box from '@components/Box';
import Button from '@components/Button/Button';
import Input from '@components/Input/Input.v1';
import Popper from '@components/Popper';
import Text, { defaultParagraphStyles } from '@components/Text';
import InputLabel from '@components/UI/Form/InputLabel';
import useClickOutside from '@hooks/useClickOutside';

import { insertTable } from '../helpers/insertTable';

import { StyledTableMenu } from './InsertTableMenu.styles';

const MAX_COLUMNS = 20;
const MAX_ROWS = 100;
const MIN_COLUMNS = 1;
const MIN_ROWS = 1;

const validateColumns = (value: string | number) => {
  const numCols = Number(value);
  if (numCols < MIN_COLUMNS) {
    return '';
  }
  if (numCols > MAX_COLUMNS) {
    return MAX_COLUMNS;
  }
  return numCols;
};

const validateRows = (value: string | number) => {
  const numRows = Number(value);
  if (numRows < MIN_ROWS) {
    return '';
  }
  if (numRows > MAX_ROWS) {
    return MAX_ROWS;
  }
  return numRows;
};

interface InsertTableMenuProps {
  anchor: React.ReactElement;
  editor: Editor;
  onClose: () => void;
  open?: boolean;
}

const InsertTableMenu: React.FC<InsertTableMenuProps> = ({ anchor, editor, onClose, open }) => {
  const [values, setValues] = useState({
    columns: 3,
    rows: 3,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newValue = name === 'rows' ? validateRows(value) : validateColumns(value);
    setValues((prev) => ({ ...prev, [name]: newValue }));
  };

  const handleInsertTable = () => {
    insertTable(editor, values.rows, values.columns);
    onClose();
  };

  const useClickOutsideRef = useClickOutside<HTMLDivElement>({
    onClick: () => {
      onClose();
    },
  });

  return (
    <Popper
      anchorComp={anchor}
      fallbackPlacements={['top-start']}
      isOpen={open}
      offset={[0, 5]}
      placement="bottom-start"
      usePortal={false}
    >
      <StyledTableMenu ref={useClickOutsideRef}>
        <Text {...defaultParagraphStyles} fontWeight="bold" mb={0}>
          Insert Table
        </Text>
        <Box compDisplay="flex" compWidth="100%" flexDirection="column" gap={1} mb={1.5} mt={1.5}>
          <InputLabel fontWeight="bold" justifyContent="space-between">
            Rows
            <Input
              compWidth="70%"
              max={10}
              min={1}
              name="rows"
              onChange={handleChange}
              type="number"
              value={values.rows}
            />
          </InputLabel>
          <InputLabel fontWeight="bold" justifyContent="space-between">
            Columns
            <Input
              compWidth="70%"
              max={100}
              min={1}
              name="columns"
              onChange={handleChange}
              type="number"
              value={values.columns}
            />
          </InputLabel>
        </Box>
        <Box compDisplay="flex" compWidth="100%" justifyContent="flex-end">
          <Button
            compSize="sm"
            disabled={!values.rows || !values.columns}
            onClick={handleInsertTable}
          >
            Insert
          </Button>
        </Box>
      </StyledTableMenu>
    </Popper>
  );
};

export default InsertTableMenu;
