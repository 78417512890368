import React from 'react';

import Box from '@components/Box';
import SearchBar from '@components/SearchBar';
import useStickyContext from '@components/Sticky';
import TopBanner from '@components/TopBanner';
import { useUserContext } from '@context/User';
import flags from '@features';
import theme from '@styles/theme';

import { StyledAppHeader, StyledAppHeaderSearchBarContainer } from './AppHeader.styles';

export interface AppHeaderProps {
  disableAccess?: boolean;
}

const AppHeader = ({ disableAccess }: AppHeaderProps) => {
  const { organization } = useUserContext();
  const stickyProps = useStickyContext({ id: 'AppHeader' });

  return (
    <Box {...stickyProps} zIndex={theme.zIndex.header}>
      {organization?.showBanner && (
        <TopBanner
          daysLeft={
            organization.subscriptionEndsAt
              ? organization.daysLeftInSubscription
              : organization?.daysLeftInTrial
          }
          isSubscriptionEndingBanner={!!organization.subscriptionEndsAt}
        />
      )}
      <StyledAppHeader as="header" hasBottomBorder={flags.show_header_bottom_border}>
        <StyledAppHeaderSearchBarContainer>
          {!disableAccess && <SearchBar />}
        </StyledAppHeaderSearchBarContainer>
      </StyledAppHeader>
    </Box>
  );
};

export default AppHeader;
