import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Editor, Range } from 'slate';
import { ReactEditor, useFocused, useSlate } from 'slate-react';
import { useDebouncedCallback } from 'use-debounce';

import Box from '@components/Box';
import Popper from '@components/Popper';
import AddLinkModal from '@components/RichTextEditor/RichTextEditorControls/AddLinkModal/AddLinkModal';
import { useModal } from '@context/Modal';

import { isBlockActive, toggleBlock } from '../helpers/blockHelpers';
import { getActiveInlineStyles, toggleStyle } from '../helpers/inlineStyleHelpers';
import {
  getLinkText,
  getLinkURLText,
  getSelectedLink,
  insertLink,
  isLinkNodeAtSelection,
} from '../helpers/linkHelpers';
import {
  BUTTON_TYPES,
  CustomElement,
  CustomElementType,
  RichTextEditorElement,
} from '../RichTextEditor.types';

import {
  ARROW_WIDTH,
  ArrowStyled,
  MINI_TOOLBAR_ITEM_WIDTH,
  MINI_TOOLBAR_PADDING,
  MiniToolbarControlsButton,
  MiniToolbarWrapper,
} from './MiniToolbar.styles';

export const MINI_TOOLBAR_BUTTON_TYPES = [
  BUTTON_TYPES.bold,
  BUTTON_TYPES.italic,
  BUTTON_TYPES.underline,
  BUTTON_TYPES.unordered_list,
  BUTTON_TYPES.ordered_list,
  BUTTON_TYPES.hyper_link,
  BUTTON_TYPES.code,
];

interface MiniToolbarProps {
  allowedElements?: RichTextEditorElement[];
  onLinkModalClose?: () => void;
  onLinkModalOpen?: () => void;
}

const MiniToolbar: React.FC<MiniToolbarProps> = ({
  allowedElements,
  onLinkModalClose,
  onLinkModalOpen,
}) => {
  const [showMiniToolbar, setShowMiniToolbar] = useState(false);
  const [position, setPosition] = useState<{
    left: string;
    top: string;
  } | null>(null);
  const { MODAL_IDS, checkModalOpened, openModal } = useModal();
  const timeOutShowToolbar = useRef<ReturnType<typeof setTimeout>>();
  const editor = useSlate();
  const { selection } = editor;
  const inFocus = useFocused();
  const linkModalOpen = checkModalOpened(MODAL_IDS.addLinkRichTextEditorMiniToolbar);

  const selectedLink = linkModalOpen ? (getSelectedLink(editor)?.[0] as CustomElement) : undefined;
  const selectedText = linkModalOpen ? Editor.string(editor, editor.selection || []) : undefined;

  useEffect(() => {
    if (
      !selection ||
      !inFocus ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ''
    ) {
      if (timeOutShowToolbar.current) {
        clearTimeout(timeOutShowToolbar.current);
      }
      setShowMiniToolbar(false);
      return;
    }

    timeOutShowToolbar.current = setTimeout(() => {
      setShowMiniToolbar(true);
    }, 500);

    return () => {
      if (timeOutShowToolbar.current) {
        clearTimeout(timeOutShowToolbar.current);
      }
    };
  }, [editor, inFocus, selection]);

  const getButtons = () => {
    if (allowedElements) {
      return MINI_TOOLBAR_BUTTON_TYPES.filter((button) =>
        allowedElements?.includes(button.style as CustomElementType),
      );
    }
    return MINI_TOOLBAR_BUTTON_TYPES;
  };

  const buttons = getButtons();

  const handlePosition = useCallback(() => {
    if (showMiniToolbar && selection) {
      const domSelection = window.getSelection();
      if (domSelection) {
        const domRange = domSelection.getRangeAt(0);
        const rect = domRange.getBoundingClientRect();
        setPosition({
          left: `${
            rect.left -
            (MINI_TOOLBAR_ITEM_WIDTH * buttons.length + MINI_TOOLBAR_PADDING) / 2 +
            rect.width / 2
          }px`,
          top: `${rect.top - ARROW_WIDTH}px`,
        });
      }
    }
  }, [buttons.length, selection, showMiniToolbar]);

  const debouncedHandlePosition = useDebouncedCallback(() => {
    handlePosition();
  }, 50);

  useEffect(() => {
    handlePosition();
  }, [handlePosition]);

  useEffect(() => {
    window.addEventListener('resize', debouncedHandlePosition);
    return () => {
      window.removeEventListener('resize', debouncedHandlePosition);
    };
  }, [debouncedHandlePosition]);

  if (!buttons) {
    return null;
  }

  return (
    <>
      <Popper
        anchorComp={
          <Box compHeight="24px" left={position?.left} position="fixed" top={position?.top} />
        }
        arrowPadding={({ popper }) => (popper ? (popper.width - ARROW_WIDTH) / 2 : 0)}
        fallbackPlacements={['top-start']}
        isOpen={showMiniToolbar}
        offset={[0, 2]}
        placement="top-start"
        renderArrow={(props) => (
          <ArrowStyled
            {...props}
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        )}
      >
        <MiniToolbarWrapper
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {buttons.map((type) => {
            const isActive =
              getActiveInlineStyles(editor).has(type.style) ||
              isBlockActive(editor, type.style as CustomElementType) ||
              (type.style === 'link' && isLinkNodeAtSelection(editor, editor.selection));

            return (
              <MiniToolbarControlsButton
                key={type.label}
                isActive={isActive}
                onMouseDown={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  if (type.style === 'link') {
                    onLinkModalOpen?.();
                    openModal(MODAL_IDS.addLinkRichTextEditorMiniToolbar);
                  }
                  if (type.type === 'block') {
                    toggleBlock(editor, type.style as CustomElementType);
                  }
                  if (type.type === 'inline') {
                    toggleStyle(editor, type.style);
                  }
                }}
              >
                {type.icon}
              </MiniToolbarControlsButton>
            );
          })}
        </MiniToolbarWrapper>
      </Popper>
      <AddLinkModal
        modalId={MODAL_IDS.addLinkRichTextEditorMiniToolbar}
        onClose={() => {
          onLinkModalClose?.();
          ReactEditor.focus(editor);
        }}
        onSave={(text, url) => insertLink(editor, text, url)}
        selectedLink={getLinkURLText(selectedLink, selectedText)}
        selectedText={getLinkText(selectedLink) ?? selectedText ?? ''}
      />
    </>
  );
};

export default MiniToolbar;
