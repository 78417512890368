import tooltipConfig from '@configs/tooltips/config';

import type { DatasourceTabV1 } from '@components/SearchBar/DatasourceTabs/config';
import { tabConfig } from '@components/SearchBar/DatasourceTabs/config';

const databaseCustomTab: DatasourceTabV1 = {
  filters: [
    {
      indexes: ['databases', 'schemata', 'tables', 'databricks_notebooks', 'databricks_folders'],
    },
  ],
  icon: 'database',
  name: 'Data',
  subtabs: [
    { indexes: ['databases'], name: 'Database' },
    { indexes: ['schemata'], name: 'Schema' },
    { indexes: ['tables'], name: 'Table' },
    { indexes: ['databricks_notebooks'], name: 'Notebook' },
  ],
};

const dataStudioCustomTab: DatasourceTabV1 = {
  filters: [{ indexes: ['data_studio_dashboards'] }],
  icon: 'data-studio',
  name: 'Looker Studio',
  subtabs: [{ indexes: ['data_studio_dashboards'], name: 'Dashboard' }],
};

const lookerCustomTab: DatasourceTabV1 = {
  filters: [
    {
      indexes: ['explores', 'looker_dashboards', 'looker_looks', 'lookml_views'],
    },
  ],
  icon: 'looker',
  name: 'Looker',
  subtabs: [
    { indexes: ['looker_dashboards'], name: 'Dashboard' },
    { indexes: ['looker_looks'], name: 'Look' },
    { indexes: ['lookml_views'], name: 'View' },
    { indexes: ['explores'], name: 'Explore' },
  ],
};

const metabaseCustomTab: DatasourceTabV1 = {
  filters: [
    {
      indexes: ['metabase_dashboards'],
    },
  ],
  icon: 'metabase',
  name: 'Metabase',
  subtabs: [{ indexes: ['metabase_dashboards'], name: 'Dashboard' }],
};

const powerbiCustomTab: DatasourceTabV1 = {
  filters: [
    {
      indexes: [
        'power_bi_dashboards',
        'power_bi_reports',
        'power_bi_datasets',
        'power_bi_dataflows',
      ],
    },
  ],
  icon: 'powerbi',
  name: 'Power BI',
  subtabs: [
    { indexes: ['power_bi_dashboards'], name: 'Dashboard' },
    { indexes: ['power_bi_reports'], name: 'Report' },
    { indexes: ['power_bi_datasets'], name: 'Dataset' },
    { indexes: ['power_bi_dataflows'], name: 'Dataflow' },
  ],
};

const quicksightCustomTab: DatasourceTabV1 = {
  filters: [
    {
      indexes: ['quicksight_reports', 'quicksight_dashboards', 'quicksight_bidatasets'],
    },
  ],
  icon: tabConfig.quicksight.icon,
  name: tabConfig.quicksight.name,
  subtabs: [
    { indexes: ['quicksight_reports'], name: 'Analysis' },
    { indexes: ['quicksight_dashboards'], name: 'Dashboard' },
    { indexes: ['quicksight_bidatasets'], name: 'Dataset' },
  ],
};

const hexCustomTab: DatasourceTabV1 = {
  filters: [
    {
      indexes: ['hex_dashboards'],
    },
  ],
  icon: tabConfig.hex.icon,
  name: tabConfig.hex.name,
  subtabs: [{ indexes: ['hex_dashboards'], name: tooltipConfig.hex?.dashboard?.default! }],
};

const sigmaCustomTab: DatasourceTabV1 = {
  filters: [
    {
      indexes: ['sigma_dashboards'],
    },
  ],
  icon: 'sigma',
  name: 'Sigma',
  subtabs: [{ indexes: ['sigma_dashboards'], name: 'Workbook' }],
};

const tableauCustomTab: DatasourceTabV1 = {
  filters: [
    {
      indexes: ['tableau_data_sources', 'tableau_projects', 'tableau_workbooks'],
    },
  ],
  icon: 'tableau',
  name: 'Tableau',
  subtabs: [
    { indexes: ['tableau_workbooks'], name: 'Workbook' },
    { indexes: ['tableau_projects'], name: 'Project' },
    { indexes: ['tableau_data_sources'], name: 'Data Source' },
  ],
};

const thoughtspotCustomTab: DatasourceTabV1 = {
  filters: [
    {
      indexes: [
        'thoughtspot_answers',
        'thoughtspot_liveboards',
        'thoughtspot_tables',
        'thoughtspot_worksheets',
        'thoughtspot_views',
      ],
    },
  ],
  icon: 'thoughtspot',
  name: 'Thoughtspot',
  subtabs: [
    { indexes: ['thoughtspot_answers'], name: 'Answer' },
    { indexes: ['thoughtspot_liveboards'], name: 'Liveboard' },
    { indexes: ['thoughtspot_tables'], name: 'Table' },
    { indexes: ['thoughtspot_worksheets'], name: 'Worksheet' },
    { indexes: ['thoughtspot_views'], name: 'View' },
  ],
};

const selectStartCustomTab: DatasourceTabV1 = {
  filters: [
    {
      indexes: ['tags', 'teams'],
    },
  ],
  icon: 'select-star-colored',
  name: 'Select Star',
  subtabs: [
    { indexes: ['teams'], name: 'Teams' },
    { indexes: ['tags'], name: 'Tags' },
  ],
};

export const collectionDataAssetsSearchTabs = [
  {
    filters: [
      tabConfig.document.filters,
      selectStartCustomTab.filters,
      databaseCustomTab.filters,
      lookerCustomTab.filters,
      tabConfig.mode.filters,
      sigmaCustomTab.filters,
      tabConfig.periscope.filters,
      powerbiCustomTab.filters,
      quicksightCustomTab.filters,
      metabaseCustomTab.filters,
      dataStudioCustomTab.filters,
      tableauCustomTab.filters,
      thoughtspotCustomTab.filters,
      hexCustomTab.filters,
    ].flat(),
    name: 'All',
  },
  tabConfig.document,
  databaseCustomTab,
  lookerCustomTab,
  tabConfig.mode,
  sigmaCustomTab,
  tabConfig.periscope,
  powerbiCustomTab,
  quicksightCustomTab,
  metabaseCustomTab,
  dataStudioCustomTab,
  tableauCustomTab,
  thoughtspotCustomTab,
  hexCustomTab,
];
