import React from 'react';

import { useFetchQueryLog } from '@api/queryLog';
import stripSpaces from '@utils/stripSpaces';

import QuerySection from './QuerySection';

const QUERY = stripSpaces(`{
  guid,
  external_id,
  executed_on,
  duration,
  rows_produced,
  credits_used,
  dsuser {
    guid,
    name,
    user {
      guid,
      first_name,
      last_name,
      avatar
    },
    data_source {
      guid,
      name,
      type
    }
  }
}`);

interface LastQuerySectionProps {
  guid?: string;
}

const LastQuerySection: React.FC<LastQuerySectionProps> = ({ guid }) => {
  const { data, isLoading } = useFetchQueryLog({
    enabled: Boolean(guid),
    params: {
      order_by: '-executed_on',
      page_size: 1,
      queries: guid,
      query: QUERY,
    },
  });

  if (isLoading) {
    return <QuerySection loading={isLoading} title="Last query run:" />;
  }

  if (data?.results.length === 0) {
    return null;
  }

  return (
    <>
      {data?.results.map((lastQueryRecord) => (
        <QuerySection
          key={lastQueryRecord.guid}
          queryLogRecord={lastQueryRecord}
          title="Last query run:"
        />
      ))}
    </>
  );
};

export default LastQuerySection;
