import React from 'react';

import { useFetchTables } from '@api/tables';
import TabError from '@components/TabContent/TabError';
import OwnsTable from '@components/Table/OwnsTable';
import { tableOwnsQuery } from '@models/Owners';
import { ProfileType } from '@pages/ProfilePage/ProfilePage.types';
import { Filter } from '@utils';

import { OwnedItemsSubTabProps } from '../types';

const TABLES_FILTER_OPTIONS: Filter.FilterOptions = {
  page: 1,
  page_size: 100,
  query: tableOwnsQuery,
};

const TABLES_SEARCH_CONFIG: { [key: string]: keyof Filter.FilterOptions } = {
  dataObject: 'search_name',
};

const OwnedTablesSubTab = ({ guid, profileType }: OwnedItemsSubTabProps) => {
  const filterService = Filter.useUpdateFilters(
    TABLES_FILTER_OPTIONS,
    TABLES_SEARCH_CONFIG,
    {},
    '',
  );
  const { filter } = filterService;

  const {
    data: tables,
    isError,
    isLoading,
  } = useFetchTables({
    params: {
      ...Filter.setParams(filter),
      owners: profileType === ProfileType.user ? guid : undefined,
      teams: profileType === ProfileType.team ? guid : undefined,
    },
  });

  if (isError) return <TabError />;

  const totalPages = tables ? Math.ceil(tables.count / TABLES_FILTER_OPTIONS.page_size!) : 1;

  return (
    <OwnsTable
      data={tables?.results}
      filterService={filterService}
      itemCount={tables?.count}
      loading={isLoading}
      totalPages={totalPages}
    />
  );
};

export default OwnedTablesSubTab;
