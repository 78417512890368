import React from 'react';

import TitleView, { TitleViewProps } from '@components/UI/TitleView';

interface AdminTitleViewProps extends TitleViewProps {}

const AdminTitleView: React.FC<AdminTitleViewProps> = (props) => {
  return <TitleView as="h1" compSize="lg" mb={3} {...props} />;
};

export default AdminTitleView;
