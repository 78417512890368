import React from 'react';
import { useTheme } from '@emotion/react';
import * as CSS from 'csstype';

import Box from '@components/Box';
import Skeleton from '@components/Skeleton';
import useStickyContext from '@components/Sticky';

import type {
  StyledPageHeaderBarIconWrapperProps,
  StyledPageHeaderBarTitleProps,
} from './PageHeaderBar.styles';
import {
  StyledContentWrapper,
  StyledPageHeaderBarIconWrapper,
  StyledPageHeaderBarSupTitle,
  StyledPageHeaderBarTitle,
  StyledPageHeaderBarWrapper,
  StyledRightEdgeElementWrapper,
} from './PageHeaderBar.styles';

export interface PageHeaderBarProps
  extends StyledPageHeaderBarTitleProps,
    Pick<StyledPageHeaderBarIconWrapperProps, 'overwriteIconsSizes'> {
  backgroundColor?: CSS.Property.BackgroundColor;
  className?: string;
  icon?: React.ReactNode;
  isLoading?: boolean;
  mb?: number;
  minHeight?: CSS.Property.MinHeight;
  rightEdgeEl?: React.ReactNode;
  sticky?: boolean;
  supIcon?: React.ReactNode;
  supTitle?: React.ReactNode;
  title: React.ReactNode;
  titleSuffix?: React.ReactNode;
  titleToolBox?: React.ReactNode;
}

const PageHeaderBar: React.FC<PageHeaderBarProps> = ({
  backgroundColor = 'white',
  className,
  icon,
  isLoading,
  mb = 1.25,
  overwriteIconsSizes,
  rightEdgeEl,
  sticky,
  supIcon,
  supTitle,
  title,
  titleFontSize,
  titleFontWeight,
  titleSuffix,
  titleToolBox,
}) => {
  const { zIndex } = useTheme();
  const stickyProps = useStickyContext({ enabled: sticky });

  return (
    <Box
      {...stickyProps}
      alignItems="center"
      backgroundColor={backgroundColor}
      className={className}
      compDisplay="flex"
      compWidth="100%"
      mb={mb}
      py={0.25}
      role="banner"
      zIndex={zIndex.contentHeaders}
    >
      <StyledPageHeaderBarWrapper>
        {isLoading ? (
          <>
            <Box alignItems="center" compDisplay="flex" mb={1} spacing={1}>
              <Skeleton ml={1} mr={0.75} />
              <Skeleton compWidth="100px" variant="text" />
            </Box>
            <Box alignItems="center" compDisplay="flex" spacing={1}>
              <Skeleton compHeight="2rem" compWidth="2rem" />
              <Skeleton compWidth="180px" variant="text" />
            </Box>
          </>
        ) : (
          <>
            {(supIcon || supTitle) && (
              <Box alignItems="center" compDisplay="flex" gap={0.5}>
                {supIcon && (
                  <StyledPageHeaderBarIconWrapper overwriteIconsSizes={overwriteIconsSizes}>
                    {supIcon}
                  </StyledPageHeaderBarIconWrapper>
                )}
                {supTitle && <StyledPageHeaderBarSupTitle>{supTitle}</StyledPageHeaderBarSupTitle>}
              </Box>
            )}
            <StyledContentWrapper>
              <Box
                alignItems="center"
                columnGap={1}
                compDisplay="flex"
                flexGrow={1}
                flexWrap="wrap"
                noDefault
              >
                <Box alignItems="center" compDisplay="flex" gap={0.5} my={0.5}>
                  {icon && (
                    <StyledPageHeaderBarIconWrapper
                      height={30}
                      overwriteIconsSizes={overwriteIconsSizes}
                      width={30}
                    >
                      {icon}
                    </StyledPageHeaderBarIconWrapper>
                  )}
                  <Box alignItems="center" compDisplay="flex" gap={1}>
                    <StyledPageHeaderBarTitle
                      titleFontSize={titleFontSize}
                      titleFontWeight={titleFontWeight}
                    >
                      {title}
                    </StyledPageHeaderBarTitle>
                    {titleSuffix}
                  </Box>
                </Box>
                {titleToolBox && (
                  <Box alignItems="center" compDisplay="inline-flex" flexWrap="wrap">
                    {titleToolBox}
                  </Box>
                )}
              </Box>
              <StyledRightEdgeElementWrapper>{rightEdgeEl}</StyledRightEdgeElementWrapper>
            </StyledContentWrapper>
          </>
        )}
      </StyledPageHeaderBarWrapper>
    </Box>
  );
};

export default PageHeaderBar;
