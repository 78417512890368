import styled from '@emotion/styled';

import Box from '@components/Box';
import rootTheme from '@styles/theme';

import { SIZES } from '../config';
import { StyledUpstreamDownstreamButton } from '../UpstreamDownstreamButton/UpstreamDownstreamButton.styles';

interface StyledTableNodeProps {
  isBITable?: boolean;
  isColumnLevelLineage?: boolean;
  isDownstreamLeaf?: boolean;
  isHoverAnimationActive?: boolean;
  isImplicit?: boolean;
  isMock?: boolean;
  isOpen?: boolean;
  isSelectedNode?: boolean;
  isUnfocused?: boolean;
  isUpstreamLeaf?: boolean;
  keepOneLevelButtonsOnScreen?: boolean;
}

export const NODE_OPACITY = {
  default: 1,
  implicit: 0.7,
  unfocused: 0.3,
};

export const ANIMATION_DURATION = 3000;

const getNodeBorderWidth = ({
  isColumnLevelLineage,
  isDownstreamLeaf,
  isSelectedNode,
  isUpstreamLeaf,
}: StyledTableNodeProps) => {
  let borderWidth = SIZES.borderWidth.table;
  let left = SIZES.borderWidth.table;
  let right = SIZES.borderWidth.table;

  if (isSelectedNode && !isColumnLevelLineage) {
    borderWidth = SIZES.borderWidth.initialNode;

    left = SIZES.borderWidth.initialNode;
    right = SIZES.borderWidth.initialNode;
  }

  if (isDownstreamLeaf) {
    right = SIZES.borderWidth.tableLeaf;
  }

  if (isUpstreamLeaf) {
    left = SIZES.borderWidth.tableLeaf;
  }

  return `${borderWidth}px ${right}px ${borderWidth}px ${left}px`;
};

export const getNodeOpacity = ({ isImplicit, isUnfocused }: StyledTableNodeProps) => {
  if (isUnfocused) {
    return NODE_OPACITY.unfocused;
  }
  if (isImplicit) {
    return NODE_OPACITY.implicit;
  }
  return NODE_OPACITY.default;
};

export const StyledTableNode = styled(Box)<StyledTableNodeProps>`
  overflow: hidden;
  opacity: ${({ isImplicit, isUnfocused }) => getNodeOpacity({ isImplicit, isUnfocused })};
  border-style: solid;
  border-width: ${(props) => getNodeBorderWidth(props)};
  background-color: ${({ theme }) => theme.colors.white};
  transition: background-color 150ms linear, border-color 150ms linear, opacity 150ms linear;
  padding-right: ${({ isDownstreamLeaf, isSelectedNode }) =>
    isDownstreamLeaf
      ? `${SIZES.paddingRight.table}px`
      : `${SIZES.paddingRight.table + SIZES.borderWidth.tableLeaf + (isSelectedNode ? -2 : -1)}px`};
  padding-left: ${({ isSelectedNode, isUpstreamLeaf }) =>
    isUpstreamLeaf
      ? `${SIZES.paddingLeft.table}px`
      : `${SIZES.paddingLeft.table + SIZES.borderWidth.tableLeaf + (isSelectedNode ? -2 : -1)}px`};
  padding-bottom: ${({ isSelectedNode }) =>
    isSelectedNode ? `${SIZES.paddingBottom.table - 1}px` : `${SIZES.paddingBottom.table}px`};
  padding-top: ${({ isSelectedNode }) =>
    isSelectedNode ? `${SIZES.paddingBottom.table - 1}px` : `${SIZES.paddingBottom.table}px`};
  height: 100%;
  width: ${({ isBITable }) => (isBITable ? `${SIZES.width.schema}px` : `${SIZES.width.table}px`)};

  ${StyledUpstreamDownstreamButton} {
    visibility: ${({ isColumnLevelLineage, isSelectedNode }) =>
      isSelectedNode && !isColumnLevelLineage ? 'visible' : 'hidden'};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.v1.primary[50]};
    border-color: ${({ theme }) => theme.colors.v1.primary[300]};

    ${StyledUpstreamDownstreamButton} {
      visibility: visible;
    }
  }

  ${({ keepOneLevelButtonsOnScreen }) => {
    if (keepOneLevelButtonsOnScreen) {
      return `
        ${StyledUpstreamDownstreamButton} {
          visibility: visible;
        }
      `;
    }
  }}

  ${({ borderColor, isHoverAnimationActive, theme }) =>
    isHoverAnimationActive &&
    `
  @keyframes hover-effect {
    0% {
      background-color: ${theme.colors.white};
      border-color: ${borderColor};
    }

    50% {
      background-color: ${theme.colors.v1.primary[100]};
      border-color: ${theme.colors.v1.primary[300]};
    }

    100% {
      background-color: ${theme.colors.white};
      border-color: ${borderColor};
    }
  }

  animation: ${ANIMATION_DURATION}ms linear hover-effect;

  `}
`;

StyledTableNode.defaultProps = {
  borderRadius: 'md',
  cursor: 'pointer',
};

export const StyledTableNodeIconContainer = styled(Box)``;

StyledTableNodeIconContainer.defaultProps = {
  alignItems: 'center',
  borderRadius: 'default',
  compDisplay: 'flex',
  compHeight: rootTheme.space(3),
  compWidth: rootTheme.space(3),
  justifyContent: 'center',
};

export const StyledTableNodeTitle = styled(Box)``;

StyledTableNodeTitle.defaultProps = {
  color: 'gray.900',
  flex: 1,
  fontSize: 'body1',
  fontWeight: 'medium',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

export const StyledTableNodeContent = styled(Box)``;

StyledTableNodeContent.defaultProps = {
  compDisplay: 'flex',
  compHeight: '100%',
  compWidth: '100%',
  flexDirection: 'column',
  justifyContent: 'space-between',
  mt: 0.5,
};

export const StyledTableNodeSearchContainer = styled(Box)``;

StyledTableNodeSearchContainer.defaultProps = {
  compDisplay: 'flex',
  compWidth: '100%',
  justifyContent: 'center',
  mb: 0.5,
};
