import React from 'react';
import * as CSS from 'csstype';
import { Dropdown as SemanticDropdown, DropdownProps } from 'semantic-ui-react';

import Icon from '@components/UI/Icon';

import DropdownStyle, { FlexDropDownStyle } from './Dropdown.styles';

type ExtDropdownProps = DropdownProps & {
  compWidth?: CSS.Property.Width;
  flexGrow?: CSS.Property.FlexGrow;
  isFlex?: boolean;
  minWidth?: CSS.Property.MinWidth;
};

const Dropdown: React.FC<ExtDropdownProps> = (props) => {
  const { className, compWidth, flexGrow, isFlex, minWidth, onClick, selection, ...rest } = props;
  const isSelection = selection !== undefined ? selection : true;

  const dropdown = (
    <SemanticDropdown
      {...rest}
      icon={<Icon className="dropdownArrow" color="currentColor" name="down" size="14px" />}
      selection={isSelection}
    />
  );

  if (isFlex) {
    return <FlexDropDownStyle className={className}>{dropdown}</FlexDropDownStyle>;
  }

  return (
    <DropdownStyle
      className={className}
      compWidth={compWidth}
      flexGrow={flexGrow}
      minWidth={minWidth}
    >
      {dropdown}
    </DropdownStyle>
  );
};

export default Dropdown;
