import { Expose, Transform, Type } from 'class-transformer';
import moment from 'moment';

import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import type { DescriptionSource } from '@api/description/description.types';
import { TableauDataSourceModel } from '@api/tableau/TableauDataSourceModel';
import { TaggedItemModel } from '@api/tags/TaggedItemModel';
import { ObjectType } from '@api/types';
import { TableauTableData, TableauTableModel } from '@api/views/TableauTableModel';
import { breadcrumbsToLabelList, breadcrumbsToList } from '@components/Breadcrumbs';
import isEmptyRichText from '@components/RichTextEditor/helpers/isEmptyRichText';
import DataTypesModel from '@models/DataTypesModel';
import { MetadataModel } from '@models/MetadataModel';
import { PopularityData, PopularityModel } from '@models/PopularityModel';
import { Reference } from '@models/Reference';
import { urlFor } from '@utils/routing';

type FieldType = 'ColumnField' | 'CalculatedField' | 'HierarchyField' | 'SetField' | 'Unknown';
type RoleType = 'measure' | 'dimension' | 'Unknown';

export interface TableauFieldData {
  dataSource?: TableauDataSourceModel;
  dataType: string;
  dataTypeIcon: string;
  description?: string;
  externalId: string;
  fieldType: FieldType;
  guid: string;
  name: string;
  popularity?: PopularityData;
  richtextDescription?: string;
  roleType: RoleType;
  suggestedDescription?: string;
  table: TableauTableData;
}

export class TableauFieldModel {
  static objectType: ObjectType = 'tableaufield';

  objectType: ObjectType = TableauFieldModel.objectType;

  static typeDisplay: string = 'Tableau Field';

  typeDisplay: string = TableauFieldModel.typeDisplay;

  guid!: string;

  @Expose({ name: 'external_id' })
  externalId!: string;

  name!: string;

  description?: string;

  query?: string;

  calculation?: string;

  @Expose({ name: 'data_types' })
  @Type(() => DataTypesModel)
  dataTypes?: DataTypesModel = new DataTypesModel();

  @Expose({ name: 'suggested_description' })
  suggestedDescription?: string;

  @Expose({ name: 'suggested_description_source' })
  suggestedDescriptionSource?: string;

  @Expose({ name: 'suggested_description_source_object' })
  @Type(() => Reference)
  suggestedDescriptionSourceObject?: Reference<MetadataModel>;

  // TODO: Remove transform https://app.shortcut.com/select-star/story/55620
  @Expose({ name: 'richtext_description' })
  @Transform((richtextDescription) =>
    isEmptyRichText(richtextDescription) ? '' : richtextDescription,
  )
  richtextDescription?: string;

  @Expose({ name: 'field_type' })
  fieldType!: FieldType;

  @Expose({ name: 'role_type' })
  roleType!: RoleType;

  @Expose({ name: 'data_type' })
  dataType!: string;

  @Expose({ name: 'is_metric' })
  isMetric?: boolean;

  @Expose({ name: 'is_mention' })
  isMention?: boolean;

  @Type(() => TableauTableModel)
  table?: TableauTableModel;

  @Type(() => PopularityModel)
  popularity?: PopularityModel;

  @Expose({ name: 'tagged_items' })
  @Type(() => TaggedItemModel)
  taggedItems?: TaggedItemModel[];

  @Expose({ name: 'data_source' })
  @Type(() => TableauDataSourceModel)
  dataSource?: TableauDataSourceModel;

  @Expose({ name: 'search_name' })
  fullName?: string;

  @Expose({ name: 'parent_guid' })
  @Transform((value, obj) => (obj.dataSource ? obj.dataSource?.guid : value))
  parentGuid?: string;

  @Expose({ name: 'deactivation_scheduled_on' })
  @Transform((value) => (value ? moment(value) : value))
  deactivationScheduledOn?: moment.Moment;

  externalType = undefined;

  @Expose({ name: 'description_source' })
  descriptionSource?: DescriptionSource;

  @Expose({ name: 'ai_description' })
  aiDescription?: string;

  @Expose({ name: 'ingested_description' })
  ingestedDescription?: string;

  @Expose({ name: 'user_description' })
  userDescription?: string;

  @Type(() => BreadcrumbModel)
  breadcrumbs?: BreadcrumbModel[];

  get breadcrumbList() {
    return breadcrumbsToList(this.breadcrumbs);
  }

  get breadcrumbLabelList() {
    const url = urlFor(this, false, '');
    return breadcrumbsToLabelList(this.name, url, this.breadcrumbs);
  }
}
