import { Type } from 'class-transformer';

import { ColumnModel } from '@api/columns/ColumnModel';

class ConditionModel {
  ordinal!: number;

  @Type(() => ColumnModel)
  column!: ColumnModel;
}

export default ConditionModel;
