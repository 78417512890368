import { uniqWith } from 'lodash';

import { TaggedItemModel } from '@api/tags/TaggedItemModel';
import { SelectedBulkItem } from '@hooks/useBulkEditSelected';

interface GetTaggedItemsParams {
  objects: SelectedBulkItem[];
  useSuggestedTags: boolean;
}

export const getTaggedItems = ({ objects, useSuggestedTags }: GetTaggedItemsParams) => {
  const taggedItems: TaggedItemModel[] = objects.reduce<TaggedItemModel[]>((acc, cur) => {
    const curTaggedItems =
      cur?.taggedItems?.filter((item) =>
        useSuggestedTags ? Boolean(item) : item.kind !== 'suggestion',
      ) ?? [];
    return [...acc, ...curTaggedItems];
  }, []);

  const taggedItemsWithoutDuplicates = uniqWith(taggedItems, (a, b) => a.tag.guid === b.tag.guid);

  return taggedItemsWithoutDuplicates;
};
