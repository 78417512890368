import React from 'react';

import { LookMLFieldModel } from '@api/fields/LookMLFieldModel';
import { DimensionItemType } from '@api/metrics/DimensionItemModel';
import { TableauFieldModel } from '@api/tableau/TableauFieldModel';
import Box from '@components/Box';
import CopyFullPathButton from '@components/CopyFullPathButton';
import IconButton from '@components/IconButton';
import UsageTypeBadge from '@components/LineageExplore/components/Common/UsageTypeBadge';
import MentionedByModalWithAnchor from '@components/MentionedBy/MentionedByModalWithAnchor';
import { TableItem } from '@components/Table/Cells/LinkedCell';
import type { TypedCell } from '@components/Table/Cells/types';
import { CellWithButtons } from '@components/Table/TableStyled/TableStyled';
import TagTable from '@components/TagTable';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import useWindowSize from '@hooks/useWindowSize';
import theme from '@styles/theme';

import DeletedLabel from '../DeletedLabel/DeletedLabel';

import { StyledTagsContainer } from './FieldNameTableCell.styles';
import { Label } from './Labels';

interface FieldNameTableCellProps {
  field: LookMLFieldModel | TableauFieldModel | DimensionItemType;
  fullName?: string;
  guid: string;
  isMentioned: boolean;
  /**
   *  This extra prop has been added so we can pass exploreField attributes here.
   *  ExploreFields are basically lookMLFields with extra props.
   *  @todo Figure out how to reduce this complexity.
   */
  isMetric: boolean;
  noLink?: boolean;
  onClick?: () => void;
  onSqlButtonClick?: () => void;
  tooltipText?: string;
}

const FieldNameTableCell: React.FC<TypedCell & FieldNameTableCellProps> = ({
  column,
  field,
  fullName,
  guid = '',
  isMentioned,
  isMetric,
  noLink = true,
  onClick,
  onSqlButtonClick,
  tooltipText,
}) => {
  const { isDownMd } = useWindowSize();
  const isUsedAsFilter = 'isUsedAsFilter' in field ? field.isUsedAsFilter : false;
  const isUsedAsPivot = 'isUsedAsFilter' in field ? field.isUsedAsPivot : false;
  const isDeactivated =
    'deactivationScheduledOn' in field ? field.deactivationScheduledOn : undefined;
  const sql =
    'query' in field && field.name?.includes('Custom SQL Query') ? field?.query : undefined;

  const iconToolTip =
    field.objectType === 'lookmlfield'
      ? `Looker Field: ${field.externalType}`
      : field.dataTypes?.tooltips.dataType;

  const getDisplayName = () => {
    if (field.objectType === 'lookmlfield') {
      return (field as LookMLFieldModel).label;
    }
    if (field.objectType === 'tableaufield') {
      return (field as TableauFieldModel).name;
    }

    return field.fullName || field.name;
  };

  const displayName: string = getDisplayName();

  const fieldType: string =
    (field as TableauFieldModel)?.roleType ?? (field as LookMLFieldModel).fieldType ?? '';

  const getLabel = () => {
    if (fieldType === 'dimension') {
      return <Label dimension />;
    }
    if (fieldType === 'measure') {
      return <Label measure />;
    }
    if (fieldType === 'field_set') {
      return <Label field_set />;
    }
    return null;
  };

  return (
    <CellWithButtons
      alignItems={isDownMd ? 'start' : 'center'}
      compDisplay="flex"
      compWidth="100%"
      flexDirection={isDownMd ? 'column' : 'row'}
      justifyContent="flex-start"
      my={0.5}
    >
      <Box alignItems="center" compDisplay="flex" gap={1}>
        <Box alignItems="center" as="span" compDisplay="flex" onClick={onClick} role="button">
          {getLabel()}
          <Tooltip content={iconToolTip}>
            <Icon color="black" mr={0.5} name={field.dataTypes?.icons.dataType!} size="16px" />
          </Tooltip>
          <TableItem
            filterValue={column.filterValue}
            item={field}
            name={displayName}
            noLink={noLink}
            showNameTooltip
            tooltipText={tooltipText}
          />
        </Box>
        {fullName && (
          <CopyFullPathButton
            className="inline-button"
            hoverBackgroundColor="primary.500"
            hoverColor="white"
            iconSize="16px"
            text={fullName}
          />
        )}
        {sql && (
          <Tooltip content="Open SQL">
            <IconButton onClick={() => onSqlButtonClick?.()}>
              <Icon color="#8C939B" name="query" size="14px" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <StyledTagsContainer compDisplay="flex" flexDirection="row" flexWrap="wrap" gap={0.5}>
        {isDeactivated && <DeletedLabel />}
        {isUsedAsFilter && <UsageTypeBadge hideFilterIcon showFilterFullText type="filter" />}
        {isUsedAsPivot && <TagTable>Pivot</TagTable>}
        {isMentioned && <MentionedByModalWithAnchor guid={guid} />}
        {isMetric && (
          <MentionedByModalWithAnchor
            filterByMetrics
            guid={guid}
            iconName="metric"
            label="Metric"
            textColor={theme.colors.hyperlink}
          />
        )}
      </StyledTagsContainer>
    </CellWithButtons>
  );
};

export default React.memo(FieldNameTableCell);
