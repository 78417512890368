import * as Sentry from '@sentry/react';
import getDebug from 'debug';
import forEach from 'lodash/forEach';

import { FeatureFlagsKeysType } from '@pages/FeatureFlagsPage/featureFlagsList';

const debug = getDebug('selectstar:flags');

const envFlags: FeatureFlagsKeysType[] = [];
forEach(window.env, (value, theKey) => {
  const key = theKey.toLowerCase();
  if (key.startsWith('react_app_ff_') && value === 'on') {
    envFlags.push(key.replace('react_app_ff_', '') as FeatureFlagsKeysType);
  }
});

let localFlags: FeatureFlagsKeysType[] = [];
try {
  const local = localStorage.getItem('flags') || '';
  localFlags = local.toLowerCase().split(',').filter(Boolean) as FeatureFlagsKeysType[];
} catch (err) {
  Sentry.captureException(err);
}

let queryFlags: FeatureFlagsKeysType[] = [];
try {
  const qsFlags = document.location.search
    .replace(/^\?/, '')
    .split('&')
    .map((_) => _.split('='))
    .find((_) => _[0] === 'flags');
  if (qsFlags) {
    queryFlags = decodeURIComponent(qsFlags[1])
      .toLowerCase()
      .split(',')
      .filter(Boolean) as FeatureFlagsKeysType[];
  }
} catch (err) {
  Sentry.captureException(err);
}

const flags: Record<FeatureFlagsKeysType, boolean> = {} as any;

// precedence depends on reverse order
envFlags.forEach((flag) => (flags[flag] = true));
localFlags.forEach((flag) => (flags[flag] = true));
queryFlags.forEach((flag) => (flags[flag] = true));

debug(Object.keys(flags).join(', '));

export default flags;
