import styled from '@emotion/styled';

export const StyledSourceTablesTabItemWrapper = styled.div`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: ${({ theme }) => theme.space(0.5)};
    bottom: ${({ theme }) => theme.space(0.5)};
    left: ${({ theme }) => theme.space(4)};
    border-left: 2px solid ${({ theme }) => theme.colors.gray[200]};
  }
`;
