import styled from '@emotion/styled';

import Button from '@components/Button/Button';
import rootTheme from '@styles/theme';

export const StyledChatBotHierarchyNewChatButton = styled(Button)``;

StyledChatBotHierarchyNewChatButton.defaultProps = {
  alignItems: 'center',
  compDisplay: 'flex',
  compHeight: rootTheme.space(4.5),
  compSize: 'lg',
  compWidth: `calc(100% - ${rootTheme.space(3)})`,
  justifyContent: 'center',
  mb: 1,
  minHeight: rootTheme.space(4.5),
  mx: 'auto',
  pl: 1.25,
  textAlign: 'center',
};

export const StyledChatBotHierarchyClearAllButton = styled(Button)`
  border: none;
  border-top: 1px solid ${({ theme }) => theme.colors.v1.gray[200]};
`;

StyledChatBotHierarchyClearAllButton.defaultProps = {
  backgroundColor: 'white',
  borderColor: 'gray.200',
  borderRadius: 0,
  bottom: 0,
  color: 'gray.700',
  compWidth: '100%',
  fontSize: 'body2',
  fontWeight: 'medium',
  iconCurrentColor: true,
  justifyContent: 'center',
  mb: 0.5,
  position: 'absolute',
};
