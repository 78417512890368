const getIntervals = ({
  min,
  max,
  count,
  end = min + Math.ceil((max - min + 1) / count) - 1,
}: {
  count: number;
  end?: number;
  max: number;
  min: number;
}): Array<[number, number]> =>
  count < 1 ? [] : [[min, end], ...getIntervals({ count: count - 1, max, min: end })];

export default getIntervals;
