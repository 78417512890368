import React, { memo } from 'react';
import type { Cell } from 'react-table';

import type { JobEventModel } from '@api/jobs/JobEventModel.v1';
import DateTime from '@components/DateTime';
import Status from '@components/Status';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import { PaginatedResponse } from '@models/PaginatedResponse';
import type { FilterServiceInterface } from '@utils/filters';
import { FilterOptions } from '@utils/filters';

enum ColumnKey {
  duration = 'duration',
  endTime = 'endTime',
  result = 'result',
  runId = 'runId',
  runUrl = 'runUrl',
  startTime = 'startTime',
}

const DEFAULT_VISIBLE_COLUMNS = [ColumnKey.endTime, ColumnKey.result, ColumnKey.duration];

export const JOB_EVENT_TABLE_SEARCH_CONFIG: PartialRecord<ColumnKey, keyof FilterOptions> = {};

export const JOB_EVENT_TABLE_SORT_CONFIG: PartialRecord<ColumnKey, string> = {
  [ColumnKey.duration]: 'duration',
  [ColumnKey.endTime]: 'end_time',
  [ColumnKey.result]: 'result',
  [ColumnKey.runId]: 'run_id',
  [ColumnKey.runUrl]: 'run_url',
  [ColumnKey.startTime]: 'start_time',
};

export interface JobEventTableProps {
  data?: PaginatedResponse<JobEventModel>;
  filterService: FilterServiceInterface;
  isLoading?: boolean;
  visibleColumns?: Array<ColumnKey>;
}

const JobEventTable: React.FC<JobEventTableProps> = ({
  data,
  filterService,
  isLoading,
  visibleColumns = DEFAULT_VISIBLE_COLUMNS,
}) => {
  const { changePage, filter, initialTableSortState, sort } = filterService;

  const columns: ColumnConfig<JobEventModel>[] = React.useMemo(() => {
    const all: Record<ColumnKey, ColumnConfig<JobEventModel>> = {
      [ColumnKey.duration]: {
        Header: 'Run Time',
        accessor: ColumnKey.duration,
        id: ColumnKey.duration,
        width: '115%',
      },
      [ColumnKey.endTime]: {
        Cell: ({ row: { original } }: Cell<JobEventModel>) => (
          <DateTime datetime={original.endTime} />
        ),
        Header: 'Run Completed',
        accessor: ColumnKey.endTime,
        disableHiding: true,
        id: ColumnKey.endTime,
        width: '115%',
      },
      [ColumnKey.startTime]: {
        Cell: ({ row: { original } }: Cell<JobEventModel>) => (
          <DateTime datetime={original.startTime} />
        ),
        Header: 'Start Time',
        accessor: ColumnKey.startTime,
        id: ColumnKey.startTime,
        width: '115%',
      },
      [ColumnKey.runId]: {
        Header: 'Run Id',
        accessor: ColumnKey.runId,
        id: ColumnKey.runId,
        width: '115%',
      },
      [ColumnKey.runUrl]: {
        Header: 'Url',
        accessor: ColumnKey.runUrl,
        id: ColumnKey.runUrl,
        width: '115%',
      },
      [ColumnKey.result]: {
        Cell: ({ row: { original } }: Cell<JobEventModel>) => (
          <Status status={original.formattedResult}>{original.result}</Status>
        ),
        Header: 'Status',
        accessor: ColumnKey.result,
        id: ColumnKey.result,
        width: '115%',
      },
    };

    return visibleColumns.map((col) => all[col]);
  }, [visibleColumns]);

  const totalPages = data && filter.page_size ? Math.ceil(data.count / filter.page_size) : 1;

  return (
    <TableStyled>
      <Table
        basic="very"
        changePage={changePage}
        className="table-full"
        columns={columns}
        compact
        data={data?.results || []}
        disableRowSelect
        initialState={{
          sortBy: initialTableSortState,
        }}
        loading={isLoading}
        manualPagination
        manualSortBy
        selectable
        setSortBy={sort}
        sortable
        stickyHeader
        totalPages={totalPages}
        unstackable
      />
    </TableStyled>
  );
};

export default memo(JobEventTable);
