import invalidateCache from '@api/invalidateCache';
import { usePatchMetadataById } from '@api/metadata';
import { searchCacheKeys } from '@api/search';
import { renderInfoToast } from '@components/Toast';
import fetchClient from '@lib/fetchClient';

interface UseMutateMetadataParams {
  guid: string;
  refetch: () => void;
}

const useMutateMetadata = ({ guid, refetch }: UseMutateMetadataParams) => {
  return usePatchMetadataById(guid, {
    onSuccess: (d) => {
      renderInfoToast('Edit saved.');
      invalidateCache((keys) => [keys.dashboards.all, keys.tables.all, keys.activity.all]);
      fetchClient.setQueryData(searchCacheKeys.searchItem(d.guid), d);
      refetch();
    },
  });
};

export default useMutateMetadata;
