import { Expose, Transform } from 'class-transformer';
import moment from 'moment';

import { DashboardModel } from './DashboardModel';

export class DashboardUserQuerySummaryModel extends DashboardModel {
  @Expose({ name: 'last_queried' })
  @Transform((value) => moment(value))
  lastQueried?: moment.Moment;

  count!: number;
}
