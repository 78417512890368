import React from 'react';

import type { StatusType } from '@api/types';
import type { FiltersListFilterType, FiltersListProps } from '@components/FiltersList/FiltersList';
import FiltersList from '@components/FiltersList/FiltersList';
import { statusMap } from '@components/Status/Status';
import Icon from '@components/UI/Icon';

export type DbtTestsCountsType = Record<StatusType, number> | null;

interface DbtFiltersListProps {
  counts?: DbtTestsCountsType;
}

export const dbtTestsDefaultFilter = { label: 'All', name: 'all' };

const DbtFiltersList: React.FC<
  DbtFiltersListProps & Omit<FiltersListProps<FiltersListFilterType>, 'data'>
> = ({ counts, onClick, selectedFilter }) => {
  const filters = counts
    ? [
        {
          count: counts?.error,
          label: 'Failed',
          name: 'error',
          startIcon: <Icon name={statusMap.error.icon} size="18px" />,
        },
        {
          count: counts?.warning,
          label: 'Warning',
          name: 'warning',
          startIcon: <Icon name={statusMap.warning.icon} size="18px" />,
        },
        {
          count: counts?.success,
          label: 'Passed',
          name: 'success',
          startIcon: <Icon name={statusMap.success.icon} size="18px" />,
        },
      ]
    : [];

  return (
    <FiltersList
      data={[dbtTestsDefaultFilter, ...filters]}
      onClick={onClick}
      selectedFilter={selectedFilter ?? dbtTestsDefaultFilter}
    />
  );
};

export default DbtFiltersList;
