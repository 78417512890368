import React, { useState } from 'react';
import { useParams, useRouteMatch } from '@routing/router';
import { useDebouncedCallback } from 'use-debounce';

import { useFetchSchemas } from '@api/schema/schema';
import { useFetchTables } from '@api/tables';
import AccordionInputItem from '@components/Accordion/AccordionInputItem';
import CircularLoader from '@components/CircularLoader';
import { getSchemaOptions, getTableOptions } from '@components/Dropdown/helpers';
import { Filter } from '@utils';
import stripSpaces from '@utils/stripSpaces';

const defaultSchemaConfig: Filter.FilterOptions = {
  order: '-popularity',
  page_size: 50,
  query: stripSpaces(`{
    guid,
    name,
    data_types
  }`),
};

const defaultTablesConfig: Filter.FilterOptions = {
  order: '-popularity',
  page_size: 50,
  query: stripSpaces(`{
    guid,
    name,
    schema{
      name
    },
    data_types
  }`),
};

interface SchemasOrTablesProps {
  enableSchemaFilter?: boolean;
  enableTableFilter?: boolean;
  update: (guid: string) => void;
}

const SchemasOrTables: React.FC<SchemasOrTablesProps> = ({
  enableSchemaFilter = true,
  enableTableFilter = true,
  update,
}) => {
  const match = useRouteMatch();
  const { guid } = useParams<{ guid: string }>();

  const [search, setSearch] = useState<string | undefined>('');

  const {
    data: schemasData,
    isFetching: schemasIsFetching,
    isLoading: schemasIsLoading,
  } = useFetchSchemas({
    enabled: enableSchemaFilter,
    keepPreviousData: true,
    params: {
      ...defaultSchemaConfig,
      databases: guid && match.path === '/databases/:guid' ? guid : undefined,
      search_name: search,
    },
  });

  const {
    data: tablesData,
    isFetching: tablesIsFetching,
    isLoading: tablesIsLoading,
  } = useFetchTables({
    enabled: enableTableFilter,
    keepPreviousData: true,
    params: {
      ...defaultTablesConfig,
      databases: guid && match.path === '/databases/:guid' ? guid : undefined,
      schema: guid && match.path === '/schemas/:guid' ? guid : undefined,
      search_name: search,
    },
  });

  const handleSearch = useDebouncedCallback(setSearch, 500);

  if (schemasIsLoading || tablesIsLoading) {
    return <CircularLoader borderWidth={2} centered compSize={2} mb={1.75} mt={4} />;
  }

  const options = [
    ...getSchemaOptions(schemasData?.results ?? []),
    ...getTableOptions(tablesData?.results ?? []),
  ];

  return (
    <AccordionInputItem
      handleSearch={handleSearch}
      loading={schemasIsFetching || tablesIsFetching}
      options={options}
      title={`${enableSchemaFilter ? 'Schema' : ''}${
        enableSchemaFilter && enableTableFilter ? '/' : ''
      }${enableTableFilter ? 'Table' : ''}`}
      update={update}
    />
  );
};

export default SchemasOrTables;
