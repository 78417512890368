import { Editor, EditorFragmentDeletionOptions, Element, Point, Range, Transforms } from 'slate';

import deleteTable from '../Tables/helpers/deleteTable';
import stripSlateNodes from '../Tables/helpers/stripSlateNodes';

const TABLE_CELL_TYPES = ['td', 'th'];

const withTablesPlugin = (editor: Editor) => {
  const { deleteBackward, deleteForward, deleteFragment, insertBreak, insertData, normalizeNode } =
    editor;

  // Fixes behavior with being able to fully delete tables when selecting all and pressing delete.
  // eslint-disable-next-line no-param-reassign
  editor.deleteFragment = (options: EditorFragmentDeletionOptions | undefined) => {
    if (editor.selection && options?.direction === 'backward') {
      const [tableNode] = Array.from(
        Editor.nodes(editor, {
          at: editor.selection,
          match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === 'table',
        }),
      );

      const cells = Array.from(
        Editor.nodes(editor, {
          match: (n) =>
            !Editor.isEditor(n) && Element.isElement(n) && TABLE_CELL_TYPES.includes(n.type),
        }),
      );

      // If inside a table node and multiple cells are selected, delete the whole table.
      if (tableNode && cells.length > 1) {
        deleteTable(editor, tableNode);
      }
    }

    deleteFragment(options);
  };

  // Prevents deleting backwards while at the start of a table cell, otherwise slate would delete the entire cell.
  // eslint-disable-next-line no-param-reassign
  editor.deleteBackward = (unit) => {
    const { selection } = editor;

    if (selection) {
      const [cell] = Array.from(
        Editor.nodes(editor, {
          match: (n) =>
            !Editor.isEditor(n) && Element.isElement(n) && TABLE_CELL_TYPES.includes(n.type),
        }),
      );

      if (cell) {
        const [, cellPath] = cell;

        const start = Editor.start(editor, cellPath);
        if (Point.equals(selection.anchor, start)) {
          // If the first element in the cell is a void element we should still delete backwards.
          const aboveVoid = Editor.above(editor, {
            match: (n) => !Editor.isEditor(n) && Element.isElement(n) && Editor.isVoid(editor, n),
          });

          if (aboveVoid) {
            deleteBackward(unit);
          }

          return;
        }
      }
    }

    deleteBackward(unit);
  };

  editor.deleteForward = (unit) => {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
      const [cell] = Array.from(
        Editor.nodes(editor, {
          match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === 'td',
        }),
      );

      const prevNodePath = Editor.after(editor, selection);
      const [tableNode] = Array.from(
        Editor.nodes(editor, {
          at: prevNodePath,
          match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === 'td',
        }),
      );

      if (cell) {
        const [, cellPath] = cell;
        const end = Editor.end(editor, cellPath);

        if (Point.equals(selection.anchor, end)) {
          return;
        }
      }
      if (!cell && tableNode) {
        return;
      }
    }

    deleteForward(unit);
  };

  // If inside table cells, we shouldn't insert breaks otherwise we may break the structure of the table. Soft break can still be applied with shift + enter
  // eslint-disable-next-line no-param-reassign
  editor.insertBreak = () => {
    const { selection } = editor;

    if (selection) {
      const [table] = Array.from(
        Editor.nodes(editor, {
          match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === 'table',
        }),
      );

      if (table) {
        return;
      }
    }

    insertBreak();
  };

  // clipboardDecode that slatejs uses internally. Decodes item from base64.
  const clipboardDecode = (str: string) => JSON.parse(decodeURIComponent(window.atob(str)));

  // Handles copy and pasting of table cells. By default slate copies the table cell and table rather than just the cell content.
  // eslint-disable-next-line no-param-reassign
  editor.insertData = (data: DataTransfer) => {
    if (editor.selection) {
      const [table] = Array.from(
        Editor.nodes(editor, {
          match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === 'table',
        }),
      );

      if (table) {
        const encodedSlateState = data.getData('application/x-slate-fragment');

        if (encodedSlateState !== '') {
          const decodedSlateState = clipboardDecode(encodedSlateState);

          if (decodedSlateState.length > 0) {
            const strippedNode = stripSlateNodes(decodedSlateState);
            Transforms.insertNodes(editor, strippedNode);
            return;
          }
        }
      }
    }
    insertData(data);
  };

  editor.normalizeNode = ([node, path]) => {
    if (Element.isElement(node) && node?.type === 'table') {
      const pointAfterTable = Editor.after(editor, path);

      if (!pointAfterTable) {
        Transforms.insertNodes(
          editor,
          {
            children: [{ text: '' }],
            type: 'paragraph',
          },
          { at: [path[0] + 1] },
        );

        return;
      }
    }

    normalizeNode([node, path]);
  };

  return editor;
};

export default withTablesPlugin;
