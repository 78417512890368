import { HierarchyModel } from './HierarchyModel';

type callback = () => void;

const pathKey = (path: string[]) => {
  return path.join(',');
};

/**
 * @deprecated
 * Use Tree.v1.tsx
 */
class HierarchyState {
  hierarchy: HierarchyModel[];

  initialized: boolean;

  parents: { [guid: string]: string };

  state: { [guid: string]: boolean };

  active?: string;

  callbacks: { [path: string]: callback };

  constructor() {
    this.hierarchy = [new HierarchyModel()];
    this.parents = {};
    this.state = {};
    this.initialized = false;
    this.callbacks = {};
  }

  updateHierarchy(hierarchy: HierarchyModel[], openAll = false) {
    this.hierarchy = hierarchy;
    this.state = {};
    this.initState(hierarchy);
    this.initialized = true;
    if (openAll) {
      const newState: { [guid: string]: boolean } = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const [k] of Object.entries(this.state)) {
        newState[k] = true;
      }
      this.state = newState;
    }
  }

  initState(hierarchy: HierarchyModel[], parent?: string) {
    for (let i = 0; i < hierarchy.length; i += 1) {
      const node = hierarchy[i];
      if (parent) {
        this.parents[node.guid as string] = parent;
      }
      if (node.children && node.children.length) {
        this.initState(node.children, node.guid);
      }
    }
  }

  subscribe(path: string[], cb: callback) {
    this.callbacks[pathKey(path)] = cb;
  }

  unsubscribe(path: string[]) {
    delete this.callbacks[pathKey(path)];
  }

  updateActive(guid?: string) {
    this.active = guid;
    let parent = guid;
    while (parent) {
      this.state[parent] = true;
      parent = this.parents[parent];
    }
  }

  isActive(path: string[]) {
    const guid = path[path.length - 1];
    return this.active === guid;
  }

  isOpen(path: string[]) {
    const guid = path[path.length - 1];
    return this.state[guid];
  }

  toggleOpen(path: string[]) {
    const guid = path[path.length - 1];
    this.state[guid] = !this.state[guid];
    const callback = this.callbacks[pathKey(path)];
    if (callback) {
      callback();
    }
  }
}

export { HierarchyState };
