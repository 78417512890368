import React from 'react';

import { useFetchDbtProjects } from '@api/dbt';
import TabError from '@components/TabContent/TabError';
import DbtProjectsTable, {
  DBT_PROJECTS_TABLE_SEARCH_CONFIG,
  DBT_PROJECTS_TABLE_SORT_CONFIG,
} from '@components/Table/DbtProjectsTable';
import type { FilterOptions } from '@utils/filters';
import { setParams, useUpdateFilters } from '@utils/filters';

const REQUEST_CONFIG: FilterOptions = {
  order: DBT_PROJECTS_TABLE_SORT_CONFIG.name,
  page: 1,
  page_size: 100,
};

interface DbtProjectsProps {
  dsGuid: string;
}

const DbtProjects: React.FC<DbtProjectsProps> = ({ dsGuid }) => {
  const filterService = useUpdateFilters(
    REQUEST_CONFIG,
    DBT_PROJECTS_TABLE_SEARCH_CONFIG,
    DBT_PROJECTS_TABLE_SORT_CONFIG,
    REQUEST_CONFIG.order,
  );
  const { data, error, isLoading } = useFetchDbtProjects({
    enabled: Boolean(dsGuid),
    params: {
      ...(setParams(filterService.filter) as any),
      data_source: dsGuid,
    },
  });

  if (error) return <TabError />;

  return <DbtProjectsTable data={data} filterService={filterService} isLoading={isLoading} />;
};

export default React.memo(DbtProjects);
