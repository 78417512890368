import styled from '@emotion/styled';

import type { MakeSpaceProps } from '@styles/mixins/makeSpace';
import makeSpace from '@styles/mixins/makeSpace';

export interface StyledAlertProps extends MakeSpaceProps {
  bgColor?: string;
  type?: 'success' | 'info' | 'error' | 'warning';
}

const backgroundColorsMap = {
  error: '#fff6f6',
  info: '#f6f9fc',
  success: '#e5f9e7',
  warning: '#FFF9DF',
};

export const StyledAlert = styled.div<StyledAlertProps>`
  ${({ theme, ...props }) => makeSpace(theme, { p: 2, ...props })};
  width: 100%;
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
  line-height: 1.185rem;
  border-radius: ${({ theme }) => theme.radius.md};
  display: flex;
  background-color: ${({ bgColor, type = 'info' }) => bgColor ?? backgroundColorsMap[type]};
`;

export const StyledAlertTitle = styled.span`
  display: block;
  font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
  font-size: 1rem;
`;

export const StyledAlertIconWrapper = styled.div`
  display: block;
  margin-right: ${({ theme }) => theme.space(1)};
  pointer-events: none;
  min-width: 20px;

  > :not(style) + :not(style) {
    margin: ${({ theme }) => theme.space(0, 0, 0, 1)};
  }
`;

export const StyledAlertContentWrapper = styled.div`
  display: block;
  font-size: 14px;
  font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
  word-break: break-word;
`;
