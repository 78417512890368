import React from 'react';

import Box from '@components/Box';

interface AssetsTabProps {
  guid: string;
}

const CollectionAssetsTab: React.FC<AssetsTabProps> = ({ guid }) => {
  return <Box>CollectionAssetsTab mock {guid}</Box>;
};

export default React.memo<AssetsTabProps>(CollectionAssetsTab);
