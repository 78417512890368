import type { FEDataSourceType } from '@configs/dataTypes/types';

import type { DataTypes, DataTypesConfig } from '../types';

export interface FindConfigsProps<T> {
  dataTypes: Partial<DataTypes>;
  defaultValue?: T;
  ref: DataTypesConfig;
}

const findConfigs = <T>({
  dataTypes,
  defaultValue,
  ref,
}: FindConfigsProps<T>): {
  dataSource: T;
  dataType: T;
  objectType: T;
} => {
  const { dataSourceType, dataType, objectType } = dataTypes;
  const feDataSourceType = (dataSourceType ?? objectType) as FEDataSourceType;
  const source = ref?.[feDataSourceType];
  const object = source?.[objectType!];
  const data = object?.[dataType!];

  return {
    dataSource: source?.default ?? defaultValue,
    dataType: data ?? object?.default ?? defaultValue,
    objectType: object?.default ?? defaultValue,
  };
};

export default findConfigs;
