import styled from '@emotion/styled';

import RichTextDescriptionEditor from '@components/RichTextDescriptionEditor';

export const StyledRichtextDescriptionEditor = styled(RichTextDescriptionEditor)`
  .content {
    min-height: auto;
    margin-bottom: 0;
  }
`;
