import React from 'react';

import { usePostVerificationResend } from '@api/verification';
import Box from '@components/Box';
import CenterAlignedBox from '@components/CenterAlignedBox';
import Link from '@components/Link';
import Text, { defaultParagraphStyles } from '@components/Text';
import Title from '@components/Title/Title';
import { renderInfoToast } from '@components/Toast';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import theme from '@styles/theme';

interface VerificationProps {
  email: string;
}

const VerificationScreen: React.FC<VerificationProps> = ({ email }) => {
  const segment = useSegmentContext();
  const { isLoading, mutate } = usePostVerificationResend({
    onError: () => {
      renderInfoToast('Verification Email Failed to Send');
    },
    onSuccess: () => {
      renderInfoToast('Verification Email Sent Again');
    },
  });

  const handleResend = () => {
    segment?.track(SegmentTrackEventName.SignUpResendVerificationLinkClicked);
    mutate({ email });
  };

  return (
    <CenterAlignedBox maxWidth="460px" minWidth="360px">
      <Box alignItems="flex-start" compWidth="100%" pb={2}>
        <Title showLogo>Thanks for choosing Select Star</Title>
      </Box>
      <Box alignItems="flex-start" compWidth="100%" pb={2}>
        <Text as="p" pt={0.5} {...defaultParagraphStyles}>
          A verification email has been sent to your email account.
        </Text>
        <Text as="p" pb={0.5} {...defaultParagraphStyles}>
          Please check your inbox to verify.
        </Text>
      </Box>
      <Box alignItems="flex-start" compWidth="100%" pt={3}>
        <Link
          as="button"
          color={theme.colors.text}
          disabled={isLoading}
          onClick={handleResend}
          pb={0}
          underline
        >
          Resend verification email
        </Link>
      </Box>
      <Box alignItems="flex-start" compDisplay="inline" compWidth="100%">
        <Text as="span" pb={0.5} {...defaultParagraphStyles} display="inline">
          Having some problems?{' '}
        </Text>
        <Text as="span" pb={0.5} {...defaultParagraphStyles} display="inline">
          <Link href="mailto:hello@selectstar.com" underline>
            Contact us
          </Link>
        </Text>
      </Box>
    </CenterAlignedBox>
  );
};

export default VerificationScreen;
