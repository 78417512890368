import React, { useRef, useState } from 'react';
import uniqBy from 'lodash/uniqBy';

import { useFetchDatabaseConfigs } from '@api/ingestion/';
import DatabaseIngestionConfigModel from '@api/ingestion/DatabaseIngestionConfigModel';
import { CenterLoader, IndeterminateLoaderModal } from '@components/IndeterminateLoader';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import { DataSourceModel } from '@models/DataSourceModel';
import { setParams } from '@utils/filters';

import { BoxWrapper } from '../DataSourceAddStep';

import SchemaIngestionConfigTable from './SchemaIngestionConfigTable';

const dbConfigRequest = {
  active: true,
  page: 1,
  page_size: 100,
};
interface SchemaIngestionConfigStepProps {
  dataSource: DataSourceModel;
  onConfigUpdated: () => void;
  prevStep: () => void;
}

const SchemaIngestionConfigStep: React.FC<SchemaIngestionConfigStepProps> = ({
  dataSource,
  onConfigUpdated,
  prevStep,
}) => {
  const segment = useSegmentContext();
  const allFetchedConfigs = useRef<DatabaseIngestionConfigModel[]>([]);
  const [databaseIdx, setDatabaseIdx] = useState(0);
  const [page, setPage] = useState<number>(1);

  const { data, isLoading } = useFetchDatabaseConfigs(dataSource.guid, {
    cacheTime: 0,
    onSuccess: ({ results }) => {
      allFetchedConfigs.current = uniqBy([...results, ...allFetchedConfigs.current], 'guid');
    },
    params: setParams({ ...dbConfigRequest, page }),
  });

  const totalPages =
    data && dbConfigRequest.page_size ? Math.ceil(data.count / dbConfigRequest.page_size) : 1;

  const database = allFetchedConfigs.current[databaseIdx];
  const databaseCount = allFetchedConfigs?.current.length;

  const postSchemaSave = (newConfig: DatabaseIngestionConfigModel) => {
    // if possible change db config
    if (databaseIdx + 1 < databaseCount) {
      setDatabaseIdx(databaseIdx + 1);
    }
    // check if there are more db configs we can get
    else if (databaseIdx + 1 === databaseCount) {
      // check if we are on the last page
      if (page === totalPages) {
        onConfigUpdated();
      } else {
        // go to next page and get more data
        setPage((prev) => prev + 1);
      }
    }
    segment?.track(SegmentTrackEventName.ImportData_SelectSchemasNextButtonClicked, {
      selectedSchemas: newConfig.schemata.filter((s) => s.shouldIngest).length,
      totalSchemas: newConfig.schemata.length,
    });
  };

  const goBack = () => {
    segment?.track(SegmentTrackEventName.ImportData_SelectSchemasPrevButtonClicked);
    if (databaseIdx - 1 >= 0) {
      setDatabaseIdx(databaseIdx - 1);
    }
    if (databaseIdx - 1 < 0) {
      prevStep();
    }
  };

  if (isLoading) {
    return (
      <BoxWrapper>
        <CenterLoader>
          <IndeterminateLoaderModal
            active
            content="Loading databases..."
            indeterminate
            inline="centered"
            size="medium"
          />
        </CenterLoader>
      </BoxWrapper>
    );
  }

  return (
    <SchemaIngestionConfigTable
      key={database.guid}
      databaseConfig={database}
      dsGuid={dataSource.guid}
      loading={isLoading}
      onSave={postSchemaSave}
      prevStep={goBack}
      showBackButton={dataSource.dataTypes?.dataSourceType !== 'glue'}
    />
  );
};

export default SchemaIngestionConfigStep;
