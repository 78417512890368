import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { Cell } from 'react-table';

import DateTime from '@components/DateTime';
import type { ColumnConfig } from '@components/Table/Table/types';
import type { DataSourceTypesType } from '@models/DataSourceCredentials';
import { MetadataData } from '@models/MetadataModel';

import LinkedCell from '../Cells/LinkedCell';
import SearchHeader from '../Cells/SearchHeader';
import Table from '../Table';
import TableStyled from '../TableStyled';

export type MostUsedObjectType = 'explore' | 'dashboard';

const getNameHeader = (dsType?: DataSourceTypesType, objectType?: MostUsedObjectType) => {
  if (objectType === 'explore') {
    return 'Explore';
  }

  if (dsType === 'looker') {
    return 'Dashboard';
  }

  if (dsType === 'mode') {
    return 'Report';
  }

  if (dsType === 'tableau') {
    return 'Workbook';
  }
  return 'Name';
};

export interface MostUsedTableInterface extends MetadataData {
  count?: number;
  lastQueried?: moment.Moment;
  lastQueriedOn?: moment.Moment;
  queryRuns?: number;
}

interface MostUsedTableProps {
  data?: MostUsedTableInterface[];
  dataSourceType?: DataSourceTypesType;
  itemCount?: number;
  loading: boolean;
  objectType?: MostUsedObjectType;
}

const MostUsedTable: React.FC<MostUsedTableProps> = ({
  data,
  dataSourceType,
  itemCount = 0,
  loading,
  objectType,
}) => {
  const [isShowFilter, setShowFilter] = useState(false);

  const columns: ColumnConfig<MostUsedTableInterface>[] = useMemo(
    () => [
      {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      {
        Cell: (props: Cell<MostUsedTableInterface>) => {
          const { row } = props;
          const item = row.original;
          return <LinkedCell {...props} item={item} showDataSourceIcon showIcon showNameTooltip />;
        },
        Header: `${getNameHeader(dataSourceType, objectType)} (${itemCount})`,
        accessor: (q) => q?.name,
        disableHiding: true,
        id: 'name',
        width: '150%',
      },
      {
        Cell: (props: Cell<MostUsedTableInterface>) => {
          const querySummary = props.row.original;
          return querySummary.count || querySummary.queryRuns || null;
        },
        Header: () =>
          dataSourceType && ['tableau', 'mode'].includes(dataSourceType) ? 'Views' : 'Query Runs',
        accessor: (q) => q?.count || q?.queryRuns,
        disableFilters: true,
        id: 'queryRuns',
        sortType: 'alphanumeric',
        width: '125%',
      },
      {
        Cell: (props: Cell<MostUsedTableInterface>) => {
          const querySummary = props.row.original;
          const lastQueried = querySummary?.lastQueried || querySummary?.lastQueriedOn;
          return <DateTime datetime={lastQueried} />;
        },
        Header: () => (dataSourceType === 'tableau' ? 'Last Viewed' : 'Last Queried'),
        accessor: (q) => q?.lastQueried?.toDate() ?? q?.lastQueriedOn?.toDate(),
        disableFilters: true,
        id: 'lastQueried',
        sortType: 'datetime',
        width: '125%',
      },
    ],
    [dataSourceType, itemCount, objectType],
  );

  return (
    <TableStyled>
      <Table
        basic="very"
        className="table-full"
        columns={columns}
        compact
        data={data || []}
        disableColumnFiltering
        disablePagination
        disableRowSelect
        initialState={{
          sortBy: [
            {
              desc: true,
              id: 'queryRuns',
            },
          ],
        }}
        loading={loading}
        showFilter={isShowFilter}
        singleLine
        sortable
        toggleFilter={() => setShowFilter((prev) => !prev)}
        unstackable
      />
    </TableStyled>
  );
};

export default MostUsedTable;
