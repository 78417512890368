import styled from '@emotion/styled';

import TableStyled from '@components/Table/TableStyled';

export const StyledRecentQueriesTable = styled(TableStyled)`
  &.user,
  &.startTime,
  &.runTime {
    text-align: center;
  }
`;
