import styled from '@emotion/styled';

export const StyledList = styled.ul`
  list-style: none;

  li::before {
      content: '\\2022';
      font-size: 22px;
      color: #aeb4bb;
      font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
      display: inline-block;
      line-height: ${({ theme }) => theme.space(2)};
      vertical-align: middle;
      width: ${({ theme }) => theme.space(2.5)};
    }
  }
`;
