import React from 'react';

import Box from '@components/Box';

interface WarehouseSelectLabelProps {
  name: string;
  size?: string;
}

const WarehouseSelectLabel = ({ name, size }: WarehouseSelectLabelProps) => {
  return (
    <Box color="inherit" fontWeight="inherit" lineHeight="20px">
      {name}&nbsp;&nbsp;
      <Box as="span" color="gray.400" fontWeight="semibold" lineHeight="inherit">
        {size}
      </Box>
    </Box>
  );
};

export default WarehouseSelectLabel;
