import type { CacheKeyType } from '@api/allCacheKeys';
import allCacheKeys from '@api/allCacheKeys';
import fetchClient from '@lib/fetchClient';

const cancelQueries = (cb: (args: CacheKeyType) => (string | undefined)[][]) => {
  const cacheKeys = cb(allCacheKeys);

  cacheKeys.forEach(([key]) => {
    fetchClient.cancelQueries({ queryKey: key });
  });
};

export default cancelQueries;
