import React from 'react';
import copy from 'copy-to-clipboard';

import Button from '@components/Button/Button';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import { LookMLProjectModel } from '@models/LookMLProjectModel';

interface Props {
  project: LookMLProjectModel;
}

const SshPublicKey: React.FC<Props> = React.memo(({ project }) => {
  const segment = useSegmentContext();
  const [copyText, setCopyText] = React.useState<'Copy Key' | 'Copied'>('Copy Key');

  const copyKey = () => {
    segment?.track(SegmentTrackEventName.ImportData_LookmlConnectSSHKeyCopyButtonClicked);
    copy(project.repository.publicKey || '');
    setCopyText('Copied');
    setTimeout(() => {
      setCopyText('Copy Key');
    }, 1000);
  };

  return (
    <Button compSize="sm" onClick={copyKey}>
      {copyText}
    </Button>
  );
});

export default SshPublicKey;
