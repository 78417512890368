import React from 'react';

import type { DashboardModel } from '@api/dashboards/DashboardModel';
import Popper from '@components/Popper';
import Popularity from '@components/Popularity';
import Icon from '@components/UI/Icon';
import getTextWidth from '@utils/getTextWidth';
import truncateDescription from '@utils/truncateDescription';

import TooltipBox from '../Tooltip/TooltipBox';
import TooltipBoxValue from '../Tooltip/TooltipBoxValue';

import {
  StyledScatterChartItem,
  StyledScatterChartItemCircle,
  StyledScatterChartItemText,
} from './ScatterChartItem.styles';

export interface ScatterChartItemProps {
  cx: number;
  cy: number;
  fill: string;
  open?: string;
  payload: DashboardModel;
  setOpen: (value: string) => void;
  width: number;
}

const ScatterChartItem = ({
  cx,
  cy,
  fill,
  open,
  payload,
  setOpen,
  width,
}: ScatterChartItemProps) => {
  const name = truncateDescription(payload.name, 30);
  const nameWidth = getTextWidth(name) + 16 + 8;

  return (
    <foreignObject height="4rem" width={nameWidth} x={cx - nameWidth / 2} y={cy - width / 2}>
      <StyledScatterChartItem>
        <Popper
          anchorComp={
            <StyledScatterChartItemCircle
              backgroundColor={fill}
              onMouseLeave={() => {
                setOpen('');
              }}
              onMouseOver={() => {
                setOpen(payload.guid);
              }}
            />
          }
          isOpen={payload.guid === open}
          offset={[0, 2]}
          placement="right-start"
        >
          <TooltipBox>
            <TooltipBoxValue divisor={false} value={payload.name} />
            <TooltipBoxValue name="Credit" value={payload.formattedTotalCreditsUsed} />
            <TooltipBoxValue
              name="Popularity"
              value={
                <Popularity
                  priority={payload.popularity?.formattedPopularity}
                  text={`Popularity score: ${payload.popularity?.popularity}`}
                />
              }
            />
          </TooltipBox>
        </Popper>
        <StyledScatterChartItemText>
          <Icon name={payload.dataTypes?.icons.dataSource!} size="16px" />
          {truncateDescription(payload.name, 30)}
        </StyledScatterChartItemText>
      </StyledScatterChartItem>
    </foreignObject>
  );
};

export default ScatterChartItem;
