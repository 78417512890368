import React, { useState } from 'react';

import TabsRouter from '@components/TabsRouter';

import { defaultAnalyticsRange } from '../../Analytics.constants';

import AnalyticsUsersNewUsers from './AnalyticsUsersNewUsers';
import AnalyticsUsersTab from './AnalyticsUsersTab';
import AnalyticsUsersTopContributors from './AnalyticsUsersTopContributors';
import AnalyticsUsersTopUsers from './AnalyticsUsersTopUsers';

const AnalyticsUsers: React.FC = () => {
  const [dateRange, setDateRange] = useState<[string, string]>(defaultAnalyticsRange);

  const tabsRouterConfig = [
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <AnalyticsUsersTab dateRange={dateRange} onDateRangeChange={setDateRange} />,
      contentOffset: 2,
      default: true,
      label: 'Overview',
      route: '/overview',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => (
        <AnalyticsUsersTopUsers dateRange={dateRange} onDateRangeChange={setDateRange} />
      ),
      contentOffset: 2,
      label: 'Top Active Users',
      route: '/top-users',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => (
        <AnalyticsUsersTopContributors dateRange={dateRange} onDateRangeChange={setDateRange} />
      ),
      contentOffset: 2,
      label: 'Top Contributors',
      route: '/top-contributors',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => (
        <AnalyticsUsersNewUsers dateRange={dateRange} onDateRangeChange={setDateRange} />
      ),
      contentOffset: 2,
      label: 'New Users',
      route: '/new-users',
    },
  ];

  return <TabsRouter config={tabsRouterConfig} subTabs />;
};

export default AnalyticsUsers;
