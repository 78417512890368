import styled from '@emotion/styled';

import Box from '@components/Box';

export const StyledOption = styled(Box)``;

StyledOption.defaultProps = {
  alignItems: 'center',
  compDisplay: 'flex',
  gap: 1,
};

interface StyledOptionContentProps {
  breakText?: boolean;
  wrapOptionText?: boolean;
}

const OVERFLOW_ELLIPSIS_PROPS = `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledOptionContent = styled(Box)<StyledOptionContentProps>`
  line-height: ${({ theme }) => theme.space(2.5)};
  word-break: ${({ breakText }) => (breakText ? 'break-word' : 'initial')};

  ${({ wrapOptionText }) =>
    !wrapOptionText &&
    `
    ${OVERFLOW_ELLIPSIS_PROPS}
    & > * {
      ${OVERFLOW_ELLIPSIS_PROPS}
    }
  `}
`;

StyledOptionContent.defaultProps = {
  compWidth: '100%',
};
