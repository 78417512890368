import { ActivityModel } from '@api/activity/ActivityModel';
import type { UseFetchOptions } from '@hooks/useFetch';
import useFetch from '@hooks/useFetch';
import { PaginatedResponse } from '@models/PaginatedResponse';

import paginatedTransform from '../paginatedTransform';

import * as cacheKeys from './cacheKeys';

const fetchActivitySelect = paginatedTransform(ActivityModel);
export const useFetchActivity = (options?: UseFetchOptions<PaginatedResponse<ActivityModel>>) => {
  return useFetch<PaginatedResponse<ActivityModel>>({
    ...options,
    queryKey: [...cacheKeys.all, options?.params],
    select: fetchActivitySelect,
    url: '/activity/',
  });
};
