import { Editor, Element, Node, Path, Text, Transforms } from 'slate';

import { CustomElement } from '../RichTextEditor.types';

import { toggleBlock } from './blockHelpers';

/**
 * Handles breaking out of blocks if the block is at the end of the document.
 * Without this there is no way to escape the last block in a document.
 */
export const handleArrowDown = (editor: Editor) => {
  const BREAKOUT_BLOCKS = ['codeblock', 'blockquote'];
  const { selection } = editor;
  if (selection) {
    // Find if we are inside one of the blocktypes we need to break out of.
    const [nodeMatch] = Array.from(
      Editor.nodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) && Element.isElement(n) && BREAKOUT_BLOCKS.includes(n.type),
      }),
    );

    const selectionPath = Editor.path(editor, selection);
    const nodeEnd = Editor.end(editor, nodeMatch?.[1]);
    const documentEnd = Editor.end(editor, []).path;

    /**
     * Determine if we are at the end of the type of block we need to breakout of
     * and also at the end of the document.
     *
     */
    const shouldBreakout =
      selectionPath &&
      nodeEnd &&
      documentEnd &&
      Path.equals(nodeEnd?.path, selectionPath) &&
      Path.equals(documentEnd, selectionPath);

    if (shouldBreakout) {
      // Insert a new empty paragraph after our current node.
      const [, parentPath] = Editor.parent(editor, selection?.focus?.path || []);
      Transforms.insertNodes(
        editor,
        {
          children: [{ text: '' }],
          type: 'paragraph',
        },
        {
          select: true,
          at: Path.next(parentPath),
        },
      );
      toggleBlock(editor, (nodeMatch[0] as CustomElement).type);
    }
  }
};

/**
 * Adds functionality to breakout of inline code by pressing right arrow.
 */
export const handleArrowRight = (editor: Editor) => {
  const BREAKOUT_STYLES = ['code', 'mention'];
  const { selection } = editor;
  if (selection) {
    // Find if we are inside one of the styles we need to break out of.
    const [nodeMatch] = Array.from(
      Editor.nodes(editor, {
        match: (node) => {
          if (Text.isText(node)) {
            return BREAKOUT_STYLES.some((style) => style in node);
          }
          if (Element.isElement(node)) {
            return BREAKOUT_STYLES.some((style) => node.type === style);
          }
          return false;
        },
      }),
    );

    const selectionPoint = Editor.point(editor, selection);
    const nodeEnd = Editor.end(editor, nodeMatch?.[1]);
    const nextNode = Editor.next(editor, { at: nodeMatch?.[1] })?.[0];
    const doesNextNodeHaveContent = nextNode && !!Node.string(nextNode);

    /**
     * Determine if we are at the end of the style we need to breakout of and there is no content after this point.
     * If so we will insert a new empty text node to the right and select it, effectively breakingout of style.
     *
     */
    const shouldBreakout =
      !doesNextNodeHaveContent &&
      selectionPoint &&
      nodeEnd &&
      Path.equals(nodeEnd?.path, selectionPoint.path) &&
      selectionPoint.offset === nodeEnd.offset;

    if (shouldBreakout) {
      // Insert a new empty span after our current node.
      Transforms.insertNodes(
        editor,
        { type: 'span', children: [{ text: ' ' }] },
        {
          select: true,
          at: nodeEnd,
        },
      );
    }
  }
};
