import React, { useState } from 'react';

import invalidateCache from '@api/invalidateCache';
import { usePatchMyUser } from '@api/user';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import CenterAlignedBox from '@components/CenterAlignedBox';
import TermsOfService from '@components/TermsOfService';
import Title from '@components/Title/Title';
import { renderErrorToast } from '@components/Toast';
import Checkbox from '@components/UI/Form/Checkbox';
import InputLabel from '@components/UI/Form/InputLabel';

interface AcceptTOSProps {
  onSuccess: () => void;
}

const AcceptTOS: React.FC<AcceptTOSProps> = ({ onSuccess }) => {
  const patchQuery = usePatchMyUser({
    onError: () => {
      renderErrorToast('There was an error. Please try again');
    },
    onSuccess: async () => {
      invalidateCache((keys) => [keys.organizationUser.organizationUser('me'), keys.user.me]);
      onSuccess();
    },
  });
  const [checked, setChecked] = useState<boolean>(false);

  const acceptTOS = () => {
    patchQuery.mutate({ is_agreed_to_terms: true });
  };

  return (
    <CenterAlignedBox maxWidth="460px" minWidth="350px">
      <Box alignItems="flex-start" compWidth="100%" mb={1.5}>
        <Title showLogo>Before Sign In</Title>
      </Box>
      <Box mb={3}>
        <InputLabel fontSize="body1">
          <Checkbox checked={checked} onChange={() => setChecked((prev) => !prev)} />
          <TermsOfService />
        </InputLabel>
      </Box>
      <Button disabled={!checked} onClick={acceptTOS}>
        Sign In
      </Button>
    </CenterAlignedBox>
  );
};

export default AcceptTOS;
