import React from 'react';

import { TaggedItemModel } from '@api/tags/TaggedItemModel';
import AppTag from '@components/AppTag';
import Box from '@components/Box';
import { useUserContext } from '@context/User';

import { TypedCell } from './types';

interface TaggedItemCellProps {
  ignoreLinksTo?: (item: TaggedItemModel) => boolean;
  value?: TaggedItemModel[];
}

const TaggedItemCell = React.memo<TaggedItemCellProps & TypedCell>((props) => {
  const { column, globalFilter, ignoreLinksTo, value } = props;

  const { organization } = useUserContext();
  const showSuggestedTags = organization?.settings?.useSuggestedTags;
  const items = value?.filter(
    (item: TaggedItemModel) =>
      (item.kind === 'suggestion' && showSuggestedTags) || item.kind === 'user-defined',
  );

  if (!value || value.length === 0) {
    return null;
  }

  return (
    <Box as="span" overflowX="hidden" overflowY="hidden" textOverflow="ellipsis">
      {items?.map((taggedItem: TaggedItemModel) => (
        <AppTag
          key={taggedItem.tag.guid}
          ignoreLinksTo={ignoreLinksTo?.(taggedItem)}
          item={taggedItem}
          searchTermsToHighlight={globalFilter || column.filterValue}
          showBreadcrumbs
          tag={taggedItem.tag}
          variant="outlined"
        />
      ))}
    </Box>
  );
});

export default React.memo(TaggedItemCell);
