import React from 'react';

import useCollapseAllNodes from '@components/LineageExplore/useCollapseAllNodes';
import { useLineageExplore } from '@components/LineageExplore/useLineageExplore';

import { StyledCollapseAllNodesButton } from './CollapseAllNodesButton.styles';

const CollapseAllNodesButton = () => {
  const { isCollapseAllButtonEnabled } = useLineageExplore();
  const { collapseAllNodes } = useCollapseAllNodes();

  const handleCollapseAllNodes = () => {
    collapseAllNodes();
  };

  return (
    <StyledCollapseAllNodesButton
      disabled={!isCollapseAllButtonEnabled}
      onClick={handleCollapseAllNodes}
      variant="outlined"
    >
      Collapse Nodes
    </StyledCollapseAllNodesButton>
  );
};

export default CollapseAllNodesButton;
