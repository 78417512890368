import { css } from '@emotion/react';
import styled from '@emotion/styled';

import type { StyledBoxProps } from '@components/Box';
import Box from '@components/Box';

import type { CheckboxState, VariantColor, VariantRadius } from './Checkbox.styles.variants';
import { colorVariants, radiusVariants } from './Checkbox.styles.variants';

export interface StyledCheckboxProps extends StyledBoxProps {
  disabled?: boolean;
  state?: CheckboxState;
  variantColor?: VariantColor;
  variantRadius?: VariantRadius;
}

export const StyledCheckbox = styled(Box)<StyledCheckboxProps>`
  align-items: center;
  border: 2px solid;
  border-radius: ${({ variantRadius = 'squared' }) => radiusVariants[variantRadius]};
  display: flex;
  height: 1rem;
  justify-content: center;
  width: 1rem;
  color: white;
  transition: all 100ms ease, outline-color 300ms ease;
  outline: 4px solid transparent;
  cursor: pointer;
  flex-shrink: 0;

  &:active {
    outline-color: ${({ theme }) => theme.colors.v1.primary[300]}50;
  }

  ${({ disabled, state = 'default', variantColor = 'purple' }) => {
    const mappedVariant = colorVariants[variantColor][state];
    return css`
      ${disabled ? mappedVariant.disabled : mappedVariant.default}
      &:hover {
        ${!disabled && mappedVariant.hover};
      }
    `;
  }};
`;

StyledCheckbox.defaultProps = {
  className: 'Checkbox',
  noDefault: true,
};
