export const all = ['analytics', 'activities'];

export const activitiesCsv = [...all, 'csv'];

export const activitiesGrouped = [...all, 'grouped'];

export const organizationMetrics = [...all, 'organization', 'metrics'];

export const searches = [...all, 'searches'];

export const usersActive = [...all, 'users', 'active'];

export const usersContributing = [...all, 'users', 'contributing'];

export const usersNew = [...all, 'users', 'new'];

export const usersTotalCount = [...all, 'users', 'totalCount'];

export const schemaChanges = [...all, 'schema-changes'];

export const schemaChangesCsv = [...all, 'schemaChanges', 'csv'];
