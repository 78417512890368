import React from 'react';

import Box from '@components/Box';
import LayoutMainGrid from '@components/Layout/LayoutMainGrid';
import TitleHeader from '@components/Title/TitleHeader';
import MetadataDecorator from '@utils/MetadataDecorator';

import AdminCustomAttributes from './AdminCustomAttributes';

const AdminCustomAttributePage: React.FC = () => {
  return (
    <>
      <MetadataDecorator title="Custom Attribute" />
      <LayoutMainGrid>
        <Box mb={5}>
          <TitleHeader>
            <span className="title big">Custom Attribute</span>
          </TitleHeader>
        </Box>
        <AdminCustomAttributes />
      </LayoutMainGrid>
    </>
  );
};

export default AdminCustomAttributePage;
