import React, { useEffect } from 'react';
import { useLocation } from '@routing/router';
import { useIntercom } from 'react-use-intercom';

import { useSegmentContext } from '@context/Segment';
import flags from '@features';

const PublicBoot: React.FC = ({ children }) => {
  const segment = useSegmentContext();
  const { boot, shutdown } = useIntercom();
  const location = useLocation();

  useEffect(() => {
    if (flags.enable_intercom) {
      boot();
    }

    return () => {
      if (flags.enable_intercom) {
        shutdown();
      }
    };
  }, []);

  useEffect(() => {
    segment?.reload();
  }, [location.pathname, segment]);

  return <>{children}</>;
};

export default PublicBoot;
