import { useEffect, useRef } from 'react';

export type IgnoreElements = 'legacy-modal' | 'modal' | 'tooltip' | 'select';
interface UseClickOutsideArgs {
  disabled?: boolean;
  excludedTargets?: () => NodeListOf<Element> | HTMLElement[] | null | undefined;
  ignoreElements?: IgnoreElements[];
  onClick: (e: MouseEvent) => void;
}

const ignoreElementQuery = {
  'legacy-modal': () => document.getElementsByClassName('ui modals visible active'),
  modal: () => document.querySelectorAll("[role='dialog']"),
  select: () => document.querySelectorAll("[role='listbox']"),
  tooltip: () => document.querySelectorAll("[role='tooltip']"),
};

const useClickOutside = <T extends HTMLElement>({
  disabled,
  excludedTargets,
  ignoreElements = [],
  onClick,
}: UseClickOutsideArgs) => {
  const ref = useRef<T>(null);

  useEffect(() => {
    if (!ref?.current) {
      return;
    }

    const handleClickOutside = (e: MouseEvent) => {
      const clickedOnAnIgnoredElement =
        ignoreElements.filter((ignoreElement) => {
          return Array.from(ignoreElementQuery[ignoreElement]()).some((element) =>
            element.contains(e.target as Node),
          );
        }).length > 0;

      if (clickedOnAnIgnoredElement) {
        return;
      }

      if (excludedTargets) {
        const excludedTargetsElements = excludedTargets();
        const isExcludedTarget =
          excludedTargetsElements &&
          Array.from(excludedTargetsElements).find((element) => element.contains(e.target as Node));

        if (isExcludedTarget) {
          return;
        }
      }

      if (onClick && !ref?.current?.contains(e.target as Node)) {
        onClick(e);
      }
    };

    if (!disabled) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClick, excludedTargets, disabled, ignoreElements]);

  return ref;
};

export default useClickOutside;
