import moment from 'moment';

import { TokenResponseType } from '@api';

export const mask = (data: TokenResponseType) => {
  // copy over masker tokens
  localStorage.setItem('Masker-Access-Token', localStorage.getItem('Access-Token') || '');
  localStorage.setItem('Masker-Access-Token-Time', localStorage.getItem('Access-Token-Time') || '');
  localStorage.setItem('Masker-Refresh-Token', localStorage.getItem('Refresh-Token') || '');
  localStorage.setItem(
    'Masker-defaultOrganization',
    localStorage.getItem('defaultOrganization') || '',
  );
  localStorage.setItem('Masker-organizations', localStorage.getItem('organizations') || '');
  localStorage.setItem('Masker-isStaff', localStorage.getItem('isStaff') || '');
  localStorage.setItem('Masker-isSuperUser', localStorage.getItem('isSuperUser') || '');

  // set masked tokens
  localStorage.setItem('Access-Token', data.access);
  localStorage.setItem('Access-Token-Time', moment().toISOString());
  localStorage.setItem('Refresh-Token', data.refresh);
  localStorage.setItem('defaultOrganization', data.default_organization || '');
  localStorage.setItem('organizations', JSON.stringify(data.organizations));
  localStorage.setItem('isStaff', String(data.is_staff));
  localStorage.setItem('isSuperUser', String(data.is_superuser));
};

export const isMasked = () => {
  const access = localStorage.getItem('Masker-Access-Token');
  const refresh = localStorage.getItem('Masker-Refresh-Token');
  return !!access && !!refresh;
};
