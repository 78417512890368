import styled from '@emotion/styled';
import * as CSS from 'csstype';

import { Breakpoint } from '@styles/theme/breakpoints';

interface GridContainerProps {
  compDisplay?: CSS.Property.Display;
  compHeight?: CSS.Property.Height;
  flexDirection?: CSS.Property.FlexDirection;
  fluid?: boolean;
  hPaddingSpace?: number;
  maxWidth?: Breakpoint;
  position?: 'static' | 'absolute' | 'relative' | 'fixed' | 'sticky';
  vPaddingSpace?: number;
}

const GridContainer = styled.div<GridContainerProps>`
  margin: 0 auto;
  max-width: ${({ fluid = false, maxWidth = 'lg', theme }) =>
    fluid ? '100%' : `${theme.breakpoints[maxWidth]}px`};
  padding: ${({ hPaddingSpace = 2, theme, vPaddingSpace = 0 }) =>
    theme.space(vPaddingSpace, hPaddingSpace)};
  position: ${({ position = 'static' }) => position};
  width: 100%;
  height: ${({ compHeight = '100%' }) => compHeight};
  flex-direction: ${({ flexDirection }) => flexDirection};
  display: ${({ compDisplay }) => compDisplay};
  min-width: 0;
`;

export default GridContainer;
