import { Direction } from '@components/ExploreTree/types';
import {
  ExploreEdge,
  ExploreNode,
  InputNodesById,
} from '@components/LineageExplore/LineageExplore.types';
import {
  CreateStacksResult,
  EdgesById,
  NodesById,
} from '@components/LineageExplore/useCreateNodesEdges/algorithm/types';
import { createShowMoreId } from '@components/LineageExplore/useCreateNodesEdges/utils/createShowMore';
import { useLineageExplore } from '@components/LineageExplore/useLineageExplore';

import { cloneLineageData } from '../LineageExplore.utils';
import { getOccupiedColumnsByNode } from '../useCreateNodesEdges/algorithm/algorithm.utils';
import useShowLoading from '../useShowLoading';

interface EnableFocusLineageParams {
  edges?: ExploreEdge[];
  inputNodesById?: InputNodesById;
  nodeKey: string;
  nodes?: ExploreNode[];
  stacksData: CreateStacksResult;
}

interface FindConnectedNodes {
  inputNodesById?: InputNodesById;
  nodeKey: string;
  stacksData?: CreateStacksResult;
}

interface EnableFocusLineageParams {
  edgesById: EdgesById;
  inputNodesById?: InputNodesById;
  nodeKey: string;
  nodesById: NodesById;
  stacksData: CreateStacksResult;
}

const MULTI_STACK_NODE_TYPES = ['bi'];

const useFocusLineage = () => {
  const {
    focusedNodeKey,
    getAllParents,
    setEdgesById,
    setFocusedNodeKey,
    setNodesById,
    setStacksData,
  } = useLineageExplore();
  const { withLoading } = useShowLoading();

  const findConnectedNodes = ({ inputNodesById, nodeKey, stacksData }: FindConnectedNodes) => {
    const connectedNodes: { [key: string]: boolean } = {};

    const seenIds = {} as { [id: string]: boolean };

    const recursiveFindConnectedNodes = (key: string, currDirection: Direction) => {
      if (!seenIds[key]) {
        const inputNode = inputNodesById?.[key];
        seenIds[key] = true;

        if (inputNode) {
          connectedNodes[key] = true;

          const parents = getAllParents(key, stacksData);

          (parents ?? []).forEach((parent) => {
            connectedNodes[parent.key!] = true;
            const parentShowMoreId = createShowMoreId(parent.key!);
            if (MULTI_STACK_NODE_TYPES.includes(parent.type!)) {
              const occupiedStacks = getOccupiedColumnsByNode(parent);
              occupiedStacks.forEach((stack) => {
                connectedNodes[`${stack}-${parentShowMoreId}`] = true;
              });
            } else {
              connectedNodes[parentShowMoreId] = true;
            }
          });

          if (currDirection !== 'right') {
            Object.keys(inputNode?.source_edges ?? {})?.forEach((node) =>
              recursiveFindConnectedNodes(node, 'left'),
            );
          }

          if (currDirection !== 'left') {
            Object.keys(inputNode?.target_edges ?? {})?.forEach((node) =>
              recursiveFindConnectedNodes(node, 'right'),
            );
          }
        }
      }
    };

    recursiveFindConnectedNodes(nodeKey, 'both');

    return connectedNodes;
  };

  const enableFocusLineage = ({
    edgesById,
    inputNodesById,
    nodeKey,
    nodesById,
    stacksData,
  }: EnableFocusLineageParams) => {
    const connectedNodes = findConnectedNodes({ inputNodesById, nodeKey, stacksData });

    const newStacksData = cloneLineageData(stacksData);
    const newNodesById = cloneLineageData(nodesById);
    const newNodesKeys = Object.keys(newNodesById);

    for (let i = 0; i < newNodesKeys.length; i += 1) {
      const node = newNodesById[newNodesKeys[i]];
      if (node.key) {
        const parentNodeKey = node.parentNode;

        if (!connectedNodes[node.key]) {
          newNodesById[newNodesKeys[i]].data.isUnfocused = true;

          if (newStacksData?.nodesById[newNodesKeys[i]]) {
            newStacksData.nodesById[newNodesKeys[i]].data.isUnfocused = true;
          }
        } else {
          if (newStacksData?.nodesById[newNodesKeys[i]]) {
            newStacksData.nodesById[newNodesKeys[i]].data.isUnfocused = false;
          }

          newNodesById[newNodesKeys[i]].data.isUnfocused = false;
        }

        if (parentNodeKey) {
          const parentNode = stacksData?.nodesById[parentNodeKey];

          /*
           * If node is inside a table that is connected, then it should be focused
           * The node can be a column or show more
           */
          if (connectedNodes[parentNodeKey] && parentNode?.type === 'table') {
            if (newStacksData?.nodesById[newNodesKeys[i]]) {
              newStacksData.nodesById[newNodesKeys[i]].data.isUnfocused = false;
            }
            newNodesById[newNodesKeys[i]].data.isUnfocused = false;
          }
        }
      }
    }

    const newEdgesById = cloneLineageData(edgesById);
    const newEdgesKeys = Object.keys(newEdgesById);

    for (let i = 0; i < newEdgesKeys.length; i += 1) {
      const edge = newEdgesById[newEdgesKeys[i]];

      if (!connectedNodes[edge.source] || !connectedNodes[edge.target]) {
        newEdgesById[newEdgesKeys[i]].data!.isUnfocused = true;
      } else {
        newEdgesById[newEdgesKeys[i]].data!.isUnfocused = false;
      }
    }

    // stacksData.edgesById and edgesById have the same content
    if (newStacksData?.edgesById) {
      newStacksData.edgesById = newEdgesById;
    }

    setFocusedNodeKey(nodeKey);
    setNodesById(newNodesById);
    setEdgesById(newEdgesById);
    setStacksData(newStacksData);
  };
  const disableFocusedLineage = ({
    edgesById,
    nodesById,
    stacksData,
  }: {
    edgesById: EdgesById;
    nodesById: NodesById;
    stacksData: CreateStacksResult;
  }) => {
    if (focusedNodeKey) {
      const newEdgesById = cloneLineageData(edgesById);
      const newEdgesKeys = Object.keys(newEdgesById);

      for (let i = 0; i < newEdgesKeys.length; i += 1) {
        newEdgesById[newEdgesKeys[i]].data!.isUnfocused = false;
      }

      const newStacksData = cloneLineageData(stacksData);

      const newNodesById = cloneLineageData(nodesById);
      const newNodesKeys = Object.keys(newNodesById);

      for (let i = 0; i < newNodesKeys.length; i += 1) {
        newNodesById[newNodesKeys[i]].data.isUnfocused = false;
        if (newStacksData?.nodesById[newNodesKeys[i]]) {
          newStacksData.nodesById[newNodesKeys[i]].data.isUnfocused = false;
        }
      }

      // stacksData.edgesById and edgesById have the same content
      if (newStacksData?.edgesById) {
        newStacksData.edgesById = newEdgesById;
      }

      setFocusedNodeKey('');
      setNodesById(newNodesById);
      setEdgesById(newEdgesById);
      setStacksData(newStacksData);
    }
  };

  return {
    disableFocusedLineage: withLoading(disableFocusedLineage),
    enableFocusLineage: withLoading(enableFocusLineage),
  };
};

export default useFocusLineage;
