import React, { forwardRef } from 'react';
import { useHistory, useRouteMatch } from '@routing/router';

import type { TooltipProps } from '@components/Tooltip';
import type DataTypesModel from '@models/DataTypesModel';

import ScaleIndicator from '../ScaleIndicator';

const PRIORITY_COLORS = [
  { color: '#e2e9f0', id: 1 },
  { color: '#e2e9f0', id: 2 },
  { color: '#7490be', id: 3 },
  { color: '#7490be', id: 4 },
  { color: '#0c3476', id: 5 },
  { color: '#0c3476', id: 6 },
];
export const NO_POPULARITY_OBJECTS = ['document', 'metric', 'term', 'tag'];
export interface PopularityProps {
  className?: string;
  compWidth?: string;
  dataTypes?: DataTypesModel;
  priority?: number;
  text?: TooltipProps['content'];
  topUsersLink?: boolean;
}

const Popularity = forwardRef<HTMLSpanElement, PopularityProps>(
  ({ className, compWidth, dataTypes, priority, text, topUsersLink, ...other }, ref) => {
    const history = useHistory();
    const { url } = useRouteMatch();

    const handleClick = () => {
      if (topUsersLink) {
        history.push(url.replace('/overview', '/topusers'));
      }
    };

    if (NO_POPULARITY_OBJECTS.includes(dataTypes?.objectType!)) {
      return null;
    }

    return (
      <ScaleIndicator
        {...other}
        ref={ref}
        className={className}
        colors={PRIORITY_COLORS}
        compWidth={compWidth}
        onClick={handleClick}
        priority={priority}
        style={topUsersLink ? { cursor: 'pointer' } : {}}
        testId="popularity"
        tooltipText={text}
      />
    );
  },
);

export default Popularity;
