import styled from '@emotion/styled';

export const StyledTableList = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  * {
    outline: none;
  }

  &.keyUser li.focused {
    outline: 1px dotted gray;
  }

  & span:not(.usage-label) {
    margin: 1px 0 0 0.25rem;
    font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
    font-size: 0.8125rem;
    font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #2c2e36;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.simple {
    margin: 0;
    border: solid 0px #e2e8f0;
    height: 100%;

    & span {
      white-space: nowrap;

      &.object-type {
        text-transform: capitalize;
      }
    }
  }

  &.with-padding {
    border: solid 1px #e2e8f0;
    padding: 0.375rem 1rem;
    margin: 0.5rem 0;
    height: 100%;
    max-height: 60vh;
  }

  &.full-height {
    max-height: 100%;
  }
`;
