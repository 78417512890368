import React from 'react';
import { useTheme } from '@emotion/react';

import Box from '@components/Box';
import Hidden from '@components/Hidden';
import SearchBar from '@components/SearchBar';
import useStickyContext from '@components/Sticky';
import TopBanner from '@components/TopBanner';
import { useUserContext } from '@context/User';

import { LogoImg, LogoLink, StyledAppHeader } from './AppHeader.styles';
import ProfileMenuList from './ProfileMenuList';

interface HeaderProps {
  disableAccess?: boolean;
}

const AppHeader: React.FC<HeaderProps> = ({ disableAccess = false }) => {
  const { organization } = useUserContext();
  const { zIndex } = useTheme();
  const stickyProps = useStickyContext({ id: 'AppHeader' });

  return (
    <Box {...stickyProps} zIndex={zIndex.header}>
      {organization?.showBanner && (
        <TopBanner
          daysLeft={
            organization.subscriptionEndsAt
              ? organization.daysLeftInSubscription
              : organization?.daysLeftInTrial
          }
          isSubscriptionEndingBanner={!!organization.subscriptionEndsAt}
        />
      )}
      <StyledAppHeader as="header" spacing={3}>
        <LogoLink disableAccess={disableAccess} to="/">
          <>
            <Hidden hideDown={['md']}>
              <LogoImg alt="logo" compHeight="20px" src="/icons/logo-ss-horizontal-white.svg" />
            </Hidden>
            <Hidden hideUp={['md']}>
              <LogoImg alt="logo small" compHeight="24px" src="/icons/logo-ss-sign.svg" />
            </Hidden>
          </>
        </LogoLink>
        <Box flexGrow={1}>{!disableAccess && <SearchBar />}</Box>
        <ProfileMenuList />
      </StyledAppHeader>
    </Box>
  );
};

export default AppHeader;
