import { Expose, Type } from 'class-transformer';

import { UserModel } from '@api/user/UserModel';

export type PiiType =
  | 'address'
  | 'banking'
  | 'birthdate'
  | 'document'
  | 'email'
  | 'face_photo'
  | 'gender'
  | 'marital_status'
  | 'nationality'
  | 'online_account'
  | 'online_address'
  | 'password'
  | 'person'
  | 'phone'
  | 'salary'
  | 'ssn'
  | 'username';

export interface PiiRulePayload {
  active?: boolean;
  created_by?: any;
  guid?: string;
  item_count?: number;
  name?: string;
  regex?: string;
  type?: string;
}

export class PiiRuleModel {
  static objectType: 'piirule' = 'piirule';

  objectType: 'piirule' = PiiRuleModel.objectType;

  static typeDisplay: string = 'PII Rule';

  typeDisplay: string = PiiRuleModel.typeDisplay;

  guid!: string;

  name!: string;

  active!: boolean;

  type!: PiiType;

  regex!: string;

  @Expose({ name: 'created_by' })
  @Type(() => UserModel)
  createdBy!: UserModel;

  @Expose({ name: 'item_count' })
  itemCount!: number;
}
