import React from 'react';
import { UseMutationResult } from 'react-query';

import invalidateCache from '@api/invalidateCache';
import { usePatchMetadataDescriptionsAndOwners } from '@api/metadata';
import { usePatchTag } from '@api/tags';
import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import IconButton from '@components/IconButton';
import type { UsePatchUpdateOwnersProps } from '@components/Modal/BulkEditOwnersModal/BulkEditOwnersModal.types';
import type { OwnerType } from '@components/Owners/OwnerItem';
import PageLink from '@components/PageLink';
import { renderInfoToast } from '@components/Toast';
import Avatar from '@components/UI/Avatar';
import { Option, SelectValue } from '@components/UI/Select';
import theme from '@styles/theme';

import BaseOwnerSelect from './BaseOwnerSelect';
import { OwnerDataParamFormatter, OwnerSelectItem } from './types';
import useDefaultOptions from './useDefaultOptions';
import {
  getBusinessOwnerDataParams,
  getTagOwnerDataParams,
  getTechnicalOwnerDataParams,
} from './utils';

type OwnerUpdateType = 'business' | 'technical' | 'tag';

export interface OwnerSelectProps {
  hasEditPermissions: boolean;
  items: OwnerSelectItem[];
  itemsType: UsePatchUpdateOwnersProps['itemsType'];
  owner?: OwnerType | null;
  ownerUpdateType: OwnerUpdateType;
  reloadData?: () => void;
  showNoOwnerOption?: boolean;
}

const OwnerSelect: React.FC<OwnerSelectProps> = ({
  hasEditPermissions,
  items,
  owner,
  ownerUpdateType,
  reloadData,
  showNoOwnerOption = true,
}) => {
  const {
    defaultValue,
    isLoading: isLoadingDefaultOptions,
    options: defaultOptions,
  } = useDefaultOptions({
    currentOwner: owner,
    items,
  });

  const onQuerySuccess = () => {
    renderInfoToast('Changes saved.');
    invalidateCache((keys) => [keys.metadata.all, keys.activity.all]);
    reloadData?.();
  };

  const metadataOwnersUpdate = usePatchMetadataDescriptionsAndOwners({
    onSuccess: onQuerySuccess,
  });

  const tagUpdateQuery = usePatchTag(items?.[0]?.guid, {
    onSuccess: onQuerySuccess,
  });

  const typeQueryMap: Record<OwnerUpdateType, UseMutationResult<any, any, any>> = {
    business: metadataOwnersUpdate,
    tag: tagUpdateQuery,
    technical: metadataOwnersUpdate,
  };

  const { isLoading: isSavingOwner, mutate: updateOwner } = typeQueryMap[ownerUpdateType];

  const handleOwnerChange = (value: SelectValue) => {
    const [selectedItem] = value as Option[];
    const newValue = selectedItem.value === owner?.guid ? '' : String(selectedItem.value);

    const formatterByOwnerUpdateType: Record<OwnerUpdateType, OwnerDataParamFormatter> = {
      business: getBusinessOwnerDataParams,
      tag: getTagOwnerDataParams,
      technical: getTechnicalOwnerDataParams,
    };

    const formatter = formatterByOwnerUpdateType[ownerUpdateType];
    const data = formatter({ items, newValue });

    updateOwner(data);
  };

  const isLoading = isLoadingDefaultOptions || isSavingOwner;

  return (
    <BaseOwnerSelect
      defaultOptions={defaultOptions}
      fitAnchorWidth={false}
      isDisabled={!hasEditPermissions || isLoadingDefaultOptions}
      onChange={handleOwnerChange}
      placeholder="Search for users and teams"
      popperConfigProps={{
        customPopperStyles: {
          marginLeft: -4,
          width: '280px',
        },
        fallbackPlacements: ['bottom-end'],
      }}
      renderCustomAnchor={({ anchorProps, isOpen }) => (
        <Box alignItems="center" compDisplay="flex" gap={0.75} minWidth={0} {...anchorProps}>
          <Box
            alignItems="center"
            compDisplay="flex"
            overflow="hidden"
            pr={0.5}
            whiteSpace="nowrap"
          >
            {owner ? (
              <Box alignItems="center" compDisplay="flex" gap={0.5}>
                <Avatar {...owner.mappedAvatar} size="1.25rem" />
                <PageLink item={owner} />
              </Box>
            ) : (
              hasEditPermissions && <Box color="gray.400">Assign an owner</Box>
            )}
          </Box>
          {hasEditPermissions &&
            (isLoading ? (
              <CircularLoader borderWidth={2} color="gray.500" compSize={1.75} />
            ) : (
              <IconButton
                backgroundColor={isOpen ? 'gray.100' : 'transparent'}
                iconColor={theme.colors.v1.gray[500]}
                iconName="edit-outline"
                iconSize="16px"
                size="sm"
              />
            ))}
        </Box>
      )}
      showNoOwnerOption={Boolean(defaultValue) && showNoOwnerOption}
      showSearchOnOptions
      value={defaultValue ? [defaultValue] : undefined}
    />
  );
};

export default OwnerSelect;
