import styled from '@emotion/styled';

import Box from '@components/Box';

export const StyledTeamCostChart = styled(Box)`
  .recharts-default-legend {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.space(0.5)};

    .recharts-legend-item {
      margin: 0 !important;
      display: flex !important;
      align-items: center;
      line-height: normal;
    }
  }
`;
