import { Expose } from 'class-transformer';

export type CloudObjectType = 's3';

export type CloudObjectFormat = 'csv' | 'json' | 'unknown';

class CloudObjectModel {
  @Expose({ name: 'cloud_object_type' })
  cloudObjectType!: CloudObjectType;

  @Expose({ name: 'format' })
  format!: CloudObjectFormat;

  @Expose({ name: 'location' })
  location!: string;

  @Expose({ name: 'objects_count' })
  objectsCount!: number;
}

export default CloudObjectModel;
