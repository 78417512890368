import styled from '@emotion/styled';

const SchemaHeaderStyled = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.space(2, 3)};

  .icon {
    margin-right: 0.25rem;
  }
  .name {
    font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
    font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
    font-size: 15px;
    color: #2c2e36;
  }
  .spacer {
    flex-grow: 1;
  }
  .selected-count {
    font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
    font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
    font-size: 13px;
    color: #0c3476;
  }
`;

export default SchemaHeaderStyled;
