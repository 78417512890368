import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Box, { StyledBoxProps } from '@components/Box';
import Resizable from '@components/Resizable';

import { PRIMARY_SIDEBAR_LOGO_CONTAINER_HEIGHT } from '../PrimarySidebar/PrimarySidebar.styles';

interface StyledSecondarySidebarProps {
  currentWidth: number;
  isCollapsed: boolean;
  isVisible: boolean;
}

export const SIDEBAR_EXPANSION_TRANSITION_DURATION = 300;

export const StyledSecondarySidebar = styled(Resizable)<StyledSecondarySidebarProps>`
  background-color: ${({ theme }) => theme.colors.white};
  border-right: 1px solid;
  border-right-color: ${({ isCollapsed, theme }) =>
    isCollapsed ? 'transparent' : theme.colors.gray[200]};
  box-shadow: ${({ isCollapsed, theme }) => (isCollapsed ? 'none' : theme.shadow.md)};
  display: flex;
  flex-direction: column;
  height: 100vh;
  transform: translateX(${({ isVisible }) => (isVisible ? '0%' : '-105%')});
  position: ${({ isVisible }) => (isVisible ? 'relative' : 'absolute !important')};
  ${({ theme }) => theme.media.down('sm')`
    max-width: 200px !important;
  `}
  width: ${({ currentWidth, isCollapsed }) =>
    isCollapsed ? '0px !important' : `${currentWidth}px`};
  transition: width ${SIDEBAR_EXPANSION_TRANSITION_DURATION}ms ease-in-out;
  overflow: ${({ isCollapsed }) => isCollapsed && 'hidden'};
`;

export const StyledSecondarySidebarContainer = styled(Box)``;

StyledSecondarySidebarContainer.defaultProps = {
  compDisplay: 'flex',
  compHeight: '100%',
  flexDirection: 'column',
  flexWrap: 'nowrap',
};

interface StyledSecondarySidebarTitleProps extends StyledBoxProps {
  isSmallTitle?: boolean;
}

export const StyledSecondarySidebarTitle = styled(Box)<StyledSecondarySidebarTitleProps>`
  margin: 0;

  ${({ isSmallTitle }) =>
    isSmallTitle
      ? css`
          align-items: flex-end;
          height: 34px;
        `
      : css`
          align-items: center;
          height: ${PRIMARY_SIDEBAR_LOGO_CONTAINER_HEIGHT - 1}px;
        `}
`;

StyledSecondarySidebarTitle.defaultProps = {
  alignItems: 'center',
  color: 'gray.800',
  compDisplay: 'flex',
  compHeight: `${PRIMARY_SIDEBAR_LOGO_CONTAINER_HEIGHT - 1}px`,
  fontWeight: 'medium',
  lineHeight: '24px',
  m: 0,
  px: 1.5,
};

export const StyledSecondarySidebarContent = styled(Box)``;

StyledSecondarySidebarContent.defaultProps = {
  compDisplay: 'flex',
  flex: 1,
  flexDirection: 'column',
  overflow: 'auto',
};
