import React from 'react';

import Box from '@components/Box';
import Text from '@components/Text';

const ExploreErrorMessage = () => {
  return (
    <Box alignItems="center" compDisplay="flex" compWidth="100%" flexDirection="column" mt={35}>
      <Text fontSize="h1">Sorry, something went wrong</Text>
      <Text fontSize="body1" textAlign="center">
        We had a problem loading the lineage. This error will be automatically reported but please
        let us know if it persists.
      </Text>
    </Box>
  );
};

export default ExploreErrorMessage;
