import { Expose, Transform } from 'class-transformer';
import moment from 'moment';

export class ClientApiTokenModel {
  key?: string;

  @Expose({ name: 'created' })
  @Transform((value) => moment(value))
  created?: moment.Moment;

  @Expose({ name: 'last_activity' })
  @Transform((value) => moment(value))
  lastActivity?: moment.Moment;
}
