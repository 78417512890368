import React from 'react';

import Box from '@components/Box';
import Testimonial from '@components/Testimonial';

import TrialInfo from './TrialInfo';
import { StyledBox } from './TwoColumnDecorativeComponent.styles';

export type CustomBrandOrigin = 'https://block.selectstar.com';

interface CustomBrandConfig {
  img: string;
  imgAltText: string;
}

export const customBrandConfig: {
  [key in CustomBrandOrigin]: CustomBrandConfig;
} = {
  'https://block.selectstar.com': {
    img: '/images/logo-block.svg',
    imgAltText: 'Block logo',
  },
};

interface TwoColumnDecorativeComponentProps {
  showTrialInfo?: boolean;
}

const TwoColumnDecorativeComponent: React.FC<TwoColumnDecorativeComponentProps> = ({
  showTrialInfo = false,
}) => {
  const showColumnData = !Object.keys(customBrandConfig).includes(window.location.origin);
  const brandConfig = showColumnData
    ? undefined
    : customBrandConfig[window.location.origin as CustomBrandOrigin];

  return (
    <StyledBox compDisplay="flex" compHeight="100%" flexDirection="column">
      {showColumnData ? (
        <Box alignItems="center" compDisplay="flex" compHeight="100%">
          {showTrialInfo ? (
            <Box mx={6}>
              <TrialInfo />
            </Box>
          ) : (
            <Box m="auto" maxWidth="440px" p={4}>
              <Testimonial />
            </Box>
          )}
        </Box>
      ) : (
        <Box m="auto" maxWidth="440px" p={4}>
          <img alt={brandConfig?.imgAltText} height="auto" src={brandConfig?.img} width="240px" />
        </Box>
      )}
    </StyledBox>
  );
};

export default React.memo(TwoColumnDecorativeComponent);
