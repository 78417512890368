/* eslint-disable import/prefer-default-export */
import theme from '@styles/theme';

export const colors = {
  axisLabel: theme.colors.v1.gray[600],
  axisText: theme.colors.v1.gray[400],
  grid: theme.colors.v1.gray[200],
  legend: theme.colors.v1.gray[600],
  tooltip: theme.colors.v1.gray[600],
} as const;
