import { useCallback, useEffect, useMemo, useState } from 'react';

import { alphabetically, numerically, sortMomentDates } from './sort';

/**
 * Sorts a given collection of keys
 *
 * depracated use @react-table useSortBy
 *
 */

export type SortDirection = 'ascending' | 'descending';
export type SortConfigItem<T extends string> = {
  name: T;
  reversed?: boolean;
  type?: 'text' | 'number' | 'moment';
};
export type SortConfig<T extends string> = SortConfigItem<T>[];
export type SortDefaults<T extends string> = {
  config: SortConfigItem<T>;
  direction: SortDirection;
};

const getObjValue = (obj: any, fullPath: string) => {
  const paths = fullPath.split('.');
  let activeObj = obj;
  // eslint-disable-next-line no-restricted-syntax
  for (const path of paths) {
    if (!activeObj || activeObj === undefined || activeObj[path] === undefined) {
      return undefined;
    }
    activeObj = activeObj[path];
  }

  return activeObj;
};

/**
 * Handles tracking of sort column and direction as well as sorts data based on
 * these changes. Sorting is executed on the client side, so the assumption is
 * that all data is already loaded. This won't work with paginated resources.
 */
export default function useSortableTableLocally<SortColumn extends string, T>(
  data: T[],
  sortConfig: SortConfig<SortColumn>,
  sortDefaults?: SortDefaults<SortColumn>,
) {
  const [sortColumn, setSortColumn] = useState<SortConfigItem<SortColumn> | undefined>();
  const [sortDirection, setSortDirection] = useState<SortDirection>();

  useEffect(() => {
    setSortColumn(sortDefaults?.config);
    setSortDirection(sortDefaults?.direction);
  }, [data, sortDefaults]);

  const handleSort = useCallback(
    (column: SortColumn) => {
      let newSortColumn = sortColumn;
      let newSortDirection = sortDirection;

      if (sortColumn?.name !== column) {
        newSortColumn = sortConfig.find((c) => c.name === column);
        newSortDirection = newSortColumn?.reversed ? 'descending' : 'ascending';
      } else if (sortDirection === 'descending') {
        if (sortColumn.reversed) {
          newSortDirection = 'ascending';
        } else {
          newSortColumn = undefined;
        }
      } else if (sortDirection === 'ascending') {
        if (sortColumn.reversed) {
          newSortColumn = undefined;
        } else {
          newSortDirection = 'descending';
        }
      }

      if (newSortColumn !== sortColumn || newSortDirection !== sortDirection) {
        if (newSortColumn !== sortColumn) {
          setSortColumn(newSortColumn);
        }
        if (newSortDirection !== sortDirection) {
          setSortDirection(newSortDirection);
        }
      }
    },
    [sortColumn, sortDirection, sortConfig],
  );

  const sortedData = useMemo(() => {
    if (sortColumn !== undefined) {
      return [...data].sort((a: T, b: T) => {
        if (sortColumn.type === 'number') {
          return numerically(sortDirection === 'ascending')(
            getObjValue(a, sortColumn.name),
            getObjValue(b, sortColumn.name),
          );
        }
        if (sortColumn.type === 'moment') {
          return sortMomentDates(sortDirection === 'ascending')(
            getObjValue(a, sortColumn.name),
            getObjValue(b, sortColumn.name),
          );
        }
        return alphabetically(sortDirection === 'ascending')(
          getObjValue(a, sortColumn.name),
          getObjValue(b, sortColumn.name),
        );
      });
    }
    return data;
  }, [data, sortColumn, sortDirection]);

  return { handleSort, sortColumn, sortDirection, sortedData };
}
