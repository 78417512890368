import React, { memo, useCallback, useState } from 'react';
import type { Cell } from 'react-table';

import type { JobModel } from '@api/jobs/JobModel.v1';
import DateTime from '@components/DateTime';
import NotApplicable from '@components/NotApplicable';
import Status from '@components/Status';
import DescriptionCell from '@components/Table/Cells/DescriptionCell';
import LinkedCell from '@components/Table/Cells/LinkedCell';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import UserCell from '@components/Table/Cells/UserCell';
import type { TableProps } from '@components/Table/Table';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import { PaginatedResponse } from '@models/PaginatedResponse';
import type { FilterServiceInterface } from '@utils/filters';
import { FilterOptions } from '@utils/filters';

enum ColumnKey {
  description = 'description',
  dsuserOwnedBy = 'dsuserOwnedBy',
  lastRun = 'lastRun',
  lastRunDuration = 'lastRunDuration',
  lastRunStatus = 'lastRunStatus',
  name = 'name',
  schedule = 'schedule',
  search = 'search',
}

const DEFAULT_VISIBLE_COLUMNS = [
  ColumnKey.search,
  ColumnKey.name,
  ColumnKey.description,
  ColumnKey.dsuserOwnedBy,
  ColumnKey.schedule,
  ColumnKey.lastRun,
  ColumnKey.lastRunStatus,
  ColumnKey.lastRunDuration,
];

const DEFAULT_HIDDEN_COLUMNS = [
  ColumnKey.dsuserOwnedBy,
  ColumnKey.lastRunDuration,
  ColumnKey.schedule,
];

export const JOB_TABLE_SEARCH_CONFIG: PartialRecord<ColumnKey, keyof FilterOptions> = {
  [ColumnKey.name]: 'search_name',
  [ColumnKey.description]: 'search_description',
};

export const JOB_TABLE_SORT_CONFIG: PartialRecord<ColumnKey, string> = {
  [ColumnKey.name]: 'name',
  [ColumnKey.description]: 'description',
  [ColumnKey.lastRunDuration]: 'last_run_duration',
  [ColumnKey.lastRun]: 'last_run_on',
  [ColumnKey.lastRunStatus]: 'last_run_status',
  [ColumnKey.dsuserOwnedBy]: 'owner',
};

export interface JobTableProps extends Pick<TableProps, 'selectedRowIds'> {
  data?: PaginatedResponse<JobModel>;
  filterService: FilterServiceInterface;
  isDataSourceEditable?: boolean;
  isLoading?: boolean;
  toggleAll?: (checked: boolean) => void;
  toggleItem?: (item: JobModel, checked: boolean) => void;
  visibleColumns?: Array<ColumnKey>;
}

const JobTable: React.FC<JobTableProps> = ({
  data,
  filterService,
  isDataSourceEditable,
  isLoading,
  selectedRowIds,
  toggleAll,
  toggleItem,
  visibleColumns = DEFAULT_VISIBLE_COLUMNS,
}) => {
  const [isShowFilter, setIsShowFilter] = useState(false);
  const { changePage, filter, initialTableSortState, search, sort } = filterService;
  const toggleFilter = useCallback(() => setIsShowFilter((prev) => !prev), [setIsShowFilter]);

  const columns: ColumnConfig<JobModel>[] = React.useMemo(() => {
    const all: Record<ColumnKey, ColumnConfig<JobModel>> = {
      [ColumnKey.search]: {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      [ColumnKey.name]: {
        Cell: ({ column, row: { original }, state }: Cell<JobModel>) => (
          <LinkedCell
            column={column}
            customUrl={original.routePath}
            item={original}
            showDataTypeTooltip
            showIcon
            showNameTooltip
            state={state}
          />
        ),
        Header: `Name (${data?.count ?? 0})`,
        accessor: ColumnKey.name,
        id: ColumnKey.name,
        width: '120%',
      },
      [ColumnKey.description]: {
        Cell: ({ column, row: { original }, state }: Cell<JobModel>) => (
          <DescriptionCell
            {...original}
            column={column}
            dataSourceType={original.dataTypes?.dataSourceType}
            isDataSourceEditable={isDataSourceEditable}
            state={state}
          />
        ),
        Header: 'Description',
        accessor: ColumnKey.description,
        id: ColumnKey.description,
        width: '150%',
      },
      [ColumnKey.lastRun]: {
        Cell: ({ row: { original } }: Cell<JobModel>) => <DateTime datetime={original.lastRun} />,
        Header: 'Last Run',
        accessor: ColumnKey.lastRun,
        disableFilters: true,
        id: ColumnKey.lastRun,
        width: 150,
      },
      [ColumnKey.lastRunStatus]: {
        Cell: ({ row: { original } }: Cell<JobModel>) => (
          <Status status={original.formattedLastRunStatus}>{original.lastRunStatus}</Status>
        ),
        Header: 'Last Run Status',
        accessor: ColumnKey.lastRunStatus,
        disableFilters: true,
        id: ColumnKey.lastRunStatus,
        width: 150,
      },
      [ColumnKey.lastRunDuration]: {
        Header: 'Last Run Time',
        accessor: ColumnKey.lastRunDuration,
        disableFilters: true,
        id: ColumnKey.lastRunDuration,
        width: '115%',
      },
      [ColumnKey.dsuserOwnedBy]: {
        Cell: ({ column, row: { original }, state }: Cell<JobModel>) => (
          <UserCell column={column} state={state} user={original?.dsuserOwnedBy} />
        ),
        Header: 'Owner',
        accessor: ColumnKey.dsuserOwnedBy,
        disableFilters: true,
        id: ColumnKey.dsuserOwnedBy,
        width: '120%',
      },
      [ColumnKey.schedule]: {
        Cell: ({ row: { original } }: Cell<JobModel>) => original.schedule || <NotApplicable />,
        Header: 'Schedule',
        accessor: ColumnKey.schedule,
        disableFilters: true,
        disableSortBy: true,
        id: ColumnKey.schedule,
        width: '115%',
      },
    };

    return visibleColumns.map((col) => all[col]);
  }, [data?.count, isDataSourceEditable, visibleColumns]);

  const totalPages = data && filter.page_size ? Math.ceil(data.count / filter.page_size) : 1;

  return (
    <TableStyled>
      <Table
        basic="very"
        changePage={changePage}
        className="table-full"
        columns={columns}
        compact
        data={data?.results || []}
        initialState={{
          hiddenColumns: DEFAULT_HIDDEN_COLUMNS,
          ...(selectedRowIds && { selectedRowIds }),
          sortBy: initialTableSortState,
        }}
        loading={isLoading}
        manualFilters
        manualPagination
        manualSortBy
        rangeSelectConfig={{
          onCellCheckChange: toggleItem,
          onHeaderCheckChange: toggleAll,
        }}
        selectable
        setFilters={search}
        setSortBy={sort}
        showFilter={isShowFilter}
        sortable
        stickyHeader
        toggleFilter={toggleFilter}
        totalPages={totalPages}
        unstackable
      />
    </TableStyled>
  );
};

export default memo(JobTable);
