import React, { useEffect, useState } from 'react';

import type { MentionType } from '@components/Mention/Mention.types';
import SearchResult from '@components/SearchBar/SearchResult';

import { MentionSuggestion } from './Mentions.styles';

interface MentionItemProps {
  activeIndex?: number;
  itemIndex?: number;
  mention: MentionType;
  onMentionSelect: () => void;
  searchValue?: string;
}

const MentionItem: React.FC<MentionItemProps> = ({
  activeIndex,
  itemIndex,
  mention,
  onMentionSelect,
  searchValue,
}) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(activeIndex === itemIndex);
  }, [activeIndex, itemIndex]);

  return (
    <MentionSuggestion
      active={active}
      onMouseDown={onMentionSelect}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      <SearchResult
        clickableBreadcrumbs={false}
        hideDescriptions
        isMentionItem
        searchItem={mention}
        searchText={searchValue || ''}
      />
    </MentionSuggestion>
  );
};

export default MentionItem;
