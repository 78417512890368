import React from 'react';

import { DescriptionSource } from '@api/description/description.types';
import Box from '@components/Box';
import Icon from '@components/UI/Icon';
import { ValidDSType } from '@models/DataSourceCredentials';
import { MetadataModel } from '@models/MetadataModel';

import DescriptionIcon from '../../DescriptionIcon';
import SourceLink from '../SourceLink';

import { StyledColumnWrapper, StyledLabel, StyledRowWrapper } from './SourceLabel.styles';

export interface SourceLabelProps {
  dataSourceType?: ValidDSType;
  guid?: string;
  plainUserDescription?: string;
  source?: DescriptionSource;
  suggestedDescriptionSourceObject?: MetadataModel;
}

const SourceLabel: React.FC<SourceLabelProps> = ({
  dataSourceType,
  guid,
  plainUserDescription,
  source,
  suggestedDescriptionSourceObject,
}) => {
  const suggestedDescriptionDataSourceType =
    suggestedDescriptionSourceObject?.breadcrumbs?.[0]?.targetDataSourceType;

  const labelObj = {
    ai: (
      <StyledRowWrapper>
        <DescriptionIcon descriptionSource={source} />
        <StyledLabel>AI-generated Description</StyledLabel>
      </StyledRowWrapper>
    ),
    downstream_lineage: (
      <StyledColumnWrapper>
        <StyledLabel>From downstream lineage column</StyledLabel>
        {suggestedDescriptionSourceObject && (
          <SourceLink
            dataSourceType={suggestedDescriptionDataSourceType}
            suggestedDescriptionSourceObject={suggestedDescriptionSourceObject}
          />
        )}
      </StyledColumnWrapper>
    ),
    ingestion: (
      <StyledRowWrapper>
        <Box mt={0.1}>
          <DescriptionIcon dataSourceType={dataSourceType} descriptionSource={source} />
        </Box>
        <StyledLabel>Source Description</StyledLabel>
      </StyledRowWrapper>
    ),
    lineage: null,
    public_docs: (
      <StyledLabel>
        <StyledRowWrapper>
          From <Icon name="fivetran" size="16px" /> Public Docs
        </StyledRowWrapper>
      </StyledLabel>
    ),
    similarity: (
      <StyledColumnWrapper>
        <StyledLabel>From similar table</StyledLabel>
        {suggestedDescriptionSourceObject && (
          <SourceLink
            dataSourceType={suggestedDescriptionDataSourceType}
            suggestedDescriptionSourceObject={suggestedDescriptionSourceObject}
          />
        )}
      </StyledColumnWrapper>
    ),
    unknown: null,
    upstream_lineage: (
      <StyledColumnWrapper>
        <StyledLabel>From upstream lineage column</StyledLabel>
        {suggestedDescriptionSourceObject && (
          <SourceLink
            dataSourceType={suggestedDescriptionDataSourceType}
            suggestedDescriptionSourceObject={suggestedDescriptionSourceObject}
          />
        )}
      </StyledColumnWrapper>
    ),
    user: (
      <StyledRowWrapper>
        <DescriptionIcon
          descriptionSource={source}
          guid={guid}
          plainUserDescription={plainUserDescription}
          showLabel
        />
      </StyledRowWrapper>
    ),
  };

  if (!source) {
    return null;
  }

  return labelObj[source];
};

export default SourceLabel;
