import React, { useRef } from 'react';

import { piiRulesCacheKeys, useFetchPiiRuleSync, usePostPiiRuleSync } from '@api/piiRules';
import type { PiiRuleSyncPayload } from '@api/piiRules/PiiRuleSyncModel';
import Box from '@components/Box';
import DateTime from '@components/DateTime';
import IconButton from '@components/IconButton';
import { renderInfoToast } from '@components/Toast';
import Tooltip from '@components/Tooltip';
import fetchClient from '@lib/fetchClient';
import theme from '@styles/theme';

export const syncTooltips: Record<PiiRuleSyncPayload['status'], string> = {
  in_progress: 'Data is being synced, this may take a moment.',
  scheduled: 'Data sync scheduled',
  unscheduled: 'Sync',
};

export const syncMessages: Record<PiiRuleSyncPayload['status'], string> = {
  in_progress: 'Sync already in progress',
  scheduled: 'Sync already scheduled',
  unscheduled: 'Sync started',
};

const Sync: React.FC = () => {
  const intervalRef = useRef<NodeJS.Timer | null>(null);

  const { data } = useFetchPiiRuleSync({
    onSuccess: (res) => {
      if (res.status !== 'unscheduled' && !intervalRef?.current) {
        fetchClient.removeQueries(piiRulesCacheKeys.piiRules);
        intervalRef.current = setInterval(() => {
          fetchClient.invalidateQueries(piiRulesCacheKeys.sync);
        }, 60000);
      }

      if (res.status === 'unscheduled' && intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
        renderInfoToast('Sync complete');
      }
    },
  });

  const postPiiRuleQuery = usePostPiiRuleSync({
    onSuccess: () => {
      fetchClient.invalidateQueries(piiRulesCacheKeys.sync);
      renderInfoToast(syncMessages[data?.status ?? 'unscheduled']);
    },
  });

  return data?.lastSyncedAt ? (
    <Box
      alignItems="center"
      compDisplay="flex"
      fontSize={theme.typography.fontSizes.body1}
      gap={0.5}
      px={1.5}
    >
      <Box fontWeight="bold">Last Synced</Box>
      <DateTime datetime={data?.lastSyncedAt} />
      <Tooltip content={syncTooltips[data?.status ?? 'unscheduled']}>
        <IconButton
          disabled={postPiiRuleQuery.isLoading}
          iconName="refresh"
          iconSize="14px"
          onClick={() => {
            postPiiRuleQuery.mutate({});
          }}
          spinLeft={data?.status !== 'unscheduled'}
          variant="outlined"
        />
      </Tooltip>
    </Box>
  ) : null;
};

export default Sync;
