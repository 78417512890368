import { ColumnsMap } from '@components/ExploreTree/types';

/**
 * Takes an array of columnIds and returns an array of tableIds they belong to.
 */
const getTableIds = (columnsIds: string[], columnsById: ColumnsMap): string[] => {
  const output: string[] = [];

  for (let i = 0; i < columnsIds.length; i += 1) {
    const column = columnsById[columnsIds[i]];

    if (column?.tableGuid) {
      output.push(column.tableGuid);
    }
  }

  return Array.from(new Set(output))?.filter(Boolean);
};

export default getTableIds;
