import { isRichTextMessageValid } from '@components/RichTextDescriptionEditor/RichTextDescriptionEditor.utils';
import type { DataSourceTypesType } from '@models/DataSourceCredentials';

import { NOTIFICATIONS_DATA_SOURCE_BLACKLIST } from './constants';
import { NotificationForm } from './types';

export const isNotificationAllowed = (dataSourceType: DataSourceTypesType) => {
  return !NOTIFICATIONS_DATA_SOURCE_BLACKLIST.includes(dataSourceType);
};

export const validatePayload = (payload: NotificationForm) => {
  const { message, recipients, richTextMessage } = payload;

  if (recipients.length === 0 && !isRichTextMessageValid(richTextMessage, message)) {
    return { errorMessage: 'Recipients and message cannot be empty.', isValid: false };
  }

  if (recipients.length === 0) {
    return { errorMessage: 'At least one recipient is required.', isValid: false };
  }

  if (!isRichTextMessageValid(richTextMessage, message)) {
    return { errorMessage: 'Message cannot be empty.', isValid: false };
  }

  return { isValid: true };
};
