import React from 'react';
import type { RouterLinkProps } from '@routing/router';

import AnalyticsUserModel from '@api/analytics/AnalyticsUserModel';
import { DsUserModel } from '@api/dsusers/DsUserModel';
import { TeamModel } from '@api/teams/TeamModel';
import { UserModel } from '@api/user/UserModel';
import Box from '@components/Box';
import NotApplicable from '@components/NotApplicable';
import Avatar from '@components/UI/Avatar';
import { OrganizationTeamModel } from '@models/OrganizationTeamModel';

import type { TypedCell } from '../types';

import { StyledPageLink, StyledUserCellContent } from './UserCell.styles';

type UserCellProps = Partial<TypedCell> & {
  ellipsis?: boolean;
  to?: RouterLinkProps['to'];
  user?: DsUserModel | UserModel | TeamModel | OrganizationTeamModel | AnalyticsUserModel;
};

const UserCell: React.FC<UserCellProps> = ({ column, ellipsis, globalFilter, to, user }) => {
  if (!user) {
    return <NotApplicable />;
  }

  return (
    <Box alignItems="center" compDisplay="flex" justifyContent="flex-start">
      <Avatar {...user.mappedAvatar} size="1.25rem" />
      <StyledUserCellContent ellipsis={ellipsis}>
        <StyledPageLink
          item={user}
          searchTermsToHighlight={globalFilter || column?.filterValue}
          to={to}
          underline
        />
      </StyledUserCellContent>
    </Box>
  );
};

export default React.memo(UserCell);
