import React, { useMemo } from 'react';
import { useDebounce } from 'use-debounce';

import { useFetchQueryLog } from '@api/queryLog';
import TabError from '@components/TabContent/TabError';
import RecentQueriesTable from '@components/Table/RecentQueriesTable';
import { TabContentProps } from '@components/Tabs/types';
import { Filter } from '@utils';
import stripSpaces from '@utils/stripSpaces';

import { StyledRecentQueriesTab } from './DSUsers.styles';

const searchConfig: { [key: string]: keyof Filter.FilterOptions } = {
  query: 'search_query',
  user: 'search_user',
};

const sortConfig: { [key: string]: string } = {
  query: 'query',
  runTime: 'duration',
  startTime: 'executed_on',
  user: 'dsuser',
};

const query = stripSpaces(`{
  guid,
  executed_on,
  external_id,
  duration,
  query {
    guid,
    raw_sql,
    database
  },
  dsuser {
    guid,
    name,
    display_name,
    user {
      guid,
      first_name,
      last_name,
      avatar
    },
    data_source {
      guid,
      name,
      type
   }
  }
}`);

interface RecentQueriesTabProps extends TabContentProps {
  dsGuid: string;
}

const AdminRecentQueriesTab: React.FC<RecentQueriesTabProps> = ({ dsGuid, stickyHeader }) => {
  const recentQueriesConfig: Filter.FilterOptions = {
    datasources: [dsGuid],
    order: '-executed_on',
    page: 1,
    page_size: 50,
    query,
    sortColumn: 'executed_on',
    sortDirection: 'descending',
  };

  const FilterService = Filter.useUpdateFilters(
    recentQueriesConfig,
    searchConfig,
    sortConfig,
    '-executed_on',
  );
  const { filter } = FilterService;
  const [debouncedFilter] = useDebounce(filter, 500);

  const effectiveConfig = useMemo(() => {
    return { params: Filter.setParams(debouncedFilter) };
  }, [debouncedFilter]);

  const { data, error, isLoading } = useFetchQueryLog({
    ...effectiveConfig,
  });

  if (error) return <TabError />;

  return (
    <StyledRecentQueriesTab>
      <RecentQueriesTable
        data={data?.results}
        enableApiSearch
        filterService={FilterService}
        loading={isLoading}
        stickyHeader={stickyHeader}
      />
    </StyledRecentQueriesTab>
  );
};

export default AdminRecentQueriesTab;
