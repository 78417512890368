import type { UseFetchOptions } from '@hooks/useFetch';
import useFetch from '@hooks/useFetch';
import type { PaginatedResponse } from '@models/PaginatedResponse';

import paginatedTransform from '../paginatedTransform';
import rawTransform from '../rawTransform';

import BiTableModel from './BiTableModel';
import * as cacheKeys from './cacheKeys';

const fetchBiTablesSelect = paginatedTransform(BiTableModel);
/**
 * We want to use the more generic API used with QuickSight to continue BI integrations.
 * @todo Migrate once the new BI tools is finished testing
 * @deprecated
 */
export const useFetchBiTables = (options: UseFetchOptions<PaginatedResponse<BiTableModel>>) => {
  return useFetch<PaginatedResponse<BiTableModel>>({
    ...options,
    queryKey: [...cacheKeys.all, options.params],
    select: fetchBiTablesSelect,
    url: `bi/thoughtspot/tables/`,
  });
};

const fetchBiTableSelect = rawTransform(BiTableModel);
/**
 * We want to use the more generic API used with QuickSight to continue BI integrations.
 * @todo Migrate once the new BI tools is finished testing
 * @deprecated
 */
export const useFetchBiTable = (id: string, options: UseFetchOptions<BiTableModel>) => {
  return useFetch<BiTableModel>({
    ...options,
    queryKey: [...cacheKeys.biTable(id), options.params],
    select: fetchBiTableSelect,
    url: `bi/thoughtspot/tables/${id}/`,
  });
};
