import { Transform } from 'class-transformer';

import { Reference, ReferenceData } from '@models/Reference';

import { parseMessageValues } from './utils';

export interface ActivityMessageData {
  template: string;
  values: Record<string, ReferenceData<any>>;
}

export class ActivityMessageModel {
  template!: string;

  @Transform((value) => parseMessageValues(value))
  values!: Record<string, Reference<any>>;
}
