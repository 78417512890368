import { Expose, Transform, Type } from 'class-transformer';
import moment from 'moment';

import { DataSourceModel } from '@models/DataSourceModel';

import { OrganizationSettings, OrganizationSettingsData } from './OrganizationSettings';

export interface OrganizationData {
  createdOn: moment.Moment;
  daysLeftInTrial: number;
  fullName?: string;
  guid: string;
  isOnboardingComplete?: boolean;
  isTrial?: boolean;
  name: string;
  settings?: OrganizationSettingsData;
  trialEndsAt: moment.Moment;
}

export class OrganizationModel {
  static objectType: string = 'organization';

  objectType: string = OrganizationModel.objectType;

  static typeDisplay: string = 'Organization';

  typeDisplay: string = OrganizationModel.typeDisplay;

  guid!: string;

  name!: string;

  @Type(() => OrganizationSettings)
  settings?: OrganizationSettings;

  @Expose({ name: 'created_on' })
  @Transform((value) => (value ? moment(value) : value))
  createdOn!: moment.Moment;

  @Expose({ name: 'trial_ends_at' })
  @Transform((value) => (value ? moment(value) : value))
  trialEndsAt?: moment.Moment;

  @Expose({ name: 'subscription_ends_at' })
  @Transform((value) => (value ? moment(value) : value))
  subscriptionEndsAt?: moment.Moment;

  @Expose({ name: 'is_onboarding_complete' })
  isOnboardingComplete?: boolean;

  @Type(() => DataSourceModel)
  @Expose({ name: 'data_sources' })
  dataSources?: DataSourceModel[];

  get isTrial() {
    return !!this.trialEndsAt;
  }

  get daysLeftInTrial() {
    const currentDate = moment().toDate();
    return Math.ceil(moment.duration(this.trialEndsAt?.clone().diff(currentDate)).asDays());
  }

  get isTrialExpired() {
    return this.isTrial && this.daysLeftInTrial < 0;
  }

  get daysLeftInSubscription() {
    const currentDate = moment().toDate();
    return Math.ceil(moment.duration(this.subscriptionEndsAt?.clone().diff(currentDate)).asDays());
  }

  get isSubscriptionEnded() {
    return !!this.subscriptionEndsAt && this.daysLeftInSubscription < 0;
  }

  get showBanner() {
    if (!this.isSubscriptionEnded && this.subscriptionEndsAt && this.daysLeftInSubscription <= 5) {
      return true;
    }
    return this.isTrial && !this.isTrialExpired && this.daysLeftInTrial <= 5;
  }

  get fullName() {
    return this.name;
  }
}

/**
 * Checks if organization ingestion is complete.
 * This means at least one Data Source has completed ingesting.
 * We check that by checking if `ds.isEmailSent` is true
 * for at least one Data Source in the Organization.
 * In case of zero Data Sources, we assume that ingestion is complete based on whether
 * user has completed onboarding process.
 */
export function isIngestionComplete(
  org: OrganizationModel | undefined,
  dataSources?: { [guid: string]: DataSourceModel } | undefined,
): boolean {
  if (org === undefined) {
    return false;
  }

  const { isOnboardingComplete } = org;

  // If there are no data sources, then ingestion is not complete, unless user has completed onboarding
  if (!dataSources || Object.keys(dataSources).length === 0) {
    return Boolean(isOnboardingComplete);
  }

  // At least one data source has `isEmailSent = true`.
  return Object.values(dataSources).some((ds) => ds.isEmailSent === true);
}
