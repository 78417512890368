interface SortByOrderArgs {
  a?: string | number | null;
  b?: string | number | null;
  orderBy?: 'asc' | 'desc' | 'default';
}

const sortByType = ({ a, b, orderBy = 'asc' }: SortByOrderArgs) => {
  if (!a && !b) {
    return 0;
  }

  if (!a) {
    return 1;
  }

  if (!b) {
    return -1;
  }

  if (typeof a === 'string' && typeof b === 'string') {
    if (orderBy === 'asc' || orderBy === 'default') {
      return a?.localeCompare(b);
    }

    if (orderBy === 'desc') {
      return b?.localeCompare(a);
    }
  }

  if (typeof a === 'number' && typeof b === 'number') {
    if (orderBy === 'asc' || orderBy === 'default') {
      return a - b;
    }

    if (orderBy === 'desc') {
      return b - a;
    }
  }

  return 0;
};

export default sortByType;
