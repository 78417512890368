import getUrl from '@configs/urls/getUrl';
import moment from 'moment';

import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import { DsUserModel } from '@api/dsusers/DsUserModel';
import type { Job as JobResponse } from '@api/openapi.generated';
import { TaggedItemModel } from '@api/tags/TaggedItemModel';
import type { ObjectType } from '@api/types';
import { rawClassArrayMap, rawClassMap, rawMap } from '@api/utils';
import isEmptyRichText from '@components/RichTextEditor/helpers/isEmptyRichText';
import DataTypesModel from '@models/DataTypesModel/DataTypesModel.v1';
import RelatedObjectsCountsModel from '@models/RelatedObjectsCountsModel';
import formatNumber from '@utils/formatNumber';
import titlelize from '@utils/titlelize';

const objectType: ObjectType = 'job';

export const STATUS_CONFIG: Record<string, 'error' | 'warning' | 'success' | 'paused'> = {
  FAILED: 'error',
  SUCCESS: 'success',
  UNKNOWN: 'paused',
};

const mapJobModel = (value: Partial<JobResponse>) => {
  const dataTypes = rawMap(DataTypesModel, value?.data_types);
  const breadcrumbs = rawClassArrayMap(BreadcrumbModel, value?.breadcrumbs);
  const taggedItems = rawClassArrayMap(TaggedItemModel, value?.tagged_items);
  const dsuserOwnedBy = rawClassMap(DsUserModel, value?.owner);
  const formattedLastRunStatus = STATUS_CONFIG[value?.last_run_status ?? 'UNKNOWN'];

  return {
    aiDescription: value?.ai_description,
    breadcrumbList: breadcrumbs,
    createdAt: rawMap(moment, value?.created_on),
    dataTypes,
    description: value?.description,
    descriptionSource: value?.description_source,
    downstreamObjectsCounts: rawClassMap(
      RelatedObjectsCountsModel,
      value?.downstream_objects_counts,
    ),
    dsuserOwnedBy,
    externalUrl: value?.job_url,
    formattedLastRunStatus,
    guid: value?.guid!,
    ingestedDescription: value?.ingested_description,
    lastRun: rawMap(moment, value?.last_run_on),
    lastRunDuration: rawMap(
      (v) => `${formatNumber({ value: moment.duration(v).as('seconds') })}s`,
      value?.last_run_duration,
    ),
    lastRunStatus: rawMap(titlelize, value?.last_run_status),
    name: value?.name,
    objectType,
    richtextDescription: isEmptyRichText(value?.richtext_description)
      ? ''
      : value?.richtext_description,
    routePath: getUrl({ dataTypes, guid: value?.guid! }),
    schedule: value?.schedule,
    showCustomAttributes: true,
    showMentionedBy: true,
    taggedItems,
    updatedAt: rawMap(moment, value?.updated_on),
    upstreamObjectsCounts: rawClassMap(RelatedObjectsCountsModel, value?.upstream_objects_counts),
    userDescription: value?.user_description,
  };
};

export default mapJobModel;

mapJobModel.objectType = objectType;

export type JobModel = ReturnType<typeof mapJobModel>;
