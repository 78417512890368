import { ExtensionStorage } from '@pages/LoginPage/ExtensionLoginPage.types';

export const getUserAuthAtLocalStorage = () => {
  const accessToken = localStorage.getItem('Access-Token');
  const accessTokenTime = localStorage.getItem('Access-Token-Time');
  const refreshToken = localStorage.getItem('Refresh-Token');
  const defaultOrganization = localStorage.getItem('defaultOrganization');
  const organizations = localStorage.getItem('organizations');
  const isStaff = localStorage.getItem('isStaff');
  const isSuperUser = localStorage.getItem('isSuperUser');
  const userEmail = localStorage.getItem('userEmail');
  const isMasked = localStorage.getItem('isMasked');

  return {
    accessToken,
    accessTokenTime,
    defaultOrganization,
    isMasked,
    isStaff,
    isSuperUser,
    organizations,
    refreshToken,
    userEmail,
  };
};

export const clearChromeAuthData = () => {
  if (chrome.storage) {
    chrome.storage.sync.set({
      [ExtensionStorage.AccessToken]: '',
      [ExtensionStorage.AccessTokenTime]: '',
      [ExtensionStorage.RefreshToken]: '',
      [ExtensionStorage.defaultOrganization]: '',
      [ExtensionStorage.organizations]: '',
      [ExtensionStorage.isStaff]: '',
      [ExtensionStorage.isSuperUser]: '',
      [ExtensionStorage.userEmail]: '',
      [ExtensionStorage.isMasked]: '',
    });
  }
};

export const chromeAuthStorageKeys = [
  ExtensionStorage.AccessToken,
  ExtensionStorage.AccessTokenTime,
  ExtensionStorage.RefreshToken,
  ExtensionStorage.defaultOrganization,
  ExtensionStorage.organizations,
  ExtensionStorage.isStaff,
  ExtensionStorage.isSuperUser,
  ExtensionStorage.userEmail,
  ExtensionStorage.isMasked,
];

export const hasAllAuthDataAtChrome = (authData: { [key: string]: any }) =>
  chromeAuthStorageKeys.filter((item) => !authData[item]).length === 0;
