import React from 'react';

import Icon from '@components/UI/Icon';

import type { ButtonProps } from '../Button';
import Button from '../Button';

const DocsButton: React.FC<ButtonProps> = (props) => {
  return (
    <Button
      {...props}
      backgroundColor="#deece9"
      borderColor="#589f8f"
      color="#589f8f"
      compSize="xs"
    >
      <Icon color="currentColor" mr={0.5} name="book" size="12px" />
      Docs
    </Button>
  );
};

export default DocsButton;
