import React, { useState } from 'react';
import type { Location } from '@routing/history';
import { Prompt, useLocation } from '@routing/router';

import { useModal } from '@context/Modal';

import LeavePageConfirmationModal from '../Modal/LeavePageConfirmationModal';

interface Props {
  confirmButtonText?: string;
  message?: string;
  navigateOnCancel?: boolean;
  onConfirm?: () => void;
  shouldBlockNavigation?: boolean;
}

const NavigationBlocker: React.FC<Props> = ({
  confirmButtonText,
  message,
  navigateOnCancel,
  onConfirm,
  shouldBlockNavigation,
}) => {
  const { MODAL_IDS, checkModalOpened, closeModal, openModal } = useModal();
  const [newLocation, setNewLocation] = useState<Location | undefined>();
  const currentLocation = useLocation();
  const isConfirmationModalOpen = checkModalOpened(
    MODAL_IDS.leavePageConfirmationNavigationBlocker,
  );

  const handleNavigationAttempt = (location: Location): string | boolean => {
    // If trying to navigate to the location we're already at we shouldn't show the modal.
    const isNewLocation = location.pathname !== currentLocation.pathname;

    if (!isConfirmationModalOpen && isNewLocation && shouldBlockNavigation) {
      // Set the desired location in state so we can pass it to the confirmation modal.
      setNewLocation(location);
      openModal(MODAL_IDS.leavePageConfirmationNavigationBlocker);
      // Returning false tells react router not allow navigation.
      return false;
    }
    closeModal(MODAL_IDS.leavePageConfirmationNavigationBlocker);

    // Returning true tells react router to allow navigation.
    return true;
  };

  return (
    <>
      {/* React router component that handles whether to send the user to new page or block navigation */}
      {shouldBlockNavigation && (
        <Prompt
          message={handleNavigationAttempt}
          when={!isConfirmationModalOpen && shouldBlockNavigation}
        />
      )}
      {shouldBlockNavigation && (
        <LeavePageConfirmationModal
          confirmButtonText={confirmButtonText}
          location={newLocation}
          message={message}
          modalId={MODAL_IDS.leavePageConfirmationNavigationBlocker}
          navigateOnCancel={navigateOnCancel}
          onConfirm={onConfirm}
          title="Leave page?"
        />
      )}
    </>
  );
};

export default NavigationBlocker;
