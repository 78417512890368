import React, { useState } from 'react';
import { ReactEditor, RenderElementProps, useSlate } from 'slate-react';

import Box from '@components/Box';
import useClickOutside from '@hooks/useClickOutside';

import TableCellMenu from '../Tables/TableCellMenu/TableCellMenu';

import { TDElement, THElement } from './TableElements.styles';

const TableCellElement = ({ attributes, children, element }: RenderElementProps) => {
  const [showButton, setShowButton] = useState(false);
  const { type } = element;
  const CellWrapper = type === 'td' ? TDElement : THElement;
  const editor = useSlate();

  const handleMouseDown = () => {
    setShowButton(true);
  };

  const handleClose = () => {
    setShowButton(false);
  };

  const useClickOutsideRef = useClickOutside<HTMLDivElement>({
    onClick: () => {
      handleClose();
    },
  });

  return (
    <CellWrapper {...attributes} onMouseDown={handleMouseDown} onMouseLeave={handleClose}>
      <Box ref={useClickOutsideRef} compHeight="100%" compWidth="100%">
        {children}
        {!ReactEditor.isReadOnly(editor) && showButton && <TableCellMenu showButton={showButton} />}
      </Box>
    </CellWrapper>
  );
};

export default TableCellElement;
