import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

import rootTheme from '@styles/theme';

export type StateVariant =
  | 'edit'
  | 'read'
  | 'edit-focused'
  | 'read-icon-always'
  | 'read-icon-hover';

export type EditorVariant = 'block' | 'block-simple' | 'inline';
type VariantStyle = PartialRecord<StateVariant, SerializedStyles>;

export const EDIT_ICON_SIZE = '14px';

const blockEditStyles = css`
  background-color: ${rootTheme.colors.white};
  border-style: none;
  box-shadow: none;
  min-height: 264px;
`;

const block: VariantStyle = {
  edit: blockEditStyles,
  'edit-focused': blockEditStyles,
  read: css`
    background-color: ${rootTheme.colors.white};
    border-style: none;
    box-shadow: none;
    min-height: 80px;
  `,
};

const inline: VariantStyle = {
  edit: css`
    background-color: ${rootTheme.colors.white};
    border-style: solid;
    border-color: ${rootTheme.colors.v1.gray[300]};
  `,
  'edit-focused': css`
    background-color: ${rootTheme.colors.white};
    border-style: solid;
    box-shadow: 0px 0px 0px 4px #f4ebff, 0px 1px 2px 0px #1018280d;
    border-color: ${rootTheme.colors.v1.primary[300]};
  `,
  read: css`
    background-color: inherit;
    border-style: none;
    box-shadow: none;
  `,
};

export const editorVariantStyles: Record<EditorVariant, VariantStyle> = {
  block,
  'block-simple': block,
  inline,
};

const slateBlock: VariantStyle = {
  edit: css`
    padding: ${rootTheme.space(1)};
    min-height: 174px !important;
  `,
  read: css`
    padding: ${rootTheme.space(0.5, 0)};
    min-height: 0px !important;
  `,
};

const slateInline: VariantStyle = {
  edit: css`
    padding: ${rootTheme.space(1)};
    min-height: 128px !important;
  `,
  read: css`
    padding: ${rootTheme.space(0.5, 0)};
    min-height: 0 !important;
  `,
  'read-icon-always': css`
    width: fit-content;
  `,
  'read-icon-hover': css`
    width: calc(100% - ${EDIT_ICON_SIZE});
  `,
};

export const slateVariantStyles: Record<EditorVariant, VariantStyle> = {
  block: slateBlock,
  'block-simple': slateBlock,
  inline: slateInline,
};
