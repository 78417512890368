import { useEffect, useRef, useState } from 'react';

import { EnrichedNewLineageData, mapNewLineage, NewLineageModel } from '@api/lineage/mapNewLineage';
import { useLineageExplore } from '@components/LineageExplore/useLineageExplore';
import useMutation from '@hooks/useMutation';
import { buildURL } from '@utils/fetchAdapter/helpers';

import parseLineageNodes from '../useCreateNodesEdges/algorithm/parseLineageNodes';
import { calculateUsageTypeFilter } from '../useCreateNodesEdges/useCreateNodesEdges';
import createStacks from '../useCreateNodesEdges/utils/createStacks';
import useGetConfigQueryParams from '../useGetConfigQueryParams';
import {
  LineageExpansionType,
  LineageModeChangeTrigger,
} from '../useLineageExplore/LineageExplore.context.types';

import { ExpandOrigin, ExpansionConfig } from './useExpandLineage.types';
import { mergeRawNodesRelations } from './useExpandLineage.utils';

const DISABLED_REQUEST_CONFIG: ExpansionConfig = {
  enabled: false,
  params: {},
  removeUnrelatedNodesOnExpand: false,
  trigger: LineageModeChangeTrigger.Click,
  type: LineageExpansionType.OpenOneLevelDownstreamLineage,
};

export interface UseExpandColumnModeAllParams {
  guid: string;
  origin?: ExpandOrigin;
  startingTableId: string;
}

const useExpandColumnModeAll = ({
  guid,
  origin = 'table',
  startingTableId,
}: UseExpandColumnModeAllParams) => {
  const [openAllExpansionConfig, setOpenAllExpansionConfig] =
    useState<ExpansionConfig>(DISABLED_REQUEST_CONFIG);

  const { enabled, params } = openAllExpansionConfig;
  const expansionConfigRef = useRef(openAllExpansionConfig);
  expansionConfigRef.current = openAllExpansionConfig;
  const isColumnAction = origin === 'column';
  const { enableHorizontalGroups } = useGetConfigQueryParams();

  const {
    inputNodesById,
    isColumnLevelLineage,
    previousTableLineageState,
    selectedUsageTypesState,
    setExpandLineageModeAllState,
    setIsFetchingExpandColumnLineageModeAll,
  } = useLineageExplore();

  const saveLineageModeAllData = (response: NewLineageModel) => {
    const previousInputNodesById = isColumnLevelLineage
      ? previousTableLineageState.inputNodesById
      : inputNodesById;

    const modeAllInputNodesById = mergeRawNodesRelations({
      newNodes: response?.data ?? {},
      pivotNodeKey: previousTableLineageState.stackData?.startingTableId ?? startingTableId,
      previousNodes: previousInputNodesById ?? {},
      shouldRemoveUnrelatedNodes: false,
    });

    const modeAllStacksData = createStacks({
      inputNodesById: modeAllInputNodesById,
      options: {
        enableColumnEdges: false,
        enableHorizontalGroups,
        enableTableEdges: true,
        openAll: false,
      },
      startingTableId: previousTableLineageState.stackData?.startingTableId ?? '',
    });

    const newTableLevelLineage = parseLineageNodes({
      edgesById: modeAllStacksData.edgesById,
      initialPosition: previousTableLineageState.initialPosition,
      isColumnLevelLineage,
      nodesById: modeAllStacksData.nodesById,
      stackGroups: modeAllStacksData.stackGroups,
    });

    setExpandLineageModeAllState({
      inputNodesById: modeAllInputNodesById,
      previousLineageState: {
        biggestConflictEndPerStack: newTableLevelLineage.biggestConflictEndPerStack,
        edgesById: newTableLevelLineage.edgesById,
        initialPosition: previousTableLineageState.initialPosition,
        inputNodesById: modeAllInputNodesById,
        nodesById: newTableLevelLineage.nodesById,
        nodesByStack: newTableLevelLineage.nodesByStack,
        stackData: modeAllStacksData,
      },
    });
    setIsFetchingExpandColumnLineageModeAll(false);
  };

  const saveLineageModeAllDataRef = useRef(saveLineageModeAllData);
  saveLineageModeAllDataRef.current = saveLineageModeAllData;

  const { mutateAsync } = useMutation({
    method: 'GET',
    onSuccess: (rawResponse: EnrichedNewLineageData) => {
      const response = mapNewLineage(rawResponse);
      saveLineageModeAllDataRef.current(response);
    },
    url: buildURL(`/lineage/${guid}/new/`, {
      ...params,
      mode: 'all',
      usage_type: calculateUsageTypeFilter(selectedUsageTypesState.usageTypes),
    }),
  });

  useEffect(() => {
    if (enabled && isColumnAction) {
      setIsFetchingExpandColumnLineageModeAll(true);
      mutateAsync({});
    }
  }, [enabled, isColumnAction, mutateAsync, setIsFetchingExpandColumnLineageModeAll]);

  return { setOpenAllExpansionConfig };
};

export default useExpandColumnModeAll;
