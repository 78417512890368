import React from 'react';

import type { StepProps } from '@components/DataSourceSetup/types';

import forms from './forms';

const DataSourceConfigureStep: React.FC<StepProps> = ({
  dataSource,
  dataType,
  onDataSourceAdded,
  prevStep,
}) => {
  const DataSourceConfigureStepForm = forms?.[dataType] ?? (() => null);

  return (
    <DataSourceConfigureStepForm
      dataSource={dataSource}
      dataType={dataType}
      onDataSourceAdded={onDataSourceAdded}
      prevStep={prevStep}
    />
  );
};

export default DataSourceConfigureStep;
