import React, { useState } from 'react';

import { useFetchWorkspacesHierarchy } from '@api/workspaces';
import { useUserContext } from '@context/User';

import { WorkspacesContext, WorkspacesContextInterface } from './Workspaces';

const WorkspacesProvider: React.FC = ({ children }) => {
  const [workspaces, setWorkspaces] = useState<string[] | undefined>();

  const {
    isOnboarding,
    isOnboardingComplete,
    loading: isLoadingOrg,
    organization,
  } = useUserContext();

  const shouldFetchWorkspaces =
    !isLoadingOrg &&
    !organization?.isTrialExpired &&
    !organization?.isSubscriptionEnded &&
    !isOnboarding &&
    isOnboardingComplete;

  useFetchWorkspacesHierarchy({
    enabled: shouldFetchWorkspaces,
    onSuccess: (data) => {
      const cache = new Set<string>();
      data?.children?.forEach((fav) => cache.add(fav.guid));
      setWorkspaces?.(Array.from(cache));
    },
  });

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const state: WorkspacesContextInterface = {
    setWorkspaces,
    workspaces,
  };

  return <WorkspacesContext.Provider value={state}>{children}</WorkspacesContext.Provider>;
};

export default WorkspacesProvider;
