import ColumnLineageModel from '@api/lineage/ColumnLineageModel';

import TableLineageModel from './TableLineageModel';

export type EdgeType = 'manual' | 'derived';

export const AVAILABLE_USAGE_TYPES = [
  'asis',
  'transformed',
  'aggregated',
  'filter',
  'none',
] as const;

export type UsageTypeType = (typeof AVAILABLE_USAGE_TYPES)[number];

export interface LineageType {
  columns: ColumnLineageModel[];
  tables: TableLineageModel[];
}
