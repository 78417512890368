import React, { useState } from 'react';

import { RelatedDashboardModel } from '@api/dashboards/RelatedDashboardModel';
import { useFetchExploreRelatedDashboards } from '@api/explores';
import { useFetchLookMLViewRelatedDashboards } from '@api/lookML';
import {
  GetBiExploresRelatedDashboardsListQueryParams,
  GetBiLookmlViewsRelatedDashboardsListQueryParams,
  GetBiTableauDatasourceRelatedDashboardsListQueryParams,
  GetTablesRelatedDashboardsListQueryParams,
} from '@api/openapi.generated';
import { useFetchTableauDataSourceRelatedDashboards } from '@api/tableau';
import { useFetchJobRelatedDashboards, useFetchTableRelatedDashboards } from '@api/tables';
import Box from '@components/Box';
import RelevantLineageToggle from '@components/LineageExplore/components/RelevantLineageToggle';
import { RelatedDashboardsList } from '@components/RelatedTableList';
import TabError from '@components/TabContent/TabError';
import RelatedDashboardsTable, {
  RELATED_DASHBOARDS_TABLE_SEARCH_CONFIG,
  RELATED_DASHBOARDS_TABLE_SORT_CONFIG,
} from '@components/Table/RelatedDashboardsTable';
import { ColumnKey } from '@components/Table/RelatedDashboardsTable/RelatedDashboardsTable';
import { TabContentProps } from '@components/Tabs/types';
import { useUserContext } from '@context/User';
import type DataTypesModel from '@models/DataTypesModel';
import { Filter } from '@utils';
import stripSpaces from '@utils/stripSpaces';

import type { RelatedDashboardsConfigItem } from './config';
import RELATED_DASHBOARDS_CONFIG from './config';
import ExportToCsv from './ExportToCsv';

export const relatedDashboardConfig: Filter.FilterOptions = {
  page: 1,
  page_size: 100,
  query: stripSpaces(
    `{
      distance,
      dashboard{
        is_hidden,
        guid,
        name,
        data_type,
        last_viewed_at,
        dsuser_created_by{
          guid,
          name,
          display_name,
          email,
          user{
            -is_invitation_pending,
            -settings,
            -team
          },
          data_source
        },
        top_dsuser{
          guid,
          name,
          display_name,
          email,
          user{
            -is_invitation_pending,
            -settings,
            -team
          },
          data_source
        },
        dashboard_updated_at,
        last_run_at,
        data_types,
        popularity{
          popularity,
          user_count,
          view_count,
          timeframe,
          service_query_count
        }
      }
    }`,
  ),
};

export interface RelatedDashboardsProps extends TabContentProps {
  assetName: string;
  dataTypes?: DataTypesModel;
  guid: string;
  objectType?:
    | 'explore'
    | 'table'
    | 'job'
    | 'column'
    | 'dashboard'
    | 'lookmlview'
    | 'tableaudatasource';
  stickyHeader?: boolean;
}

const RelatedDashboards: React.FC<RelatedDashboardsProps> = ({
  assetName,
  dataTypes,
  guid,
  objectType,
  stickyHeader,
}) => {
  const { organization } = useUserContext();
  const [isRelevantLineage, setIsRelevantLineage] = useState(false);
  const showDistance = objectType === 'table';
  const objectTypeTableOrder = showDistance
    ? RELATED_DASHBOARDS_TABLE_SORT_CONFIG.distance
    : '-popularity';

  const FilterService = Filter.useUpdateFilters(
    { ...relatedDashboardConfig, order: objectTypeTableOrder },
    RELATED_DASHBOARDS_TABLE_SEARCH_CONFIG,
    {
      ...RELATED_DASHBOARDS_TABLE_SORT_CONFIG,
      popularity: showDistance ? 'popularity,distance' : 'popularity',
    },
    objectTypeTableOrder,
  );
  const { filter } = FilterService;

  const shouldIncludeHidden = true;
  const filterParams = Filter.setParams(filter);
  const orderParam = filterParams.order;

  const {
    data: tableData,
    isError: isTableError,
    isLoading: isTableLoading,
    refetch,
  } = useFetchTableRelatedDashboards(guid, {
    enabled: objectType === 'table',
    params: {
      ...filterParams,
      include_hidden: shouldIncludeHidden,
      order: orderParam as GetTablesRelatedDashboardsListQueryParams['order'],
      relevant_lineage: organization?.settings?.useRelevantLineage ? isRelevantLineage : undefined,
    },
  });

  const {
    data: jobData,
    isError: isJobError,
    isLoading: isJobLoading,
  } = useFetchJobRelatedDashboards(guid, {
    enabled: objectType === 'job',
    params: {
      ...filterParams,
      include_hidden: shouldIncludeHidden,
      order: orderParam as GetTablesRelatedDashboardsListQueryParams['order'],
      relevant_lineage: organization?.settings?.useRelevantLineage ? isRelevantLineage : undefined,
    },
  });

  const {
    data: exploreData,
    isError: isExploreError,
    isLoading: isExploreLoading,
  } = useFetchExploreRelatedDashboards(guid, {
    enabled: objectType === 'explore',
    params: {
      ...filterParams,
      include_hidden: shouldIncludeHidden,
      order: orderParam as GetBiExploresRelatedDashboardsListQueryParams['order'],
      relevant_lineage: organization?.settings?.useRelevantLineage ? isRelevantLineage : undefined,
    },
  });

  const {
    data: lookmlViewData,
    isError: isLookMLViewError,
    isLoading: isLookMLViewLoading,
  } = useFetchLookMLViewRelatedDashboards(guid, {
    enabled: objectType === 'lookmlview',
    params: {
      ...filterParams,
      include_hidden: shouldIncludeHidden,
      order: orderParam as GetBiLookmlViewsRelatedDashboardsListQueryParams['order'],
      relevant_lineage: organization?.settings?.useRelevantLineage ? isRelevantLineage : undefined,
    },
  });

  const {
    data: tableauDSData,
    isError: isTableauDSError,
    isLoading: isTableauDSLoading,
  } = useFetchTableauDataSourceRelatedDashboards(guid, {
    enabled: objectType === 'tableaudatasource',
    params: {
      ...filterParams,
      include_hidden: shouldIncludeHidden,
      order: orderParam as GetBiTableauDatasourceRelatedDashboardsListQueryParams['order'],
      relevant_lineage: organization?.settings?.useRelevantLineage ? isRelevantLineage : undefined,
    },
  });

  const isLoading =
    isTableLoading || isJobLoading || isExploreLoading || isLookMLViewLoading || isTableauDSLoading;

  const isError =
    isTableError || isJobError || isExploreError || isLookMLViewError || isTableauDSError;

  if (isError) return <TabError />;

  const relatedDashboards: RelatedDashboardModel[] | undefined = !isLoading
    ? tableData?.results ??
      jobData?.results ??
      exploreData?.results ??
      lookmlViewData?.results ??
      tableauDSData?.results
    : undefined;

  const itemCount: number = !isLoading
    ? tableData?.count ?? exploreData?.count ?? lookmlViewData?.count ?? tableauDSData?.count ?? 0
    : 0;

  const config = dataTypes?.findConfig<RelatedDashboardsConfigItem>(RELATED_DASHBOARDS_CONFIG);
  const component =
    objectType === 'column' ? (
      <RelatedDashboardsList items={relatedDashboards} />
    ) : (
      <Box compDisplay="flex" compWidth="100%" flexDirection="column" gap={0.75} mt={2.25}>
        <RelatedDashboardsTable
          customColumProps={config?.customColumProps}
          customTopBarContent={
            objectType !== 'dashboard' && (
              <Box alignItems="center" compDisplay="flex" justifyContent="space-between">
                <ExportToCsv
                  assetName={assetName}
                  guid={guid}
                  includeHidden={shouldIncludeHidden}
                  isRelevantLineage={isRelevantLineage}
                  objectType={objectType}
                  order={orderParam}
                />
                {organization?.settings?.useRelevantLineage && (
                  <RelevantLineageToggle
                    checked={isRelevantLineage}
                    onChange={(e) => setIsRelevantLineage(Boolean(e.target.checked))}
                  />
                )}
              </Box>
            )
          }
          data={relatedDashboards}
          filterService={FilterService}
          itemCount={itemCount}
          loading={isLoading}
          reloadData={refetch}
          stickyHeader={stickyHeader}
          visibleColumns={[
            'name',
            'createdBy',
            'topUser',
            ...((showDistance ? ['distance'] : []) as ColumnKey[]),
            'updatedAt',
            'lastRun',
            'popularity',
          ]}
        />
      </Box>
    );

  return component;
};

export default React.memo(RelatedDashboards);
