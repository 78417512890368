import React from 'react';

import Box from '@components/Box';
import Text from '@components/Text';
import Icon from '@components/UI/Icon';
import { useUserContext } from '@context/User';
import useNewLayout from '@hooks/useNewLayout';
import RecentSearches from '@pages/MainPage/GreetingsSection/RecentSearches/RecentSearches';
import theme from '@styles/theme';

import { StyledGreetingsSection } from './GreetingsSection.styles';

const greetingsConfig = [
  { end: 6, message: 'Good evening' },
  { end: 12, message: 'Good morning' },
  { end: 18, message: 'Good afternoon' },
  { end: 24, message: 'Good evening' },
];

const getHelloMessage = (hour: number) => {
  const config = greetingsConfig.find((entry) => hour < entry.end);

  return config ? config.message : 'Hello';
};

interface GreetingsSectionProps {
  hour?: number;
}

const GreetingsSection: React.FC<GreetingsSectionProps> = ({ hour = new Date().getHours() }) => {
  const { user } = useUserContext();
  const { shouldUseNewLayout } = useNewLayout();
  const name = user?.firstName;

  return (
    <StyledGreetingsSection useNewLayout={shouldUseNewLayout}>
      <Box compDisplay="flex" flexWrap="wrap" gap={1}>
        <Text
          as="h1"
          color="gray.900"
          display="block"
          fontSize="h1"
          fontWeight="semibold"
          lineHeight={theme.typography.lineHeights.sm}
          mb={1}
        >
          {getHelloMessage(hour)}
          {name && `, ${name}`}
        </Text>
        <Icon name="emoji-waving-hand" size="30px" />
      </Box>
      <Text color="gray.600" fontSize="default">
        Find the data you want to explore today.
      </Text>
      <RecentSearches />
    </StyledGreetingsSection>
  );
};

export default GreetingsSection;
