import React from 'react';

import type { TypedCell } from '@components/Table/Cells/types';
import TaggedItems from '@components/TaggedItems';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import useBulkEditSelected, { SelectedBulkItem } from '@hooks/useBulkEditSelected';

import { StyledCellWithButtons } from './EditableTaggedItemCell.styles';

interface EditableTagsCellProps<T> {
  hideCategoryTags?: boolean;
  isDataSourceEditable: boolean;
  obj?: T;
}

const EditableTaggedItemCell = <T extends SelectedBulkItem>({
  hideCategoryTags,
  isDataSourceEditable,
  obj,
}: Partial<TypedCell> & EditableTagsCellProps<T>) => {
  const { isEditable: isObjectEditable } = useObjectPermissionsContext({ id: obj?.guid });
  const isEditable = isDataSourceEditable && isObjectEditable;
  const { selected, tagsCounts } = useBulkEditSelected<T>({
    defaultSelected: obj ? [obj] : undefined,
  });

  return (
    <StyledCellWithButtons compDisplay="flex" compWidth="100%" justifyContent="space-between">
      <TaggedItems
        hideCategoryTags={hideCategoryTags}
        isEditable={isEditable}
        objects={selected.items}
        showEllipsis
        taggedItemsCounts={tagsCounts}
      />
    </StyledCellWithButtons>
  );
};

export default EditableTaggedItemCell;
