import styled from '@emotion/styled';

import TableStyled from '../TableStyled';

interface Props {
  hideUserColumn?: boolean;
}

const TablesQueryTableStyled = styled(TableStyled)<Props>`
  .ui.table thead th {
    padding: 0.92857143em 0.78571429em;
  }

  .ui.table tr {
    td,
    th {
      ${({ hideUserColumn }) =>
        hideUserColumn
          ? `&:nth-of-type(1) {
            width: 70%;
          }
          &:nth-of-type(2) {
            width: 15%;
            text-align: center;
          }
          &:nth-of-type(3) {
            width: 15%;
            text-align: center;
          }`
          : `&:nth-of-type(1) {
            width: 55%;
          }
          &:nth-of-type(2) {
            text-align: center;
            width: 15%;
          }
          &:nth-of-type(3) {
            width: 15%;
            text-align: center;
          }
          &:nth-of-type(4) {
            width: 15%;
            text-align: center;
          }`}
    }
  }
`;

export default TablesQueryTableStyled;
