import React, { useMemo, useState } from 'react';
import type { Cell } from 'react-table';

import type DbtWarehouseLinkModel from '@api/dbt/DbtWarehouseLinkModel';
import Highlighter from '@components/Highlighter';
import LinkedCell from '@components/Table/Cells/LinkedCell';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import { useUserContext } from '@context/User';
import { PaginatedResponse } from '@models/PaginatedResponse';
import type { FilterOptions, FilterServiceInterface } from '@utils/filters';

const ALL_COLUMNS = ['dataSource', 'name', 'type'] as const;

type ColumnKey = (typeof ALL_COLUMNS)[number];

export const DBT_TABLE_SEARCH_CONFIG: Record<ColumnKey, keyof FilterOptions> = {
  dataSource: 'search_data_source',
  name: 'search_name',
  type: 'search_type',
};

export const DBT_TABLE_SORT_CONFIG: Record<ColumnKey, string> = {
  dataSource: 'data_source',
  name: 'name',
  type: 'type',
};

interface DbtTableProps {
  data?: PaginatedResponse<DbtWarehouseLinkModel>;
  filterService: FilterServiceInterface;
  isLoading?: boolean;
}

const DbtTable: React.FC<DbtTableProps> = ({ data, filterService, isLoading }) => {
  const [isShowFilter, setIsShowFilter] = useState(false);
  const { changePage, filter, initialTableSortState, search, sort } = filterService;
  const { dataSources } = useUserContext();

  const columns: ColumnConfig<DbtWarehouseLinkModel>[] = useMemo(
    () => [
      {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      {
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ column, row: { original }, state }: Cell<DbtWarehouseLinkModel>) => (
          <LinkedCell
            column={column}
            item={dataSources?.[original?.dataSource!] ?? {}}
            noLink
            showIcon
            state={state}
          />
        ),
        Header: `Data Source (${data?.count ?? 0})`,
        accessor: (d) => d.dataSource,
        id: 'dataSource',
        width: '100%',
      },
      {
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ column, row: { original }, state }: Cell<DbtWarehouseLinkModel>) => (
          <LinkedCell
            column={column}
            item={{ ...original.dbtModel, breadcrumbLabelList: original.breadcrumbLabelList }}
            showIcon
            showNameTooltip
            state={state}
          />
        ),
        Header: 'Name',
        accessor: (d) => d.dbtModel?.name,
        id: 'name',
        width: '100%',
      },
      {
        Cell: ({ column, row: { original } }: Cell<DbtWarehouseLinkModel>) => (
          <Highlighter
            autoEscape
            searchWords={[column?.filterValue]}
            textToHighlight={original.dbtModel.dataTypes?.dataType}
          >
            {original.dbtModel?.dataTypes?.dataType}
          </Highlighter>
        ),
        Header: 'Type',
        accessor: (d) => d.dbtModel?.dataTypes?.dataType,
        id: 'type',
        width: '100%',
      },
    ],
    [data?.count, dataSources],
  );

  const totalPages = data && filter.page_size ? Math.ceil(data.count / filter.page_size) : 1;

  return (
    <TableStyled>
      <Table
        basic="very"
        changePage={changePage}
        columns={columns}
        compact
        data={data?.results ?? []}
        disableColumnFiltering
        disableRowSelect
        initialState={{
          sortBy: initialTableSortState,
        }}
        loading={isLoading}
        manualFilters
        manualSortBy
        selectable
        setFilters={search}
        setSortBy={sort}
        showFilter={isShowFilter}
        sortable
        toggleFilter={() => {
          setIsShowFilter((prev) => !prev);
        }}
        totalPages={totalPages}
        unstackable
      />
    </TableStyled>
  );
};

export default DbtTable;
