import React, { useMemo } from 'react';

import { useFetchDescriptionAi } from '@api/description';
import { DescriptionSource } from '@api/description/description.types';
import { exploresCacheKeys } from '@api/explores';
import { fieldsCacheKeys } from '@api/fields';
import invalidateCache from '@api/invalidateCache';
import { tableauCacheKeys } from '@api/tableau';
import { viewsCacheKeys } from '@api/views';
import { isSuggestedDescription } from '@components/RichTextDescriptionEditor/RichTextDescriptionEditor.utils';
import Card from '@components/UI/Card';
import useClickOutside from '@hooks/useClickOutside';
import fetchClient from '@lib/fetchClient';
import { ValidDSType } from '@models/DataSourceCredentials';
import { MetadataModel } from '@models/MetadataModel';

import DescriptionSelectorItem from '../DescriptionSelectorItem';

export interface DescriptionObj {
  plainValue?: string;
  source?: DescriptionSource;
  value?: string;
}

export interface DescriptionListProps {
  dataSourceType?: ValidDSType;
  descriptionSource?: DescriptionSource;
  descriptions: DescriptionObj[];
  guid?: string;
  onClickOutside?: () => void;
  onSelectDescription?: ({
    description,
    descriptionSource,
    richTextDescription,
  }: {
    description?: string;
    descriptionSource?: DescriptionSource;
    richTextDescription?: string;
  }) => void;
  parentGuid?: string;
  suggestedDescriptionSourceObject?: MetadataModel;
  useAiDescriptions?: boolean;
}

export const hasAiDescription = (descriptions: DescriptionObj[]) =>
  Boolean(descriptions.find((description) => description.source === 'ai'));

const DescriptionList: React.FC<DescriptionListProps> = ({
  dataSourceType,
  descriptionSource,
  descriptions,
  guid,
  onClickOutside,
  onSelectDescription,
  parentGuid,
  suggestedDescriptionSourceObject,
  useAiDescriptions = true,
}) => {
  const useClickOutsideRef = useClickOutside<HTMLDivElement>({
    onClick: () => {
      onClickOutside?.();
    },
  });

  useFetchDescriptionAi(guid || '', {
    enabled: useAiDescriptions && !hasAiDescription(descriptions),
    onSuccess: () => {
      invalidateCache((keys) => [
        keys.columns.all,
        keys.biTables.all,
        keys.biNotebooks.all,
        keys.biColumns.all,
        keys.biDashboardElements.all,
        keys.dashboards.all,
        keys.metadata.metadata(guid ?? ''),
        keys.tables.all,
        keys.queries.all,
        keys.datasets.all,
        keys.taggedItems.all,
        keys.lookML.views,
      ]);
      fetchClient.invalidateQueries(exploresCacheKeys.explores);
      fetchClient.invalidateQueries(exploresCacheKeys.exploreFields(parentGuid || ''));
      fetchClient.invalidateQueries(fieldsCacheKeys.fields);
      fetchClient.invalidateQueries(tableauCacheKeys.tableauFields(parentGuid || ''));
      fetchClient.invalidateQueries(viewsCacheKeys.views);
      fetchClient.invalidateQueries(tableauCacheKeys.datasources);
    },
  });

  const handleDescriptionItemClick = (descriptionItem: DescriptionObj) => {
    onSelectDescription?.({
      description: descriptionItem?.plainValue,
      descriptionSource: descriptionItem.source,
      richTextDescription: descriptionItem.value,
    });
  };

  const finalDescriptions = useMemo(() => {
    const newDescriptions = [...descriptions];

    if (!useAiDescriptions) {
      return newDescriptions.filter((description) => description.source !== 'ai');
    }

    if (!hasAiDescription(newDescriptions)) {
      newDescriptions.push({ source: 'ai', value: undefined });
    }

    return newDescriptions;
  }, [descriptions, useAiDescriptions]);

  return (
    <Card
      ref={useClickOutsideRef}
      aria-label="description menu"
      compDisplay="flex"
      compWidth="360px"
      flexDirection="column"
      gap={0.75}
      onClick={(e) => {
        e.stopPropagation();
      }}
      overscrollBehavior="contain"
      role="listbox"
    >
      {finalDescriptions.map((description) => {
        if (!useAiDescriptions && description.source === 'ai') {
          return null;
        }

        return (
          <DescriptionSelectorItem
            key={description.source}
            dataSourceType={dataSourceType}
            guid={guid}
            onClick={(e) => {
              e.stopPropagation();
              handleDescriptionItemClick(description);
            }}
            plainDescription={description.plainValue}
            plainUserDescription={description.source === 'user' ? description.plainValue : ''}
            selected={
              descriptionSource
                ? descriptionSource === description.source
                : isSuggestedDescription(description.source)
            }
            source={description.source}
            suggestedDescriptionSourceObject={suggestedDescriptionSourceObject}
          />
        );
      })}
    </Card>
  );
};

export default DescriptionList;
