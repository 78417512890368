export const all = ['lineage'];

export const erd = ['erd'];

export const lineage = (id: string) => ['lineage', id];

export const lineageCsv = (id: string) => ['lineage', id, 'csv'];

export const lineageNew = (id: string) => ['lineage', id, 'new'];

export const lineageNodes = ['lineage', 'nodes'];

export const sourceColumns = (id: string) => ['lineage', 'source-columns', id];

export const sourceTables = (id: string) => ['lineage', 'source-tables', id];
