import React from 'react';
import { RenderElementProps } from 'slate-react';

import { CustomElementType, RichTextEditorElement } from '../RichTextEditor.types';

import { BlockquoteElement } from './BlockquoteElement.styles';
import { CodeElement } from './CodeElement.styles';
import {
  H1Element,
  H2Element,
  H3Element,
  H4Element,
  H5Element,
  H6Element,
} from './HeadingElement.styles';
import ImageElement from './ImageElement';
import LinkElement from './LinkElement';
import MentionElement from './MentionElement';
import { OLElement } from './OLElement.styles';
import { ParagraphElement } from './ParagraphElement.styles';
import TableCellElement from './TableCellElement';
import TableElement from './TableElement';
import { THeadElement, TRElement } from './TableElements.styles';
import { ULElement } from './ULElement.styles';

type RenderElementMapItem = CustomElementType | 'default';

export type RenderElementMap = PartialRecord<
  RenderElementMapItem,
  React.ElementType<RenderElementProps>
>;

/**
 * Map of elements that should be rendered for specific custom element types.
 * See: https://docs.slatejs.org/walkthroughs/03-defining-custom-elements for more info on defining custom elements.
 */
const renderElementMap: RenderElementMap = {
  blockquote: BlockquoteElement,
  br: (props) => (
    <span {...props.attributes}>
      <br contentEditable={false} />
      {props.children}
    </span>
  ),
  code: CodeElement,
  codeblock: (props) => (
    <pre {...props.attributes}>
      <CodeElement>{props.children}</CodeElement>
    </pre>
  ),
  default: (props) => <p {...props.attributes}>{props.children}</p>,
  em: (props) => <em {...props.attributes}>{props.children}</em>,
  fragment: ParagraphElement,
  h1: H1Element,
  h2: H2Element,
  h3: H3Element,
  h4: H4Element,
  h5: H5Element,
  h6: H6Element,
  image: ImageElement,
  li: (props) => <li {...props.attributes}>{props.children}</li>,
  link: LinkElement,
  mention: MentionElement,
  ol: (props) => <OLElement level={props.element.level} {...props} />,
  paragraph: ParagraphElement,
  pre: ParagraphElement,
  span: (props) => <span {...props.attributes}>{props.children}</span>,
  strong: (props) => <strong {...props.attributes}>{props.children}</strong>,
  table: TableElement,
  tbody: (props) => <tbody {...props.attributes}>{props.children}</tbody>,
  td: TableCellElement,
  th: TableCellElement,
  thead: (props) => <THeadElement {...props.attributes}>{props.children}</THeadElement>,
  tr: (props) => <TRElement {...props.attributes}>{props.children}</TRElement>,
  u: (props) => <u {...props.attributes}>{props.children}</u>,
  ul: (props) => <ULElement level={props.element.level} {...props} />,
};

const renderElement =
  (elementMap?: RenderElementMap, allowedElements?: RichTextEditorElement[]) =>
  (props: RenderElementProps) => {
    const { element } = props;
    let finalElementMap = { ...renderElementMap, ...(elementMap ?? {}) };

    if (allowedElements) {
      finalElementMap = Object.entries(finalElementMap).reduce((newElements, [key, value]) => {
        return allowedElements.includes(key as CustomElementType)
          ? { ...newElements, [key]: value }
          : newElements;
      }, {} as any);
    }

    const Component = finalElementMap[element.type] ?? ParagraphElement;

    return <Component {...props} />;
  };

export default renderElement;
