import React from 'react';
import { useHistory, useParams } from '@routing/router';

import BIColumnPage from '@pages/BIColumnPage';

interface BIColumnPageRoutedProps {
  isDataSourceEditable: boolean;
}

const BIColumnPageRouted: React.FC<BIColumnPageRoutedProps> = ({ isDataSourceEditable }) => {
  const history = useHistory();
  const { columnGuid } = useParams<{ columnGuid: string; guid: string }>();

  const handleClose = () => {
    history.push('../..');
  };

  return (
    <BIColumnPage
      guid={columnGuid}
      isDataSourceEditable={isDataSourceEditable}
      onClose={handleClose}
    />
  );
};

export default React.memo(BIColumnPageRouted);
