import React from 'react';

interface Props {
  className?: string;
  count?: number;
  value?: string;
}

const ItemsCount: React.FC<Props> = ({ className, count = 0, value }) => {
  return <span className={className}>{`${count} ${value}`}</span>;
};

export default React.memo(ItemsCount);
