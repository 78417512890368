import styled from '@emotion/styled';
import { RouterLink } from '@routing/router';

import Box from '@components/Box';
import getLayoutComponent from '@utils/getLayoutComponent';

export const StyledNotification = styled(Box)``;

StyledNotification.defaultProps = {
  backgroundColor: 'white',
  border: '1px solid',
  borderColor: 'gray.300',
  boxShadow: '4xl',
  compWidth: '340px',
  maxHeight: '640px',
  maxWidth: '340px',
  minHeight: '100px',
  minWidth: 'min-content',
  noDefault: true,
  overflowX: 'hidden',
  overflowY: 'auto',
};

export const StyledNotificationItem = styled.div<{ unread?: boolean }>`
  background-color: ${({ unread }) => (unread ? '#f2f5f9' : '#ffffff')};
  display: flex;
  flex-grow: 0;
  padding: 0.833em 1em;
  justify-content: space-between;
  word-break: break-word;
`;

export const StyledNotificationHeadingContainerV0 = styled.div`
  display: flex;
  flex-grow: 0;
  align-items: center;
  justify-content: space-between;
  padding: 0.833em 1em;
  border-bottom: 1px solid #dededf;
`;

export const StyledNotificationHeadingContainerV1 = styled(StyledNotificationHeadingContainerV0)`
  padding-right: ${({ theme }) => theme.space(0.5)};
  padding-top: ${({ theme }) => theme.space(0.75)};
  padding-bottom: ${({ theme }) => theme.space(0.75)};
`;

export const StyledNotificationHeadingContainer = getLayoutComponent(
  StyledNotificationHeadingContainerV0,
  StyledNotificationHeadingContainerV1,
);

export const StyledNotificationShowMoreContainerV0 = styled.div`
  display: flex;
  flex-grow: 0;
  justify-content: center;
  padding: 0.833em 1em;
  border-top: 1px solid #dededf;
`;

export const StyledNotificationShowMoreContainerV1 = styled(StyledNotificationShowMoreContainerV0)`
  padding-top: ${({ theme }) => theme.space(0.75)};
  padding-bottom: ${({ theme }) => theme.space(0.75)};
`;

export const StyledNotificationShowMoreContainer = getLayoutComponent(
  StyledNotificationShowMoreContainerV0,
  StyledNotificationShowMoreContainerV1,
);

export const StyledNotificationDescription = styled(Box)`
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  font-size: 13px;
  line-height: 1.54;
  color: #2c2e36;
`;

export const StyledNotificationDescriptionContainer = styled(StyledNotificationDescription)``;

StyledNotificationDescriptionContainer.defaultProps = {
  alignItems: 'center',
  compDisplay: 'inline-flex',
  flexWrap: 'wrap',
  gap: 0.5,
  whiteSpace: 'pre',
};

export const StyledNotificationTime = styled.span`
  display: flex;
  flex-shrink: 0;
  font-size: 11px;
  color: ${({ theme }) => theme.colors.v1.gray[400]};
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  margin-left: ${({ theme }) => theme.space(1)};
`;

export const StyledNotificationHeading = styled.span`
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  font-size: 13px;
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
  text-align: left;
  color: #2c2e36;
`;

export const StyledNotificationButton = styled.span`
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary} !important;
  font-size: 11px;
  text-align: right;
  color: #8c939b;
`;

export const StyledNotificationText = styled(RouterLink)`
  display: flex;
  font-size: ${({ theme }) => theme.typography.fontSizes.sm};
`;
