import React from 'react';

import { useFetchAnalyticsActivitiesGrouped } from '@api/analytics';
import DateRangePicker from '@components/DateRangePicker';
import { Column } from '@components/Grid';
import TabError from '@components/TabContent/TabError';
import SectionTitle, {
  SectionTitleWrapper,
} from '@pages/Admin/AdminCostAnalysisPage/tabs/SectionTitle';
import { Filter } from '@utils';

import { DateRangeProps } from '../../Analytics.types';

import TopPagesEditedTable from './tables/TopPagesEditedTable';

export const topPagesEditedDefaultFiltersConfig: Filter.FilterOptions = {
  order: '-count',
  page: 1,
  page_size: 50,
  sortColumn: 'count',
  sortDirection: 'descending',
};

export const topPagesEditedSortConfig: { [key: string]: string } = {
  count: 'count',
  lastEdited: 'last_activity',
  page: 'page',
};

const AnalyticsActivitiesTopPagesEdited: React.FC<DateRangeProps> = ({
  dateRange: [start_date, end_date],
  onDateRangeChange,
}) => {
  const filterService = Filter.useUpdateFilters(
    topPagesEditedDefaultFiltersConfig,
    {},
    topPagesEditedSortConfig,
    '-count',
  );

  const { data, isError, isLoading } = useFetchAnalyticsActivitiesGrouped({
    params: {
      ...Filter.setParams(filterService.filter),
      end_date,
      start_date,
      types: 'update',
    },
  });

  const handleFilterChange = (range: [string, string]) => {
    onDateRangeChange(range);
    filterService.changePage(1);
  };

  const totalPages = data
    ? Math.ceil(data.count / topPagesEditedDefaultFiltersConfig.page_size!)
    : 1;

  if (isError) {
    return <TabError />;
  }

  return (
    <>
      <SectionTitleWrapper my={3}>
        <SectionTitle
          subtitle="Based on all edits on tables, columns, dashboards and charts"
          title="Top Pages Edited"
        />
        <DateRangePicker initialRange={[start_date, end_date]} onChange={handleFilterChange} />
      </SectionTitleWrapper>
      <Column xs={12}>
        <TopPagesEditedTable
          detailed
          filterService={filterService}
          loading={isLoading}
          rows={data?.results}
          totalPages={totalPages}
        />
      </Column>
    </>
  );
};

export default AnalyticsActivitiesTopPagesEdited;
