import moment from 'moment';

import type { JobEvent as JobEventResponse } from '@api/openapi.generated';
import type { ObjectType } from '@api/types';
import { rawMap } from '@api/utils';
import formatNumber from '@utils/formatNumber';
import titlelize from '@utils/titlelize';

import { STATUS_CONFIG } from './JobModel.v1';

const objectType: ObjectType = 'job';

const mapJobEventModel = (value: Partial<JobEventResponse>) => {
  const formattedResult = STATUS_CONFIG[value?.result ?? 'UNKNOWN'];
  return {
    duration: rawMap(
      (v) => `${formatNumber({ value: moment.duration(v).as('seconds') })}s`,
      value?.duration,
    ),
    endTime: rawMap(moment, value?.end_time),
    formattedResult,
    guid: value?.guid,
    result: rawMap(titlelize, value?.result),
    runId: value?.run_id,
    runUrl: value?.run_url,
    startTime: rawMap(moment, value?.start_time),
  };
};

export default mapJobEventModel;

mapJobEventModel.objectType = objectType;

export type JobEventModel = ReturnType<typeof mapJobEventModel>;
