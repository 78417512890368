import React, { useCallback, useEffect, useRef } from 'react';
import { useParams } from '@routing/router';
import { uniqBy } from 'lodash';

import { useFetchLookMLProject, useFetchLookMLViews } from '@api/lookML';
import { LookMLViewModel } from '@api/lookML/LookMLViewModel';
import Box from '@components/Box';
import Breadcrumbs from '@components/Breadcrumbs';
import BulkButtons from '@components/BulkButtons/BulkButtons';
import CircularLoader from '@components/CircularLoader';
import ItemsCount from '@components/ItemsCount';
import LayoutMainGrid from '@components/Layout/LayoutMainGrid';
import PageHeaderBar from '@components/PageHeaderBar';
import Icon from '@components/UI/Icon';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import { useUserContext } from '@context/User';
import useBulkEditSelected from '@hooks/useBulkEditSelected';
import { StyledTablesPage } from '@pages/TablesPage/TablesPage.styles';
import { Filter } from '@utils';
import MetadataDecorator from '@utils/MetadataDecorator';

import ViewFilters from './Filters';
import LookMLViewsTable from './LookMLViewsTable';

const searchConfig: { [key: string]: keyof Filter.FilterOptions } = {
  description: 'search_description',
  label: 'search_label',
  name: 'search_name',
  tags: 'search_tags',
};

const sortConfig: { [key: string]: string } = {
  description: 'description',
  impactScore: 'impact_score',
  label: 'label',
  name: 'name',
  popularity: 'popularity',
};

export const defaultViewConfig: Filter.FilterOptions = {
  order: '-name',
  page: 1,
  page_size: 100,
  sortColumn: 'name',
  sortDirection: 'descending',
};

interface LookMLViewsPageProps {
  defaultConfig?: Filter.FilterOptions;
  showMainContentOnly?: boolean;
}

const LookMLViewsPage: React.FC<LookMLViewsPageProps> = ({
  defaultConfig = defaultViewConfig,
  showMainContentOnly,
}) => {
  const { dsGuid, guid } = useParams<{ dsGuid: string; guid?: string }>();
  const allFetchedViews = useRef<LookMLViewModel[]>([]);
  const { reset, selected, setSelected, tagsCounts } = useBulkEditSelected<LookMLViewModel>({
    key: `${dsGuid} ${guid}`,
  });
  const { dataSources, hasEditPermissions } = useUserContext();
  const FilterService = Filter.useUpdateFilters(defaultConfig, searchConfig, sortConfig, '-name');
  const { filter } = FilterService;
  const { isPbac, permissions } = useObjectPermissionsContext({ id: guid });
  const selectedEditableItems = selected.items.filter((item) =>
    isPbac ? permissions[item.guid]?.isEditable : true,
  );

  const {
    data: viewResponse,
    isLoading,
    refetch: refetchViews,
  } = useFetchLookMLViews({
    params: Filter.setParams({
      ...filter,
      datasources: dsGuid ? [dsGuid] : undefined,
      projects: guid ? [guid] : undefined,
    }),
  });

  const { data: projectResponse, isLoading: projectIsLoading } = useFetchLookMLProject(guid || '', {
    enabled: Boolean(guid),
  });

  useEffect(() => {
    allFetchedViews.current = uniqBy(
      [...(viewResponse?.results ?? []), ...allFetchedViews.current],
      'guid',
    );
  }, [viewResponse?.results]);

  const handleSetSelectRowIds = useCallback(
    (selectedRowIds) => {
      setSelected(allFetchedViews.current?.filter((i) => selectedRowIds?.[i.guid]));
    },
    [setSelected],
  );

  const projectDsGuid = projectResponse?.repository.dataSource;
  const showDefault = !projectIsLoading && guid;

  const views: LookMLViewModel[] | undefined =
    !isLoading && viewResponse ? viewResponse?.results : undefined;

  const totalPages =
    viewResponse && filter.page_size ? Math.ceil(viewResponse.count / filter.page_size) : 1;

  const isDataSourceEditable = Boolean(
    dataSources?.[dsGuid || projectDsGuid || '']?.settings?.isEditable ?? true,
  );

  const mainContent = (
    <>
      <BulkButtons
        canEditOwner={hasEditPermissions}
        canEditTags
        canRemove={!filter.is_hidden}
        canUnHide={filter.is_hidden}
        isDataSourceEditable={isDataSourceEditable}
        onEdit={() => {
          refetchViews();
          reset();
          handleSetSelectRowIds({});
        }}
        selectedEditableItems={selectedEditableItems}
        selectedItems={selected.items}
        showText
        tagsCounts={tagsCounts}
      />
      <Box mb={0.5}>
        <LookMLViewsTable
          data={views}
          filterService={FilterService}
          isDataSourceEditable={isDataSourceEditable}
          itemCount={viewResponse?.count}
          selectedRowIds={selected.ids}
          setSelectedRowIds={handleSetSelectRowIds}
          totalPages={totalPages}
        />
      </Box>
    </>
  );

  if (showMainContentOnly) {
    return mainContent;
  }

  return (
    <Box compDisplay="flex">
      <LayoutMainGrid>
        <MetadataDecorator title={showDefault ? projectResponse?.name : 'Views'} />
        <StyledTablesPage>
          <PageHeaderBar
            icon={<Icon name={showDefault ? 'folder' : 'looker-views'} />}
            sticky
            supIcon={<Icon name="looker" />}
            supTitle={
              projectResponse?.breadcrumbList ? (
                <Breadcrumbs items={projectResponse?.breadcrumbList} />
              ) : null
            }
            title={showDefault ? projectResponse?.name : 'Views'}
            titleSuffix={
              <ItemsCount className="subtitle" count={viewResponse?.count} value="Views" />
            }
          />
          {mainContent}
        </StyledTablesPage>
      </LayoutMainGrid>
      <React.Suspense fallback={<CircularLoader borderWidth={1} compSize={1} />}>
        <ViewFilters filterService={FilterService} />
      </React.Suspense>
    </Box>
  );
};

export default React.memo(LookMLViewsPage);
