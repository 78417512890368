import styled from '@emotion/styled';

import { StyledTab } from '@components/Tabs';
import Search from '@components/UI/Search';
import {
  StyledMainInputContainer,
  StyledMainInputPlaceholder,
} from '@components/UI/Select/MainInput/MainInput.styles';
import rootTheme from '@styles/theme';

import DatasourceTabsV1 from './DatasourceTabs/DatasourceTabsV1';

export const StyledSearchBarDatasourceTabsV1 = styled(DatasourceTabsV1)`
  ${StyledTab} {
    height: 50px;
  }
`;

export const StyledSearchBarSearch = styled(Search)`
  background-color: ${({ theme }) => theme.colors.v1.gray[100]};
  border-color: ${({ theme }) => theme.colors.v1.gray[100]};
  font-size: ${({ theme }) => theme.typography.fontSizes.body2};
  border-radius: ${({ theme }) => theme.radius.md};
  height: 2.5rem !important;
  border-width: 1px !important;
  padding-left: ${({ theme }) => theme.space(0.8)};
  z-index: 1;
  ${({ isOpen }) =>
    isOpen &&
    `
      border-color: ${rootTheme.colors.v1.gray[500]};
      background-color: ${rootTheme.colors.v1.gray[100]};
       outline: none !important;
    `};

  &:focus,
  &:focus-within {
    border-color: ${rootTheme.colors.v1.gray[500]};
    background-color: ${rootTheme.colors.v1.gray[100]};
    outline: none !important;
  }
  ${StyledMainInputContainer} {
    color: ${({ theme }) => theme.colors.v1.gray[900]};
  }
  ${StyledMainInputPlaceholder} {
    left: 37px;
    color: ${({ theme }) => theme.colors.v1.gray[500]};
    letter-spacing: 0;
    font-size: ${({ theme }) => theme.typography.fontSizes.body2};
    opacity: 0.6;
    font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
  }
`;

export const StyledSearchBarBackdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #111111;
  opacity: 0.5;
  z-index: 1;
`;
