import { CollectionModel } from '@api/collections/CollectionModel';
import { Option, SelectValue } from '@components/UI/Select';
import stripSpaces from '@utils/stripSpaces';

export interface CreateCollectionFormProps {
  guid?: string;
  initialData?: CollectionModel;
  onClose?: () => void;
  onSuccess?: () => void;
}

export interface CreateCollectionFormValues {
  businessOwner?: SelectValue;
  description?: string;
  name?: string;
  parent?: SelectValue;
  richtextDescription?: string;
  technicalOwner?: SelectValue;
}

export const COLLECTION_QUERY = stripSpaces(`{
  guid,
  name,
  icon,
  tagged_items,
  data_types,
  technical_owner {
    object_type,
    obj
  },
  business_owner {
    object_type,
    obj
  },
  description,
  richtext_description,
  updated_on,
  created_on,
  breadcrumbs
}`);

export const getCollectionInitialState = ({
  businessOwnerOption,
  initialState,
  parentOption,
  technicalOwnerOption,
}: {
  businessOwnerOption: Option[];
  initialState?: CollectionModel;
  parentOption?: Option[];
  technicalOwnerOption: Option[];
}) => {
  return {
    businessOwner: businessOwnerOption,
    description: initialState?.description ?? '',
    name: initialState?.name ?? '',
    parent: parentOption,
    richtextDescription: initialState?.richtextDescription ?? '',
    technicalOwner: technicalOwnerOption,
  };
};
