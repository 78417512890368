import React from 'react';

import { useFetchExploreFields } from '@api/explores';
import { LookerExploreFieldModel } from '@api/explores/LookerExploreFieldModel';
import Box from '@components/Box/Box.styles';
import BulkButtons from '@components/BulkButtons';
import TabError from '@components/TabContent/TabError';
import { ExploreFieldTable } from '@components/Table';
import { TabContentProps } from '@components/Tabs/types';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import useBulkEditSelected from '@hooks/useBulkEditSelected';
import { Filter } from '@utils';
import stripSpaces from '@utils/stripSpaces';

const query = stripSpaces(`{
	guid,
  joined_view_label,
  parent_guid,
  popularity,
  is_metric,
  is_mention,
  tagged_items,
  search_name,
  data_types,
  field {
    guid,
    label,
    name,
    description,
    richtext_description,
    suggested_description,
    suggested_description_source,
    suggested_description_source_object,
    view_label,
    external_type,
    type,
    field_type,
    data_type,
    is_used_as_filter,
    is_used_as_pivot,
    is_metric,
    description_source,
    ai_description,
    ingested_description,
    data_types,
    user_description,
    view {
      label,
      data_types,
      guid,
      name
    }
  }
}`);

const defaultExploreFieldConfig: Filter.FilterOptions = {
  order: '-popularity',
  page: 1,
  page_size: 50,
  query,
  sortColumn: 'popularity',
  sortDirection: 'descending',
};

const searchConfig: { [key: string]: keyof Filter.FilterOptions } = {
  description: 'search_description',
  name: 'search_name',
  view: 'search_view',
};

const sortConfig: { [key: string]: string } = {
  description: 'description',
  name: 'name',
  popularity: 'popularity',
  view: 'view',
};

interface ExploreFieldsTabProps extends TabContentProps {
  guid: string;
  isDataSourceEditable: boolean;
  stickyHeader?: boolean;
}

const ExploreFieldsTab: React.FC<ExploreFieldsTabProps> = ({
  guid,
  isDataSourceEditable,
  stickyHeader,
}) => {
  const { isEditable: isObjectEditable } = useObjectPermissionsContext({ id: guid });
  const FilterService = Filter.useUpdateFilters(
    defaultExploreFieldConfig,
    searchConfig,
    sortConfig,
  );
  const { filter } = FilterService;

  const effectiveConfig = {
    params: {
      ...Filter.setParams(filter),
      force_showing_suggested_description: true,
    },
  };

  const { data, isError } = useFetchExploreFields(guid, {
    params: effectiveConfig.params,
  });

  const totalPages = data && filter.page_size ? Math.ceil(data.count / filter.page_size) : 1;

  const { selected } = useBulkEditSelected<LookerExploreFieldModel>({
    defaultSelected: data?.results,
  });

  if (isError) return <TabError />;

  return (
    <>
      <Box compDisplay="flex" justifyContent="end" my={1}>
        <BulkButtons
          canUseBulkAssist={isObjectEditable}
          guid={guid}
          isDataSourceEditable
          selectedEditableItems={selected.items}
          selectedItems={selected.items}
        />
      </Box>
      <ExploreFieldTable
        data={data?.results}
        filterService={FilterService}
        isDataSourceEditable={isDataSourceEditable}
        itemCount={data?.count}
        stickyHeader={stickyHeader}
        totalPages={totalPages}
      />
    </>
  );
};

export default ExploreFieldsTab;
