import { renderErrorToast } from '@components/Toast';

const downloadCSV = (data: string, fileName: string) => {
  try {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.setAttribute('href', url);
    anchor.setAttribute('download', fileName);
    anchor.click();
  } catch {
    renderErrorToast('There was an error downloading the CSV file.');
  }
};

export default downloadCSV;
