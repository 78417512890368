import React, { useEffect } from 'react';
import { Redirect, useHistory } from '@routing/router';

import { usePostGoogleOAuth } from '@api/google';
import CircularLoader from '@components/CircularLoader';
import TextScreen from '@components/TextScreen';
import TwoColumnDecorativeComponent from '@components/TwoColumnDecorativeComponent';
import TwoColumnLayout from '@components/TwoColumnLayout';
import useUrlSearchParams from '@hooks/useUrlSearchParams';
import fetchClient from '@lib/fetchClient';
import SignUpPage from '@pages/SignUpPage';
import MetadataDecorator from '@utils/MetadataDecorator';

const GoogleSignUpRedirectPage: React.FC = () => {
  const query = useUrlSearchParams();
  const history = useHistory();
  const queryCode: string | null = query.get('code');
  const googleError: string | null = query.get('error');
  const state: string | null = query.get('state');
  const validState: { isLogin: boolean; redirect: string; utmCampaign?: boolean } = state
    ? JSON.parse(state)
    : null;

  const {
    error,
    isLoading,
    mutate: signUpGoogle,
  } = usePostGoogleOAuth({
    onSuccess: () => {
      fetchClient.clear();
      // false means this user is trying to sign up
      if (!validState?.isLogin) {
        history.push({
          pathname: '/signup-success',
          state: {
            redirect: validState?.redirect || '/',
          },
        });
      } else {
        history.push(validState?.redirect || '/');
      }
    },
    retry: false,
  });

  useEffect(() => {
    if (queryCode) {
      signUpGoogle({
        code: queryCode,
        is_login: validState?.isLogin || false,
        utm_campaign: validState?.utmCampaign,
      });
    }
  }, [queryCode, signUpGoogle, validState?.isLogin, validState?.utmCampaign]);

  let leftComponent = <CircularLoader centered />;

  if (!isLoading && !error) {
    leftComponent = <TextScreen text="Signing you in!" title="Sign Up Successful" />;
  }

  if (googleError) {
    return <SignUpPage errorMessage={googleError} />;
  }

  if (error) {
    const inviteLink = error.data?.invite_link;
    if (inviteLink) {
      return (
        <Redirect
          to={{
            pathname: '/request-invite',
            state: { inviteLink },
          }}
        />
      );
    }

    return (
      <SignUpPage
        errorMessage={
          error?.data?.user?.[0] ||
          error?.data?.code ||
          error?.data?.organization ||
          'Unexpected Server Error'
        }
      />
    );
  }

  return (
    <>
      <MetadataDecorator title="Google Sign Up" />
      <TwoColumnLayout left={leftComponent} right={<TwoColumnDecorativeComponent />} />
    </>
  );
};

export default GoogleSignUpRedirectPage;
