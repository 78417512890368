import type * as CSS from 'csstype';

import theme from '@styles/theme';
import type { ColorV1ValueString } from '@styles/theme/colors';

export type GetColorProps = ColorV1ValueString | CSS.Property.Color;

const getColor = (color?: GetColorProps) => {
  if (color) {
    const colors = theme.colors.v1 as any;
    const [variant, shade] = color.split('.');
    return colors?.[variant]?.[shade] ?? color;
  }
  return color;
};

export default getColor;
