import React from 'react';

import { DsUserModel } from '@api/dsusers/DsUserModel';
import { TeamModel } from '@api/teams/TeamModel';
import { UserModel } from '@api/user/UserModel';
import Box from '@components/Box';
import Avatar from '@components/UI/Avatar';
import { Reference } from '@models/Reference';

import { GroupParticipantsTooltipUser } from './GroupParticipants.styles';

export interface GroupParticipantsTooltipProps {
  participants: Reference<UserModel | TeamModel | DsUserModel>[];
}

export const PARTICIPANTS_LIST_LIMIT = 25;

const GroupParticipantsTooltip = ({ participants }: GroupParticipantsTooltipProps) => {
  const participantObjects = participants.map((ref) => ref.obj) as Array<
    UserModel | TeamModel | DsUserModel
  >;

  const trimmedParticipants = participantObjects.slice(0, PARTICIPANTS_LIST_LIMIT - 1);

  const extraParticipantsItem =
    participantObjects.length > PARTICIPANTS_LIST_LIMIT ? (
      <GroupParticipantsTooltipUser>
        + {participantObjects.length - PARTICIPANTS_LIST_LIMIT} more
      </GroupParticipantsTooltipUser>
    ) : null;

  return (
    <Box compDisplay="flex" flexDirection="column" gap={0.5}>
      {trimmedParticipants.map((participant) => {
        const validParticipant =
          participant instanceof DsUserModel ? participant.validOwner : participant;
        return (
          <Box
            key={validParticipant.guid}
            alignItems="center"
            compDisplay="flex"
            data-testid="participant"
            gap={0.5}
          >
            <Avatar {...validParticipant.mappedAvatar} size="16px" />
            <GroupParticipantsTooltipUser>{validParticipant.fullName}</GroupParticipantsTooltipUser>
          </Box>
        );
      })}
      {extraParticipantsItem}
    </Box>
  );
};

export default GroupParticipantsTooltip;
