import { Expose, Type } from 'class-transformer';

import { CustomAttributeModel } from '../customAttributes/CustomAttributeModel';

export interface CustomAttributeValue {
  customAttribute: CustomAttributeModel;
  dataSource: number;
  guid: string;
  item: string;
  organization: number;
  richtext_value: string;
  value: string;
}

export interface PartialCustomAttributeValue
  extends Partial<
    Record<keyof CustomAttributeValue, CustomAttributeValue[keyof CustomAttributeValue]>
  > {}

export class CustomAttributeValueModel {
  guid!: string;

  organization?: number;

  item!: string;

  value?: string;

  @Expose({ name: 'richtext_value' })
  richTextValue?: string;

  @Expose({ name: 'data_source' })
  dataSource?: number;

  @Expose({ name: 'custom_attribute' })
  @Type(() => CustomAttributeModel)
  customAttribute!: CustomAttributeModel;
}
