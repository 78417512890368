import styled from '@emotion/styled';

import Box from '@components/Box';
import { SelectAllOption } from '@components/UI/Select/OptionsList/OptionsList.styles';
import rootTheme from '@styles/theme';

import { StyledRelevantLineageToggleText } from '../RelevantLineageToggle/RelevantLineageToggle.styles';

export const StyledUsageFieldFilterOptionsContainer = styled(Box)`
  ${SelectAllOption} {
    font-size: ${({ theme }) => theme.typography.fontSizes.body1};
  }
`;

StyledUsageFieldFilterOptionsContainer.defaultProps = {
  noDefault: true,
};

interface StyledUsageFieldFilterAnchorProps {
  disabled?: boolean;
}

export const StyledUsageFieldFilterAnchor = styled(Box)<StyledUsageFieldFilterAnchorProps>`
  border: 1px solid ${({ theme }) => theme.colors.v1.gray[300]};
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

StyledUsageFieldFilterAnchor.defaultProps = {
  alignItems: 'center',
  backgroundColor: 'white',
  borderRadius: 'default',
  color: 'gray.800',
  compDisplay: 'flex',
  compHeight: rootTheme.space(4),
  fontSize: 'sm',
  gap: 1,
  justifyContent: 'center',
  px: 1.5,
};

export const StyledUsageFieldFilterActionContainer = styled(Box)``;

StyledUsageFieldFilterActionContainer.defaultProps = {
  borderColor: 'gray.300',
  borderTop: '1px solid',
  compDisplay: 'flex',
  gap: 1,
  justifyContent: 'flex-end',
  px: 1.25,
  py: 1,
};

export const StyledUsageFieldFilterOverlay = styled.div`
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
`;

export const StyledUsageFieldFilterHeader = styled(Box)``;

StyledUsageFieldFilterHeader.defaultProps = {
  backgroundColor: 'white',
  borderBottom: '1px solid',
  borderColor: 'gray.300',
  color: 'gray.500',
  compWidth: '100%',
  fontSize: 'sm',
  fontWeight: 'bold',
  p: 1,
};

export const StyledUsageFieldFilterRelevantLineageContainer = styled(Box)`
  font-weight: ${({ theme }) => theme.typography.fontWeights.bold};

  ${StyledRelevantLineageToggleText} {
    color: ${({ theme }) => theme.colors.v1.gray[500]};
    font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
    font-size: ${({ theme }) => theme.typography.fontSizes.sm};
  }
`;

StyledUsageFieldFilterRelevantLineageContainer.defaultProps = {
  borderColor: 'gray.300',
  borderTop: '1px solid',
  color: 'gray.500',
  fontWeight: 'bold',
  p: 1,
};
