import { Expose, Transform, Type } from 'class-transformer';

import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import type { DescriptionSource } from '@api/description/description.types';
import { LookerExploreModel } from '@api/explores/LookerExploreModel';
import { TeamModel } from '@api/teams/TeamModel';
import { ObjectType } from '@api/types';
import { UserModel } from '@api/user/UserModel';
import { MetadataObjectType } from '@atoms';
import { breadcrumbsToLabelList, breadcrumbsToList } from '@components/Breadcrumbs';
import isEmptyRichText from '@components/RichTextEditor/helpers/isEmptyRichText';
import type { DataSourceTypesType } from '@models/DataSourceCredentials';
import { DataSourceModel } from '@models/DataSourceModel';
import DataTypesModel from '@models/DataTypesModel';
import { PopularityModel } from '@models/PopularityModel';
import { Reference } from '@models/Reference';
import RelatedObjectsCountsModel from '@models/RelatedObjectsCountsModel';
import { urlFor } from '@utils/routing';

import { TaggedItemModel } from '../tags/TaggedItemModel';

export class LookMLViewModel {
  static objectType: ObjectType = 'lookmlview';

  objectType: ObjectType = LookMLViewModel.objectType;

  static typeDisplay: string = 'LookMLView';

  typeDisplay: string = LookMLViewModel.typeDisplay;

  objectTypeV1: MetadataObjectType = 'lookmlviews';

  guid!: string;

  name!: string;

  label!: string;

  description?: string;

  @Expose({ name: 'data_types' })
  @Type(() => DataTypesModel)
  dataTypes?: DataTypesModel = new DataTypesModel();

  @Expose({ name: 'search_name' })
  fullName?: string;

  // TODO: Remove transform https://app.shortcut.com/select-star/story/55620
  @Expose({ name: 'richtext_description' })
  @Transform((richtextDescription) =>
    isEmptyRichText(richtextDescription) ? '' : richtextDescription,
  )
  richtextDescription?: string;

  suggestedDescriptionSource?: undefined;

  suggestedDescriptionSourceObject?: undefined;

  @Expose({ name: 'project_name' })
  projectName!: string;

  get supTitle() {
    return this?.projectName;
  }

  @Type(() => LookerExploreModel)
  explores?: LookerExploreModel[];

  @Expose({ name: 'data_source' })
  @Type(() => DataSourceModel)
  dataSource!: DataSourceModel;

  @Type(() => PopularityModel)
  popularity?: PopularityModel;

  @Type(() => BreadcrumbModel)
  breadcrumbs?: BreadcrumbModel[];

  get breadcrumbList() {
    return breadcrumbsToList(this.breadcrumbs);
  }

  get breadcrumbLabelList() {
    const url = urlFor(this, false, '');
    return breadcrumbsToLabelList(this.name, url, this.breadcrumbs);
  }

  @Expose({ name: 'tagged_items' })
  @Type(() => TaggedItemModel)
  taggedItems?: TaggedItemModel[];

  @Expose({ name: 'is_hidden' })
  isHidden?: boolean;

  @Expose({ name: 'technical_owner' })
  @Type(() => Reference)
  technicalOwner?: Reference<UserModel | TeamModel>;

  @Expose({ name: 'business_owner' })
  @Type(() => Reference)
  businessOwner?: Reference<UserModel | TeamModel>;

  @Expose({ name: 'downstream_objects_counts' })
  @Type(() => RelatedObjectsCountsModel)
  downstreamObjectsCounts?: RelatedObjectsCountsModel;

  @Expose({ name: 'data_source_type' })
  dataSourceType?: DataSourceTypesType;

  @Expose({ name: 'upstream_objects_counts' })
  @Type(() => RelatedObjectsCountsModel)
  upstreamObjectsCounts?: RelatedObjectsCountsModel;

  owner = undefined;

  itemsType = 'lookmlviews' as const;

  bytes = undefined;

  rowCount = undefined;

  lastRefreshed = undefined;

  materialization = undefined;

  showLinksTo = true;

  get linksToObjects() {
    return this.explores;
  }

  showLinkedFrom = false;

  linkedFromObjects = undefined;

  showMentionedBy = true;

  showTableLastQuery = undefined;

  loadingStatus = undefined;

  lastUpdated = undefined;

  created = undefined;

  suggestedDescription = undefined;

  connections = undefined;

  lastRun = undefined;

  color = undefined;

  showEditTags = true;

  dataType = undefined;

  externalUrl = undefined;

  get dataSourceGuid() {
    return this?.dataSource?.guid;
  }

  showCustomAttributes = true;

  cloudObject = undefined;

  rawSql = undefined;

  showDescriptionSelector = true;

  @Expose({ name: 'description_source' })
  descriptionSource?: DescriptionSource;

  @Expose({ name: 'ai_description' })
  aiDescription?: string;

  @Expose({ name: 'ingested_description' })
  ingestedDescription?: string;

  @Expose({ name: 'user_description' })
  userDescription?: string;

  type = undefined;
}
