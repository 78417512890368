import { createContext, useContext } from 'react';

import { OrganizationModel } from '@api/organizations/OrganizationModel';
import { UserModel } from '@api/user/UserModel';
import { DataSourceTypesType } from '@models/DataSourceCredentials';
import { DataSourceModel } from '@models/DataSourceModel';
import { OrganizationUserModel } from '@models/OrganizationUserModel';

export interface UserContextProps {
  // The data sources in the user's organization
  dataSources?: { [id: string]: DataSourceModel };
  error?: boolean;
  getDataSourceSettings: (guid?: string) => DataSourceModel['settings'] | undefined;
  hasDataSource: (args: { guid?: string; type: DataSourceTypesType }) => boolean;
  hasEditPermissions?: boolean;
  isDataSourcesLoading?: boolean;
  isOnboarding?: boolean;
  isOnboardingComplete?: boolean;
  isOrgAdmin: boolean;
  isOrgDataManager: boolean;
  isOrgUser: boolean;
  isTos?: boolean;
  loading?: boolean;
  // The organization this user is a part of
  organization?: OrganizationModel;
  role?: OrganizationUserModel['role'];
  settings?: OrganizationModel['settings'];
  user?: UserModel;
}

export const UserContext = createContext<UserContextProps | undefined>(undefined);

export const useUserContext = () => {
  const userContext = useContext(UserContext);

  if (userContext === undefined) {
    throw new Error(`useUserContext must be used within <UserProvider>.`);
  }

  return userContext;
};

export default useUserContext;
