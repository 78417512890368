import React from 'react';

import { useFetchMetadataComments } from '@api/metadata';
import DiscussionTab from '@components/TabContent/DiscussionTab';
import TabsRouter, { TabRoutesConfig } from '@components/TabsRouter';
import { useUserContext } from '@context/User';
import { TabContentProps } from '@pages/MetadataObjectPage/config/types';
import wrapString from '@utils/wrapString';

interface TermsTabContentProps extends TabContentProps {}

const TermsTabContent: React.FC<TermsTabContentProps> = ({ data, guid, overviewTabConfig }) => {
  const { data: commentData } = useFetchMetadataComments(guid);
  const { organization } = useUserContext();

  const tabsRouterConfig: TabRoutesConfig[] = [
    { ...overviewTabConfig, default: true },
    ...(organization?.settings?.useDiscussions
      ? [
          {
            // eslint-disable-next-line react/no-unstable-nested-components
            component: () => (
              <DiscussionTab
                businessOwner={data?.businessOwner?.obj}
                guid={guid}
                technicalOwner={data?.technicalOwner?.obj}
              />
            ),
            contentOffset: 2,
            label: `Discussion ${wrapString(commentData?.length)}`,
            path: '/discussion/:itemId?',
            route: '/discussion',
          },
        ]
      : []),
  ];

  return <TabsRouter config={tabsRouterConfig} sticky />;
};

export default TermsTabContent;
