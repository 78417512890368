import React from 'react';
import type { ModalId } from '@constants';
import { Location } from '@routing/history';
import { RouterLink } from '@routing/router';

import Button from '@components/Button/Button';
import Modal, { ModalContent, ModalFooter, ModalHeader } from '@components/UI/Modal';
import { useModal } from '@context/Modal';

const defaultMessage =
  'You have a comment with unsaved changes. Are you sure you want to leave this page?';

interface LeavePageConfirmationModalProps {
  cancelButtonText?: string;
  confirmButtonText?: string;
  location?: Location;
  message?: string;
  modalId: ModalId;
  navigateOnCancel?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  onDiscard?: () => void;
  title: string;
}

const LeavePageConfirmationModal: React.FC<LeavePageConfirmationModalProps> = ({
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Yes',
  location,
  message = defaultMessage,
  modalId,
  navigateOnCancel,
  onClose,
  onConfirm,
  onDiscard,
  title,
}) => {
  const { checkModalOpened, closeModal } = useModal();

  const handleOnClose = () => {
    closeModal(modalId);
    onClose?.();
  };

  const handleOnDiscard = () => {
    closeModal(modalId);
    onDiscard?.();
  };

  const handleOnConfirm = () => {
    closeModal(modalId);
    onConfirm?.();
  };

  const link: React.ElementType<RouterLink> = (props) => (
    <RouterLink {...props} to={location?.pathname || '#'} />
  );

  if (!checkModalOpened(modalId)) return null;

  return (
    <Modal onClose={handleOnClose} size="tiny">
      <ModalHeader onClose={handleOnClose} title={title} />
      <ModalContent minHeight="unset" py={2}>
        {message}
      </ModalContent>
      <ModalFooter>
        {navigateOnCancel && (
          <Button as={location ? link : undefined} onClick={handleOnDiscard} variant="outlined">
            Discard Changes
          </Button>
        )}
        <Button onClick={handleOnClose} variant="outlined">
          {cancelButtonText}
        </Button>
        <Button as={location ? link : undefined} onClick={handleOnConfirm} variant="default">
          {confirmButtonText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default LeavePageConfirmationModal;
