import React from 'react';
import { NO_LINK } from '@configs/urls/config';

import Box from '@components/Box';
import ExpandCollapseLineageButton from '@components/ExploreSidebar/ExpandCollapseLineageButton';
import {
  identSize,
  StyledSidebarTreeCarret,
  StyledSidebarTreeLabel,
} from '@components/ExploreSidebar/SidebarTreeItem.styles';
import Highlighter from '@components/Highlighter';
import IconButton from '@components/IconButton';
import OpenInNewTabButton from '@components/OpenInNewTabButton';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import theme from '@styles/theme';
import { urlFor } from '@utils/routing';

import type { SidebarTreeItemProps } from './types';

const SidebarTreeNameCell: React.FC<SidebarTreeItemProps> = ({
  dataTypes,
  direction,
  disableExpandAll = false,
  enableHighlight = true,
  expandAllState,
  fullName,
  guid,
  hasDbtDwhLink,
  hasNodes,
  isExpandingAll,
  isHidden,
  isLoading,
  isOpen,
  jobs,
  label,
  level,
  loadMore,
  name,
  onExpandAllClick,
  onSqlButtonClick,
  query,
  routePath,
  searchKeyword,
  showExpandAll = false,
  tableId,
  toggleNode,
  type,
}) => {
  /**
   * @todo Architecture.
   * Move url generation to Model.
   */
  const url =
    routePath ??
    urlFor({
      dataType: dataTypes?.dataType,
      dataTypes,
      guid,
      objectType: dataTypes?.objectType,
      parentGuid: tableId,
    });
  const isImplicit = dataTypes?.dataType === 'implicit';

  return (
    <Box
      alignItems="inherit"
      compDisplay="inherit"
      compWidth="100%"
      pl={(level * identSize) / theme.grid.space}
    >
      {level > 0 && hasNodes && (
        <StyledSidebarTreeCarret data-testid={`caret-${guid}`} onClick={toggleNode}>
          <Icon name={isOpen ? 'caret-down' : 'caret-right'} size="14px" />
        </StyledSidebarTreeCarret>
      )}
      <Tooltip content={dataTypes?.tooltips.dataSource}>
        <Icon mr={0.25} name={dataTypes?.icons.dataSource!} size="16px" />
      </Tooltip>
      {hasDbtDwhLink && (
        <Tooltip content="dbt">
          <Icon mr={0.25} name="dbt" size="16px" />
        </Tooltip>
      )}
      {jobs && (
        <Tooltip content={jobs[0].dataTypes?.tooltips.dataType}>
          <Icon mr={0.25} name={jobs[0].dataTypes?.icons.objectType!} size="16px" />
        </Tooltip>
      )}
      <Tooltip content={isImplicit ? 'Temporary table (inferred)' : dataTypes?.tooltips.dataType}>
        <Icon name={dataTypes?.icons.dataType!} size="16px" />
      </Tooltip>
      <Tooltip content={fullName}>
        <StyledSidebarTreeLabel data-table-id={tableId}>
          {enableHighlight && searchKeyword ? (
            <Highlighter searchWords={[searchKeyword]} textToHighlight={label} />
          ) : (
            label ?? name
          )}
        </StyledSidebarTreeLabel>
      </Tooltip>
      {query && (
        <IconButton my={-0.5} onClick={() => onSqlButtonClick?.(query)}>
          <Tooltip action content="Open SQL Query">
            <Icon name="query" size="14px" />
          </Tooltip>
        </IconButton>
      )}
      {!isHidden && !isImplicit && !loadMore && level > 0 && url !== NO_LINK && (
        <OpenInNewTabButton
          my={-0.5}
          url={
            process.env.REACT_APP_CHROME_EXTENSION_BUILD
              ? `${process.env.REACT_APP_SS_APP_URL}${url}`
              : url
          }
        />
      )}
      {showExpandAll && level === 0 && (
        <Box ml={1.75}>
          <ExpandCollapseLineageButton
            direction={direction}
            disabled={disableExpandAll}
            isColumn={type === 'column'}
            isLoading={isExpandingAll && isLoading}
            onClick={onExpandAllClick}
            state={expandAllState}
          />
        </Box>
      )}
    </Box>
  );
};

export default SidebarTreeNameCell;
