import React from 'react';

import OverviewContent, {
  OverviewContentProps,
} from '@components/TabContent/OverviewTab/OverviewContent';
import { MetadataModel } from '@models/MetadataModel';
import useFetchObjectMetadata from '@pages/MetadataObjectPage/useFetchObjectMetadata';
import useMutateMetadata from '@pages/MetadataObjectPage/useMutateMetadata';

import OverviewSkeleton from '../OverviewSkeleton';

interface TablesOverviewProps {
  configPath: string;
  guid: string;
  isEditable: boolean;
  selectedNodeMetadata?: MetadataModel;
}

const TableOverview: React.FC<TablesOverviewProps> = ({
  configPath,
  guid,
  isEditable,
  selectedNodeMetadata,
}) => {
  const { dataTypes } = selectedNodeMetadata ?? {};

  const { config, fetchData } = useFetchObjectMetadata({
    dataSourceType: dataTypes?.dataSourceType,
    guid,
    path: configPath,
  });

  const { data, isLoading: isLoadingObjectData, refetch } = fetchData ?? {};
  const { descriptionAllowedElements } = config ?? {};

  const {
    isError: isPatchMetadataError,
    isLoading: isPatchMetadataLoading,
    mutate: mutatePatchMetadata,
  } = useMutateMetadata({ guid, refetch });

  if (isLoadingObjectData) {
    return <OverviewSkeleton />;
  }

  const enabledOverviewSections = [
    'details',
    ...(data?.showCustomAttributes ? ['customAttributes'] : []),
    'description',
  ] as OverviewContentProps['sections'];

  return (
    <OverviewContent
      canEditDescription={isEditable}
      cardProps={{ px: 1.75, py: 1.25 }}
      data={data}
      description={data?.description}
      descriptionAllowedElements={descriptionAllowedElements}
      guid={guid}
      isEditable={isEditable}
      isError={isPatchMetadataError}
      isLoading={isPatchMetadataLoading}
      onDescriptionSave={(richtextDesc, plainTextDesc, descSource) => {
        mutatePatchMetadata({
          description: plainTextDesc,
          description_source: descSource,
          richtext_description: richtextDesc,
        });
      }}
      reloadData={() => refetch()}
      richtextDescription={data?.richtextDescription}
      sections={enabledOverviewSections}
      suggestedDescriptionSourceObject={data?.suggestedDescriptionSourceObject?.obj}
      titleFontSize="body2"
    />
  );
};

export default TableOverview;
