import ColumnLineageModel from '@api/lineage/ColumnLineageModel';
import TableLineageModel from '@api/lineage/TableLineageModel';

import transformArrayToObject from '../transformArrayToObject';

interface FindColumnRelatedTablesArgs {
  columns: ColumnLineageModel[];
  tables: TableLineageModel[];
}

const findColumnRelatedTables = ({
  columns,
  tables,
}: FindColumnRelatedTablesArgs): TableLineageModel[] => {
  let foundTables: TableLineageModel[] = [];
  const tablesById = transformArrayToObject<TableLineageModel>(tables);

  for (let i = 0; i < columns.length; i += 1) {
    const column = columns[i];
    const relatedTables = column?.sourceTableGuids?.map((id) => tablesById[id]).filter(Boolean);

    foundTables = [...foundTables, ...relatedTables];
  }

  return foundTables;
};

export default findColumnRelatedTables;
