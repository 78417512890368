import React from 'react';

import type { StyledBoxProps } from '@components/Box';
import ImageMessage from '@components/ImageMessage';

interface EmptyContentProps extends StyledBoxProps {
  message: React.ReactNode;
}
const EmptyContent: React.FC<EmptyContentProps> = ({ message, ...rest }) => {
  return <ImageMessage message={message} src="/images/no-content.svg" {...rest} />;
};

export default EmptyContent;
