import React, { Fragment } from 'react';

import Highlighter from '@components/Highlighter';

import { StyledTargetObjectNameSeparator } from './TargetObjectName.styles';

interface TargetObjectNameProps {
  defaultSeparator?: string;
  globalFilter?: string;
  icon?: React.ReactNode;
  text: string;
}

const TargetObjectName = ({
  defaultSeparator = '_',
  globalFilter,
  icon = null,
  text,
}: TargetObjectNameProps) => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {text
        ?.trim()
        .split(defaultSeparator)
        .map((chunk, index, arr) => {
          const separator = arr.length - 1 <= index ? ' ' : defaultSeparator;

          return (
            <Fragment key={chunk}>
              {index === 0 && icon}
              <Highlighter
                autoEscape
                breakWords={chunk.length >= 12}
                searchWords={globalFilter?.split(/[.| ]+/) ?? []}
                textToHighlight={chunk}
              />
              <StyledTargetObjectNameSeparator>{separator}</StyledTargetObjectNameSeparator>
            </Fragment>
          );
        })}
    </>
  );
};

export default TargetObjectName;
