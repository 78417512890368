import { createContext, useContext, useEffect, useRef } from 'react';

import { RbacRole } from '@api/rbacPolicy/RbacRoles';

export interface ObjectPermissions {
  [key: string]: { isEditable: boolean; role: RbacRole };
}

interface ObjectPermissionsContextProps {
  checkPermissionsById: (id: string) => void;
  checkPermissionsByIds: (ids: string[]) => void;
  cleanPermissionsCache: () => void;
  hasEditPermissions: boolean;
  isFetching: boolean;
  isPbac: boolean;
  permissions: ObjectPermissions;
  useRbac: boolean;
}

export interface UseObjectPermissionsContextProps {
  id?: string;
  ids?: string[];
}

export const ObjectPermissionsContext = createContext<ObjectPermissionsContextProps>({} as never);

export const objectPermissionsProviderErrorMessage =
  'useObjectPermissionsContext must be used within <ObjectPermissionsProvider>.';

export const useObjectPermissionsContext = ({ id, ids }: UseObjectPermissionsContextProps = {}) => {
  const permissionsContext = useContext(ObjectPermissionsContext);
  const {
    checkPermissionsById,
    checkPermissionsByIds,
    cleanPermissionsCache,
    hasEditPermissions,
    isFetching,
    isPbac,
    permissions,
    useRbac,
  } = permissionsContext ?? {};
  const checkPermissionsByIdRef = useRef(checkPermissionsById);
  const checkPermissionsByIdsRef = useRef(checkPermissionsByIds);
  const idsString = ids?.toString();

  useEffect(() => {
    if (id) {
      checkPermissionsByIdRef.current?.(id);
    }
  }, [id]);

  useEffect(() => {
    if (idsString) {
      checkPermissionsByIdsRef.current?.(idsString.split(','));
    }
  }, [idsString]);

  if (checkPermissionsById === undefined) {
    throw new Error(objectPermissionsProviderErrorMessage);
  }

  const isEditable = Boolean(permissions?.[id ?? '']?.isEditable);
  const hasReadPermissions = Boolean(permissions?.[id ?? '']?.role !== null);

  return {
    cleanPermissionsCache,
    hasEditPermissions,
    hasReadPermissions,
    isEditable,
    isFetching,
    isPbac,
    permissions,
    useRbac,
  };
};
