import styled from '@emotion/styled';

import Box from '@components/Box';

export const StyledSubCollectionsSection = styled(Box)``;

StyledSubCollectionsSection.defaultProps = {
  compDisplay: 'flex',
  compWidth: '100%',
  flexDirection: 'column',
  gap: 0.5,
  pt: 1.5,
};

export const StyledSubCollectionsSectionContainer = styled(Box)``;

StyledSubCollectionsSectionContainer.defaultProps = {
  columnGap: 1,
  compDisplay: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  position: 'relative',
  pr: 2,
  rowGap: 1.5,
};

export const StyledSubCollectionsSectionExpansionButtonContainer = styled(Box)``;

StyledSubCollectionsSectionExpansionButtonContainer.defaultProps = {
  compDisplay: 'flex',
  justifyContent: 'center',
  left: 0,
  position: 'absolute',
  right: 0,
  top: 'calc(100% + 28px)',
};
