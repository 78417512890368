import { css } from '@emotion/react';
import styled from '@emotion/styled';
import ReactFlow from 'reactflow';

const minimapTopPosition = css`
  top: 43px;
`;

export const StyledReactFlow = styled(ReactFlow)`
  .react-flow__node-database {
    z-index: -1 !important;
  }
  .react-flow__node-schema {
    z-index: -1 !important;
  }
  .react-flow__node-table {
    z-index: -1 !important;
  }
  .react-flow__node-column {
    z-index: -1 !important;
  }
  .react-flow__node-bi {
    z-index: -1 !important;
  }

  .react-flow__node-default {
    z-index: -1 !important;
  }

  .react-flow__node-showMore {
    z-index: -1 !important;
  }

  .react-flow__panel {
    opacity: 0.9;
  }

  .react-flow__controls {
    ${minimapTopPosition}
    right: ${({ theme }) => theme.space(1)};
    box-shadow: none;
    height: fit-content;

    .react-flow__controls-zoomin,
    .react-flow__controls-zoomout {
      margin-bottom: ${({ theme }) => theme.space(1)};
    }

    .react-flow__controls-button {
      height: 14px;
      width: 14px;
      border-radius: 2px;
      border: ${({ theme }) => `1px solid ${theme.colors.gray[200]}`};
      background-color: ${({ theme }) => theme.colors.v1.gray[100]};

      &:hover {
        svg {
          opacity: 0.6;
        }
      }
    }
  }

  .react-flow__minimap {
    ${minimapTopPosition}
    right: ${({ theme }) => theme.space(4.625)};
    border-radius: 2px;
    height: 94px;
    border: ${({ theme }) => `1px solid ${theme.colors.gray[200]}`};

    svg {
      height: 92px;
      width: 124px;
    }
  }

  // Needed to make options menu clickable inside react flow container
  #options-menu-portal {
    pointer-events: initial;
  }

  .react-flow__renderer {
    overflow: hidden;
  }
`;
