import React from 'react';

import { ActivityModel } from '@api/activity/ActivityModel';
import ActivityLink from '@components/ActivityLink';

import ProfileRecentActivityStyled from './ProfileRecentActivityStyled';

interface Props {
  activity: ActivityModel;
}

const ProfileRecentActivity: React.FC<Props> = ({ activity }) => {
  return (
    <ProfileRecentActivityStyled>
      <div className="action">
        <div className="column-1">
          <ActivityLink activity={activity} />
        </div>
        <div className="column-2">
          <span className="text--time">{activity.performedOn.fromNow()}</span>
        </div>
      </div>
    </ProfileRecentActivityStyled>
  );
};

export default ProfileRecentActivity;
