import { Expose, Transform, Type } from 'class-transformer';
import moment from 'moment/moment';

import ChatBotMessageModel, { ChatBotMessageData } from './ChatBotMessageModel';

export interface ChatBotConversationData {
  created_on?: string;
  guid?: string;
  messages?: ChatBotMessageData[];
  title?: string;
}

class ChatBotConversationModel {
  guid?: string;

  title?: string;

  @Expose({ name: 'created_on' })
  @Transform((value) => moment(value))
  createdOn?: moment.Moment;

  @Type(() => ChatBotMessageModel)
  messages?: ChatBotMessageModel[];
}

export default ChatBotConversationModel;
