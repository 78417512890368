import React from 'react';
import clsx from 'clsx';

import Box from '@components/Box/Box.styles';
import ErdColumnJoinQueryModal from '@components/ErdExplore/ErdColumnJoinQueryModal';
import ErdOptionsDropdown from '@components/ExploreTree/ErdOptionsDropdown';
import Highlighter from '@components/Highlighter';
import TagTable from '@components/TagTable';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import DataTypesModel from '@models/DataTypesModel';

import { NodeExtraMeta, OpenLineageCallback } from './types';

export interface ExploreColumnProps extends NodeExtraMeta {
  canColumnPin: boolean;
  columnId: string;
  columnName: string;
  columnPos: number;
  compHeight: number;
  compWidth: number;
  componentIdentifier?: string;
  dataTypes?: DataTypesModel;
  guid: string;
  isColumnHovered: boolean;
  isColumnPinned: boolean;
  isColumnPinnedByUser: boolean;
  isForeignKey?: boolean;
  isPrimaryKey?: boolean;
  isTableHidden: boolean;
  onClick: (id: string) => void;
  onMouseEnter: (id: string) => void;
  onMouseLeave: (id: string) => void;
  openLineage: OpenLineageCallback;
  searchColumnName: string;
  showLoadErd?: boolean;
  yOffset: number;
}

const ExploreColumn: React.FC<ExploreColumnProps> = ({
  canColumnPin,
  columnId,
  columnName,
  columnPos,
  compHeight,
  compWidth,
  componentIdentifier,
  dataTypes,
  guid,
  hasJoinQueries,
  isColumnHovered,
  isColumnPinned,
  isColumnPinnedByUser,
  isForeignKey,
  isPrimaryKey,
  isTableHidden,
  joinQueriesEdge,
  onClick,
  onMouseEnter,
  onMouseLeave,
  openLineage,
  searchColumnName,
  showLoadErd,
  yOffset,
}) => {
  const handleClick = () => {
    if (canColumnPin) {
      onClick(columnId);
    }
  };

  const handleOpenErd = () => {
    openLineage({ columns: guid, componentIdentifier, direction: 'all' });
  };

  return (
    <g
      key={columnId}
      className={clsx(
        'column',
        isColumnPinned && 'pinned',
        isColumnHovered && 'hovered',
        isColumnPinnedByUser && 'userPinned',
      )}
      data-position={columnPos}
      id={columnId}
      onClick={handleClick}
      onMouseEnter={() => onMouseEnter(columnId)}
      onMouseLeave={() => onMouseLeave(columnId)}
      x="0"
    >
      <foreignObject height={compHeight} width={compWidth} y={yOffset}>
        <div className="column-row">
          <Icon mx={0.7} name={dataTypes?.icons.dataType!} size="15px" />
          <div className="column-name ellipses">
            <span className="ellipses">
              {searchColumnName ? (
                <Highlighter searchWords={[searchColumnName]} textToHighlight={columnName} />
              ) : (
                columnName
              )}
            </span>
            {isPrimaryKey && (
              <TagTable gap={0.5}>
                <Icon name="key" size="12px" /> <span>PK</span>
              </TagTable>
            )}
            {isForeignKey && (
              <TagTable gap={0.5}>
                <Icon name="key-gray" size="12px" /> <span>FK</span>
              </TagTable>
            )}
            {isColumnPinned && hasJoinQueries && (
              <Tooltip action content="Open SQL">
                <Box as="span" compHeight="100%" noDefault onClick={(e) => e.stopPropagation()}>
                  <ErdColumnJoinQueryModal
                    dataSourceType={dataTypes?.dataSourceType}
                    sourceGuid={joinQueriesEdge?.source}
                    targetGuid={joinQueriesEdge?.target}
                  />
                </Box>
              </Tooltip>
            )}
            <Icon className="pinActive" name="pin-selected" size="12px" />
            {canColumnPin && <Icon className="pin" name="pin-unselected" size="12px" />}
            <div className="flex-grow-1" />
          </div>
          {isColumnPinned && showLoadErd && !isTableHidden && (
            <ErdOptionsDropdown
              guid={guid}
              optionsSelections={[
                {
                  id: '0',
                  options: [
                    {
                      icon: 'plus-circle' as const,
                      id: 'open-direct-lineage',
                      label: 'Open connected columns',
                      onClick: handleOpenErd,
                    },
                  ],
                },
              ]}
            >
              {({ onBlur, triggerId }) => (
                <button className="iconGroup" id={triggerId} onBlur={onBlur} type="button">
                  <Icon name="settings" size="15px" />
                </button>
              )}
            </ErdOptionsDropdown>
          )}
        </div>
      </foreignObject>
    </g>
  );
};

export default React.memo(ExploreColumn);
