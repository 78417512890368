import styled from '@emotion/styled';
import * as CSS from 'csstype';

import type { StyledBoxProps } from '@components/Box/Box.styles';
import { BoxStyles } from '@components/Box/Box.styles';

export interface CardProps extends StyledBoxProps {
  backgroundColor?: CSS.Property.BackgroundColor;
  clickable?: boolean;
  compDisplay?: CSS.Property.Display;
  compWidth?: CSS.Property.Width;
  containerName?: string;
  containerType?: string;
  'data-testid'?: string;
  maxWidth?: CSS.Property.MaxWidth;
  to?: string;
}

const Card = styled.div<CardProps>`
  container-name: ${({ containerName }) => containerName};
  container-type: ${({ containerType }) => containerType};
  display: ${({ compDisplay = 'block' }) => compDisplay};
  background: ${({ backgroundColor = 'white' }) => backgroundColor};
  border-radius: ${({ theme }) => theme.radius.lg};
  border: ${({ theme }) => `1px solid ${theme.colors.v1.gray[200]}`};
  color: inherit;
  cursor: ${({ clickable }) => (clickable ? `pointer` : 'inherit')};
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  padding: ${({ theme }) => theme.space(2)};
  ${BoxStyles}; // We need a feature to pass default arguments to avoid duplication as below.

  &:hover {
    color: inherit;
    outline: ${({ clickable, theme }) => (clickable ? `1px solid ${theme.colors.border}` : 'none')};
  }
`;

export default Card;
