import { HierarchyData } from './HierarchyModel';

export interface BIFoldersHierarchyResponseData {
  data: HierarchyData;
}

export const toBIFoldersHierarchyResponse = (obj: any): BIFoldersHierarchyResponseData => {
  const toHierarchy = (d: any) => {
    return {
      ...d,
      objectType: d.object_type,
      guid: d.guid,
      name: d.name,
      type: d?.type,
      url: d?.url || null,
      open: d?.open,
      active: d?.active,
      shouldIngest: d?.should_ingest,
      children: d?.children.map((c: any) => toHierarchy(c)).filter(Boolean),
    };
  };

  return {
    data: toHierarchy(obj?.data),
  };
};
