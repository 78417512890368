const getTextWidth = (text: string): number => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (!context) return 0;

  context.font = getComputedStyle(document.body).font;

  return context.measureText(text).width;
};

export default getTextWidth;
