import React from 'react';

import { TableModel } from '@api/tables/TableModel';
import DateTime from '@components/DateTime';

/**
 * Last updated date is the last date that the table was updated
 * (`lastUpdatedOn`) or created (`dbCreatedOn`) in metadata.
 *
 * As a fallback (it will only happen if both of the above values are null),
 * it'll use `dbCreatedOn` as the last updated date.
 */
export const getLastUpdatedDate = (table?: TableModel) => {
  if (!table) {
    return null;
  }

  const tableLatest = table.lastUpdatedOn?.isAfter(table.dbCreatedOn)
    ? table.lastUpdatedOn
    : table.dbCreatedOn;

  return tableLatest || table?.createdOn;
};

/**
 * Last refreshed date is the last date that the table was refreshed by
 * running any of DDL/DML statements (`lastRefreshedOn`) that created lineage.
 * If for some reason we don't have the most recent queries,
 * and `lastRefreshedOn` is older than `getLastUpdatedDate()`, we'll use
 * the `getLastUpdatedDate()` as the last refreshed date.
 *
 * NOTE: getLastRefreshedDate() is never before getLastUpdatedDate()
 */
export const getLastRefreshedDate = (table?: TableModel) => {
  if (!table) {
    return null;
  }

  const lastUpdated = getLastUpdatedDate(table);

  if (!table.lastRefreshedOn || lastUpdated?.isAfter(table.lastRefreshedOn)) {
    return lastUpdated;
  }

  return table.lastRefreshedOn;
};
interface TableLastUpdateProps {
  table?: TableModel;
}

const TableLastUpdate: React.FC<TableLastUpdateProps> = ({ table }) => {
  const lastUpdatedDate = getLastUpdatedDate(table);
  const lastRefreshedDate = getLastRefreshedDate(table);

  return (
    <>
      {lastUpdatedDate && (
        <div>
          <strong>Last Update: </strong>
          <DateTime datetime={lastUpdatedDate} />
        </div>
      )}
      {lastRefreshedDate && (
        <div>
          <strong>Last Refreshed: </strong>
          {lastRefreshedDate && <DateTime datetime={lastRefreshedDate} />}
        </div>
      )}
    </>
  );
};

export default TableLastUpdate;
