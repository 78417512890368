import rawTransform from '@api/rawTransform';

import DataTypesModel from './DataTypesModel';

export const validDataTypesMock = rawTransform(DataTypesModel)({
  data_source_type: 'mode',
  data_type: '',
  object_type: '',
});

export const invalidDataTypesMock = rawTransform(DataTypesModel)({
  data_source_type: '',
  data_type: '',
  object_type: '',
});

export const dbtDataTypesMock = rawTransform(DataTypesModel)({
  data_source_type: 'dbt',
  data_type: '',
  object_type: '',
});

export const dwhDataTypesMock = rawTransform(DataTypesModel)({
  data_source_type: 'snowflake',
  data_type: '',
  object_type: '',
});
