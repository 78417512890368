import { useFetchTable } from '@api/tables/tables';

import query from './query';
import TablesTabContent from './TablesTabContent';

export default {
  Content: TablesTabContent,
  enableNotifications: true,
  fetchHook: useFetchTable,
  fetchHookExtraParams: { force_showing_suggested_description: true },
  query,
};
