import { Expose, Type } from 'class-transformer';

import ColumnLineageModel from './ColumnLineageModel';
import TableLineageModel from './TableLineageModel';

export class LineageExploreModel {
  @Expose({ name: 'table_lineage' })
  @Type(() => TableLineageModel)
  tables!: TableLineageModel[];

  @Expose({ name: 'column_lineage' })
  @Type(() => ColumnLineageModel)
  columns!: ColumnLineageModel[];
}

export default LineageExploreModel;
