import React from 'react';
import { useParams } from '@routing/router';

import { useFetchTeam } from '@api/teams';
import Box from '@components/Box';
import NotFoundError from '@components/Error/NotFoundError';
import LayoutMainGrid from '@components/Layout/LayoutMainGrid';
import MentionedBy from '@components/MentionedBy';
import PageHistory from '@components/PageHistory';
import { Profile } from '@components/Profile';
import TeamEditDescriptionInline from '@components/Profile/TeamEditDescriptionInline';
import RightSidebarContainer from '@components/RightSidebar/RightSidebar';
import DiscussionTab from '@components/TabContent/DiscussionTab';
import type { TabRoutesConfig } from '@components/TabsRouter';
import TabsRouter from '@components/TabsRouter';
import { useUserContext } from '@context/User';
import MetadataDecorator from '@utils/MetadataDecorator';
import stripSpaces from '@utils/stripSpaces';

import { ProfileType } from '../ProfilePage/ProfilePage.types';
import MostQueriedMainTab from '../ProfilePage/Tabs/MostQueriedMainTab';
import ProfileFavoritesTab from '../ProfilePage/Tabs/ProfileFavoritesTab';
import ProfileOwnsTab from '../ProfilePage/Tabs/ProfileOwnsTab';
import ProfileRecentQueriesTab from '../ProfilePage/Tabs/ProfileRecentQueriesTab';
import TeamMembersTab from '../ProfilePage/Tabs/TeamMembersTab';

const params = {
  order: 'most_active',
  query: stripSpaces(`{
    guid,
    name,
    richtext_description,
    users{guid,first_name,last_name,avatar},
    organization{guid,name}
  }`),
};

const TeamViewPage: React.FC = () => {
  const { hasEditPermissions } = useUserContext();
  const { guid = '' } = useParams<{ guid?: string }>();
  const { data: team } = useFetchTeam(guid, { enabled: Boolean(guid), params });

  if (!guid) {
    return <NotFoundError />;
  }

  const tabsRouterConfig: TabRoutesConfig[] = [
    {
      component: () => <TeamMembersTab />,
      default: true,
      label: 'Team Members',
      route: '/members',
    },
    {
      component: () => <MostQueriedMainTab guid={guid} profileType={ProfileType.team} />,
      label: 'Most Queried',
      route: '/most-queried',
    },
    {
      component: () => <ProfileRecentQueriesTab guid={guid} profileType={ProfileType.team} />,
      label: 'Recent Queries',
      route: '/recent-queries',
    },

    {
      component: () => <ProfileFavoritesTab guid={guid} profileType={ProfileType.team} />,
      label: 'Favorites',
      route: '/favorites',
    },
    {
      component: () => <ProfileOwnsTab guid={guid} profileType={ProfileType.team} />,
      contentOffset: 2,
      label: 'Owns',
      route: '/owns',
    },
    {
      component: () => <DiscussionTab guid={guid} />,
      contentOffset: 2,
      label: 'Discussion',
      route: '/discussion',
    },
  ];

  return (
    <Box compDisplay="flex">
      <MetadataDecorator title={team?.fullName} />
      <LayoutMainGrid>
        <span>
          {team && (
            <Box mb={1}>
              <Profile item={team} />
              {hasEditPermissions ? <TeamEditDescriptionInline initialData={team} /> : null}
            </Box>
          )}
        </span>
        {team && <TabsRouter config={tabsRouterConfig} sticky />}
      </LayoutMainGrid>
      <RightSidebarContainer hideToggle>
        <>
          <MentionedBy targetId={guid} />
          {team?.guid && (
            <Box mt={5}>
              <PageHistory teams={team.guid} />
            </Box>
          )}
        </>
      </RightSidebarContainer>
    </Box>
  );
};

export default TeamViewPage;
