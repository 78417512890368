import getUrl from '@configs/urls/getUrl';
import moment from 'moment';

import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import type { Notebook as GetBiNotebooksRetrieveResponse } from '@api/openapi.generated';
import { TaggedItemModel } from '@api/tags/TaggedItemModel';
import type { ObjectType } from '@api/types';
import { rawClassArrayMap, rawClassMap, rawMap } from '@api/utils';
import isEmptyRichText from '@components/RichTextEditor/helpers/isEmptyRichText';
import DataTypesModel from '@models/DataTypesModel';
import RelatedObjectsCountsModel from '@models/RelatedObjectsCountsModel';

const objectType: ObjectType = 'notebook';

const mapBiNotebookModel = (value: GetBiNotebooksRetrieveResponse) => {
  const dataTypes = rawClassMap(DataTypesModel, value.data_types);
  const breadcrumbs = rawClassArrayMap(BreadcrumbModel, value.breadcrumbs);
  const taggedItems = rawClassArrayMap(TaggedItemModel, value.tagged_items);

  return {
    aiDescription: value.ai_description,
    breadcrumbList: breadcrumbs,
    breadcrumbs,
    createdAt: rawMap(moment, value.external_created_on),
    dataSourceGuid: value.datasource_guid,
    dataTypes,
    description: value.description,
    descriptionSource: value.description_source,
    downstreamObjectsCounts: rawClassMap(
      RelatedObjectsCountsModel,
      value.downstream_objects_counts,
    ),
    externalId: value.external_id,
    externalUrl: value.external_url,
    guid: value.guid!,
    ingestedDescription: value.ingested_description,
    isMention: Boolean(value.is_mention),
    isMetric: Boolean(value.is_metric),
    language: value.language,
    lastRun: rawMap(moment, value.last_run),
    name: value.name,
    objectType,
    richtextDescription: isEmptyRichText(value.richtext_description)
      ? ''
      : value.richtext_description,
    routePath: getUrl({ dataTypes, guid: value.guid! }),
    showCustomAttributes: true,
    showMentionedBy: true,
    sourceTablesCount: value.source_tables_count,
    taggedItems,
    updatedAt: rawMap(moment, value.external_updated_on),
    upstreamObjectsCounts: rawClassMap(RelatedObjectsCountsModel, value.upstream_objects_counts),
    userDescription: value.user_description,
  };
};

export default mapBiNotebookModel;

mapBiNotebookModel.objectType = objectType;

export type BiNotebookModel = ReturnType<typeof mapBiNotebookModel>;
