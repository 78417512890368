import styled from '@emotion/styled';

import Text from '@components/Text';

export const AppSettingsInputOptionTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.v1.gray[700]};
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
  font-size: ${({ theme }) => theme.typography.fontSizes.body2};
  line-height: ${({ theme }) => theme.space(2.5)};
  margin: 0;
  display: flex;
  gap: ${({ theme }) => theme.space(1)};
  align-items: center;
`;

export const AppSettingsInputOptionSubtitle = styled(AppSettingsInputOptionTitle)`
  color: ${({ theme }) => theme.colors.v1.gray[600]};
  font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
`;
