import React from 'react';

import Button from '@components/Button/Button';
import CreateCollectionModal from '@components/Modal/CreateCollectionModal';
import { useModal } from '@context/Modal';
import theme from '@styles/theme';

const CreateCollectionButton: React.FC = () => {
  const { MODAL_IDS, checkModalOpened, closeModal, openModal } = useModal();

  const handleCreateCollectionClick = () => {
    openModal(MODAL_IDS.createCollection);
  };

  return (
    <>
      <Button
        compHeight={theme.space(4.5)}
        compSize="lg"
        compWidth="100%"
        justifyContent="center"
        onClick={handleCreateCollectionClick}
        pl={1.25}
        spacing={0.5}
        startIconName="plus"
        startIconSize="16px"
        textAlign="left"
        variant="outlined"
      >
        New Collection
      </Button>
      {checkModalOpened(MODAL_IDS.createCollection) && (
        <CreateCollectionModal
          onClose={() => {
            closeModal(MODAL_IDS.createCollection);
          }}
        />
      )}
    </>
  );
};

export default CreateCollectionButton;
