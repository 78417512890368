import React from 'react';

import { useFetchTableEvents } from '@api/tableEvents';
import QueryModalWithTargetBox from '@components/Modal/QueryModal/QueryModalWithTargetBox';
import stripSpaces from '@utils/stripSpaces';

const query = stripSpaces(`{
  event_on,
  query_record {
    guid,
    query {
      guid,
      raw_sql
    },
    executed_on,
    duration,
    external_id,
    rows_produced,
    dsuser {
      guid,
      name,
      display_name,
      data_source,
      is_active,
      user {
        guid,
        first_name,
        last_name,
        avatar,
        is_active
      }
    }
  }
}`);

interface TableLastQueryProps {
  children?: React.ReactElement;
  dataSourceType?: string;
  guid: string;
}

const TableLastQuery: React.FC<TableLastQueryProps> = ({ children, dataSourceType, guid }) => {
  const { data, isError, isLoading } = useFetchTableEvents({
    enabled: Boolean(guid),
    params: {
      order: '-event_on',
      page: 1,
      page_size: 10,
      query,
      tables: guid,
    },
  });

  if (isError || isLoading) {
    return null;
  }

  if (data?.results.length === 0) {
    return null;
  }

  const queryLogRecords = data?.results.map((item) => item.queryRecord);

  return (
    <QueryModalWithTargetBox dataSourceType={dataSourceType} queryLogRecords={queryLogRecords}>
      {children}
    </QueryModalWithTargetBox>
  );
};

export default TableLastQuery;
