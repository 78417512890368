import React from 'react';

import { useFetchTeams } from '@api/teams';
import { TeamModel } from '@api/teams/TeamModel';
import { AccordionItem } from '@components/Accordion';
import IndeterminateLoader from '@components/IndeterminateLoader';

interface Props {
  update: (guid: string) => void;
}

const Owners: React.FC<Props> = ({ update }) => {
  const { data: teams, isLoading } = useFetchTeams();

  const getOwnersFilters = (owners: TeamModel[]) => {
    const ownersFilters = [
      {
        id: 'no_business_owner',
        value: <div>No Business Owner</div>,
      },
      {
        id: 'no_technical_owner',
        value: <div>No Technical Owner</div>,
      },
    ];

    owners?.map((owner) =>
      ownersFilters.push({
        id: owner.guid,
        value: <div>{owner.fullName}</div>,
      }),
    );

    return ownersFilters;
  };

  if (isLoading || !teams) {
    return <IndeterminateLoader active indeterminate inline="centered" size="mini" />;
  }

  return (
    <AccordionItem
      accordionItems={getOwnersFilters(teams?.results)}
      title="Owners"
      update={(guid) => update(guid)}
    />
  );
};

export default Owners;
