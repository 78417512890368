import { v4 as uuidv4 } from 'uuid';

import paginatedTransform from '@api/paginatedTransform';
import RecentSearchModel from '@api/search/RecentSearchModel';
import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import type { UseMutationOptions } from '@hooks/useMutation';
import useMutation from '@hooks/useMutation';
import { PaginatedResponse } from '@models/PaginatedResponse';
import type { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';

import { mapSearchModel, SearchModel } from './SearchModel';
import searchTransform from './searchTransform';
import type { QuickSearchIndexFilter, SearchResult } from './types';
import useTrackSearch from './useTrackSearch';

export const searchCacheKeys = {
  quick: ['search', 'quick'],
  recentSearches: ['search', 'recentSearches'],
  search: ['search'],
  searchItem: (id: string) => ['search', id],
  suggestions: ['search', 'suggestions'],
};

export const useFetchSearchById = (
  id: string,
  options?: UseFetchOptions<SearchModel, UseFetchSearchOptionsParams>,
) => {
  return useFetch<SearchModel>({
    ...options,
    queryKey: [...searchCacheKeys.search, id],
    url: '/search/',
  });
};

interface UseFetchSearchOptionsParams {
  guids?: string;
}

export const fetchSearchSelector = searchTransform<SearchModel>(mapSearchModel);
export const useFetchSearch = (options?: UseFetchOptions<SearchResult<SearchModel>, any>) => {
  const { track } = useTrackSearch('regular');
  const fetchResult = useFetch<SearchResult<SearchModel>>({
    ...options,
    onSuccess: (data) => {
      const requestId = uuidv4();
      const variables = { uuid: requestId, ...options?.params };
      track({ response: data, variables });
      options?.onSuccess?.({ ...data, requestId });
    },
    queryKey: [...searchCacheKeys.search, options?.params],
    select: (data) => fetchSearchSelector(data as never),
    url: '/search/',
  });

  return { ...fetchResult };
};

export const useFetchSearchSuggestions = (options?: UseFetchOptions) => {
  return useFetch<{ texts: string[] }>({
    ...options,
    queryKey: [...searchCacheKeys.suggestions, options?.params],
    url: '/search_suggestions/',
  });
};

const fetchRecentSearchesSelect = paginatedTransform(RecentSearchModel);
export const useFetchRecentSearches = (
  options: UseFetchOptions<PaginatedResponse<RecentSearchModel>, { page_size: number }>,
) => {
  return useFetch<PaginatedResponse<RecentSearchModel>>({
    ...options,
    queryKey: [...searchCacheKeys.recentSearches, options?.params],
    select: fetchRecentSearchesSelect,
    url: '/recent-searches/',
  });
};

export interface UsePostQuickSearchOptions
  extends UseMutationOptions<
    SearchResult<SearchModel>,
    EnhancedErrorResult,
    {
      all_buckets_search?: boolean;
      index_filters?: QuickSearchIndexFilter[];
      no_external_tags?: boolean;
      page_size?: number;
      query?: string;
    }
  > {}

const postQuickSearchSelect = searchTransform<SearchModel>(mapSearchModel);
export const usePostQuickSearch = (options?: UsePostQuickSearchOptions) => {
  const { track } = useTrackSearch('quick');
  return useMutation({
    ...options,
    method: 'POST',
    onSuccess: (data, variables, context) => {
      const formattedData = postQuickSearchSelect(data as never);
      track({ response: formattedData, variables });
      options?.onSuccess?.(formattedData, variables, context);
    },
    url: '/quicksearch/',
  });
};
