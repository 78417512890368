import { useFetchTag, usePatchTag } from '@api/tags';

import query from './query';
import SubTags from './SubTags/SubTags';
import TagsTabContent from './TagsTabContent';

export default {
  Content: TagsTabContent,
  OverviewCustomContent: SubTags,
  fetchHook: useFetchTag,
  metadataObjectButtonToolbarConfig: {
    buttons: ['share' as const],
  },
  patchHook: usePatchTag,
  query,
};
