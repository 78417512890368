import { TaggedItemModel } from '@api/tags/TaggedItemModel';
import type { UseFetchOptions } from '@hooks/useFetch';
import useFetch from '@hooks/useFetch';
import type { UseMutationOptions } from '@hooks/useMutation';
import useMutation from '@hooks/useMutation';
import type { PaginatedResponse } from '@models/PaginatedResponse';
import type { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';

import paginatedTransform from '../paginatedTransform';
import rawTransform from '../rawTransform';

import type { PiiRulePayload } from './PiiRuleModel';
import { PiiRuleModel } from './PiiRuleModel';
import type { PiiRuleSyncPayload } from './PiiRuleSyncModel';
import PiiRuleSyncModel from './PiiRuleSyncModel';

export const piiRulesCacheKeys = {
  export: (id: string) => ['pii', 'rulesMatch', 'export', id],
  piiRule: (id: string) => ['pii', id],
  piiRules: ['pii'],
  ruleMatch: (id: string) => ['pii', 'rulesMatch', id],
  rulesMatch: ['pii', 'rulesMatch'],
  sync: ['sync'],
};

const fetchPiiRulesSelect = paginatedTransform(PiiRuleModel);
export const useFetchPiiRules = (options?: UseFetchOptions<PaginatedResponse<PiiRuleModel>>) => {
  return useFetch<PaginatedResponse<PiiRuleModel>>({
    ...options,
    queryKey: [...piiRulesCacheKeys.piiRules, options?.params],
    select: fetchPiiRulesSelect,
    url: '/pii/',
  });
};

const fetchPiiRuleMatchingSelect = paginatedTransform(TaggedItemModel);
export const useFetchPiiRuleMatching = (
  id: string,
  options?: UseFetchOptions<PaginatedResponse<TaggedItemModel>>,
) => {
  return useFetch<PaginatedResponse<TaggedItemModel>>({
    ...options,
    queryKey: [...piiRulesCacheKeys.ruleMatch(id), options?.params],
    select: fetchPiiRuleMatchingSelect,
    url: `/pii/matching/${id}`,
  });
};

const fetchPiiRuleSelect = rawTransform(PiiRuleModel);
export const useFetchPiiRule = (
  id: string,
  options?: UseFetchOptions<PaginatedResponse<PiiRuleModel>>,
) => {
  return useFetch<PaginatedResponse<PiiRuleModel>>({
    ...options,
    queryKey: piiRulesCacheKeys.piiRule(id),
    select: fetchPiiRuleSelect,
    url: `/pii/${id}/`,
  });
};

export const useDeletePiiRule = (id: string, options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    method: 'DELETE',
    url: `/pii/${id}/`,
  });
};

export interface UsePatchPiiRuleOptions
  extends UseMutationOptions<PiiRulePayload, EnhancedErrorResult, PiiRulePayload> {}

export const usePatchPiiRule = (id: string, options?: UsePatchPiiRuleOptions) => {
  return useMutation({
    ...options,
    method: 'PATCH',
    url: `/pii/${id}/`,
  });
};

export interface UsePatchPiiRulesOption
  extends UseMutationOptions<
    { active?: boolean; items?: Array<{ obj: PiiRulePayload }> },
    EnhancedErrorResult,
    { active?: boolean; items?: string[] }
  > {}

export const usePatchPiiRules = (options?: UsePatchPiiRulesOption) => {
  return useMutation({
    ...options,
    method: 'PATCH',
    url: `/pii/`,
  });
};

export interface UsePostPiiRuleOptions
  extends UseMutationOptions<never, EnhancedErrorResult, PiiRulePayload> {}

export const usePostPiiRule = (options?: UsePostPiiRuleOptions) => {
  return useMutation({
    ...options,
    method: 'POST',
    url: '/pii/',
  });
};

export const useFetchPiiRuleExport = (id: string, options?: UseFetchOptions) => {
  return useFetch({
    ...options,
    queryKey: [...piiRulesCacheKeys.export(id), options?.params],
    responseType: 'blob',
    url: `/pii/matching/${id}/export/`,
  });
};

export interface UsePostPiiRuleSyncOptions
  extends UseMutationOptions<PiiRuleSyncPayload, EnhancedErrorResult, {}> {}

export const usePostPiiRuleSync = (options?: UsePostPiiRuleSyncOptions) => {
  return useMutation({
    ...options,
    method: 'POST',
    url: '/pii/sync/',
  });
};

const fetchPiiRuleSyncSelect = rawTransform(PiiRuleSyncModel);

export const useFetchPiiRuleSync = (options?: UseFetchOptions) => {
  return useFetch<PiiRuleSyncModel>({
    ...options,
    queryKey: [...piiRulesCacheKeys.sync, options?.params],
    select: fetchPiiRuleSyncSelect,
    url: '/pii/sync/',
  });
};
