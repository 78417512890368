import React from 'react';
import { Cell } from 'react-table';

import { TagModel } from '@api/tags/TagModel';
import DateTime from '@components/DateTime';
import LinkedCell from '@components/Table/Cells/LinkedCell';
import UserCell from '@components/Table/Cells/UserCell';
import Table from '@components/Table/Table';
import { sortDates } from '@components/Table/Table/sortDates';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import { WorkspaceItemModel } from '@models/WorkspaceItemModel';

interface FavoritesTableProps {
  findTag?: (guid: string) => TagModel | undefined;
  hideUserColumn?: boolean;
  itemCount?: number;
  items?: WorkspaceItemModel[];
  stickyHeader?: boolean;
  userColName?: string;
}

const FavoritesTable: React.FC<FavoritesTableProps> = ({
  findTag,
  hideUserColumn,
  itemCount = 0,
  items,
  stickyHeader,
  userColName = 'User',
}) => {
  const columns: ColumnConfig<WorkspaceItemModel>[] = React.useMemo(
    () => [
      {
        Cell: (props: Cell<WorkspaceItemModel>) => {
          const { item, itemName } = props.row.original;

          const isTag = item?.obj?.objectType === 'tag';
          const tagItem = item?.obj?.guid && findTag?.(item?.obj?.guid);
          const noLink = isTag && !tagItem;
          const cellItem = (isTag && tagItem) || item?.obj;

          return cellItem ? (
            <LinkedCell
              {...props}
              item={cellItem}
              itemName={itemName}
              noLink={noLink}
              noLinkOnInvalidUrl
              showDataSourceIcon
              showIcon
              showNameTooltip
            />
          ) : null;
        },
        Header: `Name (${itemCount})`,
        accessor: (d) => d?.item?.obj?.name,
        disableHiding: true,
        id: 'name',
        width: '150%',
      },
      {
        Cell: (props: Cell<WorkspaceItemModel>) => {
          return <UserCell {...props} user={props.row.original.user} />;
        },
        Header: `${userColName}`,
        accessor: (d) => d?.user,
        disableSortBy: true,
        id: 'user',
        width: '120%',
      },
      {
        Cell: ({ row }: Cell<WorkspaceItemModel>) => {
          const item = row.original?.item?.obj;
          return item && 'lastUpdatedOn' in item && item.lastUpdatedOn ? (
            <DateTime datetime={item.lastUpdatedOn} format="textMonthFormat" />
          ) : null;
        },
        Header: 'Last Updated',
        accessor: (d) => {
          const item = d?.item!.obj!;
          return 'lastUpdatedOn' in item ? item.lastUpdatedOn?.toDate() : null;
        },
        disableFilters: true,
        id: 'lastUpdated',
        sortDescFirst: true,
        sortType: sortDates,
        width: '130%',
      },
    ],
    [itemCount],
  );

  return (
    <TableStyled>
      <Table
        basic="very"
        className="table-full"
        columns={columns}
        compact
        data={items || []}
        disableColumnFiltering
        disableFilters
        disablePagination
        disableRowSelect
        initialState={{
          hiddenColumns: hideUserColumn ? ['user'] : [],
          sortBy: [
            {
              desc: true,
              id: 'name',
            },
          ],
        }}
        loading={items === undefined}
        sortable
        stickyHeader={stickyHeader}
        unstackable
      />
    </TableStyled>
  );
};

export default FavoritesTable;
