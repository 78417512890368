import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import useMutation, { UseMutationOptions } from '@hooks/useMutation';
import { MetadataDescriptionResponse } from '@models/MetadataEditDescriptionResponse';
import { MetadataModel } from '@models/MetadataModel';

import { CommentModel } from '../comments/CommentModel';
import rawTransform from '../rawTransform';

import * as cacheKeys from './cacheKeys';
import { OwnedObjectsModel } from './OwnedObjectsModel';

export const fetchMetadataByIdSelect = rawTransform(MetadataModel);
export const useFetchMetadataById = (id: string, options?: UseFetchOptions<MetadataModel>) => {
  return useFetch<MetadataModel>({
    ...options,
    queryKey: [...cacheKeys.metadata(id), options?.params],
    select: fetchMetadataByIdSelect,
    url: `/metadata/${id}/`,
  });
};

export const usePatchMetadataById = (id: string, options?: UseMutationOptions<MetadataModel>) => {
  return useMutation<MetadataModel>({
    ...options,
    method: 'PATCH',
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(fetchMetadataByIdSelect(data), variables, context);
    },
    url: `/metadata/${id}/`,
  });
};

export const usePatchMetadata = (options?: UseMutationOptions<MetadataDescriptionResponse>) => {
  return useMutation<MetadataDescriptionResponse>({
    ...options,
    method: 'PATCH',
    url: '/metadata/',
  });
};

export const usePatchMetadataDescriptionsAndOwners = (
  options?: UseMutationOptions<MetadataModel>,
) => {
  return useMutation<MetadataModel>({
    ...options,
    method: 'PATCH',
    url: '/metadata/update/',
  });
};

interface CopyDataResponse {
  items: string[];
}

export const useFetchMetadataCopyData = (
  id: string,
  options?: UseFetchOptions<CopyDataResponse>,
) => {
  return useFetch<CopyDataResponse>({
    ...options,
    queryKey: cacheKeys.copyData(id),
    url: `/metadata/${id}/copy/`,
  });
};

export const usePatchMetadataShowHide = (
  visibility: 'show' | 'hide',
  options: UseMutationOptions<MetadataModel>,
) => {
  return useMutation<MetadataModel>({
    ...options,
    method: 'PATCH',
    url: `/metadata/${visibility}/`,
  });
};

const fetchMetadataCommentsSelect = rawTransform(CommentModel);
export const useFetchMetadataComments = (id: string, options?: UseFetchOptions<CommentModel[]>) => {
  return useFetch<CommentModel[]>({
    ...options,
    queryKey: [...cacheKeys.commentTarget(id), options?.params],
    select: fetchMetadataCommentsSelect,
    url: `/metadata/${id}/comments/`,
  });
};

export const fetchMetadataOwnedObjectsByOwnerIdSelect = rawTransform(OwnedObjectsModel);
export const useFetchMetadataOwnedObjectsByOwnerId = (
  ownerId: string,
  options?: UseFetchOptions<OwnedObjectsModel>,
) => {
  return useFetch<OwnedObjectsModel>({
    ...options,
    queryKey: [...cacheKeys.metadataOwnedObjectsByOwnerId(ownerId), options?.params],
    select: fetchMetadataOwnedObjectsByOwnerIdSelect,
    url: `/metadata/owned-objects/${ownerId}/`,
  });
};

export const usePatchMetadataOwnedObjectsByOwnerId = (
  ownerId: string,
  options?: UseMutationOptions<OwnedObjectsModel>,
) => {
  return useMutation<OwnedObjectsModel>({
    ...options,
    method: 'PATCH',
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(fetchMetadataOwnedObjectsByOwnerIdSelect(data), variables, context);
    },
    url: `/metadata/owned-objects/${ownerId}/`,
  });
};
