import React from 'react';

import { useDateRangePickerContext } from '@components/DateRangePicker/DateRangePicker.context';
import QueryByCostTable from '@components/Table/QueryByCostTable';

import CostAnalysisSharedFilter from '../CostAnalysisSharedFilter';
import useWarehouseSelect from '../CostAnalysisSharedFilter/useWarehouseSelect';

import { SectionTitleWrapper, Subtitle } from './SectionTitle';

const CostAnalysisQueryByCostTab: React.FC = () => {
  const { dateRange } = useDateRangePickerContext();
  const { selectedWarehouses } = useWarehouseSelect();
  return (
    <>
      <SectionTitleWrapper my={2}>
        <Subtitle>Top 100 queries by cost</Subtitle>
        <CostAnalysisSharedFilter />
      </SectionTitleWrapper>
      <QueryByCostTable range={dateRange} warehouses={selectedWarehouses} />
    </>
  );
};

export default CostAnalysisQueryByCostTab;
