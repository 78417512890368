import React from 'react';

import { useFetchActivity } from '@api/activity';
import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader/CircularLoader';
import Avatar from '@components/UI/Avatar';
import Icon from '@components/UI/Icon';
import stripSpaces from '@utils/stripSpaces';

import {
  StyledLabel,
  StyledRowWrapper,
} from '../DescriptionSelectorItem/SourceLabel/SourceLabel.styles';

interface DescriptionAvatarProps {
  guid?: string;
  plainUserDescription?: string;
  showLabel?: boolean;
  size?: string;
}

const activityQuery = stripSpaces(`{
  guid,
  user{
    guid,
    first_name,
    last_name,
    avatar,
    is_active
  }
}`);

export const activityRequestParams = {
  order: '-performed_on',
  page_size: 1,
  query: activityQuery,
  types: 'update',
};

const DescriptionAvatar: React.FC<DescriptionAvatarProps> = ({
  guid,
  plainUserDescription,
  showLabel = false,
  size = '16px',
}) => {
  const { data: activityResponse, isLoading } = useFetchActivity({
    params: { ...activityRequestParams, data: plainUserDescription, targets: `${guid}` },
  });

  const user = activityResponse?.results[0]?.user;

  if (isLoading) {
    return (
      <Box alignItems="center" compDisplay="flex">
        <CircularLoader borderWidth={2} compSize={1.5} />
      </Box>
    );
  }

  return (
    <StyledRowWrapper>
      {user?.mappedAvatar ? (
        <>
          <Avatar data-testid="user-avatar" size={size} {...user?.mappedAvatar} />
          {showLabel && <StyledLabel>{user.fullName}</StyledLabel>}
        </>
      ) : (
        <Icon name="user" size={size} />
      )}
    </StyledRowWrapper>
  );
};

export default DescriptionAvatar;
