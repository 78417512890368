import React, { useMemo } from 'react';

import { ObjectType } from '@api/types';
import type { SearchResultIconProps } from '@components/SearchBar/SearchResult/SearchResultIcon';
import SearchResultIcon from '@components/SearchBar/SearchResult/SearchResultIcon';
import Icon from '@components/UI/Icon';
import DataTypesModel from '@models/DataTypesModel';

import {
  StyledSelectedSearchItemPill,
  StyledSelectedSearchItemPillDetailsContainer,
} from './SelectedSearchItemPill.styles';

interface SelectedItem extends Pick<SearchResultIconProps, 'mappedAvatar'> {
  dataTypes?: DataTypesModel;
  fullName?: string;
  guid: string;
  name?: string;
  searchName?: string;
}

interface Props {
  disabled?: boolean;
  hideDataSourceIcon?: boolean;
  onRemove: (id?: string) => void;
  selectedItem: SelectedItem;
  useDefaultName?: boolean;
}

const TYPES_TO_HIDE_DS_ICON: ObjectType[] = ['datasource', 'user', 'team'];

const SelectedSearchItemPill: React.FC<Props> = ({
  disabled,
  hideDataSourceIcon,
  onRemove,
  selectedItem,
  useDefaultName,
}) => {
  const { dataTypes, guid, mappedAvatar } = selectedItem;

  const name = useMemo(() => {
    const DEFAULT_NAME = selectedItem.fullName ?? selectedItem.name ?? '';
    if ('searchName' in selectedItem && !useDefaultName) {
      return selectedItem.searchName;
    }
    return DEFAULT_NAME;
  }, [selectedItem, useDefaultName]);

  return (
    <StyledSelectedSearchItemPill
      className="current-selected-pill"
      opacity={disabled ? '0.5' : '1'}
    >
      <StyledSelectedSearchItemPillDetailsContainer>
        {!TYPES_TO_HIDE_DS_ICON.includes(dataTypes?.objectType as ObjectType) &&
          !hideDataSourceIcon &&
          dataTypes?.icons?.dataSource && (
            <Icon mr={0.5} name={dataTypes.icons.dataSource} size="16px" />
          )}
        {dataTypes && (
          <SearchResultIcon
            dataTypes={dataTypes}
            guid={guid}
            mappedAvatar={mappedAvatar}
            name={name}
          />
        )}
        <span className="title">{name}</span>
      </StyledSelectedSearchItemPillDetailsContainer>
      <Icon
        className="close-icon"
        cursor={disabled ? 'not-allowed' : 'pointer'}
        name="close"
        onClick={() => onRemove(guid)}
        p={0.3}
        size="16px"
      />
    </StyledSelectedSearchItemPill>
  );
};

export default SelectedSearchItemPill;
