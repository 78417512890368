import React, { useEffect, useState } from 'react';

import { DataSourcesMutatePayload, useFetchDataSources } from '@api/dataSources';
import { useFetchDsUsers, usePatchDsUser } from '@api/dsusers';
import { DsUserModel } from '@api/dsusers/DsUserModel';
import Box from '@components/Box';
import {
  StyledFormHorizontalLabelGrid,
  StyledLabel,
} from '@components/DataSourceSetup/DataSourceSetup.styles';
import { getDatasourceOptions, getDsUsersOptionsV1 } from '@components/Dropdown/helpers';
import { DropzoneFormElement } from '@components/Dropzone';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import Checkbox from '@components/UI/Form/Checkbox';
import InputLabel from '@components/UI/Form/InputLabel';
import Select, { SelectValue } from '@components/UI/Select';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import { isWarehouseType } from '@models/DataSourceCredentials';
import { DataSourceModel } from '@models/DataSourceModel';

import useDataSourceMutation from '../useDataSourceMutation';

import { DataSourceFormProps } from './types';

const REQUIRED_FIELDS = ['name', 'email', 'password', 'periscopeJson'] as const;

const PeriscopeForm: React.FC<DataSourceFormProps> = ({
  children,
  dataSource,
  dataType,
  name = '',
  onSuccess,
  renderBefore,
}) => {
  const [datasourceConnections, setDatasourceConnections] = useState<DataSourceModel[]>([]);
  const [showLogsCredentialsEmail, setShowLogsCredentialsEmail] = useState(true);
  const [showLogsCredentialsCookies, setShowLogsCredentialsCookies] = useState(false);
  const [selectedDsConnection, setSelectedDsConnection] = useState<SelectValue>();
  const [dsUsers, setDsUsers] = useState<DsUserModel[]>([]);
  const [selectedDsUser, setSelectedDsUser] = useState<SelectValue>();
  const [dsUserConfig, setDsUserConfig] = useState<{}>({});
  const segment = useSegmentContext();

  const { mutate: updateDsUser } = usePatchDsUser(String(selectedDsUser?.[0]?.value ?? ''));

  const { error, isLoading, mutate } = useDataSourceMutation({
    dataSource,
    onSuccess: (ds) => {
      onSuccess(ds);

      if (selectedDsUser) {
        updateDsUser({ data: { is_periscope_service_account: true } });
      }
    },
  });

  const { handleChange, handleSubmit, setValues, values } = useForm({
    initialValues: {
      email: '',
      name: dataSource?.name ?? name,
      password: '',
      periscopeJson: undefined as string | undefined,
    },
    onSubmit: (val) => {
      const payload = {} as DataSourcesMutatePayload;
      if (dataSource) {
        payload.guid = dataSource.guid;
        payload.type = dataSource.type;
      } else {
        payload.type = dataType;
      }
      payload.name = val.name;
      payload.credentials = {
        email: val.email,
        is_cookies_credentials: showLogsCredentialsCookies,
        is_email_credentials: showLogsCredentialsEmail,
        password: val.password,
        periscope_json: val.periscopeJson,
      };
      mutate(payload);

      segment?.track(SegmentTrackEventName.CreateServiceAccountConnectButtonClicked, { dataType });
    },
  });

  const {
    data: dataSourcesResponse,
    error: dataSourcesError,
    isLoading: isLoadingDataSources,
  } = useFetchDataSources();

  useEffect(() => {
    if (selectedDsConnection?.[0]) {
      setDsUserConfig({
        params: {
          datasources: selectedDsConnection[0].value,
        },
      });
    }
  }, [selectedDsConnection]);

  const {
    data: dsUserResponse,
    error: dsUsersError,
    isLoading: loadingDsUsers,
  } = useFetchDsUsers({
    ...dsUserConfig,
  });

  useEffect(() => {
    if (dataSourcesResponse) {
      setDatasourceConnections(
        dataSourcesResponse.results.filter((ds: DataSourceModel) => isWarehouseType(ds.type)),
      );
    }
  }, [dataSourcesResponse]);

  useEffect(() => {
    if (selectedDsConnection && dsUserResponse) {
      setDsUsers(dsUserResponse.results);
    }
  }, [dsUserResponse]);

  useEffect(() => {
    if (showLogsCredentialsEmail) {
      setShowLogsCredentialsCookies(false);
    }
  }, [showLogsCredentialsEmail]);

  useEffect(() => {
    if (showLogsCredentialsCookies) {
      setShowLogsCredentialsEmail(false);
    }
  }, [showLogsCredentialsCookies]);

  const handleDbConnectionChange = (newValue: SelectValue) => {
    setSelectedDsConnection(newValue);
    setSelectedDsUser(undefined);
  };

  const onAddCredentialsJSON = (newValue?: string) =>
    setValues((oldValues) => ({ ...oldValues, periscopeJson: newValue }));

  const dataSourceOptions = getDatasourceOptions(datasourceConnections);
  const userOptions = getDsUsersOptionsV1(dsUsers);
  const usernameFieldIsDisabled = !selectedDsConnection || selectedDsConnection?.length === 0;
  const isInvalid = REQUIRED_FIELDS.some((key) => Boolean(values[key]) === false);

  return (
    <Form isLoading={isLoading || isLoadingDataSources} onSubmit={handleSubmit}>
      <StyledFormHorizontalLabelGrid>
        {renderBefore?.({ error, loading: isLoading })}
        <StyledLabel>
          Display Name
          <Input
            error={error?.data?.name}
            helperText={error?.data?.name}
            maxLength={50}
            name="name"
            onChange={handleChange}
            placeholder="Periscope"
            type="text"
            value={values.name}
          />
        </StyledLabel>
        <Box compDisplay="flex" gap={1} gridColumn="2/3">
          <InputLabel>
            <Checkbox
              checked={showLogsCredentialsEmail}
              disabled={!!dataSource}
              onChange={() => setShowLogsCredentialsEmail((prev) => !prev)}
              variantColor="white"
            />
            Email and Password Credentials
          </InputLabel>
          <InputLabel>
            <Checkbox
              checked={showLogsCredentialsCookies}
              disabled={!!dataSource}
              onChange={() => setShowLogsCredentialsCookies((prev) => !prev)}
              variantColor="white"
            />
            Cookies json
          </InputLabel>
        </Box>
        {showLogsCredentialsCookies && (
          <StyledLabel as="div">
            Google Auth credentials JSON file
            <DropzoneFormElement
              isComplete={values.periscopeJson !== undefined}
              onFileAccept={onAddCredentialsJSON}
              text="Drag & Drop your google_cookies.json here"
            />
          </StyledLabel>
        )}
        {showLogsCredentialsEmail && (
          <>
            <StyledLabel>
              Email
              <Input
                error={error?.data?.email}
                helperText={error?.data?.email}
                name="email"
                onChange={handleChange}
                placeholder="Email"
                type="text"
                value={values.email}
              />
            </StyledLabel>
            <StyledLabel>
              Password
              <Input
                error={error?.data?.password}
                helperText={error?.data?.password}
                name="password"
                onChange={handleChange}
                placeholder="Password"
                type="password"
                value={values.password}
              />
            </StyledLabel>
          </>
        )}
        {datasourceConnections.length > 0 && (
          <>
            <StyledLabel as="div">
              DB Connection
              <Select
                error={Boolean(dataSourcesError)}
                isLoading={isLoadingDataSources}
                maxOptionsVisible={7}
                onChange={handleDbConnectionChange}
                options={dataSourceOptions}
                showClearButton
                value={selectedDsConnection}
              />
            </StyledLabel>
            <StyledLabel as="div">
              DB Username
              <Select
                error={Boolean(dsUsersError)}
                isDisabled={usernameFieldIsDisabled}
                isLoading={loadingDsUsers}
                maxOptionsVisible={7}
                onChange={setSelectedDsUser}
                options={userOptions}
                placeholder={
                  usernameFieldIsDisabled ? 'Please select a DB connection first.' : undefined
                }
                showClearButton
                value={selectedDsUser}
              />
            </StyledLabel>
          </>
        )}
      </StyledFormHorizontalLabelGrid>
      {children?.({ error, loading: isLoading || isInvalid })}
    </Form>
  );
};

export default PeriscopeForm;
