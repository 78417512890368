import React from 'react';

import Icon from '@components/UI/Icon';
import { HierarchyData } from '@models/HierarchyModel';

export const DataManagerHierarchy: HierarchyData[] = [
  {
    breadcrumbLabelList: [],
    children: [],
    guid: '/admin/discussion',
    iconEl: <Icon color="currentColor" name="comments-filled" size="16px" />,
    name: 'Discussion',
    objectType: 'menu-item',
    url: '/admin/discussion',
  },
  {
    breadcrumbLabelList: [],
    children: [],
    guid: '/admin/metadata-upload',
    iconEl: <Icon color="currentColor" name="upload" size="14px" />,
    name: 'Metadata Upload',
    objectType: 'menu-item',
    url: '/admin/metadata-upload',
  },
];
