import styled from '@emotion/styled';

interface BackdropStaticStyleProps {
  isActive: boolean;
}

const BackdropStaticStyle = styled.div<BackdropStaticStyleProps>`
  display: ${(props) => (!props.isActive ? 'none' : undefined)};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: transparent;
  z-index: 1;
`;

export default BackdropStaticStyle;
