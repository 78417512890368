import React, { useState } from 'react';
import type { ReactNode } from 'react';

import type { StyledBoxProps } from '@components/Box/Box.styles';

import type { OverflowVariantProps, StyledTabProps, TabsVariantProps } from './Tabs.styles';
import {
  StyledTab,
  StyledTabs,
  StyledTabsLeft,
  StyledTabsOverflowGradient,
  StyledTabsRight,
} from './Tabs.styles';

export interface TabProps extends StyledTabProps {
  component: () => ReactNode;
  icon?: ReactNode;
  id?: string;
  label?: string | ReactNode;
  onTabChange?: () => void;
}

export interface TabsProps extends OverflowVariantProps, TabsVariantProps, StyledBoxProps {
  action?: ReactNode;
  activeTab?: number;
  config: TabProps[];
  subTabs?: boolean;
}

const Tabs = ({
  action,
  activeTab,
  config,
  overflowVariant,
  subTabs = false,
  variant,
  ...other
}: TabsProps) => {
  const [active, setActive] = useState(0);
  const isControlled = activeTab !== undefined;
  const value = isControlled ? activeTab : active;

  return (
    <>
      <StyledTabs variant={variant} {...other}>
        <StyledTabsLeft overflowVariant={overflowVariant}>
          {config.map((item, index) => (
            <StyledTab
              key={item.id}
              as={item.as}
              className={value === index ? 'active' : ''}
              cursor={item.cursor}
              ml={item.ml}
              onClick={() => {
                if (index === value) return;
                if (!isControlled) setActive(index);
                item.onTabChange?.();
              }}
              subTab={subTabs}
              variant={item.variant || variant}
              zIndex={item.zIndex}
            >
              {item.icon}
              {item.label}
            </StyledTab>
          ))}
        </StyledTabsLeft>
        {overflowVariant === 'scroll' && <StyledTabsOverflowGradient />}
        {action && <StyledTabsRight>{action}</StyledTabsRight>}
      </StyledTabs>
      {config?.[value]?.component()}
    </>
  );
};

export default Tabs;
