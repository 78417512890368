import React from 'react';

import Box from '@components/Box';
import { Column, Row } from '@components/Grid';

interface SourceColumnsTabRowRowProps {
  firstColumn: React.ReactNode;
  secondColumn: React.ReactNode;
}

const SourceColumnsTabRow: React.FC<SourceColumnsTabRowRowProps> = ({
  firstColumn,
  secondColumn,
}) => {
  return (
    <Row alignItems="center" space={0}>
      <Column alignItems="center" compDisplay="flex" lg={10} xs={9}>
        {firstColumn}
      </Column>
      <Column lg={2} xs={3}>
        <Box alignItems="center" compDisplay="flex" justifyContent="space-between">
          {secondColumn}
        </Box>
      </Column>
    </Row>
  );
};

export default SourceColumnsTabRow;
