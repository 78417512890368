import styled from '@emotion/styled';

import type { MakeSpaceProps } from '@styles/mixins/makeSpace';
import makeSpace from '@styles/mixins/makeSpace';

const makeMultipleLines = ({
  multipleLinesNumber,
}: Pick<StyledEllipsisProps, 'multipleLinesNumber'>) => {
  if (!multipleLinesNumber) {
    return undefined;
  }

  return {
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': multipleLinesNumber,
    display: '-webkit-box',
    'overflow-wrap': 'break-word',
    'white-space': 'break-spaces',
    'word-break': 'break-word',
  };
};

interface StyledEllipsisProps {
  compWidth?: string;
  multipleLinesNumber?: string;
}

export const StyledEllipsis = styled.span<StyledEllipsisProps & MakeSpaceProps>`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: ${({ compWidth = '100%' }) => compWidth};

  ${({ theme, ...props }) => makeSpace(theme, props)};
  ${({ multipleLinesNumber }) => makeMultipleLines({ multipleLinesNumber })}
`;

export default StyledEllipsis;
