import React from 'react';

import { useFetchAnalyticsUsersActive } from '@api/analytics';
import DateRangePicker from '@components/DateRangePicker';
import { Column } from '@components/Grid';
import TabError from '@components/TabContent/TabError';
import SectionTitle, {
  SectionTitleWrapper,
} from '@pages/Admin/AdminCostAnalysisPage/tabs/SectionTitle';
import { Filter } from '@utils';

import { DateRangeProps } from '../../Analytics.types';

import TopActiveUsersTable from './tables/TopActiveUsersTable';

export const topUsersDefaultFiltersConfig: Filter.FilterOptions = {
  order: '-activity_count',
  page: 1,
  page_size: 50,
  sortColumn: 'activityCount',
  sortDirection: 'descending',
};

export const topUsersSortConfig: { [key: string]: string } = {
  activityCount: 'activity_count',
  lastActivity: 'last_activity_in_range',
  name: 'name',
  team: 'team',
};

const AnalyticsUsersTopUsers: React.FC<DateRangeProps> = ({
  dateRange: [start_date, end_date],
  onDateRangeChange,
}) => {
  const filterService = Filter.useUpdateFilters(
    topUsersDefaultFiltersConfig,
    {},
    topUsersSortConfig,
    '-activity_count',
  );

  const { data, isError, isLoading } = useFetchAnalyticsUsersActive({
    params: {
      ...Filter.setParams(filterService.filter),
      end_date,
      start_date,
    },
  });

  const handleFilterChange = (range: [string, string]) => {
    onDateRangeChange(range);
    filterService.changePage(1);
  };

  const totalPages = data ? Math.ceil(data.count / topUsersDefaultFiltersConfig.page_size!) : 1;

  if (isError) {
    return <TabError />;
  }

  return (
    <>
      <SectionTitleWrapper my={3}>
        <SectionTitle subtitle="Based on all activity logs including views" title="Top Users" />
        <DateRangePicker initialRange={[start_date, end_date]} onChange={handleFilterChange} />
      </SectionTitleWrapper>
      <Column xs={12}>
        <TopActiveUsersTable
          detailed
          filterService={filterService}
          loading={isLoading}
          rows={data?.results}
          totalPages={totalPages}
        />
      </Column>
    </>
  );
};

export default AnalyticsUsersTopUsers;
