import React from 'react';

import { useFetchTagChild } from '@api/tags';
import { TagModel } from '@api/tags/TagModel';
import Box from '@components/Box';
import MetadataGrid from '@components/MetadataGrid';
import MetadataGridCard from '@components/MetadataGridCard';
import Text from '@components/Text';
import Card from '@components/UI/Card';
import Icon from '@components/UI/Icon';
import { useModal } from '@context/Modal';
import { MetadataData } from '@models/MetadataModel';
import TagCardFooter from '@pages/TagsPage/TagCardFooter';
import theme from '@styles/theme';
import { urlFor } from '@utils/routing';
import wrapString from '@utils/wrapString';

interface SubTagsProps {
  canEdit: boolean;
  data?: MetadataData;
}

const SubTags: React.FC<SubTagsProps> = ({ canEdit, data }) => {
  const { data: childData, isLoading } = useFetchTagChild(data?.guid || '');
  const { MODAL_IDS, openModal } = useModal();

  const handleOnClickEdit = (tag: TagModel) => {
    openModal(MODAL_IDS.createTag, { initialState: tag, isEdit: Boolean(tag) });
  };

  const handleOnClickDelete = (tag: TagModel) => {
    openModal(MODAL_IDS.deleteTag, { item: tag });
  };

  if (!childData?.results || childData?.results.length === 0) {
    return null;
  }

  return (
    <Card pb={8} pt={2.5} px={3.5}>
      <Box compDisplay="flex" mb={2}>
        <Text
          as="h3"
          color="gray.700"
          fontSize={theme.typography.h4.fontSize}
          fontWeight="medium"
          m={0}
        >
          Sub Tags
        </Text>
      </Box>
      <MetadataGrid
        columnsCount={{
          lg: 12,
          md: 12,
          xl: 6,
          xs: 12,
        }}
        data={childData?.results}
        isLoading={isLoading}
      >
        {(item) => {
          return (
            <MetadataGridCard
              data-testid={`tag-${item.guid}`}
              description={item.description}
              footer={<TagCardFooter guid={item.guid} />}
              icon={
                <Box alignSelf="center">
                  <Icon color={item.color} name={item.icon} size="24px" />
                </Box>
              }
              iconColor={item.color}
              isEditable={canEdit}
              minHeight="180px"
              name={`${item.name} ${wrapString(item.count)}`}
              onDelete={() => handleOnClickDelete(item)}
              onEdit={() => handleOnClickEdit(item)}
              url={urlFor(item)}
            />
          );
        }}
      </MetadataGrid>
    </Card>
  );
};

export default SubTags;
