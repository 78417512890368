import React from 'react';

import Box from '@components/Box';
import Text from '@components/Text';
import Icon from '@components/UI/Icon';
import useWindowSize from '@hooks/useWindowSize';

import StyledIndex, { StyledName } from './OnboardingSteps.styles';

export type step = 'accountSetup' | 'addDS' | 'importData' | 'finish';

type STEP = {
  key: step;
  name: string;
};

const STEPS: STEP[] = [
  { key: 'accountSetup', name: 'Set up an account' },
  { key: 'addDS', name: 'Add data source' },
  { key: 'importData', name: 'Import data' },
];

interface SingleStepProps {
  index: number;
  isActive?: boolean;
  isCompleted?: boolean;
  text: string;
}

const getTextColor = (isCompleted?: boolean, isActive?: boolean): string => {
  if (isCompleted || isActive) return '#ffffff';
  return '#70757D';
};

const SingleStep: React.FC<SingleStepProps> = ({ index, isActive, isCompleted, text }) => {
  return (
    <>
      <StyledIndex
        alignItems="center"
        compDisplay="flex"
        compHeight="40px"
        compWidth="40px"
        index={index}
        isActive={isActive}
        isCompleted={isCompleted}
        justifyContent="center"
      >
        {isCompleted ? (
          <Icon color="white" name="check" />
        ) : (
          <Text
            color={getTextColor(isCompleted, isActive)}
            fontWeight={isActive ? 'medium' : 'regular'}
          >
            {index}
          </Text>
        )}
      </StyledIndex>
      <StyledName
        alignItems="center"
        compDisplay="flex"
        compHeight="40px"
        compWidth="240px"
        isActive={isActive}
        isCompleted={isCompleted}
        justifyContent="center"
      >
        <Text
          color={getTextColor(isCompleted, isActive)}
          fontWeight={isActive ? 'medium' : 'regular'}
        >
          {text}
        </Text>
      </StyledName>
    </>
  );
};

interface OnboardingStepProps {
  currentStep?: step;
}

const OnboardingSteps: React.FC<OnboardingStepProps> = ({ currentStep }) => {
  const { isUpMd } = useWindowSize();

  if (!isUpMd || !currentStep || currentStep === 'finish') return null;

  const currentIdx = STEPS.findIndex((step) => step.key === currentStep);

  return (
    <Box
      compDisplay="flex"
      flexDirection="row"
      justifyContent="center"
      m="auto"
      maxWidth="820px"
      mt={3}
      position="relative"
    >
      {STEPS.map((step, index) => (
        <SingleStep
          key={step.key}
          index={index + 1}
          isActive={currentStep === step.key}
          isCompleted={index < currentIdx}
          text={step.name}
        />
      ))}
    </Box>
  );
};

export default OnboardingSteps;
