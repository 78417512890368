import styled from '@emotion/styled';
import type { ElementType } from 'react';

import type { StyledBoxProps } from '@components/Box';
import Box from '@components/Box';
import Image from '@components/UI/Image';

interface StyledAvatarImageProps {
  isLoaded?: boolean;
}

export const StyledAvatarImage = styled(Image)<StyledAvatarImageProps>`
  visibility: ${({ isLoaded }) => (isLoaded ? 'visible' : 'hidden')};
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const StyledAvatarInitials = styled.svg`
  width: 100%;
  height: 100%;
  fill: white;
  line-height: normal;
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
`;

export interface StyledAvatarProps extends StyledBoxProps {
  as?: ElementType;
  className?: string;
  isGrey?: boolean;
  size?: string;
  to?: string;
}

export const StyledAvatar = styled(Box)<StyledAvatarProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 50%;
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  filter: ${({ isGrey }) => (isGrey ? 'grayscale(100%)' : 'none')};
  cursor: ${({ isGrey }) => isGrey && 'not-allowed'};
  overflow: hidden;
`;

StyledAvatar.defaultProps = {
  noDefault: true,
};
