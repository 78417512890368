import paginatedTransform from '@api/paginatedTransform';
import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import { PaginatedResponse } from '@models/PaginatedResponse';

import QueryLogRecordModel from './QueryLogRecordModel';

export const queryLogCacheKeys = {
  queryLog: ['queryLog'],
};

const fetchQueryLogSelect = paginatedTransform(QueryLogRecordModel);
export const useFetchQueryLog = (
  options?: UseFetchOptions<PaginatedResponse<QueryLogRecordModel>>,
) => {
  return useFetch<PaginatedResponse<QueryLogRecordModel>>({
    ...options,
    queryKey: [...queryLogCacheKeys.queryLog, options?.params],
    select: fetchQueryLogSelect,
    url: '/query-log/',
  });
};
