import React, { useMemo } from 'react';

import { RepositoryModel } from '@api/syncBack/RepositoryModel';
import Wizard from '@components/Wizard/Wizard';

import GithubFinishingStep from './GithubFinishingStep';
import GithubFormStep from './GithubFormStep';
import GithubInfoStep from './GithubInfoStep';

export interface GithubConfigurationWizardProps {
  onClose?: () => void;
  repository?: RepositoryModel;
}

const GithubConfigurationWizard = ({ onClose, repository }: GithubConfigurationWizardProps) => {
  const steps = useMemo(
    () => [
      {
        Content: GithubInfoStep,
        id: '0',
        title: 'Install the GitHub App',
      },
      {
        // eslint-disable-next-line react/no-unstable-nested-components
        Content: () => <GithubFormStep repository={repository} />,
        id: '1',
        title: 'Add Connection Information',
      },
      {
        Content: GithubFinishingStep,
        id: '2',
        title: 'Finish',
      },
    ],
    [repository],
  );

  return (
    <Wizard
      initialStep={repository ? 1 : undefined}
      onClose={() => onClose?.()}
      onDone={() => onClose?.()}
      steps={steps}
      title="Connect GitHub"
    />
  );
};

export default GithubConfigurationWizard;
