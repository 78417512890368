import React from 'react';
import { biSources, warehouseSources } from '@configs/dataSources/config';
import { useRecoilValue } from 'recoil';

import { TagModel } from '@api/tags/TagModel';
import { MetadataObjectType, MetadataOption, metadataType } from '@atoms';
import type { AccordionDropdownItemProps } from '@components/Accordion';
import { Accordion, AccordionDropDownItem, AccordionItem } from '@components/Accordion';
import AppTag from '@components/AppTag';
import Emoji from '@components/Emoji';
import Popularity from '@components/Popularity';
import RightSidebarContainer from '@components/RightSidebar/RightSidebar';
import Icon from '@components/UI/Icon';
import { useTagContext } from '@context/Tag';
import useNewLayout from '@hooks/useNewLayout';
import { DataSourceTypesType } from '@models/DataSourceCredentials';
import { Filter } from '@utils';
import { FilterOptions } from '@utils/filters';

import CollectionsFilter from './Sections/CollectionsFilter';
import CreatedByUsers from './Sections/CreatedByUsers';
import Owners from './Sections/Owners';
import SchemasOrTables from './Sections/SchemasOrTables';
import SearchFacetFilters from './Sections/SearchFacetsFilters';
import { FilterSidebarStyle, StyledLabel, StyledTitle } from './StyledFiltersSidebar';

export function checkItemChecked(guid: string, selectedValues?: string[]): boolean {
  return selectedValues ? selectedValues.includes(guid) : false;
}

const getDataTypeFilters = () => {
  const options = [
    { icon: 'number', id: 'number', label: 'Number' },
    { icon: 'text', id: 'string', label: 'String' },
    { icon: 'boolean', id: 'boolean', label: 'Boolean' },
    { icon: 'calendar', id: 'date', label: 'Date/Time' },
    { icon: 'variant', id: 'object_', label: 'Object' },
    { icon: 'location', id: 'geo', label: 'Geo' },
    { icon: 'question', id: 'unknown', label: 'Unknown' },
  ] as const;

  return options.map(({ icon, id, label }) => ({
    id,
    value: (
      <StyledLabel>
        <Icon mr={0.5} name={icon} size="14px" />
        {label}
      </StyledLabel>
    ),
  }));
};

const databaseDataTypeOptions: MetadataOption[] = [
  {
    key: 'tables',
    text: 'Table/View',
    value: 'tables',
  },
  {
    key: 'columns',
    text: 'Columns',
    value: 'columns',
  },
];

const projectsDataTypeOptions: MetadataOption[] = [
  {
    key: 'workbooks',
    text: 'Workbook',
    value: 'workbooks',
  },
  {
    key: 'tableauViews',
    text: 'View',
    value: 'tableauViews',
  },
];

const dashboardsDataTypeOptions: MetadataOption[] = [
  {
    key: 'power_bi_elements',
    text: 'All',
    value: 'power_bi_elements',
  },
  {
    key: 'power_bi_reports',
    text: 'Report',
    value: 'power_bi_reports',
  },
  {
    key: 'power_bi_dashboards',
    text: 'Dashboard',
    value: 'power_bi_dashboards',
  },
];

const lookerDashboardDataTypeOptions: MetadataOption[] = [
  {
    key: 'all',
    text: 'All',
    value: null,
  },
  {
    key: 'dashboards',
    text: 'Dashboards',
    value: 'dashboards',
  },
  {
    key: 'looks',
    text: 'Looks',
    value: 'looks',
  },
];

const dataTypesMap: Record<string, MetadataOption[]> = {
  dashboards: lookerDashboardDataTypeOptions,
  power_bi_elements: dashboardsDataTypeOptions,
  projects: projectsDataTypeOptions,
  tables: databaseDataTypeOptions,
};

const viewTypeFilters = [
  {
    id: 'sheet',
    value: (
      <div>
        <Icon compDisplay="inline-block" mr={0.5} name="tableau-worksheet" size="14px" />
        Sheet
      </div>
    ),
  },
  {
    id: 'dashboard',
    value: (
      <div>
        <Icon compDisplay="inline-block" mr={0.5} name="dashboard" size="14px" />
        Dashboard
      </div>
    ),
  },
];

const getPopularityFilters = (filterDefault: FilterOptions) => {
  // 0.5 kept to show 1 popularity bar
  return [5, 4, 3, 2, 1, 0.5].map((range) => ({
    checked: filterDefault?.popularityRawValues?.includes(range.toString()),
    id: range.toString(),
    value: <Popularity priority={range} />,
  }));
};

const getSelectStarFilters = (
  showLoadingIssuesFilter?: boolean,
  showJoinKeyFilter?: boolean,
  showHidden?: boolean,
  showDescriptionFilter?: boolean,
  isPopularity?: boolean,
  dataSourceType?: DataSourceTypesType | null,
) => {
  const ssDetected = [
    {
      id: 'new',
      value: <div>New</div>,
    },
    ...(isPopularity ? [{ id: 'no_popularity', value: <div>No Popularity / Inactive</div> }] : []),
    {
      id: 'no_tags',
      value: <div>No Tags</div>,
    },
    {
      id: 'no_downstream_lineage',
      value: <div>No Downstream Lineage</div>,
    },
  ];

  if (dataSourceType && Object.keys(warehouseSources).includes(dataSourceType)) {
    ssDetected.push({
      id: 'no_upstream_lineage',
      value: <div>No Upstream Lineage</div>,
    });
  }

  if (dataSourceType && Object.keys(biSources).includes(dataSourceType)) {
    ssDetected.push({
      id: 'no_source_tables',
      value: <div>No Source Tables</div>,
    });
  }

  if (showDescriptionFilter) {
    ssDetected.splice(1, 0, {
      id: 'no_description',
      value: <div>No Description</div>,
    });
  }

  if (showLoadingIssuesFilter) {
    ssDetected.splice(1, 0, {
      id: 'loading_issues',
      value: (
        <div>
          <Emoji className="filter-label-icon" type="warning" />
          Loading Issues
        </div>
      ),
    });
  }

  if (showJoinKeyFilter) {
    ssDetected.splice(1, 0, {
      id: 'is_join_key',
      value: (
        <div>
          <Icon className="filter-label-icon" name="join-link" />
          Join Field
        </div>
      ),
    });
  }

  if (showHidden) {
    ssDetected.push({
      id: 'is_hidden',
      value: <div>Hidden</div>,
    });
  }

  return ssDetected;
};

const glueSourceFileTypeFilters = [
  {
    key: 'all',
    text: 'All',
    value: null,
  },
  {
    key: 'csv',
    text: 'CSV',
    value: 'csv',
  },
  {
    key: 'json',
    text: 'JSON',
    value: 'json',
  },
  {
    key: 'unknown',
    text: 'Unknown',
    value: 'unknown',
  },
];

const getTagFilters = (tags: TagModel[] = [], selectedValues?: string[]) => {
  return tags.map((item) => ({
    checked: checkItemChecked(item.guid, selectedValues),
    id: item.guid,
    value: <AppTag ignoreLinksTo noLink showBreadcrumbs tag={item} variant="label" />,
  }));
};

export interface FiltersSidebarProps {
  dataSourceType?: DataSourceTypesType | null;
  dataTypeInitialSelected?: string | null;
  /**
   * @todo Architecture. Move options logic outside of the filter component.
   */
  dataTypeOptions?: Array<{ key: string; text: string; value: string | null }>;
  enableSchemaFilter?: boolean;
  enableTableFilter?: boolean;
  filterService: Filter.FilterServiceInterface;
  /*
   * Naming Convention:
   * Each Section i.e Accordion Item will be named using is<name>
   * Single filters will be named using show<name>
   */
  isCategoryTags?: boolean;
  isCollections?: boolean;
  isColumnDataType?: boolean;
  isCreatedBy?: boolean;
  isDashboardsDataType?: boolean;
  isDataType?: boolean;
  isDbtTags?: boolean;
  isOwners?: boolean;
  isPopularity?: boolean;
  isPowerBIDataType?: boolean;
  isProjectsDataType?: boolean;
  isSearchFilters?: boolean;
  isSelectStarDetected?: boolean;
  isStatusTags?: boolean;
  isViewDataType?: boolean;
  onSelectDataType?: AccordionDropdownItemProps['update'];
  query?: string;
  searchFacets?: { [key: string]: number };
  showDescriptionFilter?: boolean;
  showHidden?: boolean;
  showJoinKeyFilter?: boolean;
  showLoadingIssuesFilter?: boolean;
}

const FiltersSidebar: React.FC<FiltersSidebarProps> = ({
  dataSourceType,
  dataTypeInitialSelected,
  dataTypeOptions,
  enableSchemaFilter,
  enableTableFilter,
  filterService,
  isCategoryTags,
  isCollections,
  isColumnDataType,
  isCreatedBy,
  isDashboardsDataType,
  isDataType,
  isDbtTags,
  isOwners,
  isPopularity,
  isPowerBIDataType,
  isProjectsDataType,
  isSearchFilters,
  isSelectStarDetected,
  isStatusTags,
  isViewDataType,
  onSelectDataType,
  query,
  searchFacets,
  showDescriptionFilter,
  showHidden,
  showJoinKeyFilter,
  showLoadingIssuesFilter,
}) => {
  const {
    filter: filterDefaults,
    toggleHidden,
    toggleJoinKey,
    toggleLoadingIssues,
    toggleNoDownstreamLineage,
    toggleNoSourceTables,
    toggleNoUpstreamLineage,
    updateBIFolders,
    updateCategoryTags,
    updateColumnDataType,
    updateCreatedBy,
    updateDataType,
    updateDbtTags,
    updateDescriptions,
    updateFormatType,
    updateMetadataType,
    updateNew,
    updateNoPopularity,
    updateNoTags,
    updateOnwers,
    updatePopularity,
    updateSchema,
    updateSearchFilters,
    updateStatusTags,
    updateTable,
    updateTypes,
  } = filterService;
  const { shouldUseNewLayout } = useNewLayout();
  const { tagsArray } = useTagContext();
  const statusTags = tagsArray?.status ?? [];
  const categoryTags = tagsArray?.category ?? [];
  const dbtTags = tagsArray?.dbt ?? [];
  const metadata = useRecoilValue(metadataType);

  const getMetadataTypeFilters = (type: string = 'tables') => {
    const options = dataTypesMap[type];
    const selectedOption = options.find((o: any) => o.value === metadata);
    return {
      initialSelected: selectedOption?.value,
      options,
      placeholder: selectedOption?.text,
    };
  };

  return (
    <RightSidebarContainer aria-label="Filters" noPadding width={210}>
      <StyledTitle useNewLayout={shouldUseNewLayout}>Filters</StyledTitle>
      <FilterSidebarStyle>
        <Accordion>
          {isSelectStarDetected && (
            <AccordionItem
              accordionItems={getSelectStarFilters(
                showLoadingIssuesFilter,
                showJoinKeyFilter,
                showHidden,
                showDescriptionFilter,
                isPopularity,
                dataSourceType,
              )}
              title="Select Star Detected"
              update={(id) => {
                if (id === 'no_popularity') {
                  updateNoPopularity();
                } else if (id === 'no_tags') {
                  updateNoTags();
                } else if (id === 'no_description') {
                  updateDescriptions();
                } else if (id === 'new') {
                  updateNew();
                } else if (id === 'is_join_key') {
                  toggleJoinKey();
                } else if (id === 'loading_issues') {
                  toggleLoadingIssues();
                } else if (id === 'is_hidden') {
                  toggleHidden();
                } else if (id === 'no_downstream_lineage') {
                  toggleNoDownstreamLineage();
                } else if (id === 'no_upstream_lineage') {
                  toggleNoUpstreamLineage();
                } else if (id === 'no_source_tables') {
                  toggleNoSourceTables();
                }
              }}
            />
          )}
          {dataSourceType === 'glue' && (
            <AccordionDropDownItem
              accordionItems={{
                initialSelected: filterDefaults.cloud_object_format,
                options: glueSourceFileTypeFilters,
                placeholder: 'All',
              }}
              title="Source File Type"
              update={(type) => {
                updateFormatType(type);
              }}
            />
          )}
          {(isDataType || isProjectsDataType) && (
            <AccordionDropDownItem
              accordionItems={getMetadataTypeFilters(isProjectsDataType ? 'projects' : 'tables')}
              title="Data Type"
              update={(guid) => updateMetadataType(guid as MetadataObjectType)}
            />
          )}
          {dataTypeOptions && (
            <AccordionDropDownItem
              accordionItems={{
                initialSelected: dataTypeInitialSelected,
                options: dataTypeOptions,
                placeholder:
                  dataTypeOptions.find(({ value }) => value === dataTypeInitialSelected)?.text ??
                  'All',
              }}
              title="Data Type"
              update={(value, option) => {
                if (onSelectDataType) {
                  onSelectDataType?.(value, option);
                } else {
                  updateDataType(value);
                }
              }}
            />
          )}
          {isDashboardsDataType && (
            <AccordionDropDownItem
              accordionItems={{
                initialSelected: filterDefaults.data_type ?? null,
                options: lookerDashboardDataTypeOptions,
                placeholder: 'All',
              }}
              title="Data Type"
              update={(guid) => {
                updateDataType(guid as MetadataObjectType);
              }}
            />
          )}
          {isPowerBIDataType && (
            <AccordionDropDownItem
              accordionItems={{
                initialSelected: filterDefaults.data_type ?? null,
                options: dashboardsDataTypeOptions,
                placeholder: 'All',
              }}
              title="Data Type"
              update={(guid) => updateDataType(guid as MetadataObjectType)}
            />
          )}
          {(enableSchemaFilter || enableTableFilter) && (
            <SchemasOrTables
              enableSchemaFilter={enableSchemaFilter}
              enableTableFilter={enableTableFilter}
              update={(guid) => {
                // need to check the guid to figure out which function to call
                if (guid.startsWith('ta')) updateTable(guid);
                if (guid.startsWith('cl')) updateSchema(guid);
              }}
            />
          )}
          {isColumnDataType && (
            <AccordionItem
              accordionItems={getDataTypeFilters()}
              title="Column Type"
              update={(guid) => updateColumnDataType(guid)}
            />
          )}
          {isViewDataType && (
            <AccordionItem
              accordionItems={viewTypeFilters}
              title="View Type"
              update={(id) => updateTypes(id)}
            />
          )}
          {isSearchFilters && searchFacets && (
            <SearchFacetFilters
              key={JSON.stringify(searchFacets)}
              query={query}
              searchFacets={searchFacets}
              selectedValues={filterDefaults.include}
              update={(key: string) => updateSearchFilters(key)}
            />
          )}
          {isCreatedBy && <CreatedByUsers type={dataSourceType} update={updateCreatedBy} />}
          {isCollections && <CollectionsFilter update={updateBIFolders} />}
          {isCategoryTags && Boolean(categoryTags.length) && (
            <AccordionItem
              accordionItems={getTagFilters(categoryTags, filterDefaults.category_tags)}
              alignItemsStart
              title="Category Tags"
              update={(guid) => updateCategoryTags(guid)}
            />
          )}
          {isStatusTags && Boolean(statusTags.length) && (
            <AccordionItem
              accordionItems={getTagFilters(statusTags, filterDefaults.status_tags)}
              alignItemsStart
              title="Status Tags"
              update={(guid) => updateStatusTags(guid)}
            />
          )}
          {isDbtTags && Boolean(dbtTags.length) && (
            <AccordionItem
              accordionItems={getTagFilters(dbtTags, filterDefaults.dbt_tags)}
              alignItemsStart
              title="dbt Tags"
              update={(guid) => updateDbtTags(guid)}
            />
          )}
          {isOwners && <Owners update={updateOnwers} />}
          {isPopularity && (
            <AccordionItem
              accordionItems={getPopularityFilters(filterDefaults)}
              title="Popularity"
              update={(guid) => updatePopularity(guid)}
            />
          )}
        </Accordion>
      </FilterSidebarStyle>
    </RightSidebarContainer>
  );
};

export default FiltersSidebar;
