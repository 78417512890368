import styled from '@emotion/styled';

import Box from '@components/Box';

export const StyledSelectedSearchItemPill = styled(Box)`
  display: flex;
  width: 100%;
  height: 2.5rem;
  box-sizing: border-box;
  font-size: 1rem;
  justify-content: space-between;
  align-items: center;

  a {
    width: 100%;
  }

  > div {
    margin-bottom: 0 !important;
  }

  .multiple {
    font-style: italic;
  }
`;

StyledSelectedSearchItemPill.defaultProps = {
  backgroundColor: 'gray.100',
  border: 'solid 1px',
  borderColor: 'gray.300',
  borderRadius: 'default',
  px: 1.75,
  py: 0.5,
};

export const StyledSelectedSearchItemPillDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  .title {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.875rem;
  }
  .highlight {
    font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
    font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
    background-color: transparent;
    color: inherit;
    padding: 0 0 0.2rem 0;
  }

  i {
    height: 1rem;

    &.teams {
      display: flex;
      align-self: flex-start;
    }
  }
`;
