import { identifyWithExternalTools, setTokenResponseData } from '@api';
import paginatedTransform from '@api/paginatedTransform';
import rawTransform from '@api/rawTransform';
import { OrganizationSSOModel } from '@api/sso/OrganizationSSOModel';
import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import useMutation, { UseMutationOptions } from '@hooks/useMutation';
import { PaginatedResponse } from '@models/PaginatedResponse';

import { SlackTokenModel } from './SlackTokenModel';

export const ssoCacheKeys = {
  authUrl: ['sso', 'auth', 'url'],
  connection: ['sso', 'connection'],
  slack: ['sso', 'slack'],
  sso: ['sso'],
};

export const useFetchSSOAuthURL = (options?: UseFetchOptions<string>) => {
  /**
  * Encode email to base64 as requested by customer
  * Quite an important customer claim that it protects against 
  * eavesdropping, but our security team disagrees.
  * @see sc-86719
  */
  const params = options?.params ?? {};
  if (options?.params?.email) {
    params.email = `v2:${btoa(options.params.email)}`;
  }

  return useFetch<string>({
    ...options,
    params,
    queryKey: [...ssoCacheKeys.sso, options?.params],
    url: `/sso/auth/url/`,
  });
};

const fetchSSOConnectionSelect = paginatedTransform(OrganizationSSOModel);
export const useFetchSSOConnection = (
  options?: UseFetchOptions<PaginatedResponse<OrganizationSSOModel>>,
) => {
  return useFetch<PaginatedResponse<OrganizationSSOModel>>({
    ...options,
    queryKey: [...ssoCacheKeys.connection, options?.params],
    select: fetchSSOConnectionSelect,
    url: `/sso/connection/`,
  });
};

const fetchSSOSlackTokenSelect = rawTransform(SlackTokenModel);
export const useFetchSSOSlackToken = (options?: UseFetchOptions<SlackTokenModel>) => {
  return useFetch<SlackTokenModel>({
    ...options,
    queryKey: [...ssoCacheKeys.slack, options?.params],
    select: fetchSSOSlackTokenSelect,
    url: `/sso/slack${document.location.search}`,
  });
};

export const usePostSSOCallback = (options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    onSuccess: (data, variables, context) => {
      setTokenResponseData(data);
      identifyWithExternalTools(data);
      options?.onSuccess?.(data, variables, context);
    },
    url: `/sso/callback/`,
  });
};

export const usePostSSOSetup = (options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    url: `/sso/setup/`,
  });
};
