import pluralize from '@utils/pluralize';

export interface GetItemsCountParams {
  countItems?: Array<{ key: string; text: string }>;
  ref?: {};
  union?: string;
}

const getItemsCount = ({ countItems, ref, union = ', ' }: GetItemsCountParams) =>
  countItems
    ?.map(({ key, text }) => `${pluralize(ref?.[key as keyof typeof ref], text)}`)
    .join(union);

export default getItemsCount;
