import styled from '@emotion/styled';

import Box from '@components/Box';
import Text from '@components/Text';

export const StyledLabel = styled(Text)`
  color: ${({ theme }) => theme.colors.v1.gray[600]};
  font-size: ${({ theme }) => theme.typography.fontSizes.sm};
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
`;

export const StyledColumnWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space(0.5)};
`;

export const StyledRowWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space(0.5)};
`;
