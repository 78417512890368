/*
 * small function to sort dates
 * this should be added wherever we have dates as
 * react-table only includes datetime sorting.
 */

import { Row } from 'react-table';
/* eslint-disable  @typescript-eslint/no-unused-vars */
export const sortDates = (rowA: Row, rowB: Row, columnId: string, _desc: boolean | undefined) => {
  const nullableValues = [null, undefined];
  /* eslint-enable  @typescript-eslint/no-unused-vars */
  let [a, b] = [rowA.values[columnId], rowB.values[columnId]];

  if (nullableValues.includes(a) && nullableValues.includes(b)) return 0;
  if (nullableValues.includes(a)) return -1;
  if (nullableValues.includes(b)) return 1;

  if (a === 'unknown' && b === 'unknown') return 0;
  if (a === 'unknown') return -1;
  if (b === 'unknown') return 1;

  a = a.getTime();
  b = b.getTime();

  if (a === b) {
    return 0;
  }

  return a > b ? 1 : -1;
};
