import React from 'react';

import { useFetchCostAnalysisDashboards } from '@api/costAnalysis';

import type { CostAnalysisSharedFilterParams } from '../../CostAnalysisSharedFilter';
import ScatterChart from '../ScatterChart';

export interface DashboardByCostChartProps extends CostAnalysisSharedFilterParams {}

const DashboardByCostChart: React.FC<DashboardByCostChartProps> = ({ range, warehouses }) => {
  const { data, isLoading } = useFetchCostAnalysisDashboards({
    params: {
      end_date: range[1],
      order: '-total_credits_used',
      page: 1,
      page_size: 20,
      start_date: range[0],
      warehouses,
    },
  });

  return <ScatterChart data={data?.results} isLoading={isLoading} />;
};

export default DashboardByCostChart;
