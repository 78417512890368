import * as d3 from 'd3';

interface ZoomToCoordinatesArgs {
  svgRootRef: React.RefObject<SVGSVGElement>;

  transform: d3.ZoomBehavior<any, any>['transform'];

  x: number;

  y: number;
}

const zoomToCoordinates = ({ svgRootRef, transform, x, y }: ZoomToCoordinatesArgs) => {
  const svg = d3.select(svgRootRef.current);

  const destination = d3.zoomIdentity.translate(x, y);

  svg.transition().duration(500).call(transform, destination);
};

export default zoomToCoordinates;
