import React, { useCallback, useMemo } from 'react';
import { useRouteMatch } from '@routing/router';

import Box from '@components/Box';
import IconButton from '@components/IconButton';
import useLocalStorageTableFiltersConfig from '@components/Table/Table/useLocalStorageTableFiltersConfig';
import Icon from '@components/UI/Icon';
import Select from '@components/UI/Select';

import { SortByPanel } from './SidebarTree/SidebarTree.styles';
import SidebarTreeItem from './SidebarTreeItem';
import type { SidebarTreeColumn, SidebarTreeItemProps, TreeMenuItem } from './types';

const commonCellStyles = {
  alignItems: 'center',
  compDisplay: 'flex',
  px: 1,
};

interface SidebarTreeTableProps
  extends Pick<
    SidebarTreeItemProps,
    | 'direction'
    | 'disableExpandAll'
    | 'isExpandingAll'
    | 'searchKeyword'
    | 'showExpandAll'
    | 'type'
    | 'enableHighlight'
    | 'expandAllState'
    | 'isLoading'
    | 'loadLineage'
    | 'onExpandAllClick'
    | 'onItemClick'
    | 'onSqlButtonClick'
    | 'shouldShowImpactScore'
    | 'treeColumnsConfig'
  > {
  items: TreeMenuItem[];
  treeColumns: SidebarTreeColumn[];
}

const SidebarTreeTable: React.FC<SidebarTreeTableProps> = ({
  direction,
  disableExpandAll,
  enableHighlight,
  expandAllState,
  isExpandingAll,
  isLoading,
  items,
  loadLineage,
  onExpandAllClick,
  onItemClick,
  onSqlButtonClick,
  searchKeyword,
  shouldShowImpactScore,
  showExpandAll,
  treeColumns,
  type,
}) => {
  const { path } = useRouteMatch();
  const defaultColumnFilters = treeColumns.reduce(
    (acc, column) => ({ ...acc, [column.id]: column.visibleByDefault }),
    {},
  );
  const { handleStorageTableFilterUpdate, storageTableColumnsFilters } =
    useLocalStorageTableFiltersConfig({
      cacheKey: path,
      defaultFilters: defaultColumnFilters,
      storageKey: 'lineageTreeColumnFilters',
    });

  const covertToFilterOptions = useCallback(
    (columns: SidebarTreeColumn[]) => {
      return columns.map((column) => ({
        onClick: () => {
          handleStorageTableFilterUpdate(column.id, !storageTableColumnsFilters[column.id]);
        },
        text: column.name,
        value: column.id,
      }));
    },
    [handleStorageTableFilterUpdate, storageTableColumnsFilters],
  );

  const columnFilters = covertToFilterOptions(
    treeColumns.filter((column) => storageTableColumnsFilters[column.id]),
  );

  const filterColumn = useMemo(
    () => ({
      Cell: null,
      Header: (
        <Select
          isMulti
          options={covertToFilterOptions(treeColumns.filter((column) => column.canHide))}
          optionsFitAnchorWidth={false}
          renderCustomAnchor={({ anchorProps }) => (
            <IconButton
              {...anchorProps}
              aria-label="Lineage Tree Filter"
              hoverBackgroundColor="transparent"
            >
              <Icon name="filter" size="16px" />
            </IconButton>
          )}
          showSelectAllButton={false}
          value={columnFilters}
        />
      ),
      canHide: false,
      id: 'filter',
      name: 'Filter',
      styles: {
        alignItems: 'center',
        compDisplay: 'flex',
        compWidth: '30px',
        maxWidth: '30px',
        minWidth: '30px',
      },
      visibleByDefault: true,
    }),
    [columnFilters, covertToFilterOptions, treeColumns],
  );

  const finalColumns = useMemo(() => {
    const columns = treeColumns.filter((column) => {
      if (column.canHide) {
        return columnFilters.some((filter) => filter.value === column.id);
      }

      return true;
    });

    columns.push(filterColumn);

    return columns;
  }, [columnFilters, filterColumn, treeColumns]);

  return (
    <>
      <SortByPanel px={1}>
        {finalColumns.map((column) => (
          <Box {...commonCellStyles} {...column.styles} key={column.id} py={1}>
            {column.Header}
          </Box>
        ))}
      </SortByPanel>
      <Box as="ul" px={1}>
        {items?.map((item) => {
          const treeItemProps = {
            ...item,
            direction,
            disableExpandAll,
            enableHighlight,
            expandAllState,
            isExpandingAll,
            isLoading,
            loadLineage,
            onClick: () => onItemClick?.(item),
            onExpandAllClick,
            onSqlButtonClick,
            searchKeyword,
            shouldShowImpactScore,
            showExpandAll,
            type,
          };

          return (
            <SidebarTreeItem
              commonCellStyles={{ ...commonCellStyles, minHeight: '26px' }}
              treeColumns={finalColumns}
              {...treeItemProps}
              key={item.key}
            />
          );
        })}
      </Box>
    </>
  );
};

export default SidebarTreeTable;
