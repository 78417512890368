import React from 'react';
import type { DataTypes } from '@configs/dataTypes/types';

import { useFetchBiColumns } from '@api/biColumnsV1';
import { ColumnModel } from '@api/columns/ColumnModel';
import BulkButtons from '@components/BulkButtons';
import TabError from '@components/TabContent/TabError';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import useBulkEditSelected from '@hooks/useBulkEditSelected';
import type { FilterOptions } from '@utils/filters';
import { setParams, useUpdateFilters } from '@utils/filters';

import type { BiColumnsTableProps } from '../BiColumnsTab/BiColumnsTable';
import BiColumnsTable, {
  BI_COLUMNS_TABLE_SEARCH_CONFIG,
  BI_COLUMNS_TABLE_SORT_CONFIG,
} from '../BiColumnsTab/BiColumnsTable';

const REQUEST_CONFIG: FilterOptions = {
  order: 'name',
  page: 1,
  page_size: 50,
};

export interface BiColumnsTabProps
  extends Pick<DataTypes, 'dataSourceType'>,
    Pick<BiColumnsTableProps, 'visibleColumns'> {
  biTableGuid?: string;
  datasetGuid?: string;
  isDataSourceEditable: boolean;
}

const BiColumnsTab: React.FC<BiColumnsTabProps> = ({
  biTableGuid,
  dataSourceType,
  datasetGuid,
  isDataSourceEditable,
  visibleColumns,
}) => {
  const guid = biTableGuid ?? datasetGuid;
  const { isEditable: isObjectEditable } = useObjectPermissionsContext({ id: guid });
  const filterService = useUpdateFilters(
    REQUEST_CONFIG,
    BI_COLUMNS_TABLE_SEARCH_CONFIG,
    BI_COLUMNS_TABLE_SORT_CONFIG,
    REQUEST_CONFIG.order,
  );
  const { data, isError, isLoading } = useFetchBiColumns({
    enabled: Boolean(dataSourceType),
    params: {
      ...setParams(filterService.filter),
      ...(biTableGuid && { bi_tables: biTableGuid }),
      ...(datasetGuid && { datasets: datasetGuid }),
    },
  });

  const { selected } = useBulkEditSelected<ColumnModel>({
    defaultSelected: data?.results,
  });

  if (isError) return <TabError />;

  return (
    <>
      <BulkButtons
        canUseBulkAssist={isObjectEditable}
        guid={guid}
        isDataSourceEditable={isDataSourceEditable}
        selectedEditableItems={selected.items}
        selectedItems={selected.items}
      />
      <BiColumnsTable
        data={data}
        filterService={filterService}
        isDataSourceEditable={isDataSourceEditable}
        isLoading={isLoading}
        visibleColumns={visibleColumns}
      />
    </>
  );
};

export default React.memo<BiColumnsTabProps>(BiColumnsTab);
