import React, { useState } from 'react';
import { Cell } from 'react-table';

import { DimensionItemType } from '@api/metrics/DimensionItemModel';
import ExcerptText from '@components/ExcerptText';
import SuggestedDescriptionSourceObject from '@components/SuggestedDescriptionSourceObject';
import PopularityCell from '@components/Table/Cells/PopularityCell';
import PopularityCellHeader from '@components/Table/Cells/PopularityCell/PopularityCellHeader';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import FieldNameTableCell from '@components/Table/ExploreFieldTable/FieldNameTableCell';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import { getPopularityNormalized } from '@utils/popularity';

import { StyledDimensionsTable } from './DimensionsTable.styles';

interface DimensionsTableProps {
  data?: DimensionItemType[];
  itemCount?: number;
}

const DimensionsTable: React.FC<DimensionsTableProps> = ({ data, itemCount = 0 }) => {
  const [isShowFilter, setShowFilter] = useState(false);

  const columns = React.useMemo(() => {
    const cols: ColumnConfig<DimensionItemType>[] = [
      {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      {
        Cell: (props: Cell<DimensionItemType>) => {
          const dimension = props.row.original;
          return (
            <FieldNameTableCell
              {...props}
              field={dimension}
              guid={dimension.guid}
              isMentioned={false}
              isMetric={false}
            />
          );
        },
        Header: `Dimension (${itemCount})`,
        accessor: (d) => d.name,
        disableHiding: true,
        id: 'name',
        width: '140%',
      },
      {
        Cell: (props: Cell<DimensionItemType>) => {
          const { row } = props;
          const item = row.original;
          const isSuggestion = !item.description && !!item.suggestedDescription;

          return (
            <>
              <ExcerptText
                {...props}
                isSuggestion={isSuggestion}
                value={item.suggestedDescription || item.description || null}
              />
              {isSuggestion && (
                <SuggestedDescriptionSourceObject
                  sourceObj={item?.suggestedDescriptionSourceObject?.obj}
                />
              )}
            </>
          );
        },
        Header: 'Description',
        accessor: (d) => d?.description,
        id: 'description',
        width: '160%',
      },
      {
        Cell: (props: Cell<DimensionItemType>) => {
          const dimension = props.row.original;
          return <PopularityCell {...props} popularity={dimension.popularity} />;
        },
        Header: PopularityCellHeader,
        accessor: (d) => getPopularityNormalized(d?.popularity?.popularity),
        disableFilters: true,
        disableResizing: true,
        dropdownCheckboxLabel: 'Popularity',
        id: 'popularity',
        sortDescFirst: true,
        width: 120,
      },
    ];

    return cols;
  }, [itemCount]);

  const getRowId = React.useCallback((row: Partial<DimensionItemType>) => row.guid!, []);

  return (
    <StyledDimensionsTable>
      <Table
        basic="very"
        className="table-full"
        columns={columns}
        compact
        data={data || []}
        disableColumnFiltering
        disablePagination
        disableRowSelect
        getRowId={getRowId}
        initialState={{
          sortBy: [
            {
              desc: true,
              id: 'popularity',
            },
          ],
        }}
        loading={data === undefined}
        selectable
        showFilter={isShowFilter}
        singleLine
        sortable
        toggleFilter={() => setShowFilter((prev) => !prev)}
        unstackable
      />
    </StyledDimensionsTable>
  );
};

export default DimensionsTable;
