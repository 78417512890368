import { Expose } from 'class-transformer';

export class Repository {
  @Expose({ name: 'data_source' })
  dataSource!: string;

  guid!: string;

  @Expose({ name: 'repository_url' })
  repositoryUrl!: string;

  @Expose({ name: 'key' })
  publicKey!: string;
}
