import React from 'react';

import { TagModel } from '@api/tags/TagModel';
import AppTag from '@components/AppTag';
import Box from '@components/Box';
import Text from '@components/Text';

interface TagGroupProps {
  tag?: TagModel;
  totalTags?: number;
}

const TagGroup: React.FC<TagGroupProps> = ({ tag, totalTags = 0 }) => {
  return (
    <Box alignItems="center" compDisplay="flex" compHeight="29px">
      {tag && (
        <>
          <AppTag tag={tag} variant="outlined" />
          {totalTags > 1 && (
            <Text
              as="span"
              color="gray.400"
              fontSize="body2"
              fontWeight="bold"
              lineHeight="1"
              pl={1}
            >
              +{totalTags - 1}
            </Text>
          )}
        </>
      )}
    </Box>
  );
};

export default TagGroup;
