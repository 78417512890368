import React from 'react';

import Alert from '@components/Alert';
import PreviewMarkdown from '@components/PreviewMarkdown';
import type { ApiError } from '@models/ApiError';
import type { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';

interface ErrorDescriptionMarkdownProps {
  error?: EnhancedErrorResult | ApiError | null | undefined;
}

const ErrorDescriptionMarkdown: React.FC<ErrorDescriptionMarkdownProps> = ({ error }) => {
  if (!error?.data.detail) return null;

  return (
    <Alert type="error">
      <PreviewMarkdown fontSize="body1" markdown={error?.data.detail} minHeight="80px" />
    </Alert>
  );
};

export default ErrorDescriptionMarkdown;
