export const GUTTER = 16;
export const SPACE = 16 / 2;

export interface Grid {
  gutter: number;
  space: number;
  system: number;
}

const grid: Grid = {
  gutter: GUTTER,
  space: SPACE,
  system: 12,
};

export default grid;
