import React from 'react';

import { useFetchDsUsers } from '@api/dsusers';
import { DsUserModel } from '@api/dsusers/DsUserModel';
import TabError from '@components/TabContent/TabError';
import DSUsersTable from '@components/Table/DSUsersTable/DSUsersTable';
import { TabContentProps } from '@components/Tabs/types';
import { Filter } from '@utils';
import stripSpaces from '@utils/stripSpaces';

import { StyledDSUsersTab } from './DSUsers.styles';

const searchConfig: { [key: string]: keyof Filter.FilterOptions } = {
  name: 'search_name',
  user: 'search_user',
};

const sortConfig: { [key: string]: string } = {
  name: 'name',
  serviceAccount: 'is_service_account',
  user: 'user',
};

const query = stripSpaces(`{
  guid,
  data_source {
    guid,
    name,
    type
  },
  user,
  name,
  display_name,
  email,
  is_service_account,
  is_active
}`);

interface DSUsersTabProps extends TabContentProps {
  dsGuid: string;
}

const DSUsersTab: React.FC<DSUsersTabProps> = ({ dsGuid, stickyHeader }) => {
  const FilterService = Filter.useUpdateFilters(
    {
      datasources: [dsGuid],
      order: 'is_service_account',
      page: 1,
      page_size: 100,
      query,
      sortColumn: 'is_service_account',
      sortDirection: 'ascending',
    },
    searchConfig,
    sortConfig,
    'is_service_account',
  );
  const { filter } = FilterService;

  const effectiveConfig = React.useMemo(() => {
    return { params: Filter.setParams(filter) };
  }, [filter]);

  const { data, isError, isLoading } = useFetchDsUsers({
    ...effectiveConfig,
  });

  const totalPages = data && filter.page_size ? Math.ceil(data.count / filter.page_size) : 1;
  const dsusers: DsUserModel[] | undefined = data?.results;
  const itemsCount: number = data ? data.count : 0;

  if (isError) return <TabError />;

  return (
    <StyledDSUsersTab>
      <DSUsersTable
        data={dsusers}
        filterService={FilterService}
        itemsCount={itemsCount}
        loading={isLoading}
        stickyHeader={stickyHeader}
        totalPages={totalPages}
      />
    </StyledDSUsersTab>
  );
};

export default React.memo(DSUsersTab);
