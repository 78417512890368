import React from 'react';

import Avatar from '@components/UI/Avatar';
import Icon from '@components/UI/Icon';

import type { Option as OptionType } from '../types';

import { StyledOption, StyledOptionContent } from './Option.styles';

interface OptionProps
  extends Pick<
    OptionType,
    | 'avatar'
    | 'breakText'
    | 'customLabel'
    | 'fontColor'
    | 'fontWeight'
    | 'icon'
    | 'iconColor'
    | 'text'
    | 'wrapOptionText'
  > {}

const Option = ({
  avatar,
  breakText,
  customLabel = null,
  fontColor = 'gray.700',
  fontWeight,
  icon,
  iconColor = 'gray.500',
  text,
  wrapOptionText,
}: OptionProps) => {
  const getDecoration = () => {
    let decoration = null;
    if (icon) {
      decoration = <Icon color={iconColor} name={icon} size="16px" />;
    } else if (avatar) {
      decoration = <Avatar {...avatar} size="16px" />;
    }
    return decoration;
  };

  return (
    <StyledOption>
      {getDecoration()}
      <StyledOptionContent
        breakText={breakText}
        color={fontColor}
        fontWeight={fontWeight}
        wrapOptionText={wrapOptionText}
      >
        {customLabel ?? text}
      </StyledOptionContent>
    </StyledOption>
  );
};

export default Option;
