import React, { useRef } from 'react';

import { useFetchDbtWarehouseLink } from '@api/dbt';
import Box from '@components/Box';
import DbtTable, {
  DBT_TABLE_SEARCH_CONFIG,
  DBT_TABLE_SORT_CONFIG,
} from '@components/Table/DbtTable';
import { StyledTextProps } from '@components/Text/Text';
import Tooltip from '@components/Tooltip';
import Card from '@components/UI/Card';
import Icon from '@components/UI/Icon';
import theme from '@styles/theme';
import { setParams, useUpdateFilters } from '@utils/filters';

interface DbtSectionProps {
  guid: string;
  titleFontSize?: StyledTextProps['fontSize'];
}

const DbtSection: React.FC<DbtSectionProps> = ({ guid, titleFontSize = 'h4' }) => {
  const visibility = useRef<'unset' | 'hidden' | 'visible'>('unset');
  const filterService = useUpdateFilters(
    { order: DBT_TABLE_SORT_CONFIG?.name, page: 1, page_size: 100, types: ['source'] },
    DBT_TABLE_SEARCH_CONFIG,
    DBT_TABLE_SORT_CONFIG,
    DBT_TABLE_SORT_CONFIG?.name,
  );

  const { data, isLoading } = useFetchDbtWarehouseLink(guid, {
    keepPreviousData: true,
    params: setParams(filterService.filter),
  });

  if (visibility.current === 'unset' && data) {
    visibility.current = data?.count >= 1 ? 'visible' : 'hidden';
  }

  if (visibility.current !== 'visible') return null;

  return (
    <Card px={3.5} py={2.5}>
      <Box
        alignItems="center"
        as="h3"
        color="gray.700"
        compDisplay="flex"
        fontFamily="primary"
        fontSize={titleFontSize}
        fontWeight="medium"
        gap={0.5}
        mb={1}
      >
        Linked dbt Sources
        <Tooltip content="dbt projects where this table has been imported as a source.">
          <Icon color={theme.colors.gray[500]} name="info-outline" size="16px" />
        </Tooltip>
      </Box>
      <DbtTable data={data} filterService={filterService} isLoading={isLoading} />
    </Card>
  );
};

export default DbtSection;
