import { XYPosition } from 'reactflow';

import { SIZES } from '../../../components/Nodes/config';
import { ExploreNode } from '../../../LineageExplore.types';
import { EXPLORE_NODE_PAGE_SIZE } from '../../../useShowMoreNodes/useShowMore.constants';
import createShowMore from '../../utils/createShowMore';
import {
  calcGroupX,
  calculateGroupWidth,
  columnX,
  initialGroupHeight,
  showMoreTotalHeight,
  sortNodeKeysByPopularity,
} from '../algorithm.utils';
import { EdgesById } from '../types';

import parseNode from './parseNode';
import { NodeParser } from './types';

const databaseNodeParser: NodeParser = ({
  edgesById,
  initialPosition = { x: 0, y: 0 } as XYPosition,
  isColumnLevelLineage = false,
  nodesById,
  rawNode,
  shouldHideFilterLineage,
}) => {
  const groupWidth = calculateGroupWidth(
    rawNode,
    rawNode.stackedAt as number,
    isColumnLevelLineage,
  );
  const groupStartStackId = Number(rawNode.startStackId);
  const style = {
    height: initialGroupHeight,
    width: groupWidth,
  };
  const databaseNode: ExploreNode = {
    ...rawNode,
    data: {
      ...rawNode?.data,
      hiddenChildren: new Set([]),
      hiddenChildrenCount: 0,
      noMatchedChildren: false,
      style,
    },
    position: {
      x: initialPosition.x + calcGroupX(groupStartStackId, isColumnLevelLineage),
      y: initialPosition.y,
    },
    style,
  };

  const databaseChildrenNodes: ExploreNode[] = [];
  let extraEdgesById: EdgesById = {};

  if (databaseNode?.data?.isOpen) {
    databaseNode.style!.height = SIZES.openedContentHeight.database + SIZES.paddingBottom.database;
    const databaseChildren = sortNodeKeysByPopularity(
      Array.from(databaseNode.data.children ?? []),
      nodesById,
    ).map((childId) => nodesById[childId]);

    const currentInitialPosition = { x: 0, y: SIZES.openedContentHeight.database };
    const shownChildrenCount = databaseNode.data.shownChildrenCount ?? EXPLORE_NODE_PAGE_SIZE;

    databaseChildren.forEach((childNode, index) => {
      if (index < shownChildrenCount) {
        const {
          children = [],
          extraEdgesById: childExtraEdgesById,
          node,
          nodeHeight,
        } = parseNode({
          edgesById,
          initialPosition: currentInitialPosition,
          isColumnLevelLineage,
          nodeIndex: index,
          nodesById,
          rawNode: childNode,
          shouldHideFilterLineage,
        });

        databaseChildrenNodes.push(node, ...children);
        extraEdgesById = { ...extraEdgesById, ...childExtraEdgesById };
        currentInitialPosition.y += nodeHeight;
        databaseNode.style!.height = Number(databaseNode.style!.height) + nodeHeight;

        if (index < databaseChildren.length - 1 && index < shownChildrenCount - 1) {
          databaseNode.style!.height = Number(databaseNode.style!.height) + SIZES.rowGap.schema;
          currentInitialPosition.y += SIZES.rowGap.schema;
        }
      } else {
        databaseNode.data.hiddenChildrenCount = (databaseNode.data.hiddenChildrenCount ?? 0) + 1;
        databaseNode.data.hiddenChildren?.add(String(childNode.key));
      }
    });
  }

  if (databaseNode.data.hiddenChildrenCount! >= 1) {
    const showMore = createShowMore({
      data: databaseNode.data,
      edgesById,
      hiddenChildren: databaseNode.data.hiddenChildren,
      hiddenChildrenCount: databaseNode.data.hiddenChildrenCount,
      nodesById,
      parent: String(databaseNode.key),
      position: {
        x: columnX,
        y: Number(databaseNode.style!.height),
      },
      width: SIZES.width.schema,
    });

    databaseNode.style!.height = Number(databaseNode.style!.height) + showMoreTotalHeight;
    databaseChildrenNodes.push(showMore.node);
    extraEdgesById = { ...extraEdgesById, ...showMore.edges };
  }

  /*
   * We need to update the height and width of the node outside the style object
   * since react-flow uses these values to calculate the nodes overlaping and
   * remove the ones not visible on screen
   */
  databaseNode.height = Number(databaseNode.style?.height);
  databaseNode.width = Number(databaseNode.style?.width);

  return {
    children: databaseChildrenNodes,
    extraEdgesById,
    node: databaseNode,
    nodeHeight: Number(databaseNode?.style?.height),
  };
};

export default databaseNodeParser;
