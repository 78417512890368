import React from 'react';

import TabsRouter from '@components/TabsRouter';
import { TabsRouterProps } from '@components/TabsRouter/TabsRouter';

interface MetadataTabsRouterProps extends TabsRouterProps {}

const MetadataTabsRouter: React.FC<MetadataTabsRouterProps> = ({ config, ...rest }) => {
  const addChromeExtensionTabsBehavior = () => {
    if (process.env.REACT_APP_CHROME_EXTENSION_BUILD) {
      const newTabsConfig = config.map((tab) => {
        const newTabConfig = { ...tab };
        if (tab.route === '/overview') {
          newTabConfig.default = true;
        } else if (tab.default) {
          newTabConfig.default = false;
        }
        return newTabConfig;
      });

      return newTabsConfig;
    }

    return config;
  };

  return <TabsRouter config={addChromeExtensionTabsBehavior()} sticky {...rest} />;
};

export default MetadataTabsRouter;
