import { useCallback, useMemo, useState } from 'react';

export interface Step<T> {
  hidden?: boolean;
  key: T;
  name: string;
  title?: string;
}
export interface UseStepProps<T = Step<string>[]> {
  startIndex?: number;
  steps: T[];
}

const useStep = <T,>({ startIndex = 0, steps }: UseStepProps<T>) => {
  const [step, setStep] = useState(steps[startIndex]);

  const idx = useMemo(() => steps.indexOf(step), [steps, step]);
  const hasNext = Boolean(idx + 1 < steps.length);
  const hasPrev = Boolean(idx - 1 >= 0);

  const nextStep = useCallback(() => {
    if (hasNext) {
      setStep(steps[idx + 1]);
    }
  }, [hasNext, idx, steps]);

  const prevStep = useCallback(() => {
    if (hasPrev) {
      setStep(steps[idx - 1]);
    }
  }, [hasPrev, idx, steps]);

  return { currStep: step, hasNext, hasPrev, nextStep, prevStep };
};

export default useStep;
