import { Descendant, Element, Text } from 'slate';

import { CustomElement, FormattedText } from '../../RichTextEditor.types';

/**
 * Returns a text node and a boolean indicating if the node text was truncated.
 */
const truncateTextNode = (
  node: FormattedText,
  charCount: number,
  maxLength: number,
): [FormattedText, boolean] => {
  if (charCount > maxLength) {
    const charsToRemove = maxLength - charCount;
    const newNode = { ...node, text: `${node.text.slice(0, charsToRemove)}...` };
    return [newNode, true];
  }
  return [node, false];
};

const truncateMention = (
  mentionNode: CustomElement,
  maxLength: number,
  charCount: number,
): [CustomElement, number] => {
  const { mention } = mentionNode;
  const newCharCount = (mention?.name?.length ?? 0) + charCount;
  let newNode = mentionNode;
  if (newCharCount > maxLength) {
    const charsToRemove = maxLength - newCharCount;
    const newMention = { ...mention, name: `${mention.name.slice(0, charsToRemove)}...` };
    newNode = { ...mentionNode, mention: newMention };
  }
  return [newNode, newCharCount];
};

const truncateSlateRecursiveHelper = (
  state: Descendant[],
  maxLength: number,
  currCount: number,
): [Descendant[], number] => {
  if (state.length === 0 || currCount >= maxLength) {
    return [[], currCount];
  }

  let charCount = currCount;
  const newState: Descendant[] = [];
  for (let i = 0; i < state.length; i += 1) {
    const node = state[i];
    if (Text.isText(node)) {
      charCount += node.text.length;
      const [newNode, isTruncated] = truncateTextNode(node, charCount, maxLength);
      newState.push(newNode);
      if (isTruncated) {
        break;
      }
    } else if (Element.isElement(node)) {
      if (node.type === 'mention') {
        const [newMentionNode, newCharCount] = truncateMention(node, maxLength, charCount);
        newState.push(newMentionNode);
        if (newCharCount > maxLength) {
          break;
        }
      } else {
        const [returnedState, newCount] = truncateSlateRecursiveHelper(
          node.children,
          maxLength,
          charCount,
        );
        if (returnedState.length === 0) {
          break;
        }
        charCount = newCount;
        const newNode = {
          ...node,
          children: returnedState,
        };
        newState.push(newNode);
      }
    }
  }
  return [newState, charCount];
};

/**
 * Recursively traverses slate state tree to find the text(leaf) nodes and truncates state/nodes based on if
 * the character count has exceeded maxLength. Maintains styling if truncation occurs inside a styled node.
 */
const truncateSlate = (slateState: Descendant[], maxLength?: number) => {
  if (!maxLength) {
    return slateState;
  }
  const [newState] = truncateSlateRecursiveHelper(slateState, maxLength, 0);
  return newState;
};

export default truncateSlate;
