import styled from '@emotion/styled';

const CompleteInfoText = styled.div`
  font-size: 1rem;
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  line-height: 1.5;
  margin-bottom: 1rem;
`;

export default CompleteInfoText;
