import React from 'react';

import { tagColorMap } from '@components/AppTag/helpers';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import CircularLoader from '@components/CircularLoader';
import { Column, Row } from '@components/Grid';
import LayoutMainGrid from '@components/Layout/LayoutMainGrid';
import Text from '@components/Text';
import Icon from '@components/UI/Icon';
import MonteCarloConfigurationWizard from '@components/Wizard/MonteCarloConfigurationWizard';
import { useModal } from '@context/Modal';
import { useUserContext } from '@context/User';
import { ConnectableApps, useConnectableApps } from '@hooks/useConnectableApps';
import AdminAppCard from '@pages/Admin/AdminAppsPage/AdminAppCard';
import { appsBaseConfiguration } from '@pages/Admin/AdminAppsPage/AdminAppsPage';
import { SectionTitleWrapper, Title } from '@pages/Admin/AdminCostAnalysisPage/tabs/SectionTitle';
import AdminTitleView from '@pages/Admin/AdminTitleView/AdminTitleView';
import theme from '@styles/theme';
import MetadataDecorator from '@utils/MetadataDecorator';

import DisconnectMonteCarloModal from './DisconnectMonteCarloModal';

const monteCarloConfig = appsBaseConfiguration[ConnectableApps.monteCarlo];

const AdminMonteCarloPage: React.FC = () => {
  const { isLoading } = useConnectableApps();
  const { dataSources } = useUserContext();
  const { MODAL_IDS, checkModalOpened, closeModal, openModal } = useModal();

  const monteCarloDataSource = Object.values(dataSources ?? {}).find(
    (ds) => ds.type === 'monte_carlo',
  );

  const onOpenWizard = () => {
    openModal(MODAL_IDS.connectMonteCarlo);
  };

  return (
    <>
      <MetadataDecorator title="Monte Carlo Integration" />
      <LayoutMainGrid>
        <AdminTitleView as="h1" compSize="lg" mb={3} title="Monte Carlo Integration" />
        <Box alignItems="start" position="relative">
          {isLoading ? (
            <CircularLoader centered />
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {monteCarloDataSource ? (
                <>
                  <Box alignItems="center" compDisplay="flex" gap={1.5} mb={5.5}>
                    <SectionTitleWrapper>
                      <Title fontWeight={600}>Status</Title>
                    </SectionTitleWrapper>
                    <Icon color={tagColorMap.endorsed} name="endorsed" size="20px" />
                    <Button
                      onClick={() => openModal(MODAL_IDS.connectMonteCarlo)}
                      variant="outlined"
                    >
                      Edit
                    </Button>
                  </Box>
                  <Box mb={5.5}>
                    <Text
                      color={theme.colors.error}
                      cursor="pointer"
                      fontSize="body2"
                      fontWeight="medium"
                      onClick={() => openModal(MODAL_IDS.disconnectMontecarlo)}
                      role="button"
                    >
                      Remove Integration
                    </Text>
                  </Box>
                </>
              ) : (
                <Row flexWrap="wrap">
                  <Column flexShrink={0} lg={4} md={6} sm={12}>
                    <AdminAppCard
                      connected={false}
                      description={monteCarloConfig.description}
                      icon={monteCarloConfig.icon}
                      name={monteCarloConfig.name}
                      onClick={onOpenWizard}
                      onConnect={onOpenWizard}
                    />
                  </Column>
                </Row>
              )}
            </>
          )}
        </Box>
      </LayoutMainGrid>
      {checkModalOpened(MODAL_IDS.disconnectMontecarlo) && (
        <DisconnectMonteCarloModal
          guid={monteCarloDataSource?.guid}
          onClose={() => closeModal(MODAL_IDS.disconnectMontecarlo)}
          onSuccess={() => closeModal(MODAL_IDS.disconnectMontecarlo)}
        />
      )}
      {checkModalOpened(MODAL_IDS.connectMonteCarlo) && (
        <MonteCarloConfigurationWizard
          guid={monteCarloDataSource?.guid}
          onClose={() => closeModal(MODAL_IDS.connectMonteCarlo)}
        />
      )}
    </>
  );
};

export default AdminMonteCarloPage;
