import { FullStory } from '@fullstory/browser';
import * as Sentry from '@sentry/react';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import getDebug from 'debug';
import moment from 'moment';
import Url from 'url-parse';

import { OrganizationModel } from '@api/organizations/OrganizationModel';
import { clearChromeAuthData } from '@pages/LoginPage/ExtensionLoginPage.utils';
import { isMasked } from '@pages/MaskPage/MaskPage.utils';
import { unmask } from '@pages/UnmaskPage/UnmaskPage.utils';

import { DeauthorizedCallback } from './api.utils';
import {
  CustomAxiosMetadataConfig,
  CustomAxiosRequestConfig,
  RefreshTokenAdapter,
  refreshTokenInterceptor,
} from './refreshToken';

const debug = getDebug('selectstar:api');

export const availableApiUrls = window?.env?.REACT_APP_SS_API_URL?.split(',') || [];

export const api = axios.create({
  baseURL: window.localStorage.apiUrl ?? availableApiUrls[0],
});

export const setApiUrl = (newUrl: string) => {
  /*
   * When a value is written to localStorage key, a storage event gets fired
   * in all tabs (that are open on the same domain) except the one that did
   * the writing.
   */
  localStorage.setItem('apiUrl', newUrl);
  api.defaults.baseURL = newUrl;
};

window.addEventListener('storage', function (e) {
  if (e.key === 'apiUrl' && e.newValue) {
    api.defaults.baseURL = e.newValue;
  }
});

const userLocalStorageItems: string[] = [
  'Access-Token',
  'Access-Token-Time',
  'Refresh-Token',
  'defaultOrganization',
  'organizations',
  'isStaff',
  'isSuperUser',
  'userEmail',
  'isMasked',
];

export const cleanLocalStorage = (masked: boolean = false) => {
  userLocalStorageItems.forEach((item) =>
    localStorage.removeItem(`${masked ? 'Masker-' : ''}${item}`),
  );
};
const deauthorizedCallbacks: DeauthorizedCallback[] = [];

const appRefreshTokenAdapter: RefreshTokenAdapter = {
  clearAuthData: () => {
    cleanLocalStorage();

    if (process.env.REACT_APP_CHROME_EXTENSION_BUILD) {
      clearChromeAuthData();
    }
  },
  setAuthData: (authData) => {
    localStorage.setItem('Access-Token', authData.accessToken);
    localStorage.setItem('Access-Token-Time', authData.accessTokenTime);

    if (process.env.REACT_APP_CHROME_EXTENSION_BUILD && chrome.storage) {
      chrome.storage.sync.set({
        'Select-Star-Access-Token': authData.accessToken,
        'Select-Star-Access-Token-Time': authData.accessTokenTime,
      });
    }
  },
};

api.interceptors.request.use(
  async (config) => {
    const metadata = { startTime: new Date() };
    const accessToken = localStorage.getItem('Access-Token');
    const refreshToken = localStorage.getItem('Refresh-Token');
    return refreshTokenInterceptor({
      accessToken,
      accessTokenTimeDateString: localStorage.getItem('Access-Token-Time'),
      api,
      config: { ...config, metadata },
      deauthorizedCallbacks,
      refreshToken,
      storage: appRefreshTokenAdapter,
    });
  },
  (err) => {
    if (err instanceof Error) {
      Sentry.captureException(err);
    } else {
      Sentry.captureException(Error(err.message), { extra: { ...err } });
    }

    return Promise.reject(err);
  },
);

export interface CustomAxiosConfig extends AxiosRequestConfig, CustomAxiosMetadataConfig {
  customTransform?: (data: any) => any;
}

interface CustomAxiosResponse extends AxiosResponse<any> {
  config: CustomAxiosRequestConfig;
}

api.interceptors.response.use(
  (response: CustomAxiosResponse) => {
    const endTime = new Date();

    const baseResponse = {
      ...response,
      config: {
        ...response.config,
        metadata: {
          ...response?.config?.metadata,
          duration: endTime.getTime() - (response?.config?.metadata?.startTime as Date).getTime(),
          endTime,
        },
      },
    };

    const { config }: { config: CustomAxiosConfig } = baseResponse;
    if (config && config.customTransform) {
      const myResponse: CustomAxiosResponse = { ...baseResponse };
      myResponse.data = config.customTransform(baseResponse.data);
      return myResponse;
    }
    return baseResponse;
  },
  (err) => {
    if (err instanceof Error) {
      Sentry.captureException(err);
    } else {
      Sentry.captureException(Error(err.message), { extra: { ...err } });
    }

    if (axios.isCancel(err)) {
      debug('debug', `request canceled: ${err.message}`);
      return Promise.reject(err);
    }

    if (!err.request) {
      return Promise.reject(err);
    }

    const url = Url(err.request.responseURL);

    if (err.response && err.response.status === 403) {
      debug(err.response.data);

      if (
        err.response.data?.detail === 'free_trial_expired' &&
        window.location.pathname !== '/free-trial-expired' &&
        !window.location.pathname.includes('/admin/billing')
      ) {
        window.location.href = '/free-trial-expired';
        return Promise.reject(err);
      }

      if (
        err.response.data?.detail === 'subscription_expired' &&
        window.location.pathname !== '/subscription-expired' &&
        !window.location.pathname.includes('/admin/billing')
      ) {
        window.location.href = '/subscription-expired';
        return Promise.reject(err);
      }

      return Promise.reject(err);
    }

    if (err?.response?.data?.code === 'user_not_found' && url.pathname !== '/v1/token/refresh/') {
      client.logout();
      window.location.href = '/logout';
      return Promise.reject(err);
    }
    return Promise.reject(err);
  },
);

export interface TokenResponseType {
  access: string;
  default_organization?: string;
  email: string;
  first_name: string;
  is_staff: boolean;
  is_superuser: boolean;
  organizations: { [orgGuid: string]: { role: string } };
  refresh: string;
}

export interface AuthStorage {
  AccessToken: string;
  AccessTokenTime: string;
  RefreshToken: string;
  defaultOrganization: string;
  isMasked: string;
  isStaff: string;
  isSuperUser: string;
  organizations: string;
  userEmail: string;
}

export const setAuthDataLocalStorage = (authData: AuthStorage) => {
  localStorage.setItem('Access-Token', authData.AccessToken);
  localStorage.setItem('Access-Token-Time', authData.AccessTokenTime);
  localStorage.setItem('Refresh-Token', authData.RefreshToken);
  localStorage.setItem('defaultOrganization', authData.defaultOrganization);
  localStorage.setItem('organizations', authData.organizations);
  localStorage.setItem('isStaff', authData.isStaff);
  localStorage.setItem('isSuperUser', authData.isSuperUser);
  localStorage.setItem('userEmail', authData.userEmail);
  localStorage.setItem('isMasked', authData.isMasked);
};

export function setTokenResponseData(
  response: TokenResponseType,
  postLogin?: () => void,
  setLoading?: (val: boolean) => void,
) {
  /**
   * Before setting local storage, remove all other types of tokens
   * The assumption is that, every time you login, this should be
   * set from the data from the BE and we should never pick up old
   * data.
   */
  // unset tokens
  cleanLocalStorage();
  // unset masker tokens
  cleanLocalStorage(true);

  setAuthDataLocalStorage({
    AccessToken: response.access,
    AccessTokenTime: moment().toISOString(),
    RefreshToken: response.refresh,
    defaultOrganization: response.default_organization || '',
    isMasked: String(isMasked()),
    isStaff: String(response.is_staff),
    isSuperUser: String(response.is_superuser),
    organizations: JSON.stringify(response.organizations),
    userEmail: response.email,
  });

  postLogin?.();
  setLoading?.(false);
}

export function identifyWithExternalTools(response: TokenResponseType) {
  requestAnimationFrame(() => {
    const userVars = {
      domain: document.location.hostname.split('.')[0],
      email: response.email,
      first_name: response?.first_name,
      isMasked: isMasked(),
      organizationGuid: response.default_organization,
    };

    Sentry.setUser(userVars);

    if (!process.env.REACT_APP_CHROME_EXTENSION_BUILD && !isMasked()) {
      FullStory('setIdentity', {
        properties: userVars,
        uid: response.email,
      });
    }

    if (typeof window.heap !== 'undefined' && typeof heap.identify === 'function') {
      heap.identify(response.email);
      heap.addUserProperties(userVars);
    }
  });
}

export function updateOrganizationDataInLocalStorage(data: OrganizationModel) {
  /*
   * When a new organization is created, update the
   * local storage so we can check permissions properly
   */
  localStorage.setItem('defaultOrganization', data.guid);
  localStorage.setItem('organizations', JSON.stringify({ [data.guid]: { role: 'admin' } }));
}

/**
 * Client will be removed soon.
 * @deprecated
 */
class client {
  static registerDeauthorizedCallback(cb: DeauthorizedCallback) {
    deauthorizedCallbacks.push(cb);
  }

  static unregisterDeauthorizedCallback(cb: () => void) {
    const index = deauthorizedCallbacks.indexOf(cb);
    if (index > -1) {
      deauthorizedCallbacks.splice(index, 1);
    }
  }

  /** @deprecated */
  static logout() {
    if (process.env.REACT_APP_CHROME_EXTENSION_BUILD) {
      clearChromeAuthData();
    }

    unmask();
    cleanLocalStorage();
    cleanLocalStorage(true);

    requestAnimationFrame(() => {
      if (!process.env.REACT_APP_CHROME_EXTENSION_BUILD) {
        FullStory('setIdentity', { anonymous: true });
      }
      if (typeof window.heap !== 'undefined' && typeof heap.resetIdentity === 'function') {
        heap.resetIdentity();
      }
      Sentry.setUser({});
    });
  }

  /** @deprecated */
  static isAuthed(): boolean {
    const access = localStorage.getItem('Access-Token');
    const refresh = localStorage.getItem('Refresh-Token');
    return !!access && !!refresh;
  }

  /** @deprecated */
  static isStaff(): boolean {
    return /true/i.test(localStorage.getItem('isStaff') || 'false');
  }

  /** @deprecated */
  static isSuperUser(): boolean {
    return /true/i.test(localStorage.getItem('isSuperUser') || 'false');
  }
}

export default client;
