import convertStringToSlate from '../convertStringToSlate';
import hasElement from '../hasElement/hasElement';
import { serializeSlateToPlainText } from '../serializationHelpers';

const isEmptyRichText = (richText: string = '') => {
  const slateState = convertStringToSlate(richText);
  const plainText = serializeSlateToPlainText({
    getMentionFromCacheById: () => ({ name: 'mention_placeholder' }), // we don't need the real mention name for this check, only to know if it exits
    nodes: slateState,
  });

  if (!plainText.trim()) {
    // look for elements that don't show up in the plain text
    return !hasElement(slateState, ['table', 'image']);
  }

  return false;
};

export default isEmptyRichText;
