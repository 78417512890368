import React from 'react';

import Button from '@components/Button/Button';

interface NextStepButtonProps {
  disabled?: boolean;
  onClick?: () => void;
  text?: string;
}

const NextStepButton: React.FC<NextStepButtonProps> = ({ disabled, onClick, text }) => {
  return (
    <Button compWidth="100px" disabled={disabled} onClick={onClick} type="submit">
      {text || 'Next'}
    </Button>
  );
};

export default NextStepButton;
