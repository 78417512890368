import React from 'react';
import styled from '@emotion/styled';
import { RouterLink } from '@routing/router';
import { Label as SemanticLabel } from 'semantic-ui-react';

import teamColorGenerator from '@utils/teamColorGenerator';

interface StyleProps {
  color: string;
  ml?: number;
}
const LabelStyled = styled.div<StyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  .ui.label {
    display: flex;
    border-radius: 0.8125rem;
    margin-left: ${({ ml = 1, theme }) => theme.space(ml)};
    background-color: ${({ color }) => color || '#f2f5f9'};
    white-space: nowrap;
    color: #fff;
    font-size: 0.7rem;
    padding: 0.24rem 0.625rem;
    max-height: 1.375rem;
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    align-content: baseline;
    text-overflow: ellipsis;
    font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
  }

  .ui.tiny.label {
    border-radius: 0.3rem;
    clear: both;
    float: right;
    color: #0c3476;
    background-color: #f2f5f9;
  }
`;

export const StyledLink = styled(RouterLink)`
  text-decoration: none;
  transition: none;
  color: inherit;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: inherit;
  }

  opacity: 1 !important;
`;

interface Props {
  extraClass?: string;
  guid?: string;
  labelText?: string;
  ml?: number;
  url?: string;
}

const Label: React.FC<Props> = ({ extraClass, guid, labelText, ml, url }) => {
  const color = teamColorGenerator(guid);
  if (!labelText) return <></>;

  if (url) {
    return (
      <LabelStyled color={color} ml={ml}>
        <StyledLink to={url}>
          <SemanticLabel className={extraClass}>{labelText}</SemanticLabel>
        </StyledLink>
      </LabelStyled>
    );
  }
  return (
    <LabelStyled color={color} ml={ml}>
      <SemanticLabel className={extraClass}>{labelText}</SemanticLabel>
    </LabelStyled>
  );
};

export default React.memo(Label);
