import { FilterOptions } from '@utils/filters';

export const defaultTagConfig: FilterOptions = {
  order: '-popularity',
  sortColumn: 'popularity',
  sortDirection: 'descending',
  page_size: 100,
  page: 1,
  object_type: ['all'],
};

export const defaultStatusTagConfig: FilterOptions = {
  ...defaultTagConfig,
  types: ['status'],
};

export const defaultDbtTagConfig: FilterOptions = {
  ...defaultTagConfig,
  types: ['dbt'],
};

export const defaultCategoryTagConfig: FilterOptions = {
  ...defaultTagConfig,
  types: ['category'],
};
