import React, { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';
import type { ModalContent, ModalId } from '@constants';
import { MODAL_IDS } from '@constants';

const defaultContextValue: {
  MODAL_IDS: typeof MODAL_IDS;
  checkModalOpened: (id: ModalId) => boolean;
  closeModal: (id: ModalId) => void;
  getModalContent: <T extends ModalId>(id: T) => ModalContent[T] | undefined;
  openModal: <T extends ModalId>(id: T, content?: ModalContent[T]) => void;
  openModals: Set<ModalId>;
  updateModalContent: <T extends ModalId>(id: T, content?: ModalContent[T]) => void;
} = {
  MODAL_IDS,
  checkModalOpened: () => false,
  closeModal: () => {},
  getModalContent: () => undefined,
  openModal: () => {},
  openModals: new Set(),
  updateModalContent: () => {},
};

const ModalContext = createContext(defaultContextValue);

export const useModal = () => useContext(ModalContext);

type ModalProviderProps = {
  children: React.ReactNode;
};

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const [openModals, setOpenModals] = useState<Set<ModalId>>(new Set());
  const [contentRecord, setContentRecord] = useState<ModalContent>({} as any);
  const sizeRef = useRef(openModals.size);

  const openModal = useCallback(
    <T extends ModalId>(id: T, content: ModalContent[T] | null | undefined = null) => {
      setOpenModals((prev) => new Set(prev).add(id));
      if (content === null) return;
      setContentRecord((prev) => ({ ...prev, [id]: content }));
    },
    [],
  );

  const closeModal = useCallback((id: ModalId) => {
    setOpenModals((prev) => {
      const next = new Set(prev);
      next.delete(id);
      return next;
    });
    setContentRecord((prev) => {
      const next = { ...prev };
      if (next[id]) delete next[id];
      return next;
    });
  }, []);

  const checkModalOpened = useCallback(
    (id: ModalId) => {
      return openModals.has(id);
    },
    [openModals],
  );

  const getModalContent = useCallback(
    <T extends ModalId>(id: T) => contentRecord[id],
    [contentRecord],
  );

  const updateModalContent = useCallback(
    <T extends ModalId>(id: T, content: ModalContent[T] | null | undefined = null) => {
      setContentRecord((prev) => ({ ...prev, [id]: content }));
    },
    [],
  );

  if (openModals.size !== sizeRef.current) {
    if (openModals.size > 0) {
      document.body.classList.add('is-modal-open');
    } else {
      document.body.classList.remove('is-modal-open');
    }
    sizeRef.current = openModals.size;
  }

  const value = useMemo(
    () => ({
      MODAL_IDS,
      checkModalOpened,
      closeModal,
      getModalContent,
      hasOpenModals: openModals.size > 0,
      openModal,
      openModals,
      updateModalContent,
    }),
    [checkModalOpened, closeModal, getModalContent, openModal, openModals, updateModalContent],
  );

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};
