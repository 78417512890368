import React from 'react';
import { SERVER_PROCESSING_ERROR, SMTH_WENT_WRONG, UNKNOWN_SERVER_ERROR } from '@constants';
import getDebug from 'debug';

import invalidateCache from '@api/invalidateCache';
import { usePatchTag, usePostTag } from '@api/tags';
import { TagModel } from '@api/tags/TagModel';
import Alert from '@components/Alert';
import { TERM_DESCRIPTION_ELEMENTS } from '@components/AppMainSidebar/Hierarchy/DocumentsHierarchy/CreateDocMenu/CreateTermModal/CreateTermModal';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import BaseOwnerSelect from '@components/OwnerSelect/BaseOwnerSelect';
import RichTextDescriptionEditor from '@components/RichTextDescriptionEditor';
import { renderInfoToast } from '@components/Toast';
import InputLabel from '@components/UI/Form/InputLabel';
import { ModalFooter } from '@components/UI/Modal';
import Select, { Option } from '@components/UI/Select';
import theme from '@styles/theme';

import {
  categoryTagOptions,
  defaultIconForType,
  statusTagOptions,
  TAG_TYPE_OPTIONS,
} from './constants';
import {
  StyledCreateTagContentFieldLabel,
  StyledCreateTagContentNameColorFieldLabel,
  StyledCreateTagContentNameColorFieldLabelText,
} from './CreateTagContent.styles';
import { getInitialState } from './CreateTagContent.utils';
import IconSelect from './IconSelect';
import { CreateTagForm, CreateTagModalPayload } from './types';
import useDefaultOwnerOptions from './useDefaultOwnerOptions';

const debug = getDebug('selectstar:tags:create');

export interface CreateTagContentProps {
  initialState?: CreateTagModalPayload;
  isEdit?: boolean;
  onClose?: (guid?: string, type?: TagModel['type']) => void;
  onCreateTag?: (tag: TagModel) => void;
}

const CreateTagContent: React.FC<CreateTagContentProps> = ({
  initialState,
  isEdit = false,
  onClose,
  onCreateTag,
}) => {
  const { options: defaultOwnerOptions, selectedOption: currentOwner } = useDefaultOwnerOptions({
    isEdit,
    owner: initialState?.owner,
  });

  const onSuccess = (data: TagModel) => {
    renderInfoToast(isEdit ? 'Tag Updated' : 'New Tag Created');
    invalidateCache((keys) => [keys.tags.all]);
    onCreateTag?.(data);
  };

  const postQuery = usePostTag({
    onSuccess,
  });

  const patchQuery = usePatchTag(initialState?.guid, {
    onSuccess,
  });

  const { error, isLoading, mutate } = isEdit ? patchQuery : postQuery;

  const { handleChange, handleSubmit, setValues, touched, values } = useForm<CreateTagForm>({
    initialValues: getInitialState(currentOwner, initialState),
    onSubmit: (payload) => {
      const [selectedOwner] = payload.owner ?? [];
      const [selectedType] = payload.type ?? [];

      const getFieldValue = (field: keyof CreateTagForm) => {
        return !isEdit || initialState?.[field] !== payload[field] ? payload[field] : undefined;
      };

      let newOwnerValue: string | number | undefined | null =
        !isEdit || selectedOwner?.value !== initialState?.owner?.guid
          ? selectedOwner?.value
          : undefined;

      if (isEdit && selectedOwner?.value !== initialState?.owner?.guid && !selectedOwner?.value) {
        newOwnerValue = null;
      }

      mutate({
        color: getFieldValue('color'),
        description: getFieldValue('description'),
        icon: getFieldValue('icon'),
        name: getFieldValue('name'),
        owner: newOwnerValue,
        richtext_description: getFieldValue('richtextDescription'),
        type:
          !isEdit || selectedType?.value !== initialState?.type ? selectedType?.value : undefined,
      });
    },
  });

  const handleDescriptionChange = (richTextDescription: string, description?: string) => {
    setValues((prevValues) => ({
      ...prevValues,
      description: description ?? '',
      richtextDescription: richTextDescription ?? '',
    }));
  };

  const iconOptions =
    values.type?.[0]?.value === 'category' ? categoryTagOptions : statusTagOptions;

  debug('state type/icon', values.type, values.icon);

  return (
    <Form isLoading={isLoading} onSubmit={handleSubmit}>
      <Box compDisplay="flex" compWidth="100%" flexDirection="column" gap={2} px={3} py={2.5}>
        <StyledCreateTagContentFieldLabel>
          <StyledCreateTagContentNameColorFieldLabelText>
            Tag Type
          </StyledCreateTagContentNameColorFieldLabelText>
          <Select
            error={Boolean(error?.data?.type)}
            helperText={error?.data?.type?.[0]}
            onChange={(newValue) => {
              const [selectedOption] = newValue as Option[];
              const def = defaultIconForType[selectedOption.value];

              setValues((prev) => ({
                ...prev,
                color: def.color,
                icon: def.icon,
                type: newValue as Option[],
              }));
            }}
            options={TAG_TYPE_OPTIONS}
            value={values.type}
          />
        </StyledCreateTagContentFieldLabel>
        <StyledCreateTagContentNameColorFieldLabel>
          <StyledCreateTagContentNameColorFieldLabelText>
            Icon/Name
          </StyledCreateTagContentNameColorFieldLabelText>
          <Box alignItems="flex-start" compDisplay="flex" gap={1} gridColumn="2 / 4">
            <IconSelect
              onChange={(newValue) => {
                setValues((prev) => ({
                  ...prev,
                  color: newValue.color,
                  icon: newValue.icon,
                }));
              }}
              options={iconOptions}
              value={{ color: values.color, icon: values.icon } as any}
            />
            <Input
              autoFocus
              error={!touched.name && error?.data?.name}
              flexGrow="1"
              helperText={!touched.name && error?.data?.name}
              max={30}
              min={1}
              name="name"
              onChange={handleChange}
              placeholder="Enter Tag Name"
              value={values.name}
            />
          </Box>
        </StyledCreateTagContentNameColorFieldLabel>
        <StyledCreateTagContentFieldLabel>
          <StyledCreateTagContentNameColorFieldLabelText>
            Tag Owner
          </StyledCreateTagContentNameColorFieldLabelText>
          <BaseOwnerSelect
            defaultOptions={defaultOwnerOptions}
            error={Boolean(error?.data?.owner)}
            fitAnchorWidth
            helperText={error?.data?.owner?.[0]}
            label="newOwnerSelect"
            onChange={(newValue) => {
              setValues((prev) => ({
                ...prev,
                owner: newValue as Option[],
              }));
            }}
            placeholder="Search for teams or users"
            showClearSelection
            showSearchOnOptions
            value={values.owner}
          />
        </StyledCreateTagContentFieldLabel>
        <Box compDisplay="flex" flexDirection="column">
          <InputLabel color="gray.700" cursor="default" fontWeight="medium" mb={1} mt={1.25}>
            Tag Description
          </InputLabel>
          <RichTextDescriptionEditor
            allowedElements={TERM_DESCRIPTION_ELEMENTS}
            descriptions={{
              description: values.description,
              richTextDescription: values.richtextDescription,
            }}
            disableSaveOnEnter
            editIconVariant="always"
            editMode
            fontSize="body2"
            isEditable
            maxHeight={theme.space(45)}
            onDescriptionChange={handleDescriptionChange}
            placeholder="Enter the description here"
            shouldFocusOnEdit={false}
            shouldStartFocused={false}
            title=""
            variant="inline"
          />
        </Box>
        {error && error?.status === 500 && (
          <Alert title={SMTH_WENT_WRONG} type="error">
            {SERVER_PROCESSING_ERROR} {error?.data?.detail || UNKNOWN_SERVER_ERROR}
          </Alert>
        )}
      </Box>
      <ModalFooter p={3}>
        <Button onClick={() => onClose?.()} type="button" variant="outlined">
          Cancel
        </Button>
        <Button disabled={isLoading || !values.name} type="submit">
          Save
        </Button>
      </ModalFooter>
    </Form>
  );
};

export default CreateTagContent;
