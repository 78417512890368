import React from 'react';

import { usePatchDataSource } from '@api/dataSources';
import Alert from '@components/Alert';
import Box from '@components/Box';
import NextStepButton from '@components/DataSourceSetup/components/Buttons/NextStepButton';
import PreviousStepButton from '@components/DataSourceSetup/components/Buttons/PreviousStepButton';
import ErrorCredentialAlert from '@components/DataSourceSetup/components/ErrorCredentialAlert/ErrorCredentialAlert';
import {
  StyledFormHorizontalLabelGrid,
  StyledLabel,
} from '@components/DataSourceSetup/DataSourceSetup.styles';
import Dropdown from '@components/Dropdown';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import { ModalFooter } from '@components/UI/Modal';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import { MetabaseCredentials } from '@models/DataSourceCredentials';
import { DataSourceModel } from '@models/DataSourceModel';

import ErrorDescriptionMarkdown from '../../ErrorDescriptionMarkdown';

import { DataSourceAddActivityDataStepProps } from './types';

const MetabaseAddActivityDataStepForm: React.FC<DataSourceAddActivityDataStepProps> = ({
  dataSource,
  onDataSourceAdded,
  prevStep,
}) => {
  const segment = useSegmentContext();

  const {
    error,
    isLoading,
    mutate: updateDataSource,
  } = usePatchDataSource(dataSource.guid, {
    onSuccess: onDataSourceAdded,
  });

  const { handleChange, handleSubmit, setValues, values } = useForm({
    initialValues: {
      database: '',
      host: '',
      password: '',
      port: '',
      service: 'cloud',
      type: 'postgres' as const,
      username: '',
    },
    onSubmit: (val) => {
      if (!isLoading) {
        const payload = {
          credentials: dataSource?.credentials ?? {},
          guid: dataSource?.guid,
          type: dataSource?.type,
        };

        if (val.service === 'on-prem') {
          (payload.credentials as MetabaseCredentials).data_store_params = {
            database: val.database,
            host: val.host,
            password: val.password,
            port: Number(val.port),
            type: val.type,
            user: val.username,
          };

          updateDataSource(payload);
        } else {
          onDataSourceAdded(payload as DataSourceModel);
        }

        segment?.track(SegmentTrackEventName.ImportData_TableauActivityDBNextButtonClicked);
      }
    },
  });

  return (
    <Form isLoading={isLoading} onSubmit={handleSubmit}>
      <StyledFormHorizontalLabelGrid>
        <StyledLabel as="div">
          Service
          <Dropdown
            error={error?.data && error.data?.service}
            icon="dropdown"
            onChange={(_, d) => {
              setValues((oldValues) => ({
                ...oldValues,
                service: d.value as string,
              }));
            }}
            options={[
              { text: 'Cloud', value: 'cloud' },
              { text: 'On-prem', value: 'on-prem' },
            ]}
            placeholder="Service"
            selection
            value={values.service}
          />
        </StyledLabel>
        {values.service === 'on-prem' && (
          <>
            <StyledLabel as="div">
              DB type
              <Dropdown
                error={error?.data && error.data?.type}
                icon="dropdown"
                onChange={(_, d) => {
                  setValues((oldValues) => ({
                    ...oldValues,
                    type: d.value as any,
                  }));
                }}
                options={[{ text: 'PostgreSQL', value: 'postgres' as const }]}
                placeholder=""
                selection
                value={values.type}
              />
            </StyledLabel>
            <StyledLabel>
              Host
              <Input
                error={error?.data?.host}
                helperText={error?.data?.host}
                name="host"
                onChange={handleChange}
                placeholder="db.example.com"
                type="text"
                value={values.host}
              />
            </StyledLabel>
            <StyledLabel>
              Port
              <Input
                error={error?.data?.port}
                helperText={error?.data?.port}
                name="port"
                onChange={handleChange}
                placeholder="5432"
                type="number"
                value={values.port}
              />
            </StyledLabel>
            <StyledLabel>
              Username
              <Input
                error={error?.data?.user}
                helperText={error?.data?.user}
                name="username"
                onChange={handleChange}
                placeholder="Username"
                type="text"
                value={values.username}
              />
            </StyledLabel>
            <StyledLabel>
              Password
              <Input
                error={error?.data?.password}
                helperText={error?.data?.password}
                name="password"
                onChange={handleChange}
                placeholder="Password"
                type="password"
                value={values.password}
              />
            </StyledLabel>
            <StyledLabel>
              Database
              <Input
                error={error?.data?.database}
                helperText={error?.data?.database}
                name="database"
                onChange={handleChange}
                placeholder="Database name"
                type="text"
                value={values.database}
              />
            </StyledLabel>
          </>
        )}
        <Box gridColumn="1/3">
          {error && error.status === 500 && (
            <Alert title="Couldn't store credentials." type="error">
              Please try again later.
            </Alert>
          )}
          <ErrorCredentialAlert error={error?.data.credentials} />
          <ErrorDescriptionMarkdown error={error} />
        </Box>
      </StyledFormHorizontalLabelGrid>
      <ModalFooter>
        <PreviousStepButton
          disabled={isLoading}
          onClick={() => {
            segment?.track(SegmentTrackEventName.ImportData_TableauActivityDBPrevButtonClicked);
            prevStep();
          }}
        />
        <NextStepButton disabled={isLoading} text="Save" />
      </ModalFooter>
    </Form>
  );
};

export default MetabaseAddActivityDataStepForm;
