import { plainToClass } from 'class-transformer';
import { ClassType } from 'class-transformer/ClassTransformer';

import { PaginatedResponse } from '@models/PaginatedResponse';

export const rawClassMap = <T>(model: ClassType<T>, data: any): T => {
  if (!data) return data;
  return plainToClass(model, data);
};

export const rawClassArrayMap = <T>(model: ClassType<T>, data: any) => {
  return rawClassMap(model, data) as unknown as T[];
};

export const rawMap = <T extends (...args: any) => any>(mapFunc: T, data: any): ReturnType<T> => {
  if (!data) return data;
  return mapFunc(data);
};

export const paginatedMap = <T extends (...args: any) => any>(
  mapFunc: T,
  data: any,
): PaginatedResponse<ReturnType<T>> => {
  if (!data || !data.results || !data.results.length) {
    return data;
  }
  return { ...data, results: data.results.map(mapFunc) };
};

export const paginatedSelect = <T extends (...args: any) => any>(mapFunc: T) => {
  return (data: any) => paginatedMap<T>(mapFunc, data);
};

export const rawSelect = <T extends (...args: any) => any>(mapFunc: T) => {
  return (data: any) => rawMap<T>(mapFunc, data);
};
