import { plainToClass } from 'class-transformer';

const rawTransform = (Model: any) => {
  return (data: any) => {
    if (data.length && data[0] instanceof Model) {
      return data;
    }
    if (data.length === undefined && data instanceof Model) {
      return data;
    }
    return plainToClass(Model, data);
  };
};

export default rawTransform;
