import styled from '@emotion/styled';

import TableStyled from '@components/Table/TableStyled';

const PopularJoinTableStyled = styled(TableStyled)`
  .ui.table tr td {
    &.joinConditions {
      & td.join {
        border: none;
        background: inherit;
      }
    }
  }
`;

export default PopularJoinTableStyled;
