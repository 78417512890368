interface FindAllDeepConfig {
  searchFieldName: string;
  searchText: string;
  tree: any;
}

const findAllDeep = (
  { searchFieldName, searchText, tree }: FindAllDeepConfig,
  result: any = [],
) => {
  if (tree == null) {
    return result;
  }

  tree.forEach((node: any) => {
    if (node[searchFieldName].toLowerCase().includes(searchText.toLowerCase())) {
      const copyNode = { ...node };
      const { children, ...rest } = copyNode;
      result.push(rest);
    }
    if (node?.children?.length > 0) {
      findAllDeep({ searchFieldName, searchText, tree: node.children }, result);
    }
  });

  return result;
};

export default findAllDeep;
