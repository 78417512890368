import React from 'react';
import * as Sentry from '@sentry/react';

import { usePatchDataSource } from '@api/dataSources';
import Alert from '@components/Alert';
import Box from '@components/Box';
import NextStepButton from '@components/DataSourceSetup/components/Buttons/NextStepButton';
import PreviousStepButton from '@components/DataSourceSetup/components/Buttons/PreviousStepButton';
import ErrorCredentialAlert from '@components/DataSourceSetup/components/ErrorCredentialAlert/ErrorCredentialAlert';
import {
  StyledFormHorizontalLabelGrid,
  StyledLabel,
} from '@components/DataSourceSetup/DataSourceSetup.styles';
import Dropdown from '@components/Dropdown';
import { DropzoneFormElement } from '@components/Dropzone';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import { ModalFooter } from '@components/UI/Modal';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import { DataStudioCredentials } from '@models/DataSourceCredentials';
import { DataSourceModel } from '@models/DataSourceModel';

import ErrorDescriptionMarkdown from '../../ErrorDescriptionMarkdown';

import { DataSourceAddActivityDataStepProps } from './types';

const DataStudioAddActivityDataStepForm: React.FC<DataSourceAddActivityDataStepProps> = ({
  dataSource,
  onDataSourceAdded,
  prevStep,
}) => {
  const segment = useSegmentContext();

  const {
    error,
    isLoading,
    mutate: updateDataSource,
  } = usePatchDataSource(dataSource.guid, {
    onSuccess: onDataSourceAdded,
  });

  const { handleChange, handleSubmit, setValues, values } = useForm({
    initialValues: {
      activityLogSource: '',
      authProviderx509CertUrl: 'https://www.googleapis.com/oauth2/v1/certs',
      authUri: 'https://accounts.google.com/o/oauth2/auth',
      clientEmail: '',
      clientId: '',
      clientx509CertUrl: '',
      privateKey: '',
      privateKeyId: '',
      projectId: '',
      service: 'add-activity',
      tokenUri: 'https://oauth2.googleapis.com/token',
      type: 'service_account' as const,
    },
    onSubmit: (val) => {
      if (!isLoading) {
        const payload = {
          credentials: dataSource?.credentials ?? {},
          guid: dataSource?.guid,
          type: dataSource?.type,
        };

        if (val.service === 'add-activity') {
          (payload.credentials as DataStudioCredentials).data_store_params = {
            activity_log_source: val.activityLogSource,
            auth_provider_x509_cert_url: val.authProviderx509CertUrl,
            auth_uri: val.authUri,
            client_email: val.clientEmail,
            client_id: val.clientId,
            client_x509_cert_url: val.clientx509CertUrl,
            private_key: val.privateKey,
            private_key_id: val.privateKeyId,
            project_id: val.projectId,
            token_uri: val.tokenUri,
            type: val.type,
          };

          updateDataSource(payload);
        } else {
          onDataSourceAdded(payload as DataSourceModel);
        }

        segment?.track(SegmentTrackEventName.ImportData_TableauActivityDBNextButtonClicked);
      }
    },
  });

  const handleOnFileAccept = (newServiceAcct?: string) => {
    if (newServiceAcct !== undefined) {
      try {
        const serviceAcctObj = JSON.parse(newServiceAcct);
        setValues((oldValues) => ({
          ...oldValues,
          authProviderx509CertUrl: serviceAcctObj.auth_provider_x509_cert_url,
          authUri: serviceAcctObj.auth_uri,
          clientEmail: serviceAcctObj.client_email,
          clientId: serviceAcctObj.client_id,
          clientx509CertUrl: serviceAcctObj.client_x509_cert_url,
          privateKey: serviceAcctObj.private_key,
          privateKeyId: serviceAcctObj.private_key_id,
          projectId: serviceAcctObj.project_id,
          tokenUri: serviceAcctObj.token_uri,
          type: serviceAcctObj.type,
        }));
      } catch (err) {
        Sentry.captureException(err);
      }
    }
  };

  return (
    <Form isLoading={isLoading} onSubmit={handleSubmit}>
      <StyledFormHorizontalLabelGrid>
        <StyledLabel as="div">
          Service
          <Dropdown
            error={error?.data && error.data?.service}
            icon="dropdown"
            onChange={(_, d) => {
              setValues((oldValues) => ({
                ...oldValues,
                service: d.value as string,
              }));
            }}
            options={[
              { text: 'Skip Activity Source', value: 'no-activity' },
              { text: 'Add Activity Source', value: 'add-activity' },
            ]}
            placeholder="Service"
            selection
            value={values.service}
          />
        </StyledLabel>
        {values.service === 'no-activity' && (
          <Box gridColumn="1/3">
            <Alert type="warning">
              Selecting "Skip Activity Source" we won't be able to calculate popularity. If that is
              acceptable, please move to the next step. Otherwise, chose the "Add Activity Source"
              option.
            </Alert>
          </Box>
        )}
        {values.service !== 'no-activity' && (
          <>
            <StyledLabel>
              Activity Log Source
              <Input
                error={error?.data?.activity_log_source}
                helperText={error?.data?.activity_log_source}
                name="activityLogSource"
                onChange={handleChange}
                placeholder="Activity Log Source"
                type="text"
                value={values.activityLogSource}
              />
            </StyledLabel>
            <StyledLabel as="div">
              Service Account
              <DropzoneFormElement
                isComplete={values.projectId !== ''}
                onFileAccept={handleOnFileAccept}
                text="Drag & Drop your Service Account API key in .json here"
              />
            </StyledLabel>
            <StyledLabel>
              Account Type
              <Input
                error={error?.data?.type}
                helperText={error?.data?.type}
                name="type"
                onChange={handleChange}
                placeholder="service_account"
                readOnly
                type="text"
                value={values.type}
              />
            </StyledLabel>
            <StyledLabel>
              Project ID
              <Input
                error={error?.data?.project_id}
                helperText={error?.data?.project_id}
                name="projectId"
                onChange={handleChange}
                placeholder="Project ID"
                readOnly
                type="text"
                value={values.projectId}
              />
            </StyledLabel>
            <StyledLabel>
              Private Key ID
              <Input
                error={error?.data?.private_key_id}
                helperText={error?.data?.private_key_id}
                name="privateKeyId"
                onChange={handleChange}
                placeholder="Private Key ID"
                readOnly
                type="text"
                value={values.privateKeyId}
              />
            </StyledLabel>
            <StyledLabel>
              Private Key
              <Input
                error={error?.data?.private_key}
                helperText={error?.data?.private_key}
                name="privateKey"
                onChange={handleChange}
                placeholder="Private Key"
                readOnly
                type="password"
                value={values.privateKey}
              />
            </StyledLabel>
            <StyledLabel>
              Client Email
              <Input
                error={error?.data?.client_email}
                helperText={error?.data?.client_email}
                name="clientEmail"
                onChange={handleChange}
                placeholder="Client Email"
                readOnly
                type="text"
                value={values.clientEmail}
              />
            </StyledLabel>
            <StyledLabel>
              Client ID
              <Input
                error={error?.data?.client_id}
                helperText={error?.data?.client_id}
                name="clientId"
                onChange={handleChange}
                placeholder="Client ID"
                readOnly
                type="text"
                value={values.clientId}
              />
            </StyledLabel>
            <StyledLabel>
              Auth URI
              <Input
                error={error?.data?.auth_uri}
                helperText={error?.data?.auth_uri}
                name="authUri"
                onChange={handleChange}
                placeholder="Auth URI"
                readOnly
                type="text"
                value={values.authUri}
              />
            </StyledLabel>
            <StyledLabel>
              Token URI
              <Input
                error={error?.data?.token_uri}
                helperText={error?.data?.token_uri}
                name="tokenUri"
                onChange={handleChange}
                placeholder="Token URI"
                readOnly
                type="text"
                value={values.tokenUri}
              />
            </StyledLabel>
            <StyledLabel>
              Auth Provider x509 Cert URL
              <Input
                error={error?.data?.auth_provider_x509_cert_url}
                helperText={error?.data?.auth_provider_x509_cert_url}
                name="authProviderx509CertUrl"
                onChange={handleChange}
                placeholder="Auth Provider x509 Cert URL"
                readOnly
                type="text"
                value={values.authProviderx509CertUrl}
              />
            </StyledLabel>
            <StyledLabel>
              Client x509 Cert URL
              <Input
                error={error?.data?.client_x509_cert_url}
                helperText={error?.data?.client_x509_cert_url}
                name="clientx509CertUrl"
                onChange={handleChange}
                placeholder="client Provider x509 Cert URL"
                readOnly
                type="password"
                value={values.clientx509CertUrl}
              />
            </StyledLabel>
          </>
        )}
        <Box gridColumn="1/3">
          {error && error.status === 500 && (
            <Alert title="Couldn't store credentials." type="error">
              Please try again later.
            </Alert>
          )}
          <ErrorCredentialAlert error={error?.data.credentials} />
          <ErrorDescriptionMarkdown error={error} />
        </Box>
      </StyledFormHorizontalLabelGrid>
      <ModalFooter>
        <PreviousStepButton
          disabled={isLoading}
          onClick={() => {
            segment?.track(SegmentTrackEventName.ImportData_TableauActivityDBPrevButtonClicked);
            prevStep();
          }}
        />
        <NextStepButton disabled={isLoading} text="Save" />
      </ModalFooter>
    </Form>
  );
};

export default DataStudioAddActivityDataStepForm;
