import mapBiDashboardElementModel from '@api/biDashboardElements/biDashboardElementModel.v1';
import { BIFolderModel } from '@api/bifolder/BIFolderModel';
import mapBiNotebookModel from '@api/biNotebooks/biNotebookModel.v1';
import BiTableModel from '@api/biTables/BiTableModel';
import mapCollectionModel from '@api/collections/CollectionModel';
import { ColumnModel } from '@api/columns/ColumnModel';
import { CommentModel } from '@api/comments/CommentModel';
import WarehouseModel from '@api/costAnalysis/WarehouseModel';
import { DashboardElementModel } from '@api/dashboards/DashboardElementModel';
import { DashboardModel } from '@api/dashboards/DashboardModel';
import { DatabaseModel } from '@api/databases/DatabaseModel';
import mapDatasetModel from '@api/datasets/DatasetModel.v1';
import mapDatasetModelV1 from '@api/datasetsV1/DatasetModel.v1';
import mapDbtProjectModel from '@api/dbt/DbtProjectModel.v1';
import DbtTestModel from '@api/dbt/DbtTestModel';
import { DocumentModel } from '@api/documents/DocumentModel';
import { DsUserModel } from '@api/dsusers/DsUserModel';
import { LookerExploreFieldModel } from '@api/explores/LookerExploreFieldModel';
import { LookerExploreModel } from '@api/explores/LookerExploreModel';
import { LookMLFieldModel } from '@api/fields/LookMLFieldModel';
import { FolderModel } from '@api/folder/FolderModel';
import IndexModel from '@api/indexes/IndexModel';
import mapJobModel from '@api/jobs/JobModel.v1';
import { LookMLViewModel } from '@api/lookML/LookMLViewModel';
import { MentionModel } from '@api/mentions/MentionModel';
import { MetricModel } from '@api/metrics/MetricModel';
import mapMonitorsModel from '@api/monitors/MonitorsModel';
import { PiiRuleModel } from '@api/piiRules/PiiRuleModel';
import { ReportQueryModel } from '@api/queries/ReportQueryModel';
import { SchemaModel } from '@api/schema/SchemaModel';
import { TableauDataSourceModel } from '@api/tableau/TableauDataSourceModel';
import { TableauFieldModel } from '@api/tableau/TableauFieldModel';
import { TableModel } from '@api/tables/TableModel';
import { TaggedItemModel } from '@api/tags/TaggedItemModel';
import { TagModel } from '@api/tags/TagModel';
import { TeamModel } from '@api/teams/TeamModel';
import { TermModel } from '@api/terms/TermModel';
import { UserModel } from '@api/user/UserModel';
import { UserListModel } from '@api/userLists/UserListModel';
import { BiConnectionModel } from '@api/views/BiConnectionModel';
import { BiViewModel } from '@api/views/BiViewModel';
import { TableauColumnModel } from '@api/views/TableauColumnModel';
import { TableauTableModel } from '@api/views/TableauTableModel';
import { DataSourceModel } from '@models/DataSourceModel';
import { referenceObjectMap, referenceObjectMapV1 } from '@models/Reference';

referenceObjectMap[TableauColumnModel.objectType] = TableauColumnModel;
referenceObjectMap[BiConnectionModel.objectType] = BiConnectionModel;
referenceObjectMap[BIFolderModel.objectType] = BIFolderModel;
referenceObjectMap[BiTableModel.objectType] = BiTableModel;
referenceObjectMap[ColumnModel.objectType] = ColumnModel;
referenceObjectMap.thoughtspotcolumn = ColumnModel;
referenceObjectMap.powerbicolumn = ColumnModel;
referenceObjectMap.bicolumn = ColumnModel; // new generic, will replace ds[column]
referenceObjectMap[CommentModel.objectType] = CommentModel;
referenceObjectMap[WarehouseModel.objectType] = WarehouseModel;
referenceObjectMap[DashboardElementModel.objectType] = DashboardElementModel;
referenceObjectMap[DashboardModel.objectType] = DashboardModel;
referenceObjectMap[DatabaseModel.objectType] = DatabaseModel;
referenceObjectMap[DbtTestModel.objectType] = DbtTestModel;
referenceObjectMap[DocumentModel.objectType] = DocumentModel;
referenceObjectMap[DsUserModel.objectType] = DsUserModel;
referenceObjectMap[LookerExploreFieldModel.objectType] = LookerExploreFieldModel;
referenceObjectMap[LookerExploreModel.objectType] = LookerExploreModel;
referenceObjectMap[LookMLFieldModel.objectType] = LookMLFieldModel;
referenceObjectMap[FolderModel.objectType] = FolderModel;
referenceObjectMap[IndexModel.objectType] = IndexModel;
referenceObjectMap[LookMLViewModel.objectType] = LookMLViewModel;
referenceObjectMap[MentionModel.objectType] = MentionModel;
referenceObjectMap[MetricModel.objectType] = MetricModel;
referenceObjectMap[PiiRuleModel.objectType] = PiiRuleModel;
referenceObjectMap[ReportQueryModel.objectType] = ReportQueryModel;
referenceObjectMap[SchemaModel.objectType] = SchemaModel;
referenceObjectMap[TableauDataSourceModel.objectType] = TableauDataSourceModel;
referenceObjectMap[TableauFieldModel.objectType] = TableauFieldModel;
referenceObjectMap[TableModel.objectType] = TableModel;
referenceObjectMap[TaggedItemModel.objectType] = TaggedItemModel;
referenceObjectMap[TagModel.objectType] = TagModel;
referenceObjectMap[TermModel.objectType] = TermModel;
referenceObjectMap[UserModel.objectType] = UserModel;
referenceObjectMap[UserListModel.objectType] = UserListModel;
referenceObjectMap[BiConnectionModel.objectType] = BiConnectionModel;
referenceObjectMap[BiViewModel.objectType] = BiViewModel;
referenceObjectMap[TableauTableModel.objectType] = TableauTableModel;
referenceObjectMap[DataSourceModel.objectType] = DataSourceModel;
referenceObjectMap[TeamModel.objectType] = TeamModel;
referenceObjectMapV1[mapDatasetModel.objectType] = mapDatasetModel;
referenceObjectMapV1[mapDatasetModelV1.objectType] = mapDatasetModelV1; // new generic, will replace ds[dataset]
referenceObjectMapV1[mapBiDashboardElementModel.objectType] = mapBiDashboardElementModel;
referenceObjectMapV1[mapBiNotebookModel.objectType] = mapBiNotebookModel;
referenceObjectMapV1[mapDbtProjectModel.objectType] = mapDbtProjectModel;
referenceObjectMapV1[mapMonitorsModel.objectType] = mapMonitorsModel;
referenceObjectMapV1[mapJobModel.objectType] = mapJobModel;
referenceObjectMapV1[mapCollectionModel.objectType] = mapCollectionModel;
