import React, { useMemo } from 'react';

import { ActivityMessageModel } from '@api/activity/ActivityMessageModel';
import Highlighter from '@components/Highlighter';

import { StyledActivityMessage } from './ActivityMessage.styles';
import { TargetComponent, UserComponent } from './parsedObjectComponents';
import { ParsedComponentProps, TemplateObject } from './types';

const templateObjects: Record<TemplateObject, React.FC<ParsedComponentProps>> = {
  parent: TargetComponent,
  target: TargetComponent,
  user: UserComponent,
};

export interface UseParsedMessageProps {
  globalFilter?: string;
  hideUserAvatar?: boolean;
  isShownOnTable?: boolean;
  message: ActivityMessageModel;
}

const useParsedMessage = ({
  globalFilter,
  hideUserAvatar,
  isShownOnTable = true,
  message: { template, values },
}: UseParsedMessageProps) => {
  const parsedObjects = useMemo(() => {
    const templateChunks = template.split(' ');
    const chunkRegex = /^{(.*?)}$/g;

    return templateChunks.map((chunk) => {
      if (chunkRegex.test(chunk)) {
        const objectKey = chunk.replace(/{|}/g, '') as TemplateObject;
        const ObjectComponent = templateObjects[objectKey];

        return React.createElement(ObjectComponent, {
          config: { globalFilter, hideUserAvatar },
          key: chunk,
          obj: values?.[objectKey]?.obj,
        });
      }

      return (
        <Highlighter
          key={chunk}
          autoEscape
          searchWords={globalFilter?.split(/[.| ]+/) ?? []}
          textToHighlight={`${chunk} `}
        />
      );
    });
  }, [template, values, globalFilter, hideUserAvatar]);

  return (
    <StyledActivityMessage data-testid="parsedMessage" pr={0.5} py={isShownOnTable ? 1 : undefined}>
      {parsedObjects}
    </StyledActivityMessage>
  );
};

export default useParsedMessage;
