import React from 'react';

import { GetLineageRetrieveQueryParams } from '@api/openapi.generated';
import ExpandCollapseButton, { ExpandCollapseState } from '@components/ExpandCollapseButton';

interface ExpandCollapseLineageButtonProps {
  direction: GetLineageRetrieveQueryParams['direction'];
  disabled?: boolean;
  isColumn?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
  state?: ExpandCollapseState;
}

const ExpandCollapseLineageButton: React.FC<ExpandCollapseLineageButtonProps> = ({
  direction,
  disabled,
  isColumn,
  isLoading,
  onClick,
  state = 'collapsed',
}) => {
  const isExpanded = state === 'expanded';
  const directionLabel = direction === 'left' ? 'upstream' : 'downstream';

  const tooltipText = disabled
    ? `Too many nodes to open all ${directionLabel}.${
        isColumn ? '' : ' Download CSV to get the lineage list.'
      }`
    : undefined;

  return (
    <ExpandCollapseButton
      disabled={disabled}
      isLoading={isLoading}
      onClick={onClick}
      state={state}
      tooltipText={tooltipText}
    >
      {isExpanded ? 'Close' : 'Open'} all {directionLabel}
    </ExpandCollapseButton>
  );
};

export default ExpandCollapseLineageButton;
