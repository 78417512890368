export const all = ['metadata'];

export const canPropagateDescription = (id: string) => [...all, 'canPropagateDescription', id];

export const commentTarget = (id: string) => [...all, 'comments', id];

export const copyData = (id: string) => [...all, 'copyData', id];

export const metadata = (id: string) => [...all, id];

export const metadataComments = [...all, 'comments'];

export const metadataOwnedObjectsByOwnerId = (ownerId: string) => [
  ...all,
  'owned-objects',
  ownerId,
];
