import getDebug from 'debug';
import sortBy from 'lodash/sortBy';

import TableLineageModel from '@api/lineage/TableLineageModel';

import type { TablesMap } from '../../types';
import { Stack, StackIndex } from '../../types';

const debug = getDebug('selectstar:explore');
const debugGraph = debug.extend('graph');

interface CreateStackArgs {
  startingTableId?: string;
  suppressedTableIds?: string[];
  tables: Partial<TableLineageModel>[];
  tablesById: TablesMap;
}

const dataSourceGroupsOrder = [
  'snowflake',
  'bigquery',
  'redshift',
  'looker',
  'mode',
  'tableau',
  'dbt',
  'periscope',
  'sigma',
  'power_bi',
];

const createStack = ({
  startingTableId,
  suppressedTableIds = [],
  tables,
  tablesById,
}: CreateStackArgs) => {
  // walk happens every time
  const walked = {} as { [id: string]: boolean };
  // stack and stackedAt are based on previous state, and gets added as data is loaded
  const stack: Stack = {};
  const stackedAt: StackIndex = {};

  // Stack is stateful, and will keep track of suppressed tables, so try to remove, if any
  suppressedTableIds
    .filter((id) => typeof stackedAt[id] !== 'undefined')
    .forEach((id) => {
      const pos = stackedAt[id];
      const index = stack[pos].findIndex((stackedTable) => id === stackedTable[0]);
      stack[pos].splice(index, 1);
      delete stackedAt[id];
    });

  const tryToStackIt = (id: string, pos: number) => {
    // only loaded tables and only stack once
    if (tablesById[id] && typeof stackedAt[id] === 'undefined') {
      // create stack if needed
      if (!stack[pos]) {
        Object.assign(stack, { [pos]: [] });
      }
      stack[pos].push([id, tablesById[id]?.name || id]);
      stackedAt[id] = pos;
    }
  };

  function recursiveStack(id: string, pos: number) {
    const table = tablesById[id];

    if (!table || walked[id]) return;

    walked[id] = true;

    // make sure table is stacked
    tryToStackIt(id, pos);

    // sort props
    const sources = table?.sourceTableGuids?.filter((t) => !!tablesById[t]) ?? [];
    const targets = table?.targetTableGuids?.filter((t) => !!tablesById[t]) ?? [];

    // first stack source/target to positions
    targets.forEach((_) => tryToStackIt(_, stackedAt[id] + 1));
    sources.forEach((_) => tryToStackIt(_, stackedAt[id] - 1));
    // only recurse after stacks are defined
    targets.forEach((_) => recursiveStack(_, stackedAt[id] + 1));
    sources.forEach((_) => recursiveStack(_, stackedAt[id] - 1));
  }

  if (startingTableId) {
    recursiveStack(startingTableId, 0);
  }

  // start with all visible items
  tables.forEach((t) => recursiveStack(t?.guid ?? '', startingTableId ? 1 : 0));

  Object.keys(stack).forEach((stackPos: unknown) => {
    /**
     * Sort by dataSourceType when stack changes.
     * This causes popular tables to be load above the already loaded tables.
     */
    stack[stackPos as number] = sortBy(stack[stackPos as number], (item) => {
      return dataSourceGroupsOrder.indexOf(tablesById[item[0] ?? item]?.dataSourceType as string);
    });
  });

  debugGraph('stack', stack);
  return { stack, stackedAt, startingTableId };
};

export default createStack;
