import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';

import { SearchModel } from './SearchModel';
import { SearchResult } from './types';

type SearchType = 'quick' | 'regular';

interface TrackProps {
  response: SearchResult<SearchModel>;
  variables?: any;
}

const EVENT_TYPE_MAP: Record<SearchType, SegmentTrackEventName> = {
  quick: SegmentTrackEventName.QuickSearchPerformed,
  regular: SegmentTrackEventName.RegularSearchPerformed,
};

const useTrackSearch = (type: SearchType) => {
  const segment = useSegmentContext();

  const mappedEvent = EVENT_TYPE_MAP[type];

  const track = ({ response, variables }: TrackProps) => {
    segment?.track(mappedEvent, {
      duration: response.requestMetadata?.duration,
      results: response.data.map((result) => result.guid),
      resultsCount: response.data.length ?? 0,
      variables,
    });
  };

  return { track };
};

export default useTrackSearch;
