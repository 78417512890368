import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import moment from 'moment';

interface DateRangePickerContextValue {
  dateRange: [string, string];
  maxRangeDays: number;
  presetDateOptions: Array<{ days: number; label: string }>;
  setDateRange: (value: [string, string]) => void;
}

const initialState: DateRangePickerContextValue = {
  dateRange: [
    moment.utc().add(-14, 'days').format('YYYY-MM-DD'),
    moment.utc().add(-1, 'days').format('YYYY-MM-DD'),
  ],
  maxRangeDays: 89,
  presetDateOptions: [
    {
      days: 0,
      label: 'Today',
    },
    {
      days: 6,
      label: 'Past 7 days',
    },
    {
      days: 13,
      label: 'Past 14 days',
    },
    {
      days: 29,
      label: 'Past 30 days',
    },
    {
      days: 89,
      label: 'Past 90 days',
    },
  ],
  setDateRange: () => {},
};

export const DateRangePickerContext = createContext(initialState);
export const useDateRangePickerContext = () => useContext(DateRangePickerContext);

interface DateRangePickerProviderProps
  extends Partial<Pick<DateRangePickerContextValue, 'maxRangeDays'>> {
  children: ReactNode;
}

export const DateRangePickerProvider = ({
  children,
  maxRangeDays = initialState.maxRangeDays,
}: DateRangePickerProviderProps) => {
  const [dateRange, setDateRange] = useState(initialState.dateRange);

  const value = useMemo(
    () => ({
      dateRange,
      maxRangeDays,
      presetDateOptions: initialState.presetDateOptions,
      setDateRange,
    }),
    [dateRange, maxRangeDays],
  );

  return (
    <DateRangePickerContext.Provider value={value}>{children}</DateRangePickerContext.Provider>
  );
};
