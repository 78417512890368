import styled from '@emotion/styled';

import Box from '@components/Box';

export const StyledTileTooltip = styled(Box)`
  display: flex;
  width: 248px;
  max-height: 194px;
  overflow: auto;
  flex-direction: column;
  align-items: flex-start;

  border-radius: ${({ theme }) => theme.radius.lg};
  border: 1px solid ${({ theme }) => theme.colors.v1.gray[300]};
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 10px 10px 10px 3px rgba(111, 116, 124, 0.07);
`;
