import React, { cloneElement } from 'react';

import { QueryModel } from '@api/queries/QueryModel';
import Button from '@components/Button/Button';
import Highlighter, { HighlighterProps } from '@components/Highlighter/Highlighter';
import Link from '@components/Link';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import { useModal } from '@context/Modal';
import { QueryLogRecordModel } from '@models/QueryLogRecordModel';

interface QueryTextProps {
  children?: React.ReactElement;
  codeString?: string;
  dataSourceType?: string;
  disableFormat?: boolean;
  displayText?: string;
  editable?: boolean;
  guid?: string;
  hideSidebar?: boolean;
  highlighterProps?: Partial<HighlighterProps>;
  modalTitle?: string;
  onClose?: () => void;
  onCodeStringEdit?: () => void;
  onOpen?: () => void;
  query?: QueryModel;
  queryLogRecords?: QueryLogRecordModel[];
  tooltipText?: string;
}

const QueryModalWithTargetBox: React.FC<QueryTextProps> = ({
  children,
  codeString = '',
  dataSourceType,
  disableFormat,
  displayText,
  editable,
  guid,
  hideSidebar,
  highlighterProps,
  modalTitle,
  onClose,
  onCodeStringEdit,
  onOpen,
  query,
  queryLogRecords,
  tooltipText,
}) => {
  const { MODAL_IDS, openModal } = useModal();

  if (!codeString && !query && !queryLogRecords) {
    return null;
  }

  const handleTriggerClick = (e: any) => {
    openModal(MODAL_IDS.query, {
      codeString,
      dataSourceType,
      disableFormat,
      editable,
      hideSidebar,
      metricGuid: guid,
      onClose,
      query,
      queryLogRecords,
      reloadData: onCodeStringEdit,
      title: modalTitle,
    });
    children?.props?.onClick?.(e);
    onOpen?.();
  };

  let content = (
    <Button
      backgroundColor="rgba(12, 52, 118, 0.07)"
      color="#0C3476"
      iconCurrentColor
      onClick={handleTriggerClick}
      startIcon={<Icon name="code-block" size="16px" />}
      type="button"
    >
      SQL
    </Button>
  );

  if (children) {
    content = cloneElement(children, {
      onClick: handleTriggerClick,
    });
  }

  if (displayText) {
    const { searchWords = [] } = highlighterProps ?? {};
    content = (
      <Link
        as="span"
        boldOnHover
        color="inherit"
        fontSize="inherit"
        onClick={handleTriggerClick}
        role="button"
      >
        <Highlighter autoEscape searchWords={searchWords} textToHighlight={displayText} />
      </Link>
    );
  }

  if (tooltipText) {
    return <Tooltip content={tooltipText}>{content}</Tooltip>;
  }

  return content;
};

export default QueryModalWithTargetBox;
