import React, { useCallback, useState } from 'react';
import getDebug from 'debug';
import isEqual from 'lodash/isEqual';
import pull from 'lodash/pull';
import union from 'lodash/union';

import { folderCacheKeys } from '@api/bifolder';
import { exploresCacheKeys } from '@api/explores';
import invalidateCache from '@api/invalidateCache';
import { tableauCacheKeys } from '@api/tableau';
import { usePatchTags } from '@api/tags';
import { TaggedItemsUpdateData } from '@api/tags/TagModel';
import { viewsCacheKeys } from '@api/views';
import { renderInfoToast } from '@components/Toast';
import fetchClient from '@lib/fetchClient';
import { MetadataData } from '@models/MetadataModel';

import getInitialMap from './getInitialMap';
import {
  SelectedItems,
  TagPropagationContext,
  TagPropagationContextInterface,
} from './TagPropagation';
import { TagNode } from './types';

const debug = getDebug('selectstar:tag-propagation-context');

const getIdxOf = (state: TaggedItemsUpdateData[], tagGuids: string[]) => {
  const values = Object.values(state);
  for (let i = 0; i < values.length; i += 1) {
    if (isEqual(values[i], tagGuids)) {
      return i;
    }
  }
  return -1;
};

export const getFinalState = (
  initialState: { [id: string]: string[] },
  tagGuids: string[],
  tagsToRemove: string[],
) => {
  const finalState: TaggedItemsUpdateData[] = [];

  Object.entries(initialState).forEach(([tableGuid, prevTagsGuids]) => {
    const unionValues = union(prevTagsGuids, tagGuids);
    const removedUnion = pull(unionValues, ...tagsToRemove);

    // initial state not equal to the final state
    if (!isEqual(removedUnion, prevTagsGuids)) {
      const idx = getIdxOf(finalState, removedUnion);

      if (idx >= 0) {
        finalState[idx].tags.push(tableGuid);
      } else {
        finalState.push({
          items: [tableGuid],
          tags: removedUnion,
        });
      }
    }
  });
  return finalState;
};

interface TagPropagationProps {
  isColumnLineage?: boolean;
  itemGuid: string;
  selectedItems: MetadataData[];
  tagGuids: string[];
  tagsToRemoveGuids: string[];
}

const TagPropagationContextProvider: React.FC<TagPropagationProps> = ({
  children,
  isColumnLineage,
  itemGuid,
  selectedItems,
  tagGuids,
  tagsToRemoveGuids,
}) => {
  const [selected, setSelected] = useState(getInitialMap(selectedItems));
  const [error, setError] = useState<string>();

  const handleSelectItem = (checked: boolean, guid: string, currentTagGuids?: string[]) => {
    debug('handling toggle item');
    setSelected((prev) => {
      if (checked) return { ...prev, [guid]: currentTagGuids || [] };
      const { [guid]: _, ...allOthers } = prev;
      return allOthers;
    });
  };

  const { isLoading, mutate } = usePatchTags({
    onError: (e) => {
      setError(e?.message || e?.statusText);
    },
    onSuccess: () => {
      invalidateCache((keys) => [
        keys.columns.all,
        keys.dashboards.all,
        keys.tableau.all,
        keys.documents.all,
        keys.tables.all,
        keys.queries.all,
        keys.lookML.views,
      ]);
      fetchClient.invalidateQueries(folderCacheKeys.folders);
      fetchClient.invalidateQueries(exploresCacheKeys.explores);
      fetchClient.invalidateQueries(viewsCacheKeys.views);
      fetchClient.invalidateQueries(tableauCacheKeys.datasources);

      renderInfoToast('Tags Updated');
      setSelected({});
    },
  });

  /**
   *  Remove all the children of a node from the selected state.
   *  This flow happens when the carret is closed.
   */
  const handleChildrenItemDelete = (isOpen: boolean, nodes: TagNode[]) => {
    if (nodes) {
      const state: SelectedItems = {};
      if (isOpen) {
        const nodeKeys = nodes.map((node) => node.key);
        const filteredState = Object.keys(selected).filter((item) => !nodeKeys.includes(item));
        filteredState.forEach((itemKey: string) => {
          state[itemKey] = selected[itemKey];
        });
        setSelected(state);
      }
    }
  };

  const isChecked = useCallback((guid: string) => Object.keys(selected).includes(guid), [selected]);

  const updateTags = () => {
    if (!isLoading) {
      const finalState: TaggedItemsUpdateData[] = getFinalState(
        selected,
        tagGuids,
        tagsToRemoveGuids,
      );

      debug('final state', finalState);
      if (finalState.length > 0) {
        mutate({ replace_existing: true, tag_set: finalState });
      }
    }
  };

  const defaultContext: TagPropagationContextInterface = {
    error,
    handleChildrenItemDelete,
    handleSelectItem,
    isChecked,
    isColumnLineage,
    isLoading,
    itemGuid,
    selected,
    setError,
    update: updateTags,
  };

  return (
    <TagPropagationContext.Provider value={defaultContext}>
      {children}
    </TagPropagationContext.Provider>
  );
};

export default TagPropagationContextProvider;
