import styled from '@emotion/styled';

import type { StyledBoxProps } from '@components/Box';
import Box from '@components/Box';

export interface StyledAddDSHeaderProps extends StyledBoxProps {}

export const StyledAddDSHeader = styled(Box)<StyledAddDSHeaderProps>``;

StyledAddDSHeader.defaultProps = {
  alignItems: 'center',
  compDisplay: 'flex',
  gap: 1,
};
