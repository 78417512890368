import React, { useState } from 'react';
import { useLocation, useParams } from '@routing/router';

import { useFetchFolder } from '@api/bifolder';
import CircularLoader from '@components/CircularLoader';
import NotFoundError from '@components/Error/NotFoundError';
import { Option } from '@components/UI/Select';
import type { DashboardsPageProps } from '@pages/DashboardsPage/DashboardsPage.v1';
import DashboardsPage from '@pages/DashboardsPage/DashboardsPage.v1';
import DatasetPage from '@pages/DatasetsPage';
import stripSpaces from '@utils/stripSpaces';

import type { DataTypeOption, QuicksightPageViewProps } from './constants';
import { CONTAINER_CONFIG, DATA_TYPE_OPTION_CONFIG, DATA_TYPE_OPTIONS } from './constants';

const FOLDER_QUERY = stripSpaces(`{
  name,
  breadcrumbs,
  data_types,
  data_source{
    guid
  }
}`);

const COUNT_ITEMS_CONFIG: Record<
  DataTypeOption['value'],
  QuicksightPageViewProps['countItemsConfig']
> = {
  analysis: [{ key: 'count', text: 'Analysis' }],
  bidataset: [{ key: 'count', text: 'Dataset' }],
  content: [
    { key: 'reports_count', text: 'Analysis' },
    { key: 'dashboard_count', text: 'Dashboard' },
  ],
  dashboard: [{ key: 'count', text: 'Dashboard' }],
};

const DASHBOARD_VISIBLE_COLUMNS: DashboardsPageProps['visibleColumns'] = [
  'search',
  'name',
  'description',
  'dsuser',
  'dsuserOwnedBy',
  'createdAt',
  'updatedAt',
  'lastRun',
  'tags',
  'sourceTablesCount',
  'popularity',
];

const QuickSightListPage: React.FC = () => {
  const location = useLocation();
  const { dsGuid = '', guid = '' } = useParams<{ dsGuid?: string; guid?: string }>();
  const { data, isError, isLoading } = useFetchFolder(guid, {
    enabled: Boolean(guid),
    params: { query: FOLDER_QUERY },
  });
  const [selectedOption, setSelectedOption] = useState<DataTypeOption>(
    DATA_TYPE_OPTION_CONFIG.content,
  );

  const handleSelect: QuicksightPageViewProps['onSelectDataType'] = (
    value: string,
    option: Option,
  ) => {
    setSelectedOption(option as DataTypeOption);
  };

  const viewProps: QuicksightPageViewProps = {
    breadcrumbList: data?.breadcrumbList,
    dataTypeOptions: DATA_TYPE_OPTIONS,
    dataTypes: guid ? data?.dataTypes : CONTAINER_CONFIG?.dataTypes,
    dsGuid: dsGuid || data?.dataSource.guid,
    filterDataType: selectedOption.key,
    guid,
    key: location.pathname,
    onSelectDataType: handleSelect,
    selectDataType: selectedOption?.value,
    title: guid ? data?.name : CONTAINER_CONFIG?.title,
  };

  if ((!dsGuid && !guid) || isError) {
    return <NotFoundError />;
  }

  if (isLoading) {
    return <CircularLoader centered cover />;
  }

  if (selectedOption.value === DATA_TYPE_OPTION_CONFIG.bidataset.value) {
    return <DatasetPage countItemsConfig={COUNT_ITEMS_CONFIG.bidataset} {...viewProps} />;
  }

  return (
    <DashboardsPage
      countItemsConfig={COUNT_ITEMS_CONFIG[selectedOption.value]}
      visibleColumns={DASHBOARD_VISIBLE_COLUMNS}
      {...viewProps}
    />
  );
};

export default QuickSightListPage;
