import { NO_LINK } from '@configs/urls/config';

import { MetadataModel } from '@models/MetadataModel';
import { urlFor } from '@utils/routing';

export const getObjectRouteUrl = (data?: MetadataModel) => {
  const { dataTypes, isHidden, routePath } = data ?? {};

  const isImplicit = dataTypes?.dataType === 'implicit';

  if (
    !data ||
    isImplicit ||
    isHidden ||
    ['columngroup', 'tablegroup'].includes(dataTypes?.objectType!)
  ) {
    return '';
  }

  if (routePath && routePath !== NO_LINK) {
    return routePath;
  }

  const url = urlFor(data) ?? '';
  return url === NO_LINK ? '' : url;
};
