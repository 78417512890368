import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from '@routing/router';

import PageHeaderBar from '@components/PageHeaderBar';
import TabsRouter from '@components/TabsRouter';
import useNewLayout from '@hooks/useNewLayout';
import MetadataDecorator from '@utils/MetadataDecorator';

import BaseDocsTab from './BaseDocsTab';
import { StyledGridContainer } from './DocumentsPage.styles';
import GlossaryTab from './GlossaryTab/GlossaryTab';

const DOCUMENTS_TITLE = {
  '/all': 'All Docs',
  '/documents': 'Pages',
  '/glossary': 'Business Glossary',
  '/metrics': 'Metrics',
};

const DocumentsPage: React.FC = () => {
  const { shouldUseNewLayout } = useNewLayout();
  const { path, url } = useRouteMatch();

  const tabsRouterConfig = () => {
    const tabs = [
      {
        // eslint-disable-next-line react/no-unstable-nested-components
        component: () => <BaseDocsTab />,
        default: true,
        label: 'All',
        route: '/all',
      },
      {
        // eslint-disable-next-line react/no-unstable-nested-components
        component: () => <BaseDocsTab variant="document" />,
        label: 'Pages',
        route: '/documents',
      },
      {
        // eslint-disable-next-line react/no-unstable-nested-components
        component: () => <BaseDocsTab variant="metric" />,
        label: 'Metrics',
        route: '/metrics',
      },
      {
        // eslint-disable-next-line react/no-unstable-nested-components
        component: () => <GlossaryTab />,
        label: 'Business Glossary',
        route: '/glossary',
      },
    ];

    return tabs;
  };

  const config = tabsRouterConfig();

  return (
    <>
      {!shouldUseNewLayout && <MetadataDecorator title="All Docs" />}
      <StyledGridContainer>
        {!shouldUseNewLayout && <PageHeaderBar title="All Docs" />}
        {shouldUseNewLayout ? (
          /*
           * NOTE: move the routes to the Routes.shared.tsx file once removing the shouldUseNewLayout flag
           */
          <Switch>
            {config.map((item) => (
              <Route key={`${url}${item.route}`} path={`${path}${item?.route}`}>
                <MetadataDecorator
                  title={DOCUMENTS_TITLE[item?.route as keyof typeof DOCUMENTS_TITLE]}
                />
                <PageHeaderBar
                  title={DOCUMENTS_TITLE[item?.route as keyof typeof DOCUMENTS_TITLE]}
                  titleFontSize="h4"
                  titleFontWeight="medium"
                />
                {item.component()}
              </Route>
            ))}
            {shouldUseNewLayout && <Redirect to={`${url}/all`} />}
          </Switch>
        ) : (
          <TabsRouter config={config} sticky />
        )}
      </StyledGridContainer>
    </>
  );
};

export default DocumentsPage;
