import paginatedTransform from '@api/paginatedTransform';
import { QueryModel } from '@api/queries/QueryModel';
import { ReportQueryModel } from '@api/queries/ReportQueryModel';
import rawTransform from '@api/rawTransform';
import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import { PaginatedResponse } from '@models/PaginatedResponse';

import * as cacheKeys from './cacheKeys';

const fetchBiQueriesSelect = paginatedTransform(ReportQueryModel);
export const useFetchBiQueries = (
  options?: UseFetchOptions<PaginatedResponse<ReportQueryModel>>,
) => {
  return useFetch<PaginatedResponse<ReportQueryModel>>({
    ...options,
    queryKey: [...cacheKeys.all, options?.params],
    select: fetchBiQueriesSelect,
    url: '/bi/queries/',
  });
};

export const fetchQueriesSelect = paginatedTransform(QueryModel);
export const useFetchQueries = (options?: UseFetchOptions<PaginatedResponse<QueryModel>>) => {
  return useFetch<PaginatedResponse<QueryModel>>({
    ...options,
    queryKey: [...cacheKeys.all, options?.params],
    select: fetchQueriesSelect,
    url: '/queries/',
  });
};

const fetchQuerySelect = rawTransform(QueryModel);
export const useFetchQuery = (id: string, options?: UseFetchOptions<QueryModel>) => {
  return useFetch<QueryModel>({
    ...options,
    queryKey: [...cacheKeys.query(id), options?.params],
    select: fetchQuerySelect,
    url: `/queries/${id}/`,
  });
};
