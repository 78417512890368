import React, { useMemo, useState } from 'react';
import { useHistory, useParams } from '@routing/router';
import { Cell } from 'react-table';

import { BiViewModel } from '@api/views/BiViewModel';
import Box from '@components/Box';
import CopyFullPathButton from '@components/CopyFullPathButton';
import DescriptionCell from '@components/Table/Cells/DescriptionCell';
import EditableTaggedItemCell from '@components/Table/Cells/EditableTaggedItemCell';
import LinkedCell from '@components/Table/Cells/LinkedCell';
import PopularityCell from '@components/Table/Cells/PopularityCell';
import PopularityCellHeader from '@components/Table/Cells/PopularityCell/PopularityCellHeader';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import DeletedLabel from '@components/Table/DeletedLabel';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import { CellWithButtons } from '@components/Table/TableStyled/TableStyled';
import { useUserContext } from '@context/User';
import useCustomAttributesColumns from '@hooks/useCustomAttributesColumns';
import BIColumnPage from '@pages/BIColumnPage';
import { getPopularityNormalized } from '@utils/popularity';

interface TableauViewTableProps {
  data?: BiViewModel[];
  isDataSourceEditable: boolean;
}

const TableauViewTable: React.FC<TableauViewTableProps> = ({ data, isDataSourceEditable }) => {
  const history = useHistory();
  const { organization } = useUserContext();
  const { guid, itemId } = useParams<{ guid: string; itemId: string }>();
  const [isShowFilter, setShowFilter] = useState(false);
  const [currView, setCurrView] = useState<string | undefined>(itemId);
  const customAttributesAssetType = `tableau,tableauview,`;

  const { customAttributesColumns } = useCustomAttributesColumns({
    assetType: customAttributesAssetType,
    isDataSourceEditable,
    tableData: data ?? [],
  });

  const columns: ColumnConfig<BiViewModel>[] = useMemo(
    () => [
      {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      {
        Cell: (props: Cell<BiViewModel>) => {
          const { row } = props;
          const item = row.original;
          return (
            <CellWithButtons
              alignItems="center"
              compDisplay="flex"
              flexWrap="wrap"
              onClick={() => {
                setCurrView(item.guid);
              }}
            >
              <Box alignItems="center" compDisplay="flex">
                <LinkedCell {...props} item={item} showDataTypeTooltip showIcon />
                {item.fullName && (
                  <CopyFullPathButton
                    className="inline-button"
                    iconSize="14px"
                    text={item.fullName}
                  />
                )}
              </Box>
              {item.deactivationScheduledOn && <DeletedLabel />}
            </CellWithButtons>
          );
        },
        Header: `View (${data?.length || 0})`,
        accessor: (d) => d.name,
        disableHiding: true,
        id: 'name',
        width: '130%',
      },
      {
        Cell: (props: Cell<BiViewModel>) => {
          const { row } = props;
          const item = row.original;
          return (
            <DescriptionCell
              {...props}
              aiDescription={item.aiDescription}
              dataSourceType={item.dataTypes?.dataSourceType}
              description={item.description}
              descriptionSource={item.descriptionSource}
              guid={item.guid}
              ingestedDescription={item.ingestedDescription}
              isDataSourceEditable={isDataSourceEditable}
              name={item.name}
              richtextDescription={item.richtextDescription}
              truncateDisabled={!organization?.settings?.useShowMoreButton}
              userDescription={item.userDescription}
            />
          );
        },
        Header: 'Description',
        accessor: (d) => d.description,
        id: 'description',
        width: '150%',
      },
      ...customAttributesColumns,
      {
        Cell: (props: Cell<BiViewModel>) => {
          const { row } = props;
          const view = row.original;
          return (
            <EditableTaggedItemCell
              {...props}
              isDataSourceEditable={isDataSourceEditable}
              obj={view}
            />
          );
        },
        Header: 'Tags',
        accessor: (d) => d.taggedItems,
        disableSortBy: true,
        id: 'tags',
        width: '120%',
      },
      {
        Cell: (props: Cell<BiViewModel>) => {
          const { row } = props;
          const view = row.original;
          return <PopularityCell {...props} isTableauView popularity={view.popularity} />;
        },
        Header: PopularityCellHeader,
        accessor: (d) => getPopularityNormalized(d?.popularity?.popularity),
        disableFilters: true,
        disableResizing: true,
        dropdownCheckboxLabel: 'Popularity',
        id: 'popularity',
        sortDescFirst: true,
        width: 120,
      },
    ],
    [
      data?.length,
      customAttributesColumns,
      isDataSourceEditable,
      organization?.settings?.useShowMoreButton,
    ],
  );

  const getRowId = React.useCallback((row: Partial<BiViewModel>) => row.guid!, []);

  return (
    <>
      <TableStyled>
        <Table
          basic="very"
          className="table-full"
          columns={columns}
          compact
          data={data || []}
          disablePagination
          disableRowSelect
          getRowId={getRowId}
          initialState={{
            sortBy: [
              {
                desc: true,
                id: 'popularity',
              },
            ],
          }}
          loading={data === undefined}
          selectable
          showFilter={isShowFilter}
          singleLine
          sortable
          toggleFilter={() => setShowFilter((prev) => !prev)}
          unstackable
        />
      </TableStyled>
      {currView && (
        <BIColumnPage
          guid={currView}
          isDataSourceEditable={isDataSourceEditable}
          onClose={() => {
            history.push(`/dashboards/${guid}/views`);
            setCurrView(undefined);
          }}
        />
      )}
    </>
  );
};

export default TableauViewTable;
