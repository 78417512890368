import React from 'react';

import CreateDocMenu from '@components/AppMainSidebar/Hierarchy/DocumentsHierarchy/CreateDocMenu';
import Box from '@components/Box';
import EmptyContent from '@components/EmptyContent';
import Link from '@components/Link';
import Text from '@components/Text';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import useNewLayout from '@hooks/useNewLayout';
import theme from '@styles/theme';

import NoContentMessage from '../NoContentMessage';

import { StyledNoDocumentMessage } from './NoDocumentMessage.styles';

interface NoDocumentMessageProps {
  customCreateNewDocument?: React.ReactNode;
}
const NoDocumentMessage: React.FC<NoDocumentMessageProps> = ({ customCreateNewDocument }) => {
  const { isEditable } = useObjectPermissionsContext({ id: 'all-docs' });
  const { shouldUseNewLayout } = useNewLayout();

  return (
    <StyledNoDocumentMessage
      alignItems="center"
      compDisplay="flex"
      compWidth="500px"
      flexDirection="column"
      justifyContent="start"
    >
      {shouldUseNewLayout ? (
        <EmptyContent mb={1} message="There are no items here" />
      ) : (
        <NoContentMessage />
      )}
      {shouldUseNewLayout && isEditable && (
        <Text color="gray.400" fontSize="h4" fontWeight="medium">
          Start adding your data documentation
        </Text>
      )}
      {isEditable && (
        <Box compWidth={theme.space(24.5)}>
          {customCreateNewDocument ?? <CreateDocMenu useNewLayout={shouldUseNewLayout} />}
        </Box>
      )}
      {shouldUseNewLayout && isEditable && (
        <Box
          alignItems="center"
          backgroundColor="primary.50"
          borderRadius="md"
          compDisplay="flex"
          flexDirection="column"
          mt={5}
          px={7.5}
          py={2}
        >
          <Text color="gray.600" fontSize="h4" fontWeight="medium" m={0}>
            💡 New to creating docs in Select Star?
          </Text>
          <Link
            color="gray.600"
            fontSize="h4"
            fontWeight="medium"
            href="https://docs.selectstar.com/features/documents"
            rel="noopener noreferrer"
            target="_blank"
            underline
          >
            Learn more what you can do
          </Link>
        </Box>
      )}
    </StyledNoDocumentMessage>
  );
};

export default NoDocumentMessage;
