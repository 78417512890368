import { Expose, Transform } from 'class-transformer';
import moment from 'moment';

import { getPopularityNormalized } from '@utils/popularity';

export interface PopularityData {
  endsOn: moment.Moment;
  popularity?: number;
  queryCount?: number;
  timeframe?: moment.Moment;
  userCount: number;
  viewCount?: number;
}

export class PopularityModel {
  static objectType: string = 'popularity';

  objectType: string = PopularityModel.objectType;

  static typeDisplay: string = 'Popularity';

  typeDisplay: string = PopularityModel.typeDisplay;

  @Transform((value) => (value ? moment(value) : value))
  timeframe?: moment.Moment;

  @Expose({ name: 'query_count' })
  queryCount?: number;

  /*
   * To be deprecated in favor of query count
   * Once Dashboards move to query count
   */
  @Expose({ name: 'view_count' })
  viewCount?: number;

  /**
   * Number of queries/views done by service accounts
   */
  @Expose({ name: 'service_query_count' })
  serviceQueryCount!: number;

  @Expose({ name: 'user_count' })
  userCount?: number;

  @Expose({ name: 'user_query_count' })
  userQueryCount?: number;

  popularity?: number;

  @Expose({ name: 'ends_on' })
  @Transform((value) => moment(value))
  endsOn?: moment.Moment;

  /**
   * Total number of queries/views executed by users and service accounts.
   */
  get count() {
    const queryOrViewCount = this.queryCount ?? this.viewCount ?? this.userQueryCount ?? 0;

    return queryOrViewCount + this.serviceQueryCount;
  }

  get formattedPopularity() {
    return getPopularityNormalized(this.popularity);
  }
}
