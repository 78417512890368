import React from 'react';

import LinkedTags from './LinkedTags';

interface LinkedFromProps {
  guid: string;
}

const LinkedFrom: React.FC<LinkedFromProps> = (props) => {
  const { guid } = props;

  if (guid.startsWith('tg_')) {
    return <LinkedTags {...props} type="linkedFrom" />;
  }

  return null;
};

export default LinkedFrom;
