import { Expose, Transform, Type } from 'class-transformer';
import moment from 'moment';

import { DataSourceModel } from '@models/DataSourceModel';

class IngestionLogModel {
  static objectType: string = 'ingestionlog';

  objectType: string = IngestionLogModel.objectType;

  static typeDisplay: string = 'Ingestion Log';

  typeDisplay: string = IngestionLogModel.typeDisplay;

  @Expose({ name: 'data_source' })
  @Type(() => DataSourceModel)
  dataSource!: DataSourceModel;

  @Expose({ name: 'created_on' })
  @Transform((value) => (value ? moment(value) : value))
  createdOn!: moment.Moment;

  @Expose({ name: 'job_type' })
  jobType?: string;
}
export default IngestionLogModel;
