import React, { useEffect, useRef } from 'react';
import { Ref, Table, TableRowProps } from 'semantic-ui-react';

interface Props extends TableRowProps {
  highlight?: boolean;
}

const TableRowWithHighlight: React.FC<Props> = ({ children, className, highlight, role }) => {
  const rowRef = useRef<HTMLDivElement>(null);
  // If column is highlighted, scroll to it.
  useEffect(() => {
    if (highlight && rowRef.current) {
      /*
       * We need to offset where we scroll to from the top of the page because tabs/header area will be blocking the column
       * if scrolled to the very top.
       */
      const topOffset = 280;
      const top = rowRef.current.getBoundingClientRect().top - topOffset;
      window.scrollTo({ behavior: 'smooth', top });
    }
  }, [highlight, rowRef]);

  return (
    <Ref innerRef={rowRef}>
      <Table.Row
        className={highlight ? `${className ?? ''} current-selected` : className ?? ''}
        role={role}
      >
        {children}
      </Table.Row>
    </Ref>
  );
};

export default TableRowWithHighlight;
