import { plainToClass } from 'class-transformer';
import { isEqual, uniqWith } from 'lodash';
import moment from 'moment';
import type { Formatter } from 'recharts/types/component/DefaultTooltipContent';

import TimeModel from '@api/costAnalysis/TimeModel';
import getIntervals from '@utils/getIntervals';

import type { Name, Value } from '../types';

export const formatValue: Formatter<Value, Name> = (value, name, item) => {
  if (!item.payload.totalCreditsUsed) return [];
  const hour = moment.utc().hour(item.payload.aggregation.hour);
  const weekName = moment
    .utc()
    .weekday(item.payload.aggregation.dayOfWeek - 1)
    .format('ddd');
  return [
    [
      {
        name: '',
        value: `${weekName}  ${hour.format('H')}:00 - ${hour.add(1, 'hour').format('H')}:00`,
      },
      { name: 'Avg Credit: ', value: item.payload.formattedTotalCreditsUsed },
      { name: 'Query Count: ', value: item.payload.formattedTotalRun },
      { name: 'Active Users: ', value: item.payload.formattedActiveUser },
    ],
    '',
  ];
};

const generateEmptyTimeDataObject = (dayOfWeek: number) => {
  const data: Record<number, TimeModel> = {};
  Array.from({ length: 24 }).forEach((_, id) => {
    data[id] = plainToClass(TimeModel, {
      active_user: 0,
      aggregation: {
        day_of_week: dayOfWeek,
        hour: id,
      },
      total_credits_used: 0,
      total_run: 0,
    });
  });

  return data;
};

export const mapToWeekly = (data: TimeModel[] | undefined) => {
  const weeklyData = Array.from({ length: 7 }).map((_, id) => ({
    data: generateEmptyTimeDataObject(id),
    day: id,
  }));

  const credits = new Set<number>();

  data?.forEach((el) => {
    const dayOfWeek = el.aggregation?.dayOfWeek ?? 1;
    const hour = el.aggregation?.hour ?? 0;
    weeklyData[dayOfWeek - 1].data[hour] = el;
    credits.add(el.totalCreditsUsed);
  });

  const creditsValues = Array.from(credits);

  const ranges = uniqWith(
    getIntervals({
      count: 5,
      max: Math.max(...creditsValues),
      min: Math.min(...creditsValues),
    }),
    isEqual,
  );

  const flatWeeklyData = weeklyData.map((dayData) => ({
    ...dayData,
    data: Object.values(dayData.data),
  }));

  return {
    data: flatWeeklyData,
    ranges,
  };
};
