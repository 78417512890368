import getUrl from '@configs/urls/getUrl';
import { Expose, Type } from 'class-transformer';

import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import { DSUserData, DsUserModel } from '@api/dsusers/DsUserModel';
import { TaggedItemModel } from '@api/tags/TaggedItemModel';
import { breadcrumbsToLabelList, breadcrumbsToList } from '@components/Breadcrumbs';
import { DataSourceTypesType } from '@models/DataSourceCredentials';
import { DataSourceData, DataSourceModel } from '@models/DataSourceModel';
import DataTypesModel from '@models/DataTypesModel';
import { urlFor } from '@utils/routing';

export interface BIFolderData {
  dataSource: DataSourceData;
  description?: string;
  dsuserCreatedBy?: DSUserData;
  guid: string;
  name: string;
}

export class BIFolderModel {
  static objectType: string = 'bifolder';

  objectType: string = BIFolderModel.objectType;

  static typeDisplay: string = 'BIFolder';

  typeDisplay: string = BIFolderModel.typeDisplay;

  guid!: string;

  name!: string;

  description?: string;

  @Expose({ name: 'data_source' })
  @Type(() => DataSourceModel)
  dataSource!: DataSourceModel;

  @Expose({ name: 'dsuser_created_by' })
  @Type(() => DsUserModel)
  dsuserCreatedBy?: DsUserModel;

  @Expose({ name: 'tagged_items' })
  @Type(() => TaggedItemModel)
  taggedItems?: TaggedItemModel[];

  @Expose({ name: 'data_type' })
  dataType?: string;

  @Expose({ name: 'data_source_type' })
  dataSourceType?: DataSourceTypesType;

  @Expose({ name: 'search_name' })
  fullName?: string;

  @Expose({ name: 'data_types' })
  @Type(() => DataTypesModel)
  dataTypes?: DataTypesModel;

  @Type(() => BreadcrumbModel)
  breadcrumbs?: BreadcrumbModel[];

  get breadcrumbList() {
    return breadcrumbsToList(this.breadcrumbs);
  }

  get breadcrumbLabelList() {
    const url = urlFor(this, false, '');
    return breadcrumbsToLabelList(this.name, url, this.breadcrumbs);
  }

  get routePath() {
    return getUrl({ dataTypes: this.dataTypes!, guid: this.guid });
  }
}
