import {
  syncBackCacheKeys,
  usePatchSyncBackRepository,
  usePostSyncBackRepository,
} from '@api/syncBack';
import { RepositoryModel } from '@api/syncBack/RepositoryModel';
import fetchClient from '@lib/fetchClient';

interface UseMutateSyncBackRepositoryProps {
  repository?: RepositoryModel;
}
const useMutateSyncBackRepository = (props?: UseMutateSyncBackRepositoryProps) => {
  const { repository } = props || {};
  const isEditing = Boolean(repository);

  const handleSuccess = () => {
    fetchClient.invalidateQueries(syncBackCacheKeys.syncBack);
  };

  const {
    error: updateError,
    isLoading: updatingRepository,
    mutateAsync: updateRepository,
  } = usePatchSyncBackRepository(repository?.guid || '', {
    onSuccess: handleSuccess,
  });

  const {
    error: createError,
    isLoading: creatingRepository,
    mutateAsync: createRepository,
  } = usePostSyncBackRepository({
    onSuccess: handleSuccess,
  });

  return {
    error: isEditing ? updateError : createError,
    isLoading: updatingRepository || creatingRepository,
    mutate: isEditing ? updateRepository : createRepository,
  };
};

export default useMutateSyncBackRepository;
