import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import type { StyledBoxProps } from '@components/Box';
import { BoxStyles } from '@components/Box/Box.styles';
import type { MakeSpaceProps } from '@styles/mixins/makeSpace';
import makeSpace from '@styles/mixins/makeSpace';

import type { VariantColor, VariantSize } from './Button.styles.variants';
import { colorVariants, sizeVariants } from './Button.styles.variants';

export interface StyledButtonProps
  extends StyledBoxProps,
    Pick<
      React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
      'onClick' | 'disabled' | 'type' | 'name' | 'title'
    > {
  children?: React.ReactNode;
  compSize?: VariantSize;
  fluid?: boolean;
  href?: string;
  noHover?: boolean;
  rel?: string;
  target?: string;
  variant?: VariantColor;
}

export interface StyledButtonIconWrapperProps {
  iconCurrentColor?: boolean;
}

export const StyledButtonIconWrapper = styled.span<StyledButtonIconWrapperProps & MakeSpaceProps>`
  svg,
  path {
    display: block;
    fill: ${({ iconCurrentColor }) => (iconCurrentColor ? 'currentColor' : undefined)};
  }

  ${({ theme, ...props }) => makeSpace(theme, props)};
`;

const StyledButton = styled.button<StyledButtonProps>`
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  appearance: none;
  border: 1px solid transparent;
  box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
  cursor: ${({ disabled, noHover }) => (disabled || noHover ? 'auto' : 'pointer')};
  font-style: normal;
  justify-content: ${({ justifyContent = 'center' }) => justifyContent};
  line-height: 1;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  outline: 0 !important; // important can be removed once sc-16264 is done
  overflow: visible;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  user-select: none;
  vertical-align: middle;
  width: ${({ fluid }) => (fluid ? '100%' : undefined)};

  ${({ compSize = 'default' }) => sizeVariants[compSize]};

  ${({ color, disabled, noHover, variant = 'default' }) =>
    css`
      ${colorVariants[variant].default}
      &:hover {
        ${color && `color:${color}`};
        ${(!noHover || disabled) && !color && colorVariants[variant].hover};
      }
    `};

  ${BoxStyles};
`;

StyledButton.defaultProps = {
  compDisplay: 'inline-flex',
  fontFamily: 'primary',
};

export default StyledButton;
