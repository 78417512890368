import useMutation, { UseMutationOptions } from '@hooks/useMutation';
import { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';

export const usePostMask = (
  options?: UseMutationOptions<
    never,
    EnhancedErrorResult,
    {
      email: string;
    }
  >,
) => {
  return useMutation({
    ...options,
    method: 'POST',
    url: '/mask/',
  });
};
