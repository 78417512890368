import styled from '@emotion/styled';

import Box from '@components/Box';

import {
  StyledIntegrationNodeCaret,
  StyledIntegrationNodeCaretContainer,
  StyledIntegrationNodeCount,
} from '../../Common/IntegrationNode/IntegrationNode.styles';
import { SIZES } from '../config';
import { NODE_OPACITY } from '../TableNode/TableNode.styles';

interface StyledSchemaExploreNode {
  isUnfocused?: boolean;
}

export const StyledSchemaNode = styled(Box)<StyledSchemaExploreNode>`
  opacity: ${({ isUnfocused }) => (isUnfocused ? NODE_OPACITY.unfocused : NODE_OPACITY.default)};
  padding-right: ${SIZES.paddingRight.schema}px;
  padding-left: ${SIZES.paddingLeft.schema}px;
  padding-bottom: ${SIZES.paddingBottom.schema}px;
  padding-top: ${SIZES.paddingTop.schema}px;
  height: 100%;
`;

StyledSchemaNode.defaultProps = {
  backgroundColor: 'white',
  borderRadius: 'lg',
  compDisplay: 'flex',
  compWidth: `${SIZES.width.schema}px`,
  flexDirection: 'column',
  justifyContent: 'space-between',
};

export const StyledSchemaNodeTitle = styled(Box)``;

StyledSchemaNodeTitle.defaultProps = {
  color: 'gray.800',
  fontSize: 'sm',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

export const StyledSchemaNodeContent = styled(Box)``;

StyledSchemaNodeContent.defaultProps = {
  compDisplay: 'flex',
  compWidth: '100%',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  mt: 0.75,
};

export const StyledSchemaColumnCount = styled(StyledIntegrationNodeCount)``;

export const StyledSchemaNodeCaretContainer = styled(StyledIntegrationNodeCaretContainer)``;

export const StyledSchemaNodeCaret = styled(StyledIntegrationNodeCaret)``;
