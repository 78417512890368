import { Editor, Element, NodeEntry, Transforms } from 'slate';

const deleteTable = (editor: Editor, table?: NodeEntry) => {
  if (editor.selection) {
    Transforms.removeNodes(editor, {
      at: table ? table[1] : undefined,
      match: (n) => Element.isElement(n) && n.type === 'table',
    });
  }
};

export default deleteTable;
