import { ExploreEdge } from '@components/LineageExplore/LineageExplore.types';
import {
  EdgesById,
  NodesById,
} from '@components/LineageExplore/useCreateNodesEdges/algorithm/types';

import { getOccupiedColumnsByNode } from '../algorithm/algorithm.utils';

interface CreateRedirectedEdgesParams {
  edgesById: EdgesById;
  isShowMore?: boolean;
  nodesById: NodesById;
  redirectionTargetNodeId: string;
  targetOrSourceNodeKeys: Set<string>;
}

export const createRedirectedEdges = ({
  edgesById,
  isShowMore = false,
  nodesById,
  redirectionTargetNodeId,
  targetOrSourceNodeKeys,
}: CreateRedirectedEdgesParams) => {
  const edgesKeys = Object.keys(edgesById);
  const collapsedEdges: Record<string, ExploreEdge[]> = {};

  let newEdges: EdgesById = {};
  const targetOrSourceNodeKeysArray = Array.from(targetOrSourceNodeKeys);

  for (let i = 0; i < targetOrSourceNodeKeysArray.length; i += 1) {
    const targetOrSourceNodeKey = targetOrSourceNodeKeysArray[i];
    const relevantEdges = edgesKeys.filter((key) => key.includes(targetOrSourceNodeKey)); // Find edges that have the targetOrSource node as target or source; edges that are related to the hidden nodes
    const redirectedEdges: EdgesById = {};

    for (let j = 0; j < relevantEdges.length; j += 1) {
      const key = relevantEdges[j]; // edge id

      const inTarget = edgesById[key].target.includes(targetOrSourceNodeKey); // is the hidden node the target of the edge?
      let { source, target } = edgesById[key];

      let finalRedirectedTargetNodeKey = '';
      let finalRedirectedSourceNodeKey = '';

      /** Re-route target/source to the redirection target node. */
      if (inTarget) {
        finalRedirectedTargetNodeKey = redirectionTargetNodeId; // if the hidden node is the target, show more will now be the new target
      } else {
        finalRedirectedSourceNodeKey = redirectionTargetNodeId; // if the hidden node is the source, show more will now be the new source
      }

      // Do not redirect to the parent node if we are redirecting to a show more node
      if (!isShowMore) {
        // redirect to the parent if the target is inside a collapsed node
        const targetParents = (nodesById[target] ?? {})?.data?.parents;
        targetParents?.forEach((parentKey) => {
          if (!nodesById[parentKey]?.data?.isOpen) {
            finalRedirectedTargetNodeKey = parentKey; // if any of the target's parents is collapsed, the new target will be the parent
          }
        });

        // redirect to the parent if the source is inside a collapsed node
        const sourceParents = (nodesById[source] ?? {})?.data?.parents;
        sourceParents?.forEach((parentKey) => {
          if (!nodesById[parentKey]?.data?.isOpen) {
            finalRedirectedSourceNodeKey = parentKey; // if any of the source's parents is collapsed, the new source will be the parent
          }
        });
      }

      target = finalRedirectedTargetNodeKey || target;
      source = finalRedirectedSourceNodeKey || source;

      if (source === target) {
        continue;
      }

      let { sourceHandle, targetHandle } = edgesById[key];
      const { data } = edgesById[key];
      let { side, sourceTargetSameStack } = data ?? {};
      const targetNode = nodesById[target];
      const sourceNode = nodesById[source];

      const targetNodeOccupiedColumns = targetNode ? getOccupiedColumnsByNode(targetNode) : [];
      const sourceNodeOccupiedColumns = sourceNode ? getOccupiedColumnsByNode(sourceNode) : [];

      const nodesHaveOverlappingColumns = targetNodeOccupiedColumns.some((column) =>
        sourceNodeOccupiedColumns.includes(column),
      );

      if (
        nodesHaveOverlappingColumns &&
        (targetNodeOccupiedColumns.length > 1 || sourceNodeOccupiedColumns.length > 1)
      ) {
        const sourceEndStackedAt = Number(sourceNode?.endStackId ?? sourceNode?.stackedAt);
        const targetEndStackedAt = Number(targetNode?.endStackId ?? targetNode?.stackedAt);
        const sourceStartStackedAt = Number(sourceNode?.startStackId ?? sourceNode?.stackedAt);
        const targetStartStackedAt = Number(targetNode?.startStackId ?? targetNode?.stackedAt);

        if (
          sourceStartStackedAt === targetStartStackedAt &&
          sourceEndStackedAt !== targetEndStackedAt
        ) {
          sourceHandle = 'left';
          targetHandle = 'left';
          sourceTargetSameStack = true;
          side = 'left';
        } else if (
          sourceEndStackedAt === targetEndStackedAt ||
          sourceEndStackedAt >= targetStartStackedAt
        ) {
          sourceHandle = 'right';
          targetHandle = 'right';
          sourceTargetSameStack = sourceEndStackedAt === targetEndStackedAt;
          side = 'right';
        }
      }

      const redirectedEdgeId = `${source}-${target}`;

      collapsedEdges[redirectedEdgeId] = [
        ...(collapsedEdges?.[redirectedEdgeId] ?? []),
        edgesById[key],
      ];

      redirectedEdges[redirectedEdgeId] = {
        ...edgesById[key],
        data: {
          ...edgesById[key].data,
          collapsedEdges: collapsedEdges[redirectedEdgeId],
          isUnfocused: !collapsedEdges[redirectedEdgeId].some((edge) => !edge.data?.isUnfocused),
          side,
          sourceTargetSameStack,
        },
        id: redirectedEdgeId,
        source,
        sourceHandle,
        target,
        targetHandle,
      };
    }

    newEdges = {
      ...newEdges,
      ...redirectedEdges,
    };
  }
  return newEdges;
};

interface RedirectEdgesResult {
  edges: EdgesById;
  nodeId: string;
}

interface CreateRedirectedEdgesToShowMoreArgs {
  edgesById: EdgesById;
  inputNodeId: string;
  nodesById: NodesById;
  targetOrSourceNodeKeys: Set<string>;
}

export const createRedirectedEdgesToShowMore = ({
  edgesById,
  inputNodeId,
  nodesById,
  targetOrSourceNodeKeys,
}: CreateRedirectedEdgesToShowMoreArgs): RedirectEdgesResult => {
  const nodeId = `${inputNodeId}-showMore`;

  const finalTargetOrSourceNodeKeys: Array<string> = [];
  Array.from(targetOrSourceNodeKeys).forEach((key) => {
    const node = nodesById[key];
    if (!node.data.isOpen) {
      finalTargetOrSourceNodeKeys.push(key);
    }
    const children = node?.data?.children;

    finalTargetOrSourceNodeKeys.push(...Array.from(children ?? []));
  });

  const edges = createRedirectedEdges({
    edgesById,
    isShowMore: true,
    nodesById,
    redirectionTargetNodeId: nodeId,
    targetOrSourceNodeKeys: new Set(finalTargetOrSourceNodeKeys),
  });

  return { edges, nodeId };
};

interface CreateRedirectedEdgesToCollapsedNodeArgs {
  edgesById: EdgesById;
  inputNodeId: string;
  nodesById: NodesById;
}

export const createRedirectedEdgesToCollapsedNode = ({
  edgesById,
  inputNodeId,
  nodesById,
}: CreateRedirectedEdgesToCollapsedNodeArgs): RedirectEdgesResult => {
  const nodeId = inputNodeId;
  const targetOrSourceNodeKeys = Object.values(nodesById).reduce((acc, node) => {
    if (
      node.data.parents?.includes(inputNodeId) &&
      (node.type === 'table' || node.type === 'column') &&
      node.key
    ) {
      acc.add(node.key);
    }

    return acc;
  }, new Set<string>([]));

  const edges = createRedirectedEdges({
    edgesById,
    nodesById,
    redirectionTargetNodeId: inputNodeId,
    targetOrSourceNodeKeys,
  });

  return { edges, nodeId };
};
