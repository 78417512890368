import type { DataTypes } from '@configs/dataTypes/types';

import { ColumnModel } from '@api/columns/ColumnModel';
import type { UseFetchOptions } from '@hooks/useFetch';
import useFetch from '@hooks/useFetch';
import type { PaginatedResponse } from '@models/PaginatedResponse';

import paginatedTransform from '../paginatedTransform';

import * as cacheKeys from './cacheKeys';

export const fetchBiColumnsSelect = paginatedTransform(ColumnModel);
interface FetchBiColumnsParams {
  bi_tables?: string;
  dataSourceType: DataTypes['dataSourceType'];
  datasets?: string;
  order?: string;
}
/**
 * We want to use the more generic API used with QuickSight to continue BI integrations.
 * @todo Migrate once the new BI tools is finished testing
 * @deprecated
 */
export const useFetchBiColumns = (
  options: UseFetchOptions<PaginatedResponse<ColumnModel>, FetchBiColumnsParams>,
) => {
  const { dataSourceType, ...params } = options.params!;
  return useFetch<PaginatedResponse<ColumnModel>>({
    ...options,
    params,
    queryKey: [...cacheKeys.all, params],
    select: fetchBiColumnsSelect,
    url: `bi/${dataSourceType}/columns/`,
  });
};
