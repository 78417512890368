import React from 'react';

const ExploreTreeDefs = React.memo(() => {
  return (
    <defs>
      <marker
        id="startingTableLinkArrow"
        markerHeight="15"
        markerUnits="userSpaceOnUse"
        markerWidth="15"
        orient="auto"
        refX="0"
        refY="3"
      >
        <path d="M 0 0 L 5 3 L 0 5.5 z" />
      </marker>
      <marker
        id="manualLineageTableLinkArrow"
        markerHeight="15"
        markerUnits="userSpaceOnUse"
        markerWidth="15"
        orient="auto"
        refX="0"
        refY="3"
      >
        <path d="M 0 0 L 5 3 L 0 5.5 z" />
      </marker>
      <marker
        id="pinnedTableLinkArrow"
        markerHeight="15"
        markerUnits="userSpaceOnUse"
        markerWidth="15"
        orient="auto"
        refX="0"
        refY="3"
      >
        <path d="M 0 0 L 5 3 L 0 5.5 z" />
      </marker>
      <marker
        id="hoveredTableLinkArrow"
        markerHeight="15"
        markerUnits="userSpaceOnUse"
        markerWidth="15"
        orient="auto"
        refX="0"
        refY="3"
      >
        <path d="M 0 0 L 5 3 L 0 5.5 z" />
      </marker>
      <marker
        id="toBeRemovedTableLinkArrow"
        markerHeight="15"
        markerUnits="userSpaceOnUse"
        markerWidth="15"
        orient="auto"
        refX="0"
        refY="3"
      >
        <path d="M 0 0 L 5 3 L 0 5.5 z" />
      </marker>
      <marker
        id="hoveredColumnLinkArrow"
        markerHeight="15"
        markerUnits="userSpaceOnUse"
        markerWidth="15"
        orient="auto"
        refX="8.5"
        refY="3"
      >
        <path d="M 0 0 L 5 3 L 0 5.5 z" />
      </marker>
      <marker
        id="manualhoveredColumnLinkArrow"
        markerHeight="15"
        markerUnits="userSpaceOnUse"
        markerWidth="15"
        orient="auto"
        refX="8.5"
        refY="3"
      >
        <path d="M 0 0 L 5 3 L 0 5.5 z" />
      </marker>
      <marker
        id="pinnedColumnLinkArrow"
        markerHeight="15"
        markerUnits="userSpaceOnUse"
        markerWidth="15"
        orient="auto"
        refX="8.5"
        refY="3"
      >
        <path d="M 0 0 L 5 3 L 0 5.5 z" />
      </marker>
      <marker
        id="manualpinnedColumnLinkArrow"
        markerHeight="15"
        markerUnits="userSpaceOnUse"
        markerWidth="15"
        orient="auto"
        refX="8.5"
        refY="3"
      >
        <path d="M 0 0 L 5 3 L 0 5.5 z" />
      </marker>
      <marker
        id="circle"
        markerHeight="10"
        markerUnits="userSpaceOnUse"
        markerWidth="10"
        orient="auto"
        refX="5"
        refY="5"
      >
        <circle cx="5" cy="5" r="3" />
      </marker>
    </defs>
  );
});

export default ExploreTreeDefs;
