import React, { useMemo, useState } from 'react';
import { Route, Switch, useRouteMatch } from '@routing/router';
import { Cell } from 'react-table';

import { ColumnModel } from '@api/columns/ColumnModel';
import Highlighter from '@components/Highlighter';
import ColumnNameTableCell from '@components/TabContent/ColumnsTab/ColumnTable/ColumnNameTableCell';
import DescriptionCell from '@components/Table/Cells/DescriptionCell';
import EditableTaggedItemCell from '@components/Table/Cells/EditableTaggedItemCell';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import TextCell from '@components/Table/Cells/TextCell';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import { useUserContext } from '@context/User';
import BIColumnPageRouted from '@pages/BIColumnPageRouted';
import { Filter } from '@utils';
import type { FilterOptions } from '@utils/filters';

enum ColumnKey {
  calculation = 'calculation',
  description = 'description',
  name = 'name',
  search = 'search',
  tableName = 'tableName',
  tags = 'tags',
}

const BI_COLUMNS_TABLE_DEFAULT_VISIBLE_COLUMNS = [
  ColumnKey.search,
  ColumnKey.tableName,
  ColumnKey.name,
  ColumnKey.description,
  ColumnKey.tags,
];

export const BI_COLUMNS_TABLE_SEARCH_CONFIG: PartialRecord<ColumnKey, keyof FilterOptions> = {
  description: 'search_description',
  name: 'search_name',
  tableName: 'search_table_name',
};

export const BI_COLUMNS_TABLE_SORT_CONFIG: PartialRecord<ColumnKey, string> = {
  description: 'description',
  name: 'name',
  tableName: 'table_name',
};

export interface BiColumnsTableProps {
  data?: {
    count: number;
    results: ColumnModel[];
  };
  filterService: Filter.FilterServiceInterface;
  isDataSourceEditable: boolean;
  isLoading?: boolean;
  visibleColumns?: Array<keyof typeof ColumnKey>;
}
const BiColumnsTable: React.FC<BiColumnsTableProps> = ({
  data,
  filterService,
  isDataSourceEditable,
  isLoading,
  visibleColumns = BI_COLUMNS_TABLE_DEFAULT_VISIBLE_COLUMNS,
}) => {
  const { organization } = useUserContext();
  const { path } = useRouteMatch();
  const [isShowFilter, setShowFilter] = useState(false);
  const { changePage, filter, initialTableSortState, search, sort } = filterService;

  const columns: ColumnConfig<ColumnModel>[] = useMemo(() => {
    const all: Record<ColumnKey, ColumnConfig<ColumnModel>> = {
      calculation: {
        Cell: ({ row: { original } }: Cell<ColumnModel>) => (
          <TextCell text={original?.calculation} tooltip={original?.calculation} />
        ),
        Header: 'Calculation',
        accessor: (d) => d.calculation,
        disableFilters: true,
        disableSortBy: true,
        id: 'calculation',
        width: '150%',
      },
      description: {
        Cell: ({ column, row: { original }, state }: Cell<ColumnModel>) => (
          <DescriptionCell
            {...original}
            column={column}
            dataSourceType={original.dataTypes?.dataSourceType}
            isDataSourceEditable={isDataSourceEditable}
            state={state}
            suggestedDescriptionSourceObj={original.suggestedDescriptionSourceObject?.obj}
            truncateDisabled={!organization?.settings?.useShowMoreButton}
          />
        ),
        Header: 'Description',
        accessor: (d) => d.description,
        id: 'description',
        width: '150%',
      },
      name: {
        Cell: ({ column, row: { original }, state }: Cell<ColumnModel>) => {
          return (
            <ColumnNameTableCell
              column={column}
              columnItem={original}
              customUrl={original.routePath}
              isDataSourceEditable={isDataSourceEditable}
              state={state}
            />
          );
        },
        Header: `Fields (${data?.count ?? 0})`,
        accessor: (d) => d.name,
        disableHiding: true,
        id: 'name',
        width: '130%',
      },
      search: {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      tableName: {
        Cell: ({ column, row: { original } }: Cell<ColumnModel>) => (
          <Highlighter
            autoEscape
            searchWords={[column?.filterValue]}
            textToHighlight={original.tableName ?? ''}
          >
            {original.tableName}
          </Highlighter>
        ),
        Header: 'Table',
        accessor: (d) => d.tableName,
        id: 'tableName',
        width: '110%',
      },
      tags: {
        Cell: ({ row: { original } }: Cell<ColumnModel>) => (
          <EditableTaggedItemCell
            hideCategoryTags
            isDataSourceEditable={isDataSourceEditable}
            obj={original}
          />
        ),
        Header: 'Tags',
        accessor: (d) => d.formattedTaggedItems,
        disableFilters: true,
        disableSortBy: true,
        id: 'tags',
        width: '110%',
      },
    };

    return visibleColumns.map((col) => all[col]);
  }, [data?.count, isDataSourceEditable, organization?.settings, visibleColumns]);

  const totalPages = data && filter.page_size ? Math.ceil(data.count / filter.page_size) : 1;

  return (
    <>
      <TableStyled>
        <Table
          aria-label="Fields table"
          basic="very"
          changePage={changePage}
          columns={columns}
          compact
          data={data?.results ?? []}
          disableRowSelect
          initialState={{
            pageIndex: filter.page ? filter.page - 1 : 0,
            sortBy: initialTableSortState,
          }}
          loading={isLoading}
          manualFilters
          manualPagination
          manualSortBy
          setFilters={search}
          setSortBy={sort}
          showFilter={isShowFilter}
          singleLine
          sortable
          stickyHeader
          toggleFilter={() => setShowFilter((prev) => !prev)}
          totalPages={totalPages}
          unstackable
        />
      </TableStyled>
      <Switch>
        <Route path={`${path}/:columnGuid`}>
          <BIColumnPageRouted isDataSourceEditable={isDataSourceEditable} />
        </Route>
      </Switch>
    </>
  );
};

export default React.memo(BiColumnsTable);
