import { Expose, Transform } from 'class-transformer';
import moment from 'moment';

export class RecentSearchModel {
  @Expose({ name: 'search_term' })
  searchTerm!: string;

  @Expose({ name: 'event_on' })
  @Transform((value) => moment(value))
  completedAt!: moment.Moment;
}

export default RecentSearchModel;
