import { useMemo } from 'react';

import { useFetchCustomAttributes } from '@api/customAttributes';
import { useFetchCustomAttributeValues } from '@api/customAttributeValues';

import buildCustomAttributesColumns from './buildCustomAttributesColumns';

interface TableData {
  guid: string;
  isEditable?: boolean;
}

interface UseCustomAttributesColumnsProps<T> {
  assetType: string;
  isDataSourceEditable: boolean;
  tableData: T[];
}

const useCustomAttributesColumns = <T extends TableData>({
  assetType,
  isDataSourceEditable,
  tableData,
}: UseCustomAttributesColumnsProps<T>) => {
  const { data: customAttributesResponse } = useFetchCustomAttributes({
    params: {
      asset_type: assetType,
    },
  });

  const { data: customAttributesValuesResponse } = useFetchCustomAttributeValues({
    params: {
      asset_type: assetType,
      items: tableData?.map((item) => item.guid).join(','),
    },
  });

  const customAttributesColumns = useMemo(
    () =>
      buildCustomAttributesColumns({
        customAttributes: customAttributesResponse?.results || [],
        customAttributesValues: customAttributesValuesResponse?.results || [],
        isDataSourceEditable,
      }),
    [
      isDataSourceEditable,
      customAttributesResponse?.results,
      customAttributesValuesResponse?.results,
    ],
  );

  return { customAttributesColumns };
};

export default useCustomAttributesColumns;
