import React from 'react';
import { RouterLink } from '@routing/router';

import { UserModel } from '@api/user/UserModel';
import Avatar from '@components/UI/Avatar';
import Icon from '@components/UI/Icon';
import { urlFor } from '@utils/routing';

import Box from '../Box';
import Tooltip from '../Tooltip';

import { StyledActivityMessageTargetLink } from './ActivityMessage.styles';
import TargetObjectName from './TargetObjectName';
import { ParsedComponentProps } from './types';

interface UserObjectProps extends ParsedComponentProps {
  obj: UserModel;
}

export const UserComponent = ({ config, obj }: UserObjectProps) => {
  if (!obj) return null;

  const { globalFilter, hideUserAvatar = false } = config ?? {};
  const { fullName, mappedAvatar } = obj;
  const targetUrl = urlFor(obj);

  return (
    <Tooltip content={fullName}>
      <StyledActivityMessageTargetLink as={RouterLink} to={targetUrl}>
        {!hideUserAvatar && (
          <Box as="span" compDisplay="inline-flex" mr={0.75}>
            <Avatar verticalAlign="middle" {...mappedAvatar} size="1.25rem" />
          </Box>
        )}
        <TargetObjectName defaultSeparator=" " globalFilter={globalFilter} text={fullName} />
      </StyledActivityMessageTargetLink>
    </Tooltip>
  );
};

export const TargetComponent = ({ config, obj }: ParsedComponentProps) => {
  if (!obj) return null;

  const { globalFilter } = config ?? {};
  const { dataTypes, guid, name, objectType, parentGuid, rawName } = obj;
  const item = {
    dataTypes,
    guid,
    name,
    objectType,
    parentGuid,
  };

  const targetUrl = urlFor(item);

  return (
    <Tooltip content={name}>
      <StyledActivityMessageTargetLink as={RouterLink} to={targetUrl}>
        <TargetObjectName
          globalFilter={globalFilter}
          icon={
            <Icon
              compDisplay="inline"
              mr={0.5}
              name={dataTypes?.icons?.dataType!}
              size="13px"
              verticalAlign="middle"
            />
          }
          text={name || rawName}
        />
      </StyledActivityMessageTargetLink>
    </Tooltip>
  );
};
