// Adapted from the Axios library
import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { isArray, isDate, isObject } from 'lodash';

import { AxiosErrorCodes } from './types';
import {
  encode,
  isAbsoluteURL,
  isFormData,
  isStandardBrowserEnv,
  isURLSearchParams,
} from './utils';

export const buildFullPath = (baseURL: string, requestedURL: string) => {
  if (baseURL && !isAbsoluteURL(requestedURL ?? '')) {
    return requestedURL
      ? `${baseURL.replace(/\/$/, '')}/${requestedURL.replace(/^\//, '')}`
      : baseURL;
  }
  return requestedURL;
};

export const enhanceError = (
  error: Error,
  config: InternalAxiosRequestConfig,
  code?: AxiosErrorCodes,
  request?: Request,
  response?: AxiosResponse,
) => {
  const enhancedError = { ...error } as Record<string, any>;

  enhancedError.config = config;
  if (code) {
    enhancedError.code = code;
  }

  enhancedError.request = request;
  enhancedError.response = response;
  enhancedError.isAxiosError = true;

  enhancedError.toJSON = function toJSON() {
    return {
      code: this.code,
      columnNumber: this.columnNumber,
      // Axios
      config: this.config,
      // Microsoft
      description: this.description,
      // Mozilla
      fileName: this.fileName,
      lineNumber: this.lineNumber,
      // Standard
      message: this.message,
      name: this.name,
      number: this.number,
      stack: this.stack,
      status: this?.response?.status ? this.response.status : null,
    };
  };
  return enhancedError;
};

export const createError = (
  message: string,
  config: InternalAxiosRequestConfig,
  code?: AxiosErrorCodes,
  request?: Request,
  response?: AxiosResponse,
) => {
  if (AxiosError && typeof AxiosError === 'function') {
    return new AxiosError(message, code, config, request, response);
  }

  const error = new Error(message);
  return enhanceError(error, config, code, request, response);
};

type Settle = (
  resolve: (value: unknown) => void,
  reject: (reason?: any) => void,
  response: any,
) => void;

export const settle: Settle = (resolve, reject, response) => {
  const { validateStatus } = response.config;
  if (!response.status || !validateStatus || validateStatus(response.status)) {
    resolve(response);
  } else {
    reject(
      createError(
        `Request failed with status code ${response.status}`,
        response.config,
        undefined,
        response.request,
        response,
      ),
    );
  }
};

type BuildURL = (url: string, params?: Record<string, any>, paramsSerializer?: any) => string;

export const buildURL: BuildURL = (url, params, paramsSerializer) => {
  if (!params) {
    return url;
  }

  let builtUrl = url;

  let serializedParams;
  if (paramsSerializer) {
    serializedParams = paramsSerializer(params);
  } else if (isURLSearchParams(params)) {
    serializedParams = (params as URLSearchParams).toString();
  } else {
    const parts: Array<string> = [];

    Object.entries(params).forEach(([key, value]) => {
      if (value === null || typeof value === 'undefined') {
        return;
      }

      const isArrayValue = isArray(value);

      const paramKey = isArrayValue ? `${key}[]` : key;
      const paramValue = isArrayValue ? value : [value];

      paramValue.forEach((v) => {
        let parsedValue = v;
        if (isDate(v)) {
          parsedValue = v.toISOString();
        } else if (isObject(v)) {
          parsedValue = JSON.stringify(v);
        }
        parts.push(`${encode(paramKey)}=${encode(parsedValue)}`);
      });
    });

    serializedParams = parts.join('&');
  }

  if (serializedParams) {
    const hashmarkIndex = url.indexOf('#');
    if (hashmarkIndex !== -1) {
      builtUrl = url.slice(0, hashmarkIndex);
    }

    const connectionCharacter = url.indexOf('?') === -1 ? '?' : '&';

    builtUrl = `${builtUrl}${connectionCharacter}${serializedParams}`;
  }

  return builtUrl;
};

export const getResponse = async (request: Request, config: InternalAxiosRequestConfig) => {
  let fetchResponse: Response;
  try {
    fetchResponse = await fetch(request);
  } catch (e: any) {
    return createError(e.message, config, AxiosErrorCodes.ERR_NETWORK, request);
  }

  const response = {
    config,
    headers: new Headers(fetchResponse.headers),
    ok: fetchResponse.ok,
    request,
    status: fetchResponse.status,
    statusText: fetchResponse.statusText,
  } as Record<string, any>;

  if (fetchResponse.status >= 200 && fetchResponse.status !== 204) {
    switch (config.responseType) {
      case 'arraybuffer':
        response.data = await fetchResponse.arrayBuffer();
        break;
      case 'blob':
        response.data = await fetchResponse.blob();
        break;
      case 'json':
        response.data = await fetchResponse.json();
        break;
      default:
        response.data = await fetchResponse.text();
        break;
    }
  }

  return response;
};

export const createRequest = (config: InternalAxiosRequestConfig) => {
  const headers = new Headers(config.headers);

  if (config.auth) {
    const username = config.auth.username || '';
    const password = config.auth.password
      ? decodeURI(encodeURIComponent(config.auth.password))
      : '';

    const encodedCredentials = window.btoa(`${username}:${password}`);
    headers.set('Authorization', `Basic ${encodedCredentials}`);
  }

  const method = config.method?.toUpperCase();
  const options = {
    headers,
    method,
  } as RequestInit;
  if (method !== 'GET' && method !== 'HEAD') {
    options.body = config.data;

    /*
     * In these cases the browser will automatically set the correct Content-Type,
     * but only if that header hasn't been set yet. So that's why we're deleting it.
     */
    if (isFormData(options.body) && isStandardBrowserEnv()) {
      headers.delete('Content-Type');
    }
  }
  /*
   * This config is similar to XHR’s withCredentials flag, but with three available values instead of two.
   * So if withCredentials is not set, default value 'same-origin' will be used
   */
  if (config.withCredentials !== undefined) {
    options.credentials = config.withCredentials ? 'include' : 'omit';
  }

  const fullPath = buildFullPath(config.baseURL ?? '', config.url ?? '');
  const url = buildURL(fullPath, config.params, config.paramsSerializer);

  // Expected browser to throw error if there is any wrong configuration value
  return new Request(url, options);
};
