import styled from '@emotion/styled';

export const StyledSVG = styled.svg`
  display: block;
  margin: 20px auto;
  max-width: 100%;
`;

export const BackgroundCircle = styled.circle`
  fill: none;
  stroke: #f2f5f9;
`;

export const ProgressCircle = styled.circle`
  fill: none;
  transform-origin: center;
  transform: rotate(-90deg);
`;

export const MiddleText = styled.text`
  font-size: 0.75rem;
  text-anchor: middle;
  fill: #4c288f;
  font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
`;
