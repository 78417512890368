import React from 'react';
import { RouterLink } from '@routing/router';

import { TableModel } from '@api/tables/TableModel';

import Item from './Item';
import { StyledRelatedTableList, StyledRelatedTableListItem } from './RelatedTableListStyle';

interface Props {
  loading?: boolean;
  tables?: TableModel[];
}

const RelatedTableList: React.FC<Props> = ({ loading, tables }) => {
  return (
    <StyledRelatedTableList>
      {!!tables?.length &&
        tables.map((table) => {
          const tableName = table.fullName ? table.fullName : table.name;
          return (
            <RouterLink key={table.guid} to={`/tables/${table.guid}`}>
              <Item dataTypes={table.dataTypes} name={tableName} />
            </RouterLink>
          );
        })}
      {tables?.length === 0 && (
        <StyledRelatedTableListItem>None detected yet.</StyledRelatedTableListItem>
      )}
      {loading && <StyledRelatedTableListItem>Loading data...</StyledRelatedTableListItem>}
    </StyledRelatedTableList>
  );
};

export default React.memo(RelatedTableList);
