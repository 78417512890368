import React, { useCallback, useState } from 'react';

import { HierarchyResponseData } from '@models/HierarchyResponse';

import { Node, SessionContext, SessionContextProps, SidebarItems } from './Session';

const buildSidebarCache =
  (dataSourceHierarchy: HierarchyResponseData) => (prev: SidebarItems | undefined) => {
    const cache = {} as { [id: string]: Node };

    function allNodes(node: Node) {
      if (cache[node.guid]) return;
      node.children.forEach(allNodes);
      cache[node.guid] = node;
    }

    if (dataSourceHierarchy && dataSourceHierarchy.data) {
      dataSourceHierarchy.data.forEach((d: Node) => allNodes(d));
    }
    return { ...prev, ...cache };
  };

const SessionProvider: React.FC = ({ children }) => {
  const [sidebarCache, setSidebarCache] = useState<SidebarItems | undefined>();

  const setSidebarItems = useCallback(
    (data: HierarchyResponseData) => setSidebarCache(buildSidebarCache(data)),
    [],
  );

  const state: SessionContextProps = {
    sidebarItems: sidebarCache,
    setSidebarItems,
  };

  return <SessionContext.Provider value={state}>{children}</SessionContext.Provider>;
};

export default SessionProvider;
