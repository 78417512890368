import { Expose, Transform } from 'class-transformer';
import moment from 'moment';

export interface PiiRuleSyncPayload {
  last_synced_at: string;
  status: 'unscheduled' | 'scheduled' | 'in_progress';
}

export default class PiiRuleSyncModel {
  static objectType: 'piiruleSync' = 'piiruleSync';

  objectType: 'piiruleSync' = 'piiruleSync';

  @Expose({ name: 'last_synced_at' })
  @Transform((value) => moment(value))
  lastSyncedAt?: moment.Moment;

  status?: PiiRuleSyncPayload['status'];
}
