import { TableLink, TreeContext } from '../types';

interface DrawTableLinksArgs {
  containerId: string;
  coordinates: TableLink[];
  customClass: string;
}

const drawTableLinks =
  (
    root: d3.Selection<d3.BaseType, unknown, null, undefined>,
    lineGenerator: TreeContext['lineGenerator'],
  ) =>
  (config: DrawTableLinksArgs[]) => {
    config.forEach(({ containerId, coordinates, customClass }) => {
      if (!root.select(`#${containerId}`).node()) {
        root.append('g').attr('id', containerId);
      }

      root
        .select(`#${containerId}`)
        .selectAll(`path.${customClass}Link`)
        .data(coordinates)
        .join('path')
        .attr('class', (d) => `${customClass}Link ${d.edgeType}`)
        .attr('d', (d) => lineGenerator(d.coordinates))
        .attr('marker-end', `url(#${customClass}LinkArrow)`);
    });
  };

export default drawTableLinks;
