import styled from '@emotion/styled';

import TableStyled from '@components/Table/TableStyled';

export const StyledDimensionsTable = styled(TableStyled)`
  .ui.table tr td {
    &.name {
      width: 100%;
    }

    &.description {
      &.no-text {
        color: ${({ theme }) => theme.colors.gray[500]};
        cursor: pointer;
      }
    }
  }
`;
