import React, { useState } from 'react';
import { useHistory } from '@routing/router';

import Text, { defaultParagraphStyles } from '@components/Text';
import TextScreen from '@components/TextScreen';
import TwoColumnDecorativeComponent from '@components/TwoColumnDecorativeComponent';
import TwoColumnLayout from '@components/TwoColumnLayout';
import MetadataDecorator from '@utils/MetadataDecorator';

import ResetPassword from './ResetPassword';

const PasswordResetPage: React.FC = () => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const history = useHistory();

  let leftComponent = <ResetPassword onSuccess={() => setShowConfirmation(true)} />;

  if (showConfirmation) {
    leftComponent = (
      <TextScreen
        buttonText="Sign in"
        onClick={() => history.push('/')}
        text={
          <Text {...defaultParagraphStyles} lineHeight={1.5}>
            Check your email for a link to reset your password. If it doesn’t appear within a few
            minutes, check your spam folder.
          </Text>
        }
        title="Reset your password"
      />
    );
  }

  return (
    <>
      <MetadataDecorator title="Reset Password" />
      <TwoColumnLayout left={leftComponent} right={<TwoColumnDecorativeComponent />} />
    </>
  );
};

export default PasswordResetPage;
