import React from 'react';

import Box from '@components/Box';
import Text from '@components/Text';
import Image from '@components/UI/Image';

interface TitleProps {
  showLogo?: boolean;
}

const Title: React.FC<TitleProps> = ({ children, showLogo }) => {
  return (
    <>
      {showLogo && (
        <Box alignItems="start" compDisplay="flex" compWidth="100%" mb={3}>
          <Image height="2.5rem" src="/icons/logo-ss-horizontal-black.svg" width="auto" />
        </Box>
      )}
      <Text
        display="block"
        fontFamily="primary"
        fontSize="25px"
        fontWeight="bold"
        lineHeight="29px"
        mb={1}
        textAlign="left"
      >
        {children}
      </Text>
    </>
  );
};

export default Title;
