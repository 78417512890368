import React from 'react';
import type { ImgHTMLAttributes } from 'react';

import { StyledImage } from './Image.styles';

export interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {}

const Image: React.FC<ImageProps> = (props) => <StyledImage {...props} />;

export default Image;
