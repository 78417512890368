import { Expose, Transform, Type } from 'class-transformer';
import moment from 'moment';

import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import { TeamModel } from '@api/teams/TeamModel';
import { ObjectType } from '@api/types';
import { UserModel } from '@api/user/UserModel';
import { breadcrumbsToLabelList, breadcrumbsToList } from '@components/Breadcrumbs';
import isEmptyRichText from '@components/RichTextEditor/helpers/isEmptyRichText';
import DataTypesModel from '@models/DataTypesModel';
import { Reference } from '@models/Reference';
import { urlFor } from '@utils/routing';

import { TaggedItemModel } from '../tags/TaggedItemModel';

export enum DocumentType {
  document = 'document',
  metric = 'metric',
}

export class DocumentModel {
  static objectType: ObjectType = 'document';

  objectType: ObjectType = DocumentModel.objectType;

  static typeDisplay: string = 'Document';

  typeDisplay: string = DocumentModel.typeDisplay;

  guid!: string;

  name!: string;

  /*
   * fullName here is strictly for type compatibility.
   */
  get fullName() {
    return this.name;
  }

  description?: string;

  @Expose({ name: 'data_type' })
  dataType!: DocumentType;

  // TODO: Remove transform https://app.shortcut.com/select-star/story/55620
  @Expose({ name: 'richtext_description' })
  @Transform((richtextDescription) =>
    isEmptyRichText(richtextDescription) ? '' : richtextDescription,
  )
  richtextDescription?: string;

  @Expose({ name: 'technical_owner' })
  @Type(() => Reference)
  technicalOwner?: Reference<UserModel | TeamModel>;

  @Expose({ name: 'business_owner' })
  @Type(() => Reference)
  businessOwner?: Reference<UserModel | TeamModel>;

  @Expose({ name: 'updated_on' })
  @Transform((value) => moment(value))
  updatedOn?: moment.Moment;

  @Expose({ name: 'created_on' })
  @Transform((value) => moment(value))
  createdOn?: moment.Moment;

  @Expose({ name: 'tagged_items' })
  @Type(() => TaggedItemModel)
  taggedItems?: TaggedItemModel[];

  @Expose({ name: 'full_path' })
  fullPath?: string;

  @Type(() => BreadcrumbModel)
  breadcrumbs?: BreadcrumbModel[];

  @Expose({ name: 'data_types' })
  @Type(() => DataTypesModel)
  dataTypes?: DataTypesModel;

  get breadcrumbList() {
    const breadcrumbsList = breadcrumbsToList(this.breadcrumbs);
    breadcrumbsList?.unshift({
      label: 'All Docs',
      url: '/docs/tabs',
    });
    return breadcrumbsList;
  }

  get breadcrumbLabelList() {
    const url = urlFor(this, false, '');
    return breadcrumbsToLabelList(this.name, url, this.breadcrumbs);
  }

  representedAs = undefined;

  richtextRepresentedAs = undefined;
}
