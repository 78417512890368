import React from 'react';

import { TabContentProps } from '@components/Tabs/types';
import type { TabRoutesConfig } from '@components/TabsRouter';
import TabsRouter from '@components/TabsRouter';
import { useUserContext } from '@context/User';
import { TableViewTabProps } from '@pages/TabbedTableViewPage/TableViewTab';

import RelatedDashboards from './RelatedDashboards';
import RelatedTables from './RelatedTables';
import SimilarTables from './SimilarTables';

const BI_DS_TYPES = ['mode', 'looker', 'tableau'];

interface RelatedSimilarTabProps extends TableViewTabProps, TabContentProps {
  showDownstreamDashboardsAllowed?: boolean;
  showSimilarTablesAllowed?: boolean;
}

const RelatedSimilarTab: React.FC<RelatedSimilarTabProps> = (props) => {
  const { dataSources } = useUserContext();
  const {
    showDownstreamDashboardsAllowed = true,
    showSimilarTablesAllowed = true,
    stickyHeader,
  } = props;
  const showDownstreamDashboards =
    showDownstreamDashboardsAllowed &&
    dataSources &&
    Object.values(dataSources).some((ds) => ds !== undefined && BI_DS_TYPES.includes(ds.type));

  const tabsRouterConfig: TabRoutesConfig[] = [
    ...(showDownstreamDashboards
      ? [
          {
            component: () => <RelatedDashboards {...props} assetName={props.fullTableName ?? ''} />,
            default: true,
            label: 'Downstream Dashboards',
            route: '/related-dashboards',
          },
        ]
      : [
          {
            component: () => <RelatedTables {...props} />,
            default: true,
            label: 'Related Tables',
            route: '/related-tables',
          },
        ]),
    ...(showSimilarTablesAllowed
      ? [
          {
            component: () => <SimilarTables {...props} />,
            label: 'Similar Tables',
            route: '/similar',
          },
        ]
      : []),
  ];

  return <TabsRouter config={tabsRouterConfig} sticky={stickyHeader} subTabs />;
};

export default RelatedSimilarTab;
