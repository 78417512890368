import styled from '@emotion/styled';

import getLayoutComponent from '@utils/getLayoutComponent';

export const StyledLayout = styled.div`
  display: flex;
  width: 100%;
  min-height: 100%;
`;

export const StyledLayoutWrapperV0 = styled(StyledLayout)`
  flex-direction: column;
  flex: 1;
`;

export const StyledLayoutWrapperV1 = styled(StyledLayoutWrapperV0)`
  min-width: 0;
`;

export const StyledLayoutWrapper = getLayoutComponent(StyledLayoutWrapperV0, StyledLayoutWrapperV1);

export const LayoutContent = styled.div`
  display: flex;
  flex-grow: 1;
`;
