import React from 'react';

import AppHeader from '@components/AppHeader';
import Box from '@components/Box';
import Emoji from '@components/Emoji';
import Text from '@components/Text';
import Title from '@components/Title/Title';
import YoutubePlayer from '@components/YoutubePlayer';
import { useUserContext } from '@context/User';
import { StyledContent, Wrapper } from '@pages/FreeTrialExpired/FreeTrialExpired.styles';
import MetadataDecorator from '@utils/MetadataDecorator';

const IncompleteOrganizationLayout: React.FC = () => {
  const { user } = useUserContext();

  return (
    <Wrapper>
      <MetadataDecorator title="Incomplete Organization" />
      <AppHeader disableAccess />
      <StyledContent>
        <Box compDisplay="flex" flexDirection="column" maxWidth="40vw" p={2}>
          <Title>
            Welcome to Select Star <Emoji type="tada" />
          </Title>
          <Text as="p" display="block" fontSize="16px" fontWeight="regular" mb={1} mt={1}>
            Hi {user?.firstName},
          </Text>
          <Text display="block" fontSize="16px" fontWeight="regular">
            We are still loading your data. We'll email you when it's done!
          </Text>
          <Text fontSize="16px" fontWeight="regular" m={0} mb={2}>
            Meanwhile, feel free to check out our demo video.
          </Text>
          <Box compDisplay="flex" justifyContent="center">
            <YoutubePlayer id="eZuIyq4DP1o" />
          </Box>
          <Text fontSize="16px" fontWeight="regular" mt={2}>
            And of course, if you have any questions, just click on the messenger below.
          </Text>
        </Box>
      </StyledContent>
    </Wrapper>
  );
};

export default IncompleteOrganizationLayout;
