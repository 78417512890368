import styled from '@emotion/styled';

import Text from '@components/Text';

export const StyledTermsOfService = styled(Text)<{ isLogin?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ isLogin, theme }) =>
    isLogin ? theme.typography.fontSizes.sm : theme.typography.fontSizes.body1};
  color: ${({ theme }) => theme.colors.v1.gray[900]};
  a {
    color: ${({ theme }) => theme.colors.text};

    &:hover {
      text-decoration: underline;
    }
  }
`;
