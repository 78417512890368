import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Theme } from '@styles/theme';

interface StyledMessageProps {
  hasReplies?: boolean;
  isHighlighted?: boolean;
  isReplyMessage?: boolean;
}

export const StyledMessage = styled.div<StyledMessageProps>`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  &:first-child {
    padding-top: ${({ isReplyMessage, theme }) => isReplyMessage && theme.space(3)};
  }

  background: ${({ isHighlighted }) =>
    isHighlighted === true ? 'rgba(0,0,0,.05)' : 'transparent'};

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.space(4)};
  }
`;

export const MessageContent = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.space(1.25, 0, 0, 1)};
`;

export const MessageControls = styled.span<StyledMessageProps>`
  display: flex;
  align-items: center;
  gap: 20px;
  font-weight: 400 !important;
  margin-top: ${({ theme }) => theme.space(1.25)};
  margin-left: ${({ theme }) => theme.space(-0.5)};
`;

const getMessageBorderStyle = (theme: Theme, isReply?: boolean) => {
  return css`
    &::before {
      content: '';
      position: absolute;
      width: 4px;
      top: ${isReply ? theme.space(3) : 0};
      bottom: 0;
      left: -2px;
      background: ${theme.colors.v1.gray[200]};
      border-radius: ${theme.radius.xs};
    }
  `;
};

export const MessageBody = styled.div<StyledMessageProps>`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  margin-left: ${({ isReplyMessage, theme }) => theme.space(isReplyMessage ? 0 : 1.4)};
  padding-left: ${({ isReplyMessage, theme }) => theme.space(isReplyMessage ? 6 : 4.5)};

  ${({ isReplyMessage, theme }) => !isReplyMessage && getMessageBorderStyle(theme)}
`;

export const ReplyList = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  margin-left: ${({ theme }) => theme.space(4)};
  padding-left: ${({ theme }) => theme.space(2)};
  ${({ theme }) => getMessageBorderStyle(theme, true)}
`;
