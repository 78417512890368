import { css } from '@emotion/react';

import stripUnit from '@styles/mixins/stripUnit';

import breakpoints, { Breakpoint } from './breakpoints';

export type MediaRules = Record<MediaRuleName, (v: string[]) => string>;

export type MediaRuleName = 'between' | 'down' | 'up';

export type Styles = Parameters<typeof css> | any;

export type MediaFunction = (
  ...breakNames: [first: Breakpoint, second?: Breakpoint]
) => (...styles: Styles) => string;

export type Media = Record<MediaRuleName, MediaFunction>;

const mediaRules: MediaRules = {
  between: (v) => `(min-width: ${v[0]}) and (max-width: ${v[1]})`,
  down: (v) => `(max-width: ${stripUnit(v[0]) - 0.5}px)`,
  up: (v) => `(min-width: ${v[0]})`,
};

const media = Object.keys(mediaRules).reduce((acc, rule): Media => {
  return {
    ...acc,
    [rule]: (...breakNames: Breakpoint[]) => {
      return (...styles: Styles) => {
        const values = breakNames.map((name: Breakpoint) => `${breakpoints[name]}px`);
        const query = mediaRules[rule as MediaRuleName](values);

        return css`
          @media ${query} {
            ${css(...styles)}
          }
        `;
      };
    },
  };
}, {} as Media);

export default media;
