import styled from '@emotion/styled';

import Box from '@components/Box';

const Banner = styled(Box)`
  background-color: #33b1ff;
  flex-direction: row;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.header};
`;

export default Banner;
