import { usePatchDataSource, usePostDataSource } from '@api/dataSources';
import { DataSourceModel } from '@models/DataSourceModel';

interface UseDataSourceMutationProps {
  dataSource?: DataSourceModel;
  onSuccess?: (ds: DataSourceModel) => void;
}
const useDataSourceMutation = ({ dataSource, onSuccess }: UseDataSourceMutationProps) => {
  const isPatch = Boolean(dataSource);
  const patchQuery = usePatchDataSource(dataSource?.guid ?? '', { onSuccess });
  const postQuery = usePostDataSource({ onSuccess });

  return isPatch ? patchQuery : postQuery;
};

export default useDataSourceMutation;
