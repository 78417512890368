const truncateDescription = (description: string = '', length: number, searchText = '') => {
  if (!description || description.length <= length) return description;

  const lowerCaseDesc = description.toLocaleLowerCase();
  const lowerCaseSearchText = searchText.toLocaleLowerCase();
  const searchTextIndex = lowerCaseDesc.indexOf(lowerCaseSearchText);

  if (searchTextIndex === -1) {
    return `${description.slice(0, length)}...`;
  }

  const beginning = searchTextIndex - Math.floor(length / 2);
  const isBeginningTruncated = beginning > 0;
  const effectiveBeginning = beginning > 0 ? beginning : 0;

  const ending = effectiveBeginning + length;
  const effectiveEnding = ending < description.length ? ending : description.length;
  const isEndingTruncated = effectiveEnding < description.length;

  return `${isBeginningTruncated ? '...' : ''}${description.substring(
    effectiveBeginning,
    effectiveEnding,
  )}${isEndingTruncated ? '...' : ''}`;
};

export default truncateDescription;
