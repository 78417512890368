import React, { memo, useCallback, useMemo, useState } from 'react';
import { Cell } from 'react-table';

import { BiConnectionModel } from '@api/biconnections/BiConnectionModel';
import { DsUserModel } from '@api/dsusers/DsUserModel';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import { DataSourceModel } from '@models/DataSourceModel';
import { Filter } from '@utils';
import wrapString from '@utils/wrapString';

import BIConnectionsSetupTableSelect from './BIConnectionsSetupTableSelect';

const INITIAL_SORT_STATE = [
  {
    desc: false,
    id: 'name',
  },
];

interface ModeConnectionsTableProps {
  data?: BiConnectionModel[];
  dataSources?: DataSourceModel[];
  filterService: Filter.FilterServiceInterface;
  itemsCount?: number;
  loadingDS: boolean;
  selectedRowIds: { [guid: string]: boolean };
  setSelectedRowIds: React.Dispatch<React.SetStateAction<{}>>;
  totalPages?: number;
}

const BIConnectionsSetupTable: React.FC<ModeConnectionsTableProps> = ({
  data,
  dataSources,
  filterService,
  itemsCount,
  loadingDS,
  selectedRowIds,
  setSelectedRowIds,
  totalPages,
}) => {
  const [isShowFilter, setShowFilter] = useState(false);
  const { changePage, filter, search } = filterService;

  const toggleFilter = useCallback(() => setShowFilter((prev) => !prev), [setShowFilter]);

  const columns: ColumnConfig<BiConnectionModel>[] = useMemo(
    () => [
      {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      {
        Cell: (props: Cell<BiConnectionModel>) => {
          const conn = props.row.original;
          return conn.name;
        },
        Header: `Connections ${wrapString(itemsCount)}`,
        accessor: (d) => d.name,
        disableHiding: true,
        id: 'name',
        width: '40%',
      },
      {
        Cell: (props: Cell<BiConnectionModel>) => {
          const conn = props?.row?.original;
          return conn?.connectionType;
        },
        Header: `Type`,
        disableFilters: true,
        disableHiding: true,
        id: 'type',
      },
      {
        Cell: ({ row }: Cell<BiConnectionModel>) => {
          const connection = row.original;
          return (
            <BIConnectionsSetupTableSelect
              connection={connection}
              dataSources={dataSources}
              isLoadingOptions={loadingDS}
            />
          );
        },
        Header: 'Select Star Data Source',
        disableFilters: true,
        id: 'datasource',
        width: '43%',
      },
    ],
    [itemsCount, dataSources, loadingDS],
  );

  const getRowId = useCallback((row: Partial<DsUserModel>) => row.guid!, []);

  return (
    <TableStyled>
      <Table
        basic="very"
        changePage={changePage}
        className="table-full"
        columns={columns}
        data={data ?? []}
        disableColumnFiltering
        disablePageRefresh
        getRowId={getRowId}
        initialState={{
          pageIndex: filter.page ? filter.page - 1 : 0,
          selectedRowIds,
          sortBy: INITIAL_SORT_STATE,
        }}
        loading={data === undefined}
        manualFilters
        manualPagination
        selectable
        setFilters={search}
        setSelectedRowIds={setSelectedRowIds}
        showFilter={isShowFilter}
        sortable
        toggleFilter={toggleFilter}
        totalPages={totalPages}
      />
    </TableStyled>
  );
};

export default memo(BIConnectionsSetupTable);
