import styled from '@emotion/styled';

import rootTheme from '@styles/theme';

const SPACE = 2;
const SIZES = {
  default: {
    size: 24,
    width: 44,
  },
  sm: {
    size: 20,
    width: 36,
  },
  xs: {
    size: 14,
    width: 24,
  },
};

const COLOR = {
  disabled: {
    checked: rootTheme.colors.v1.primary[200],
    unchecked: rootTheme.colors.v1.primary[50],
  },
  enabled: {
    checked: rootTheme.colors.v1.primary[600],
    unchecked: rootTheme.colors.v1.gray[300],
  },
};

export interface StyledToggleProps {
  compSize: 'sm' | 'xs' | 'default';
  disabled?: boolean;
}

export const StyledToggle = styled.label<StyledToggleProps>`
  border-radius: ${({ theme }) => theme.space(1.5)};
  height: ${({ compSize }) => `${SIZES[compSize].size}px`};
  width: ${({ compSize }) => `${SIZES[compSize].width}px`};
`;

export const Circle = styled.div<StyledToggleProps>`
  background-color: ${({ disabled }) =>
    disabled ? COLOR.disabled.unchecked : COLOR.enabled.unchecked};
  border-radius: inherit;
  height: inherit;
  position: relative;
  transition: background-color 0.2s ease-out;
  width: inherit;
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'cursor' : 'pointer')};

  &::after {
    background-color: white;
    border-radius: inherit;
    bottom: 0;
    content: '';
    height: ${({ compSize }) => `${SIZES[compSize].size - SPACE * 2}px`};
    width: ${({ compSize }) => `${SIZES[compSize].size - SPACE * 2}px`};
    left: ${SPACE}px;
    margin: auto;
    pointer-events: none;
    position: absolute;
    top: 0;
    transform: translateX(0px);
    transition: transform 0.2s ease-out;
  }
`;

export const ToggleInput = styled.input<StyledToggleProps>`
  display: none;

  &:checked {
    & + ${Circle} {
      background-color: ${({ disabled }) =>
        disabled ? COLOR.disabled.checked : COLOR.enabled.checked};

      &::after {
        transform: translateX(${({ compSize }) => SIZES[compSize].width - SIZES[compSize].size}px);
      }
    }
  }
`;
