import styled from '@emotion/styled';

const ProfileStyled = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  
  .profile--container {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .icon--profile {
      max-width: 4.25rem;
      max-height: 4.25rem;
      min-width: 4.25rem;
      min-height: 4.25rem;
      width: 4.25rem;
      height: 4.25rem;
      margin-right: 1rem;
    }

    .title-row {
      display: flex;
      align-items: center;
      flex-flow: row wrap;
      justify-content: flex-start;
      min-width 16rem;

      .ui.label {
        display: inline-flex;
        margin-left: 0;
        margin-right: 0.5rem;
        margin-top: 0.4rem;
      }
    }

    .text--name {
        font-size: 1.7rem;
        font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
        font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
        line-height: 1;
        color: #2c2e36;
        margin-right: 0.75rem;
      }

    .subtitle-row {
      margin-top: 0.25rem;
      display: flex;
      align-items: center;
      gap: ${({ theme }) => theme.space(0.25)};
    }

    .email-address--container {
      display: flex;
      align-items: center;
      margin-top: 0.3rem;
      .icon--control {
        fill: #8c939b;
        transform: scale(1.3);
      }
      .text {
        margin-left: 0.5rem;
        color: #6f747c;
        font-size: 0.875rem;
      }
    }
  }
`;

export default ProfileStyled;
