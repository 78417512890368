import React, { useMemo, useState } from 'react';

import { useFetchTagsHierarchy } from '@api/tags';
import type { Option } from '@components/UI/Select';
import { HierarchyData } from '@models/HierarchyModel';

import {
  TagsHierarchyContext,
  TagsHierarchyContextProps,
  TagsSelectOptions,
} from './TagsHierarchyContext';

const TAG_SECTION_TITLE_ITEM = ['category_tag_container', 'status_tag_container'];

const processTagOptions = (node: HierarchyData) => {
  const { children, ...tagInfo } = node;

  const option: Option = {
    children: node.children.map(processTagOptions),
    icon: node.icon,
    iconColor: node.color,
    isDividerItem: TAG_SECTION_TITLE_ITEM.includes(node.guid),
    original: tagInfo,
    text: node.name,
    value: node.guid,
  };

  return option;
};

export const EMPTY_MENU_ITEM_GUID = 'empty-menu-item';

export const EMPTY_MENU_ITEM = {
  breadcrumbLabelList: [],
  children: [],
  guid: EMPTY_MENU_ITEM_GUID,
  name: 'No tag created',
} as unknown as HierarchyData;

const addEmptyMenuItem = (tagsHierarchies: HierarchyData[]) => {
  const parsedTagsHierarchies = tagsHierarchies.map((hierarchy) => {
    if (hierarchy.children.length === 0) {
      return { ...hierarchy, children: [EMPTY_MENU_ITEM] };
    }

    return hierarchy;
  });

  return parsedTagsHierarchies;
};

const TagsHierarchyProvider: React.FC = ({ children }) => {
  const [tagsSelectOptions, setTagsSelectOptions] = useState<TagsSelectOptions>({});
  const [tagsHierarchy, setTagsHierarchy] = useState<HierarchyData[]>([]);

  const { isLoading: tagsHierarchyIsLoading } = useFetchTagsHierarchy({
    onSuccess: (tagsData) => {
      let categoryTags;
      let statusTags;

      if (tagsData?.[0]) {
        categoryTags = processTagOptions(tagsData[0]);
      }
      if (tagsData?.[1]) {
        statusTags = processTagOptions(tagsData[1]);
      }
      setTagsHierarchy(addEmptyMenuItem(tagsData));
      setTagsSelectOptions({ category: categoryTags, status: statusTags });
    },
  });

  const state: TagsHierarchyContextProps = useMemo(() => {
    return {
      tagsHierarchy,
      tagsHierarchyIsLoading,
      tagsSelectOptions,
    };
  }, [tagsHierarchy, tagsHierarchyIsLoading, tagsSelectOptions]);

  return <TagsHierarchyContext.Provider value={state}>{children}</TagsHierarchyContext.Provider>;
};

export default TagsHierarchyProvider;
