import React, { useEffect, useState } from 'react';

import { DescriptionConversionModel } from '@api/description/DescriptionConversionModel';
import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import RichTextDescriptionEditor from '@components/RichTextDescriptionEditor';
import { RichTextEditorElement } from '@components/RichTextEditor/RichTextEditor.types';
import { renderErrorToast } from '@components/Toast';
import useMarkdownConverter from '@hooks/useMarkdownConverter';
import theme from '@styles/theme';

import { EDITOR_MIN_HEIGHT } from '../MarkdownRichTextEditor.styles';
import { MarkdownRichTextEditorProps } from '../MarkdownRichTextEditor.types';

interface PreviewMarkdownRichTextEditorProps {
  allowedElements?: RichTextEditorElement[];
  markdown: string;
  variant?: MarkdownRichTextEditorProps['variant'];
}

const STYLES_CONFIG = {
  block: {
    fontSize: theme.typography.fontSizes.body2,
    minHeight: EDITOR_MIN_HEIGHT.block,
    mt: 2,
    p: 0,
  },
  inline: {
    fontSize: theme.typography.fontSizes.body1,
    minHeight: EDITOR_MIN_HEIGHT.inline,
    mt: 0,
    p: 1,
  },
};

const PreviewMarkdownRichTextEditor: React.FC<PreviewMarkdownRichTextEditorProps> = ({
  allowedElements,
  markdown = '',
  variant = 'block',
}) => {
  const [value, setValue] = useState<DescriptionConversionModel>();

  const { convertMarkdownToRichText, isLoading } = useMarkdownConverter({
    onConvertToRichTextSuccess: (data) => {
      setValue(data);
    },
    onError: () => {
      renderErrorToast('Something went wrong.');
    },
  });

  const styles = STYLES_CONFIG[variant];

  useEffect(() => {
    convertMarkdownToRichText(markdown);
  }, [convertMarkdownToRichText, markdown]);

  return (
    <Box data-testid="preview-markdown-editor" mt={styles.mt}>
      <Box
        data-testid="preview-markdown"
        fontSize={styles.fontSize}
        minHeight={styles.minHeight}
        overflowX="auto"
        p={styles.p}
      >
        {isLoading ? (
          <Box
            alignItems="center"
            compDisplay="flex"
            justifyContent="center"
            pt={variant === 'block' ? 4 : 3}
          >
            <CircularLoader borderWidth={2} compSize={variant === 'block' ? 5 : 4} delay={500} />
          </Box>
        ) : (
          <RichTextDescriptionEditor
            allowedElements={allowedElements}
            descriptions={{
              richTextDescription: value?.richtextDescription,
            }}
            fontSize={styles.fontSize}
            isEditable={false}
            shouldFocusOnEdit={false}
            shouldStartFocused={false}
            title=""
            variant="block-simple"
          />
        )}
      </Box>
    </Box>
  );
};

export default PreviewMarkdownRichTextEditor;
