import { Expose, Transform, Type } from 'class-transformer';
import truncate from 'lodash/truncate';
import moment from 'moment';

import { ColumnData, ColumnModel } from '@api/columns/ColumnModel';
import { DatabaseModel } from '@api/databases/DatabaseModel';
import { DSUserData, DsUserModel } from '@api/dsusers/DsUserModel';
import { JoinData, JoinModel } from '@api/joins/JoinModel';
import { TableData, TableModel } from '@api/tables/TableModel';
import { UserData, UserModel } from '@api/user/UserModel';
import { PopularityData, PopularityModel } from '@models/PopularityModel';
import formatNumber from '@utils/formatNumber';

export interface QueryData {
  averageDuration?: moment.Duration;
  columns?: ColumnData[];
  createdBy?: UserData;
  description?: string;
  displayName: string;
  dsuserCreatedBy: DSUserData;
  firstSeenOn: moment.Moment;
  guid: string;
  icon: string;
  joins?: JoinData[];
  lastExecutedOn: moment.Moment;
  mainDSUser: DSUserData;
  mainUser: UserData;
  name?: string;
  popularity?: PopularityData;
  queryType: string;
  rawSql: string;
  tables?: TableData[];
  totalRun: number;
}

export class QueryModel {
  static objectType: string = 'query';

  objectType: string = QueryModel.objectType;

  static typeDisplay: string = 'Query';

  typeDisplay: string = QueryModel.typeDisplay;

  guid!: string;

  name?: string;

  description?: string;

  @Type(() => DatabaseModel)
  database!: DatabaseModel;

  @Expose({ name: 'raw_sql' })
  rawSql!: string;

  @Expose({ name: 'query_type' })
  queryType?: string;

  @Type(() => TableModel)
  tables?: TableModel[];

  @Type(() => ColumnModel)
  columns?: ColumnModel[];

  @Type(() => JoinModel)
  joins?: JoinModel[];

  @Expose({ name: 'first_seen_on' })
  @Transform((value) => value && moment.utc(value))
  firstSeenOn?: moment.Moment;

  @Expose({ name: 'last_executed_on' })
  @Transform((value) => value && moment.utc(value))
  lastExecutedOn?: moment.Moment;

  @Expose({ name: 'created_by' })
  @Type(() => UserModel)
  createdBy?: UserModel;

  @Expose({ name: 'dsuser_created_by' })
  @Type(() => DsUserModel)
  dsuserCreatedBy!: DsUserModel;

  @Expose({ name: 'main_user' })
  @Type(() => UserModel)
  mainUser?: UserModel;

  @Expose({ name: 'main_dsuser' })
  @Type(() => DsUserModel)
  mainDSUser?: DsUserModel;

  @Expose({ name: 'total_run' })
  totalRun: number = 0;

  get formattedTotalRun() {
    return formatNumber({ value: this.totalRun });
  }

  @Expose({ name: 'max_duration' })
  @Transform((value) => moment.duration(value ?? 0))
  maxDuration?: moment.Duration;

  get formattedMaxDuration() {
    return `${formatNumber({ value: this.maxDuration?.as('seconds') })}s`;
  }

  @Expose({ name: 'total_credits_used' })
  totalCreditsUsed?: number = 0;

  get formattedTotalCreditsUsed() {
    return formatNumber({ value: this.totalCreditsUsed });
  }

  @Expose({ name: 'average_credits_used' })
  averageCreditsUsed: number = 0;

  get formattedAverageCreditsUsed() {
    return formatNumber({ value: this.averageCreditsUsed });
  }

  warehouse: string = '';

  @Type(() => PopularityModel)
  popularity?: PopularityModel;

  get displayName() {
    return this.name ? this.name : truncate(this.rawSql, { length: 250 });
  }
}
