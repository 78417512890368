import { Expose } from 'class-transformer';

class SchemataIngestionConfigModel {
  static objectType: 'schemataingestionconfig' = 'schemataingestionconfig';

  objectType = SchemataIngestionConfigModel.objectType;

  static typeDisplay: 'Schemata Ingestion Config' = 'Schemata Ingestion Config';

  typeDisplay = SchemataIngestionConfigModel.typeDisplay;

  guid!: string;

  name!: string;

  @Expose({ name: 'should_ingest' })
  shouldIngest!: boolean;
}

export default SchemataIngestionConfigModel;
