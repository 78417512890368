import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Box from '@components/Box';
import Button from '@components/Button/Button';
import rootTheme from '@styles/theme';

type Variant = 'default' | 'defaultDisabled';

const defaultVariant = 'default';

export type VariantProps = {
  variant?: Variant;
};

const dateRangePickerContainerVariants: Record<Variant, SerializedStyles> = {
  default: css`
    font-weight: ${rootTheme.typography.fontWeights.medium};
    border: 1px solid ${rootTheme.colors.v1.gray[200]};
    border-radius: 3px;
    color: ${rootTheme.colors.v1.gray[700]};
    font-size: ${rootTheme.typography.fontSizes.body2};
    padding: ${rootTheme.space(1, 1.25)};
    cursor: pointer;
    & #icon {
      margin-top: -2px;
      color: ${rootTheme.colors.v1.gray[500]};
    }
  `,
  defaultDisabled: css`
    border: 1px solid ${rootTheme.colors.v1.gray[300]};
    border-radius: 3px;
    cursor: not-allowed;
    color: ${rootTheme.colors.v1.gray[500]};
    font-size: ${rootTheme.typography.fontSizes.body2};
    padding: ${rootTheme.space(1, 1.25)};
    & #icon {
      display: none;
    }
  `,
};

export interface DateRangePickerContainerProps extends VariantProps {
  disabled?: boolean;
}

export const DateRangePickerContainer = styled(Box)<DateRangePickerContainerProps>`
  display: flex;
  align-items: center;
  min-width: fit-content;
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};

  ${({ disabled, variant = defaultVariant }) => {
    const statusVariant = (disabled ? `${variant}Disabled` : variant) as Variant;
    return dateRangePickerContainerVariants[statusVariant];
  }}
`;

DateRangePickerContainer.defaultProps = {
  'aria-label': 'Date picker',
};

export const DateRangePickerDateSeparator = styled(Box)<VariantProps>`
  font-weight: ${rootTheme.typography.fontWeights.regular};
`;

export const RangeSelectButton = styled(Button)`
  border-radius: 0;
  padding: ${({ theme }) => theme.space(0, 1)};

  &:hover {
    background-color: ${({ theme }) => theme.colors.hover};
  }
`;
