import styled from '@emotion/styled';
import { RouterLink } from '@routing/router';

import { MENU_ITEM_WIDTH } from '@components/AppMainSidebar/AppMainSidebar.styles';
import Box from '@components/Box';
import px from '@styles/mixins/px';

export const StyledAppHeader = styled(Box)`
  align-items: center;
  background: ${({ theme }) => theme.colors.darkBackground};
  display: flex;
  height: 60px;
  width: 100%;
  padding-left: ${px(MENU_ITEM_WIDTH / 2.5)};
  padding-right: ${({ theme }) => theme.space(3.5)};
  pointer-events: auto;
`;

interface LogoImgProps {
  compHeight?: string;
}

export const LogoImg = styled.img<LogoImgProps>`
  height: ${({ compHeight }) => compHeight};
  width: auto;
`;

interface LogoLinkProps {
  disableAccess: boolean;
}

export const LogoLink = styled(RouterLink)<LogoLinkProps>`
  display: block;
  flex-shrink: 0;
  pointer-events: ${({ disableAccess }) => (disableAccess ? 'none' : 'auto')};
`;
