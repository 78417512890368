export class EmployeeRoleModel {
  static objectType: string = 'EmployeeRole';

  objectType: string = EmployeeRoleModel.objectType;

  static typeDisplay: string = 'Employee Role';

  typeDisplay: string = EmployeeRoleModel.typeDisplay;

  name!: string;
}
