import React from 'react';

import LayoutMainGrid from '@components/Layout/LayoutMainGrid';
import AdminTitleView from '@pages/Admin/AdminTitleView/AdminTitleView';
import MetadataDecorator from '@utils/MetadataDecorator';

import AdminDataSources from './AdminDataSources';

const AdminDataPage: React.FC = () => {
  return (
    <>
      <MetadataDecorator title="Data Admin" />
      <LayoutMainGrid>
        <AdminTitleView title="Data" />
        <AdminDataSources />
      </LayoutMainGrid>
    </>
  );
};

export default AdminDataPage;
