import React, { useMemo } from 'react';

import { useFetchComments } from '@api/comments';
import { CommentModel } from '@api/comments/CommentModel';
import Box from '@components/Box';
import LayoutMainGrid from '@components/Layout/LayoutMainGrid';
import AdminDiscussionTable from '@components/Table/AdminDiscussionTable';
import AdminTitleView from '@pages/Admin/AdminTitleView/AdminTitleView';
import { Filter } from '@utils';
import MetadataDecorator from '@utils/MetadataDecorator';

const searchConfig: { [key: string]: keyof Filter.FilterOptions } = {
  comment: 'search_comment',
  target: 'search_target',
  user: 'search_commented_by',
};

const sortConfig: { [key: string]: string } = {
  lastEdited: 'commented_on',
};

const defaultCommentConfig = {
  order: '-commented_on',
  page: 1,
  page_size: 50,
  query:
    '{guid,message,richtext_message,target{object_type,obj},commented_by,commented_on,in_reply_to}',
};

const AdminDiscussionPage: React.FC = () => {
  const FilterService = Filter.useUpdateFilters(defaultCommentConfig, searchConfig, sortConfig);
  const { filter } = FilterService;

  const effectiveConfig = useMemo(() => {
    return { params: Filter.setParams(filter) };
  }, [filter]);

  const { data: commentsResponse } = useFetchComments(effectiveConfig);

  const comments: CommentModel[] | undefined = commentsResponse?.results || undefined;

  const totalPages =
    commentsResponse && filter.page_size ? Math.ceil(commentsResponse.count / filter.page_size) : 1;

  return (
    <>
      <MetadataDecorator title="Discussion Admin" />
      <LayoutMainGrid>
        <Box maxWidth="100%" mb={8} px={1.5} py={0}>
          <AdminTitleView title="Discussion" />
        </Box>
        <Box maxWidth="100%" mb={8} px={1.5} py={0}>
          <AdminDiscussionTable
            comments={comments}
            filterService={FilterService}
            itemCount={commentsResponse?.count}
            totalPages={totalPages}
          />
        </Box>
      </LayoutMainGrid>
    </>
  );
};

export default AdminDiscussionPage;
