import React, { useMemo } from 'react';

import { useDeleteDbtProject } from '@api/dbt';
import type { DbtProjectModelV1 } from '@api/dbt/DbtProjectModel.v1';
import invalidateCache from '@api/invalidateCache';
import Box from '@components/Box';
import IconButton from '@components/IconButton';
import { renderInfoToast } from '@components/Toast';
import { useModal } from '@context/Modal';
import useRefValueChange from '@hooks/useRefValueChange';

export interface DeleteProjectCellProps {
  dbtProject: DbtProjectModelV1;
}

const DeleteProjectCell: React.FC<DeleteProjectCellProps> = ({ dbtProject }) => {
  const { MODAL_IDS, closeModal, openModal, updateModalContent } = useModal();
  const { error, isLoading, mutate } = useDeleteDbtProject('', {
    onSuccess: () => {
      invalidateCache((keys) => [keys.dbt.all]);
      renderInfoToast('Project deleted Successfully');
      closeModal(MODAL_IDS.deleteConfirmation);
    },
  });

  const modalContent = useMemo(
    () => ({
      children: (
        <Box>
          Are you sure you want to remove{' '}
          <Box as="span" fontWeight="bold">
            {dbtProject.name}
          </Box>
          ? All your data catalog information related to this project will also be deleted
        </Box>
      ),
      error: error?.data?.[0],
      loading: isLoading,
      onClose: () => {
        closeModal(MODAL_IDS.deleteConfirmation);
      },
      onRemove: () => {
        mutate({ httpClientUrl: `/dbt/project/${dbtProject.guid}/` });
      },
      title: 'Remove Project',
    }),
    [
      dbtProject.name,
      dbtProject.guid,
      error?.data,
      isLoading,
      closeModal,
      MODAL_IDS.deleteConfirmation,
      mutate,
    ],
  );

  const handleClick = () => {
    openModal(MODAL_IDS.deleteConfirmation, modalContent);
  };

  useRefValueChange({
    onChange: () => updateModalContent(MODAL_IDS.deleteConfirmation, modalContent),
    value: modalContent,
  });

  return (
    <IconButton
      iconColor="gray.600"
      iconName="trash"
      iconSize="20px"
      onClick={handleClick}
      variant="clear"
      verticalAlign="middle"
    />
  );
};

export default DeleteProjectCell;
