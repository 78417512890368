import React from 'react';

import { useFetchJobEvents } from '@api/jobs';
import type { GetJobsEventsListPathParams } from '@api/openapi.generated';
import TabError from '@components/TabContent/TabError';
import JobEventTable, {
  JOB_EVENT_TABLE_SEARCH_CONFIG,
  JOB_EVENT_TABLE_SORT_CONFIG,
} from '@components/Table/JobEventTable';
import type { FilterOptions } from '@utils/filters';
import { setParams, useUpdateFilters } from '@utils/filters';

const REQUEST_CONFIG: FilterOptions = {
  order: `-${JOB_EVENT_TABLE_SORT_CONFIG.endTime}`,
  page: 1,
  page_size: 50,
};

export interface JobEventsTabProps {
  guid?: string;
}

const JobEventsTab: React.FC<JobEventsTabProps> = ({ guid }) => {
  const filterService = useUpdateFilters(
    REQUEST_CONFIG,
    JOB_EVENT_TABLE_SEARCH_CONFIG,
    JOB_EVENT_TABLE_SORT_CONFIG,
    REQUEST_CONFIG.order,
  );
  const { data, isError, isLoading } = useFetchJobEvents(guid, {
    params: setParams(filterService.filter) as GetJobsEventsListPathParams,
  });

  if (isError) return <TabError />;

  return <JobEventTable data={data} filterService={filterService} isLoading={isLoading} />;
};

export default React.memo(JobEventsTab);
