// Generates a random color from a list of team colors.

import itemRandomGenerator from './itemRandomGenerator';

export default function teamColorGenerator(text: string | undefined) {
  const teamColors = [
    '#FF7987',
    '#A099E9',
    '#EB8EDF',
    '#5DA283',
    '#9269FF',
    '#4573D2',
    '#45BBDA',
    '#FFB239',
    '#FFD130',
  ];
  return itemRandomGenerator({ itemsList: teamColors, representativeText: text }) as string;
}
