import React, { ChangeEvent } from 'react';
import {
  CellProps as ReactTableCellProps,
  Column,
  ColumnInstance,
  Hooks,
  Row,
  TableInstance,
  UseRowSelectRowProps,
} from 'react-table';

import Checkbox from '@components/UI/Form/Checkbox';
import InputLabel from '@components/UI/Form/InputLabel';

interface CellRow extends Row<any>, UseRowSelectRowProps<any> {}

export interface CellProps extends ReactTableCellProps<any, any> {
  canSelectRow?: (row: CellRow) => boolean;
  onCellCheckChange?: (original: any, checked: boolean) => void;
  row: CellRow;
}

export const Cell = (props: CellProps) => {
  const { canSelectRow, onCellCheckChange, row, rows } = props;
  const disabled = Boolean(canSelectRow) && !canSelectRow?.(row);
  const rowProps = row.getToggleRowSelectedProps();

  const checkRow = (targetRow: CellRow, checked: boolean) => {
    onCellCheckChange?.(targetRow.original, checked);
    targetRow.toggleRowSelected(!targetRow.isSelected);
  };

  const handleChange = (e: React.MouseEvent<HTMLInputElement>) => {
    if (e.shiftKey) {
      const checkedRows = rows.filter((r) => r.isSelected);

      if (checkedRows.length === 0) {
        checkRow(row, true);
        return;
      }

      const startIndex = rows.findIndex((r) => r.id === checkedRows[0].id);
      const endIndex = rows.findIndex((r) => r.id === row.id);

      const start = Math.min(startIndex, endIndex);
      const end = Math.max(startIndex, endIndex);

      rows.forEach((r, i) => {
        if (i >= start && i <= end) {
          if (!r.isSelected) checkRow(r, true);
        }
      });
    } else {
      checkRow(row, !row.isSelected);
    }
  };

  return (
    <Checkbox
      alignItems="center"
      checked={rowProps?.checked}
      compDisplay="flex"
      disabled={disabled}
      justifyContent="center"
      mx="auto"
      onClick={handleChange}
      title={rowProps?.title}
    />
  );
};

interface HeaderCheckboxProps {
  getToggleAllRowsSelectedProps: () =>
    | {
        checked?: boolean;
        indeterminate?: boolean;
        label?: string;
        onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
        ref?: (instance: HTMLInputElement | null) => void;
        title?: string;
      }
    | undefined;
  headerLabel?: string;
  hideCheckbox?: boolean;
  onHeaderCheckChange?: (checked: boolean) => void;
}

const Header: React.FC<HeaderCheckboxProps & TableInstance> = ({
  canSelectRow,
  getToggleAllRowsSelectedProps,
  headerLabel,
  hideCheckbox,
  onHeaderCheckChange,
  page,
}) => {
  const rowProps = getToggleAllRowsSelectedProps();
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (canSelectRow) {
      page.forEach((row) => {
        if (!e.target.checked) {
          row.toggleRowSelected(false);
        } else if (canSelectRow?.(row)) {
          row.toggleRowSelected(!row.isSelected);
        }
      });
    } else {
      rowProps?.onChange?.(e);
      onHeaderCheckChange?.(e.target.checked);
    }
  };

  if (hideCheckbox) {
    return <span>{headerLabel}</span>;
  }

  return (
    <InputLabel
      compDisplay="flex"
      compWidth="fit-content"
      fontSize="inherit"
      mx="auto"
      verticalAlign="middle"
    >
      <Checkbox
        ref={rowProps?.ref}
        checked={rowProps?.checked}
        onChange={handleChange}
        title={rowProps?.title}
      />
      {headerLabel}
    </InputLabel>
  );
};

export interface RangeSelectionColumnConfigArgs
  extends Pick<CellProps, 'onCellCheckChange'>,
    Pick<HeaderCheckboxProps, 'onHeaderCheckChange'> {
  canSelectRow?: (row: CellRow) => boolean;
  headerLabel?: string;
  hideHeaderCheckbox?: boolean;
  maxWidth?: number;
  minWidth?: number;
  width?: number;
}

const generateColumn =
  ({
    canSelectRow,
    headerLabel,
    hideHeaderCheckbox,
    maxWidth = 32,
    minWidth = 32,
    onCellCheckChange,
    onHeaderCheckChange,
    width = 32,
  }: RangeSelectionColumnConfigArgs) =>
  (columns: ColumnInstance<any>[]) => {
    const selectionColumn: Column<any> = {
      Cell: (props) => (
        <Cell {...props} canSelectRow={canSelectRow} onCellCheckChange={onCellCheckChange} />
      ),
      Header: (props) => (
        <Header
          {...props}
          canSelectRow={canSelectRow}
          headerLabel={headerLabel}
          hideCheckbox={hideHeaderCheckbox}
          onHeaderCheckChange={onHeaderCheckChange}
        />
      ),
      disableFilters: true,
      disableGroupBy: true,
      disableResizing: true,
      disableSortBy: true,
      id: 'selection',
      maxWidth,
      minWidth,
      width,
    };

    return [selectionColumn, ...columns];
  };

const useRangeSelect =
  (columnConfig: RangeSelectionColumnConfigArgs) =>
  (hooks: Hooks): void => {
    hooks.visibleColumns.push(generateColumn(columnConfig));
  };

export default useRangeSelect;

useRangeSelect.pluginName = 'useRangeSelect';
