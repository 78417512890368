import React from 'react';

import type { JobModel } from '@api/jobs/JobModel.v1';
import { useFetchMetadataComments } from '@api/metadata';
import { isNotificationAllowed } from '@components/Modal/NotifyUsersModal/utils';
import DiscussionTab from '@components/TabContent/DiscussionTab';
import JobEventsTab from '@components/TabContent/JobEventsTab/JobEventsTab';
import TabbedLineage from '@components/TabContent/TabbedLineage';
import type { TabRoutesConfig } from '@components/TabsRouter';
import { useMetadataObjectContext } from '@context/MetadataObject';
import { useUserContext } from '@context/User';
import MetadataTabsRouter from '@pages/MetadataObjectPage/MetadataTabsRouter';
import wrapString from '@utils/wrapString';

import type { TabContentProps } from '../types';

interface JobsTabContentProps extends Pick<TabContentProps, 'guid' | 'overviewTabConfig'> {
  data?: JobModel;
}

const JobsTabContent: React.FC<JobsTabContentProps> = ({ data, guid, overviewTabConfig }) => {
  const { data: commentData } = useFetchMetadataComments(guid);
  const { organization } = useUserContext();
  const { dataTypes } = useMetadataObjectContext();

  const tabsRouterConfig: TabRoutesConfig[] = [
    { ...overviewTabConfig, default: true },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <JobEventsTab guid={guid} />,
      label: 'Run History',
      route: '/run-history',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => (
        <TabbedLineage
          guid={guid}
          hideCount
          itemMetadata={data}
          objectType="job"
          showExplore={false}
        />
      ),
      contentOffset: 1.6,
      label: 'Lineage',
      route: '/lineage',
    },

    ...(organization?.settings?.useDiscussions
      ? [
          {
            // eslint-disable-next-line react/no-unstable-nested-components
            component: () => (
              <DiscussionTab
                enableNotifications={isNotificationAllowed(dataTypes?.dataSourceType!)}
                guid={guid}
              />
            ),
            contentOffset: 2,
            label: `Discussion ${wrapString(commentData?.length)}`,
            path: '/discussion/:itemId?',
            route: '/discussion',
          },
        ]
      : []),
  ];

  return <MetadataTabsRouter config={tabsRouterConfig} />;
};

export default JobsTabContent;
