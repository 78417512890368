import { TeamModel } from '@api/teams/TeamModel';
import { UserModel } from '@api/user/UserModel';
import { Option } from '@components/UI/Select';
import { removeDuplicatedOptions } from '@components/UI/Select/Select/Select.utils';
import { useUserContext } from '@context/User';

interface UseDefaultOwnerOptionsParams {
  enableUserDefaultOwner?: boolean;
  isEdit?: boolean;
  owner?: UserModel | TeamModel;
}

const useDefaultOwnerOptions = (params?: UseDefaultOwnerOptionsParams) => {
  const { enableUserDefaultOwner = true, isEdit, owner } = params ?? {};
  const { user } = useUserContext();

  const currentOwnerOption = owner
    ? {
        avatar: owner?.mappedAvatar,
        text: owner?.fullName ?? '',
        value: owner?.guid ?? '',
      }
    : undefined;

  const loggedUserOption = {
    avatar: user?.mappedAvatar,
    text: user?.fullName ?? '',
    value: user?.guid ?? '',
  };

  const validOptions = [currentOwnerOption, loggedUserOption].filter((opt) => Boolean(opt));

  const options = removeDuplicatedOptions(validOptions as Option[]);

  let selectedOption;
  if (isEdit) {
    selectedOption = currentOwnerOption;
  } else if (enableUserDefaultOwner) {
    selectedOption = currentOwnerOption ?? loggedUserOption;
  } else {
    selectedOption = undefined;
  }

  return { options, selectedOption: selectedOption ? [selectedOption] : [] };
};

export default useDefaultOwnerOptions;
