import styled from '@emotion/styled';
import { Popup } from 'semantic-ui-react';

interface DropDownMenuItemProps {
  isDisabled?: Boolean;
}

const DropDownMenuItem = styled.div<DropDownMenuItemProps>`
  align-items: center;
  color: #4c288f;
  cursor: pointer;
  display: flex;
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  font-size: 13px;
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
  gap: ${({ theme }) => theme.space(0.5)};
  height: 36px;
  opacity: ${({ isDisabled = false }) => (isDisabled ? 0.75 : 1)};
  padding: ${({ theme }) => theme.space(0.5, 0, 0.5, 2)};
  pointer-events: ${({ isDisabled = false }) => (isDisabled ? 'none' : undefined)};
  user-select: none;
  width: 100%;
  svg {
    height: 0.75rem;
    width: 0.75rem;
    path {
      fill: #4c288f;
    }
  }
  &:hover {
    color: #fff;
    background: #4c288f;
    svg {
      path {
        fill: #fff;
      }
    }
  }
  &:first-of-type {
    border-radius: 0.25rem 0.25rem 0 0;
  }
  &:last-of-type {
    border-radius: 0 0 0.25rem 0.25rem;
  }
`;

const DropDownMenuPopup = styled(Popup)`
  border: 0 !important;
  &.ui.bottom.left.popup::before {
    border: 1px solid rgb(34 36 38 / 6%);
    border-width: 1.25px 0 0 1.25px;
    box-shadow: none !important;
    top: -0.4rem;
    z-index: -1;
  }
`;

export const DropDownMenuPopupStyled = styled(DropDownMenuPopup)`
  &.ui.bottom.left.popup::before {
    border: 1px solid #d8dbe0;
  }
`;

export const DropDownMenuItemStyled = styled(DropDownMenuItem)`
  gap: ${({ theme }) => theme.space(1)};
  color: #2c2e36;
  font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
  svg {
    height: 16px;
    width: 16px;
    path {
      fill: #4c288f;
    }
  }
  &:hover {
    background: #f2f5f9;
    color: #2c2e36;
  }
`;
