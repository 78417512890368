import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { useFetchBillingCustomer } from '@api/billing';
import LayoutMainGrid from '@components/Layout/LayoutMainGrid';
import type { TabRoutesConfig } from '@components/TabsRouter';
import TabsRouter from '@components/TabsRouter';
import Icon from '@components/UI/Icon';
import TitleView from '@components/UI/TitleView';
import flags from '@features';
import MetadataDecorator from '@utils/MetadataDecorator';

import CurrentPlanTab from './Tabs/CurrentPlanTab';
import CustomerPortalTab from './Tabs/CustomerPortalTab';

const stripePromise = loadStripe(window?.env?.STRIPE_PUBLIC_KEY);

const AdminBillingPage: React.FC = () => {
  const { data: billingCustomer, isLoading } = useFetchBillingCustomer();

  if (!flags.billing) return null;

  const tabsRouterConfig: TabRoutesConfig[] = [
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <CurrentPlanTab billingCustomer={billingCustomer} />,
      default: true,
      label: 'Current Plan',
      route: '/current-plan',
    },
    ...(billingCustomer?.stripePriceId
      ? [
          {
            // eslint-disable-next-line react/no-unstable-nested-components
            component: () => <CustomerPortalTab />,
            label: (
              <>
                Billing Details <Icon color="currentColor" name="copy" size="14px" />
              </>
            ),
            route: '/billing-details',
          },
        ]
      : []),
  ];

  return (
    <Elements stripe={stripePromise}>
      <LayoutMainGrid>
        <MetadataDecorator title="Billing Admin" />
        <TitleView as="h1" compSize="lg" mb={3} title="Billing" />
        <TabsRouter config={tabsRouterConfig} isLoading={isLoading} />
      </LayoutMainGrid>
    </Elements>
  );
};

export default AdminBillingPage;
