import React, { cloneElement, ReactNode, useState } from 'react';
import { Boundary, Padding, Placement, PositioningStrategy, Rect } from '@popperjs/core';
import { usePopper } from 'react-popper';

import Portal from '@components/Portal';
import theme from '@styles/theme';

import { PopperContainer, PopperContainerProps } from './Popper.styles';
import { RenderArrowProps } from './Popper.types';

export interface PopperProps extends PopperContainerProps {
  /**
   * The anchor element allows to calculate the proper tooltip position.
   * The proper way of getting opener element DOM reference is:
   * `const [anchorDomEl, setAnchorDomEl] = useState(null);`
   * Afterwords `setAnchorDomEl` should be attached to opener element as:
   * `ref={setAnchorDomEl}`.
   */
  anchorComp: React.ReactElement;
  arrowPadding?:
    | ((arg0: { placement: Placement; popper: Rect; reference: Rect }) => Padding)
    | number;
  boundary?: Boundary;
  boundaryPadding?: Padding;
  /** Sets additional classes to the container. */
  className?: string;
  /**
   * Defines fallback placements by providing a list of placements.
   * E.g.: if the popper has placement set to bottom, but there
   * isn't enough space to position the popper in that direction,
   * by default, it will change the popper placement to top.
   * Using this prop it is possible to customize that.
   * @see See {@link https://popper.js.org/docs/v2/modifiers/flip/#fallbackplacements}
   */
  fallbackPlacements?: Placement[];
  fitAnchorWidth?: boolean;
  flipPadding?: Padding;
  isOpen?: boolean;
  /** Sets offset from anchor element. */
  offset?: [number, number];
  /** Defines a popover position relative to the anchor element. */
  placement?: Placement;
  portalSelector?: string;
  renderArrow?: (props: RenderArrowProps) => ReactNode;
  /**
   * Describes the positioning strategy to use. By default, it is absolute,
   * which in the simplest cases does not require repositioning of the popper.
   * If your reference element is in a fixed container, use the fixed strategy
   */
  strategy?: PositioningStrategy;
  usePortal?: boolean;
}

/**
 * The component is responsible for rendering the "popper" element
 * in a proper place on the screen.
 * In fact, it a preconfigured wrapper over Popper.js library.
 * @see {@link https://popper.js.org/react-popper/v2/}
 */
const Popper: React.FC<PopperProps> = ({
  anchorComp,
  arrowPadding,
  boundary,
  boundaryPadding,
  children,
  className,
  containerWidth = 'auto',
  fallbackPlacements = ['top', 'left', 'right'],
  fitAnchorWidth,
  flipPadding,
  isOpen,
  maxWidth,
  offset = [0, 8],
  placement = 'bottom',
  portalSelector,
  renderArrow,
  strategy = 'absolute',
  usePortal = true,
}) => {
  const [popperDomEl, setPopperDomEl] = useState<HTMLDivElement | null>(null);
  const [anchorDomEl, setAnchorDomEl] = useState<HTMLDivElement | null>(null);
  const [arrowDomEl, setArrowDomEl] = useState(null);
  const { attributes, state, styles } = usePopper(anchorDomEl, popperDomEl, {
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowDomEl,
          padding: arrowPadding,
        },
      },
      {
        name: 'offset',
        options: {
          offset,
        },
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements,
          padding: flipPadding,
        },
      },
      {
        name: 'preventOverflow',
        options: {
          boundary,
          padding: boundaryPadding,
        },
      },
    ],
    placement,
    strategy,
  });

  const arrow = renderArrow
    ? renderArrow({
        'data-placement': attributes?.popper?.['data-popper-placement'] || placement,
        'data-popper-arrow': true,
        placement: state?.placement || placement,
        ref: setArrowDomEl,
        style: styles.arrow,
        ...attributes.arrow,
      })
    : null;

  return (
    <>
      {cloneElement(anchorComp, {
        ref: setAnchorDomEl,
        style: styles.reference,
        ...attributes.reference,
      })}
      {isOpen && anchorDomEl && (
        <Portal disable={!usePortal} selector={portalSelector}>
          <PopperContainer
            ref={setPopperDomEl}
            className={className}
            containerWidth={fitAnchorWidth ? `${anchorDomEl?.offsetWidth}px` : containerWidth}
            maxWidth={fitAnchorWidth ? `${anchorDomEl?.offsetWidth}px` : maxWidth}
            role="tooltip"
            style={{
              ...styles.popper,
              transform: styles.popper.transform,
              zIndex: theme.zIndex.floatingElement,
            }}
            {...attributes.popper}
          >
            {arrow}
            {children}
          </PopperContainer>
        </Portal>
      )}
    </>
  );
};

export default Popper;
