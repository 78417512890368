import React from 'react';

import Icon from '@components/UI/Icon';

import { Heading, Link, StyledError, Text, Wrapper } from './StyledError';

export default function UnexpectedError() {
  return (
    <StyledError>
      <Wrapper>
        <Heading>Sorry, something went wrong</Heading>
        <Text>
          We had a problem. This error will be automatically reported but please let us know if it
          persists.
        </Text>
        <Text>
          <Link href="/">
            Return Home <Icon color="currentColor" name="arrow-right" />
          </Link>
        </Text>
      </Wrapper>
    </StyledError>
  );
}
