import React from 'react';

import { LineageType } from '@api/lineage/types';
import { useFetchMetadataById } from '@api/metadata';
import AppTag from '@components/AppTag';
import Box from '@components/Box';
import Breadcrumbs from '@components/Breadcrumbs';
import Skeleton from '@components/Skeleton';
import Text from '@components/Text';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import { MetadataModel } from '@models/MetadataModel';
import theme from '@styles/theme';
import { urlFor } from '@utils/routing';

import {
  SidebarInfoDescription,
  SidebarInfoObjectInfo,
  SidebarInfoTitle,
} from './SidebarInfo.styles';

export interface SidebarInfoProps extends Partial<LineageType> {
  nodeKey?: string | null;
  onDataLoad?: (data: MetadataModel) => void;
  type: 'table' | 'column';
}

const SidebarInfo: React.FC<SidebarInfoProps> = ({ columns, nodeKey, onDataLoad, type }) => {
  const column = columns?.find((col) => col.key === nodeKey);
  const guid = type === 'table' ? nodeKey : column?.guid;

  const { data, isLoading } = useFetchMetadataById(guid ?? '', {
    enabled: Boolean(guid),
    onSuccess: (d) => {
      onDataLoad?.(d);
    },
  });

  const tags = data?.taggedItems?.map((item) => item.tag) || [];
  const dataTypes = data?.dataTypes;
  const name = data?.name || '';
  const fullTableName = data?.fullName || data?.name || '';
  const url = data?.isHidden ? '' : data?.routePath || (data && urlFor(data)) || '';

  if (isLoading) {
    return (
      <SidebarInfoObjectInfo>
        <Box alignItems="center" compDisplay="flex" mb={1} spacing={1}>
          <Skeleton ml={0.5} />
          <Skeleton compWidth="100px" variant="text" />
        </Box>
        <Box alignItems="center" compDisplay="flex" spacing={1}>
          <Skeleton compHeight="25px" compWidth="25px" />
          <Skeleton compWidth="180px" variant="text" />
        </Box>
      </SidebarInfoObjectInfo>
    );
  }

  return (
    <SidebarInfoObjectInfo>
      <Box alignItems="center" compDisplay="flex">
        <Tooltip content={dataTypes?.tooltips?.dataSource}>
          <Icon ml={0.65} mr={0.5} name={dataTypes?.icons.dataSource!} size="16px" />
        </Tooltip>
        <Breadcrumbs fontSize="14px" items={data?.breadcrumbList} />
      </Box>
      <SidebarInfoTitle>
        <Tooltip content={dataTypes?.tooltips?.dataType}>
          <Icon mr={0.5} name={dataTypes?.icons.dataType!} size="25px" />
        </Tooltip>
        {!url ||
        url === '#' ||
        dataTypes?.dataType === 'implicit' ||
        ['columngroup', 'tablegroup'].includes(dataTypes?.objectType!) ? (
          <span>{name}</span>
        ) : (
          <Tooltip action content={`Open ${fullTableName}`}>
            <a href={url} rel="noopener noreferrer" target="_blank">
              {name}
            </a>
          </Tooltip>
        )}
      </SidebarInfoTitle>
      <Box mx={-0.5}>
        {tags?.map((tag) => (
          <AppTag key={tag.guid} showBreadcrumbs tag={tag} variant="outlined" />
        ))}
      </Box>
      <SidebarInfoDescription>
        <Text
          display="block"
          fontSize={theme.typography.fontSizes.body1}
          lineHeight={theme.typography.lineHeights.default}
          wordBreak="normal"
        >
          {data?.description}
        </Text>
      </SidebarInfoDescription>
    </SidebarInfoObjectInfo>
  );
};

export default SidebarInfo;
