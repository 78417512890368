import React from 'react';
import { useDebouncedCallback } from 'use-debounce';

import invalidateCache from '@api/invalidateCache';
import { usePatchUserSettings } from '@api/settings';
import Button from '@components/Button/Button';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import { renderErrorToast, renderInfoToast } from '@components/Toast';
import InputLabel from '@components/UI/Form/InputLabel/InputLabel';
import { useUserContext } from '@context/User';

import {
  Section,
  SECTION_GRID_TEMPLATE,
  SectionInfo,
  SectionRows,
} from './AccountSettingsPage.styles';

interface ProfileProps {
  openChangePasswordModal: () => void;
  openClientApiModal: () => void;
  ssoFound?: boolean;
}
const Profile: React.FC<ProfileProps> = ({
  openChangePasswordModal,
  openClientApiModal,
  ssoFound,
}) => {
  const { hasEditPermissions, organization, user } = useUserContext();
  const { error, isLoading, mutate } = usePatchUserSettings({
    onError: () => {
      renderErrorToast("Couldn't save settings.");
    },
    onSuccess: () => {
      renderInfoToast('Changes saved.');
      invalidateCache((keys) => [
        keys.organizationUser.all,
        keys.organizationUser.organizationUser('me'),
        keys.user.me,
      ]);
    },
  });

  const { handleChange, values } = useForm({
    initialValues: {
      enableEmailNotifications: Boolean(user?.settings?.enableEmailNotifications),
      firstName: user?.firstName,
      lastName: user?.lastName,
    },
  });

  const update = useDebouncedCallback(() => {
    if (!isLoading && !error) {
      mutate({
        enable_email_notifications: values.enableEmailNotifications,
        first_name: values.firstName,
        last_name: values.lastName,
      });
    }
  }, 500);

  const handleFieldChange = (e: ChangeEvent) => {
    handleChange(e);
    update();
  };

  return (
    <SectionRows>
      <InputLabel
        compDisplay="grid"
        fontWeight="semibold"
        gridTemplateColumns={SECTION_GRID_TEMPLATE}
      >
        First Name
        <Input
          disabled={ssoFound}
          error={error?.data && error.data?.first_name}
          helperText={error?.data && error.data?.first_name}
          name="firstName"
          onChange={handleFieldChange}
          type="text"
          value={values.firstName}
        />
      </InputLabel>
      <InputLabel
        compDisplay="grid"
        fontWeight="semibold"
        gridTemplateColumns={SECTION_GRID_TEMPLATE}
      >
        Last Name
        <Input
          disabled={ssoFound}
          error={error?.data && error.data?.last_name}
          helperText={error?.data && error.data?.last_name}
          name="lastName"
          onChange={handleFieldChange}
          type="text"
          value={values.lastName}
        />
      </InputLabel>
      <Section>
        Email
        <SectionInfo>{user?.email}</SectionInfo>
      </Section>
      <Section>
        Team
        <SectionInfo>{user?.team?.name || 'No team assigned'}</SectionInfo>
      </Section>
      {hasEditPermissions && organization?.settings?.useClientApi && (
        <Section>
          Client API Token
          <Button onClick={openClientApiModal} variant="outlined">
            Manage API tokens
          </Button>
        </Section>
      )}
      {ssoFound && (
        <Section>
          Login
          <SectionInfo>Single Sign On</SectionInfo>
        </Section>
      )}
      {!ssoFound && (
        <Section>
          Password
          <Button onClick={openChangePasswordModal} variant="outlined">
            Change Password
          </Button>
        </Section>
      )}
    </SectionRows>
  );
};

export default Profile;
