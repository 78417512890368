import styled from '@emotion/styled';

import Box from '@components/Box';

interface StyledChatBotMessageProps {
  error?: boolean;
}

export const StyledChatBotMessageWrapper = styled(Box)<StyledChatBotMessageProps>`
  border-radius: ${({ theme }) => theme.radius.lg};
  border-top-left-radius: 0;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ error, theme }) => (error ? theme.colors.error : 'transparent')};
`;
