export const all = ['lookML'];

export const project = (id: string) => ['lookML', 'projects', id];

export const projects = (id: string) => ['lookML', id, 'projects'];

export const view = (id: string) => ['lookML', 'views', id];

export const viewFrequentDsUsers = (id: string) => ['lookML', 'views', id, 'frequentDsUsers'];

export const viewRelatedDashboards = (id: string) => ['lookML', 'views', id, 'relatedDashboards'];

export const viewRelatedDashboardsCsv = (id: string) => [
  'lookML',
  'views',
  id,
  'relatedDashboards',
  'csv',
];

export const views = ['lookML', 'views'];
