import { Expose, Transform, Type } from 'class-transformer';
import moment from 'moment';

import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import { TagModel } from '@api/tags/TagModel';
import { DataType, ObjectType } from '@api/types';
import { breadcrumbsToList } from '@components/Breadcrumbs';
import DataTypesModel from '@models/DataTypesModel';

class AnalyticsPageModel {
  static objectType: string = 'analyticspage';

  objectType: string = AnalyticsPageModel.objectType;

  @Expose({ name: 'page' })
  pageName!: string;

  @Expose({ name: 'page_guid' })
  pageGuid!: string;

  @Expose({ name: 'page_object_type' })
  pageObjectType!: ObjectType;

  @Expose({ name: 'page_data_type' })
  pageDataType!: DataType;

  @Expose({ name: 'page_parent_guid' })
  pageParentGuid?: string;

  count!: number;

  @Expose({ name: 'page_description' })
  description!: string;

  /** @deprecated. Use TaggedItemModel instead.  */
  @Type(() => TagModel)
  tags?: TagModel[];

  @Expose({ name: 'last_activity' })
  @Transform((value) => moment(value))
  lastEdited?: moment.Moment;

  @Expose({ name: 'data_types' })
  @Type(() => DataTypesModel)
  dataTypes?: DataTypesModel = new DataTypesModel();

  @Type(() => BreadcrumbModel)
  breadcrumbs?: BreadcrumbModel[];

  get breadcrumbList() {
    return breadcrumbsToList(this.breadcrumbs);
  }
}

export default AnalyticsPageModel;
