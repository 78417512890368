import paginatedTransform from '@api/paginatedTransform';
import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import useMutation, { UseMutationOptions } from '@hooks/useMutation';
import { DataSourceModel } from '@models/DataSourceModel';
import { PaginatedResponse } from '@models/PaginatedResponse';

import rawTransform from '../rawTransform';

import { PullRequestModel } from './PullRequestModel';
import { RepositoryModel, RepositoryNextExecutionModel } from './RepositoryModel';

export const syncBackCacheKeys = {
  availableDataSources: ['syncBack', 'dataSources'],
  pullRequests: ['syncBack', 'pullRequests'],
  repositories: ['syncBack', 'repositories'],
  repository: (dsGuid: string) => ['syncBack', 'repositories', dsGuid],
  repositoryNextExecution: ['syncBack', 'repositories', 'nextExecution'],
  syncBack: ['syncBack'],
};

const fetchSyncBackRepositoriesSelect = paginatedTransform(RepositoryModel);
export const useFetchSyncBackRepositories = (
  options?: UseFetchOptions<PaginatedResponse<RepositoryModel>>,
) => {
  return useFetch<PaginatedResponse<RepositoryModel>>({
    ...options,
    queryKey: [...syncBackCacheKeys.repositories, options?.params],
    select: fetchSyncBackRepositoriesSelect,
    url: '/sync-back/repositories/',
  });
};

const fetchSyncBackRepositorySelect = rawTransform(RepositoryModel);
export const useFetchSyncBackRepository = (
  dsGuid: string,
  options?: UseFetchOptions<RepositoryModel>,
) => {
  return useFetch<RepositoryModel>({
    ...options,
    queryKey: syncBackCacheKeys.repository(dsGuid),
    select: fetchSyncBackRepositorySelect,
    url: `/sync-back/repositories/${dsGuid}/`,
  });
};

const fetchSyncBackRepositoryNextExecutionSelect = rawTransform(RepositoryNextExecutionModel);
export const useFetchSyncBackRepositoryNextExecution = (
  options?: UseFetchOptions<RepositoryNextExecutionModel>,
) => {
  return useFetch<RepositoryNextExecutionModel>({
    ...options,
    queryKey: syncBackCacheKeys.repositoryNextExecution,
    select: fetchSyncBackRepositoryNextExecutionSelect,
    url: '/sync-back/repositories/next-sync-back-execution/',
  });
};

const fetchSyncBackPullRequestsSelect = paginatedTransform(PullRequestModel);
export const useFetchSyncBackPullRequests = (
  options?: UseFetchOptions<PaginatedResponse<PullRequestModel>>,
) => {
  return useFetch<PaginatedResponse<PullRequestModel>>({
    ...options,
    queryKey: [...syncBackCacheKeys.pullRequests, options?.params],
    select: fetchSyncBackPullRequestsSelect,
    url: `/sync-back/pull-requests/`,
  });
};

const fetchSyncBackAvailableDataSourcesSelect = rawTransform(DataSourceModel);
export const useFetchSyncBackAvailableDataSources = (
  options?: UseFetchOptions<DataSourceModel[]>,
) => {
  return useFetch<DataSourceModel[]>({
    ...options,
    queryKey: [...syncBackCacheKeys.availableDataSources, options?.params],
    select: fetchSyncBackAvailableDataSourcesSelect,
    url: `/sync-back/repositories/available-data-sources/`,
  });
};

export const usePostSyncBackRepository = (options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    method: 'POST',
    url: '/sync-back/repositories/',
  });
};

export const useDeleteSyncBackRepository = (id: string, options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    method: 'DELETE',
    url: `/sync-back/repositories/${id}/`,
  });
};

export const usePatchSyncBackRepository = (id: string, options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    method: 'PATCH',
    url: `/sync-back/repositories/${id}/`,
  });
};
