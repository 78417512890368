import React from 'react';

import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';

const Labels = {
  dimension: {
    icon: 'dimension',
    tooltip: 'Dimension',
  },
  field_set: {
    icon: 'fieldset',
    tooltip: 'Field Set',
  },
  measure: {
    icon: 'measure',
    tooltip: 'Measure',
  },
} as const;

interface GetLabelArgs {
  dimension?: boolean;
  field_set?: boolean;
  measure?: boolean;
}

const getLabel = ({ dimension, field_set, measure }: GetLabelArgs) => {
  if (dimension) {
    return Labels.dimension;
  }
  if (measure) {
    return Labels.measure;
  }
  if (field_set) {
    return Labels.field_set;
  }
  return undefined;
};

interface LabelProps {
  dimension?: boolean;
  field_set?: boolean;
  measure?: boolean;
}

const Label: React.FC<LabelProps> = ({ dimension, field_set, measure }) => {
  const label = getLabel({ dimension, field_set, measure });

  return label ? (
    <Tooltip content={label.tooltip}>
      <Icon mr={0.5} name={label.icon} size="22px" />
    </Tooltip>
  ) : null;
};

export default React.memo(Label);
