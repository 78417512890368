import featureFlagsList from './featureFlagsList';

const flagsToArray = (flagsString: string): string[] => {
  return flagsString.toLowerCase().split(',').filter(Boolean);
};

export const defaultFlagState = (flagValue: string) => {
  const envVarName = `react_app_ff_${flagValue}`.toLocaleUpperCase();
  return window.env[envVarName] === 'on';
};

export const localFlagsToObject = (): any => {
  const localFlags = localStorage.getItem('flags') || '';
  const localFlagsArray = flagsToArray(localFlags);
  const selectedFlags: { [key: string]: boolean } = {};

  featureFlagsList.forEach((flag) => {
    if (localFlagsArray.indexOf(flag.value) >= 0 || defaultFlagState(flag.value)) {
      selectedFlags[flag.value] = true;
    } else {
      selectedFlags[flag.value] = false;
    }
  });

  return selectedFlags;
};
