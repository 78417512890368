import React from 'react';

import { useTeamsSections } from '@components/AppMainSidebar/TeamsMenu';
import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import LayoutMainGrid from '@components/Layout/LayoutMainGrid';
import MetadataGrid from '@components/MetadataGrid';
import MetadataGridCard from '@components/MetadataGridCard';
import PageHeaderBar from '@components/PageHeaderBar';
import Text from '@components/Text';
import Avatar from '@components/UI/Avatar';
import AvatarGroup from '@components/UI/AvatarGroup';
import Icon from '@components/UI/Icon';
import Image from '@components/UI/Image';
import useNewLayout from '@hooks/useNewLayout';
import theme from '@styles/theme';
import MetadataDecorator from '@utils/MetadataDecorator';
import pluralize from '@utils/pluralize';
import { urlFor } from '@utils/routing';

const TeamsPage: React.FC = () => {
  const { shouldUseNewLayout } = useNewLayout();
  const { isLoading, sections, teams } = useTeamsSections();

  return (
    <>
      <MetadataDecorator title="Teams" />
      <LayoutMainGrid>
        {!shouldUseNewLayout && <PageHeaderBar title="Teams" />}
        {!isLoading && teams.length === 0 ? (
          <Box
            alignItems="center"
            compDisplay="flex"
            compWidth="100%"
            flexDirection="column"
            gap={1}
            mt={30}
          >
            <Image alt="No chats" height="auto" src="/images/no-content.svg" width="120px" />
            <Text color="gray.500" fontSize="body2" textAlign="center">
              You have no teams yet
            </Text>
          </Box>
        ) : (
          sections.map((section) => (
            <Box key={section.key} mb={shouldUseNewLayout ? 4 : 0}>
              <Box
                fontSize={theme.typography.h4.fontSize}
                fontWeight="medium"
                mb={2.5}
                mt={shouldUseNewLayout ? 0 : 4}
              >
                {section.title}
              </Box>
              {isLoading && <CircularLoader centered compSize={4} p={5} />}
              {!isLoading && section.isEmpty && <Box>{section.emptyMessage}</Box>}
              {!isLoading && !section.isEmpty && (
                <MetadataGrid data={section.teams} isLoading={isLoading}>
                  {(item) => (
                    <MetadataGridCard
                      data-testid={`tag-card-${item.guid}`}
                      description={item.description}
                      footer={
                        <Box>
                          <Box
                            alignItems="center"
                            color={theme.colors.gray[600]}
                            compDisplay="flex"
                            gap={0.5}
                            mb={1.5}
                          >
                            <Icon color="currentColor" name="team" size="16px" />
                            {pluralize(item?.membersCount, 'Member')}
                          </Box>
                          <AvatarGroup
                            compHeight="24px"
                            items={item.sampleUsers?.map((user) => user.mappedAvatar)}
                            size="24px"
                          />
                        </Box>
                      }
                      icon={<Avatar {...item.mappedAvatar} size="24px" />}
                      iconColor={item.color}
                      minHeight="180px"
                      name={item.name}
                      url={urlFor(item)}
                    />
                  )}
                </MetadataGrid>
              )}
            </Box>
          ))
        )}
      </LayoutMainGrid>
    </>
  );
};

export default TeamsPage;
