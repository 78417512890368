import styled from '@emotion/styled';

export const ARROW_WIDTH = 16;
export const MINI_TOOLBAR_ITEM_WIDTH = 32;
export const MINI_TOOLBAR_PADDING = 24;
export const MINI_TOOLBAR_WIDTH = 240;

export const MiniToolbarWrapper = styled.div`
  width: ${MINI_TOOLBAR_WIDTH};
  padding: ${({ theme }) => theme.space(1)};
  z-index: ${({ theme }) => theme.zIndex.floatingElement};
  background: ${({ theme }) => theme.colors.gray[700]};
  border-radius: ${({ theme }) => theme.radius.default};
  transition: all 0.065s cubic-bezier(0.2, 0.75, 0.1, 0.5) 0s;
  display: flex;
`;

interface MiniToolbarControlsButtonProps {
  isActive: boolean;
}

export const MiniToolbarControlsButton = styled.button<MiniToolbarControlsButtonProps>`
  border-radius: ${({ theme }) => theme.radius.default};
  color: ${({ isActive, theme }) => (isActive ? theme.colors.gray[700] : theme.colors.white)};
  background-color: ${({ isActive, theme }) => (isActive ? theme.colors.white : 'none')};
  margin: ${({ theme }) => theme.space(0, 1, 0, 0)};
  width: ${({ theme }) => theme.space(3)};
  border: ${({ theme }) => `1px solid ${theme.colors.gray[700]}`};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;

  &:hover {
    border-color: ${({ theme }) => theme.colors.white};
  }

  svg {
    display: block;
    fill: currentColor;

    path,
    rect {
      transition: fill 0.1s ease;
      fill: currentColor;
    }
  }
`;

export const ArrowStyled = styled.div`
  width: 0;
  height: 0;
  border-left: ${(ARROW_WIDTH / 4) * 3}px solid transparent;
  border-right: ${(ARROW_WIDTH / 4) * 3}px solid transparent;
  bottom: -12px;
  border-top: ${({ theme }) => `${ARROW_WIDTH}px solid ${theme.colors.gray[700]}`};
  z-index: -1;
`;
