import React from 'react';

import Box from '@components/Box/Box.styles';
import Breadcrumbs, { BreadcrumbItem } from '@components/Breadcrumbs';
import IconButton from '@components/IconButton';
import PopularityCell from '@components/Table/Cells/PopularityCell';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import { ValidDSType } from '@models/DataSourceCredentials';
import { PopularityModel } from '@models/PopularityModel';

import { StyledDownstreamChartsTabParentWrapper } from './DownstreamChartsTab.styles';
import type { DownstreamChartsTabDropdownProps } from './DownstreamChartsTabDropdown';
import DownstreamChartsTabDropdown from './DownstreamChartsTabDropdown';
import DownstreamChartsTabRow from './DownstreamChartsTabRow';

interface DownstreamChartsTabDashboardRowProps
  extends Pick<DownstreamChartsTabDropdownProps, 'dataTypes'> {
  breadcrumbs: BreadcrumbItem[];
  copyPath: string;
  dataSourceType?: ValidDSType;
  externalUrl: string;
  isOpen: boolean;
  isToggleDisabled: boolean;
  onToggle: () => void;
  popularity?: PopularityModel;
  routePath: string;
}

const DownstreamChartsTabDashboardRow: React.FC<DownstreamChartsTabDashboardRowProps> = ({
  breadcrumbs,
  copyPath,
  dataSourceType,
  dataTypes,
  externalUrl,
  isOpen,
  isToggleDisabled,
  onToggle,
  popularity,
  routePath,
}) => {
  return (
    <StyledDownstreamChartsTabParentWrapper>
      <DownstreamChartsTabRow
        firstColumn={
          <Box alignItems="center" compDisplay="flex">
            <IconButton disabled={isToggleDisabled} onClick={onToggle} variant="clear">
              <Icon color="#0C3476" name={isOpen ? 'down' : 'right'} size="16px" />
            </IconButton>
            <Box alignItems="center" compDisplay="flex" flexGrow="1">
              <Box alignItems="center" compDisplay="flex" flexShrink={0} mr={0.5}>
                <Tooltip content={dataTypes?.tooltips.dataSource}>
                  <Icon name={dataTypes?.icons.dataSource!} size="16px" />
                </Tooltip>
                <Tooltip content={dataTypes?.tooltips.dataType}>
                  <Icon name={dataTypes?.icons.dataType!} size="16px" />
                </Tooltip>
              </Box>
              <Box mr={0.25}>
                <Breadcrumbs
                  clickable={false}
                  color="inherit"
                  fontSize="inherit"
                  fontWeight="normal"
                  items={breadcrumbs}
                  showTrailingSlash={false}
                />
              </Box>
              <DownstreamChartsTabDropdown
                copyPath={copyPath}
                dataSourceType={dataSourceType}
                dataTypes={dataTypes}
                externalUrl={externalUrl}
                routePath={routePath}
              />
            </Box>
          </Box>
        }
        secondColumn={popularity && <PopularityCell popularity={popularity} />}
      />
    </StyledDownstreamChartsTabParentWrapper>
  );
};

export default DownstreamChartsTabDashboardRow;
