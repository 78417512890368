import React from 'react';

import { useFetchDsUsers } from '@api/dsusers';
import { AccordionInputItem } from '@components/Accordion';
import Box from '@components/Box';
import { getDsUsersOptionsV1 } from '@components/Dropdown/helpers';
import Skeleton from '@components/Skeleton';
import { DataSourceTypesType } from '@models/DataSourceCredentials';
import stripSpaces from '@utils/stripSpaces';

const query = stripSpaces(`{
  guid,
  name,
  display_name,
  data_source {
    guid,
    type
    }
  }
`);

interface CreatedByUsersProps {
  type: DataSourceTypesType | null | undefined;
  update: (guid: string) => void;
}

const CreatedByUsers: React.FC<CreatedByUsersProps> = ({ type = 'mode', update }) => {
  const { data, isLoading } = useFetchDsUsers({
    params: {
      created_by_type: 'dashboards',
      query,
      type,
    },
  });

  if (isLoading) {
    return (
      <Box mt={2}>
        <Skeleton compWidth="60%" />
      </Box>
    );
  }

  if (!data) {
    return null;
  }

  const options = getDsUsersOptionsV1(data.results);

  return (
    <AccordionInputItem
      loading={isLoading}
      options={options}
      title="Created By"
      update={(value) => update(value)}
    />
  );
};

export default CreatedByUsers;
