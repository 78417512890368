import paginatedTransform from '@api/paginatedTransform';
import { TaggedItemModel } from '@api/tags/TaggedItemModel';
import type { UseFetchOptions } from '@hooks/useFetch';
import useFetch from '@hooks/useFetch';
import useMutation, { UseMutationOptions } from '@hooks/useMutation';
import { PaginatedResponse } from '@models/PaginatedResponse';

import * as cacheKeys from './cacheKeys';

type TaggedItemsPaginatedResponse = {
  object_type_counts: { [type: string]: number };
} & PaginatedResponse<TaggedItemModel>;

const fetchTaggedItemsSelect = paginatedTransform(TaggedItemModel);
export const useFetchTaggedItems = (
  id: string,
  options?: UseFetchOptions<TaggedItemsPaginatedResponse>,
) => {
  return useFetch<TaggedItemsPaginatedResponse>({
    ...options,
    queryKey: [...cacheKeys.taggedItem(id), options?.params],
    select: fetchTaggedItemsSelect,
    url: `/tags/items/${id}/`,
  });
};

export const usePatchTaggedItem = (id?: string, options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    method: 'PATCH',
    url: id ? `/tagged-items/${id}/` : undefined,
  });
};
