import type { UseFetchOptions } from '@hooks/useFetch';
import useFetch from '@hooks/useFetch';

import rawTransform from '../rawTransform';

import * as cacheKeys from './cacheKeys';
import { FolderModel } from './FolderModel';

export const fetchFolderSelect = rawTransform(FolderModel);
export const useFetchFolder = (id: string, options?: UseFetchOptions<FolderModel>) => {
  return useFetch<FolderModel>({
    ...options,
    queryKey: cacheKeys.folder(id),
    select: fetchFolderSelect,
    url: `/folders/${id}/`,
  });
};
