import styled from '@emotion/styled';

const DatabaseListStyled = styled.ul`
  li {
    padding: 0.5rem 1.25rem;
    margin: 0.25rem 0;
    border-radius: 6px;
    border: solid 1px #e2e9f0;
  }
`;

export default DatabaseListStyled;
