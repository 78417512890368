import React, { useMemo } from 'react';

import type { ColumnModel } from '@api/columns/ColumnModel';
import { useFetchIndexes } from '@api/indexes';
import Box from '@components/Box';
import Code from '@components/UI/Code';
import stripSpaces from '@utils/stripSpaces';

const query = stripSpaces(`{
  definition,
  guid,
  name,
  conditions{
    column{
      guid
    }
  }
}`);

interface ColumnTableIndexCellProps {
  columnItem?: ColumnModel;
}
/**
 * @todo sc-69420
 * add index information to columns API to avoid fetching separately
 */

const ColumnTableIndexCell: React.FC<ColumnTableIndexCellProps> = ({ columnItem }) => {
  const { data } = useFetchIndexes({
    params: { query, tables: columnItem?.parentGuid },
  });

  const indexes = useMemo(
    () =>
      data?.results.filter(({ conditions }) =>
        conditions?.some(({ column }) => column.guid === columnItem?.guid),
      ),
    [columnItem?.guid, data?.results],
  );

  return (
    <Box compDisplay="flex" flexWrap="wrap" gap={0.5} noDefault py={0.5}>
      {indexes?.map(({ definition, guid, name }) => (
        <Code key={guid} text={definition}>
          {name}
        </Code>
      ))}
    </Box>
  );
};

export default ColumnTableIndexCell;
