import React from 'react';

import type { StyledBoxProps } from '@components/Box';
import Box from '@components/Box';
import Text from '@components/Text';
import Image from '@components/UI/Image';
import useNewLayout from '@hooks/useNewLayout';

interface ImageMessageProps extends StyledBoxProps {
  message: React.ReactNode;
  src: string;
}
const ImageMessage: React.FC<ImageMessageProps> = ({ message, src, ...rest }) => {
  const { shouldUseNewLayout } = useNewLayout();

  return (
    <Box
      alignItems="center"
      compDisplay="flex"
      flexDirection="column"
      gap={shouldUseNewLayout ? 5 : 1}
      justifyContent="start"
      mb={2}
      mt={3}
      {...rest}
    >
      <Image
        alt="No content"
        height="auto"
        src={src}
        width={shouldUseNewLayout ? '242px' : '120px'}
      />
      {message && (
        <Text
          color={shouldUseNewLayout ? 'gray.600' : 'gray.500'}
          fontSize={shouldUseNewLayout ? 'h1' : 'body2'}
          fontWeight={shouldUseNewLayout ? 'bold' : 'regular'}
        >
          {message}
        </Text>
      )}
    </Box>
  );
};

export default ImageMessage;
