import paginatedTransform from '@api/paginatedTransform';
import rawTransform from '@api/rawTransform';
import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import useMutation, { UseMutationOptions } from '@hooks/useMutation';
import { PaginatedResponse } from '@models/PaginatedResponse';

import { NotificationModel } from './NotificationModel';

export const notificationsCacheKeys = {
  notifications: ['notifications'],
};

const fetchNotificationsSelect = paginatedTransform(NotificationModel);
export const useFetchNotifications = (
  options?: UseFetchOptions<PaginatedResponse<NotificationModel>>,
) => {
  return useFetch<PaginatedResponse<NotificationModel>>({
    ...options,
    queryKey: [...notificationsCacheKeys.notifications, options?.params],
    select: fetchNotificationsSelect,
    url: '/notifications/',
  });
};

const patchAllNotificationsAsReadSelect = paginatedTransform(NotificationModel);
export const usePatchAllNotificationsAsRead = (
  options?: UseMutationOptions<PaginatedResponse<NotificationModel>>,
) => {
  return useMutation<PaginatedResponse<NotificationModel>>({
    ...options,
    method: 'PATCH',
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(patchAllNotificationsAsReadSelect(data), variables, context);
    },
    url: '/notifications/mark-all-as-read/',
  });
};

const patchNotificationAsReadSelect = rawTransform(NotificationModel);
export const usePatchNotificationAsRead = (
  id: string,
  options?: UseMutationOptions<NotificationModel>,
) => {
  return useMutation<NotificationModel>({
    ...options,
    method: 'PATCH',
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(patchNotificationAsReadSelect(data), variables, context);
    },
    url: `/notifications/mark-as-read/${id}/`,
  });
};
