import styled from '@emotion/styled';

interface StyledTagTableProps {
  gap?: number;
}

export const StyledTagTable = styled.div<StyledTagTableProps>`
  align-items: center;
  background: #f2f5f9;
  border-radius: ${({ theme }) => theme.radius.default};
  color: ${({ theme }) => theme.colors.violet};
  display: inline-flex;
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  font-size: ${({ theme }) => theme.typography.fontSizes.xs};
  font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
  height: 20px;
  line-height: 1;
  margin-bottom: ${({ theme }) => theme.space(0.1)};
  margin-left: ${({ theme }) => theme.space(0.25)};
  padding: ${({ theme }) => theme.space(0.25, 0.5)};
  user-select: none;
  white-space: nowrap;
  gap: ${({ gap, theme }) => (gap ? theme.space(gap) : undefined)};

  &:focus {
    outline: none;
  }
`;
