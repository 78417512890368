import React from 'react';

import useNewLayout from '@hooks/useNewLayout';

import AppMainSidebarV1 from '../AppMainSidebar/AppMainSidebar.v1';

import SettingsSidebarV0 from './SettingsSidebar';

const SettingsSidebar: React.FC = () => {
  const { shouldUseNewLayout } = useNewLayout();

  return shouldUseNewLayout ? <AppMainSidebarV1 /> : <SettingsSidebarV0 />;
};

export default SettingsSidebar;
