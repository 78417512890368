import styled from '@emotion/styled';

import Box from '@components/Box';
import IconButton from '@components/IconButton';
import Link from '@components/Link';

export const StyledChatBotHierarchyItemIconButton = styled(IconButton)`
  display: none;
  flex-shrink: 0;
`;

export const StyledChatBotHierarchyItem = styled(Box)`
  &:hover {
    ${StyledChatBotHierarchyItemIconButton} {
      display: block;
    }
  }
`;

export const StyledChatBotHierarchyItemLink = styled(Link)`
  flex-grow: 1;
`;
