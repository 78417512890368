import React from 'react';

import { useFetchTeamMostQueriedDashboards } from '@api/teams';
import { useFetchUserMostQueriedDashboards } from '@api/users';
import TabError from '@components/TabContent/TabError';
import MostUsedTable from '@components/Table/MostUsedTable';
import { DataSourceTypesType } from '@models/DataSourceCredentials';
import { ProfileType } from '@pages/ProfilePage/ProfilePage.types';

const defaultConfig = {
  params: {
    page_size: 20,
  },
};

export interface MostQueriedSubTabProps {
  dataSourceType?: DataSourceTypesType;
  guid: string;
  profileType: ProfileType;
}

const MostQueriedSubTabDashboards: React.FC<MostQueriedSubTabProps> = ({
  dataSourceType,
  guid,
  profileType,
}) => {
  const {
    data: userDashboardResponse,
    isError: userDashboardError,
    isLoading: userDashboardLoading,
  } = useFetchUserMostQueriedDashboards(guid, {
    ...defaultConfig,
    enabled: profileType === 'user',
  });

  const {
    data: teamDashboardResponse,
    isError: teamDashboardError,
    isLoading: teamDashboardLoading,
  } = useFetchTeamMostQueriedDashboards(guid, {
    ...defaultConfig,
    enabled: profileType === 'team',
  });

  const data = userDashboardResponse || teamDashboardResponse;
  const isLoading = userDashboardLoading || teamDashboardLoading;
  const error = userDashboardError || teamDashboardError;

  if (error) return <TabError />;

  return (
    <MostUsedTable
      data={data?.results}
      dataSourceType={dataSourceType}
      itemCount={data?.results?.length}
      loading={isLoading}
    />
  );
};

export default MostQueriedSubTabDashboards;
