import React from 'react';

import Box from '@components/Box';
import Text from '@components/Text';
import Toggle from '@components/Toggle';
import theme from '@styles/theme';

import { AppSettingsInputOptionTitle } from './AppSettingsInput/AppSettingsInput.styles';
import { StyledUserSettingsPageInputContainer } from './UserSettingsPage.styles';

interface UserSettingsInputProps {
  checked: boolean;
  onChange: (newValue: boolean) => void;
  subtitle?: string;
  title: string;
}

const UserSettingsInput = ({ checked, onChange, subtitle, title }: UserSettingsInputProps) => {
  return (
    <StyledUserSettingsPageInputContainer>
      <Box compDisplay="flex" flexDirection="column" gap={0.5}>
        <AppSettingsInputOptionTitle>{title}</AppSettingsInputOptionTitle>
        {subtitle && (
          <Text as="h4" color="gray.600" fontSize="body2" lineHeight={theme.space(2.5)} m={0}>
            {subtitle}
          </Text>
        )}
      </Box>
      <Toggle checked={checked} onChange={() => onChange(!checked)} />
    </StyledUserSettingsPageInputContainer>
  );
};

export default UserSettingsInput;
