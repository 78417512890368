import styled from '@emotion/styled';

interface Props {
  maxHeight?: string;
  scroll?: boolean;
}

const BaseTableStyled = styled.div<Props>`
  max-height: ${({ maxHeight }) => maxHeight};
  overflow: ${({ scroll }) => (scroll ? 'auto' : '')};

  .ui.table {
    width: ${({ scroll }) => (scroll ? 'auto' : '100%')};
    min-width: ${({ scroll }) => (scroll ? '100%' : 'unset')};
    color: ${({ theme }) => theme.colors.text};

    thead th {
      background-color: #fff;
    }

    tr {
      &.selected-row {
        background-color: #f8fafb;
      }

      th,
      td {
        font-size: 0.8125rem !important;
        border-left: none !important;
        border-top: none !important;
        border-bottom: 1px solid #d8d8d8;
      }

      th,
      th.sorted {
        color: ${({ theme }) => theme.colors.text};
      }

      td {
        max-width: 100%;
        -webkit-box-orient: vertical;
        padding-top: 0;
        padding-bottom: 0;

        &:not(.description):not(.user):not(.datasource):not(.status):not(.ss_tag):not(
            .configure
          ):not(.dbt_tag) {
          line-height: 47px;
          height: 47px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .content {
          font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
          color: ${({ theme }) => theme.colors.text};

          &.inline {
            padding-left: 0.5rem;
          }
        }

        .avatar {
          width: 1.25rem;
          height: 1.25rem;
          border-radius: 50% !important;
          margin-right: 0;
        }

        h4.ui.header {
          font-size: 0.8125rem !important;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          a {
            div.hovered-content {
              &:hover {
                font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
              }
            }
          }
        }
      }
    }

    &.sticky {
      thead tr > th {
        background-color: #fff;

        &:hover {
          background-color: #f2f2f2;
        }
      }
    }
  }

  .ui.header {
    font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  }

  .ui[class*='very basic'].table:not(.sortable):not(.striped) td:first-of-type,
  .ui[class*='very basic'].table:not(.sortable):not(.striped) th:first-of-type {
    padding-left: 0.7em;
  }

  .ui.pagination {
    .item,
    .active.item {
      color: ${({ theme }) => theme.colors.text};
    }
  }

  div.ui.mini.pagination.secondary.menu {
    display: flex;
    justify-content: center;
  }
`;

export default BaseTableStyled;
