import { Editor } from 'slate';

import { BasePoints } from '../RichTextEditor.types';

import calculateCharsSelection from './calculateCharsSelection';
import { INLINE_SHORTCUTS } from './shortcuts';

const getMDInlineShortcut = (editor: Editor, { anchor, focus }: BasePoints) => {
  const shortcutWithOneChar = Editor.string(
    editor,
    calculateCharsSelection(
      {
        distanceFromAnchor: 1,
        distanceFromFocus: 0,
      },
      { anchor, focus },
      { direction: 'left' },
    ),
  );
  const shortcutWithTwoChars = Editor.string(
    editor,
    calculateCharsSelection(
      {
        distanceFromAnchor: 2,
        distanceFromFocus: 0,
      },
      { anchor, focus },
      { direction: 'left' },
    ),
  );

  if (INLINE_SHORTCUTS[shortcutWithTwoChars]) {
    return { type: INLINE_SHORTCUTS[shortcutWithTwoChars], key: shortcutWithTwoChars };
  }
  if (INLINE_SHORTCUTS[shortcutWithOneChar]) {
    return { type: INLINE_SHORTCUTS[shortcutWithOneChar], key: shortcutWithOneChar };
  }
  return null;
};

export default getMDInlineShortcut;
