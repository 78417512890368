import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Box from '@components/Box';
import rootTheme from '@styles/theme';

type Variant = 'default';

const defaultVariant = 'default';

export type VariantProps = {
  variant?: Variant;
};

const StyledStatSummaryBarVariants: Record<Variant, SerializedStyles> = {
  default: css`
    border: 1px solid ${rootTheme.colors.v1.gray[200]};
    border-radius: ${rootTheme.radius.md};
  `,
};

export const StyledStatSummaryBar = styled(Box)<VariantProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  min-height: 10rem;
  gap: ${({ theme }) => theme.space(2.25)};
  padding: ${({ theme }) => theme.space(3.5, 2)};

  ${({ theme }) => theme.media.up('sm')`
    flex-wrap: nowrap;
  `}

  ${({ variant = defaultVariant }) => StyledStatSummaryBarVariants[variant]}
`;

type NumberVariant = 'invalid';

const StyledStatSummaryBarNumberVariants: Record<Variant | NumberVariant, SerializedStyles> = {
  default: css`
    color: ${rootTheme.colors.v1.gray[700]};
    font-weight: ${rootTheme.typography.fontWeights.semibold};
  `,
  invalid: css`
    color: ${rootTheme.colors.v1.gray[500]};
  `,
};

export type StyledStatSummaryBarNumberProps = {
  variant?: Variant | NumberVariant;
};

export const StyledStatSummaryBarNumber = styled(Box)<StyledStatSummaryBarNumberProps>`
  text-align: center;
  font-size: 30px;
  line-height: 1.1em;

  ${({ variant = defaultVariant }) => StyledStatSummaryBarNumberVariants[variant]}
`;

const StyledStatSummaryBarDescriptionVariants: Record<Variant, SerializedStyles> = {
  default: css`
    color: ${rootTheme.colors.v1.gray[600]};
  `,
};

export const StyledStatSummaryBarDescription = styled(Box)<VariantProps>`
  display: flex;
  align-items: center;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  gap: ${({ theme }) => theme.space(0.5)};

  ${({ variant = defaultVariant }) => StyledStatSummaryBarDescriptionVariants[variant]}

  &:hover {
    font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
  }
`;
