import * as Sentry from '@sentry/react';

import DataTypesModel from '@models/DataTypesModel';
import replaceAll from '@utils/replaceAll';
import InvalidDataException from '@utils/sentry/errors/invalidDataException';

import { NO_LINK, URL_CONFIG } from './config';

interface GetRouteUrlProps {
  dataTypes?: DataTypesModel;
  defaultUrl?: string;
  guid: string;
  parentGuid?: string;
}

interface CaptureSentryErrorProps extends Omit<GetRouteUrlProps, 'defaultUrl'> {
  message: string;
  template?: string;
}

const captureSentryException = ({
  dataTypes,
  guid,
  message,
  parentGuid,
  template,
}: CaptureSentryErrorProps) => {
  Sentry.captureException(new InvalidDataException(message), {
    extra: {
      dataTypes,
      guid,
      parentGuid,
      template,
    },
    tags: {
      guid,
      parentGuid,
      template,
    },
  });
};

const getRouteUrl = (props: GetRouteUrlProps): string => {
  const { dataTypes, defaultUrl = NO_LINK, guid, parentGuid } = props;
  const template = dataTypes?.findConfig<string>(URL_CONFIG) ?? NO_LINK;

  if (!template) {
    captureSentryException({
      message: 'Invalid makeUrl arguments: no template found',
      template,
      ...props,
    });
  }

  if (template && template.includes('{parentGuid}') && !parentGuid) {
    captureSentryException({
      message: `Invalid makeUrl arguments (template: ${template})`,
      template,
      ...props,
    });
  }

  if (!template || !guid || (template.includes('{parentGuid}') && !parentGuid)) {
    return defaultUrl;
  }

  const dataTypesParams = dataTypes
    ? new URLSearchParams({
        dataSourceType: dataTypes.dataSourceType,
        objectType: dataTypes.objectType,
        ...(dataTypes.dataType && { dataType: dataTypes.dataType }),
      }).toString()
    : '';

  return replaceAll(template, { dataTypesParams, guid, parentGuid });
};

export default getRouteUrl;
