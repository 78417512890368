import React from 'react';

import Box from '@components/Box';
import Text from '@components/Text';
import Avatar from '@components/UI/Avatar';
import Image from '@components/UI/Image';
import theme from '@styles/theme';

const TestimonialData = [
  {
    avatar: '/testimonials/irina.svg',
    by: 'Irina Ishurova',
    logo: '/testimonials/pb.svg',
    text: '"Select Star provides everything we need for a data catalog, and it’s crystal clear & easy to use for our users."',
    title: 'Sr. Director of Data Development',
  },
  {
    avatar: '/testimonials/derek.svg',
    by: 'Derek Racine',
    logo: '/testimonials/opendoor.svg',
    text: '"With Select Star, we define our single source of truth for data, and have it as the main go-to reference for data questions."',
    title: 'Data Science Manager',
  },
  {
    avatar: '/testimonials/vishal.svg',
    by: 'Vishal Shah',
    logo: '/testimonials/pb.svg',
    text: '"Select Star makes Discoverability, Collaboration, Lineage, Usability of data and process extremely simple, efficient and timely leading to data trustworthiness and increase in data consumption across different consumers and thereby promoting growth."',
    title: 'Sr. Principal Architect, Big Data & Analytics',
  },
  {
    avatar: '/testimonials/christine.svg',
    by: 'Christine Liu',
    logo: '/testimonials/anvyl.svg',
    text: '"Select Star enabled us to refactor and update LookML without fear of breaking business critical dashboards."',
    title: 'Data Engineer',
  },
  {
    avatar: '/testimonials/zdenek.svg',
    by: 'Zdeněk Hejnak',
    logo: '/testimonials/livesport.svg',
    text: '"We can finally set up our data mart and build our data governance strategy, because of Select Star."',
    title: 'Sr. BI Engineer',
  },
];
function randomNumber(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min) + min);
}

interface TestimonialProps {
  defaultIndex?: number;
}

const Testimonial: React.FC<TestimonialProps> = ({ defaultIndex }) => {
  const index = defaultIndex ?? randomNumber(0, TestimonialData.length);
  const selectedTestimonial = TestimonialData[index];

  return (
    <>
      <Image alt="logo" height="5rem" src={selectedTestimonial.logo} width="11rem" />
      <Text
        as="h3"
        color={theme.colors.white}
        fontSize="16px"
        fontWeight="regular"
        lineHeight={1.5}
        mt={0}
        textAlign="center"
      >
        {selectedTestimonial.text}
      </Text>
      <Box
        alignItems="center"
        compDisplay="flex"
        flexDirection="row"
        justifyContent="center"
        mt={0.5}
        p={0.5}
      >
        <Box compDisplay="flex" flexGrow={0} justifyContent="right">
          <Avatar name={selectedTestimonial.by} size="50px" src={selectedTestimonial.avatar} />
        </Box>
        <Box ml={2}>
          <Text
            as="p"
            color={theme.colors.white}
            display="block"
            fontSize="14px"
            fontWeight="regular"
            m={0}
          >
            {selectedTestimonial.by}
          </Text>
          <Text
            as="p"
            color={theme.colors.white}
            display="block"
            fontSize="14px"
            fontWeight="regular"
          >
            {selectedTestimonial.title}
          </Text>
        </Box>
      </Box>
    </>
  );
};

export default Testimonial;
