import theme from '@styles/theme';

import { NODE_OPACITY } from '../Nodes/TableNode/TableNode.styles';

export type EdgeType = 'column' | 'manual' | 'table';
export type ColorState = 'default' | 'selected' | 'unfocused' | 'focused';

type ColorConfig = Record<EdgeType, Record<ColorState, string>>;

const UNFOCUSED_OPACITY = (NODE_OPACITY.unfocused * 100).toString();

const UNFOCUSED_OPACITY_HEX = `${theme.colors.v1.gray[400]}${UNFOCUSED_OPACITY}`;

const MANUAL_EDGE_COLOR = '#4573D2';
const FOCUSED_EDGE_COLOR = theme.colors.v1.primary[500];

export const EDGE_MARKER_COLOR_ID: ColorConfig = {
  column: {
    default: 'column-default-edge-marker',
    focused: 'column-focused-edge-marker',
    selected: 'column-selected-edge-marker',
    unfocused: 'column-unfocused-edge-marker',
  },
  manual: {
    default: 'manual-edge-marker',
    focused: 'manual-focused-edge-marker',
    selected: 'manual-selected-manual-edge-marker',
    unfocused: 'manual-unfocused-manual-edge-marker',
  },
  table: {
    default: 'table-default-edge-marker',
    focused: 'table-focused-edge-marker',
    selected: 'table-selected-edge-marker',
    unfocused: 'table-unfocused-edge-marker',
  },
};

export const COLORS: { edge: ColorConfig; node: { connectedToSelectedEdge: string } } = {
  edge: {
    column: {
      default: '#696E83',
      focused: FOCUSED_EDGE_COLOR,
      selected: theme.colors.v1.gray[500],
      unfocused: UNFOCUSED_OPACITY_HEX,
    },
    manual: {
      default: MANUAL_EDGE_COLOR,
      focused: MANUAL_EDGE_COLOR,
      selected: MANUAL_EDGE_COLOR,
      unfocused: `${MANUAL_EDGE_COLOR}${UNFOCUSED_OPACITY}`,
    },
    table: {
      default: theme.colors.v1.gray[500],
      focused: FOCUSED_EDGE_COLOR,
      selected: '#EB8EDF',
      unfocused: UNFOCUSED_OPACITY_HEX,
    },
  },
  node: {
    connectedToSelectedEdge: '#EB8EDF',
  },
};
