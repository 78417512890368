import 'highlight.js/styles/github.css';

import styled from '@emotion/styled';
import Editor from 'react-simple-code-editor';

import Button from '@components/Button/Button';

const LINE_NUMBER_WIDTH = 2.5;
const LINE_NUMBER_COLOR = '#f2f5f9';

export const StyledEditorCopyButton = styled(Button)`
  position: absolute;
  right: ${({ theme }) => theme.space(1)};
  top: ${({ theme }) => theme.space(1)};
  z-index: 1;
  opacity: 0;
  transition: opacity 0.1s ease;
`;

export const StyledCodeEditor = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid #ced4da;
  margin: 0.5rem 0;
  max-height: 100%;
  overflow: auto;
  position: relative;

  &:hover {
    ${StyledEditorCopyButton} {
      opacity: 1;
    }
  }
`;

export const StyledEditor = styled(Editor)`
  font-family: ${({ theme }) => theme.typography.fontFamilies.mono};
  font-size: 0.8rem;

  textarea,
  pre {
    outline: 0;
    padding-left: ${LINE_NUMBER_WIDTH + 1}rem !important;
  }

  pre {
    min-height: 50px;
    background-image: linear-gradient(
      to right,
      ${LINE_NUMBER_COLOR} ${LINE_NUMBER_WIDTH}rem,
      transparent ${LINE_NUMBER_WIDTH}rem
    ) !important;
  }

  .editorLineNumber {
    background-color: ${LINE_NUMBER_COLOR};
    color: #6f747c;
    left: 0;
    min-width: ${LINE_NUMBER_WIDTH}rem;
    padding-right: 0.5rem;
    position: absolute;
    text-align: right;
  }
`;
