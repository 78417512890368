import styled from '@emotion/styled';

interface StyledItemProps {
  isBold?: boolean;
}

export const identSize = 24;

export const StyledSidebarTreeItem = styled.li<StyledItemProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: ${({ isBold, theme }) => (isBold ? theme.typography.fontWeights.bold : undefined)};
  color: ${({ theme }) => theme.colors.v1.gray[800]};
  width: 100%;

  .explore-open {
    display: none;
  }

  &:hover {
    background-color: #f2f5f9;
  }
`;

export const StyledSidebarTreeLabel = styled.span`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  line-height: 1;
  overflow-wrap: break-word;
  overflow: hidden;
  padding-left: ${({ theme }) => theme.space(0.5)};
  text-overflow: ellipsis;
  white-space: break-spaces;
  word-break: break-word;
`;

export const StyledSidebarTreeCarret = styled.span`
  width: ${identSize}px;
  margin-left: -${identSize}px;
  padding-left: 2px;
  flex-shrink: 0;

  svg {
    width: 13px;
  }
`;

export const StyledSidebarTreeDescription = styled.span`
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #2c2e36;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.2;
  margin-right: ${({ theme }) => theme.space(1)};
`;
