import React from 'react';
import { css, Global } from '@emotion/react';

import theme from '@styles/theme';

const globalStyles = css`
  @font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: ${theme.typography.fontWeights.regular};
    src: url('/fonts/inter-v12-latin-400.woff2') format('woff2'),
      url('/fonts/inter-v12-latin-400.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: ${theme.typography.fontWeights.medium};
    src: url('/fonts/inter-v12-latin-500.woff2') format('woff2'),
      url('/fonts/inter-v12-latin-500.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: ${theme.typography.fontWeights.semibold};
    src: url('/fonts/inter-v12-latin-600.woff2') format('woff2'),
      url('/fonts/inter-v12-latin-600.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: ${theme.typography.fontWeights.bold};
    src: url('/fonts/inter-v12-latin-700.woff2') format('woff2'),
      url('/fonts/inter-v12-latin-700.woff') format('woff');
  }

  :root {
    font-size: ${theme.typography.fontSizes.default};
  }

  *,
  ::before,
  ::after {
    box-sizing: border-box;
    border: 0 solid #e2e8f0;
  }

  *:focus {
    outline: none !important;
  }

  * {
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${theme.colors.gray[200]} !important;
      border-radius: 10px;
      border: 2px solid white;

      &:hover {
        background-color: ${theme.colors.gray[200]};
      }
    }

    ::-webkit-scrollbar-track {
      background-color: transparent !important;
    }
  }

  html,
  body,
  #root,
  .App {
    height: 100%;
  }

  body {
    font-family: ${theme.typography.fontFamilies.primary};
    color: ${theme.colors.text};
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.is-modal-open {
      overflow: hidden;
    }
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  pre {
    margin: 0;
  }

  button {
    padding: 0;
    border: none;
    background-color: initial;
    color: inherit;
  }

  [role='button'],
  button {
    cursor: pointer;
  }

  img,
  video {
    max-width: 100%;
    height: auto;
  }
  table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse;
  }
  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: block;
    vertical-align: middle;
  }
  blockquote {
    margin: 0;
  }
  ol,
  ul,
  menu {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ::selection {
    color: #fff;
    background-color: #4c288f;
  }

  .markdown a,
  .markdown a:hover {
    color: #4c288f;
  }

  .markdown a[target='_blank'] {
    text-decoration: underline;
  }

  .markdown a[target='_blank']:visited {
    color: #423760;
  }

  .ReactCollapse--collapse {
    transition: height 500ms;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
`;

const GlobalStyles: React.FC = () => {
  return <Global styles={globalStyles} />;
};

export default GlobalStyles;
