import React, { useEffect } from 'react';
import { useHistory, useLocation } from '@routing/router';

/*
 * `state.scrollToTop` can be used to prevent default router behavior.
 * Use it with the `<Link>` component to controll scroll behavior, like so:
 *
 * `<Link to={{pathname: '/foo/123', scrollToTop: false}}>`
 */
const ScrollToTop: React.FC = () => {
  const history = useHistory();
  const location: { state: { scrollToTop: boolean } } = useLocation();

  useEffect(() => {
    return history.listen(() => {
      const shouldScroll = location?.state?.scrollToTop !== false;
      if (shouldScroll) window.scrollTo(0, 0);
    });
  }, [location, history]);

  return null;
};

export default ScrollToTop;
