import React, { useEffect, useRef, useState } from 'react';

/**
 * Usage: Add component between td tags to make that column resizable.
 */

interface ColumnResizerProps {
  className?: string;
  disabled?: boolean;
  index: number;
  minWidth?: number;
  updateIndex: (index: number | null) => void;
}

const ColumnResizer: React.FC<ColumnResizerProps> = ({
  className = '',
  disabled = false,
  index,
  minWidth = 0,
  updateIndex,
}) => {
  const [dragging, setDragging] = useState(false);
  const [mouseX, setMouseX] = useState(0);
  const [startPos, setStartPos] = useState(0);
  const [startWidthPrev, setStartWidthPrev] = useState(0);
  const [startWidthNext, setStartWidthNext] = useState(0);
  const componentRef = useRef<HTMLTableDataCellElement>(null);
  const prevElem = componentRef.current?.previousElementSibling as HTMLElement;
  const nextElem = componentRef.current?.nextElementSibling as HTMLElement;

  const handleMouseUp = () => {
    if (disabled) {
      return;
    }
    setDragging(false);
    updateIndex(null);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (disabled) {
      return;
    }

    setMouseX(e.screenX);

    if (!dragging) {
      return;
    }

    const moveDiff = startPos - mouseX;
    let newPrev = startWidthPrev - moveDiff;
    let newNext = startWidthNext + moveDiff;

    if (newPrev < minWidth) {
      const offset = newPrev - minWidth;
      newPrev = minWidth;
      newNext += offset;
    } else if (newNext < minWidth) {
      const offset = newNext - minWidth;
      newNext = minWidth;
      newPrev += offset;
    }

    prevElem.style.width = `${newPrev}px`;
    nextElem.style.width = `${newNext}px`;
  };

  const handleMouseDown = () => {
    if (disabled) {
      return;
    }

    setDragging(true);
    setStartPos(mouseX);

    setStartWidthPrev(prevElem?.clientWidth || 0);
    setStartWidthNext(nextElem?.clientWidth || 0);

    updateIndex(index);
  };

  const handleMouseOver = () => {
    if (!disabled && !dragging) {
      updateIndex(index);
    }
  };

  const handleMouseOut = () => {
    if (!disabled && !dragging) {
      updateIndex(null);
    }
  };

  useEffect(() => {
    if (!disabled) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [dragging, mouseX, startPos]);

  const style: React.CSSProperties = {
    backgroundColor: '#fff',
    cursor: !disabled ? 'ew-resize' : 'pointer',
    padding: '0',
    position: 'sticky',
    top: '0',
    userSelect: 'none',
    width: '0.25rem',
  };

  return (
    <td
      ref={componentRef}
      className={className}
      onMouseDown={handleMouseDown}
      onMouseOut={handleMouseOut}
      onMouseOver={handleMouseOver}
      style={style}
    />
  );
};

export default ColumnResizer;
