import React from 'react';

import LinkedTags from './LinkedTags';
import LookerViewLinksTo from './LookerViewLinksTo';
import TableLinksTo from './TableLinksTo';

interface LinksToProps {
  guid: string;
  linksToObjects?: any[];
}

const LinksTo: React.FC<LinksToProps> = ({ guid, linksToObjects }) => {
  if (guid.startsWith('tg_')) {
    return <LinkedTags guid={guid} type="linksTo" />;
  }

  if (guid.startsWith('lv_') && linksToObjects) {
    return <LookerViewLinksTo linksToObjects={linksToObjects} />;
  }

  return <TableLinksTo guid={guid} />;
};

export default LinksTo;
