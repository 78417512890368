import styled from '@emotion/styled';

import Box from '@components/Box';
import Button from '@components/Button/Button';
import rootTheme from '@styles/theme';

export const StyledOptionsMenu = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors.v1.gray[200]};
`;

StyledOptionsMenu.defaultProps = {
  backgroundColor: 'white',
  borderRadius: 'lg',
  boxShadow: 'lg',
  compWidth: 'fit-content',
  minWidth: rootTheme.space(25.25),
  overflow: 'hidden',
};

export const StyledOptionsMenuSection = styled(Box)`
  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.v1.gray[200]};
  }
`;

interface StyledOptionsMenuOptionProps {
  isDisabled?: boolean;
}

export const StyledOptionsMenuOption = styled(Button)<StyledOptionsMenuOptionProps>`
  transition: all 300ms linear;
  background-color: ${({ theme }) => theme.colors.white};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.3 : 1)};

  &:hover {
    background-color: ${({ isDisabled, theme }) => !isDisabled && theme.colors.v1.gray[100]};
  }
`;

StyledOptionsMenuOption.defaultProps = {
  alignItems: 'center',
  color: 'gray.800',
  compDisplay: 'flex',
  compHeight: rootTheme.space(3.75),
  compWidth: '100%',
  fontSize: 'sm',
  fontWeight: 'normal',
  gap: 1.25,
  justifyContent: 'flex-start',
  px: 1,
  whiteSpace: 'nowrap',
};

export const StyledOptionsMenuOptionCount = styled(Box)``;

StyledOptionsMenuOptionCount.defaultProps = {
  alignItems: 'center',
  backgroundColor: 'gray.100',
  borderRadius: 'default',
  color: 'gray.700',
  compDisplay: 'flex',
  compHeight: rootTheme.space(2.25),
  fontSize: 'sm',
  px: 0.5,
};
