import { Editor, Element, Path, Range, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import { createCell } from './createTable';

/**
 * Finds the insert point within a row for new cells for column insertion.
 */
const getInsertLocation = (startCellPath: Path, direction: 'left' | 'right' = 'right') => {
  /**
   * If we want to insert to the left of the current column, we want to return the current position of
   * the cell in the path. This will insert at current location effectively pushing current column over.
   */
  if (direction === 'left') {
    return startCellPath[startCellPath.length - 1];
  }

  // If inserting to the right of the current cell we should insert at the next position in the path.
  return startCellPath[startCellPath.length - 1] + 1;
};

/**
 * Find the table cell that we will use as a reference point for inserting the new column.
 */
const findInsertionCell = (editor: Editor) => {
  return Editor.above(editor, {
    match: (n) => Element.isElement(n) && (n.type === 'td' || n.type === 'th'),
  });
};

/**
 *  Find the table that we will be inserting into.
 */
const findTable = (editor: Editor) => {
  return Editor.above(editor, {
    match: (n) => Element.isElement(n) && n.type === 'table',
  });
};

const insertColumn = (editor: Editor, direction: 'left' | 'right' = 'right') => {
  if (!editor.selection) return;

  const table = findTable(editor);

  const insertionCell = findInsertionCell(editor);

  const isCollapsed = Range.isCollapsed(editor.selection);

  if (table && insertionCell && isCollapsed) {
    const insertLocationPoint = getInsertLocation(insertionCell[1], direction);

    // Iterate through the table children. These could be a table header and/or a table body.
    table[0].children.forEach((child, tableChildPathPoint) => {
      if (Element.isElement(child)) {
        if (child.type === 'thead') {
          /**
           * For every child row of the table header, generate the path to the position we want to insert
           * and then insert a new cell. Path is formed by copying the path to the table then pushing the index of
           * each child we've encountered to get to this point.
           *
           * Example:
           * Path to table: [2, 1]
           * Index of table header: 0
           * Index of row within header: 0 (represents the first row within the header)
           * Index of cell position we want to insert at: 3 (represents the fourth cell position in the row);
           * Final path to insertion: [2, 1, 0, 0, 3]
           *
           * The same process is repeated for the table body below.
           */
          child.children.forEach((headerChild, tableHeadRowPathPoint) => {
            const newHeaderCell = createCell(true);
            const cellPath = Array.from(table[1]);
            cellPath.push(tableChildPathPoint, tableHeadRowPathPoint, insertLocationPoint);
            Transforms.insertNodes(editor, [newHeaderCell], { at: cellPath });
          });
        }

        if (child.type === 'tbody') {
          child.children.forEach((bodyChild, tableBodyChildPathPoint) => {
            const newCell = createCell();
            const cellPath = Array.from(table[1]);
            cellPath.push(tableChildPathPoint, tableBodyChildPathPoint, insertLocationPoint);
            Transforms.insertNodes(editor, [newCell], { at: cellPath });
          });
        }
      }
    });
  }
  ReactEditor.focus(editor);
};

export default insertColumn;
