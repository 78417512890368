import { SearchPaginatedResponse, SearchResult } from '@api/search/types';

const searchTransform =
  <T>(cb: Function) =>
  (response: SearchPaginatedResponse<T>): SearchResult<T> => {
    return {
      accessDeniedGuids: response.access_denied_guids,
      data: response.results.map((res, id) => ({ ...cb(res), indexPosition: id })),
      facets: {
        ...response.facets?.index_count?.buckets?.reduce((res: Record<string, number>, cur) => {
          res[cur.key] = cur.doc_count;
          return res;
        }, {}),
      },
      requestMetadata: response.requestMetadata,
      shown: response?.count || 0,
      total:
        response?.facets?.index_count?.buckets?.reduce((res, cur) => res + cur.doc_count, 0) || 0,
    };
  };

export default searchTransform;
