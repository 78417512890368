import { useRef } from 'react';
import isEqual from 'lodash/isEqual';

export interface UseRefValueChangeProps<T = Object> {
  enabled?: boolean;
  onChange: (value: T) => void;
  value: T;
}

const useRefValueChange = <T = Object>({
  enabled = true,
  onChange,
  value,
}: UseRefValueChangeProps<T>) => {
  const valueRef = useRef(value);

  if (!isEqual(value, valueRef.current) && enabled) {
    onChange(value);
    valueRef.current = value;
  }
};

export default useRefValueChange;
