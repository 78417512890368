import React from 'react';

import type { TooltipProps } from '@components/Tooltip';
import Tooltip from '@components/Tooltip';

import type { StyledCodeProps } from './Code.styles';
import { StyledCode } from './Code.styles';

interface CodeProps extends StyledCodeProps {
  text?: TooltipProps['content'];
}

const Code: React.FC<CodeProps> = ({ children, text, ...other }) => (
  <Tooltip content={text}>
    <StyledCode {...other}>{children}</StyledCode>
  </Tooltip>
);

export default Code;
