import React from 'react';

import CircularLoader from '@components/CircularLoader';

import { LineageLoadingState } from '../../LineageExplore.types';

import {
  StyledExploreLoader,
  StyledExploreLoaderAnimatedSubtitle,
  StyledExploreLoaderBackdrop,
  StyledExploreLoaderContent,
  StyledExploreLoaderSubtitle,
  StyledExploreLoaderTitle,
} from './LineageExploreLoader.styles';

interface ExploreLoaderProps {
  state: LineageLoadingState;
  subtitleLoadingTime?: number;
}

const LOADING_TITLE = 'Loading';
const LOADING_SUBTITLE = 'Looks like your lineage has a lot of data... hang tight!';
const SUBTITLE_DEFAULT_DELAY = 1000;

const LineageExploreLoader = ({
  state,
  subtitleLoadingTime = SUBTITLE_DEFAULT_DELAY,
}: ExploreLoaderProps) => {
  const { delay = 0, enabled, type } = state;

  if (!enabled) {
    return null;
  }

  return (
    <StyledExploreLoader delay={delay}>
      <StyledExploreLoaderBackdrop
        isTranslucent={['expansion', 'column-level', 'generic-operation'].includes(type)}
      />
      <StyledExploreLoaderContent>
        <CircularLoader borderWidth={2} color="primary.500" compSize={7.625} />
        <StyledExploreLoaderTitle>{LOADING_TITLE}</StyledExploreLoaderTitle>
        <StyledExploreLoaderSubtitle>
          <StyledExploreLoaderAnimatedSubtitle delay={subtitleLoadingTime}>
            {LOADING_SUBTITLE}
          </StyledExploreLoaderAnimatedSubtitle>
        </StyledExploreLoaderSubtitle>
      </StyledExploreLoaderContent>
    </StyledExploreLoader>
  );
};

export default LineageExploreLoader;
