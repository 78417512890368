import React, { useEffect, useState } from 'react';

import type { TreeProps } from '@components/Tree/Tree.v1';
import Tree from '@components/Tree/Tree.v1';
import type { TreeKey } from '@components/Tree/types';

interface RoutedHierarchyTreeProps<T> extends TreeProps<T> {
  allowDragDrop?: boolean;
  useNewLayout?: boolean;
}

const RoutedHierarchyTree = <T,>({
  defaultExpandedKeys,
  defaultSelectedKeys,
  useNewLayout,
  ...other
}: RoutedHierarchyTreeProps<T>) => {
  const [treeState, setTreeState] = useState<{
    autoExpandParent: boolean;
    expandedKeys?: TreeKey[];
    selectedKeys?: TreeKey[];
  }>({
    autoExpandParent: true,
    expandedKeys: defaultExpandedKeys,
    selectedKeys: defaultSelectedKeys,
  });

  useEffect(() => {
    setTreeState((prevTreeState) => ({ ...prevTreeState, selectedKeys: defaultSelectedKeys }));
  }, [defaultSelectedKeys]);

  return (
    <Tree
      {...other}
      autoExpandParent={treeState.autoExpandParent}
      expandedKeys={treeState.expandedKeys}
      onExpand={(keys) =>
        setTreeState((prev) => ({ ...prev, autoExpandParent: false, expandedKeys: keys }))
      }
      onSelect={(keys, info) => {
        setTreeState((prev) => {
          const isExpanded = prev?.expandedKeys?.includes(info.node.key);
          const mergedKeys = [...(prev?.expandedKeys ?? []), ...keys];
          const expandedKeys = isExpanded
            ? mergedKeys?.filter((key) => key !== info.node.key)
            : mergedKeys;

          return {
            ...prev,
            expandedKeys,
            selectedKeys: keys,
          };
        });
      }}
      selectedKeys={treeState.selectedKeys}
      useNewLayout={useNewLayout}
    />
  );
};

export default RoutedHierarchyTree;
