import styled from '@emotion/styled';

const TitleHeader = styled.h1`
  margin-bottom: 1rem;
  svg {
    width: 1.875rem;
    display: inline-block;
    margin: 0.3125rem;
    margin-right: 0.5rem;
    margin-left: 0;
    vertical-align: text-top;
  }
  font-size: 2.25em;
  .title {
    font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
    font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
    color: ${({ theme }) => theme.colors.text};
    &.big {
      font-size: 1.75rem;
    }
    &.nobold {
      font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
      font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
    }
  }
  .subtitle {
    margin-left: 0.75rem;
    font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
    font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.gray[600]};
  }
  .clickable {
    cursor: pointer;
  }
  .search-icon {
    margin-bottom: 0;
    vertical-align: baseline;
  }
`;

export default TitleHeader;
