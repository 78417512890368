import getUrl from '@configs/urls/getUrl';
import { Expose, Transform, Type } from 'class-transformer';

import { ColumnModel } from '@api/columns/ColumnModel';
import DbtRunModel from '@api/dbt/DbtRunModel';
import { TaggedItemModel } from '@api/tags/TaggedItemModel';
import { ObjectType, StatusType } from '@api/types';
import isEmptyRichText from '@components/RichTextEditor/helpers/isEmptyRichText';
import { DataSourceModel } from '@models/DataSourceModel';
import DataTypesModel from '@models/DataTypesModel';
import { MetadataModel } from '@models/MetadataModel';
import { Reference } from '@models/Reference';

class DbtTestModel {
  static objectType: ObjectType = 'dbttest';

  objectType: ObjectType = DbtTestModel.objectType;

  guid!: string;

  @Type(() => DataSourceModel)
  @Expose({ name: 'data_source' })
  dataSource?: DataSourceModel;

  name!: string;

  @Type(() => ColumnModel)
  column!: ColumnModel;

  status!: StatusType;

  description?: string;

  @Expose({ name: 'parent_guid' })
  parentGuid?: string;

  @Expose({ name: 'table_guid' })
  tableGuid?: string;

  @Expose({ name: 'data_types' })
  @Type(() => DataTypesModel)
  dataTypes?: DataTypesModel = new DataTypesModel();

  // TODO: Remove transform https://app.shortcut.com/select-star/story/55620
  @Expose({ name: 'richtext_description' })
  @Transform((richtextDescription) =>
    isEmptyRichText(richtextDescription) ? '' : richtextDescription,
  )
  richtextDescription?: string;

  @Expose({ name: 'ingested_description' })
  suggestedDescription?: string;

  @Expose({ name: 'suggested_description_source' })
  suggestedDescriptionSource?: string;

  @Expose({ name: 'suggested_description_source_object' })
  @Type(() => Reference)
  suggestedDescriptionSourceObject?: Reference<MetadataModel>;

  @Expose({ name: 'tagged_items' })
  @Type(() => TaggedItemModel)
  taggedItems!: TaggedItemModel[];

  @Expose({ name: 'latest_run' })
  @Type(() => DbtRunModel)
  latestRun?: DbtRunModel;

  get routePath() {
    return getUrl({
      dataTypes: this.dataTypes,
      guid: this.guid,
      parentGuid: this.parentGuid ?? this.dataSource?.guid ?? this.tableGuid,
    });
  }
}

export default DbtTestModel;
