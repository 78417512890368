import React, { useCallback, useState } from 'react';
import { difference } from 'lodash';

import { TagModel } from '@api/tags/TagModel';
import { useFetchTags, usePatchTagModels } from '@api/tags/tags';
import TabError from '@components/TabContent/TabError';
import DbtTagsSetupTable from '@components/Table/DbtTagsSetupTable';
import { TabContentProps } from '@components/Tabs/types';
import { renderInfoToast } from '@components/Toast';
import { useTagContext } from '@context/Tag';
import { Filter } from '@utils';

const searchConfig: { [key: string]: keyof Filter.FilterOptions } = {
  name: 'search_name',
};

const sortConfig: { [key: string]: string } = {
  name: 'name',
};

const DbtTagsConfigMode: Filter.FilterOptions = {
  page: 1,
  page_size: 100,
  query: '{guid,data_source{guid,name,type},name}',
};

interface DbtTagsProps extends TabContentProps {
  dsGuid: string;
}

const DbtSetupTags: React.FC<DbtTagsProps> = ({ dsGuid }) => {
  const FilterService = Filter.useUpdateFilters(
    DbtTagsConfigMode,
    searchConfig,
    sortConfig,
    'name',
  );

  const { filter } = FilterService;

  const [selectedRowIds, setSelectedRowIds] = useState<{ [key: string]: boolean }>({});
  const tagContext = useTagContext();

  const {
    data: tagsResponse,
    error,
    refetch: refetchTags,
  } = useFetchTags({
    onSuccess: (response) => {
      const map: { [guid: string]: boolean } = {};

      response?.results.forEach((tag: TagModel) => {
        if (tag.type === 'dbt') {
          map[tag.guid] = tag.visible ?? true;
        }
      });

      setSelectedRowIds(map);
    },
    params: {
      datasources: dsGuid,
      only_visible: false,
    },
  });

  const { mutate } = usePatchTagModels({
    onSuccess: refetchTags,
  });

  const totalPages =
    tagsResponse && filter.page_size ? Math.ceil(tagsResponse.count / filter.page_size) : 1;
  const dbtTags: TagModel[] | undefined = tagsResponse?.results;

  const handleSetSelectedRowIds = useCallback(
    (selected) => {
      if (selectedRowIds !== selected) {
        const selectedKeys = Object.keys(selected).filter((key) => selected[key]);
        {
          const prevSelectedKeys = Object.keys(selectedRowIds).filter((key) => selectedRowIds[key]);

          const newlySelectedKeys = difference(selectedKeys, prevSelectedKeys);
          const removedSelectedKeys = difference(prevSelectedKeys, selectedKeys);

          if (newlySelectedKeys.length === 1) {
            const tagGuid = newlySelectedKeys[0];
            const tag = tagContext.findTag?.(tagGuid)?.name;
            renderInfoToast(`${tag} has been added to dbt Tag Sync`);
          } else if (newlySelectedKeys.length > 0) {
            renderInfoToast(`${newlySelectedKeys.length} dbt tags have been added to dbt Tag Sync`);
          }
          if (removedSelectedKeys.length === 1) {
            const tagGuid = removedSelectedKeys[0];
            const tag = tagContext.findTag?.(tagGuid)?.name;
            renderInfoToast(`${tag} has been removed from dbt Tag Sync`);
          } else if (removedSelectedKeys.length > 0) {
            renderInfoToast(
              `${removedSelectedKeys.length} dbt tags have been removed from dbt Tag Sync`,
            );
          }
        }

        mutate({
          items: selectedKeys,
          type: 'dbt',
        });
        setSelectedRowIds(selected);
      }
    },
    [selectedRowIds, setSelectedRowIds, mutate],
  );

  if (error) return <TabError />;

  return (
    <DbtTagsSetupTable
      data={dbtTags}
      filterService={FilterService}
      selectedRowIds={selectedRowIds}
      setSelectedRowIds={handleSetSelectedRowIds}
      totalPages={totalPages}
    />
  );
};

export default React.memo(DbtSetupTags);
