import React from 'react';

import type IndexModel from '@api/indexes/IndexModel';
import Box from '@components/Box';
import ColumnConstraint from '@components/TabContent/ColumnsTab/ColumnTable/ColumnConstraint';
import LinkedCell from '@components/Table/Cells/LinkedCell';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';

interface IndexTableColumnCellProps {
  indexItem: IndexModel;
}

const IndexTableColumnCell: React.FC<IndexTableColumnCellProps> = ({ indexItem }) => (
  <Box compDisplay="flex" flexDirection="column" gap={0.5} py={0.5}>
    {indexItem.conditions?.map(({ column }) => (
      <Box key={column.guid} alignItems="center" compDisplay="flex" gap={0.5} noDefault>
        <Tooltip content={column.dataTypes?.dataType}>
          <Icon name={column.dataTypes?.icons.dataType!} size="16px" />
        </Tooltip>
        <LinkedCell customUrl={column.routePath} item={column} showNameTooltip />
        <ColumnConstraint columnItem={column} />
      </Box>
    ))}
  </Box>
);

export default IndexTableColumnCell;
