import { useFetchCollection, usePatchCollection } from '@api/collections';

import CollectionsTabContent from './CollectionsTabContent';
// import query from './query';

export default {
  Content: CollectionsTabContent,
  enableSidebarMetadataObjectDetails: false,
  fetchHook: useFetchCollection,
  metadataObjectButtonToolbarConfig: {
    buttons: ['share' as const],
  },
  patchHook: usePatchCollection,
  // query,
};
