import React, { useCallback, useRef } from 'react';
import { useLocation, useParams } from '@routing/router';
import cloneDeep from 'lodash/cloneDeep';
import uniqBy from 'lodash/uniqBy';

import { useFetchFolder } from '@api/bifolder';
import { useFetchExplores } from '@api/explores/explores';
import { LookerExploreModel } from '@api/explores/LookerExploreModel';
import Box from '@components/Box';
import Breadcrumbs from '@components/Breadcrumbs';
import BulkButtons from '@components/BulkButtons/BulkButtons';
import CircularLoader from '@components/CircularLoader';
import ItemsCount from '@components/ItemsCount';
import LayoutMainGrid from '@components/Layout/LayoutMainGrid';
import PageHeaderBar from '@components/PageHeaderBar';
import Icon from '@components/UI/Icon';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import { useUserContext } from '@context/User';
import useBulkEditSelected from '@hooks/useBulkEditSelected';
import { StyledTablesPage } from '@pages/TablesPage/TablesPage.styles';
import { Filter } from '@utils';
import MetadataDecorator from '@utils/MetadataDecorator';
import stripSpaces from '@utils/stripSpaces';

import ExploresTable from './ExploresTable';
import ExploreFilters from './Filters';

const sortConfig: { [key: string]: string } = {
  businessOwner: 'business_owner',
  description: 'description',
  folder: 'group_label',
  impactScore: 'impact_score',
  model: 'model',
  name: 'name',
  popularity: 'popularity',
  technicalOwner: 'technical_owner',
};

export const exploreRequestConfig: Filter.FilterOptions = {
  order: '-popularity',
  page: 1,
  page_size: 100,
  query: stripSpaces(
    `{
      guid,
      group,
      group_label,
      lookml_model{
        guid,
        name,
        label
      },
      name,
      label,
      description,
      richtext_description,
      business_owner,
      technical_owner,
      popularity,
      tagged_items,
      is_hidden,
      ai_description,
      ingested_description,
      description_source,
      data_types,
      user_description,
      downstream_objects_counts
    }`,
  ),
};

interface ExploresPageProps {
  defaultConfig?: Filter.FilterOptions;
  showMainContentOnly?: boolean;
}

const ExploresPage: React.FC<ExploresPageProps> = ({ defaultConfig, showMainContentOnly }) => {
  const location = useLocation();
  const { dsGuid, guid } = useParams<{ dsGuid: string; guid?: string }>();
  const allFetchedExplores = useRef<LookerExploreModel[]>([]);
  const { dataSources } = useUserContext();
  const { reset, selected, setSelected, tagsCounts } = useBulkEditSelected<LookerExploreModel>({
    key: guid,
  });
  const { isPbac, permissions } = useObjectPermissionsContext({ id: dsGuid || guid });
  const selectedEditableItems = selected.items.filter((item) =>
    isPbac ? permissions[item.guid]?.isEditable : true,
  );
  const config = defaultConfig || cloneDeep(exploreRequestConfig);
  const FilterService = Filter.useUpdateFilters(config, {}, sortConfig);
  const { filter } = FilterService;
  const isGroupUrl = location.pathname.includes('/groups');
  const isModelUrl = location.pathname.includes('/models');
  const { data: exploreResponse, refetch: refetchExplores } = useFetchExplores({
    onSuccess: (d) => {
      allFetchedExplores.current = uniqBy(
        [...(d?.results ?? []), ...allFetchedExplores.current],
        'guid',
      );
    },
    params: Filter.setParams({
      ...filter,
      datasources: dsGuid ? [dsGuid] : undefined,
      groups: guid && isGroupUrl ? [guid] : undefined,
      models: guid && isModelUrl ? [guid] : undefined,
    }),
  });
  const { data: folderResponse } = useFetchFolder(guid || '', {
    enabled: Boolean(guid) && isGroupUrl,
  });

  const folderDsGuid = folderResponse?.dataSource.guid;
  const explores: LookerExploreModel[] | undefined = exploreResponse?.results;
  const totalPages =
    exploreResponse && filter.page_size ? Math.ceil(exploreResponse.count / filter.page_size) : 1;
  const isDataSourceEditable = Boolean(
    dataSources?.[dsGuid || folderDsGuid || '']?.settings?.isEditable ?? true,
  );

  const handleSetSelectRowIds = useCallback(
    (selectedRowIds) => {
      setSelected(allFetchedExplores.current?.filter((i) => selectedRowIds?.[i.guid]));
    },
    [setSelected],
  );

  const mainContent = (
    <>
      <BulkButtons
        canEditOwner
        canEditTags
        canRemove={!filter.is_hidden}
        canUnHide={filter.is_hidden}
        isDataSourceEditable={isDataSourceEditable}
        onEdit={() => {
          refetchExplores();
          reset();
          handleSetSelectRowIds({});
        }}
        selectedEditableItems={selectedEditableItems}
        selectedItems={selected.items}
        showText
        tagsCounts={tagsCounts}
      />
      <Box mb={0.5}>
        <ExploresTable
          data={explores}
          filterService={FilterService}
          isDataSourceEditable={isDataSourceEditable}
          itemCount={exploreResponse?.count}
          selectedRowIds={selected.ids}
          setSelectedRowIds={handleSetSelectRowIds}
          totalPages={totalPages}
        />
      </Box>
    </>
  );

  if (showMainContentOnly) {
    return mainContent;
  }

  return (
    <Box compDisplay="flex">
      <LayoutMainGrid>
        <MetadataDecorator
          title={guid && isGroupUrl && folderResponse?.name ? folderResponse?.name : 'Explores'}
        />
        <StyledTablesPage>
          <PageHeaderBar
            icon={<Icon name="folder" />}
            sticky
            supIcon={<Icon name="looker" />}
            supTitle={
              folderResponse?.breadcrumbList ? (
                <Breadcrumbs items={folderResponse?.breadcrumbList} />
              ) : null
            }
            title={guid && isGroupUrl && folderResponse?.name ? folderResponse?.name : 'Explores'}
            titleSuffix={
              <ItemsCount className="subtitle" count={exploreResponse?.count} value="Explores" />
            }
          />
          {mainContent}
        </StyledTablesPage>
      </LayoutMainGrid>
      <React.Suspense fallback={<CircularLoader borderWidth={1} compSize={1} />}>
        <ExploreFilters filterService={FilterService} />
      </React.Suspense>
    </Box>
  );
};

export default React.memo(ExploresPage);
