import { Expose, Type } from 'class-transformer';

import { MetadataModel } from '@models/MetadataModel';
import { Reference } from '@models/Reference';

export class AccessToModel {
  static objectType: string = 'accessTo';

  objectType: string = AccessToModel.objectType;

  @Type(() => Reference)
  target?: Reference<MetadataModel>;

  @Expose({ name: 'is_wildcard' })
  isWildcard?: boolean;

  @Expose({ name: 'wildcard_type' })
  wildcardType?: string;
}
