import styled from '@emotion/styled';

import Box from '@components/Box';
import Text from '@components/Text';

export const StyledIconWrapper = styled(Box)`
  height: 50px;
  width: 50px;

  svg {
    height: 32px;
  }

  ${({ theme }) => theme.media.down('sm')`
    height: 36px;
    width: 36px;

    svg {
      height: 24px;
    }
`};
`;

export const StyledTitle = styled(Text)`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.media.down('sm')`
        font-size: ${theme.typography.fontSizes.h3};
  `};
`;

export const StyledDescription = styled(Text)`
  font-size: ${({ theme }) => theme.typography.fontSizes.default};
  color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.media.down('sm')`
      font-size: ${theme.typography.fontSizes.body2};
  `};
`;
