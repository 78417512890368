import React from 'react';
import { RouterLink } from '@routing/router';

import { useUserContext } from '@context/User';
import { HierarchyData } from '@models/HierarchyModel';

import getUrl from './getUrl';

interface NodeLinkProps {
  className?: string;
  dsGuid?: string;
  handleOpen: (url?: string) => void;
  node: HierarchyData;
  onOpen: (e: React.MouseEvent, url?: string) => void;
}

const NodeLink: React.FC<NodeLinkProps> = React.memo(
  ({ children, className, dsGuid, handleOpen, node, onOpen }) => {
    const { organization } = useUserContext();
    const showSchemataPage = organization?.settings?.showSchemataPage || false;
    const url = getUrl({ ...node, dsGuid, showSchemataPage });

    return (
      <RouterLink
        className={className}
        onClick={(e) => {
          onOpen(e, url);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') handleOpen(url);
        }}
        tabIndex={-1}
        to={url || '#'}
      >
        {children}
      </RouterLink>
    );
  },
);

export default NodeLink;
