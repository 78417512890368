import React from 'react';
import { Cell } from 'react-table';

import { DashboardModel } from '@api/dashboards/DashboardModel';
import DateTime from '@components/DateTime';
import PopularityCellHeader from '@components/Table/Cells/PopularityCell/PopularityCellHeader';
import type { ColumnConfig } from '@components/Table/Table/types';
import { getPopularityNormalized } from '@utils/popularity';

import LinkedCell from '../Cells/LinkedCell';
import PopularityCell from '../Cells/PopularityCell';
import SearchHeader from '../Cells/SearchHeader';
import UserCell from '../Cells/UserCell';
import Table from '../Table';
import TableStyled from '../TableStyled';

interface Props {
  data?: DashboardModel[];
  itemCount?: number;
}

const MetricsRelatedDashboardsTable: React.FC<Props> = React.memo(({ data, itemCount = 0 }) => {
  const [isShowFilter, setShowFilter] = React.useState(false);

  const columns: ColumnConfig<DashboardModel>[] = React.useMemo(
    (): ColumnConfig<DashboardModel>[] => [
      {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      {
        Cell: (props: Cell<DashboardModel>) => {
          const dashboard = props.row.original;
          return <LinkedCell {...props} item={dashboard} showIcon />;
        },
        Header: `Dashboard (${itemCount})`,
        accessor: (d) => d?.name,
        disableHiding: true,
        id: 'name',
      },
      {
        Cell: (props: Cell<DashboardModel>) => {
          const dashboard = props.row.original;
          return (
            <UserCell
              {...props}
              user={
                dashboard.dsuserCreatedBy?.user?.fullName
                  ? dashboard.dsuserCreatedBy?.user
                  : dashboard.dsuserCreatedBy
              }
            />
          );
        },
        Header: 'Created By',
        accessor: (d) => {
          return d?.dsuserCreatedBy?.user?.fullName
            ? d?.dsuserCreatedBy?.user.fullName
            : d?.dsuserCreatedBy?.fullName;
        },
        id: 'createdBy',
      },
      {
        Cell: (props: Cell<DashboardModel>) => {
          const dashboard = props.row.original;
          return (
            <UserCell
              {...props}
              user={
                dashboard?.topDSUser?.user?.fullName
                  ? dashboard?.topDSUser?.user
                  : dashboard.topDSUser
              }
            />
          );
        },
        Header: 'Top User',
        accessor: (d) =>
          d?.topDSUser?.user?.fullName ? d?.topDSUser?.user.fullName : d?.topDSUser?.fullName,
        id: 'topUser',
      },
      {
        Cell: (props: Cell<DashboardModel>) => {
          const dashboard = props.row.original;
          return <DateTime datetime={dashboard?.updatedAt} />;
        },
        Header: 'Last Modified',
        accessor: (d) => d?.updatedAt?.toDate(),
        disableFilters: true,
        id: 'updatedAt',
        sortType: 'datetime',
      },
      {
        Cell: (props: Cell<DashboardModel>) => {
          const dashboard = props.row.original;
          return <DateTime datetime={dashboard?.lastRunAt} />;
        },
        Header: 'Last Run',
        accessor: (d) => d?.lastRunAt?.toDate(),
        disableFilters: true,
        id: 'lastRun',
        sortType: 'datetime',
      },
      {
        Cell: (props: Cell<DashboardModel>) => {
          const dashboard = props.row.original;
          return <PopularityCell {...props} isDashboard popularity={dashboard.popularity} />;
        },
        Header: PopularityCellHeader,
        accessor: (d) => getPopularityNormalized(d?.popularity?.popularity),
        disableFilters: true,
        disableResizing: true,
        dropdownCheckboxLabel: 'Popularity',
        id: 'popularity',
        width: 120,
      },
    ],
    [itemCount],
  );

  return (
    <TableStyled>
      <Table
        basic="very"
        className="table-full"
        columns={columns}
        compact
        data={data || []}
        disableColumnFiltering
        disablePagination
        disableRowSelect
        initialState={{
          sortBy: [
            {
              desc: true,
              id: 'popularity',
            },
          ],
        }}
        loading={data === undefined}
        showFilter={isShowFilter}
        singleLine
        sortable
        toggleFilter={() => setShowFilter((prev) => !prev)}
        unstackable
      />
    </TableStyled>
  );
});

export default MetricsRelatedDashboardsTable;
