import React, { useCallback, useRef } from 'react';
import { Editor } from 'slate';

import UploadTooLargeModal from '@components/Modal/UploadTooLargeModal';
import { useModal } from '@context/Modal';

import {
  IMAGE_EXTENSIONS,
  insertImage,
  MAX_IMAGE_SIZE_AS_MB_STRING,
  parseFilesToImages,
} from '../helpers/imageHelpers';

const useImageUpload = (editor: Editor) => {
  const { selection } = editor;
  const inputRef = useRef<HTMLInputElement>(null);
  const { MODAL_IDS, openModal } = useModal();

  const handleFileSizeExceeded = () => {
    openModal(MODAL_IDS.uploadTooLarge);
  };

  const handleInsertImage = (url: string) => {
    insertImage(editor, url);
  };

  const handleUpload = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      const { files } = event.target;
      if (!files || files?.length === 0) {
        return;
      }
      parseFilesToImages(files, handleFileSizeExceeded, handleInsertImage);
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    },
    [editor, selection],
  );

  const handleSelectImage = () => {
    if (inputRef && inputRef.current) {
      inputRef?.current.click();
    }
  };

  const FileSizeExceededModal = (
    <UploadTooLargeModal
      maxFileSize={MAX_IMAGE_SIZE_AS_MB_STRING}
      onSelectNewFile={handleSelectImage}
    />
  );

  const uploadInputComponent = (
    <input
      ref={inputRef}
      accept={`image/.${IMAGE_EXTENSIONS.join(',.')}`}
      onChange={handleUpload}
      style={{ display: 'none' }}
      title="image-upload-input"
      type="file"
    />
  );

  return {
    FileSizeExceededModal,
    handleSelectImage,
    uploadInputComponent,
  };
};

export default useImageUpload;
