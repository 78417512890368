import React from 'react';
import { Position } from 'reactflow';

import EdgeHandle, { StyledEdgeHandleProps } from './EdgeHandle';

interface NodeHandlesProps extends StyledEdgeHandleProps {}

const NodeHandles = ({ height }: NodeHandlesProps) => {
  return (
    <>
      <EdgeHandle height={height} id="left" position={Position.Left} type="source" />
      <EdgeHandle height={height} id="left" position={Position.Left} type="target" />
      <EdgeHandle height={height} id="right" position={Position.Right} type="source" />
      <EdgeHandle height={height} id="right" position={Position.Right} type="target" />
    </>
  );
};

export default NodeHandles;
