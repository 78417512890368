import React, { useMemo } from 'react';
import type { Cell } from 'react-table';

import type IndexModel from '@api/indexes/IndexModel';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import Code from '@components/UI/Code';

import IndexTableColumnCell from './IndexTableColumnCell';

interface IndexTableProps {
  data?: {
    results?: Array<IndexModel>;
  };
  isLoading?: boolean;
}

const IndexTable: React.FC<IndexTableProps> = ({ data, isLoading }) => {
  const columns: ColumnConfig<IndexModel>[] = useMemo(
    () => [
      {
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row: { original } }: Cell<IndexModel>) => (
          <Code text={original.definition}>{original.name}</Code>
        ),
        Header: 'Name',
        id: 'name',
        width: '100%',
      },
      {
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row: { original } }: Cell<IndexModel>) => (
          <IndexTableColumnCell indexItem={original} />
        ),
        Header: 'Columns',
        id: 'columns',
        width: '100%',
      },
    ],
    [],
  );
  return (
    <TableStyled>
      <Table
        basic="very"
        columns={columns}
        compact
        data={data?.results ?? []}
        disableColumnFiltering
        disablePagination
        disableRowSelect
        loading={isLoading}
        selectable
        sortable
        unstackable
      />
    </TableStyled>
  );
};

export default IndexTable;
