import type { GetBiNotebooksListQueryParams } from '@api/openapi.generated';
import { paginatedSelect, rawSelect } from '@api/utils';
import type { UseFetchOptions } from '@hooks/useFetch';
import useFetch from '@hooks/useFetch';
import type { PaginatedResponse } from '@models/PaginatedResponse';

import type { BiNotebookModel } from './biNotebookModel.v1';
import mapBiNotebookModel from './biNotebookModel.v1';
import * as cacheKeys from './cacheKeys';

export const fetchBiNotebooksSelect = paginatedSelect(mapBiNotebookModel);

export const useFetchBiNotebooks = (
  options?: UseFetchOptions<PaginatedResponse<BiNotebookModel>, GetBiNotebooksListQueryParams>,
) => {
  return useFetch<PaginatedResponse<BiNotebookModel>>({
    ...options,
    queryKey: [...cacheKeys.all, options?.params],
    select: fetchBiNotebooksSelect,
    url: `/bi/notebooks/`,
  });
};

export const fetchBiNotebookSelect = rawSelect(mapBiNotebookModel);

export const useFetchBiNotebook = (
  guid: string,
  options: UseFetchOptions<BiNotebookModel, GetBiNotebooksListQueryParams>,
) => {
  return useFetch<BiNotebookModel>({
    ...options,
    queryKey: [...cacheKeys.biNotebook(guid), options.params],
    select: fetchBiNotebookSelect,
    url: `/bi/notebooks/${guid}/`,
  });
};
