import { DataTypesConfig } from '@configs/dataTypes/types';

import type { RelatedDashboardsTableProps } from '@components/Table/RelatedDashboardsTable';

export interface RelatedDashboardsConfigItem
  extends Pick<RelatedDashboardsTableProps, 'customColumProps'> {}

const RELATED_DASHBOARDS_CONFIG: DataTypesConfig<RelatedDashboardsConfigItem> = {
  tableau: {
    default: {
      customColumProps: {
        lastRun: {
          Header: 'Last Viewed',
        },
      },
    },
  },
};

export default RELATED_DASHBOARDS_CONFIG;
