import React from 'react';
import { Cell } from 'react-table';

import { CustomAttributeModel } from '@api/customAttributes/CustomAttributeModel';
import { CustomAttributeValueModel } from '@api/customAttributeValues';
import { CustomAttributeValue } from '@api/customAttributeValues/CustomAttributeValueModel';
import { CustomAttributeTitle } from '@components/Forms/EditCustomAttributeValueForm';
import CustomAttributeCell from '@components/Table/Cells/CustomAttributeCell/CustomAttributeCell';

interface BuildCustomAttributesColumnsArgs {
  customAttributes: CustomAttributeModel[];
  customAttributesValues: CustomAttributeValueModel[];
  isDataSourceEditable: boolean;
}

const buildCustomAttributesColumns = ({
  customAttributes,
  customAttributesValues,
  isDataSourceEditable,
}: BuildCustomAttributesColumnsArgs) =>
  customAttributes?.map((customAttribute) => ({
    Cell: (props: Cell<CustomAttributeValue>) => {
      const column = props.row.original;
      return (
        <CustomAttributeCell
          {...props}
          customAttribute={customAttribute}
          customAttributeValue={customAttributesValues
            .filter((value) => value.customAttribute.guid === customAttribute.guid)
            .find((value) => value.item === column.guid)}
          guid={column.guid}
          isDataSourceEditable={isDataSourceEditable}
        />
      );
    },
    Header: () => (
      <CustomAttributeTitle
        compDisplay="block"
        item={customAttribute}
        overflow="hidden"
        textOverflow="ellipsis"
      />
    ),
    disableFilters: true,
    dropdownCheckboxLabel: customAttribute.name,
    id: `custom-attribute-${customAttribute.guid}`,
    width: 120,
  }));

export default buildCustomAttributesColumns;
