import React from 'react';

import Text from '@components/Text';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import flags from '@features';
import theme from '@styles/theme';

import { StyledWrapper } from './DeletedLabel.styles';

const DeletedLabel: React.FC = () => {
  if (!flags.deleted_label) {
    return null;
  }

  return (
    <Tooltip content="This object was deleted from source">
      <StyledWrapper>
        <Icon name="warning" size="12px" />
        <Text
          color={theme.colors.gray[700]}
          fontSize={theme.typography.fontSizes.xs}
          fontWeight="medium"
        >
          Deleted
        </Text>
      </StyledWrapper>
    </Tooltip>
  );
};

export default DeletedLabel;
