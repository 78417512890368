import { RefObject, useState } from 'react';
import { Boundary, Padding, Placement, PositioningStrategy, Rect } from '@popperjs/core';
import { usePopper as useReactPopper } from 'react-popper';

import theme from '@styles/theme';

export interface UsePopperProps {
  arrowPadding?:
    | ((arg0: { placement: Placement; popper: Rect; reference: Rect }) => Padding)
    | number;
  boundary?: Boundary;
  boundaryPadding?: Padding;
  customPopperStyles?: React.CSSProperties;
  fallbackPlacements?: Placement[];
  fitAnchorWidth?: boolean;
  flipPadding?: Padding;
  isOpen?: boolean;
  offset?: [number, number];
  placement?: Placement;
  popperRef?: RefObject<any>;
  showArrow?: boolean;
  strategy?: PositioningStrategy;
}

const usePopper = ({
  arrowPadding,
  boundary,
  boundaryPadding,
  customPopperStyles,
  fallbackPlacements = ['top', 'left', 'right'],
  fitAnchorWidth,
  flipPadding,
  offset = [0, 8],
  placement = 'bottom',
  showArrow = false,
  strategy = 'absolute',
}: UsePopperProps) => {
  const [popperRef, setPopperRef] = useState<HTMLElement | null>(null);
  const [anchorRef, setAnchorRef] = useState<HTMLElement | null>(null);
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);

  const { attributes, state, styles, update } = useReactPopper(anchorRef, popperRef, {
    modifiers: [
      {
        enabled: showArrow,
        name: 'arrow',
        options: {
          element: arrowRef,
          padding: arrowPadding,
        },
      },
      {
        name: 'offset',
        options: {
          offset,
        },
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements,
          padding: flipPadding,
        },
      },
      {
        name: 'preventOverflow',
        options: {
          boundary,
          padding: boundaryPadding,
        },
      },
    ],
    placement,
    strategy,
  });

  return {
    anchorProps: {
      style: styles.reference,
      ...attributes.reference,
      ref: setAnchorRef,
    },
    ...(showArrow && {
      arrowProps: {
        'data-placement': attributes?.popper?.['data-popper-placement'] || placement,
        'data-popper-arrow': true,
        placement: state?.placement || placement,
        ref: setArrowRef,
        style: styles.arrow,
        ...attributes.arrow,
      },
    }),
    popperProps: {
      style: {
        ...styles.popper,
        maxWidth: 'calc(100% - 80px)',
        zIndex: theme.zIndex.floatingElement,
        ...(fitAnchorWidth && { width: `${anchorRef?.offsetWidth}px` }),
        ...customPopperStyles,
      },
      ...attributes.popper,
      ref: setPopperRef,
    },
    update,
  };
};

export default usePopper;
