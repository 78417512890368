import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import * as CSS from 'csstype';

import makeSpace, { MakeSpaceProps } from '@styles/mixins/makeSpace';
import type Theme from '@styles/theme';
import { Radius } from '@styles/theme/radius';

const skeletonShapes = {
  circular: {
    borderRadius: '50%',
    height: 50,
    width: 50,
  },
  default: {
    width: '100%',
  },
  text: {
    borderRadius: '3px',
    height: '1rem',
  },
};

const skeletonAnimation = () => keyframes`
  50% {
    opacity: 0.65;
  }
`;

interface SkeletonProps {
  animate?: boolean;
  borderRadius?: keyof Radius;
  compHeight?: CSS.Property.Height;
  compWidth?: CSS.Property.Width;
  gap?: number;
  grayShade?: keyof typeof Theme.colors.gray;
  variant?: keyof typeof skeletonShapes;
}

const Skeleton = styled.span<SkeletonProps & MakeSpaceProps>`
  ${({ theme, ...props }) => makeSpace(theme, props)};
  ${({ variant = 'default' }) => skeletonShapes[variant]}
  animation: ${({ animate }) => (animate ? skeletonAnimation : undefined)};
  animation-duration: 2s;
  animation-iteration-count: infinite;
  background-color: ${({ grayShade = 100, theme }) => theme.colors.gray[grayShade]};
  border-color: ${({ grayShade = 100, theme }) => theme.colors.gray[grayShade]};
  color: ${({ grayShade = 100, theme }) => theme.colors.gray[grayShade]};
  display: flex;
  flex-wrap: wrap;
  gap: ${({ gap = 0, theme }) => theme.space(gap)};
  height: ${({ compHeight }) => compHeight};
  user-select: none;
  width: ${({ compWidth }) => compWidth};
  border-radius: ${({ borderRadius, theme }) => theme.radius[borderRadius as keyof Radius]};
`;

Skeleton.defaultProps = {
  animate: true,
  'aria-busy': 'true',
  'aria-label': 'loading',
  'aria-live': 'polite',
  role: 'progressbar',
};

export default Skeleton;
