import findConfig from '@configs/dataTypes/findConfig';
import type { DataTypesConfig } from '@configs/dataTypes/types';
import getIcons from '@configs/icons/getIcons';
import getTooltips from '@configs/tooltips/getTooltips';

import DataTypesModel from '@models/DataTypesModel/DataTypesModel';
import getTooltip from '@utils/getTooltip';
import titlelize from '@utils/titlelize';

const mapDataTypesModel = (data: any): DataTypesModel | undefined => {
  if (!data) {
    return undefined;
  }

  const obj = {
    dataSourceType: data.data_source_type,
    dataType: (() => {
      if (data.data_source_type === data.data_type) {
        return '';
      }

      return data.data_type;
    })(),
    objectType: data.object_type,
    rawDataType: data.data_type,
  };

  const tooltips = () => {
    const dataTypesTooltips = getTooltips(obj);
    const dataType = getTooltip(obj.objectType, obj.dataType);
    const objectType = getTooltip(obj.objectType);
    return {
      dataSource: dataTypesTooltips.dataSource ?? titlelize(obj.dataSourceType),
      dataType: dataTypesTooltips.dataType ?? dataType ?? objectType,
      objectType: dataTypesTooltips.objectType ?? objectType,
    };
  };

  return {
    ...obj,
    findConfig<T>(ref: DataTypesConfig<any>) {
      return findConfig<T>(ref, obj);
    },
    icons: getIcons(obj),
    tooltips: tooltips(),
  };
};

export default mapDataTypesModel;
