import React, { useState } from 'react';
import styled from '@emotion/styled';

import { useFetchDataSourceConfigInit } from '@api/ingestion';
import { useFetchLookMLProjects, usePatchLookMLProjects } from '@api/lookML';
import Alert from '@components/Alert';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import NextStepButton from '@components/DataSourceSetup/components/Buttons/NextStepButton';
import PreviousStepButton from '@components/DataSourceSetup/components/Buttons/PreviousStepButton';
import { BoxWrapper } from '@components/DataSourceSetup/components/DataSourceAddStep';
import { USER_DOCS_URL } from '@components/DataSourceSetup/components/DataSourceAddStep/userDocsUrlConfig';
import { CenterLoader, IndeterminateLoaderModal } from '@components/IndeterminateLoader';
import Link from '@components/Link';
import Text from '@components/Text';
import { StyledTextProps } from '@components/Text/Text';
import { ModalFooter } from '@components/UI/Modal';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import { FilterOptions, setParams, useUpdateFilters } from '@utils/filters';

import ErrorDescriptionMarkdown from '../ErrorDescriptionMarkdown';

import LookMLProjectTable from './LookMLProjectTable';

const lookmlConfigRequest: FilterOptions = {
  page: 1,
  page_size: 100,
};

const searchConfig: { [key: string]: keyof FilterOptions } = {
  name: 'search_name',
};

export const StyledText = styled(Text)<StyledTextProps>`
  color: black;
  font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
  margin: ${({ theme }) => theme.space(0.5, 0)} !important;
  font-size: 14px;
`;

const helpText = (
  <>
    <StyledText display="inline">
      This step is required if you want to see Data Lineage between DWH and Looker.
    </StyledText>
    <StyledText display="block">1. Check the LookML project(s) to connect from below.</StyledText>
    <StyledText display="inline">
      2. Copy the Select Star SSH Key to GitHub settings + Deploy keys (
      <Link
        fontSize="inherit"
        href={USER_DOCS_URL.looker}
        rel="noopener noreferrer"
        target="_blank"
        underline
      >
        instructions
      </Link>
      ).
    </StyledText>
  </>
);

interface Props {
  guid: string;
  nextStep: () => void;
  prevStep: () => void;
}

const LookMLProjectSetup: React.FC<Props> = ({ guid, nextStep, prevStep }) => {
  const segment = useSegmentContext();
  const [selectedRowIds, setSelectedRowIds] = useState<{ [guid: string]: boolean }>({});
  const FilterService = useUpdateFilters(lookmlConfigRequest, searchConfig, {});
  const { filter } = FilterService;
  const [isFirstTime, setIsFirstTime] = useState<boolean>(true);

  const {
    data: lookmlConfigResponse,
    error: lookmlError,
    isLoading,
    refetch,
  } = useFetchLookMLProjects(guid, {
    cacheTime: 0,
    onSuccess: (d) => {
      if (d?.count === 0 && isFirstTime) {
        initLookMLProjectsConfig();
      }
      if (d.count > 0 && Object.keys(selectedRowIds).length === 0) {
        const state: { [guid: string]: boolean } = {};
        d?.results?.filter((ds) => ds.shouldIngest).forEach((ds) => (state[ds.guid] = true));
        setSelectedRowIds(state);
      }
      setIsFirstTime(false);
    },
    params: setParams(filter),
    staleTime: 0,
  });

  const {
    error: errorRefetchInit,
    isLoading: isLoadingDSInit,
    refetch: initLookMLProjectsConfig,
  } = useFetchDataSourceConfigInit(guid, {
    cacheTime: 0,
    enabled: false,
    /**
     * we may not get any lookml projects, so we have to run data source
     *  config init to figure out if we have any at all
     */
    onSuccess: () => {
      refetch();
    },

    staleTime: 0,
  });

  const {
    error: errorSave,
    isLoading: loadingSave,
    mutate,
  } = usePatchLookMLProjects(guid, {
    onSuccess: () => {
      nextStep();
    },
  });

  const projects = !isLoading && lookmlConfigResponse ? lookmlConfigResponse?.results : undefined;

  const totalPages =
    lookmlConfigResponse && filter.page_size
      ? Math.ceil(lookmlConfigResponse.count / filter.page_size)
      : 1;

  const onResponse = () => {
    const projectsToSave = { items: Object.keys(selectedRowIds) };
    mutate(projectsToSave);
    segment?.track(SegmentTrackEventName.ImportData_LookmlConnectNextButtonClicked);
  };

  const handleRefresh = () => {
    initLookMLProjectsConfig();
  };

  return (
    <>
      <BoxWrapper>
        <Box gridColumn="1/3">
          <Alert type="info">{helpText}</Alert>
        </Box>
        {isLoadingDSInit ? (
          <CenterLoader>
            <IndeterminateLoaderModal
              active
              content="Loading projects..."
              indeterminate
              inline="centered"
              size="medium"
            />
          </CenterLoader>
        ) : (
          <LookMLProjectTable
            data={projects}
            filterService={FilterService}
            selectedRowIds={selectedRowIds}
            setSelectedRowIds={setSelectedRowIds}
            totalPages={totalPages}
          />
        )}
        {(lookmlError || errorRefetchInit || errorSave) && (
          <Box mt={2}>
            {lookmlError && (
              <Alert title="Something went wrong" type="error">
                {lookmlError.data ?? 'Please try again later.'}
              </Alert>
            )}
            {errorRefetchInit && (
              <Alert title="Something went wrong" type="error">
                {errorRefetchInit.data ?? 'Please try again later.'}
              </Alert>
            )}
            {errorSave && errorSave.status === 500 && (
              <Alert title="Couldn't save changes" type="error">
                {errorSave.data ?? 'Please try again later.'}
              </Alert>
            )}
            {errorSave && errorSave.status === 403 && (
              <Alert title="Couldn't save changes" type="error">
                {errorSave.data.detail ?? 'Please try again later.'}
              </Alert>
            )}
            {errorSave && errorSave.data.items && (
              <Alert title="Invalid Selections" type="error">
                {errorSave.data.items}
              </Alert>
            )}
            <ErrorDescriptionMarkdown error={lookmlError} />
            <ErrorDescriptionMarkdown error={errorRefetchInit} />
            <ErrorDescriptionMarkdown error={errorSave} />
          </Box>
        )}
      </BoxWrapper>
      <ModalFooter>
        <PreviousStepButton
          disabled={loadingSave || !projects}
          onClick={() => {
            segment?.track(SegmentTrackEventName.ImportData_LookmlConnectPrevButtonClicked);
            prevStep();
          }}
        />
        <Button compWidth="100px" disabled={loadingSave} onClick={handleRefresh}>
          Refresh
        </Button>
        <NextStepButton disabled={loadingSave || !projects} onClick={onResponse} />
      </ModalFooter>
    </>
  );
};

export default LookMLProjectSetup;
