import { Editor } from 'slate';

import { insertImage, isImageUrl, parseFilesToImages } from '../helpers/imageHelpers';

const handleFileSizeExceeded = () => {
  alert('Image size cannot exceed 2MB');
};

/**
 * Plugin for handing pasting images, image urls or uploading images into the editor.
 */
const withImagesPlugin = (editor: Editor) => {
  const { insertData } = editor;

  const handleInsertImage = (url: string) => {
    insertImage(editor, url);
  };

  editor.insertData = (data) => {
    const text = data.getData('text/plain');
    const { files } = data;
    if (files && files.length > 0) {
      parseFilesToImages(files, handleFileSizeExceeded, handleInsertImage);
    } else if (isImageUrl(text)) {
      insertImage(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

export default withImagesPlugin;
