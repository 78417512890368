import React from 'react';

import { usePatchCollectionsItems } from '@api/collections';
import { CollectionModel } from '@api/collections/CollectionModel';
import { SearchModel } from '@api/search/SearchModel';
import Alert from '@components/Alert';
import Box from '@components/Box';
import Button from '@components/Button';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import SelectedSearchItemPill from '@components/Modal/CreateMetricModal/SelectedSearchItemPill';
import QuickSearchInput from '@components/QuickSearch/QuickSearchInput';
import { renderInfoToast } from '@components/Toast';
import Icon from '@components/UI/Icon';
import { IconName } from '@components/UI/Icon/sprites/icon.list.generated';
import Modal, { ModalContent, ModalFooter, ModalHeader } from '@components/UI/Modal';
import theme from '@styles/theme';

import { collectionDataAssetsSearchTabs } from './collectionDataAssetsSearchTabs';

export interface AddCollectionDataAssetsModalProps {
  collection: CollectionModel;
  onClose: () => void;
}

const AddCollectionDataAssetsModal: React.FC<AddCollectionDataAssetsModalProps> = ({
  collection,
  onClose,
}) => {
  const {
    error,
    isLoading,
    mutateAsync: addAssetsMutate,
  } = usePatchCollectionsItems({
    onSuccess: () => {
      renderInfoToast('Assets successfully added.');
      onClose?.();
    },
  });

  const { handleSubmit, setValues, values } = useForm<{ assets: SearchModel[] }>({
    initialValues: {
      assets: [],
    },
    onSubmit: (data) => {
      const assetGuids = data?.assets.map((asset) => asset.guid);

      addAssetsMutate({
        item_set: [
          {
            collections: [collection?.guid],
            items: assetGuids,
          },
        ],
      });
    },
  });

  return (
    <Modal compWidth="540px" onClose={onClose} size="unset">
      <ModalHeader
        onClose={onClose}
        title={
          <>
            Add Data Assets to <Icon name={collection?.icon as IconName} /> {collection?.name}
          </>
        }
      />
      <Form isLoading={isLoading} onSubmit={handleSubmit}>
        <ModalContent maxHeight="80vh" minHeight="auto" overflowY="auto" px={3} py={2}>
          <Box compDisplay="flex" flexDirection="column" gap={2}>
            <Box compDisplay="flex" compWidth="100%" flexDirection="column" gap={0.5}>
              <QuickSearchInput
                leftIcon={<Icon color="gray.700" name="search" size="16px" />}
                onResultSelect={(item: SearchModel) =>
                  setValues((prev) => ({
                    ...prev,
                    assets: [...(prev?.assets || []), item],
                  }))
                }
                tabs={collectionDataAssetsSearchTabs}
              />
              <Box
                compDisplay="flex"
                flexDirection="column"
                gap={1}
                maxHeight={theme.space(30)}
                noDefault
                overflow="auto"
              >
                {values.assets?.map((item) => (
                  <Box key={item.guid}>
                    <SelectedSearchItemPill
                      onRemove={() =>
                        setValues((prev) => ({
                          ...prev,
                          assets: prev?.assets?.filter((i) => i !== item),
                        }))
                      }
                      selectedItem={item}
                      useDefaultName
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
          {error && (
            <Alert mt={2} title="Something went wrong." type="error">
              {error?.data?.detail}
            </Alert>
          )}
        </ModalContent>
        <ModalFooter>
          <Button disabled={isLoading} onClick={onClose} type="button" variant="outlined">
            Cancel
          </Button>
          <Button disabled={isLoading || values?.assets?.length === 0} type="submit">
            Add
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddCollectionDataAssetsModal;
