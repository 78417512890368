import React from 'react';

import { useFetchTeams } from '@api/teams';
import { TeamModel } from '@api/teams/TeamModel';
import Box from '@components/Box';
import IconButton from '@components/IconButton';
import LayoutMainGrid from '@components/Layout/LayoutMainGrid';
import CreateTeamModal from '@components/Modal/CreateTeamModal';
import AdminTeamsTable from '@components/Table/AdminTeamsTable';
import Icon from '@components/UI/Icon';
import { useModal } from '@context/Modal';
import AdminTitleView from '@pages/Admin/AdminTitleView/AdminTitleView';
import MetadataDecorator from '@utils/MetadataDecorator';

const AdminTeamsPage: React.FC = () => {
  const { data, refetch } = useFetchTeams();
  const { MODAL_IDS, checkModalOpened, closeModal, openModal } = useModal();
  const totalMemberCount = data?.results?.reduce(
    (totalCount: number, team: TeamModel) => totalCount + (team?.membersCount || 0),
    0,
  );

  return (
    <>
      <MetadataDecorator title="Teams Admin" />
      <LayoutMainGrid>
        <Box maxWidth="100%" mb={8} px={1.5} py={0}>
          <AdminTitleView
            action={
              <IconButton label="Create Team" onClick={() => openModal(MODAL_IDS.createTeam)}>
                <Icon name="plus" />
              </IconButton>
            }
            as="h1"
            compSize="lg"
            mb={3}
            title="Teams"
          />
          <AdminTeamsTable
            itemCount={data?.results.length}
            memberCount={totalMemberCount}
            teams={data?.results}
          />
        </Box>
      </LayoutMainGrid>
      {checkModalOpened(MODAL_IDS.createTeam) && (
        <CreateTeamModal
          onClose={() => {
            closeModal(MODAL_IDS.createTeam);
            refetch();
          }}
        />
      )}
    </>
  );
};

export default AdminTeamsPage;
